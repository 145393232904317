<template>
  <div id="RewardMessage">
    <section class="content-header">
      <h1>信件發獎</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">信件發獎</li>
      </ol>
    </section>
    <section class="content">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li class="active"><a style="cursor:pointer">信件發獎</a></li>
          <li><router-link to="/NMJ/RewardMessageHistory">玩家信件查詢</router-link></li>
        </ul>
        <div class="box-body">
          <form class="top-box" @submit="$event.preventDefault(); resetDataList();">
            <button v-if="$user.hasPermission('給獎訊息編輯')" type="button" @click="addMessage()" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增
            </button>
            <div class="form-group" style="width: auto">
              <label class="control-label">發送時間：</label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="true"
                  :propEndDate.sync="local.endTime"
                  :propStartDate.sync="local.startTime"
                />
              </div>
            </div>
            <div class="select-group">
              <select class="form-control" v-model.number="searchData.狀態" @change="resetDataList()">
                <option v-for="(option, i) in def.mailState" :key="i" :value="i">{{ option }}</option>
              </select>
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary">
                查詢
              </button>
            </div>
          </form>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <!-- <th style="min-width: 75px; width: 5%">編號</th> -->
                  <th style="min-width:100px; width:10%">發送時間</th>
                  <th style="min-width:100px; width:10%">有效時間</th>
                  <th style="min-width: 75px; width: 5%">處理人員</th>
                  <th style="min-width:150px; width:15%">標題</th>
                  <th style="min-width:150px; width: 5%">發送內容</th>
                  <th style="min-width: 75px; width: 5%">狀態</th>
                </tr>
                <tr v-if="message"><td colspan="8">{{ message }}</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="index" :class="{ 'bg-gray-light': item.狀態 === 3 }">
                  <!-- <td>{{ item.信件編號 }}</td> -->
                  <td>{{ uiDate(item.發送時間) }}</td>
                  <td>{{ uiDate(item.到期時間) }}</td>
                  <td>{{ item.建立者 }}</td>
                  <td class="text-left">{{ item.信件標題 }}</td>
                  <td>
                    <button class="btn btn-primary" @click="checkContent(item)">
                      查看
                    </button>
                    <button v-if="$user.hasPermission('給獎訊息編輯') && (item.狀態 === 0 || item.狀態 === 1)" class="btn btn-primary" @click="editMessage(item)" style="margin-left:.35em;">
                      修改
                    </button>
                    <button v-else-if="item.狀態 === 2" class="btn btn-success" @click="checkContent(item, true)" style="margin-left:.35em;">
                      明細
                    </button>
                    <button v-else class="btn" style="margin-left:.35em; visibility:hidden;">查看</button>
                  </td>
                  <td :class="{ 'text-red': item.狀態 === 0 }">
                    {{ def.mailState[item.狀態] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <!-- <pre>{{ datas[0] }}</pre> -->
        <!-- <pre>{{ gameTypes }}</pre> -->
      </div>
    </section>
    <rewardMessageContent ref="rewardMessageContent" />
  </div>
</template>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import rewardMessageContent from "@/views/projects/NMJ/RewardMessageContent";
import { mapState } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    rewardMessageContent,
  },
  data() {
    return {
      def: define,
      loading: false,
      message: "",
      total:  0, //總筆數
      searchData: {
        狀態: -1,
        pageNum: 1,
        pageSize: 20,
      },
      local: {
        startTime: new moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().add(7, "days").format("YYYY-MM-DD 23:59:59"),
      },
      datas: [],
      gameTypes: {},
      checks: ["類型編號", "類型名稱", "獎項編號", "獎項名稱"],
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  async created() {
    this.getDataList();
    this.$store.dispatch("nmjMail/itemList").then(result => {
      this.gameTypes = result;
      Object.assign(this.gameTypes.Item, define.gameCoins);
    })
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.onPageChange(1, this.searchData.pageSize)
    },
    getDataList() {
      this.searchData.開始時間 = new Date(`${this.local.startTime} UTC`);
      this.searchData.結束時間 = new Date(`${this.local.endTime} UTC`);
      this.message = "查詢中";
      this.loading = true;
      this.$store
        .dispatch('nmjMail/queryAll', this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.message = this.datas.length ? "" : "查詢無任何資料";
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }, (msg) =>{
          this.message = msg;
          this.loading = false;
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // 查看內容
    checkContent(item, showPlayers = false) {
      this.$refs.rewardMessageContent.showRewardMessageContent(
        item,
        this.gameTypes,
        this.searchData,
        showPlayers
      );
    },
    // 查看歷程
    checkHistory(item) {
      this.$router.push({
        name: "NmjRewardMessageHistory",
        params: {
          id: item.prizeMessageId,
          searchData: this.searchData,
        },
      });
    },
    // 刪除 = 停用
    deleteMessage(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjMail/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },
    // 修改草稿
    editMessage(item) {
      this.$router.push({
        name: "NmjRewardMessageEdit",
        params: {
          id: item.信件編號,
          searchData: this.searchData,
        },
      });
    },
    // 新增
    addMessage() {
      this.$router.push({
        name: "NmjRewardMessageEdit",
        params: {
          searchData: this.searchData,
        },
      });
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>
