<template>
  <div class="modal fade" ref="formDialog">
    <div class="modal-dialog">
      <form class="modal-content" ref="form" @submit.prevent="submit">
        <div class="modal-header">
          <slot name="header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">{{ $t(title) }}</h4>
          </slot>
        </div>
        <div class="modal-body">
          <slot>

          </slot>
        </div>
        <div class="modal-footer text-center">
          <slot name="footer">
            <button type="submit" class="btn btn-primary" style="min-width:8em">{{$t('確認送出')}}</button>
            <button type="button" class="btn btn-default" style="min-width:8em" data-dismiss="modal">{{$t('取消')}}</button>
          </slot>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  props: ['title'],
  emits: ['submit', 'show', 'hide'],
  data() {
    return {};
  },
  mounted() {
    $(this.$refs.formDialog).on('show.bs.modal', this.onShow)
    $(this.$refs.formDialog).on('hidden.bs.modal', this.onHide)
  },
  methods: {
    show() {
      $(this.$refs.formDialog).modal("show");
    },
    hide() {
      $(this.$refs.formDialog).modal("hide");
    },
    submit(event){
      this.$emit('submit', event);
    },
    onShow(event){
      this.$emit('show', event);
    },
    onHide(event){
      this.$emit('hide', event);
    },
  },
};
</script>