<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>{{$t('遊戲歷程')}}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">{{$t('首頁')}}</router-link></li>
        <li class="active">{{$t('遊戲歷程')}}</li>
      </ol>
    </section>
    <section class="content" style="min-height:auto;">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="tab = 1" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('遊戲歷程')}}</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="tab = 2" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">{{$t('勝負分歷程')}}</a>
          </li>
        </ul>
      
        <!-- 遊戲歷程 -->
        <div class="tab-content" v-show="tab == 1">
          <form class="top-box clearfix" @submit.prevent="getData()">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('暱稱')" v-model="searchHistoryData.暱稱">
            </div>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('遊戲館')" v-model="searchHistoryData.遊戲館">
            </div>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('搜尋關鍵字')" v-model="searchHistoryData.關鍵字">
            </div>
            <div class="form-group pull-left">
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date datetime pull-left mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker
                    :propStartDate.sync="searchHistoryData.開始時間"
                    :propEndDate.sync="searchHistoryData.結束時間"
                    :propTimePicker="true"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>

              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  {{$t('查詢')}}
                </button>
                <button type="button" class="btn btn-default" @click="exportGameHistory()" :disabled="playerGameHistoryList.length === 0">
                  {{$t('匯出')}}
                </button>
              </div>
            </div>
          </form>

          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <!-- <th style="min-width: 50px; width: 3%">序號</th> -->
                  <th style="min-width:105px; width:15%">{{$t('時間')}}</th>
                  <th style="min-width:105px; width:10%">{{$t('暱稱')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('等級')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('遊戲館')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('點數')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('回饋金')}}</th>
                  <th style="min-width:105px; width: 5%">{{$t('星幣')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('押注')}}</th>
                  <th style="min-width: 80px; width: 5%">{{$t('增減分')}}</th>
                  <th style="min-width:180px; width:20%">{{$t('訊息')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading"><td colspan="11">{{$t('查詢中')}}</td></tr>
                <tr v-else-if="playerGameHistoryList.length === 0"><td colspan="11">{{$t('查無資料')}}</td></tr>
                <tr v-else v-for="(item, index) in pagedHistoryData" :key="item.日期">
                  <!-- <td>{{ index +1}}</td> -->
                  <td>{{ item.時間 }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ item.等級 }}</td>
                  <td>{{ item.遊戲館 }}</td>
                  <td>{{ item.點數 }}</td>
                  <td>{{ item.回饋金 }}</td>
                  <td>{{ item.星幣 }}</td>
                  <td>{{ item.押注 }}</td>
                  <td>{{ item.增減分 }}</td>
                  <td class="t-l">{{ item.訊息 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="total > 0" class="text-center mt-10">
            <Pagination
              ref="paginationHistory"
              :total="total"
              :pageLimitCount="searchHistoryData.pageSize"
              v-on:pageMethod="onPageChange"
              :type2="true"
            />
          </div>
        </div>

        <!-- 勝負分歷程 -->
        <div class="tab-content" v-show="tab == 2">
          <form class="top-box clearfix" 
            @submit.prevent="getData()">
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" :placeholder="$t('暱稱')" v-model="searchPointData.暱稱">
            </div>
            <div class="form-group pull-left">
              <div class="form-group pull-left m-0">
                <label class="control-label pull-left mt-7">{{$t('查詢區間')}}：</label>
                <div class="input-group date pull-left mb-10 mr-10">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <DateRangePicker 
                    :propStartDate.sync="searchPointData.開始日期"
                    :propEndDate.sync="searchPointData.結束日期"
                    displayFormat="YYYY/MM/DD HH:mm"
                  />
                </div>
              </div>
              <div class="pull-left mb-10 mr-10">
                <button type="submit" class="btn btn-primary mr-10">
                  {{$t('查詢')}}
                </button>
                <button type="button" class="btn btn-default" 
                  @click="exportPointHistory()" 
                  :disabled=" playerPoinList.length == 0">
                  {{$t('匯出')}}
                </button>
              </div>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:10%">{{$t('日期')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('日押')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('日得')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('勝負分')}}</th>
                  <th style="min-width:100px; width:10%">{{$t('得分率')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="poinLoading">
                  <td colspan="5">{{$t('查詢中')}}</td>
                </tr>
                <tr v-else-if="pointMessage">
                  <td colspan="5">{{ $t(pointMessage) }}</td>
                </tr>
                <tr v-else-if="playerPoinList.length === 0">
                  <td colspan="5">{{$t('查無資料')}}</td>
                </tr>
                <tr v-else v-for="(item, index) in pagedPointData" :key="index">
                  <td>{{ item.時間 }}</td>
                  <td>{{ item.押 | currencyFormat }}</td>
                  <td>{{ item.得 | currencyFormat }}</td>
                  <td>{{ item.勝負分 | currencyFormat  }}</td>
                  <td>{{ item.得分率 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-show="poinTotal > 0" class="text-center mt-10">
            <Pagination
              ref="paginationPoint"
              :total="poinTotal"
              :pageLimitCount="searchPointData.pageSize"
              v-on:pageMethod="onPageChange"
              :type2="true"
            />
          </div>
        </div>
      </div>
    </section>
    
    <!-- <pre>tab: {{ tab }}</pre> -->
    <!-- <pre>tota:{{ total  }}</pre>
    <pre>{{ playerGameHistoryList }}</pre> -->
    <!-- <pre>poinTotal:{{ poinTotal }}</pre> -->
    <!-- <pre>{{ playerPoinList }}</pre> -->
    
  </div>
</template>


<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
    router
  },
  props:{
    name: ""
  },
  data() {
    return {
      loading: false,
      tab: 1,

      searchHistoryData: {
        暱稱: "",
        遊戲館: "",
        關鍵字: "",
        開始時間: new moment().add(-3, "days").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20, 
      },
      playerGameHistoryList: [], //all data
      playerGameHistoryPage: [], //分頁過
      total: 0, //總筆數

      // Points
      searchPointData: {
        暱稱: "",
        開始日期: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
        結束日期: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20, 
      },
      pointMessage: "", //錯誤訊息
      poinLoading: false,
      poinTotal: 0, //總筆數
      playerPoinList: [],
      playerPoinPage: [], //分頁過
    };
  },
  async created() {
    if (this.$route.query.nick) {
      this.searchHistoryData.暱稱 = this.$route.query.nick;
      this.searchPointData.暱稱 = this.$route.query.nick;
      this.getData();
    }
  },
  watch: {
    // 'playerGameHistoryList'(){ this.searchHistoryData.pageNum = 1; },
    // 'playerPoinList'(){ this.searchPointData.pageNum = 1; },
    'searchHistoryData.暱稱'(newNickname) { this.searchPointData.暱稱 = newNickname; },
    'searchPointData.暱稱'(newNickname) { this.searchHistoryData.暱稱 = newNickname; },
  },
  computed: {
    pagedHistoryData: function(){
      let pageNum = this.searchHistoryData.pageNum;
      let pageSize = this.searchHistoryData.pageSize;
      return this.playerGameHistoryList.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    },
    pagedPointData: function(){
      let pageNum = this.searchPointData.pageNum;
      let pageSize = this.searchPointData.pageSize;
      return this.playerPoinList.slice((pageNum - 1) * pageSize, (pageNum) * pageSize);
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 1) {
        this.searchHistoryData.pageNum = pageIdx;
        this.searchHistoryData.pageSize = pageSize;
      } else {
        this.searchPointData.pageNum = pageIdx;
        this.searchPointData.pageSize = pageSize;
      }
    },
    getData(){
      this.getGameHistoryList();
      this.getProcessPoints();
    },
    getGameHistoryList() {
      if (!this.searchHistoryData.暱稱) {
        this.$eventBus.$emit("showAlert", "請填寫玩家暱稱！");
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("xsw/遊戲歷程查詢", this.searchHistoryData)
        .then((result) => {
          result.sort((a, b) => {return new Date(b.時間) - new Date(a.時間) });
          this.playerGameHistoryList = result || [];
          this.total = this.playerGameHistoryList.length || 0;
          this.$refs.paginationHistory.onPageChange(1);
          this.loading = false;
          if (this.total > 0){
            let query = {};
            if (this.searchHistoryData.暱稱 != "") query['nick'] = this.searchHistoryData.暱稱;
            if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
              this.$router.replace({query:query});
            }
          }
        }, (msg) => {
          this.playerGameHistoryList = [];
          this.total =  0;
          this.loading = false;
        });
    },

    getProcessPoints() {
      if (!this.searchPointData.暱稱) {
        this.$eventBus.$emit("showAlert", "請填寫玩家暱稱！");
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("xsw/勝負分歷程查詢", this.searchPointData)
        .then((result) => {
          result.sort((a, b) => {return new Date(b.時間) - new Date(a.時間) });
          this.playerPoinList = result || [];
          this.poinTotal = this.playerPoinList.length || 0;
          this.$refs.paginationPoint.onPageChange(1);
          this.calculatePints();
          this.loading = false;
          if (this.poinTotal > 0){
            let query = {};
            if (this.searchPointData.暱稱 != "") query['nick'] = this.searchPointData.暱稱;
            if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
              this.$router.replace({query:query});
            }
          }
        }, (msg) => {
          this.playerPoinList = [];
          this.poinTotal =  0;
          this.loading = false;
        });
    },

    calculatePints() {
      this.playerPoinList = this.playerPoinList.map(item => {
          const 押 = item.押;
          const 得 = item.得;
          const 勝負分 = 得 - 押;
          const 得分率 = 押 > 0 ? 得 / 押 : 0; // 避免除以0
          item.時間 = item.時間.split('T')[0].replace(/-/g,'/');

          return {
              ...item,
              勝負分: 勝負分,
              得分率: 得分率.toFixed(2) + '%' // 小數點後兩位
          };
      });
    },

    exportGameHistory() {
        let data = utils.deepCloneObj(this.playerGameHistoryList);
        let items = [];
        data.forEach(item => {
          // console.log(item);
            items.push({
                '時間': item.時間,
                '暱稱': item.暱稱,
                '等級': item.等級,
                '遊戲館': item.遊戲館,
                '點數': item.點數,
                '回饋金': item.回饋金,
                '星幣': item.星幣,
                '押注': item.押注,
                '增減分': item.增減分,
                '訊息': item.訊息,
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.searchPointData.暱稱}_遊戲歷程.xlsx`,
            '遊戲歷程'
        );
    },

    exportPointHistory() {
        let data = utils.deepCloneObj(this.playerPoinList);
        let items = [];
        data.forEach(item => {
          // console.log(item);
            items.push({
                '時間': item.時間,
                '押': item.押,
                '得': item.得,
                '勝負分': item.勝負分,
                '得分率': item.得分率,
            });
        });
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_${this.searchHistoryData.暱稱}_勝負分歷程.xlsx`,
            '勝負分歷程'
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.t-l {
  text-align: left !important;
}

table.table>tbody>tr {
  word-break: break-word;
}
table.table>thead>tr>th {
  word-break: keep-all;
}
.list-table{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}
.dl-grid{
  display: grid;
  grid-template-columns: repeat(4, minmax(min-content, 1fr) 2fr);
  border: 1px solid #eee;

  dt,dd{
    padding: .25em;
    border: inherit;
    margin-right: -1px;
    margin-bottom: -1px;
  }
  dt{
    background: rgba(0,0,0,.1);
    text-align: right;
    white-space: nowrap;
    &::after{content: "："}
  }
  dd{
    text-align-last: left;
  }
}

  .table-grid{
    @supports(display:contents){
      tbody{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 2fr) minmax(150px, 3fr));
      }
      tr{
        display: contents;
      }
    }
    // border-width: 1px 0 0 1px;
    th, td{
      margin: 0 -1px -1px 0;
      border-width: 0 1px 1px 0;
    }
    th{
      background: rgba(0,0,0,.08);
      text-align: right;
      // &::after{content: "："}
    }
    td{
      text-align: left;
    }
    
  }
</style>
