<template>
  <div id="NmjEventAnnouncementEdit">
    <section class="content-header">
      <h1>{{ typeString }}設定</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/AppBannerList">遊戲Banner設定</router-link></li>
        <li class="active">{{ typeString }}設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog"><i class="fa fa-reply"></i> 返回列表</button>
          </div>
          <form class="form-horizontal" name="fcAnnouncementForm" method="post" @submit.prevent="submit()">
            <div class="box-body">
              <div class="form-group" v-if="id">
                <label class="col-sm-3 control-label" for="sendAwards">
                  <span class="text-red">*</span> 編號：
                </label>
                <div class="col-sm-9">
                  <span>{{data.公告編號}}</span>
                </div>
              </div>

              <div class="form-group" v-if="id">
                <label class="col-sm-3 control-label" for="sendAwards">
                  <span class="text-red">*</span> 建立時間：
                </label>
                <div class="col-sm-9">
                  <span>{{data.建立時間 | dateFormat("YYYY/MM/DD HH:mm:ss") }}</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputStatus">
                  <span class="text-red">*</span> 是否開啟：
                </label>
                <div class="col-sm-9" v-if="loading == false">
                  <toggle-button v-model="data.status" :labels="true" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="sendAwards">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-9">
                  <select class="form-control" style="max-width:320px" required v-model="data.分類">
                    <option v-for="(t, k, i) in def.bannerType" :value="k">{{ t }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputPlatform">
                  <span class="text-red">*</span> 顯示平台(可複選)：
                </label>
                <div class="form-group col-sm-9">
                  <div class="checkbox checkbox-inline" v-for="(platform, i) in def.gamePlatform">
                    <label>
                      <input type="checkbox" v-model="data.platforms[i]" @change="updatePlatforms()"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >
                      <!-- <iCheckCheckBox
                        v-bind:propChecked="platforms[i]"
                        data-v-on:update:checked="platforms[i] = $event['checked']; updatePlatforms()"
                      /> -->
                      &nbsp;{{ platform }}
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputSort">
                  <span class="text-red">*</span> 名稱：
                </label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" v-model.trim="data.公告名稱" placeholder="請輸入公告名稱" required maxlength="50" />
                  <span :class="{'text-red': data.公告名稱.length > 50}">
                    名稱上限 {{ data.公告名稱.length }} / 50 字
                  </span>
                </div>
              </div>

              <!-- 圖片： -->
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputImg">
                  <span class="text-red">*</span> 圖片：
                </label>
                <div class="col-sm-9">
                  <input type="file" id="Img" accept="image/*" @change="onFileChange($event, type)" :required="!data.fileUrl" />
                  <span class="text-red" v-html="picLimit"></span><br />
                  <div class="pic has-imgClose mt-10" v-if="data.fileUrl">
                    <a target="_blank" :href="data.fileUrl">
                      <img :src="data.fileUrl" style="max-width:200px" alt="" draggable="false" />
                    </a>
                    <button type="button" @click="removeFile()" class="btn btn-imgClose fa fa-close">
                      <span class="sr-only">close</span>
                    </button>
                  </div>
                </div>
              </div>

              <fieldset v-show="data.分類 == 2">
                <div class="form-group">
                  <label class="col-sm-3 control-label" for="sendAwards">
                    <span class="text-red">*</span> 標籤：
                  </label>
                  <div class="col-sm-9">
                    <select class="form-control" style="max-width:320px" required v-model="data.標籤">
                      <option v-for="(t, k, i) in def.bannerTag" :value="k">{{ t }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-3 control-label" for="sendAwards">
                    <span class="text-red">*</span> 圖示：
                  </label>
                  <div class="col-sm-9">
                    <select class="form-control" style="max-width:320px" required v-model="data.圖示">
                      <option v-for="(t, k, i) in def.bannerBadge" :value="k">{{ t }}</option>
                    </select>
                    <span class="text-red" v-show="data.分類 != 2">
                      分類選擇為【活動公告】才會顯示「標籤」與「圖示」
                    </span>
                  </div>
                </div>
              </fieldset>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-9">
                  <input type="number" class="form-control" style="max-width:320px" min="1" max="999" required v-model="data.排序" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="actionRadio">
                  <span class="text-red">*</span> 動作：
                </label>
                <div class="col-sm-9">
                  <div class="form-group">
                    <div class="radio radio-inline">
                      <iCheckRedioButton checkValue="0" name="actionRadio" message="無功能" v-model.number="data.連結機制" />
                    </div>
                    <div class="radio radio-inline">
                      <iCheckRedioButton checkValue="1" name="actionRadio" message="輸入網址" v-model.number="data.連結機制" />
                    </div>
                    <div class="radio radio-inline">
                      <iCheckRedioButton checkValue="2" name="actionRadio" message="連結至遊戲功能" v-model.number="data.連結機制" />
                    </div>
                  </div>
                  <div class="form-group" v-if="data.連結機制 == 1">
                    <label class="col-sm-2 control-label" style="padding-inline:0">
                      <span class="text-red">*</span> 網址：
                    </label>
                    <div class="col-sm-10">
                      <input class="form-control" v-model.trim="data.網址Url" type="text" placeholder="請輸入網址連結" :required="data.連結機制 == 1" />
                    </div>
                  </div>
                  <div class="form-group" v-if="data.連結機制 == 2">
                    <label class="col-sm-2 control-label" style="padding-inline:0">
                      <span class="text-red">*</span> 遊戲功能：
                    </label>
                    <div class="col-sm-5">
                      <select class="form-control" v-model.string="data.連結機制編號" required>
                        <option v-for="(option, i) in def.gameAction" :value="i" v-text="option"></option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="form-group" v-if="/^(遊戲館|排行榜|客製優惠|連結獎勵|活動)$/.test(ActionOptions[data.連結機制])">
                    <label class="col-sm-3 control-label" for="actionNo" style="padding-right:0">
                      {{ ActionOptions[data.連結機制] }}編號：
                    </label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" style="width:40%" v-model.trim="data.機制編號"
                        :required="/^(遊戲館|排行榜|客製優惠|連結獎勵|活動)$/.test(ActionOptions[data.連結機制])" />
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 開始時間：
                </label>
                <div class="col-sm-9">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <div class="pull-right"><DatePicker :propValue.sync="startTime" /></div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="startTimeOnly" class="form-control timepicker" />
                    </div>
                    <div class="col-sm-3">
                      <div class="checkbox">
                        <label>
                          <iCheckCheckBox
                            v-bind:propChecked="data.是否常駐顯示"
                            v-on:update:checked="data.是否常駐顯示 = $event['checked']"
                          />&nbsp;常駐顯示
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-show="!data.是否常駐顯示">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 截止時間：
                </label>
                <div class="col-sm-9">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <div class="pull-right"><DatePicker :propValue.sync="endTime" /></div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="endTimeOnly" class="form-control timepicker" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 顯示條件(可複選)：
                </label>
                <div class="col-sm-9 control-label text-red text-left">
                  若皆無選擇則全玩家皆可看到
                  <!-- <button class="btn btn-default pull-right" type="button" @click="getTypes(true)"><i class="fa fa-refresh"></i> 重抓段位資訊</button> -->
                </div>
              </div>
              <div class="box-group">
                <div class="col-sm-3 control-label" style="padding-top:0;">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox v-model="data.是否顯示等級" v-bind:propChecked="data.是否顯示等級" v-on:update:checked="data.是否顯示等級 = $event['checked']" />
                    &nbsp; 段位等級
                  </label>
                </div>
                <fieldset class="vip col-md-9" :disabled="!data.是否顯示等級">
                  <select class="form-control" v-model="data.levels[0]" :required="data.是否顯示等級">
                    <!-- <option value="">請選擇</option> -->
                    <option v-for="(option, i) in gameTypes.Level" :key="i" :value="i" v-text="`${option} (${i})`"></option>
                  </select>
                  <p>~</p>
                  <select class="form-control" v-model="data.levels[1]" :required="data.是否顯示等級">
                    <!-- <option value="">請選擇</option> -->
                    <option v-for="(option, i) in gameTypes.Level" :key="i" :value="i" v-text="`${option} (${i})`"></option>
                  </select>
                </fieldset>
              </div>
              <div class="box-group">
                <div class="col-sm-3 control-label" style="padding-top:0;">
                  <label class="checkbox checkbox-inline">
                    <iCheckCheckBox v-model="data.是否顯示雀能" v-bind:propChecked="data.是否顯示雀能" v-on:update:checked="data.是否顯示雀能 = $event['checked']" />
                    &nbsp; 雀幣<span style="display:inline-block;width:2em;"></span>
                  </label>
                </div>
                <fieldset class="level col-md-9" :disabled="!data.是否顯示雀能">
                  <input type="number" class="form-control" v-model="data.coins[0]" min="1" :required="data.是否顯示雀能" />
                  <p>~</p>
                  <input type="number" class="form-control" v-model="data.coins[1]" min="1" :required="data.是否顯示雀能" />
                </fieldset>
              </div>

            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg" style="margin-right: 10px">
                  確認送出
                </button>
                <button type="button" @click="backList()" class="btn btn-default btn-lg">
                  取消
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton,
    ToggleButton,
  },
  props: {
    id: "",
    type: "",
  },
  data() {
    return {
      def: define,
      countDown: "選擇開啟後如倒數時間大於7天，則仍不會顯示，直至7天才會顯示",
      imgList: [],
      projectId: "omp_nmj",
      contentMax: 500,
      loading: false,
      gameTypes: {},
      data: {
        公告編號: "",
        分類: this.type, 
        排序: 1,
        是否開啟: 1, /*==*/ status: true,
        顯示平台: "0,1,2",  platforms: [true,true,true],
        公告名稱: "",
        fileUrl: "",
        網址Url: "",
        連結機制: 0,
        連結機制編號: "0",
        是否常駐顯示: 0,
        是否顯示等級: 0,
        是否顯示雀能: 0,
        標籤: 1,
        圖示: 1,
        等級: "", /*=====*/ levels: ["0", "0"],
        雀能: "", /*=====*/ coins: ["1", "100000"],
        建立時間: null,
        開始時間: null,
        結束時間: null,
      },
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
    };
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").unbind("focus");
    // this.checkSession();
    this.getData();
    this.getTypes();
    

    window.v = this;
  },
  computed:{
      picLimit() {
        switch(this.data.分類){
          case "1": return "圖片尺寸需符合1200*600以下。建議格式: jpg、png、gif";
          default: return "圖片尺寸需符合1200*675。建議格式: jpg、png、gif";
        }
      },
      typeString(){
        return this.def.bannerType[this.data.分類];
      }
  },
  methods: {
    getTypes(isReload){
      this.$store.dispatch("nmjMail/itemList", isReload).then(result => {
        this.gameTypes = result;
        this.gameTypes.Item[0] = "雀幣";
        if (!isReload) this.data.levels[1] = Math.max(...Object.keys(this.gameTypes.Level)).toString();
      })
    },
    getData() {
      this.contentMax = this.type === "1" ? 160 : 500;
      this.data.公告編號 = this.id;
      // this.data.公告類別 = this.type;

      if (this.id){
        this.loading = true;
        this.$store
          .dispatch("nmjAppBanner/query", this.data)
          .then((result) => {
            Object.assign(this.data, result)
            if (this.data.顯示平台 != null){
              let arrIn = this.data.顯示平台.split(',');
              let arrOut = [];
              for (let key of Object.keys(this.def.gamePlatform)){
                arrOut[key] = arrIn.includes(key);
              }
              this.data.platforms = arrOut;
              // console.log(arrIn, arrOut)
            }
            if (this.data.等級 != null) this.data.levels = this.data.等級.split('~');
            if (this.data.雀能 != null) this.data.coins = this.data.雀能.split('~');
            this.data.status = !!result.是否開啟;
            this.updateTime();
            this.loading = false;
          });
      }
      else this.updateTime();
    },
    updateTime() {
      this.startTime = this.data.開始時間 || new moment().add(1, "days").format("YYYY-MM-DDT00:00:01");
      this.endTime = this.data.結束時間 || new moment().add(8, "days").format("YYYY-MM-DDT23:59:59");
      $("#startTimeOnly").timepicker("setTime", this.global.getTime(this.startTime));
      $("#endTimeOnly").timepicker("setTime", this.global.getTime(this.endTime));
    },
    updatePlatforms(){
      let arr = [];
      for (let i = 0, len = this.data.platforms.length; i < len; i++){
        if (!!this.data.platforms[i] == true) arr.push(i);
      }
      this.data.顯示平台 = arr.join(",");
      // console.log(arr, this.data.顯示平台)
    },

    // checkSession() {
    //   if (this.timer) clearInterval(this.timer);
    //   this.timer = setInterval(() => {
    //     this.$store.dispatch("checkSession");
    //   }, 600000);
    // },

    backList() {
      this.$router.push({
        name: "NmjAppBannerList",
        params: {
          type: this.type,
          searchData: this.$route.params.searchData,
        },
      });
    },

    submit() {
      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      this.startTime = new Date(this.startTime.split("T")[0] + " " + startTimeOnly);
      this.endTime = new Date(this.endTime.split("T")[0] + " " + endTimeOnly);

      if (this.data.顯示平台 == "")
        return this.$eventBus.$emit("showAlert", "至少需要選1個顯示平台！");

      if (!this.data.公告名稱)
        return this.$eventBus.$emit("showAlert", "請填寫公告名稱！");

      if (startTimeOnly === "" || !this.global.isValidDay(this.startTime))
        return this.$eventBus.$emit("showAlert", "請填寫開始時間！");

      if (endTimeOnly === "" || !this.global.isValidDay(this.endTime))
        return this.$eventBus.$emit("showAlert", "請填寫結束時間！");

      // if (!this.global.isValidFutureDay(this.startTime))
      //   return this.$eventBus.$emit("showAlert", "「開始時間」請勿填寫過去時間！");

      if (!this.global.isValidFutureDay(this.endTime))
        return this.$eventBus.$emit("showAlert", "「結束時間」請勿填寫過去時間！");

      if (!this.global.isValidDayRange(this.startTime, this.endTime))
        return this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");

      if (parseInt(this.data.levels[0]) > parseInt(this.data.levels[1]))
        return this.$eventBus.$emit("showAlert", "請填寫正確段位等級區間，由小到大！");

      if (parseInt(this.data.coins[0]) > parseInt(this.data.coins[1]))
        return this.$eventBus.$emit("showAlert", "請填寫正確雀能區間，由小到大！");
      
      const api = this.data.公告編號 ? "update" : "create";
      this.data.開始時間 = new Date(`${this.startTime} UTC`);
      this.data.結束時間 = new Date(`${this.endTime} UTC`);
      this.data.顯示平台;
      this.data.等級 = this.data.levels.join("~");
      this.data.雀能 = this.data.coins.join("~");
      this.data.排序 = parseInt(this.data.排序);
      this.data.是否開啟 = +this.data.status;
      this.$store.dispatch(`nmjAppBanner/${api}`, this.data).then(() => {
        this.backList();
      });
    },

    onFileChange(evt, src) {
      var files = evt.target.files;

      // if (src === "活動公告") {
        this.folderName = "appBanner";
      // } else {
      //   this.folderName = "carousell";
      // }

      if (files.length > 0) {
        var file = files[0];

        if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
          return this.$eventBus.$emit("showAlert", "您選擇的檔案格式不符，請重新選擇。");
        }
        if (file.size > 3000000) {
          return this.$eventBus.$emit("showAlert", "您選擇的檔案超過上限 3 MB，請重新選擇。");
        }
      }

      this.uploadFile(files, this.projectId, this.folderName, this.typeString);
    },

    removeFile() {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.data.fileUrl = "";
          document.getElementById("Img").value = "";
        }
      });
    },

    //存入檔案 還需調整
    uploadFile(files, projectId, folderName, type) {
      // console.log(files, projectId, folderName, type);

      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }
      data.append("files", files);
      data.append("projectId", projectId);
      data.append("module", folderName);
      data.append("moduleId", type);

      this.$store.dispatch("nmjClassTypes/fileUpload", data).then((result) => {
        if (result.status != "1") {
          this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
        } else if (folderName == this.folderName) {
          this.data.fileUrl = result.result[0].filesUrl;
        }
      });
    },
  },
};
</script>
