@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲介紹列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">遊戲介紹列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit.prevent="clickSearch()">
            <router-link :to="{name:'XsgWebGameEdit', params:{ id:'New', searchData }}" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增
            </router-link>
            <div class="select-group mb-10">
              <select id="selectUnit" class="form-control" v-model="searchData.遊戲分類" @change="clickSearch()">
                <option value="">全部遊戲分類</option>
                <option v-for="(name, key) in dfn.gameCategory" :key="key" :value="key">
                  {{ key + ' ' + name }}
                </option>
              </select>
            </div>

            <div class="select-group mb-10">
              <select class="form-control" v-model="searchData.是否發佈" @change="clickSearch()">
                <option value=-1>上/下架</option>
                <option value=1>上架</option>
                <option value=0>下架</option>
              </select> 
            </div>

            
            
            <!-- 搜尋 -->
            <div class="input-group mb-10">
              <input type="text" class="form-control pull-right" v-model="searchData.中文名稱" placeholder="搜尋遊戲名稱" />
              <div class="input-group-btn">
                <button type="submit" class="btn btn-default">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text mb-10">
              <p class="data-num">資料總筆數: <span>{{ total }}</span></p>
            </div>
          </form>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 5%">分類</th>
                  <th style="min-width:120px; width:15%">遊戲Logo</th>
                  <th style="min-width:100px; width:10%">遊戲名稱</th>
                  <th style="min-width:100px; width:auto; word-wrap:break-word; word-break:break-word;" v-for="(tag, group) in tags" :key="group">{{ group }}</th>
                  <th style="min-width: 75px; width: 5%">狀態</th>
                  <th style="min-width:100px; width: 8%">排序</th>
                  <th style="min-width:100px; width: 8%">功能</th>
                </tr>
                <tr v-if="message != ''"><td class="text-center" colspan="10">{{ message }}</td></tr>
                <tr v-else-if="total <= 0"><td class="text-center" colspan="10">無資料</td></tr>
                <tr v-else v-for="(item, index) in datas" :key="item.id">
                  <td :title="item.遊戲分類">{{ item.遊戲分類 + ' ' + dfn.gameCategory[item.遊戲分類] }}</td>
                  <td><img :src="item.logo" draggable="false" alt="" style="max-height:80px;" /></td>
                  <td :title="item.英文名稱">{{ item.中文名稱 }}</td>
                  <td v-for="(tag, group) in tags">
                    <TagSelect :value="getTags(item, group)" readonly></TagSelect>
                  </td>
                  <td>{{ item.是否發佈 ? '上架' : '下架'  }}</td>
                  <td class="text-nowrap">
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" :disabled="!item.是否發佈" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add" @click="updateOrder(item)" :disabled="!item.是否發佈">
                        <i class="fa fa-arrows-v"></i> 更新
                      </button>
                    </div>
                  </td>
                  <td>
                    <div style="display:flex; flex-direction:column; gap:5px">
                      <router-link class="btn btn-primary" :to="{name:'XsgWebGameEdit', params:{id:item.gid, searchData}}">
                        <i class="fa fa-fw fa-pencil"></i> 修改
                      </router-link>
                      <button @click="deleteItem(item)" class="btn btn-danger">
                        <i class="fa fa-fw fa-close"></i> 刪除
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <pre style="line-height:1.1;">{{ datas }}</pre>
        <pre style="line-height:1.1;">{{ tags }}</pre>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSG/_Define";
import TagSelect from "@/views/projects/XSG/components/TagSelect";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    TagSelect
  },
  props: {
    id: "",
  },
  data() {
    return {
      dfn: dfn,
      projectId: this.$store.state.projectId,

      searchData: {
        遊戲分類: "",
        是否發佈: -1,
        中文名稱: "",
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
      total: 0, //總筆數
      message: "",

      tags: [],

      coverMode: false, // 蓋板廣告(賀緹)
      hasIsOn: '',
      filterStatus: "",
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getDataList();
    this.getTagList();
  },
  mounted() {
    // window.v = this;
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
    },

    getDataList(){
      this.$store.dispatch("xsw/遊戲館列表查詢", this.searchData).then(res => {
        this.datas = res.datas || [];
        this.total = res.total || 0;
        this.message = "";
      }, err => {
        this.datas = [];
        this.total = 0;
        this.message = err;
      });
    },
    getTagList(){
      this.$store.dispatch("xsw/類別與標籤查詢", {}).then(res => {
        let data = res.datas || [];
        // data = data.filter(i => i.狀態);
        let groups = {};
        for (let item of data){
          if (groups[item.標籤類別]) groups[item.標籤類別][item.id] = item.標籤;
          else groups[item.標籤類別] = {[item.id]: item.標籤};
        }
        this.tags = groups;
      });
    },
    getTags(item, type){
      let tags = item.tagType.filter(i => i.type == type).map(i => i.tags);
      if (!tags) return [];
      else return tags;

      let arr = [];
      for (let id of item.tags){
        if (this.tags[type] && this.tags[type][id]){
          arr.push(this.tags[type][id]);
        }
      }
      if (!tags) return [];
      return arr;
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲館刪除", item).then(()=>{
            this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
            this.getDataList();
          });
        }
      });
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>