<template>
    <div id="RobotList" class="lableFloat">
        <section class="content-header">
            <h1>FB機器人 - 社群管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/FBRobot/RobotList">FB機器人</router-link>
                </li>
                <li class="active">社群管理</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary max850">
                <div class="box-body">
                    <div class="top-box">
                        <div id="fb-root"></div>
                        <div class="fb-login-button" data-width="" data-size="medium" data-button-type="login_with"
                            data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="false"></div>

                        <div v-if="isConnected">名字: {{fbName}}</div>
                        <div v-if="!isConnected">
                            <facebook-login class="button" appId="372739654154496" 
                                @login="onLogin" @logout="onLogout"
                                @sdk-loaded="sdkLoaded">
                            </facebook-login>
                        </div>

                        <div style=" width: 100%; text-align: left; margin-top: 5px; margin-bottom: 5px;">
                            *要增加管理粉絲團，請先登入有FB粉絲團的FB帳號，以取得該FB粉絲團的權限 <br>
                            *登入後，即可看到連結的粉絲專頁資訊
                        </div>
                    </div>

                    <div class="table-responsive mt-10" v-if="isConnected">
                        <table class="table table-bordered min1000">
                            <tbody>
                                <tr class="bg-gray">
                                    <th style="min-width: 200px; width: 50%;">社群</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px">
                                        功能
                                    </th>
                                </tr>

                                <tr :key="item.id" v-for="(item, index) in fanList">
                                    <td class="text-center"><a :href="`https://www.facebook.com/`+item.粉專ID"
                                            target="_blank">
                                            {{ item.粉專名稱 }} <span class="sr-only">{{item.代號}}</span>
                                        </a> </td>
                                    <td class="text-center">

                                        <span v-if="item.綁定YN == 1">已綁定</span>
                                        
                                        <button v-else  @click="reAuthorize(item.代號)" 
                                            class="btn btn-primary mb-5">
                                            <i class="fa fa-chain"></i> 取得臉書授權綁定
                                        </button>
                                        &nbsp;
                                        <!--<button @click="Deauthorize(item)" class="btn btn-danger mb-5">
                                            <i class="fa fa-chain-broken"></i> 解除連結
                                        </button>-->
                                    </td>
                                </tr>

                                <tr v-if="fanList == 0">
                                    <td class="text-center" colspan="9">目前無連接任何粉絲專業</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </section>
    </div>
</template>


<script>
    /* eslint-disable */
    import api from "@/assets/js/app-api";
    import {
        mapState,
        mapActions
    } from "vuex";
    import utils from "@/assets/js/app-utils";
    import facebookLogin from 'facebook-login-vuejs'

    export default {
        components: {
            facebookLogin
        },
        data() {
            return {
                isConnected: false,
                fbName: '',
                FbUserID: '',
                FbToken: '',
                FB: undefined
            };
        },
        created() {
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/zh_TW/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        },
         computed: {
            ...mapState({
                fanList: state => state.fbRobot.fanList,
            }),
        },
        methods: {

            getUserData() {
                const vm = this;

                this.FB.api('/me', 'GET', {
                        fields: 'id,name,pages_show_list'
                    },
                    userInformation => {
                        //console.log('get fb data', userInformation);
                        // this.FbUserID = userInformation.id; // user's App-Scoped User ID
                        vm.fbName = userInformation.name;
                        
                    }
                )

                this.FB.getLoginStatus(function(response) {
                    //console.log(response);
                    vm.FbUserID = response.authResponse.userID;
                    vm.FbToken = response.authResponse.accessToken;
                    // console.log(vm.FbUserID, vm.FbToken);
                    statusChangeCallback(response);
                    vm.isConnected = true;

                    vm.getDataList();
                });
            },
            sdkLoaded(payload) {
                //console.log("sdkLoaded");
                this.isConnected = payload.isConnected
                this.FB = payload.FB
                if (this.isConnected) this.getUserData()
            },
            onLogin() {
                //console.log("onLogin");
                this.isConnected = true
                this.getUserData()
            },
            // onLogout() {
            //     //console.log("onLogout");
            //     this.isConnected = false;
            // },

            getDataList() {
                this.$store.dispatch("fbRobot/getFan", {
                    userID: this.FbUserID,
                    accessToken: this.FbToken,
                });
            },

            reAuthorize(item) {
                this.$store
                    .dispatch("fbRobot/getAuth", {
                        // opreateId: "omp_FBRobot",
                        "代號": (Number(item)),
                    })
                    .then((result) => {
                        this.getDataList();
                    });
            },

        },
    };

</script>
<style>
    .fb_iframe_widget {
        float: right;
    }

    .fb-login-button div .button {
        width: 100%; 
        text-align: center;
    }

</style>
