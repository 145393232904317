import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    detailQueryAll: [],
    detailQuery: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    salePointQueryAll({ commit }, data) {
        api.salePoint.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setSalePointQueryAll', res.data.result);
            }
        })
    },
    salePointQuery({ commit }, data) {
        api.salePoint.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setSalePointQuery', res.data.result);
            }
        })
    },
    salePointCreate({ commit }, data) {
        api.salePoint.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    salePointUpdate({ commit }, data) {
        api.salePoint.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    salePointUpdateSort({ commit }, data) {
        api.salePoint.updateSort(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    salePointDelete({ commit }, data) {
        api.salePoint.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    salePointDetailQueryAll({ commit }, data) {
        api.salePointDetail.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailSalePointQueryAll', res.data.result);
            }
        })
    },
    salePointDetailQuery({ commit }, data) {
        api.salePointDetail.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailSalePointQuery', res.data.result);
            }
        })
    },
}

// mutations
const mutations = {
    setSalePointQueryAll(state, salePointQueryAll) {
        state.queryAll = salePointQueryAll;
    },
    setSalePointQuery(state, salePointQuery) {
        state.query = salePointQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setDetailSalePointQueryAll(state, salePointDetailQueryAll) {
        state.detailQueryAll = salePointDetailQueryAll;
    },
    setDetailSalePointQuery(state, salePointDetailQuery) {
        state.detailQuery = salePointDetailQuery;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}