<template>
	<div id="gameList" class="slot">
		<section class="content-header">
			<h1>文章列表</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>分析管理</li>
				<li class="active">文章列表</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<button @click="add()" class="btn btn-block btn-warning btn-add" type="button">
							<i class="fa fa-plus"></i> 新增
						</button>
						<div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
								v-model="searchData.遊戲編號"
                @change="resetDataList()"
              >
                <option :value="-1">所有遊戲</option>
								<option
                  v-for="(game, index) in gameList"
                  :value="game.編號"
                >
                  {{ game.遊戲名稱 }}
                </option>
              </select>
            </div>
						<div class="input-group">
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="搜尋關鍵字"
								type="text"
								v-model="searchData.文章標題"
							/>
              <i class="fa fa-close" v-if="searchData.文章標題" @click="searchData.文章標題 = ''"></i>
							<div class="input-group-btn">
								<button v-on:click="resetDataList()" class="btn btn-default" type="button">
									<i class="fa fa-search"></i>
								</button>
							</div>
						</div>
						<div class="text">
							<p class="data-num">
								<span>資料總筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th class="text-nowrap text-center" style="min-width: 90px;">封面</th>
									<th style="min-width: 150px;" width="50%">文章標題</th>
									<th style="min-width: 150px;" width="50%">App</th>
									<th style="min-width: 97px;">
                    <span style="cursor: pointer;" @click="changeSort(2)">
											點閱數
											<i class="fa fa-sort"></i>
										</span>
                  </th>
									<th style="min-width: 150px;" width="50%">建立時間</th>
									<th style="min-width: 150px;" width="50%">
                    <span style="cursor: pointer;" @click="changeSort(1)">
											發佈時間
											<i class="fa fa-sort"></i>
										</span>
                  </th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
								</tr>
								<tr v-if="total == 0">
									<td colspan="8" ><Empty /></td>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td class="text-center">
										<img :src="item.封面" alt="">
									</td>
									<td class="text-center">{{ item.文章標題 }}</td>
									<td class="text-center">{{ game[item.遊戲編號] }}</td>
									<td class="text-center">{{ item.點閱數 }}</td>
									<td class="text-center">{{ item.建立時間 | dateFormat("YYYY/MM/DD HH:mm") }}</td>
									<td class="text-center">
										<p v-if="!item.發佈時間">未發布</p>
										<p>{{ item.發佈時間 | dateFormat("YYYY/MM/DD HH:mm") }}</p>
									</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border" v-if="total != 0">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="20">每頁筆數20</option>
            <option value="30">每頁筆數30</option>
            <option value="50">每頁筆數50</option>
          </select>
					<Pagination
						ref="pagination"
						:total="total"
						:pageLimitCount="searchData.pageSize"
						v-on:pageMethod="onPageChange"
						:type2="true"
						/>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
	import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";
	import Empty from "@/components/SlotEmpty";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
			Empty
		},
    delimiters: ["${", "}"],
    data() {
      return {
        gameList: [],
				searchData: {
					遊戲編號: -1,
          文章標題: "", //文章標題關鍵字
          // 是否啟用: 1,
          排序條件: 1, //1:ISNULL(A.發佈時間,''9999-12-31''),2.點閱數
          排序順序: 1, //1:DESC 2:ASC
          pageNum: 1,
          pageSize: 20,
				},
				list: [],
				total: 0,
      };
    },
    created() {
      this.getGameList();
			this.getDataList();
    },
    mounted() {},
    computed: {
			game() {
				var list = {}
				this.gameList.forEach(item => {
					list[item.編號] = item.遊戲名稱
				})
				return list
			}
		},
    watch: {},
    methods: {
      getGameList() {
        api.slotGame.game.queryAll({
          開發商編號: 0,
          排序條件: 3,//1:更新時間,2:建立時間,3.遊戲名稱
          排序順序: 2,//1:DESC,2:ASC
        })
          .then((res) => {
            this.gameList = res.data.result.datas;
          })
      },
			getDataList() {
				api.slotGame.document.queryAll(this.searchData)
					.then((res) => {
            console.log(res.data);
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
						this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
					})
			},
      resetDataList() {
				this.searchData.pageNum = 1;
        this.getDataList();
      },
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}文章？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}此資料`,
				isOK => {
					if (isOK) {
						api.slotGame.document.state({
							編號: item.編號,
							遊戲編號: item.遊戲編號,
							是否啟用: item.是否啟用 == 1 ? 0 : 1
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
					}
				});
			},
      add() {
        this.$router.push({
          name: 'slotArticleEdit'
        })
      },
			edit(item) {
        this.$router.push({
          name: 'slotArticleEdit',
					params: {
						id: item.編號
					}
        })
      },
			changeSort(condition) {
				this.searchData.排序條件 = condition;
				this.searchData.排序順序 = this.searchData.排序順序 == 1 ? 2 : 1;
				this.resetDataList();
			},
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>