<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>網紅編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>網紅列表</li>
        <li class="active">網紅編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">人物編輯</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>

            <!-- <button type="button" class="btn btn-cog btn-success">
              <i class="fa fa-upload"></i> 匯入資料
            </button> -->
          </div>

          <form class="form-horizontal">
            <div class="box-body" style="max-width: 80%">
              <!-- 圖片： -->
              <div class="form-group">
                <label class="col-sm-2 control-label" for="AdPic">
                  圖片：
                </label>
                <div class="col-sm-9">
                  <div class="pic">
                    <img
                      draggable="false"
                      style="max-width: 200px"
                      :src="edit.main_img"
                    />
                  </div>
                  <input
                    accept="image/*"
                    id="AdPic"
                    type="file"
                    @change="onFileChange($event)"
                  />
                  <div class="help-block">
                    <span class="text-red" id="note1"
                      >圖片建議尺寸為正方型。</span
                    >
                  </div>
                </div>
              </div>

              <!-- 姓名 -->
              <div class="form-group">
                <label class="col-sm-2 control-label" for="target_name">
                  <span class="text-red">*</span> 姓名：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="target_name"
                    required="required"
                    type="text"
                    v-model="edit.target_name"
                  />
                </div>
              </div>


              <!-- 性別 -->
              <div class="form-group">
                <label class="col-sm-2 control-label" for="target_name">
                  <span class="text-red">*</span> 性別：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.gender">
                    <option disabled="disabled" value>請選擇性別</option>
                    <option
                      :key="index"
                      :value="item.value"
                      v-for="(item, index) in sexList"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- 類型 -->
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>類型：
                </label>
                <div class="col-sm-9 checkbox-wrapper">
                  <iCheckMultiCheckBox
                    :propOptions="selectTypeList"
                    :propSelecteds.sync="edit.categorys"
                  />
                </div>
              </div>

              <!-- 描述 -->
              <div class="form-group bannercontent-part">
                <label for="description" class="col-sm-2 control-label"
                  >描述：
                </label>
                <div class="col-sm-10">
                  <textarea
                    id="description"
                    placeholder="建議100字內"
                    class="form-control"
                    v-model="edit.description"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-lg-1 col-md-2 col-sm-4">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        :checkValue="true"
                        message="上架"
                        name="radioMode"
                        v-model="edit.is_visibility"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-1 col-md-2 col-sm-4">
                  <div class="radio">
                    <iCheckRedioButton
                      :checkValue="false"
                      message="下架"
                      name="radioMode"
                      v-model="edit.is_visibility"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save()"
                >
                  確認送出
                </button>
              </div>
            </div>

            <!-- =======================================================
                網紅平台
            ======================================================= -->
            <hr class="divider mb-10" />

            <div class="top-box with-border" v-if="isEdit">
              <h4 class="pull-left" id="title">社群平台編輯</h4>
              <button
                @click="addSocial()"
                class="btn btn-block btn-warning btn-add fr"
                type="button"
              >
                <i class="fa fa-plus"></i> 新增
              </button>
            </div>

            <div
              class="table-responsive mt-10 m-auto2 mb-30"
              style="max-width: 80%"
              v-if="isEdit"
            >
              <table
                class="table table-bordered classTypeTable"
                id="classTypes"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th style="min-width: 140px" width="10%">平台</th>
                    <th style="min-width: 150px" width="15%">
                      粉絲團/頻道名稱
                    </th>
                    <th style="min-width: 150px" width="15%">連結</th>
                    <th style="min-width: 70px" width="6%">列表</th>
                    <th style="min-width: 70px" width="6%">粉專</th>
                    <th style="min-width: 95px" width="8%">爬蟲狀態</th>
                    <th align="center" style="min-width: 180px" width="12%">
                      功能
                    </th>
                  </tr>

                  <tr v-if="editChannels.length == 0">
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>

                  <tr
                    :key="index"
                    v-for="(channel, index) in editChannels"
                    v-show="channel.isDelete != true"
                  >
                    <td>
                      <select
                        class="form-control"
                        v-model="channel.platform"
                        :disabled="!channel.editor"
                      >
                        <option disabled value="">請選擇平台</option>
                        <option
                          v-for="(platform, key) in selectPlatformsList"
                          :value="platform.classId"
                          :key="key"
                        >
                          {{ platform.className }}
                        </option>
                      </select>
                    </td>

                    <td>
                      <p class="m-0" v-show="!channel.editor">
                        {{ channel.channel_name }}
                      </p>
                      <input
                        class="form-control"
                        type="text"
                        v-model.trim="channel.channel_name"
                        v-show="channel.editor"
                      />
                    </td>

                    <td align="center" data-label="連結">
                      <a
                        class="m-0"
                        target="_blank"
                        v-bind:href="channel.channel_url"
                        v-show="!channel.editor"
                      >
                        {{ channel.channel_url }}
                      </a>
                      <textarea
                        class="form-control"
                        type="text"
                        v-model.trim="channel.channel_url"
                        v-show="channel.editor"
                      />
                    </td>
                    
                    <td align="center" data-label="列表呈現">
                      <div class="ckbx ckbx-style-8 toggleInput">
                        <input
                          v-if="channel.channel_id"
                          :id="'check' + index"
                          type="checkbox"
                          v-model="channel.is_visibility"
                          @change="updateChannel(channel)"
                        />
                        <label :for="'check' + index"></label>
                      </div>
                    </td>

                    <td align="center" data-label="是否為粉專">
                      <div class="ckbx ckbx-style-8 toggleInput">
                        <input
                          :id="'check' + index"
                          type="checkbox"
                          v-model="channel.fans_page"
                          @change="updateChannel(channel)"
                        />
                        <label :for="'check' + index"></label>
                      </div>
                    </td>

                    <td align="center" data-label="爬蟲狀態">
                      <select class="form-control" 
                        v-model="channel.must_crawler"
                        :disabled="!channel.editor">
                        <option
                          v-for="(item, key) in crawlerList"
                          :value="item.value"
                          :key="key"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                    </td>

                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-maroon"
                        @click="LinkKPI(channel)"
                        v-show="!channel.editor"
                      >
                        KPI
                      </button>

                      &nbsp;
                      <button
                        type="button"
                        @click="changeSocial(channel)"
                        class="btn btn-primary"
                        v-show="!channel.editor"
                      >
                        修改
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        @click="deleteSocial(channel, index)"
                        class="btn btn-danger"
                        v-show="!channel.editor"
                      >
                        刪除
                      </button>

                      <button
                        type="button"
                        @click="saveSocial(channel)"
                        class="btn btn-success btn-save"
                        v-show="channel.editor"
                      >
                        儲存
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        @click="cancel(channel, index)"
                        class="btn btn-default"
                        v-show="channel.editor"
                      >
                        取消
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="note mt-10">
                *<span class="color-red">各</span
                >社群平台第一筆資料預設為主要社群 <br />
                *平台選擇youtube時連結欄位提示「使用"<b>影片</b>"頁(連結中帶有<a
                  href="https://reurl.cc/1YLlQD"
                  target="_blank"
                  class="text-line"
                  ><b> videos</b>. ex:https://www.youtube.com/c/xxx/<b>videos</b></a
                >)」<br />
                *一開始新增社群資料不需勾選"列表"，點擊"儲存"後，系統會自動勾選。
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>

    <!-- <BigDataSimiler ref="BigDataSimiler" /> -->
  </div>
</template>

<script>
/* eslint-disable */

import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { mapState, mapActions } from "vuex";
// import BigDataSimiler from "@/views/projects/spider/BigDataSimiler";
import iCheckMultiCheckBox from "@/components/iCheckMultiCheckBox";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    // BigDataSimiler,
    iCheckMultiCheckBox,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      fadeApi: {
        category: [],
      },

      edit: {
        KolID: "", //人物id
        main_img: "", // 圖片
        target_name: "", //姓名
        categorys: [],
        description: "", //描述
        gender: "",
        is_visibility: true,
        fans_page: false,
        must_crawler: null,
        channel_data: [],
      },

      editChannels: [],

      sexList: [
        { value: "male", text: "男" },
        { value: "fmale", text: "女" },
        { value: "transgender", text: "其他" },
      ],

      crawlerList: [
        { value: null , text: "一般" },
        { value: "true", text: "必爬" },
        { value: "none", text: "不爬" },
      ],
 
      selectTypeList: [],
      selectAttributeList: [],
      selectPlatformsList: ["Facebook", "Youtube"],
      platformSelected: "",
    };
  },

  created() {
    if (this.$route.params.id != null) {
      this.isEdit = true;
      this.GetQueryData();
    }
  },

  mounted() {
    this.GetClassTypes(); //for tyep
    window.scrollTo(0,0);
  },

  computed: {
    ...mapState({
      kolList: (state) => state.kolBigData.kolList,
      kolTypes: (state) => state.classTypes.queryAll,
      detailQuery: (state) => state.kolBigData.kolQuery,
    }),
  },
  watch: {
    detailQuery() {
      this.edit = utils.deepCloneObj(this.detailQuery.main_data);
      if (this.edit.categorys == null) this.edit.categorys = [];
      if (this.edit.gender == null) this.edit.gender = "";
      
      this.detailQuery.channel_data.forEach((res) => {
        res.KolID = this.edit.target_id;
        res.editor = false;
      });
      this.editChannels = utils.deepCloneObj(this.detailQuery.channel_data);
    },
    kolTypes() {
      this.selectTypeList = [];
      this.kolTypes.datas.forEach((type) => {
        if (type.module == "KOLCategory")
          this.selectTypeList.push(type.className);
      });
      this.selectPlatformsList = this.kolTypes.datas.filter(
        (type) => type.module == "KOLPlatform"
      );
      this.selectAttributeList = this.kolTypes.datas.filter(
        (type) => type.module == "KOLSubCategory"
      );
    },
    response(value) {},
  },
  methods: {
    //取得該資料
    GetQueryData() {
      this.$store.dispatch("kolBigData/query", {
        KolID: this.$route.params.id,
      });
    },

    //取得分類資料
    GetClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    updateChannel(item) {
      this.$store.dispatch("kolBigData/updateChannel", item).then((res) => {
        if (res.status == "1") {
          setTimeout(() => {
            this.GetQueryData();
          }, 1000);
          item.editor = false;
        }
      });
    },

    createChannel(item) {
      this.$store.dispatch("kolBigData/createChannel", item).then((res) => {
        if (res.status == "1") {
          setTimeout(() => {
            this.GetQueryData();
          }, 1000);
          item.editor = false;
        }
      });
    },

     //將頻道加入爬蟲排程
    crawlerKOLChannel(item) {
      this.$store
        .dispatch("kolBigData/crawlerKOLChannel", {
          params: { channel_id: item.channel_id },
        })
        .then((res) => {
          if (res.status == "1") {
            console.log("已加入排程執行");
            return;
          } else {
            console.log("排程出現異常");
            return;
          }
        });
    },

    addSocial() {
      this.editChannels.push({
        KolID: this.edit.target_id,
        platform: "",
        channel_name: "",
        channel_url: "",
        is_visibility: false,
        must_crawler: null,
        editor: true,
      });
    },

    changeSocial(item) {
      item.editor = true;
    },

    saveSocial(item) {
      if (item.platform == "") {
        this.$eventBus.$emit("showAlert", "請選擇平台!!");
        return;
      }

      if (item.channel_name == "") {
        this.$eventBus.$emit("showAlert", "請填寫名稱!!");
        return;
      }

      if (item.channel_url == "") {
        this.$eventBus.$emit("showAlert", "請填寫連結!!");
        return;
      }

      let data = this.editChannels.filter(
        (channel) =>
          channel.channel_url == item.channel_url && channel.channel_id != null
      );

      if (data.length > 0) {
        if (data[0].isDelete) {
          let tempData = utils.deepCloneObj(data[0]);
          tempData.isDelete = false;
          tempData.platform = item.platform;
          tempData.channel_name = item.channel_name;
          this.updateChannel(tempData);
          return;
        } else {
          if (!data[0].editor) {
            this.$eventBus.$emit("showAlert", "該頻道已經存在!");
            return;
          }
        }
      }

      if (item.platform == "yt") {
        let UrlArr = item.channel_url.split("/");
        if (
          item.channel_url.indexOf("https://www.youtube.com/") == -1 ||
          UrlArr[UrlArr.length - 1] != "videos"
        ) {
          this.$eventBus.$emit("showAlert", "YouTube連結錯誤!!");
          return;
        }
      }

      if (item.channel_id != null) {
        this.updateChannel(item);
      } else {
        this.createChannel(item);

        //新增後再去爬
      }
    },

    cancel(item, index) {
      item.editor = false;
      if (item.channel_id == null) {
        this.editChannels.splice(index);
      }
    },

   

    // 刪除
    deleteSocial(item) {
      let deleteItem = {
        channel_id: item.channel_id,
        isDelete: true,
      };

      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.updateChannel(deleteItem);
        }
      });
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }
      this.preview(files);
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          that.edit.main_img = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: this.$route.params.back || "SpiderBigDataList",
        params: {
          id: this.$route.params.id,
          searchData: this.$route.params.searchData,
        },
      });
    },

    //送出
    Save() {
      if (this.edit.target_name == "") {
        this.$eventBus.$emit("showAlert", "請填寫姓名");
        return;
      }

      if (this.edit.gender == "") {
        this.$eventBus.$emit("showAlert", "請選擇性別");
        return;
      }

      if (!this.edit.categorys || this.edit.categorys.length == 0) {
        this.$eventBus.$emit("showAlert", "請選擇類型");
        return;
      }

      let AdPic = document.getElementById("AdPic");
      if (AdPic.files.length >= 1) {
        let data = new FormData();
        data.append("image", AdPic.files[0]);
        this.$store.dispatch("kolBigData/uploadImage", data).then((res) => {
          if (res.status == "1") {
            this.edit.main_img = res.result.url;
            //編輯
            if (this.isEdit) {
              this.$store.dispatch("kolBigData/edit", this.edit);
            } else {
              //新增
              this.$store
                .dispatch("kolBigData/create", this.edit)
                .then((res) => {
                  if (res.status == "1") {
                    this.backList();
                  }
                });
            }
          } else {
            this.$eventBus.$emit("showAlert", "圖片上傳失敗，請重新操作!");
          }
        });
      } else {
        //編輯
        if (this.isEdit) {
          this.$store.dispatch("kolBigData/edit", this.edit);
        } else {
          //新增
          this.$store.dispatch("kolBigData/create", this.edit).then((res) => {
            if (res.status == "1") {
              this.backList();
            }
          });
        }
      }
    },

    LinkKPI(item) {
      this.$router.push({
        name: "SpiderBigDataKOL",
        params: { searchData: this.searchData, id: item.KolID },
      });
    },
  },
};
</script>
