<template>
  <div id="MftdContactUsEdit" class="normal">
    <section class="content-header">
      <h1>聯繫客服資訊</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>聯繫客服列表</li>
        <router-link to="/MFTD/ContactUsList">聯繫客服列表</router-link>
        <li class="active">聯繫客服資訊</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 角色名稱：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>
                    {{ data.name }}
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 玩家ID：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>
                    {{ data.companyName }}
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 聯絡Email：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>
                    {{ data.email }}
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 聯絡電話：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>
                    {{ data.phoneNumber }}
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 主旨：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>
                    {{ data.subject }}
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="contactNote">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="contactNote">
                    <textarea
                      style="width: 100%; height: 200px"
                      disabled
                      v-model="data.note"
                    ></textarea>
                  </div>
                  <div v-for="(file, index) in data.files" :key="index">
                    <a class="link" target="_blank" :href="file.filesUrl">
                      {{ file.name }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-2">
                  <select class="form-control" v-model.number="data.status">
                    <option value="0">待處理</option>
                    <option value="1">已處理</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="saveData()"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      data: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store
        .dispatch("mftdBefore/contactQuery", {
          contactUsId: this.$route.params.id,
        })
        .then((result) => {
          this.data = result;
          this.loading = false;
        });
    },
    saveData() {
      this.$store
        .dispatch("mftdBefore/contactUpdate", this.data)
        .then((result) => {
          this.backList();
        });
    },
    backList() {
      this.$router.push({
        name: "MftdContactUsList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
