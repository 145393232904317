<template>
  <!--序號產生-->
  <div class="modal fade" ref="importData">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">匯入序號</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <div class="form-group name-group">
                      <div class="right">
                        <input id="batchCancelOrder" ref="batchCancelOrder" type="file" />
                      </div>
                    </div>
                    <button
                      @click="readExcel()"
                      class="btn btn-block btn-danger btn-cog mt-10"
                      type="button"
                    >確定匯入</button>
                    <a
                      href="/uploads/序號範例.xlsx"
                      class="btn btn-block btn-success btn-cog"
                      type="button"
                      download
                    >序號範例</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import xlsx from "xlsx";

export default {
  computed: {
    ...mapState({
      award: state => state.award.query,
    })
  },
  methods: {
    showSerialNumberEdit(award) {
      $("#batchCancelOrder").val("");

      if (award == "" || award == undefined || award.awardsId == "") {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      // await this.getEmpHabitsInfo();
      this.GetAwardQuery(award.awardsId);

      $(this.$refs.importData).modal("show");
    },
    GetAwardQuery(awardsId) {
      //活動列表
      this.$store.dispatch("award/awardQuery", {
        awardsId: awardsId
      });
    },
    readExcel() {
      //excel匯入
      var that = this;
      const files = that.$refs.batchCancelOrder.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx|csv)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
            cellDates: true
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
          let outputs = []; //清空
          let error = false;
          let errMessage = "";
          for (var i = 0; i < ws.length; i++) {
            if(ws[i].原廠序號 == null || ws[i].原廠序號 == "") {
              error = true;
              errMessage = "第"+(i+1)+"筆資料內容為空值!!";
              break;
            }
            outputs.push({
              SN: ws[i].序號,
              vendorSN: ws[i].原廠序號,
            });
          }

          if (error) {
            this.$eventBus.$emit("showAlert", errMessage);
            return;
          }

          this.$store.dispatch("exchangeCode/exchangeCodeImport", {
            datas: outputs,
            awardsId: this.award.awardsId
          });

          // api.actionwork
          //   .import({
          //     datas: outputs
          //   })
          //   .then(res => {
          //     if (res.data.status == "1") {
          //       that.$eventBus.$emit("showAlert", "序號匯入成功!!");
          //     } else {
          //       that.$eventBus.$emit("showAlert", res.data.message);
          //     }
          //     that.workShowAll();
          //   });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
  }
};
</script>