<template>
  <div class="editor">
    <!-- 工具欄容器 -->
    <div class="toolbar-container"></div>
    <!-- 編輯器容器 -->
    <div class="CKEditor"></div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import api from "@/assets/js/app-api";

export default {
  props: ["content", "lang"],
  data() {
    return {
      editor: null // 編輯器例項
    };
  },
  mounted() {
    this.initCKEditor();
    $(".editor").each(function() {
      $(this)
        .find("table")
        .addClass("table table-bordered");
    });
  },
  watch: {
    content: function(value) {
      this.editor.setData(value);
    }
  },
  methods: {
    initCKEditor() {
      CKEditor.create(document.querySelector(".CKEditor"), {
        language: "zh",
        toolbar: [
          "heading",
          "|",
          "fontSize",
          "fontColor",
          "fontFamily",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "alignment",
          "|",
          "highlight",
          "code",
          "blockQuote",
          "|",
          "link",
          "imageUpload",
          "insertTable",
          "|",
          "undo",
          "redo"
        ],
        ckfinder: {
          // uploadUrl: apiPath + "/UploadFile",
          // // 後端處理上傳邏輯返回json資料,包括uploaded(選項true/false)和url兩個欄位

          // // Define the CKFinder configuration (if necessary).
          options: {
            resourceType: "Images"
          }
        }
      })
        .then(editor => {
          const toolbarContainer = document.querySelector(".toolbar-container");
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          this.editor = editor;
          // editor.model.document.on( 'change', () => {
          //     this.$emit("onContentChagne", this.editor.getData());
          // } );
          // 將編輯器儲存起來，用來隨時獲取編輯器中的內容等，執行一些操作

          editor.plugins.get("FileRepository").createUploadAdapter = loader => {
            return new UploadAdapter(loader);
          };
        })
        .catch(error => {
          var console = console;
          // console.error(error);
        });

      let that = this;
      class UploadAdapter {
        constructor(loader) {
          self.loader = loader;
          self.oldValue = that.editor.getData(); //保存原来的内容
        }
        upload() {
          //重置上传路径
          return self.loader.file.then(uploadedFile => {
            return new Promise((resolve, reject) => {
              const data = new FormData();
              data.append("files", uploadedFile);
              data.append("projectId", that.$store.state.projectId);
              data.append("module", "news");
              data.append("lang", that.lang);
              data.append("multi", true);
              api.file
                .upload(data)
                .then(result => {
                  resolve({
                    default: result.data.url
                  });
                })
                .catch(err => {
                  console.log(err);
                });
            });
          });
        }
        abort() {}
      }
    },
    SetData() {
      return this.editor.getData();
    }
  }
};
</script>