<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{ isSpecialPage ? '優惠專案' : '公告列表' }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>{{ isSpecialPage ? '優惠專案' : '公告管理' }}</li>
        <li class="active" v-if="isSpecialPage">公告列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> {{ isSpecialPage ? '新增優惠' : '新增訊息' }}
            </button>
            <div class="date-group" 
              v-if="projectId == 'omp_xinstars' || projectId == 'omp_kf3'">
                <label class="control-label">發布日期：</label>
                <div class="input-group date">
                    <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                    </div>
                    <div class="pull-right">
                      <DateRangePicker :propEndDate.sync="searchData.EndTime"
                          :propStartDate.sync="searchData.StartTime" propsIn="format2" />
                    </div>
                </div>
            </div>

            <div class="select-group" v-if="projectId !== 'omp_heti'">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.classTypeId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in newsTypes.datas"
                  :key="index"
                >{{item.className}}</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ news.total }}</span>
              </p>
            </div>

            <div class="dropdown sort-dropdown" v-if="projectId == 'omp_kf3'">
              <button
                class="btn btn-sort dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
              >
                <div class="icon-sort"></div>
                排序依據
              </button>
              <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
                <li>
                  <a
                  :class="['dropdown-item', searchData.排序條件 == '發布日期' ? 'active' : '']"
                  href="javaScript:;"
                  @click="resetData('發布日期')"
                  >
                    發布日期
                  </a>
                </li>
                <li>
                  <a
                  :class="['dropdown-item', searchData.排序條件 == '建立日期' ? 'active' : '']"
                  href="javaScript:;"
                  @click="resetData('建立日期')"
                  >
                    建立日期
                  </a>
                </li>
              </ul>
            </div>


          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray" v-if="projectId === 'omp_heti'">
                  <th class="text-nowrap" style="width: 10%;">ID</th>
                  <th style="min-width: 90px; width:3%">置頂</th>
                  <th class="text-nowrap" style="width: 5%;" v-if="$route.name=='HetiSpecialOffer'">優惠新訊</th>
                  <th class="text-nowrap" style="width: 20%;">標題</th>
                  <th class="text-nowrap" style="width: 15%;">發佈日期</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <tr class="bg-gray" v-else>
                  <th class="text-nowrap" style="width: 10%;"
                    v-if="projectId !== 'omp_xinstars'"
                    >id</th>
                  <th style="min-width: 90px; width: 5%">分類</th>
                  <th style="min-width: 90px; width: 5%"  v-if="projectId !== 'omp_dem'">主圖</th>
                  <th style="min-width: 90px; width:3%"
                    v-if="projectId !== 'omp_xinstars'">置頂</th>
                  <th class="text-nowrap" style="width: 10%;">主旨</th>
                  <th class="text-nowrap" style="width: 10%;">發佈日期</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap" style="width: 5%;"
                    v-if="projectId == 'omp_xinstars'">瀏覽人數</th>
                  <th class="text-nowrap" style="width: 3%;"
                    v-if="projectId !== 'omp_xinstars'">建立者工號</th>
                  <th class="text-nowrap" style="width: 3%;">建立者</th>
                  <th class="text-nowrap" style="width: 10%;">建立日期</th>
                  <th class="text-nowrap" style="width: 3%;"
                    v-if="projectId == 'omp_xinstars'">更新者</th>
                  <th class="text-nowrap" style="width: 10%;"
                    v-if="projectId == 'omp_xinstars'">更新日期</th>

                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="news.total > 0">
                  <tr :key="item.id" v-for="(item, index) in news.datas">
                    <td align="center"  v-if="projectId !== 'omp_xinstars'">
                      <span
                        v-if="
                          projectId == 'omp_heti' && item.isStop == 0 
                        "
                        style="cursor: pointer;"
                        class="text-primary"
                        @click="previewItem(item)"
                      >
                        {{ item.newsId }}
                      </span>
                      <span v-else>{{ item.newsId }}</span>
                    </td>
                    <td v-if="projectId !== 'omp_heti'">{{ item.classTypeName }}</td>
                    <td v-if="projectId !== 'omp_heti' &&  projectId !== 'omp_dem' ">
                      <div class="pic jqimgFill">
                        <img draggable="false" :src="item.mainImgFullFileName" />
                      </div>
                    </td>
                    <td align="center" v-if="projectId !== 'omp_xinstars'">
                      {{ item.isTop === 1 ? '是':'否' }}
                      <button
                        class="btn btn-danger"
                        @click="cancelTop(item)"
                        v-if="projectId === 'omp_heti' && item.isTop === 1"
                      >
                        取消
                      </button>
                    </td>
                    
                    <td
                      class="text-center"
                      align="center"
                      v-if="projectId === 'omp_heti' && $route.name=='HetiSpecialOffer'"
                    >
                      <div class="w"></div>
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          :false-value="0"
                          :id="'check'+ index"
                          :true-value="1"
                          name="ckbx-style-8"
                          type="checkbox"
                          v-model="item.isHint"
                          :disabled="hasIsHint != item.newsId && hasIsHint != ''"
                          @change="changeHint(item)"
                          />
                        <label :for="'check'+index" @click="hasHint(item)"></label>
                      </div>
                    </td>
                    <td>{{ item.detailTopTitle }}</td>
                    <td align="center">{{ FormatTime(item.publishTime) }}</td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td align="center"
                      v-if="projectId == 'omp_xinstars'">{{ item.點擊次數 }}</td>
                    <td align="center"
                      v-if="projectId !== 'omp_xinstars' && projectId !== 'omp_heti'">
                        {{ item.createUId }}</td>
                    <td align="center" v-if="projectId !== 'omp_heti'">{{ item.createUName }}</td>
                    <td align="center" v-if="projectId !== 'omp_heti'">{{ item.createTime | dateFormat("YYYY/MM/DD hh:mm:ss") }}</td>

                    <td align="center"
                      v-if="projectId == 'omp_xinstars'">{{ item.updateUName }}</td>
                    <td align="center"
                      v-if="projectId == 'omp_xinstars'">{{ item.updateTime }}</td>
                    
                    <td class="text-center text-nowrap">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <button
                        @click="previewItem(item)"
                        class="btn btn-warning"
                        v-if="item.isStop == '1' && (projectId == 'omp_mftd' || projectId == 'omp_dk' || projectId == 'omp_heti' || projectId == 'omp_dem')"
                      >預覽</button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                        v-if="projectId !== 'omp_heti'"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="news.total > 0">
          <Pagination
            ref="pagination"
            :total="news.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
  #AnnouncementList{
    .pic{
      img{
        max-height: 80px;
        margin: 0 auto;
      }
    }
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import appUtils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    DateRangePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      dropdown: 'none',
      projectId: this.$store.state.projectId || appUtils.getCookie("projectId"),
      isSpecialPage: this.$route.name == 'HetiSpecialOffer' ? true : false,
      routeName: this.$route.name,
      searchData: {
        // projectId: this.$store.state.projectId,
        classTypeId: "",
        module: "news",
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, //每頁顯示筆數
        排序條件: "建立日期",
      },
      url: config.dkOption.url,
      hasIsHint: '',
      catchOb: {
        newsID: "",
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    // 開箱文日期預設值
    if (this.projectId === 'omp_xinstars') {
      this.searchData.StartTime = new moment().subtract(24, "month").format("YYYY-MM-DD")
      this.searchData.EndTime = new moment().add(2, "month").format("YYYY-MM-DD")

      // this.xinCatch()
    }

    if (this.projectId === 'omp_kf3' &&　this.$route.params.searchData == undefined) {
      this.searchData.StartTime = new moment().subtract(3, "month").format("YYYY-MM-DD")
      this.searchData.EndTime = new moment().add(1, "days").format("YYYY-MM-DD")
    }

    if(this.projectId == 'omp_heti' && this.isSpecialPage) {
      this.searchData.module = 'special_offer'
      this.getHint();
    }
    await this.getNewsClassTypes();
    this.getNewsQueryAll();

      
  },
  mounted() {},
  computed: {
    ...mapState({
      newsTypes: (state) => state.classTypes.queryAll,
      news: (state) => state.news.queryAll,
      response: (state) => state.news.response,
    }),
  },
  watch: {
    newsTypes() {},
    $route(val) {
      if(
        this.projectId == 'omp_heti' &&
        val.name == 'HetiSpecialOffer'
      ) {
        this.searchData.module = 'special_offer'
      } else {
        this.searchData.module = 'news'
      }
      this.isSpecialPage = this.$route.name == 'HetiSpecialOffer' ? true : false,
      this.getNewsClassTypes();
      this.getNewsQueryAll();
    },
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getNewsQueryAll();
    },
    news() {
      this.$refs.pagination.setPage(this.searchData.pageNum, this.news.total);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getNewsQueryAll();
    },

    getNewsQueryAll() {
      //列表
      if (this.projectId == "omp_xinstars") {
        this.searchData.module = "unboxing"
      }

      // console.log(this.searchData);
      //列表
      this.$store.dispatch("news/newsQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async getNewsClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: this.projectId == "omp_xinstars" ? "unboxing" : "news" ,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    resetData(sort) {
      this.searchData.排序條件 = sort;
      this.dropdown = "none";
      this.getNewsQueryAll();
    },

    getHint() {
      let data = {
        projectId: "omp_heti",
        module: "special_offer",
        classId: "discount_info",
        lang: "zh-tw"
      }
      api.heti.GetHint(data).then(({data}) => {
        if(data.status == '1') {
          this.hasIsHint = data.result.newsId
        }
      })
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getNewsQueryAll();
    },

    //*---- 新增內容
    addNew() {
      if (this.projectId == "omp_xinbo") {
        this.$store.dispatch("news/newsQueryAll", {
          classTypeId: "",
          module: "news",
          isDelete: 0,
          isStop: 0,
        });
      }
      this.$router.push({
        name: this.isSpecialPage ? 'HetiSpecialOfferEdit' : 'AnnouncementEdit',
        params: {
          searchData: this.searchData,
        },
      });
      return;
    },

    //*---- 優惠新訊按鈕
    changeHint(data) {
      if(data.isHint == 1) this.hasIsHint = data.newsId
      else this.hasIsHint = '';
      data.isStop = 0;
      this.updateItem(data);
    },
    hasHint(val) {
      if(this.hasIsHint != '' && this.hasIsHint !== val) {
        this.$eventBus.$emit("showAlert", "請先取消其他優惠新訊再進行新增");
      }
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: this.isSpecialPage ? 'HetiSpecialOfferEdit' : 'AnnouncementEdit',
        params: {
          id: item.newsId,
          searchData: this.searchData,
        },
      });
      return;
    },

    updateItem(item) {
      item.newsDetailId = item.newsDetailTopId;
      var detailNewsLang = []
      detailNewsLang.push({
        info: item.detailTopInfo,
        lang: "zh-tw",
        newsDetailId: item.newsDetailTopId,
        title: item.detailTopTitle
      })
      this.$store.dispatch("news/newsUpdate", {
          master_model: item,
          detail_model: detailNewsLang
          ,
        });
    },

    cancelTop(item) {
      item.isTop = 0;
      this.updateItem(item);
    },

    //*----預覽

    previewItem(item) {
      switch (this.projectId) {
        case "omp_mftd":
          window.open(`${process.env.VUE_APP_MFTDWEB_PATH}/web/new/${item.newsId}?preview`);
          break;
        case "omp_dk":
          window.open(
            this.url + "News/Article/" + item.newsId + "?preview=1"
          );
          break;
        case "omp_xinstars":
          window.open(`${process.env.VUE_APP_XIN_PATH}/GAME_Unboxing.aspx?news=${item.newsId}&preview=1#${item.classId}`);
          break;
        case "omp_dem":
          window.open(`${process.env.VUE_APP_Dem_PATH}/news/${item.newsId}?preview=1`);
          break;
        case "omp_heti":
          var id = item.newsId
          if(item.isStop == 1) {
            id = `preview_${item.newsId}`
          }
          if(this.$route.name == 'HetiSpecialOffer') {
            window.open(`${process.env.VUE_APP_HETIWEB_PATH}/promo/${id}`);
            return
          } 
          window.open(`${process.env.VUE_APP_HETIWEB_PATH}/news/${id}`);
          break;
        }
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("news/newsDelete", item)

            .then((result) => {
              if (this.projectId === 'omp_xinstars'){
                this.xinCatch(item);
              }
            });

        
          
        }
      });


    },
    checkPermission(permission) {
      // let userPermissionList = this.$store.state.loginUserPermission;
      // let num = -1;
      // let isCheck = false;
      // permission.split(",").some(element => {
      //   num = userPermissionList.indexOf(element);
      //   switch (num) {
      //     case -1:
      //       isCheck = false;
      //       break;
      //     default:
      //       isCheck = true;
      //       break;
      //   }
      //   if (isCheck) return true;
      // });
      return utils.checkByUserPermission(permission);
    },
    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },

    xinCatch(item){
      console.log(item.newsId);
      this.catchOb.newsID = item.newsId;
      console.log(this.catchOb);

      this.$store
            .dispatch("xinGameIntro/unboxingCatch" , this.catchOb)
            .then(() => {
                console.log("已清除Catch");
            });
    }
  },
};
</script>
<style scoped>
.ckbx-style-8 label:before {
  background: #999;
}
</style>