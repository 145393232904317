export default {
    Permission: {
        CompanyDocumentEdit: "F0-03",
        CompanyDocumentSelectTarget: "F0-04",
        AnnouncementEdit: "AN-03",
        AnnouncementEditAll: "AN-04",
        SeatingSetting: "D0-310", // 座位表維護
        ExtensionSetting: "D0-410", // 分機表維護
        SiteSetting: "B0-900", //場地維護
        ProductsManager: "J0-900", //公司產品管理
        WhiteListAdd: "L0-002",
        PaymentOwedEdit: "PaymentOwedEdit", //購點欠費紀錄編輯
        TransactionInfoAll: "TransactionInfoAll",
        MessageSystem: "MessageSystem",       //系統訊息
        ChannelManager: "ChannelManager",       //頻道管理
        MessageSystemFinishService: "MessageSystemFinishService",//客服系統-強制結束事件
        MessageSystemStartService: "MessageSystemStartService",//客服系統-強制接手事件
        "MCM-omp_starcastle": "MCM-omp_starcastle",
        "MCM-omp_xinstars": "MCM-omp_xinstars",
        API_GetPaymentLimiteList: "API_GetPaymentLimiteList",
        API_SetPaymentLimiteList: "API_SetPaymentLimiteList",
        API_DelPaymentLimiteList: "API_DelPaymentLimiteList",
        API_GetPaymentLimiteBlack: "API_GetPaymentLimiteBlack",
        API_DelPaymentLimiteBlack: "API_DelPaymentLimiteBlack",
        API_GetPaymentLimiteWhite: "API_GetPaymentLimiteWhite",
        API_SetPaymentLimiteWhite: "API_SetPaymentLimiteWhite",
        API_DelPaymentLimiteWhite: "API_DelPaymentLimiteWhite",
        MessageSystemVIPService: "MessageSystemVIPService",//客服系統-VIP客服
        PublicKeyWordPermission: "PublicKeyWordPermission",
        API_QueryOrderList_ShowCreditCardNum: "API_QueryOrderList_ShowCreditCardNum",
    },
    dkOption: {
        devUrl: 'https://dev-dk.waningames.com/',
        stageUrl: 'https://stage-dk.waningames.com/',
        url: 'https://dk.waningames.com/'
    }
}