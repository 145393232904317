<template>
  <div class="table-responsive mt-10">
    <table class="table table-bordered table-flow" id="classTypes">
      <tbody>
        <tr class="text-center" style="background-color: #FEE94E;">
          <td colspan="2" style="width: 50%;">機制項目</td>
          <td style="width: 50%;">延伸機制或活動</td>
        </tr>
        <template v-for="(list, index) in data">
          <!-- 僅有主項目 -->
          <template v-if="list.sub.length == 0">
            <tr>
              <td colspan="2" :class="{ 'td-hover': hasDetail(list.id) }">
                <span 
                  @click="showDetail(list.id)"
                >
                  {{ list.title }}
                </span>
              </td>
              <td></td>
            </tr>
          </template>
          <template v-for="(sub, s_index) in list.sub">
            <!-- 僅有主項目和延伸項目 -->
            <template v-if="sub.id == 0">
              <tr :class="{'bg-gray': index % 2 == 1}">
                <td
                  class="text-center"
                  v-if="s_index == 0"
                  :rowspan="setRowspan(list)"
                  :colspan="setColspan(list)"
                  :class="{ 'td-hover': hasDetail(list.id) }"
                >
                  <span
                    @click="showDetail(list.id)"
                  >
                    {{ list.title }}
                  </span>
                </td>
                <td v-if="setColspan(list) == 1"></td>
                <td class="text-center">
                  <span
                    :class="{ 'has-link': hasDetail(sub.extend[0].id) }"
                    @click="showDetail(sub.extend[0].id)"
                  >
                    {{ sub.extend[0].title }}
                  </span>
                </td>
              </tr>
            </template>

            <!-- 僅有主項目和次項目 -->
            <template v-else-if="sub.extend.length == 0">
              <tr :class="{'bg-gray': index % 2 == 1}">
                <td
                  class="text-center"
                  v-if="s_index == 0"
                  :rowspan="setRowspan(list)"
                  :class="{ 'td-hover': hasDetail(list.id) }"
                >
                  <span
                    :class="{ 'has-link': hasDetail(list.id) }"
                    @click="showDetail(list.id)"
                  >
                    {{ list.title }}
                  </span>
                </td>
                <td class="text-center" :class="{ 'td-hover': hasDetail(sub.id) }">
                  <span
                    :class="{ 'has-link': hasDetail(sub.id) }"
                    @click="showDetail(sub.id)"
                  >
                    {{ sub.title }}
                  </span>
                </td>
                <td></td>
              </tr>
            </template>

            <template v-else>
              <template v-for="(extend, e_index) in sub.extend">
                <tr :class="{'bg-gray': index % 2 == 1}">
                  <td
                    class="text-center"
                    v-if="s_index == 0 && e_index == 0"
                    :rowspan="setRowspan(list)"
                    :class="{ 'td-hover': hasDetail(list.id) }"
                  >
                    <span
                      :class="{ 'has-link': hasDetail(list.id) }"
                      @click="showDetail(list.id)"
                    >
                      {{ list.title }}
                    </span>
                  </td>
                  <td
                    class="text-center"
                    v-if="e_index == 0"
                    :rowspan="sub.extend.length"
                    :class="{ 'td-hover': hasDetail(sub.id) }"
                  >
                    <span
                      :class="{ 'has-link': hasDetail(sub.id) }"
                      @click="showDetail(sub.id)"
                    >
                      {{ sub.title }}
                    </span>
                  </td>
                  <td class="text-center" :class="{ 'td-hover': hasDetail(extend.id) }">
                    <span
                      :class="{ 'has-link': hasDetail(extend.id) }"
                      @click="showDetail(extend.id)"
                    >
                      {{ extend.title }}
                    </span>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
    <!--內容-->
    <div class="modal fade" ref="detail" id="activityContent">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-center">{{ detail.項目名稱 }}</h4>
          </div>
          <div class="modal-body">
            <div v-html="detail.詳細說明"></div>
            <p class="is-bottom">已經到底了</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import api from "@/assets/js/app-api";

export default {
  props: {
    data: Array,
    id: Number,
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      itemList: [],
      itemIdList: [],
      detail: {},
    };
  },
  async created() {
    this.getItemList();
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    getItemList() {
      api.slot.eventItem.queryAll({遊戲編號: Number(this.id)})
        .then((res) => {
          this.itemList = res.data.result.datas;
          this.itemIdList = this.itemList.map(item => item.編號);

          this.deleteStopItem();
        })
    },
    deleteStopItem() {
      this.data.forEach((item, index) => {
        if(this.itemIdList.findIndex(id => id == item.id) == -1) this.data.splice(index, 1);

        else if(item.sub.length > 0) {
          item.sub.forEach((sub, s_index) => {
            if(this.itemIdList.findIndex(id => id == sub.id) == -1) item.sub.splice(s_index, 1);

            else if(sub.extend.length > 0) {
              sub.extend.forEach((extend, e_index) => {
                if(this.itemIdList.findIndex(id => id == extend.id) == -1) sub.extend.splice(e_index, 1);
              })
            }
          })
        }
      })
    },
    setColspan(list) {
      var num = 0;
      var cols = 0

      list.sub.forEach(item => {
        if(item.id == 0) num += 1;
      })
      cols = num < list.sub.length ? 1 : 2

      return cols
    }, 
    setRowspan(list) {
      let num = list.sub.length;
      list.sub.forEach(item => {
        if(item.extend.length >= 2) {
          num = num + (Number(item.extend.length) - 1)
        }
      })
      return num
    },
    hasDetail(id) {
      if(this.isEdit) return false
      else return this.itemList.findIndex(item => item.編號 == id && item.詳細說明 != '') > -1 ? true : false
    },
    showDetail(id) {
      if(this.isEdit) return
      let data = this.itemList.find(item => item.編號 == id);
      if(data.詳細說明 != "") {
        this.detail = data;
				$(this.$refs.detail).modal("show");
      } 
    }
  }
};
</script>