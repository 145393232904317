import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    // query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    bonusAndRefundQueryAll({ commit }, data) {
        api.bonusAndRefund.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setBonusAndRefundQueryAll', res.data.result);
            }
        })
    },
    // activityQuery({ commit }, data) {
    //     api.activity.query(data).then(res => {
    //         if (res.data.status == "1") {
    //             commit('setActivityQuery', res.data.result);
    //         }
    //     })
    // },
    bonusAndRefundCreate({ commit }, data) {
        api.bonusAndRefund.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    bonusAndRefundImport({ commit }, data) {
        api.bonusAndRefund.import(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    // activityUpdate({ commit }, data) {
    //     api.activity.update(data).then(res => {
    //         commit('setResponse', res.data);
    //     })
    // },
    // activityDelete({ commit }, data) {
    //     api.activity.delete(data).then(res => {
    //         commit('setResponse', res.data);
    //     })
    // },
}

// mutations
const mutations = {
    setBonusAndRefundQueryAll(state, bonusAndRefundQueryAll) {
        state.queryAll = bonusAndRefundQueryAll;
    },
    // setActivityQuery(state, activityQuery) {
    //     state.query = activityQuery;
    // },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}