<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲介紹列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲介紹管理</li>
        <li class="active">遊戲介紹列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">

            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增遊戲
            </button>

            <button
              @click="clearCatch()"
              class="btn btn-block btn-default btn-add mr-10"
              type="button"
            >
              <i class="fa fa-refresh"></i> 清除Catch
            </button>

            <!-- 分類下拉 -->
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.遊戲分類"
                @change="clickSearch"
                v-if="newTypeClass"
              >
                 <option value>全部遊戲分類</option>
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in newTypeClass"
                  :key="index"
                >
                  {{ item.className }}
                </option>
              </select> 
            </div>

            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.是否發佈"
                @change="clickSearch"
              >
                <option value=-1>上/下架</option>
                <option value=1>上架</option>
                <option value=0>下架</option>
              </select> 
            </div>

            <!-- 搜尋 -->
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋遊戲名稱"
                type="text"
                v-model="searchData.遊戲名稱"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="select-group checkbox-group ml-10">
              <input
                id='checkLogoLong' 
                type="checkbox"
                v-model="searchData.是否出現首頁"
                @change="clickSearch()"
              />
              <label for='checkLogoLong'>首頁顯示中的遊戲長圖</label>
            </div>

            

            <div class="text">
              <p class="data-num">
                 資料總筆數:
                 <span id="total">{{total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px;width: 5%">No</th>
                  <th style="min-width: 90px; width: 10%">分類</th>
                  <th style="min-width: 90px; width: 15%">遊戲Logo</th>
                  <th style="min-width: 90px; width: 15%">遊戲名稱</th>
                  <th class="text-nowrap" style="width: 5%">狀態</th>
                  <th class="text-nowrap" style="width: 5%">排序</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 5%"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="total > 0">
                  <tr :key="item.id" v-for="(item, index) in datas">
                    <td align="center">{{ index + 1 }}</td>
                    <td>{{ getClassTypeName(item.遊戲分類) }} </td>
                    <td>
                      <div class="pic jqimgFill">
                        <img :src="getLogoUrl(item)" />
                      </div>
                    </td>
                    <td align="center">
                      {{ item.遊戲名稱 }}
                    </td>
                    <td align="center">
                      <!-- {{ GetStatus(item) }} -->
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          type="checkbox"
                          name="ckbx-style-8"
                          :id="`check${index}`"
                          :false-value="false"
                          :true-value="true"
                          v-model="item.是否發佈"
                          @change="toggle(item)"
                        />
                        <label :for="`check${index}`"></label>
                      </div>
                    </td>

                    <td class="td-sort text-nowrap">
                      <input
                        type="number"
                        v-model="item.排序"
                        min="1"
                        :disabled="!item.是否發佈"
                      />
                      <button
                        @click="updateOrder(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        v-if="item.是否發佈"
                      >
                        <i class="fa fa-arrow-up"></i> 更新
                      </button>
                    </td>

                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">
                        <i class="fa fa-pencil"></i> 修改
                      </button>
                      <br>
                      <button @click="copyItem(item.gid)" class="btn btn-warning mt-5">
                        <i class="fa fa-copy"></i> 複製
                      </button>
                      <br>
                      <button @click="deleteItem(item)" class="btn btn-danger mt-5">
                        <i class="fa fa-close"></i> 刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">查詢無任何資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="total"
        >
          <Pagination
            ref="pagination"
            :total="total "
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
            :type2="true"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
    
      searchData: {
        是否發佈: -1,  //-1:all, 1:上架, 0:下架
        遊戲分類: "", 
        遊戲名稱: "",
        pageNum: 1,
        是否出現首頁: null,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
      newTypeClass: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    await this.GetBannerClassTypes();
    
  },
  mounted() {
    this.getDataList();
    // console.log(this.gameTypes.datas);

    //濾掉 isDelete=1 的項目
    setTimeout(() => {
        this.newTypeClass = this.gameTypes.datas.filter(item => item.isDelete === 0);
    }, 200);

    
  },
  computed: {
    ...mapState({
      gameTypes: (state) => state.classTypes.queryAll,
    }),

    getLogoUrl() {
      return (item) => {
        const logo1File = item.files.find((file) => file.info === "logo1");
        return logo1File ? logo1File.filesUrl : "";
      };
    },

    getClassTypeName() {
      return (classTypeId) => {
        const typeClassItem = this.newTypeClass.find((item) => item.classTypeId === classTypeId);
        return typeClassItem ? typeClassItem.className : '未分類';
      };
    },
  },

  methods: {
    getDataList() {
      //列表
      this.$store
        .dispatch("xinGameIntro/GameIntroList", this.searchData)
        .then((result) => {
          if (result) {
            this.datas = result.datas || [];
            this.total = result.total;
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          } else {
            this.datas = [];
            this.total = 0;
          }
         
        });
    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    async GetBannerClassTypes() {
      //分類
      this.$store
        .dispatch("classTypes/classTypeQueryAll", {
          projectId: this.searchData.projectId,
          module: "game",
          lang: "zh-tw",
        })
    },

    updateOrder(item) {
      let edit = item;
      edit.排序 = parseInt(edit.排序);

      //console.log(edit)

      this.$store
          .dispatch("xinGameIntro/GameIntroUpdate", edit)
          .then((result) => {
            this.$eventBus.$emit("showAlert", "排序更新成功！");
            this.getDataList();
          });
    },

    toggle(item) {
      const action = item.是否發佈 ? "上架" : "下架";
    
      console.log(item);
      this.$eventBus.$emit("showConfirm", `確定要${action}嗎?`, (isOK) => {
        if (isOK) {
          this.$store
          .dispatch("xinGameIntro/GameIntroUpdate", item)
          .then((result) => {
            this.$eventBus.$emit("showAlert", `${action}更新成功！`);
            this.getDataList();
          });
        } else {
          item.是否發佈 = item.是否發佈 ? false : true;
        }
      });
    },

    clickSearch() {
      this.searchData.是否發佈 = parseInt(this.searchData.是否發佈);
      this.searchData.pageNum = 1;
      this.searchData.是否出現首頁 = this.searchData.是否出現首頁 || null;

      this.getDataList();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "XinGameIntroEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "XinGameIntroEdit",
        params: { id: item.gid, searchData: this.searchData },
      });
      return;
    },

    //*---- 復製
    copyItem(id) {
      this.$eventBus.$emit("showConfirm", "確定要複製嗎?", (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinGameIntro/GameIntroCopy", 
            {
              gid: id,
              CreateUId:this.$store.state.loginUser.EmployeeId,
              CreateUName:this.$store.state.loginUser.username,
            }
            )
            .then((result) => {
              this.getDataList();
            });
        }
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store
            .dispatch("xinGameIntro/GameIntroDel", item)
            .then((result) => {
              this.getDataList();
            });
        }
      });
    },

    // clear 
    clearCatch() {
      this.$store
          .dispatch("xinGameIntro/GameIntroClear")
          .then((result) => {
            console.log("已清除Catch");
          });
    },

    

    GetStatus(item) {
      return item.是否發佈 == true ? "上架" : "下架" ;
    },
  },
};
</script>
