<template>
  <div id="ForumMuteSetting">
    <section class="content-header">
      <h1>討論區數據統計</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">討論區數據統計</li>
      </ol>
    </section>
    <section class="content forum-mute">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group clearfix">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                />
              </div>
            </div>
            <button
              class="btn btn-default"
              type="button"
              @click="resetDataList()"
            >
              查詢
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min1000">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 100px">日期</th>
                  <th>新訪客ID數</th>
                  <th>新FB會員數</th>
                  <th>新QPP會員門號數</th>
                  <th>新話題數</th>
                  <th>新評論數</th>
                  <th>新投書數</th>
                  <th>新特企數</th>
                  <th>新留言數</th>
                  <th>新留言不重複人數</th>
                  <th>新收藏新聞數</th>
                  <th>新追蹤文章數</th>
                  <th>新追蹤標籤數</th>
                  <th>新追蹤記者數</th>
                  <th>新追蹤作者數</th>
                  <th>新投票數</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="12">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="12">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">{{ item.CountDay }}</td>
                  <td class="text-center">{{ item.visitorCount }}</td>
                  <td class="text-center">{{ item.fbCount }}</td>
                  <td class="text-center">{{ item.memberCount }}</td>
                  <td class="text-center">{{ item.articleCount }}</td>
                  <td class="text-center">{{ item.NewsArticle }}</td>
                  <td class="text-center">{{ item.sendArticle }}</td>
                  <td class="text-center">{{ item.planArticle }}</td>
                  <td class="text-center">{{ item.commentCount }}</td>
                  <td class="text-center">{{ item.commentMember }}</td>
                  <td class="text-center">{{ item.collectNewsCount }}</td>
                  <td class="text-center">{{ item.TrackArticle }}</td>
                  <td class="text-center">{{ item.TrackTag }}</td>
                  <td class="text-center">{{ item.TrackReporter }}</td>
                  <td class="text-center">{{ item.TrackAuthor }}</td>
                  <td class="text-center">{{ item.voteCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix"></div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        startTime: new moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),
      },
      datas: [],
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.$store
        .dispatch("newtalk/statisticQueryAll", this.searchData)
        .then((result) => {
          this.datas = result || [];
          this.total = result.length;
          this.loading = false;
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
  },
};
</script>
