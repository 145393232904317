<template>
  <div id="contactUssInfo">
    <section class="content-header">
      <h1>聯絡我們</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>聯絡我們列表</li>
        <li class="active">聯絡我們資訊</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">聯絡我們資訊</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 真實姓名：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>{{contactUs.name}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red"></span> 公司名稱：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>{{contactUs.companyName}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 聯絡Email：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>{{contactUs.email}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 聯絡電話：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>{{contactUs.phoneNumber}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label ">
                  <span class="text-red">*</span> 主旨：
                </label>
                <div class="col-sm-4">
                  <label class="form-control" disabled>{{contactUs.subject}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="contactUsor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="contactUsor">
                    <textarea v-model="contactUs.note" style="width: 100%; height: 200px" disabled></textarea>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-2">
                  <select class="form-control" v-model="contactUs.status">
                    <option value="0">待處理</option>
                    <option value="1">已處理</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save()">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
    tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {};
  },
  created() {
    this.GetQuery();
  },
  mounted() {},
  computed: {
    ...mapState({
      contactUs: state => state.contactUs.query,
      response: state => state.contactUs.response
    })
  },
  watch: {
    contactUs() {},
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        this.backList();
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    }
  },
  methods: {
    //取得資料
    GetQuery() {
      this.$store.dispatch("contactUs/contactUsQuery", {
        contactUsId: this.$route.params.id
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XinContactUsList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    formatTime: function(t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },

    //送出
    Save() {
      this.contactUs.status = parseInt(this.contactUs.status);
      this.$store.dispatch("contactUs/contactUsUpdate", this.contactUs);
    }
  }
};
</script>
