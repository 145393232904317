<template>
  <div>
    <highcharts
      :options="chartOptions"
      :constructor-type="'mapChart'"
      v-if="!loading"
    ></highcharts>
  </div>
</template>

<script>
/* eslint-disable */
import TaiwanMap from "@highcharts/map-collection/countries/tw/tw-all.geo.json";

export default {
  props: ["datas", "chartTitle"],
  data() {
    return {
      loading: true,
      seriesDataList: [],
      chartOptions: {
        chart: {
          map: TaiwanMap,
          width: 450,
          height: 550,
          events: {
            render() {
              this.series.forEach((datas, i) => {
                datas.points.forEach((point, i) => {
                  if (
                    point["hc-key"] === "tw-ph" ||
                    point["hc-key"] === "tw-km" ||
                    point["hc-key"] === "tw-lk"
                  ) {
                    const bbox = point.graphic.getBBox();
                    const centerX =
                      point["hc-key"] === "tw-km"
                        ? bbox.width / 2 + bbox.x * 2
                        : bbox.width / 2 + bbox.x;
                    const centerY = bbox.height / 2 + bbox.y;
                    const scale = point["hc-key"] === "tw-lk" ? 3 : 2;
                    point.graphic
                      .attr(
                        {
                          transform:
                            "translate(" +
                            (1 - scale) * centerX +
                            ", " +
                            (1 - scale) * centerY +
                            ") scale(" +
                            scale +
                            ")",
                        },
                        { "stroke-width": "" }
                      )
                      .toFront();
                  }
                });
              });
            },
          },
        },
        title: {
          text: "",
        },
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: "right",
          },
        },
        colorAxis: {
          min: 0,
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "downloadJPEG",
                "downloadPNG",
                "downloadSVG",
                "downloadPDF",
              ],
            },
          },
          enabled: false,
        },
        tooltip: {
          outside: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "15px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [],
      },
    };
  },
  watch: {
    datas(val) {
      if (val) this.setSeriesDataList(val);
    },
  },
  methods: {
    switchBadgeColor(name) {
      return this.$parent.switchBadgeColor(name);
    },
    switchHckey(id) {
      switch (id) {
        // 屏東縣
        case "10013":
          return "tw-pt";
        // 台南市
        case "67000":
          return "tw-tn";
        // 宜蘭縣
        case "10002":
          return "tw-il";
        // 嘉義縣
        case "10010":
          return "tw-ch";
        // 台東縣
        case "10014":
          return "tw-tt";
        // 澎湖縣
        case "10016":
          return "tw-ph";
        // 金門縣
        case "09020":
          return "tw-km";
        // 連江縣
        case "09007":
          return "tw-lk";
        // 台北市
        case "63000":
          return "tw-tw";
        // 嘉義市
        case "10020":
          return "tw-cs";
        // 台中市
        case "66000":
          return "tw-th";
        // 雲林縣
        case "10009":
          return "tw-yl";
        // 高雄市
        case "64000":
          return "tw-kh";
        // 新北市
        case "65000":
          return "tw-tp";
        // 新竹市
        case "10018":
          return "tw-hs";
        // 新竹縣
        case "10004":
          return "tw-hh";
        // 基隆市
        case "10017":
          return "tw-cl";
        // 苗栗縣
        case "10005":
          return "tw-ml";
        // 桃園市
        case "68000":
          return "tw-ty";
        // 彰化縣
        case "10007":
          return "tw-cg";
        // 花蓮縣
        case "10015":
          return "tw-hl";
        // 南投縣
        case "10008":
          return "tw-nt";
        default:
          return id;
      }
    },
    switchCityName(id) {
      let menu = this.$parent.menu;
      let area
      let city_index
      for(const key in menu) {
        let Index = menu[key].findIndex(city => city.代碼 == id)
        if(Index > -1) {
          area = key
          city_index = Index
        }
      }
      return menu[area][city_index]["縣市"]
    },
    setSeriesDataList(datas) {
      this.loading = true;

      const kmtArray = datas.filter((item) => item.badge === "中國國民黨");
      let kmtSeries = {
        name: "國民黨",
        states: {
          hover: {
            color: "#a7c7ff",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "13px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        allAreas: false,
        borderWidth: 0.7,
        data: [],
      };
      kmtArray.forEach((city) => {
        kmtSeries.data.push({
          "hc-key": this.switchHckey(city.id),
          color: "#5389e9",
          city: this.switchCityName(city.id),
        });
      });

      const dppArray = datas.filter((item) => item.badge === "民主進步黨");
      let dppSeries = {
        name: "民進黨",
        states: {
          hover: {
            color: "#51f3a1",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "13px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        allAreas: false,
        borderWidth: 0.7,
        data: [],
      };
      dppArray.forEach((city) => {
        dppSeries.data.push({
          "hc-key": this.switchHckey(city.id),
          color: "#00c06d",
          city: this.switchCityName(city.id),
        });
      });

      const tppArray = datas.filter((item) => item.badge === "台灣民眾黨");
      let tppSeries = {
        name: "民眾黨",
        states: {
          hover: {
            color: "#b2ebef",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "13px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        allAreas: false,
        borderWidth: 0.7,
        data: [],
      };
      tppArray.forEach((city) => {
        tppSeries.data.push({
          "hc-key": this.switchHckey(city.id),
          color: "#42bbc4",
          city: this.switchCityName(city.id),
        });
      });

      const nppArray = datas.filter((item) => item.badge === "時代力量");
      let nppSeries = {
        name: "時代力量",
        states: {
          hover: {
            color: "#ffdf7d",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "13px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        allAreas: false,
        borderWidth: 0.7,
        data: [],
      };
      nppArray.forEach((city) => {
        nppSeries.data.push({
          "hc-key": this.switchHckey(city.id),
          color: "#fcbe01",
          city: this.switchCityName(city.id),
        });
      });

      const pfpArray = datas.filter((item) => item.badge === "親民黨");
      let pfpSeries = {
        name: "親民黨",
        states: {
          hover: {
            color: "#ffbb80",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "13px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        allAreas: false,
        borderWidth: 0.7,
        data: [],
      };
      pfpArray.forEach((city) => {
        pfpSeries.data.push({
          "hc-key": this.switchHckey(city.id),
          color: "#f70",
          city: this.switchCityName(city.id),
        });
      });

      const othersArray = datas.filter(
        (item) =>
          item.badge !== "中國國民黨" &&
          item.badge !== "民主進步黨" &&
          item.badge !== "台灣民眾黨" &&
          item.badge !== "時代力量" &&
          item.badge !== "親民黨"
      );
      let othersSeries = {
        name: "其他政黨",
        states: {
          hover: {
            color: "#dbdbdb",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.city;
          },
          style: {
            fontSize: "13px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        allAreas: false,
        data: [],
      };
      othersArray.forEach((city) => {
        othersSeries.data.push({
          "hc-key": this.switchHckey(city.id),
          color: "#dbdbdb",
          city: this.switchCityName(city.id),
        });
      });

      const finalSeries = [];
      finalSeries.push(kmtSeries);
      finalSeries.push(dppSeries);
      finalSeries.push(tppSeries);
      finalSeries.push(nppSeries);
      finalSeries.push(pfpSeries);
      finalSeries.push(othersSeries);
      this.chartOptions.series = [];
      this.chartOptions.series = finalSeries;

      this.loading = false;
    },
  },
};
</script>
