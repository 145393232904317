<template>
  <div id="CustomerServiceEdit">
    <section class="content-header">
      <h1>{{ type }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/FC/CustomerService">客服處理</router-link>
        </li>
        <li class="active">{{ type }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="customerServiceForm">
            <div class="box-body">
              <div class="table-responsive mt-10">
                <table
                  class="table table-bordered table-striped min-600"
                  v-show="type === '聯絡我們'"
                >
                  <tbody>
                    <tr class="bg-gray">
                      <td colspan="4">玩家基本資料</td>
                    </tr>
                    <tr>
                      <td>聯絡時間</td>
                      <td>
                        {{
                          data.reactionTime | dateFormat("YYYY/MM/DD HH:mm:ss")
                        }}
                      </td>
                      <td>平台</td>
                      <td>{{ define.contactPlatforms[data.platform] }}</td>
                    </tr>
                    <tr>
                      <td>會員編號</td>
                      <td>{{ data.reactionUId }}</td>
                      <td>網路環境</td>
                      <td>{{ data.network }}</td>
                    </tr>
                    <tr>
                      <td>暱稱</td>
                      <td>{{ data.reactionNickname }}</td>
                      <td>手機型號</td>
                      <td>{{ data.phoneModel }}</td>
                    </tr>
                    <tr>
                      <td>系統版本</td>
                      <td>{{ data.systemVersion }}</td>
                      <td>APP版本</td>
                      <td>{{ data.appVersion }}</td>
                    </tr>
                    <tr>
                      <td>IP</td>
                      <td>{{ data.ip }}</td>
                      <td>問題類別</td>
                      <td>{{ define.contactTypes[data.reactionType] }}</td>
                    </tr>
                    <tr>
                      <td>玩家Eamil</td>
                      <td colspan="3">
                        <a :href="`mailto:${data.email}`">
                          {{ data.email }}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td class="bg-gray" colspan="4">玩家回饋內容</td>
                    </tr>
                    <tr>
                      <td colspan="4">{{ data.reactionNote }}</td>
                    </tr>
                  </tbody>
                </table>

                <table
                  class="table table-bordered table-striped"
                  v-show="type === '檢舉'"
                >
                  <tbody>
                    <tr class="bg-gray">
                      <td colspan="2">玩家基本資料</td>
                    </tr>
                    <tr>
                      <td>檢舉玩家UID</td>
                      <td>{{ data.reactionUId }}</td>
                    </tr>
                    <tr>
                      <td>被檢舉玩家UID</td>
                      <td>{{ data.sufferUId }}</td>
                    </tr>
                    <tr>
                      <td>檢舉類別</td>
                      <td>{{ define.reportTypes[data.reactionType] }}</td>
                    </tr>
                    <tr>
                      <td>檢舉玩家Email</td>
                      <td>
                        <a :href="`mailto:${data.email}`">
                          {{ data.email }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-bordered table-striped min-600 mt-30">
                  <tbody>
                    <tr class="bg-gray">
                      <td colspan="4">客服回饋</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <div class="editor">
                          <div
                            v-if="data.status === 2"
                            v-html="data.replyNote"
                          ></div>
                          <tinymce
                            :propLang="editLang"
                            v-else
                            v-model="data.replyNote"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div class="box-footer">
            <div class="btns" v-if="data.status !== 2">
              <button
                @click="save(1)"
                class="btn btn-warning btn-lg"
                style="margin-right: 10px"
                type="button"
              >
                儲存
              </button>
              <button
                @click="save(2)"
                class="btn btn-primary btn-lg"
                type="submit"
              >
                確認送出
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import tinymce from "@/components/Tinymce";

export default {
  components: {
    tinymce,
  },
  props: {
    id: "",
    type: "",
  },
  data() {
    return {
      timer: null,
      editLang: "zh-tw",
      param: {},
      data: {},
    };
  },
  mounted() {
    this.checkSession();
    this.getService();
  },
  methods: {
    getService() {
      this.param.reactionId = this.id;
      this.$store.dispatch("fcService/query", this.param).then((result) => {
        this.data = result;
      });
    },
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
      }, 600000);
    },
    backList() {
      this.$router.push({
        name: "CustomerService",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    save(status) {
      if (this.data.replyNote) {
        this.data.status = status;
        this.$store.dispatch("fcService/update", this.data).then(() => {
          this.backList();
        });
      } else {
        this.$eventBus.$emit("showAlert", "客服回饋不得為空！");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>
