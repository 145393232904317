<template>
  <!-- 辯論圖示 -->
  <div class="modal fade newtalk" ref="voteImgContent">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增連結</h4>
        </div>
        <div class="modal-body">
          <input
            type="text"
            placeholder="戰隊/學校名稱"
            class="form-control"
            v-model="data.social.teamName"
          />
          <input
            type="text"
            placeholder="遊戲項目"
            class="form-control mt-10"
            v-model="data.social.gameName"
          />
          <input
            type="text"
            placeholder="Facebook"
            class="form-control mt-10"
            v-model="data.social.facebook"
          />
          <input
            type="text"
            placeholder="Instagram"
            class="form-control mt-10"
            v-model="data.social.instagram"
          />
          <input
            type="text"
            placeholder="youtube"
            class="form-control mt-10"
            v-model="data.social.youtube"
          />
          <input
            type="text"
            placeholder="twitch"
            class="form-control mt-10"
            v-model="data.social.twitch"
          />
          <input
            type="text"
            placeholder="其它"
            class="form-control mt-10"
            v-model="data.social.other"
          />
          <div class="mt-10" style="display: flex; align-items: center">
            <div style="min-width: 75px">
              <span class="text-red">*</span> 圖片上傳
            </div>
            <span class="text-red">(圖片大小限制10MB)</span>
          </div>
          <div class="mt-10">
            <button @click="uploadImg">選擇檔案</button>
            <input
              accept="image/*"
              type="file"
              id="AEAImg"
              name="AEAImg"
              @change="changeImage($event)"
              style="display: none"
            />
          </div>
          <div class="mt-10">
            <p v-if="data.imageName">{{ data.imageName }}</p>
            <img :src="data.imageUrl" style="width: 100%" />
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" data-dismiss="modal" type="button">
            取消
          </button>
          <button @click="submit()" class="btn btn-primary" type="button">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import utils from "@/assets/js/app-utils";

export default {
  data() {
    return {
      lang: "zh-tw",
      oIndex: 0,
      data: {
        imageUrl: "",
        imageName: "",
        social: {
          teamName: "",
          gameName: "",
          facebook: "",
          instagram: "",
          youtube: "",
          twitch: "",
          other: "",
        },
      },
    };
  },
  methods: {
    showContent(data, oIndex) {
      let social = {
        facebook: "",
        instagram: "",
        youtube: "",
        twitch: "",
        other: "",
      };
      this.oIndex = oIndex;
      this.data.imageName = data.options[oIndex].imageName || "";
      this.data.imageUrl = data.options[oIndex].imageUrl || "";
      this.data.social = data.options[oIndex].social || social;
      this.showAlert();
    },
    showAlert() {
      $(this.$refs.voteImgContent).modal("show");
    },
    hideAlert() {
      $(this.$refs.voteImgContent).modal("hide");
    },
    uploadImg() {
      $("#AEAImg").click();
    },
    changeImage(event) {
      const files = event.target.files;
      const fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 10) {
        this.$eventBus.$emit("showAlert", "超過圖片大小限制10MB！");
        return;
      }
      this.data.imageName = files[0].name;
      if (files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target.result.startsWith("data:image")) {
            this.data.imageUrl = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      const data = new FormData();
      data.append("files", files);
      data.append("qppPath", "vote");
      this.$store.dispatch("newtalk/fileUpload", data).then((result) => {
        if (result.status == "1") {
          this.data.imageUrl = result.files[0].imageUrl;
          this.data.imageName = result.files[0].fileName;
          this.submitData();
        } else {
          this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
        }
      });
    },
    submit() {
      const AEAImg = document.getElementById("AEAImg");
      if (this.data.imageUrl == "") {
        this.$eventBus.$emit("showAlert", "請選擇圖片！");
        return;
      }
      if (this.data.imageUrl.indexOf("v.newtalk.tw") == -1) {
        this.data.AEAImgId =
          `${this.$parent.voteImgId}-${this.oIndex}` || utils.getTimeId();
        this.uploadFile(AEAImg.files[0], "voteImg", this.data.AEAImgId);
      } else {
        this.submitData();
      }
    },
    submitData() {
      this.$emit("updateContent", this.data, this.oIndex);
      this.hideAlert();
    },
  },
};
</script>
