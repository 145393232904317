<template>
  <div class="modal fade" ref="mediaContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            :disabled="submitLoading"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ this.mediaId ? "編輯" : "新增" }}內容</h4>
        </div>
        <form class="form-horizontal" method="post" @submit.prevent="submit">
          <div class="modal-body">
            <div class="table-responsive mt-10">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td width="90px">選擇檔案</td>
                    <td>
                      <div class="form-group name-group">
                        <div class="right">
                          <input
                            accept="audio/*"
                            type="file"
                            id="media"
                            name="media"
                            @change="onFileChange($event)"
                            :required="!mediaId"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>開始日期</td>
                    <td>
                      <div class="radio-date">
                        <div class="input-group date">
                          <div class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                          </div>
                          <div class="pull-right">
                            <DatePicker :propValue.sync="startTime" />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>結束日期</td>
                    <td>
                      <iCheckRedioButton
                        checkValue="1"
                        message="永久"
                        name="timeType"
                        style="margin-left: 20px"
                        v-model.number="item.timeType"
                      />
                      <div style="margin-top: 10px">
                        <iCheckRedioButton
                          checkValue="2"
                          message="限時"
                          name="timeType"
                          style="float: left; margin-left: 20px"
                          v-model.number="item.timeType"
                        />
                        <div class="radio-date" style="margin-left: 60px">
                          <div class="input-group date">
                            <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <div class="pull-right">
                              <DatePicker :propValue.sync="endTime" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>播放順序</td>
                    <td>
                      <input
                        class="form-control"
                        style="width: 40%"
                        type="number"
                        required
                        v-model.number="item.orderNum"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>啟用狀態</td>
                    <td>
                      <iCheckRedioButton
                        checkValue="0"
                        message="啟用"
                        name="isStop"
                        style="margin-left: 20px"
                        v-model.number="item.isStop"
                      />
                      <iCheckRedioButton
                        checkValue="1"
                        message="停用"
                        name="isStop"
                        style="margin-left: 50px"
                        v-model.number="item.isStop"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="submitLoading"
            >
              {{ submitLoading ? '新增中' : '儲存' }}
            </button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
              :disabled="submitLoading"
            >
              關閉
            </button>
          </div>
        </form>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import utils from "@/assets/js/app-utils";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";

export default {
  components: {
    DatePicker,
    iCheckRedioButton,
  },
  data() {
    return {
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      mediaId: "",
      item: {},
      startTime: new moment().format("YYYY-MM-DD"),
      endTime: new moment().format("YYYY-MM-DD"),
      submitLoading: false,
    };
  },
  methods: {
    async showContent(item) {
      this.item = item ? JSON.parse(JSON.stringify(item)) : { isStop: 0 };
      this.updateContent();
      $(this.$refs.mediaContent).modal("show");
    },
    updateContent() {
      document.getElementById("media").value = "";
      this.startTime = this.item.startTime || this.startTime;
      this.endTime = this.item.endTime || this.endTime;
      this.mediaId = this.item.mediaId;
      this.item.orderNum = this.item.orderNum || 0;
      this.item.timeType =
        new Date(this.endTime).getFullYear() === 2200 ? 1 : 2;
    },
    submit() {
      this.startTime = new Date(this.startTime);
      this.endTime = new Date(this.endTime);
      if (!this.global.isValidDay(this.startTime)) {
        this.$eventBus.$emit("showAlert", "請填寫開始日期！");
        return;
      }
      if (this.item.timeType === 1) {
        this.endTime = new Date(2200, 1, 1);
      } else if (this.item.timeType === 2) {
        if (!this.global.isValidDay(this.endTime)) {
          this.$eventBus.$emit("showAlert", "請填寫結束日期！");
          return;
        }
      }
      if (!this.global.isValidDayRange(this.startTime, this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }
      this.item.startTime = new Date(`${this.startTime} UTC`);
      this.item.endTime = new Date(`${this.endTime} UTC`);
      const media = document.getElementById("media");

      this.submitLoading = true;
      if (media.files.length >= 1) {
        this.item.mediaId = this.mediaId || utils.getTimeId();
        this.uploadFile(media.files[0], media.name, this.item.mediaId);
      } else {
        this.updateData();
      }
    },
    onFileChange(evt) {
      var files = evt.target.files;
    
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          if (fileSize > 11000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 10 MB，請重新選擇。"
            );
            evt.target.value = null;
            return;
          }
        }
      }
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      const data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data).then((result) => {
        if (result.status == "1") {
          this.updateData();
        } else {
          this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
        }
      });
    },
    updateData() {
      const api = this.mediaId ? "update" : "create";
      this.$store.dispatch(`media/${api}`, this.item).then(() => {
        this.submitLoading = false;
        $(this.$refs.mediaContent).modal("hide");
        this.$emit("updateList");
      });
    },
  },
};
</script>
