<template>
  <div id="CertificationDetail">
    <section class="content-header">
      <h1>實名認證歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>實名認證歷程</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">

            <div class="date-group" style="width: auto; margin-bottom: 0;">
              <label class="control-label" style="float: none; padding-top: 0;">認證期間：</label>
              <div class="input-group date" style="float: none;">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propEndDate.sync="searchData.endTime"
                    :propStartDate.sync="searchData.startTime"
                  />
                </div>
              </div>
            </div>

            <div class="input-group mr-10">
              <label for="" class="control-label">門號：</label>
              <input
                class="form-control pull-right"
                name="mobile"
                placeholder="輸入手機門號"
                type="text"
                v-model="searchData.mobile"
              />
            </div>   

            <!-- <div class="input-group mr-10">
              <label for="" class="control-label">身分證：</label>
              <input
                class="form-control pull-right"
                name="memberNo"
                placeholder="輸入身分證"
                type="text"
                v-model="searchData.memberNo"
              />
            </div> -->

            <div class="select-group" style="width: 200px;">
              <label for="" class="control-label">驗證狀態：</label>
              <select
                class="form-control"
                v-model="searchData.verifyStatus"
              >
                <option :value="-1">全部</option>
                <option :value="0">未完成</option>
                <option :value="1">完成</option>
                <option :value="2">認證失敗</option>
                <option :value="3">封鎖</option>
                <option :value="4">解封</option>
                <option :value="5">重置驗證</option>
                <option :value="6">取消驗證</option>
              </select>
            </div>

            <div class="text" style="margin-top: 22px;">
              <button
                class="btn btn-warning mr-10"
                type="button"
                @click="resetDataList()"
              >
                查詢
              </button>
              <button
                class="btn btn-default mr-10"
                type="button"
                @click="back()"
                v-if="$route.params.mobile"
              >
                返回列表
              </button>
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 10%">專案</th>
                  <th class="text-nowrap" style="width: 10%">手機號碼*</th>
                  <th class="text-nowrap" style="width: 10%;">驗證狀態</th>
                  <th class="text-nowrap" style="width: 10%;">驗證錯誤碼</th>
                  <th class="text-nowrap" style="width: 10%;">驗證錯誤說明</th>
                  <th class="text-nowrap" style="width: 15%;">驗證時間</th>
                  <th class="text-nowrap" style="width: 10%;">操作者</th>
                  <th class="text-nowrap" style="width: 15%;">備註</th>
                </tr>
                <template v-if="loading">
									<td class="text-center" colspan="10">查詢中</td>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="10">查詢無任何資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="index" v-for="(item, index) in data">
                    <td class="text-center">{{ searchData.pageSize * (searchData.pageNum - 1) + index + 1 }}</td>
                    <td class="text-center">{{ item.vendorName }}</td>
                    <td class="text-center">{{ item.mobile }}</td>
                    <td class="text-center">{{ verifyStatus[item.verifyStatus] }}</td>
                    <td class="text-center">{{ item.verifyCode }}</td>
                    <td class="text-center">{{ item.verifyDesc }}</td>
                    <td class="text-center">{{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">{{ item.noteUId }}</td>
                    <td class="text-center">{{ item.note }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0 && !loading">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        memberNo: "", 
        mobile: this.$route.params.mobile, 
        vendorID: "", //認證帳號ID
        verifyStatus: -1, //-1:全選，0:未完成，1:成功，2:認證失敗  3:封鎖 4:解封 5:重置驗證 6:取消驗證
        startTime: new moment().subtract(7, "month").format("YYYY-MM-DD"), 
        endTime: new moment().add(1, "day").format("YYYY-MM-DD"), 
        pageNum: 1, 
        pageSize: 10, 
      },
      data: [],
      total: 0,
      verifyStatus: {
        "-1": "全選",
        "0": "未完成",
        "1": "完成",
        "2": "認證失敗",  
        "3": "封鎖", 
        "4": "解封", 
        "5": "重置驗證", 
        "6": "取消驗證"
      }
    };
  },
  created() {
    this.getDataList();
  },
  watch: {
    $route() {
      this.searchData.mobile = this.$route.params.id || "";
      this.searchData.resetDataList = "";
      this.searchData.verifyStatus = -1;
      this.searchData.startTime = new moment().subtract(7, "month").format("YYYY-MM-DD");
      this.searchData.endTime = new moment().add(1, "day").format("YYYY-MM-DD");
      this.resetDataList();
    },
  },
  mounted() { },
  methods: {
    getDataList() {
      this.loading = true;

      api.mid.mobile.detail(this.searchData)
        .then((res) => {
          this.loading = false;
          this.data = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    back() {
      this.$router.push({
        name: "MIDCertificationList",
        params: { 
          searchData: this.$route.params.searchData,
          }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>