import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    query: [],
    queryAll: [],
    moduleQueryAll: [],

    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    classTypeQuery({ commit }, data) {
        api.nmjClassType.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setClassTypeQuery', res.data.result);
            }
        })
    },
    classTypeQueryAll({ commit }, data) {
        api.nmjClassType.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setClassTypeQueryAll', res.data.result);
            }
        })
    },
    classTypeCreate({ commit }, data) {
        api.nmjClassType.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    classTypeUpdate({ commit }, data) {
        api.nmjClassType.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    classTypeDelete({ commit }, data) {
        api.nmjClassType.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },

    // Module
    moduleQueryAll({ commit }, data) {
        api.nmjClassType.queryModules(data).then(res => {
            if (res.data.status == "1") {
                commit('setModuleQueryAll', res.data.result);
            }
        })
    },

    // File
    fileUpload({ commit }, data){
        return new Promise((resolve, reject) => {
            api.nmjClassType.fileUpload(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    }
}

// mutations
const mutations = {
    setClassTypeQueryAll(state, classTypeQueryAll) { //類別多筆
        var tmp = classTypeQueryAll.datas;
        if (tmp != null) {
            for (var i = 0; i < tmp.length; i++) {
                tmp[i]['editor'] = 0;
            }
            classTypeQueryAll.datas = tmp;
            state.queryAll = classTypeQueryAll;
        } else state.queryAll = classTypeQueryAll;
    },
    setClassTypeQuery(state, classTypeQuery) { //類別單筆
        state.query = classTypeQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setModuleQueryAll(state, moduleQueryAll) {
        state.moduleQueryAll = moduleQueryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}