<template>
  <span>
    <input
      :checked="isChecked"
      :value="propValue"
      class="flat"
      type="checkbox"
      :disabled="propDisabled"
    />
  </span>
</template>

<script>
export default {
  props: {
    propValue: "",
    propChecked: false,
    propDisabled: false,
  },
  data() {
    return {
      isChecked: 0,
      isDisabled: false,
      defaultChecked: false,
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
    });

    this.defaultSet(this.propChecked);

    this.isDisabled = this.propDisabled;
    if (this.isDisabled) this.setDisabled(this.isDisabled);
  },
  watch: {
    propChecked(value) {
      if (value) {
        this.setStatus(1);
      } else {
        this.setStatus(0);
      }
    },
    propDisabled(value) {
      //this.defaultSet(value);
      //this.setDisabled(value);
    },
  },
  methods: {
    defaultSet(value) {
      var vm = this;
      $(vm.$el).on("ifChecked", function (e) {
        vm.setStatus(1);
        vm.emitFunc();
      });
      $(vm.$el).on("ifUnchecked", function (e) {
        vm.setStatus(0);
        vm.emitFunc();
      });
    },
    emitFunc() {
      this.$emit("input", this.isChecked);
      this.$emit("update:checked", {
        value: this.propValue,
        checked: this.isChecked,
      });
    },
    setStatus(isChecked) {
      if (isChecked != 1 && isChecked != 0) {
        return;
      }
      this.isChecked = isChecked;
      if (this.isChecked == 1) {
        $(this.$el).iCheck("check");
      } else {
        $(this.$el).iCheck("uncheck");
      }
    },
    setDisabled(value) {
      if (value === true) $(this.$el).iCheck("disable");
      else $(this.$el).iCheck("enable");
    },
  },
};
</script>
