<template>
  <!--序號產生-->
  <div class="modal fade" ref="serialNumber">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">序號產生</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>序號長度(含活動代碼)</td>
                  <td>
                    <input
                      class="form-control"
                      required="required"
                      type="number"
                      v-model.number="edit.snCodeLen"
                    />
                  </td>
                </tr>
                <tr>
                  <td>產生序號數量</td>
                  <td>
                    <input
                      class="form-control"
                      min="1"
                      required="required"
                      type="number"
                      v-model.number="edit.snCount"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            type="button"
            @click="UpdateSerialNumber()"
          >
            產生序號
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      edit: {
        snCodeLen: 12,
        snCount: 1,
      },
    };
  },
  computed: {
    ...mapState({
      award: (state) => state.award.query,
    }),
  },
  methods: {
    showSerialNumberEdit(award) {
      if (!award || !award.awardsId) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }
      this.GetAwardQuery(award.awardsId);
      this.edit.snCodeLen = 12;
      this.edit.snCount = 1;
      $(this.$refs.serialNumber).modal("show");
    },
    GetAwardQuery(awardsId) {
      this.$store.dispatch("award/awardQuery", {
        awardsId: awardsId,
      });
    },
    UpdateSerialNumber() {
      if (this.edit.snCodeLen <= 0) {
        this.$eventBus.$emit("showAlert", "序號長度不可小於0!");
        return;
      }
      if (this.edit.snCount <= 0) {
        this.$eventBus.$emit("showAlert", "序號數量不可小於0!");
        return;
      }
      this.$store.dispatch("exchangeCode/exchangeCodeCreate", {
        ruleType: this.award.ruleType,
        awardsId: this.award.awardsId,
        snCodeLen: this.edit.snCodeLen,
        snCount: this.edit.snCount,
      });
    },
  },
};
</script>