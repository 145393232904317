<template>
  <div>
    <div class="modal fade" ref="playerServiceContent">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ data.編號 ? '編輯' : '新增' }}紀錄</h4>
          </div>
          <form class="form-horizontal" name="playerServiceForm" @submit.prevent="submit">
            <div class="modal-body">
              <div class="table-responsive mt-0">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th>服務類型</th>
                      <td>
                        <select id="服務類型" v-model.number="data.分類" class="form-control" required>
                          <option value="" selected disabled hidden>請選擇類別</option>
                          <option v-for="name, id in types" :value="id" :key="id">{{ name }}</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>內容</th>
                      <td><textarea id="內容" v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <pre>{{ player }}</pre> -->
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">確認送出</button>
              <button type="button" class="btn btn-default pull-right" data-dismiss="modal">&emsp;取消&emsp;</button>
            </div>
          </form>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import Pagination from "@/components/Pagination";
import store from "../../../store";

export default {
  props:[
    'types'
  ],
  components: {
    DatePicker,
    iCheckRedioButton,
    Pagination,
  },
  data() {
    return {
      player: null,
      data: {
        暱稱:"",
        內容:"",
        分類:""
      },
      pageNum: 1,
      pageSize: 5,
      pageTotal: 0,
    };
  },
  mounted(){
  },
  methods: {
    // getTypes(){
    //   this.$store.dispatch("nmjServiceTypes/serviceTypeQuery").then((result) => {
    //     for (let item of result){
    //       this.$set(this.types, item.編號, item.名稱);
    //     }
    //   });
    // },
    async showContent(player, unban) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      
      // this.getTypes();
      // console.log(player)
      this.data.暱稱 = player;
      $(this.$refs.playerServiceContent).modal("show");
      // console.log("show", this.$refs);
    },
    submit() {
      this.$store.dispatch("nmjPlayerData/serviceCreate", this.data).then(() => {
        $(this.$refs.playerServiceContent).modal("hide");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
