<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="videoAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span>新增影片</span>
          </h4>
        </div>
        <div class="modal-body">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="text-right" style="width: 90px;">
                  <span class="text-red">*</span>標題：
                </td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="data.title"
                  >
                </td>
              </tr>
              <tr>
                <td class="text-right" style="width: 90px;">
                  <span class="text-red">*</span>連結：
                </td>
                <td>
                  <input
                    class="form-control"
                    type="text"
                    v-model="data.url"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button @click="submit()" class="btn btn-primary" type="button">
            新增
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  props: ["actionType", "checkDatas"],
  data() {
    return {
      data: {
        classTypeId: this.$parent.searchData.classTypeId,
        title: "",
        url: ""
      }
    };
  },
  methods: {
    showAlert() {
      $(this.$refs.videoAlert).modal("show");
    },
    hideAlert() {
      $(this.$refs.videoAlert).modal("hide");
      this.resetData();
    },
    resetData() {
      this.data.title = "";
      this.data.url = "";
    },
    submit() {
      console.log(this.data);
      if(this.data.title == '') {
        this.$eventBus.$emit("showAlert", "請輸入標題");
        return
      }

      if(this.data.url == '') {
        this.$eventBus.$emit("showAlert", "請輸入連結");
        return
      }

      this.$store
          .dispatch(`link/linkCreate`, this.data)
          .then(() => {
            this.resetData();
            this.$emit('updateList');
            this.hideAlert();
          });
    },
  },
};
</script>

<style scoped>
table tbody tr td {
  border: none;
}
</style>