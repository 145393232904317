<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲介紹{{ isEdit ? '編輯' : '新增' }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="goBack">遊戲介紹{{ isEdit ? '編輯' : '新增' }}</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? '編輯' : '新增' }}內容</h4>
            <!-- <p class="pull-left color-red ml-5 mt-10">*請依序編輯內容</p> -->
            <router-link :to="goBack" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width:1200px;">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  Gid：
                </label>
                <div class="col-sm-10">
                  <label class="control-label">{{ edit.gid }}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 遊戲編號：
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputTitle" v-model="edit.遊戲館" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputType">
                  <span class="text-red">*</span> 遊戲分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part" id="inputType" v-model="edit.遊戲分類" @change="updateTypeClassID">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option v-for="(item, index) in newTypeClass" :key="index" :value="item.classTypeId" :class_id="item.classId">
                      {{ item.className }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                  <label class="col-sm-2 control-label" for="summary">
                      <span class="text-red">*</span> 遊戲簡介：
                  </label>
                  <div class="col-sm-10">
                      <textarea id="summary" class="form-control" v-model="edit.遊戲簡介" placeholder="內容建議100字內"></textarea>
                  </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="summary">
                  <span class="text-red">*</span>遊戲簡介：
                </label>
                <div class="col-sm-10">
                  <textarea class="form-control" id="summary" v-model="edit.遊戲簡介" placeholder="內容建議100字內"></textarea>
                </div>
              </div> -->

              <div>
                <div class="form-group image-part">
                  <label class="col-sm-2 control-label" for="imgLogo">
                    <span class="text-red">*</span> 遊戲館圖：
                  </label>
                  <div class="col-sm-10">
                    <ImageSelect id="imgLogo" ref="imageSelect"
                      :gid="edit.gid" info="logo" module="GameGlobal"
                      :maxsize="200*1024" accept="image/png"
                      v-model="edit.files">
                      <p class="help-block"> 小於200kb,背景透明png </p>
                    </ImageSelect>
                  </div>
                </div>
              </div>
              
              <hr>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red" v-if="type.id == langs[0].id">*</span> 版本類型：
                </label>
                <div class="col-sm-10 btns" style="display:flex; flex-wrap:wrap; gap:5px;">
                  <button v-for="lang in langs" type="button" class="btn"
                    :class="{ 'btn-primary': type.id == lang.id }" @click="type = lang"
                  >{{ lang.name }}</button>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red" v-if="type.id == langs[0].id">*</span> {{type.name}}名稱：
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputTitle" v-model="toggleName" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="Limits">
                  <span class="text-red" v-if="type.id == langs[0].id">*</span> {{type.name}}說明：
                </label>
                <div class="col-sm-10">
                  <div class="Limits editor"><tinymce v-model="toggleContent" /></div>
                </div>
              </div>

              <hr>

              <!-- 遊戲操作說明 (應該 value 是 id)-->
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 操作說明：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part" v-model="edit.遊戲操作說明ID">
                    <option disabled="disabled" value>
                      請選擇操作說明版本
                    </option>
                    <option :key="item.gid" :value="item.gid" v-for="item in operationTypes">
                      {{ item.version }}
                    </option>
                  </select>
                  <div class="help-block">
                    *進入
                    <router-link :to="{name:'AWH5GameOperationList'}" target="_blank">遊戲操作說明列表</router-link>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputOrderNum">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input class="form-control" id="inputOrderNum" type="number" v-model.number="edit.排序" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton :checkValue="true" message="上架" name="radioMode" v-model="edit.是否發佈" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton :checkValue="false" message="下架" name="radioMode" v-model="edit.是否發佈" />
                  </div>
                </div>
              </div>

            </div>
            <div class="box-footer">
              <div class="btns">
                <!-- <button class="btn btn-warning btn-lg" style="margin-right: 10px" type="button" @click="Save(true)">
                  預覽
                </button> -->

                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <pre>{{ edit }}</pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "./_Define";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImageSelect from "../XSG/components/ImageSelect";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import draggable from "vuedraggable";

class Lang {
  constructor(lang){
    this.id = 0;
    this.language_type = lang;
    this.name = "";
    this.content = "";
  }
}
class Image {
  constructor(info){
    this.id = 0;
    this.info = info;
    this.filesUrl = "";
  }
}

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
    moment,
    tinymce,
    ImageSelect
  },
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: this.id > 0 && this.id != "New",
      waitTimeout: 0,

      langs: dfn.langs,
      type: dfn.langs[0],

      edit: {
        gid: utils.getTimeId(),
        遊戲分類: "",
        遊戲簡介: "",
        遊戲操作說明ID: "",
        遊戲館: "",
        排序: 1,
        是否發佈: true,
        files: [],
        languageList: [...dfn.langs].map(type => new Lang(type.id)),
      },
      imgFile: null,
      newTypeClass: [],
      typeClassID: "",


      //Operation
      gameOperationSearch: {
        pageNum: 1,
        pageSize: 100 //每頁顯示筆數
      },
      operationTypes: [],

      checkInterval: 0,
    };
  },
  created() {
    this.GetBannerClassTypes();
    this.getGameOperationList();
    if (this.isEdit) {
      this.GetGameIntro();
    }

    this.checkInterval = setInterval(() => {
      this.$store.dispatch("news/newsCheck")
    }, 2*60*1000 );
  },
  beforeDestroy(){
    clearInterval(this.checkInterval);
  },
  mounted() {
    //濾掉 isDelete=1 的項目
    setTimeout(() => {
      this.newTypeClass = this.gameTypes.datas.filter(
        item => item.isDelete === 0
      );
    }, 200);

    // this.clearCatch();
  },
  computed: {
    ...mapState({
      gameTypes: state => state.classTypes.queryAll,
      game: state => state.game.query,
      response: state => state.xinGameIntro.response
    }),

    goBack(){
      return {name:'AWH5GameIntroList'}
    },

    imgLogo: {
      get(){
        let img = this.edit.files.find(item => item.info === "logo");
        if (img) return img.filesUrl;
        else {
          this.edit.files.unshift(new Image("logo"));
          return this.edit.files[0].filesUrl;
        }
      },
      set(value){
        let img = this.edit.files.find(item => item.info === "logo");
        if (img && (value != "" || img.id == 0)) this.$set(img, "filesUrl", value);
        else {
          this.edit.files.unshift(new Image("logo"));
          this.$set(this.edit.files[0], "filesUrl", value);
        }
      }
    },

    toggleContent: {
      get() {
        let lang = this.edit.languageList.find(item => item.language_type === this.type.id);
        if (lang){ 
          return lang.content;
        } else {
          this.edit.languageList.push(new Lang(this.type.id));
          return this.edit.languageList[this.edit.languageList.length - 1].content;
        }
      },
      set(value) {
        let item = this.edit.languageList.find(item => item.language_type === this.type.id);
        this.$set(item, "content", value);
        // console.log("SET", item.content, value);
      }
    },
    toggleName: {
      get() {
        let lang = this.edit.languageList.find(item => item.language_type === this.type.id);
        if (lang){ 
          return lang.name;
        } else {
          this.edit.languageList.push(new Lang(this.type.id));
          return this.edit.languageList[this.edit.languageList.length - 1].name;
        }
      },
      set(value) {
        let item = this.edit.languageList.find(item => item.language_type === this.type.id);
        this.$set(item, "name", value);
        // console.log("SET", item.name, value);
      }
    },
  },
  watch: {
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
  },
  methods: {
    //取得該遊戲介紹資料
    GetGameIntro() {
      this.$store.dispatch("xsw/遊戲介紹查詢", {
        gid: this.id
      }).then((result) => {
        let data = result;
        data.files.sort((a, b) => b.id - a.id);
        // console.log(data.files);
        this.edit = data;
      });
    },

    //取得 遊戲分類 資料
    GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "game",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    updateTypeClassID() {
      let selectedClassTypeId = this.edit.遊戲分類;
      let selectedTypeClass = this.gameTypes.datas.find(
        item => item.classTypeId === selectedClassTypeId
      );

      if (selectedTypeClass) {
        this.typeClassID = selectedTypeClass.classId;
      } else {
        this.typeClassID = ""; // 如果找不到匹配项，可以设置为默认值
      }
    },

    //取得 遊戲操作說明 資料
    getGameOperationList() {
      this.$store.dispatch("xsw/遊戲操作說明列表查詢", this.gameOperationSearch).then(result => {
          this.operationTypes = result.datas || [];
        });
    },

    // onFileChange(event, info){
    //   let files = event.target.files;
    //   let file = files[0];
    //   if (!file){
    //     let index = this.edit.files.findIndex(item => item.info === info);
    //     if (index >= 0){  }
    //     this.$set(this.edit.files, index, undefined);
    //   }

    //   if (file) {
    //     if (!/\.(jpg|png|gif|jpeg)$/i.test(file.name)) {
    //       this.$eventBus.$emit("showAlert", "您選擇的檔案格式不符，請重新選擇。");
    //       return;
    //     }
    //     if (file.size > 150 * 1024) {
    //       this.$eventBus.$emit("showAlert", "您選擇的檔案超過上限 150KB，請重新選擇。");
    //       return;
    //     }
    //   }
    //   this.imgFile = file;
    //   let reader = new FileReader();
    //   reader.onload = () => { this.imgLogo = reader.result; };
    //   reader.readAsDataURL(file);
    // },

    // uploadFile(file, info, callback) {
    //   let data = new FormData();

    //   data.append("Files", file);
    //   data.append("Info", info);
    //   data.append("Module", "GameGlobal");
    //   data.append("ModuleId", this.edit.gid);
    //   data.append("projectId", this.$store.state.projectId);

    //   this.$store.dispatch("xsw/檔案上傳", data).then(res => {
    //     this.imgLogo = res[0].filesUrl;
    //     if (typeof callback == "function"){
    //       callback.apply(this);
    //     } else {
    //       this.GetGameIntro();
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
    //   });
    // },

    // clear
    clearCatch() {
      this.$store.dispatch("xsw/GameIntroClear").then(result => {
        console.log("已清除Catch");
      });
    },

    //送出
    async Save() {
      let err = [];

      if (this.edit.遊戲分類 == "") err.push("請選擇遊戲分類");
      if (this.edit.遊戲簡介 == "") err.push("請填寫遊戲簡介");
      if (this.edit.files[0].filesUrl == "") err.push(`請上傳遊戲館圖`);

      let defaultContent = this.edit.languageList.find(item => item.language_type == this.langs[0].id);
      if (!defaultContent || defaultContent.name == ""){
        err.push(`請填寫${this.langs[0].name}名稱`);
      } 
      if (!defaultContent || defaultContent.content == ""){
        err.push(`請填寫${this.langs[0].name}說明`);
      } 

      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let api = this.isEdit ? "修改" : "新增";
      let sendEdit = Object.assign({}, this.edit, {files:undefined});

      Promise.all([
        this.$store.dispatch("xsw/遊戲介紹" + api, sendEdit),
        this.$refs.imageSelect.upload(),
      ]).then(res => {
        this.backList();
      }, err => {
        this.$eventBus.$emit("showAlert", err)
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AWH5GameIntroList",
        params: { searchData: this.$route.params.searchData }
      });
    },
  }
};
</script>

<style scoped>
fieldset:required label::before{
    content: "*";
    color: #dd4b39;
}

.d-inline {
  display: inline-block;
}

/* .chooseBox .img-close {
  right: -10px !important;
  z-index: 9 !important;
} */

.gameGallery {
  display: flex;
  flex-wrap: wrap;
}

.gameGallery .chooseBox {
  margin-bottom: 10px;
}

.gameGallery .chooseBox img {
  background: #ccc;
}

.chooseBox .img-close {
  right: 5px !important;
}

#DisconnectionRules .disconContnet {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 1px solid #f4f4f4;
  padding-top: 10px;
  position: relative;
}


#DisconnectionRules .disconContnet .form-control {
  margin-right: 10px;
  margin-bottom: 10px;
  width: auto;
  min-width: 140px;
}

#DisconnectionRules .disconContnet .d-c {
  width: 80%;
}

#DisconnectionRules .disconContnet .d-c {
  width: 80%;
}

#DisconnectionRules .disconContnet button {
  position: absolute;
  right: 0;
  width: 80px;
  bottom: 0;
}
</style>
