<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>公告編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公告編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width: 70%;">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 公告分類：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="edit.noteType"
                  >
                    <option value="系統公告">系統公告</option>
                  </select>
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 主旨：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.articleTitle"
                    :disabled="newsType == '個人'"
                  />
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <textarea class="form-control" rows="15" v-model="edit.note"></textarea>
                    <!-- <tinymce
                      v-model="edit.note"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date mb-10">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="local.publishSTime"/>
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker_start" type="text" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date mb-10">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="local.publishETime"/>
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker_end" type="text" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>


            </div>


            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save()"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import moment from "moment";
import tinymce from "@/components/Tinymce";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
    tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      isEdit: false,
      id: this.$route.params.id,
      local: {
        publishSTime: new moment().format("YYYY/MM/DD"), 
        publishETime: new moment().format("YYYY/MM/DD"), 
      },
      edit: {
        noteType: "系統公告", //固定值
        note: "", 
        publishSTime: "", 
        publishETime: "", 
        isStop: 0, 
      },
      timepickerOption: {},
      timeStringOption: {timeStyle: "short"},
    };
  },
  created() {
    // this.getCategoryquery();
    if (this.id != null) {
      this.isEdit = true;
      this.getData();
    }
  },
  mounted() {
    this.timepickerOption.showInputs = false;

    $(".timepicker_start").timepicker(this.timepickerOption);
    $(".timepicker_end").timepicker(this.timepickerOption);

  },
  computed: {},
  watch: {},
  methods: {
    getData() {
      api.mid.article.query({ id: this.id })
        .then((res) => {
          this.edit = res.data.result;

          if (this.edit.publishSTime != "") {
            let time = new Date(this.edit.publishSTime).toLocaleDateString()
            this.local.publishSTime = new moment(time).format("YYYY/MM/DD")

            $(".timepicker_start").val(
              new Date(this.edit.publishSTime).toLocaleTimeString("en-US", this.timeStringOption)
            );
          }

          if (this.edit.publishETime != "") {
            let time = new Date(this.edit.publishETime).toLocaleDateString()
            this.local.publishETime = new moment(time).format("YYYY/MM/DD")

            $(".timepicker_end").val(
              new Date(this.edit.publishETime).toLocaleTimeString("en-US", this.timeStringOption)
            );
          }
        })
    },
    backList() {
      this.$router.push({
        name: "MIDAnnouncementList",
        params: {
          searchData: this.$route.params.searchData
        }
      });
    },
    Save() {
      this.edit.publishSTime = this.local.publishSTime + " " + $(".timepicker_start").val();
      this.edit.publishETime = this.local.publishETime + " " + $(".timepicker_end").val();
      this.edit.publishSTime = new Date(this.edit.publishSTime + " UTC");
      this.edit.publishETime = new Date(this.edit.publishETime + " UTC");

      if (this.edit.note == "") {
        this.$eventBus.$emit("showAlert", "請輸入內容");
        return;
      }

      const type = this.isEdit ? "update" : "create";
      const message = this.isEdit ? "更新" : "新增";

      api.mid.article[type](this.edit)
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", `${message}成功!`);
            this.backList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message ||　`${message}失敗!`);
          }
        })
    }
  }
};
</script>
