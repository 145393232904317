<template>
    <div class="mymodal" ref="PopForm">
        <div style="width:600px;margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box box-info">
                <div class="box-header with-border">
                    <h3 class="box-title"> {{ title }}</h3>
                    <button type="button" class="close" @click="Close()"><span aria-hidden="true">×</span></button>
                </div>

                <div class="card">
                    <div>
                        <span class="card-title span-vertical-middle" v-show="VIPMsgEnable">{{ $t('通用訊息') }}</span>
                        <span class="state iradio_flat-blue" :checked="true"></span>
                        <span class="span-vertical-middle">{{ $t('自訂內容') }}</span>
                    </div>
                    <textarea class="form-control card-textarea" :placeholder="$t('請輸入內容')" ref="editTextarea"
                        v-model.trim="edit.Msg" v-AddArgInput="{ model: 'edit.Msg', format: AddArgMixinsFormat }">
                        </textarea>
                    <div>
                        <button class="btn btn-warning" type="button"
                            v-AddArgBtn="{ insertText: '{{cs_number}}', ref: 'editTextarea', model: 'edit.Msg' }">{{
                                $t('添加客服編號') }}</button>
                    </div>
                </div>
                <div class="card" v-show="VIPMsgEnable">
                    <div>
                        <span class="card-title span-vertical-middle">{{ $t('VIP專屬') }}</span>
                        <span class="state iradio_flat-blue" :checked="true"></span>
                        <span class="span-vertical-middle">{{ $t('自訂內容') }}</span>

                        <div style="float:right;font-size: 18px;">
                            <span style="color:#437d43;font-size: 14px;">{{ $t('VIPMsgTip-' + projectId) }}</span>
                            <!-- <span style="font-size: 18px;visibility: hidden;"></span> -->
                        </div>
                    </div>
                    <textarea class="form-control" :placeholder="$t('請輸入內容')" style="height: 100px;" ref="editTextarea2"
                        v-model.trim="edit.MsgVIP" v-AddArgInput="{ model: 'edit.MsgVIP', format: AddArgMixinsFormat }">
                        </textarea>
                    <div>
                        <button class="btn btn-warning" type="button"
                            v-AddArgBtn="{ insertText: '{{cs_number}}', ref: 'editTextarea2', model: 'edit.MsgVIP' }">{{
                                $t('添加客服編號') }}</button>
                    </div>
                </div>
                <div class="box-footer">
                    <button type="button" class="btn" style="margin-right: 15px;" @click="Close">{{ $t('關閉') }}</button>
                    <button type="button" class="btn btn-warning" @click="save">{{ $t('儲存') }}</button>
                </div>

                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import { ProjectSetting, SystemChatType } from "@/store/MessageSystem/messageSystemConst";
import { AddArgMixins } from '@/assets/js/Tool/AddArgMixins'
export default {
    mixins: [AddArgMixins],
    data() {
        return {
            isEdit: true,//是否編輯
            title: "",
            edit: {
                ServiceNo: "",
                PlatformType: "",
                SystemChatType: "",
                觸發時機: "",
                Msg: "",
                MsgVIP: "",
                enable: true
            },
            AddArgMixinsFormat: AddArgMixins.AddArgFormatEnum.兩個大刮號
        };
    },
    computed: mapState({
        VIPMsgEnable(state) {
            //這段比較麻煩一點，為星城編輯VIP專屬迎賓訊息，做操作畫面的特別處理
            //訊息有很多類別，參SystemChatType
            //其中迎賓訊息(WelcomeMsg)又可輸入兩種訊息，一個是通用訊息(每個站台都有)，一個是VIP迎賓訊息(只有星城多加)
            //這一段的話，會是在編輯迎賓訊息，且專案是星城才會回傳true
            //考量以後每種訊息格式都不太相同，之後最好是統整一下
            let isProject設定啟用 = ProjectSetting.VIPMsgEnable(state.projectId);
            return this.edit.SystemChatType == SystemChatType.WelcomeMsg && isProject設定啟用;
        },
        projectId: state => state.projectId,
    }),
    mounted() {
        $(this.$refs.PopForm).hide();
    },
    methods: {
        Open(title, ServiceNo, PlatformType, { 觸發時機, Msg, MsgVIP, SystemChatType }) {
            this.title = title;
            this.edit.觸發時機 = 觸發時機;
            this.edit.Msg = Msg;
            this.edit.MsgVIP = MsgVIP;
            this.edit.ServiceNo = ServiceNo;
            this.edit.PlatformType = PlatformType;
            this.edit.SystemChatType = SystemChatType;
            this.selectionStart = -1;
            this.selectionEnd = -1;
            // todo:下面這行自動焦點失效
            // this.$nextTick(function () {
            //     this.$refs.editTextarea.focus();
            // });
            $(this.$refs.PopForm).show();
            $(this.$refs.loading).hide();
        },
        Close() {
            $(this.$refs.PopForm).hide();
        },
        async save() {
            $(this.$refs.loading).show();
            try {
                let res = (await api.CS.SetSystemChatMsg({
                    ServiceNo: this.edit.ServiceNo,
                    PlatformType: this.edit.PlatformType,//0:App；1:Web
                    SystemChatType: this.edit.SystemChatType,
                    Msg: this.edit.Msg,
                    MsgVIP: this.edit.MsgVIP,
                })).data;
                if (res && res.ApiResponse && res.ApiResponse.Success) {
                    this.$emit("refresh", res);
                    throw this.$t('修改成功');
                } else {
                    let err = res && res.ApiResponse && res.ApiResponse.ErrorMsg ? res.ApiResponse.ErrorMsg : "失敗(未知)";
                    throw this.$t(err);
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.PopForm).hide();
        },
    }
}


</script>
<style scoped>
.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.iradio_flat-blue {
    margin-left: 0px;
}

.iradio_flat-blue[checked] {
    background-position: -110px 0;
}

.icheckbox_flat-blue,
.iradio_flat-blue {
    margin-top: 0;
    margin-right: 5px;
}

.card {
    margin: 10px;
}

.card>div {
    margin: 10px 0;
}

.card>div:last-child {
    text-align: right;
}

.card-title {
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
    color: #444;
}

.card-textarea {
    height: 100px;
}

.span-vertical-middle {
    vertical-align: middle;
}
</style>