<template>
    <div id="AnnouncementEdit">
        <section class="content-header">
            <h1>頻道管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>頻道管理</li>
                <li class="active">頻道編輯</li>
            </ol>
        </section>

        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">頻道編輯</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button"><i
                                class="fa fa-reply"></i> 返回列表</button>
                    </div>
                    <form class="form-horizontal" name="announcementForm">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="inputLink">
                                    <span class="text-red">*</span> 頻道URL：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="inputLink" required="required" type="text"
                                        v-model="edit.頻道連結" />
                                    <span v-show="!this.isEdit" style="color: #fd3e3e;">*請填入帶@的頻道網址 https://www.youtube.com/@</span>              
                                </div>     
                                           
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" > 頻道名稱：
                                </label>
                                <div class="col-sm-10">
                                    <span class="form-control" style="background-color: #e5e5e5;">{{edit.頻道名稱}}</span>
                                    <span style="color: gray;">*送出後自動填入</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label"> channel ID：
                                </label>
                                <div class="col-sm-10">
                                    <span class="form-control" style="background-color: #e5e5e5;">{{edit.channelId}}</span>
                                    <span style="color: gray;">*送出後自動填入</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label"><span class="text-red">*</span>
                                    巡邏人：
                                </label>
                                <div class="col-sm-10">
                                    <div class="select-group">
                                        <select class="form-control" id="selectUnit"  v-model="edit.巡邏人">
                                                <option value="" disabled selected>請選擇巡邏人</option>
                                                <option value="尚未派發" >尚未派發</option>
                                                <option
                                                    v-for="(item, index) in channelMan.result"
                                                :value="item.人員名稱"                  
                                                :key="index"
                                                >{{item.人員名稱}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" ><span class="text-red">*</span>
                                    留言板狀態：
                                </label>
                                <div class="col-sm-10">
                                    <div class="select-group">
                                        <select class="form-control" id="selectUnit" v-model="edit.留言板狀態">
                                            <option disabled="disabled" value>請選擇分類</option>
                                            <option value="0" key="0">關閉</option>
                                            <option value="1" key="1">開放</option>
                                            <option value="2" key="2">部分關閉</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" ><span class="text-red">*</span>
                                    影片下架數：
                                </label>
                                <div class="col-sm-10" style="width:120px;">
                                    <input type="number" class="form-control" id="amountInput" min="0" v-model="edit.影片下架數">
                                </div>
                            </div>


                            <div class="form-group">
                                <label class="col-sm-2 control-label" >
                                    玩家資訊：
                                </label>
                                <div class="col-sm-10">
                                    <!-- 玩家 -->
                                    
                                    <div v-for="(item, index) in edit.玩家資訊" :key="index" class="playerbox">                                            
                                        <div class="form-group">
                                            <label class="col-sm-2 control-label" for="inputtel">電話：</label>
                                            <div class="col-sm-10">
                                                <input class="form-control" id="inputtel" required="required"
                                                    type="text" v-model="item.玩家角色電話" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-2 control-label" for="inputTitleID">ID：</label>
                                            <div class="col-sm-10">
                                                <input class="form-control" id="inputTitleID" required="required"
                                                    type="text" v-model="item.玩家角色名稱" />
                                            </div>
                                        </div>     
                                        <div class="form-group text-red">
                                            使用頓號(、)分隔遊戲ID
                                        </div>
                                        <div class="form-group " style="    padding-right: 100px;">
                                            <div style="      min-width: 160px;    padding-left: 18px;">版權使用規範簡訊：</div>
                                            <div class="col-sm-10">
                                                <div class="radio">
                                                    <iCheckRedioButton checkValue="0" message="未寄送" :name="`radioMode${index + 1}`"
                                                        v-model="item.版權使用規範簡訊狀態" />
                                                    <iCheckRedioButton checkValue="1" message="已寄送" :name="`radioMode${index + 1}`"
                                                        v-model="item.版權使用規範簡訊狀態" />
                                                    <iCheckRedioButton checkValue="2" message="發送失敗" :name="`radioMode${index + 1}`"
                                                        v-model="item.版權使用規範簡訊狀態" />
                                                </div>
                                            </div>
                                        </div>

                                        <button @click="removeInputFields(index)" class="btn btn-block btn-danger " type="button">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i> 刪除
                                        </button>
                                    </div>    

                                    <div style=" width: 100%;    display: flex;    justify-content: space-between;">
                                        <button @click="addInputFields()" class="btn btn-block btn-warning " type="button"
                                            style="    width: 100px;">
                                            <i class="fa fa-plus"></i> 新增資訊
                                        </button>
                                        <span style="color: gray;">*相同電話號碼的遊戲ID寫在一起</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="inputTitleMemo">備註：</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control" id="inputTitleMemo" required="required" type="text"
                                        v-model="edit.備註" style="    height: 100px;" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" >
                                    <span class="text-red">*</span>
                                    檢索狀態：
                                </label>
                                <div class="col-sm-10" style="width:120px;">
                                    <div class="ckbx-style-8 ckbx toggleInput">
                                        <input
                                        :false-value="0"
                                        id="state"
                                        :true-value="1"                                                
                                        name="ckbx-style-8"
                                        type="checkbox"
                                        v-model="edit.檢索狀態"
                                        />
                                         <label for="state"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">送出儲存</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
    }
    .playerbox {
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        margin-bottom: 10px;
        display: block;
        padding: 10px 0;
        position: relative;
        .form-group {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 10px;
            margin-top: 0;
            display: flex;
            &.text-red {
                padding-left: 79px;
            }
            .radio {
                padding: 0;

                >label {
                    margin-right: 15px;
                }
            }
        }
        .btn-danger {
            position: absolute;
            right: 18px;
            width: 70px;
            bottom: 15px;
        }
        .control-label {
            text-align: left;
            width: 65px;
            padding-right: 0;
        }
        .col-sm-10 {
            width: calc(100% - 70px);
            margin: 0;
        }
    }

</style>

<script>
    /* eslint-disable */
    import DatePicker from "@/components/DatePicker";
    import CKEditor from "@/components/CKEditor";
    import iCheckCheckBox from "@/components/iCheck_CheckBox";
    import iCheckRedioButton from "@/components/iCheckRedioButton";
    import utils from "@/assets/js/app-utils";
    import {
        mapState,
        mapActions
    } from "vuex";
    import tinymce from "@/components/Tinymce";
    import config from "@/assets/js/app-config";
    import moment from "moment";
    import multiselect from "vue-multiselect";
    import api from "@/assets/js/app-api";

    export default {
        components: {
            iCheckCheckBox,
            iCheckRedioButton: iCheckRedioButton,
            CKEditor,
            DatePicker,
            tinymce,
            multiselect
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                timer: null,
                isEdit: false,
                editLang: "zh-tw",
                moduleId: "",
                edit: {
                    newsId: utils.getTimeId(), //公告Id
                    projectId: this.$store.state.projectId, // 專案Id
                    classTypeId: "", //分類Id
                    lang: this.editLang, // 語系
                    newsDetailId: "", // 內容Id
                    isStop: 1, // 發佈
                    isNowOn: 1, //0:參考startTime ; 1:即日起
                    publishTime: "",
                    相關公告編號: '',
                    startTime: '',
                    endTime: '',
                    巡邏人:'',
                    留言板狀態:1,
                    檢索狀態:1,
                    玩家資訊:[],
                    影片下架數:0
                },             
                langTypes: {},
                timeStringOption: {
                    timeStyle: "short"
                },
                timepickerOption: {},
                detailNewsLang: [],
                imgList: [],
                fileList: [],
                url: config.dkOption.url,
            };
        },
        created() {
            this.checkSession();
            this.GetAllLang();
        },
        mounted() {
           this.getchannelMan();
            // if(!this.edit.頻道連結){
            //     this.edit.頻道連結=this.$route.params.url;
            // }
            

            var that=this;
            $("#inputLink").blur(function(){
                const inputElement = document.getElementById("inputLink");
                const inputValue = inputElement.value;
                if(inputValue.length > 10){
                    const searchString = 'https://www.youtube.com/@';
                    const searchString2='https://www.youtube.com/channel/';
                    if (inputValue.includes(searchString) || inputValue.includes(searchString2)) {
                  
                    }else{
                        alert(`請貼上頻道連結`);
                    }                    
                   
                }                
            });

        },
        
        computed: {
            ...mapState({
                channelMan: (state) => state.smm.QueryMan,
                channelQueryAll: (state) => state.smm.QueryAll,
         //       channelQuery: (state) => state.smm.Query,
                response: (state) => state.smm.response,
                projectData: (state) => state.module.queryAll,
            }),
        },
        watch: {
            // channelQuery() {
            //     this.edit = this.channelQuery;    
                
            // },

            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;             

                //this.edit = this.channelQuery;

                // this.GetNewsClassTypes();
                // this.imgList = [];

                if (this.$route.params.id != null) {
                    this.isEdit = true;
                    this.GetAnnouncement();                    
                } else {

                    // this.detailNewsLang = [];
                    // for (var i = 0; i < this.langTypes.length; i++) {
                    //     const detailId = this.edit.newsId + i + 1;
                    //     this.detailNewsLang.push({
                    //         newsDetailId: detailId,
                    //         lang: this.langTypes[i].id,
                    //         title: this.edit.detailTopTitle,
                    //         info: this.edit.detailTopInfo,
                    //         introduction: this.edit.introduction,
                    //         keyword: this.edit.keyword,
                    //         description: this.edit.description,

                    //     });
                    // }
                }

            },
            newsDetailQueryAll() {
                let that = this;
                this.detailNewsLang = [];
                if (this.newsDetailQueryAll.total > 0) {
                    for (var i = 0; i < this.langTypes.length; i++) {
                        let findLang = false;
                        const Id = this.edit.newsId + i + 1;
                        this.newsDetailQueryAll.datas.some((newsDetail) => {
                            if (that.langTypes[i].id == newsDetail.lang) {
                                if (that.langTypes[i].id == this.editLang) {
                                    that.edit.newsDetailId = newsDetail.newsDetailId;
                                    that.edit.detailTopTitle = newsDetail.title;
                                    that.edit.detailTopInfo = newsDetail.info;
                                    that.edit.introduction = newsDetail.introduction;
                                    that.edit.keyword = newsDetail.keyword;
                                    that.edit.description = newsDetail.description;
                                }
                                if (this.isEdit) {
                                    this.$store.dispatch("classTypes/classTypeQueryAll", {
                                        module: this.moduleId,
                                        isDelete: 0,
                                        isStop: 0,
                                    });
                                }
                                that.detailNewsLang.push({
                                    newsDetailId: newsDetail.newsDetailId,
                                    lang: newsDetail.lang,
                                    title: newsDetail.title,
                                    info: newsDetail.info,
                                });
                                findLang = true;
                                return true;
                            }
                        });

                        if (!findLang) {
                            that.detailNewsLang.push({
                                newsDetailId: Id,
                                lang: that.langTypes[i].id,
                                title: "",
                                info: "",
                            });
                        }
                    }
                    this.GetImgList();
                }
            },
            response(value) {
                if (value.status == "1") {
                    this.$eventBus.$emit("showAlert", "儲存成功!!");
                     this.isEdit = true;
                    if (!this.isPreview)
                         this.$router.push({
                            name: "smmAnnouncementList",
                            params: {
                                searchData: this.$route.params.searchData,
                            },
                        });
                }else if (value.status == "0") {
                    this.$eventBus.$emit("showAlert", value.message);
                } else{
                    this.$eventBus.$emit("showAlert", "儲存失敗!!");
                }
                   
                if (this.isPreview) {
                    window.open(`${process.env.VUE_APP_Xac_PATH}/News/${this.edit.newsId}?preview=1`);
                }
            },
            imgUrlList() {
                this.imgList = [];
                this.imgList = this.imgUrlList;
            },
            fileUrlList() {
                this.fileList = [];
                this.fileList = this.fileList;
            },

        },
        methods: {
            //取得資料
            GetAnnouncement() {
                console.log(this.$route.params.id)
                this.$store.dispatch("smm/channelQuery",  {   頻道管理編號: this.$route.params.id } ).then((res) => {
                   this.edit=res.result;
                });
            },
            async getchannelMan() {
                this.$store.dispatch("smm/channelMan", {
                    projectId: 'omp_smm',                
                });
            },
         
            GetAllLang() {
                this.$store.dispatch("module/moduleQueryAll", {});
            },
            addInputFields() {
                this.edit.玩家資訊.push(['', '']);
            },
            removeInputFields(index) {
                this.edit.玩家資訊.splice(index, 1);
            },
            //儲存該語系資料
            SetLangData(lang) {
                for (var i = 0; i < this.langTypes.length; i++) {
                    if (this.langTypes[i].id == lang) {
                        this.detailNewsLang[i].title = this.edit.detailTopTitle;
                        this.detailNewsLang[i].info = this.edit.detailTopInfo;
                        this.detailNewsLang[i].introduction = this.edit.introduction;
                        this.detailNewsLang[i].keyword = this.edit.keyword;
                        this.detailNewsLang[i].description = this.edit.description;
                    }
                }
            },

            //檢查Session
            checkSession() {
                if (this.timer) clearInterval(this.timer);
                this.timer = setInterval(() => {
                    this.$store.dispatch("checkSession");
                }, 600000);
            },

            //*---- 返回
            backList() {
                this.$router.push({
                    name: "smmAnnouncementList",
                    params: {
                        searchData: this.$route.params.searchData,
                    },
                });
            },
            //送出
            async Save() {
                // this.isPreview = isPreview;
                // this.SetLangData(this.editLang);

                this.edit.isStop = parseInt(this.edit.isStop);

                if (this.edit.頻道連結 == "") {
                    this.$eventBus.$emit("showAlert", "頻道連結不能為空");
                    return;
                }
                if (this.edit.巡邏人 == "") {
                    this.$eventBus.$emit("showAlert", "請選擇巡邏人");
                    return;
                }
                                
                var 玩家資訊=[];
                if(this.edit.玩家資訊){
                    const isInvalid = this.edit.玩家資訊.some(player => {
                        return player.版權使用規範簡訊狀態 === null || player.版權使用規範簡訊狀態 === undefined ;
                    });
                    if (isInvalid) {
                        this.$eventBus.$emit("showAlert", "版權使用規範簡訊狀態不能為空");
                        return;
                    }
                    玩家資訊 = this.edit.玩家資訊.map(player => ({
                            "玩家角色名稱": player.玩家角色名稱 || "",
                            "玩家角色電話": player.玩家角色電話 || "",
                            "版權使用規範簡訊狀態": parseInt(player.版權使用規範簡訊狀態, 10) 
                    }));
                }
            

                if (this.isEdit) {     

                    this.$store.dispatch("smm/channelUpdate", {
                        頻道管理編號: this.edit.頻道管理編號,
                        頻道連結: this.edit.頻道連結,
                        巡邏人: this.edit.巡邏人,
                        留言板狀態: parseInt(this.edit.留言板狀態,10), //0:關閉 1:開放 2:部分關閉
                        影片下架數: parseInt(this.edit.影片下架數,10),
                        備註: this.edit.備註,
                        檢索狀態: this.edit.檢索狀態,
                        玩家資訊: 玩家資訊        
                    });

                } else {
                    this.$store.dispatch("smm/channelCreate", {
                        頻道連結: this.edit.頻道連結,
                        巡邏人: this.edit.巡邏人,
                        留言板狀態: parseInt(this.edit.留言板狀態,10), //0:關閉 1:開放 2:部分關閉
                        影片下架數: parseInt(this.edit.影片下架數,10) || 0,
                        備註: this.edit.備註,
                        檢索狀態: this.edit.檢索狀態,
                        玩家資訊: 玩家資訊        
                    });
                   
                }


            },
      
        },
        beforeRouteLeave(to, from, next) {
            if (this.timer) clearInterval(this.timer);
            next();
        },
    };

</script>
