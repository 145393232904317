<template>
    <div>
        <section class="content-header">
            <h1>白名單</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">白名單</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-header">
                    <div class="input-group-for-multiselect-wrap">
                        <span style="white-space:nowrap">廠商：</span>
                        <multiselect class="multiselect-width" placeholder="請選取廠商" v-model="searchData.廠商"
                            :options="廠商Opts" label="名稱" track-by="廠商代碼" :show-labels="false" :allow-empty="false">
                        </multiselect>
                    </div>
                    <div class="input-group">
                        <label>類別：
                            <select class="form-control" style="float:none;" v-model="searchData.型態">
                                <!-- <option :value="-1">全部</option> -->
                                <option v-for="(value, k) in 型態Enum" :value="value" :key="value">{{ k }}</option>
                            </select>
                        </label>
                    </div>
                    <label class="input-group">
                        <span class="input-group-span">識別值：</span>
                        <input class="form-control" type="text" placeholder="請輸入 識別值" v-model.trim="searchData.識別值" />
                    </label>
                    <div class="input-group">
                        <button class=" btn btn-primary" @click="search()">
                            <i class="fa fa-search"></i>
                            查詢
                        </button>
                    </div>
                    <div class="input-group" v-if="editPermission">
                        <button class=" btn btn-warning" @click="openAddPopForm()"><i class="fa fa-plus"></i>
                            新增 </button>
                    </div>
                    <div class="input-group" v-if="editPermission">
                        <button class=" btn btn-info" @click="openBatchForm()">
                            批次匯入 </button>
                    </div>
                </div>
                <div class="box-body">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr class="bg-gray">
                                <th>建立日期</th>
                                <th>廠商</th>
                                <th>類別</th>
                                <th>識別值</th>
                                <th>原因</th>
                                <th>風控來源</th>
                                <th>驗證狀態</th>
                                <th>備註</th>
                                <th>功能</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of list" :key="item.型態 + '-' + item.廠商編號 + '-' + item.識別值">
                                <td>{{ item.建立時間 }}</td>
                                <td>{{ item.廠商 }}</td>
                                <td>{{ item.型態 }}</td>
                                <td>{{ item.識別值 }}</td>
                                <td>{{ item.原因 }}</td>
                                <td :class="item.風控來源 == 'MID' ? 'text-yellow' : ''">{{ item.風控來源 }}</td>
                                <td>
                                    <span v-show="item.驗證狀態 == '無使用'" class="">{{ item.驗證狀態 }}</span>
                                    <span v-show="item.驗證狀態 == 'MID驗證'" class="text-light-blue">{{ item.驗證狀態 }}</span>
                                    <span v-show="item.驗證狀態 == '免驗證'" class="text-green">{{ item.驗證狀態 }}</span>
                                </td>
                                <td>{{ item.備註 }}</td>
                                <td>
                                    <!-- 無使用 -->
                                    <template v-if="item.風控來源 == 'MID' && item.驗證狀態 == 'MID驗證'"><span
                                            class="text-red">解除</span>請到OMP-MID實名認證，重置驗證</template>
                                    <template v-else-if="item.風控來源 == 'MID' && item.驗證狀態 == '免驗證'"><span
                                            class="text-red">解除</span>請 廠商後台\人員，移除免驗證</template>
                                    <template v-else>
                                        <button class="btn btn-danger" @click="lift(item)" v-if="delPermission">解除
                                        </button>
                                    </template>
                                </td>
                            </tr>
                            <tr v-show="list.length == 0">
                                <td colspan="7" style="text-align: center;">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="overlay" ref="loading"><i class="fa fa-refresh fa-spin"></i></div>
            </div>
        </section>

        <PopForm ref="addForm" class="popform">
            <template v-slot:slot-title>
                新增白名單
            </template>
            <template v-slot:slot-body>
                <span style="white-space:nowrap"><span class="text-red">*</span>廠商：</span>
                <div class="input-group-for-multiselect-wrap" style="padding-right: 0;">
                    <multiselect placeholder="請選取廠商" v-model="edit.廠商" :options="廠商List" label="名稱" track-by="廠商代碼"
                        :show-labels="false" :allow-empty="false" :maxHeight="200">
                    </multiselect>
                </div>
                <label class="form-group"><span class="text-red">*</span>類別：
                    <select class="form-control" v-model="edit.型態">
                        <option v-for="(value, k) in 型態Enum" :value="value" :key="value">{{ k }}</option>
                    </select>
                </label>
                <label class="form-group"><span class="text-red">*</span>識別值：
                    <input class="form-control" type="text" placeholder="請輸入 識別值" v-model.trim="edit.識別值" />
                </label>
                <label class="form-group">原因：
                    <textarea class="form-control" placeholder="請輸入 原因" v-model.trim="edit.原因" />
                </label>
                <label class="form-group">備註：
                    <textarea class="form-control" placeholder="請輸入 備註" v-model.trim="edit.備註" />
                </label>
            </template>
            <template v-slot:slot-footer>
                <button class="btn" @click="$refs.addForm.hide();">關閉</button>
                <button class="btn btn-primary" @click="addEditData()">新增</button>
            </template>
        </PopForm>
        <PopForm ref="batchForm" class="popform">
            <template v-slot:slot-title>
                批次匯入白名單
            </template>
            <template v-slot:slot-body>
                <span style="white-space:nowrap"><span class="text-red">*</span>廠商：</span>
                <div class="input-group-for-multiselect-wrap" style="padding-right: 0;">
                    <multiselect placeholder="請選取廠商" v-model="batch.廠商" :options="廠商List" label="名稱" track-by="廠商代碼"
                        :show-labels="false" :allow-empty="false" :maxHeight="200">
                    </multiselect>
                </div>
                <label class="form-group"><span class="text-red">*</span>類別：
                    <select class="form-control" v-model="batch.型態">
                        <option v-for="(value, k) in 型態Enum" :value="k" :key="k">{{ k }}</option>
                    </select>
                </label>
                <label class="form-group">原因：
                    <textarea class="form-control" placeholder="請輸入 原因" v-model.trim="batch.原因" />
                </label>
                <label class="form-group">備註：
                    <textarea class="form-control" placeholder="請輸入 備註" v-model.trim="batch.備註" />
                </label>
                <label class="form-group"><span class="text-red">*</span>匯入檔案：
                    <input type="file" accept=".txt" ref="batchData">
                </label>
                <span>限用<span class="text-red">txt</span>文字檔，請使用<span class="text-red">換行</span>區別不同識別值</span>

                <!-- Yoe 匯入格式不一樣, -->
                <div class="callout callout-danger" style='padding: 8px 22px 8px 9px;' v-if="batch.廠商.廠商代碼 == 'Yoe'">
                    <p style='font-size: 16px; margin-bottom: 0px;'>注意！ Yoe 匯入格式變動！</p>
                    <p>
                        匯入格式請改使用 平台識別值(Y000000000) 格式匯入<br/>
                        範例: Y000195471
                    </p>
                </div>
                
            </template>
            <template v-slot:slot-footer>
                <button class="btn" @click="$refs.batchForm.hide();">關閉</button>
                <button class="btn btn-primary" @click="batchData()">匯入</button>
            </template>
        </PopForm>
    </div>
</template>

<script>
import FileReaderPromise from "@/assets/js/Tool/FileReaderPromise.js";
import { parseStrToAry } from "@/assets/js/Tool/parseStr.js";
import api from "@/assets/js/app-api";
import TransEnumMixins from '@/assets/js/Tool/TransEnumMixins'
import PopForm from "@/views/Template/PopFormTemplate";
import { APILib } from '@/views/projects/ips/IPSTool2';
import Multiselect from "vue-multiselect";

let 型態Enum = {
    帳號: "帳號",
    IP: "IP",
}
function wait(sec) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res();
        }, sec * 1000);
    })
}
export default {
    mixins: [TransEnumMixins],
    components: {
        PopForm,
        Multiselect
    },
    data() {
        return {
            型態Enum,
            searchData: {
                廠商: "",
                型態: 型態Enum.帳號,
                識別值: "",
            },
            廠商List: [],
            廠商Opts: [],
            list: [],
            edit: {
                廠商: "",
                型態: 型態Enum.帳號,
                識別值: "",
                原因: "",
                備註: "",
            },
            batch: {
                廠商: "",
                型態: 型態Enum.帳號,
                原因: "",
                備註: "",
            }
        };
    },
    computed: {
        editPermission() {
            return this.$user.hasPermission("API_SetPaymentLimiteWhite");
        },
        delPermission() {
            return this.$user.hasPermission("API_DelPaymentLimiteWhite");
        },
    },
    watch: {
        廠商List: {
            handler(v) {
                this.廠商Opts = [];
                // this.廠商Opts.unshift({
                //     廠商代碼: "-1",
                //     廠商編號: "-1",
                //     顯示名稱: "全部"
                // });
                for (let i of v) {
                    this.廠商Opts.push(i);
                }
                this.searchData.廠商 = this.廠商Opts[0]
            },
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // this.廠商List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
            {
                this.廠商List = [];
                let tmp廠商List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                for (let i of tmp廠商List) {
                    // if (i.廠商編號 != "10001") {
                    //     continue;
                    // }
                    this.廠商List.push(i);
                }
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async search() {
            $(this.$refs.loading).show();
            try {
                if (!this.searchData.廠商.廠商編號) {
                    throw "請選取廠商";
                }
                //if (!this.searchData.識別值) {
                //    throw "請輸入識別值";
                //}
                await this._search({
                    型態: this.searchData.型態,//"帳號",
                    廠商編號: this.searchData.廠商.廠商編號,//"10001",
                    識別值: this.searchData.識別值//"jontest"
                });
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async _search(cmd) {
            let res = await api.IPSSetting.risk.GetPaymentLimiteWhite(cmd);
            if (res && res.data && res.data.status == 1) {
                this.list = [];
                //因為只能單一廠商查詢，這裡就不放在迴圈裡每一筆去跑了
                let 廠商 = this.廠商List.find((item) => { return item.廠商編號 == cmd.廠商編號 })
                let 廠商名稱 = 廠商 ? 廠商.名稱 : cmd.廠商編號;
                //因為只能單一廠商查詢，這裡就不放在迴圈裡每一筆去跑了 End
                if (res.data.content) {
                    for (let i of res.data.content) {
                        this.$set(i, '廠商', 廠商名稱);
                        this.$set(i, '廠商編號', cmd.廠商編號);
                        this.list.push(i);
                    }
                }
            } else {
                let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                throw errMsg;
            }
        },
        openAddPopForm() {
            this.edit.廠商 = "";
            this.edit.型態 = 型態Enum.帳號;
            this.edit.識別值 = "";
            this.edit.原因 = "";
            this.edit.備註 = "";
            this.$refs.addForm.show();
        },
        openBatchForm() {
            this.batch.廠商 = "";
            this.batch.型態 = 型態Enum.帳號;
            this.$refs.batchData.value = "";
            this.$refs.batchForm.show();
        },
        async lift(item) {
            this.$eventBus.$emit("showAsyncConfirm", "確定解除白名單？", "解除", async () => {
                $(this.$refs.loading).show();
                let cmd = {
                    廠商編號: item.廠商編號,
                    target: {
                        型態: item.型態,//"帳號",
                        識別值: item.識別值,//"jontest"
                        風控來源: item.風控來源,
                    }
                };
                try {
                    let res = await api.IPSSetting.risk.DelPaymentLimiteWhite(cmd);
                    if (res && res.data && res.data.status == 1) {
                        this.$eventBus.$emit("showAlert", "刪除成功");
                        this.list = [];
                    } else {
                        let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                        throw errMsg;
                    }
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                $(this.$refs.loading).fadeOut(400);
            });
        },
        async addEditData() {
            if (!this.edit.廠商.廠商編號) {
                this.$eventBus.$emit("showAlert", "請選取廠商");
                return;
            }
            if (!this.edit.識別值) {
                this.$eventBus.$emit("showAlert", "請輸入識別值");
                return;
            }

            $(this.$refs.loading).show();
            let cmd = {
                廠商編號: this.edit.廠商.廠商編號,//"10001",
                target: [{
                    型態: this.edit.型態,//"帳號"
                    識別值: this.edit.識別值,//"jontest",
                    原因: this.edit.原因,//"中強手動測試 - 原因",
                    備註: this.edit.備註,//"中強手動測試 - 備註"
                    風控來源: "OMP",
                    驗證狀態: "無使用"
                }]
            };
            try {
                let res = await api.IPSSetting.risk.SetPaymentLimiteWhite(cmd);
                if (res && res.data && res.data.status == 1) {
                    this.$eventBus.$emit("showAlert", "新增成功");
                    this.list = [];
                } else {
                    let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                    throw errMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
            this.$refs.addForm.hide();
        },
        async batchData() {

            if (!this.batch.廠商.廠商編號) {
                this.$eventBus.$emit("showAlert", "請選取廠商");
                return;
            }
            if (!this.$refs.batchData.value) {
                this.$eventBus.$emit("showAlert", "請選取檔案");
                return;
            }

            this.$refs.batchForm.loading(true);
            try {
                let analyzeRes = await FileReaderPromise(this.$refs.batchData, {
                    fileExtension: ['txt'],
                    analyze: function (res) {
                        return parseStrToAry(res);
                    }
                });
                if (analyzeRes.analyzeData.length <= 0) {
                    this.$refs.batchData.value = "";
                    this.$refs.batchForm.loading(false);
                    this.$eventBus.$emit("showAlert", "文字內容解析筆數為0，請重新選取文字檔");
                    return;
                }

                //分批
                let 每批筆數 = 200;
                while (analyzeRes.analyzeData.length > 0) {
                    let batchData = analyzeRes.analyzeData.splice(0, 每批筆數);
                    let cmd = {
                        廠商編號: this.batch.廠商.廠商編號,//"10001",
                        target: []
                    };
                    for (let i of batchData) {
                        cmd.target.push({
                            型態: this.batch.型態,//"帳號"
                            識別值: i,//"jontest",
                            原因: this.batch.原因,//"中強手動測試 - 原因",
                            備註: this.batch.備註,//"中強手動測試 - 備註"
                            風控來源: "OMP",
                            驗證狀態: "無使用"
                        });
                    }
                    let res = await api.IPSSetting.risk.SetPaymentLimiteWhite(cmd);
                    if (res && res.data && res.data.status == 1) {
                        //this.$eventBus.$emit("showAlert", "新增成功");
                    } else {
                        let serverMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "";
                        let msg = `批次執行識別值為 ${cmd.target[0].識別值} ~ ${cmd.target[cmd.target.length - 1].識別值} 間出錯，請檢查這區間的檔案`;
                        throw `${msg}，server回傳：${serverMsg}`;
                    }
                    await wait(0.4);
                }
                throw "新增成功";
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            this.list = [];
            this.$refs.batchData.value = "";
            this.$refs.batchForm.hide();
            this.$refs.batchForm.loading(false);
        },
    },
};
</script>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.popform>>>.modalWidth {
    width: 400px;
}

.multiselect-width {
    width: 250px;
}
</style>