<template>
  <div>
    <div class="g-info"  @click="goContent(item, 'slotClientGameHallList')" style="cursor: pointer;">
      <div class="g-photo">
        <img :src="item.圖示" alt="">
      </div>
      <div class="text">
        <div class="g-name">{{ item.遊戲名稱 }}</div>
        <div>
          <p class="g-company">{{ item.開發商名稱 }}</p>
          <p class="g-time" v-if="$parent.searchData.排序條件 == 1">{{ item.更新時間 | dateTimeFormat }}更新</p>
          <p class="g-time" v-if="$parent.searchData.排序條件 == 2">{{ item.建立時間 | dateTimeFormat }}建立</p>
        </div>
      </div>
    </div>
    <div class="g-link">
      <div class="left">
        <a :href="item.android網址" v-if="item.android網址 != ''" target="_blank" class="btn btn-mobile mr-5">Android</a>
        <a :href="item.ioS網址" v-if="item.ioS網址 != ''" target="_blank" class="btn btn-mobile">iOS</a>
      </div>
      <div class="right">
        <a href="javaScript:;" title="遊戲介紹" @click="goContent(item, 'slotClientGameHallList')">
          <i class="fa fa-gamepad"></i>
        </a>
        <a href="javaScript:;" title="活動機制" @click="goContent(item, 'slotClientGameActivity')">
          <i class="fa fa-gears"></i>
        </a>
        <a href="javaScript:;" title="集成分析" @click="goContent(item, 'slotAnalyzeList')">
          <i class="fa fa-chart-pie"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props:['item'],
  methods: {
    goContent(item, page) {
      if(this.$route.name == 'slotSearch') {
        this.$parent.goContent(item);
      } else {
        this.$router.push({
          name: page,
          params: {
            id: item.編號,
            backSearchData: this.searchData
          }
        });
      }
    },
    showPlay(id) {
      if(id) this.$refs.slotPlayContent.getData(id);
      else this.$refs.slotPlayContent.showContent = true;
    },
    imgSize(index) {
      let h = $('#img_' + index).height();
      let w = $('#img_' + index).width();
      if(h > w) return 'height: 100%;'
    },
  },
};
</script>