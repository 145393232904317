<template>
  <div>
    <FormDialog ref="moderateDialog" :title="player ? $t(unban ? '玩家解鎖' : '玩家控管') : $t('執行大量停權')" @submit="submit">
      <div class="table-responsive mt-0">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>{{ $t((unban ? '解鎖' : '控管') + '對象') }}</th>
              <td v-if="player">{{ data.暱稱 }}</td>
              <!-- <td v-else>
                <button type="button" class="btn btn-primary fa fa-file-alt mt-0" @click="importSN()">
                  匯入名單
                </button>
                <span class="ml-10"> {{ importFileName }} </span>
                <div v-if="pagedTable.length > 0">
                  <table class="table table-bordered mt-10 mb-10">
                    <thead>
                      <tr class="bg-gray-light">
                        <th colspan="10" style="padding-block: .25em;">玩家名單</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="row in pagedTable">
                        <td v-for="col in row" style="padding:.1em .5em;width:25%">{{ col }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center" v-show="pageTotal > 1">
                    <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal"
                      v-on:pageMethod="onPageChange" />
                  </div>
                </div>
              </td> -->
            </tr>
            <tr v-if="player">
              <th>{{$t('綁定門號')}}</th>
              <td>{{ player.綁定門號 ? player.綁定門號 : "-" }}</td>
            </tr>
            <tr>
              <th>{{ $t((unban ? '解鎖' : '控管') + '類型' )}}</th>
              <td v-if="unban">
                <iCheckRedioButton v-if="!unban || (player && player.停權狀態 != 0)" v-model.number="data.動作" checkValue="0" :message="$t('停權')" name="blockType" class="radio-inline" />
                <iCheckRedioButton v-if="!unban || (player && player.禁言狀態 != 0)" v-model.number="data.動作" checkValue="1" :message="$t('禁言')" name="blockType" class="radio-inline" :class="{'ml-25': player.停權狀態 != 0}" />
              </td>
              <td v-else>
                <iCheckRedioButton  v-model.number="data.動作" checkValue="0" :message="$t('停權')" name="blockType" class="radio-inline" />
                <iCheckRedioButton v-model.number="data.動作" checkValue="1" :message="$t('禁言')" name="blockType" class="radio-inline ml-25" />
              </td>
            </tr>
            <tr>
              <th>{{ $t(unban ? '解鎖' : '期限') }}</th>
              <td v-if="unban">
                <iCheckRedioButton v-model="data.解鎖" :checkValue="true" :message="$t('立即解鎖')" name="unban" class="radio-inline" />
              </td>
              <td v-else>
                <div v-if="data.動作 == 0" :key="0">
                  <div class="radio-group">
                    <iCheckRedioButton v-model.number="data.類型" checkValue="1" message="" name="timeType" class="radio radioCenter" />
                    <input type="number" class="form-control" v-model.number="data.期限" min="1">
                    <span>{{$t('天')}}</span>
                  </div>
                  <!-- <iCheckRedioButton v-model.number="data.類型" checkValue="0" :message="'3 ' + $t('天')" name="timeType" class="radio radioCenter" /> -->
                  <iCheckRedioButton v-model.number="data.類型" checkValue="3" :message="$t('暫時凍結')" name="timeType" class="radio radioCenter" />
                  <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('終止契約')" name="timeType" class="radio radioCenter" />
                </div>
                <div v-else="data.動作==1" :key="1">
                  <div class="radio-group">
                    <iCheckRedioButton v-model.number="data.類型" checkValue="1" message="" name="timeType" class="radio radioCenter" />
                    <input type="number" class="form-control" v-model.number="data.期限" min="1">
                    <span>{{$t('分鐘')}}</span>
                  </div>
                  <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('永久禁言')" name="timeType" class="radio radioCenter" />
                </div>
              </td>
            </tr>
            <tr>
              <th>{{$t('服務類型')}}</th>
              <td>
                <select v-model="data.分類" class="form-control" required>
                  <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                  <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
                </select>
              </td>
            </tr>
            <tr v-if="!unban">
              <th>{{$t('發送簡訊')}}</th>
              <td>
                <iCheckRedioButton v-model="data.發簡訊" :checkValue="true" :message="$t('是')" name="sms" class="radio-inline" />
                <iCheckRedioButton v-model="data.發簡訊" :checkValue="false" :message="$t('否')" name="sms" class="radio-inline ml-25" />
              </td>
            </tr>
            <tr>
              <th>{{$t('原因')}}</th>
              <td>
                <textarea class="form-control" type="text" v-model.trim="data.原因"
                  :placeholder="$t('必填')" rows="3" required></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <template v-slot:footer>
        <button type="submit" class="btn btn-primary">確認送出</button>
        <button type="button" class="btn btn-default pull-right" data-dismiss="modal">&emsp;取消&emsp;</button>
      </template> -->
      
      <!-- <pre>{{ data }}</pre> -->
    </FormDialog>
    <ImportData ref="ImportData" title="玩家" :checks="checks" @importData="importData" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImportData from "@/views/projects/NMJ/ImportData";
import Pagination from "@/components/Pagination";
import FormDialog from "./FormDialog.vue";

export default {
  components: {
    DatePicker,
    iCheckRedioButton,
    ImportData,
    Pagination,
    FormDialog,
  },
  props: ['types'],
  data() {
    return {
      player: null,
      unban: false,
      tab: 1,
      // playerList: "",
      data: {
        暱稱: "", // 大量停權玩家暱稱array帶入多筆暱稱
        動作: 0, // 0:停權 1:禁言
        類型: 1, // 1:暫時禁言 2:永久禁言
        原因: "",
        分類: "", // 問題類型
        解鎖: false,
        發簡訊: false,
        期限:10 // 動作==1 && 類型==1 的時候需要帶入
      },

      resultData: {},
      filterString: "",
      pageNum: 1,
      pageSize: 5,
      pageTotal: 0,

      importFileName: "",
      importList: [],
      checks: ["玩家暱稱"],
    };
  },
  watch: {
    "data.動作": function(value) { this.update類型(); },
    "filterString": function () { this.pageNum = 1 }
  },
  computed: {
    // "playerTable": function () {
    //   let search = ""; //this.filterString.trim();
    //   let re = new RegExp(search, "i");
    //   let list = search == '' ?
    //     this.data.玩家暱稱 :
    //     this.data.玩家暱稱.filter(s => re.test(s));

    //   let arr = [];
    //   for (let i = 0, len = list.length; i < len;) {
    //     let row = [];
    //     for (let j = 0; j < 3; j++) {
    //       row.push(list[i++])
    //     }
    //     arr.push(row);
    //   }
    //   this.pageTotal = arr.length;
    //   return arr;
    // },
    // "pagedTable": function () {
    //   return this.playerTable.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    // },
    // "resultTable": function () {
    //   let search = this.filterString.trim();
    //   let re = new RegExp(search, "i");
    //   let list = search == '' ?
    //     Object.keys(this.resultData) :
    //     Object.keys(this.resultData).filter(s => re.test(s));
    //   let arr = [];
    //   for (let i = 0, len = list.length; i < len; i += 2) {
    //     arr.push({
    //       name1: list[i],
    //       status1: 1 - this.resultData[list[i]],
    //       name2: list[i + 1] || null,
    //       status2: 1 - this.resultData[list[i + 1]] || null,
    //     });
    //   }
    //   this.pageSize = 10;
    //   this.pageTotal = arr.length;
    //   return arr;
    // },
    // "resultPaged": function () {
    //   return this.resultTable.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    // },
    // "resultTotal": function () {
    //   return Object.values(this.resultData).length;
    // },
    // "resultSuccess": function () {
    //   return Object.values(this.resultData).reduce((a, i) => a + (1 - i), 0);
    // },
  },
  methods: {
    async show(player, unban) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player || null;
      this.unban = unban || false;
      this.data.解鎖 = this.unban;
      if (this.player) {
        this.data.暱稱 = this.player.暱稱;
        if (this.unban && this.player.停權狀態 == 0){
          this.data.動作 = 1;
        } else {
          this.data.動作 = 0;
        }
        this.update類型();
      }
      this.$refs.moderateDialog.show();
    },
    async showResult(resultData) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.tab = 2;
      this.resultData = resultData;
      this.$refs.moderateDialog.show();
    },
    // async onPageChange(pageIdx, pageSize) {
    //   this.pageNum = pageIdx;
    //   this.pageSize = pageSize;
    // },
    update類型(){
      if (this.data.動作 == 0){
        this.data.類型 = this.unban ? this.player.停權狀態 : 1;
      } else {
        this.data.類型 = this.unban ? this.player.禁言狀態 : 1;
      }
      console.log("動作", this.data.動作, "類型", this.data.類型)
    },
    importSN() {
      this.$refs.ImportData.showSerialNumberEdit();
    },
    importData(data, fileName) {
      console.log(data)
      this.data.玩家暱稱 = data.map(p => p.玩家暱稱);
      this.importFileName = fileName;
    },
    // exportOrders() {
    //   this.$store.dispatch("nmjPlayerData/exportControl", this.resultData);
    // },
    submit() {
      this.$store.dispatch("xsw/會員控管", this.data).then(() => {
        

        this.$refs.moderateDialog.hide();
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.radio-group>input {
  margin: 0 5px;
  max-width: 100px;
}

.radio-group>span {
  white-space: nowrap;
}
</style>
