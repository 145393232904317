<template>
  <div id="NewtalkAnnouncementDetailList" class="normal">
    <section class="content-header">
      <h1>留言與回覆列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/Newtalk/AnnouncementList">
            討論區公告管理
          </router-link>
        </li>
        <li class="active">留言與回覆列表</li>
      </ol>
    </section>
    <section class="content forum-announcement">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 話題列表
            </button>
            <div class="form-group">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="detailSearchData.endTime"
                  :propStartDate.sync="detailSearchData.startTime"
                />
              </div>
            </div>
            <div class="select-group">
              <input
                class="form-control"
                type="text"
                placeholder="發佈者搜尋"
                v-model.trim="detailSearchData.authorUid"
              />
            </div>
            <div class="select-group" v-if="detailSearchData.articleType !== 1">
              <input
                class="form-control"
                type="text"
                placeholder="暱稱搜尋"
                v-model.trim="detailSearchData.nickName"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋關鍵字"
                v-model.trim="detailSearchData.contentKey"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
            <div class="btns">
              <button
                @click="deleteAll()"
                class="btn btn-block btn-danger btn-cog mr-10"
                type="button"
              >
                <i class="fa fa-trash"></i> 批次刪除
              </button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered"
              v-if="detailSearchData.articleType === 3"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 100px">ID</th>
                  <th style="min-width: 60px">樓層</th>
                  <th style="min-width: 200px">內容</th>
                  <th style="min-width: 100px">發佈日期</th>
                  <th style="min-width: 100px">發佈者</th>
                  <th style="min-width: 100px">暱稱</th>
                  <th style="min-width: 60px">狀態</th>
                  <th style="min-width: 60px">喜歡</th>
                  <th style="min-width: 60px">不喜歡</th>
                  <th style="min-width: 60px">回覆</th>
                  <th style="min-width: 200px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="11">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="!item.isDelete">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-nowrap text-center">{{ item.commentId }}</td>
                  <td class="text-nowrap text-center">
                    {{ item.commentFloor || "-" }}
                  </td>
                  <td>
                    <span
                      :class="{ 'text-red': item.isDelete }"
                      v-html="showText(item, item.commentContent)"
                    ></span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.commentTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap">{{ item.commentAuthor }}</td>
                  <td class="text-nowrap">{{ item.nickName || "-" }}</td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ 'text-red': item.isDelete }"
                  >
                    {{ define.deleteStatus[item.isDelete] }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.likecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.notlikecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    <span
                      class="link"
                      v-if="item.reply_count > 0"
                      @click="updateReply(item)"
                    >
                      {{ item.reply_count }}
                    </span>
                    <span v-else>
                      {{ item.reply_count }}
                    </span>
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary"
                      @click="showContent(item, item.commentId)"
                    >
                      詳細
                    </button>
                    <button
                      class="btn btn-warning ml-5"
                      v-if="!item.isDelete"
                      @click="officialRply(item, 3)"
                    >
                      回覆
                    </button>
                    <button
                      class="btn btn-danger ml-5"
                      v-if="!item.isDelete"
                      @click="deleteItem(item)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-hover table-bordered"
              v-if="detailSearchData.articleType === 4"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 60px">ID</th>
                  <th style="min-width: 80px">樓層</th>
                  <th style="min-width: 200px">內容</th>
                  <th style="min-width: 150px">發佈日期</th>
                  <th style="min-width: 150px">發佈者</th>
                  <th style="min-width: 150px">暱稱</th>
                  <th style="min-width: 60px">狀態</th>
                  <th style="min-width: 60px">喜歡</th>
                  <th style="min-width: 60px">不喜歡</th>
                  <th style="min-width: 200px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="10">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="10">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="!item.isDelete">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-nowrap text-center">{{ item.replyId }}</td>
                  <td class="text-nowrap text-center">
                    {{ item.commentFloor }}-{{ item.ReplyFloor }}
                  </td>
                  <td>
                    <span
                      :class="{ 'text-red': item.isDelete }"
                      v-html="showText(item, item.replyContent)"
                    ></span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.replyTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap">{{ item.author }}</td>
                  <td class="text-nowrap">{{ item.nickName || "-" }}</td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ 'text-red': item.isDelete }"
                  >
                    {{ define.deleteStatus[item.isDelete] }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.likecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.notlikecount }}
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary"
                      @click="showContent(item, item.replyId)"
                    >
                      詳細
                    </button>
                    <button
                      class="btn btn-warning ml-5"
                      v-if="!item.isDelete"
                      @click="officialRply(item, 3)"
                    >
                      回覆
                    </button>
                    <button
                      class="btn btn-danger ml-5"
                      v-if="!item.isDelete"
                      @click="deleteItem(item)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="detailSearchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <forumContent ref="forumContent" :getDataList="getDataList" :page="'announcement'" />
    <announcementAlert
      ref="announcementAlert"
      :actionType="actionType"
      :checkDatas="checkDatas"
      v-on:updateList="getDataList"
    />
    <announcementReplyAlert
      ref="announcementReplyAlert"
      :actionType="actionType"
      v-on:updateList="getDataList"
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import forumContent from "@/views/projects/Newtalk/ForumContent";
import announcementAlert from "@/views/projects/Newtalk/AnnouncementAlert";
import announcementReplyAlert from "@/views/projects/Newtalk/AnnouncementReplyAlert";
import htmlToText from "html-to-formatted-text";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    forumContent,
    announcementAlert,
    announcementReplyAlert,
  },
  data() {
    return {
      loading: true,
      total: 0,
      detailPage: {},
      detailSearchData: {
        keyword: "",
        isDelete: -1,
        page: 1,
        pageSize: 20,
        categoryNo: 1, // 1: 討論區
        orderType: 0, // 0：時間順排，2：時間逆排
        articleType: Number(this.$route.params.type),
        authorUid: "",
        contentKey: "",
        nickName: "",
        startTime: new moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      id: this.$route.params.id,
      datas: [],
      actionType: "",
      checkDatas: [],
      isCheckAll: false,
      checkIds: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.detailSearchData.articleType = Number(to.params.type);
    this.detailSearchData.page =
      this.detailPage[this.detailSearchData.articleType] || 1;
    this.getDataList();
    next();
  },
  mounted() {
    this.detailSearchData =
      this.$route.params.detailSearchData || this.detailSearchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.clearCheckAll();
      if (this.id) {
        this.actionType =
          this.detailSearchData.articleType === 4 ? "reply" : "comment";
        if (this.detailSearchData.articleType === 4)
          this.detailSearchData.commentId = this.id;
        else this.detailSearchData.articleId = this.id;
        this.$store
          .dispatch(`newtalk/${this.actionType}QueryAll`, this.detailSearchData)
          .then((result) => {
            this.datas =
              result.articleList ||
              result.commentDetailList ||
              result.replyDetailList ||
              [];
            this.total = result.totalCount;
            this.loading = false;
            this.$refs.pagination.setPage(
              this.detailSearchData.page,
              this.total
            );
          });
      } else {
        this.loading = false;
      }
    },
    resetDataList() {
      this.detailPage[this.detailSearchData.articleType] = 1;
      this.detailSearchData.page = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.detailPage[this.detailSearchData.articleType] = pageIdx;
      this.detailSearchData.page = pageIdx;
      this.detailSearchData.pageSize = pageSize;
      this.getDataList();
    },
    showContent(item, targetId) {
      item.articleType = this.detailSearchData.articleType;
      this.$refs.forumContent.showForumContent(item, targetId);
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.datas.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    checkChange(data) {
      const id =
        data.value.replyId || data.value.commentId || data.value.articleId;
      if (data.checked && !this.checkIds.includes(id)) {
        this.checkIds.push(id);
        this.checkDatas.push(data.value);
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.checkDatas.splice(index, 1);
        }
      }
    },
    clearCheckAll() {
      this.checkIds = [];
      this.checkDatas = [];
      this.checkAll({ checked: 0 });
      this.$forceUpdate();
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.datas.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    officialRply(item, type) {
      this.$refs.announcementReplyAlert.showAlert(item, type);
    },
    deleteAll() {
      if (this.checkIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇刪除對象！");
        return;
      }
      this.$refs.announcementAlert.showAlert();
    },
    deleteItem(item) {
      this.$refs.announcementAlert.showAlert(item);
    },
    showText(item, content) {
      if (!item.contentText) {
        if (item.newtalkType === "討論區" && !item.userType && !item.replyId) {
          item.contentText = this.ellipsis(htmlToText(content), 35);
        } else if (content.startsWith("<p>")) {
          item.contentText = this.ellipsis(htmlToText(content), 35);
        } else {
          item.contentText = this.ellipsis(content, 35);
        }
      }
      return item.contentText;
    },
    ellipsis(value, limit) {
      value = value || "";
      limit = limit || value.length;
      return value.length > limit ? `${value.substring(0, limit)}...` : value;
    },
    updateReply(item) {
      this.detailSearchData.articleId = item.articleId;
      this.$router.push({
        name: "NewtalkAnnouncementDetailList",
        params: {
          type: 4,
          id: item ? item.commentId : null,
          detailSearchData: this.detailSearchData,
        },
      });
    },
    backList() {
      const detail =
        this.$route.params.detailSearchData &&
        this.detailSearchData.articleType === 4
          ? "Detail"
          : "";
      this.$router.push({
        name: `NewtalkAnnouncement${detail}List`,
        params: {
          type: 3,
          id: this.detailSearchData.articleId,
          searchData: this.$route.params.searchData,
          detailSearchData: this.$route.params.detailSearchData,
        },
      });
    },
  },
};
</script>
