// import Vue from 'vue'; 沒使用到
import store from '@/store.js';

class User {
    constructor() {
        this.isInstalled = false;
    }
    install(Vue, options) {
        if (this.isInstalled) return;
        this.isInstalled = true;
        Vue.prototype.$user = this;
    }
    hasPermission(permissions) {
        let isCheck = false;
        try {
            let projectId = store.state.projectId;
            let loginUserMenu = store.state.loginUserMenu;
            let userPermissionList = loginUserMenu.filter(
                project => project.projectId == projectId
            )[0].permissions;
            isCheck = permissions.split(",").some(element => userPermissionList.indexOf(element) >= 0);
        } catch (e) {
            // console.log("檢查權限出錯：", e);
            return false;
        }
        return isCheck;
    }
    getUerData() {
        return store.state.loginUser;
    }
    getUserCompanyId() {
        let usrData = this.getUerData();
        let companyId = usrData['companyId'];
        return companyId;
    }
}

export default new User();