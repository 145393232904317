<template>
  <div id="NmjGameDataList">
    <section class="content-header">
      <h1>牌局紀錄查詢</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">牌局紀錄查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit="$event.preventDefault(); resetDataList()">
            <div class="form-group">
              <label class="control-label">
                查詢時間：
              </label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propStartDate.sync="searchData.StartTime"
                  :propEndDate.sync="searchData.EndTime"
                  :propTimePicker="true"
                />
              </div>
            </div>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="牌桌ID"
                v-model.trim="searchData.TableID"
              />
            </div>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="玩家暱稱"
                v-model.trim="searchData.PlayerName"
              />
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()" :disabled="datas.length <= 0">
                匯出
              </button>
            </div>
            <button type="button" v-show="Object.keys($route.query).length > 0" @click="$router.go(-1)" class="btn btn-default pull-right fa fa-arrow-left"> 返回</button>
          </form>
          <!-- <pre>{{ datas[0] }}</pre> -->
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 75px; width:2%">牌桌ID</th>
                  <th style="min-width: 60px; width:2%">圈風</th>
                  <th style="min-width: 50px; width:2%">連莊<wbr>數</th>
                  <th style="min-width:105px; width:2%">牌局<wbr>模式</th>
                  <th style="min-width: 60px; width:2%">貨幣<wbr>類別</th>
                  <th style="min-width: 50px; width:2%">底分</th>
                  <th style="min-width: 50px; width:2%">台分</th>
                  <th style="min-width: 90px; width:5%">桌主<wbr>暱稱</th>
                  <th style="min-width: 50px; width:2%">牌局<wbr>結果</th>
                  <th style="min-width: 90px; width:5%">胡牌/自摸<wbr>玩家暱稱</th>
                  <th style="min-width: 90px; width:5%">放槍玩家</th>
                  <th style="min-width: 50px; width:2%">總台<wbr>數</th>
                  <th style="min-width: 75px; width:2%">贏分</th>
                  <th style="min-width: 50px; width:2%">東錢</th>
                  <th style="min-width: 50px; width:2%">剩餘<wbr>張數</th>
                  <th style="min-width: 90px; width:5%">莊家<wbr>暱稱</th>
                  <th style="min-width:280px; width:5%; padding:.25em 0;">
                    <table class="table-player">
                      <tr>
                        <td>風位</td>
                        <td>暱稱</td>
                        <td>被吃分</td>
                        <td>技術分</td>
                      </tr>
                    </table>
                  </th>
                  <th style="min-width:105px; width:5%;">牌局結束時間</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="20">查詢中</td>
                </tr>
                <tr v-else-if="result.message">
                  <td colspan="20">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="20">查詢無任何資料</td>
                </tr>
                <template v-else v-for="(item, index) in pagedData">
                <tr>
                  <td v-text="item.牌桌ID"></td>
                  <td v-text="item.圈風"></td>
                  <td v-text="item.連莊次數"></td>
                  <td v-text="item.牌桌類型"></td>
                  <td v-text="item.籌碼類型"></td>
                  <td v-text="item.底"></td>
                  <td v-text="item.台"></td>
                  <td>
                    <span :class="{mark: item.桌主暱稱 && searchData.PlayerName == item.桌主暱稱}">
                      <span v-if="/^NPC-\d|^$/.test(item.桌主暱稱)">{{ item.桌主暱稱 || '-' }}</span>
                      <router-link :to="{name:'NmjPlayerData', query:{nick:item.贏家暱稱}}" v-else v-text="item.桌主暱稱"></router-link>
                    </span>
                  </td>
                  <td v-text="item.牌局結果"></td>
                  <td>
                    <span :class="{mark: item.贏家暱稱 && searchData.PlayerName == item.贏家暱稱}">
                      <span v-if="/^NPC-\d|^$/.test(item.贏家暱稱)">{{ item.贏家暱稱 || '-' }}</span>
                      <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.贏家暱稱}}" v-text="item.贏家暱稱" />
                    </span>
                  </td>
                  <td>
                    <span :class="{mark: item.放槍暱稱 && searchData.PlayerName == item.放槍暱稱}">
                      <span v-if="/^NPC-\d|^$/.test(item.放槍暱稱)">{{ item.放槍暱稱 || '-' }}</span>
                      <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.放槍暱稱}}" v-text="item.放槍暱稱" />
                    </span>
                  </td>
                  <td v-text="item.總台數"></td>
                  <td v-text="item['籌碼變動(不含東錢)']"></td>
                  <td v-text="item.東錢"></td>
                  <td v-text="item.剩餘張數"></td>
                  <td>
                    <span :class="{mark: item.莊家暱稱 && searchData.PlayerName == item.莊家暱稱}">
                      <span v-if="/^NPC-\d|^$/.test(item.莊家暱稱)">{{ item.莊家暱稱 || '-' }}</span>
                      <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.莊家暱稱}}" v-text="item.莊家暱稱"/>
                    </span>
                  </td>
                  <td style="padding: .25em 0;">
                    <table class="table-player">
                      <tr>
                        <th class="text-right">東風位</th>
                        <td class="text-left" :class="{mark:searchData.PlayerName == item.東風位暱稱}">
                          <span v-if="/^NPC-\d/.test(item.東風位暱稱)">{{ item.東風位暱稱 }}</span>
                          <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.東風位暱稱}}" v-text="item.東風位暱稱"/>
                        </td>
                        <td v-text="item.東風位被吃扣分"></td>
                        <td v-text="item.東風位技術分"></td>
                      </tr>
                      <tr class="">
                        <th class="text-right">南風位</th>
                        <td class="text-left" :class="{mark:searchData.PlayerName == item.南風位暱稱}">
                          <span v-if="/^NPC-\d/.test(item.南風位暱稱)">{{ item.南風位暱稱 }}</span>
                          <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.南風位暱稱}}" v-text="item.南風位暱稱"/>
                        </td>
                        <td v-text="item.南風位被吃扣分"></td>
                        <td v-text="item.南風位技術分"></td>
                      </tr>
                      <tr>
                        <th class="text-right">西風位</th>
                        <td class="text-left" :class="{mark:searchData.PlayerName == item.西風位暱稱}">
                          <span v-if="/^NPC-\d/.test(item.西風位暱稱)">{{ item.西風位暱稱 }}</span>
                          <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.西風位暱稱}}" v-text="item.西風位暱稱"/>
                        </td>
                        <td v-text="item.西風位被吃扣分"></td>
                        <td v-text="item.西風位技術分"></td>
                      </tr>
                      <tr>
                        <th class="text-right">北風位</th>
                        <td class="text-left" :class="{mark:searchData.PlayerName == item.北風位暱稱}">
                          <span v-if="/^NPC-\d/.test(item.北風位暱稱)">{{ item.北風位暱稱 }}</span>
                          <router-link v-else :to="{name:'NmjPlayerData', query:{nick:item.北風位暱稱}}" v-text="item.北風位暱稱"/>
                        </td>
                        <td v-text="item.北風位被吃扣分"></td>
                        <td v-text="item.北風位技術分"></td>
                      </tr>
                    </table>
                  </td>
                  <td v-text="uiDate(item.結束時間)"></td>
                </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  #NmjGameDataList{
    .top-box > .input-group{
      margin-bottom: 10px;
    }
    .table td{
      line-height: 1.2;
    }
    .table .table-player{
      th, td{
        min-width: auto;
        padding: .1em .25em;
        &:nth-child(1), &:nth-child(3), &:nth-child(4){ min-width:4em; width:10%;}
      }

      tr:nth-child(2n){
        background: rgba(0,0,0,.03);
      } 
    }
    .mark{
      padding: 0;
    }
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import Pagination from "@/components/Pagination";


export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        TableID: "", //查詢全部帶-1
        PlayerName: "",
        StartTime: new moment().format("YYYY-MM-DD 00:00:00"),
        EndTime: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
      },
      result: {},
      gameTypes: {},
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.searchData.TableID = this.$route.query.id || "";
    this.searchData.PlayerName = this.$route.query.nick || "";
    this.getDataList();
    // this.getTypes();
  },
  computed:{
    pagedData(){
      let start = (this.searchData.pageNum - 1) * this.searchData.pageSize;
      let end = this.searchData.pageNum * this.searchData.pageSize;
      return this.datas.slice(start, end);
    }
  },
  methods: {
    getTypes(isReload = false){
      this.$store.dispatch("nmjMail/itemList", isReload).then(result => {
        this.gameTypes = result;
        this.gameTypes.Item[0] = "雀幣";
      })
    },
    getDataList() {
      if (!/^-?\d*$/.test(this.searchData.TableID)) {
        this.$eventBus.$emit("showAlert", "牌桌ID格式錯誤！");
        return;
      }

      let search = this.searchData.TableID == "" ? Object.assign({}, this.searchData, {TableID: "-1"}) : this.searchData;
      this.$store
        .dispatch("nmjGameRecord/query", search)
        .then((result) => {
          this.result = result;
          this.datas = result.recordList || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          let query = {};
          if (this.searchData.TableID) query['id'] = this.searchData.TableID;
          if (this.searchData.PlayerName) query['nick'] = this.searchData.PlayerName;
          if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
            this.$router.replace({query:query});
          }
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      // this.getDataList();
    },
    toPlayerData(nick){
      return {
        name:'NmjPlayerData',
        query:{
          nick: nick,
          st: this.searchData.StartTime,
          et: this.searchData.EndTime,
        }
      }
    },
    exportOrders() {
      let search = this.searchData.TableID == "" ? Object.assign({}, this.searchData, {TableID: "-1"}) : this.searchData;
      this.$store.dispatch("nmjGameRecord/export", search);
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>