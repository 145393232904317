<template>
  <div class="modal fade" ref="editSN">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">序號修改</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>使用狀態</td>
                  <td>
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="未使用"
                        name="radioMode"
                        v-model="edit.isUsed"
                      />
                    </div>
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="1"
                        message="已使用"
                        name="radioMode"
                        v-model="edit.isUsed"
                      />
                    </div>
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="2"
                        message="待確認"
                        name="radioMode"
                        v-model="edit.isUsed"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>備註</td>
                  <td>
                    <input class="form-control" required="required" type="text" v-model="edit.note" />
                  </td>
                </tr>
                <tr v-show="edit.isUsed == 1">
                  <td>使用時間</td>
                  <td>
                    <div class="radio-date">
                      <div class="input-group date">
                        <div class="input-group-addon">
                          <i class="fa fa-calendar"></i>
                        </div>
                        <div class="pull-right">
                          <DatePicker />
                        </div>
                      </div>
                      <div class="input-group time">
                        <div class="input-group-addon">
                          <i class="fa fa-clock-o"></i>
                        </div>
                        <input class="form-control timepicker" type="text" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>使用者</td>
                  <td>
                    <input class="form-control" required="required" type="text" v-model="edit.uId" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="UpdateSN()">確定</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">取消</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import DatePicker from "@/components/DatePicker";
import moment from "moment";

export default {
  components: {
    iCheckRedioButton: iCheckRedioButton,
    DatePicker
  },
  data() {
    return {
      edit: {}
    };
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false
    });
  },
  computed: {
    ...mapState({
      award: state => state.award.query,
      response: state => state.exchangeCode.response
    })
  },
  watch: {
    response(val) {
      if(val.status == '1') $(this.$refs.editSN).modal("hide");
    }
  },
  methods: {
    showSNEdit(award) {
      if (award == "" || award == undefined || award.awardsId == "") {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      this.edit = utils.deepCloneObj(award);

      if (this.edit.usedTime != "" && this.edit.usedTime != undefined) {
        $(".datepicker").val(new Date(this.edit.usedTime).toLocaleDateString());
        $(".timepicker").val(
          new Date(this.edit.usedTime).toLocaleTimeString("en-US", {
            timeStyle: "short"
          })
        );
      } else {
        $(".datepicker").val(new Date().toLocaleDateString());
        $(".timepicker").val(
          new Date().toLocaleTimeString("en-US", {
            timeStyle: "short"
          })
        );
      }

      $(this.$refs.editSN).modal("show");
    },
    UpdateSN() {
      if (this.edit.note == "" || this.edit.note == undefined) {
        this.$eventBus.$emit("showAlert", "備註不可為空!");
        return;
      }
      this.edit.isUsed = parseInt(this.edit.isUsed);
      if (this.edit.isUsed == 0) this.edit.usedTime = null;
      else {
        this.edit.usedTime =
          $(".datepicker").val() + " " + $(".timepicker").val();

        if (this.edit.usedTime == "") {
          this.$eventBus.$emit("showAlert", "發佈時間不可為空");
          return;
        }

        this.edit.usedTime = new Date(this.edit.usedTime + " UTC");
      }

      if(this.edit.isUsed == 1 && this.edit.uId == ""){
         this.$eventBus.$emit("showAlert", "使用者不可為空");
          return;
      }

      if(this.edit.isUsed == 2 && this.edit.uId == ""){
         this.$eventBus.$emit("showAlert", "使用者不可為空");
          return;
      }

      this.$store.dispatch("exchangeCode/exchangeCodeUpdate", this.edit);
    },
  }
};
</script>