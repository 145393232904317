<template>
    <div>
        <PopForm ref="popform" class="popform" @onBGClick="onBGClick">
            <template v-slot:slot-title>
                {{ $t('下載歷程') }}-{{ Sender }}
            </template>
            <template v-slot:slot-body>
                <button v-if="下載遊戲歷程功能" class="btn control-btn" @click="onDownloadGameBtnClick($event)"><i
                        class="fa fa-fw fa-gamepad" style="padding-right: 10px;"></i>{{ $t('遊戲歷程') }}</button>
                <button class="btn control-btn" @click="onDownloadMsgBtnClick($event)">
                    <i class="fa fa-fw fa-commenting" style="padding-right: 10px;"></i>
                    {{ $t('發話歷程') }}
                </button>
            </template>
            <template v-slot:slot-footer>
            </template>
        </PopForm>
    </div>
</template>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.popform>>>.modalWidth {
    width: 600px;
}

.popform>>>.box {
    border-top-width: 0;
}

.popform>>>.box-footer {
    display: flex;
    justify-content: flex-end;
}

.popform>>> {
    color: rgb(68, 68, 68);
}

.popform>>>.box-footer {
    display: none;
}

.btn.control-btn {
    color: #ebebeb;
    background-color: #1fa86c;
    padding: 5px 22px;
    margin-right: 10px;
}
</style>
<script>
import moment from "moment";
const XLSX = require('xlsx');
import PopForm from "@/views/Template/PopFormTemplate";
import Multiselect from "vue-multiselect";
import { MsgTypeEnum, GetMonitoringHistory } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
// import utils from "@/assets/js/app-utils";
const DownloadFn = {
    // async omp_xin_my_web() {
    //     let Sender = this.Sender;
    //     let SendTime = this.SendTime;
    //     let result = await this.$store.dispatch("xsw/遊戲歷程查詢", {
    //         暱稱: Sender,
    //         遊戲館: "",
    //         關鍵字: "",
    //         開始時間: moment.unix(SendTime / 1000).format("YYYY/MM/DD 00:00:00"),
    //         結束時間: moment.unix(SendTime / 1000).format("YYYY/MM/DD 23:59:00"),
    //         pageNum: 1,
    //         pageSize: 20,
    //     }).catch((msg) => {
    //         return [];
    //     });
    //     result.sort((a, b) => { return new Date(b.時間) - new Date(a.時間) });
    //     let data = utils.deepCloneObj(result);
    //     let items = [];
    //     data.forEach(item => {
    //         items.push({
    //             [this.$t('時間')]: item.時間,
    //             [this.$t('暱稱')]: item.暱稱,
    //             [this.$t('等級')]: item.等級,
    //             [this.$t('遊戲館')]: item.遊戲館,
    //             [this.$t('點數')]: item.點數,
    //             [this.$t('回饋金')]: item.回饋金,
    //             [this.$t('星幣')]: item.星幣,
    //             [this.$t('押注')]: item.押注,
    //             [this.$t('增減分')]: item.增減分,
    //             [this.$t('訊息')]: item.訊息,
    //         });
    //     });
    //     if (items.length == 0) {
    //         this.$eventBus.$emit("showAlert", this.$t('無資料'));
    //         return;
    //     }
    //     utils.exportData(
    //         items,
    //         `${utils.formatTime(new Date(), 'YYYYMMDD')}_${Sender}_${this.$t('遊戲歷程')}.xlsx`,
    //         `${this.$t('遊戲歷程')}`
    //     );
    // }
}
export default {
    components: {
        PopForm,
        Multiselect,
    },
    data() {
        return {
            Sender: "",
            SendTime: "",
            isLoading: false,
            pageSize: 20,
        }
    },
    async mounted() {
    },
    computed: {
        下載遊戲歷程功能() {
            return DownloadFn[this.$store.state.projectId];
        }
    },
    methods: {
        onBGClick() {
            if (this.isLoading) {
                return;
            }
            this.$refs.popform.hide();
        },
        async show({ Channel,
            ChannelType,
            Content,
            Keyword,
            MsgType,
            Receiver,
            SendId,
            SendTime,
            Sender,
            ServiceNo }) {
            this.Sender = Sender;
            this.SendTime = SendTime;
            this.$refs.popform.show();
            this.loading(false)
        },
        async onDownloadGameBtnClick() {
            this.loading(true);
            await this.下載遊戲歷程功能.call(this).catch((reason) => {
                this.$eventBus.$emit("showAlert", reason);
            });
            this.loading(false);
        },
        async onDownloadMsgBtnClick() {
            let Sender = this.Sender;
            let SendTime = this.SendTime;
            let query = {
                SDate: moment.unix(SendTime / 1000).format("YYYY-MM-DD"),
                EDate: moment.unix(SendTime / 1000).format("YYYY-MM-DD"),
                Partaker: Sender,
                ChannelType: 0,//全部
                Page: 1
            };

            let pageIdx = 0;
            let Total = 0;
            let list = [];
            this.loading(true);
            try {
                let exportData = [];
                do {
                    pageIdx++;
                    query.Page = pageIdx;
                    let res = await GetMonitoringHistory(query, this.$store.state.projectId);//this.apiSearch(query);
                    Total = res.Total;
                    list = res.list;


                    let 建立時間 = this.$t('建立時間');
                    let 玩家暱稱 = this.$t('玩家暱稱');
                    let Act = this.$t('動作/頻道');
                    let 接收人 = this.$t('接收人');
                    let 內容 = this.$t('內容');

                    for (let item of list) {
                        let tmp = {};
                        tmp[建立時間] = item.SendTime;
                        tmp[玩家暱稱] = item._玩家暱稱;
                        tmp[Act] = item._act;
                        tmp[接收人] = item._接收人;
                        tmp[內容] = (item.MsgType == MsgTypeEnum.Sticker) ? `<${this.$t('貼圖')}>${item.Content}` : item.Content;
                        exportData.push(tmp);
                    }
                    // await wait(0.5);
                    // console.error("check....", (Total > pageIdx * this.pageSize), `${Total} > ${pageIdx} * ${this.pageSize}`)
                } while (Total > pageIdx * this.pageSize);
                if (exportData.length == 0) {
                    throw this.$t('無資料，請修改查詢條件');
                }
                // 创建工作簿
                let workbook = XLSX.utils.book_new();
                let worksheet = XLSX.utils.json_to_sheet(exportData);
                XLSX.utils.book_append_sheet(workbook, worksheet, this.$t('發話歷程'));
                // 将工作簿导出为 Excel 文件
                XLSX.writeFile(workbook, `${query.Partaker} ${this.$t('發話歷程')}-${query.SDate}.xlsx`);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            this.loading(false);
        },
        loading(bol) {
            this.isLoading = bol;
            this.$refs.popform.loading(bol);
        }
    },
};
</script>