<template>
  <!-- 更改密碼 -->
  <div class="modal fade" ref="midify_password" id="midify_password">
    <div class="modal-dialog" style="overflow: hidden">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>

          <div class="nav-tabs-custom" id="pwTabs">
            <ul class="nav nav-tabs">
              <li
                v-on:click="changeTab('pwOut')"
                v-bind:class="{ active: tab == 'pwOut' }"
                >
                <a href="javascript:;">修改密碼</a>
              </li>
              <li
                v-on:click="changeTab('info')"
                v-bind:class="{ active: tab == 'info' }"
                v-if="isOutsider"
                >
                <a href="javascript:;">修改信箱</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="login-box-body">
          <form>
            <div class="form-group has-feedback">
              <div>
                <input
                  class="form-control"
                  placeholder="帳號"
                  type="text"
                  v-model="user.userid"
                  disabled="disabled"
                />
                <span
                  class="glyphicon glyphicon-user form-control-feedback"
                ></span>
              </div>
            </div>
            <template v-if="tab == 'info'">
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="信箱"
                  type="email"
                  v-model="email"
                />
                <span
                  class="glyphicon glyphicon-lock form-control-feedback"
                ></span>
              </div>
            </template>
            <template v-else>
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="原密碼"
                  type="password"
                  v-model="password"
                />
                <span
                  class="glyphicon glyphicon-lock form-control-feedback"
                ></span>
              </div>
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="新密碼"
                  type="password"
                  v-model="newPassword"
                />
                <span
                  class="glyphicon glyphicon-lock form-control-feedback"
                ></span>
              </div>
              <div class="form-group has-feedback">
                <input
                  class="form-control"
                  placeholder="再次輸入新密碼"
                  type="password"
                  v-model="chkPassword"
                />
                <span
                  class="glyphicon glyphicon-lock form-control-feedback"
                ></span>
              </div>
            </template>
          </form>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-success btn-block btn-flat"
            type="button"
            @click="changePassword()"
          >
            確定更改
          </button>
          <button
            class="btn btn-default btn-block btn-flat"
            type="button"
            data-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  props: {
    proplogin: true,
  },
  data() {
    return {
      isADInsider: false,
      password: "",
      newPassword: "",
      chkPassword: "",
      email: "",
      user: [],
      tab: "pwOut",
      isOutsider: false,
    };
  },
  watch: {
    proplogin(value) {
      this.proplogin = value;
    },
  },
  mounted() {},
  methods: {
    showMidifyBox(tab) {
      this.tab = tab || "pwOut"
      this.newPassword = "";
      this.chkPassword = "";
      this.password = "";
      this.user = this.$store.state.loginUser;
      this.isADInsider = this.$store.state.changePassword;
      this.email = this.user.EmpEmail;
      this.isOutsider = this.user.CompanyId != 'A';
      $('.wrapper').css('z-index', '10000');
      $(this.$refs.midify_password).modal("show");
    },
    changeTab(tab) {
      this.newPassword = "";
      this.chkPassword = "";
      this.password = "";
      this.tab = tab;
    },
    //確定更改
    changePassword() {
      if (
        this.tab != 'info' &&
        (this.newPassword == "" ||
        this.chkPassword == "" ||
        this.password == "")
      ) {
        this.$eventBus.$emit("showAlert", "請確認欄位是否都有填寫!!");
        return;
      }

      if (this.tab != 'info' && (this.newPassword != this.chkPassword)) {
        this.$eventBus.$emit("showAlert", "新密碼與確認新密碼不一致");
        return;
      }

      if(this.tab == 'info' && this.email == "") {
        this.$eventBus.$emit("showAlert", "信箱不得為空");
        return;
      }

      if (this.tab == "pwOut") {
        this.$eventBus.$emit(
          "showConfirm",
          "是否確定更改密碼？",
          (isOK) => {
            if (isOK) {
              api.login
                .changePassword({
                  userid: this.user.userid,
                  password: this.password,
                  newPassword: this.newPassword,
                  OutsideADPwd: 1,
                })
                .then((res) => {
                  if (res.data.status == "1") {
                    this.$eventBus.$emit(
                      "showConfirm",
                      "更密碼修改成功!!",
                      () => {
                        this.newPassword = "";
                        this.chkPassword = "";
                        this.password = "";
                        $(this.$refs.midify_password).modal("hide");
                        $('.wrapper').css('z-index', '');
                      }
                    );
                  } else {
                    this.$eventBus.$emit("showAlert", res.data.message);
                    $("#attention-delete").hide();
                  }
                })
                .catch((err) => {
                  // console.log(err);
                });
            }
          }
        );
      } else if (this.tab == "info") {
        this.$eventBus.$emit(
          "showConfirm",
          "是否確定更改信箱？",
          (isOK) => {
            if (isOK) {
              api.login
                .updateProfile({
                  userid: this.user.userid,
                  email: this.email
                })
                .then((res) => {
                  if (res.data.status == "1") {
                    this.user.EmpEmail = this.email;
                    this.$store.commit("setLoginUser", this.user);
                    this.$eventBus.$emit("showAlert", "信箱修改成功!!");
                  } else {
                    this.$eventBus.$emit("showAlert", res.data.message);
                    $("#attention-delete").hide();
                  }
                })
                .catch((err) => {
                  // console.log(err);
                });
            }
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.modal-backdrop {
  z-index: 1 !important;
}
</style>