<template>
  <div id="RewardMessage">
    <section class="content-header">
      <h1>給獎訊息設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">給獎訊息設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addMessage()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增
            </button>
            <div class="select-group">
              <select
                class="form-control"
                v-model.number="searchData.messageStatus"
                @change="resetDataList()"
              >
                <option value="-9">所有狀態</option>
                <option value="0">草稿</option>
                <option value="1">等待中</option>
                <option value="2">已發送</option>
                <option value="-1">已刪除</option>
              </select>
            </div>
            <div class="form-group" style="width: auto">
              <label class="control-label">發送時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>
            <div class="btns">
              <button
                @click="resetDataList()"
                class="btn btn-primary"
                type="button"
              >
                查詢
              </button>
              <button class="btn btn-default" type="button" @click="importSN()">
                獎項匯入
              </button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px">發送時間</th>
                  <th style="min-width: 150px">有效時間</th>
                  <th class="text-nowrap" style="min-width: 150px">訊息主旨</th>
                  <th>發送內容</th>
                  <th class="text-nowrap">操作人</th>
                  <th>狀態</th>
                  <th>歷程明細</th>
                  <th style="min-width: 150px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="8">查詢無任何資料</td>
                </tr>
                <tr
                  :class="{ 'bg-gray-light': item.messageStatus === -1 }"
                  v-else
                  v-for="(item, index) in datas"
                  :key="index"
                >
                  <td>
                    {{ item.sendStartTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td>
                    {{ item.sendEndTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-left">
                    {{ item.messageTitle | ellipsis(13) }}
                  </td>
                  <td>
                    <button
                      class="btn btn-primary"
                      @click="
                        item.messageStatus === 0
                          ? editMessage(item)
                          : checkContent(item)
                      "
                    >
                      查看
                    </button>
                  </td>
                  <td>{{ item.updateUName }}</td>
                  <td :class="{ 'text-red': item.messageStatus === 0 }">
                    {{ define.rewardStatus[item.messageStatus] }}
                  </td>
                  <td>
                    <button
                      @click="checkHistory(item)"
                      class="btn btn-success"
                      v-if="
                        item.messageStatus === 2 ||
                        new Date(item.sendStartTime) < today
                      "
                    >
                      查看
                    </button>
                  </td>
                  <td>
                    <button
                      @click="deleteMessage(item)"
                      class="btn btn-danger"
                      v-if="item.messageStatus !== -1"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <rewardMessageContent ref="rewardMessageContent" />
    <fcImportData ref="fcImportData" title="獎項" :checks="checks" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import fcImportData from "@/views/projects/FC/FcImportData";
import rewardMessageContent from "@/views/projects/FC/RewardMessageContent";
import { mapState } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    fcImportData,
    rewardMessageContent,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment()
            .subtract(2, "days")
            .format("YYYY-MM-DD 00:00:00"),
          endTime: new moment().add(2, "days").format("YYYY-MM-DD 23:59:59"),
        },
        messageStatus: -9,
      },
      checks: ["類型編號", "類型名稱", "獎項編號", "獎項名稱"],
      today: new Date(),
      datas: [],
      itemList: { datas: [] },
    };
  },
  computed: {
    ...mapState({
      importList: (state) => state.fcReward.importList,
    }),
  },
  watch: {
    importList() {
      this.itemListCreate();
    },
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} UTC`
      );
      this.$store
        .dispatch("fcReward/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // 查看內容
    checkContent(item) {
      this.$refs.rewardMessageContent.showRewardMessageContent(
        item,
        this.searchData
      );
    },
    // 查看歷程
    checkHistory(item) {
      this.$router.push({
        name: "RewardMessageHistory",
        params: {
          id: item.prizeMessageId,
          searchData: this.searchData,
        },
      });
    },
    // 刪除 = 停用
    deleteMessage(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("fcReward/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },
    // 修改草稿
    editMessage(item) {
      this.$router.push({
        name: "RewardMessageEdit",
        params: {
          id: item.prizeMessageId,
          searchData: this.searchData,
        },
      });
    },
    // 新增
    addMessage() {
      this.$router.push({
        name: "RewardMessageEdit",
        params: {
          searchData: this.searchData,
        },
      });
    },
    itemListCreate() {
      this.itemList.datas = [];
      if (this.importList.datas) {
        this.importList.datas.forEach((data) => {
          this.itemList.datas.push({
            prizeTypeNo: data.類型編號.toString().trim(),
            prizeTypeName: data.類型名稱.toString().trim(),
            prizeItemNo: data.獎項編號.toString().trim(),
            prizeItemName: data.獎項名稱.toString().trim(),
            prizeSubType: data.次類型 ? data.次類型.toString().trim() : null,
          });
        });
        this.$store.dispatch("fcReward/itemListCreate", this.itemList);
      }
    },
    importSN() {
      this.$refs.fcImportData.showSerialNumberEdit();
    },
  },
};
</script>
