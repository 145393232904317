<template>
  <div class="modal fade" ref="announcementContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">發送內容</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td width="100px">發送時間</td>
                  <td>
                    {{ item.sendStartTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
                <tr>
                  <td>截止時間</td>
                  <td>
                    {{ item.sendEndTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
                <tr>
                  <td>發送平台</td>
                  <td>{{ item.platform }}</td>
                </tr>
                <tr v-if="searchData.typeName === '系統'">
                  <td>訊息主旨</td>
                  <td>{{ item.title }}</td>
                </tr>
                <tr>
                  <td>訊息內容</td>
                  <td v-if="item.info.startsWith('<')">
                    <div v-html="item.info"></div>
                  </td>
                  <td v-else style="white-space: pre-line">
                    {{ item.info }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            type="button"
            v-if="isEdit"
            @click="updateContent()"
          >
            編輯
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      isEdit: false,
      item: { info: "" },
      searchData: {},
    };
  },
  methods: {
    async showContent(item, searchData) {
      this.isEdit = item.status === 0;
      this.item = item;
      this.searchData = searchData;
      $(this.$refs.announcementContent).modal("show");
    },
    updateContent() {
      $(this.$refs.announcementContent).modal("hide");
      this.$router.push({
        name: "FcAnnouncementEdit",
        params: {
          id: this.item.announcementId,
          type: this.searchData.typeName,
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
