<template>
  <div id="SMSProvider">
    <section class="content-header">
      <h1>簡訊發送備援切換</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">簡訊發送備援切換</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">自訂簡訊門號</h4>
            <button @click="importData()" class="btn btn-block btn-success btn-cog" type="button">
              多筆匯入
            </button>
          </div>

          <div class="form-horizontal">
            <div class="box-body">

              <div class="form-group mb-20">
                <label class="col-sm-1 control-label text-nowrap">
                  <span class="text-red">*</span> 手機：
                </label>
                <div class="col-sm-6">
                  <input
                    class="form-control"
                  />
                </div>
                <div class="col-sm-5">
                  * 國碼請以+號分隔。例如：886+0910111222
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-1 control-label text-nowrap">
                  <span></span> 序號：
                </label>
                <div class="col-sm-6">
                  <input
                    class="form-control"
                  />
                </div>
                <div class="col-sm-5">
                  * 序號請填半形
                </div>
              </div>

              <div class="form-group mt-20">
                <label class="col-sm-1 control-label text-nowrap">
                  <span></span> 簡訊內容：
                </label>
                <div class="col-sm-6">
                  <textarea
                    class="form-control"
                    rows="10"
                  >
                  </textarea>
                </div>
                <div class="col-sm-5">
                  * 簡訊字數上限為300字。請將#CODE#(大寫)放在您欲取代序號的位置。例如：歡迎使用moLo最新遊戲：moLo快樂送_#CODE#。
                </div>
              </div>

              <div class="btns text-center">
                <label class="col-sm-1 control-label"></label>
                <div class="col-sm-6">
                  <button class="btn btn-primary btn-lg" type="button">
                    確定送出
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!--匯入簡訊資料-->
    <div class="modal fade" ref="import">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">匯入簡訊資料</h4>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <input type="file">
            </div>
            <button class="btn btn-primary mt-10 mb-10" type="button" style="width: 100%;">
              確定匯入
            </button>
            <br>
            <a
              href="/uploads/簡訊配點範例.xlsx"
              class="btn btn-block btn-success btn-cog"
              type="button"
              download
              >
              配點範例 
            </a>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import multiselect from "vue-multiselect";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import smsInfo from '@/static/json/sms.json';
export default {
  components: {
    multiselect,
    tinymce,
    iCheckRedioButton,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    importData() {
      $(this.$refs.import).modal("show");
    }
  },
};
</script>
