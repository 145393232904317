/* eslint-disable */
import api from "@/assets/js/app-api";
import Vue from "vue";
// function createMenuNode(menuId, menuName, menuUrl) {
//     return {
//         menuId,
//         menuName,
//         menuUrl,
//         subMenu: []
//     };
// }
// const menus = {
//     "金流管理": createMenuNode("IPSSetting", "金流管理", ""),
//     "廠商管理": createMenuNode("FirmSetting", "廠商管理", "")
// }
// let IPSSettingList = createMenuNode("IPSSettingList", "金流設定", "/IPS/IPSSettingList");
// let IPSSettingEdit = createMenuNode("IPSSettingEdit", "新增/修改金流", "/IPS/IPSSettingEdit");
// let IPSSettingAccountList = createMenuNode("IPSSettingAccountList", "金流帳號設定", "/IPS/IPSSettingAccountList");
// let IPSSettingAccountEdit = createMenuNode("IPSSettingAccountEdit", "新增/修改金流帳號");
// let IPSSettingPayList = createMenuNode("IPSSettingPayList", "支付設定", "/IPS/IPSSettingPayList");
// let IPSSettingPayEdit = createMenuNode("IPSSettingPayEdit", "新增/修改支付設定", "/IPS/IPSSettingPayEdit");

// menus.金流管理.subMenu.push(IPSSettingList);
// IPSSettingList.subMenu.push(IPSSettingEdit);
// IPSSettingList.subMenu.push(IPSSettingAccountList);
// IPSSettingAccountList.subMenu.push(IPSSettingAccountEdit);
// IPSSettingList.subMenu.push(IPSSettingPayList);
// IPSSettingPayList.subMenu.push(IPSSettingPayEdit);

// let PayFlowMaintainList = createMenuNode("PayFlowMaintainList", "金流維護設定", "/IPS/PayFlowMaintainList")
// let PayFlowMaintainEdit = createMenuNode("PayFlowMaintainEdit", "新增金流維護時段", "/IPS/PayFlowMaintainEdit")
// let FirmSettingList = createMenuNode("FirmSettingList", "廠商設定", "/IPS/FirmSettingList")

// let FirmSettingEdit = createMenuNode("FirmSettingEdit", "新增/修改廠商", "/IPS/FirmSettingEdit")
// let FirmPaySettingList = createMenuNode("FirmPaySettingList", "廠商支付金流管理", "/IPS/FirmPaySettingList")
// let FirmPaySettingEdit = createMenuNode("FirmPaySettingEdit", "新增/修改廠商支付設定", "/IPS/FirmPaySettingEdit")
// let GoodsManagerList = createMenuNode("GoodsManagerList", "品項管理", "/IPS/GoodsManagerList")
// let GoodsManagerEdit = createMenuNode("GoodsManagerEdit", "新增/修改廠商品項", "/IPS/GoodsManagerEdit")

// let FirmPayFlowList = createMenuNode("FirmPayFlowList", "廠商直儲金流", "/IPS/FirmPayFlowList")
// let FirmPayFlowEdit = createMenuNode("FirmPayFlowEdit", "新增/修改廠商直儲金流", "/IPS/FirmPayFlowEdit")

// menus.廠商管理.subMenu.push(PayFlowMaintainList);
// PayFlowMaintainList.subMenu.push(PayFlowMaintainEdit);
// menus.廠商管理.subMenu.push(FirmSettingList);
// FirmSettingList.subMenu.push(FirmSettingEdit);

// menus.廠商管理.subMenu.push(FirmSettingList);
// FirmSettingList.subMenu.push(FirmSettingEdit);
// FirmSettingList.subMenu.push(FirmPaySettingList);
// FirmPaySettingList.subMenu.push(FirmPaySettingEdit);


// menus.廠商管理.subMenu.push(GoodsManagerList);
// GoodsManagerList.subMenu.push(GoodsManagerEdit);

// menus.廠商管理.subMenu.push(FirmPayFlowList);
// FirmPayFlowList.subMenu.push(FirmPayFlowEdit);
const state = {
    基礎設定檔: {
        地區列表: [],
        金流類型設定: [],
        條件式: [],
        直儲模式: [],
        維護狀態: [],
        額外限制列表: [],
        金額限制項目: [],
    },
    // menus
}

// getters
const getters = {
    金流類型設定Key(state) {
        let obj = {};
        for (let i of state.基礎設定檔.金流類型設定) {
            Vue.set(obj, i.編號, i);
        }
        return obj;
    },
    地區列表Key(state) {
        let obj = {};
        for (let i of state.基礎設定檔.地區列表) {
            Vue.set(obj, i.編號, i);
        }
        return obj;
    },
    幣別列表(state) {
        let ary = [];
        for (let i of state.基礎設定檔.地區列表) {
            if (i.幣別 == "None") {
                continue;
            }
            ary.push(i.幣別);
        }
        return ary;
    }
}

// actions
const actions = {
    金流維護設定_金流列表_付款方式({ commit }, data) {
        if (api.IPSSetting.debug) {
            console.log("debug:金流維護設定_金流列表_付款方式 todo:", JSON.stringify(data));
            return;
        }
        // return new Promise((resolve, reject) => {
        //     api.IPSSetting.金流維護設定_金流列表_付款方式(data).then(res => {
        //         if (res && res.data && res.data.stateCode == 1) {
        //             resolve(res.data.content);
        //         } else {
        //             let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
        //             this._vm.$eventBus.$emit("showAlert", errMsg);
        //         }
        //     })
        // });
    },
    // 金流維護設定_廠商列表({ commit }, data) {
    //     if (api.IPSSetting.debug) {
    //         let tmp = ` [{"廠商設定主檔編號":1,"金流設定主檔編號":2,"顯示名稱":"星城ONLINE"}]`;
    //         commit('set廠商List', JSON.parse(tmp));
    //         return;
    //     }
    //     api.IPSSetting.金流維護設定_廠商列表(data).then(res => {
    //         if (res && res.data) {
    //             let tmp = JSON.parse(res.data.content);
    //             let len = tmp.length;
    //             for (let i = 0; i < len; i++) {
    //                 tmp[i].廠商設定主檔編號 = parseInt(tmp[i].廠商設定主檔編號);
    //                 tmp[i].金流設定主檔編號 = parseInt(tmp[i].金流設定主檔編號)
    //             }
    //             commit('set廠商List', tmp);
    //         } else {
    //             let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
    //             this._vm.$eventBus.$emit("showAlert", errMsg);
    //         }
    //     })
    // },
    基礎設定檔_查詢({ commit }, data) {
        return api.IPSSetting.基礎設定檔_查詢(data).then(res => {
            if (res && res.data && res.data.stateCode == 1) {
                commit('set基礎設定檔', res.data.content[0]);
            } else {
                let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    額外限制_刪除({ commit }, data) {//ok
        return new Promise((resolve, reject) => {
            api.IPSSetting.額外限制_刪除(data).then(res => {
                if (res && res.data && res.data.stateCode == 1) {
                    resolve(res.data.content);
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    this._vm.$eventBus.$emit("showAlert", errMsg);
                    resolve("");
                }
            })
        });
    },
    // 額外限制_查詢({ commit }, data) {
    //     api.IPSSetting.額外限制_查詢(data).then(res => {
    //         if (res && res.data && res.data.stateCode == 1) {
    //             // commit('set輸入List', res.data.content);
    //         } else {
    //             let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
    //             this._vm.$eventBus.$emit("showAlert", errMsg);
    //         }
    //     })
    // },
    額外限制_修改({ commit }, data) {//ok
        return new Promise((resolve, reject) => {
            api.IPSSetting.額外限制_修改(data).then(res => {
                if (res && res.data && res.data.stateCode == 1) {
                    resolve(res.data.content);
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    this._vm.$eventBus.$emit("showAlert", errMsg);
                    resolve("");
                }
            })
        });
    },
    額外限制_新增({ commit }, data) {//ok
        return new Promise((resolve, reject) => {
            api.IPSSetting.額外限制_新增(data).then(res => {
                if (res && res.data && res.data.stateCode == 1) {
                    resolve(res.data.content);
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    this._vm.$eventBus.$emit("showAlert", errMsg);
                    resolve("");
                }
            })
        });
    },
    額外限制List_查詢2({ commit }, { 額外限制, 額外限制列表 }) {
        if (!額外限制 || 額外限制 == "-") {
            return [];
        }
        return new Promise((resolve, reject) => {
            api.IPSSetting.額外限制List_查詢({ 編號集合: 額外限制 }).then(res => {
                if (res && res.data && res.data.stateCode == 1) {
                    // console.log("return = ", JSON.stringify(res.data.content));
                    res.data.content.forEach(element => {
                        element.限制細項 = JSON.parse(element.限制細項);
                        delete element.建立時間;
                        for (let item of 額外限制列表) {
                            let find = element.限制細項.find((限制) => { return 限制.編號 == item.編號; })
                            if (find) {
                                find.是否已設 = true;
                                find.限制細項Tip = "";
                            } else {
                                element.限制細項.push({
                                    編號: item.編號,
                                    描述: item.名稱,
                                    參數: -1,
                                    是否已設: false,
                                    限制細項Tip: "",
                                })
                            }
                        }
                        element.限制細項.sort((a, b) => {
                            return a.編號 - b.編號;
                        })
                    });
                    resolve(res.data.content);
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    this._vm.$eventBus.$emit("showAlert", errMsg);
                    resolve([]);
                }
            })
        });
    },
    額外限制List_查詢({ commit }, data) {
        // console.log("todo:額外限制List_查詢"); return;
        let send = {
            編號集合: data.編號集合.join(",")
        }
        if (api.IPSSetting.debug) {
            return new Promise((res, rej) => {
                let obj = JSON.parse(`[{"編號":1,"限制主項目":"海外限制門號bbc","限制主條件":1,"判斷主條件":"1","限制主參數":"66","限制細項":"","建立時間":"2022/09/22 16:36:30"}]`);
                Object.defineProperty(obj[0], "限制細項", { value: `[{"編號":1,"限制主條件":3,"限制主條件描述":"單筆上限","限制細項參數":100000}]` })
                res(obj);
            });
        }
        return new Promise((resolve, reject) => {
            api.IPSSetting.額外限制List_查詢(send).then(res => {
                if (res && res.data && res.data.stateCode == 1) {
                    console.log("return = ", JSON.stringify(res.data.content));
                    resolve(res.data.content);
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    this._vm.$eventBus.$emit("showAlert", errMsg);
                }
            })
        });
    },
    金流設定主檔Key_查詢(content, { }) {
        return new Promise((resolve, reject) => {
            let 金流設定主檔Key = {};
            api.IPSSetting.金流設定主檔_查詢({}).then(res => {
                let 金流設定主檔List = res.data.content;
                for (let i of 金流設定主檔List) {
                    金流設定主檔Key[i.編號] = i;
                }
                resolve(金流設定主檔Key);
            })
        });
    },

    金流帳號Key_查詢(content, { }) {
        return new Promise((resolve, reject) => {
            let 金流帳號Key = {};
            api.IPSSetting.金流帳號_查詢({}).then(res => {
                let 金流帳號List = res.data.content;
                for (let i of 金流帳號List) {
                    金流帳號Key[i.編號] = i;
                }
                resolve(金流帳號Key);
            })
        });
    },

    // 金流支付設定Key_查詢(content, { 額外限制列表 }) {
    //     return new Promise((rtransactionInfoResetesolve, reject) => {
    //         api.IPSSetting.金流支付設定_查詢({}).then(res => {
    //             let 金流支付設定List = res.data.content;
    //             (async () => {
    //                 let 金流支付設定Key = {};
    //                 for (let i of 金流支付設定List) {
    //                     i.server端的額外限制編號 = i.額外限制;
    //                     i.額外限制 = await content.dispatch("額外限制List_查詢2", { 額外限制: i.額外限制, 額外限制列表 });
    //                     金流支付設定Key[i.編號] = i;
    //                 }
    //                 resolve(金流支付設定Key);
    //             })();
    //         })
    //     });
    // },
    金流支付設定Key_查詢2(content) {
        return new Promise((resolve, reject) => {
            api.IPSSetting.金流支付設定_查詢({}).then(res => {
                let 金流支付設定List = res.data.content;
                (async () => {
                    let 金流支付設定Key = {};
                    for (let i of 金流支付設定List) {
                        i.server端的額外限制編號 = i.額外限制;
                        // i.額外限制 = await content.dispatch("額外限制List_查詢2", { 額外限制: i.額外限制, 額外限制列表 });
                        金流支付設定Key[i.編號] = i;
                    }
                    resolve(金流支付設定Key);
                })();
            })
        });
    },
    // 黑貓金額
    transactionInfoUpdate ({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.IPSSetting.TransactionInfoUpdate(data).then(res => {
                resolve(res)
            }).catch(e => console.log(e))
        })
    },
    // 重置 交易明細訂單(需登入並有權限)
    transactionInfoReset ({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.IPSSetting.TransactionInfoReset(data).then(res => {
                resolve(res)
            }).catch(e => console.log(e))
        })
    },

    // 額外限制細項_查詢({ commit }, data) {
    //     console.log("todo:額外限制細項_查詢"); return;
    //     api.IPSSetting.額外限制細項_查詢(data).then(res => {
    //         // if (res && res.data && res.data.stateCode == 1) {
    //         //     console.log(res.data.content)
    //         //     commit('set輸入List', res.data.content);
    //         // } else {
    //         //     let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
    //         //     this._vm.$eventBus.$emit("showAlert", errMsg);
    //         // }
    //     })
    // },
}

// mutations
const mutations = {
    set基礎設定檔(state, 基礎設定檔) {
        state.基礎設定檔 = 基礎設定檔;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}