<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>影片</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>在地饗宴</li>
        <li class="active">影片</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addVideo()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增影片
            </button>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ list.length }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" width="25%" style="min-width: 150px;">標題</th>
                  <th class="text-nowrap" width="25%" style="min-width: 150px;">影片連結</th>
                  <th class="text-nowrap">發布時間</th>
                  <th class="text-nowrap">狀態</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 10%"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="list.length > 0">
                  <tr :key="item.id" v-for="(item, index) in list">
                    <td>
                      <input
                        class="form-control"
                        type="text"
                        v-model="item.title"
                        v-if="item.editor && item.editor != 0"
                      >
                      <p v-else align="center">{{ item.title }}</p>
                    </td>
                    <td>
                      <input
                      class="form-control"
                      type="text"
                      v-model="item.url"
                      v-if="item.editor && item.editor != 0"
                      >
                      <p v-else align="center">{{ item.url }}</p>
                    </td>
                    <td align="center">{{ dateFormat(item.createTime) }}</td>
                    <td class="text-center" align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          :false-value="1"
                          :id="'check' + index"
                          :true-value="0"
                          @change="updateData(item, true)"
                          name="ckbx-style-8"
                          type="checkbox"
                          v-model="item.isStop"
                        />
                        <label :for="'check'+index"></label>
                      </div>
                    </td>
                    <td class="text-center">
                      <template v-if="item.editor && item.editor != 0">
                        <button
                        class="btn btn-success mr-10"
                        @click="updateData(item)"
                        >
                          儲存
                        </button>
                        &nbsp;
                        <button
                          class="btn btn-default"
                          @click="cancel(item)"
                        >
                          取消
                        </button>
                      </template>
                      <template v-else>
                        <button
                        class="btn btn-primary mr-10"
                        @click="change(item)"
                        >
                          修改
                        </button>
                        &nbsp;
                        <button
                          class="btn btn-danger"
                          @click="deleteData(item)"
                        >
                          刪除
                        </button>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div> -->
        <VideoAlert ref="videoAlert" v-on:updateList="getVideoQueryAll" />
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import VideoAlert from "@/views/projects/heti/VideoAlert";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
    VideoAlert,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      pageSize: 10,
      orderStatusOptions: [],
      productSN: "",
      tempEdit: false,
      // searchData: {
      //   serviceID: "10004",
      //   paymentType: "ECPay",
      //   orderID: "",
      //   vendorOrderID: "",
      //   userId: "",
      //   orderStatus: null,
      //   givePointResult: -2,
      //   startTime: new moment().format("YYYY-MM-DD 00:00:00"),
      //   endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      //   startNo: 1,
      //   endNo: 10,
      // },
      searchData: {
        classTypeId: "",
        pageSize: 10,
        pageNum: 1,
        isDelete: 0,
      },
    };
  },
  mounted() {
    this.GetClassTypes();
  },
  computed: {
    ...mapState({
      list: (state) => state.link.queryAll.datas,
    }),
  },
  wacth: {
  },
  methods: {
    addVideo() {
      this.$refs.videoAlert.showAlert();
    },
    getVideoQueryAll() {
      this.$store.dispatch("link/linkQueryAll", this.searchData);
    },
    GetClassTypes() {
      //分類
      let data = {
        projectId: this.searchData.projectId,
        module: "video",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      }
      api.classType.queryAll(data).then(({data}) => {
        if(data.status == '1') {
          this.searchData.classTypeId = data.result.datas[0].classTypeId
          this.getVideoQueryAll();
        }
      })
    },
    updateData(data, status = false) {
      console.log(data);
      this.$store
        .dispatch("link/linkUpdate", data)
        .then(() => {
          if(!status) {
            this.getVideoQueryAll();
            this.tempEdit = false;
            item.editor = 0;
          }
        })
    },
    deleteData(data) {
      this.$store
        .dispatch("link/linkDelete", data)
        .then(() => {
            this.getVideoQueryAll();
        })
    },
    dateFormat(data) {
      let time = data.split(" ")
      return `${time[0]} ${time[2]}`
    },
    changeStatus(item) {
      console.log(item);
    },
    change(item) {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      item.editor = item.editor == 1 ? 0 : 1;
      this.$forceUpdate();
    },
    cancel(item) {
      item.editor = 0
      this.tempEdit = false;
      this.getVideoQueryAll();
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.ckbx-style-8 label:before {
  background: #999;
}
</style>