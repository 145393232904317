<template>
    <div class="mymodal" ref="PopForm">
        <div style="width:800px;margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box" :class="發票聯別與類型.聯別 == 發票聯別Enum.二聯 ? 'style2聯' : 'style3聯'">
                <div class="box-header with-border">
                    <h3 class="box-title">發票資料</h3>
                    <button type="button" class="close" @click="Close()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="form-horizontal">
                    <div class="box-body">
                        <div class="form-group">
                            <label>開立時間</label>
                            <span v-show="!(編輯模式 == 編輯模式Enum.檢視)">
                                <date-picker :config='{
                                    format: "YYYY-MM-DD",
                                    showTodayButton: true,
                                    showClear: true,
                                    useCurrent: false,
                                    sideBySide: true,
                                    locale: "zh-tw",
                                }' v-model="edit.發票時間"></date-picker>
                            </span>
                            <span v-show="編輯模式 == 編輯模式Enum.檢視">
                                <input type="text" class="form-control" :value="edit.發票時間" disabled>
                            </span>
                        </div>

                        <div class="form-group">
                            <label>發票種類</label>
                            <select class=" form-control" v-model="edit.發票種類" :disabled="編輯模式 == 編輯模式Enum.檢視">
                                <option v-for="(item, key) in 發票種類Enum" :value="item">{{ key }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group" v-if="edit.發票種類 == 發票種類Enum.二聯捐贈">
                            <div>
                                <label class="addItemBtn">愛心碼</label>
                                <button type="button" class="btn btn聯別 btn-xs addItemBtn"
                                    @click="$refs.company.Open(true, 手開發票常用資料類別Enum.愛心碼)" :disabled="編輯模式 == 編輯模式Enum.檢視">
                                    <i class="fa fa-plus"></i>
                                    選擇愛心碼
                                </button>
                            </div>
                            <input type="text" class="form-control" placeholder="愛心碼" v-model.trim="edit.愛心碼"
                                :disabled="編輯模式 == 編輯模式Enum.檢視">
                        </div>
                        <div class="form-group" v-if="edit.發票種類 == 發票種類Enum.二聯載具">
                            <div>
                                <label class="addItemBtn">載具條碼</label>
                                <button type="button" class="btn btn聯別 btn-xs addItemBtn"
                                    @click="$refs.company.Open(true, 手開發票常用資料類別Enum.載具條碼)" :disabled="編輯模式 == 編輯模式Enum.檢視">
                                    <i class="fa fa-plus"></i>
                                    選擇條碼
                                </button>
                            </div>
                            <input type="text" class="form-control" placeholder="載具條碼" v-model.trim="edit.載具條碼"
                                :disabled="編輯模式 == 編輯模式Enum.檢視">
                        </div>
                        <div class="form-group" v-if="edit.發票種類 == 發票種類Enum.三聯紙本 || edit.發票種類 == 發票種類Enum.三聯交換">
                            <div>
                                <label class="addItemBtn">公司統編</label>
                                <button type="button" class="btn btn聯別 btn-xs addItemBtn"
                                    @click="$refs.company.Open(true, 手開發票常用資料類別Enum.統一編號)" :disabled="編輯模式 == 編輯模式Enum.檢視">
                                    <i class="fa fa-plus"></i>
                                    選擇公司
                                </button>
                            </div>
                            <input type="text" class="form-control" placeholder="公司統編" v-model.trim="edit.公司統編"
                                :disabled="編輯模式 == 編輯模式Enum.檢視">
                        </div>
                        <div class="form-group" v-if="edit.發票種類 == 發票種類Enum.三聯紙本 || edit.發票種類 == 發票種類Enum.三聯交換">
                            <label>公司名稱</label>
                            <input type="text" class="form-control" placeholder="公司名稱" v-model.trim="edit.公司名稱"
                                :disabled="編輯模式 == 編輯模式Enum.檢視">
                        </div>
                        <div class="form-group">
                            <label>備註</label>
                            <input type="text" class="form-control" placeholder="備註" v-model.trim="edit.備註"
                                :disabled="編輯模式 == 編輯模式Enum.檢視">
                        </div>
                        <div class="form-group">
                            <div>
                                <label class="addItemBtn">商品明細</label>
                                <button type="button" class="btn btn聯別 btn-xs" @click="新增商品()"
                                    :disabled="編輯模式 == 編輯模式Enum.檢視">
                                    <i class="fa fa-plus"></i>
                                    新增商品
                                </button>
                            </div>
                            <InvoiceElectronicProcessItems ref="itemsForm" :營業稅.sync="itemData.營業稅"
                                :營業稅別.sync="itemData.營業稅別" :商品明細.sync="itemData.商品明細" :發票種類.sync='edit.發票種類'
                                :isEdit.sync='itemData.isEdit' :Const銷售總額.sync="itemData.Const銷售總額"
                                :Const發票金額.sync="itemData.Const發票金額" @刪除商品="刪除商品($event)">
                            </InvoiceElectronicProcessItems>
                        </div>
                    </div>
                    <div class="box-footer">
                        <button type="button" class="btn" style="margin-right: 15px;" @click="Close">關閉</button>
                        <button type="button" class="btn btn聯別" @click="save" :disabled="編輯模式 == 編輯模式Enum.檢視">儲存</button>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
                <InvoiceElectronicProcessOneClickSetting ref="company" @companyClick="companyClick">
                </InvoiceElectronicProcessOneClickSetting>
            </div>
        </div>
    </div>
</template>
<script>

import moment from "moment";
import api from "@/assets/js/app-api";
import InvoiceElectronicProcessOneClickSetting from "./InvoiceElectronicProcessOneClickSetting.vue";
import InvoiceElectronicProcessItems from "./InvoiceElectronicProcessItems.vue";
import {
    發票種類Enum,
    發票聯別Enum,
    Get發票聯別And類型,
    手開發票常用資料類別Enum, 稅別Enum, InvoiceAPI, 取得發票單筆資料檢查器, 四捨五入
} from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
import { Validator } from "@/assets/js/Tool/Validator.js";
import datePicker from "vue-bootstrap-datetimepicker";
export const 編輯模式Enum = {
    修改: 1,
    新增: 2,
    檢視: 3,
}

export default {
    components: {
        InvoiceElectronicProcessOneClickSetting,
        InvoiceElectronicProcessItems,
        datePicker,
    },
    data() {
        return {
            發票種類Enum,
            手開發票常用資料類別Enum,
            稅別Enum,
            編輯模式Enum,
            編輯模式: 編輯模式Enum.檢視,
            發票聯別Enum,
            edit: {
                編號: -1,//唯一值，新增沒有
                發票種類: 發票種類Enum.二聯捐贈,
                愛心碼: "",
                載具條碼: "",
                公司統編: "",
                公司名稱: "",
                備註: "",
                發票時間: new moment().format("YYYY-MM-DD"),
            },
            itemData: {
                商品明細: [],
                營業稅: 0,
                營業稅別: 稅別Enum.應稅,
                isEdit: false,
                Const銷售總額: 0,//資料從server來，檢視時用，無法變更 = data.銷售總額;
                Const發票金額: 0,//資料從server來，檢視時用，無法變更 = data.發票金額;
            },
        };
    },
    computed: {
        發票聯別與類型() {
            // { 聯別: 發票聯別, 類型: 發票類別 }
            // console.error("發票種類", this.發票種類)
            if (!this.edit.發票種類) {
                return Get發票聯別And類型("2_0");
            }
            return Get發票聯別And類型(this.edit.發票種類);
        },
    },
    watch: {
        "edit.發票種類"(newV) {
            if (newV != 發票種類Enum.二聯捐贈) {
                this.edit.愛心碼 = "";
            }
            if (!(newV == 發票種類Enum.三聯紙本 || newV == 發票種類Enum.三聯交換)) {
                this.edit.公司統編 = "";
                this.edit.公司名稱 = "";
            }
            if (newV != 發票種類Enum.二聯載具) {
                this.edit.載具條碼 = "";
            }
        }
    },
    mounted() {
        $(this.$refs.PopForm).hide();
    },
    methods: {
        Open(data) {
            console.error("data...", data);
            if (!data) {
                this.編輯模式 = 編輯模式Enum.新增;
                this.edit.發票時間 = new moment().format("YYYY-MM-DD")
                this.edit.發票種類 = 發票種類Enum.二聯紙本;
                this.edit.愛心碼 = "";
                this.edit.載具條碼 = "";
                this.edit.公司統編 = "";
                this.edit.公司名稱 = "";
                this.edit.備註 = "";
                this.itemData.商品明細.splice(0, this.itemData.商品明細.length);
                this.itemData.營業稅別 = 稅別Enum.應稅;
                this.itemData.營業稅 = 0;
                this.itemData.isEdit = true;

                this.itemData.Const銷售總額 = 0;
                this.itemData.Const發票金額 = 0;
            } else {
                this.編輯模式 = data.匯入工具 ? 編輯模式Enum.檢視 : 編輯模式Enum.修改;
                this.edit.編號 = data.編號;
                this.edit.發票時間 = moment.unix(Math.floor(data.發票時間 / 1000)).format("YYYY-MM-DD");
                this.edit.發票種類 = data.發票種類;
                this.edit.愛心碼 = data.愛心碼;
                this.edit.載具條碼 = data.載具條碼;
                this.edit.公司統編 = data.統一編號;
                this.edit.公司名稱 = data.買方;
                this.edit.備註 = data.備註;
                let sn = 0;
                this.itemData.商品明細 = data.商品明細.map(({ 品名, 數量, 單價 }) => {
                    let id = ++sn;
                    return { id, 品名, 數量, 單價 };
                })
                this.itemData.營業稅別 = data.營業稅別;
                this.itemData.營業稅 = data.營業稅;
                this.itemData.isEdit = this.編輯模式 == 編輯模式Enum.檢視 ? false : true;

                this.itemData.Const銷售總額 = data.銷售總額;
                this.itemData.Const發票金額 = data.發票金額;
            }
            $(this.$refs.PopForm).show();
            $(this.$refs.loading).hide();
        },
        Close() {
            $(this.$refs.PopForm).hide();
        },
        新增商品() {
            let min = 0;
            this.itemData.商品明細.forEach(element => {
                min = element.id <= min ? element.id : min;
            });
            min = min - 1;
            this.itemData.商品明細.push({
                id: min,
                品名: "",
                數量: 1,
                單價: 0,
            });
        },
        刪除商品({ id }) {
            let index = this.itemData.商品明細.findIndex((element) => { return element.id == id });
            if (index >= 0) {
                this.itemData.商品明細.splice(index, 1);
            }
        },
        async save() {
            let { 聯別: 發票聯別, 類型: 發票類別 } = Get發票聯別And類型(this.edit.發票種類);
            let query = {
                發票日期: this.edit.發票時間,
                發票聯別,
                發票類別,
                稅別: this.itemData.營業稅別,
                稅額: this.itemData.營業稅,
                銷售總額: this.$refs.itemsForm.銷售額總計,
                發票金額: this.$refs.itemsForm.總計,
                載具條碼: this.edit.載具條碼,
                愛心碼: this.edit.愛心碼,
                統一編號: this.edit.公司統編,
                買方: this.edit.公司名稱,
                備註: this.edit.備註,
                明細: this.itemData.商品明細.map(({ 品名, 數量, 單價 }) => ({ 品名, 數量, 單價, 金額: 四捨五入(數量, 單價) }))
            };
            try {
                let errorMsg = 取得發票單筆資料檢查器(new Validator(), query).start();
                if (errorMsg) {
                    throw errorMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                return;
            }
            $(this.$refs.loading).show();
            try {
                if (this.編輯模式 == 編輯模式Enum.修改) {
                    //修改
                    query.編號 = this.edit.編號;
                    InvoiceAPI(await api.IPSSetting.UpdateManualInvoice(query));
                } else if (this.編輯模式 == 編輯模式Enum.新增) {
                    //新增
                    InvoiceAPI(await api.IPSSetting.AddManualInvoice([query]));
                } else {
                    throw "編輯模式錯誤";
                }
                this.$eventBus.$emit("showAlert", "成功");
                this.$emit("refresh", "");
                $(this.$refs.PopForm).hide();
            } catch (e) {
                try {
                    let msg = '';
                    let error = JSON.parse(e);
                    for (let i of error) {
                        msg = msg + `第${i.資料行數 + 1}筆，${i.錯誤資訊}<BR>`;
                    }
                    this.$eventBus.$emit("showAlert", msg);
                } catch (parseJson失敗) {
                    // console.error("parseJson失敗", e);
                    this.$eventBus.$emit("showAlert", e);
                }
            }
            $(this.$refs.loading).hide();
        },
        companyClick(類別, 值, 名稱) {
            if (類別 == 手開發票常用資料類別Enum.愛心碼) {
                this.edit.愛心碼 = 值;
            } else if (類別 == 手開發票常用資料類別Enum.載具條碼) {
                this.edit.載具條碼 = 值;
            } else if (類別 == 手開發票常用資料類別Enum.統一編號) {
                this.edit.公司統編 = 值;
                this.edit.公司名稱 = 名稱;
            }
            this.$refs.company.Close();
        }
    }
}
</script>
<style scoped>
.box.style2聯 {
    border-top-color: #555299 !important;
}

.box.style3聯 {
    border-top-color: #ca195a !important;
}

.box.style2聯 .btn聯別 {
    background-color: #605ca8;
    border-color: #555299;
    color: white;
}

.box.style3聯 .btn聯別 {
    background-color: #D81B60;
    border-color: #ca195a;
    color: white;
}

.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.iradio_flat-blue {
    margin-left: 0px;
}

.iradio_flat-blue[checked] {
    background-position: -110px 0;
}

.div15margin {
    margin-bottom: 15px;
}

.mymodal .form-group {
    margin-left: 0px;
    margin-right: 0px;
    /* background-color: green; */
}

.addItemBtn {
    margin-right: 10px;
}
</style>