<template>
    <div>
        <section class="content-header">
            <h1> 廠商支付金流管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'FirmSettingList',
                        params: $route.params,
                    });">廠商設定</a>
                </li>
                <li class="active">廠商支付金流管理 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <label>廠商：</label>
                        <select v-model="sel廠商" class="form-control">
                            <option value="-1">全部</option>
                            <option v-for="item in 廠商設定List" :key="item.編號" :value="item.編號">
                                {{ item.名稱 }} - {{ item.顯示名稱 }}
                            </option>
                        </select>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                        <button class="btn btn-block btn-warning btn-add" style="margin-left: 10px;" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap" v-show="sel廠商 != -1">拖曳排序
                                        <button class="btn btn-block btn-primary btn-add"
                                            :disabled="sel廠商 == -1 || isSort異動 == false" @click="saveSort()">
                                            儲存排序
                                        </button>
                                    </th>
                                    <th class="text-nowrap">廠商</th>
                                    <th class="text-nowrap">金流名稱</th>
                                    <th class="text-nowrap">金流帳號</th>
                                    <th class="text-nowrap">支付名稱</th>
                                    <th class="text-nowrap">支付方式代碼</th>
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="filterList.length > 0">
                                    <tr v-for="(item, index) in filterList" :key="item.編號">
                                        <td class="text-center" v-show="sel廠商 != -1" @dragenter="dragenter($event, index)"
                                            @dragover="dragover($event, index)" @dragstart="dragstart(index)"
                                            :draggable="sel廠商 != -1"><i class="fa fa-navicon"></i> {{ item.順序 }}</td>
                                        <td class="text-center">{{ item.showInfo.廠商 }}</td>
                                        <td class="text-center">{{ item.showInfo.金流名稱 }}</td>
                                        <td class="text-center">{{ item.showInfo.金流帳號 }}</td>
                                        <td class="text-center">{{ item.showInfo.支付名稱 }}</td>
                                        <td class="text-center">{{ item.showInfo.支付方式代碼 }}</td>
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="7">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import { IPSTool, APILib } from './IPSTool2'
export default {
    components: {
        iCheckRedioButton,
    },
    data() {
        return {
            廠商設定List: [],
            金流設定List: [],
            金流支付List: [],
            金流帳號List: [],
            list: [],
            sel廠商: -1,

            filterList: [],
            dragIndex: '',
            enterIndex: '',
            isSort異動: false,
        };
    },
    watch: {
        list(value) {
            this.更新FilterList();
        },
        sel廠商(value) {
            this.更新FilterList();
        }
    },
    // computed: {
    //     filterList() {
    //         let list = this.list.filter((item) => {
    //             return (item.廠商設定主檔編號 === parseInt(this.sel廠商) || parseInt(this.sel廠商) === -1);
    //         });
    //         for (let item of list) {
    //             item.showInfo = {};
    //             item.showInfo["廠商"] = this.get廠商Name(item.廠商設定主檔編號);
    //             item.showInfo["金流名稱"] = this.get金流名稱(item.金流設定主檔編號);
    //             item.showInfo["金流帳號"] = this.get金流帳號(item.金流帳號編號);
    //             let 支付 = this.get支付方式(item.金流支付設定編號);
    //             item.showInfo["支付名稱"] = 支付 ? 支付.支付名稱 : "";
    //             item.showInfo["支付方式代碼"] = 支付 ? 支付.支付方式代碼 : "";
    //         }
    //         return list;
    //     },
    // },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                this.廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                this.金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                this.金流支付List = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;
                this.金流帳號List = APILib.IPS(await api.IPSSetting.金流帳號_查詢({})).content;
                await this.refresh();
                this.sel廠商 = parseInt(this.$route.params.firmId);
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        async refresh() {
            let res = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;
            this.list = IPSTool.deCodeData(res, "額外限制");
        },
        更新FilterList() {
            let list = this.list.filter((item) => {
                return (item.廠商設定主檔編號 === parseInt(this.sel廠商) || parseInt(this.sel廠商) === -1);
            });
            for (let item of list) {
                item.順序 = item.順序 == null ? 0 : item.順序;
                item.showInfo = {};
                item.showInfo["廠商"] = this.get廠商Name(item.廠商設定主檔編號);
                item.showInfo["金流名稱"] = this.get金流名稱(item.金流設定主檔編號);
                item.showInfo["金流帳號"] = this.get金流帳號(item.金流帳號編號);
                let 支付 = this.get支付方式(item.金流支付設定編號);
                item.showInfo["支付名稱"] = 支付 ? 支付.支付名稱 : "";
                item.showInfo["支付方式代碼"] = 支付 ? 支付.支付方式代碼 : "";
            }
            list.sort((a, b) => {
                if (a.廠商設定主檔編號 < b.廠商設定主檔編號) {
                    return -1;
                } else if (a.廠商設定主檔編號 > b.廠商設定主檔編號) {
                    return 1;
                } else {
                    if (a.順序 < b.順序) {
                        return -1;
                    } else if (a.順序 > b.順序) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
            this.isSort異動 = false;
            this.filterList = list;
        },
        backList() {
            this.$router.push({
                name: "FirmSettingList",
                params: {},
            });
        },
        get廠商Name(廠商設定主檔編號) {
            let find = this.廠商設定List.find((item) => {
                return item.編號 === 廠商設定主檔編號;
            });
            return find ? find.名稱 + "-" + find.顯示名稱 : "";
        },
        get金流名稱(金流設定主檔編號) {
            let find = this.金流設定List.find((item) => {
                return item.編號 === 金流設定主檔編號;
            });
            return find ? find.金流名稱 + "-" + find.金流代碼 : "";
        },
        get金流帳號(金流帳號編號) {
            let find = this.金流帳號List.find((item) => {
                return item.編號 === 金流帳號編號;
            });
            return find ? find.名稱 + "-" + find.金流識別值 : "";
        },
        get支付方式(金流支付設定編號) {
            let find = this.金流支付List.find((item) => {
                return item.編號 === 金流支付設定編號;
            });
            return find;
        },
        addItem() {
            if (this.sel廠商 <= 0) {
                this.$eventBus.$emit("showAlert", "請先選擇廠商");
                return;
            }
            this.$router.push({
                name: "FirmPaySettingEdit",
                params: { firmId: this.sel廠商, firmPayId: -this.sel廠商 },
            });
        },
        async saveSort() {
            $(this.$refs.loading).show();
            try {
                let cmd = [];
                let i = 0;
                for (let item of this.filterList) {
                    i++;
                    cmd.push({ 編號: item.編號, 順序: i })
                }
                APILib.IPS(await api.IPSSetting.廠商支付設定_排序(cmd));
                this.refresh();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        editItem(item) {
            this.$router.push({
                name: "FirmPaySettingEdit",
                params: { firmId: this.sel廠商, firmPayId: item.編號 },
            });
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                delete obj.建立時間;
                delete obj.showInfo;
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                obj = IPSTool.enCodeData(obj, "額外限制");
                APILib.IPS(await api.IPSSetting.廠商支付設定_修改(obj));
                this.refresh();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        // shuffle() {
        //     console.error("這行要做什麼？")
        //     this.filterList = this.$shuffle(this.filterList);
        // },
        dragstart(index) {
            this.dragIndex = index;
        },
        dragenter(e, index) {
            e.preventDefault();
            // 避免源对象触发自身的dragenter事件
            if (this.dragIndex !== index) {
                this.isSort異動 = true;
                const moving = this.filterList[this.dragIndex];
                this.filterList.splice(this.dragIndex, 1);
                this.filterList.splice(index, 0, moving);
                // 排序变化后目标对象的索引变成源对象的索引
                this.dragIndex = index;
            }
        },
        dragover(e, index) {
            e.preventDefault();
        },
    },

};
</script>
<style scoped>
.form-control {
    display: inline;
    width: auto;
}
</style>