<template>
  <div id="PotMember" class="searchList">
    <section class="content-header">
      <h1>領取紀錄查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">領取紀錄查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="endTime"
                    :propStartDate.sync="startTime"
                    propsIn="format2"
                  />
                </div>
              </div>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                required="required"
                @change="resetDataList()"
                v-model.trim="searchData.missionType"
              >
                <option value="">全部品項</option>
                <option value="109635">會員卡</option>
                <option value="109633">珍重再見券</option>
              </select>
            </div>
            <div class="input-group search-group">
              <input
                type="text"
                class="form-control pull-right"
                placeholder="搜尋門號"
                v-model="searchData.identityKey"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="width: 10%">#</th>
                  <th class="text-nowrap" style="width: 30%">門號</th>
                  <th class="text-nowrap" style="width: 20%">品項</th>
                  <th class="text-nowrap" style="width: 40%">領取時間</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="4">查詢中</td>
                </tr>
                <tr v-else-if="total === 0">
                  <td colspan="4">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in list" :key="index">
                  <td>
                    {{ page(index) }}
                  </td>
                  <td class="text-nowrap">{{ item.identityKey }}</td>
                  <td class="text-nowrap">{{ item.missionType == '109635' ? '會員卡' : '珍重再見券' }}</td>
                  <td class="text-nowrap">{{ item.createTime | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        eventId: "10010", //固定值
        identityKey: "", //門號
        keyword: "", //固定空值
        missionType: "",
        pageNum: 1,
        pageSize: 20,
        startTime: new moment().subtract(3, "month").format("YYYY/MM/DD"),
        endTime: new moment().format("YYYY/MM/DD"),
      },
      startTime: new moment().subtract(3, "month").format("YYYY/MM/DD"),
      endTime: new moment().format("YYYY/MM/DD"),
      list: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.searchData.startTime = new moment(this.startTime).format("YYYY/MM/DD 00:00:00");
      this.searchData.endTime = new moment(this.endTime).format("YYYY/MM/DD 23:59:59");

      api.pot.member(this.searchData)
        .then((res) => {
          this.loading = false;
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
        })

    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    page(index) {
      const pageIndex = index + 1;
      const PageNum = this.searchData.pageNum;
      const PageSize = this.searchData.pageSize;
      return ((PageNum - 1) * PageSize) + pageIndex
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
      //
    },
  },
};
</script>

<style lang="scss" scoped>
#PotMember {
  .input-group {
    &.date {
      .form-control {
        width: 200px;
      }
    }
  }
}
</style>