<template>
  <div id="DeliveryRate">
    <section class="content-header">
      <h1>簡訊送達率查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊送達率查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <div class="date-group" style="width: auto;">
              <label class="control-label">時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-left">
                  <DateRangePicker
                    :propTimePicker="false"
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                  />
                </div>
              </div>
            </div>   

            <div class="text-red display-ib mr-10" style="padding-top: 7px;">
              <p>* 查詢日期區間以30天為限</p>
            </div>   
          </div>
          <div class="top-box topBox-announceList">
            <div class="select-group">
              <label for="" class="control-label">區碼：</label>
              <select
                class="form-control"
                v-model="searchData.區碼"
                @change="resetDataList()"
              >
                <option value="">全部</option>
                <option
                  v-for="(areaCode, index) in define.areaCodeList"
                  :value="areaCode.code"
                >
                  {{ areaCode.code }}({{ areaCode.name }})
                </option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">簡訊商：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.簡訊商代碼"
                @change="resetDataList()"
              >
                <option value>全部</option>
                <template
                  v-for="(com, index) in comList"
                >
                  <option :value="com.簡訊商門號代碼">
                    {{ com.簡訊商門號說明 }}
                  </option>
                </template>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">服務列表：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.帳號"
                @change="resetDataList()"
              >
                <option value>全部</option>
                <option
                  v-for="(account, index) in accountList"
                  :value="account.accId"
                >
                  {{ account.accName }}
                </option>
              </select>
            </div>

            <div class="btns mr-10">
              <button class="btn btn-primary" type="button" @click="resetDataList()">查詢</button>
            </div>    
            
            <div class="input-group display-ib ml-10" style="width: auto;">
              <label for="" class="control-label"></label>
              <div class="checkbox">
                <label>
                  <iCheckCheckBox
                    v-bind:propChecked="searchData.不分區碼YN"
                    v-on:update:checked="searchData.不分區碼YN=$event['checked']"
                  />
                </label>
                <span>不分區碼(隱藏區碼欄位)</span>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 15%;" v-if="searchData.不分區碼YN == 0">區碼</th>
                  <th class="text-nowrap" style="width: 20%;">簡訊商</th>
                  <th class="text-nowrap" style="width: 20%;">服務</th>
                  <th class="text-nowrap" style="width: 5%;">發送筆數</th>
                  <th class="text-nowrap" style="width: 5%;">到達筆數</th>
                  <th class="text-nowrap" style="width: 10%;">送達率</th>
                </tr>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="7">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="7">沒有資料</td>
                  </tr>
                </template>
                <template v-else v-for="(item, index) in list">
                  <tr>
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center" v-if="searchData.不分區碼YN == 0">{{ area(item.區碼) }}</td>
                    <td class="text-center">{{ comListName[item.簡訊商代碼] }}</td>
                    <td class="text-center">{{ item.名稱 }}</td>
                    <td class="text-center">{{ item.筆數 }}</td>
                    <td class="text-center">{{ item.送達筆數 }}</td>
                    <td class="text-center">{{ item.送達率 }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import api from "@/assets/js/app-api";
import iCheckCheckBox from "@/components/iCheck_CheckBox";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        區碼: "", //全部為空值
        簡訊商代碼: "", //簡訊商；全部為空值
        帳號: "", //服務；全部為空值
        不分區碼YN: 0, //0 分區碼，1 不分區碼
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD"),
        結束時間: new moment().format("YYYY-MM-DD"),
      },
      list: [],
      total: 0,
      accountList: [],
      comList: [],
      comListName: []
    }
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    this.getAccountList();
    this.getComList();
    this.getDataList();
  },
  watch: {
    'searchData.不分區碼YN'() {
      this.resetDataList();
    }
  },
  mounted() {},
  computed: {},
  methods: {
    getDataList() {
      this.loading = true;
      api.moloSMS.deliveryRate(this.searchData)
        .then((res) => {
          this.loading = false;
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    getAccountList() {
      api.moloSMS.user.queryAll({
        IsOpen: 1, //帳號 1:啟用 0:停用
        IsScmOpen: -1, //互動簡訊 1:啟用 0:停用
      })
        .then((res) => {
          this.accountList = res.data.result;
        })
    },
    getComList() {
      api.moloSMS.user.SmsComTypeQueryAll()
        .then((res) => {
          this.comList = res.data.result;
          res.data.result.forEach(item => {
            this.comListName[item.簡訊商門號代碼] = item.簡訊商門號說明;
          });
        })
    },
    area(data) {
      const Index = this.define.areaCodeList.findIndex(item => item.code == data)
      return `${this.define.areaCodeList[Index].code}(${this.define.areaCodeList[Index].name})`
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    save() {
      api.moloSMS.block.create({
        區碼: this.data.區碼,
        門號: this.data.門號,
        來源集合: this.checkIds
      })
        .then((res) => {
          this.closeModal();
          this.global.showResponse(
            this, 
            res, 
            'create', 
            this.getDataList()
          )
        })
    },
  }
}
</script>
<style lang="scss">
#CustomizeList {
  .modal-dialog {
    .table-responsive {
      max-height: 60vh;
    }
  }
}
</style>