<template>
    <div>
        <section class="content-header">
            <h1>退貨明細</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">退貨明細</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="form-group">
                            <label class="control-label">服務名稱：</label>
                            <select class="selPaymentType form-control" v-model="searchData.服務編號">
                                <!-- <option value="">全部</option> -->
                                <option v-for="item in serverList" :key="item.廠商編號" :value="item.廠商編號">{{ item.名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" placeholder="請輸入訂單編號"
                                v-model="searchData.訂單編號" />
                        </div>
                        <div class="form-group">
                            <input type="text" class="selPaymentType form-control" placeholder="請輸入門號"
                                v-model="searchData.門號" />
                        </div>

                        <div class="form-group">
                            <label class="control-label">查詢日期：</label>
                            <div class="input-group date">
                                <div class="input-group-addon">
                                    <i class="fa fa-calendar"></i>
                                </div>
                                <div class="pull-right">
                                    <DateRangePicker v-bind:propStartDate.sync="searchData.開始時間"
                                        v-bind:propEndDate.sync="searchData.結束時間" ref="dateRangePicker" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button @click="onPageChange(1)" class="btn btn-primary" type="button">
                                查詢
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">訂單編號</th>
                                    <th class="text-nowrap">服務名稱</th>
                                    <th class="text-nowrap">門號</th>
                                    <th class="text-nowrap">銀行代碼</th>
                                    <th class="text-nowrap">銀行戶名</th>
                                    <th class="text-nowrap">銀行分行</th>
                                    <th class="text-nowrap">銀行帳號</th>
                                    <th class="text-nowrap">收款人</th>
                                    <th class="text-nowrap">訂單金額</th>
                                    <th class="text-nowrap">退貨金額</th>
                                    <th class="text-nowrap">備註</th>
                                    <th class="text-nowrap">建立日期</th>
                                    <th class="text-nowrap">功能</th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.訂單編號">
                                        <td class="text-center">{{ item.訂單編號 }}</td>
                                        <td class="text-center">{{ item.服務名稱 }}</td>
                                        <td class="text-center">{{ item.門號 }}</td>
                                        <td class="text-center">{{ item.銀行代碼 }}</td>
                                        <td class="text-center">{{ item.銀行戶名 }}</td>
                                        <td class="text-center">{{ item.銀行分行 }}</td>
                                        <td class="text-center">{{ item.銀行帳號 }}</td>
                                        <td class="text-center">{{ item.收款人 }}</td>
                                        <td class="text-center">{{ item.訂單金額 }}</td>
                                        <td class="text-center">{{ item.退貨金額 }}</td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td class="text-center">{{ $utils.formatTime(item.建立日期,
                                "YYYY/MM/DD HH:mm:ss")
                                            }}</td>
                                        <td>
                                            <button class="btn btn-danger" @click="openRefundForm(item)">修改</button>
                                        </td>

                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="11">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="total > 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
            </div>
        </section>
        <RefoundForm ref="refoundForm" @更新退貨資料="queryAll(lastQueryCommand)"></RefoundForm>
    </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import { IPSTool } from './IPSTool2'
import RefoundForm from "./TransactionInfoList/RefoundForm.vue";
export default {
    components: {
        DateRangePicker,
        Pagination,
        RefoundForm
    },
    data() {
        let pageSize = 20;
        return {
            pageSize,
            total: 0,
            searchData: {
                服務編號: "",
                開始時間: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),//*/"2020/02/01 00:00:00",
                結束時間: new moment().format("YYYY-MM-DD 23:59:59"),//*/"2023/03/01 00:00:00",
                訂單編號: "",
                門號: "",
                startNo: 1,
                endNo: pageSize
            },
            serverList: [],
            list: [],
            lastQueryCommand: null
        };
    },
    watch: {
        "searchData.訂單編號"(value) {
            this.$refs.dateRangePicker.$refs.datepicker.disabled = (value != "") ? true : false;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.serverList = await IPSTool.ConstServerList.call(this, this.$store.state.projectId, "退貨明細");//廠商專案列表;

            if (this.serverList.length > 0) {
                this.searchData.服務編號 = this.serverList[0].廠商編號;
            }
        },
        onPageChange(pageIdx) {
            this.searchData.startNo = (pageIdx - 1) * this.pageSize + 1;
            this.searchData.endNo = pageIdx * this.pageSize;
            let send = JSON.parse(JSON.stringify(this.searchData));
            this.queryAll(send, pageIdx);
        },
        async queryAll(send, pageIdx) {
            if (!send) {
                return;
            }
            if (!send.服務編號) {
                this.$eventBus.$emit("showAlert", "請選擇服務編號");
            }
            if (send.訂單編號 != "") {
                delete send.開始時間;
                delete send.結束時間;
            } else {
                send.開始時間 = this.$utils.formatTime(send.開始時間, "YYYY-MM-DD");
                send.結束時間 = this.$utils.formatTime(send.結束時間, "YYYY-MM-DD");
            }

            // send.開始時間 = this.$utils.formatTime(send.開始時間, "YYYY-MM-DD");
            // send.結束時間 = this.$utils.formatTime(send.結束時間, "YYYY-MM-DD");
            let tmp = await IPSTool.callAPIList.call(this, api.IPSSetting.RefundSearch, send);
            let list = tmp.list;
            for (let item of list) {
                let 服務名稱 = this.find服務名稱(item.服務編號);
                this.$set(item, "服務名稱", 服務名稱);
            }
            this.list = list;
            this.total = tmp.total;
            this.lastQueryCommand = send;
            if (pageIdx > 0) {
                this.$refs.pagination.setPage(
                    pageIdx,
                    this.total
                );
            }
        },
        openRefundForm({ serviceID: 服務編號, 訂單編號 }) {
            this.$refs.refoundForm.show(true, {
                訂單編號,
                服務編號
            });
        },
        find服務名稱(服務編號) {
            let find = this.serverList.find((item) => { return item.廠商編號 + "" === 服務編號 + "" })
            return find ? find.顯示名稱 : 服務編號;
        }
    },
};
</script>

<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.selPaymentType {
    width: 150px;
}
</style>