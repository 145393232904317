<template>
  <div id="AnnouncementEdit" class="slot max1040">
    <section class="content-header">
      <h1>項目設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲管理</li>
        <li>活動機制</li>
        <li>機制項目</li>
        <li class="active">項目設定</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <h4 id="title">
            {{ $route.params.name }}
          </h4>
          <div class="top-box with-border"></div>
          <div class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 項目選擇：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="data.項目"
                  >
                    <option value="">請選擇項目</option>
                    <option :value="1">主項目</option>
                    <option :value="2">次項目</option>
                    <option :value="3">延伸項目</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 項目名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    maxlength="50"
                    v-model="data.項目名稱"
                  />
									<span>{{ data.項目名稱.length }} / 50</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  詳細說明：
                </label>
                <div class="col-sm-10">
                  <div class="editor">

                    <tinymce v-model="data.詳細說明" />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
								<button
									class="btn btn-warning pull-left mr-5"
                  :disabled="
                    data.項目 == '' ||
                    data.項目名稱 == ''
                  "
                  @click="preview()"
								>
									預覽
								</button>
								<button
									class="btn btn-primary pull-left"
                  @click="save()"
                  :disabled="
                    data.項目 == '' ||
                    data.項目名稱 == ''
                  "
								>
									儲存
								</button>
								<button
									class="btn btn-default pull-right"
                  @click="backList()"
								>
									返回
								</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--預覽-->
			<div class="modal fade" ref="preview">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
							<h4 class="modal-title text-center">預覽</h4>
						</div>
						<div class="modal-body">
							<form class="form-horizontal" name="announcementForm">
								<div class="box-body">
									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap" style="padding-top: 0;">
											項目選擇：
										</label>
										<div class="col-sm-10">
                      {{ data.項目 == 1 ? '主項目' : data.項目 == 2 ? '次項目' : '延伸項目' }}
                    </div>
									</div>
									
									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap" style="padding-top: 0;">
											項目名稱：
										</label>
										<div class="col-sm-10">
                      {{ data.項目名稱 }}
										</div>
									</div>

									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap" style="padding-top: 0;">
											詳細說明：
										</label>
										<div class="col-sm-10">
                      <div v-html="data.詳細說明"></div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import api from "@/assets/js/app-api";
import tinymce from "@/components/Tinymce";

export default {
  components: {
		tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      isEdit: false,
      id: Number(this.$route.params.id) || '',
      data: {
        遊戲編號: Number(this.$route.params.gameId) || '',
        項目: "",//1：主項目 2：次項目 3：延伸項目
        項目名稱: "",
        詳細說明: "",
        是否啟用: 1,
      }
		};
  },
  created() {},
  mounted() {
    if(this.id) {
      this.getData();
      this.isEdit = true;
    } 
  },
  computed: {},
  watch: {},
  methods: {
    getData() {
      api.slotEvent.item.query({ 編號: this.id })
        .then((res) => {
          this.data = res.data.result;
        })
    },
    save() {
      if(this.isEdit) this.data.編號 = this.id;
      let type = this.isEdit ? 'update' : 'create'

      api.slotEvent.item[type](this.data)
        .then((res) => {
          if(res.data.status = "1") {
            this.$eventBus.$emit("showAlert", "更新成功!");
            this.backList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        })
    },
    preview() {
      $(this.$refs.preview).modal("show");
    },
    backList() {
      let searchData = this.$route.params.backSearchData;
      if(this.data.項目) searchData.項目 = this.data.項目;
			this.$router.push({
				name: 'slotActivityItemSet',
				params: {
          id: this.$route.params.gameId,
          name: this.$route.params.name,
          searchData: this.$route.params.backSearchData,
				},
			});
		}
  },
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>