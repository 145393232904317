<template>
  <div id="SMSProvider">
    <section class="content-header">
      <h1>簡訊發送備援切換</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">簡訊發送備援切換</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 國內
                </label>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 預設發送簡訊商：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    readonly="readonly"
                    value="Every8D"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 目前發送簡訊商：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    readonly="readonly"
                    v-model="this.localProviderName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span></span> 切換發送簡訊商：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    id="localSelect"
                    v-model="this.localProvider"
                  >
                  <option 
                    :value="item.val"
                    v-for="(item, index) in localProviderOption"
                    :key="index"
                  >{{item.name}}</option>
                  </select>
                </div>
                <div class="col-sm-2">
                  <div class="input-group-btn">
                    <button v-on:click="setDefaultProvider(1)" class="btn btn-default" type="button">
                      切換
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 國外
                </label>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 預設發送簡訊商：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    readonly="readonly"
                    value="Every8D"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 目前發送簡訊商：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    readonly="readonly"
                    v-model.trim="this.overseaProviderName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span></span> 切換發送簡訊商：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    id="overseaSelect"
                    v-model="this.overseaProvider"
                  >
                  <option 
                    :value="item.val"
                    v-for="(item, index) in overseaProviderOption"
                    :key="index"
                  >{{item.name}}</option>
                  </select>
                </div>
                <div class="col-sm-2">
                  <div class="input-group-btn">
                    <button v-on:click="setDefaultProvider(2)" class="btn btn-default" type="button">
                      切換
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import multiselect from "vue-multiselect";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import smsInfo from '@/static/json/sms.json';
export default {
  components: {
    multiselect,
    tinymce,
    iCheckRedioButton,
  },
  data() {
    return {
      timer: null,
      allProvider: [],
      localProviderOption: [],
      overseaProviderOption: [],
      setData: {
        defaultType: 1,
        defaultSms: 12
      },
      localProvider: 0,
      localProviderName: "",
      overseaProvider: 0,
      overseaProviderName: "",
    };
  },
  
  // async created() {
    
  // },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").unbind("focus");
    this.checkSession();
    this.getDefaultProvider();
    this.getProviderList();
  },
  methods: {
    getProviderList() {
      this.allProvider = smsInfo[0].allProvider;
      var localOption = [];
      var overseaOption = [];
      // 取得設定檔資料塞入選項
      $.each(smsInfo[0].localProvider, function(idx, option){
        $.each(option, function(val, name){
          localOption.push({name: name, val: val});
        });
      });
      this.localProviderOption = localOption;

      $.each(smsInfo[0].overseaProvider, function(idx, option){
        $.each(option, function(val, name){
          overseaOption.push({name: name, val: val});
        });
      });
      this.overseaProviderOption = overseaOption;
    },
    getDefaultProvider() {
      this.$store
        .dispatch("sms/getDefaultProvider", [])
        .then((result) => {
          this.localProvider = result.defaultInternal;
          this.overseaProvider = result.defaultOversea;
          this.localProviderName = this.getProviderName(this.localProvider);
          this.overseaProviderName = this.getProviderName(this.overseaProvider);
        });
    },
    setDefaultProvider(type) {
      this.setData.defaultType = type;
      this.setData.defaultSms = this.getProviderByType(type);
      this.$store
        .dispatch("sms/setDefaultProvider", this.setData)
        .then((result) => {
          if (result.status == "1"){
            this.getDefaultProvider();
            this.$eventBus.$emit("showAlert", "修改成功!");
          }else{
            this.$eventBus.$emit("showAlert", "修改失敗!");
          }
        });
    },
    getProviderByType(type) {
      // 1：國內   2：國外
      var provider = (type == 2) ? $("#overseaSelect").val() : $("#localSelect").val();
      return parseInt(provider);
    },
    getProviderName(id) {
      return this.allProvider[id];
    },
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
      }, 600000);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>
