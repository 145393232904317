<template>
	<div id="activityList" class="slot">
		<section class="content-header">
			<h1>{{ type == 'normal' ? '常態活動' : '非常態活動'}}</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li>活動機制</li>
				<li class="active">{{ type == 'normal' ? '常態活動' : '非常態活動'}}</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
          <h4 id="title">{{ $route.params.name }}</h4>
					<div class="top-box with-border">
            <button @click="backList()" class="btn btn-block btn-default btn-add" type="button">
              返回
            </button>
            <button @click="add()" class="btn btn-block btn-warning btn-add mr-10" type="button">
							新增
						</button>
            <div class="text">
							<p class="data-num">
								<span>資料總筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th style="min-width: 150px;" width="20%">
										{{ type == 'normal' ? '時間' : '活動名稱' }}
									</th>
									<th style="min-width: 150px;" width="30%">
										{{ type == 'normal' ? '週期活動' : '活動簡述' }}
									</th>
									<th style="width: 200px;">建立時間</th>
									<th style="width: 200px;">更新時間</th>
									<th v-if="type == 'normal'" class="text-nowrap text-center" style="min-width: 100px;">排序</th>
									<th class="text-nowrap text-center" style="min-width: 100px;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px;">功能</th>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td>{{ item.活動時間 || item.活動名稱 }}</td>
									<td>
										<div class="text-ellipsis" v-if="type == 'normal'" v-html="item.內容"></div>
										<div class="text-ellipsis" v-else>{{ item.活動說明 }}</div>
									</td>
									<td class="text-nowrap text-center">{{ item.建立時間 | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
									<td class="text-nowrap text-center">{{ item.更新時間 | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
									<td class="td-sort text-nowrap" v-if="type == 'normal'">
										<input
                      class="form-control text-center mr-10"
                      required="required"
                      type="number"
                      v-model="item.排序數字"
											style="
												width: 60px;
												display: inline-block;
												vertical-align: middle;
											"	
                    />
										<button
											class="btn btn-warning btn-add btn-upDate"
											type="button"
											@click="updateOrder(item)"
										>更新</button>
									</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item, true)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border">
					<Pagination
						ref="pagination"
						:total="1"
						:pageLimitCount="1"
						/>
						<!-- v-on:pageMethod="onPageChange" -->
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
  import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
		},
    delimiters: ["${", "}"],
    data() {
      return {
				isEdit: false,
				type: this.$route.params.type,
				searchData: {
					遊戲編號: Number(this.$route.params.id),
					// 是否啟用: 0,
					PageNum: 1,
					PageSize: 20,
				},
				list: [],
				total: 0,
      };
    },
    created() {
			this.getDataList();
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
			getDataList() {
				api.slotEvent[this.type].queryAll(this.searchData)
					.then((res) => {
						console.log(res.data);
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
					})
			},
			updateOrder(item) {
				item.遊戲編號 = this.searchData.遊戲編號;
				item.排序數字 = Number(item.排序數字);
				api.slotEvent[this.type].order({
					編號: item.編號,
					遊戲編號: item.遊戲編號,
					排序數字: Number(item.排序數字),
				})
					.then((res) => {
						if(res.data.status == '1') {
							this.$eventBus.$emit("showAlert", "更新成功!");
							this.getDataList();
						} else {
							let message = res.data.message != '' ? res.data.message : '更新失敗'; 
							this.$eventBus.$emit("showAlert", message);
						}
					})
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}資料？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}此資料`,
				isOK => {
					if (isOK) {
						api.slotEvent[this.type].state({
							編號: item.編號,
							遊戲編號: this.searchData.遊戲編號,//必傳
							是否啟用: item.是否啟用 == 1 ? 0 : 1,
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
					}
				});
			},
			add() {
				this.$router.push({
					name: 'slotActivityEdit',
					params: {
						gameId: this.searchData.遊戲編號,
						type: this.type,
						name: this.$route.params.name
					},
				});
			},
			edit(item) {
				this.$router.push({
					name: 'slotActivityEdit',
					params: {
						gameId: this.searchData.遊戲編號,
						type: this.type,
						id: item.編號,
						name: this.$route.params.name
					},
				});
			},
      backList() {
        this.$router.push({
					name: 'slotActivity',
					// params: {
					// 	searchData: this.searchData,
					// },
				});
      }
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
	#activityList .text-ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-moz-line-clamp: 2;
		-ms-line-clamp: 2;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		-ms-box-orient: vertical;
	}
</style>