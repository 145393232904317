/* eslint-disable */
export class NObject {
    Equals(other) {
        return this === other;
    }
    GetHashCode() {
        return NString.GetHashCode(this.toString());
    }
    ToString() {
        return this.GetType().Name;
    }
    toString() {
        return this.ToString();
    }
    GetType() {
        return new Type(this.constructor.toString().match(/function (\w*)/)[1]);
    }
    static ReferenceEquals(x, y) {
        return x === y;
    }
    static GenericEquals(x, y) {
        if (typeof x === "object")
            return x.Equals(y);
        return x === y;
    }
    static GenericToString(x) {
        if (typeof x === "object")
            return x.ToString();
        return x.toString();
    }
    static GenericGetHashCode(x) {
        if (typeof x === "object")
            return x.GetHashCode();
        return NString.GetHashCode(this.toString());
    }
}
export class Exception extends NObject {
    constructor(message = "") {
        super();
        this.Message = message;
    }
    ToString() {
        return "Exception: " + this.Message;
    }
}
export class NEvent {
    constructor() {
        this.listeners = new Array();
    }
    Add(listener) {
        this.listeners.push(listener);
    }
    Remove(listener) {
        var index = this.listeners.indexOf(listener);
        if (index < 0)
            return;
        this.listeners.splice(index, 1);
    }
    ToMulticastFunction() {
        if (this.listeners.length === 0)
            return null;
        return function () {
            for (var i in this.listeners) {
                this.listeners[i].call(arguments);
            }
        };
    }
}
export class NArray {
    static IndexOf(values, value) {
        var i, n = values.length;
        for (i = 0; i < n; i++) {
            if (values[i] === value)
                return i;
        }
        return -1;
    }
    static ToEnumerable(array) {
        return new Array_Enumerable(array);
    }
    static Resize(parray, newLength) {
        if (parray[0] === null) {
            parray[0] = new Array(newLength);
            return;
        }
        if (parray[0].length === newLength) {
            return;
        }
        throw new NotImplementedException();
    }
    static Copy(sourceArray, sourceIndex, destinationArray, destinationIndex, length) {
        for (var index = 0; index < length; index++) {
            destinationArray[index + destinationIndex] = sourceArray[index + sourceIndex];
        }
    }
    static Uint8ArrayCopy(sourceArray, sourceIndex, destinationArray, destinationIndex, length) {
        for (var index = 0; index < length; index++) {
            destinationArray[index + destinationIndex] = sourceArray[index + sourceIndex];
        }
    }
    static Clear(array, index, length) {
        for (var i = 0; i < length; i++) {
            array[i] = null;
        }
    }
    static Uint8ArrayClear(array, index, length) {
        for (var i = 0; i < length; i++) {
            array[i] = 0;
        }
    }
}
export class NNumber {
    static Parse(text, styleOrProvider, provider) {
        return parseFloat(text);
    }
    static ToString(num, providerOrFormat, provider) {
        return num.toString();
    }
    static GetHashCode(num) {
        return num;
    }
    static IsInfinity(num) {
        return num === Infinity;
    }
    static TryParse(str, pvalue) {
        try {
            pvalue[0] = parseFloat(str);
            return true;
        }
        catch (ex) {
            pvalue[0] = 0;
            return false;
        }
    }
    static IsNaN(num) {
        return isNaN(num);
    }
}
export class NBoolean {
    static TryParse(str, pbool) {
        throw new NotImplementedException;
    }
    static GetHashCode(bool) {
        return bool ? 1 : 0;
    }
}
export class NChar {
    static IsWhiteSpace(ch) {
        return ch === 32 || (ch >= 9 && ch <= 13) || ch === 133 || ch === 160;
    }
    static IsLetter(ch) {
        return (65 <= ch && ch <= 90) || (97 <= ch && ch <= 122) || (ch >= 128 && ch !== 133 && ch !== 160);
    }
    static IsLetterOrDigit(ch) {
        return (48 <= ch && ch <= 57) || (65 <= ch && ch <= 90) || (97 <= ch && ch <= 122) || (ch >= 128 && ch !== 133 && ch !== 160);
    }
    static IsDigit(chOrStr, index) {
        if (arguments.length == 1) {
            return 48 <= chOrStr && chOrStr <= 57;
        }
        else {
            var ch = chOrStr.charCodeAt(index);
            return 48 <= ch && ch <= 57;
        }
    }
}
export class NString {
    static IndexOf(str, chOrSub, startIndex) {
        var sub;
        if (chOrSub.constructor instanceof Number) {
            sub = String.fromCharCode(chOrSub);
        }
        else {
            sub = chOrSub;
        }
        return str.indexOf(sub);
    }
    static IndexOfAny(str, subs) {
        for (var i = 0; i < str.length; ++i) {
            var c = str.charCodeAt(i);
            for (var j = 0; j < subs.length; ++j) {
                if (c == subs[j])
                    return i;
            }
        }
        return -1;
    }
    static GetHashCode(str) {
        var hash = 0, i, l, ch;
        if (str.length == 0)
            return hash;
        for (i = 0, l = str.length; i < l; i++) {
            ch = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + ch;
            hash |= 0;
        }
        return hash;
    }
    static Replace(str, pattern, replacement) {
        var ps = (pattern.constructor instanceof Number) ? String.fromCharCode(pattern) : pattern;
        var rs = (replacement.constructor instanceof Number) ? String.fromCharCode(replacement) : replacement;
        return str.replace(ps, rs);
    }
    static Substring(str, startIndex, length = -1) {
        return length < 0 ? str.substr(startIndex) : str.substr(startIndex, length);
    }
    static Remove(str, startIndex, length) {
        if (typeof str === undefined) {
            return str.substring(startIndex);
        }
        else {
            return str.substring(0, startIndex - 1) + str.substring(startIndex + length);
        }
    }
    static Trim(str) {
        return str.trim();
    }
    static TrimStart(str, trimChars) {
        throw new NotImplementedException();
    }
    static TrimEnd(str, trimChars) {
        throw new NotImplementedException();
    }
    static ToUpperInvariant(str) {
        return str.toUpperCase();
    }
    static ToLowerInvariant(str) {
        return str.toLowerCase();
    }
    static Contains(str, sub) {
        return str.indexOf(sub) >= 0;
    }
    static StartsWith(str, sub, comp) {
        return str.indexOf(sub) === 0;
    }
    static EndsWith(str, sub, comp) {
        return str.indexOf(sub) === str.length - sub.length;
    }
    static Format(format, arg0, arg1, arg2, arg3, arg4, arg5) {
        if (arg0.constructor === Array) {
            var s = format, i = arg0.length;
            while (i--) {
                s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arg0[i]);
            }
            return s;
        }
        else {
            var args = [arg0, arg1, arg2, arg3, arg4, arg5];
            return this.Format(format, args, null, null, null, null, null);
        }
    }
    static IsNullOrEmpty(str) {
        return !str;
    }
    static Join(separator, parts) {
        throw new NotImplementedException();
    }
    static Concat(parts) {
        throw new NotImplementedException();
    }
    static FromChars(chOrChars, count = 1) {
        if (chOrChars.constructor instanceof Number) {
            var r = String.fromCharCode(chOrChars);
            for (var i = 2; i < count; i++) {
                r += String.fromCharCode(chOrChars);
            }
            return r;
        }
        throw new NotImplementedException();
    }
}
NString.Empty = "";
var StringComparison;
(function (StringComparison) {
    StringComparison[StringComparison["InvariantCultureIgnoreCase"] = 0] = "InvariantCultureIgnoreCase";
    StringComparison[StringComparison["Ordinal"] = 1] = "Ordinal";
})(StringComparison || (StringComparison = {}));
export class NMath extends NObject {
    static Truncate(value) {
        return value >= 0 ? Math.floor(value) : Math.ceil(value);
    }
    static Log(a, newBase = Math.E) {
        if (newBase === Math.E)
            return Math.log(a);
        return Math.log(a) / Math.log(newBase);
    }
    static Round(a, decimals = 0) {
        if (decimals === 0)
            return Math.round(a);
        var s = Math.pow(10, decimals);
        return Math.round(a * s) / s;
    }
    static Cosh(x) {
        throw new NotImplementedException();
    }
    static Sinh(x) {
        throw new NotImplementedException();
    }
    static Tanh(x) {
        throw new NotImplementedException();
    }
}
export class Type extends NObject {
    constructor(Name) {
        super();
        this.Name = Name;
    }
    Equals(obj) {
        return (obj instanceof Type) && (obj.Name === this.Name);
    }
}
export class Nullable extends NObject {
    constructor(value = null) {
        super();
        this.Value = value;
    }
    get HasValue() { return this.Value != null; }
}
export var DateTimeKind;
(function (DateTimeKind) {
    DateTimeKind[DateTimeKind["Local"] = 0] = "Local";
    DateTimeKind[DateTimeKind["Unspecified"] = 1] = "Unspecified";
    DateTimeKind[DateTimeKind["Utc"] = 2] = "Utc";
})(DateTimeKind || (DateTimeKind = {}));
export var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek[DayOfWeek["Sunday"] = 0] = "Sunday";
    DayOfWeek[DayOfWeek["Monday"] = 1] = "Monday";
    DayOfWeek[DayOfWeek["Tuesday"] = 2] = "Tuesday";
    DayOfWeek[DayOfWeek["Wednesday"] = 3] = "Wednesday";
    DayOfWeek[DayOfWeek["Thursday"] = 4] = "Thursday";
    DayOfWeek[DayOfWeek["Friday"] = 5] = "Friday";
    DayOfWeek[DayOfWeek["Saturday"] = 6] = "Saturday";
})(DayOfWeek || (DayOfWeek = {}));
export class DateTime extends NObject {
    constructor(year = 1, month = 1, day = 1) {
        super();
        this.dt = new Date(year, month - 1, day);
        this.kind = DateTimeKind.Unspecified;
    }
    get Kind() { return this.kind; }
    get Year() { return this.kind === DateTimeKind.Utc ? this.dt.getUTCFullYear() : this.dt.getFullYear(); }
    get Month() { return this.kind === DateTimeKind.Utc ? this.dt.getUTCMonth() + 1 : this.dt.getMonth() + 1; }
    get Day() { return this.kind === DateTimeKind.Utc ? this.dt.getUTCDate() : this.dt.getDate(); }
    get DayOfWeek() { return this.dt.getDay(); }
    ToString() {
        return this.kind === DateTimeKind.Utc ? this.dt.toUTCString() : this.dt.toString();
    }
    static get UtcNow() {
        var d = new DateTime();
        d.dt = new Date();
        d.kind = DateTimeKind.Utc;
        return d;
    }
    static get Now() {
        var d = new DateTime();
        d.dt = new Date();
        d.kind = DateTimeKind.Local;
        return d;
    }
    static op_Subtraction(x, y) {
        return TimeSpan.FromSeconds((x.dt.getTime() - y.dt.getTime()) / 1000);
    }
    static op_GreaterThanOrEqual(x, y) {
        return x.dt >= y.dt;
    }
}
export class TimeSpan extends NObject {
    constructor(ticks) {
        super();
        this.ticks = ticks;
    }
    get TotalDays() {
        throw new NotImplementedException();
    }
    get Days() {
        throw new NotImplementedException();
    }
    get Hours() {
        throw new NotImplementedException();
    }
    get Minutes() {
        throw new NotImplementedException();
    }
    get Seconds() {
        throw new NotImplementedException();
    }
    static FromSeconds(seconds) {
        return new TimeSpan(seconds * 100e9);
    }
    static FromDays(days) {
        var hours = days * 24;
        var minutes = 60 * hours;
        return TimeSpan.FromSeconds(60 * minutes);
    }
    static op_GreaterThanOrEqual(x, y) {
        return x.ticks >= y.ticks;
    }
}
export class NConsole extends NObject {
    static WriteLine(lineOrFormat, arg0) {
        throw new NotImplementedException();
    }
}
export class ArgumentException extends Exception {
    constructor(nameOrMessage, name) {
        super();
    }
}
export class ArgumentNullException extends ArgumentException {
    constructor(name) {
        super(name);
    }
}
export class ArgumentOutOfRangeException extends ArgumentException {
    constructor(name) {
        super(name);
    }
}
export class EventArgs extends NObject {
}
export class EventHandler extends NObject {
    Invoke(sender, e) {
    }
}
export class InvalidOperationException extends Exception {
}
export class Environment {
}
Environment.NewLine = "\n";
export class Convert extends NObject {
    static ToUInt16(str) {
        var value = Number(str);
        if (value < 0)
            value = 0;
        if (value >= 0xFFFF)
            value = 0xFFFF;
        return value;
    }
    static ToUInt32(str) {
        var value = Number(str);
        if (value < 0)
            value = 0;
        if (value >= 0xFFFFFFFF)
            value = 0xFFFFFFFF;
        return value;
    }
    static ToString(num, radixOrProvider) {
        throw new NotImplementedException();
    }
}
export class NumberFormatInfo extends NObject {
    constructor() {
        super(...arguments);
        this.NumberDecimalSeparator = ".";
        this.NumberGroupSeparator = ",";
    }
}
export var NumberStyles;
(function (NumberStyles) {
    NumberStyles[NumberStyles["HexNumber"] = 0] = "HexNumber";
})(NumberStyles || (NumberStyles = {}));
export class Encoding extends NObject {
}
Encoding.UTF8 = new Encoding();
export class CultureInfo extends NObject {
    constructor(name) {
        super();
        this.Name = "Invariant";
        this.nfi = new NumberFormatInfo();
    }
    GetFormat(type) {
        if (type.Name === "NumberFormatInfo") {
            return this.nfi;
        }
        return null;
    }
}
CultureInfo.InvariantCulture = new CultureInfo("Invariant");
CultureInfo.CurrentCulture = CultureInfo.InvariantCulture;
export class NotImplementedException extends Exception {
    constructor(message = "Not implemented") {
        super(message);
    }
}
export class NotSupportedException extends Exception {
    constructor(message = "Not supported") {
        super(message);
    }
}
export class OverflowException extends Exception {
    constructor() {
        super("Overflow");
    }
}
class List extends NObject {
    constructor(itemsOrCapacity) {
        super();
        this.array = new Array();
        if (arguments.length == 1 && itemsOrCapacity.constructor !== Number) {
            this.AddRange(itemsOrCapacity);
        }
    }
    Add(item) {
        this.array.push(item);
    }
    AddRange(items) {
        var e = items.GetEnumerator();
        while (e.MoveNext()) {
            this.Add(e.Current);
        }
    }
    get Count() {
        return this.array.length;
    }
    get_Item(index) {
        return this.array[index];
    }
    set_Item(index, value) {
        this.array[index] = value;
    }
    GetEnumerator() {
        return new List_Enumerator(this);
    }
    RemoveAt(index) {
        this.array.splice(index, 1);
    }
    RemoveRange(index, count) {
        throw new NotImplementedException();
    }
    Insert(index, item) {
        this.array.splice(index, 0, item);
    }
    Clear() {
        this.array = new Array();
    }
    ToArray() {
        return this.array.slice(0);
    }
    RemoveAll(predicate) {
        var newArray = new Array();
        for (var i = 0; i < this.array.length; i++) {
            if (!predicate(this.array[i]))
                newArray.push(this.array[i]);
        }
        this.array = newArray;
    }
    Reverse() {
        throw new NotImplementedException();
    }
    IndexOf(item) {
        return this.array.indexOf(item);
    }
}
export class Array_Enumerator extends NObject {
    constructor(array) {
        super();
        this.index = -1;
        this.array = array;
    }
    MoveNext() {
        this.index++;
        return this.index < this.array.length;
    }
    get Current() {
        return this.array[this.index];
    }
    Dispose() {
    }
}
export class Array_Enumerable extends NObject {
    constructor(array) {
        super();
        this.array = array;
    }
    GetEnumerator() {
        return new Array_Enumerator(this.array);
    }
}
export class List_Enumerator extends Array_Enumerator {
    constructor(list) {
        super(list.array);
    }
}
export class Stack extends List {
    Push(item) {
        this.Add(item);
    }
    Pop() {
        throw new NotImplementedException();
    }
}
export class HashSet extends NObject {
    constructor() {
        super(...arguments);
        this.store = {};
    }
    Add(item) {
        throw new NotImplementedException();
    }
    GetEnumerator() {
        throw new NotImplementedException();
    }
    Contains(item) {
        throw new NotImplementedException();
    }
    get Count() {
        throw new NotImplementedException();
    }
}
export class HashSet_Enumerator extends NObject {
    MoveNext() {
        throw new NotImplementedException();
    }
    get Current() {
        throw new NotImplementedException();
    }
    Dispose() {
    }
}
export class KeyValuePair extends NObject {
    constructor(key, value) {
        super();
        this.Key = key;
        this.Value = value;
    }
}
export class Dictionary extends NObject {
    constructor(other) {
        super();
        this.keys = {};
        this.values = {};
    }
    get_Item(key) {
        return this.values[this.GetKeyString(key)];
    }
    set_Item(key, value) {
        var ks = this.GetKeyString(key);
        if (!this.values.hasOwnProperty(ks)) {
            this.keys[ks] = key;
        }
        this.values[ks] = value;
    }
    Add(key, value) {
        var ks = this.GetKeyString(key);
        if (this.values.hasOwnProperty(ks)) {
            throw new InvalidOperationException();
        }
        else {
            this.keys[ks] = key;
            this.values[ks] = value;
        }
    }
    GetKeyString(key) {
        if (key === null)
            return "null";
        if (typeof key === "undefined")
            return "undefined";
        return key + "";
    }
    ContainsKey(key) {
        return this.values.hasOwnProperty(this.GetKeyString(key));
    }
    TryGetValue(key, pvalue) {
        var ks = this.GetKeyString(key);
        if (this.values.hasOwnProperty(ks)) {
            pvalue[0] = this.values[ks];
            return true;
        }
        else {
            pvalue[0] = null;
            return false;
        }
    }
    Remove(key) {
        var ks = this.GetKeyString(key);
        delete this.values[ks];
        delete this.keys[ks];
    }
    Clear() {
        this.values = {};
        this.keys = {};
    }
    get Count() {
        return Object.keys(this.values).length;
    }
    GetEnumerator() {
        var kvs = new List();
        for (var ks in this.values) {
            kvs.Add(new KeyValuePair(this.keys[ks], this.values[ks]));
        }
        return new Dictionary_Enumerator(kvs);
    }
    get Keys() {
        var keys = new Dictionary_KeyCollection();
        for (var ks in this.keys) {
            keys.Add(this.keys[ks]);
        }
        return keys;
    }
    get Values() {
        var vals = new Dictionary_ValueCollection();
        for (var ks in this.values) {
            vals.Add(this.values[ks]);
        }
        return vals;
    }
}
export class Dictionary_Enumerator extends List_Enumerator {
    constructor(list) {
        super(list);
    }
}
export class Dictionary_KeyCollection extends List {
}
export class Dictionary_KeyCollection_Enumerator extends List_Enumerator {
    constructor(list) {
        super(list);
    }
}
export class Dictionary_ValueCollection extends List {
}
export class Dictionary_ValueCollection_Enumerator extends List_Enumerator {
    constructor(list) {
        super(list);
    }
}
export class Regex extends NObject {
    constructor(pattern) {
        super();
        this.re = new RegExp(pattern, "g");
    }
    Match(input) {
        var m = new Match();
        var r = this.re.exec(input);
        if (r) {
            var loc = r.index;
            for (var i = 0; i < r.length; ++i) {
                var text = "";
                if (typeof r[i] === "undefined") { }
                else if (r[i].constructor === String)
                    text = r[i];
                m.Groups.Add(new Group(text, loc));
                if (i !== 0)
                    loc += text.length;
            }
            m.Success = true;
        }
        return m;
    }
    Replace(input, repl) {
        return input.replace(this.re, repl);
    }
    IsMatch(input) {
        return this.re.test(input);
    }
}
export class Match extends NObject {
    constructor() {
        super(...arguments);
        this.Groups = new List();
        this.Success = false;
    }
}
export class Group extends NObject {
    constructor(value, index) {
        super();
        this.Length = 0;
        this.Value = "";
        this.Index = 0;
        this.Value = value || "";
        this.Length = this.Value.length;
        this.Index = index;
    }
}
export class Stream extends NObject {
}
export class MemoryStream extends Stream {
    ToArray() {
        throw new NotImplementedException();
    }
}
export class TextWriter extends NObject {
    Write(text) {
        throw new NotSupportedException();
    }
    WriteLine(text) {
        this.Write(text + Environment.NewLine);
    }
    Flush() {
        throw new NotSupportedException();
    }
    Dispose() {
    }
}
export class StreamWriter extends TextWriter {
    constructor(streamOrPath, encoding) {
        super();
    }
}
export class BinaryWriter extends NObject {
    constructor(baseStream, encoding) {
        super();
    }
    Write(n) {
        throw new NotImplementedException();
    }
    Flush() {
        throw new NotImplementedException();
    }
}
export class StringBuilder extends NObject {
    constructor() {
        super(...arguments);
        this.parts = new Array();
    }
    Append(textOrChar) {
        var text = (textOrChar.constructor instanceof Number) ? String.fromCharCode(textOrChar) : textOrChar;
        this.parts.push(text);
    }
    AppendLine(text = null) {
        if (text !== null) {
            this.parts.push(text);
        }
        this.parts.push(Environment.NewLine);
    }
    AppendFormat(textOrFormat, arg0, arg1, arg2) {
        throw new NotImplementedException();
    }
    ToString() {
        return this.parts.join("");
    }
    get Length() {
        var len = 0;
        for (var i = 0; i < this.parts.length; i++) {
            len += this.parts[i].length;
        }
        return len;
    }
    get_Item(index) {
        var o = 0;
        for (var i = 0; i < this.parts.length; ++i) {
            var p = this.parts[i];
            if (index < o + p.length) {
                return p.charCodeAt(index - o);
            }
            o += p.length;
        }
        return 0;
    }
}
export class TextReader extends NObject {
    ReadLine() {
        throw new NotSupportedException();
    }
    ReadToEnd() {
        throw new NotSupportedException();
    }
    Dispose() {
    }
}
export class StringReader extends TextReader {
    constructor(str) {
        super();
        this.str = str;
        this.pos = 0;
    }
    ReadLine() {
        var p = this.pos;
        if (p >= this.str.length)
            return null;
        var end = p;
        while (end < this.str.length && this.str.charCodeAt(end) !== 10) {
            end++;
        }
        var tend = end;
        if (tend > p && this.str.charCodeAt(tend - 1) == 13) {
            tend--;
        }
        var r = this.str.substr(p, tend - p);
        this.pos = end + 1;
        return r;
    }
}
export class StringWriter extends TextWriter {
}
export class Enumerable extends NObject {
    static ToArray(e) {
        throw new NotImplementedException();
    }
    static ToList(e) {
        return new List(e);
    }
    static Empty() {
        return new List();
    }
    static Range(start, count) {
        var end = start + count;
        var r = new List();
        for (var i = start; i < end; i++) {
            r.Add(i);
        }
        return r;
    }
    static Select(e, selector) {
        var r = new List();
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            r.Add(selector(i.Current));
        }
        return r;
    }
    static SelectMany(e, selector, comb) {
        throw new NotImplementedException();
    }
    static Where(e, p) {
        var r = new List();
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            if (p(i.Current))
                r.Add(i.Current);
        }
        return r;
    }
    static OrderBy(e, s) {
        var r = new List();
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            r.Add(i.Current);
        }
        r.array.sort(function (x, y) {
            var sx = s(x);
            var sy = s(y);
            if (sx === sy)
                return 0;
            if (sx < sy)
                return -1;
            return 1;
        });
        return r;
    }
    static OrderByDescending(e, s) {
        var r = new List();
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            r.Add(i.Current);
        }
        r.array.sort(function (x, y) {
            var sx = s(x);
            var sy = s(y);
            if (sx === sy)
                return 0;
            if (sx < sy)
                return 1;
            return -1;
        });
        return r;
    }
    static ThenBy(e, s) {
        return Enumerable.OrderBy(e, s);
    }
    static Concat(x, y) {
        var r = new List(x);
        r.AddRange(y);
        return r;
    }
    static Take(e, count) {
        var r = new List();
        var i = e.GetEnumerator();
        while (r.Count < count && i.MoveNext()) {
            r.Add(i.Current);
        }
        return r;
    }
    static Skip(e, count) {
        var r = new List();
        var i = e.GetEnumerator();
        var j = 0;
        while (i.MoveNext()) {
            if (j >= count)
                r.Add(i.Current);
            j++;
        }
        return r;
    }
    static Distinct(e) {
        var d = new Dictionary();
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            d.set_Item(i.Current, null);
        }
        return d.Keys;
    }
    static Cast(e) {
        return e;
    }
    static OfType(e) {
        throw new NotImplementedException();
    }
    static Contains(e, val) {
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            if (i.Current === val)
                return true;
        }
        return false;
    }
    static FirstOrDefault(e, p = null) {
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            if (p === null || p(i.Current))
                return i.Current;
        }
        return null;
    }
    static LastOrDefault(e, p = null) {
        var i = e.GetEnumerator();
        var last = null;
        while (i.MoveNext()) {
            if (p === null || p(i.Current))
                last = i.Current;
        }
        return last;
    }
    static Last(e, p = null) {
        var i = e.GetEnumerator();
        var last = null;
        var gotLast = false;
        while (i.MoveNext()) {
            if (p === null || p(i.Current)) {
                last = i.Current;
                gotLast = true;
            }
        }
        if (gotLast)
            return last;
        throw new Exception("Not found");
    }
    static First(e, p = null) {
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            if (p === null || p(i.Current))
                return i.Current;
        }
        throw new Exception("Not found");
    }
    static Any(e, p) {
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            if (p(i.Current))
                return true;
        }
        return false;
    }
    static All(e, p) {
        var i = e.GetEnumerator();
        while (i.MoveNext()) {
            if (!p(i.Current))
                return false;
        }
        return true;
    }
    static Count(e) {
        throw new NotImplementedException();
    }
    static Sum(e, s) {
        throw new NotImplementedException();
    }
    static Max(e, s) {
        throw new NotImplementedException();
    }
    static Min(e, s) {
        throw new NotImplementedException();
    }
    static ToDictionary(e, k, v) {
        throw new NotImplementedException();
    }
}
export class PropertyChangedEventArgs extends EventArgs {
    constructor(name) {
        super();
    }
}
export class Debug extends NObject {
    static WriteLine(text) {
        console.log(text);
    }
}
export class Thread extends NObject {
    constructor() {
        super();
        this.ManagedThreadId = Thread.nextId++;
    }
}
Thread.nextId = 1;
Thread.CurrentThread = new Thread();
export class ThreadPool extends NObject {
    static QueueUserWorkItem(workItem) {
        throw new NotImplementedException();
    }
}
export class Monitor extends NObject {
    static Enter(lock) {
    }
    static Exit(lock) {
    }
}
export class Interlocked extends NObject {
    static CompareExchange(location1, value, comparand) {
        var v = location1[0];
        if (v === comparand)
            location1[0] = value;
        return v;
    }
}
export class WebClient extends NObject {
    DownloadString(url) {
        throw new NotImplementedException();
    }
}
export class Random extends NObject {
    constructor(seed) {
        super();
        this.seed = seed;
    }
    Next(min, max) {
        max = max || 0;
        min = min || 0;
        this.seed = (this.seed * 9301 + 49297) % 233280;
        var rnd = this.seed / 233280;
        return min + rnd * (max - min);
    }
    NextInt(min, max) {
        return Math.round(this.Next(min, max));
    }
    NextDouble() {
        return this.Next(0, 1);
    }
    pick(collection) {
        return collection[this.NextInt(0, collection.length - 1)];
    }
}
