<template>
  <!-- 快訊 -->
  <div class="modal fade" ref="TicketDetail">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">票券明細</h4>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-habits">
            <tbody>
              <tr class="bg-gray">
                <th>單價</th>
                <th class="text-center">優惠券名稱</th>
                <th>票券序號</th>
                <th>使用狀態</th>
              </tr>
              <tr :key="item.流水號" v-for="item in info">
                <td class="text-center">{{ item.商品單價 }}</td>
                <td class="text-center">
                  {{ item.商品名稱 }}
                </td>
                <td class="text-center">
                  {{ item.序號 }}
                </td>
                <td class="text-center">
                  {{
                    orderResult == 1 ? GetStatus(item.信託狀態) : "未配發"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info: {},
      orderResult: -1,
    };
  },
  mounted() {},
  methods: {
    showTicketDetailBox(orderId, orderResult) {
      this.orderResult = orderResult;
      this.GetOrderProducts(orderId);
    },
    GetOrderProducts(orderId) {
      this.$store
        .dispatch("payment/getOrderProducts", { orderId: orderId })
        .then((res) => {
          if (res.data.status == "1") {
            this.info = res.data.content;
            $(this.$refs.TicketDetail).modal("show");
          } else {
            let code = res.data.stateCode;
            let msg = res.data.message;
            this.$eventBus.$emit("showAlert", "操作失敗(" + code + ")、" + msg);
            $(this.$refs.TicketDetail).modal("hide");
          }
        });
    },
    GetStatus(state) {
      switch (state) {
        case 1:
          return "已配發";
        case 2:
          return "已使用";
        case 3:
          return "已丟棄";
      }
    },
  },
};
</script>