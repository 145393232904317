<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="videoAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span>影片詳細</span>
          </h4>
        </div>
 
        <div class="modal-body">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="text-right" style="width: 120px;">頻道名稱：</td>
                <td><span>{{videoitem.頻道名稱}}</span></td>
                
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">影片標題：</td>
                <td><span><a class="linkStyle" :href="`https://www.youtube.com/watch?v=`+videoitem.videoId" target="_blank">{{videoitem.影片標題}}</a></span></td>
              </tr>
              <tr>
                <td class="text-right" style="    width: 120px;    vertical-align: baseline;    padding-top: 8px;">影片簡介：</td>
                <td><span style="  display: -webkit-box;  -webkit-box-orient: vertical;  -webkit-line-clamp: 5;  overflow: hidden;" v-html="changeN(videoitem.影片簡介)"></span></td>
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">發布日期：</td>
                <td><span>{{videoitem.影片發布日期}}</span></td>
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">巡邏人：</td>
                <td>
                  <span v-if="this.CheckMan">{{videoitem.巡邏人}}</span>
                  <div v-else class="select-group">
                      <select class="form-control" id="selectUnit"  v-model="videoitem.巡邏人">
                              <option value="" disabled selected>請選擇巡邏人</option>
                              <option
                                  v-for="(item, index) in channelMan.result"
                              :value="item.人員名稱"                  
                              :key="index"
                              >{{item.人員名稱}}</option>
                      </select>
                   </div>
                  </td>
              </tr>
              <tr>
                <td class="text-right" style="    width: 120px;    vertical-align: baseline;    padding-top: 17px;">狀態：</td>
                <td>
                     <select class="form-control" id="selectUnit" required="required" v-model="videoitem.狀態">
                      <option value="0" key="1">未處理</option>
                      <option value="1" key="2">不處理</option>
                      <option value="2" key="3">待追蹤</option>
                      <option value="3" key="4">已結案</option>
                    </select>
                    <span class="text-red">*待追蹤會列入追蹤中影片區</span>
                </td>
           
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">遊戲ID：</td>
                <td>
                  <input
                    class="form-control"
                    type="text" 
                    v-model="videoitem.遊戲角色名稱"                   
                  >
                </td>
              </tr>
              <tr>
                <td class="text-right" style="    width: 120px;    vertical-align: baseline;    padding-top: 15px;">追蹤狀態：</td>
                <td>
                  <input class="form-control" type="text" v-model="videoitem.追蹤狀態">
                  <div style="    margin-top: 5px;">
                      <button style="margin: 4px;" class="btn btn-warning" v-for="(text, index) in commonTexts" :key="index" @click="insertText(text)">{{ text }}</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">備註：</td>
                <td>
                  <textarea class="form-control"  name="" id="" cols="30" rows="3" v-model="videoitem.備註"></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button @click="submit()" class="btn btn-primary" type="button">
            儲存
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
         
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
     .linkStyle {
        cursor: pointer;
        color: black;
        border-bottom: 1px solid;
    }

</style>


<script>
/* eslint-disable */

import {
    mapState,
    mapActions
} from "vuex";

export default {
  data() {
    return {
      //  classTypeId: this.$parent.searchData.classTypeId,
        title: "",
        inputstatus:"",
        commonTexts: ["系統已刪除","持續觀察","已返還影片","已留言","頻道主已改善","已申請下架","對方已刪除"],
        url: "",
        CheckMan:false,
        videoitem:[]
    };
  },
    computed: {
      ...mapState({
          channelMan: (state) => state.smm.QueryMan,
      }),
  },
  methods: {
    openBox(item) {
      this.videoitem={ ...item };
      if(this.videoitem.巡邏人 != '尚未派發'){
        this.CheckMan=true;
      }else{
        this.CheckMan=false;
      }
      $(this.$refs.videoAlert).modal("show");
    },
    insertText(text) {
        this.videoitem.追蹤狀態 = text;
    },
    hideAlert() {
      $(this.$refs.videoAlert).modal("hide");
      this.resetData();
    },
    resetData() {
      this.videoitem.title = "";
      this.videoitem.url = "";
    },
    changeN(name) {
      if(name != undefined){
        return name.replace(/\n/g, '<br>');
      }        
    },
    submit() {

      this.$store.dispatch("smm/videoUpdate", {
          videoId: this.videoitem.videoId,
          狀態: parseInt(this.videoitem.狀態,10), //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
          遊戲角色名稱: this.videoitem.遊戲角色名稱,
          追蹤狀態: this.videoitem.追蹤狀態,
          備註: this.videoitem.備註,
          頻道管理編號: this.videoitem.頻道管理編號,
          巡邏人:this.videoitem.巡邏人
      }).then((res) => {
          this.hideAlert();
          if(res.status == 1){
            //this.$eventBus.$emit("showAlert", "儲存成功!!");
            alert("更新成功!")
            this.$emit('submit','success');
          }
      });

      // this.$store
      //     .dispatch(`link/linkCreate`, this.data)
      //     .then(() => {
      //       this.resetData();
      //       this.$emit('updateList');
      //       this.hideAlert();
      //     });
    },
  },
};
</script>

<style scoped>
table tbody tr td {
  border: none;
}
</style>