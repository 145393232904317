export default class {
  static permission = {
    //:'', 

    // 精華影片列表查詢
    精華影片列表查詢:'精華影片列表查詢',
    精華影片上下架: '精華影片上下架',
    
  };
}
