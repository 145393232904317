<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>常見問題列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li>常見問題列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" type="button" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增問題
            </button>
            <div class="select-group">
              <select id="selectUnit" class="form-control" v-model="searchData.類別" @change="clickSearch" required>
                <option value>全部</option>
                <option v-for="(item, index) in types.datas" :value="item.className" :key="index">
                  {{item.className}}
                </option>
              </select>
            </div>
            <!-- <div class="input-group">
              <input type="text" v-model="searchData.keyword" name="table_search" class="form-control pull-right" placeholder="搜尋關鍵字" />
              <div class="input-group-btn">
                <button type="button" @click="clickSearch()" class="btn btn-default fa fa-search"></button>
              </div>
            </div> -->
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ questions.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered" id="NmjWebQuestion">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width:120px; width:10%">類別</th>
                  <th style="min-width: 50px; width:5%">排序</th>
                  <th style="min-width:460px; width:75%">內容</th>
                  <th style="min-width:140px; width:10%">功能</th>
                </tr>
                <tr v-if="datas.length <= 0">
                  <td colspan="10" class="text-center">查無資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td v-if="!item.isEdit" class="text-center">{{ item.類別 }}</td>
                  <td v-if="!item.isEdit" class="text-center">{{ item.排序 }}</td>
                  <td v-else style="vertical-align:top" colspan="2">
                    <!-- <div v-if="!item.isEdit" class="text-center text-nowrap">
                      <div class="question">{{ item.類別 }}</div>
                      <div class="answer">排序：{{ item.排序 }}</div>
                    </div> -->
                    <div>
                      <div class="form-group">
                        <label for="類別" v-if="item.isEdit">類別：</label>
                        <select id="類別" v-model="item.類別" class="form-control" required :disabled="!item.isEdit">
                          <option value="" selected disabled hidden>請選擇類別</option>
                          <option v-for="category in types.datas" :value="category.className">{{ category.className }}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="排序" v-if="item.isEdit">排序：</label>
                        <input id="排序" type="number" v-model="item.排序" class="form-control" :readonly="!item.isEdit" min="1">
                      </div>
                    </div>
                  </td>
                  <td style="vertical-align:top">
                    <div v-if="!item.isEdit">
                      <div class="question" v-text="item.問題"></div>
                      <div class="answer" v-html="item.回答"></div>
                    </div>
                    <div v-else>
                      <div class="form-group">
                        <label for="問題">問題：</label>
                        <input id="問題" v-model="item.問題" type="text" class="form-control" required />
                      </div>
                      <div class="form-group">
                        <label for="回答">回答：</label>
                        <tinymce id="回答" v-model="item.回答" propLang="zh-TW" height="300" />
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <button v-if="item.isEdit" @click="saveItem(item, index)" class="btn btn-success">
                      儲存
                    </button>
                    <button v-else @click="editItem(item, index)" class="btn btn-primary" :disabled="isEditing && !item.isEdit">
                      編輯
                    </button>
                    &nbsp;
                    <button v-if="item.isEdit" @click="cancelItem(item, index)" class="btn btn-default">
                      取消
                    </button>
                    <button v-else @click="deleteItem(item)" class="btn btn-danger" :disabled="isEditing && !item.isEdit">
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="questions.total > 0">
          <Pagination
            ref="pagination"
            :total="questions.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!-- <pre style="line-height:1.1;">{{ datas }}</pre> -->
    </section>
  </div>
</template>

<style lang="scss">
  #NmjWebQuestion{
    p, ol, ul, dl{
      margin: 0;
      margin-top: .35em;
      &:first-child{ margin-top:0 }
    }
    .question{
      text-shadow: 0 0;
      background: #eee;
      border-bottom: none;
      padding: .35em 1em;
    }
    .answer{
      font-weight: normal;
      background: #fff;
      color: #555;
      border-top: none;
      padding: .4em 1em;
    }
    .form-group + .form-group{
      margin-top:5px;
    }
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import tinymce from "@/components/Tinymce";
import DateRangePicker from "@/components/DateRangePicker";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      routeName: this.$route.name,
      searchData: {
        // projectId: this.$store.state.projectId,
        // classTypeId: "",
        類別: "",
        module: "problem",
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
      },
      datas: [],
      isEditing: false,
      url: config.dkOption.url,
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    // 開箱文日期預設值
    if (this.projectId === 'omp_xinstars') {
      this.searchData.StartTime = new moment().subtract(360, "days").format("YYYY-MM-DD")
      this.searchData.EndTime = new moment().add(60, "days").format("YYYY-MM-DD")
    }

    await this.getClassTypes();
    this.getQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      types: (state) => state.nmjClassTypes.queryAll,
      questions: (state) => state.nmjWebQuestion.queryAll,
      response: (state) => state.nmjWebQuestion.response,
    }),
  },
  watch: {
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getQueryAll();
    },
    questions(value) {
      this.datas = value.datas;
      this.$refs.pagination.setPage(this.searchData.pageNum, this.questions.total);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getQueryAll();
    },

    getQueryAll() {
      //列表
      this.$store.dispatch("nmjWebQuestion/QueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async getClassTypes() {
      //分類
      this.$store.dispatch("nmjClassTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "problem",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.datas = [{
        排序: 1,
        類別: this.searchData.類別,
        問題: "",
        回答: "",
      }, ...this.questions.datas]
      this.editItem(this.datas[0], 0);
    },

    //*---- 修改內容
    editItem(item, index) {
      if (this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.isEditing = true;
      item.isEdit = true;
      this.$set(this.datas, index, item);

    },
    saveItem(item, index){
      if (item.類別 == "") {
        return this.$eventBus.$emit("showAlert", "請填選擇類別");
      }
      if (item.問題 == "") {
        return this.$eventBus.$emit("showAlert", "請填寫問題");
      }
      if (item.回答 == "") {
        return this.$eventBus.$emit("showAlert", "請填寫回答");
      }

      delete item.isEdit;

      if (typeof item.id === "undefined") {
        this.$store.dispatch("nmjWebQuestion/Create", item);
      }
      else {
        this.$store.dispatch("nmjWebQuestion/Update", item);
      }
      this.isEditing = false;
    },
    cancelItem(item, index){
      this.getQueryAll()
      this.isEditing = false;
    },

    //*----預覽
    previewItem(item) {
      let width = 1080;
      let height = 840;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 2;
      // console.log(width, height, left, top)
      window.open(
        `${process.env.VUE_APP_NMJWEB_PATH}/Service/${item.id}`,
        "preview",
        `popup,top=${top},left=${left},width=${width},height=${height}`
      );
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjWebQuestion/Delete", item);
        }
      });
    },

    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>