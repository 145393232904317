<template>
  <div id="ClassTypeManager">
    <section class="content-header">
      <h1>服務類別管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">服務類別管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button type="button" class="btn btn-block btn-warning btn-add fa fa-plus" @click="addType()">
              新增
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered classTypeTable" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px; width: 30%;">分類名稱</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <tr v-for="(item, index) in datas" :key="item.編號">
                  <td>
                    <p v-show="!item.isEdit" class="m-0">{{ item.名稱 }}</p>
                    <input v-show="item.isEdit" type="text" class="form-control" v-model="item.名稱" required />
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx toggleInput" >
                      <input type="checkbox" :true-value="0" :false-value="1" :id="'check'+index" v-model="item.啟用YN" @change="changeStatus(item)" name="ckbx-style-8" />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button v-show="!item.isEdit" @click="change(item, index)" class="btn btn-primary">修改</button>
                    <button v-show="item.isEdit" @click="save(item, index)" class="btn btn-success btn-save">儲存</button>
                    <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default ml-5">取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- <pre>{{ datas }}</pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      detailClassLang: [],
      tempEdit: false,
      datas: [],
    };
  },
  created() {
    this.queryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      classTypes: state => state.nmjServiceTypes.query,
      response: state => state.nmjServiceTypes.response,
    })
  },
  watch: {
    response(value) {
      if (value.status == "1") this.$eventBus.$emit("showAlert", "更新成功!!");
      else this.$eventBus.$emit("showAlert", "更新失敗!!");
      // this.queryAll();
    },
  },
  methods: {
    // 顯示列表
    queryAll() {
      this.$store.dispatch("nmjServiceTypes/serviceTypeQuery")
      .then((result) => {
        this.datas = result;
        // console.log(this.datas)
      });
    },

    // 切換文字或輸入框
    change(item, index) {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      item.isEdit = true;
      console.log(this.datas[index]);
      this.$set(this.datas, index, item);
    },

    // 儲存
    save(item, index) {
      this.tempEdit = false;
      let api = item.編號 ? 'serviceTypeUpdate' : 'serviceTypeCreate';
      this.$store.dispatch("nmjServiceTypes/" + api, {
        "名稱": item.名稱,
        "編號": item.編號,
        "啟用YN": item.啟用YN,
      }).then(result => {
        console.log(result);
        this.datas = result;
      });
    },

    // 新增類別
    addType() {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }
      this.tempEdit = true;

      this.datas.unshift({
        "名稱": "",
        "啟用YN": 0,
        "isEdit": true
      });
    },

    cancel(item, index) {
      this.tempEdit = false;
      if (item.classTypeId == "") {
        this.classTypes.datas.splice(index, 1);
      } else {
        this.queryAll();
      }
    },

    // 改變狀態
    changeStatus(item) {
      if (item.isEdit) return;

      this.$store.dispatch("nmjServiceTypes/serviceTypeUpdate", {
        "編號": item.編號,
        "啟用YN": item.啟用YN,
      });
    },
  }
};
</script>