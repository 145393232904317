<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>活動編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動列表</li>
        <li class="active">活動編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="activityCode">
                  <span class="text-red">*</span> 活動代號：
                </label>
                <div class="col-sm-2">
                  <input
                    class="form-control"
                    id="activityCode"
                    required="required"
                    type="text"
                    maxlength="3"
                    v-model="edit.activityCode"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="vendorActivityCode">原廠活動代號：</label>
                <div class="col-sm-2">
                  <input
                    class="form-control"
                    id="vendorActivityCode"
                    required="required"
                    type="text"
                    v-model="edit.vendorActivityCode"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="activityTitle">
                  <span class="text-red">*</span> 活動名稱：
                </label>
                <div class="col-sm-5">
                  <input
                    class="form-control"
                    id="activityTitle"
                    required="required"
                    type="text"
                    v-model="edit.activityTitle"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 限制條件：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.ruleType">
                    <option value>無</option>
                    <option value="1帳多次">一個帳號領多個序號 (全活動)</option>
                    <option value="綁定帳號">綁定帳號</option>
                    <!-- <option value="1序多帳">一個序號對應多個帳號</option> -->
                  </select>
                </div>
              </div>

              <div class="form-group" v-show="edit.ruleType.includes('1帳')">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 限制個數：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="number"
                    min="1"
                    v-model.number="edit.ruleCountLimit"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 活動開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="startTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="startTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 活動結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="endTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="endTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="啟用"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="停用"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    iCheckRedioButton,
    DatePicker
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      edit: {
        activityId: utils.getTimeId(), //活動Id
        activityCode: "", //活動代碼
        activityTitle: "", // 名稱
        vendorActivityCode: "", //原廠活動代碼
        isStop: 0, // 狀態
        ruleType: "", // 限制條件
        ruleCountLimit: 1, // 限制個數
      },
      startTime: new moment().format("YYYY-MM-DD 00:00:00"),
      endTime: new moment().format("YYYY-MM-DD 23:59:59"),
    };
  },
  created() {},
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetActivity();
    }
  },
  computed: {
    ...mapState({
      activity: state => state.activity.query,
      response: state => state.activity.response
    })
  },
  watch: {
    activity() {
      this.edit = this.activity;
      this.edit.ruleType =
        this.edit.ruleType === "1帳1次" ? "1帳多次" : this.edit.ruleType;
      this.edit.ruleCountLimit = this.edit.ruleCountLimit || 1;
      this.updateTime();
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    }
  },
  methods: {
    //取得該廣告資料
    GetActivity() {
      this.$store.dispatch("activity/activityQuery", {
        activityId: this.$route.params.id
      });
    },

    updateTime() {
      this.startTime = this.edit.startTime || this.startTime;
      this.endTime = this.edit.endTime || this.endTime;
      $("#startTimeOnly").val(this.global.getTime(this.startTime));
      $("#endTimeOnly").val(this.global.getTime(this.endTime));
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "ActivityManager",
        params: { searchData: this.$route.params.searchData }
      });
    },

    //送出
    async Save() {
      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      this.startTime = new Date(
        `${this.startTime.split(" ")[0]} ${startTimeOnly}`
      );
      this.endTime = new Date(
        `${this.endTime.split(" ")[0]} ${endTimeOnly}`
      );
      this.edit.isStop = parseInt(this.edit.isStop);

      if (this.edit.activityCode == "") {
        this.$eventBus.$emit("showAlert", "活動代碼不能為空");
        return;
      }

      this.edit.activityCode = this.edit.activityCode.toUpperCase();

      if (this.edit.activityTitle == "") {
        this.$eventBus.$emit("showAlert", "名稱不能為空");
        return;
      }

      if (this.edit.ruleType.includes('1帳') && this.edit.ruleCountLimit == "") {
        this.$eventBus.$emit("showAlert", "限制個數不能為空");
        return;
      }

      if (
        startTimeOnly === "" ||
        !this.global.isValidDay(this.startTime)
      ) {
        this.$eventBus.$emit("showAlert", "請填寫活動開始時間！");
        return;
      }

      if (
        endTimeOnly === "" ||
        !this.global.isValidDay(this.endTime)
      ) {
        this.$eventBus.$emit("showAlert", "請填寫活動結束時間！");
        return;
      }

      if (
        !this.global.isValidDayRange(
          this.startTime,
          this.endTime
        )
      ) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }

      this.edit.ruleCountLimit = this.edit.ruleCountLimit || 1;
      this.edit.startTime = new Date(`${this.startTime} UTC`);
      this.edit.endTime = new Date(`${this.endTime} UTC`);

      let newItem = utils.deepCloneObj(this.edit);

      if (this.isEdit) {
        this.$store.dispatch("activity/activityUpdate", newItem);
      } else {
        this.$store.dispatch("activity/activityCreate", newItem);
      }
    }
  }
};
</script>