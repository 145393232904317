<template>
  <div id="SMSMessage">
    <section class="content-header">
      <h1>罐頭訊息管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">罐頭訊息管理</li>
      </ol>
    </section>
    <section class="content forum-mute">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="add()"
            >
              <i class="fa fa-plus"></i> 新增
            </button>

            <div class="select-group" >
              <select
                class="form-control"
                v-model="searchData.classTypeId"
                @change="resetDataList()"
              >
                <option value="">全部類型</option>
                <option 
                  v-for="category in categoryList"
                  :value="category.classTypeId"
                >
                  {{ category.className }}
                </option>
              </select>
            </div>

            <div class="select-group">
              <input
                class="form-control"
                type="text"
                placeholder="請輸入關鍵字"
                v-model="searchData.keyword"
              />
            </div>
            <button
              class="btn btn-default"
              type="button"
              @click="resetDataList()"
            >
              查詢
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min1000">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;">類別</th>
                  <th>罐頭訊息內容</th>
                  <th style="min-width: 100px;">建立人員</th>
                  <th style="min-width: 180px;">建立時間</th>
                  <th style="min-width: 80px;">排序</th>
                  <th style="min-width: 80px;">狀態</th>
                  <th style="min-width: 180px;">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="text-center" colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in list" :key="index">
                  <td class="text-center">
                    <select
                      class="form-control"
                      v-model="item.classTypeId"
                      v-if="item.editor == 1"
                    >
                      <option 
                        v-for="category in categoryList"
                        :value="category.classTypeId"
                      >
                        {{ category.className }}
                      </option>
                    </select>
                    <span v-else>{{ item.classTypeName }}</span>
                  </td>
                  <td>
                    <textarea
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.detailTopTitle"
                      v-if="item.editor == 1"
                    />
                    <span v-else>{{ item.detailTopTitle }}</span>
                  </td>
                  <td class="text-center">{{ item.createUName }}</td>
                  <td class="text-center">{{ item.createTime }}</td>
                  <td class="text-center">
                    <input
                      class="form-control"
                      required="required"
                      type="number"
                      v-model="item.sortId"
                      v-if="item.editor == 1"
                    />
                    <span v-else>{{ item.sortId }}</span>
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :false-value="1"
                        :id="'check'+index"
                        :true-value="0"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.isStop"
                      />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary"
                      v-if="item.editor != 1"
                      @click="change(item)"
                    >
                      修改
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger"
                      v-if="item.editor != 1"
                      @click="remove(item)"
                    >
                      刪除
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-success"
                      v-if="item.editor == 1"
                      @click="save(item)"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-default"
                      v-if="item.editor == 1"
                      @click="cancel(item, index)"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import moment from "moment";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        module: "canned", //固定值
        keyword: "",
        classTypeId: "",
        isDelete: 0, //固定值
        pageNum: 1,
        pageSize: 10,
      },
      categoryList: [],
      list: [],
      isEdit: false,
      isAdd: false
    };
  },
  computed: mapState({
    loginUser: state => state.loginUser,
  }),
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;

    this.getClass();
    this.getDataList();
  },
  methods: {
    getClass() {
      api.classType.queryAll({
        Module: "canned", //固定值
        IsStop: 0, //固定值
        IsDelete: 0, //固定值
      }).then(({data}) => {
        this.categoryList = data.result.datas;
      })
    },
    getDataList() {
      this.loading = true;
      api.moloSMS.message.queryAll(this.searchData)
        .then(({data}) => {
          this.loading = false;
          this.list = data.result.datas;
          this.total = data.result.total;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    add() {
      if(this.isEdit || this.isAdd) {
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }

      let date = moment(new Date()).format("YYYY/M/D a hh:mm:ss");
      date = date.replace('pm', '下午');
      date = date.replace('am', '上午');

      this.isAdd = true;
      this.list.push({
        classTypeId: this.categoryList[0].classTypeId,
        detailTopTitle: "",
        createTime: date,
        sortId: 0,
        isStop: 0,
        editor: 1,
        employeeName: this.loginUser.EmployeeName
      })
    },
    change(item) {
      if(this.isEdit || this.isAdd) {
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }
      item.editor = 1;
      this.isEdit = true;
      this.$forceUpdate();
    },
    remove(item) {
      if(this.isEdit || this.isAdd) {
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }

      api.moloSMS.message.delete({
        contentId: item.contentId
      })
        .then((res) => {
          this.global.showResponse(this, res, 'delete', this.resetDataList);
        })
    },
    cancel(item, index) {
      this.isEdit = false;
      this.isAdd = false;
      if (item.detailTopTitle == "") {
        this.list.splice(index, 1);
      } else {
        this.getDataList();
      }
    },
    save(item) {
      if(item.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "請輸入訊息內容");
        return
      }
      let type = this.isAdd ? 'create' : 'update';

      let master_model = {
        projectId: "omp_sms",
        classTypeId: item.classTypeId,
        sortId: Number(item.sortId),
        isStop: item.isStop
      }

      let detail_model = [{
        lang: "zh-tw",
        title: item.detailTopTitle
      }]

      if(this.isEdit) master_model.contentId = item.contentId;

      api.moloSMS.message[type]({
        master_model: master_model,
        detail_model: detail_model
      })
        .then((res) => {
          this.isAdd = false;
          this.isEdit = false;
          this.global.showResponse(this, res, type, this.getDataList);
        })
    },
    changeStatus(item) {
      api.moloSMS.message.updateStop({
        contentId: item.contentId,
        isStop: item.isStop
      })
        .then(({data}) => {
          this.getDataList();
        })
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
