<template>
  <div>
    <section class="content-header">
      <h1>雙平台額度刪除</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">雙平台額度刪除</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max1000">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group mr-10">
              <input
                class="form-control"
                placeholder="玩家門號"
                type="text"
                v-model.trim="phoneNumber"
              />
            </div>
            <div class="input-group">
              <button type="button" class="btn btn-primary" @click="AddList()">
                新增
              </button>
            </div>
            <div class="input-group">
              <input type="file" id="ImportPhoneData" ref="ImportPhoneData" />
            </div>
            <div class="input-group">
              <button
                type="button"
                class="btn btn-success"
                @click="importData()"
              >
                匯入名單
              </button>
              <a
                href="/uploads/刪除額度門號範例.xlsx"
                class="btn btn-block btn-info btn-cog"
                type="button"
                download
                >範例下載</a
              >
            </div>
          </div>

          <div class="mt-10 w-60">
            <div class="form-group">
              <label>總數:{{ receiverList.length }}</label>
            </div>
            <v-client-table
              :data="receiverList"
              :columns="['phone', 'edit']"
              :options="receiverListOpts"
            >
              <button
                type="button"
                class="btn btn-danger"
                slot="edit"
                slot-scope="props"
                v-on:click="OnReceiverRemove(props.row)"
              >
                移除
              </button>
            </v-client-table>
            <div class="btns mt-20 fr">
              <button type="button" class="btn btn-warning" @click="Del()">
                送出
              </button>
            </div>
          </div>
        </div>
        <div class="box-footer text-center no-border"></div>
      </div>
    </section>
  </div>
</template>
  
  <script>
/* eslint-disable */

import xlsx from "xlsx";

export default {
  components: {},
  data() {
    return {
      phoneNumber: "",
      receiverList: [],
      receiverListOpts: {
        perPage: 30,
      },
    };
  },
  mounted() {},
  methods: {
    AddList() {
      if (!this.phoneNumber) return;
      if (this.receiverList.some((data) => data.phone == this.phoneNumber)) {
        this.$eventBus.$emit("showAlert", "門號重複");
        return;
      }

      this.receiverList.push({ phone: this.phoneNumber });
      this.phoneNumber = "";
    },
    Del() {
      if (this.receiverList.length == 0) {
        this.$eventBus.$emit("showAlert", "目前無欲刪除額度的門號");
        return;
      }

      let phones = [];
      this.receiverList.forEach((x) => phones.push(x.phone));

      this.$store.dispatch("xinPlatformBuy/Delete", phones);
    },
    importData() {
      const that = this;
      const files = that.$refs.ImportPhoneData.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
          let outputs = []; //清空
          let error = false;
          let errMessage = "";
          for (var i = 0; i < ws.length; i++) {
            if (!ws[i].門號) {
              error = true;
              errMessage = "第" + (i + 1) + "筆資料內容為空值!!";
              break;
            }

            if (that.receiverList.some((data) => data.phone == ws[i].門號))
              continue;

            that.receiverList.push({ phone: ws[i].門號 });
          }

          if (error) {
            that.$eventBus.$emit("showAlert", errMessage);
            return;
          }

          // that.receiverList.concat(outputs);
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    OnReceiverRemove(item) {
      const index = this.receiverList.findIndex(
        (data) => data.phone == item.phone
      );
      if (index >= 0) this.receiverList.splice(index, 1);
    },
  },
};
</script>
  