<template>
  <div id="PlayerSettingList">
    <section class="content-header">
      <h1>玩家控管設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">玩家控管設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="updateData()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增
            </button>
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.blockType"
                @change="resetDataList()"
              >
                <option value="">控管類型</option>
                <option value="1">停權</option>
                <option value="2">禁言</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="玩家UID"
                type="text"
                v-model.trim="searchData.playUId"
              />
            </div>
            <div class="btns">
              <button
                @click="resetDataList()"
                class="btn btn-primary"
                type="button"
              >
                查詢
              </button>
              <button
                class="btn btn-default"
                type="button"
                @click="exportOrders()"
              >
                匯出
              </button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="min-width: 100px">玩家UID</th>
                  <th>控管類型</th>
                  <th class="text-nowrap" style="min-width: 150px">控管期限</th>
                  <th>功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="9">查詢中</td>
                </tr>
                <tr v-else-if="result.message">
                  <td colspan="9">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="9">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-nowrap">{{ item.playUId }}</td>
                  <td>{{ define.controlStatus[item.blockType] }}</td>
                  <td v-if="item.timeType === 1">永久</td>
                  <td v-else>
                    {{ item.blockEndTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td>
                    <button @click="updateData(item)" class="btn btn-primary">
                      修改
                    </button>
                    <button
                      @click="recoveryData(item, '3', 0)"
                      class="btn btn-danger ml-10"
                    >
                      解除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <playerSettingContent
      ref="playerSettingContent"
      v-on:updateList="getDataList()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import playerSettingContent from "@/views/projects/FC/PlayerSettingContent";

export default {
  components: {
    DateRangePicker,
    Pagination,
    playerSettingContent,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        blockType: "",
        playUId: "",
      },
      result: {},
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.$store
        .dispatch("fcPlayerSetting/queryAll", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          this.updateDataList();
        });
    },
    updateDataList() {
      this.datas.forEach((data) => {
        data.timeType =
          new Date(data.blockEndTime).getFullYear() === 2200 ? 1 : 2;
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateData(item) {
      this.$refs.playerSettingContent.showContent(item);
    },
    recoveryData(item, blockType, timeType) {
      this.$eventBus.$emit("showConfirm", "確定要解除嗎?", (isOK) => {
        if (isOK) {
          item.blockEndTime = null;
          item.blockType = blockType;
          item.timeType = timeType;
          this.$store.dispatch("fcPlayerSetting/create", item).then(() => {
            this.getDataList();
          });
        }
      });
    },
    exportOrders() {
      this.$store.dispatch("fcPlayerSetting/export", this.searchData);
    },
  },
};
</script>
