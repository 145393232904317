<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="forumReportAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">是否禁言以下使用者ID？</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li v-for="(item, index) in muteDatas" :key="index">
              {{ item.reportedId }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button @click="updateMute()" class="btn btn-primary" type="button">
            確定
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  props: ["muteDatas", "checkIds", "targetIds"],
  data() {
    return {
      editorName: this.$store.state.loginUser.EmployeeName,
    };
  },
  methods: {
    showReportAlert() {
      $(this.$refs.forumReportAlert).modal("show");
    },
    updateMute() {
      const datas = [];
      this.muteDatas.forEach((item) => {
        datas.push({
          opreateId: this.editorName,
          muteId: item.reportedId,
          muteReason: item.reportData,
          note: item.note,
          dateNumber: item.dateNumber || 0,
        });
      });
      this.$store.dispatch("newtalk/muteCreate", datas).then((result) => {
        this.readReport();
      });
    },
    readReport() {
      this.$store
        .dispatch("newtalk/reportUpdate", {
          serialNumber: this.checkIds,
          reportTargetId: this.targetIds,
        })
        .then((result) => {
          $(this.$refs.forumReportAlert).modal("hide");
          this.$emit("updateList");
        });
    },
  },
};
</script>
