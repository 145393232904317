<template>
  <div>
    <div class="g-info">
      <div class="g-photo">
        <img :src="item.圖示" alt="">
      </div>
      <div class="text">
        <div class="g-name">{{ item.遊戲館名稱 }}</div>
        <div>
          <ul class="g-play">
            <li v-for="(play, index) in item.玩法集合.slice(0, 3)">
              {{ play.玩法名稱 }}
            </li>
          </ul>
          <span v-if="item.玩法集合.length > 3">更多+</span>
        </div>
      </div>
    </div>
    <div class="bg-gray-light g-link">
      <div class="left">
        <router-link
          :to="`/slot/searchTag/${tag.編號}`"
          v-for="(tag, index) in item.標籤集合.slice(0, 3)"
          class="tag btn btn-success mr-5"
        >
          {{ tag.標籤名稱 }}
        </router-link>
      </div>
      <div class="right">
        <a
          href=""
          class="mr-10"
          style="font-size: 14px;"
          v-if="item.標籤集合.length > 3"
        >
          +
        </a>
      </div>
    </div>
    <!-- hover -->
    <div class="g-card-hover">
      <div class="g-photo" @click="goContent(item)" style="overflow: hidden;">
        <template v-for="(media, m_index) in item.預覽視覺集合">
          <img
            :src="media.視覺路徑"
            v-if="media.視覺型態 == '1' && media.是否啟用 == 1"
            :id="`img_${m_index+1}`"
          >
          <iframe
            v-if="media.視覺型態 == '2' && media.是否啟用 == 1"
            id="YTVideo"
            :src="`https://www.youtube.com/embed/${media.視覺路徑.split('youtu.be/')[1]}?enablejsapi=1&autoplay=1&mute=1`"
            title="YouTube video player"
            frameborder="0"
            allow="autoplay; encrypted-media;"
            allowfullscreen
            style="width: 100%;"
          ></iframe>
        </template>
      </div>
      <div class="text">
        <div class="g-name" @click="goContent(item)">{{ item.遊戲館名稱 }}</div>
        <div>
          <p>玩法</p>
          <ul class="g-play">
            <li v-for="(play, index) in item.玩法集合.slice(0, 3)" @click="showPlay(play.編號)">
              {{ play.玩法名稱 }}
            </li>
          </ul>
          <div class="more" v-if=" item.玩法集合.length > 3">更多+</div>
        </div>
        <div class="g-link">
          <p>標籤</p>
          <div>
            <router-link
              :to="`/slot/searchTag/${tag.編號}`"
              v-for="(tag, index) in item.標籤集合.slice(0, 3)"
              class="tag btn btn-success mr-5 mb-5"
              >
              {{ tag.標籤名稱 }}
            </router-link>
          </div>
          <div class="more" v-if=" item.標籤集合.length > 3">更多+</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props:['item'],
  methods: {
    goContent(data) {
      if(this.$route.name == 'slotSearchTag' || this.$route.name == 'slotSearch') {
        this.$parent.goContent(data);
      } else {
        this.$router.push({
          name: "clientGameContent",
          params: {
            gameId: data.遊戲編號,
            id: data.編號,
            backSearchData: this.$parent.searchData,
            back: this.$route.name,
          }
        });
      }
    },
    showPlay(id) {
      this.$parent.showPlay(id);
    },
    pauseVideo() {
      const video = document.getElementById("YTVideo")
      video.contentWindow.postMessage( '{"event":"command", "func":"pauseVideo", "args":""}', '*');
    },
    playVideo() {
      const video = document.getElementById("YTVideo")
      video.contentWindow.postMessage( '{"event":"command", "func":"playVideo", "args":""}', '*');
    },
  },
};
</script>