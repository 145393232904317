<template>
    <div id="ForumMuteSetting">
        <section class="content-header">
            <h1><span > {{專案}}</span> - 關鍵字回覆設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/FBRobot/RobotList">FB機器人</router-link>
                </li>
                <li>
                    <a href="javascript:;" @click="backList()">貼文設定/關鍵字列表</a>
                </li>
                <li class="active">關鍵字回覆設定</li>
            </ol>
        </section>

        <section class="content forum-mute">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <h4 class="pull-left" id="title">關鍵字回覆設定</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>

                    <form class="form-horizontal" @submit.prevent>

                        <div class="box-body" style="max-width: 80%">
                            <div class="form-group sr-only">
                                <label class="col-sm-2 control-label">
                                    編號: {{edit.代號}}
                                </label>
                            </div>

                             <!-- 需要關鍵字 -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 需要關鍵字：
                                </label>
                                <div class="col-lg-2 col-md-2 col-sm-3">
                                    <div class="radio-date">
                                        <div class="radio">
                                            <iCheckRedioButton :checkValue="1" message="是" name="radioNeedKey"
                                                v-model="needKey" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-3">
                                    <div class="radio">
                                        <iCheckRedioButton :checkValue="0" message="否" name="radioNeedKey"
                                            v-model="needKey" />
                                    </div>
                                </div>
                            </div>
                            
                            <!-- 關鍵字 -->
                            <div class="form-group" v-if="needKey">
                                <label class="col-sm-2 control-label" for="target_keywords">
                                   <span class="text-red">*</span>關鍵字：
                                </label>
                                <div class="col-sm-10">
                                    <div class="keyWrapper">
                                        <div class="keyText" 
                                            v-for="(item, index) in keys">
                                            {{item}}
                                            <button 
                                                class="btn btn-keyClose fa fa-close"
                                                type="button" @click="delKey(index)">
                                                <span class="sr-only">close</span>
                                            </button>
                                        </div>
                                        <input @keyup.enter="keyEnter()"
                                            v-model.trim="itemKey"
                                            class="form-control" id="target_keywords" 
                                            placeholder="請輸入關鍵字"
                                            type="text" />
                                    </div>
                                    <span class="text-red">關鍵字請以"Enter"送出</span><br>
                                </div>
                            </div>

                            <!-- 留言訊息符合度 -->
                            <div class="form-group" v-if="needKey">
                                <label class="col-sm-2 control-label">留言訊息：
                                </label>
                                <div class="col-lg-2 col-md-2 col-sm-3" >
                                    <div class="radio-date">
                                        <div class="radio">
                                            <iCheckRedioButton :checkValue="0" message="部分符合" name="radioNeeds"
                                                v-model="edit.符合度"
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-3">
                                    <div class="radio">
                                        <iCheckRedioButton :checkValue="1" message="完全符合" name="radioNeeds"
                                            v-model="edit.符合度"  />
                                    </div>
                                </div>
                            </div>

                            <!-- 自動留言按讚 -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 自動按讚：
                                </label>
                                <div class="col-lg-2 col-md-2 col-sm-3">
                                    <div class="radio-date">
                                        <div class="radio">
                                            <iCheckRedioButton :checkValue="1" message="是" name="radioAutoLike"
                                                v-model="edit.自動按讚" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-3">
                                    <div class="radio">
                                        <iCheckRedioButton :checkValue="0" message="否" name="radioAutoLike"
                                            v-model="edit.自動按讚" />
                                    </div>
                                </div>
                            </div>

                            <!-- 留言回覆內容 -->
                            <div class="form-group">
                                <label for="commentMsgConten" class="col-sm-2 control-label">留言回覆內容：
                                </label>
                                <div class="col-sm-10">
                                    <textarea id="commentMsgConten" placeholder="請輸入留言回覆文字，不填寫即不做留言回覆"
                                        class="form-control" rows="4" cols="100"
                                        v-model="edit.留言回覆訊息"></textarea>
                                </div>
                            </div>

                            <!-- 留言圖片： -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="commentMsgImg">
                                    留言回覆圖片：
                                </label>
                                <div class="col-sm-9">
                                    <input 
                                        accept="image/*" id="commentMsgImg" type="file"
                                        @change="onFileChange($event,'comment')" />
                                    <span class="text-red" v-html="picLimit"></span><br>

                                    <div class="pic has-imgClose mt-10"
                                         v-if="edit.留言圖片">
                                        <button 
                                            class="btn btn-imgClose fa fa-close"
                                            type="button" @click="removeFile('comment')">
                                            <span class="sr-only">close</span>
                                        </button>

                                        <a target="_blank" :href="edit.留言圖片">
                                            <img draggable="false" 
                                                style="max-width: 200px" 
                                                :src="edit.留言圖片" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- 私訊回覆內容 -->
                            <div class="form-group">
                                <label for="messengerMsgConten" class="col-sm-2 control-label">私訊回覆內容：
                                </label>
                                <div class="col-sm-10">
                                    <textarea id="messengerMsgConten" placeholder="請輸入私訊回覆文字，不填寫即不做私訊回覆"
                                        class="form-control" rows="4" cols="100"
                                        v-model="edit.私訊回覆訊息"></textarea>
                                </div>
                            </div>

                            <!-- 私訊圖片： -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="messengerMsgImg">
                                    私訊回覆圖片：
                                </label>
                                <div class="col-sm-9">
                                    <input accept="image/*" id="messengerMsgImg" type="file" 
                                            @change="onFileChange($event,'messenger')" />
                                    <span class="text-red" v-html="picLimit"></span><br>

                                    <div class="pic has-imgClose mt-10"
                                        v-if="edit.私訊圖片">
                                        <button 
                                            class="btn btn-imgClose fa fa-close"
                                            type="button" @click="removeFile('messenger')">
                                            <span class="sr-only">close</span>
                                        </button>
                                        <img draggable="false" style="max-width: 200px" 
                                            :src="edit.私訊圖片" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-footer">
                            <div class="btns">
                                <button @click="cancel()" class="btn btn-warning btn-lg"
                                    style="margin-right: 10px" type="button">
                                    放棄設定
                                </button>
                                <button class="btn btn-primary btn-lg" type="button"
                                    @click.prevent="submit()">
                                    確認送出
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </section>

    </div>
</template>
<script>
    /* eslint-disable */
    import iCheckRedioButton from "@/components/iCheckRedioButton";
    import { mapState, mapActions } from "vuex";
    import utils from "@/assets/js/app-utils";

    export default {
        components: {
            iCheckRedioButton: iCheckRedioButton,
        },
        props: {
            id: "",
        },

        data() {
            return {
                itemKey: '',   //input key
                sender_name: '{{sender_name}}',
                picLimit: "圖片建議為3MB以下。建議格式:jpg、png、gif、jpeg",
                folderName: '',
                imgList: [],
                projectId: "omp_FBRobot",
                專案:"",

                edit: {
                    代號: 0,
                    貼文設定代號: 0,
                    關鍵字: "", //api 帶的
                    留言回覆訊息: '',
                    留言圖片: '',
                    私訊回覆訊息: '',
                    私訊圖片: '',
                    符合度: 0 , 
                    自動按讚:1,

                },
                needKey: 1,
                imgList: [],
                keys: [], //前端切割

                searchData: {
                    代號: (Number(this.$route.params.key_id)),
                },

            };
        },
        created() {
            this.專案 = (this.$route.params.fanPage);

            if (this.$route.params.key_id != null) {
                this.GetKeywrodData();
            } else {
                this.edit.貼文設定代號 = (Number(this.$route.params.id));
            }
        },
        mounted() {
            
        },
        computed: {
            ...mapState({
                fileUploadResponse: state => state.file.response
            })
        },
        methods: {
            GetKeywrodData() {
                this.$store
                    .dispatch("fbRobot/queryKeywords", this.searchData)
                    .then((result) => {
                        // console.log(result);
                        this.edit = result;
                        this.keys = this.edit.關鍵字.split(',');
                        // console.log(this.keys);

                        //如果含空值
                        if (this.keys.indexOf("Null") > -1) {
                            // console.log('is Null');
                            this.edit.關鍵字 = ''; 
                            this.keys = []; 
                            this.needKey = 0 ;
                        }
                    });
            },  

            keyEnter(){
                //禁止空
                if (this.itemKey == "") {
                    return;
                }

                if (this.itemKey == "Null") {
                     this.$eventBus.$emit("showAlert", "Null為程式保留字，請勿輸入此關鍵字");
                    return;
                }
                
                if (this.keys.length >4) {
                    this.$eventBus.$emit("showAlert", "最多五個關鍵字");
                    return;
                }

                let isRepeat = false;
                this.keys.forEach(item => {
                    if (item == this.itemKey ) {
                        isRepeat = true;
                        this.$eventBus.$emit("showAlert", "請勿重覆關鍵字");
                        return;
                    } 
                });
                

                if (!isRepeat) {
                    this.keys.push(this.itemKey);
                    this.edit.關鍵字 = this.keys.toString();
                }

                this.itemKey ="";
            },
            delKey(index){
                this.keys.splice(index, 1);
                this.edit.關鍵字 = this.keys.toString();
                //console.log("delkey:",index);
            },

            onFileChange(evt , src) {
                var files = evt.target.files;
                this.folderName = src;

                if (files.length > 0) {
                    var isCheckType = true; //是否檢查圖片副檔名
                    var fileSize = files[0].size;
                    var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
                    var fileName = files[0].name;

                    if (isCheckType && !re.test(fileName)) {
                        this.$eventBus.$emit(
                            "showAlert",
                            "您選擇的檔案格式不符，請重新選擇。"
                        );
                        return;
                    }

                    if (fileSize > 3000000) {
                        this.$eventBus.$emit(
                            "showAlert",
                            "您選擇的檔案超過上限 3 MB，請重新選擇。"
                        );
                        return;
                    }
                }
                
                this.uploadFile(files, this.projectId,  this.folderName , this.專案);
                // this.preview(files);
            },

            removeFile(src){
                this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                    if (isOK) {
                        if(src == 'comment') {
                            this.edit.留言圖片 = '';
                            document.getElementById("commentMsgImg").value = "";
                        } else {
                            this.edit.私訊圖片 = '';
                            document.getElementById("messengerMsgImg").value = "";
                        }
                    }
                });
                
            },

             //存入檔案 還需調整
            uploadFile(files, projectId, folderName, id) {                
                let data = new FormData();

                for (var i = 0; i < files.length; i++) {
                    data.append("files", files[i]);
                }
                data.append("files", files);
                data.append("projectId", projectId);
                data.append("module", folderName);
                data.append("moduleId", id);

                this.$store.dispatch("file/fileUpload", data).then((result) => {
                    if (result.status != "1") {
                        this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
                    } 
                    else if (folderName == "comment") {
                        this.edit.留言圖片 = result.url;
                    } else {
                        this.edit.私訊圖片 = result.url;
                    }

                });
            },

            submit() {
                if (this.itemKey && !this.edit.關鍵字) {
                    this.$eventBus.$emit("showAlert", "請按'Enter'鍵送出關鍵字");
                    return;
                }

                if (this.needKey && !this.edit.關鍵字) {
                    this.$eventBus.$emit("showAlert", "請至少輸入一組關鍵字");
                    return;
                }

                 //若無關鍵字，1.符合度調整為部分符合0 2. 傳Null
                if (!this.needKey) {
                    this.edit.符合度 = 0;
                    this.edit.關鍵字 = "Null";
                    this.keys = []; 
                }

                
                // console.log(this.edit);

                if (this.edit.代號 == 0) {
                    //console.log('is creatKey');
                    this.$store
                        .dispatch("fbRobot/createKeywords", this.edit)
                        .then((result) => {
                            this.backList();
                        });
                    
                } else {
                    //console.log('is editKey');
                    this.$store
                        .dispatch("fbRobot/updateKeywords", this.edit)
                        .then((result) => {
                            this.backList();
                        });
                }
            },

            cancel(){
                this.$eventBus.$emit('showConfirm', '是否放棄設定?', (isOK) => {
                    if (isOK) {
                        this.backList();
                    }
                });
            },

            // TODO: 轉跳至列表頁
            backList() {
                this.$router.push({
                    name: "FBPostKeywordList",
                    params: {
                        searchData: this.$route.params.searchData,
                        fanPage: this.$route.params.fanPage,
                        id: this.$route.params.id,
                    },
                });
            },
        },
    };

</script>
