<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>購點欠費紀錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>購點欠費紀錄</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <label class="control-label">查詢日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker v-bind:propStartDate.sync="searchData.startTime"
                    v-bind:propEndDate.sync="searchData.endTime" />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">搜尋時間條件：</label>
              <select class="selPaymentType form-control" required="required" v-model="searchData.dateType">
                <option value="1">新增時間</option>
                <option value="2">退款時間</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">交易方式：</label>
              <select class="selPaymentType form-control" required="required" v-model="searchData.交易方式">
                <option value="">全部</option>
                <option value="IOS">IOS</option>
                <option value="Android">Android</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">服務名稱：</label>
              <select class="selPaymentType form-control" required="required" v-model="searchData.服務編號">
                <option :value="item.廠商編號" :key="index" v-for="(item, index) in serviceOptions">
                  {{ item.名稱 }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">是否繳款：</label>
              <select class="selPaymentType form-control" required="required" v-model="searchData.是否繳款">
                <option value="-1">全部</option>
                <option value="0">未繳款</option>
                <option value="1">已繳款</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">訂單編號：</label>
              <input type="text" class="selPaymentType form-control" v-model="searchData.訂單編號" />
            </div>
            <div class="form-group">
              <label class="control-label">儲值角色：</label>
              <input type="text" class="selPaymentType form-control" v-model="searchData.儲值角色" />
            </div>
            <div class="form-group">
              <label class="control-label">門號：</label>
              <input type="text" class="selPaymentType form-control" v-model="searchData.門號" />
            </div>
            <div class="form-group">
              <button @click="clickSearch" class="btn btn-primary" type="button">
                查詢
              </button>
            </div>
            <div class="form-group" v-if="list.length > 0">
              <button @click="exportOwedList" class="btn btn-success" type="button">
                匯出
              </button>
            </div>
            <div class="form-group" v-if="hasEditPermission">
              <button @click="addData()" class="btn btn-block btn-warning btn-add" type="button">
                <i class="fa fa-plus"></i> 新增
              </button>

              <!-- <i class="fa fa-plus" @click="addData"></i> 新增 -->
              <!-- <button @click="addData" class="btn btn-primary" type="button">
                          新增
                        </button> -->
            </div>
            <div class="form-group" v-if="hasEditPermission">
              <button @click="importData" class="btn btn-default" type="button">
                匯入資料
              </button>
            </div>
            <p class="data-num">
              <span>總筆數：</span>
              <span id="total" style="margin-right: 10px">{{ total }}</span>
              <!-- <span>不重複人數：</span>
                        <span id="people" style="margin-right: 10px">{{ people }}</span>-->
              <span>總金額：</span>
              <span id="amount" style="margin-right: 10px">{{ amount }}</span>
            </p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">
                    <span class="state icheckbox_flat-green" :class="批次處理.是否全選 ? 'checked' : ''"
                      @click="批次處理.是否全選 = !批次處理.是否全選">
                    </span>
                    <button class="btn btn-primary" type="button" :class="是否至少一筆被勾選 ? '' : 'disabled'"
                      @click="clickBatch">批次處理</button>
                  </th>
                  <th class="text-nowrap">訂單編號</th>
                  <th class="text-nowrap">服務編號</th>
                  <th class="text-nowrap">交易方式</th>
                  <th class="text-nowrap">儲值角色</th>
                  <th class="text-nowrap">金額</th>
                  <th class="text-nowrap">是否繳款</th>
                  <!-- <th class="text-nowrap">備註</th> -->
                  <th class="text-nowrap">平台收據時間</th>
                  <th class="text-nowrap">退款時間</th>
                  <th class="text-nowrap">凍結日期</th>
                  <th class="text-nowrap">繳費時間</th>
                  <th class="text-nowrap">新增時間</th>
                  <!-- <th class="text-nowrap">取得訂單時間</th> -->
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%" v-if="hasEditPermission">
                    功能
                  </th>
                </tr>
                <template v-if="list.length > 0">
                  <tr :key="item.id" v-for="item in list">
                    <td class="text-center">
                      <span class="state icheckbox_flat-green" :class="item.checked ? 'checked' : ''"
                        @click="item.checked = !item.checked">
                      </span>
                    </td>
                    <td class="text-center">{{ item.訂單編號 }}</td>
                    <td class="text-center">{{ item.服務編號 }}</td>
                    <td class="text-center">{{ item.交易方式 }}</td>
                    <td class="text-center">{{ item.儲值角色 }}</td>
                    <td class="text-center">{{ item.金額 }}</td>
                    <td class="text-center">
                      {{ item.是否繳款 == 0 ? "否" : "是" }}
                    </td>
                    <!-- <td class="text-center">{{ item.備註 }}</td> -->
                    <td class="text-center">
                      {{
                    $utils.formatTime(item.交易時間, "YYYY/MM/DD HH:mm:ss")
                  }}
                    </td>
                    <td class="text-center">
                      {{
                      $utils.formatTime(item.退款時間, "YYYY/MM/DD HH:mm:ss")
                    }}
                    </td>
                    <td class="text-center">
                      <span>{{
                      $utils.formatTime(item.凍結日期, "YYYY/MM/DD HH:mm:ss")
                    }}</span>
                      <br />
                      <!-- <button
                                  class="btn btn-warning"
                                  @click="openSettingTimeBox('1', item)"
                                >
                                  設定
                                </button> -->
                    </td>
                    <td class="text-center">
                      <span>{{
                      $utils.formatTime(item.繳費時間, "YYYY/MM/DD HH:mm:ss")
                    }}</span>
                      <br />
                      <!-- <button
                                  class="btn btn-warning"
                                  @click="openSettingTimeBox('2', item)"
                                >
                                  設定
                                </button> -->
                    </td>
                    <!-- <td class="text-center">
                                {{
                                  $utils.formatTime(item.新增時間, "YYYY/MM/DD HH:mm:ss")
                                }}
                              </td> -->
                    <td class="text-center">
                      {{
                      $utils.formatTime(
                        item.取得訂單時間,
                        "YYYY/MM/DD HH:mm:ss"
                      )
                    }}
                    </td>
                    <td class="text-center" v-if="hasEditPermission">
                      <button class="btn btn-danger" @click="editorItem(item)">
                        編輯
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="13">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <select class="form-control page-size" v-model="pageSize">
            <option :value=10>單頁顯示10項</option>
            <option :value=20>單頁顯示20項</option>
            <option :value=40>單頁顯示40項</option>
            <option :value=100>單頁顯示100項</option>
          </select>
          <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
        </div>
      </div>
      <PaymentOwedSettingTime ref="PaymentOwedSettingTime" v-on:refreshList="queryAll"></PaymentOwedSettingTime>
      <ImportPaymentOwedDatas ref="ImportPaymentOwedDatas" v-on:refreshList="queryAll"></ImportPaymentOwedDatas>
      <PaymentOwedBatch ref="PaymentOwedBatch" v-on:refreshList="queryAll"></PaymentOwedBatch>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import PaymentOwedSettingTime from "@/views/projects/ips/PaymentOwedSettingTime";
import ImportPaymentOwedDatas from "@/views/projects/ips/ImportPaymentOwedDatas";
import PaymentOwedBatch from "@/views/projects/ips/PaymentOwedBatch";
import { IPSTool } from './IPSTool2'
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
    DateRangePicker,
    PaymentOwedSettingTime,
    ImportPaymentOwedDatas,
    PaymentOwedBatch
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      hasEditPermission: false,
      pageSize: 10,
      orderStatusOptions: [],
      serviceOptions: [],
      productSN: "",
      searchData: {
        dateType: "1",
        服務編號: "-1",
        交易方式: "",
        是否繳款: "0",
        訂單編號: "",
        儲值角色: "",
        門號: "",
        開始時間: "",
        結束時間: "",
        開始取得訂單時間: "",
        結束取得訂單時間: "",
        起始行: 1,
        結束行: 10,
        startTime: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      批次處理: {
        是否全選: false,
      }
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
      this.pageSize = this.searchData.結束行 - this.searchData.起始行 + 1;
    }
  },
  mounted() {
  },
  computed: {
    ...mapState({
      user: (state) => state.loginUser,
      list(state) {
        //批次處理，加checked屬性
        for (let i of state.ipsPaymentOwed.list) {
          this.$set(i, "checked", false);
          this.$set(i, "st", 0);//增加狀態//0:未處理1:處理中2:完成
          this.$set(i, "log", "");//增加log//
        }
        return state.ipsPaymentOwed.list
      },
      total: (state) => state.ipsPaymentOwed.total,
      amount: (state) => state.ipsPaymentOwed.amount,
    }),
    是否至少一筆被勾選() {
      let flag = false;
      for (let i of this.list) {
        if (i.checked) {
          flag = true; break;
        }
      }
      return flag;
    },
  },
  watch: {
    list() {
      this.$refs.pagination.setPage(
        this.searchData.結束行 / this.pageSize,
        this.total
      );
    },
    ["批次處理.是否全選"](nowV, oldV) {
      for (let i of this.list) {
        i.checked = nowV;
      }
    },
    pageSize(v) {
      this.onPageChange(1);
    },
    ["$store.state.projectId"]: {
      handler(nowV, oldV) {
        this.hasEditPermission = this.$user.hasPermission(
          config.Permission.PaymentOwedEdit
        );
        this.取得服務名稱(nowV)
      },
      immediate: true
    }
  },
  methods: {
    async 取得服務名稱(nowV) {
      this.serviceOptions = await IPSTool.ConstServerList.call(this, this.$store.state.projectId, "購點欠費紀錄");//廠商專案列表;//await IPSTool.callAPIList.call(this, api.IPSSetting.廠商設定主檔_查詢, {});

      if (this.serviceOptions.length == 0) {
        this.searchData.服務編號 = "-1";
      } else {
        this.searchData.服務編號 = this.serviceOptions[0].廠商編號;
      }

      if (nowV != "") {
        this.queryAll();
      }
    },
    onPageChange(pageIdx) {
      this.searchData.起始行 = (pageIdx - 1) * this.pageSize + 1;
      this.searchData.結束行 = pageIdx * this.pageSize;
      this.queryAll();
    },
    queryAll() {
      if (this.searchData.服務編號 == -1) {
        this.$eventBus.$emit("showAlert", "請選擇服務編號");
        return;
      }
      this.searchData.開始取得訂單時間 = "";
      this.searchData.結束取得訂單時間 = "";
      this.searchData.開始時間 = "";
      this.searchData.結束時間 = "";

      if (
        this.searchData.儲值角色 == "" &&
        this.searchData.訂單編號 == "" &&
        this.searchData.門號 == ""
      ) {
        switch (this.searchData.dateType) {
          case "1":
            this.searchData.開始取得訂單時間 = this.searchData.startTime;
            this.searchData.結束取得訂單時間 = this.searchData.endTime;
            break;
          case "2":
            this.searchData.開始時間 = this.searchData.startTime;
            this.searchData.結束時間 = this.searchData.endTime;
            break;
        }
      }
      this.$store.dispatch("ipsPaymentOwed/queryAll", this.searchData);
      //this.$store.dispatch("payment/queryStatistics", this.searchData);
      this.$store.dispatch("ipsPaymentOwed/statistics", this.searchData);
    },
    clickSearch() {
      this.onPageChange(1);
    },
    //*---- 新增內容
    addData() {
      this.$router.push({
        name: "PaymentOwedEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "PaymentOwedEdit",
        params: {
          id: item.訂單編號,
          searchData: this.searchData,
        },
      });
      return;
    },
    openSettingTimeBox(type, data) {
      this.$refs.PaymentOwedSettingTime.showSettingTimeBox(type, data);
    },
    exportOwedList() {
      this.searchData.開始取得訂單時間 = "";
      this.searchData.結束取得訂單時間 = "";
      this.searchData.開始時間 = "";
      this.searchData.結束時間 = "";

      if (
        this.searchData.儲值角色 == "" &&
        this.searchData.訂單編號 == "" &&
        this.searchData.門號 == ""
      ) {
        switch (this.searchData.dateType) {
          case "1":
            this.searchData.開始取得訂單時間 = this.searchData.startTime;
            this.searchData.結束取得訂單時間 = this.searchData.endTime;
            break;
          case "2":
            this.searchData.開始時間 = this.searchData.startTime;
            this.searchData.結束時間 = this.searchData.endTime;
            break;
        }
      }

      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.起始行 = 0;
      tmpSearchData.結束行 = 0;
      this.$store.dispatch("ipsPaymentOwed/export", tmpSearchData);
    },
    importData() {
      this.$refs.ImportPaymentOwedDatas.importDataBox();
    },
    isSetTime(time) {
      if (time == "" || time == null) return true;
      return false;
    },
    clickBatch() {
      if (!this.是否至少一筆被勾選) {
        return;
      }
      let list = this.list.filter((item) => {
        return item.checked;
      });
      this.$refs.PaymentOwedBatch.open(list);
    },
  },
};
</script>

<style scoped>
.top-box .form-group {
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}

.top-box .input-group {
  min-width: 250px;
}

.selPaymentType {
  width: 150px;
}

.icheckbox_flat-green {
  margin-right: 10px;
}

.page-size {
  float: left;
  width: auto;
}
</style>