<template>
  <div class="modal fade" id="dataAlert" ref="BigDataAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">人物簡介</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td style="min-width: 85px">姓名</td>
                  <td>
                    {{ peopleData.target_name }}
                  </td>
                </tr>
                <tr>
                  <td>類型</td>
                  <td>
                    {{  array2string(peopleData.categorys) }}
                  </td>
                </tr>
                <tr>
                  <td>描述</td>
                  <td>
                    {{ peopleData.description }}
                  </td>
                </tr>
                <tr v-for="(item, key) in peopleData.channel_data" :key="key">
                  <td>
                    {{ channelNameChart[item.platform] }}
                  </td>
                  <td>
                    <a :href="item.channel_url" target="_blank">{{ item.channel_name }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer tc">
          <button class="btn btn-default" data-dismiss="modal" type="button">
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: ["propsin"], //props-in ，要傳入的api name
  data() {
    return {
      peopleData: [],
      apiName: "",
      channelNameChart: {
        ig: "Instagram",
        fb: "Facebook",
        yt: "Youtube",
        tw: "Twitch",
        tt: "Twitter",
        tiktok: "Tiktok",
        poscast: "Podcast",
      },
      selectSexList: {
        male: "男",
        fmale: "女",
        transgender: "其他",
      }
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    showContent(item) {
      this.peopleData = item;
      $(this.$refs.BigDataAlert).modal("show");
    },
    array2string(items) {
      if (items == null || items == []) return "暫無資料";
      return items.join("、 ");
    },
    showSuccess(content) {
      $(this.$refs.BigDataAlert).modal("hide");
      // this.$emit("kolList");
      this.$emit(props);
    },
  },
};
</script>
