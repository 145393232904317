<template>
  <div id="WhiteListByPhone">
    <section class="content-header">
      <h1>Molo進線歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">Molo進線歷程</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">
            <div class="select-group">
              <label class="control-label">開始時間：</label>
              <DatePicker v-bind:propValue.sync="searchData.開始時間" />
            </div>

            <div class="select-group">
              <label class="control-label">結束時間：</label>
              <DatePicker v-bind:propValue.sync="searchData.結束時間" />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">輸入門號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="門號"
                type="text"
                v-model="searchData.門號"
              />
            </div>

            <div class="input-group sW">
              <label class="control-label">輸入molokey：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="molokey"
                type="text"
                v-model="searchData.molokey"
              />
              <div class="input-group-btn verAlign-bottom">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ queryAll.total }}</span>
              </p>
            </div>

            <div class="form-group fr snmMT w100-md mt-15-md">
              <button
                @click="show"
                class="btn btn-primary w100-md mr-20"
                v-if="hasEditPermission"
                type="button"
              >添加白名單</button>
              <button @click="exportOrders" class="btn btn-success w100-md" type="button">匯出</button>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">遊戲名稱</th>
                  <th style="min-width: 90px; width: 5%">molokey</th>
                  <th class="text-nowrap" style="width: 10%;">門號</th>
                  <th class="text-nowrap" style="width: 10%;">白名單</th>
                  <th class="text-nowrap" style="width: 10%;">建立時間</th>
                </tr>
                <template v-if="queryAll.total > 0">
                  <tr :key="item.id" v-for="item in queryAll.datas">
                    <td align="center">{{ item.遊戲名稱 }}</td>
                    <td align="center">{{ item.molokey }}</td>
                    <td align="center">{{ item.門號}}</td>
                    <td align="center">{{ item.白名單 == 0? "進線":"新增" }}</td>
                    <td align="center">{{ FormatTime(item.建立時間) }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="queryAll.total > 0">
          <Pagination
            ref="pagination"
            :total="queryAll.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <addWhiteAcc ref="addWhiteAcc" v-on:refreshList="GetWhiteList" />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import addWhiteAcc from "@/views/WhiteListRecord/AddWhiteAcc";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DatePicker,
    Pagination,
    addWhiteAcc,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      hasEditPermission: false,
      searchData: {
        molokey: "",
        門號: "",
        遊戲名稱: "",
        開始時間: new moment().format("YYYY/MM/DD"),
        結束時間: new moment().format("YYYY/MM/DD"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetWhiteList();
  },
  mounted() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.WhiteListAdd
    );
  },
  computed: {
    ...mapState({
      queryAll: (state) => state.phoneVerify.queryAll,
    }),
  },
  watch: {
    queryAll() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.queryAll.total
      );
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetWhiteList();
    },

    GetWhiteList() {
      switch (this.$store.state.projectId) {
        case "omp_dk":
          this.searchData.遊戲名稱 = "DK";
          break;
      }

      this.$store.dispatch("phoneVerify/QueryAll", this.searchData);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetWhiteList();
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return utils.formatTime(time, "YYYY/MM/DD HH:ss:mm");
    },

    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("phoneVerify/Export", tmpSearchData);
    },

    show() {
      this.$refs.addWhiteAcc.show();
    },
  },
};
</script>


<style scoped>
.mr-20 {
  margin-right: 20px;
}
</style>