<template>
	<div id="clientGameHallList" class="slot">
		<section class="content">
			<div class="box box-primary" style="background: none;">
        <div class="box-body">
          <div class="g_back">
            <div @click="goBack()">
              <i class="fa fa-chevron-left"></i>
            </div>
            <div>
              <p>{{ query.遊戲名稱 }} 遊戲介紹</p>
              <p>{{ query.開發商名稱 }}</p>
            </div>
          </div>
					<div class="top-box">
            <div class="left">
              <div class="input-group mr-10">
                <input
                  class="form-control pull-right"
                  name="table_search"
                  placeholder="遊戲館名稱"
                  type="text"
                  v-model="searchData.遊戲館名稱"
                />
                <i class="fa fa-close" v-if="searchData.遊戲館名稱" @click="searchData.遊戲館名稱 = ''"></i>
                <div class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="resetData()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div class="input-group">
                <input
                  class="form-control pull-right"
                  name="table_search"
                  placeholder="玩法名稱"
                  type="text"
                  v-model="searchData.玩法名稱"
                />
                <i class="fa fa-close" v-if="searchData.玩法名稱" @click="searchData.玩法名稱 = ''"></i>
                <div class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="resetData()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div class="text">
                <p class="data-num">
                  <span>資料筆數：</span>
                  <span id="total">{{ total }}</span>
                </p>
                <div class="dropdown" style="display: inline;">
                  <button
                    class="btn btn-sort dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
                  >
                    <div class="icon-sort"></div>
                    排序依據
                  </button>
                  <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
                    <li>
                      <a 
                        :class="['dropdown-item', searchData.排序順序 == 2 ? 'active' : '']"
                        href="#" 
                        @click="resetData(2)"
                      >
                        A to Z
                      </a>
                    </li>
                    <li>
                      <a
                        :class="['dropdown-item', searchData.排序順序 == 1 ? 'active' : '']"
                        href="#" 
                        @click="resetData(1)"
                      >
                      Z to A
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button class="btn btn-default btn-play" style="float: right;" @click="showPlay()">
              <i class="fa fa-book"></i>
              所有玩法定義
            </button>
          </div>
          <Empty v-if="total == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
          <div class="g-list hall table-responsive mt-20">
            <div
              v-for="(item, index) in list"
              class="g-card"
              style="cursor: pointer;"
              @mouseout="pauseVideo()"
              @mouseover="playVideo()"
            >
              <GameHallCard :item="item"></GameHallCard>
            </div>
            <div class="loading-box" v-if="searchData.PageNum < totolPage">
              <div class="loading"></div>
            </div>
          </div>
				</div>
			</div>
		</section>
    <slotPlayContent ref="slotPlayContent"></slotPlayContent>
	</div>
</template>

<script>
/* eslint-disable */
// import api from "../assets/js/app-api";
import { mapState } from "vuex";
import Empty from "@/components/SlotEmpty";
import slotPlayContent from "@/components/slot/slotPlayContent";
import GameHallCard from "@/components/slot/gameHallCard";

export default {
  components: {
    Empty,
    slotPlayContent,
    GameHallCard
  },
  data() {
    return {
      loading: false,
      dropdown: 'none',
      searchData: {
        遊戲編號: Number(this.$route.params.id),
        遊戲館名稱: "",//關鍵字比對
        標籤編號: "",//查詢標籤
        玩法名稱: "",//查詢玩法名稱關鍵字比對
        PageNum: 1,
        PageSize: 12,
        排序條件: 2,//1:更新時間,2.遊戲館名稱
        排序順序: 2,//1:DESC,2:ASC
      },
      list: [],
      isEnd: false,
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
      this.searchData.PageNum = 1;
    }
    this.getDataList();
    this.$store.dispatch("slot/gameQuery", { 編號: this.searchData.遊戲編號 })
  },
  computed: {
    ...mapState({
      query: state => state.slot.gameQuery,
      gameHallList: state => state.slot.gameHallQueryAll.datas,
      total: state => state.slot.gameHallQueryAll.total,
    }),
    totolPage() {
      return Math.ceil(this.total / this.searchData.PageSize)
    }
  },
  mounted() {
    $(window).on("scroll", () => {
      this.scrollEvent();
    })
  },
  watch: {
    gameHallList() {
      this.list = this.list.concat(this.gameHallList);
      $(window).on("scroll", () => {
        this.scrollEvent();
      })
      this.$forceUpdate();
    }
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.$store.dispatch("slot/gameHallQueryAll", this.searchData)
        .then((res) => {
          this.loading = false;
        })
    },
    resetData(order) {
      this.isEnd = false;
      this.list = [];
      this.searchData.PageNum = 1;
      if(order) this.searchData.排序順序 = order;
      this.dropdown = "none";
      this.getDataList();
    },
    goBack() {
      if(this.$route.params.back == 'slotSearch') {
        this.$router.push({
          name: "slotSearch",
          params: {
            keyword: this.$route.params.search,
            searchData: this.$route.params.backSearchData,
          }
        });
      } else {
        this.$router.push({
          name: "slotClientGameList",
          params: {
            searchData: this.$route.params.backSearchData,
          }
        });
      }
    },
    goContent(id) {
      this.$router.push({
        name: "clientGameContent",
        params: {
          gameId: this.searchData.遊戲編號,
          id: id,
          backSearchData: this.searchData,
          back: 'slotClientGameHallList'
        }
      });
    },
    showPlay(id) {
      if(id) this.$refs.slotPlayContent.getData(id);
      else this.$refs.slotPlayContent.showContent = true;
    },
    imgSize(index) {
      let h = $('#img_' + index).height();
      let w = $('#img_' + index).width();
      if(h > w) return 'height: 100%;'
    },
    scrollEvent() {
      if(this.isEnd) return
      let sectionTop = $(window).scrollTop();
      let sectionH = $(window).height();
      let documentH = $(".loading-box").offset().top;

      if(this.searchData.PageNum < this.totolPage) {
        if(sectionTop + sectionH >= documentH + 300) {
          this.searchData.PageNum = this.searchData.PageNum + 1;
          this.getDataList();
        }
      }
      if(this.searchData.PageNum == this.totolPage) {
        this.isEnd = true;
      }
    },
    pauseVideo() {
      const video = document.getElementById("YTVideo")
      video.contentWindow.postMessage( '{"event":"command", "func":"pauseVideo", "args":""}', '*');
    },
    playVideo() {
      const video = document.getElementById("YTVideo")
      video.contentWindow.postMessage( '{"event":"command", "func":"playVideo", "args":""}', '*');
    },
  },
  beforeDestroy() {
    $(window).off("scroll");
  },
};
</script>