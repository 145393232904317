<template>
    <div>
        <div class="btn btn-primary">
            <span @click="show()">新增金額</span>
        </div>
        <br />
        <div class="btn btn-danger" v-for="金額 in 儲值金額List" :key="金額">
            <span>{{金額}} </span>
            <span class="img-close fa fa-fw fa-close" @click="delOption(金額)"></span>
        </div>
        <SelItemForm ref="selIPSForm" @addOption="addOption"></SelItemForm>
    </div>
</template>
  
<script>
import SelItemForm from './SelItemForm.vue';
export default {
    components: { SelItemForm },
    props: ["儲值金額List"],
    methods: {
        show() {
            this.$refs.selIPSForm.show();
        },
        addOption(金額) {
            this.$emit("addOption", 金額);
        },
        delOption(金額) {
            this.$emit("delOption", 金額);
        },
    },
}
</script>
  