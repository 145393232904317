<template>
  <table class="table table-hover table-bordered min-800">
    <tbody>
      <tr class="bg-gray">
        <th style="text-nowrap">訂單編號</th>
        <th style="text-nowrap">GASH交易序號</th>
        <th style="text-nowrap">代收方式</th>
        <th style="text-nowrap">暱稱</th>
        <th style="text-nowrap">玩家識別值</th>
        <th class="text-nowrap">訂單狀態</th>
        <th class="text-nowrap">幣別</th>
        <th class="text-nowrap">金額</th>
        <th class="text-nowrap">配點狀態</th>
        <th class="text-nowrap">配點金額</th>
        <th class="text-nowrap">建立日期</th>
        <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
      </tr>
      <template v-if="orders.length > 0">
        <tr :key="item.id" v-for="item in orders">
          <td>{{ item.訂單編號 }}</td>
          <td>{{ item.廠商訂單編號 }}</td>
          <td>{{ item.儲值類型 }}</td>
          <td>{{ item.玩家暱稱}}</td>
          <td>{{ item.玩家識別值}}</td>
          <td>{{ item.訂單狀態 }}</td>
          <td>{{ item.幣別 }}</td>
          <td>{{ item.交易金額 }}</td>
          <td>{{ item.通知配點狀態 }}</td>
          <td>{{ item.配點金額 }}</td>
          <td>{{ $utils.formatTime( item.建立日期, "YYYY/MM/DD HH:mm:ss") }}</td>
          <td class="text-center">
            <button
              v-show="isRequireCheck(item)"
              @click="checkOrder(item)"
              class="btn btn-primary"
            >重新驗證</button>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td class="text-center" colspan="12">沒有資料</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    orders: Array
  },
  methods: {
    checkOrder(order) {
      this.$emit("checkOrder", order, "Gash");
    },
    isRequireCheck(order) {
      let code = order["訂單狀態"];
      if (code != null) code = code.trim();
      if (code == "" || code == "0" || code == "W") return true;
      if (order["通知配點狀態"] == "-1" || order["通知配點狀態"] == "2") return true;
      return false;
    }
  }
};
</script>