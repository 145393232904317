<template>
  <!-- Left side column. contains the logo and sidebar -->
  <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul class="sidebar-menu" data-widget="tree" v-if="menus">
        <li>
          <router-link to="/Home">
            <i class="fa fa-fw fa-home"></i>
            <span>{{$t('首頁')}}</span>
          </router-link>
        </li>
        <li :class="subMenu(item)" :data-v="item.isVueMenu" :key="index" v-for="(item, index) in menus">
          <template>
            <a href="#" v-if="item.subMenu.length > 0">
              <!-- 	公告 -->
              <i class="fa fa-fw fa-bullhorn" v-if="item.menuId == 'A0'"></i>
              <!-- 其他 / 預設 -->
              <i class="fa fa-fw" :class="includeIcon(item.menuName) || 'fa-circle'" v-else></i>
              <span>{{ $t(removeIcon(item.menuName)) }}</span>
              <span class="pull-right-container">
                <i class="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <router-link :to="{ path: item.menuUrl }" v-else>
              <!-- 其他 / 預設 -->
              <i class="fa fa-fw" :class="includeIcon(item.menuName) || 'fa-circle-o'"></i>
              <span>{{ $t(removeIcon(item.menuName)) }}</span>
            </router-link>
          </template>

          <!-- 第二層 -->
          <template v-show="item.subMenu.length > 0">
            <ul class="treeview-menu">
              <li :class="subMenu2(item2)" :key="index" v-for="(item2, index) in item.subMenu">
                <template>
                  <a href="#" v-if="item2.subMenu.length > 0">
                    <!-- 其他 / 預設 -->
                    <i class="fa fa-fw" :class="includeIcon(item2.menuName) || 'fa-circle'"></i>
                    <span>{{ $t(removeIcon(item2.menuName)) }}</span>
                    <span class="pull-right-container">
                      <i class="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <template v-else>
                    <router-link :to="{ path: item2.menuUrl }" v-show="item2.menuId != 'AN-02'">
                      <!-- 其他 / 預設 -->
                      <i class="fa fa-fw" :class="includeIcon(item2.menuName) || 'fa-circle-o'"></i>
                      <span>{{ $t(removeIcon(item2.menuName)) }}</span>
                    </router-link>
                  </template>
                </template>

                <!-- 第三層 -->
                <template v-show="item3.subMenu.length > 0">
                  <ul class="treeview-menu">
                    <li :key="index" v-for="(item3, index) in item2.subMenu">
                      <router-link :to="{ path: item3.menuUrl }">
                        <i class="fa fa-fw" :class="includeIcon(item3.menuName) || 'fa-circle-o'"></i>
                        <span>{{ $t(item3.menuName) }}</span>
                      </router-link>
                    </li>
                  </ul>
                </template>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
export default {
  data() {
    return {
      menus: [],
    };
  },
  created() {
  },
  computed: mapState({
    projectId: state => state.projectId,
  }),
  watch: {
    projectId() {
      this.showMenuTree(this.projectId);
    }
  },
  mounted() {
    this.showMenuTree(this.projectId);
  },
  methods: {
    showMenuTree(projectId) {
      if (this.$store.state.loginUserMenu == "") {
        return;
      }
      var Selector = {
        wrapper: ".wrapper",
        contentWrapper: ".content-wrapper",
        layoutBoxed: ".layout-boxed",
        mainFooter: ".main-footer",
        mainHeader: ".main-header",
        mainSidebar: ".main-sidebar",
        slimScrollDiv: "slimScrollDiv",
        sidebar: ".sidebar",
        controlSidebar: ".control-sidebar",
        fixed: ".fixed",
        sidebarMenu: ".sidebar-menu",
        logo: ".main-header .logo"
      };
      if ($(Selector.mainSidebar).find(Selector.slimScrollDiv).length === 0) {
        $(Selector.sidebar).slimScroll({
          height: $(window).height() - $(Selector.mainHeader).height() + "px"
        });
      }

      if (this.$store.state.projectId) {
        this.menus = this.$store.state.loginUserMenu.filter(
          project => project.projectId == projectId
        )[0].menus;
      }
      if (this.menus) $("ul").tree();
    },
    subMenu(item) {
      if (item.subMenu.length > 0) {
        return "treeview";
      }
    },
    subMenu2(item2) {
      if (item2.subMenu.length > 0) {
        return "treeview";
      }
    },

    // 回傳字串中的 fa-[icon]
    includeIcon(text){
      let match = text.match(/(fa-\S+)/ig);
      return match && match[0] ? match[0] : '';
    },
    // 字串中移除 fa-[icon]
    removeIcon(text){
      return text.replace(/(fa-\S+)/ig, '').trim();
    }
  }
  // computed: {
  //   subMenu(item) {
  //     return {
  //       active: this.isActive,
  //     };
  //   }
  // }
  ,
};
</script>
<style scoped></style>