<template>
  <FormDialog ref="memberServiceDialog" :title="$t((isEdit ? '修改' : '新增') + '服務紀錄')" @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-if="!isEdit" v-model="data.分類" class="form-control" required :disabled="isEdit">
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
              <span v-else>{{ data.異動動作 }}</span> 
            </td>
          </tr>
          <tr>
            <th>{{$t('內容')}}</th>
            <td>
              <textarea v-if="!isEdit" v-model="data.內容" class="form-control" min="1" rows="5" required></textarea>
              <textarea v-else v-model="data.新內容" class="form-control" min="1" rows="5" required></textarea>
            </td>
          </tr>
          <tr>
            <th>{{$t('處理狀態')}}</th>
            <td>
              <iCheckRedioButton  v-model.number="data.處理狀態" checkValue="0" :message="$t('處理中')" name="blockType" class="radio-inline" />
              <iCheckRedioButton v-model.number="data.處理狀態" checkValue="1" :message="$t('結案')" name="blockType" class="radio-inline ml-25" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog";
import iCheckRedioButton from "@/components/iCheckRedioButton";

export default {
  props:{
    types: {type:Object, required:true}
  },
  components: {
    FormDialog,
    iCheckRedioButton
  },
  data() {
    return {
      data: {
        暱稱:"",
        分類:"",
        內容:"",
        處理狀態: 0, // 0未處理 1處理
      },
      user: {},
      isEdit: false,
    };
  },
  mounted(){
  },
  methods: {
    init(){
      Object.assign(this.$data, this.$options.data()); // 重設data
    },
    async add(user) {
      this.init();
      this.user = user;
      this.data.暱稱 = user.玩家識別值;
      this.$refs.memberServiceDialog.show();
    },
    async edit(data) {
      this.init();
      this.isEdit = true;
      this.data = Object.assign({}, data);
      this.$refs.memberServiceDialog.show();
    },
    async delete(data) {
      this.$store.dispatch("xsw/會員服務記錄刪除", {
        "玩家異動紀錄ID": data.玩家異動紀錄ID
      }).then(res => {
        this.$eventBus.$emit("showAlert", this.$t("刪除成功"));
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      });
    },
    submit() {
      let api = this.isEdit ? '修改' : '新增';
      this.$store.dispatch("xsw/會員服務記錄" + api, this.data).then(() => {
        this.$refs.memberServiceDialog.hide();
        this.$eventBus.$emit("showAlert", this.$t(api + "成功"));
        this.$emit("update");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
