import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";

// initial state
// shape: [{ id, quantity }]
const state = {
    response: '',
    kolList: [],
    kpiList: [],
    kolTotal: 0,
    kpiTotal: 0,
    historyTotal: 0,
    kolQuery: [],
    historyList: [],
    kolkpiData: [],
    postkpiData: [],
    leaderboardList: [],
    leaderboardTotal: 0,
    kolResponse: "",
    keyList: [],
}

// getters
const getters = {
}

// actions
const actions = {
    search({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.queryAll(data).then(res => {
                if (res.data.status == "1") {
                    commit('setKolList', res.data.result.datas);
                    commit('setKolTotal', res.data.result.total);
                } else {
                    // this._vm.$eventBus.$emit("showAlert", res.data.message);
                    commit('setKolList', []);
                    commit('setKolTotal', 0);
                }
                resolve(res.data);
            })
        })
    },
    query({ commit }, data) {
        api.kolBigData.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setKolQuery', res.data.result);
            } else {
                commit('setKolQuery', []);
            }
        })
    },
    kpi({ commit }, data) {
        api.kolBigData.kpi(data).then(res => {
            commit('setKpiList', res.data);
            commit('setKpiTotal', res.data.length);
        })
    },
    queryChannel({ commit }, data) {
        api.kolBigData.queryChannel(data).then(res => {
            if (res.data.status == "1") {
                commit('setChannel', res.data.result.datas);
            }
        })
    },
    edit({ commit }, data) {
        api.kolBigData.update(data).then(res => {
            if (res.data.status == "1") {
                this._vm.$eventBus.$emit("showAlert", "更新完成");
            } else {
                this._vm.$eventBus.$emit("showAlert", "更新失敗");
            }

            commit('setResponse', res.data);
        })
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.create(data).then(res => {
                if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                }

                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    follow({ commit }, data) {
        api.kolBigData.follow(data).then(res => {
            if (res.data.status == "1") {
                commit('setResponse', res.data.content);
            }
        })
    },
    updateChannel({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.updateChannel(data).then(res => {
                if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "更新完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "更新失敗");
                }
                resolve(res.data);
            })
        })
    },
    createChannel({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.createChannel(data).then(res => {
                if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                }
                resolve(res.data);
            })
        })
    },
    uploadImage({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.uploadImage(data).then(res => {
                resolve(res.data);
            })
        })
    },
    export({ commit }, data) {
        let typeName = "網紅大數據";
        api.kolBigData.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.result.datas.length <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                    return;
                }

                let tempDatas = [];

                res.data.result.datas.forEach(data => {
                    data.channel_data.forEach(channel => {
                        tempDatas.push({
                            姓名: data.target_name,
                            社群平台: channel.platform,
                            頻道名稱: channel.channel_name,
                            連結: channel.channel_url,
                            總粉絲數: channel.sum_like,
                            "貼文(影片)數": channel.interval_post_count,
                            平均留言數: channel.avg_comment,
                            平均按讚數: channel.avg_like,
                            平均觀看數: channel.avg_view_content,
                            互動率: channel.avg_post_probability,
                            漲粉率: channel.fans_up_probability,
                            最後更新時間: data.crawler_time
                        });
                    });
                });


                appUtils.exportData(
                    tempDatas,
                    appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
                    typeName,
                    [
                        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
                        { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
                        { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
                        { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
                        { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } },
                        { s: { r: 0, c: 5 }, e: { r: 1, c: 5 } },
                        { s: { r: 0, c: 6 }, e: { r: 1, c: 6 } },
                        { s: { r: 0, c: 7 }, e: { r: 1, c: 7 } },
                        { s: { r: 0, c: 8 }, e: { r: 1, c: 8 } },
                        { s: { r: 0, c: 9 }, e: { r: 1, c: 9 } },
                        { s: { r: 0, c: 10 }, e: { r: 1, c: 10 } },
                        { s: { r: 0, c: 11 }, e: { r: 1, c: 11 } }
                    ]
                );
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    history({ commit }, data) {
        api.kolBigData.history(data).then(res => {
            if (res.data.status == "1") {
                commit('setHistoryList', res.data.result.history_item);
                commit('setHistoryTotal', res.data.result.total);
            }
            else
                commit('setHistoryList', []);
        });
    },
    kolkpi({ commit }, data) {
        api.kolBigData.kolkpi(data).then(res => {
            if (res.data.status == "1") {
                commit('setkolkpiData', res.data.result);
                //    commit('setkolkpiData', res.data.result.hits);
            }
        });
    },
    postkpi({ commit }, data) {
        api.kolBigData.postkpi(data).then(res => {
            if (res.data.status == "1") {
                commit('setPostkpiData', res.data.result);
            }
        });
    },
    crawlerKOLChannel({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.crawlerKOLChannel(data).then(res => {
                resolve(res.data);
            });
        });
    },
    kolleaderboard({ commit }, data) {
        api.kolBigData.kolleaderboard(data).then(res => {
            if (res.data != null && res.data.status == "1") {
                commit('setLeaderboardList', res.data.result.result);
                commit('setLeaderboardTotal', res.data.result.total);
            } else {
                commit('setLeaderboardList', []);
                commit('setLeaderboardTotal', 0);
            }
        });
    },
    keySearch({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.kolBigData.keySearch(data).then(res => {
                if (res.data.status == "1") {
                    commit('setKolList', res.data.result.datas);
                    commit('setKolTotal', res.data.result.total);
                } else {
                    // this._vm.$eventBus.$emit("showAlert", res.data.message);
                    commit('setKolList', []);
                    commit('setKolTotal', 0);
                }
                resolve(res.data);
            })
        })
    },

}

// mutations
const mutations = {
    setKolList(state, kolList) {
        state.kolList = kolList;
    },
    setKpiList(state, kpiList) {
        state.kpiList = kpiList;
    },
    setHistoryList(state, historyList) {
        state.historyList = historyList;
    },
    setLeaderboardList(state, leaderboardList) {
        state.leaderboardList = leaderboardList;
    },
    setLeaderboardTotal(state, leaderboardTotal) {
        state.leaderboardTotal = leaderboardTotal;
    },
    setkolkpiData(state, kolkpiData) {
        state.kolkpiData = kolkpiData;
    },
    setPostkpiData(state, postkpiData) {
        state.postkpiData = postkpiData;
    },
    setKolTotal(state, kolTotal) {
        state.kolTotal = kolTotal;
    },
    setKpiTotal(state, kpiTotal) {
        state.kpiTotal = kpiTotal;
    },
    setHistoryTotal(state, historyTotal) {
        state.historyTotal = historyTotal;
    },
    setKolQuery(state, kolQuery) {
        state.kolQuery = kolQuery;
    },
    setKolResponse(state, kolResponse) {
        state.kolResponse = kolResponse;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setKeyList(state, keyList) {
        state.keyList = keyList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}