import { render, staticRenderFns } from "./gameContent.vue?vue&type=template&id=23c3db47"
import script from "./gameContent.vue?vue&type=script&lang=js"
export * from "./gameContent.vue?vue&type=script&lang=js"
import style0 from "./gameContent.vue?vue&type=style&index=0&id=23c3db47&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports