<template>
    <div>
        <section class="content-header">
            <h1> 新增/修改廠商直儲金流</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'FirmPayFlowList',
                        query: this.$route.query,
                    });">廠商直儲金流</a>
                </li>
                <li class="active">新增/修改廠商直儲金流 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 廠商：
                                </label>
                                <div class="col-sm-8">
                                    <select class="form-control" v-model="edit.廠商設定主檔編號" :disabled="isEdit">
                                        <option v-for="item in 廠商設定List" :key="item.編號" :value="item.編號">
                                            {{ item.顯示名稱 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 平台遊戲編號：
                                </label>
                                <div class="col-sm-8">
                                    <select class="form-control" v-model="edit.平台遊戲編號">
                                        <option value="">不設定</option>
                                        <option v-for="item in 廠商設定List" :key="item.編號" :value="item.廠商編號">
                                            {{ item.顯示名稱 }} {{ item.廠商編號 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 直儲名稱：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.直儲名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 商品代碼：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.商品代碼" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    第三方物品編號：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.第三方物品編號" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 廠商產品名稱
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"
                                        aria-expanded="true">
                                        <span><i class="fa fa-fw fa-question"></i></span>
                                    </button>
                                    ：
                                    <div class="dropdown-menu common">
                                        顯示在「付款資訊頁-付款品項」<br />
                                        後端帶入儲值金額=&lt;儲值金額&gt; <br />
                                        EX： <br />
                                        後端儲值金額設100， <br />
                                        廠商產品名稱輸入<font class="text-red">&lt;儲值金額&gt;點</font> <br />
                                        前端會顯示<font class="text-red">100</font>點 <br />
                                        <hr />
                                        官網帶入點數=&lt;point&gt; <br />
                                        官網帶入價格=&lt;price&gt; <br />
                                        <br />
                                        範例： <br />
                                        付款品項顯示：yoe數位卡<font class="text-blue">50</font>點(單價$<font class="text-red">50</font>
                                        *1) <br />
                                        設定需設為：yoe數位卡<font class="text-blue">&lt;point&gt;</font>點(單價$<font class="text-red">
                                            &lt;price&gt;</font>*1)
                                        <br />
                                        <img src="/download/IPS_illustrate01.png" />
                                    </div>
                                </label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" placeholder="" v-model.trim="edit.廠商產品名稱"
                                        v-AddArgInput="{ model: 'edit.廠商產品名稱', format: AddArgFormat }" ref="廠商Input">
                                    <span class="input-group-btn widthAuto">
                                        <button type="button" class="btn btn-info btn-flat"
                                            v-AddArgBtn="{ insertText: '<儲值金額>', ref: '廠商Input', model: 'edit.廠商產品名稱' }">添加&lt;儲值金額&gt;</button>
                                    </span>
                                    <span class="input-group-btn widthAuto">
                                        <button type="button" class="btn btn-info btn-flat"
                                            v-AddArgBtn="{ insertText: '<point>', ref: '廠商Input', model: 'edit.廠商產品名稱' }">添加&lt;point&gt;</button>
                                    </span>
                                    <span class="input-group-btn widthAuto">
                                        <button type="button" class="btn btn-info btn-flat"
                                            v-AddArgBtn="{ insertText: '<price>', ref: '廠商Input', model: 'edit.廠商產品名稱' }">添加&lt;price&gt;</button>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> ERP名稱：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.erP名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 直儲模式：
                                </label>
                                <div class="col-sm-8">
                                    <select class="form-control" v-model="edit.直儲模式">
                                        <option v-for="item in 直儲模式" :key="item.編號" :value="item.編號">
                                            {{ item.名稱 }}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 金流帳號-金流支付：
                                </label>
                                <div class="col-sm-8">
                                    <select class="form-control" v-model="edit.廠商支付編號">
                                        <option v-for="item in filter廠商支付設定List" :key="item.編號" :value="item.編號">
                                            {{ 金流帳號Key[item.金流帳號編號].名稱 }}-{{ 金流支付設定Key[item.金流支付設定編號].支付名稱 }}
                                            <!-- {{ 金流設定主檔Key[item.金流設定主檔編號].金流名稱 }} -->
                                        </option>
                                    </select>
                                    <PayInfo ref="upSetting" :上層設定="上層設定" :金流設定主檔Key="金流設定主檔Key" :金流帳號Key="金流帳號Key"
                                        :金流支付設定Key="金流支付設定Key" :地區列表Key="地區列表Key" :金流類型設定Key="金流類型設定Key">
                                    </PayInfo>
                                    <!-- <div class="payStyle" v-if="上層設定">
                                        <ul>
                                            <li>廠商支付</li>
                                            <li>啟停用：
                                                <span v-if="上層設定.啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>金流設定</li>
                                            <li>金流名稱：{{ 金流設定主檔Key[上層設定.金流設定主檔編號].金流名稱 }}</li>
                                            <li>金流代碼：{{ 金流設定主檔Key[上層設定.金流設定主檔編號].金流代碼 }}</li>
                                            <li>啟停用：
                                                <span v-if="金流設定主檔Key[上層設定.金流設定主檔編號].啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>金流帳號</li>
                                            <li>名稱：{{ 金流帳號Key[上層設定.金流帳號編號].名稱 }} </li>
                                            <li>金流識別值：{{ 金流帳號Key[上層設定.金流帳號編號].金流識別值 }} </li>
                                            <li>啟停用：
                                                <span v-if="金流帳號Key[上層設定.金流帳號編號].啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>金流支付</li>
                                            <li>支付名稱：{{ 金流支付設定Key[上層設定.金流支付設定編號].支付名稱 }} </li>
                                            <li>支付方式代碼：{{ 金流支付設定Key[上層設定.金流支付設定編號].支付方式代碼 }} </li>
                                            <li>地區：{{ 地區列表Key[金流支付設定Key[上層設定.金流支付設定編號].地區].地區 }}</li>
                                            <li>限制幣別：{{ 地區列表Key[金流支付設定Key[上層設定.金流支付設定編號].限制幣別].幣別 }} </li>
                                            <li>金流類型：{{ 金流類型設定Key[金流支付設定Key[上層設定.金流支付設定編號].金流類型].類型 }} </li>
                                            <li>是否開立發票：{{ 金流支付設定Key[上層設定.金流支付設定編號].是否開立發票 == 1 ? '是' : '否' }} </li>
                                            <li>啟停用：
                                                <span v-if="金流支付設定Key[上層設定.金流支付設定編號].啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                            </div>
                            <div class="form-group" v-if="上層設定">
                                <label class="col-sm-4 control-label">支付品號： </label>
                                <div class="col-sm-8">
                                    <div class="input-group margin" style="margin: 0px;">
                                        <input type="text" class="form-control"
                                            :placeholder="支付品號placeholder(上層設定.支付品號, 金流支付設定Key[上層設定.金流支付設定編號].支付品號)"
                                            v-model.trim="edit.支付品號">

                                        <!-- <span class="input-group-btn">
                                            <button type="button" class="btn btn-info btn-flat" style="width:200px"
                                                @click="edit.支付品號 = 上層設定.支付品號">上層設定：{{
                                                    上層設定.支付品號
                                                }}</button>
                                        </span> -->
                                        <span class="input-group-btn">
                                            <button type="button" class="btn btn-info btn-flat" style="width:200px"
                                                @click="edit.支付品號 = 金流支付設定Key[上層設定.金流支付設定編號].支付品號">品號(金流層)：{{
                                                    金流支付設定Key[上層設定.金流支付設定編號].支付品號
                                                }}</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="上層設定">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 儲值金額
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"
                                        aria-expanded="true">
                                        <span><i class="fa fa-fw fa-question"></i></span>
                                    </button>
                                    ：
                                    <div class="dropdown-menu">
                                        如果儲值金額不設，此值會由遊戲帶入
                                    </div>
                                </label>
                                <div class="col-sm-8" v-if="是否檢查儲值金額">
                                    <AddItemForm :儲值金額List="edit.儲值金額" @addOption="addOption" @delOption="delOption">
                                    </AddItemForm>
                                </div>
                                <div class="col-sm-8" v-else>
                                    <div class="input-group margin" style="margin: 0px;">
                                        <span class="form-control noline">此金流類型不需設定</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 自訂金額：
                                </label>
                                <div class="col-sm-8" v-if="是否檢查儲值金額">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="自訂金額" v-model="edit.是否自訂金額" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="自訂金額" v-model="edit.是否自訂金額" />
                                    </div>
                                </div>
                                <div class="col-sm-8" v-else>
                                    <div class="input-group margin" style="margin: 0px;">
                                        <span class="form-control noline">此金流類型不需設定</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-show="限制開關">
                                <!-- {{ edit.額外限制 }} -->
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 額外限制：
                                </label>
                                <div class="col-sm-8">
                                    <LimitControler ref="comp" :額外限制List.sync="edit.額外限制"></LimitControler>
                                </div>
                            </div>


                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { mapState } from "vuex";
import AddItemForm from "./FirmPayFlowEdit/AddItemForm.vue";
import LimitControler from "./components/LimitControler2.vue";
import api from "@/assets/js/app-api";
import { 檢查輸入數值 } from "@/views/projects/ips/Arithmetic";
import { IPSTool, APILib, IsSelectedInOptions, IPSMixins } from './IPSTool2'
import { AddArgMixins } from '@/assets/js/Tool/AddArgMixins'
import PayInfo from "./components/PayInfo.vue";
export default {
    mixins: [IPSMixins, AddArgMixins],
    components: {
        iCheckRedioButton,
        AddItemForm,
        LimitControler,
        PayInfo
    },
    data() {
        return {
            AddArgFormat: AddArgMixins.AddArgFormatEnum.一個大於小於,
            廠商設定List: [],
            金流設定主檔Key: {},
            金流帳號Key: {},
            金流支付設定Key: {},
            廠商支付設定List: [],
            isEdit: false,
            edit: {
                廠商設定主檔編號: -1,//int，必填，來源 廠商設定主檔_查詢
                直儲名稱: "",//str，必填
                商品代碼: "",// str，必填
                第三方物品編號: "",//str，選填
                廠商產品名稱: "",// str，必填
                直儲模式: -1,//int，必填，來源 基礎設定檔.直儲模式
                支付品號: "",//int，必填，來源 金流支付設定_查詢
                儲值金額: [],//這裡收到的會是處理過的一維數字陣列 //，str，必填，ex: 100,200,300,400,500,600
                是否自訂金額: 1,// int 必填，0:關閉 1:開啟
                server端的額外限制編號: "-",//複製一份用，避免前端修改資料時動到，最後送出時，比對用
                額外限制: [],//str，選填，格式為1,2,3,5,10 來源額外限制-編號
                啟用: 1,//int 必填，0:關閉 1:開啟
                平台遊戲編號: "",
            }
        };
    },
    computed: {
        filter廠商支付設定List() {
            if (this.edit.廠商設定主檔編號 <= 0) {
                return [];
            }
            let filter = this.廠商支付設定List.filter((item) => {
                return item.廠商設定主檔編號 + "" === this.edit.廠商設定主檔編號 + "";
            });
            return filter;
        },
        ...mapState({
            直儲模式: state => state.IPSSetting.基礎設定檔.直儲模式,
            額外限制列表: state => state.IPSSetting.基礎設定檔.額外限制列表,
        }),
        地區列表Key() {
            return this.$store.getters["IPSSetting/地區列表Key"];
        },
        金流類型設定Key() {
            return this.$store.getters["IPSSetting/金流類型設定Key"];
        },
        限制開關() {
            if (this.上層設定 && this.金流支付設定Key[this.上層設定.金流支付設定編號]) {
                let 金流類型 = this.金流支付設定Key[this.上層設定.金流支付設定編號].金流類型;
                return this.金流類型設定Key[金流類型].限額開關;
            }
            return false;
        },
        是否檢查儲值金額() {
            if (this.上層設定 && this.金流支付設定Key[this.上層設定.金流支付設定編號]) {
                let 金流類型 = this.金流支付設定Key[this.上層設定.金流支付設定編號].金流類型;
                return this.金流類型設定Key[金流類型].儲值金額開關;
            }
            return false;
        },
        上層設定() {
            let value = this.edit.廠商支付編號;
            return this.filter廠商支付設定List.find((item) => { return item.編號 + "" === value + "" })
        },
    },
    watch: {
        "edit.廠商支付編號"(val, newV) {
            this.$refs.comp.set上層限制集合([this.上層設定.額外限制, this.金流支付設定Key[this.上層設定.金流支付設定編號].額外限制]);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
                this.廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                this.金流設定主檔Key = await this.$store.dispatch("IPSSetting/金流設定主檔Key_查詢", {});
                this.金流帳號Key = await this.$store.dispatch("IPSSetting/金流帳號Key_查詢", {});
                this.金流支付設定Key = await this.$store.dispatch("IPSSetting/金流支付設定Key_查詢2", {});

                this.廠商支付設定List = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;//await IPSTool.callAPIList.call(this, api.IPSSetting.廠商支付設定_查詢, {});
                await this.整理額外限制資料();
                let 編號 = parseInt(this.$route.params.firmPayFlowId);
                if (編號 > 0) {
                    this.isEdit = true;
                    let res = APILib.IPS(await api.IPSSetting.廠商直儲金流_查詢({ 編號 })).content;//await IPSTool.callAPIList.call(this, api.IPSSetting.廠商直儲金流_查詢, { 編號 });
                    res = IPSTool.deCodeData(res, "儲值金額");
                    let find = { ...res[0] };
                    // find.額外限制 = "35,";
                    this.server端的額外限制編號 = find.額外限制;
                    find.額外限制 = await this.$store.dispatch("IPSSetting/額外限制List_查詢2", { 額外限制: find.額外限制, 額外限制列表: this.額外限制列表 });
                    delete find.建立時間;
                    this.edit = { ...find };
                    if (this.edit.平台遊戲編號 === null || this.edit.平台遊戲編號 == undefined) {
                        this.edit.平台遊戲編號 = "";
                    }
                }
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        async 整理額外限制資料() {
            let tmp額外限制str = "";
            for (let i of this.廠商支付設定List) {
                tmp額外限制str += i.額外限制 + ",";
            }
            let keys = Object.keys(this.金流支付設定Key);
            for (let k of keys) {
                tmp額外限制str += this.金流支付設定Key[k].server端的額外限制編號 + ",";
            }
            let tmpAry = tmp額外限制str.split(",");
            let 額外限制Keys = [];
            for (let i of tmpAry) {
                let n = parseInt(i);
                if (n > 0) {
                    額外限制Keys.push(n);
                }
            }
            let 額外限制所有資料 = await this.$store.dispatch("IPSSetting/額外限制List_查詢2", { 額外限制: 額外限制Keys.join(","), 額外限制列表: this.額外限制列表 });
            for (let i of this.廠商支付設定List) {
                if (!i.額外限制 || i.額外限制 == "-") {
                    this.$set(i, "額外限制", []);
                } else {
                    let tmp = i.額外限制.split(",");
                    let find = 額外限制所有資料.filter(({ 編號 }) => {
                        let f = false;
                        for (let j of tmp) {
                            if (j == 編號) {
                                f = true;
                            }
                        }
                        return f;
                    });
                    this.$set(i, "額外限制", find);
                }
            }
            for (let k of keys) {
                let i = this.金流支付設定Key[k];
                if (!i.server端的額外限制編號 || i.server端的額外限制編號 == "-") {
                    this.$set(i, "額外限制", []);
                } else {
                    let tmp = i.server端的額外限制編號.split(",");
                    let find = 額外限制所有資料.filter(({ 編號 }) => {
                        let f = false;
                        for (let j of tmp) {
                            if (j == 編號) {
                                f = true;
                            }
                        }
                        return f;
                    });
                    this.$set(i, "額外限制", find);
                }
            }
        },
        backList() {
            this.$router.push({
                name: "FirmPayFlowList",
                query: this.$route.query,
            });
        },
        async Save() {
            try {
                let obj = JSON.parse(JSON.stringify(this.edit));

                if (obj.廠商設定主檔編號 <= 0 || !IsSelectedInOptions(obj.廠商設定主檔編號, this.廠商設定List, "編號")) {
                    throw "請選擇廠商";
                }

                if (obj.直儲名稱 == "") {
                    throw "直儲名稱不可為空";
                }
                if (obj.商品代碼 == "") {
                    throw "商品代碼不可為空";
                }
                if (obj.廠商產品名稱 == "") {
                    throw "廠商產品名稱不可為空";
                }
                if (obj.直儲模式 <= 0 || !IsSelectedInOptions(obj.直儲模式, this.直儲模式, "編號")) {
                    throw "直儲模式不可為空";
                }
                if (obj.廠商支付編號 <= 0 || !IsSelectedInOptions(obj.廠商支付編號, this.filter廠商支付設定List, "編號")) {
                    throw "金流不可為空";
                }

                obj.金流類型 = this.金流支付設定Key[this.上層設定.金流支付設定編號].金流類型;
                if (obj.金流類型 <= 0) {
                    throw "金流類型有誤，請選擇其它金流";
                }

                if (this.是否檢查儲值金額) {
                    //
                } else {
                    obj.儲值金額 = [];
                }
                if (this.限制開關 && !this.$refs.comp.Check(obj.額外限制)) {
                    throw "請檢查額外限制";
                }

                delete obj.server端的額外限制編號;
                $(this.$refs.loading).show();
                if (this.限制開關) {
                    obj.額外限制 = await this.$refs.comp.Save(this.server端的額外限制編號, obj.額外限制);
                } else {
                    obj.額外限制 = this.server端的額外限制編號;
                }

                obj = IPSTool.enCodeData(obj, "儲值金額");

                if (obj.儲值金額 == "") {
                    obj.儲值金額 = "-";
                }
                // obj.ERP名稱 = obj.erP名稱;
                // delete obj.erP名稱;

                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.廠商直儲金流_修改(obj));
                } else {
                    APILib.IPS(await api.IPSSetting.廠商直儲金流_新增(obj));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
                this.backList();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                $(this.$refs.loading).hide();
            }
        },
        addOption(argV) {
            if (!檢查輸入數值(argV, 0)) {
                this.$eventBus.$emit("showAlert", "儲值金額輸入錯誤");
                return;
            }
            let find = this.edit.儲值金額.find((item) => { return item + "" === argV + "" });
            if (find) {
                this.$eventBus.$emit("showAlert", "儲值金額輸入錯誤，金額重複");
                return;
            }
            this.edit.儲值金額.push(argV);
        },
        delOption(argV) {
            let index = this.edit.儲值金額.findIndex((item) => {
                return item + "" === argV + "";
            });
            if (index >= 0) {
                this.edit.儲值金額.splice(index, 1);
            }
        },
    },
};
</script>


<style scoped>
span.form-control {
    margin: 0px;
    border-width: 0px;
    padding-left: 0px;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.payStyle>ul>li:first-child {
    padding: 5px 0px 5px 0px;
    color: #3c8dbc;
}

.payStyle>ul {
    border-bottom: 1px solid #f4f4f4;
}

.ColorRed {
    color: red;
}

.btnlimit {
    width: 400px;
}

button.btnlimit>span {
    display: inline-block;
    width: 200px;
}

.dropdown-menu {
    top: auto;
    left: 280px;
    bottom: 0px;
    right: -300px;
    padding: 15px;
    white-space: nowrap;
}


.dropdown-menu.common {
    /* bottom: -480px; */
    line-height: 1.5;

    top: 0px;
    bottom: auto;
    right: -600px;
}

.input-group-btn.widthAuto {
    width: auto;
}
</style>