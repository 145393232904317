<template>
  <div id="playEdit" class="slot max1040">
    <section class="content-header">
      <h1>玩法說明</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>玩法管理 </li>
        <li>玩法列表</li>
        <li class="active">玩法說明</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="form-horizontal" name="announcementForm">
            <div class="box-body">
							<div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 玩法名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    maxlength="50"
                    v-model.trim="data.玩法名稱"
                  />
									<span>{{ 50 - data.玩法名稱.length }} / 50</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 詳細說明：
                </label>
                <div class="col-sm-10">
									<div class="editor">
                    <tinymce v-model="data.詳細說明" :toolbar1="toolbar1" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
								<button
									class="btn btn-warning pull-left mr-10"
                  :disabled="
                    data.玩法名稱 == '' ||
                    data.詳細說明 == ''
                  "
                  @click="preview()"
								>
									預覽
								</button>
								<button
									class="btn btn-primary pull-left"
                  @click="save()"
                  :disabled="
                    data.玩法名稱 == '' ||
                    data.詳細說明 == ''
                  "
								>
									儲存
								</button>
								<button
									class="btn btn-default pull-right"
									@click="backList()"
								>
									返回
								</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--預覽-->
			<div class="modal fade" ref="preview" id="detailAlert">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
							<h4 class="modal-title text-center">{{ data.玩法名稱 }}</h4>
						</div>
						<div class="modal-body">
              <p v-html="data.詳細說明" class="text-center"></p>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import api from "@/assets/js/app-api";
import tinymce from "@/components/Tinymce";

export default {
  components: {
		tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      toolbar1: "undo redo | codesample code | formatselect| fontsizeselect | fontselect | bold italic underline strikethrough",
      isEdit: false,
      id: Number(this.$route.params.id) || '',
      data: {
        玩法名稱: "",
        詳細說明: "",
        是否啟用: 1,
      }
		};
  },
  created() {
    if(this.id != '') {
      this.getData();
      this.isEdit = true;
    }
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    getData() {
      api.slotGame.play.query({ 編號: this.id })
        .then((res) => {
          this.data = res.data.result;
        })
    },
    save() {
      let type = this.isEdit ? 'update' : 'create';
      let message = this.isEdit ? '更新' : '新增';

      api.slotGame.play[type](this.data)
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", `${message}成功`);
            this.backList()
          } else {
            this.$eventBus.$emit("showAlert", `${message}失敗`);
          }
        })
    },
    preview() {
      $(this.$refs.preview).modal("show");
    },
		backList() {
			this.$router.push({
				name: 'slotPlayList',
				params: {
					searchData: this.$route.params.backSearchData,
				},
			});
		}
	},
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}

#detailAlert .modal-body img {
  height: auto;
}
</style>