<template>
  <div id="ForumMuteSetting">
    <section class="content-header">
      <h1>討論區禁言管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">討論區禁言管理</li>
      </ol>
    </section>
    <section class="content forum-mute">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addMute()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增禁言
            </button>
            <div class="form-group clearfix">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.expiryDate"
                  :propStartDate.sync="searchData.startDate"
                />
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="輸入使用者ID"
                type="text"
                v-model="searchData.muteId"
              />
            </div>
            <button
              @click="resetDataList()"
              class="btn btn-default"
              type="button"
            >
              查詢
            </button>
            <p class="text text-red">
              * 禁言天數不輸入或大於3650天，為永久禁言。
            </p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-center">編號</th>
                  <th style="min-width: 150px">使用者ID</th>
                  <th style="min-width: 150px">暱稱</th>
                  <th style="min-width: 80px">禁言天數</th>
                  <th style="min-width: 100px">更新日期</th>
                  <th style="min-width: 100px">結束日期</th>
                  <th style="min-width: 200px">備註</th>
                  <th>操作人</th>
                  <th class="text-nowrap text-center" style="min-width: 150px">
                    功能
                  </th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="9">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="9">查詢無任何資料</td>
                </tr>
                <tr
                  :class="item.dateNumber === 0 ? 'bg-gray-light' : ''"
                  :key="index"
                  v-else
                  v-for="(item, index) in datas"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ item.muteId }}</td>
                  <td>{{ item.nickName || "-" }}</td>
                  <td class="text-center">
                    <span v-if="!item.isEdit">
                      <p
                        v-if="item.dateNumber === '' || item.dateNumber > 3650"
                      ></p>
                      <p v-else-if="item.dateNumber === 0">已解禁</p>
                      <p v-else>{{ item.dateNumber }} 天</p>
                    </span>
                    <input
                      class="form-control text-center"
                      min="1"
                      placeholder="輸入天數"
                      style="min-width: 100px"
                      type="number"
                      v-else
                      v-model.number="item.dateNumber"
                    />
                  </td>
                  <td class="text-center">
                    {{ item.startDate.split(/\s/)[0] }}
                  </td>
                  <td class="text-center">
                    <p
                      class="text-red"
                      v-if="item.dateNumber === '' || item.dateNumber > 3650"
                    >
                      永久禁言
                    </p>
                    <p v-else-if="item.isEdit">–</p>
                    <p v-else>{{ item.expiryDate.split(/\s/)[0] }}</p>
                  </td>
                  <td>
                    <p v-if="!item.isEdit">
                      {{ item.note }}
                    </p>
                    <input
                      class="form-control"
                      type="text"
                      v-else
                      v-model="item.note"
                    />
                  </td>
                  <td class="text-center">{{ item.opreateId }}</td>
                  <td class="text-center">
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="!item.isEdit"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.isEdit"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="remove(item)"
                      class="btn btn-danger"
                      v-show="!item.isEdit"
                    >
                      刪除
                    </button>
                    <button
                      @click="cancel(item)"
                      class="btn btn-default"
                      v-show="item.isEdit"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <select
            class="form-control page-size"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <forumMuteAdd ref="forumMuteAdd" v-on:updateList="resetDataList" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import forumMuteAdd from "@/views/projects/Newtalk/ForumMuteAdd";

export default {
  components: {
    Pagination,
    DateRangePicker,
    forumMuteAdd,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10,
        muteId: "",
        startDate: new moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD 00:00:00"),
        expiryDate: new moment().format("YYYY-MM-DD 23:59:59"),
        startRow: 0,
        endRow: 0,
      },
      datas: [],
      item: {},
      orginItem: {},
      editorName: this.$store.state.loginUser.EmployeeName,
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.setPageRow(this.searchData.pageNum, this.searchData.pageSize);
      this.$store
        .dispatch("newtalk/muteQueryAll", this.searchData)
        .then((result) => {
          this.datas = result.muteData || [];
          this.total = result.totalNumber;
          this.updateDataList();
          this.loading = false;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        });
    },
    updateDataList() {
      this.datas.forEach((item) => {
        this.dateDiff(item);
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    setPageRow(pageIdx, pageSize) {
      this.searchData.endRow = pageIdx * pageSize;
      this.searchData.startRow = this.searchData.endRow + 1 - pageSize;
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    addMute() {
      this.$refs.forumMuteAdd.showAddMuteBox();
    },
    change(item) {
      this.cancel(this.item);
      item.isEdit = true;
      this.item = item;
      this.orginItem.dateNumber = item.dateNumber;
      this.orginItem.note = item.note;
      this.$forceUpdate();
    },
    cancel(item) {
      item.isEdit = false;
      item.dateNumber = this.orginItem.dateNumber;
      item.note = this.orginItem.note;
      this.$forceUpdate();
    },
    remove(item) {
      this.$eventBus.$emit(
        "showConfirm",
        "刪除即解除禁言，確定要刪除嗎？",
        (isOK) => {
          if (isOK) {
            this.$store
              .dispatch("newtalk/muteDelete", {
                opreateId: this.editorName,
                muteId: item.muteId,
              })
              .then((result) => {
                this.getDataList();
              });
          }
        }
      );
    },
    save(item) {
      if (item.dateNumber === "" || item.dateNumber > 3650) {
        this.$eventBus.$emit(
          "showConfirm",
          "天數為空或超過3650天，將設定為永久禁言，是否確認?",
          (isOK) => {
            if (isOK) {
              this.updateMute(item);
            }
          }
        );
      } else {
        this.updateMute(item);
      }
    },
    updateMute(item) {
      item.isEdit = true;
      item.dateNumber = item.dateNumber || 0;
      this.$store.dispatch("newtalk/muteCreate", [item]).then((result) => {
        this.getDataList();
      });
    },
    dateDiff(item) {
      // sDate1 和 sDate2 是 2016-06-18 格式
      var aDate, oDate1, oDate2, iDays;
      var sDate1 = item.startDate.split(/\s/)[0];
      var sDate2 = item.expiryDate.split(/\s/)[0];
      aDate = sDate1.split("/");

      // 轉換為 06/18/2016 格式
      oDate1 = new Date(aDate[1] + "/" + aDate[2] + "/" + aDate[0]);

      aDate = sDate2.split("/");
      oDate2 = new Date(aDate[1] + "/" + aDate[2] + "/" + aDate[0]);

      // 把相差的毫秒數轉換為天數
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
      if (iDays <= 3650) item.dateNumber = iDays;
      else item.dateNumber = "";
    },
  },
};
</script>
