/* eslint-disable */
import axios from 'axios';
import store from '@/store.js';
import appUtils from '@/assets/js/app-utils';

//測試(Allen)
// const apiPath = "http://192.168.10.59:3953";
//測試(Local)
// const apiPath = "https://localhost:44302";
//正式
// const apiPath = "https://ompapi.wanin.tw";
//"https://ompapi.wanin.tw";
//測試
// const paymentApiHost = "https://localhost:44346";
//正式
// const paymentApiHost = "https://api-ips.wanin.tw/";

const apiPath = process.env.VUE_APP_OMPAPI_PATH;
const paymentApiHost = process.env.VUE_APP_PAYMENTAPI_PATH;
const qppApiPath = process.env.VUE_APP_QPPAPI_PATH;
const newtalkForumApiPath = process.env.VUE_APP_NEWTALKAPI_PATH;
const newtalkApiPath = process.env.VUE_APP_VOTEWEB_PATH;
const fcApiPath = process.env.VUE_APP_FCAPI_PATH;
const nmjApiPath = process.env.VUE_APP_NMJAPI_PATH;
const xswApiPath = process.env.VUE_APP_XSWAPI_PATH;
const kolApiPath = process.env.VUE_APP_KOLAPI_PATH;
const fbApiPath = process.env.VUE_APP_OMPAPI_PATH;
const fbFanPath = process.env.VUE_APP_OMPAPI_PATH;
const csSystemPath = process.env.VUE_APP_CS_SYSTEM_PATH;
const xinToolPath = process.env.VUE_APP_XIN_TOOL_PATH;
const xthApiPATH = process.env.VUE_APP_XTHAPI_PATH;
const smsSystemPath = process.env.VUE_APP_SMS_SYSTEM_PATH;
const xinGameApiPath = process.env.VUE_APP_XINGameAPI_PATH;
const xinOfficePath = process.env.VUE_APP_XIN_PATH;
const xin2ApiPath = process.env.VUE_APP_XIN2_PATH;

const ompRequest = axios.create({
    withCredentials: true,
    baseURL: apiPath
})
const paymentRequest = axios.create({
    withCredentials: true,
    baseURL: paymentApiHost
})
const CSRequest = axios.create({
    withCredentials: true,
    baseURL: csSystemPath
})
const XintoolRequest = axios.create({
    withCredentials: true,
    baseURL: xinToolPath
})
const XinGameAPIRequest = axios.create({
    withCredentials: true,
    baseURL: xinGameApiPath
})

const XinOfficeRequest = axios.create({
    withCredentials: true,
    baseURL: xinOfficePath
})

const XinDevUnboxing = axios.create({
    withCredentials: true,
    baseURL: xinOfficePath
})

const XTHRequest = axios.create({
    withCredentials: true,
    baseURL: xthApiPATH
})
const qppRequest = axios.create({
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    baseURL: qppApiPath
})
const newtalkForumRequest = axios.create({
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    baseURL: newtalkForumApiPath
})
const newtalkRequest = axios.create({
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
    withCredentials: true,
    baseURL: newtalkApiPath
})
const newtalkRequest2 = axios.create({
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
    withCredentials: true,
    baseURL: "https://dev89.newtalk.tw"
})
const newtalkApiRequest = axios.create({
    withCredentials: true,
    baseURL: newtalkApiPath
})
const fcRequest = axios.create({
    withCredentials: true,
    baseURL: fcApiPath
})
const nmjRequest = axios.create({
    withCredentials: true,
    baseURL: nmjApiPath
})
const xswRequest = axios.create({
    withCredentials: true,
    baseURL: xswApiPath,
})
const xswUpload = axios.create({
    withCredentials: true,
    baseURL: xswApiPath,
    headers: { 'Content-Type':'multipart/form-data' },
})
const kolRequest = axios.create({
    withCredentials: true,
    baseURL: kolApiPath
})

const xinRequest = axios.create({
    withCredentials: true,
    baseURL: paymentApiHost
})
const fbRequest = axios.create({
    withCredentials: true,
    baseURL: fbApiPath
})
const fbFanRequest = axios.create({
    withCredentials: true,
    baseURL: fbFanPath
})
const smsSystemRequest = axios.create({
    withCredentials: true,
    baseURL: smsSystemPath
})

let loginOut = false;

function callAPI(request, path, data) {
    if (data == undefined) data = {};
    data.projectId = store.state.projectId || appUtils.getCookie("projectId");
    let headers = { 'Authorization': `Bearer ${store.state.jwtToken}` };
    let promise = request.post(path, data, { headers: headers });
    promise.then(res => {
        if (res.data.status == "-999" && !loginOut) {
            loginOut = true;
            alert("閒置過久，請重新登入系統");
            window.location.href = "/";
        }
    }).catch(err => {
        let status = err && err.request && err.request.status;
        if (status == 401) {
            loginOut = true;
            alert("閒置過久，請重新登入系統-401");
            window.location.href = "/";
        }
        console.log(err);
    });
    return promise;
}

function callAPIByJsonStr(request, path, data) {
    if (path !== '/ForumHome/UploadAdmin' &&
        !path.includes('/api_election')) {
        if (data == undefined) data = {};
        data.projectId = store.state.projectId;
        data = JSON.stringify(data);
    }
    let promise = request.post(path, data);
    promise.then(res => {
        if (res.data.status == "-999" && !loginOut) {
            loginOut = true;
            alert("閒置過久，請重新登入系統");
            window.location.href = "/";
        }
    }).catch(err => {
        // console.log("bbb");
    });
    return promise;
}
function callCSAPIByFormData(request, path, data) {
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         resolve();
    //     }, 5000);
    // }).then(() => {
    let headers = {
        'Authorization': `Bearer ${store.state.jwtToken}`
    };
    let item = {
        headers: headers,
    };
    let fd = new FormData();
    let keys = Object.keys(data);
    for (let key of keys) {
        fd.append(key, data[key]);
    }
    let promise = request.post(path, fd, item).then(res => {
        return res;
    }).catch(err => {
        let status = err && err.request && err.request.status;
        if (status == 401) {
            //CSRequest token失效會跑這裡
            loginOut = true;
            alert("閒置過久，請重新登入系統");
            window.location.href = "/";
        } else {
            throw err;
        }
    });
    return promise;
    // });
}
function callAPIByFormData(request, path, data, withAuth = true) {
    if (data == undefined) data = {};
    let headers = {};
    if (withAuth) {
        headers = { 'Authorization': `Bearer ${store.state.jwtToken}` };
    }
    let item = {
        headers: headers,
        contentType: false,
        processData: false,
        mimeType: 'multipart/form-data'
    };

    let promise = request.post(path, data, item).then(res => {
        if (res.data.status == "-999" && !loginOut) {
            loginOut = true;
            alert("閒置過久，請重新登入系統");
            window.location.href = "/";
        } else if (res.data.status == "0" && res.data.message == "網頁Timeout，請重新登入~" && !loginOut) {
            //getUserJWT token失效會跑這裡
            loginOut = true;
            alert("閒置過久，請重新登入系統");
            window.location.href = "/";
        }
        return res;
    }).catch(err => {
        console.log(err);
    });
    return promise;
}

function callAPiByGet(request, path, data) {
    let promise = request.get(path, data);
    promise.then(res => {
        if (res.data.status == "-999" && !loginOut) {
            loginOut = true;
            alert("閒置過久，請重新登入系統");
            window.location.href = "/";
        }
    }).catch(err => {
        console.log(err);
    });
    return promise;
}


let api = {
    login: {
        login: data => callAPI(ompRequest, '/Login/UserLogin', data),
        logout: data => callAPI(ompRequest, '/Login/UserLogout', data),
        check: data => callAPI(ompRequest, '/Login/getUserData', data),
        checkSession: data => callAPI(ompRequest, '/Login/CheckSession', data),
        changePassword: data => callAPI(ompRequest, '/Login/ChangePassword', data),
        forgetPassword: data => callAPI(ompRequest, '/Login/ForgetPassword', data),
        updateProfile: data => callAPI(ompRequest, '/Login/UpdateProfile', data),
        updateProfile: data => callAPI(ompRequest, '/Login/UpdateProfile', data),
        mailStatus: data => callAPI(ompRequest, '/Login/GetMailVerifyStatus', data),
        getUserJWT: data => callAPIByFormData(ompRequest, '/Login/GetUserJWTData', data),
        group: data => callAPIByFormData(ompRequest, '/Login/ProjectGroupList', data),
    },
    classType: {
        query: data => callAPI(ompRequest, '/ClassType/Query', data),
        queryAll: data => callAPI(ompRequest, '/ClassType/QueryAll', data),
        create: data => callAPI(ompRequest, '/ClassType/Create', data),
        update: data => callAPI(ompRequest, '/ClassType/Update', data),
        delete: data => callAPI(ompRequest, '/ClassType/Delete', data),
    },
    classTypeDetail: {
        query: data => callAPI(ompRequest, '/classTypeDetail/Query', data),
        queryAll: data => callAPI(ompRequest, '/classTypeDetail/QueryAll', data),
    },
    news: {
        query: data => callAPI(ompRequest, '/News/Query', data),
        queryReturn: data => callAPI(ompRequest, '/News/Query', data),
        queryAll: data => callAPI(ompRequest, '/News/QueryAll', data),
        create: data => callAPI(ompRequest, '/News/Create', data),
        update: data => callAPI(ompRequest, '/News/Update', data),
        delete: data => callAPI(ompRequest, '/News/Delete', data),
        check: data => callAPI(ompRequest, '/app/check', data),
    },
    newsDetail: {
        query: data => callAPI(ompRequest, '/NewsDetail/Query', data),
        queryAll: data => callAPI(ompRequest, '/NewsDetail/QueryAll', data),
        imgList: data => callAPI(ompRequest, '/NewsDetail/GetImgList', data),
    },
    module: {
        query: data => callAPI(ompRequest, '/Module/Query', data),
        queryAll: data => callAPI(ompRequest, '/Module/GetProjectData', data),
    },
    media: {
        query: data => callAPI(ompRequest, '/Media/Query', data),
        queryAll: data => callAPI(ompRequest, '/Media/QueryList', data),
        create: data => callAPI(ompRequest, '/Media/Create', data),
        update: data => callAPI(ompRequest, '/Media/Update', data),
        delete: data => callAPI(ompRequest, '/Media/Delete', data),
    },
    file: {
        upload: data => callAPI(ompRequest, '/api/UploadFile', data),
        delete: data => callAPI(ompRequest, '/file/DeleteFileApply', data),
        deleteFilesApply: data => callAPI(ompRequest, '/file/DeleteFileApplyList', data),
        setIsMain: data => callAPI(ompRequest, '/file/UpdateFilesApplyIsMain', data),
        fileUploadByOrder: data => callAPI(ompRequest, '/file/UploadFiles', data),
        readFile: data => callAPI(ompRequest, '/file/ReadFile', data),
        updateFileInfoApply: data => callAPI(ompRequest, '/file/updateFileInfoApply', data),
        queryAll: data => callAPI(ompRequest, '/file/GetFilesApplyList', data),
        updateFile: data => callAPI(ompRequest, '/file/UpdateFile', data),
        updateFiles: data => callAPI(ompRequest, '/file/UpdateFiles', data),
    },
    banner: {
        query: data => callAPI(ompRequest, '/Banner/Query', data),
        queryAll: data => callAPI(ompRequest, '/Banner/QueryAll', data),
        create: data => callAPI(ompRequest, '/Banner/Create', data),
        update: data => callAPI(ompRequest, '/Banner/Update', data),
        delete: data => callAPI(ompRequest, '/Banner/Delete', data),
        imgList: data => callAPI(ompRequest, '/Banner/GetImgList', data),
    },
    salePoint: {
        query: data => callAPI(ompRequest, '/SalePoint/Query', data),
        queryAll: data => callAPI(ompRequest, '/SalePoint/QueryAll', data),
        create: data => callAPI(ompRequest, '/SalePoint/Create', data),
        update: data => callAPI(ompRequest, '/SalePoint/Update', data),
        delete: data => callAPI(ompRequest, '/SalePoint/Delete', data),
        updateSort: data => callAPI(ompRequest, '/SalePoint/UpdateSort', data),
    },
    salePointDetail: {
        query: data => callAPI(ompRequest, '/SalePointDetail/Query', data),
        queryAll: data => callAPI(ompRequest, '/SalePointDetail/QueryAll', data),
    },
    payment: {
        queryAll: data => callAPI(paymentRequest, 'api/payment/QueryOrderList', data),
        checkOrder: data => callAPI(paymentRequest, 'api/checkOrder', data),
        queryStatistics: data => callAPI(paymentRequest, 'api/payment/QueryOrderStatistics', data),
        getOrderProducts: data => callAPI(paymentRequest, 'api/heti/GetOrderProducts', data),
    },
    game: {
        query: data => callAPI(ompRequest, '/gameShow/Query', data),
        queryAll: data => callAPI(ompRequest, '/gameShow/QueryAll', data),
        create: data => callAPI(ompRequest, '/gameShow/Create', data),
        update: data => callAPI(ompRequest, '/gameShow/Update', data),
        delete: data => callAPI(ompRequest, '/gameShow/Delete', data),
        updateSort: data => callAPI(ompRequest, '/gameShow/UpdateSort', data),
        updateListSort: data => callAPI(ompRequest, '/gameShow/UpdateListSort', data),
        imgList: data => callAPI(ompRequest, '/gameShow/GetImgList', data),
    },
    gameDetail: {
        query: data => callAPI(ompRequest, '/gameShowDetail/Query', data),
        queryAll: data => callAPI(ompRequest, '/gameShowDetail/QueryAll', data),
    },
    asgard: {
        checkServerStatus: data => callAPI(ompRequest, '/AsgardService/CheckServerStatus', data),
        udpateCSV: data => callAPI(ompRequest, '/AsgardService/UpdateCSV', data),
        getGiftList: data => callAPI(ompRequest, '/AsgardService/getGiftList', data),
        sendPlayerGift: data => callAPI(ompRequest, '/AsgardService/sendPlayerGift', data),
        kickPlayer: data => callAPI(ompRequest, '/AsgardService/kickPlayer', data),
    },
    activity: {
        query: data => callAPI(ompRequest, '/Exchange/ActivityQuery', data),
        queryAll: data => callAPI(ompRequest, '/Exchange/ActivityQueryAll', data),
        create: data => callAPI(ompRequest, '/Exchange/ActivityCreate', data),
        update: data => callAPI(ompRequest, '/Exchange/ActivityUpdate', data),
        delete: data => callAPI(ompRequest, '/Exchange/ActivityDelete', data),
    },
    award: {
        query: data => callAPI(ompRequest, '/Exchange/AwardsQuery', data),
        queryAll: data => callAPI(ompRequest, '/Exchange/AwardsQueryAll', data),
        create: data => callAPI(ompRequest, '/Exchange/AwardsCreate', data),
        update: data => callAPI(ompRequest, '/Exchange/AwardsUpdate', data),
        delete: data => callAPI(ompRequest, '/Exchange/AwardsDelete', data),
    },
    awardsItem: {
        query: data => callAPI(ompRequest, '/Exchange/AwardsItemQuery', data),
        queryAll: data => callAPI(ompRequest, '/Exchange/AwardsItemQueryAll', data),
        create: data => callAPI(ompRequest, '/Exchange/AwardsItemCreate', data),
        update: data => callAPI(ompRequest, '/Exchange/AwardsItemUpdate', data),
        delete: data => callAPI(ompRequest, '/Exchange/AwardsItemDelete', data),
    },
    exchanges: {
        query: data => callAPI(ompRequest, '/Exchange/ItemTypeQuery', data),
        queryAll: data => callAPI(ompRequest, '/Exchange/ItemTypeQueryAll', data),
        create: data => callAPI(ompRequest, '/Exchange/ItemTypeCreate', data),
        update: data => callAPI(ompRequest, '/Exchange/ItemTypeUpdate', data),
        delete: data => callAPI(ompRequest, '/Exchange/ItemTypeDelete', data),
    },
    exchangeItems: {
        query: data => callAPI(ompRequest, '/Exchange/ItemQuery', data),
        queryAll: data => callAPI(ompRequest, '/Exchange/ItemQueryAll', data),
        create: data => callAPI(ompRequest, '/Exchange/ItemCreate', data),
        update: data => callAPI(ompRequest, '/Exchange/ItemUpdate', data),
        delete: data => callAPI(ompRequest, '/Exchange/ItemDelete', data),
    },
    exchangeCode: {
        queryAll: data => callAPI(ompRequest, '/Exchange/SNQueryAll', data),
        create: data => callAPI(ompRequest, '/Exchange/SNCreate', data),
        delete: data => callAPI(ompRequest, '/Exchange/SNDelete', data),
        update: data => callAPI(ompRequest, '/Exchange/SNUpdate', data),
        import: data => callAPI(ompRequest, '/Exchange/SNCreateImport', data),
        queryPatch: data => callAPI(ompRequest, '/Exchange/SNQueryPatch', data),
        deletePatch: data => callAPI(ompRequest, '/Exchange/SNDelete', data),
    },
    bonusAndRefund: {
        queryAll: data => callAPI(ompRequest, '/GivePoint/QueryAll', data),
        create: data => callAPI(ompRequest, '/GivePoint/Create', data),
        import: data => callAPI(ompRequest, '/GivePoint/CreateImport', data),
    },
    redeemPrize: {
        queryAll: data => callAPI(ompRequest, '/RedeemPrizes/QueryAll', data),
        update: data => callAPI(ompRequest, '/RedeemPrizes/HandleForm', data),
        query: data => callAPI(ompRequest, '/RedeemPrizes/Query', data),
    },
    event: {
        queryAll: data => callAPI(ompRequest, '/Event/QueryAll', data),
        getRedeemCodeRecordList: data => callAPI(ompRequest, '/Event/RedeemCodeRecordQueryAll', data),
        getItemCodeList: data => callAPI(ompRequest, '/Event/ItemCodeQueryAll', data),
        getDailySignInList: data => callAPI(ompRequest, '/Event/DailySignInQueryAll', data)
    },
    phoneVerify: {
        query: data => callAPI(ompRequest, '/ShortCode/ShortCodeListQuery', data),
        queryAll: data => callAPI(ompRequest, '/api/ShortCode/ShortCodeListQueryAll', data),
        create: data => callAPI(ompRequest, '/api/ShortCode/AddWhiteListByPhone', data)
    },
    contactUs: {
        query: data => callAPI(ompRequest, '/ContactUs/Query', data),
        queryAll: data => callAPI(ompRequest, '/ContactUs/QueryAll', data),
        update: data => callAPI(ompRequest, '/ContactUs/Update', data),
    },
    link: {
        queryList: data => callAPI(ompRequest, '/Link/QueryList', data),
        query: data => callAPI(ompRequest, '/Link/Query', data),
        create: data => callAPI(ompRequest, '/Link/Create', data),
        update: data => callAPI(ompRequest, '/Link/Update', data),
        delete: data => callAPI(ompRequest, '/Link/Delete', data),
    },
    qppArticle: {
        Categoryquery: data => callAPIByJsonStr(qppRequest, '/api/System/categoryquery', data),
        QueryList: data => callAPIByJsonStr(qppRequest, '/api/System/ArticleQueryList', data),
        DetailQuery: data => callAPIByJsonStr(qppRequest, '/api/System/ArticleDetailQuery', data),
        DeleteQuery: data => callAPIByJsonStr(qppRequest, '/api/System/ArticleDel', data),
        Add: data => callAPIByJsonStr(qppRequest, '/api/System/ArticleAdd', data),
        Edit: data => callAPIByJsonStr(qppRequest, '/api/System/ArticleEdit', data),
        SetTop: data => callAPIByJsonStr(qppRequest, '/api/System/SetTop', data),
        DeleteTop: data => callAPIByJsonStr(qppRequest, '/api/System/DelTop', data)
    },
    qppMute: {
        search: data => callAPIByJsonStr(qppRequest, '/System/Mute/SearchMute', data),
        create: data => callAPIByJsonStr(qppRequest, '/System/Mute/AddMute', data),
        delete: data => callAPIByJsonStr(qppRequest, '/System/Mute/DelMute', data),
    },
    qppReport: {
        search: data => callAPIByJsonStr(qppRequest, '/System/Report/SearchReport', data),
        edit: data => callAPIByJsonStr(qppRequest, '/System/Report/EditReport', data),
        comment: data => callAPIByJsonStr(qppRequest, '/System/OpreateArticle/SearchComment', data),
        deleteComment: data => callAPIByJsonStr(qppRequest, '/System/OpreateArticle/DelComment', data),
        reply: data => callAPIByJsonStr(qppRequest, '/System/OpreateArticle/SearchReply', data),
        deleteReply: data => callAPIByJsonStr(qppRequest, '/System/OpreateArticle/DelReply', data),
    },
    newtalk: {
        top: {
            create: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/SetTop', data),
            delete: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/DelTop', data),
        },
        article: {
            tagQueryAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/SearchTagFromNewtalkBackend', data),
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ArticleQueryList', data),
            disableAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteArticlePatch', data),
            query: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ArticleDetailQuery', data),
            create: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ArticleAdd', data),
            update: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ArticleEdit', data),
            disable: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteArticle', data),
            fileUpload: data => callAPIByJsonStr(newtalkForumRequest, '/ForumHome/UploadAdmin', data),
        },
        comment: {
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/System/OpreateArticle/SearchComment', data),
            disableAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteCommentPatch', data),
            disable: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteComment', data),
            add: data => callAPIByJsonStr(newtalkForumRequest, 'api/System/CommentAdd', data),
        },
        reply: {
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/System/OpreateArticle/SearchReply', data),
            disableAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteReplyPatch', data),
            disable: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteReply', data),
            add: data => callAPIByJsonStr(newtalkForumRequest, 'api/System/ReplyAdd', data),
        },
        mute: {
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute/SearchMute', data),
            create: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute/AddMute', data),
            delete: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute/DelMute', data),
            memberQueryAll: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute/MemberMuteHideQuery', data),
            memberAdd: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute/MemberMuteHideAdd', data),
            memberDelete: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute//MemberMuteHideDel', data),
            memberUpdate: data => callAPIByJsonStr(newtalkForumRequest, '/System/Mute/MemberMuteHideUpdate', data),
        },
        report: {
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/System/Report/SearchReport', data),
            query: data => callAPIByJsonStr(newtalkForumRequest, '/System/Report/SearchReportDetail', data),
            update: data => callAPIByJsonStr(newtalkForumRequest, '/System/Report/EditReport', data),
        },
        statistic: {
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ReportDailyCount', data),
        },
        vote: {
            queryAll: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ArticleQueryList', data),
            query: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/ArticleDetailQuery', data),
            create: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/PollsAdd', data),
            update: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/PollsEdit', data),
            disable: data => callAPIByJsonStr(newtalkForumRequest, '/api/System/MuteArticle', data),
        },
        election: {
            mayors: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getMayorData', data),
            councilman: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getCouncilmanData', data),
            menu: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getCandidateMenu', data),
            history: {
                president: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getPresidentData', data),
                legislator: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getLegislatorData', data),
                party: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getPartyData', data),
            },
            group: {
                queryAll: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getPoliticalPartyList', data),
                query: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getPoliticalParty', data),
                add: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/addPoliticalParty', data),
                update: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/updatePoliticalParty', data),
            },
            person: {
                queryAll: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getCandidateList', data),
                add: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/importCandidateList', data),
                export: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/exportCandidateList', data),
                constituency: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getCandidateMenu', data),
                setTickets: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/setCandidateTicketsStatus', data),
                getTickets: data => callAPIByJsonStr(newtalkApiRequest, '/api_election/getCandidateTicketsStatus', data),
            }
        },
        image: {
            upload: data => callAPIByFormData(newtalkApiRequest, 'api/uploadImageByModule', data, false),
            query: data => callAPIByFormData(newtalkApiRequest, 'api/getImageByModule', data, false),
        },
        fileDisplay: {
            query: data => callAPIByFormData(newtalkApiRequest, 'api/getFileDisplayById', data, false),
            queryAll: data => callAPIByFormData(newtalkApiRequest, 'api/getFileDisplayList', data, false),
            create: data => callAPIByFormData(newtalkApiRequest, 'api/insertFileDisplayData', data, false),
            update: data => callAPIByFormData(newtalkApiRequest, 'api/updateFileDisplayData', data, false),
            delete: data => callAPIByFormData(newtalkApiRequest, 'api/deleteFileDisplayData', data, false),
        },
        survey: {
            events: data => callAPIByFormData(newtalkApiRequest, 'api_survey/get_type', data),
            types: data => callAPIByFormData(newtalkApiRequest, 'api_survey/get_list', data),
            queryAll: data => callAPIByFormData(newtalkApiRequest, 'api_survey/query_all', data),
        }
    },
    fcVersion: {
        maintainQuery: data => callAPI(fcRequest, '/api/System/MaintainSetQuery', data),
        maintainUpdate: data => callAPI(fcRequest, '/api/System/MaintainSetCreate', data),
        queryAll: data => callAPI(fcRequest, '/api/VersionManage/QueryList', data),
        create: data => callAPI(fcRequest, '/api/VersionManage/Create', data),
        update: data => callAPI(fcRequest, '/api/VersionManage/Update', data),
    },
    fcService: {
        query: data => callAPI(fcRequest, '/api/Reaction/QueryOne', data),
        queryAll: data => callAPI(fcRequest, '/api/Reaction/QueryList', data),
        update: data => callAPI(fcRequest, '/api/Reaction/Update', data),
    },
    fcDeposit: {
        productQueryAll: data => callAPI(fcRequest, '/api/Payment/ProductTypeList', data),
        itemQueryAll: data => callAPI(fcRequest, '/api/Payment/QueryList', data),
        queryAll: data => callAPI(fcRequest, '/api/Payment/QueryList', data),
        create: data => callAPI(fcRequest, '/api/Payment/QueryList', data),
        update: data => callAPI(fcRequest, '/api/Payment/QueryList', data),
        disable: data => callAPI(fcRequest, '/api/Payment/QueryList', data),
    },
    fcReward: {
        historyQueryAll: data => callAPI(fcRequest, '/api/PrizeMessage/MessageQueryHistory', data),
        typeQueryAll: data => callAPI(fcRequest, '/api/PrizeMessage/PrizeTypeList', data),
        itemListQueryAll: data => callAPI(fcRequest, '/api/PrizeMessage/PrizeTypeList', data),
        itemListCreate: data => callAPI(fcRequest, '/api/PrizeMessage/PrizeTypeCreateList', data),
        itemQueryAll: data => callAPI(fcRequest, '/api/PrizeMessage/ItemQueryList', data),
        itemCreate: data => callAPI(fcRequest, '/api/PrizeMessage/ItemCreate', data),
        itemDelete: data => callAPI(fcRequest, '/api/PrizeMessage/ItemDelete', data),
        query: data => callAPI(fcRequest, '/api/PrizeMessage/MessageQueryOne', data),
        queryAll: data => callAPI(fcRequest, '/api/PrizeMessage/MessageQueryList', data),
        create: data => callAPI(fcRequest, '/api/PrizeMessage/MessageCreate', data),
        update: data => callAPI(fcRequest, '/api/PrizeMessage/MessageUpdate', data),
        delete: data => callAPI(fcRequest, '/api/PrizeMessage/MessageDelete', data),
    },
    fcPlayerData: {
        queryAll: data => callAPI(fcRequest, '/api/PlayerInfo/QueryList', data),
    },
    fcPlayerHistory: {
        queryTxt: data => callAPI(fcRequest, '/api/History/QueryTxt', data),
        queryAll: data => callAPI(fcRequest, '/api/History/QueryList', data),
    },
    fcAnnouncement: {
        query: data => callAPI(fcRequest, '/api/Announcement/QueryOne', data),
        queryAll: data => callAPI(fcRequest, '/api/Announcement/QueryList', data),
        create: data => callAPI(fcRequest, '/api/Announcement/Create', data),
        update: data => callAPI(fcRequest, '/api/Announcement/Update', data),
        delete: data => callAPI(fcRequest, '/api/Announcement/Delete', data),
    },
    fcPlayerSetting: {
        query: data => callAPI(fcRequest, '/api/BlockPlay/QueryOne', data),
        queryAll: data => callAPI(fcRequest, '/api/BlockPlay/QueryList', data),
        create: data => callAPI(fcRequest, '/api/BlockPlay/Create', data),
    },
    nmjClassType: {
        query: data => callAPI(nmjRequest, '/api/ClassType/Query', data),
        queryAll: data => callAPI(nmjRequest, '/api/ClassType/QueryAll', data),
        queryModules: data => callAPI(nmjRequest, '/api/ClassType/GetProjectData', data),
        create: data => callAPI(nmjRequest, '/api/ClassType/Create', data),
        update: data => callAPI(nmjRequest, '/api/ClassType/Update', data),
        delete: data => callAPI(nmjRequest, '/api/ClassType/Delete', data),
        fileUpload: data => callAPI(nmjRequest, '/api/File/Upload', data),
    },
    nmjServiceType: {
        query: data => callAPI(nmjRequest, '/api/ServiceRecord/QueryServiceType', data),
        create: data => callAPI(nmjRequest, '/api/ServiceRecord/CreateServiceType', data),
        update: data => callAPI(nmjRequest, '/api/ServiceRecord/UpdateServiceType', data),
    },
    nmjService: {
        query: data => callAPI(nmjRequest, '/api/CustomerService/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/CustomerService/QueryList', data),
        update: data => callAPI(nmjRequest, '/api/CustomerService/Update', data),
    },
    nmjAnnouncement: {
        query: data => callAPI(nmjRequest, '/api/Announcement/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/Announcement/QueryList', data),
        create: data => callAPI(nmjRequest, '/api/Announcement/Create', data),
        update: data => callAPI(nmjRequest, '/api/Announcement/Update', data),
        delete: data => callAPI(nmjRequest, '/api/Announcement/Delete', data),
    },
    nmjAppBanner: {
        query: data => callAPI(nmjRequest, '/api/Announcement/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/Announcement/QueryList', data),
        create: data => callAPI(nmjRequest, '/api/Announcement/Create', data),
        update: data => callAPI(nmjRequest, '/api/Announcement/Update', data),
        delete: data => callAPI(nmjRequest, '/api/Announcement/Delete', data),
    },
    nmjMarquee: {
        query: data => callAPI(nmjRequest, '/api/Marquee/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/Marquee/QueryList', data),
        create: data => callAPI(nmjRequest, '/api/Marquee/Create', data),
        update: data => callAPI(nmjRequest, '/api/Marquee/Update', data),
        delete: data => callAPI(nmjRequest, '/api/Marquee/Delete', data),
    },
    nmjWebBanner: {
        query: data => callAPI(nmjRequest, '/api/Banner/Query', data),
        queryAll: data => callAPI(nmjRequest, '/api/Banner/QueryAll', data),
        create: data => callAPI(nmjRequest, '/api/Banner/Create', data),
        update: data => callAPI(nmjRequest, '/api/Banner/Update', data),
        delete: data => callAPI(nmjRequest, '/api/Banner/Delete', data),
    },
    nmjWebNews: {
        query: data => callAPI(nmjRequest, '/api/News/Query', data),
        queryAll: data => callAPI(nmjRequest, '/api/News/QueryAll', data),
        create: data => callAPI(nmjRequest, '/api/News/Create', data),
        update: data => callAPI(nmjRequest, '/api/News/Update', data),
        delete: data => callAPI(nmjRequest, '/api/News/Delete', data),
    },
    nmjWebQuestion: {
        query: data => callAPI(nmjRequest, '/api/CommonProblem/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/CommonProblem/QueryList', data),
        create: data => callAPI(nmjRequest, '/api/CommonProblem/Create', data),
        update: data => callAPI(nmjRequest, '/api/CommonProblem/Update', data),
        delete: data => callAPI(nmjRequest, '/api/CommonProblem/Delete', data),
    },
    nmjWebHighlight: {
        query: data => callAPI(nmjRequest, '/api/HighlightsCompetition/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/HighlightsCompetition/QueryList', data),
        create: data => callAPI(nmjRequest, '/api/HighlightsCompetition/Create', data),
        update: data => callAPI(nmjRequest, '/api/HighlightsCompetition/Update', data),
        delete: data => callAPI(nmjRequest, '/api/HighlightsCompetition/Delete', data),
    },
    nmjMail: {
        query: data => callAPI(nmjRequest, '/api/Mail/QueryOne', data),
        queryAll: data => callAPI(nmjRequest, '/api/Mail/QueryList', data),
        create: data => callAPI(nmjRequest, '/api/Mail/CreateMail', data),
        update: data => callAPI(nmjRequest, '/api/Mail/UpdateMail', data),
        delete: data => callAPI(nmjRequest, '/api/Mail/DeleteMail', data),
        history: data => callAPI(nmjRequest, '/api/Mail/MailHistoryQueryList', data),
        itemList: data => callAPI(nmjRequest, '/api/Item/ItemList'),
        itemReload: data => callAPI(nmjRequest, '/api/Item/ItemReload'),
    },
    nmjPlayerData: {
        query: data => callAPI(nmjRequest, '/api/Player/GetPlayerInfo', data),
        history: data => callAPI(nmjRequest, '/api/PlayerHistory/GetPlayerHistory', data),
        deposit: data => callAPI(nmjRequest, '/api/Deposit/QueryList', data),
        control: data => callAPI(nmjRequest, '/api/Player/SendPlayerControl', data),
        controlInfo: data => callAPI(nmjRequest, '/api/Player/QueryPlayerControlInfo', data),
        controlList: data => callAPI(nmjRequest, '/api/Player/QuerySuspensionList', data),
        queryService: data => callAPI(nmjRequest, '/api/ServiceRecord/QueryPlayerServiceRecord', data),
        createService: data => callAPI(nmjRequest, '/api/ServiceRecord/CreatePlayerServiceRecord', data),
        deleteService: data => callAPI(nmjRequest, '/api/ServiceRecord/DeletePlayerServiceRecord', data),
    },
    nmjGameBet: {
        queryEntry: data => callAPI(nmjRequest, '/api/BetSystem/EntryQuery', data),
        queryAllEntry: data => callAPI(nmjRequest, '/api/BetSystem/EntryQueryList', data),
        createEntry: data => callAPI(nmjRequest, '/api/BetSystem/EntryCreate', data),
        updateEntry: data => callAPI(nmjRequest, '/api/BetSystem/EntryUpdate', data),
        queryEvent: data => callAPI(nmjRequest, '/api/BetSystem/EventQuery', data),
        queryAllEvent: data => callAPI(nmjRequest, '/api/BetSystem/EventQueryList', data),
        createEvent: data => callAPI(nmjRequest, '/api/BetSystem/EventCreate', data),
        updateEvent: data => callAPI(nmjRequest, '/api/BetSystem/EventUpdate', data),
        queryCompetition: data => callAPI(nmjRequest, '/api/BetSystem/CompetitionQuery', data),
        queryAllCompetition: data => callAPI(nmjRequest, '/api/BetSystem/CompetitionQueryList', data),
        createCompetition: data => callAPI(nmjRequest, '/api/BetSystem/CompetitionCreate', data),
        updateCompetition: data => callAPI(nmjRequest, '/api/BetSystem/CompetitionUpdate', data),
        updateResult: data => callAPI(nmjRequest, '/api/BetSystem/UpdateResult', data),
        notifyGameServer: data => callAPI(nmjRequest, '/api/BetSystem/UpdateGameServer', data),
    },
    nmjGameRecord: {
        query: data => callAPI(nmjRequest, '/api/GameRecord/QueryList', data),
        queryBattle: data => callAPI(nmjRequest, '/api/BattleForCoin/GetCompetitionRecord', data),
        queryBet: data => callAPI(nmjRequest, '/api/BetSystem/GetBetRecordData', data),
    },
    nmjHistory: {
        queryAll: data => callAPI(nmjRequest, '/api/History/QueryHistoryList', data),
        getTypes: data => callAPI(nmjRequest, '/api/History/GetChangedProject', data),
    },
    // XinStar_Web
    xsw: {
        post: data => callAPI(xswRequest, '/api/ApiBase/ExecuteCommand', data),
        upload: data => callAPI(xswUpload, '/api/ApiBase/ExecuteUploadFile', data),
    },

    kolBigData: {
        queryAll: data => callAPI(kolRequest, '/ProjectKOL/Kol/QueryKOLList', data),
        query: data => callAPI(kolRequest, '/ProjectKOL/Kol/HitQuery', data),
        kpi: data => callAPI(kolRequest, '/ProjectKOL/Kol/KpiCalculator', data),
        follow: data => callAPI(kolRequest, '/ProjectKOL/Kol/FollowKol', data),
        create: data => callAPI(kolRequest, '/ProjectKOL/Kol/CreateKOL', data),
        update: data => callAPI(kolRequest, '/ProjectKOL/Kol/UpdateKOL', data),
        queryChannel: data => callAPI(kolRequest, '/ProjectKOL/Kol/QueryKOLChannelList', data),
        updateChannel: data => callAPI(kolRequest, '/ProjectKOL/Kol/UpdateKOLChannel', data),
        createChannel: data => callAPI(kolRequest, '/ProjectKOL/Kol/CreateKOLChannel', data),
        uploadImage: data => callAPI(kolRequest, '/ProjectKOL/Kol/UploadImage', data),
        history: data => callAPI(kolRequest, "/ProjectKOL/Kol/QueryKolHisItemList", data),
        kolkpi: data => callAPI(kolRequest, '/ProjectKOL/Kol/NewKpiCalculator', data),
        postkpi: data => callAPI(kolRequest, '/ProjectKOL/Kol/PostKpiQuery', data),
        crawlerKOLChannel: data => callAPiByGet(kolRequest, '/ProjectKOL/Kol/CrawlerKOLChannel', data),
        kolleaderboard: data => callAPI(kolRequest, '/ProjectKOL/Kol/ItemKeyQuery', data)
    },
    mftdBefore: {
        shareQueryAll: data => callAPI(ompRequest, '/ProjectMFTD/Mftd/BeforeShareQueryList', data),
        queryAll: data => callAPI(ompRequest, '/ProjectMFTD/Mftd/BeforeQueryList', data),
        flopCardQueryAll: data => callAPI(ompRequest, '/ProjectMFTD/Mftd/FlopCardQueryAll', data),
        LotteryQueryAll: data => callAPI(ompRequest, '/ProjectMFTD/Mftd/LotteryQueryAll_Admin', data),
    },
    starCastle: {
        prizeQueryAll: data => callAPI(ompRequest, '/Project/StarCastle/BeforePrizeQueryList', data),
        queryAll: data => callAPI(ompRequest, '/Project/StarCastle/BeforeQueryList', data),
    },
    album: {
        query: data => callAPI(ompRequest, '/PhotoAlbum/Query', data),
        queryAll: data => callAPI(ompRequest, '/PhotoAlbum/QueryAll', data),
        create: data => callAPIByFormData(ompRequest, '/PhotoAlbum/Create', data),
        update: data => callAPIByFormData(ompRequest, '/PhotoAlbum/Update', data),
        delete: data => callAPI(ompRequest, '/PhotoAlbum/DeleteFileInfoApply', data),
        updatevideos: data => callAPI(ompRequest, '/PhotoAlbum/BatchUpdateVideos', data),
    },
    ipsPaymentOwed: {
        queryAll: data => callAPI(paymentRequest, '/api/Accounting/PaymentOwedSearch', data),
        create: data => callAPI(paymentRequest, '/api/Accounting/PaymentOwedAdd', data),
        update: data => callAPI(paymentRequest, '/api/Accounting/PaymentOwedEdit', data),
        updateOrder: data => callAPI(paymentRequest, '/api/Accounting/PaymentOwedUpdateOrder', data),
        statistics: data => callAPI(paymentRequest, '/api/Accounting/PaymentOwedStatistics', data)
    },
    IPSSetting: {
        debug: false,
        雙平台廠商下拉選單: data => callAPI(paymentRequest, '/api/Accounting/PaymentOwedList', data),
        金流支付設定_查詢: data => callAPI(paymentRequest, '/api/IPSms/CashFlowPaySettings_Search', data),
        金流支付設定_修改: data => callAPI(paymentRequest, '/api/IPSms/CashFlowPaySettings_Up', data),
        金流支付設定_新增: data => callAPI(paymentRequest, '/api/IPSms/CashFlowPaySettings_Add', data),
        金流帳號_查詢: data => callAPI(paymentRequest, '/api/IPSms/CashFlowAccount_Search', data),
        金流帳號_修改: data => callAPI(paymentRequest, '/api/IPSms/CashFlowAccount_Up', data),
        金流帳號_新增: data => callAPI(paymentRequest, '/api/IPSms/CashFlowAccount_Add', data),
        金流設定主檔_查詢: data => callAPI(paymentRequest, '/api/IPSms/CashFlowSettingsMaster_Search', data),
        金流設定主檔_修改: data => callAPI(paymentRequest, '/api/IPSms/CashFlowSettingsMaster_Up', data),
        金流設定主檔_新增: data => callAPI(paymentRequest, '/api/IPSms/CashFlowSettingsMaster_Add', data),
        // 金流維護設定_金流列表_付款方式: data => callAPI(paymentRequest, '/api/IPSms/金流維護設定_金流列表_付款方式', data),
        金流維護設定_修改: data => callAPI(paymentRequest, '/api/IPSms/CashFlowMaintainSettings_Up', data),
        金流維護設定_新增: data => callAPI(paymentRequest, '/api/IPSms/CashFlowMaintainSettings_Add', data),
        // 金流維護設定_廠商列表: data => callAPI(paymentRequest, '/api/IPSms/金流維護設定_廠商列表', data),
        金流維護設定_查詢: data => callAPI(paymentRequest, '/api/IPSms/CashFlowMaintainSettings_Search', data),
        基礎設定檔_查詢: data => callAPI(paymentRequest, '/api/IPSms/BasicSettings_Search', data),
        廠商支付設定_查詢: data => callAPI(paymentRequest, '/api/IPSms/CompanyPaySettings_Search', data),
        廠商支付設定_修改: data => callAPI(paymentRequest, '/api/IPSms/CompanyPaySettings_Up', data),
        廠商支付設定_新增: data => callAPI(paymentRequest, '/api/IPSms/CompanyPaySettings_Add', data),
        廠商設定主檔_查詢: data => callAPI(paymentRequest, '/api/IPSms/CompanySettingsMaster_Search', data),
        廠商設定主檔_修改: data => callAPI(paymentRequest, '/api/IPSms/CompanySettingsMaster_Up', data),
        廠商設定主檔_新增: data => callAPI(paymentRequest, '/api/IPSms/CompanySettingsMaster_Add', data),
        額外限制_刪除: data => callAPI(paymentRequest, '/api/IPSms/ExtraLimit_Del', data),
        額外限制_查詢: data => callAPI(paymentRequest, '/api/IPSms/ExtraLimit_Search', data),
        額外限制_修改: data => callAPI(paymentRequest, '/api/IPSms/ExtraLimit_Up', data),
        額外限制_新增: data => callAPI(paymentRequest, '/api/IPSms/ExtraLimit_Add', data),
        額外限制List_查詢: data => callAPI(paymentRequest, '/api/IPSms/ExtraLimitList_Search', data),
        額外限制細項_查詢: data => callAPI(paymentRequest, '/api/IPSms/ExtraLimitDetail_Search', data),
        廠商品項管理_查詢: data => callAPI(paymentRequest, '/api/IPSms/CompanyItemsManage_Search', data),
        廠商品項管理_修改: data => callAPI(paymentRequest, '/api/IPSms/CompanyItemsManage_Up', data),
        廠商品項管理_新增: data => callAPI(paymentRequest, '/api/IPSms/CompanyItemsManage_Add', data),
        // 廠商品項管理_廠商下拉_查詢金流集合_查詢: data => callAPI(paymentRequest, '/api/IPSms/CompanyItemsManage_DrowList_CashFlowSet_Search', data),
        // 廠商品項管理_廠商下拉_超商品號_查詢: data => callAPI(paymentRequest, '/api/IPSms/CompanyItemsManage_DrowList_CVSNumber_Search', data),
        廠商直儲金流_新增: data => callAPI(paymentRequest, '/api/IPSms/CompanyStoredCashFlow_Add', data),
        廠商直儲金流_修改: data => callAPI(paymentRequest, '/api/IPSms/CompanyStoredCashFlow_Up', data),
        廠商直儲金流_查詢: data => callAPI(paymentRequest, '/api/IPSms/CompanyStoredCashFlow_Search', data),
        // 廠商直儲金流_金流設定主檔_查詢: data => callAPI(paymentRequest, '/api/Value/廠商直儲金流_金流設定主檔_查詢', data),//拿掉，等同 金流設定主檔_查詢
        // 廠商直儲金流_金流帳號_查詢: data => callAPI(paymentRequest, '/api/Value/廠商直儲金流_金流帳號_查詢', data),//拿掉，等同 金流帳號_查詢
        // 廠商直儲金流_金流支付設定_查詢: data => callAPI(paymentRequest, '/api/Value/廠商直儲金流_金流支付設定_查詢', data),//拿掉，等同 金流支付設定_查詢
        廠商支付設定_排序: data => callAPI(paymentRequest, '/api/IPSms/CompanyPaySettings_Up_Sort', data),

        HistoryManage_Search: data => callAPI(paymentRequest, '/api/IPSms/HistoryManage_Search', data),//歷程記錄 查詢
        HistoryManageUserList: data => callAPI(paymentRequest, '/api/IPSms/HistoryManageUserList', data),//歷程紀錄 異動者清單
        HistoryManageTableList: data => callAPI(paymentRequest, '/api/IPSms/HistoryManageTableList', data),//歷程紀錄 資料表清單


        CheckOrder: data => callAPI(paymentRequest, '/api/CheckOrder', data),//重驗證

        TransactionInfoUpdate: data => callAPI(paymentRequest, '/api/OrderUpdate/TransactionInfoUpdate', data), // 修改 黑貓金額 或 訂單狀態為失敗(需登入並有權限)
        TransactionInfoReset: data => callAPI(paymentRequest, '/api/OrderUpdate/TransactionInfoReset', data), // 重置 交易明細訂單(需登入並有權限)

        QueryOrderList: data => callAPI(paymentRequest, '/api/accounting/QueryOrderList', data),//查詢訂單(不含主檔明細)
        QueryOrderDetailList: data => callAPI(paymentRequest, '/api/accounting/QueryOrderDetailList', data),//查詢訂單商品明細
        RefundSearch: data => callAPI(paymentRequest, '/api/Accounting/RefundSearch', data),//退貨明細
        RefundAdd: data => callAPI(paymentRequest, '/api/Accounting/RefundAdd', data),//新增退貨
        RefundEdit: data => callAPI(paymentRequest, '/api/Accounting/RefundEdit', data),//修改退貨

        // getLastUserInfo: data => callAPI(paymentRequest, '/api/Invoice/getLastUserInfo', data),//取得最後一次使用者KeyIN資料
        InvoiceJackpotImport: data => callAPIByFormData(paymentRequest, '/api/Invoice/InvoiceJackpotImport', data),//發票中獎匯入
        getInvoiceJackpot: data => callAPI(paymentRequest, '/api/Invoice/getInvoiceJackpot', data),//取得發票中獎專取
        upInvoiceJackpot: data => callAPI(paymentRequest, '/api/Invoice/upInvoiceJackpot', data),//修改中獎發票資訊
        DownloadInvoiveExcel: data => {
            return paymentApiHost + '/api/Invoice/DownloadInvoiveExcel';
            // return 'https://dev3-ips.wanin.tw' + '/api/Invoice/DownloadInvoiveExcel';
        },//匯出中獎專區發票
        GetInvoicePrintCourse: data => callAPIByFormData(paymentRequest, '/api/Invoice/GetInvoicePrintCourse', data),//發票列印歷程
        SendWinningSMS: data => callAPIByFormData(paymentRequest, '/api/Invoice/SendWinningSMS', data),//寄送發票中獎簡訊
        ResetPrintStatus: data => callAPIByFormData(paymentRequest, '/api/Invoice/ResetPrintStatus', data),//寄送發票中獎簡訊
        SetMaintains: data => callAPI(paymentRequest, '/api/MainTains/SetMaintains', data),//修改維護
        GetMaintainsList: data => callAPI(paymentRequest, '/api/MainTains/GetMaintainsList', data),//取得維護列表
        OneClickMaintainST0: data => callAPiByGet(paymentRequest, '/api/System/updateMaintanceStatus?isMaintain=0', data),//一鍵維護
        OneClickMaintainST1: data => callAPiByGet(paymentRequest, '/api/System/updateMaintanceStatus?isMaintain=1', data),//一鍵維護
        GetOneClickMaintainST: data => callAPiByGet(paymentRequest, '/api/System/getMaintanceStatus', data),//一鍵維護-取得狀態
        GetManualInvoiceList: data => callAPI(paymentRequest, '/api/Invoice/GetManualInvoiceList', data),//查詢手開發票列表
        AddManualInvoice: data => callAPI(paymentRequest, '/api/Invoice/AddManualInvoice', data),//新增手開發票列表
        UpdateManualInvoice: data => callAPI(paymentRequest, '/api/Invoice/UpdateManualInvoice', data),//修改手開發票列表
        BatchUpdateManualInvoice: data => callAPI(paymentRequest, '/api/Invoice/BatchUpdateManualInvoice', data),//批次修改手開發票列表
        RemoveManualInvoice: data => callAPI(paymentRequest, '/api/Invoice/RemoveManualInvoice', data),//移除手開發票列表
        GetCommonInformationList: data => callAPI(paymentRequest, '/api/Invoice/GetCommonInformationList', data),//移除手開發票列表
        AddCommonInformation: data => callAPI(paymentRequest, '/api/Invoice/AddCommonInformation', data),//移除手開發票列表
        UpdateCommonInformation: data => callAPI(paymentRequest, '/api/Invoice/UpdateCommonInformation', data),//移除手開發票列表
        RemoveCommonInformation: data => callAPI(paymentRequest, '/api/Invoice/RemoveCommonInformation', data),//移除手開發票列表

        updateInvoiceOpenYn: data => callAPiByGet(paymentRequest, '/api/System/updateInvoiceOpenYn?' + data),//設定驗證發票API開關
        GetInvoiceOpenYn: data => callAPI(paymentRequest, '/api/System/GetInvoiceOpenYn', data),//取得驗證發票API開關狀態
        VerifyEInvoice: data => callAPI(paymentRequest, '/api/Invoice/VerifyEInvoice', data),//連線至財政部驗證發票是否正確


        GetProductData: data => callAPI(paymentRequest, '/api/Invoice/GetProductData', data),//查詢商品資料設定 ok
        AddProductData: data => callAPI(paymentRequest, '/api/Invoice/AddProductData', data),//新增商品資料設定 ok
        UpdateProductData: data => callAPI(paymentRequest, '/api/Invoice/UpdateProductData', data),//更改商品資料設定
        ChangeProductDataType: data => callAPI(paymentRequest, '/api/Invoice/ChangeProductDataType', data),//更改商品資料啟用狀態

        GetProductCommonData: data => callAPI(paymentRequest, '/api/Invoice/GetProductCommonData', data),//查詢商品常用資料 ok
        AddProductCommonData: data => callAPI(paymentRequest, '/api/Invoice/AddProductCommonData', data),//新增商品常用資料 ok
        UpdateProductCommonData: data => callAPI(paymentRequest, '/api/Invoice/UpdateProductCommonData', data),//修改商品常用資料 ok

        risk: {
            GetPaymentLimiteList: data => callAPI(paymentRequest, '/api/WhiteBlack/GetPaymentLimiteList', data),//查詢 風控條件列表
            SetPaymentLimiteList: data => callAPI(paymentRequest, '/api/WhiteBlack/SetPaymentLimiteList', data),//增加 風控條件列表
            DelPaymentLimiteList: data => callAPI(paymentRequest, '/api/WhiteBlack/DelPaymentLimiteList', data),//刪除 風控條件列表
            GetPaymentLimiteBlack: data => callAPI(paymentRequest, '/api/WhiteBlack/GetPaymentLimiteBlack', data),//取得風控黑名單 (風控記錄)
            // SetPaymentLimiteBlack: data => callAPI(paymentRequest, '/api/WhiteBlack/SetPaymentLimiteBlack', data),//設定風控黑名單 todo:介面不用，記得拿掉
            DelPaymentLimiteBlack: data => callAPI(paymentRequest, '/api/WhiteBlack/DelPaymentLimiteBlack', data),//刪除風控黑名單 todo:介面為dev暫存
            GetPaymentLimiteWhite: data => callAPI(paymentRequest, '/api/WhiteBlack/GetPaymentLimiteWhite', data),//取白名單
            SetPaymentLimiteWhite: data => callAPI(paymentRequest, '/api/WhiteBlack/SetPaymentLimiteWhite', data),//新增白名單
            DelPaymentLimiteWhite: data => callAPI(paymentRequest, '/api/WhiteBlack/DelPaymentLimiteWhite', data),//刪除白名單
        },
    },
    xinAccount: {
        search: data => callAPI(xinRequest, '/api/Accounting/PartnerShareReportQuery', data),
    },
    fbRobot: {
        queryAll: data => callAPI(fbRequest, '/FBPost/FBPostList', data),
        query: data => callAPI(fbRequest, '/FBPost/FBPost', data),
        fbAll: data => callAPI(fbRequest, '/FBPost/FBBotPostList', data),
        create: data => callAPI(fbRequest, '/FBPost/Create', data),
        update: data => callAPI(fbRequest, '/FBPost/Update', data),
        postList: data => callAPI(fbRequest, '/FBPost/FBBotPostList', data),
        delPost: data => callAPI(fbRequest, '/FBPost/Delete', data),
        copyPost: data => callAPI(fbRequest, '/FBPost/Copy', data),
        FanPageList: data => callAPI(fbRequest, '/FBPost/FBFanPageList', data),

        queryKeywords: data => callAPI(fbRequest, '/FBKeyword/FBKeywordQuery', data),
        createKeywords: data => callAPI(fbRequest, '/FBKeyword/Create', data),
        updateKeywords: data => callAPI(fbRequest, '/FBKeyword/Update', data),
        delKeywords: data => callAPI(fbRequest, '/FBKeyword/Delete', data),

        getFan: data => callAPI(fbFanRequest, '/FBFanPage/GetFanPage', data),
        getAuth: data => callAPI(fbFanRequest, '/FBFanPage/AuthorizedFanPage', data),
    },
    kf3: {
        before: {
            queryAll: data => callAPI(ompRequest, '/Project/kf3/BeforeQueryList', data),
            shareQueryAll: data => callAPI(ompRequest, '/Project/kf3/BeforePrizeQueryList', data),
            TopUpQueryAll: data => callAPI(ompRequest, '/Project/kf3/RechageFeedbackQueryAll_Admin', data),
            create: data => callAPI(ompRequest, '/Project/kf3/BeforeFirstPrizeCreate_Admin', data),
            update: data => callAPI(ompRequest, '/Project/KF3/BeforeFirstPrizeUpdate_Admin', data),
        },
        tag: {
            queryAll: data => callAPI(ompRequest, '/Tags/GetList', data),
        },
        event: {
            treasureQueryAll: data => callAPI(ompRequest, '/Project/KF3/MindGameLotteryQueryAll_Admin', data),
            inviteQueryAll: data => callAPI(ompRequest, '/Project/KF3/MindGameQueryAll_Admin', data),
            rewardQueryAll: data => callAPI(ompRequest, '/Project/KF3/RechageRewardQueryAll_Admin', data),
            winnerQueryAll: data => callAPI(ompRequest, '/Project/KF3/ListOfWinnersQueryAll_Admin', data),
            raiseFinishQueryAll: data => callAPI(ompRequest, '/event/MissionCompleteRecordQueryAll', data),
            raisePairQueryAll: data => callAPI(ompRequest, '/event/PlayerInviteQueryAll', data),
        }
    },
    dem: {
        before: {
            queryAll: data => callAPI(ompRequest, '/Project/DEM/BeforeEventPhoneQuery', data),
            create: data => callAPI(ompRequest, '/Project/DEM/BeforeEventCreatePhone', data),
        },
        event: {
            inviteQueryAll: data => callAPI(ompRequest, '/Project/DEM/EventPlayerQuery', data),
        }
    },
    heti: {
        GetHint: data => callAPI(ompRequest, '/ProjectHeti/Heti/GetHint', data),
    },
    smm: { //社群監控用
        channelQueryAll: data => callAPI(ompRequest, '/Project/smm/Channel/QueryAll_Admin', data),
        channelQuery: data => callAPI(ompRequest, '/Project/smm/Channel/Query_Admin', data),
        channelDelete: data => callAPI(ompRequest, '/Project/smm/Channel/Delete', data),
        channelUpdate: data => callAPI(ompRequest, '/Project/smm/Channel/Update', data),
        channelCreate: data => callAPI(ompRequest, '/Project/smm/Channel/Create', data),
        channelMan: data => callAPI(ompRequest, '/Project/Smm/Channel/GetPatrollerList', data),
        channelTime: data => callAPI(ompRequest, '/Project/Smm/Channel/SetPatrolTime', data),
        videoQueryAll: data => callAPI(ompRequest, '/Project/smm/Videotrack/QueryAll_Admin', data),
        videoQuery: data => callAPI(ompRequest, '/Project/smm/Videotrack/Query_Admin', data),
        videoUpdate: data => callAPI(ompRequest, '/Project/smm/Videotrack/Update', data),
        commentQueryAll: data => callAPI(ompRequest, '/Project/smm/Comment/QueryAll_Admin', data),
        commentQuery: data => callAPI(ompRequest, '/Project/smm/Comment/Query_Admin', data),
        commentUpdate: data => callAPI(ompRequest, '/Project/smm/Comment/Update', data),
        KeywordsDelete: data => callAPI(ompRequest, '/Project/smm/Keywords/Delete', data),
        KeywordsUpdate: data => callAPI(ompRequest, '/Project/smm/Keywords/Update', data),
        KeywordsCreate: data => callAPI(ompRequest, '/Project/smm/Keywords/Create', data),
        KeywordsQuery: data => callAPI(ompRequest, '/Project/smm/Keywords/Query_Admin', data),
        KeywordsQueryAll: data => callAPI(ompRequest, '/Project/smm/Keywords/QueryAll_Admin', data),
    },
    CS: {
        // Login: (fd) => {
        //     const CSRequest = axios.create({
        //         withCredentials: true,
        //         baseURL: "https://cschat.wanin.tw"
        //     });
        //     let request = CSRequest;
        //     let path = 'https://cschat.wanin.tw:8085/Login/Login';
        //     let promise = request.post(path, fd/*, { headers: fd.getHeaders() }*/);
        //     return promise;
        // },
        Login: (data) => {//CS登入，取得長連線要用的token
            return callCSAPIByFormData(CSRequest, '/Login/GetChatServiceToken', data).then((res) => {
                if (res && res.data == "驗證失敗") {
                    throw "客服系統登入失敗，請重新登入，錯誤訊息：" + res.data;
                }
                return res;
            });
        },
        SearchEvent: data => callCSAPIByFormData(CSRequest, '/ServiceHistory/SearchEvent', data),//客服歷程：搜尋事件
        SearchMessage: data => callCSAPIByFormData(CSRequest, '/ServiceHistory/SearchMessage', data),//客服歷程：搜尋內容
        GetEventDetailData: data => callCSAPIByFormData(CSRequest, '/ServiceHistory/GetEventDetailData', data),//客服歷程：取得事件詳細資料
        GetEventHistoryMsg: data => callCSAPIByFormData(CSRequest, '/ServiceHistory/GetEventHistoryMsg', data),//客服歷程：取得歷史訊息(依EventId)
        GetOverallPerformance: data => callCSAPIByFormData(CSRequest, '/ServiceStatistics/GetOverallPerformance', data),//統計：取得全體人員統計資料(含Cs列表)
        GetCsPerformance: data => callCSAPIByFormData(CSRequest, '/ServiceStatistics/GetCsPerformance', data),//統計：取得指定客服統計資料
        GetCsDataList: data => callCSAPIByFormData(CSRequest, '/Base/GetCsDetailDataList', data),//取得客服資料列表(ID與中文姓名對照用)
        ChatServerReconnect: data => callCSAPIByFormData(CSRequest, '/Base/ChatServerReconnect', data),//呼叫客服系統重連GameServer(GameServer系統重開時使用)
        // VIP: data => callCSAPIByFormData(XintoolRequest, '/_Handler/CommandHandler.ashx', data),//移除，改由客服系統直接回傳
        // VIP_omp_starcastle: data => callAPI(XTHRequest, '/Personal/PlayerQuery', data),//移除，改由客服系統直接回傳
        GetCategoryList: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/GetCategoryList', data),//取得類別列表 ok
        AddCategory: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/AddCategory', data),//新增類別
        UpdateCategory: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/UpdateCategory', data),//修改類別 ok
        GetCannedMsgList: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/GetCannedMsgList', data),//取得罐頭訊息列表
        AddCannedMsg: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/AddCannedMsg', data),//新增罐頭訊息
        UpdateCannedMsg: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/UpdateCannedMsg', data),//修改罐頭訊息
        RemoveCannedMsg: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/CannedMsg/RemoveCannedMsg', data),//刪除罐頭訊息


        GetSystemChatMsg: data => callCSAPIByFormData(CSRequest, '/Community/GetSystemChatMsg', data),//社群管理：取得系統訊息資料
        SetSystemChatMsg: data => callCSAPIByFormData(CSRequest, '/Community/SetSystemChatMsg', data),//社群管理：修改系統訊息資料
        SetSystemChatMsgEnable: data => callCSAPIByFormData(CSRequest, '/Community/SetSystemChatMsgEnable', data),//社群管理：系統訊息資料啟停用

        GetMonitoringHistory: data => callCSAPIByFormData(CSRequest, '/ChannelManage/GetMonitoringHistory', data),//發話歷程
        GetKeywordList: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/ChatKeyword/GetKeywordList', data),//取得關鍵字列表
        AddKeyword: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/ChatKeyword/AddKeyword', data),//新增關鍵字
        UpdateKeyword: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/ChatKeyword/UpdateKeyword', data),//編輯關鍵字
        RemoveKeyword: data => callCSAPIByFormData(ompRequest, '/ProjectAutoChat/ChatKeyword/RemoveKeyword', data),//刪除關鍵字
    },
    maintain: {
        queryAll: data => callAPI(ompRequest, '/Maintain/GetList', data),
        create: data => callAPI(ompRequest, '/Maintain/Create', data),
        update: data => callAPI(ompRequest, '/Maintain/Update', data),
    },
    smsSystem: {
        defaultProvider: {
            getData: data => callAPI(ompRequest, '/Project/MoloSMS/GetProvider', data),
            SetData: data => callAPI(ompRequest, '/Project/MoloSMS/SetProvider', data),
        }
    },
    slot: {
        game: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/GameShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/GameShowOne', data),
        },
        company: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/CompanyShowList', data),
        },
        document: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/DocumentShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/DocumentShowOne', data),
        },
        eventAbnormal: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventAbnormalShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventAbnormalShowOne', data),
        },
        eventFlow: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventFlowShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventFlowShowOne', data),
        },
        eventItem: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventItemShowList', data),
        },
        eventNormal: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventNormalShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/EventNormalShowOne', data),
        },
        gameHall: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/GameHallShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/GameHallShowOne', data),
        },
        hallContent: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/HallContentShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/HallContentShowOne', data),
        },
        play: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/Slot/PlayShowList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/PlayShowOne', data),
        },
        search: {
            query: data => callAPI(ompRequest, '/ProjectSlot/Slot/SearchCount', data),
        },
    },
    slotGame: {
        company: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/CompanyQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/CompanyQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/CompanyCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/CompanyUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/CompanyState', data),
        },
        document: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/DocumentQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/DocumentQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/DocumentCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/DocumentUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/DocumentState', data),
        },
        game: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameState', data),
        },
        gameHall: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameHallQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameHallQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameHallCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameHallUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/GameHallState', data),
        },
        hallContent: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/HallContentQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/HallContentQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/HallContentCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/HallContentUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/HallContentlState', data),
            order: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/HallContentOrder', data),
        },
        play: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/PlayQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/PlayQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/PlayCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/PlayUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/PlayState', data),
        },
        tag: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/TagQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/TagQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/TagCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/TagUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotGame/TagState', data),
        },
    },
    slotEvent: {
        abnormal: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventAbnormalQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventAbnormalQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventAbnormalCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventAbnormalUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventAbnormalState', data),
        },
        flow: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventFlowQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventFlowQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventFlowCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventFlowUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventFlowState', data),
            order: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventFlowOrder', data),
        },
        item: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventItemQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventItemQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventItemCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventItemUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventItemState', data),
        },
        normal: {
            queryAll: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventNormalQueryList', data),
            query: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventNormalQueryOne', data),
            create: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventNormalCreate', data),
            update: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventNormalUpdate', data),
            state: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventNormalState', data),
            order: data => callAPI(ompRequest, '/ProjectSlot/SlotEvent/EventNormalOrder', data),
        },
    },
    moloSMS: {
        user: {
            queryAll: data => callAPI(ompRequest, '/Project/MoloSMS/UserGetList', data),
            add: data => callAPI(ompRequest, '/Project/MoloSMS/UserAdd', data),
            update: data => callAPI(ompRequest, '/Project/MoloSMS/UserUpdate', data),
            SmsComTypeQueryAll: data => callAPI(ompRequest, '/Project/MoloSMS/SmsComTypeList', data),
        },
        customize: {
            queryAll: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendQueryList', data),
            query: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendQuery', data),
            create: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendCreate', data),
            update: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendUpdate', data),
            delete: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendDelete', data),
            phoneQueryAll: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendPhoneList', data),
        },
        block: {
            queryAll: data => callAPI(ompRequest, '/Project/MoloSMS/BlockQueryList', data),
            query: data => callAPI(ompRequest, '/Project/MoloSMS/BlockQuery', data),
            create: data => callAPI(ompRequest, '/Project/MoloSMS/BlockCreate', data),
            update: data => callAPI(ompRequest, '/Project/MoloSMS/BlockUpdate', data),
            delete: data => callAPI(ompRequest, '/Project/MoloSMS/BlockDelete', data),
        },
        message: {
            queryAll: data => callAPI(ompRequest, '/GameShow/QueryAll', data),
            create: data => callAPI(ompRequest, '/GameShow/Create', data),
            update: data => callAPI(ompRequest, '/GameShow/Update', data),
            delete: data => callAPI(ompRequest, '/GameShow/Delete', data),
            updateStop: data => callAPI(ompRequest, '/GameShow/UpdateStop', data),
        },
        mapping: {
            queryAll: data => callAPI(ompRequest, '/User/GroupMappingQueryAll', data),
            query: data => callAPI(ompRequest, '/User/GroupMappingQuery', data),
            create: data => callAPI(ompRequest, '/User/GroupMappingCreate', data),
            update: data => callAPI(ompRequest, '/User/GroupMappingUpdate', data),
            delete: data => callAPI(ompRequest, '/User/GroupMappingDelete', data),
        },
        service: data => callAPI(ompRequest, '/Project/MoloSMS/GetMessageSendStatus', data),
        details: data => callAPI(ompRequest, '/Project/MoloSMS/GetSendDetails', data),
        status: data => callAPI(ompRequest, '/Project/MoloSMS/StatusOptions', data),
        sendComList: data => callAPI(ompRequest, '/Project/MoloSMS/AdminSendComList', data),
        deliveryRate: data => callAPI(ompRequest, '/Project/MoloSMS/GetSendDeliveredRate', data),
    },
    XinStarsTw: {
        AddLog: data => callCSAPIByFormData(XintoolRequest, '/_Handler/CommandHandler.ashx', data),
        PlatformBuyDel: data => callAPI(XinGameAPIRequest, '/CallgRPC/PlatformBuyDel', data),

        //Game Operation 
        GameOperationList: data => callAPI(XinGameAPIRequest, '/api/GameOverview/QueryListGameOperation', data),
        GameOperationQuery: data => callAPI(XinGameAPIRequest, '/api/GameOverview/QueryGameOperation', data),
        GameOperationCreat: data => callAPI(XinGameAPIRequest, '/api/GameOverview/CreateGameOperation', data),
        GameOperationUpdate: data => callAPI(XinGameAPIRequest, '/api/GameOverview/UpdateGameOperation', data),
        GameOperationDel: data => callAPI(XinGameAPIRequest, '/api/GameOverview/DeleteGameOperation', data),
        GameOperationClear: data => callAPI(XinGameAPIRequest, '/api/GameOverview/ClearCacheGameOperation', data),
        GameOperationCopy: data => callAPI(XinGameAPIRequest, '/api/GameOverview/CopyGameOperation', data),

        //Game Introduce
        GameIntroList: data => callAPI(XinGameAPIRequest, '/api/GameOverview/QueryListGameIntroduction', data),
        GameIntroQuery: data => callAPI(XinGameAPIRequest, '/api/GameOverview/QueryGameIntroduction', data),
        GameIntroCreat: data => callAPI(XinGameAPIRequest, '/api/GameOverview/CreateGameIntroduction', data),
        GameIntroUpdate: data => callAPI(XinGameAPIRequest, '/api/GameOverview/UpdateGameIntroduction', data),
        GameIntroDel: data => callAPI(XinGameAPIRequest, '/api/GameOverview/DeleteGameIntroduction', data),
        GameIntroClear: data => callAPI(XinGameAPIRequest, '/api/GameOverview/ClearCacheGameIntroduction', data),
        GameIntroCopy: data => callAPI(XinGameAPIRequest, '/api/GameOverview/CopyGameIntroduction', data),

        //img
        xinUploadImg: data => callAPI(XinGameAPIRequest, '/File/Upload', data),
        xinDelImg: data => callAPI(XinGameAPIRequest, '/File/Delete', data),
        xinUpdateImg: data => callAPI(XinGameAPIRequest, '/File/Update', data),

        //unboxing
        unboxingCatch: data => callAPI(XinOfficeRequest, '/api/Unboxing/ClearCache', data),

        PlatformBuyDel: data => callAPI(XinGameAPIRequest, '/CallgRPC/PlatformBuyDel', data),

        //新中控API
        post: data => callAPI(xswRequest, '/api/ApiBase/ExecuteCommand', data),
    },
    mid: {
        mobile: {
            queryAll: data => callAPI(ompRequest, '/Project/MIDAdmin/MobileQueryList', data),
            unblock: data => callAPI(ompRequest, '/Project/MIDAdmin/MobileUnlock', data),
            unVerify: data => callAPI(ompRequest, '/Project/MIDAdmin/MobileUnVerify', data),
            detail: data => callAPI(ompRequest, '/Project/MIDAdmin/VerifyQueryList', data),
        },
        article: {
            queryAll: data => callAPI(ompRequest, '/Project/MIDAdmin/NewsQueryList', data),
            query: data => callAPI(ompRequest, '/Project/MIDAdmin/NewsQuery', data),
            create: data => callAPI(ompRequest, '/Project/MIDAdmin/NewsCreate', data),
            delete: data => callAPI(ompRequest, '/Project/MIDAdmin/NewsDelete', data),
            update: data => callAPI(ompRequest, '/Project/MIDAdmin/NewsUpdate', data),
        }
    },
    pot: {
        member: data => callAPI(ompRequest, '/Event/MissionCompleteRecordQueryAll', data),
    }
}
import fack from "@/assets/js/fack/app-apiFack";
export default api = process.env.VUE_APP_FACK_API === 'true' ? fack(api) : api;