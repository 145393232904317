<template>
  <div id="BigData">
    <section class="content-header">
      <h1>訂閱管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">訂閱管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <!-- 新增 -->

            <button
              v-bind:class="{ isAll: ischeckAll === true }"
              @click="checkAll()"
              class="btn btn-purple btn-add btn-subAll mr-5"
              type="button"
            >
              <span class="isChooseText"
                ><i class="fa fa-check-square"></i> 這頁全選</span
              >
              <span class="isCancelText"
                ><i class="fa fa-close"></i> 這頁取消</span
              >
            </button>

            <!-- 請選擇類型 -->
            <div class="select-group">
              <select class="form-control" id="selectType" required="required">
                <option value="0" disabled>請選擇類型</option>
                <option v-for="(type, key) in types" :value="key" :key="key">
                  {{ type }}
                </option>
              </select>
            </div>

            <!-- 請選擇平台 -->
            <div class="select-group">
              <select class="form-control" id="selectType" required="required">
                <option value="0" disabled>請選擇平台</option>
                <option
                  v-for="(item, key) in platforms"
                  :value="key"
                  :key="key"
                  
                >
                  {{ item }}
                </option>
              </select>
            </div>

            <!-- 訂閱/未訂閱 -->
            <div class="select-group">
              <select class="form-control" id="selectType" required="required">
                <option value="0" disabled>請選擇訂閱/未訂閱</option>
                <option value="1">不區分是否訂閱</option>
                <option value="2">訂閱</option>
                <option value="3">未訂閱</option>
              </select>
            </div>

            <!-- 搜尋 -->
            <div class="input-group">
              <input
                name="table_search"
                placeholder="搜尋網紅名稱"
                type="text"
                class="form-control pull-right"
              />
              <div class="input-group-btn">
                <button type="button" class="btn btn-default">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <!-- 資料總筆數 -->
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total"> {{ currentData.length }} </span>
              </p>
            </div>
          </div>

          <ul class="row" id="SubManagement">
            <li
              class="col-lg-2 col-md-3 col-sm-4 col-xs-6"
              :key="item.id"
              v-for="item in currentData"
            >
              <div class="people">
                <div class="card-img pic jqimgFill">
                  <img :src="item.imageSrc" draggable="false" />
                </div>
                <div class="card-body">
                  <p class="name">
                    {{ item.name }}
                  </p>
                  <p class="label">類型:{{ item.module }}</p>
                  <p class="socialNum">
                    <i class="media-icon fb2"></i>
                    <span class="data-value">
                      <span class="text">追蹤數:</span>
                      {{ item.FBNumber }}</span
                    >
                  </p>
                  <button
                    class="btn btn-primary"
                    v-bind:class="{
                      isChosen: item.isChosen == true,
                    }"
                    v-on:click="item.isChosen = !item.isChosen"
                    @click="checkItem(item)"
                  >
                    <i class="fa fa-check"></i> 訂閱
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <div class="box-footer text-center no-border">
            <Pagination
              ref="pagination"
              :total="currentData.total"
              :pageLimitCount="searchData.pageSize"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */

import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      total: 0, //總筆數
      currentPage: 0,
      types: ["全部類型", "政治", "生活", "時尚"],
      platforms: ["Facebook", "Youtube"],
      platformsSelect: "Facebook",
      ischeckAll: false,
      currentData: [
        {
          id: "B001",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/50?t=1605954161",
          name: "這群人",
          module: "生活",
          FBNumber: "3,320,000",
          YTNumber: "2,170,367",
          isChosen: true,
        },
        {
          id: "B002",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/171?t=1605954166",
          name: "蔡阿嘎",
          module: "生活、親子",
          FBNumber: "1,932,760",
          YTNumber: "2,460,000",
          isChosen: true,
        },
        {
          id: "B003",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/125?t=1605954209",
          name: "阿滴英文",
          module: "生活",
          FBNumber: "922,541",
          YTNumber: "2,660,000",
          isChosen: false,
        },
        {
          id: "B004",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/48732?t=1605954161",
          name: "隋棠",
          module: "生活",
          FBNumber: "2,664,102",
          YTNumber: "4,260",
          isChosen: false,
        },
        {
          id: "B005",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/900?t=1605954161",
          name: "Duncan",
          module: "插畫",
          FBNumber: "3,164,265",
          YTNumber: "7,400",
          isChosen: false,
        },
        {
          id: "B006",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/463?t=1605954177",
          name: "黃阿瑪的後宮生活",
          module: "寵物",
          FBNumber: "1,401,139",
          YTNumber: "2,170,367",
          isChosen: false,
        },
        {
          id: "B007",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/50?t=1605954161",
          name: "這群人",
          module: "生活",
          FBNumber: "3,320,000",
          YTNumber: "2,170,367",
          isChosen: true,
        },
        {
          id: "B008",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/171?t=1605954166",
          name: "蔡阿嘎",
          module: "生活、親子",
          FBNumber: "1,932,760",
          YTNumber: "2,460,000",
          isChosen: true,
        },
        {
          id: "B009",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/125?t=1605954209",
          name: "阿滴英文",
          module: "生活",
          FBNumber: "922,541",
          YTNumber: "2,660,000",
          isChosen: false,
        },
        {
          id: "B010",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/48732?t=1605954161",
          name: "隋棠",
          module: "生活",
          FBNumber: "2,664,102",
          YTNumber: "4,260",
          isChosen: false,
        },
        {
          id: "B011",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/900?t=1605954161",
          name: "Duncan",
          module: "插畫",
          FBNumber: "3,164,265",
          YTNumber: "7,400",
          isChosen: false,
        },
        {
          id: "B012",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/463?t=1605954177",
          name: "黃阿瑪的後宮生活",
          module: "寵物",
          FBNumber: "1,401,139",
          YTNumber: "2,170,367",
          isChosen: false,
        },
        {
          id: "B013",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/50?t=1605954161",
          name: "這群人",
          module: "生活",
          FBNumber: "3,320,000",
          YTNumber: "2,170,367",
          isChosen: true,
        },
        {
          id: "B014",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/171?t=1605954166",
          name: "蔡阿嘎",
          module: "生活、親子",
          FBNumber: "1,932,760",
          YTNumber: "2,460,000",
          isChosen: true,
        },
        {
          id: "B015",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/125?t=1605954209",
          name: "阿滴英文",
          module: "生活",
          FBNumber: "922,541",
          YTNumber: "2,660,000",
          isChosen: false,
        },
        {
          id: "B016",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/48732?t=1605954161",
          name: "隋棠",
          module: "生活",
          FBNumber: "2,664,102",
          YTNumber: "4,260",
          isChosen: false,
        },
        {
          id: "B017",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/900?t=1605954161",
          name: "Duncan",
          module: "插畫",
          FBNumber: "3,164,265",
          YTNumber: "7,400",
          isChosen: false,
        },
        {
          id: "B018",
          imageSrc:
            "https://storage.googleapis.com/kol-pro/image/thumbnail/463?t=1605954177",
          name: "黃阿瑪的後宮生活",
          module: "寵物",
          FBNumber: "1,401,139",
          YTNumber: "2,170,367",
          isChosen: false,
        },
      ],
      // id: "B001",
      //     imageSrc:
      //       "https://storage.googleapis.com/kol-pro/image/thumbnail/50?t=1605954161",
      //     name: "這群人",
      //     module: "生活",
      //     FBNumber: "3,320,000",
      //     YTNumber: "2,170,367",
      //     isChosen: true,

      searchData: {
        classTypeId: "",
        description: "",
        module: "",
        keyword: "",
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
      },
    };
  },
  mounted() {
    $(".jqimgFill").imgLiquid();
  },
  watch: {},

  methods: {
    checkAll() {
      if (this.ischeckAll == false) {
        //is isChoose all
        console.log("turn true")

        this.$eventBus.$emit(
          'showConfirm',
          '這頁將會"全部訂閱"，是否確定?',
          (isOK) => {
            if (isOK) {
              this.ischeckAll = true

              this.currentData.forEach((item) => {
                item.isChosen = true
              });
            }
          }
        );
      } else {
        //is cancel all
        this.$eventBus.$emit(
          'showConfirm',
          '這頁將會"全部取消訂閱"，是否確定?',
          (isOK) => {
            if (isOK) {
              this.ischeckAll = false

              this.currentData.forEach((item) => {
                item.isChosen = false
              });
            }
          }
        );
      }
    },
    addNew() {
      this.$router.push({
        name: "SpiderBigDataEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    async updateBigData(item) {
      this.$router.push({
        name: "SpiderBigDataEdit",
        params: { searchData: this.searchData },
      });
      return;
    },
  },
};
</script>
