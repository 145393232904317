function isMSbrowser() {
    const userAgent = window.navigator.userAgent
    return userAgent.indexOf('Edge') !== -1 || userAgent.indexOf('Trident') !== -1
}

function format(data) {
    return String(data).replace(/"/g, '""').replace(/(^[\s\S]*$)/, '"$1"')
}

function saveCSV(title, head, data) {
    let wordSeparator = ','
    let lineSeparator = '\n'

    let reTitle = title + '.csv'
    let headBOM = '\ufeff'
    let headStr = head ? head.map(item => format(item)).join(wordSeparator) + lineSeparator : ''
    let dataStr = data ? data.map(row => row.map(item => format(item)).join(wordSeparator)).join(lineSeparator) : ''

    return isMSbrowser()
        ? new Promise(resolve => { // Edge、IE11
            let blob = new Blob([headBOM + headStr + dataStr], { type: 'text/plain;charset=utf-8' })
            window.navigator.msSaveBlob(blob, reTitle)
            resolve()
        })
        : new Promise(resolve => { // Chrome、Firefox
            let a = document.createElement('a')
            a.href = 'data:text/csv;charset=utf-8,' + headBOM + encodeURIComponent(headStr + dataStr)
            a.download = reTitle
            a.click()
            resolve()
        })
}

export const exportCSV = function (title, datas) {
    //參考資料：https://juejin.cn/post/6957521395270025252
    let csv = [];
    let keys = datas && datas[0] ? Object.keys(datas[0]) : [];
    for (let data of datas) {
        let tmp = [];
        for (let key of keys) {
            tmp.push(data[key] ? data[key] : "");
        }
        csv.push(tmp);
    }
    // console.log(csv);
    // let title = 'test'
    // let keys = ['key', 'value']
    // let csv = [
    //     ['a', '我是正常文本'],
    //     ['b', '我是"双引号"'],
    //     ['c', '我是,小逗号,'],
    //     ['d', '我是\n换行符']
    // ]
    saveCSV(title, keys, csv);
}
export const IPSTool = {
    deCodeData(data, name) {
        data.forEach(element => {
            if (element[name]) {
                let tmp = element[name].split(",");
                tmp = tmp.filter((item) => {
                    return parseInt(item) > 0
                });
                element[name] = tmp;
            } else {
                element[name] = [];
            }
            delete element.建立時間;
        });
        return data;
    },
    enCodeData(element, name) {
        if (element[name]) {
            if (element[name].length > 0) {
                element[name] = element[name].join(",") /*+ ","*/;
            } else {
                element[name] = "";
            }
        } else {
            element[name] = "";
        }
        return element;
    },
    //整數 && min>val
    檢查輸入金額(val, min) {
        let n = parseInt(val);
        if (n + "" != val + "") {
            return false;
        }
        if (n <= min) {
            return false;
        }
        return true;
    },
    //輸入>=-1的正整數，且不可為0；upLimit:上層設定
    檢查輸入限額(val, upLimit = -1) {
        let n = parseInt(val);
        if (n + "" != val + "") {
            return false;
        }
        if (n < -1) {
            return false;
        }
        if (n === 0) {
            return false;
        }
        if (upLimit != -1) {
            //檢查上層設定
            if (n == -1) {
                return false;
            }
            if (n > upLimit) {
                return false;
            }
        }
        return true;
    },
    // apiList(res) {
    //     console.log("enter1....");
    //     // if (res && res.data && res.data.status == 1) {
    //     if (res && res.data && (res.data.stateCode == 1 || res.data.status == 1)) {
    //         return res.data.content;
    //     } else {
    //         let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
    //         this.$eventBus.$emit("showAlert", errMsg);
    //         return [];
    //     }
    // },
    async callAPIList(cmd, send) {
        try {
            let res = await cmd(send);
            if (res && res.data && (res.data.stateCode == 1 || res.data.status == 1)) {
                return res.data.content;
            } else {
                let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                this.$eventBus.$emit("showAlert", errMsg);
            }
        } catch (e) {
            this.$eventBus.$emit("showAlert", e);
            throw e;
        }
    },
    apiEdit(res) {
        // if (res && res.data && res.data.status == 1) {
        if (res && res.data && (res.data.stateCode == 1 || res.data.status == 1)) {
            this.$eventBus.$emit("showAlert", "成功");
        } else {
            let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
            this.$eventBus.$emit("showAlert", errMsg);
        }
    },
}

export const APILib = {
    Invoice(res) {
        if (res && res.data && res.data.status == 1) {
            return res.data;
        } else {
            let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
            throw errMsg;
        }
    },
}