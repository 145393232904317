<template>
  <div id="BeforeLoginList" class="searchList">
    <section class="content-header">
      <h1>維護時間管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">維護時間管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <button
              @click="showCreat()"
              class="btn btn-block btn-primary btn-add ml-5"
              type="button"
            >
              新增維護時段
            </button>
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                    v-on:restList="getDataList()"
                  />
                </div>
              </div>
            </div>
            <div class="select-group">
              <label class="control-label" style="width: 70px;">類別：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.狀態類別"
                @change="getDataList()"
              >
                <option value="">全部</option>
                <option value="執行中">執行中</option>
                <option value="待執行">待執行</option>
                <option value="維護完成">維護完成</option>
                <option value="取消執行">取消執行</option>
              </select>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>編號</th>
                  <th>維護類型</th>
                  <th class="text-nowrap" style="min-width: 150px">維護時段</th>
                  <th class="text-nowrap" style="min-width: 100px">備註</th>
                  <th class="text-nowrap" style="min-width: 100px">執行狀態</th>
                  <th class="text-nowrap" style="min-width: 100px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="4">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="4">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <template
                    v-if="searchData.狀態類別 == '' ||
                      searchData.狀態類別 == getStatus(item)
                      "
                  >
                    <td>
                      {{ item.編號 }}
                    </td>
                    <td>
                      {{ item.維護類別 == 1 ? '一般維護' : '臨時維護' }}
                    </td>
                    <td class="text-nowrap">
                      {{ item.開始時間 }} ~ {{ item.結束時間 }}
                    </td>
                    <td class="text-nowrap">
                      {{ item.備註 }}
                    </td>
                    <td class="text-nowrap">
                      <span
                        :class="[
                          getStatus(item) == '取消執行' ?
                          'text-red': getStatus(item) == '待執行' ?
                          'text-danger': getStatus(item) == '維護完成' ?
                          'text-success' : 'text-primary'
                        ]"
                      >
                        {{ getStatus(item) }}
                      </span>
                    </td>
                    <td>
                      <button
                        class="btn btn-success mr-10"
                        @click="edit(item)"
                        v-if="getStatus(item) == '待執行' || getStatus(item) == '執行中'"
                      >
                        修改
                      </button>
                      <button
                        class="btn btn-danger"
                        v-if="getStatus(item) == '待執行'"
                        @click="update(item)"
                      >
                        取消
                      </button>
                      <button
                        class="btn btn-primary"
                        v-if="getStatus(item) == '執行中'"
                        @click="update(item)"
                      >
                        結束開啟
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <!--新增維護時段-->
    <div class="modal fade" ref="addMaintain">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">新增維護時段</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>維護類型：</td>
                    <td style="display: flex;">
                      <select
                        class="form-control"
                        v-model="addData.維護類別"
                        style="width: 30%;"
                      >
                        <option value="1">一般維護</option>
                        <option value="2">臨時維護</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>維護時段：</td>
                    <td>
											<div style="padding-left: 0 ; padding-right: 0;">
                        <div style="display: flex;">
                          <div class="input-group date">
                            <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <DatePicker :propValue.sync="addData.開始時間" />
                          </div>
                          <div class="input-group time ml-10">
                            <div class="input-group-addon">
                              <i class="fa fa-clock-o"></i>
                            </div>
                            <input class="form-control timepicker" id="timepicker_start" type="text" />
                          </div>
                        </div>
											</div>
											<div class="text-center" style="padding-left: 0 ; padding-right: 0;">~</div>
											<div style="padding-left: 0 ;">
                        <div style="display: flex;">
                          <div class="input-group date">
                            <div class="input-group-addon">
                              <i class="fa fa-calendar"></i>
                            </div>
                            <DatePicker :propValue.sync="addData.結束時間" />
                          </div>
                          <div class="input-group time ml-10">
                            <div class="input-group-addon">
                              <i class="fa fa-clock-o"></i>
                            </div>
                            <input class="form-control timepicker" id="timepicker_end" type="text" />
                          </div>
                        </div>
											</div>
                    </td>
                  </tr>
                  <tr>
                    <td>備註：</td>
                    <td>
                      <input class="form-control" required="required" type="text" v-model="addData.備註" />
                    </td>
                  </tr>
                  <tr>
                    <td>公告id：</td>
                    <td>
                      <input class="form-control" required="required" type="text" v-model="addData.維護公告編號" @blur="checkNewsId()" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!isEdit" class="btn btn-primary" type="button" @click="create()">確定</button>
            <button v-else class="btn btn-primary" type="button" @click="update(addData)">修改</button>
            <button class="btn btn-default pull-right" data-dismiss="modal" type="button">取消</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import appUtils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";

export default {
  components: {
		DatePicker,
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      isEdit: false,
      searchData: {
        專案代號: this.$store.state.projectId || appUtils.getCookie("projectId"),
        開始時間: new moment().subtract(3, "month").format("YYYY-MM-DD"),
				結束時間: new moment().format("YYYY-MM-DD"),
				狀態類別 : "",
      },
      addData: {
        專案代號: this.$store.state.projectId || appUtils.getCookie("projectId"),
				備註: "",
				啟用YN: 1,
				姓名: "",
				建立人ID: "",
				建立時間: new moment().format("YYYY-MM-DD"),
				開始時間: new moment().format("YYYY-MM-DD"),
				結束時間: new moment().format("YYYY-MM-DD"),
				維護公告編號: "",
				維護類別: 1
      },
			local: {
				startTime: new moment().format("YYYY-MM-DD"),
				endTime: new moment().format("YYYY-MM-DD"),
			},
      datas: [],
      allDatas: [],
      timeStringOption: {timeStyle: "short"},
      timepickerOption: {},
    };
  },
	computed: {
    ...mapState({
      user: (state) => state.loginUser,
    }),
  },
  mounted() {
    this.timepickerOption.showInputs = false;
    $(".timepicker").timepicker(this.timepickerOption);

    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
			api.maintain.queryAll(this.searchData)
				.then(({data}) => {
					this.datas = data.result.datas.reverse();
					this.loading = false;
				})
    },
    resetCreateBox() {
      this.addData.維護公告編號 = "";
      this.addData.維護類別 = 1;
      this.addData.備註 = "";
    },
    showCreat() {
      this.addData.開始時間 = new moment().format("YYYY-MM-DD");
      this.addData.結束時間 = new moment().format("YYYY-MM-DD");
      this.resetCreateBox();

      $("#timepicker_start").val(
        new Date().toLocaleTimeString("en-US", this.timeStringOption)
      );
      
      $("#timepicker_end").val(
        new Date().toLocaleTimeString("en-US", this.timeStringOption)
      );
      $(this.$refs.addMaintain).modal("show");
    },
    create() {
			this.addData.姓名 = this.user.EmployeeName;
			this.addData.建立人ID = this.user.EmployeeId;
      this.addData.開始時間 = this.addData.開始時間 + " " + $("#timepicker_start").val();
      this.addData.結束時間 = this.addData.結束時間 + " " + $("#timepicker_end").val();
      this.addData.維護類別 = Number(this.addData.維護類別)

      this.addData.開始時間 = new Date(this.addData.開始時間 + " UTC");
      this.addData.結束時間 = new Date(this.addData.結束時間 + " UTC");

			api.maintain.create(this.addData)
				.then(({data}) => {
          if (data.status == "1") {
            this.$eventBus.$emit("showAlert", "新增成功!");
            $(this.$refs.addMaintain).modal("hide");
            this.getDataList();
          }
          else this.$eventBus.$emit("showAlert", "新增失敗!");
				})
			return
    },

    edit(item) {
      this.isEdit = true;
      this.addData.編號 = item.編號;
      this.addData.備註 = item.備註;
      this.addData.維護公告編號 = item.維護公告編號;
      this.addData.開始時間 = new Date(item.開始時間 + " UTC");
      this.addData.結束時間 = new Date(item.結束時間);
      $("#timepicker_start").val(
        new Date(item.開始時間).toLocaleTimeString("en-US", this.timeStringOption)
      );      
      $("#timepicker_end").val(
        new Date(item.結束時間).toLocaleTimeString("en-US", this.timeStringOption)
      );
      $(this.$refs.addMaintain).modal("show");
    },

    update(item) {
      console.log(item);

      item.姓名 = this.user.EmployeeName;
			item.修改人ID = this.user.EmployeeId;

      if(this.isEdit) {
        item.開始時間 = item.開始時間 + " " + $("#timepicker_start").val();
        item.結束時間 = item.結束時間 + " " + $("#timepicker_end").val();
        item.開始時間 = new Date(item.開始時間 + " UTC");
        item.結束時間 = new Date(item.結束時間 + " UTC");
      } else {
        if(this.getStatus(item) == '執行中') {
          item.結束時間 = new moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
          item.結束時間 = new Date(item.結束時間 + " UTC");
        }
        if(this.getStatus(item) == '待執行') {
          item.啟用YN = 0
        }
      }

      api.maintain.update(item)
				.then(({data}) => {
          if(this.isEdit) {
            this.isEdit = false;
            this.$eventBus.$emit("showAlert", "修改成功!");
            $(this.$refs.addMaintain).modal("hide");
          }
          this.getDataList();
				})
    },
    getStatus(item) {
      // new moment(item.結束時間)
      let timeIsBetween = new moment(new Date()).isBetween(new moment(item.開始時間), new moment(item.結束時間));
      let timeIsBefore = new moment(new Date()).isAfter(new moment(item.結束時間));
      let timeIsAfter = new moment(new Date()).isBefore(new moment(item.結束時間));
      let isStart = item.啟用YN;

      if(isStart == 1) {
        if(timeIsBetween) return '執行中'
        if(timeIsBefore) return '維護完成'
        if(timeIsAfter) return '待執行'
      } else {
        return '取消執行'
      }
    },
    checkNewsId() {
      api.news.query({ newsId: this.addData.維護公告編號 })
        .then((res) => {
          if(res.data.status != 1) {
            this.$eventBus.$emit("showAlert", "公告Id不正確!");
          }
        })
    },
  },
};
</script>

<style scoped>
.select-group {
  width: 200px !important;
  display: flex !important;
  align-items: center !important;
}
</style>