/* eslint-disable */
import api from '@/assets/js/app-api';

const state = {
    queryAll: {},
    fileDisplayList: [],
    fileDisplay: {},
    fileDisplayResponse: {},
    imageList: [],
}

// getters
const getters = {
}

// actions
const actions = {
    classQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.classType.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    topCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.top.create(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    topDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.top.delete(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    tagQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.tagQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    articleQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    articleDisableAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.disableAll(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    articleQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.query(data).then(res => {
                resolve(res.data.content);
            });
        });
    },
    articleCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.create(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'create', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    articleUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.update(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    articleDisable({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.disable(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    commentQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.comment.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    commentDisableAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.comment.disableAll(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    commentDisable({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.comment.disable(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    commentAdd({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.comment.add(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    replyQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.reply.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    replyDisableAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.reply.disableAll(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    replyDisable({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.reply.disable(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    replyAdd({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.reply.add(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    muteQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    muteCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.create(data).then(res => {
                const action = data[0] && data[0].isEdit ? 'update' : 'create';
                this._vm.global.showResponse(this._vm, res, action, null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    muteDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.delete(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    memberMuteQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.memberQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    memberMuteDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.memberDelete(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    memberMuteAdd({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.memberAdd(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'create', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    memberMuteUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.mute.memberUpdate(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    reportQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.report.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    reportQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.report.query(data).then(res => {
                resolve(res.data.content);
            });
        });
    },
    reportUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.report.update(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    statisticQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.statistic.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                } else {
                    this._vm.global.showResponse(this._vm, res);
                }
            });
        });
    },
    voteQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.vote.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const content = res.data.content;
                    commit('setQueryAll', content);
                    resolve(content);
                }
            });
        });
    },
    voteQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.vote.query(data).then(res => {
                resolve(res.data.content);
            });
        });
    },
    voteCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.vote.create(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'create', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    voteUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.vote.update(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    voteDisable({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.vote.disable(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    fileUpload({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.article.fileUpload(data).then(res => {
                resolve(res.data);
            })
        })
    },

    getPresidentData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.election.history.president(data).then(res => {
                resolve(res.data);
            })
        });
    },
    getLegislatorData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.election.history.legislator(data).then(res => {
                resolve(res.data);
            })
        });
    },
    getPartyData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.election.history.party(data).then(res => {
                resolve(res.data);
            })
        });
    },
    //九合一大選
    getMayorData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.election.mayors(data).then(res => {
                resolve(res.data);
            })
        });
    },
    getCouncilmanData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.election.councilman(data).then(res => {
                resolve(res.data);
            })
        });
    },

    imageUpload({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.image.upload(data).then(res => {
                resolve(res.data);
            })
        })
    },

    getImageList({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.image.query(data).then(res => {
                if (res.data.isSuccess) {
                    const result = res.data.data;
                    commit('setImageList', result);
                    resolve(result);
                }
            })
        });
    },

    getFileDisplay({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.fileDisplay.query(data).then(res => {
                if (res.data.isSuccess) {
                    const result = res.data.data;
                    commit('setFileDisplay', result);
                    resolve(result);
                }
            });
        });
    },

    getFileDisplayList({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.fileDisplay.queryAll(data).then(res => {
                if (res.data.isSuccess) {
                    const result = res.data.data;
                    commit('setFileDisplayList', result);
                    resolve(result);
                }
            });
        });
    },

    insertFileDisplayData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.fileDisplay.create(data).then(res => {
                const result = res.data;
                commit('setfileDisplayResponse', result);
                resolve(result);
            });
        });
    },

    updateFileDisplayData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.fileDisplay.update(data).then(res => {
                const result = res.data;
                commit('setfileDisplayResponse', result);
                resolve(result);
            });
        });
    },

    deleteFileDisplayData({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.fileDisplay.delete(data).then(res => {
                const result = res.data;
                commit('setfileDisplayResponse', result);
                resolve(result);
            });
        });
    },

    //問券調查
    surveyEvents({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.survey.events(data).then(res => {
                resolve(res.data);
            });
        });
    },
    surveyTypes({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.survey.types(data).then(res => {
                resolve(res.data);
            });
        });
    },
    surveyQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.newtalk.survey.queryAll(data).then(res => {
                resolve(res.data);
            });
        });
    },
}

// mutations
const mutations = {
    setQueryAll(state, response) {
        state.queryAll = response;
    },
    setFileDisplayList(state, response) {
        state.fileDisplayList = response;
    },
    setFileDisplay(state, response) {
        state.fileDisplay = response;
    },
    setfileDisplayResponse(state, response) {
        state.fileDisplayResponse = response;
    },
    setImageList(state, response) {
        state.imageList = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
