<template>
  <div id="BeforeShareList" class="searchList">
    <section class="content-header">
      <h1>玩家對應查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">玩家對應查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <button
              @click="exportOrders()"
              class="btn btn-block btn-success btn-add"
              type="button"
            >
              資料匯出
            </button>
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propsIn="'format2'"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>

            <!-- <div class="select-group">
              <select
                class="form-control"
                v-model="activity"
              >
                <!-- <option :value="'psychologicalTest'">心理測驗活動</option> 
                <option :value="'before'">事前登錄活動</option>
                <!-- <option :value="'finish_raise'">[完成]好友招募活動</option> 
              </select>
            </div> -->

            <!-- <div class="select-group">
              <select
                class="form-control"
                v-model.number="searchData.PrizeType"
                @change="resetDataList()"
              >
                <option value="">全部</option>
                <!-- <template  v-if="activity == 'psychologicalTest'">
                  <option :value="1">邀請1人</option>
                  <option :value="2">邀請2人</option>
                  <option :value="3">邀請3人</option>
                </template>
                <template  v-if="activity == 'finish_raise'">
                  <option :value="'A'">任務A</option>
                  <option :value="'B'">任務B</option>
                </template> 

              </select>
            </div> -->

            <div class="input-group search-group" style="width:200px">
              <input
                type="text"
                class="form-control pull-right"
                placeholder="搜尋邀請者、邀請碼"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>編號</th>
                  <th class="text-nowrap" style="min-width: 150px">活動類型</th>
                  <th class="text-nowrap" style="min-width: 150px">邀請者</th>
                  <!-- <th class="text-nowrap" style="min-width: 150px">完成條件</th> -->
                  <th class="text-nowrap" style="min-width: 150px">邀請碼</th>
                  <th class="text-nowrap" style="min-width: 150px">被邀請者</th>
                  <th class="text-nowrap" style="min-width: 150px">建立時間</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ ((searchData.pageNum - 1) * searchData.pageSize) + index + 1 }}
                  </td>
                  <td class="text-nowrap">
                    {{ eventName[activity] }}
                  </td>
                  <td class="text-nowrap">
                            {{item.usedIdentityKey}}
                  </td>
                  <!-- <td class="text-nowrap">
                    <span v-if="activity == 'psychologicalTest'">邀請{{ item.prizeType }}人</span>
                    <span v-else-if="activity == 'before'">配對</span>
                    <span v-else>任務{{  item.missionType }}</span>
                  </td> -->
                  <td class="text-nowrap">
                    <span>{{ item.usedInviteCode }}</span>
                  </td>
                  <td class="text-nowrap">
          
                      {{ item.identityKey }}
                    <!-- {{ activity == 'psychologicalTest' ? item.usedIdentityKey : activity == 'before' ? item.usedIdentityKey : item.corresopndIdentityKey }} -->
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="20"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      activity: "before",
      eventName: {
        "psychologicalTest" : `心理測驗`,
        "before" : `事前登錄活動`,
        "finish_raise" : `[完成]好友招募活動`,
      },
      searchData: {
        PrizeType: "",
        PhoneNumber: "",
        keyword: "",
        pageNum: 1,
        pageSize: 20,
        local: {
          startTime: new moment().subtract(3, "month").format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
        startTime: "",
        endTime: "",
      },
      totalNum:999,
      datas: [],
      allDatas: []
    };
  },
  mounted() {
    this.getDataList();
  },
  watch: {
    activity() {
      this.searchData.PrizeType = "";
      this.getDataList();
    },
    'searchData.local.endTime': function(newVal, oldVal) {
       this.getDataList();
    }
  },
  methods: {
    getDataList(all) {
      if(all) this.searchData.pageSize = this.total;
      if(this.searchData.PrizeType != "") 
          this.searchData.PrizeType = String(this.searchData.PrizeType);
        this.searchData.startTime = new moment(this.searchData.local.startTime).format("YYYY-MM-DD");   
        this.searchData.endTime = new moment(this.searchData.local.endTime).format("YYYY-MM-DD");
       let type = 'inviteQueryAll';
        // this.activity == "psychologicalTest" ? 
        // "inviteQueryAll" : this.activity == "before" ?
        // "raisePairQueryAll" : "raiseFinishQueryAll";

      let searchData = this.activity == "psychologicalTest" ?
        this.searchData :  
        {
          EventId: this.activity,
          Keyword: this.searchData.keyword,
          PageNum: this.searchData.pageNum,
          PageSize: this.searchData.pageSize,
          StartTime: this.searchData.startTime,
          EndTime: this.searchData.endTime,
          MissionType: this.searchData.PrizeType
        }

      api.dem.event[type](searchData)
        .then((res) => {
          if(all) {
            this.allDatas = res.data.result.datas;
            this.searchData.pageSize = 20;
          } else {
            this.loading = false;
            this.datas = res.data.result.datas;
            this.total = res.data.result.total;
            this.getDataList(true);
          }
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    exportOrders() {
      let typeName = this.eventName[this.activity].replace("[", "").replace("]", "_");
      let exportData = [];


      this.allDatas.forEach(item => {
        let dateString = item.createTime.replace('T', ' ').split('.')[0];
        exportData.push({
          // 活動類型:  '事前登錄活動' ,
          邀請者: item.usedIdentityKey,
          邀請碼:  item.usedInviteCode ,
          被邀請者: item.identityKey ,
          建立時間: dateString,
        })
      });
  
      appUtils.exportData(
        exportData,
        appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
        typeName
      );
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
