<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>獎項編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>獎項列表</li>
        <li class="active">獎項編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 活動：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.activityId" :disabled="isEdit">
                    <option disabled="disabled" value>請選擇活動</option>
                    <option
                      :value="item.activityId"
                      v-for="(item, index) in activity.datas"
                      :key="index"
                    >{{item.activityTitle}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 限制條件：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.ruleType">
                    <option value>無</option>
                    <option value="1帳多次">一個帳號領多個序號</option>
                    <option value="公開序號">公開序號每人限領1次</option>
                    <option value="綁定帳號">綁定帳號</option>
                    <!-- <option value="1序多帳">一個序號對應多個帳號</option> -->
                  </select>
                </div>
              </div>

              <div class="form-group" v-show="edit.ruleType.includes('1帳')">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 限制個數：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="number"
                    min="1"
                    v-model.number="edit.ruleCountLimit"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="awardsTitle">
                  <span class="text-red">*</span>
                  名稱 (200字)：
                </label>
                <div class="col-sm-10">
                  <input
                    id="awardsTitle"
                    type="text"
                    class="form-control"
                    required="required"
                    maxlength="200"
                    v-model="edit.awardsTitle"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  獎項開始時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right" id="startTime">
                        <DatePicker :propValue.sync="startTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        autocomplete="off"
                        id="startTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  獎項結束時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right" id="endTime">
                        <DatePicker :propValue.sync="endTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        autocomplete="off"
                        id="endTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="form-group" v-if="projectId != 'omp_dk'">
                <label class="col-sm-2 control-label" for="inputFile">添加道具：</label>
                <div class="col-sm-5">
                  <select class="form-control" v-model="awardItem.itemId">
                    <option disabled="disabled" value>請選擇道具</option>
                    <option
                      :value="exchangeItem.itemId"
                      v-for="(exchangeItem, index) in exchangeItems.datas"
                      :key="index"
                    >{{exchangeItem.itemTitle}}</option>
                  </select>
                </div>
                <div class="col-sm-2">
                  <input
                    class="form-control"
                    id="itemCount"
                    required="required"
                    type="number"
                    v-model="awardItem.itemCount"
                  />
                </div>
                <button @click="AddAwardItem(awardItem)" class="btn btn-danger" type="button">添加</button>
              </div>

              <div class="form-group" v-show="awardsItems.total > 0">
                <label class="col-sm-2 control-label" for="inputFile">已添加道具：</label>
                <div class="col-sm-10 text">
                  <table class="table table-bordered">
                    <tbody>
                      <tr :key="index" v-for="(awardsItem, index) in awardsItems.datas">
                        <td>{{awardsItem.itemTitle}}</td>
                        <td>
                          <input type="number" v-model="awardsItem.itemCount" />
                        </td>
                        <td>
                          <button
                            class="btn btn-danger"
                            type="button"
                            @click="UpdateAwardsItem(awardsItem)"
                          >修改</button>
                          <button
                            class="btn btn-danger"
                            type="button"
                            @click="DeleteAwardsItem(awardsItem)"
                          >刪除</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
             
              <div class="form-group" v-if="projectId == 'omp_newtalk'">
                <label class="col-sm-2 control-label" for="exchangeCost">
                  扣除頭殼幣：
                </label>
                <div class="col-sm-5">
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="edit.exchangeCost"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="marketCost">
                  獎項市值：
                </label>
                <div class="col-sm-5">
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="edit.marketCost"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="awardsInfo">
                  獎項說明 (500字)：
                </label>
                <div class="col-sm-10">
                  <textarea
                    id="awardsInfo"
                    type="text"
                    class="form-control"
                    maxlength="500"
                    v-model="edit.awardsInfo"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="note">
                  備註 (500字)：
                </label>
                <div class="col-sm-10">
                  <textarea
                    id="note"
                    type="text"
                    class="form-control"
                    maxlength="500"
                    v-model="edit.note"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="vendorActivityCode">
                  <span class="text-red"></span> 原廠活動代碼：
                </label>
                <div class="col-sm-5">
                  <input
                    class="form-control"
                    id="vendorActivityCode"
                    required="required"
                    type="text"
                    v-model="edit.vendorActivityCode"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="啟用"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="停用"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    iCheckRedioButton,
    DatePicker
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      edit: {
        activityId: "",
        vendorActivityCode:"",// 原廠活動代碼
        awardsId: utils.getTimeId(), // 獎項Id
        awardsTitle: "", // 名稱
        awardsInfo: "", // 獎項說明
        note: "", // 備註
        isStop: 0, // 狀態
        ruleType: "", // 限制條件
        ruleCountLimit: 1, // 限制個數
        exchangeCost: 0, // 扣除頭殼幣
        marketCost: 0, // 獎項市值
      },
      startTime: "",
      endTime: "",
      awardItem: {
        awardsId: "",
        itemId: "",
        itemCount: 0,
        isStop: 0 // 狀態
      },
    };
  },
  created() {},
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    this.GetActivityQueryAll();
    this.GetexchangeItemsQueryAll();
    this.GetAwardItem();
    if (this.$route.params.searchData != undefined) {
      this.edit.activityId = this.$route.params.searchData.activityId;
    }
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetAwardQuery();
    } else {
      this.updateTime();
    }
  },
  computed: {
    ...mapState({
      activity: state => state.activity.queryAll,
      award: state => state.award.query,
      awardsItems: state => state.awardsItem.queryAll,
      exchangeItems: state => state.exchangeItems.queryAll,
      response: state => state.award.response,
      awardsItemResponse: state => state.awardsItem.response
    })
  },
  watch: {
    award() {
      this.edit = this.award;
      this.edit.ruleType =
        this.edit.ruleType === "1帳1次" ? "1帳多次" : this.edit.ruleType;
      this.edit.ruleCountLimit = this.edit.ruleCountLimit || 1;
      this.edit.exchangeCost = this.edit.exchangeCost || 0;
      this.edit.marketCost = this.edit.marketCost || 0;
      this.GetAwardItem();
      this.updateTime();
    },
    response(value) {
      if (value.status == "1") {
        this.GetAwardQuery();
        this.$eventBus.$emit("showAlert", "更新成功!!");
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    awardsItemResponse() {
      this.GetAwardItem();
    }
  },
  methods: {
    GetAwardQuery() {
      //活動列表
      this.$store.dispatch("award/awardQuery", {
        awardsId: this.$route.params.id
      });
    },

    GetActivityQueryAll() {
      //活動列表
      this.$store.dispatch("activity/activityQueryAll", {
        isStop: 0,
        isDelete: 0
      });
    },

    GetexchangeItemsQueryAll() {
      //道具列表
      this.$store.dispatch("exchangeItems/exchangeItemsQueryAll", {
        isStop: 0,
        isDelete: 0
      });
    },

    updateTime() {
      this.startTime = this.edit.awardsStartTime || "";
      this.endTime = this.edit.awardsEndTime || "";
      if (this.startTime) {
        $("#startTimeOnly").val(this.global.getTime(this.startTime));
      } else {
        $("#startTime").find(".datepicker").val("");
        $("#startTimeOnly").val("");
      }
      if (this.endTime) {
        $("#endTimeOnly").val(this.global.getTime(this.endTime));
      } else {
        $("#endTime").find(".datepicker").val("");
        $("#endTimeOnly").val("");
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AwardList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    AddAwardItem(item) {
      item.activityId = this.edit.activityId;
      item.awardsId = this.edit.awardsId;

      if (item.itemId == "") {
        this.$eventBus.$emit("showAlert", "請選擇道具");
        return;
      }

      item.itemCount = parseInt(item.itemCount);
      if (item.itemCount <= 0) {
        this.$eventBus.$emit("showAlert", "道具數量需大於0");
        return;
      }

      this.$store.dispatch("awardsItem/awardsItemCreate", item);
    },

    GetAwardItem() {
      this.$store.dispatch("awardsItem/awardsItemQueryAll", {
        awardsId: this.edit.awardsId,
        isStop: 0,
        isDelete: 0
      });
    },

    DeleteAwardsItem(item) {
      this.$store.dispatch("awardsItem/awardsItemDelete", item);
    },

    UpdateAwardsItem(item) {
      item.itemCount = parseInt(item.itemCount);
      if (item.itemCount <= 0) {
        this.$eventBus.$emit("showAlert", "道具數量需大於0");
        return;
      }

      this.$store.dispatch("awardsItem/awardsItemUpdate", item);
    },

    //送出
    Save() {
      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      this.startTime = new Date(
        `${this.startTime.split(" ")[0]} ${startTimeOnly}`
      );
      this.endTime = new Date(`${this.endTime.split(" ")[0]} ${endTimeOnly}`);
      this.edit.isStop = parseInt(this.edit.isStop);

      if (this.edit.activityId == "") {
        this.$eventBus.$emit("showAlert", "請選擇活動");
        return;
      }

      if (this.edit.awardsTitle == "") {
        this.$eventBus.$emit("showAlert", "名稱不能為空");
        return;
      }

      if (this.edit.ruleType.includes('1帳') && this.edit.ruleCountLimit == "") {
        this.$eventBus.$emit("showAlert", "限制個數不能為空");
        return;
      }

      this.edit.ruleCountLimit = this.edit.ruleCountLimit || 1;
      this.edit.exchangeCost = this.edit.exchangeCost || 0;
      this.edit.marketCost = this.edit.marketCost || 0;
      this.edit.awardsStartTime = this.global.isValidDay(this.startTime)
        ? new Date(`${this.startTime} UTC`)
        : null;
      this.edit.awardsEndTime = this.global.isValidDay(this.endTime)
        ? new Date(`${this.endTime} UTC`)
        : null;

      if (this.isEdit) {
        this.$store.dispatch("award/awardUpdate", this.edit);
      } else {
        this.$store.dispatch("award/awardCreate", this.edit);
      }
    }
  }
};
</script>