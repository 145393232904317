<template>
  <div id="RewardMessageEdit">
    <section class="content-header">
      <h1>給獎訊息設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/FC/RewardMessage">給獎訊息設定</router-link>
        </li>
        <li class="active">{{ id ? "編輯" : "新增" }}內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form
            class="form-horizontal"
            name="rewardMessageForm"
            method="post"
            @submit.prevent="submit"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發送名單：
                </label>
                <div class="col-sm-10">
                  <div class="form-group">
                    <div class="radio col-md-2">
                      <iCheckRedioButton
                        checkValue="1"
                        message="單一玩家"
                        name="sendRadio"
                        v-model.number="message.sendType"
                      />
                    </div>
                    <div class="col-md-10">
                      <input
                        class="form-control"
                        placeholder="輸入玩家編號，中間用逗號分隔"
                        type="text"
                        :required="message.sendType === 1"
                        v-model.trim="playerIds"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="radio col-md-2 col">
                      <iCheckRedioButton
                        checkValue="2"
                        message="指定名單"
                        name="sendRadio"
                        v-model.number="message.sendType"
                      />
                    </div>
                    <div class="col-md-10 col">
                      <label class="btn btn-primary" @click="importSN()">
                        選擇檔案
                      </label>
                      <span style="margin-left: 8px">
                        {{ importList.name }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="radio col-md-2">
                      <iCheckRedioButton
                        checkValue="3"
                        message="複合條件"
                        name="sendRadio"
                        v-model.number="message.sendType"
                      />
                    </div>
                    <div class="col-md-10">
                      <div class="box-group">
                        <div class="col-md-3">
                          <label class="checkbox checkbox-inline">
                            <iCheckCheckBox
                              :propValue="1"
                              :propChecked="conditions[0].checked"
                              v-on:update:checked="conditionChange"
                            />&nbsp;等級
                          </label>
                        </div>
                        <div class="level col-md-9">
                          <input
                            class="form-control"
                            type="number"
                            v-model="conditions[0].ruleValue1"
                            :required="+conditions[0].checked === 1"
                          />
                          <p>~</p>
                          <input
                            class="form-control"
                            type="number"
                            v-model="conditions[0].ruleValue2"
                            :required="+conditions[0].checked === 1"
                          />
                        </div>
                      </div>
                      <div class="box-group">
                        <div class="col-md-3">
                          <label class="checkbox checkbox-inline">
                            <iCheckCheckBox
                              :propValue="2"
                              :propChecked="conditions[1].checked"
                              v-on:update:checked="conditionChange"
                            />&nbsp;VIP
                          </label>
                        </div>
                        <div class="vip col-md-9">
                          <select
                            class="form-control"
                            v-model="conditions[1].ruleValue1"
                            :required="+conditions[1].checked === 1"
                          >
                            <option value="">請選擇</option>
                            <option value="1">BRONZE</option>
                            <option value="2">SILVER</option>
                            <option value="3">GOLD</option>
                            <option value="4">PLATINUM</option>
                            <option value="5">DIAMOND</option>
                            <option value="6">ROYAL DIAMOND</option>
                            <option value="7">BLACK DIAMOND</option>
                          </select>
                          <p>~</p>
                          <select
                            class="form-control"
                            v-model="conditions[1].ruleValue2"
                            :required="+conditions[1].checked === 1"
                          >
                            <option value="">請選擇</option>
                            <option value="1">BRONZE</option>
                            <option value="2">SILVER</option>
                            <option value="3">GOLD</option>
                            <option value="4">PLATINUM</option>
                            <option value="5">DIAMOND</option>
                            <option value="6">ROYAL DIAMOND</option>
                            <option value="7">BLACK DIAMOND</option>
                          </select>
                        </div>
                      </div>
                      <div class="box-group">
                        <div class="col-md-3">
                          <label class="checkbox checkbox-inline">
                            <iCheckCheckBox
                              :propValue="3"
                              :propChecked="conditions[2].checked"
                              v-on:update:checked="conditionChange"
                            />&nbsp;俱樂部名稱
                          </label>
                        </div>
                        <div class="club-id col-md-9">
                          <input
                            class="form-control"
                            placeholder="輸入俱樂部名稱，中間用逗號分隔"
                            type="text"
                            v-model.trim="groupIds"
                            :required="+conditions[2].checked === 1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 訊息主旨：
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    id="inputTitle"
                    class="form-control"
                    :maxlength="titleMax"
                    :placeholder="`請輸入主旨，上限${titleMax}字`"
                    required
                    v-model.trim="message.messageTitle"
                  />
                  <p class="word-count">
                    {{ message.messageTitle.length }} / {{ titleMax }}
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 訊息內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <textarea
                      cols="100"
                      rows="10"
                      class="form-control"
                      :maxlength="contentMax"
                      :placeholder="`請輸入內容，上限${contentMax}字。`"
                      required
                      v-model="message.messageNote"
                    />
                  </div>
                  <p class="word-count">
                    {{ message.messageNote.length }} / {{ contentMax }}
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 訊息發送時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="startTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="startTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 訊息有效時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="endTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="endTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 保留天數：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    placeholder="保留天數"
                    type="number"
                    min="0"
                    required
                    v-model.number="message.getDayLimit"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="sendAwards">
                  <span class="text-red">*</span> 發送獎勵：
                </label>
                <div class="col-sm-10">
                  <div
                    class="box-group sendAwards"
                    v-for="(item, index) in message.items"
                    :key="index"
                  >
                    <select
                      class="form-control"
                      required
                      v-model="item.prizeTypeNo"
                      @change="mainTypeChange(item)"
                    >
                      <option value="">請選擇類型</option>
                      <option
                        v-for="(mainType, index) in mainTypes"
                        :key="index"
                        :value="mainType.prizeTypeNo"
                      >
                        {{ mainType.prizeTypeName }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      :required="item.subTypes && item.subTypes.length"
                      v-if="item.subTypes && item.subTypes.length"
                      v-model="item.prizeSubType"
                      @change="subTypeChange(item)"
                    >
                      <option value="">請選擇副類別</option>
                      <option
                        v-for="(subType, index) in item.subTypes"
                        :key="index"
                        :value="subType"
                      >
                        {{ subType }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      required
                      v-model="item.prizeItemNo"
                    >
                      <option value="">請選擇道具</option>
                      <option
                        v-for="(detailType, index) in item.detailTypes"
                        :key="index"
                        :value="detailType.prizeItemNo"
                      >
                        {{ detailType.prizeItemName }}
                      </option>
                    </select>
                    <input
                      class="form-control inputNumber"
                      placeholder="請輸入數量"
                      type="number"
                      required
                      v-model.number="item.amount"
                    />
                    <button
                      class="btn btn-danger"
                      type="button"
                      v-show="index !== 0"
                      @click="deleteAwards(item, index)"
                    >
                      刪除
                    </button>
                  </div>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="addAwards()"
                  >
                    <i class="fa fa-plus"></i>&nbsp;新增獎勵
                  </button>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  @click="save(0)"
                  v-if="!message.messageStatus"
                  class="btn btn-warning btn-lg"
                  style="margin-right: 10px"
                  type="submit"
                >
                  儲存
                </button>
                <button
                  @click="save(1)"
                  class="btn btn-primary btn-lg"
                  type="submit"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <fcImportData ref="fcImportData" title="玩家" :checks="checks" />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import fcImportData from "@/views/projects/FC/FcImportData";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton,
    fcImportData,
  },
  props: {
    id: "",
  },
  data() {
    return {
      loading: true,
      titleMax: 30,
      contentMax: 500,
      message: {
        messageTitle: "",
        messageNote: "",
        items: [],
        players: [],
      },
      startTime: new moment().format("YYYY-MM-DD 00:00:00"),
      endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      conditions: [{}, {}, {}],
      mainTypes: [],
      playerIds: [],
      groupIds: [],
      checks: ["玩家UID"],
    };
  },
  computed: {
    ...mapState({
      importList: (state) => state.fcReward.importList,
    }),
  },
  watch: {
    "message.sendType": function (sendType) {
      this.updatePlayerList(sendType);
    },
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").unbind("focus");
    this.getMessage();
  },
  methods: {
    getMessage() {
      this.message.prizeMessageId = this.id;
      if (this.message.prizeMessageId) {
        this.$store.dispatch("fcReward/query", this.message).then((result) => {
          this.message = result;
          this.updateItemList();
        });
      } else {
        this.resetConditions(1);
        this.updateItemList();
      }
    },
    updatePlayerList(sendType) {
      this.resetConditions(sendType);
      const players = this.message.players.filter(
        (x) => x.sendType === sendType
      );
      switch (sendType) {
        case 1: {
          players.forEach((player) => {
            this.playerIds.push(player.playUId);
          });
          break;
        }
        case 2: {
          if (players.length) {
            this.importList.name = players[0].ruleValue1;
          }
          break;
        }
        case 3: {
          players.forEach((player) => {
            Object.assign(this.conditions[player.ruleType - 1], player);
            this.conditions[player.ruleType - 1].checked = true;
            if (player.ruleType === 3) {
              this.groupIds.push(player.ruleValue1);
            }
          });
          break;
        }
      }
    },
    updateItemList() {
      this.updateTime();
      this.getMainTypeList();
      if (!this.message.prizeMessageId && this.message.items.length < 3) {
        for (let i = this.message.items.length; i < 3; i++) {
          this.message.items.push({ prizeTypeNo: "", prizeItemNo: "" });
        }
      }
    },
    updateTime() {
      this.startTime = this.message.sendStartTime || this.startTime;
      this.endTime = this.message.sendEndTime || this.endTime;
      $("#startTimeOnly").val(this.global.getTime(this.startTime));
      $("#endTimeOnly").val(this.global.getTime(this.endTime));
    },
    getMainTypeList() {
      this.$store.dispatch("fcReward/typeQueryAll").then((result) => {
        this.mainTypes = result || [];
        this.message.items.forEach((item) => {
          this.mainTypeChange(item, false);
        });
      });
    },
    getDetailTypeList(item) {
      if (item.prizeTypeNo) {
        this.$store.dispatch("fcReward/typeQueryAll", item).then((result) => {
          item.detailTypes = result.datas || [];
          this.$forceUpdate();
        });
      }
    },
    backList() {
      this.$router.push({
        name: "RewardMessage",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    addAwards() {
      if (this.message.items.length === 12) {
        this.$eventBus.$emit("showAlert", "已達新增上限12筆！");
        return;
      }
      this.message.items.push({ prizeTypeNo: "", prizeItemNo: "" });
    },
    deleteAwards(item, index) {
      if (item.prizeItemId) {
        this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
          if (isOK) {
            this.$store.dispatch("fcReward/itemDelete", item).then(() => {
              this.getMessage();
            });
          }
        });
      } else {
        this.message.items.splice(index, 1);
      }
    },
    submit() {
      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      this.startTime = new Date(
        `${this.startTime.split(" ")[0]} ${startTimeOnly}`
      );
      this.endTime = new Date(`${this.endTime.split(" ")[0]} ${endTimeOnly}`);
      if (!this.message.sendType) {
        this.$eventBus.$emit("showAlert", "請填寫發送名單！");
        return;
      }
      if (this.message.sendType === 2 && !this.importList.name) {
        this.$eventBus.$emit("showAlert", "請選擇指定名單檔案！");
        return;
      }
      if (!this.message.messageNote) {
        this.$eventBus.$emit("showAlert", "請填寫訊息內容！");
        return;
      }
      if (startTimeOnly === "" || !this.global.isValidDay(this.startTime)) {
        this.$eventBus.$emit("showAlert", "請填寫訊息發送時間！");
        return;
      }
      if (!this.global.isValidFutureDay(this.startTime)) {
        this.$eventBus.$emit("showAlert", "「訊息發送時間」請勿填寫過去時間！");
        return;
      }
      if (endTimeOnly === "" || !this.global.isValidDay(this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫訊息有效時間！");
        return;
      }
      if (!this.global.isValidFutureDay(this.endTime)) {
        this.$eventBus.$emit("showAlert", "「訊息有效時間」請勿填寫過去時間！");
        return;
      }
      if (!this.global.isValidDayRange(this.startTime, this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }
      this.savePlayerList();
      const api = this.message.prizeMessageId ? "update" : "create";
      this.message.sendStartTime = new Date(`${this.startTime} UTC`);
      this.message.sendEndTime = new Date(`${this.endTime} UTC`);
      this.$store.dispatch(`fcReward/${api}`, this.message).then(() => {
        this.backList();
      });
    },
    save(messageStatus) {
      this.message.messageStatus = messageStatus;
    },
    savePlayerList() {
      switch (this.message.sendType) {
        case 1: {
          this.message.players = [];
          this.playerIds
            .toString()
            .split(",")
            .forEach((id) => {
              this.message.players.push({ playUId: id.trim() });
            });
          break;
        }
        case 2: {
          this.message.players = [];
          if (this.importList.datas) {
            this.importList.datas.forEach((data) => {
              this.message.players.push({
                playUId: data.玩家UID.toString().trim(),
                ruleValue1: this.importList.name,
              });
            });
          }
          break;
        }
        case 3: {
          this.message.players = [];
          if (this.conditions[0].checked) {
            this.message.players.push(this.conditions[0]);
          }
          if (this.conditions[1].checked) {
            this.message.players.push(this.conditions[1]);
          }
          if (this.conditions[2].checked) {
            this.groupIds
              .toString()
              .split(",")
              .forEach((id) => {
                this.message.players.push({
                  ruleType: this.conditions[2].ruleType,
                  ruleValue1: id.trim(),
                });
              });
          }
          break;
        }
      }
    },
    resetConditions(sendType) {
      this.playerIds = [];
      this.importList.name = "";
      if (this.loading && this.message.sendType === 3) {
        this.conditions[0].ruleValue1 = this.conditions[0].ruleValue1 || "1";
        this.conditions[0].ruleValue2 =
          this.conditions[0].ruleValue2 || "10000";
        this.conditions[1].ruleValue1 = this.conditions[1].ruleValue1 || "";
        this.conditions[1].ruleValue2 = this.conditions[1].ruleValue2 || "";
        this.groupIds = this.groupIds.length ? this.groupIds : [];
        this.loading = false;
      } else {
        for (let i = 0; i < 3; i++) {
          this.conditions[i].checked = undefined;
        }
        if (sendType === 3) {
          this.conditions[0].checked = true;
        } else {
          for (let i = 0; i < 3; i++) {
            this.conditions[i].checked = false;
          }
        }
        this.conditions[0].ruleValue1 = "1";
        this.conditions[0].ruleValue2 = "10000";
        this.conditions[1].ruleValue1 = "";
        this.conditions[1].ruleValue2 = "";
        this.groupIds = [];
        this.loading = false;
      }
    },
    conditionChange(data) {
      const ruleType = data.checked ? data.value : 0;
      this.conditions[data.value - 1].ruleType = ruleType;
      this.conditions[data.value - 1].checked = data.checked;
      this.$forceUpdate();
    },
    mainTypeChange(item, reset = true) {
      if (reset) {
        item.prizeSubType = "";
        item.prizeItemNo = "";
        item.subTypes = [];
        item.detailTypes = [];
      }
      if (item.prizeTypeNo !== "") {
        const mapType = this.mainTypes.find(
          (x) => x.prizeTypeNo === item.prizeTypeNo
        );
        item.subTypes = mapType ? mapType.subTypes : [];
        this.getDetailTypeList(item);
      }
    },
    subTypeChange(item) {
      item.prizeItemNo = "";
      item.detailTypes = [];
      if (item.prizeSubType !== "") {
        this.getDetailTypeList(item);
      }
    },
    importSN() {
      this.$refs.fcImportData.showSerialNumberEdit();
    },
  },
};
</script>

<style>
#RewardMessageEdit .tox-statusbar__wordcount {
  display: none;
}
</style>
