<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動獎項領取歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動管理</li>
        <li class="active">活動獎項領取歷程</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="input-group mr-10">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.startTime" />
              </div>
            </div>

            <div class="input-group mr-10">
              <label class="control-label">結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.endTime" />
              </div>
            </div>

            <div class="select-group">
              <label class="control-label">活動：</label>
              <select
                class="form-control"
                id="selectEvent"
                required="required"
                v-model="searchData.eventId"
                @change="clickSearch"
              >
                <option value="">全部</option>
                <option
                  v-for="(item, index) in eventOptionList"
                  :key="index"
                  :value="item.value"
                >{{item.name}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">獎項：</label>
              <select
                class="form-control"
                id="selectGameType"
                required="required"
                v-model="searchData.gameType"
                @change="clickSearch"
              >
                <option value="">全部</option>
                <option
                  v-for="(item, index) in gameTypeOptionList"
                  :key="index"
                  :value="item.value"
                >{{item.name}}</option>
              </select>
            </div>

            <div class="input-group mr-10">
              <label class="control-label">輸入使用對象：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋使用對象"
                type="text"
                v-model="searchData.identityKey"
              />
            </div>

            <div class="input-group">
              <label class="control-label">輸入序號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋序號"
                type="text"
                v-model="searchData.itemCode"
              />
              <div class="input-group-btn verAlign-bottom">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ redeemCodeRecordList.total }}</span>
                <!-- <span>總筆數：</span>
                <span id="total">{{ itemCodeTotal }}</span> -->
              </p>
            </div>

            <!-- <div class="form-group fr snmMT w100-md mt-15-md">
              <button @click="exportOrders" class="btn btn-success w100-md" type="button">序號匯出</button>
            </div> -->
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">編號</th>
                  <th style="min-width: 90px; width: 10%">活動類型</th>
                  <th style="min-width: 90px; width: 10%">玩家類型</th>
                  <th style="min-width: 90px; width: 10%">完成條件</th>
                  <th style="min-width: 90px; width: 10%">識別值</th>
                  <th style="min-width: 90px; width: 10%">對應新玩家</th>
                  <th style="min-width: 90px; width: 10%">虛寶代號</th>
                  <th style="min-width: 90px; width: 15%">獎項名稱</th>
                  <th class="text-nowrap" style="width: 10%;">建立時間</th>
                </tr>
                <template v-if="redeemCodeRecordList.total > 0">
                  <tr :key="item.id" v-for="(item, index) in redeemCodeRecordList.datas">
                    <td align="center">{{ index+1 }}</td>
                    <td align="center">{{ transferEventName(item.eventId) }}</td>
                    <td align="center">{{ transferPlayerType(item.gameType) }}</td>
                    <td align="center">{{ transferMissionType(item.gameType) }}</td>
                    <td align="center">{{ item.identityKey }}</td>
                    <td align="center">{{ item.corresopndIdentityKey }}</td>
                    <td align="center">{{ item.itemCode }}</td>
                    <td align="center">{{ item.memo }}</td>
                    
                    
                    <td align="center">{{ FormatTime(item.createTime) }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="9">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="redeemCodeRecordList.total > 0">
          <Pagination
            ref="pagination"
            :total="redeemCodeRecordList.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import DatePicker from "@/components/DatePicker";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import eventInfo from '@/static/json/eventOption.json';

export default {
  components: {
    Pagination,
    DateRangePicker,
    DatePicker: DatePicker
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      itemCodeTotal: 0,
      eventOptionList: [],
      gameTypeOptionList: [],
      searchData: {
        projectId: this.$store.state.projectId,
        eventId: "20004",
        identityKey: "",
        gameType: "",
        itemCode: "",
        startTime: new moment(),
        endTime: new moment(),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      eventList: [],
      selectedEventInfo: []
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getEventList();
    this.getGameTypeList();
    this.getRedeemCodeRecordList();
  },
  mounted() {},
  computed: {
    ...mapState({
      redeemCodeRecordList: state => state.event.getRedeemCodeRecordList,
      itemCodeList: state => state.event.getItemCodeList,
      response: state => state.event.response
    })
  },
  watch: {
    redeemCodeRecordList() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.redeemCodeRecordList.total
      );
    },
    itemCodeList() {
      this.itemCodeTotal = this.itemCodeList.total;
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getGameTypeList();
      this.getRedeemCodeRecordList();
      this.getItemCodeList();
    },

    getRedeemCodeRecordList() {
      //列表
      if (this.searchData.startTime != "")
        this.searchData.startTime = new moment(
          this.searchData.startTime
        ).format("YYYY/MM/DD 00:00:00");
      if (this.searchData.endTime != "")
        this.searchData.endTime = new moment(
          this.searchData.endTime
        ).format("YYYY/MM/DD 23:59:59");
      // this.searchData.eventId = parseInt(this.searchData.eventId);
      this.$store.dispatch("event/getRedeemCodeRecordList", this.searchData);

      // if (this.$route.params.searchData != undefined)
      //   this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    getEventList() {
      var id = this.searchData.projectId;
      var index = eventInfo.findIndex(function(obj){
        return obj.projectId == id;
      });
      var eventOptions = [];
      if (index != -1){
        this.eventList = eventInfo[index].events;
        //取得活動列表
        $.each(this.eventList, function(idx, val){
          eventOptions.push({ name: val.name, value: val.id});
        });
        this.eventOptionList = eventOptions;
      }
    },

    getGameTypeList(){
      var eventId = this.searchData.eventId;
      var eventInfo = [];
      // 用選取活動id找出對應獎項
      $.each(this.eventList, function(idx, val){
        if (eventId == val.id){
          eventInfo = val;
        }
      });

      this.selectedEventInfo = eventInfo;
      // 有資料才取獎項
      if (eventInfo && eventInfo.awardOptionList){
        this.gameTypeOptionList = this.selectedEventInfo.awardOptionList;
        var gameType = this.searchData.gameType;
        
        var index = eventInfo.awardOptionList.findIndex(function(obj){
          return obj.value == gameType;
        });
        // 獎項id不再獎項列表中則將獎項改為預設值
        if (index == -1) this.searchData.gameType = "";
      }else{
        this.searchData.gameType = "";
        this.gameTypeOptionList = [];
      }
    },

    getItemCodeList() {
      //活動序號列表
      var gameTypeCode = (this.searchData.gameType != "") ? parseInt(this.searchData.gameType) : -1;
      var eventId = (this.searchData.eventId != "") ? parseInt(this.searchData.eventId) : -1;
      this.$store.dispatch("event/getItemCodeList", {
        活動編號: eventId,
        獎項代號: gameTypeCode
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getEventList();
      this.getGameTypeList();
      this.getRedeemCodeRecordList();
      this.getItemCodeList();
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return utils.formatTime(time, "YYYY/MM/DD hh:ss:mm");
    },

    exportOrders() {
      this.searchData.isUsed = parseInt(this.searchData.isUsed);
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("exchangeCode/exchangeCodeExport", tmpSearchData);
    },

    // 轉換活動名稱
    transferEventName(eventId) {
      if (this.selectedEventInfo && this.selectedEventInfo.name){
        return this.selectedEventInfo.name;
      }
      return eventId;
    },

    // 轉換任務完成條件
    transferMissionType(gameType) {
      if (this.selectedEventInfo && this.selectedEventInfo.missionOptionList){
        var index = this.selectedEventInfo.missionOptionList.findIndex(function(obj){
          return obj.value == gameType;
        });
        if (index != -1){
          return this.selectedEventInfo.missionOptionList[index].name;
        }
      }
      return gameType;
    },

    // 轉換玩家類型
    transferPlayerType(gameType) {
      if (this.selectedEventInfo && this.selectedEventInfo.playerTypeOptionList){
        var index = this.selectedEventInfo.playerTypeOptionList.findIndex(function(obj){
          return obj.value == gameType;
        });
        if (index != -1){
          return this.selectedEventInfo.playerTypeOptionList[index].name;
        }
      }
      return gameType;
    }
  }
};
</script>

<style scoped>
.mr-10 {
  margin-right: 10px;
}

.fl {
  float: left;
}
</style>