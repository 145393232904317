const TransEnumMixins = {
    filters: {
        TransEnum(v, list) {
            let keys = Object.keys(list);
            for (let key of keys) {
                if (list[key] == v) {
                    return key;
                }
            }
            return "";
        }
    }
}
export default TransEnumMixins;