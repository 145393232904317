<template>
  <div id="ClassTypeManager">
    <section class="content-header">
      <h1>伺服器狀態</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">伺服器狀態</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="queryAll()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa"></i> 刷新狀態
            </button>
          </div>
          <div class="table-responsive mt-10" v-for="(item,key,index) in datas" :key="index">
            <p>{{key}}</p>
            <table class="table table-bordered classTypeTable" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px;" v-for="(item2,key2,index2) in item.header" :key="index2" >{{item2}}</th>
                </tr>
                <tr v-for="(item3,key3,index3) in item.datas" :key="index3">
                  <td v-for="(item4,key4,index4) in item3" :key="index4">{{ item3[key4] }}</td>
                </tr>
              </tbody>
            </table>
            </br>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      datas: {}
    };
  },
  created() {
    this.queryAll();
  },
  mounted() {
      
  },
  methods: {
    // 顯示列表
    async queryAll() {
        try {
            this.datas = [];
            var res = await api.asgard.checkServerStatus();
            res = res.data;
            if( res.status != "1"){
                this.$eventBus.$emit("showAlert", res.message);
                return;
            }

            this.datas = JSON.parse(res.result);
            // var infos = res.result.split(",")
            // for(var i = 0; i < infos.length; i+=3){
            //     if( infos[i] == "" ) continue;
            //     this.datas.push({
            //         name : infos[i],
            //         status : infos[i+1],
            //         playerCount : infos[i+2],
            //     });
            // }
        } catch (error) {
            this.$eventBus.$emit("showAlert", error);
        }
    },
  }
};
</script>