<template>
  <div id="ContactUsList">
    <section class="content-header">
      <h1>聯絡我們列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">聯絡我們列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group mr-20">
              <label class="control-label">聯絡門號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋門號"
                type="text"
                v-model="searchData.phoneNumber"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">真實姓名：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋真實姓名"
                type="text"
                v-model="searchData.Name"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">填單開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.startTime" />
              </div>
            </div>

            <div class="input-group">
              <label class="control-label">填單結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.endTime" />
              </div>
            </div>

            <div class="text mt-15">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ contactUs.total }}</span>
              </p>
            </div>

            <div class="form-group fl mr-20 snmMT w100-md mt-15-md">
              <button @click="clickSearch" class="btn btn-warning w100-md" type="button">查詢</button>
            </div>

            <div class="form-group fl mr-20 snmMT w100-md mt-15-md">
              <button @click="clearBtn" class="btn btn-danger w100-md" type="button">清除</button>
            </div>

            <div class="form-group fl snmMT w100-md mt-15-md">
              <button
                @click="exportOrders"
                class="btn btn-success w100-md"
                type="button"
                v-if="contactUs.total > 0"
              >匯出</button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 1%">編號</th>
                  <th class="text-nowrap" style="width: 5%;">填單時間</th>
                  <th class="text-nowrap" style="width: 5%;">真實姓名</th>
                  <th class="text-nowrap" style="width: 8%;">公司名稱</th>
                  <th class="text-nowrap" style="width: 10%;">Email</th>
                  <th class="text-nowrap" style="width: 5%;">連絡電話</th>
                  <th class="text-nowrap" style="width: 10%;">主旨</th>
                  <th class="text-nowrap" style="width: 2%;">狀態</th>
                  <th class="text-nowrap" style="width: 3%;">處理人員</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="contactUs.total > 0">
                  <tr :key="item.id" v-for="(item, index) in contactUs.datas">
                    <td align="center">{{ index+1 }}</td>
                    <td align="center">{{ item.createTime }}</td>
                    <td align="center">{{ item.name }}</td>
                    <td align="center">{{ item.companyName }}</td>
                    <td align="center">{{ item.email }}</td>
                    <td align="center">{{ item.phoneNumber }}</td>
                    <td>{{ item.subject }}</td>
                    <td align="center">{{ GetStatus(item.status)}}</td>
                    <td align="center">{{ item.updateUName }}</td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">編輯</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="contactUs.total > 0">
          <Pagination
            ref="pagination"
            :total="contactUs.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import DatePicker from "@/components/DatePicker";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
    DateRangePicker,
    DatePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        // projectId: this.$store.state.projectId,
        name: "",
        phoneNumber: "",
        status: -1,
        startTime: new moment(),
        endTime: new moment(),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      contactUs: (state) => state.contactUs.queryAll,
      response: (state) => state.contactUs.response,
    }),
  },
  watch: {
    contactUs() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.contactUs.total
      );
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetQueryAll();
    },

    GetQueryAll() {
      //列表
      if (this.searchData.startTime != "")
        this.searchData.startTime = new moment(this.searchData.startTime).format(
          "YYYY/MM/DD 00:00:00"
        );
      if (this.searchData.endTime != "")
        this.searchData.endTime = new moment(this.searchData.endTime).format(
          "YYYY/MM/DD 23:59:59"
        );
      this.$store.dispatch("contactUs/contactUsQueryAll", this.searchData);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetQueryAll();
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "XinContactUsEdit",
        params: {
          id: item.contactUsId,
          searchData: this.searchData,
        },
      });
      return;
    },
    clearBtn() {
      this.searchData = {
        name: "",
        phoneNumber: "",
        status: -1,
        startTime: new moment(),
        endTime: new moment(),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      };
    },

    GetStatus(status) {
      switch (status) {
        case 0:
          return "待處理";
        case 1:
          return "已處理";
      }
    },
    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("contactUs/contactUsExport", tmpSearchData);
    },
  },
};
</script>

<style scoped>
.mr-20 {
  margin-right: 20px;
}

.fl {
  float: left;
}
</style>
