<template>
  <div class="col-md-10">
    <date-picker
      v-model="date"
      :config="options"
      @dp-hide="showDatePickResult"
    />
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import $ from "jquery";

export default {
  props: {
    propDateTime: "",
  },
  data() {
    return {
      date: "",
      //options params url:https://getdatepicker.com/4/Options/#format
      options: {
        format: "YYYY/MM/DD HH:mm:ss",
        showTodayButton: true,
        showClear: true,
        useCurrent: false,
        sideBySide: true,
        locale: "zh-tw",
        tooltips: {
          selectTime: "",
        },
      },
    };
  },
  watch: {
    propDateTime() {
      this.date = this.propDateTime;
    },
  },
  methods: {
    showDatePickResult: function () {
      console.log(this.date);
      this.$emit("update:propDateTime", this.date);
    },
  },
  components: {
    datePicker,
  },
  created: function () {
    $.extend(true, $.fn.datetimepicker.defaults, {
      icons: {
        time: "far fa-clock",
        close: "far fa-times-circle",
      },
    });
  },
};
</script>