<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('社群管理') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('社群管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-bordered classTypeTable" style="width: 60%;">
                            <tbody>
                                <tr class="bg-gray">
                                    <th>{{ $t('社群') }}</th>
                                    <th class="text-nowrap text-center">{{ $t('功能') }}</th>
                                </tr>
                                <tr v-for="(item, key) of PlatformType" :key="key">
                                    <td>{{ $t(`PlatformType-${item.Type}`) }}</td>
                                    <td><button @click="詳細(key)" class="btn btn-primary">{{ $t('詳細') }}</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { PlatformType } from "@/store/MessageSystem/messageSystemConst";
export default {
    data() {
        return {
            PlatformType: PlatformType
        };
    },
    mounted() {
    },
    computed: {
    },
    watch: {

    },
    methods: {
        詳細(PlatformType) {
            this.$router.push({
                name: "MessageSocialDetailManager",
                params: {
                    PlatformType
                },
            });
        }
    }
}
</script>