<template>
  <div class="modal fade" ref="popForm">
    <div class="modal-dialog complete_style">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ $t('分類和完成事件') }}</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>{{ $t('提示訊息1') }}</label>
            <br />
            <label>{{ $t('事件原因') }}：</label>
            <select v-model="eventType">
              <option value="-1">{{ $t('請選擇事件原因') }}</option>
              <option v-for="item of 固定事件類型" :value="item.id" :key="item.id">{{ $t(item.name) }}</option>
              <!-- <option value="Game">遊戲機制</option>
              <option value="Credit">點數儲值</option>
              <option value="Event">活動相關</option>
              <option value="Account">帳號相關</option>
              <option value="Record">紀錄查詢</option>
              <option value="Report">異常回報</option>
              <option value="Violate">違規檢舉</option>
              <option value="Complain">抱怨謾罵</option>
              <option value="Ambiguous">問題不清楚</option>
              <option value="Other">其他</option> -->
            </select>
            <textarea class="form-control" rows="3" :placeholder="$t('新增此次對話備註')" v-model="remark"></textarea>
          </div>
        </div>
        <div class="footer">
          <button class="close" data-dismiss="modal">{{ $t('取消') }}</button>
          <div>
            <button class="btn savebtn" v-if="是否出現儲存草稿" @click="save()">{{ $t('儲存草稿') }}</button>
            <button class="btn sendbtn" @click="send()">{{ $t('完成') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters, mapState } from "vuex";
import {
  ProcessStatus,
} from "@/store/MessageSystem/messageSystemConst";

export default {
  data() {
    return {
      remark: "",
      eventType: -1,
      是否出現儲存草稿: false,
    };
  },
  computed: {
    ...mapGetters({
      固定事件類型: 'messageSystemServer/固定事件類型',
      線上玩家列表: 'messageSystemServer/計算玩家列表',
    }),
    ...mapState({
      CSLoginUser: state => state.messageSystemServer.CSLoginUser,
      分類EventId: state => state.messageSystemServer.分類EventId,
    }),
  },
  methods: {
    get分類Event() {
      if (!this.線上玩家列表) {
        return null;
      }
      let 事件 = this.線上玩家列表.find((事件) => { return 事件.EventId == this.分類EventId });
      if (!事件) {
        return null;
      }
      if (!事件.聊天視窗_分類和完成事件) {
        return null;
      }
      return 事件;
    },
    save() {
      let 分類Event = this.get分類Event();
      if (!this.是否能儲存草稿(分類Event)) {
        $(this.$refs.popForm).modal("hide");
        this.$eventBus.$emit("showAlert", this.$t('執行失敗'));
        return;
      }
      if (this.eventType == -1) {
        this.$eventBus.$emit("showAlert", this.$t('請選擇事件原因'));
        return;
      }
      $(this.$refs.popForm).modal("hide");
      this.$emit("ResetEventType", 分類Event.EventId, this.eventType, this.remark);
    },
    send() {
      let 分類Event = this.get分類Event();
      if (!分類Event) {
        $(this.$refs.popForm).modal("hide");
        this.$eventBus.$emit("showAlert", this.$t('執行失敗'));
        return;
      }
      if (this.eventType == -1) {
        this.$eventBus.$emit("showAlert", this.$t('請選擇事件原因'));
        return;
      }
      if (分類Event.ProcessStatus == ProcessStatus.Processing.value) {
        this.$emit("FinishService", 分類Event.EventId, this.eventType, this.remark);
      } else {
        this.$emit("ResetEventType", 分類Event.EventId, this.eventType, this.remark);
      }
      $(this.$refs.popForm).modal("hide");
    },
    是否能儲存草稿(分類Event) {
      return 分類Event && (分類Event.ProcessStatus == ProcessStatus.Processing.value) ? true : false;
    },
    show(EventType, Remark) {
      $(this.$refs.popForm).modal("show");
      this.eventType = !EventType ? -1 : EventType;
      this.remark = Remark;
      let 分類Event = this.get分類Event();
      this.是否出現儲存草稿 = this.是否能儲存草稿(分類Event);
    },
    hide() {
      $(this.$refs.popForm).modal("hide");
    }
  }
};
</script>
<style scoped>
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 60px;
}
</style>