<template>
  <!-- 新增/修改群組 -->
  <div class="modal fade" id="addMute" ref="add_mute">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">修改群組</h4>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="classTypeId">
                <span class="text-red">*</span> 群組名稱：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="classTypeId"
                  required="required"
                  type="text"
                  v-model="classTypeId"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-3 control-label" for="description"
                >群組說明：</label
              >
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="description"
                  type="text"
                  v-model="description"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-3 control-label" for="description"
                >訂閱類型：</label
              >
              <div class="col-sm-9">
                <label class="checkbox-inline">
                  <input type="checkbox" id="description1" value="option1" />
                  生活
                </label>
                <label class="checkbox-inline">
                  <input type="checkbox" id="description2" value="option2" />
                  政治
                </label>
                <label class="checkbox-inline">
                  <input type="checkbox" id="description3" value="option3" />
                  美妝
                </label>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-2 control-label">
                <span class="text-red">*</span> 發佈：
              </label>
              <div class="col-sm-10">
                <div class="radio radio-inline">
                  <iCheckRedioButton
                    checkValue="0"
                    message="上架"
                    name="radioMode"
                    v-model="edit.isStop"
                  />
                </div>
                <div class="radio radio-inline">
                  <iCheckRedioButton
                    checkValue="1"
                    message="下架"
                    name="radioMode"
                    v-model="edit.isStop"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button @click="submitGroup()" class="btn btn-primary" type="button">
            新增
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
// import iCheckCheckBox from "@/components/iCheck_CheckBox";
// import iCheckRedioButton from "@/components/iCheckRedioButton";

export default {
  components: {},
  data() {
    return {
      isNewAdd: false,
      edit: {
        classTypeId: "", //分類Id
        bannerTitle: "", // 名稱
        types: ["全部", "政治", "生活", "時尚"],
        isStop: 0, // 狀態
      },
      classTypeId: "",
      description: "",
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    showAddMuteBox() {
      this.classTypeId = "";
      this.muteDay = "";
      this.muteNote = "";
      $(this.$refs.add_mute).modal("show");
    },
    showSuccess(content) {
      if (content.status == "1") {
        $(this.$refs.add_mute).modal("hide");

        // 新增
        if (this.isNewAdd) {
          this.$eventBus.$emit("showAlert", "新增成功");
          this.isNewAdd = false;
        } else {
          this.$eventBus.$emit("showAlert", "更新成功");
        }
        this.$emit("restList");
      } else {
        if (content.errorMsg == "") {
          // 新增
          if (this.isNewAdd) {
            this.$eventBus.$emit("showAlert", "新增失敗!!");
            this.isNewAdd = false;
          } else {
            this.$eventBus.$emit("showAlert", "更新失敗!!");
          }
        } else {
          this.$eventBus.$emit("showAlert", content.errorMsg);
        }
      }
    },
    submitGroup() {
      if (this.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請輸入門號");
        return;
      }
    },
    checkID() {
      let date = {
        classTypeId: this.classTypeId,
      };
      this.$store.dispatch("qppMute/query", date);
    },

    addMute() {
      this.isNewAdd = true;
      if (this.muteDay == "") this.muteDay = 0;
      this.$store.dispatch("qppMute/create", [
        {
          opreateId: this.editorName,
          classTypeId: this.classTypeId,
          muteReason: this.muteReason,
          note: this.muteNote,
          dateNumber: this.muteDay,
        },
      ]);
    },
  },
};
</script>
