import api from "@/assets/js/app-api";

export const ConstOneClickType = [
    { 類別: 1, 名稱: "產品" },
    { 類別: 2, 名稱: "介接廠商" },
];
export const 分類Enum = {
    品項: 0,
    金流: 1,
}

export const ERP庫別Enum = {
    A13非庫存倉: "A13",
    A1301向序倉: "A1301",
    A01台中倉: "A01",
    ["A02台北倉(遊E卡)"]: "A02",
    A03哲元倉: "A03",
    A07星城倉: "A07",
    ["A08台中倉(28FA5)"]: "A08",
    A20美卡倉: "A20",
    A021台北倉_虛擬: "A021"
}

const state = {
    products: {},//key值為類別
    // {
    //     "1": [
    //         {
    //             "編號": 1,
    //             "類別": 1,
    //             "資料": "星城123"
    //         }
    //     ],
    //     "2": [
    //         {
    //             "編號": 4,
    //             "類別": 2,
    //             "資料": "yoe2"
    //         }
    //     ]
    // }
}

// getters
const getters = {
}

// actions
const actions = {
    async GetProductCommonData({ commit, state }, { 類別 }) {
        let res = await api.IPSSetting.GetProductCommonData({ 類別 });
        if (res && res.data && res.data.sucess) {
            this._vm.$set(state.products, 類別, res.data.message);
            commit('setProducts', state.products);
        } else {
            let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
            this._vm.$eventBus.$emit("showAlert", errMsg);
        }
    },
}

// mutations
const mutations = {
    setProducts(state, data) {
        state.products = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}