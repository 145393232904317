<template>
  <div id="BonusAndRefundEdit">
    <section class="content-header">
      <h1>配點紀錄編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>配點紀錄列表</li>
        <li class="active">配點紀錄編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 遊戲帳號：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.molokey"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <!--<span class="text-red">*</span>-->
                  配發類型：
                </label>
                <div class="col-sm-8">
                  <select class="form-control" v-model="edit.activityCode">
                    <option value="0">補點</option>
                    <option value="1">扣點</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 配發金額：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control amount"
                    id="inputTitle"
                    required="required"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 配發原因：
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    id="inputTitle"
                    v-model="edit.note"
                  >
                  </textarea>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
// import iCheckCheckBox from "@/components/iCheck_CheckBox";
// import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
// import DatePicker from "@/components/DatePicker";
// import DateRangePicker from "@/components/DateRangePicker";
// import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // iCheckCheckBox,
    // iCheckRedioButton: iCheckRedioButton,
    // DatePicker,
    // DateRangePicker
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
    //   isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      edit: {
        molokey: "", //遊戲帳號
        activityCode: 0, //配發類型
        pointAmount: 0, // 配發金額
        note: "" //配發原因
      },
      disableTime: false
    };
  },
  created() {},
  mounted() {
    // if (this.$route.params.id > 0) {
    //   this.isEdit = true;
    //   this.GetActivity();
    // }
  },
  computed: {
    ...mapState({
    //   bonusAndRefund: state => state.bonusAndRefund.queryAll,
      response: state => state.bonusAndRefund.response
    })
  },
  watch: {
    // activity() {
    //   this.activity.startTime = new moment(this.activity.startTime).format(
    //     "YYYY-MM-DD"
    //   );
    //   this.activity.endTime = new moment(this.activity.endTime).format(
    //     "YYYY-MM-DD"
    //   );
    //   this.edit = this.activity;
    //   this.disableTime = this.checkTime();
    // },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    }
  },
  methods: {
    //取得該廣告資料
    // GetActivity() {
    //   this.$store.dispatch("bonusAndRefund/bonusAndRefundQueryAll", {
    //     activityId: this.$route.params.id
    //   });
    // },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "BonusAndRefundList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    // checkTime() {
    //   return this.isEdit && new moment(new Date()).isAfter(this.edit.startTime);
    // },

    //送出
    async Save() {
    //   this.edit.isStop = parseInt(this.edit.isStop);

      if (this.edit.molokey == "") {
        this.$eventBus.$emit("showAlert", "遊戲帳號不能為空");
        return;
      }

    //   this.edit.activityCode = this.edit.activityCode.toUpperCase();
      var amount = $(".amount").val();
      if (amount == 0) {
        this.$eventBus.$emit("showAlert", "配發金額不能為0");
        return;
      }else{
        // 補點數字為正、扣點數字為負
        
        this.edit.pointAmount = (this.edit.activityCode == 0) ? Math.abs(amount) : -Math.abs(amount);
      }

      if (this.edit.note == "") {
        this.$eventBus.$emit("showAlert", "配發原因不可為空");
        return;
      }

    //   this.edit.startTime = new Date(this.edit.startTime + " UTC");
    //   this.edit.endTime = new Date(this.edit.endTime + " UTC");

      let newItem = utils.deepCloneObj(this.edit);

    //   if (this.isEdit) {
    //     this.$store.dispatch("activity/activityUpdate", newItem);
    //   } else {
      this.$store.dispatch("bonusAndRefund/bonusAndRefundCreate", newItem);
    //   }
    }
  }
};
</script>