<template>
    <div>
        <div class="btn btn-primary">
            <span @click="addLimit()">新增限制</span>
        </div>
        <table border="1">
            <tr>
                <td>限制主項目</td>
                <td>每月上限</td>
                <td>每周上限</td>
                <td>每日上限</td>
                <td>單筆上限</td>
                <td>單筆下限</td>
            </tr>

            <tr v-for="item of test">
                <!-- <td>{{ item }}</td> -->
                <td>{{ item.限制主項目 }}</td>
                <td>{{ item.每月上限 }}</td>
                <td>{{ item.每周上限 }}</td>
                <td>{{ item.每日上限 }}</td>
                <td>{{ item.單筆上限 }}</td>
                <td>{{ item.單筆下限 }}</td>
                <!-- , "每月上限": -1, "每周上限": -1, "每日上限": "300.1234", "單筆上限": -1, "單筆下限": -1 -->
            </tr>
        </table>
        <br>
        <div class="box box-success mybox" v-for="edit in 額外限制List" :key="edit.編號">
            <div class="box-header with-border" style="background: #f4f4f4;">
                <h3 class="box-title">
                    <input type="text" class="form-control form-control2" placeholder="請輸入限制說明" v-model.trim="edit.限制主項目" />
                    <select v-model="edit.限制主條件" @change="reset限制(edit)" class="form-control form-control2">
                        <option :value="-1">請選擇限制主條件 </option>
                        <option v-for="item in 金額限制項目" :key="item.編號" :value="item.編號">
                            {{ item.名稱 }}
                        </option>
                    </select>
                    <br>
                </h3>
                <div class="box-tools pull-right">
                    <div class="btn btn-danger">
                        <span @click="delLimit(edit)"><i class="fa fa-times"></i>移除限制</span>
                    </div>
                </div>
            </div>
            <div class="box-body">
                <template v-if="edit.限制主條件 == 1">
                    <div v-for="item in edit.限制細項" :key="item.編號">
                        <span v-if="item.是否已設">
                            {{ item.描述 }}：<input type="text" v-model="item.參數" class="form-control form-control2" />
                            <span class="img-close fa fa-fw fa-close" @click="del(edit.限制細項, item)"></span>
                            <span class="red">{{ item.限制細項Tip }}</span>
                        </span>
                        <span v-else>
                            {{ item.描述 }}：
                            <button type="button" style="width:214px;" class="btn btn-gray" @click="add(edit.限制細項, item)">
                                設定</button>
                        </span>
                    </div>
                </template>
                <template v-else-if="edit.限制主條件 == 2">
                    <select v-model="edit.判斷主條件" class="form-control form-control2" style="width:132px;">
                        <option v-for="item in 條件式" :key="item.編號" :value="item.編號">{{ item.描述 }}</option>
                    </select>
                    <input v-model.trim="edit.限制主參數" class="form-control form-control2" />
                    <div v-for="item in edit.限制細項" :key="item.編號">
                        <span v-if="item.是否已設">
                            {{ item.描述 }}：<input type="text" v-model="item.參數" class="form-control form-control2" />
                            <span class="img-close fa fa-fw fa-close" @click="del(edit.限制細項, item)"></span>
                            <span class="red">{{ item.限制細項Tip }}</span>
                        </span>
                        <span v-else>
                            {{ item.描述 }}：
                            <button type="button" style="width:214px;" class="btn btn-gray" @click="add(edit.限制細項, item)">
                                設定</button>
                        </span>
                    </div>
                </template>
                <template v-else>
                    請選擇限制主條件
                </template>
            </div>
        </div>
        <div>
            <div class="red" v-for="log of logs.error">{{ log }}</div>
            <div v-for="log of logs.warning">{{ log }}</div>
        </div>
    </div>
</template>  
<script>
import { mapState } from "vuex";
import { Limit } from "./Limit";
export default {
    // components: { LimitBody1, LimitBody2 },
    props: ["額外限制List"],
    data() {
        return {
            //限制細項顯示Tip
            // 限制細項Tip: {
            // 限制流水號: {
            //     限制細項編號: "1~1000"
            // }
            // 例如：新增的第一筆每日上限範圍為10~500                
            // -1: {
            //     3: "10~500"
            // }
            // },
            logs: {
                warning: [],
                error: []
            },
            上層限制集合: [],
            test: [],
        };
    },
    computed: {
        ...mapState({
            額外限制列表: state => state.IPSSetting.基礎設定檔.額外限制列表,
            金額限制項目: state => state.IPSSetting.基礎設定檔.金額限制項目,
            條件式: state => state.IPSSetting.基礎設定檔.條件式,
        }),
    },
    watch: {
        額外限制List: {
            handler(newData) {
                let errorList = Limit.Check(newData, this.上層限制集合);
                this.logs = { ...errorList.getLogs() };
                let tmp = errorList.getTest();
                this.test = [];
                this.test.push(tmp.s1);
                this.test.push(tmp.s2);
                this.test.push(tmp.s3);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        set上層限制集合(v) {
            this.上層限制集合 = v;
            let errorList = Limit.Check(this.額外限制List, this.上層限制集合);
            this.logs = { ...errorList.getLogs() };
            let tmp = errorList.getTest();
            this.test = [];
            this.test.push(tmp.s1);
            this.test.push(tmp.s2);
            this.test.push(tmp.s3);
        },
        get限制細項Tip(限制主條件, 判斷主條件, 限制主參數, 限制細項) {
            return "";
        },
        reset限制(edit) {
            switch (edit.限制主條件) {
                case 1: {
                    edit.判斷主條件 = "";
                    edit.限制主參數 = "";
                    edit.限制細項 = [];

                    for (let item of this.額外限制列表) {
                        edit.限制細項.push({
                            編號: item.編號,
                            描述: item.名稱,
                            參數: -1,
                            是否已設: false,
                            限制細項Tip: ""
                        })
                    }
                    break;
                }
                case 2: {
                    edit.判斷主條件 = 1;
                    edit.限制主參數 = "";
                    edit.限制細項 = [];
                    for (let item of this.額外限制列表) {
                        edit.限制細項.push({
                            編號: item.編號,
                            描述: item.名稱,
                            參數: -1,
                            是否已設: false,
                            限制細項Tip: ""
                        })
                    }
                    break;
                }
                default: {
                    edit.限制主條件 = -1;
                    edit.判斷主條件 = "";
                    edit.限制主參數 = "";
                    edit.限制細項 = [];
                }
            }
        },
        delLimit(find) {
            let findIndex = this.額外限制List.findIndex((item) => {
                return item.編號 == find.編號;
            })
            if (findIndex >= 0) {
                this.額外限制List.splice(findIndex, 1);
            }
        },
        addLimit() {
            let min = 0;
            this.額外限制List.forEach((item) => {
                if (item.編號 < min) {
                    min = item.編號;
                }
            });
            min = min - 1;
            this.額外限制List.push({
                編號: min,
                限制主項目: "",
                限制主條件: -1,
                判斷主條件: "",
                限制主參數: "",
                限制細項: []
            });
        },
        Check(額外限制List, 上層限制集合 = []) {
            let errorList = Limit.Check(額外限制List, 上層限制集合);
            return errorList.getCanSend();
        },
        async Save(舊的額外限制編號List, 額外限制List) {
            if (!舊的額外限制編號List || 舊的額外限制編號List == "-") {
                舊的額外限制編號List = [];
            } else {
                let tmp = 舊的額外限制編號List.split(",");
                舊的額外限制編號List = [];
                for (let i of tmp) {
                    if (parseInt(i) > 0) {
                        舊的額外限制編號List.push(i);
                    }
                }
            }
            if (額外限制List == "-") {
                額外限制List = [];
            }
            let 新的額外限制編號List = [];
            for (let data of 額外限制List) {
                if (data.編號 > 0) {
                    //修改 
                    console.error("修改:" + data.編號);
                    let send = this.處理Send資料(data);
                    send.編號 = data.編號;
                    await this.$store.dispatch("IPSSetting/額外限制_修改", [send]);
                    舊的額外限制編號List = 舊的額外限制編號List.filter((item) => {
                        return item != data.編號;
                    });
                    新的額外限制編號List.push(data.編號);
                } else {
                    //新增
                    delete data.編號;
                    console.error("新增:" + data.編號, data);
                    let send = this.處理Send資料(data);
                    let res = await this.$store.dispatch("IPSSetting/額外限制_新增", [send]);
                    新的額外限制編號List.push(res.編號);
                }
            }
            for (let i of 舊的額外限制編號List) {
                //刪除
                console.error("刪除:" + i);
                await this.$store.dispatch("IPSSetting/額外限制_刪除", { "編號": i });
            }
            return (新的額外限制編號List.length > 0 ? 新的額外限制編號List.join(",") : "-");
        },
        處理Send資料(data) {
            let 限制細項 = [];
            for (let 細項 of data.限制細項) {
                if (細項.是否已設) {
                    限制細項.push(
                        { 編號: 細項.編號, 描述: 細項.描述, 參數: 細項.參數 }
                    );
                }
            }
            let send = {
                判斷主條件: data.判斷主條件,
                限制主參數: data.限制主參數,
                限制主條件: data.限制主條件,
                限制主項目: data.限制主項目,
                限制細項: JSON.stringify(限制細項)
            }
            return send;
        },
        add(list, { 編號, 描述, 參數 }) {
            let find = list.find((item) => { return item.編號 == 編號 })
            find.參數 = 參數;
            find.是否已設 = true;
        },
        // upd(list,{ 編號, 參數 }) {
        //   let find = list.find((item) => { return item.編號 == 編號 })
        //   find.參數 = 參數;
        // },
        del(list, { 編號, 描述, 參數 }) {
            let find = list.find((item) => { return item.編號 == 編號 })
            find.參數 = 參數;
            find.是否已設 = false;
        }
    },
};
</script>
<style scoped>
.mybox {
    border-top-color: #f4f4f4 !important;
}

.form-control2 {
    display: inline;
    width: auto;
}

.red {
    color: red;
}

.box-header>.box-tools {
    margin: 0px;
}
</style>