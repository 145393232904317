<template>
  <FormDialog ref="memberCoinDialog" :title="$t('星幣異動')"
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr v-if="isAddCoin !== null">
            <th><span :class="isAddCoin ? 'text-green' : 'text-red'">{{ $t(isAddCoin ? '增加' : '扣除') }}</span> {{$t('星幣')}}</th>
            <td><input type="number" v-model.number="data.星幣" class="form-control" min="0" :max="isAddCoin == false ? player.xinvalues : false" required></td>
          </tr>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-model="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{$t('內容')}}</th>
            <td><textarea v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  props:[
    'types'
  ],
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        玩家識別值: "",
        星幣: "",
        內容: "",
        分類: ""
      },
      player: null,
      isAddCoin: true,
    };
  },
  mounted(){
  },
  methods: {
    async show(player, isAddCoin) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player;
      this.isAddCoin = isAddCoin;
      this.data.玩家識別值 = player.暱稱;
      this.$refs.memberCoinDialog.show();
    },
    submit(){
      this.$store.dispatch("xsw/會員星幣異動", this.data).then(() => {
        this.$refs.memberCoinDialog.hide();
        this.$eventBus.$emit("showAlert", "更新成功");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
