<template>
	<div id="gameContent" class="slot max1040">
		<section class="content-header">
			<h1>遊戲內容</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li>遊戲列表</li>
				<li class="active">遊戲內容</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
          <h4 id="title">{{ $route.params.name }} 內容說明</h4>
					<div class="top-box with-border">
            <button @click="backList()" class="btn btn-block btn-default btn-add" type="button">
              返回
            </button>
            <button @click="add()" class="btn btn-block btn-warning btn-add mr-10" type="button">
							新增
						</button>
            <div class="text">
							<p class="data-num">
								<span>資料筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th class="text-nowrap text-center" style="min-width: 150px; width: 25%;" >名稱</th>
									<th class="text-nowrap text-center" style="width: 170px; width: 10%;">建立時間</th>
									<th class="text-nowrap text-center" style="width: 170px; width: 10%;">更新時間</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 5%;">排序</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 5%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 5%;">功能</th>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td class="text-nowrap text-center">{{ item.名稱 }}</td>
									<td class="text-nowrap text-center">{{ item.建立時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
									<td class="text-nowrap text-center">{{ item.更新時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
									<td class="td-sort text-nowrap text-center">
										<input
                      class="form-control text-center mr-10"
                      required="required"
                      type="number"
                      v-model="item.排序數字"
											style="
												width: 60px;
												display: inline-block;
												vertical-align: middle;
											"	
											:disabled="
												item.名稱 == 'Info' ||
												item.名稱 == 'MG 盤面' ||
												item.名稱 == 'Loading 頁' 
											"
                    />
										<button
											class="btn btn-warning btn-add btn-upDate"
											type="button"
											@click="updateOrder(item)"
											v-if="
												item.名稱 != 'Info' &&
												item.名稱 != 'MG 盤面' &&
												item.名稱 != 'Loading 頁' 
											"
										>更新</button>
									</td>

									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item, true)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border" v-if="total != 0">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="20">每頁筆數20</option>
            <option value="30">每頁筆數30</option>
            <option value="50">每頁筆數50</option>
          </select>
					<Pagination
						ref="pagination"
						:total="total"
						:pageLimitCount="searchData.pageSize"
						v-on:pageMethod="onPageChange"
						:type2="true"
					/>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
	import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
		},
    delimiters: ["${", "}"],
    data() {
      return {
				isEdit: false,
				searchData:{
					遊戲館編號: Number(this.$route.params.id),
					pageNum: 1,
					pageSize: 20,
				},
				total: 0,
				list: [],
				editData: {},
				testList: [
					{
						day: '週一',
						name: 'Loading 頁',
						info: '經常出現Lazy Monday Sale (金幣+獎勵的儲優組合包)',
						time: '2022-10-25 10:54:00',
						isStop: 0,
					},
					{
						day: '週一',
						name: 'MG 盤面',
						info: 'Frenzy Mission (Daily Mission多2個任務可以達成)',
						time: '2022-10-25 10:54:00',
						isStop: 1,
					},
				]
      };
    },
    created() {
			this.getDataList();
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
			getDataList(){
				api.slotGame.hallContent.queryAll(this.searchData)
					.then((res) => {
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
						this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
					})
			},
			resetDataList() {
				this.searchData.pageNum = 1;
				this.getDataList();
			},
			updateOrder(item) {
				item.遊戲編號 = Number(this.$route.params.GameId);
				item.排序數字 = Number(item.排序數字);
				api.slotGame.hallContent.order(item)
					.then((res) => {
						if(res.data.status == '1') {
							this.$eventBus.$emit("showAlert", "更新成功!");
							this.getDataList();
						} else {
							let message = res.data.message != '' ? res.data.message : '更新失敗'; 
							this.$eventBus.$emit("showAlert", message);
						}
					})
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}開發商？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}已建立的相關資料
						包含遊戲、遊戲館、集成分析。`,
				isOK => {
					if (isOK) {
						api.slotGame.hallContent.state({
							編號: item.編號,
							遊戲編號: Number(this.$route.params.GameId),//必傳
							遊戲館編號: item.遊戲館編號,//必傳
							是否啟用: item.是否啟用 == 1 ? 0 : 1,
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
					}
				});
			},
			add() {
				this.$router.push({
					name: 'slotGameContentEdit',
					params: {
						GameId: this.$route.params.GameId,
						GameHallId: this.$route.params.id,
					},
				});
			},
			edit(item) {
				this.$router.push({
					name: 'slotGameContentEdit',
					params: {
						GameId: this.$route.params.GameId,
						GameHallId: this.$route.params.id,
						id: item.編號,
						name: this.$route.params.name
					},
				});
			},
      backList() {
        this.$router.push({
					name: 'slotGamehallList',
					params: {
						searchData: this.$route.params.backSearchData,
					},
				});
      },
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>