/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
// import define from '@/views/projects/FC/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}
const dateOptions = {
    year:"numeric",
    month:"2-digit",
    day:"2-digit",
    hour:"2-digit",
    minute:"2-digit",
    second:"2-digit",
    hourCycle:"h23",
};

// actions
const actions = {
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameRecord.query(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    queryBattle({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameRecord.queryBattle(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    queryBet({ commit }, data){
        return new Promise((resolve, reject) => {
            api.nmjGameRecord.queryBet(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.nmjGameRecord.query(data).then(res => {
            // console.log('export:', res)
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    let items = [];
                    res.data.result.recordList.forEach(item => {
                        items.push({
                            '牌桌ID': item.牌桌ID,
                            '圈風': item.圈風,
                            '連莊數': item.連莊次數,
                            '牌局模式': item.牌桌類型,
                            '貨幣類別': item.籌碼類型,
                            '底分': item.底,
                            '台分': item.台,
                            '桌主暱稱': item.桌主暱稱,
                            '牌局結果': item.牌局結果,
                            '胡牌/自摸玩家暱稱': item.贏家暱稱,
                            '總台數': item.總台數,
                            '贏分': item['籌碼變動(不含東錢)'],
                            '東錢': item.東錢,
                            '剩餘張數': item.剩餘張數,
                            '莊家暱稱': item.莊家暱稱,
                            '東風位暱稱': item.東風位暱稱,
                            '東風位被吃扣分': item.東風位被吃扣分,
                            '東風位技術分': item.東風位技術分,
                            '南風位暱稱': item.南風位暱稱,
                            '南風位被吃扣分': item.南風位被吃扣分,
                            '南風位技術分': item.南風位技術分,
                            '西風位暱稱': item.西風位暱稱,
                            '西風位被吃扣分': item.西風位被吃扣分,
                            '西風位技術分': item.西風位技術分,
                            '北風位暱稱': item.北風位暱稱,
                            '北風位被吃扣分': item.北風位被吃扣分,
                            '北風位技術分': item.北風位技術分,
                            '結束時間': new Date(item.結束時間).toLocaleString('zh-TW', dateOptions),
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_牌局資料列表.xlsx`,
                        '牌局資料查詢'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
    exportBattle({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.nmjGameRecord.queryBattle(data).then(res => {
            // console.log('export:', res)
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    let items = [];
                    res.data.result.competitionRecordList.forEach(item => {
                        items.push({
                            '玩家暱稱': item.暱稱,
                            '賽事類別': item.賽事類別,
                            'Round ID': item.roundID,
                            '牌桌ID': item.牌桌ID,
                            '名次結果': item.名次結果,
                            '賽事籌碼': item.賽事籌碼,
                            '比賽開始時間': new Date(item.比賽開始時間).toLocaleString('zh-TW', dateOptions),
                            '報名順序': item.報名順序,
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_爭霸戰牌局.xlsx`,
                        '爭霸戰牌局'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}