<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲操作說明{{ isEdit ? '編輯' : '新增' }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link :to="goBack">遊戲操作說明{{ isEdit ? '編輯' : '新增' }}</router-link></li>
        <li class="active">{{ id }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ isEdit ? '編輯' : '新增' }}內容</h4>
            <router-link :to="goBack" class="btn btn-block btn-default btn-cog pull-right">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width:1200px;">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 版本名稱：
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="edit.version" id="inputTitle" required />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red" v-show="type.id == langs[0].id">*</span> 語系版本：
                </label>
                <div class="col-sm-10 btns" style="display:flex; flex-wrap:wrap; gap:5px;">
                  <button v-for="lang in langs" type="button" class="btn"
                    :class="{ 'btn-primary': type.id == lang.id }" @click="type = lang"
                  >{{ lang.name }}</button>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red" v-show="type.id == langs[0].id">*</span> {{type.name}}說明：
                </label>
                <div class="col-sm-10">
                  <div class="editor" ><tinymce v-model="toggleContent" /></div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input type="number" class="form-control" id="inputSort" v-model.number="edit.sort" min="0" />
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button type="button" class="btn btn-primary btn-lg" @click="Save(false)">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>{{ edit }}</pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import dfn from "./_Define";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";

class Lang {
  constructor(lang){
    this.id = 0;
    this.language_type = lang;
    this.name = "";
    this.content = "";
  }
}

export default {
  components: {
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: this.id > 0 && this.id != "New",

      langs: dfn.langs,
      type: dfn.langs[0],

      edit:{
        // gid: "", //Id
        version: "",
        sort: 1,
        languageList: [...dfn.langs].map(type => new Lang(type.id)),
      },

      checkInterval: 0,
    };
  },
  created() {
    if (this.isEdit) {
      this.GetOperation();
    }
    this.checkInterval = setInterval(() => {
      this.$store.dispatch("news/newsCheck")
    }, 5*60*1000 );
  },
  beforeDestroy(){
    clearInterval(this.checkInterval);
  },
  mounted() {

  },
  computed: {
    goBack(){
      return {name:'AWH5GameOperationList'}
    },

    toggleContent: {
      get() {
        let lang = this.edit.languageList.find(item => item.language_type === this.type.id);
        if (lang){ 
          return lang.content;
        } else {
          this.edit.languageList.push(new Lang(this.type.id));
          return this.edit.languageList[this.edit.languageList.length - 1].content;
        }
      },
      set(value) {
        let item = this.edit.languageList.find(item => item.language_type === this.type.id);
        this.$set(item, "content", value);
        // console.log("SET", item.content, value);
      }
    },
  },
  watch: {

  },
  methods: {
    //取得該遊戲操作說明資料
    GetOperation() {
      this.$store.dispatch("xsw/遊戲操作說明查詢", {
        gid: this.id
      }).then((result) => {
        this.edit = result;
      });
    },

    //送出
    async Save() {
      let err = [];

      if (this.edit.version == "") err.push("請填寫版本名稱");

      let defaultContent = this.edit.languageList.find(item => item.language_type == this.langs[0].id);
      if (!defaultContent || defaultContent.content == ""){
        err.push(`請填寫${this.langs[0].name}說明`)
      } 

      if (err.length > 0){
        this.$eventBus.$emit("showAlert", err.join('<br>'));
        return;
      }

      let api = this.isEdit ? "修改" : "新增";
      this.$store.dispatch("xsw/遊戲操作說明" + api, this.edit).then((res) => {
        this.backList();
      }, (err) => {
        this.$eventBus.$emit("showAlert", err);
      });
    },

     //*---- 返回
    backList() {
      this.$router.push({
        name: "AWH5GameOperationList"
      });
    },
  },
};
</script>

<style scoped>
.editor .tox-tinymce {
    height: 800px !important;
}
.chooseBox .img-close {
  right: -10px !important;
}
</style>