<template>
  <!-- 內容 -->
  <div class="modal fade" id="forumContent" ref="forum_content">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title" v-html="edit.articleTitle"></h4>
        </div>
        <div class="modal-body" v-html="edit.articleContent"></div>
        <div class="modal-footer">
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: {},
  props: {
    propsID: String,
    propsCategoryNo: '',
  },
  data() {
    return {
      edit: {
        categoryNo: 0,
        articleTitle: '',
        articleContent: '',
      },
    };
  },
  computed: {
    ...mapState({
      detailQuery: (state) => state.qppArticle.detailQuery,
      commentQuery: (state) => state.qppReport.commentQuery,
      replyQuery: (state) => state.qppReport.replyQuery,
    }),
  },
  watch: {
    propsID(value) {
      if (value == '' || value == undefined) {
        return;
      }
      this.GetAnnouncement(value, this.propsCategoryNo);
    },
    detailQuery(value) {
      if (value == null) return;
      this.edit = this.detailQuery;
      this.edit.articleTitle =
        '<span class="text-blue">文章ID：' +
        this.propsID +
        '</span><br />' +
        this.edit.articleTitle;
    },
    commentQuery(value) {
      if (value.totalCount === 0) {
        this.edit.articleContent = '<span class="text-red">資料不存在。</span>';
      } else {
        let content = value.commentDetailList[0].commentContent;
        this.edit.articleContent = content;
      }
    },
    replyQuery(value) {
      if (value.totalCount === 0) {
        this.edit.articleContent = '<span class="text-red">資料不存在。</span>';
      } else {
        let content = value.replyDetailList[0].replyContent;
        this.edit.articleContent = content;
      }
    },
  },
  methods: {
    showForumContent() {
      $(this.$refs.forum_content).modal('show');
    },

    //取得該公告資料
    GetAnnouncement(id, no) {
      if (no == 'C') {
        this.edit.articleTitle = '回覆ID：' + id;
        let data = {
          page: 1,
          contentKey: '',
          authorUid: '',
          targetId: id,
        };
        this.$store.dispatch('qppReport/commentQuery', data);
      } else if (no == 'R') {
        this.edit.articleTitle = '留言ID：' + id;
        let data = {
          page: 1,
          contentKey: '',
          authorUid: '',
          targetId: id.split('R')[1],
        };
        this.$store.dispatch('qppReport/replyQuery', data);
      } else {
        let data = {
          categoryNo: no, // 個人0 官方>0
          articleId: id,
        };
        this.$store.dispatch('qppArticle/DetailQuery', data);
      }
    },
  },
};
</script>
