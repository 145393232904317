<template>
  <div id="CustomerService">
    <section class="content-header">
      <h1>客服處理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">客服處理</li>
      </ol>
    </section>
    <section class="content customer-service">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: searchData.module === '玩家回報' }">
            <a @click="resetDataList('玩家回報', '問題類別')" href="#problem" aria-expanded="true" data-toggle="tab">
              玩家回報
            </a>
          </li>
          <li :class="{ active: searchData.module === '檢舉' }">
            <a @click="resetDataList('檢舉', '檢舉類別')" href="#report" aria-expanded="false" data-toggle="tab">
              檢舉
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- 玩家回報 -->
          <div class="tab-pane" :class="{ active: searchData.module === '玩家回報' }">
            <div class="top-box">
              <!-- <div class="form-group"> -->
                <div class="select-group mb-10">
                  <select class="form-control" v-model="searchData.reactionType" @change="resetDataList('玩家回報')">
                    <option value="">問題類別</option>
                    <option value="大廳">大廳</option>
                    <option value="段位賽">段位賽</option>
                    <option value="好友對決">好友對決</option>
                    <option value="儲值相關">儲值相關</option>
                    <option value="帳號相關">帳號相關</option>
                    <option value="活動">活動</option>
                    <option value="建議">建議</option>
                    <option value="其他">其他</option>
                  </select>
                </div>
                <div class="select-group mb-10">
                  <select class="form-control" v-model="searchData.platform" @change="resetDataList('玩家回報')">
                    <option value="">所有平台</option>
                    <option value="Android">Android</option>
                    <option value="iOS">iOS</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="select-group mb-10">
                  <select class="form-control" v-model.number="searchData.status" @change="resetDataList('玩家回報')">
                    <option value="-1">處理進度</option>
                    <option value="0">未回覆</option>
                    <option value="2">已回覆</option>
                    <option value="1">草稿</option>
                  </select>
                </div>
              <!-- </div> -->
              <div style="clear:both"></div>
              <!-- <div class="input-group">
                <input type="text" class="form-control" placeholder="玩家UID" v-model.trim="searchData.reactionUId"/>
              </div> -->
              <div class="input-group">
                <input type="text" class="form-control" placeholder="玩家暱稱" v-model.trim="searchData.ReactionNickname"/>
              </div>
              <div class="form-group" style="float:left;margin-right:.5em;width:auto;">
                <label class="control-label">回報時間：</label>
                <div class="input-group date">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <div class="pull-right">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.local.contactEndTime"
                      :propStartDate.sync="searchData.local.contactStartTime"
                    />
                  </div>
                </div>
              </div>
              <div class="btns">
                <button class="btn btn-primary" type="button" @click="resetDataList('玩家回報')" >查詢</button>
                <button class="btn btn-default" type="button" @click="exportOrders()">匯出</button>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 92px; width:10%">編號</th>
                    <th style="min-width: 92px; width:10%">回報<wbr>時間</th>
                    <th style="min-width:100px; width:10%">玩家<wbr>暱稱</th>
                    <th style="min-width: 80px; width: 5%">回報<wbr>來源/平台</th>
                    <th style="min-width:100px; width:15%">遊玩<wbr>裝置<wbr>型號</th>
                    <th style="min-width:100px; width:15%">裝置<wbr>版本</th>
                    <th style="min-width: 80px; width: 5%">遊戲<wbr>版本</th>
                    <th style="min-width: 80px; width: 5%">問題<wbr>類別</th>
                    <th style="min-width: 80px; width: 5%">處理<wbr>人員</th>
                    <th style="min-width: 80px; width: 5%">處理<wbr>進度</th>
                    <th style="min-width: 92px; width:10%">回覆<wbr>時間</th>
                    <th style="min-width: 80px; width: 5%">內容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading"><td colspan="14">查詢中</td></tr>
                  <tr v-else-if="datas.length === 0"><td colspan="13">查詢無資料</td></tr>
                  <tr v-else v-for="(item, index) in datas" :key="index">
                    <td>{{ item.reactionId }}</td>
                    <td>{{ uiDate(item.reactionTime) }}</td>
                    <td><router-link :to="{name:'NmjPlayerData', query:{nick: item.reactionNickname}}">{{ item.reactionNickname}}</router-link></td>
                    <td>{{ item.platform }}</td>
                    <td>{{ item.phoneModel }}</td>
                    <td>{{ item.systemVersion }}</td>
                    <td>{{ item.appVersion }}</td>
                    <td>{{ item.reactionType }}</td>
                    <td>{{ item.replyUName }}</td>
                    <td :class="{'text-red': item.status === 1}">
                      {{ def.serviceState[item.status] }}
                    </td>
                    <td>{{ uiDate(item.replyTime) }}</td>
                    <td><button class="btn btn-primary" @click="checkContent(item)">查看</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 檢舉 -->
          <div class="tab-pane" :class="{ active: searchData.module === '檢舉' }">
            <div class="top-box">
              <div class="select-group mb-10">
                <select class="form-control" v-model="searchData.reactionType" @change="resetDataList('檢舉')">
                  <option value="">檢舉類別</option>
                  <option value="辱罵聊天">辱罵聊天</option>
                  <option value="不當暱稱">不當暱稱</option>
                  <option value="作弊">作弊</option>
                  <option value="濫用廣告">濫用廣告</option>
                  <option value="其他">其他</option>
                </select>
              </div>
              <div class="select-group mb-10">
                <select class="form-control" v-model.number="searchData.status" @change="resetDataList('檢舉')">
                  <option value="-1">處理進度</option>
                  <option value="0">未回覆</option>
                  <option value="2">已回覆</option>
                  <option value="3">不需回復</option>
                </select>
              </div>
              <div style="clear:both"></div>
              <!-- <div class="input-group">
                <input type="text" class="form-control" placeholder="玩家UID" v-model.trim="searchData.reactionUId" />
              </div> -->
              <div class="input-group">
                <input type="text" class="form-control" placeholder="玩家暱稱" v-model.trim="searchData.ReactionNickname" />
              </div>
              <div class="form-group" style="float:left;margin-right:.5em;width:auto;">
                <label class="control-label">檢舉時間：</label>
                <div class="input-group date">
                  <div class="input-group-addon fa fa-calendar"></div>
                  <div class="pull-right">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.local.reportEndTime"
                      :propStartDate.sync="searchData.local.reportStartTime"
                    />
                  </div>
                </div>
              </div>
              <div class="btns">
                <button type="button" class="btn btn-primary" @click="resetDataList('檢舉')">查詢</button>
                <button class="btn btn-default" type="button" @click="exportOrders()">匯出</button>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 90px;width:10%">編號</th>
                    <th style="min-width:100px;width:10%">回報<wbr>時間</th>
                    <th style="min-width:125px;width:10%">玩家<wbr>暱稱</th>
                    <th style="min-width:125px;width:10%">被檢舉<wbr>玩家<wbr>暱稱</th>
                    <th style="min-width: 80px;width: 5%">檢舉<wbr>類別</th>
                    <th style="min-width: 80px;width: 5%">客服<wbr>人員</th>
                    <th style="min-width: 80px;width: 5%">處理<wbr>狀況</th>
                    <th style="min-width:100px;width:10%">回覆<wbr>時間</th>
                    <th style="min-width: 80px;width: 5%">內容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading">
                    <td colspan="10">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td colspan="10">查詢無資料</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="index">
                    <td>{{ item.reactionId }}</td>
                    <td>{{ uiDate(item.reactionTime) }}</td>
                    <td><router-link :to="{name:'NmjPlayerData', query:{nick: item.reactionNickname}}">{{ item.reactionNickname}}</router-link></td>
                    <td><router-link :to="{name:'NmjPlayerData', query:{nick: item.sufferUId}}">{{ item.sufferUId}}</router-link></td>
                    <td>{{ item.reactionType }}</td>
                    <td>{{ item.replyUName }}</td>
                    <td :class="{ 'text-red': item.status === 1 }">
                      {{ def.serviceState[item.status] }}
                    </td>
                    <td>{{ uiDate(item.replyTime) }}</td>
                    <td><button class="btn btn-primary" @click="checkContent(item)">查看</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 頁數 -->
          <div class="box text-center no-border no-shadow mt-20">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              ref="pagination"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <!-- /.tab-content -->
        <!-- <pre>{{ JSON.stringify(datas, null, 2) }}</pre> -->
      </div>
    </section>
  </div>
</template>

<style lang="scss">
  table.table > tbody > tr > td {
    word-break: break-word;
  }
  table.table > thead > tr > th{
    word-break: keep-all;
  }
</style>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      def: define,
      loading: false,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          contactStartTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          contactEndTime: new moment().format("YYYY-MM-DD 23:59:59"),
          reportStartTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          reportEndTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
        module: "玩家回報",
        reactionType: "",
        platform: "",
        status: -1,
        reactionUId: "",
      },
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    resetDataList(moduleType, reactionType) {
      this.searchData.pageNum = 1;
      this.getDataList(moduleType, reactionType);
    },
    getDataList(moduleType, reactionType) {
      this.searchData.module = moduleType || this.searchData.module;
      if (reactionType) {
        this.searchData.reactionType = "";
        this.searchData.platform = "";
      }
      this.updateTime();
      this.loading = true;
      this.$store
        .dispatch("nmjService/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }, (message) => {
          this.datas = [];
          this.total = 0;
          this.loading = false;
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateTime() {
      if (this.searchData.module === "玩家回報") {
        this.searchData.startTime = new Date(
          `${this.searchData.local.contactStartTime} UTC`
        );
        this.searchData.endTime = new Date(
          `${this.searchData.local.contactEndTime} UTC`
        );
      } else {
        this.searchData.startTime = new Date(
          `${this.searchData.local.reportStartTime} UTC`
        );
        this.searchData.endTime = new Date(
          `${this.searchData.local.reportEndTime} UTC`
        );
      }
    },
    // 查看內容
    checkContent(item) {
      this.$router.push({
        name: "NmjCustomerServiceEdit",
        params: {
          id: item.reactionId,
          type: this.searchData.module,
          searchData: this.searchData,
        },
      });
    },
    exportOrders() {
      this.$store.dispatch("nmjService/export", this.searchData);
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>