<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>相簿管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>相簿管理</li>
        <li class="active">相簿列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增內容
            </button>
            <!-- <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.classTypeId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in classTypes.datas"
                  :key="index"
                >{{item.className}}</option>
              </select>
            </div> -->
            <div class="select-group">
              <label class="control-label">排序方式：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.orderByVoteCount"
                @change="clickSearch"
              >
                <option
                  :value="item.value"
                  v-for="(item, index) in orderByOptions"
                  :key="index"
                >{{item.name}}</option>
              </select>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ listDatas.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 150px;">分類</th>
                  <th style="min-width: 90px; width: 5%">主圖</th>
                  <th style="min-width: 150px;">名稱</th>
                  <th style="min-width: 60px;">年度</th>
                  <th style="min-width: 180px;">建立日期</th>
                  <th style="min-width: 60px;">狀態</th>
                  <th style="min-width: 150px;">排序</th>
                  <th style="min-width: 100px;">票數</th>
                  <th style="min-width: 150px;">功能</th>
                </tr>
                <template v-if="listDatas.total > 0">
                  <tr :key="item.id" v-for="item in listDatas.datas">
                    <td align="center">{{ item.classTypeName }}</td>
                    <td>
                      <div class="pic jqimgFill">
                        <!-- 430 * 330 -->
                        <img :src="item.mainImgFullFileName" draggable="false" />
                      </div>
                    </td>
                    <td align="center">{{ item.detailTopTitle }}</td>
                    <td align="center">{{ $utils.formatTime(item.publishTime,"YYYY") }}</td>
                    <td align="center">{{ item.createTime }}</td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.sortId" min="1" />
                      <button
                        @click="updateGameOd(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                      >更新</button>
                    </td>
                    <td align="center">{{ item.voteCount == null ? '0':item.voteCount }}</td>
                    <td class="text-center text-nowrap">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>&nbsp;
                      <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="listDatas.total > 0">
          <Pagination
            ref="pagination"
            :total="listDatas.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      orderByList: [],
      total: 0, //總筆數
      editPage: "WaninUnite2020AlbumEditing",
      searchData: {
        year : 2020,
        module: "album",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        orderByVoteCount: -1,
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetClassTypes();
    this.QueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      classTypes: state => state.classTypes.queryAll,
      listDatas: state => state.game.queryAll,
      response: state => state.game.response
    }),
    yearOptions(){
      return [
        {"id":2019,"label":"2019"},
        {"id":2020,"label":"2020"},
        {"id":2021,"label":"2021"},
        {"id":2022,"label":"2022"},
        {"id":2023,"label":"2023"},
        {"id":2024,"label":"2024"},
      ]
    },
    orderByOptions(){
      return [
        {"name":"編號","value":-1},
        {"name":"票數","value":1},
      ]
    }
  },
  watch: {
    classTypes() {},
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.QueryAll();
    },
    listDatas() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.listDatas.total
      );
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.QueryAll();
    },

    QueryAll() {
      //列表
      this.$store.dispatch("game/gameQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async GetClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: this.searchData.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    updateGameOd(item) {
      item.sortId = parseInt(item.sortId);
      item.isTurnPlay = parseInt(item.isTurnPlay);

      this.$store.dispatch("game/gameUpdateSort", item);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.QueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: this.editPage,
        params: { searchData: this.searchData }
      });
      return;
    },

    //*---- 修改內容
    editorItem(item) {
      this.$router.push({
        name: this.editPage,
        params: { id: item.contentId, searchData: this.searchData }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("game/gameDelete", item);
        }
      });
    }
  }
};
</script>