<template>
    <button type="button" class="btn btn-default pull-left" style="width: 200px; margin-right: 10px;" ref="daterange">
        <span style="padding-right: 10px;" ref="daterangeSpan">
            <i class="fa fa-calendar"></i> {{ $t('選擇時間區間') }}
        </span>
        <i class="fa fa-caret-down"></i>
    </button>
</template>
<script>
import moment from "moment";
export default {
    // components: {
    // },
    // props: {
    // },
    data() {
        let format = "YYYY-MM-DD";
        return {
            format,
            開始時間: "",
            結束時間: "",
            現在時間: moment()
        };
    },
    mounted() {
        let format = this.format;
        //API文件 https://www.daterangepicker.com/#config
        let cb = (start, end) => {
            if (start.isSame(this.現在時間, 'day')) {
                $(this.$refs.daterangeSpan).html(this.$t('即時'));
            } else {
                $(this.$refs.daterangeSpan).html(start.format(format) + ' - ' + end.format(format));
            }
            this.開始時間 = start.format(format);
            this.結束時間 = end.format(format);
        }
        $(this.$refs.daterange).daterangepicker(
            {
                // "showDropdowns": true,
                "autoApply": true,
                "locale": {
                    // "format": "MM/DD/YYYY",
                    // "separator": " - ",
                    "applyLabel": this.$t('應用'),
                    "cancelLabel": this.$t('取消'),
                    // "fromLabel": "From",
                    // "toLabel": "To",
                    "customRangeLabel": this.$t('自訂時間'),
                    // "weekLabel": "W",
                    // "daysOfWeek": [
                    //     "Su",
                    //     "Mo",
                    //     "Tu",
                    //     "We",
                    //     "Th",
                    //     "Fr",
                    //     "Sa"
                    // ],
                    // "monthNames": [
                    //     "January",
                    //     "February",
                    //     "March",
                    //     "April",
                    //     "May",
                    //     "June",
                    //     "July",
                    //     "August",
                    //     "September",
                    //     "October",
                    //     "November",
                    //     "December"
                    // ],
                    // "firstDay": 1
                },
                ranges: {
                    [this.$t('即時')]: [moment(), moment()],
                    [this.$t('近七日')]: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
                    [this.$t('近三十日')]: [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
                },
                startDate: moment(),
                endDate: moment(),
                maxDate: moment()
            }
            , cb
        );
        cb(moment(), moment());
        $(this.$refs.daterange).on('apply.daterangepicker', (ev, picker) => {
            if (picker.endDate.format(format) == moment().format(format) && picker.startDate.format(format) != moment().format(format)) {
                //if (picker.chosenLabel == me.$t('自訂時間') && picker.endDate.format(format) == moment().format(format)) {
                this.$eventBus.$emit("showAlert", this.$t('自訂時間的結束時間無法選今天'));
                picker.setStartDate(picker.oldStartDate);
                picker.setEndDate(picker.oldEndDate);
                cb(picker.oldStartDate, picker.oldEndDate);
            } else {
                cb(picker.startDate, picker.endDate);
            }
            this.$emit("ApplyDaterangepicker"/*, this.開始時間, this.結束時間*/);
        });
    },
    watch: {
        // "$i18n.locale"(val) {
        //     console.error($(this.$refs.daterange))
        // },
    }
}
</script>