<template>
  <div id="AlbumEdit">
    <section class="content-header">
      <h1>相簿編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>相簿列表</li>
        <li class="active">相簿編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <!-- <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"
                      @click="ChangeLang(item.id)"
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 相簿名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.名稱"
                  />
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 模組類別：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.功能模組">
                    <option disabled="disabled" value>請選擇類別</option>
                    <option
                      :key="index"
                      :value="item.id"
                      v-for="(item, index) in projectData.module"
                    >{{item.name}}</option>
                  </select>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 對應ID：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.功能模組編號"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <!--<span class="text-red">*</span> -->
                  圖片：
                </label>
                <div class="col-sm-10">
                  <div class="pic">
                    <img draggable="false" :src="edit.filesUrl" />
                  </div>
                  <input
                    accept="image/*"
                    id="AnnouncementPic"
                    type="file"
                    @change="onFileChange($event)"
                    multiple
                  />
                  <div class="help-block">
                    <span class="text-red">{{ picLimit }}</span>
                  </div>

                  <div class="row ann-imgChoose mt-10">
                    <dnd-zone
                      vertical-search
                    >
                      <dnd-container
                        :dnd-model="edit.相片列表"
                        dnd-id="list-example"
                        class="row"
                      >
                        <template v-for="card in edit.相片列表">
                          <dnd-item :dnd-model="card" :key="card.applyId" :dnd-id="card.applyId">
                            <div class="col-sm-3 col-xs-6 chooseBox">
                              <div
                                class="img-close fa fa-fw fa-close"
                                @click="DeletePic(card)"
                                style="height: 25px; width: 25px; line-height: 20px;"
                              ></div>

                              <img :src="card.filesUrl" @mouseup="getMainPic()">

                              <div class="radio">
                                <input
                                  type="radio"
                                  :id="'mainImg'+card.applyId"
                                  name="mainImg"
                                  v-bind:checked="card.isMain == 1"
                                  v-on:change="SetPicIsMain(card)"
                                />主圖
                              </div>
                            </div>
                          </dnd-item>
                        </template>
                      </dnd-container>
                    </dnd-zone>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="1"
                        message="上架"
                        name="radioMode"
                        v-model="edit.是否顯示"
                      />
                    </div>
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="下架"
                        name="radioMode"
                        v-model="edit.是否顯示"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-warning btn-lg"
                  style="margin-right: 10px"
                  type="button"
                  @click="Save(true)"
                  v-if="
                    edit.isStop == 1 &&
                    (edit.projectId == 'omp_dk' || edit.projectId == 'omp_jx2')
                  "
                >
                  預覽
                </button>
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save(false)"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    DatePicker,
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      edit: {
        名稱: "",
        編號: 0,
        專案編號: this.$store.state.projectId,
        分類編號: "",
        功能模組: "news",
        功能模組編號: "",
        排序: "",
        是否顯示: "1",
        建立人員: "",
        語系: "zh-tw",
        相片列表: [],
        語系資訊編號: "",
      },
      imageFolder: "album",
      langTypes: {},
      timeStringOption: { timeStyle: "short" },
      timepickerOption: {},
      detailNewsLang: [],
      imgList: [],
      isPreview: false,
      picLimit: "圖片建議尺寸 350 * 250。",
      url: config.dkOption.url,
    };
  },
  created() {
    this.GetAllLang();
    this.getNewsList();
    if(this.edit.專案編號 == "omp_wanin"){
      this.picLimit = "圖片建議尺寸 1000*800。";
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      album: (state) => state.album.query,
      newsTypes: (state) => state.classTypes.queryAll,
      newsList: (state) => state.news.queryAll,
      response: (state) => state.album.response,
      projectData: (state) => state.module.queryAll,
    }),
  },
  watch: {
    album() {
      this.edit = this.album;
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;
      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetAlbum();
      } else {
        this.detailNewsLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.newsId + i + 1;
          this.detailNewsLang.push({
            newsDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo,
          });
        }
      }
    },

    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        if (!this.isPreview)
          this.$router.push({
            name: "AlbumList",
            params: {
              searchData: this.$route.params.searchData,
            },
          });
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    },
  },
  methods: {
    //取得該公告資料
    GetAlbum() {
      this.$store.dispatch("album/albumQuery", {
        id: parseInt(this.$route.params.id),
      });
    },

    GetAllLang() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetImgList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("news/newsDetailImgList", {
        newsDetailId: this.edit.newsDetailId,
      });
    },

    //取得該語系資料
    // GetDetailNewsLang(lang) {
    //   for (var i = 0; i < this.langTypes.length; i++) {
    //     if (lang == this.langTypes[i].id) {
    //       this.edit.newsDetailId = this.detailNewsLang[i].newsDetailId;
    //       this.edit.detailTopTitle = this.detailNewsLang[i].title;
    //       this.edit.detailTopInfo = this.detailNewsLang[i].info;
    //     }
    //   }
    // },

    //更換語系
    // ChangeLang(lang) {
    //   this.removeFile();
    //   this.SetLangData(this.editLang);
    //   this.GetDetailNewsLang(lang);
    //   this.editLang = lang;
    //   this.GetImgList();
    // },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailNewsLang[i].title = this.edit.detailTopTitle;
          this.detailNewsLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    onFileChange(evt) {
      // this.SetLangData(this.editLang);
      // this.GetDetailNewsLang(this.editLang);

      var files = evt.target.files;
      if (files.length > 50) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var reader = new FileReader();
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }

          if (this.isEdit) {
            this.uploadFile(files, this.imageFolder, this.edit.編號, true);
          } else {
            this.imgList.push(files[i]);
            let lastModified = files[i].lastModified;
            let tmpImgList = this.imgList;

            reader.onload = function (e) {
              $(".ann-imgChoose").append(
                `
                <div class="col-sm-3 col-xs-6 chooseBox" id="` +
                  lastModified +
                  `">
                  <div class="img-close fa fa-fw fa-close" data-id="` +
                  lastModified +
                  `"></div>
                  <img src="` +
                  e.target.result +
                  `">
                  <div class="radio">
                    <input type="radio" id="mainImg`+ lastModified +`" name="mainImg">主圖 
                  </div>
                </div>
              `
              );

              $(".fa-close").click(function () {
                var id = $(this).attr("data-id");
                tmpImgList.forEach((item, index) => {
                  if (item.lastModified == id) {
                    tmpImgList.splice(index, 1);
                    $("#" + id).remove();
                  }
                  return;
                });
              });

              // // 預設選擇首張
              // $("#isMain-" + input[0].lastModified).attr("checked", true);

              // $('input[type="radio"][name=isMain]').on('change', function () {
              //     checkMainImageId = $('input[name=isMain]:checked').val();
              //     if (lastModified == checkMainImageId) {
              //         VueApp.editData.mainImageId = $.map(input, function (item, index) {
              //             return item.lastModified;
              //         }).indexOf(lastModified);
              //     }
              // });
            };
            console.log(files[i]);
            reader.readAsDataURL(files[i]);
          }
        }
      }
    },

    getMainPic() {
      this.edit.相片列表.forEach((item, index) => {
        if (item.isMain == 1) {
          radiobtn = document.getElementById("mainImg" + item.applyId);
          radiobtn.checked = true;
          return;
        }
      });
    },

    // 取消圖片
    cancelImg(lastModified) {},

    changeFileOrder() {
      this.edit.相片列表;
    },

    removeFile() {
      document.getElementById("AnnouncementPic").value = "";
    },

    uploadFile(files, folderName, id, multi = false) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.edit.專案編號);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.editLang);
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then((res) => {
        if (res.status != "0") this.GetAlbum();
      });
    },

    SetPicIsMain(item) {
      item = this.TransImgData(item);
      this.$store.dispatch("file/setIsMain", item).then((res) => {
        if (res.status != "0") {
          this.imgList = [];
          // this.GetAlbum();
        }
      });
    },

    DeletePic(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          item = this.TransImgData(item);
          this.$store.dispatch("file/fileDelete", item).then((res) => {
            if (res.status != "0") {
              this.GetAlbum();
              this.removeFile();
            }
          });
        }
      });
    },

    TransImgData(item) {
      var imgObj = {
        id: item.applyId,
        imgFullFileName: item.filesUrl,
        imgName: item.name,
        isMain: item.isMain.toString(),
        od: item.od,
        projectId: item.projectId,
      };

      return imgObj;
    },

    checkIfNewsIdExist() {
      let isNewsExist = false
      this.newsList.datas.forEach((item, index) => {
        if (item.newsId == this.edit.功能模組編號) {
          isNewsExist = true;
        }
      });
      return isNewsExist;
    },

    getNewsList() {
      //新聞列表   
      this.$store.dispatch("news/newsQueryAll", {
        newsId: this.edit.功能模組編號,
        projectId: this.edit.專案編號,
        module: this.edit.功能模組
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AlbumList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },

    //送出
    Save(isPreview) {
      this.isPreview = isPreview;
      // this.SetLangData(this.editLang);
      this.edit.是否顯示;

      if (this.edit.名稱 == "") {
        this.$eventBus.$emit("showAlert", "相簿名稱不可為空");
        return;
      }

      // if (this.edit.功能模組 == "") {
      //   this.$eventBus.$emit("showAlert", "請選擇模組類別");
      //   return;
      // }

      if (this.edit.功能模組編號 == "") {
        this.$eventBus.$emit("showAlert", "對應ID不可為空");
        return;
      }

      if (!this.checkIfNewsIdExist()) {
        this.$eventBus.$emit("showAlert", "對應ID不存在");
        return;
      }

      if (this.isEdit) {
        var img_ary = [];
        if(this.edit.相片列表.length > 0){
          this.edit.相片列表.forEach((item, index) => {
            img_ary.push({
              ApplyId: item.applyId,
              Od: index+1
            })
          });
          this.$store.dispatch("file/updateFileInfoApply", img_ary);
        }
        
        var data = new FormData();
        data.append("MasterModel.專案編號", this.edit.專案編號);
        data.append("MasterModel.功能模組", this.edit.功能模組);
        data.append("MasterModel.功能模組編號", this.edit.功能模組編號);
        data.append("MasterModel.編號", parseInt(this.edit.編號));
        // data.append("MasterModel.排序", this.edit.排序);
        data.append("MasterModel.是否顯示", this.edit.是否顯示);
        data.append("DetailModel[0].語系資訊編號", this.edit.語系資訊編號);
        data.append("DetailModel[0].名稱", this.edit.名稱);

        this.$store.dispatch("album/albumUpdate", data);
      } else {
        var data = new FormData();
        data.append("MasterModel.專案編號", this.edit.專案編號);
        data.append("MasterModel.功能模組", this.edit.功能模組);
        data.append("MasterModel.功能模組編號", this.edit.功能模組編號);
        data.append("DetailModel[0].語系", this.edit.語系);
        data.append("DetailModel[0].名稱", this.edit.名稱);
        if (this.imgList.length > 0) {
          for (var i = 0; i < this.imgList.length; i++) {
            data.append("Images", this.imgList[i]);
          }
          data.append("UpoladOption.FirstImgIsMain", "true");
          data.append("UpoladOption.Multi", "true");
        }

        this.$store.dispatch("album/albumCreate", data);
      }
    },
    isNewsInList() {
      if (this.isEdit) {
        let index = this.newsList.datas.findIndex(
          (news) => news.newsId == this.edit.newsId && news.isStop == 0
        );
        if (index != -1) return true;
      }
      return false;
    },
  },
};
</script>
