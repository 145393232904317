<template>
    <div class="modal fade" data-backdrop="static" ref="popForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Close" class="close" type="button" @click="hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title">重置</h4>
                </div>
                <div class="box-body">
                    <div class="form-group">
                        <label> 訂單編號：</label>{{ 訂單編號 }}
                    </div>
                    <div class="form-group">
                        <div class="form-group">
                            <label>備註：</label>
                            <input type="text" class="form-control" placeholder="請輸入備註" v-model.trim="備註">
                        </div>
                    </div>
                </div>
                <div class="box-footer">
                    <button type="button" class="btn btn-default pull-right" @click="hide()">取消</button>
                    <button type="button" class="btn btn-primary pull-right" style="margin-right: 15px;"
                        @click="send()">確認重置</button>
                </div>
                <!-- <div class="box-footer">
                    <button class="btn btn-default pull-right" type="button" @click="匯入結束()">匯入結束</button>
                </div> -->
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
export default {
    components: {
    },
    // props: ["廠商options", "年份options"],
    data() {
        return {
            訂單編號: "",
            備註: "",
        };
    },
    computed: {
        ...mapState({
            loading: (state) => state.invoice.loading,
        }),
    },
    // mounted() {
    // },
    methods: {
        show({ 訂單編號 }) {
            this.訂單編號 = 訂單編號;
            this.備註 = "";
            $(this.$refs.popForm).modal("show");
            // this.processST = processSTEnum.初始;
            // this.processPercent = 0;
            // this.searchData.服務編號 = 服務編號;
            // this.searchData.發票年份 = 發票年份;
            // this.searchData.中獎類別 = 中獎類別;
            // this.searchData.發票期數 = 發票期數 == "" ? "1, 2月" : 發票期數;
        },
        hide() {
            if (this.loading) {
                this.$eventBus.$emit("showAlert", "請等資料執行執行完畢才關閉");
                return;
            }
            $(this.$refs.popForm).modal("hide");
        },
        async send() {
            this.$store.dispatch('invoice/loading', true);
            try {
                // await this.onSearch(this.searchData);
                // let len = this.list.length;
                // let count = 0;
                // for (let i = 0; i < len; i++) {
                //     this.list[i].st = 'P';//狀態改成處理中
                //     try {
                //         // 是否重寄:true  <--不檢查是否有寄送過，直接寄
                //         // 是否重寄:false  <--會檢查是否有寄送過，沒寄送過才寄
                let res = await api.IPSSetting.ResetPrintStatus({
                    訂單編號: this.訂單編號,
                    備註: this.備註
                });
                if (res && res.data && res.data.message) {
                    throw res.data.message;
                } else {
                    this.$eventBus.$emit("showAlert", "成功");
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                return;
            } finally {
                this.$store.dispatch('invoice/loading', false);
            }
            this.hide();
            this.$emit('更新列表', this.訂單編號);
        },
    },
};
</script>

<style scoped>
.form-group>label {
    display: inline-block;
    width: 30%;
    text-align: right;
}

/* .form-group>sapn {
    display: inline-block;
    width: 50%;
} */

.form-control {
    width: 70%;
    display: inline-block;
}
</style>