<template>
  <div id="NmjEventAnnouncementList">
    <section class="content-header">
      <h1>遊戲Banner設定</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">遊戲Banner設定</li>
      </ol>
    </section>
    <section class="content customer-service">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li v-for="(item, key) in def.bannerType" :class="{active: searchData.分類 == key}" :key="key">
            <a :href="`#${item}`" @click="resetDataList(+key, -1)" aria-expanded="true" data-toggle="tab">{{ item }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- 活動公告設定 -->
          <div class="tab-pane active">
            <div class="top-box">
              <button type="button" @click="addNew()" class="btn btn-block btn-warning btn-add"><i class="fa fa-plus"></i> 新增</button>
              <div class="form-group">
                <label class="control-label">狀態：</label>
                <div class="select-group">
                <select class="form-control" v-model.number="searchData.是否開啟" @change="resetDataList()">
                  <option value="-1">全部</option>
                  <option value="0">關閉</option>
                  <option value="1">開啟</option>
                </select></div>
              </div>
              <!-- <div class="form-group" style="width: auto">
                <label class="control-label">開始時間：</label>
                <div class="input-group date">
                  <div class="input-group-addon"><i class="fa fa-calendar"></i></div>
                  <div class="pull-right">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.endTime"
                      :propStartDate.sync="searchData.startTime"
                    />
                  </div>
                </div>
              </div> -->
              <!-- <div class="btns">
                  <button type="button" @click="resetDataList()" class="btn btn-primary">查詢</button>
              </div> -->
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered table-center min-800 AdListTable">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 60px; width: 5%; word-break:keep-all">編號</th>
                    <th style="min-width: 80px; width: 5%; word-break:keep-all">狀態</th>
                    <th style="min-width: 80px; width: 5%; word-break:keep-all">平台</th>
                    <th style="min-width:105px; width: 5%; word-break:keep-all">分類</th>
                    <th style="min-width:105px; width:10%; word-break:keep-all">名稱</th>
                    <th style="min-width:105px; width:10%; word-break:keep-all">縮圖</th>
                    <th style="min-width: 88px; width: 5%; word-break:keep-all">排序</th>
                    <th style="min-width: 92px; width:10%; word-break:keep-all">上線<wbr>時間</th>
                    <th style="min-width: 92px; width:10%; word-break:keep-all">下線<wbr>時間</th>
                    <th style="min-width:140px; width: 5%; word-break:keep-all">功能</th>
                  </tr>
                </thead>
                <tbody v-if="total > 0">
                  <tr v-for="(item, index) in datas" :key="item.公告編號">
                    <td class="text-center">{{ item.公告編號 }}</td>
                    <td class="text-center">
                      <toggle-button v-model="item.status" :labels="true" @change="updateStatus(item)" />
                      <br><span :class="{'text-red':GetStatus(item)=='已過期'}">{{ GetStatus(item) }}</span>
                    </td>
                    <td class="text-center">{{ GetPlatfrom(item.顯示平台) }}</td>
                    <td class="text-center">{{ def.bannerType[item.分類] }}</td>
                    <td class="text-center">{{ item.公告名稱 }}</td>
                    <td>
                      <div class="pic jqimgFill" v-show="item.fileUrl"><!-- 430 * 330 -->
                        <img :src="item.fileUrl" alt="" draggable="false" />
                      </div>
                    </td>
                    <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.排序" min="1" :max="total" />
                      <button type="button" @click="updateSort(item)" class="btn btn-warning btn-add btn-upDate">更新</button>
                    </td>
                    <td class="text-center">{{ uiDate(item.開始時間) }}</td>
                    <td class="text-center">{{ item.是否常駐顯示 === 1 ? "常駐" : uiDate(item.結束時間) }}</td>
                    <td class="text-center">
                      <button type="button" @click="editorItem(item)" class="btn btn-primary">修改</button>&nbsp;
                      <button type="button" @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="11">查詢沒有資料</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 頁數 -->
          <div class="box text-center no-border no-shadow mt-20">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              ref="pagination"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <!-- /.tab-content -->
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import { ToggleButton } from "vue-js-toggle-button";
import { mapState } from "vuex";
import { get } from "sortablejs";

export default {
  components: {
    DateRangePicker,
    Pagination,
    ToggleButton
  },
  data() {
    return {
      def: define,
      loading: true,
      message: "",
      total: 0, //總筆數
      datas: [],
      searchData: {
        分類: 1,
        是否開啟: -1, //-1:ALL 0:關閉 1:開啟
        // 平台: "ALL",
        pageNum: 1,
        pageSize: 30, //每頁顯示筆數
        startTime: new moment().subtract(7, "days").format("YYYY-MM-DDT00:00:01"),
        endTime: new moment().add(0, "days").format("YYYY-MM-DDT23:59:59"),
      },
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  mounted() {
    window.v = this;
  },
  // computed: {
  //   ...mapState({
  //     event: (state) => state.fcEventAnnouncement.queryAll,
  //   }),
  // },
  // watch: {
  //   bannerTypes() {},
  //   response() {
  //     if (this.response.狀態 == "1")
  //       this.$eventBus.$emit("showAlert", "更新成功!");
  //     else this.$eventBus.$emit("showAlert", "更新失敗!");

  //     this.getDataList();
  //   },
  //   event() {
  //     this.$refs.pagination.setPage(
  //       this.searchData.pageNum,
  //       this.fcEventAnnouncement.total
  //     );
  //   },
  // },
  methods: {
    resetDataList(公告類別, 公告狀態) {
      if (公告類別 !== undefined) this.searchData.分類 = 公告類別;
      if (公告狀態 !== undefined) this.searchData.是否開啟 = 公告狀態;
      this.searchData.pageNum = 1;
      // console.log(this.searchData.分類, this.searchData.是否開啟)
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.searchData.startTime = this.searchData.startTime.replace(/\//g, "-").replace(" ", "T").replace(/(Z|\+.*)?$/, "");
      this.searchData.endTime = this.searchData.endTime.replace(/\//g, "-").replace(" ", "T").replace(/(Z|\+.*)?$/, "");
      this.$store
      .dispatch("nmjAppBanner/queryAll", this.searchData)
      .then((result) => {
        this.result = result;
        this.datas = result.datas || [];
        this.datas.forEach(item => {
          item.status = !!item.是否開啟
        });
        // this.datas.sort((a, b) => { a.排序 - b.排序})
        this.total = result.total;
        this.loading = false;
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);

      },
      (reject) => {
          this.total = 0;
          this.loading = false;
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    updateSort(item) {
      // item.排序 = parseInt(item.排序);
      if (item.排序 == 0 || isNaN(item.排序)) item.排序 = 1;
      if (item.排序 > this.total)
        return this.$eventBus.$emit( "showAlert", "排序不可大於最大排序(" + this.total + ")!!");

      this.$store.dispatch("nmjAppBanner/update", item).then(() => {
        this.getDataList()
      });;
    },
    updateStatus(item){
      item.是否開啟 = +item.status;
      this.$store.dispatch("nmjAppBanner/update", item).then(() => {
        this.getDataList();
        this.$eventBus.$emit( "showAlert", this.def.bannerType[item.分類] + "的狀態更新成功");
      });
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "NmjAppBannerEdit",
        params: {
          type: this.searchData.分類,
          searchData: this.searchData,
        },
      });
    },
    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "NmjAppBannerEdit",
        params: { 
          id: item.公告編號,
          type: this.searchData.分類,
          searchData: this.searchData,
        }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjAppBanner/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },

    //*---- 公告狀態
    //0：已過期 ，1：進行中，2：等待中
    GetStatus(item) {
      let type = 0;
      if (!item.是否開啟) type = -1;
      else if (Date.now() < (new Date(item.開始時間))) type = 2;
      else if (Date.now() < (new Date(item.結束時間))) type = 1;

      switch(type){
        case  0: return "已過期";
        case  1: return "進行中";
        case  2: return "等待上線";
        default: return "關閉";
      }
    },
    GetPlatfrom(item) {
      let arr = item.split(",");
      if (arr.length == 0) return "無";
      if (arr.length == Object.keys(this.def.gamePlatform).length) return "全部";
      return arr.map(i => this.def.gamePlatform[i]).join(", ");
    },
    uiDate(date){
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>