<template>
  <div class="tinymce-editor" id="tinymce4">
    <Editor
      :disabled="disabled"
      :init="init"
      :propLang="propLang"
      :propPlaceholder="propPlaceholder"
      :propPlugins="plugins"
      @onClick="onClick"
      @onPaste="onPaste"
      id="uuid"
      v-model="myValue"
    ></Editor>
  </div>
</template>

<style>
  .tinymce-editor .tox .tox-toolbar{
    background-color: #f8f8f8;
  }
</style>

<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import api from "@/assets/js/app-api";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/codesample";
import "tinymce/plugins/code";
import "tinymce/plugins/preview";
import "tinymce/plugins/link";
import "tinymce-placeholder-master/placeholder/plugin.js";

export default {
  name: "Tinymce",
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    propLang: {
      type: String,
      default: "zh-tw",
    },
    propPlaceholder: {
      type: String,
      default: "",
    },
    plugins: {
      type: [String, Array],
      default:
        "lists code image media table textcolor wordcount contextmenu codesample preview link",
    },
    font_formats: {
      type: String,
      default: `
        華康圓體='DFYuan';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;
        Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino;
        Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;
        Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif;
        Terminal=terminal,monaco;Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva;
        Verdana=verdana,geneva;Webdings=webdings; Wingdings=wingdings,zapf dingbats
      `,
    },
    toolbar1: {
      type: [String, Array],
      default:
        "undo redo | codesample code | formatselect| fontsizeselect | fontselect | bold italic underline strikethrough QPP_LINK ",
    },
    toolbar2: {
      type: [String, Array],
      default:
        "forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists link image media table | preview removeformat",
    },
    height: {
      type: String,
      default: "800",
    }
  },
  data() {
    return {
      //初始化
      init: {
        language_url: "/tinymce/langs/zh_TW.js", // 語系包路徑
        language: "zh_TW", //語系
        skin_url: "/tinymce/skins/ui/oxide", // skin路徑
        height: this.height, //編輯器高度
        plugins: this.plugins,
        toolbar1: this.toolbar1,
        toolbar2: this.toolbar2,
        content_css: "/tinymce/skins/ui/oxide/font.css?v=0309",
        allow_script_urls: true,
        font_formats: this.font_formats,
        // extended_valid_elements: "a[href|onclick|style|tarblank]",
        placeholder: this.propPlaceholder,
        readonly: 1,
        remove_trailing_brs: false,
        image_title: true,
        // link_list: [
        //   {
        //     title: "QPP 特殊連結",
        //     value: "javascript:getTargetArticle('請輸入文章ID')"
        //   }
        // ],
        branding: false, //是否禁用“Powered by TinyMCE”
        menubar: false, //置頂選單顯示
        setup: function (editor) {
          editor.ui.registry.addButton("QPP_LINK", {
            text: "QPP LINK",
            onAction: function () {
              tinymce.activeEditor.windowManager.open({
                title: "QPP LINK", // The dialog's title - displayed in the dialog header
                body: {
                  type: "panel", // The root body type - a Panel or TabPanel
                  items: [
                    // A list of panel components
                    {
                      type: "input",
                      name: "qppnewsName",
                      label: "顯示文字",
                    },
                    {
                      type: "input",
                      name: "qppnews",
                      label: "QPP文章ID",
                    },
                  ],
                },
                buttons: [
                  // A list of footer buttons
                  {
                    type: "submit",
                    text: "OK",
                  },
                ],
                onSubmit: function (api) {
                  var data = api.getData();
                  tinymce.activeEditor.execCommand(
                    "mceInsertContent",
                    false,
                    '<a href="#" onclick="getTargetArticle(\'' +
                      data.qppnews +
                      "');\">" +
                      data.qppnewsName +
                      "</a>"
                  );
                  api.close();
                },
              });
            },
          });
        },

        // 圖片上傳處理
        images_upload_handler: (blobInfo, success, failure) => {
          // 如有專案需更改上傳的api路徑
          // 記得filter.js的「uploadFile」，也要一起進行調整
          let fileApi;
          const isNewtalk =
            location.href.indexOf("/Newtalk/") !== -1 ? true : false;
          const data = new FormData();
          data.append("files", blobInfo.blob());
          if (isNewtalk) {
            // 新頭殼
            data.append("qppPath", "vote");
            fileApi = api.newtalk.article.fileUpload(data);
          } else {
            data.append("projectId", this.$store.state.projectId);
            data.append("module", "news");
            data.append("lang", this.propLang);
            data.append("multi", true);
            fileApi = api.file.upload(data);
          }
          fileApi
            .then((result) => {
              let imgUrl = "";
              if (isNewtalk) {
                imgUrl = result.data.files[0].imageUrl.replace(
                  /https:\/\/(s|v).newtalk.tw\//g,
                  "https://images.newtalk.tw/resizeForum/800/"
                );
              } else {
                imgUrl = result.data.url;
              }
              success(imgUrl);
            })
            .catch((err) => {
              console.log(err);
            });
        },
      },
      myValue: this.value,
      countNumber: "",
      pasteFiles: false,
    };
  },
  mounted() {
    tinymce.init({});
    this.wordCount();
  },
  methods: {
    wordCount() {
      setTimeout(() => {
        if (tinymce.activeEditor) {
          let wordcount = tinymce.activeEditor.plugins.wordcount;
          this.countNumber = wordcount.body.getCharacterCount();
          this.$emit("wordCount", this.countNumber);
        }
      }, 500);
    },
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    //可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = "";
    },

    onPaste(e) {
      const files = e.clipboardData.files;
      if (files && files.length > 0) {
        this.pasteFiles = true;
      }
    },
    newContent(val) {
      const editor = tinymce.activeEditor;
      if (editor) {
        editor.setContent(val);
      }
      this.$emit("input", val);
      this.wordCount();
    },
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      if (this.pasteFiles) {
        this.pasteFiles = false;
        this.global.regexImg64(this, newValue, this.propLang, this.newContent);
      } else {
        this.$emit("input", newValue);
        this.wordCount();
      }
    },
  },
};
</script>
