<template>
  <!-- 新增禁言 -->
  <div class="modal fade" id="reportAlert" ref="report_alert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">是否禁言以下門號？</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li :key="index" v-for="(item, index) in propsIDList">{{ item }}</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button @click="submitMute()" class="btn btn-primary" type="button">確定</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  components: {},
  props: {
    propsList: Array,
    propsIDList: Array,
    propsArticleId: Array,
    propsSerNum: Array,
  },
  data() {
    return {
      isNewAdd: false,
      isPhone: false,
      muteId: '',
      muteDay: '',
      muteNote: '',
      muteReason: '',
      editorName: this.$store.state.loginUser.EmployeeName,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      response: (state) => state.qppMute.muteResponse,
      query: (state) => state.qppMute.muteQuery,
    }),
  },
  watch: {
    response(value) {
      if (value.status == 1) {
        this.showSuccess(value.content[0]);
      }
    },
  },
  methods: {
    showReportAlert() {
      $(this.$refs.report_alert).modal('show');
    },
    showSuccess(content) {
      if (content.status == '1') {
        this.$eventBus.$emit('showAlert', '禁言成功');
      } else {
        if (content.errorMsg == '') {
          this.$eventBus.$emit('showAlert', '禁言失敗!!');
        } else {
          this.$eventBus.$emit('showAlert', content.errorMsg);
        }
      }
      $(this.$refs.report_alert).modal('hide');
      this.$emit('restList');
    },
    submitMute() {
      this.$eventBus.$emit(
        'showConfirm',
        '禁言後將會刪除文章/留言/回覆，且無法恢復，是否確定？',
        (isOK) => {
          if (isOK) {
            this.addMute(); // 禁言
          }
        }
      );
    },
    //已讀
    haveRead() {
      let data = {
        serialNumber: this.propsSerNum,
        reportTargetId: this.propsArticleId,
      };
      this.$store.dispatch('qppReport/edit', data);
      this.removeItems(); // 刪除文章
    },
    returnOrder() {
      return;
    },
    //禁言
    addMute() {
      let list = this.propsList.sort(function (a, b) {
        let aDate = Date.parse(a.reportDate);
        let bDate = Date.parse(b.reportDate);
        if (aDate > bDate) return 1;
        if (aDate < bDate) return -1;
        return 0;
      });

      let data = [];
      for (var i = 0; i < list.length; i++) {
        let days = '';
        if (
          list[i].days == '' ||
          list[i].days == undefined
        ) {
          days = 0;
        } else {
          days = list[i].days;
        }
        data[i] = {
          serialNumber: list[i].serialNumber,
          opreateId: this.editorName,
          muteId: list[i].reportedId,
          muteReason: list[i].reportData,
          note: list[i].note,
          dateNumber: days,
        };
      }
      this.$store.dispatch('qppMute/create', data);

      this.haveRead(); // 已讀
    },
    removeItems() {
      for (var i = 0; i < this.propsArticleId.length; i++) {
        if (this.propsArticleId[i].indexOf('C') === 0) {
          // 回覆
          let comment = {
            commentId: this.propsArticleId[i],
          };
          this.$store.dispatch('qppReport/deleteComment', comment);
        } else if (this.propsArticleId[i].indexOf('R') === 0) {
          // 留言
          let reply = {
            replyId: this.propsArticleId[i].split('R')[1],
          };
          this.$store.dispatch('qppReport/deleteReply', reply);
        } else if (this.propsArticleId[i].indexOf('P') === 0) {
          // 個人文章
          let query = {
            articleId: this.propsArticleId[i],
            uid: '0',
          };
          this.$store.dispatch('qppArticle/DeleteQuery', query);
        } else {
          // 官方文章
          let query = {
            articleId: this.propsArticleId[i],
            uid: '',
          };
          this.$store.dispatch('qppArticle/DeleteQuery', query);
        }
      }
    },
  },
};
</script>
