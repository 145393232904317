<template>
  <div id="ContactUsList">

    <section class="content-header">
      <h1>聯絡網銀-友善連結</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">聯絡網銀-友善連結</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">

          <Table2 :table-layout="tableLayout" :editor-layout="editorLayout" table-data="" />

      </div>
    </div>
    </section>
  </div>
</template>

<script>
import BannerPicker from "./components/BannerPicker.vue";
import Table2 from "./components/Table2.vue";

export default {
  components:{
    BannerPicker,
    Table2
  },
  props: {
    id: ""
  },

  data(){
    return{
      editorLayout:[

        // name="欄位名稱" fields=[{編輯欄位}]
        // type="編輯方式", content="編輯的資料", label="標籤" value="資料值"
        // TYPES: text, file, select, checkbox, radio, help (可擴充eg: date, number, textarea 等等)
        
        {name:"分類", fields:[
          {type:"select", content:"classTypeId",  value:"className"}
        ]},
        {name:"標題", fields:[
          {type:"text", content:"bannerTitle"}
        ]},
        {name:"網址", fields:[
          {type:"text", content:"bannerLink"},
          {type:"checkbox", label:"另開視窗", content:"isLinkOpen", value:"1:0"},
        ]},
        {name:"圖示", fields:[
          {type:"file", content:"filesUrl"},
          {type:"help", value:"圖片建議尺寸 158 * 66，請勿小於該尺寸。"} // value="文字內容"
        ]},
        {name:"狀態", fields:[
          {type:"radio", content:"isStop", label:"上架:下架", value:"0:1"}, // label="一項:二項[:n項]" value="一項:二項[:n項]"
          {type:"number", value:"1:0"}
        ]}
      ],

      tableLayout:[
        
        // name="欄位名稱", content="資料欄位", type="呈現方式", value="參數"
        // TYPES: text, number, image, url, boolean, date, html, options

        {name:"編號", content:"bannerId", type:"number"},
        {name:"標題", content:"bannerTitle", type:"text"},
        {name:"圖示", content:"filesUrl", type:"image"},
        {name:"連結網站", content:"bannerLink", type:"url"},
        {name:"狀態", content:"isStop", type:"boolean", value:"下架:上架"}, // value:"是文字 : 否文字"
        {name:"排序", content:"orderNum", type:"options", value:[{move:"更新"}]},
        {name:"功能", type:"options", value:[{edit:"修改"}, {remove:"刪除"}]}, // value:[{功能名稱:"按鈕上文字"}]
      ],
    }
  },
  
  
  methods: {
  }
}
</script>
