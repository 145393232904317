<template>
    <table class="table table-bordered classTypeTable InvoiceElectronicItemStyle"
        :class="發票聯別與類型.聯別 == 發票聯別Enum.二聯 ? 'style2聯' : 'style3聯'">
        <tbody>
            <tr class="bg-gray">
                <th class="text-nowrap" v-if="isEdit">功能</th>
                <th class="text-nowrap">品名</th>
                <th class="text-nowrap">數量</th>
                <th class="text-nowrap">單價<span class="color聯別">{{ 發票聯別與類型.聯別 == 發票聯別Enum.二聯 ? '(含稅)' : '(未稅)' }}</span>
                </th>
                <th class="text-nowrap">金額</th>
            </tr>
            <tr v-for="item of 商品明細" :key="item.id">
                <td class="text-center" v-if="isEdit">
                    <i class="fa fa-close" @click="$emit('刪除商品', item)"></i>
                </td>
                <td> <input class="form-control" type="text" v-model.trim="item.品名" :disabled="!isEdit" />
                </td>
                <td> <input class="form-control text-right" type="number" v-model.trim.number="item.數量"
                        :disabled="!isEdit" />
                </td>
                <td> <input class="form-control text-right" type="number" v-model.trim.number="item.單價"
                        :disabled="!isEdit" />
                </td>
                <td class="text-nowrap text-right" style="padding-right:12px;"> {{ 四捨五入(item.數量, item.單價)
                }} </td>
            </tr>
            <tr v-if="商品明細.length == 0">
                <td :colspan="isEdit ? 5 : 4" class="text-center">
                    無商品
                </td>
            </tr>
            <tr class="noBorder1">
                <td class="text-nowrap text-right" :colspan="isEdit ? 4 : 3">
                    {{ 發票聯別與類型.聯別 == 發票聯別Enum.三聯 ? "銷售額總計" : "總計(發票金額)" }}</td>
                <td class="text-nowrap text-right" style="padding-right:12px;">
                    {{ 發票聯別與類型.聯別 == 發票聯別Enum.三聯 ? 銷售額總計 : 總計 }}
                </td>
            </tr>
            <tr class="noBorder2">
                <td :colspan="isEdit ? 4 : 3" class="text-nowrap text-right">
                    營業稅別
                    <select class="form-control" style="display: inline-block;padding-right: 10px;width: 90px;"
                        :disabled="!isEdit" :value="營業稅別" @input="(e) => $emit('update:營業稅別', e.target.value)">
                        <option v-for="(item, key) in 稅別Enum" :value="item">{{ key }}</option>
                    </select>

                    <button style="margin-left:15px;margin-top: -7px;" type="button" class="btn btn聯別" :disabled="!isEdit"
                        @click="帶入營業稅(營業稅自動計算.值)">
                        {{ 營業稅自動計算.字串 }}{{ 營業稅自動計算.值 }}
                    </button>
                    營業稅
                </td>
                <td class="text-nowrap text-right" style="padding:0px;">
                    <input class="form-control text-right" type="number" :class="營業稅自動計算.值 == 營業稅 ? '' : 'style-conflict'"
                        :value="營業稅" @input="更新營業稅($event)" :disabled="!isEdit" ref="營業稅Ref">
                </td>
            </tr>
            <tr class="noBorder2">
                <td class="text-nowrap text-right" :colspan="isEdit ? 4 : 3">
                    {{ 發票聯別與類型.聯別 == 發票聯別Enum.二聯 ? "銷售額總計" : "總計(發票金額)" }}</td>
                <td class="text-nowrap text-right" style="padding-right:12px;">
                    {{ 發票聯別與類型.聯別 == 發票聯別Enum.二聯 ? 銷售額總計 : 總計 }}
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { 稅別Enum, 四捨五入, Get商品明細總計/*, Get總計*/, Get發票聯別And類型, 發票聯別Enum } from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
export default {
    components: {
    },
    props: ["isEdit", "營業稅", "營業稅別", "商品明細", "發票種類", "Const銷售總額", "Const發票金額"],
    data() {
        return {
            稅別Enum,
            發票聯別Enum
        };
    },
    watch: {

    },
    computed: {
        發票聯別與類型() {
            // { 聯別: 發票聯別, 類型: 發票類別 }
            // console.error("發票種類", this.發票種類)
            if (!this.發票種類) {
                return Get發票聯別And類型("2_0");
            }
            return Get發票聯別And類型(this.發票種類);
        },
        銷售額總計() {
            if (!this.isEdit) {
                return this.Const銷售總額;
            }
            if (this.發票聯別與類型.聯別 == 發票聯別Enum.三聯) {
                return Get商品明細總計(this.商品明細);
            } else {
                return Get商品明細總計(this.商品明細) - this.營業稅;
            }
        },
        總計() {
            if (!this.isEdit) {
                return this.Const發票金額;
            }
            if (this.發票聯別與類型.聯別 == 發票聯別Enum.三聯) {
                return Get商品明細總計(this.商品明細) + this.營業稅;
            } else {
                return Get商品明細總計(this.商品明細);
            }
        },
        營業稅自動計算() {
            if (this.營業稅別 == 稅別Enum.應稅 && this.發票聯別與類型.聯別 == 發票聯別Enum.三聯) {
                return {
                    字串: `銷售額總計*0.05=${this.銷售額總計}*0.05=`,
                    值: 四捨五入(this.銷售額總計, 0.05)
                }
            } else if (this.營業稅別 == 稅別Enum.應稅 && this.發票聯別與類型.聯別 == 發票聯別Enum.二聯) {
                return {
                    字串: `總計/1.05*0.05=${this.總計}/1.05*0.05=`,
                    值: 四捨五入(this.總計 / 1.05, 0.05)
                }
            } else {
                return {
                    字串: `自動帶入=`,
                    值: 0
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        帶入營業稅(num) {
            this.$emit('update:營業稅', num/*this.營業稅自動計算三聯*/);
        },
        更新營業稅(e) {
            let num = parseInt(this.$refs.營業稅Ref.value);
            num = isNaN(num) ? 0 : num;
            this.$emit('update:營業稅', num/*e.target.value*/);
        },
        四捨五入(數量, 單價) {
            return 四捨五入(數量, 單價);
        }
    },
}
</script>
<style scoped>
table.InvoiceElectronicItemStyle tr.noBorder1 td {
    /* background-color: green; */
    border-style: none hidden hidden hidden;
}

table.InvoiceElectronicItemStyle tr.noBorder2 td {
    /* background-color: green; */
    border-style: hidden;
}

.style2聯 .btn聯別 {
    background-color: #605ca8;
    border-color: #555299;
    color: white;
}

.style3聯 .btn聯別 {
    background-color: #D81B60;
    border-color: #ca195a;
    color: white;
}

.style2聯 .color聯別 {
    color: #555299;
}

.style3聯 .color聯別 {
    color: #ca195a;
}


.style2聯 .style-conflict {
    background-color: #9795c6;
}

.style3聯 .style-conflict {
    background-color: #e56b97;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    display: none;
}
</style>