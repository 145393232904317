import { render, staticRenderFns } from "./ChatMessageForm.vue?vue&type=template&id=683f4520&scoped=true"
import script from "./ChatMessageForm.vue?vue&type=script&lang=js"
export * from "./ChatMessageForm.vue?vue&type=script&lang=js"
import style0 from "./ChatMessageForm.vue?vue&type=style&index=0&id=683f4520&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683f4520",
  null
  
)

export default component.exports