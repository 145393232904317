<template>
    <div>
        <section class="content-header">
            <h1> 金流維護設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li class="active">金流維護設定 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="form-group">
                            <label class="control-label">廠商：</label>
                            <select class="form-control form-option" v-model="sel廠商">
                                <option value="-1">
                                    全部
                                </option>
                                <option v-for="(item, key) of 廠商設定Key" :key="item.編號" :value="item.編號">
                                    {{ item.名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">金流：</label>
                            <select class="form-control form-option" v-model="sel金流">
                                <option value="-1">
                                    全部
                                </option>
                                <!-- Object.keys(this.金流設定主檔Key) -->
                                <option v-for="(item, key) of 金流設定主檔Key" :key="key" :value="key">
                                    {{ item.金流名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">支付：</label>
                            <select class="form-control form-option" v-model="sel支付">
                                <option value="-1">
                                    全部
                                </option>
                                <option v-for="(item, key) of filterSel金流支付設定Key" :key="key" :value="key">
                                    {{ item.支付名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">

                            <label class="control-label">系統維護：</label>

                            <template v-if="一鍵維護狀態 == 0 || 一鍵維護狀態 == 1">
                                <div class="ckbx-style-8 ckbx toggleInput" style="padding-top:5px" @click="upd一鍵維護()">
                                    <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                        v-model="一鍵維護狀態" />
                                    <label :for="'check'"></label>
                                </div>
                            </template>
                            <template v-else>
                                <label class="control-label">維護狀態錯誤</label>
                            </template>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-block btn-warning btn-add" @click="openPopForm(false, null)">
                                <i class="fa fa-plus"></i> 新增維護時段
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">編號</th>
                                    <th class="text-nowrap">廠商</th>
                                    <th class="text-nowrap">金流</th>
                                    <th class="text-nowrap">付款方式</th>
                                    <th class="text-nowrap">維護時段</th>
                                    <th class="text-nowrap">備註</th>
                                    <th class="text-nowrap">功能</th>
                                </tr>
                                <template v-if="pageList.length > 0">
                                    <tr v-for="(item) in pageList" :key="item.編號"
                                        v-bind:class="{ 'maintain': item.isMaintain }">
                                        <td class="text-center">{{ item.編號 }}</td>
                                        <td class="text-center">{{ get廠商Name(item) }}</td>
                                        <td class="text-left" style="vertical-align: top;">
                                            <template v-if="item.金流設定主檔編號 == -1">
                                                <span style="line-height: 34px;">全部</span>
                                            </template>
                                            <template v-else>
                                                <button type="button" class="btn btn-info"
                                                    @click="showTip('tipA' + item.編號)">
                                                    <span>{{
                                                        金流設定主檔Key[item.金流設定主檔編號] && 金流設定主檔Key[item.金流設定主檔編號].金流名稱
                                                    }}</span>
                                                </button>
                                                <div class="payStyle" :id="'tipA' + item.編號" style="display: none;">
                                                    <ul>
                                                        <li>金流名稱：{{
                                                            金流設定主檔Key[item.金流設定主檔編號] && 金流設定主檔Key[item.金流設定主檔編號].金流名稱
                                                        }}</li>
                                                        <li>金流代碼：{{
                                                            金流設定主檔Key[item.金流設定主檔編號] && 金流設定主檔Key[item.金流設定主檔編號].金流代碼
                                                        }}</li>
                                                        <li>啟停用：
                                                            <span
                                                                v-if="金流設定主檔Key[item.金流設定主檔編號] && 金流設定主檔Key[item.金流設定主檔編號].啟用 == 1">啟用</span>
                                                            <span v-else class="text-red">停用</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </template>
                                        </td>
                                        <td class="text-left" style="vertical-align: top;">
                                            <template v-if="item.付款方式 == -1">
                                                <span style="line-height: 34px;">全部</span>
                                            </template>
                                            <template v-else>
                                                <button type="button" class="btn btn-info"
                                                    @click="showTip('tipB' + item.編號)">
                                                    <span>{{
                                                        金流支付設定Key[item.付款方式] && 金流支付設定Key[item.付款方式].支付名稱
                                                    }}</span>
                                                </button>
                                                <div class="payStyle" :id="'tipB' + item.編號" style="display: none;">
                                                    <ul>
                                                        <li>支付名稱：{{ 金流支付設定Key[item.付款方式] && 金流支付設定Key[item.付款方式].支付名稱 }}
                                                        </li>
                                                        <li>支付方式代碼：{{
                                                            金流支付設定Key[item.付款方式] &&
                                                            金流支付設定Key[item.付款方式].支付方式代碼
                                                        }}
                                                        </li>
                                                        <li>限制幣別：{{
                                                            金流支付設定Key[item.付款方式] &&
                                                            地區列表Key[金流支付設定Key[item.付款方式].限制幣別].幣別
                                                        }} </li>
                                                        <li>金流類型：{{
                                                            金流支付設定Key[item.付款方式] &&
                                                            金流類型設定Key[金流支付設定Key[item.付款方式].金流類型].類型
                                                        }} </li>
                                                        <li>是否開立發票：{{
                                                            金流支付設定Key[item.付款方式] && 金流支付設定Key[item.付款方式].是否開立發票 ==
                                                            1 ? '是' : '否'
                                                        }}
                                                        </li>
                                                        <li>啟停用：
                                                            <span
                                                                v-if="金流支付設定Key[item.付款方式] && 金流支付設定Key[item.付款方式].啟用 == 1">啟用</span>
                                                            <span v-else class="text-red">停用</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </template>
                                        </td>
                                        <td class="text-center">{{ item.開始維護時段 }}~{{ item.結束維護時段 }}</td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="stop(item)"
                                                v-if="item.isMaintain">立刻中止</button>
                                            <button class="btn btn-primary" @click="openPopForm(true, item)">修改</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="7">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="total > 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
        <div class="modal fade" ref="popForm">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 class="modal-title">{{ this.isEdit ? "修改" : "新增" }}</h4>
                    </div>
                    <div class="box-body">
                        <table style="margin:0 auto; width:580px;">
                            <tr>
                                <td class="text-right" style="padding-right:10px;width:100px;"><label>廠商：</label></td>
                                <td>
                                    <select class="form-control" v-model="edit.廠商設定主檔編號" :disabled="this.isEdit">
                                        <option value="-1">
                                            全部
                                        </option>
                                        <option v-for="(item, key) of 廠商設定Key" :key="item.編號" :value="item.編號">
                                            {{ item.名稱 }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right" style="padding-right:10px;width:100px;"><label>金流：</label>
                                </td>
                                <td>
                                    <select class="form-control" v-model="edit.金流設定主檔編號" :disabled="this.isEdit">
                                        <option value="-1">
                                            全部
                                        </option>
                                        <option v-for="(item, key) of filterEdit金流設定主檔Key" :key="item.編號" :value="item.編號">
                                            {{ item.金流名稱 }}
                                        </option>
                                    </select>
                                    <!-- {{ 金流設定主檔Key[edit.金流設定主檔編號] && 金流設定主檔Key[edit.金流設定主檔編號].金流名稱 }} -->
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right" style="padding-right:10px;width:100px;"><label>付款方式：</label>
                                </td>
                                <td>
                                    <select class="form-control" v-model="edit.付款方式" :disabled="this.isEdit">
                                        <option value="-1">
                                            全部
                                        </option>
                                        <option v-for="(item, key) of filterEdit金流支付設定Key" :key="item.編號" :value="item.編號">
                                            {{ item.支付名稱 }}
                                        </option>
                                    </select>
                                    <!-- {{ 金流支付設定Key[edit.付款方式] && 金流支付設定Key[edit.付款方式].支付名稱 }} -->
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right" style="padding-right:10px;width:100px;"><label>維護時間：</label>
                                </td>
                                <td>

                                    <DateTimePicker v-bind:propDateTime.sync="edit.開始維護時段"></DateTimePicker>
                                    <span class="col-md-10 col-md-span">~</span>
                                    <DateTimePicker v-bind:propDateTime.sync="edit.結束維護時段"></DateTimePicker>
                                    <!-- <input class="form-control" type="text" ref="daterange" /> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right" style="padding-right:10px;width:100px;"><label>備註：</label>
                                </td>
                                <td>
                                    <input class="form-control" type="text" v-model="edit.備註" />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" align="right">
                                    <button type="button" class="btn btn-primary" @click="send()">
                                        送出</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "@/assets/js/app-api";
import { IPSTool, APILib } from './IPSTool2'
import DateTimePicker from "@/components/DateTimePicker";
import moment from "moment";
import Pagination from "@/components/Pagination";
export default {
    components: {
        DateTimePicker, Pagination
    },
    data() {
        let pageSize = 10;
        return {
            一鍵維護狀態: -1,//(1: 開啟維護, 0:關閉維護)
            pageSize,
            pageNo: 1,
            total: 0,
            金流設定主檔Key: {},
            金流支付設定Key: {},
            list: [],
            廠商設定Key: {},
            廠商支付設定List: [],
            sel廠商: "-1",
            sel金流: "-1",
            sel支付: "-1",
            isEdit: true,
            edit: {

            },
        };
    },
    watch: {
        sel廠商(v1, v2) {
            if (v1 != v2) {
                this.onPageChange(1);
            }
        },
        sel金流(v1, v2) {
            if (v1 != v2) {
                this.onPageChange(1);
            }
        },
        sel支付(v1, v2) {
            if (v1 != v2) {
                this.onPageChange(1);
            }
        },
    },
    computed: {
        pageList() {
            let filter = this.list.filter((item) => {
                return (this.sel廠商 == -1 || (this.sel廠商 == item.廠商設定主檔編號))
            });
            filter = filter.filter((item) => {
                return (this.sel金流 == -1 || (this.sel金流 == item.金流設定主檔編號))
            });
            filter = filter.filter((item) => {
                return (this.sel支付 == -1 || (this.sel支付 == item.付款方式))
            });
            filter.sort((item1, item2) => {
                return moment(item2.結束維護時段).valueOf() - moment(item1.結束維護時段).valueOf();
            });
            for (let item of filter) {
                item.isMaintain = moment().isBetween(moment(item.開始維護時段), moment(item.結束維護時段));//現在時間是否落在維護時間區間
            }
            this.total = filter.length;

            if (this.$refs.pagination) {
                this.$refs.pagination.setPage(
                    this.pageNo,
                    this.total
                );
            }

            let list = [];
            let offset = (this.pageNo - 1) * this.pageSize;
            let end = (offset + this.pageSize >= filter.length) ? filter.length : offset + this.pageSize;
            for (let i = offset; i < end; i++) {
                list.push(filter[i]);
            }
            return list;
        },
        地區列表Key() {
            return this.$store.getters["IPSSetting/地區列表Key"];
        },
        金流類型設定Key() {
            return this.$store.getters["IPSSetting/金流類型設定Key"];
        },
        filterEdit金流設定主檔Key() {
            let tmp = {};
            let keys = Object.keys(this.金流設定主檔Key);
            for (let key of keys) {
                if (this.edit.廠商設定主檔編號 == -1) {
                    this.$set(tmp, key, this.金流設定主檔Key[key]);
                } else {
                    let find = this.廠商支付設定List.find((item) => {
                        return item.廠商設定主檔編號 == this.edit.廠商設定主檔編號 && item.金流設定主檔編號 == key;
                    });
                    if (find) {
                        this.$set(tmp, key, this.金流設定主檔Key[key]);
                    }
                }
            }
            return tmp;
        },
        filterEdit金流支付設定Key() {
            let tmp = {};
            let keys = Object.keys(this.金流支付設定Key);
            for (let key of keys) {
                if (this.edit.金流設定主檔編號 == -1) {
                    //
                } else {
                    let find = this.廠商支付設定List.find((item) => {
                        if (this.edit.廠商設定主檔編號 == -1) {
                            return item.金流設定主檔編號 == this.edit.金流設定主檔編號 && item.金流支付設定編號 == key;
                        } else {
                            //金流支付設定編號
                            return item.廠商設定主檔編號 == this.edit.廠商設定主檔編號 && item.金流設定主檔編號 == this.edit.金流設定主檔編號 && item.金流支付設定編號 == key;
                        }
                    });
                    if (find) {
                        this.$set(tmp, key, this.金流支付設定Key[key]);
                    }
                }
            }
            return tmp;
        },
        filterSel金流支付設定Key() {
            let tmp = {};
            let keys = Object.keys(this.金流支付設定Key);
            for (let key of keys) {
                if (this.sel金流 == -1) {
                    //
                } else {
                    let find = this.廠商支付設定List.find((item) => {
                        if (this.sel廠商 == -1) {
                            return item.金流設定主檔編號 == this.sel金流 && item.金流支付設定編號 == key;
                        } else {
                            //金流支付設定編號
                            return item.廠商設定主檔編號 == this.sel廠商 && item.金流設定主檔編號 == this.sel金流 && item.金流支付設定編號 == key;
                        }
                    });
                    if (find) {
                        this.$set(tmp, key, this.金流支付設定Key[key]);
                    }
                }
            }
            return tmp;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
            let 廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
            for (let i of 廠商設定List) {
                this.$set(this.廠商設定Key, i.編號, i);
            }
            let 金流設定主檔List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
            for (let i of 金流設定主檔List) {
                this.$set(this.金流設定主檔Key, i.編號, i);
            }
            let 金流支付設定List = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;
            for (let i of 金流支付設定List) {
                this.$set(this.金流支付設定Key, i.編號, i);
            }
            let 廠商支付設定List = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;//await IPSTool.callAPIList.call(this, api.IPSSetting.廠商支付設定_查詢, {});
            this.廠商支付設定List = IPSTool.deCodeData(廠商支付設定List, "額外限制");
            await this.refresh();
            await this.refresh一鍵維護();
            $(this.$refs.loading).hide();
        },
        async refresh() {
            this.onPageChange(1);
            this.list = APILib.IPS(await api.IPSSetting.金流維護設定_查詢({})).content;
        },
        get廠商Name(target) {
            if (target.廠商設定主檔編號 == -1) {
                return "全部";
            }
            return this.廠商設定Key[target.廠商設定主檔編號] && this.廠商設定Key[target.廠商設定主檔編號].名稱;
        },
        async stop(item) {
            let obj = {
                編號: item.編號,
                結束維護時段: new moment().format("YYYY/MM/DD HH:mm:ss"),
                // 備註: "#(取消)"
            };
            $(this.$refs.loading).show();
            try {
                APILib.IPS(await api.IPSSetting.金流維護設定_修改(obj)).content;
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            await this.refresh();
            $(this.$refs.loading).hide();
        },
        async send() {
            try {
                if (this.isEdit) {
                    let obj = {
                        編號: this.edit.編號,
                        開始維護時段: this.edit.開始維護時段,
                        結束維護時段: this.edit.結束維護時段,
                        備註: this.edit.備註,
                    };
                    if ((moment(this.edit.開始維護時段).valueOf() - moment(this.edit.結束維護時段).valueOf()) >= 0) {
                        throw "請重新選取時間區間";
                    }
                    $(this.$refs.loading).show();
                    APILib.IPS(await api.IPSSetting.金流維護設定_修改(obj)).content;
                } else {
                    //檢查資料
                    if (this.edit.金流設定主檔編號 != -1 && !this.filterEdit金流設定主檔Key[this.edit.金流設定主檔編號]) {
                        throw "請選擇金流";
                    }
                    if (this.edit.付款方式 != -1 && !this.filterEdit金流支付設定Key[this.edit.付款方式]) {
                        throw "請選擇付款方式";
                    }
                    if ((moment(this.edit.開始維護時段).valueOf() - moment(this.edit.結束維護時段).valueOf()) >= 0) {
                        throw "請重新選取時間區間";
                    }

                    let obj = {
                        廠商設定主檔編號: this.edit.廠商設定主檔編號,
                        金流設定主檔編號: this.edit.金流設定主檔編號,
                        付款方式: this.edit.付款方式,
                        開始維護時段: this.edit.開始維護時段,
                        結束維護時段: this.edit.結束維護時段,
                        備註: this.edit.備註,
                    };
                    $(this.$refs.loading).show();
                    APILib.IPS(await api.IPSSetting.金流維護設定_新增(obj)).content;
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            await this.refresh();
            $(this.$refs.loading).hide();
            $(this.$refs.popForm).modal("hide");
        },
        openPopForm(isEdit, item) {
            this.isEdit = isEdit;
            if (!isEdit) {
                item = {
                    // 編號: 2,
                    廠商設定主檔編號: -1,
                    金流設定主檔編號: -1,
                    付款方式: -1,
                    開始維護時段: moment().format("YYYY/MM/DD HH:mm:ss"),
                    結束維護時段: moment().format("YYYY/MM/DD HH:mm:ss"),
                    備註: ""
                };
            }

            this.edit = { ...item };
            this.edit.開始維護時段 = item.開始維護時段;
            this.edit.結束維護時段 = item.結束維護時段;
            $(this.$refs.popForm).modal("show");
        },
        showTip(domId) {
            $("#" + domId).slideToggle();
        },
        onPageChange(pageIdx) {
            this.pageNo = pageIdx;
        },
        async upd一鍵維護() {
            $(this.$refs.loading).show();
            let st = this.一鍵維護狀態;
            if (st == 0) {
                let res = await api.IPSSetting.OneClickMaintainST1({});
                this.$eventBus.$emit("showAlert", res.data);
            } else {
                let res = await api.IPSSetting.OneClickMaintainST0({});
                this.$eventBus.$emit("showAlert", res.data);
            }
            await this.refresh一鍵維護();
            $(this.$refs.loading).hide();
        },
        async refresh一鍵維護() {
            this.一鍵維護狀態 = -1;
            try {
                let st = await api.IPSSetting.GetOneClickMaintainST({});
                if (st && st.data && st.data.status == 1) {
                    //取得狀態成功
                    let 狀態 = st.data.content;
                    this.一鍵維護狀態 = 狀態;
                } else {
                    //發生錯誤
                    let msg = (st && st.data && st.data.message) ? st.data.message : "未知";
                    throw msg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
    },
};
</script>
  
<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.maintain {
    background-color: #deefff !important
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.payStyle>ul>li:first-child {
    padding: 5px 0px 5px 0px;
    color: #3c8dbc;
}

.payStyle>ul {
    border-bottom: 1px solid #f4f4f4;
}

.form-control {
    width: 100%;
}

.form-option {
    width: 250px;
}

.col-md-10 {
    padding-left: 0px;
    padding-right: 0px;
    width: 45%;
}

.col-md-span {
    width: 10%;
    text-align: center;
    line-height: 34px;
}
</style>