<template>
  <div id="ClassManager">
    <section class="content-header">
      <h1>分類管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">分類管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="addType()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
            <div class="select-group">
              <select class="form-control" id="selectUnit" @change="$router.replace({params:{module:$event.target.value}})">
                <option v-for="item in projectData.modules" :value="item.id" :key="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered classTypeTable" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px; width:20%">分類ID</th>
                  <th style="min-width: 150px; width:40%">分類名稱</th>
                  <th style="min-width: 100px; width:10%">module</th>
                  <th style="min-width: 100px; width:10%;">排序</th>
                  <th style="min-width: 100px; width:10%;">啟用狀態</th>
                  <th style="min-width: 140px; width:10%;">功能</th>
                </tr>
                <tr :key="item.classTypeId" v-for="(item, index) in datas">
                  <td>
                    <p v-show="!item.isEdit" class="m-0">{{ item.classId }}</p>
                    <input v-show="item.isEdit" type="text" class="form-control" v-model="item.classId" />
                  </td>
                  <td>
                    <p v-show="!item.isEdit" class="m-0">{{ item.className }}</p>
                    <table>
                      <tr><td></td><td></td></tr>
                    </table>
                    <fieldset v-show="item.isEdit" v-for="(detail, index) in details" :key="index">
                      <p>{{ projectData.langs[detail.lang] }}：</p>
                      <input type="text" class="form-control" v-model="detail.className" />
                    </fieldset>
                  </td>
                  <td>
                    <select :disabled="!item.isEdit" v-model="item.module" class="form-control" style="padding:0;">
                      <option v-for="(item, index) in projectData.modules" :value="item.id" :key="index">{{item.name}}</option>
                    </select>
                  </td>
                  <td align="center">
                    <p v-show="!item.isEdit" class="m-0">{{ item.sortId }}</p>
                    <input v-show="item.isEdit" type="number" class="form-control text-center" v-model.number="item.sortId" />
                  </td>
                  <td class="text-center" align="center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input type="checkbox" v-model="item.isStop" :false-value="1" :true-value="0" :disabled="isEditing && !item.isEdit" :id="'check'+index" name="ckbx-style-8"
                        @change="changeStatus(item)" />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <fieldset :disabled="isEditing && !item.isEdit">
                      <button v-show="!item.isEdit" @click="edit(item)" class="btn btn-primary">
                        修改
                      </button>
                      <button v-show="item.isEdit" @click="save(item)" class="btn btn-success">
                        儲存
                      </button>
                      <button v-show="!item.isEdit" @click="deleteType(item, index)" class="btn btn-danger ml-5">
                        刪除
                      </button>
                      <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default ml-5">
                        取消
                      </button>
                    </fieldset>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <pre style="line-height:1.1;">
      {{ datas }}
      {{ details }}
      <!-- {{ langTypes }} -->
      <!-- {{ detailClassLang }} -->
    </pre>
  </div>
</template>

<style scoped>
  .ckbx-style-8 input:disabled + label{
    opacity: .6;
    cursor: default;
  }
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import dfn from "./_Define";
// import { mapState, mapActions } from "vuex";

export default {
  props:{
    module: { type:String, default:dfn.projectData.modules[0].id,} 
  },
  data() {
    return {
      projectData: dfn.projectData,
      // projectId: this.$store.state.projectId,
      moduleId: "",
      langTypes: {},

      datas:[],
      details:[],


      detailClassLang: [],
      tempEdit: false,
      isEditing: false,
    };
  },
  created() {
    // this.getModuleQueryAll();
    this.getData();
  },
  mounted() {

  },
  computed: {
    // ...mapState({
      // classTypes: state => state.classTypes.queryAll,
      // response: state => state.classTypes.response,
      // projectData: state => state.module.queryAll,
      // detailQueryAll: state => state.classTypes.detailQueryAll
    // })
  },
  watch: {
    module(){
      this.getData();
    },
    response(value) {
      if (value.status == "1") this.$eventBus.$emit("showAlert", "更新成功!!");
      else this.$eventBus.$emit("showAlert", "更新失敗!!");
      this.queryAll();
    },
    projectData(value) {
      console.log(JSON.stringify(value, 2));
      this.langTypes = this.projectData.lang;
      if (this.projectData != null && this.projectData.module.length > 0)
        this.moduleId = this.projectData.module[0].id;
      this.queryAll();
      console.log(this.queryAll);
    },
    detailQueryAll() {
      let that = this;
      let Id = utils.getTimeId();
      this.detailClassLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          let findLang = false;
          Id = Id + i + 1;
          this.detailQueryAll.datas.some(detail => {
            if (that.langTypes[i].id == detail.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.classTypeDetailId = detail.classTypeDetailId;
                that.edit.className = detail.className;
              }
              that.detailClassLang.push({
                classTypeDetailId: detail.classTypeDetailId,
                lang: detail.lang,
                className: detail.className
              });
              findLang = true;
              return true;
            }
          });

          if (!findLang) {
            that.detailClassLang.push({
              classTypeDetailId: Id,
              lang: that.langTypes[i].id,
              className: ""
            });
          }
        }
      }
    }
  },
  methods: {
    // 顯示列表
    getData(){
      this.$store.dispatch("xsw/分類列表查詢", {module:this.module}).then(res => {
        this.datas = res.datas || [];
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      })
    },
    getDetail(classTypeId){
      this.$store.dispatch("xsw/分類查詢", {classTypeId}).then(res => {
        this.details = res || [];
      }, err => {
        this.$eventBus.$emit("showAlert", err);
      })
    },

    add(){

    },
    edit(item){
      if(this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.isEditing = true;

      item.isEdit = true;
      this.getDetail(item.classTypeId);
    },


    queryAll() {
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.moduleId,
        isDelete: 0
      });
    },

    detailLangData(classTypeId) {
      this.$store.dispatch("classTypes/classTypeDetailQueryAll", {
        classTypeId: classTypeId
      });
    },

    getModuleQueryAll() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    // 儲存更新
    workUpdate(item) {
      this.$store.dispatch("classTypes/classTypeUpdate", {
        master_model: item,
        detail_model: this.detailClassLang
      });
    },

    // 切換文字或輸入框
    change(item) {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      item.editor = item.editor == 1 ? 0 : 1;
      this.detailLangData(item.classTypeId);
    },

    // 儲存
    save(item) {
      this.tempEdit = false;
      
      if (item.module == "") {
        return this.$eventBus.$emit("showAlert", "請選擇module!!");
      }

      let api = item.classTypeId == "" ? "新增" : "修改";
      this.$store.dispatch("xsw/分類" + api, {
        master_model: item,
        detail_model: this.detailClassLang
      });
    },

    // 新增類別
    addType() {
       if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      this.detailClassLang = [];
      let Id = utils.getTimeId();
      this.classTypes.datas.push({
        classTypeId: "",
        projectId: this.$store.state.projectId,
        className: "",
        module: this.moduleId,
        isStop: 0,
        sortId: -1,
        editor: "1"
      });
      for (var i = 0; i < this.langTypes.length; i++) {
        Id = Id + i + 1;
        this.detailClassLang.push({
          classTypeDetailId: Id,
          lang: this.langTypes[i].id,
          className: ""
        });
      }
    },

    cancel(item) {
      this.isEditing = false;
      this.getData();
      // if (item.classTypeId == "") {
      //   this.classTypes.datas.splice(index, 1);
      // } else {
      //   this.queryAll();
      // }
    },

    // 改變狀態
    changeStatus(item) {
      this.$store.dispatch("classTypes/classTypeUpdate", {
        master_model: item
      });
    },

    // 刪除
    deleteType(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("classTypes/classTypeDelete", item);
        }
      });
    },

    getLangName(lang) {
      switch (lang) {
        case "zh-tw":
          return "繁";
        case "zh-cn":
          return "簡";
        case "en-us":
          return "英";
      }
    }
  }
};
</script>
