<template>
  <span>
    <input
      class="flat"
      type="checkbox"
      :value="propValue"
      :disabled="propDisabled"
      :checked="isChecked"
    />
  </span>
</template>

<script>
export default {
  props: {
    propValue: "",
    propDisabled: false,
    propChecked: false,
  },
  data() {
    return {
      isChecked: false,
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
    });
    $(this.$el).on("ifUnchecked", () => {
      this.emitFunc(0);
    });
    $(this.$el).on("ifChecked", () => {
      this.emitFunc(1);
    });
  },
  watch: {
    propChecked(value) {
      this.checkFunc(value);
    },
  },
  methods: {
    checkFunc(isChecked) {
      this.isChecked = isChecked;
      if (isChecked) {
        $(this.$el).iCheck("check");
      } else {
        $(this.$el).iCheck("uncheck");
      }
    },
    emitFunc(isChecked) {
      this.$emit("input", isChecked);
      this.$emit("update:checked", {
        value: this.propValue,
        checked: isChecked,
      });
    },
  },
};
</script>
