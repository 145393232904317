import api from "@/assets/js/app-api";

const state = {
  gameQueryAll: [],
  gameQuery: [],
  gameHallQueryAll: [],
  gameHallQuery: [],
  documentQueryAll: [],
  debuggerocumentQuery: [],
  hallContentQueryAll: [],
  hallContentQuery: [],
  companyQueryAll: [],
  playQueryAll: [],
  playQuery: [],
  response: "",
}

// getters
const getters = {
}

// actions
const actions = {
  gameQueryAll({ commit }, data) { // 遊戲列表
    return new Promise((resolve, reject) => {
      api.slot.game.queryAll(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setGameQueryAll', res.data.result);
          resolve(result);
        }
      })
    });
  },
  gameQuery({ commit }, data) { // 遊戲(單筆)
    return new Promise((resolve, reject) => {
      api.slot.game.query(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setGameQuery', res.data.result);
          resolve(result);
        }
      })
    });
  },
  gameHallQueryAll({ commit }, data) { // 遊戲館列表
    return new Promise((resolve, reject) => {
      api.slot.gameHall.queryAll(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setGameHallQueryAll', res.data.result);
          resolve(result);
        }
      })
    });
  },
  gameHallQuery({ commit }, data) { // 遊戲館(單筆)
    return new Promise((resolve, reject) => {
      api.slot.gameHall.query(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setGameHallQuery', res.data.result);
          resolve(result);
        }
      })
    });
  },


  gameDocumentQueryAll({ commit }, data) { // 遊戲館列表
    return new Promise((resolve, reject) => {
      api.slot.document.queryAll(data).then(res => {
        if (res.data.status == "1") {
          console.log(res.data.result);
          const result = res.data.result;
          commit('setDocumentQueryAll', res.data.result);
          resolve(result);
        }
      })
    });
  },
  gameDocumentQuery({ commit }, data) { // 遊戲館(單筆)
    return new Promise((resolve, reject) => {
      api.slot.document.query(data).then(res => {
        if (res.data.status == "1") {
          console.log(res.data.result);
          const result = res.data.result;
          commit('setdocumentQuery', res.data.result);
          resolve(result);
        }
      })
    });
  },



  hallContentQueryAll({ commit }, data) { // 遊戲館內容列表
    return new Promise((resolve, reject) => {
      api.slot.hallContent.queryAll(data).then(res => {
        if (res.data.status == "1") {
          console.log(res.data.result);
          const result = res.data.result;
          commit('setHallContentQueryAll', res.data.result);
          resolve(result);
        }
      })
    });
  },
  hallContentQuery({ commit }, data) { // 遊戲館內容(單筆)
    return new Promise((resolve, reject) => {
      api.slot.hallContent.query(data).then(res => {
        if (res.data.status == "1") {
          console.log(res.data.result);
          const result = res.data.result;
          commit('setHallContentQuery', res.data.result);
          resolve(result);
        }
      })
    });
  },
  companyQueryAll({ commit }, data) { // 開發商列表
    return new Promise((resolve, reject) => {
      api.slot.company.queryAll(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setCompanyQueryAll', res.data.result);
          resolve(result);
        }
      })
    });
  },
  playQueryAll({ commit }, data) { // 玩法列表
    return new Promise((resolve, reject) => {
      api.slot.play.queryAll(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setPlayQueryAll', res.data.result);
          resolve(result);
        }
      })
    });
  },
  playQuery({ commit }, data) { // 玩法(單筆)
    return new Promise((resolve, reject) => {
      api.slot.play.query(data).then(res => {
        if (res.data.status == "1") {
          const result = res.data.result;
          commit('setPlayQuery', res.data.result);
          resolve(result);
        }
      })
    });
  },
}

const mutations = {
  setGameQueryAll(state, queryAll) {
      state.gameQueryAll = queryAll;
  },
  setGameQuery(state, queryAll) {
    state.gameQuery = queryAll;
  },
  setGameHallQueryAll(state, queryAll) {
    state.gameHallQueryAll = queryAll;
  },
  setGameHallQuery(state, queryAll) {
    state.gameHallQuery = queryAll;
  },
  setDocumentQueryAll(state, queryAll) {
    state.documentQueryAll = queryAll;
  },
  setDocumentQuery(state, queryAll) {
    state.documentQuery = queryAll;
  },
  setHallContentQueryAll(state, queryAll) {
    state.hallContentQueryAll = queryAll;
  },
  setHallContentQuery(state, queryAll) {
    state.hallContentQuery = queryAll;
  },
  setCompanyQueryAll(state, queryAll) {
    state.companyQueryAll = queryAll;
  },
  setPlayQueryAll(state, queryAll) {
    state.playQueryAll = queryAll;
  },
  setPlayQuery(state, queryAll) {
    state.playQuery = queryAll;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}