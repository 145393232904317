<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>儲值歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>報表管理</li>
        <li class="active">儲值歷程</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <label class="control-label">交易日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    v-bind:propStartDate.sync="searchData.startTime"
                    v-bind:propEndDate.sync="searchData.endTime"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">交易方式：</label>
              <select
                class="selPaymentType form-control"
                required="required"
                v-model="searchData.paymentName"
              >
                <option
                  :value="item.value"
                  v-for="(item, index) in paymentOptions"
                  :key="index"
                >{{item.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">訂單狀態：</label>
              <select
                class="selPaymentType form-control"
                required="required"
                v-model="searchData.orderStatus"
              >
                <option value="null">全部</option>
                <option
                  :value="item.value"
                  v-for="(item, index) in getCurrentPaymentStatusOptions"
                  :key="index"
                >{{item.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">訂單編號：</label>
              <input type="text" class="selPaymentType form-control" v-model="searchData.orderID"/>
            </div>
            <div class="form-group">
              <label class="control-label">玩家識別值：</label>
              <input type="text" class="selPaymentType form-control" v-model="searchData.userId"/>
            </div>
            <div class="form-group">
              <button @click="clickSearch" class="btn btn-default" type="button">查詢</button>
            </div>
            <div class="form-group">
              <button @click="exportOrders" class="btn btn-success" type="button">匯出</button>
            </div>
            <p class="data-num">
              <span>總筆數：</span>
              <span id="total" style="margin-right: 10px;">{{ total }}</span>
              <span>不重複人數：</span>
              <span id="people" style="margin-right: 10px;">{{ people }}</span>
              <span>總金額：</span>
              <span id="amount" style="margin-right: 10px;">{{ amount }}</span>
            </p>
          </div>
          <div class="table-responsive mt-10">
            <component
              v-bind:is="getCurrentPayment"
              v-on:checkOrder="checkOrder"
              :orders="orders"
              ref="currentPayment"
            ></component>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import PaymentOrderListYoe from "@/views/Report/PaymentOrderListYoe.vue";
import PaymentOrderListGash from "@/views/Report/PaymentOrderListGash.vue";

export default {
  components: {
    Pagination,
    DateRangePicker,
    PaymentOrderListYoe,
    PaymentOrderListGash
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      pageSize: 10,
      paymentOptions: [
        { text: "YoeCard", value: "Yoe" },
        { text: "Gash", value: "Gash" }
      ],
      orderStatusOptions: [],
      seletedPayment: "Yoe",
      searchData: {
        serviceID: "12312",
        paymentName: "Yoe",
        paymentType: "Yoe",
        orderID: "",
        vendorOrderID: "",
        userId: "",
        orderStatus: null,
        givePointResult: -2,
        startTime: new moment().format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        startNo: 1,
        endNo: 10
      }
    };
  },
  async created() {},
  mounted() {},
  computed: {
    ...mapState({
      user: state => state.loginUser,
      orders: state => state.payment.orders,
      total: state => state.payment.total,
      people: state => state.payment.people,
      amount: state => state.payment.amount
    }),
    getCurrentPayment() {
      if (this.seletedPayment == "Yoe") return PaymentOrderListYoe;
      if (this.seletedPayment == "Gash") return PaymentOrderListGash;
      return PaymentYoeOrderList;
    },
    getCurrentPaymentStatusOptions() {
      if (this.searchData.paymentName == "Gash") {
        return [
          { text: "交易成功(S)", value: "S" },
          { text: "交易未完成(0)", value: "0" },
          { text: "交易待確認(W)", value: "W" },
          { text: "交易失敗(F)", value: "F" },
          { text: "交易逾時(T)", value: "T" },
          { text: "已調退(C)", value: "C" }
        ];
      } else {
        return [
          { text: "交易成功(S)", value: "S" },
          { text: "交易失敗(F)", value: "F" }
        ];
      }
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.startNo = (pageIdx - 1) * this.pageSize + 1;
      this.searchData.endNo = pageIdx * this.pageSize;
      this.queryAll();
    },
    queryAll() {
      this.searchData.paymentType = this.searchData.paymentName;
       if (this.searchData.orderStatus == "null") this.searchData.orderStatus = null;
      this.$store.dispatch("payment/queryAll", this.searchData);
      this.$store.dispatch("payment/queryStatistics", this.searchData);
    },
    clickSearch() {
      this.seletedPayment = this.searchData.paymentName;
      this.queryAll();
    },
    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.startNo = 0;
      tmpSearchData.endNo = 0;
      this.$store.dispatch("payment/export", tmpSearchData);
    },
    checkOrder(order, paymentType) {
      let request = {
        GameType: "DK",
        PaymentType: paymentType,
        OrderNumber: order["訂單編號"],
        note: "後台重新驗證、操作者:" + this.user.employeeName
      };
      this.$store.dispatch("payment/checkOrder", request).then(res => {
        console.log(res);
        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", "操作成功");
        } else {
          let code = res.data.stateCode;
          let msg = res.data.message;
          this.$eventBus.$emit("showAlert", "操作失敗(" + code + ")、" + msg);
        }
      });
    },
    checkPermission(permission) {
      return utils.checkByUserPermission(permission);
    }
  }
};
</script>

<style scoped>
.top-box .form-group {
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}

.top-box .input-group {
  min-width: 250px;
}

.selPaymentType {
  width: 150px;
}
</style>