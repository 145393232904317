<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>企業沿革編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>企業沿革列表</li>
        <li class="active">企業沿革編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <!--div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option
                      :key="index"
                      :value="item.classTypeId"
                      v-for="(item, index) in gameTypes.datas"
                    >{{item.className}}</option>
                  </select>
                </div>
              </div -->

              <!--div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"
                    
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div -->
              
              <div class="form-group onlym">
                <label class="col-sm-2 control-label" >
                  <span class="text-red">*</span> 開始日期：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">                        
                       <DatePicker class="start" :propValue.sync="startTime" ref="startTime" />
                      </div>
                    </div>
                  </div>
                </div>
                        
                <!--div class="col-sm-10">
                  <input
                    class="form-control" id="inputTitle1"
                    type="text"
                    v-model="edit.years"
                  />
                  <input
                    class="form-control" id="inputTitle2"
                    type="text"
                    v-model="edit.months"
                  />
                </div -->
              </div>
              
              <div class="form-group onlym">
                <label class="col-sm-2 control-label" >
                  <span class="text-red">*</span> 結束日期：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                         <DatePicker class="end" :propValue.sync="endTime" ref="endTime" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--div class="col-sm-10">
                  <input
                    class="form-control" id="inputTitle3"
                    type="text"
                    v-model="edit.yeare"
                  />
                  <input
                    class="form-control" id="inputTitle4"
                    type="text"
                    v-model="edit.monthe"
                  />
                </div-->
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle"><span class="text-red">*</span> 名稱：</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputinfo"><span class="text-red">*</span> 內容：</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputinfo"
                    required="required"
                    type="text"
                    v-model="edit.detailTopInfo"
                  />
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="inputLink">
                  <span class="text-red">*</span> 圖片:
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputLink"
                    required="required"
                    type="text"
                    v-model="edit.contentUrl"
                  />
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label" for="AdPic">
                  圖片：
                </label>
                <div class="col-sm-9">
                  <div class="pic" style="    background-color: #d0d0d0;     max-width: 33%;    position: relative;">
                    <img draggable="false" :src="edit.contentUrl" />
                     <div v-show="edit.contentUrl.length > 0 "
                        style="       padding-right: 0;
                          position: absolute;
                          right: -32px;
                          top: 0;
                          border: 1px solid black;
                          border-radius: 50%;
                          height: 25px;
                          width: 25px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          cursor: pointer;">
                        <div class="img-close fa fa-fw fa-close" @click="deleteFile(edit.contentUrl)"
                            style="    top: 0;"></div>
                    </div>
                  </div>
                  <input accept="image/*" id="AdPic" type="file" @change="onFileChange($event)" />
                  <div class="help-block">
                    <span class="text-red" id="note1">圖片建議300px * 160px</span>
                  </div>
                </div>
               
              </div>


              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="上架"
                        name="radioMode"
                        v-model="edit.isStop"
                      />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker  class="publicdate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker" type="text" />
                    </div>
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.datepicker{
  z-index: 50 !important;
}
.onlym .pull-right{
  float: left !important;
    border: 1px solid #d2d6de;
    width: 75px;
}
.onlym .form-control{
      width: 60px;
    min-width: 10px;
    border: 0;
}
</style>
<script>
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      module:"service",
      detailGameLang: [],
      startTime:'',
      endTime:'',
      edit: {
        contentId: utils.getTimeId(), //Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "business", //分類Id
        contentUrl: "", // 圖片的路徑
        contentDetailTopId: "", // 內容Id
        detailTopTitle: "", //標題
        detailTopInfo: "", //內容

        years : '',//開始年份
        months : '',//開始月份
        yeare : '',//結束年份
        monthe : '',//結束月份

        isStop: 0, // 狀態
        sortId: 0 // 排序
      },
      fileList: [],
      timeStringOption: {timeStyle: "short"}
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false
    });

  },
  computed: {
    ...mapState({
      gameTypes: state => state.classTypes.queryAll,
      game: state => state.game.query,
      detailQueryAll: state => state.game.detailQueryAll,
      projectData: state => state.module.queryAll,
      response: state => state.game.response,
      fileUploadResponse: state => state.file.response,
      imgUrlList: state => state.game.imgList
    })
  },
  watch: {
    game() {
      this.edit = this.game;
      if (this.edit.publishTime != "") {
        $(".publicdate").val(
          new Date(this.edit.publishTime).toLocaleDateString()
        );
        $(".timepicker").val(
          new Date(this.edit.publishTime).toLocaleTimeString("en-US", this.timeStringOption)
        );        
      }

      if (this.edit.years != "") {
        //2023/07/12
        var date1=this.edit.years+ '/' + this.edit.months +'/1';
       // $(/ ".start" ).datepicker( "option", "dateFormat", "yy-mm-dd" );

        $('.start').datepicker( "setDate", date1 ); 
        var date2=this.edit.yeare+'/'+this.edit.monthe+'/1';
        $('.end').datepicker( "setDate", date2 );
        //$( ".end" ).datepicker( "option", "dateFormat", "yy-mm-dd" );

      }
      this.GetGameLangData();
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetGameClassTypes();
      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetGameData();
      } else {
        this.detailGameLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.contentId + i + 1;
          this.detailGameLang.push({
            gameDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo
          });
        }
      }
    },
    detailQueryAll() {
      let that = this;
      this.detailGameLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          const Id = this.edit.contentId + i + 1;
          let findLang = false;
          this.detailQueryAll.datas.some(details => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.gameDetailId = details.gameDetailId;
                that.edit.detailTopTitle = details.title;
                that.edit.detailTopInfo = details.info;
              }
              that.detailGameLang.push({
                gameDetailId: details.gameDetailId,
                lang: details.lang,
                title: details.title,
                info: details.info
              });
              findLang = true;
              return true;
            }
          });
          if (!findLang) {
            that.detailGameLang.push({
              gameDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: ""
            });
          }
        }
      //  this.GetFileList();
      }
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: "XacServiceList",
          params: { searchData: this.$route.params.searchData }
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    imgUrlList() {
      this.fileList = [];
      this.fileList = this.imgUrlList;
    }
  },
  methods: {
    //取得該銷售據點資料
    GetGameData() {
      this.$store.dispatch("game/gameQuery", {
        contentId: this.$route.params.id
      });
    },

    //取得該銷售據點多語系資料
    GetGameLangData() {
      this.$store.dispatch("game/gameDetailQueryAll", {
        contentId: this.$route.params.id
      });
    },

    //取得分類資料
    GetGameClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    // GetFileList() {
    //   this.SetLangData(this.editLang);
    //   this.$store.dispatch("game/gameImgList", {
    //     contentId: this.edit.contentId
    //   });
    // },

    //取得該語系資料
    GetDetailGameLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.gameDetailId = this.detailGameLang[i].gameDetailId;
          this.edit.detailTopTitle = this.detailGameLang[i].title;
          this.edit.detailTopInfo = this.detailGameLang[i].info;
        }
      }
    },

    //更換語系
    // ChangeLang(lang) {
    //   this.removeFile();
    //   this.SetLangData(this.editLang);
    //   this.GetDetailGameLang(lang);
    //   this.editLang = lang;
    //   this.GetFileList();
    // },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailGameLang[i].title = this.edit.detailTopTitle;
          this.detailGameLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XacServiceList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }
      
      let AdPic = document.getElementById("AdPic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          '',
          this.edit.bannerId,
          false,
          false
        );

      } 
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          that.edit.contentUrl = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },
    removeFile() {
      document.getElementById("AdPic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", 'omp_xac');
      data.append("module", 'business');
      data.append("moduleId", this.edit.contentId);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);
      this.$store.dispatch("file/fileUpload", data).then(res => {
        if (res.status != "0"){
            this.edit.contentUrl = res.url;    
        }
      });

    },
  

    deleteFile(item) {
      this.edit.contentUrl = '';
      // this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
      //   if (isOK) {
      //     this.$store.dispatch("file/fileDelete", item).then(res => {
      //       if (res.status != "0") {
      //         //this.GetFileList();
      //       }
      //     });
      //   }
      // });
    },

    //送出
    async Save() {

      this.edit.isStop = parseInt(this.edit.isStop);

      var dateFrom = $( ".start" ).datepicker( "getDate" );
      var dateEnd = $( ".end" ).datepicker( "getDate" );

      const diffMillis = dateEnd - dateFrom;

      if(diffMillis < -50){
          this.$eventBus.$emit("showAlert", "結束日期不能小於起始日期");
          return;
      }
      this.edit.years = parseInt(dateFrom.getFullYear());
      this.edit.months =dateFrom.getMonth() + 1;
      this.edit.yeare = parseInt(dateEnd.getFullYear());
      this.edit.monthe = dateEnd.getMonth() + 1;
      this.SetLangData(this.editLang);
      this.edit.publishTime = $(".publicdate").val() + " " + $(".timepicker").val();
      this.edit.publishTime = new Date(this.edit.publishTime + " UTC");

     /* if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }*/

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "名稱不能為空");
        return;
      }
      if (this.edit.detailTopInfo == "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return;
      }

      if (this.isEdit) {
        this.$store.dispatch("game/gameUpdate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      } else {
        this.$store.dispatch("game/gameCreate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      }
    }
  }
};
</script>