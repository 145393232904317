/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import define from '@/views/projects/FC/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcService.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcService.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcService.update(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.fcService.queryAll(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    res.data.result.datas.forEach(item => {
                        items.push({
                            '聯絡時間': utils.formatTime(item.reactionTime, 'YYYY/MM/DD HH:mm:ss'),
                            '玩家UID': item.reactionUId,
                            '平台': define.contactPlatforms[item.platform],
                            '手機型號': item.phoneModel,
                            '系統版本': item.systemVersion,
                            '網路環境': item.network,
                            'APP版本': item.appVersion,
                            '問題類別': define.contactTypes[item.reactionType],
                            '客服人員': item.replyUName,
                            '回覆時間': item.replyTime ? utils.formatTime(item.replyTime, 'YYYY/MM/DD HH:mm:ss') : '',
                            '處理進度': define.serviceStatus[item.status],
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_聯絡我們問題列表.xlsx`,
                        '聯絡我們'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}