// 四則運算 https://ithelp.ithome.com.tw/articles/10229666
//除法
// export const Div = function (arg1, arg2) {
//     var t1 = 0, t2 = 0, r1, r2;
//     try {
//         t1 = arg1.toString().split(".")[1].length;
//     } catch (e) { }
//     try {
//         t2 = arg2.toString().split(".")[1].length;
//     } catch (e) { }

//     r1 = Number(arg1.toString().replace(".", ""));
//     r2 = Number(arg2.toString().replace(".", ""));
//     return (r1 / r2) * pow(10, t2 - t1);
// }

//乘法
export const Mul = function (arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
        //
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
        //
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

//加法
// export const Add = function (arg1, arg2) {
//     var r1, r2, m;
//     try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
//     try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
//     m = Math.pow(10, Math.max(r1, r2));
//     return (arg1 * m + arg2 * m) / m;
// }

// //減法
// export const Sub = function (arg1, arg2) {
//     var r1, r2, m, n;
//     try {
//         r1 = arg1.toString().split(".")[1].length;
//     } catch (e) { r1 = 0 }
//     try {
//         r2 = arg2.toString().split(".")[1].length;
//     } catch (e) { r2 = 0 }
//     m = Math.pow(10, Math.max(r1, r2));
//     n = (r1 >= r2) ? r1 : r2;
//     return ((arg1 * m - arg2 * m) / m).toFixed(n);
// }

//eqMin為false時  min>val；decimal：小數點位數
//eqMin為true時   min>=val；decimal：小數點位數
export const 檢查輸入數值 = function (val, min, decimal = 4, eqMin = false) {
    if (isNaN(parseInt(val))) {
        return false;
    }

    let pow = Math.pow(10, decimal);
    val = Mul(val, pow);
    min = Mul(min, pow);

    let n = parseInt(val);
    if (n + "" != val + "") {
        return false;
    }
    if (n < min) {
        return false;
    }
    if (!eqMin && n == min) {
        return false;
    }
    return true;
}