export const STEnum = {
    View: 0,
    Update: 1,
}
export const MsgTypeEnum = {
    Text: 1,  //文字
    Photo: 2, //圖片
    Voice: 3, //語音
    Sticker: 4 //貼圖
}

export const ChannelTypeEnum = {
    Public: 1, //公開頻道
    Private: 2, //私人頻道
    Game: 3, //遊戲頻
    Basic: 4, //一般頻
    Guild: 5 //公會頻
}
export const 關鍵字特殊符號 = "❤●";
export const 關鍵字特殊處理Function = function (str) {
    str = str.replace(new RegExp("[" + 關鍵字特殊符號 + "]", "g"), "");//移除自定義的特殊符號
    str = str.toLowerCase();//一律轉小寫
    return str;
}
//去除重複
export const removeDuplicates = function (tmps) {
    let groups = {};
    for (let i of tmps) {
        groups[i] = i;
    }
    return Object.keys(groups);
}
export const parseData = function (list) {
    let resList = [];
    for (let i of list) {
        resList.push({ text: i.text, 原始Text: i.text, id: i.id, st: STEnum.View });
    }
    return resList;
}
import moment from "moment";
import { ProjectSetting } from "@/store/MessageSystem/messageSystemConst";
import api from "@/assets/js/app-api";

export const ACTEnum = {
    密: "密",
    收: "收",
}
export async function GetMonitoringHistory(query, project) {
    let res = (await api.CS.GetMonitoringHistory({
        ChannelType: query.ChannelType,
        Partaker: query.Partaker,
        SDate: query.SDate,
        EDate: query.EDate,
        Page: query.Page,
        ServiceNo: project
    })).data;

    let 頻道類型設定 = ProjectSetting.ChannelTypes(project)
    for (let item of res.Data) {
        item.SendTime = moment(item.SendTime).format("YYYY-MM-DD HH:mm:ss");
        let _act = item.Channel;
        let _接收人 = "";
        let _玩家暱稱 = query.Partaker;
        if (頻道類型設定.privateTypes.includes(item.ChannelType)) {
            _act = query.Partaker == item.Sender ? ACTEnum.密 : ACTEnum.收;
            _接收人 = query.Partaker == item.Sender ? item.Receiver : item.Sender;
        }
        item._act = _act;
        item._接收人 = _接收人;
        item._玩家暱稱 = _玩家暱稱;
    }
    return {
        Total: res.Total,
        list: res.Data
    };
}