<template>
  <div id="NewtalkAnnouncementEdit">
    <section class="content-header">
      <h1>討論區公告內容</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/Newtalk/AnnouncementList">
            討論區公告管理
          </router-link>
        </li>
        <li class="active">討論區公告內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <div class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 公告分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" disabled>
                    <option value="官方">官方</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 標題：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    maxlength="50"
                    placeholder="請輸入標題，上限50字"
                    type="text"
                    required
                    v-model.trim="data.articleTitle"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce
                      v-model="data.articleContent"
                      :propLang="editLang"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 標籤：
                </label>
                <div class="col-sm-10">
                  <multiselect
                    placeholder="自行輸入標籤"
                    label="Tag"
                    track-by="Tag"
                    required
                    :options="options"
                    :hide-selected="true"
                    :internal-search="false"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="data.tags"
                    selectLabel=""
                    @tag="addTag"
                    @search-change="updateTags"
                    max-elements="已達標籤上限"
                  >
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.Tag }}
                        </span>
                        <span class="option__small" style="float: right">
                          使用文章數：{{ props.option.UsedCount || 0 }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model.number="data.isDelete"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="2"
                      message="下架"
                      name="radioMode"
                      v-model.number="data.isDelete"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-warning btn-lg"
                  style="margin-right: 10px"
                  type="button"
                  v-if="data.isDelete === 2"
                  @click="submit(true)"
                >
                  預覽
                </button>
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="submit(false)"
                >
                  確認送出
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import multiselect from "vue-multiselect";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";

export default {
  components: {
    multiselect,
    tinymce,
    iCheckRedioButton,
  },
  data() {
    return {
      timer: null,
      editLang: "zh-tw",
      titleMax: 50,
      contentMax: 500,
      id: this.$route.params.id,
      options: [],
      data: {
        tags: [],
        categoryNo: 1, // 1: 討論區
        isDelete: 2,
        articleId: "",
        articleTitle: "",
        articleContent: "",
      },
    };
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").unbind("focus");
    this.checkSession();
    this.getData();
    this.updateTags();
  },
  methods: {
    getData() {
      if (this.id) {
        this.data.articleId = this.id;
        this.$store
          .dispatch("newtalk/articleQuery", this.data)
          .then((result) => {
            this.data = result || {};
          });
      }
    },
    updateTags(query) {
      if (this.lastTime) clearTimeout(this.lastTime);
      this.lastTime = setTimeout(() => {
        this.$store
          .dispatch("newtalk/tagQueryAll", { search: query || "" })
          .then((result) => {
            if (result) {
              this.options = [];
              result.forEach((item) => {
                this.options.push({
                  Tag: item.title,
                  UsedCount: item.usedCount,
                });
              });
            }
          });
      }, 1000);
    },
    addTag(newTag) {
      const tag = { Tag: newTag.trim() };
      this.options.push(tag);
      this.data.tags.push(tag);
    },
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
      }, 600000);
    },
    backList() {
      this.$router.push({
        name: "NewtalkAnnouncementList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    preview() {
      window.open(
        `${process.env.VUE_APP_NEWTALKWEB_PATH}/post/${this.id}?preview=1`
      );
    },
    updateFirstImage() {
      this.data.articleContent = this.data.articleContent.replace(/amp;/g, "");
      const imgs = this.data.articleContent.split(
        /<img\s.*?src=(?:'|")([^'">]+)(?:'|")/g
      );
      this.data.imgFirstUrl = imgs.length > 1 ? imgs[1] : "";
    },
    submit(preview) {
      if (this.data.articleTitle === "") {
        this.$eventBus.$emit("showAlert", "標題不能為空");
        return;
      } else if (this.data.articleTitle.length > 50) {
        this.$eventBus.$emit("showAlert", "標題長度限制50字");
        return;
      }
      if (this.data.articleContent === "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return;
      }
      if (this.data.tags.length < 1) {
        this.$eventBus.$emit("showAlert", "請輸入至少一個標籤");
        return;
      }
      this.updateFirstImage();
      const action = this.id ? "Update" : "Create";
      this.$store
        .dispatch(`newtalk/article${action}`, this.data)
        .then((result) => {
          if (preview) {
            this.id = result || this.id;
            this.data.articleId = this.id;
            this.preview();
          } else {
            this.backList();
          }
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>

<style>
.multiselect__tags {
  min-height: 34px !important;
  height: auto !important;
}
</style>
