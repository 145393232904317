<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>禮物發送</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>禮物發送</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 玩家帳號：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="account"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發送禮物：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="giftId">
                    <option disabled="disabled" value>請選擇禮物</option>
                    <option
                      :key="index"
                      :value="item.id"
                      v-for="(item, index) in gifts"
                    >{{item.id}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="submit">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      account:"",
      giftId:"",
      gifts:[]
    };
  },
  created() {
    this.queryGiftList();
  },
  mounted() {
      
  },
  methods: {
    // 顯示列表
    async queryGiftList(){
        try {
            this.gifts = [];
            var res = await api.asgard.getGiftList();
            res = res.data;
            if( res.status != "1" ){
                this.$eventBus.$emit("showAlert", res.message);
                return;
            }
            this.gifts = res.result;
            if( this.gifts.length > 0 ) this.giftId = this.gifts[0].id;
        } catch (error) {
            this.$eventBus.$emit("showAlert", error);
        }
    },
    async submit() {
        try {
            if( this.account == ""){
                this.$eventBus.$emit("showAlert", "帳號不得為空");
                return;
            }
            if( this.giftId == ""){
                this.$eventBus.$emit("showAlert", "請選擇禮物");
                return;
            }
            var res = await api.asgard.sendPlayerGift({
                accountID : this.account,
                giftID : this.giftId
            });
            res = res.data;
            if( res.status != "1" ){
                this.$eventBus.$emit("showAlert", res.message);
                return;
            }
            this.account = "";
            this.$eventBus.$emit("showAlert", res.result);
        } catch (error) {
            this.$eventBus.$emit("showAlert", error);
        }
    },
  }
};
</script>