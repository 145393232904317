<template>
  <div id="BeforeShareList" class="searchList">
    <section class="content-header">
      <h1>獲獎名單</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">獲獎名單</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList mb-10">

            <div class="date-group" style="width: auto;">
              <label class="control-label">日期：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.endTime"
                    :propStartDate.sync="searchData.startTime"
                  />
                </div>
              </div>
            </div>

            <div class="input-group search-group mr-10">
              <label class="control-label">活動：</label>
              <div class="select-group" style="width: 200px;">
              <select
                class="form-control"
                v-model="searchData.eventId"
                @change="resetDataList()"
              >
                <option :value="15003">嘉年華滿額儲值活動</option>
                <option :value="15004">滿額儲值活動第五輪</option>
                <option :value="15006">滿額儲值活動第六輪</option>
              </select>
            </div>
            </div>
            
            <div class="input-group search-group mr-10">
              <label class="control-label">輸入ID：</label>
              <input
                type="text"
                class="form-control pull-right"
                placeholder="輸入ID"
                v-model="searchData.playerCode"
              />
            </div>

            <div class="input-group search-group">
              <label class="control-label">輸入門號：</label>
              <input
                type="text"
                class="form-control pull-right"
                placeholder="輸入門號"
                v-model="searchData.phone"
              />
            </div>

            <div class="btns">
              <button @click="resetDataList()" class="btn btn-primary mr-10" type="button">查詢</button>
              <button @click="exportOrders()" class="btn btn-success" type="button">資料匯出</button>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%">編號</th>
                  <th class="text-nowrap" style="width: 10%">姓名</th>
                  <th class="text-nowrap" style="width: 10%">玩家ID</th>
                  <th class="text-nowrap" style="width: 15%">Email</th>
                  <th class="text-nowrap" style="width: 10%">連絡電話</th>
                  <th class="text-nowrap" style="width: 20%">地址</th>
                  <th class="text-nowrap" style="width: 15%">建立時間</th>
                  <th class="text-nowrap" style="width: 15%">修改時間</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="total === 0">
                  <td colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-nowrap">{{ index + 1 }}</td>
                  <td class="text-nowrap">{{ item.name }}</td>
                  <td class="text-nowrap">{{ item.playerCode }}</td>
                  <td class="text-nowrap">{{ item.email }}</td>
                  <td class="text-nowrap">{{ item.phone }}</td>
                  <td class="text-nowrap">{{ item.address }}</td>
                  <td class="text-nowrap">{{ item.createTime }}</td>
                  <td class="text-nowrap">{{ item.updateTime }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      searchData: {
        eventId: 15006,
        playerCode: "",
        phone: "",
        pageNum: 1,
        pageSize: 20,
        startTime: new moment().subtract(3, "month").format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      datas: [],
      total: 0
    };
  },
  watch: {},
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.searchData.startTime = moment(this.searchData.startTime).format();
      this.searchData.endTime = moment(this.searchData.endTime).format();

      api.kf3.event.winnerQueryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.datas = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    exportOrders() {
      let typeName = "獲獎名單";
      let exportData = [];

      this.datas.forEach(item => {
        exportData.push({
          時間: item.time,
          姓名: item.name,
          玩家ID: item.playerCode,
          Email: item.email,
          連絡電話: item.phone,
          地址: item.address,
        })
      });
  
      appUtils.exportData(
        exportData,
        appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
        typeName
      );
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
