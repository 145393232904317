<template>
  <!--序號產生-->
  <div class="modal fade" ref="AddWhiteAcc">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">添加白名單</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <div class="form-group name-group">
                      <div class="right">
                        <input v-model="edit.phoneNumber" placeholder="請輸入門號" type="text" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <button @click="Add()" class="btn btn-block btn-danger btn-cog" type="button">確定</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      edit: {
        phoneNumber: ""
      }
    };
  },
  computed: {
    ...mapState({
      response: state => state.phoneVerify.response
    })
  },
  watch: {
    response(value) {
      if (value.status == "1") this.refreshList();
    }
  },
  methods: {
    show() {
      this.edit.phoneNumber = "";
      this.edit.gameType = "DK";
      $(this.$refs.AddWhiteAcc).modal("show");
    },
    Add() {
      if (this.edit.phoneNumber == "") {
        this.$eventBus.$emit("showAlert", "請填寫手機門號!!");
        return;
      }

      this.$store.dispatch("phoneVerify/Create", this.edit);
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>