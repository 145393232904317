<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="muteAdd">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">新增禁言</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMuteId">
                <span class="text-red">*</span> 使用者ID：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputMuteId"
                  required="required"
                  type="text"
                  v-model.trim="data.muteId"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMuteDay">
                <span class="text-red">*</span> 禁言時間：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputMuteDay"
                  min="1"
                  placeholder="輸入天數"
                  required="required"
                  type="number"
                  v-model.number="data.dateNumber"
                />
                <p class="text-red mt-10">
                  * 不輸入或天數大於3650天，為永久禁言。
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputMuteNote">
                備註：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputMuteNote"
                  type="text"
                  v-model="data.note"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="submitMute()" class="btn btn-primary" type="button">
            新增
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */

export default {
  data() {
    return {
      data: {
        opreateId: this.$store.state.loginUser.EmployeeName,
        muteId: "",
        muteReason: "",
        note: "",
        dateNumber: "",
      },
    };
  },
  methods: {
    showAddMuteBox() {
      this.data.muteId = "";
      this.data.muteReason = "";
      this.data.note = "";
      this.data.dateNumber = "";
      $(this.$refs.muteAdd).modal("show");
    },
    submitMute() {
      if (this.data.muteId === "") {
        this.$eventBus.$emit("showAlert", "請輸入使用者ID");
        return;
      }
      if (
        this.data.muteId.length > 20 ||
        !this.isPhoneNumber(this.data.muteId)
      ) {
        this.$eventBus.$emit("showAlert", "使用者ID格式錯誤");
        return;
      }
      if (this.data.dateNumber === "" || this.data.dateNumber > 3650) {
        this.$eventBus.$emit(
          "showConfirm",
          "天數為空或超過3650天，將設定為永久禁言，是否確認?",
          (isOK) => {
            if (isOK) {
              this.checkMute();
            }
          }
        );
      } else {
        this.checkMute();
      }
    },
    checkMute() {
      this.$store
        .dispatch("newtalk/muteQueryAll", {
          muteId: this.data.muteId,
        })
        .then((result) => {
          if (result.totalNumber === 0) {
            this.updateMute(false);
          } else {
            this.$eventBus.$emit(
              "showConfirm",
              "此會員已經為禁言狀態，是否覆蓋最新禁言？",
              (isOK) => {
                if (isOK) {
                  this.updateMute(true);
                }
              }
            );
          }
        });
    },
    updateMute(isEdit) {
      this.data.isEdit = isEdit;
      this.data.dateNumber = this.data.dateNumber || 0;
      this.$store.dispatch("newtalk/muteCreate", [this.data]).then((result) => {
        $(this.$refs.muteAdd).modal("hide");
        this.$emit("updateList");
      });
    },
    isPhoneNumber(num) {
      const phones = [
        /^(\+?213|0)(5|6|7)\d{8}$/, //ar-DZ
        /^(!?(\+?963)|0)?9\d{8}$/, //ar-SY
        /^(!?(\+?966)|0)?5\d{8}$/, //ar-SA
        /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/, //en-US
        /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/, //cs-CZ
        /^(\+?49[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/, //de-DE
        /^(\+?45)?(\d{8})$/, //da-DK
        /^(\+?30)?(69\d{8})$/, //el-GR
        /^(\+?61|0)4\d{8}$/, //en-AU
        /^(\+?44|0)7\d{9}$/, //en-GB
        /^(\+?852\-?)?[569]\d{3}\-?\d{4}$/, //en-HK
        /^(\+?91|0)?[789]\d{9}$/, //en-IN
        /^(\+?64|0)2\d{7,9}$/, //en-NZ
        /^(\+?27|0)\d{9}$/, //en-ZA
        /^(\+?26)?09[567]\d{7}$/, //en-ZM
        /^(\+?34)?(6\d{1}|7[1234])\d{7}$/, //es-ES
        /^(\+?358|0)\s?(4(0|1|2|4|5)?|50)\s?(\d\s?){4,8}\d$/, //fi-FI
        /^(\+?33|0)[67]\d{8}$/, //fr-FR
        /^(\+972|0)([23489]|5[0248]|77)[1-9]\d{6}/, //he-IL
        /^(\+?36)(20|30|70)\d{7}$/, //hu-HU
        /^(\+?39)?\s?3\d{2} ?\d{6,7}$/, //it-IT
        /^(\+?81|0)\d{1,4}[ \-]?\d{1,4}[ \-]?\d{4}$/, //ja-JP
        /^(\+?6?01){1}(([145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/, //ms-MY
        /^(\+?47)?[49]\d{7}$/, //nb-NO
        /^(\+?32|0)4?\d{8}$/, //nl-BE
        /^(\+?47)?[49]\d{7}$/, //nn-NO
        /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/, //pl-PL
        /^(\+?55|0)\-?[1-9]{2}\-?[2-9]{1}\d{3,4}\-?\d{4}$/, //pt-BR
        /^(\+?351)?9[1236]\d{7}$/, //pt-PT
        /^(\+?7|8)?9\d{9}$/, //ru-RU
        /^(\+3816|06)[- \d]{5,9}$/, //sr-RS
        /^(\+?90|0)?5\d{9}$/, //tr-TR
        /^(\+?84|0)?((1(2([0-9])|6([2-9])|88|99))|(9((?!5)[0-9])))([0-9]{7})$/, //vi-VN
        /^(\+?0?86\-?)?1[345789]\d{9}$/, //zh-CN
        /^(\+?886\-?|0)?9\d{8}$/, //zh-TW
      ];
      for (let i = 0; i < phones.length; i++) {
        if (phones[i].test(num)) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
