<template>
    <div id="AnnouncementList">
        <section class="content-header">
            <h1>違規關鍵字管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">違規關鍵字管理</li>
            </ol>
        </section>
        <section class="content">
            <keywordAlert ref="keywordAlert" @submit="getKeywordsQueryAll" />
            
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="btn-add" style="    display: flex;    width: 150px;">
                            <button @click="showaddkeyword()" class="btn btn-block btn-warning " type="button">
                                <i class="fa fa-plus"></i> 新增違規關鍵字
                            </button>
                        </div>
                        <div class="select-group">
                            <!-- v-model="searchData.留言板狀態" @change="clickSearch" -->
                            <select class="form-control" id="selectUnit" required="required" >
                                <!-- <option value="-1" key="0" >全部</option> -->
                                <option value="0" key="1" select>Youtube</option>
                            </select>
                        </div>
                        <div class="input-group">
                            <input class="form-control pull-right" name="table_search" placeholder="搜尋違規關鍵字"
                                type="text" v-model="searchData.搜尋關鍵字" />
                            <div class="input-group-btn">
                                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="text">
                            <p class="data-num">
                                <span v-if="searchShow = 1">資料總筆數：</span>
                                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                                <span id="total">{{ data.total }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered " style="    width: 600px;">
                            <tbody>
                                <tr class="bg-gray">
                                    <th style="">關鍵字</th>
                                    <th class="text-nowrap text-center" style=" width: 27%;">功能</th>
                                </tr>
                                <tr class="addnew" :class="{ 'showup': shownewkeyword }">
                                    <td>
                                        <input class="form-control pull-right" name="table_add" placeholder="輸入違規關鍵字" type="text" v-model="newkeyword" />
                                    </td>
                                    <td style="text-align:center;" class="text-center text-nowrap">
                                            <button @click="addKeyword()" class="btn btn-success">新增</button>
                                            &nbsp;
                                            &nbsp;&nbsp;
                                            <button @click="addclose()" class="btn btn-light">取消</button>
                                    </td>
                                </tr>
                                <template v-if="data.total > 0">
                                    <tr :key="item.id" v-for="(item, index) in data.datas">
                                        <td style="text-align:left;">{{item.關鍵字}}</td>
                                        <td style="text-align:center;" class="text-center text-nowrap">
                                            <button @click="openVideo(item)" class="btn btn-primary">修改</button>
                                            &nbsp;
                                            &nbsp;&nbsp;
                                            <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="10">沒有資料</td>
                                    </tr>
                                </template>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style lang="scss" scoped>
    #AnnouncementList {
        .pic {
            img {
                max-height: 80px;
                margin: 0 auto;
            }
        }
    }

    .linkStyle {
        cursor: pointer;
        color: black;
        border-bottom: 1px solid;
    }

    .table-bordered>tbody>tr>td {
        border: 1px solid #b8b8b8;
    }

    .moretd {
        padding: 0;

        div {
            border-top: 1px solid #ebebeb;
            padding: 6px 0;
            min-height: 35px;

            &:first-child {
                border-top: 0;
            }
        }
    }
    .btn-success.waiting{
        pointer-events: none;
        opacity: .5;
    }
    .addnew{
        display: none;
        &.showup{
            display: table-row;
        }
    }

</style>


<script>
    /* eslint-disable */
    //import CKEditor from "@/components/CKEditor";
    import api from "@/assets/js/app-api";
    //import Pagination from "@/components/Pagination";
    import utils from "@/assets/js/app-utils";
    import config from "@/assets/js/app-config";
    import moment from "moment";
    import DateRangePicker from "@/components/DateRangePicker";
    import keywordAlert from "@/views/projects/smm/KeywordBox";
    const XLSX = require('xlsx');
    import {
        mapState,
        mapActions
    } from "vuex";

    export default {
        components: {
            DateRangePicker,
       //     Pagination,
            DateRangePicker,
            keywordAlert,
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                total: 0, //總筆數
                projectId: this.$store.state.projectId,
                moduleId: '',
                searchData: {
                    頻道平台: "Youtube",
                    關鍵字類別: "違規",
                    搜尋關鍵字:"",
                    pageNum: 1,
                    pageSize: 15
                },
                shownewkeyword:false,
                newkeyword:'',
                data:{

                },
                loadingExcel:false,
                url: config.dkOption.url,
            };
        },
        async created() {
            if (this.$route.params.searchData != undefined) {
                this.searchData = this.$route.params.searchData;
            }
            this.getKeywordsQueryAll();
        },
        mounted() {
	        this.loadingExcel=false;
        },
        computed: {
            ...mapState({
                KeywordsQueryAll: (state) => state.smm.QueryAll,
                response: (state) => state.smm.response,
                projectData: (state) => state.module.queryAll,
            }),
        },
        watch: {
            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;
            },
            $route(val) {
                //this.searchData.module = 'news'
                this.getKeywordsQueryAll();
            },
            response() {
                if (this.response.status == "1")
                    this.$eventBus.$emit("showAlert", "更新成功!");
                else this.$eventBus.$emit("showAlert", this.response.message);

                this.getKeywordsQueryAll();
            },
        },
        methods: {
            async onPageChange(pageIdx, pageSize) {
                this.searchData.pageNum = pageIdx;
                this.searchData.pageSize = pageSize;
                this.getKeywordsQueryAll();
            },

            getKeywordsQueryAll() {
                //列表
                // this.$store.dispatch("smm/KeywordsQueryAll", this.searchData{

                // });
                this.$store.dispatch("smm/KeywordsQueryAll", 
                    this.searchData
                ).then((res) => {
                    this.data=res.result;
                });

              //  if (this.$route.params.searchData != undefined) this.$refs.pagination.setPage(this.searchData.pageNum, this.KeywordsQueryAll.total);
            },

            clickSearch() {
                this.searchData.pageNum = 1;
                this.searchData.留言板狀態 = parseInt(this.searchData.留言板狀態, 10);
                this.getKeywordsQueryAll();
            },

            showaddkeyword(){
                this.shownewkeyword=true;
            },
            addclose(){
                this.shownewkeyword=false;
            },
            addKeyword() {
                if(this.newkeyword.length == 0){
                    alert("新增關鍵字不能為空白");
                    return ;
                }
                this.$store.dispatch("smm/KeywordsCreate", {
                    頻道平台: "Youtube",
                    關鍵字類別: "違規",
                    關鍵字: this.newkeyword
                });    
                this.newkeyword='';  
                   this.shownewkeyword=false;
            },            
            openVideo(item) {
                this.$refs.keywordAlert.openBox(item);
            },

            //*---- 刪除
            deleteItem(item) {
                this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                    if (isOK) {
                        this.$store.dispatch("smm/KeywordsDelete", {
                            關鍵字管理編號: item.關鍵字管理編號
                        });
                    }
                });
            },
            checkPermission(permission) {
                // let userPermissionList = this.$store.state.loginUserPermission;
                // let num = -1;
                // let isCheck = false;
                // permission.split(",").some(element => {
                //   num = userPermissionList.indexOf(element);
                //   switch (num) {
                //     case -1:
                //       isCheck = false;
                //       break;
                //     default:
                //       isCheck = true;
                //       break;
                //   }
                //   if (isCheck) return true;
                // });
                return utils.checkByUserPermission(permission);
            },
            FormatTime(time) {
                if (time == undefined) return "";
                return new moment(time).format("YYYY/MM/DD HH:mm:ss");
            },
        },
    };

</script>
<style scoped>
    .ckbx-style-8 label:before {
        background: #999;
    }

</style>
