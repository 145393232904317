<template>
  <!--圖片檢視-->
  <div class="modal fade" ref="pictureview">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">圖片檢視</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr style="height:600px;">
                  <td align="center">
                    <img id="idCard" :style="rotateStyle" :src="dataUrl" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer" style="text-align: center;">
          <button class="btn btn-info" type="button" @click="undo">
            <i class="fa fa-undo"></i>
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="changePic()"
          >{{picCount == 1 ? "下一張":"上一張"}}</button>
          <button class="btn btn-info" type="button" @click="repeat">
            <i class="fa fa-repeat"></i>
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      picture: "",
      picCount: 1,
      rotate: 0,
      rotateStyle: {}
    };
  },
  computed: {
    ...mapState({
      redeemPrize: state => state.redeemPrize.query,
      dataUrl: state => state.file.dataUrl
    })
  },
  watch: {
    redeemPrize() {
      if (this.redeemPrize.files.length > 0) {
        this.picture = this.readPic(this.redeemPrize.files[0]);
        this.picCount = 1;
      }
    },
    dataUrl() {}
  },
  methods: {
    showPictures(item) {
      if (item == "" || item == undefined) {
        this.$eventBus.$emit("showAlert", "發生異常，請重新操作!");
        return;
      }

      this.GetQuery(item);

      this.rotate = 0;
      this.rotateStyle = { transform: "rotate(" + this.rotate * 90 + "deg)" };

      $(this.$refs.pictureview).modal("show");
    },
    readPic(item) {
      this.$store.dispatch("file/readFile", {
        module: item.folder,
        fileName: item.fileName
      });
    },
    changePic() {
      if (this.picCount > 1) {
        this.picCount = 1;
        this.picture = this.readPic(this.redeemPrize.files[0]);
      } else {
        this.picCount = 2;
        this.picture = this.readPic(this.redeemPrize.files[1]);
      }

      this.rotate = 0;
      this.rotateStyle = { transform: "rotate(" + this.rotate * 90 + "deg)" };
    },
    undo() {
      this.rotate--;
      this.rotateStyle = { transform: "rotate(" + this.rotate * 90 + "deg)" };
      if (this.rotate == -4) this.rotate = 0;
    },
    repeat() {
      this.rotate++;
      this.rotateStyle = { transform: "rotate(" + this.rotate * 90 + "deg)" };
      if (this.rotate == 4) this.rotate = 0;
    },
    GetQuery(item) {
      this.$store.dispatch("redeemPrize/redeemPrizeQuery", {
        兌換Id: item.兌換Id
      });
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>