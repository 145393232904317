<template>
  <!-- 發票資訊 -->
  <div class="modal fade" ref="InvoiceInfo">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">發票資訊</h4>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-habits" v-if="invoiceInfo != null">
            <tbody>
              <tr>
                <td>發票類別</td>
                <td>
                  <div class="form-group">
                    <select class="form-control" v-model="invoiceInfo.發票聯別" disabled>
                      <option value="1">捐贈</option>
                      <option value="2">二聯</option>
                      <option value="3">三聯</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>電子郵件</td>
                <td>{{ invoiceInfo.Email }}</td>
              </tr>
              <tr>
                <td>聯絡電話</td>
                <td>{{ invoiceInfo.門號 }}</td>
              </tr>
              <tr v-if="invoiceInfo.發票聯別 != 1">
                <td>收件姓名</td>
                <td>{{ invoiceInfo.收件姓名 }}</td>
              </tr>
              <tr v-if="invoiceInfo.發票聯別 != 1">
                <td>載具類型</td>
                <td>
                  <div class="form-group">
                    <select class="form-control" v-model="invoiceInfo.載具類別" disabled>
                      <option value="1">會員載具</option>
                      <option value="2">手機條碼</option>
                      <option value="3">自然人憑證條碼</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="invoiceInfo.發票聯別 == 1">
                <td>愛心條碼</td>
                <td>{{ invoiceInfo.愛心條碼 }}</td>
              </tr>
              <tr v-if="invoiceInfo.載具類別 == 2 || invoiceInfo.載具類別 == 3">
                <td>載具條碼</td>
                <td>{{ invoiceInfo.載具條碼 }}</td>
              </tr>
              <tr v-if="invoiceInfo.發票聯別 != 1">
                <td>收件地址</td>
                <td>{{ invoiceInfo.發票地址 }}</td>
              </tr>
              <tr v-if="invoiceInfo.發票聯別 == 3">
                <td>公司抬頭</td>
                <td>{{ invoiceInfo.發票抬頭 }}</td>
              </tr>
              <tr v-if="invoiceInfo.發票聯別 == 3">
                <td>統一編號</td>
                <td>{{ invoiceInfo.統一編號 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="modal-footer">
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div> -->
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      invoiceInfo: {
        門號: "",
        Email: "",
        發票聯別: 0,
        發票抬頭: "",
        發票地址: "",
        統一編號: "",
        載具條碼: "",
        愛心條碼: "",
        載具類別: 0,
        收件姓名: "",
      },
    };
  },
  mounted() { },
  methods: {
    async showInvoiceInfoBox(info) {
      this.invoiceInfo = {
        玩家暱稱: "",
        門號: "",
        Email: "",
        發票聯別: 0,
        發票抬頭: "",
        發票地址: "",
        統一編號: "",
        載具條碼: "",
        愛心條碼: "",
        載具類別: 0,
        收件姓名: "",
      };

      if (info != null && info.門號 != null && info.門號 != "")
        this.invoiceInfo = info;
      else this.invoiceInfo = null;

      $(this.$refs.InvoiceInfo).modal("show");
    },
  },
};
</script>