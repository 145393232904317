function isMSbrowser() {
    const userAgent = window.navigator.userAgent
    return userAgent.indexOf('Edge') !== -1 || userAgent.indexOf('Trident') !== -1
}

function format(data) {
    return String(data).replace(/"/g, '""').replace(/(^[\s\S]*$)/, '"$1"')
}

export const saveCSV = function (title, head, data) {
    let wordSeparator = ','
    let lineSeparator = '\n'

    let reTitle = title + '.csv'
    let headBOM = '\ufeff'
    let headStr = head ? head.map(item => format(item)).join(wordSeparator) + lineSeparator : ''
    let dataStr = data ? data.map(row => row.map(item => format(item)).join(wordSeparator)).join(lineSeparator) : ''

    return isMSbrowser()
        ? new Promise(resolve => { // Edge、IE11
            let blob = new Blob([headBOM + headStr + dataStr], { type: 'text/plain;charset=utf-8' })
            window.navigator.msSaveBlob(blob, reTitle)
            resolve()
        })
        : new Promise(resolve => { // Chrome、Firefox
            let a = document.createElement('a')
            a.href = 'data:text/csv;charset=utf-8,' + headBOM + encodeURIComponent(headStr + dataStr)
            a.download = reTitle
            a.click()
            resolve()
        })
}
export const saveTxt = function (title, data) {
    let reTitle = title + '.json'
    let headBOM = '\ufeff'
    return isMSbrowser()
        ? new Promise(resolve => { // Edge、IE11
            let blob = new Blob([headBOM + data], { type: 'text/plain;charset=utf-8' })
            window.navigator.msSaveBlob(blob, reTitle)
            resolve()
        })
        : new Promise(resolve => { // Chrome、Firefox
            let a = document.createElement('a')
            a.href = 'data:text/csv;charset=utf-8,' + headBOM + encodeURIComponent(data)
            a.download = reTitle
            a.click()
            resolve()
        })
}

export const exportCSV = function (title, datas) {
    //參考資料：https://juejin.cn/post/6957521395270025252
    let csv = [];
    let keys = datas && datas[0] ? Object.keys(datas[0]) : [];
    for (let data of datas) {
        let tmp = [];
        for (let key of keys) {
            tmp.push(data[key] ? data[key] : "");
        }
        csv.push(tmp);
    }
    // console.log(csv);
    // let title = 'test'
    // let keys = ['key', 'value']
    // let csv = [
    //     ['a', '我是正常文本'],
    //     ['b', '我是"双引号"'],
    //     ['c', '我是,小逗号,'],
    //     ['d', '我是\n换行符']
    // ]
    saveCSV(title, keys, csv);
}