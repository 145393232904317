<template>
  <div id="Certification">
    <section class="content-header">
      <h1>實名認證管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>實名認證管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">

            <div class="date-group" style="width: auto; margin-bottom: 0;">
              <label class="control-label" style="float: none; padding-top: 0;">認證期間：</label>
              <div class="input-group date" style="float: none;">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    ref="DateRangePicker"
                    :propEndDate.sync="searchData.endTime"
                    :propStartDate.sync="searchData.startTime"
                    :key="resetDate"
                  />
                </div>
              </div>
            </div>

            <div class="input-group mr-10">
              <label for="" class="control-label">門號：</label>
              <input
                class="form-control pull-right"
                name="mobile"
                placeholder="輸入手機門號"
                type="text"
                v-model="searchData.mobile"
                @change="resetDataList()"
              />
            </div>   

            <div class="select-group" style="width: 200px;">
              <label for="" class="control-label">驗證狀態：</label>
              <select
                class="form-control"
                v-model="verifyStatusSelect"
              >
                <option :value="-1">全部</option>
                <option :value="0">未通過</option>
                <option :value="1">已通過</option>
                <option :value="3">已封鎖</option>
              </select>
            </div>

            <div class="text" style="margin-top: 22px;">
              <button
                class="btn btn-warning mr-10"
                type="button"
                @click="resetDataList()"
              >
                查詢
              </button>
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
              <p class="data-num" @click="resetSearch()" style="cursor: pointer; text-decoration: underline;">
                重設條件
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 10%">手機號碼</th>
                  <th class="text-nowrap" style="width: 10%;">驗證狀態</th>
                  <th class="text-nowrap" style="width: 10%;">封鎖狀態</th>
                  <th class="text-nowrap" style="width: 15%;">驗證時間</th>
                  <th class="text-nowrap" style="width: 15%; min-width: 300px;">功能</th>
                </tr>
                <template v-if="loading">
									<td class="text-center" colspan="10">查詢中</td>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="10">查詢無任何資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="index" v-for="(item, index) in data">
                    <td class="text-center">{{ searchData.pageSize * (searchData.pageNum - 1) + index + 1 }}</td>
                    <td class="text-center">{{ item.mobile }}</td>
                    <td class="text-center">{{ verifyStatus[item.verifyStatus] }}</td>
                    <td class="text-center">{{ item.lockStatus == 1 ? "封鎖" : "未封鎖" }}</td>
                    <td class="text-center">{{ item.verifyTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-primary mr-10"
                        type="button"
                        @click="goContent(item)"
                        >
                        驗證紀錄
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-success mr-10"
                        type="button"
                        v-if="item.verifyStatus != 0"
                        @click="unVerifyShow(item)"
                      >
                        重製驗證
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-danger mr-10"
                        type="button"
                        v-if="item.lockStatus != 0"
                        @click="unBlockShow(item)"
                      >
                        解封
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0 && !loading">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!--重製-->
			<div class="modal fade" ref="reset" id="MIDreset">
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title text-center">是否{{ resetModal ? "重置" : "解鎖" }}驗證此門號？</h4>
						</div>
						<div class="modal-body">
              <div class="mb-10">＊{{ resetModal ? "重置驗證" : "3次封鎖的解鎖" }}原因：</div>
              <input type="text" class="form-control" placeholder="選其他的原因(選填)" v-model.trim="unVerifyData.note">
						</div>
						<div class="modal-footer text-center ml-10">
							<button class="btn btn-default" data-dismiss="modal" type="button" @click="cancelInput()">取消</button>
							<button
								class="btn btn-primary"
								type="button"
								@click=" resetModal ? unVerify() : unBlock()"
							>
								確認
							</button>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        memberNo: "", 
        mobile: "", 
        verifyStatus: -1, //-1:全選，0:未完成，1:成功，2:認證失敗  3:封鎖 4:解封 5:重置驗證 6:取消驗證
        lockStatus: -1, //-1:全選，0:未封鎖，1:封鎖
        startTime: new moment().subtract(1, "year").format("YYYY-MM-DD"), 
        endTime: new moment().add(1, "day").format("YYYY-MM-DD"), 
        pageNum: 1, 
        pageSize: 10, 
      },
      verifyStatusSelect: -1,
      unVerifyData: {
        mobile: "",
        note: "", //說明原因
      },
      data: [],
      total: 0,
      verifyStatus: {
        "-1": "全選",
        "0": "未通過",
        "1": "通過",
        "2": "認證失敗",  
        "3": "已封鎖", 
        "4": "解封", 
        "5": "重置驗證", 
        "6": "取消驗證"
      },
      resetModal: true,
      resetDate: ""
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getDataList();
  },
  mounted() { },
  watch: {
    verifyStatusSelect() {
      if(this.verifyStatusSelect != 3) {
        this.searchData.verifyStatus = this.verifyStatusSelect;
        this.searchData.lockStatus = 0;
      } else {
        this.searchData.verifyStatus = -1;
        this.searchData.lockStatus = 1;
      }
      this.resetDataList();
    },
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.searchData.startTime = new moment(this.searchData.startTime).format("YYYY-MM-DD");
      this.searchData.endTime = new moment(this.searchData.endTime).format("YYYY-MM-DD");

      api.mid.mobile.queryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.data = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    resetSearch() {
      this.searchData.mobile = "";
      this.searchData.memberNo = "";
      this.searchData.verifyStatus = -1;
      this.searchData.startTime = new moment().subtract(7, "day"),
      this.searchData.endTime = new moment().add(1, "day"),
      this.resetDate = new Date().getTime();
      this.$refs.DateRangePicker.$forceUpdate();
      this.resetDataList();
    },
    unBlockShow(item) {
      this.resetModal = false;
      this.unVerifyData.mobile = item.mobile;
      $(this.$refs.reset).modal("show");
    },
    unBlock() {
      api.mid.mobile.unblock(this.unVerifyData)
        .then((res) => {
          if(res.data.status == '1') {
            this.$eventBus.$emit("showAlert", "解封成功!");
            this.getDataList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message || "解封失敗!");
          }
          $(this.$refs.reset).modal("hide");
        })
    },
    unVerifyShow(item) {
      this.resetModal = true;
      this.unVerifyData.mobile = item.mobile;
      $(this.$refs.reset).modal("show");
    },
    unVerify() {
      api.mid.mobile.unVerify(this.unVerifyData)
        .then((res) => {
          if(res.data.status == '1') {
            this.$eventBus.$emit("showAlert", "重置成功!");
            this.getDataList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message || "重置失敗!");
          }
          $(this.$refs.reset).modal("hide");
        })
    },
    goContent(item) {
      this.$router.push({
        name: "MIDCertificationDetailList",
        params: { 
          memberNo: item.memberNo,
          mobile: item.mobile,
          searchData: this.searchData
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>