<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲參與</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">遊戲參與</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增遊戲參與
            </button>
            <!-- <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isStop"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option value="0">上架</option>
                <option value="1">下架</option>
              </select>
            </div> -->
             <!-- <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div> 
            </div>-->
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ banners.total }}</span>
              </p>
            </div>
          </div>
          <div class="text-red" style="    display: block;    margin-top: 14px;    font-size: 16px;">採用最新50筆資料</div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable" >
              <tbody>
                <tr class="bg-gray text-nowrap">
   
                  <th style="min-width: 150px">主圖</th>
                  <th style="min-width: 150px">名稱</th>
                  <th style="min-width: 180px">建立日期</th>
                  <!-- <th style="min-width: 65px">狀態</th> -->
                  <!-- <th style="min-width: 150px">排序</th> -->
                  <th style="min-width: 150px">功能</th>
                </tr>
                <template v-if="banners.total > 0">
                  <tr :key="item.id" v-for="item in banners.datas">
                    <td>
                      <div class="pic jqimgFill" style="background:black;    width: 120px;    height: 120px;    max-width: 120px;">
                        <!-- 430 * 330 -->
                        <img :src="item.filesUrl" draggable="false" style="max-height: 120px;" />
                      </div>
                    </td>
                    <td>{{ item.bannerTitle }}</td>
                    <td align="center">{{ item.createTime }}</td>
                    <!-- <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td> -->
                    <!-- <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.orderNum" />
                      <button
                        @click="updateBannerOd(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                      >更新</button>
                    </td> -->
                    <td class="text-center text-nowrap">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="banners.total > 0">
          <Pagination
            ref="pagination"
            :total="banners.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import CKEditor from "@/components/CKEditor";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      searchData: {
        module: "game",
        keyword:"",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        isStop: 0
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetBannerClassTypes();
    this.GetBannerQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      bannerTypes: state => state.classTypes.queryAll,
      banners: state => state.banner.queryAll,
      response: state => state.banner.response
    })
  },
  watch: {
    bannerTypes() {},
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetBannerQueryAll();
    },
    banners() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.banners.total
      );
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetBannerQueryAll();
    },

    GetBannerQueryAll() {
      //列表
      this.searchData.isStop = parseInt(this.searchData.isStop);
      this.$store.dispatch("banner/bannerQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "game",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    updateBannerOd(item) {
      item.orderNum = parseInt(item.orderNum);
      // item.isStop = parseInt(item.isStop);

      this.$store.dispatch("banner/bannerUpdate", item);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetBannerQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "XacGamePartnerEdit",
        params: { searchData: this.searchData }
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "XacGamePartnerEdit",
        params: { id: item.bannerId, searchData: this.searchData }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("banner/bannerDelete", item);
        }
      });
    }
  }
};
</script>
