<template>
  <div id="NmjGameDataList">
    <section class="content-header">
      <h1>雀幣爭霸戰牌局紀錄</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">雀幣爭霸戰牌局紀錄</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit="$event.preventDefault(); resetDataList()">
            <div class="form-group">
              <label class="control-label">
                查詢時間：
              </label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propStartDate.sync="searchData.開始時間"
                  :propEndDate.sync="searchData.結束時間"
                  :propTimePicker="true"
                />
              </div>
            </div>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="玩家暱稱"
                v-model.trim="searchData.暱稱"
              />
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()" :disabled="datas.length <= 0">
                匯出
              </button>
            </div>
            <button type="button" v-show="Object.keys($route.query).length > 0" @click="$router.go(-1)" class="btn btn-default pull-right fa fa-arrow-left"> 返回</button>
          </form>
          <!-- <pre>{{ datas[0] }}</pre> -->
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width:120px; width:10%">玩家暱稱</th>
                  <th style="min-width: 90px; width: 5%">賽事類別</th>
                  <th style="min-width: 75px; width: 5%">Round ID</th>
                  <th style="min-width: 90px; width: 5%">牌桌ID</th>
                  <th style="min-width: 75px; width: 5%">名次結果</th>
                  <th style="min-width: 90px; width: 5%">賽事籌碼</th>
                  <th style="min-width:105px; width:10%">比賽開始時間</th>
                  <th style="min-width: 75px; width: 5%">報名順序</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="20">查詢中</td>
                </tr>
                <tr v-else-if="result.message">
                  <td colspan="20">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="20">查詢無任何資料</td>
                </tr>
                <template v-else v-for="(item, index) in pagedData">
                <tr>
                  <td><router-link v-text="item.暱稱.trim()" :to="{name:'NmjPlayerData', query:{nick:item.暱稱.trim()}}"/></td>
                  <td v-text="item.賽事類別"></td>
                  <td v-text="item.roundID"></td>
                  <td><router-link v-text="item.牌桌ID" :to="{name:'NmjGameRecord', query:{id: item.牌桌ID}}"></router-link></td>
                  <td v-text="item.名次結果"></td>
                  <td v-text="item.賽事籌碼"></td>
                  <td v-text="uiDate(item.比賽開始時間)"></td>
                  <td v-text="item.報名順序"></td>
                </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
  #NmjGameDataList{
    .top-box > .input-group{
      margin-bottom: 10px;
    }
    .table td{
      line-height: 1.2;
    }
    .table .table-player{
      th, td{
        min-width: auto;
        padding: .1em .25em;
        &:nth-child(1), &:nth-child(3), &:nth-child(4){ min-width:4em; width:10%;}
      }

      tr:nth-child(2n){
        background: rgba(0,0,0,.03);
      } 
    }
    .mark{
      padding: 0;
    }
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import Pagination from "@/components/Pagination";


export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        暱稱: "",
        開始時間: new moment().subtract(6, "days").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
      },
      result: {},
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.searchData.暱稱 = this.$route.query.nick || "";
    this.getDataList();
  },
  computed:{
    pagedData(){
      let start = (this.searchData.pageNum - 1) * this.searchData.pageSize;
      let end = this.searchData.pageNum * this.searchData.pageSize;
      return this.datas.slice(start, end);
    }
  },
  methods: {
    getDataList() {
      this.searchData.開始時間 = this.searchData.開始時間.replace(/\//g, '-');
      this.searchData.結束時間 = this.searchData.結束時間.replace(/\//g, '-');
      this.$store
        .dispatch("nmjGameRecord/queryBattle", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.competitionRecordList || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          if (this.$route.query.nick && this.$route.query.nick != this.searchData.暱稱){
            let query = {};
            if (this.searchData.暱稱) query['nick'] = this.searchData.暱稱;
            this.$router.replace({query:query});
          }
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      // this.getDataList();
    },
    toPlayerData(nick){
      return {
        name:'NmjPlayerData',
        query:{
          nick: nick,
          st: this.searchData.開始時間,
          et: this.searchData.結束時間,
        }
      }
    },
    exportOrders() {
      this.$store.dispatch("nmjGameRecord/exportBattle", this.searchData);
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>