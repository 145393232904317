<template>
  <div id="ElectionPersonList" class="normal">
    <form id="myForm" :action="url"
              enctype="application/x-www-form-urlencoded"
              method="post">
      <input type="hidden" id="year" name="year" :value="searchData.year">
      <input type="hidden" id="type" name="type" :value="searchData.type">
    </form>
    <section class="content-header">
      <h1>候選人管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>候選人管理</li>
        <li class="active">候選人列表</li>
      </ol>
    </section>
    <section class="content forum-announcement">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box mb-10">
            <div style="float: right;">
              <div class="btn-custom">
                切換自訂票數
                <div class="ckbx-style-8 ckbx toggleInput mb-5 ml-5">
                  <input
                    :false-value="false"
                    id="tickets_type"
                    :true-value="true"
                    @change="setTicketStatus()"
                    name="ckbx-style-8"
                    type="checkbox"
                    v-model="customize"
                  />
                  <label for="tickets_type"></label>
                </div>
              </div>
            </div>
            <div class="select-group" style="width: auto;">
              <select
                class="form-control"
                required="required"
                v-model="searchData.year"
              >
                <option value="0">選擇年分</option>
                <option :value="2024">2024</option>
                <option :value="2022">2022</option>
                <option :value="2020">2020</option>
                <option :value="2018">2018</option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                required="required"
                v-model="searchData.type"
                @change="getTicketStatus()"
              >
                <option value="">大選類型</option>
                <option value="縣市長大選">縣市長大選</option>
                <option value="總統大選">總統大選</option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                required="required"
                v-model="searchData.category"
              >
              <option value="">大選類別</option>
              <option value="市長" v-if="searchData.type == '縣市長大選'">市長</option>
              <option value="議員" v-if="searchData.type == '縣市長大選'">議員</option>
              <option value="總統" v-if="searchData.type == '總統大選'">總統</option>
              <option value="立委" v-if="searchData.type == '總統大選'">立委</option>
              <option value="山地原住民立委" v-if="searchData.type == '總統大選'">山地原住民立委</option>
              <option value="平地原住民立委" v-if="searchData.type == '總統大選'">平地原住民立委</option>
              <option value="不分區立委" v-if="searchData.type == '總統大選'">不分區立委</option>
              </select>
            </div>
            <!-- 黨派選單 -->
            <div class="select-group" style="width: auto;">
              <select
                class="form-control"
                required="required"
                v-model="searchData.candidateParty"
              >
                <option value="">選擇黨派</option>
                <option
                  v-for="(gorup, index) in goropList"
                  :key="gorup.id"
                >
                  {{ gorup.partyName }}
                </option>
              </select>
            </div>
            <!-- 縣市區域選單 -->
            <div
              v-if="
                searchData.category == '縣市長' ||
                searchData.category == '議員' ||
                searchData.category == '立委' ||
                searchData.category == '不分區立委'
              "
              class="select-group"
              style="width: auto;"
            >
              <select
                class="form-control"
                required="required"
                v-model="area"
              >
                <option value="全部">全部</option>
                <option
                  :value="key"
                  v-for="(data, key) in menu"
                  :key="key"
                  v-show="key != '原住民'"
                >
                  {{ key }}
                </option>
              </select>
            </div>
            <!-- 縣市選單 -->
            <div
              v-if="
                (searchData.category == '縣市長' ||
                searchData.category == '議員' ||
                searchData.category == '立委' ||
                searchData.category == '不分區立委') &&
                area != '全部'
              "
              class="select-group"
              style="width: auto;"
            >
              <select
                class="form-control"
                required="required"
                v-model="searchData.city"
                @change="setConstituency()"
              >
                <option
                  :value="county.代碼"
                  v-for="(county, key) in countyList"
                  :key="county.代碼"
                >
                  {{ county.縣市 }}
                </option>
              </select>
            </div>
            <!-- 選區選單 -->
            <div
              v-if="
                searchData.category == '立委' &&
                area != '全部'
              "
              class="select-group"
              style="width: auto;"
            >
              <select
                class="form-control"
                required="required"
                v-model="searchData.constituency"
              >
                <option
                  :value="index+1"
                  v-for="(constituency, index) in constituency"
                  :key="index+1"
                >
                  {{ constituency }}
                </option>
              </select>
            </div>
            <!-- 當選狀態選單 -->
            <div class="select-group" style="width: auto;">
              <select
                class="form-control"
                required="required"
                v-model="searchData.electStatus"
              >
                <option value="">狀態</option>
                <option value="1">當選</option>
                <option value="0">落選</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋關鍵字"
                v-model="searchData.candidateName"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text reset" @click="resetSearchData()">
              <p class="data-num">
                重設條件
              </p>
            </div>
          </div>
          <div class="top-box">
            <div style="float: right;">
              <button
                class="btn btn-block btn-success btn-add ml-10"
                type="button"
                @click="exportData()"
              >
                匯出
              </button>
              <button
                class="btn btn-block btn-warning btn-add"
                type="button"
                @click="addNew()"
              >
                匯入
              </button>
              <p style="float: right;" class="mt-10 mr-10">
                *同一選舉只需匯入一份
              </p>
            </div>
            <div class="text mt-5">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
            <!-- <div class="sort">
              <select
                class="form-control"
                required="required"
              >
                <option value="">排序方式</option>
                <option value="">新增日期</option>
                <option value="">黨派</option>
              </select>
            </div> -->
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px">序號</th>
                  <th style="min-width: 80px">年分</th>
                  <th style="min-width: 100px">大選類型</th>
                  <th style="min-width: 100px">大選類別</th>
                  <th style="min-width: 80px">號次</th>
                  <th style="min-width: 100px">縣市</th>
                  <th style="min-width: 100px">選區</th>
                  <th style="min-width: 150px">主圖</th>
                  <th style="min-width: 100px">姓名</th>
                  <th style="min-width: 100px">黨派</th>
                  <th style="min-width: 100px">票數</th>
                  <th style="min-width: 80px">狀態</th>
                  <th style="min-width: 100px" v-if="customize">自訂票數</th>
                  <th style="min-width: 80px" v-if="customize">自訂狀態</th>

                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="15">查詢中</td>
                </tr>
                <tr v-else-if="list&&list.length === 0">
                  <td class="text-center" colspan="15">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in list" :key="index">
                  <td class="text-nowrap text-center">{{ item.candidateId }}</td>
                  <td class="text-nowrap text-center">{{ item.year }}年</td>
                  <td class="text-nowrap text-center">{{ item.type }}</td>
                  <td class="text-nowrap text-center">
                    <!-- {{ item.isVice == "1" ? "副" : "" }} -->
                    {{ item.category }}
                  </td>
                  <td class="text-nowrap text-center">{{ item.candidateNum }}</td>
                  <td class="text-nowrap text-center"> 
                    {{ getCountyName(item.city) }}  
                  </td>
                  <td class="text-nowrap text-center"> 
                    {{ !item.constituency ? "-" : constituency[item.constituency - 1] }}  
                  </td>
                  <td class="text-nowrap text-center">
                    <img :src="`${imgUrl}${item.candidateImage}`" alt="">
                  </td>
                  <td class="text-nowrap text-center">{{ item.candidateName }}</td>
                  <td class="text-nowrap text-center">{{ item.candidateParty }}</td>
                  <td class="text-nowrap text-center">{{ item.tickets || 0 }}</td>
                  <td class="text-nowrap text-center"> 
                    <img
                      v-if="item.electStatus"
                      :src="require('@/assets/img/icon-mark.png')"
                      alt="當選"
                    />
                  </td>
                  <td class="text-nowrap text-center" v-if="customize">{{ item.customizeTickets || 0 }}</td>
                  <td class="text-nowrap text-center" v-if="customize"> 
                    <img
                      v-if="item.customizeElectStatus == '1'"
                      :src="require('@/assets/img/icon-mark.png')"
                      alt="當選"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>

      <!--新增-->
			<div class="modal fade" ref="create">
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title text-center">匯入檔案</h4>
						</div>
						<div class="modal-body">
							<form class="form-horizontal" name="announcementForm">
								<div class="box-body">
                  <div>
                    <a
                      href="/uploads/大選候選人名單範例.xlsx"
                      type="button"
                      download
                      >範例下載</a
                    >
                  </div>
                  <div>
                    <input
                      accept=".xls,.xlsx"
                      id="file"
                      type="file"
                      @change="onFileChange($event)"
                    />
                  </div>
								</div>
							</form>
						</div>
						<div class="modal-footer text-center">
							<button class="btn btn-default" data-dismiss="modal" type="button">取消</button>
							<button
								class="btn btn-primary"
								type="button"
                @click="importFile()"
							>
								確定
							</button>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";


export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      searchData: {
        page: 1,
        pageSize: 10,
        year: 2024, //年份
        type: "總統大選", //大選類型
        category: "", //大選類別
        city: "", //縣市
        constituency: "", //選區
        candidateName: "", //候選人姓名
        candidateParty: "", //候選人政黨
        electStatus: "" //當選狀態 1:當選
      },
      customize: true,
      addFile: {},
      area: "全部",
      countyList: [],
      countyNameList: {},
      constituency: {},
      url: process.env.VUE_APP_VOTEWEB_PATH + '/api_election/exportCandidateList',
      imgUrl: process.env.VUE_APP_VOTEWEB_PATH + '/public/images/election/2024/總統大選/'
    };
  },
  computed: {
    ...mapState({
      goropList: state => state.electionGroup.queryAll.datas,
      menu: state => state.electionPerson.menu,
      list: state => state.electionPerson.queryAll.datas,
      total: state => state.electionPerson.queryAll.total,
    })
  },
  watch: {
    'searchData.type'(val) {
      if(!val) this.searchData.category = "";
      else if(val =='縣市長大選') this.searchData.category = '市長';
      else this.searchData.category = '總統';
    },
    'searchData.category'(val) {
      this.setCounty();
    },
    area(val) {
      this.setCounty();
    }
  },
  mounted() {
    this.getMenu();
    this.getGroupList();
    this.getTicketStatus();
    this.getDataList();
  },
  methods: {
    getMenu() {
      let data = new FormData();
      data.append("year", 2024);
      this.$store.dispatch(`electionPerson/menu`, data)
        .then((res) => {
          this.setCounty();
        })
    },
    getGroupList() {
      let data = new FormData();
      data.append("page", 1);
      data.append("pageSize", 999);

      this.$store.dispatch(`electionGroup/queryAll`, data)
    },
    setTicketStatus() {
      let sourse = this.customize ? "備份資料" : "中選會";

      let data = new FormData();
      data.append("year", this.searchData.year); //年份(必填)
      data.append("type", "總統大選"); //大選類型(必填)
      data.append("source", sourse); //(必填)中選會，備份資料

      this.$store
        .dispatch(`electionPerson/setTickets`, data)
        .then((res) => {
          if(res.data.isSuccess) {
            this.getDataList();
            this.$forceUpdate();
          }
        })
    },
    getTicketStatus() {
      let data = new FormData();
      data.append("year", this.searchData.year); //年份(必填)
      data.append("type", "總統大選"); //大選類型(必填)
      data.append("source", "備份資料"); //(必填)中選會，備份資料

      this.$store
        .dispatch(`electionPerson/getTickets`, data)
        .then((res) => {
          this.customize = res.data == "備份資料" ? true : false;
          this.loading = false;
        })
    },
    getDataList() {
      this.loading = true;
      
      // 不分縣市和選區
      if(this.searchData.category != "立委" && this.searchData.category != "議員") {
        this.searchData.city = "";
        this.searchData.constituency = "";
      }

      // 不分選區
      if(this.searchData.category == "市長") this.searchData.constituency = "";

      let data = new FormData();
      data.append("page", this.searchData.page);
      data.append("pageSize", this.searchData.pageSize);
      data.append("year", this.searchData.year);
      data.append("type", this.searchData.type);
      data.append("category", this.searchData.category);
      data.append("city", this.searchData.city);
      data.append("constituency", this.searchData.constituency);
      data.append("candidateName", this.searchData.candidateName);
      data.append("candidateParty", this.searchData.candidateParty);
      data.append("electStatus", this.searchData.electStatus);

      this.$store
        .dispatch(`electionPerson/queryAll`, data)
        .then((res) => {
          this.$forceUpdate();
          this.loading = false;
        })
    },
    resetDataList() {
      this.searchData.page = 1;
      this.getDataList();
    },
    resetSearchData() {
      this.searchData.page = 1,
      this.searchData.pageSize = 10,
      this.searchData.year = 2024, //年份
      this.searchData.type = "", //大選類型
      this.searchData.category = "", //大選類別
      this.searchData.city = "", //縣市
      this.searchData.constituency = "", //選區
      this.searchData.candidateName = "", //候選人姓名
      this.searchData.candidateParty = "", //候選人政黨
      this.searchData.electStatus = "" //當選狀態 1:當選
      this.area = "六都";
      this.getDataList();
    },
    // 設定縣市選單
    setCounty() {
      if(this.area == '全部') {
        this.searchData.city = "";
        this.searchData.constituency = "";
        return
      }
      this.countyList = this.menu[this.area]
      this.countyNameList = {};
      this.menu[this.area].forEach((data) => {
        this.countyNameList[data.代碼] = data;
      })
      this.searchData.city = this.countyList[0].代碼;
      this.setConstituency();
    },
    // 設定選區選單
    setConstituency() {
      // if(this.searchData.category != '立委') return
      const city = this.searchData.city;
      this.searchData.constituency = "";
      this.constituency = this.countyNameList[city]["選區"];
      this.searchData.constituency = "1";
    },
    // 列表顯示縣市中文
    getCountyName(id) {
      let list = [];
      let index = list.findIndex(item => item.id == id);
      return index == -1 ? "-" : list[index].name
    },
    // 列表顯示選區中文
    getConstituencyName(county, id) {
      if(!this.constituencyQueryAll[county]) return "-"
      let list = this.constituencyQueryAll[county].選區;
      return list[id]
    },
    addNew() {
      $(this.$refs.create).modal("show");
    },
    onFileChange(evt) {
      console.log(evt.target.files[0]);
      this.addFile = evt.target.files[0];
    },
    importFile() {
      let data = new FormData();
      data.append("file", this.addFile);

      this.$store
        .dispatch(`electionPerson/add`, data)
        .then((res) => {
          if(res.data.isSuccess) {
            $(this.$refs.create).modal("hide");
            this.$eventBus.$emit("showAlert", `新增成功!`);
            this.getDataList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.errorMessage || `新增失敗!`);
          }
        })
    },
    exportData() {
      document.getElementById('myForm').submit();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
#ElectionPersonList .reset p{
  text-decoration: underline;
  cursor: pointer;
}
#ElectionPersonList .sort {
  float: right;
  min-width: 150px;
}

#ElectionPersonList .btn-custom {
  font-size: 18px;
  color: #aaaaaa;
  border: 1px solid #aaaaaa;
  padding: 5px 15px;
}
</style>