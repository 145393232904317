<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>獎項列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動列表</li>
        <li class="active">獎項列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-cog" type="button">
              <i class="fa fa-plus"></i> 新增獎項
            </button>
            <button @click="activityList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 活動列表
            </button>
            <button @click="SNList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 序號列表
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.activityId"
                @change="onChangeActivity"
              >
                <option
                  :value="item.activityId"
                  v-for="(item, index) in activity.datas"
                  :key="index"
                >{{item.activityTitle}}</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋獎項名稱"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ award.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 10%">ID</th>
                  <th style="min-width: 90px; width: 5%">獎項名稱</th>
                  <th class="text-nowrap" style="width: 5%;">規則</th>
                  <!-- <th class="text-nowrap" style="width: 5%;">道具</th> -->
                  <th class="text-nowrap" style="width: 5%;">序號數量</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap" style="width: 10%;">獎項開始時間</th>
                  <th class="text-nowrap" style="width: 10%;">獎項結束時間</th>
                  <th class="text-nowrap" style="width: 10%;">獎項說明</th>
                  <th class="text-nowrap" style="width: 3%;">建立者工號</th>
                  <th class="text-nowrap" style="width: 3%;">建立者</th>
                  <th class="text-nowrap" style="width: 10%;">建立時間</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="award.total > 0">
                  <tr :key="item.id" v-for="item in award.datas">
                    <td align="center">{{ item.awardsId }}</td>
                    <td align="center">{{ item.awardsTitle }}</td>
                    <td align="center">
                      {{
                        item.ruleType == ""
                          ? "無"
                          : item.ruleType == "1帳1次"
                          ? "1帳多次"
                          : item.ruleType
                      }}
                    </td>
                   <!-- 
                     <td align="center">
                      <span v-for="(award, index) in item.awardsItems" :key="index">
                        {{award.itemTitle}} * {{award.itemCount}}
                        <br />
                      </span>
                    </td>
                    -->
                    <td align="center">{{ item.snCount }}</td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td align="center">{{ item.awardsStartTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td align="center">{{ item.awardsEndTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td align="center">{{ item.awardsInfo }}</td>
                    <td align="center">{{ item.createUId }}</td>
                    <td align="center">{{ item.createUName }}</td>
                    <td align="center">{{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">
                      <button @click="GoSNDetail(item)" class="btn btn-primary">序號明細</button>
                      &nbsp;
                      <button
                        @click="editorItem(item)"
                        class="btn btn-primary"
                      >修改</button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                        v-if="deleteButton"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="12">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="award.total > 0">
          <Pagination
            ref="pagination"
            :total="award.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      deleteButton: false,
      searchData: {
        activityId: "",
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, //每頁顯示筆數
      }
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    if (this.$route.params.activityId != undefined) {
      this.searchData.activityId = this.$route.params.activityId;
    }
    this.GetActivityQueryAll();
  },
  computed: {
    ...mapState({
      activity: state => state.activity.queryAll,
      award: state => state.award.queryAll,
      response: state => state.award.response
    })
  },
  watch: {
    activity() {
      if (
        (this.searchData.activityId == "" ||
          this.searchData.activityId == null) &&
        this.activity.total > 0
      )
        this.searchData.activityId = this.activity.datas[0].activityId;

      this.onChangeActivity();
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
        this.getAwardQueryAll();
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    },
    award() {
      this.$refs.pagination.setPage(this.searchData.pageNum, this.award.total);
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getAwardQueryAll();
    },

    GetActivityQueryAll() {
      //活動列表
      this.$store.dispatch("activity/activityQueryAll", {
        isStop: 0,
        isDelete: 0
      });
    },

    getAwardQueryAll() {
      //列表
      this.$store.dispatch("award/awardQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    onChangeActivity() {
      let info = this.activity.datas.find(
        act => act.activityId == this.searchData.activityId
      );

      if (info) {
        this.deleteButton = new moment(new Date()).isBefore(info.startTime);
      }

      this.clickSearch();
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getAwardQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "AwardEdit",
        params: { searchData: this.searchData }
      });
      return;
    },

    activityList() {
      this.$router.push({
        name: "ActivityManager",
        params: { searchData: this.$route.params.searchData }
      });
    },

    GoSNDetail(item) {
      this.$router.push({
        name: "SNDetail",
        params: {
          activityId: item.activityId,
          awardsId: item.awardsId,
          searchData: this.searchData
        }
      });
      return;
    },

    //*---- 修改內容
    editorItem(item) {
      this.$router.push({
        name: "AwardEdit",
        params: { id: item.awardsId, searchData: this.searchData }
      });
      return;
    },

    SNList() {
      this.$router.push({
        name: "SNManagerList"
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("award/awardDelete", item);
        }
      });
    }
  }
};
</script>
