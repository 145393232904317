<template>
  <div id="ContactUsEdit">

    <section class="content-header">
      <h1>聯絡網銀-友善連結-編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">聯絡網銀-友善連結-編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="contactUsForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 標題：
                </label>
                <div class="col-sm-10">
                  <input 
                    class="form-control"
                    id="ContactPageTitle"
                    required="required"
                    type="text"
                    v-model="edit.ContactPageTitle">
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="videoIntro">
                  <span class="text-red">*</span> 連結：
                </label>
                <div class="col-sm-10">
                  <input 
                    class="form-control"
                    id="ContactPageLink"
                    required="required"
                    type="text"
                    v-model="edit.ContactPageLink">
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="Pic">
                  <span class="text-red">*</span> LOGO：
                </label>
                <div class="col-sm-10">
                  <div class="pic">
                    <img draggable="false" :src="edit.mainImgFullFileName" />
                  </div>
                  <input accept="image/*" id="Pic" type="file" @change="onFileChange($event)" />
                  <div class="help-block">
                    <span class="text-red">請勿小於該尺寸。</span>
                  </div>
                </div>
              </div>

              

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, /*mapActions*/ } from "vuex";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      detailSalePointLang: [],
      edit: {
        salePointId: utils.getTimeId(), //銷售據點Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        salePointUrl: "", // 網址
        salePointType: 0, //銷售據點類型
        salePointDetailId: "", // 內容Id
        detailTopTitle: "", //標題
        isStop: 0, // 狀態
        sortId: 0, // 排序
        mainImgFullFileName: "" // 圖片
      }
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {},
  computed: {
    ...mapState({
      salePointTypes: state => state.classTypes.queryAll,
      salePoint: state => state.salePoint.query,
      detailQueryAll: state => state.salePoint.detailQueryAll,
      projectData: state => state.module.queryAll,
      response: state => state.salePoint.response,
      fileUploadResponse: state => state.file.response
    })
  },
  watch: {
    salePoint() {
      this.edit = this.salePoint;
      this.GetSalePointLangData();
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetSalePointClassTypes();
      // this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetSalePointData();
      } else {
        this.detailSalePointLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.salePointId + i + 1;
          this.detailSalePointLang.push({
            salePointDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle
          });
        }
      }
    },
    detailQueryAll() {
      let that = this;
      this.detailSalePointLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          let findLang = false;
          const Id = this.edit.salePointId + i + 1;
          this.detailQueryAll.datas.some(details => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.salePointDetailId = details.salePointDetailId;
                that.edit.detailTopTitle = details.title;
              }
              that.detailSalePointLang.push({
                salePointDetailId: details.salePointDetailId,
                lang: details.lang,
                title: details.title
              });
              findLang = true;
              return true;
            }
          });

          if (!findLang) {
            that.detailSalePointLang.push({
              salePointDetailId: Id,
              lang: that.langTypes[i].id,
              title: ""
            });
          }

        }
        // this.GetImgList();
      }
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: "SalePointList",
          params: { searchData: this.$route.params.searchData }
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      } else {
        if (this.isEdit) {
          this.$store.dispatch("salePoint/salePointUpdate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        } else {
          this.$store.dispatch("salePoint/salePointCreate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        }
      }
    }
  },
  methods: {
    //取得該銷售據點資料
    GetSalePointData() {
      this.$store.dispatch("salePoint/salePointQuery", {
        salePointId: this.$route.params.id
      });
    },

    //取得該銷售據點多語系資料
    GetSalePointLangData() {
      this.$store.dispatch("salePoint/salePointDetailQueryAll", {
        salePointId: this.$route.params.id
      });
    },

    //取得分類資料
    GetSalePointClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "salepoint",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    // GetImgList() {
    //   this.SetLangData(this.editLang);
    //   this.$store.dispatch("news/newsDetailImgList", {
    //     newsDetailId: this.edit.newsDetailId
    //   });
    // },

    //取得該語系資料
    GetDetailSalePointLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.salePointDetailId = this.detailSalePointLang[
            i
          ].salePointDetailId;
          this.edit.detailTopTitle = this.detailSalePointLang[i].title;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      // this.removeFile();
      this.SetLangData(this.editLang);
      this.GetDetailSalePointLang(lang);
      this.editLang = lang;
      // this.GetImgList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailSalePointLang[i].title = this.edit.detailTopTitle;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "ContactUsList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },
    removeFile() {
      document.getElementById("Pic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.edit.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", "zh-tw");
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data);
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          that.edit.mainImgFullFileName = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      this.edit.salePointType = parseInt(this.edit.salePointType);
      this.SetLangData(this.editLang);

      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      let folderName = "salepoint";
      let override = false;

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "通路名稱不能為空");
        return;
      }

      if (this.edit.salePointUrl == "") {
        this.$eventBus.$emit("showAlert", "網址不能為空");
        return;
      }

      let AdPic = document.getElementById("Pic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.salePointId,
          false,
          override
        );
      } else if (this.edit.mainImgFullFileName == "") {
        this.$eventBus.$emit("showAlert", "請上傳圖片!!");
        return;
      } else {
        if (this.isEdit) {
          this.$store.dispatch("salePoint/salePointUpdate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        } else {
          this.$store.dispatch("salePoint/salePointCreate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        }
      }
    }
  }
};
</script>