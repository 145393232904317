<template>
  <div id="ClassTypeManager">
    <section class="content-header">
      <h1>群組管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">群組管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增
            </button>

            <div class="select-group">
              <select class="form-control" id="selectType" required="required">
                <option value="0" disabled>請選擇訂閱類型</option>
                <option v-for="(type, key) in types" :value="key" :key="key">
                  {{ type }}
                </option>
              </select>
            </div>

            <div class="input-group">
              <input
                name="table_search"
                placeholder="群組名稱、群組說明"
                type="text"
                class="form-control pull-right"
              />
              <div class="input-group-btn">
                <button type="button" class="btn btn-default">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total"> {{ currentData.length }} </span>
              </p>
            </div>

            <!-- <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ news.total }}</span>
              </p>
            </div> -->

            <!-- <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div> -->
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered AdListTable" id="groupSetTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px" width="20%">群組名稱</th>
                  <th style="min-width: 150px" width="25%">群組說明</th>
                  <th style="min-width: 50px" width="10%">群組類型</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 15%"
                  >
                    排序
                  </th>
                  <th
                    align="center"
                    class="text-nowrap"
                    style="min-width: 100px; width: 10%"
                  >
                    啟用狀態
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 200px"
                    width="25%"
                  >
                    功能
                  </th>
                </tr>

                <tr :key="item.id" v-for="item in currentData">
                  <td>{{ item.classTypeId }}</td>
                  <td>{{ item.description }}</td>
                  <td align="center">{{ item.module }}</td>
                  <td class="td-sort text-nowrap">
                    <input type="number" v-model="item.sortId" />
                    <button
                      class="btn btn-warning btn-add btn-upDate"
                      type="button"
                    >
                      更新
                    </button>
                  </td>
                  <td align="center">{{ item.isStop }}</td>

                  <td class="text-center">
                    <button class="btn btn-primary">修改</button>
                    &nbsp;
                    <button @click="deleteItem(item)" class="btn btn-danger">
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="box-footer text-center no-border">
              <Pagination
                ref="pagination"
                :total="currentData.total"
                :pageLimitCount="searchData.pageSize"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      total: 0, //總筆數
      currentPage: 0,
      types: ["全部", "政治", "生活", "時尚"],

      currentData: [
        {
          id: 1,
          classTypeId: "政治一",
          description: "關注快樂無法黨",
          module: "政治",
          sortId: "1",
          isStop: "上線",
        },
        {
          id: 2,
          classTypeId: "政治二",
          description: "關注全民最大黨",
          module: "政治",
          sortId: "2",
          isStop: "上線",
        },
        {
          id: 3,
          classTypeId: "生活一",
          description: "時尚、娛樂",
          module: "生活",
          sortId: "3",
          isStop: "上線",
        },
        {
          id: 4,
          classTypeId: "生活二",
          description: "價格、食材",
          module: "生活",
          sortId: "4",
          isStop: "下線",
        },
      ],

      searchData: {
        classTypeId: "",
        description: "",
        module: "",
        keyword: "",
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
    };
  },
  computed: {},

  methods: {
    addNew() {
      this.$router.push({
        name: "GroupSetEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    // 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
        }
      });
    },
  },
};
</script>
