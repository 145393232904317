<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>{{ editTitle }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公告列表</li>
        <li class="active">{{ editTitle }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ detailTitle }}</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width: 70%;">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 公告分類：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="newsType"
                    :disabled="isEdit"
                  >
                    <option value="官方">官方</option>
                    <option value="個人" :hidden="!isEdit">個人</option>
                  </select>
                </div>
              </div>

              <div class="form-group" v-if="newsType == '官方'">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    required="required"
                    v-model="edit.categoryNo"
                    :disabled="isEdit"
                  >
                    <option value="0" disabled>請選擇語系</option>
                    <option
                      :value="item.categoryNo"
                      v-for="(item, index) in categoryquery"
                      :key="index"
                      >{{ item.language }}</option
                    >
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 主旨：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.articleTitle"
                    :disabled="newsType == '個人'"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <!-- 工具欄容器 -->
                    <!-- <div class="toolbar-container"></div> -->
                    <!-- 編輯器容器 -->
                    <!-- <div class="CKEditor"></div> -->
                    <tinymce
                      v-model="edit.articleContent"
                      :propLang="editLang"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer" v-if="newsType == '官方'">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save()"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    DatePicker,
    tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      editTitle: "公告編輯",
      detailTitle: "編輯內容",
      editLang: "zh-tw",
      newsType: "官方",
      edit: {
        categoryNo: 0,
        articleTitle: "",
        articleContent: ""
      }
    };
  },
  created() {
    this.getCategoryquery();
    if (this.$route.params.lang != null && this.$route.params.id != null) {
      this.isEdit = true;
      this.GetAnnouncement();
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      categoryquery: state => state.qppArticle.categoryquery,
      detailQuery: state => state.qppArticle.detailQuery,
      response: state => state.qppArticle.response
    })
  },
  watch: {
    detailQuery() {
      if (this.detailQuery == null) this.backList();
      this.edit = this.detailQuery;
      if (this.detailQuery.categoryNo > 0) this.newsType = "官方";
      else {
        this.detailTitle = "詳細內容";
        this.editTitle = "公告內容";
        this.newsType = "個人";
      }
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        this.backList();
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    }
  },
  methods: {
    getCategoryquery() {
      //語系
      this.$store.dispatch("qppArticle/categoryquery", {});
    },
    //取得該公告資料
    GetAnnouncement() {
      this.$store.dispatch("qppArticle/DetailQuery", {
        categoryNo: this.$route.params.lang,
        articleId: this.$route.params.id
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "QppAnniuncementList",
        params: {
          searchData: this.$route.params.searchData
        }
      });
    },

    formatTime: function(t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },

    //送出
    Save() {
      if (this.edit.categoryNo == "") {
        this.$eventBus.$emit("showAlert", "請選擇語系");
        return;
      }

      if (this.edit.articleTitle == "") {
        this.$eventBus.$emit("showAlert", "標題不能為空");
        return;
      } else if (this.edit.articleTitle.length > 50) {
        this.$eventBus.$emit("showAlert", "標題長度限制50字");
        return;
      }

      if (this.edit.articleContent == "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return;
      }

      if (this.isEdit) {
        this.$store.dispatch("qppArticle/ArticleEdit", {
          articleId: this.edit.articleId,
          articleTitle: this.edit.articleTitle,
          articleContent: this.edit.articleContent,
          uid: this.edit.authorHide
        });
      } else {
        this.$store.dispatch("qppArticle/ArticleAdd", {
          categoryNo: this.edit.categoryNo,
          articleTitle: this.edit.articleTitle,
          articleContent: this.edit.articleContent
        });
      }
    }
  }
};
</script>
