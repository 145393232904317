<template>
  <FormDialog ref="memberPointDialog" :title="$t('配發點數')"
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>{{$t('配發點數')}}</th>
            <td><input type="number" v-model.number="data.點數" class="form-control"></td>
          </tr>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-model="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{$t('內容')}}</th>
            <td><textarea v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  props:[
    'types'
  ],
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        暱稱:"",
        點數:"",
        內容:"",
        分類:""
      },
      player: null,
    };
  },
  mounted(){
  },
  methods: {
    async show(player) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player;
      this.data.暱稱 = player;
      this.$refs.memberPointDialog.show();
    },
    submit(){
      this.$store.dispatch("xsw/會員配點", this.data).then(() => {
        this.$refs.memberPointDialog.hide();
        this.$eventBus.$emit("showAlert", "配點成功！");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
