<template>
  <div class="slot-empty">
    <img
        v-if="title == '找不到結果'"
        :src="require('@/assets/img/slot-empty.png')"
      />
    <h2>{{ title }}</h2>
    <p>{{ desc }}</p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "無搜尋結果"
    },
    desc: {
      type: String,
      default: "請使用其他關鍵字"
    }
  }
}
</script>