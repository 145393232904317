<template>
  <div class="modal fade" ref="popForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button" @click="hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="box-body">
          <table style="margin:0 auto; width:580px;  ">
            <tr v-for="(item, key) of edit" :key="key">
              <td class="text-right" style="padding-right:10px;"><label>{{ key }}</label></td>
              <td><label class="form-control">{{ item }}</label></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      edit: {
      },
    };
  },
  methods: {
    async show(title, edit) {
      this.title = title;
      this.edit = edit;
      $(this.$refs.popForm).modal("show");
    },
    hide() {
      $(this.$refs.popForm).modal("hide");
    },
  },
}
</script>