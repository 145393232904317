<template>
  <div id="FcAnnouncementEdit">
    <section class="content-header">
      <h1>{{ type }}公告</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/FC/AnnouncementList">官方公告發送</router-link>
        </li>
        <li class="active">{{ type }}公告</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form
            class="form-horizontal"
            name="fcAnnouncementForm"
            method="post"
            @submit.prevent="submit()"
          >
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發送時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="startTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="startTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 截止時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="endTime" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input
                        class="form-control timepicker"
                        type="text"
                        id="endTimeOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="sendAwards">
                  <span class="text-red">*</span> 發送平台：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    style="width: 40%"
                    required
                    v-model="data.platform"
                  >
                    <option value="所有平台">所有平台</option>
                    <option value="Android">Android</option>
                    <option value="iOS">iOS</option>
                  </select>
                </div>
              </div>
              <div class="form-group" v-if="type === '系統'">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 訊息主旨：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    maxlength="30"
                    placeholder="請輸入主旨，上限30字"
                    type="text"
                    required
                    v-model.trim="data.title"
                  />
                  <p class="word-count">
                    {{ data.title.length }} / {{ titleMax }}
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 訊息內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <textarea
                      cols="100"
                      rows="10"
                      class="form-control"
                      :maxlength="contentMax"
                      :placeholder="`請輸入內容，上限${contentMax}字。`"
                      required
                      v-model="data.info"
                    />
                  </div>
                  <p class="word-count">
                    {{ data.info.length }} / {{ contentMax }}
                  </p>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  @click="save(0)"
                  class="btn btn-primary btn-lg"
                  style="margin-right: 10px"
                  type="submit"
                >
                  確認送出
                </button>
                <button
                  @click="backList()"
                  class="btn btn-default btn-lg"
                  type="button"
                >
                  取消
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    DatePicker,
  },
  props: {
    id: "",
    type: "",
  },
  data() {
    return {
      timer: null,
      titleMax: 30,
      contentMax: 500,
      data: {
        title: "",
        info: "",
        platform: "所有平台",
      },
      startTime: new moment().format("YYYY-MM-DD 00:00:00"),
      endTime: new moment().format("YYYY-MM-DD 23:59:59"),
    };
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").unbind("focus");
    this.checkSession();
    this.getData();
  },
  methods: {
    getData() {
      this.contentMax = this.type === "緊急" ? 160 : 500;
      this.data.announcementId = this.id;
      this.data.typeName = this.type;
      if (this.id) {
        this.$store
          .dispatch("fcAnnouncement/query", this.data)
          .then((result) => {
            this.data = result;
            this.updateTime();
          });
      } else {
        this.updateTime();
      }
    },
    updateTime() {
      this.startTime = this.data.sendStartTime || this.startTime;
      this.endTime = this.data.sendEndTime || this.endTime;
      $("#startTimeOnly").val(this.global.getTime(this.startTime));
      $("#endTimeOnly").val(this.global.getTime(this.endTime));
    },
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
      }, 600000);
    },
    backList() {
      this.$router.push({
        name: "FcAnnouncementList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    submit() {
      const startTimeOnly = $("#startTimeOnly").val();
      const endTimeOnly = $("#endTimeOnly").val();
      this.startTime = new Date(
        `${this.startTime.split(" ")[0]} ${startTimeOnly}`
      );
      this.endTime = new Date(`${this.endTime.split(" ")[0]} ${endTimeOnly}`);
      if (!this.data.info) {
        this.$eventBus.$emit("showAlert", "請填寫訊息內容！");
        return;
      }
      if (startTimeOnly === "" || !this.global.isValidDay(this.startTime)) {
        this.$eventBus.$emit("showAlert", "請填寫發送時間！");
        return;
      }
      if (!this.global.isValidFutureDay(this.startTime)) {
        this.$eventBus.$emit("showAlert", "「發送時間」請勿填寫過去時間！");
        return;
      }
      if (endTimeOnly === "" || !this.global.isValidDay(this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫截止時間！");
        return;
      }
      if (!this.global.isValidFutureDay(this.endTime)) {
        this.$eventBus.$emit("showAlert", "「截止時間」請勿填寫過去時間！");
        return;
      }
      if (!this.global.isValidDayRange(this.startTime, this.endTime)) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }
      const api = this.id ? "update" : "create";
      this.data.sendStartTime = new Date(`${this.startTime} UTC`);
      this.data.sendEndTime = new Date(`${this.endTime} UTC`);
      this.$store.dispatch(`fcAnnouncement/${api}`, this.data).then(() => {
        this.backList();
      });
    },
    save(status) {
      this.data.status = status;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>

<style>
#FcAnnouncementEdit .tox-statusbar__wordcount {
  display: none;
}
</style>
