<template>
  <div id="ForumMuteSetting">
    <section class="content-header">
      <h1>論壇禁言管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">論壇禁言管理</li>
      </ol>
    </section>
    <section class="content forum-mute">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
            
            <div class="form-group clearfix">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  v-bind:propEndDate.sync="searchData.endTime"
                  v-bind:propStartDate.sync="searchData.startTime"
                  v-on:restList="GetRestList"
                />
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="輸入門號"
                type="text"
                v-model="searchData.targetMobile"
              />
            </div>
            <button @click="clickSearch()" class="btn btn-default" type="button">查詢</button>
            <p class="text text-red">* 禁言天數不輸入或大於3650天，為永久禁言。</p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-center">編號</th>
                  <th style="min-width: 150px; width: 20%;">門號</th>
                  <th style="min-width: 80px;">禁言天數</th>
                  <th style="min-width: 100px;">更新日期</th>
                  <th style="min-width: 100px;">結束日期</th>
                  <th style="min-width: 200px; width: 20%;">備註</th>
                  <th>操作人</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <tr v-if="muteTotal === 0">
                  <td class="text-center" colspan="8">沒有資料</td>
                </tr>
                <tr
                  :class="DateDiff(item) == 0 ? 'bg-gray-light': ''"
                  :key="index"
                  v-else
                  v-for="(item, index) in muteList"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ item.muteId }}</td>
                  <td class="text-center">
                    <span v-if="!item.editor">
                      <p v-if="item.days > 3650"></p>
                      <p v-else-if="item.days === 0">已解禁</p>
                      <p v-else>{{ item.days }} 天</p>
                    </span>
                    <input
                      class="form-control text-center"
                      min="1"
                      placeholder="輸入天數"
                      style="min-width: 100px;"
                      type="number"
                      v-else
                      v-model="item.days"
                    />
                  </td>
                  <td class="text-center">{{ item.startDate.split(/\s/)[0] }}</td>
                  <td class="text-center">
                    <p class="text-red" v-if="item.days > 3650">永久禁言</p>
                    <p v-else>{{ item.expiryDate.split(/\s/)[0] }}</p>
                  </td>
                  <td>
                    <p v-if="!item.editor">{{ item.note == ''? item.muteReason : item.note }}</p>
                    <input class="form-control" type="text" v-else v-model="item.reason" />
                  </td>
                  <td>{{ item.opreateId }}</td>
                  <td class="text-center">
                    <button @click="change(item)" class="btn btn-primary" v-show="!item.editor">修改</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-show="!item.editor"
                    >刪除</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <select
            @change="pageDisplay()"
            class="form-control page-size"
            v-model="searchData.pageSize"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="muteTotal"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            v-show="muteTotal > 0"
          />
        </div>
      </div>
    </section>
    <forumMuteAdd ref="forumMuteAdd" v-on:restList="GetRestList" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import forumMuteAdd from '@/views/projects/qpp/ForumMuteAdd';

export default {
  components: {
    Pagination,
    DateRangePicker,
    forumMuteAdd,
  },
  data() {
    return {
      defaultDate: '',
      defaultReason: '',
      editorName: this.$store.state.loginUser.EmployeeName,
      reason: '',
      searchData: {
        targetMobile: '',
        page: 1,
        pageSize: 10,
        startTime: new moment().subtract(7, 'd').format('YYYY-MM-DD 00:00:00'),
        endTime: new moment().format('YYYY-MM-DD 23:59:59'),
      },
    };
  },
  mounted() {
    this.queryAll();
  },
  computed: {
    ...mapState({
      muteList: (state) => state.qppMute.muteList.muteData,
      muteTotal: (state) => state.qppMute.muteList.totalNumber,
      response: (state) => state.qppMute.muteResponse,
    }),
  },
  watch: {
    muteList() {
      this.$refs.pagination.setPage(this.searchData.page, this.muteTotal);
    },
    defaultDate(newValue, oldValue) {
      this.defaultDate = newValue;
    },
    defaultReason(newValue, oldValue) {
      this.defaultReason = newValue;
    },
    response(value) {
      if (value == 'ok') {
        this.$eventBus.$emit('showAlert', '已刪除');
        this.queryAll();
      }
    },
  },
  methods: {
    DateDiff(item) {
      // sDate1 和 sDate2 是 2016-06-18 格式
      var aDate, oDate1, oDate2, iDays;
      var sDate1 = item.startDate.split(/\s/)[0];
      var sDate2 = item.expiryDate.split(/\s/)[0];
      aDate = sDate1.split('/');

      // 轉換為 06/18/2016 格式
      oDate1 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0]);

      aDate = sDate2.split('/');
      oDate2 = new Date(aDate[1] + '/' + aDate[2] + '/' + aDate[0]);

      // 把相差的毫秒數轉換為天數
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
      item.days = iDays;

      return iDays;
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;

      this.searchData.endRow = pageIdx * pageSize;
      this.searchData.startRow = this.searchData.endRow + 1 - pageSize;

      this.queryAll();
    },
    pageDisplay() {
      let page = this.searchData.pageSize;
      this.onPageChange(1, Number(page));
    },
    clickSearch() {
      this.searchData.muteId = this.searchData.targetMobile;
      this.onPageChange(1, this.searchData.pageSize);
      if (this.searchData.muteId != '') {
        this.$store.dispatch('qppMute/search', this.searchData);
      }
    },
    GetRestList() {
      this.queryAll();
    },
    queryAll() {
      this.searchData.endRow = this.searchData.page * this.searchData.pageSize;
      this.searchData.startRow =
        this.searchData.endRow + 1 - this.searchData.pageSize;

      this.searchData.expiryDate = this.searchData.endTime; // 禁言到期日
      this.searchData.startDate = this.searchData.startTime; // 禁言開始日
      this.$store.dispatch('qppMute/search', this.searchData);
    },
    // 切換文字或輸入框
    change(item) {
      item.editor = item.editor == true ? false : true;
      this.defaultDate = item.days;

      if (item.note == '') {
        item.reason = item.muteReason;
      } else {
        item.reason = item.note;
      }
      this.defaultReason = item.reason;

      this.$forceUpdate();
    },
    // 儲存
    save(item) {
      if (item.days == 0) {
        this.$eventBus.$emit('showAlert', '天數不可為0');
        return;
      }

      if (item.days != this.defaultDate) {
        if (item.days == '' || item.days > 3650) {
          this.$eventBus.$emit(
            'showConfirm',
            '天數為空或超過3650天，將設定為永久禁言，是否確認?',
            (isOK) => {
              if (isOK) {
                if (item.days == '') item.dateNumber = 0;
                this.editMute(item);
              }
            }
          );
        } else {
          item.dateNumber = item.days;
          this.editMute(item);
        }
      }

      if (item.reason != this.defaultReason) {
        item.dateNumber = item.days;
        this.editMute(item);
      }

      if (item.days == this.defaultDate && item.reason == this.defaultReason) {
        item.editor = item.editor == true ? false : true;
      }
    },

    editMute(item) {
      this.$store.dispatch('qppMute/create', [
        {
          opreateId: this.editorName,
          muteId: item.muteId,
          muteReason: item.muteReason,
          note: item.reason,
          dateNumber: item.dateNumber,
        },
      ]);
      item.editor = item.editor == true ? false : true;
      this.queryAll();
    },

    // 新增
    addNew() {
      this.$refs.forumMuteAdd.showAddMuteBox();
    },

    cancel(item, index) {
      if (item.classTypeId == '') {
        this.muteList.datas.splice(index, 1);
      } else {
        this.queryAll();
      }
    },

    // 刪除
    deleteType(item) {
      let data = {
        opreateId: this.editorName,
        muteId: item.muteId,
      };
      this.$eventBus.$emit(
        'showConfirm',
        '刪除即解除禁言，確定要刪除嗎？',
        (isOK) => {
          if (isOK) {
            this.$store.dispatch('qppMute/delete', data);
            this.queryAll();
          }
        }
      );
    },
  },
};
</script>
