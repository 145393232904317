import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    activityQueryAll({ commit }, data) {
        api.activity.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setActivityQueryAll', res.data.result);
            }
        })
    },
    activityQuery({ commit }, data) {
        api.activity.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setActivityQuery', res.data.result);
            }
        })
    },
    activityCreate({ commit }, data) {
        api.activity.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    activityUpdate({ commit }, data) {
        api.activity.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    activityDelete({ commit }, data) {
        api.activity.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
}

// mutations
const mutations = {
    setActivityQueryAll(state, activityQueryAll) {
        state.queryAll = activityQueryAll;
    },
    setActivityQuery(state, activityQuery) {
        state.query = activityQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}