<template>
  <div id="RedeemPrizesList">
    <section class="content-header">
      <h1>獎項兌換列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>獎項兌換管理</li>
        <li class="active">獎項兌換列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group mr-20">
              <label class="control-label">門號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋玩家識別值、門號"
                type="text"
                v-model="searchData.玩家識別值"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">真實姓名：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋真實姓名"
                type="text"
                v-model="searchData.真實姓名"
              />
            </div>

            <div class="select-group">
              <label class="control-label">活動：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.活動編號"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option
                  :value="item.活動編號"
                  v-for="(item, index) in activityList"
                  :key="index"
                >{{item.活動名稱}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">獎項：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.獎項名稱"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  v-for="(item, index) in adwardList"
                  :key="index"
                  :value="item.獎項名稱"
                >{{item.獎項名稱}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.狀態"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option value="0">未填單</option>
                <option value="1">未處理</option>
                <option value="2">已處理</option>
                <option value="3">停止兌換</option>
              </select>
            </div>

            <div class="input-group mr-20">
              <label class="control-label">填單開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.填單開始時間" />
              </div>
            </div>

            <div class="input-group">
              <label class="control-label">填單結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.填單結束時間" />
              </div>
            </div>

            <div class="text mt-15">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ redeemPrizes.total }}</span>
              </p>
            </div>

            <div class="form-group fl mr-20 snmMT w100-md mt-15-md">
              <button @click="clickSearch" class="btn btn-warning w100-md" type="button">查詢</button>
            </div>

            <div class="form-group fl mr-20 snmMT w100-md mt-15-md">
              <button @click="clearBtn" class="btn btn-danger w100-md" type="button">清除</button>
            </div>

            <div class="form-group fl snmMT w100-md mt-15-md">
              <button @click="exportOrders" class="btn btn-success w100-md" type="button">匯出</button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">編號</th>
                  <th style="min-width: 90px; width: 5%">活動編號</th>
                  <th style="min-width: 90px; width:3%">門號</th>
                  <th class="text-nowrap" style="width: 10%;">獎項名稱</th>
                  <th class="text-nowrap" style="width: 10%;">中獎時間</th>
                  <th class="text-nowrap" style="width: 10%;">填單時間</th>
                  <th class="text-nowrap" style="width: 10%;">真實姓名</th>
                  <th class="text-nowrap" style="width: 10%;">地址</th>
                  <th class="text-nowrap" style="width: 10%;">連絡電話</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap" style="width: 3%;">處理人員</th>
                  <th class="text-nowrap" style="width: 3%;">圖片</th>
                  <th class="text-nowrap" style="width: 10%;">備註</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="redeemPrizes.total > 0">
                  <tr :key="item.id" v-for="(item, index) in redeemPrizes.datas">
                    <td align="center">{{ index+1 }}</td>
                    <td align="center">{{ item.活動編號 }}</td>
                    <td align="center">{{ item.玩家識別值 }}</td>
                    <td align="center">{{ item.獎項名稱 }}</td>
                    <td align="center">{{ item.中獎時間 }}</td>
                    <td align="center">{{ item.填單時間 }}</td>
                    <td align="center">{{ item.真實姓名 }}</td>
                    <td>{{ item.地址 }}</td>
                    <td align="center">{{ item.連絡電話 }}</td>
                    <td align="center">{{ GetStatus(item.狀態)}}</td>
                    <td align="center">{{ item.handleUId + " " + item.handleUName }}</td>
                    <td align="center">
                      <input type="button" v-if="item.files.length > 0" value="檢視" @click="showImg(item)" />
                    </td>
                    <td align="center">{{ item.備註 }}</td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">編輯</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="14">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="redeemPrizes.total > 0">
          <Pagination
            ref="pagination"
            :total="redeemPrizes.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <PictureView ref="pictureview" v-on:refreshList="GetQueryAll" />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import DatePicker from "@/components/DatePicker";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import PictureView from "@/views/RedeemPrizes/PictureView";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
    DateRangePicker,
    DatePicker,
    PictureView
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      activityList: [],
      adwardList: [],
      searchData: {
        // projectId: this.$store.state.projectId,
        活動編號: -1,
        玩家識別值: "",
        真實姓名: "",
        獎項名稱: "",
        狀態: -1,
        填單開始時間: new moment(),
        填單結束時間: new moment(),
        pageNum: 1,
        pageSize: 10 //每頁顯示筆數
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetActivity();
    this.GetQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      redeemPrizes: state => state.redeemPrize.queryAll,
      response: state => state.redeemPrize.response
    })
  },
  watch: {
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetQueryAll();
    },
    redeemPrizes() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.redeemPrizes.total
      );
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetQueryAll();
    },

    GetQueryAll() {
      //列表
      if (this.searchData.填單開始時間 != "")
        this.searchData.填單開始時間 = new moment(
          this.searchData.填單開始時間
        ).format("YYYY/MM/DD 00:00:00");
      if (this.searchData.填單結束時間 != "")
        this.searchData.填單結束時間 = new moment(
          this.searchData.填單結束時間
        ).format("YYYY/MM/DD 23:59:59");
      this.searchData.活動編號 = parseInt(this.searchData.活動編號);
      this.searchData.狀態 = parseInt(this.searchData.狀態);
      this.$store.dispatch("redeemPrize/redeemPrizeQueryAll", this.searchData);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetQueryAll();
      this.GetAdWardList();
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "RedeemPrizesInfo",
        params: { id: item.兌換Id, searchData: this.searchData }
      });
      return;
    },

    showImg(item) {
      this.$refs.pictureview.showPictures(item);
    },

    GetActivity() {
      switch (this.projectId) {
        case "omp_dk":
          this.activityList = [
            { 活動編號: 20000, 活動名稱: "七月-每日簽到打卡活動" }
          ];
          break;
      }
    },

    clearBtn() {
      this.searchData = {
        // projectId: this.$store.state.projectId,
        活動編號: -1,
        玩家識別值: "",
        真實姓名: "",
        獎項名稱: "",
        狀態: -1,
        pageNum: 1,
        pageSize: 10 //每頁顯示筆數
      };
    },

    GetAdWardList() {
      switch (this.projectId) {
        case "omp_dk":
          switch (parseInt(this.searchData.活動編號)) {
            case 20000:
              this.adwardList = [{ 獎項名稱: "特色保溫瓶 *1" }, { 獎項名稱: "造型小夜燈 *1" }, { 獎項名稱: "精美海報" }, { 獎項名稱: "周邊小物組" }];
              break;
            default:
              this.searchData.活動編號 = -1;
              this.adwardList = [];
              break;
          }
          break;
      }
    },

    GetStatus(status) {
      switch (status) {
        case 0:
          return "未填單";
        case 1:
          return "已填單";
        case 2:
          return "已處理";
        case 3:
          return "停止兌換";
      }
    },
    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("redeemPrize/redeemPrizeExport", tmpSearchData);
    }
  }
};
</script>

<style scoped>
.mr-20 {
  margin-right: 20px;
}

.fl {
  float: left;
}
</style>
