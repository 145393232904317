<template>
  <div id="analyze" class="slot">
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ data.文章標題 }}</h4>
          </div>
          <div class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width: none;">
              <div v-html="data.內文" id="article"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="main">
      <div class="app mb-10">
        <div class="photo">
          <img :src="query.圖示" alt="">
        </div>
        <div class="info">
          <p class="name">{{ query.遊戲名稱 }}</p>
          <p class="time">{{ data.發佈時間 | dateTimeFormat }}發佈</p>
        </div>
      </div>
      <button class="btn btn-default btn-play mb-10" type="button" @click="backList(query)">
        此 App 所有分析
      </button>
      <button class="btn btn-default btn-play" type="button" @click="backList()">
        返回集成分析
      </button>
      <div class="sub-title-list">
        <p
          v-for="(sub, index) in h2List"
          :id="`link_h2_${index}`"
          @click="goText(index)"
        >
          {{ sub }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";

export default {
  components: {
    tinymce,
		iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      isEdit: false,
      id: Number(this.$route.params.id) || "",
      fileId: utils.getTimeId(),
      gameList: [],
      data: {},
      視覺型態: "1",
      h2List: [],
		};
  },
  created() {
    if(this.id != "") {
      this.getData();
      this.isEdit = true;
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      query: state => state.slot.gameQuery,
      imgUrlList: state => state.game.imgList
    }),
  },
  watch: {
    imgUrlList() {
      this.addData.封面 = this.imgUrlList[0].imgFullFileName;
      this.$forceUpdate();
    },
  },
  methods: {
    getData() {
      api.slot.document.query({ 編號: this.id })
        .then((res) => {
          this.data = res.data.result;
          this.$store.dispatch("slot/gameQuery", { 編號: res.data.result.遊戲編號 });

          this.$nextTick(function() {
            let article = document.getElementById('article');
            let h2 = article.querySelectorAll('h2');
            h2.forEach((item, index) => {
              item.setAttribute("id", `h2_${index}`)
              this.h2List.push(item.textContent);
            })
            setTimeout(() => {
              $('#link_h2_0').addClass('is-active');
            }, 100);
            
            $(window).on("scroll", () => {
              const links = document.querySelectorAll('.sub-title-list p');
              
              h2.forEach((section) => {
                const sectionTop = $(window).scrollTop();
                const windowHeight = window.innerHeight / 2;
                const targetHeight = section.getBoundingClientRect().top;
                if(sectionTop == 0) {
                  $('.sub-title-list p').removeClass('is-active');
                  $('#link_h2_0').addClass('is-active');
                }
                if(targetHeight <= windowHeight) {
                  links.forEach(function (link) {
                    if (link.getAttribute('id') == 'link_' + section.getAttribute('id')) {
                      link.classList.add('is-active');
                    } else {
                      link.classList.remove('is-active');
                    }
                  });
                }
              })
            })

          })
        })
    },
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片格式不符，請選擇 png 或 jpg。"
            );
            return;
          }
          console.log(fileSize);
          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片超出 5MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files, 'slotGame', this.fileId);
    },
    uploadFile(files, folderName, id, multi = false) {
    let data = new FormData();

    for (var i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    data.append("projectId", this.$store.state.projectId);
    data.append("module", folderName);
    data.append("moduleId", id);
    data.append("lang", "zh-tw");
    data.append("multi", multi);

    this.$store.dispatch("file/fileUpload", data).then(res => {
      if (res.status != "0") this.GetImgList();
    });
    },
    GetImgList() {
      this.$store.dispatch("game/gameImgList", {
        contentId: this.fileId
      });
    },
    goText(index) {
      let top = $(`#h2_${index}`).offset().top;
      let height = $(`#h2_${index}`).outerHeight(true);
      $("html , body").animate({
        scrollTop: top - height
      }, 800);
    },
    save(type) {
      this.addData.存檔型態 = type;

      api.slotGame.document.create(this.addData)
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", "新增成功");
            this.backList()
          } else {
            this.$eventBus.$emit("showAlert", "新增失敗");
          }
        })
    },
    preview() {
      $(this.$refs.preview).modal("show");
    },
		backList(data) {
			this.$router.push({
				name: 'slotAnalyzeList',
				params: {
          id: data ? data.編號 : "",
					searchData: this.$route.params.searchData
				},
			});
		}
	},
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>