<template>
    <div>
        <section class="content-header">
            <h1>手開發票管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">手開發票管理</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="form-group">
                            <select v-model="condition" class=" form-control">
                                <option :value="1">一般條件搜尋</option>
                                <option :value="2">搜尋發票號碼</option>
                                <option :value="3">搜尋交易序號</option>
                            </select>
                        </div>
                        <span v-show="(condition == 2)">
                            <div class="form-group">
                                <input type="text" class="form-control" style="width:115px;" placeholder="發票號碼"
                                    v-model.trim="searchData.發票號碼" />
                            </div>
                        </span>
                        <span v-show="(condition == 3)">
                            <div class="form-group">
                                <input type="text" class="form-control" style="width:115px;" placeholder="交易序號"
                                    v-model.trim="searchData.編號" />
                            </div>
                        </span>
                        <span v-show="(condition == 1)">
                            <div class="form-group">
                                <select class=" form-control" style="width: 110px;" v-model="searchData.時間條件">
                                    <option v-for="(item, key) in 時間條件Enum" :value="item">{{ key }}
                                    </option>
                                </select>
                            </div>
                            <span style="line-height:34px" v-show="searchData.時間條件 == 時間條件Enum.建立時間">
                                <div class="form-group" style="width: 160px;margin-right: 0px;">
                                    <date-picker :config='{
                                        format: "YYYY-MM-DD HH:mm:ss",
                                        showTodayButton: true,
                                        showClear: true,
                                        useCurrent: false,
                                        sideBySide: true,
                                        locale: "zh-tw",
                                    }' v-model="searchData.建立開始時間"></date-picker>
                                </div>
                                ~
                                <div class="form-group" style="width: 160px;">
                                    <date-picker :config='{
                                        format: "YYYY-MM-DD HH:mm:ss",
                                        showTodayButton: true,
                                        showClear: true,
                                        useCurrent: false,
                                        sideBySide: true,
                                        locale: "zh-tw",
                                    }' v-model="searchData.建立結束時間"></date-picker>
                                </div>
                            </span>
                            <div class="form-group" style="width: 100px;" v-show="searchData.時間條件 == 時間條件Enum.發票時間">
                                <date-picker :config='{
                                    format: "YYYY-MM-DD",
                                    showTodayButton: true,
                                    showClear: true,
                                    useCurrent: false,
                                    sideBySide: true,
                                    locale: "zh-tw",
                                }' v-model="searchData.發票日期"></date-picker>
                            </div>
                            <br />
                            <div class="form-group">
                                <label class="control-label">發票種類： </label>
                                <select class=" form-control" style="width: 110px;" v-model="searchData.發票種類">
                                    <option :value="發票種類全部">全部</option>
                                    <option :value="發票種類二聯全部">二聯全部</option>
                                    <option :value="發票種類三聯全部">三聯全部</option>
                                    <option v-for="(item, key) in 發票種類Enum" :value="item">{{ key }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" style="width:90px;" placeholder="買方統編"
                                    v-model.trim="searchData.買方統編" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" style="width:90px;" placeholder="建立者"
                                    v-model.trim="searchData.建立者" />
                            </div>
                            <div class="form-group">
                                <label class="control-label">是否已入排程：</label>
                                <select class="form-control" style="width: 80px;" v-model="searchData.匯入工具">
                                    <option :value="匯入工具全部">全部</option>
                                    <option v-for="(item, key) in 匯入工具Enum" :value="item">{{ key }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label">發票狀態：</label>
                                <select class="form-control" style="width: 100px;" v-model="searchData.發票狀態">
                                    <option :value="手開發票狀態全部">全部</option>
                                    <option v-for="(item, key) in 手開發票狀態Enum" :value="item">{{ key }}
                                    </option>
                                </select>
                            </div>
                        </span>
                        <br>
                        <div class="form-group">
                            <button class="btn btn-primary" type="button" @click="onSearch(1, searchData)">
                                查詢
                            </button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-warning" type="button" @click="openDetail(null)"> 新增 </button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-info" type="button" @click="openImport">
                                Excel匯入
                            </button>
                        </div>
                        <button type="button" class="btn btn-success" @click="$refs.company.Open(false)">
                            常用代碼設定
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-center text-nowrap">
                                        批次處理<br>
                                        <select class="batchSelect" style="margin-bottom: 5px;" v-model="批次處理.操作類型">
                                            <option v-for="(item, key) in 批次修改操作類型Enum" :value="item">{{ key }}
                                            </option>
                                        </select>
                                        <br>
                                        <span class="state icheckbox_flat-green marginRight"
                                            :class="批次處理.是否全選 ? 'checked' : ''" @click="批次處理.是否全選 = !批次處理.是否全選">
                                        </span>
                                        <button class="btn btn-primary" type="button" :disabled="!是否至少一筆被勾選"
                                            @click="clickBatch">執行</button>
                                    </th>
                                    <th>是否已入排程</th>
                                    <th @click="改變排序欄位('交易序號')">
                                        <i class="fa fa-fw fa-long-arrow-up" v-if="searchData.是否正序 == true"></i>
                                        <i class="fa fa-fw fa-long-arrow-down" v-if="searchData.是否正序 == false"></i>
                                        交易序號
                                    </th>
                                    <th>發票狀態</th>
                                    <th>發票號碼</th>
                                    <th>發票開立時間</th>
                                    <th>發票金額</th>
                                    <th>營業稅別</th>
                                    <th>銷售總額</th>
                                    <th>發票種類</th>
                                    <th>買方統編</th>
                                    <th>發票抬頭<br>(買受人)</th>
                                    <th>備註</th>
                                    <th>建立者</th>
                                    <th>功能</th>
                                    <th><span>列印</span><br />
                                        <a href="/download/手開發票熱感機注意事項.pdf" class="btn btn-block btn-success btn-cog"
                                            style="display: inline-block;width: 100px;" type="button" download><i
                                                class="fa fa-fw fa-download"></i>注意事項</a>
                                    </th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.編號">
                                        <td class="text-center">
                                            <span v-show="該項目是否可勾選(item)">
                                                <span class="state icheckbox_flat-green"
                                                    :class="item.checked ? 'checked' : ''" :disabled="true"
                                                    @click="item.checked = !item.checked">
                                                </span>
                                            </span>
                                        </td>
                                        <td class="text-center">{{ item.匯入工具 | TransEnum(匯入工具Enum) }}</td>
                                        <td class="text-center">{{ item.編號 }}</td>
                                        <td class="text-center">{{ item.發票狀態 | TransEnum(手開發票狀態Enum) }}</td>
                                        <td class="text-center">{{ item.發票號碼 }}</td>
                                        <td class="text-center">{{ item.發票時間 | filtersTime }}</td>
                                        <td class="text-center">{{ item.發票金額 }}</td>
                                        <td class="text-center">{{ item.營業稅別 | TransEnum(稅別Enum) }}
                                        </td>
                                        <td class="text-center">{{ item.銷售總額 }}</td>
                                        <td class="text-center">{{ item.發票種類 | TransEnum(發票種類Enum) }}</td>
                                        <td class="text-center">{{ item.統一編號 }}</td>
                                        <td class="text-center">{{ item.買方 }}</td>
                                        <td class="text-center">{{ item.備註 }}</td>
                                        <td class="text-center">{{ item.建立者 }}</td>
                                        <td class="text-center text-nowrap">
                                            <button class="btn btn-primary" type="button" @click="Open商品明細(item)">
                                                商品明細
                                            </button>
                                            <button class="btn bg-purple" v-show="!item.匯入工具"
                                                @click="openDetail(item)">修改</button>
                                            <button class="btn btn-success" v-show="item.匯入工具"
                                                @click="openDetail(item)">查看</button>
                                            <button class="btn btn-danger" v-show="!item.匯入工具" @click="刪除(item)">刪除</button>
                                        </td>
                                        <td class="text-nowrap">
                                            {{ item.列印狀態 | TransEnum(手開發票列印狀態Enum) }}
                                            <br>
                                            <button class="btn btn-info" v-show="item.是否可列印"
                                                @click="列印發票([item], 手開發票列印狀態Enum.未曾列印)">列印</button>
                                            <button class="btn btn-info" v-show="item.是否可列印"
                                                @click="列印發票([item], 手開發票列印狀態Enum.已列印)">補印</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="16">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border">
                    <select class="form-control page-size" v-model="pageSize">
                        <option :value=10>單頁顯示10項</option>
                        <option :value=20>單頁顯示20項</option>
                        <option :value=40>單頁顯示40項</option>
                        <option :value=100>單頁顯示100項</option>
                    </select>
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
        <InvoiceElectronicProcessPopDetail ref="detailForm" @refresh="onPageRefresh(searchData)">
        </InvoiceElectronicProcessPopDetail>
        <InvoiceElectronicProcessPopImport ref="importForm"></InvoiceElectronicProcessPopImport>
        <InvoiceElectronicProcessOneClickSetting ref="company"> </InvoiceElectronicProcessOneClickSetting>
        <div class="mymodal" ref="itemsForm">
            <div style="width:800px;margin-top: 30px;margin-left: auto;margin-right: auto;">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <h3 class="box-title"></h3>
                        <button type="button" class="close" @click="Close商品明細()"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <InvoiceElectronicProcessItems ref="itemsForm" :營業稅.sync="items.營業稅" :營業稅別.sync="items.營業稅別"
                                :商品明細.sync="items.商品明細" :發票種類.sync="items.發票種類" :Const銷售總額.sync="items.銷售總額"
                                :Const發票金額.sync="items.發票金額" :isEdit='false'>
                            </InvoiceElectronicProcessItems>
                        </div>
                        <div class="box-footer">
                            <button type="button" class="btn" style="margin-right: 15px;" @click="Close商品明細()">關閉</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import InvoiceElectronicProcessPopDetail from "./InvoiceElectronicProcessPopDetail.vue";
import InvoiceElectronicProcessPopImport from "./InvoiceElectronicProcessPopImport.vue";
import InvoiceElectronicProcessOneClickSetting from "./InvoiceElectronicProcessOneClickSetting.vue";
import InvoiceElectronicProcessItems from "./InvoiceElectronicProcessItems.vue";
import {
    發票種類Enum,
    發票聯別Enum,
    發票類別Enum,
    發票聯別全部,
    發票類別全部,
    發票種類全部,
    發票種類二聯全部,
    發票種類三聯全部,
    TransEnum, 稅別Enum, 手開發票狀態Enum,
    時間條件Enum, 匯入工具Enum, InvoiceAPI,
    手開發票狀態全部, 批次修改操作類型Enum,
    匯入工具全部,
    手開發票列印狀態Enum,
    Get發票聯別And類型,
    Get發票種類
} from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
import DateTimePicker from "@/components/DateTimePicker";
import "@/views/projects/ips/JsBarcode.code39.min.js";// https://www.npmjs.com/package/jsbarcode
import datePicker from "vue-bootstrap-datetimepicker";
import { Strategies, Validator } from "@/assets/js/Tool/Validator.js";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
    components: {
        Multiselect,
        datePicker,
        Pagination,
        InvoiceElectronicProcessPopDetail,
        InvoiceElectronicProcessPopImport,
        InvoiceElectronicProcessOneClickSetting,
        InvoiceElectronicProcessItems,
        DateTimePicker
    },
    data() {
        let pageSize = 20;
        return {
            發票種類Enum,
            發票聯別Enum,
            發票類別Enum,
            發票聯別全部,
            發票類別全部,
            發票種類全部,
            發票種類二聯全部,
            發票種類三聯全部,
            稅別Enum,
            手開發票狀態Enum,
            時間條件Enum,
            匯入工具Enum,
            手開發票狀態全部,
            批次修改操作類型Enum,
            匯入工具全部,
            手開發票列印狀態Enum,
            condition: 1,
            searchData: {
                發票號碼: "",
                編號: "",
                時間條件: 時間條件Enum.建立時間,
                建立開始時間: new moment().add(-7, "days").format("YYYY-MM-DD 00:00:00"),
                建立結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
                發票日期: new moment().format("YYYY-MM-DD"),
                發票種類: 發票種類全部,
                買方統編: "",
                建立者: "",
                匯入工具: 匯入工具全部,
                發票狀態: 手開發票狀態全部,
                page: 1,
                是否正序: false
            },
            items: {
                商品明細: [
                ],
                營業稅別: 稅別Enum.應稅,
                營業稅: 0,
                發票種類: 0,
                銷售總額: 0,
                發票金額: 0,

                isEdit: false,
            },
            pageSize,
            total: 0,
            list: [],
            批次處理: {
                操作類型: 批次修改操作類型Enum.匯入排程,
                是否全選: false,
            }
        };
    },
    watch: {
        ["批次處理.是否全選"](nowV) {
            if (nowV) {
                this.list.forEach(element => element.checked = this.該項目是否可勾選(element));
            } else {
                this.list.forEach(element => element.checked = false);
            }
        },
        ["批次處理.操作類型"]() {
            this.批次處理.是否全選 = false;
            this.list.forEach(element => element.checked = false);
        },
        pageSize(v) {
            this.onPageChange(1);
        }
    },
    computed: {
        是否至少一筆被勾選() {
            return this.list.some((element) => { return element.checked; });
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            $(this.$refs.loading).show();
            this.Close商品明細();
            await this.onSearch(1, this.searchData);
            $(this.$refs.loading).hide();
        },
        //Page用
        onPageChange(pageIdx) {
            $(this.$refs.loading).show();
            this.onSearch(pageIdx, this.searchData);
            $(this.$refs.loading).hide();
        },
        onPageRefresh(條件) {
            $(this.$refs.loading).show();
            this.searchData = { ...條件 }
            this.onSearch(1, this.searchData);
            $(this.$refs.loading).hide();
        },
        改變排序欄位(欄位) {
            $(this.$refs.loading).show();
            if (this.searchData.是否正序 == true) {
                this.searchData.是否正序 = false;
            } else {
                this.searchData.是否正序 = true;
            }
            this.onSearch(1, this.searchData);
            $(this.$refs.loading).hide();
        },
        async onSearch(pageIdx, searchData) {
            try {
                let query = {
                    頁數: pageIdx,
                    每頁筆數: this.pageSize,
                    是否正序: searchData.是否正序,
                };
                if (this.condition == 2) {
                    if (searchData.發票號碼 == "") {
                        throw "請輸入發票號碼";
                    }
                    query.發票號碼 = searchData.發票號碼;
                } else if (this.condition == 3) {
                    let error = new Validator().add(searchData.編號, [{
                        strategy: Strategies.NZeroPositiveInteger,
                        errorMsg: '交易序號請輸入數字'
                    }]).start();
                    if (error) {
                        throw error;
                    }
                    query.編號 = searchData.編號;
                } else {
                    if (searchData.匯入工具 != 匯入工具全部) {
                        query.匯入工具 = searchData.匯入工具;
                    }
                    if (searchData.發票狀態 != 手開發票狀態全部) {
                        query.發票狀態 = searchData.發票狀態;
                    }

                    if (searchData.發票種類 != 發票種類全部) {
                        let tmp = Get發票聯別And類型(searchData.發票種類);
                        query.發票聯別 = tmp.聯別;
                        query.發票類別 = tmp.類型;
                    }
                    if (searchData.時間條件 == 時間條件Enum.發票時間) {
                        query.發票日期 = searchData.發票日期;
                    } else {
                        if (moment(searchData.建立開始時間) >= moment(searchData.建立結束時間)) {
                            throw "請輸入正確的結束時間"
                        }
                        query.開始時間 = searchData.建立開始時間;
                        query.結束時間 = searchData.建立結束時間;
                    }
                    if (searchData.買方統編 != "") {
                        query.買方統編 = searchData.買方統編;
                    }
                    if (searchData.建立者 != "") {
                        query.建立者 = searchData.建立者;
                    }
                }
                let res = InvoiceAPI(await api.IPSSetting.GetManualInvoiceList(query));
                //特殊處理，別的頁碼找不到資料，回到第一頁
                if (pageIdx != 1 && res.datas.length == 0) {
                    pageIdx = query.頁數 = 1;
                    res = InvoiceAPI(await api.IPSSetting.GetManualInvoiceList(query));
                }
                this.searchData.page = pageIdx;
                //轉換資料格式-start
                this.list = res.datas.map((i) => {
                    i.發票種類 = Get發票種類(i.發票聯別, i.發票類別);
                    i.營業稅別 = i.稅別;
                    delete (i.稅別);
                    i.營業稅 = i.稅額;
                    delete (i.稅額);
                    if (i.明細 == 0) {
                        i.商品明細 = [];
                    } else {
                        i.商品明細 = i.明細;
                    }
                    delete (i.明細);
                    i.checked = false;
                    i.是否可匯入排程 = !i.匯入工具;

                    i.是否可列印 = i.發票號碼 && /*i.列印狀態 == 手開發票列印狀態Enum.未曾列印 &&*/ (
                        (i.發票狀態 == 手開發票狀態Enum.一般 && i.發票聯別 == 發票聯別Enum.三聯 && i.發票類別 == 發票類別Enum.紙本)
                        || (i.發票狀態 == 手開發票狀態Enum.一般 && i.發票聯別 == 發票聯別Enum.三聯 && i.發票類別 == 發票類別Enum.交換)
                        || (i.發票狀態 == 手開發票狀態Enum.一般 && i.發票聯別 == 發票聯別Enum.二聯 && i.發票類別 == 發票類別Enum.紙本)
                        || (i.發票狀態 == 手開發票狀態Enum.一般 && i.發票聯別 == 發票聯別Enum.二聯 && i.發票類別 == 發票類別Enum.載具)
                        // 目前沒有折讓單的列印功能，因此先關閉
                        // || (i.發票狀態 == 手開發票狀態Enum.已折讓)
                    );
                    i.是否可批次列印 = i.是否可列印;
                    return i;
                });
                //轉換資料格式-end
                this.$refs.pagination.setPage(
                    pageIdx,
                    res.total
                );
                this.total = res.total;
            } catch (e) {
                let sucess = e && e.data && e.data.sucess;
                let errMsg = e && e.data && e.data.errMsg;
                if (!sucess && errMsg === null) {
                    this.$eventBus.$emit("showAlert", "無資料");
                } else {
                    this.$eventBus.$emit("showAlert", e);
                }
                this.list = [];
                this.$refs.pagination.setPage(
                    1,
                    0
                );
                this.total = 0;
            }
        },
        //Page用 End
        async 刪除({ 編號 }) {
            $(this.$refs.loading).show();
            try {
                InvoiceAPI(await api.IPSSetting.RemoveManualInvoice({ 編號 }));
                this.$eventBus.$emit("showAlert", "成功");
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            await this.onSearch(1, this.searchData);
            $(this.$refs.loading).hide();
        },
        openDetail(data) {
            this.$refs.detailForm.Open(data);
        },
        openImport() {
            this.$refs.importForm.Open();
        },
        async clickBatch() {
            if (!this.是否至少一筆被勾選) {
                this.$eventBus.$emit("showAlert", "至少選取一筆資料");
                return;
            }
            $(this.$refs.loading).show();
            try {
                if (this.批次處理.操作類型 == 批次修改操作類型Enum.匯入排程) {
                    let 過去基準時間 = moment().subtract(1, 'days').format("YYYY-MM-DD");
                    let 過去發票List = [];
                    let idList = this.list.filter(item => { return item.checked }).map(item => {
                        if (moment(item.發票時間) < moment(過去基準時間)) {
                            過去發票List.push(item.編號);
                        }
                        return item.編號
                    });
                    InvoiceAPI(await api.IPSSetting.BatchUpdateManualInvoice({
                        操作: this.批次處理.操作類型,
                        編號: idList
                    }));
                    this.$eventBus.$emit("showAlert", "匯入成功" + (過去發票List.length > 0 ? "，過去發票請至開立工具手動配發票號碼，交易序號:" + 過去發票List.join(",") : ""));
                } else {
                    let printList = this.list.filter(element => element.checked);
                    this.列印發票(printList);
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            } finally {
                this.onPageRefresh(this.searchData);
            }
            $(this.$refs.loading).hide();
        },
        Open商品明細({ 商品明細, 營業稅別, 營業稅, 發票種類, 銷售總額, 發票金額 }) {
            this.items.商品明細 = 商品明細;
            this.items.營業稅別 = 營業稅別;
            this.items.營業稅 = 營業稅;
            this.items.發票種類 = 發票種類;
            this.items.銷售總額 = 銷售總額;
            this.items.發票金額 = 發票金額;
            $(this.$refs.itemsForm).show();
        },
        Close商品明細() {
            $(this.$refs.itemsForm).hide();
        },
        async 列印發票(items, 列印格式 = "一般") {
            let idList = items.map(item => { return item.編號 });
            InvoiceAPI(await api.IPSSetting.BatchUpdateManualInvoice({
                操作: 批次修改操作類型Enum.列印發票,
                編號: idList
            }));
            let clone = JSON.parse(JSON.stringify(items));

            if (列印格式 === 手開發票列印狀態Enum.未曾列印 || 列印格式 === 手開發票列印狀態Enum.已列印) {
                for (let i of clone) {
                    i.列印狀態 = 列印格式;
                }
            }
            //資料量太大用router的get方式怕會遺失，因此先暫存到localStorage
            localStorage.setItem('printerData', JSON.stringify(clone));
            let routeUrl = this.$router.resolve({ path: "/InvoiceElectronicProcessPrint", query: {} });
            window.open(routeUrl.href, '_blank');
        },
        該項目是否可勾選(item) {
            return !((this.批次處理.操作類型 == 批次修改操作類型Enum.匯入排程 && !item.是否可匯入排程) ||
                (this.批次處理.操作類型 == 批次修改操作類型Enum.列印發票 && !item.是否可批次列印))
        }
    },
    filters: {
        TransEnum,
        filtersTime(v) {
            if (v == 0) {
                return "尚未開立";
            }
            return moment(v).format("YYYY-MM-DD");
        }
    }
};
</script>
  
<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.marginRight {
    margin-right: 10px;
}

.page-size {
    float: left;
    width: auto;
}

.batchSelect {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #d2d6de;
    /* display: block; */
    /* width: 100%; */
    height: 32px px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
}

.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.iradio_flat-blue {
    margin-left: 0px;
}

.iradio_flat-blue[checked] {
    background-position: -110px 0;
}

/* .top-box .form-group {
    margin: 0 0 5px 0;
} */

div .col-md-10 {
    padding-left: 0px;
    padding-right: 0px;
}

/* .icheckbox_flat-green {
    margin-right: 10px;
} */
</style>
<style lang="scss" >
.multiselect {
    height: 40px;
}

.multiselect__single {
    font-size: 14px;
    padding-left: 0;
}

.multiselect__input {
    font-size: 14px;
    padding-left: 0px;
    padding-top: 2px;
}
</style>