<template>
  <div class="message_style" v-if="$user.hasPermission('MessageSystem')">
    <section class="content-header">
      <h1>{{ $t('訊息系統') }}
        <i class="fa fa-warning" style="color:red;cursor: pointer;" :title="$t('下載事件記錄')" v-show="是否顯示異常按鈕"
          @click="刷新畫面()"></i>
        <i class="fa fa-check-square" style="color:red;cursor: pointer;" title="" v-show="isDebug"
          @click='$store.dispatch("messageSystemServer/ReadLog", "")'></i>
      </h1>
      <span class="copyTip" ref="copyTip"><i class="fa fa-check"></i> {{ $t('複製成功') }}</span>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">{{ $t('訊息系統') }}</li>
      </ol>
    </section>
    <ServerStatusForm></ServerStatusForm>
    <section class="content" :style="{ display: ServerIsOnline != 'Connected' ? 'none' : '' }">
      <div class="row">
        <div class="col-sm-3 col-xs-12" style="border-top: 1px solid #E8E8E8;border-right: 1px solid #E8E8E8;">
          <div class="left-nav">
            <select class="form-control" v-model="sel左邊Tool">
              <option value="1">{{ $t('客服狀態') }}</option>
              <option value="2">{{ $t('罐頭訊息') }}</option>
            </select>
          </div>
          <div class="left-content" v-show="sel左邊Tool == 1">
            <div class="left-content-cs-list">
              <table>
                <tbody>
                  <tr>
                    <th>{{ $t('客服名稱') }}</th>
                    <th>{{ $t('允許派發') }}</th>
                    <th>{{ $t('服務數量') }}</th>
                  </tr>
                  <template v-if="Sort線上客服列表.length > 0">
                    <tr v-for="(item) in Sort線上客服列表" :key="item.Account" @click="sel客服 = item.Account" class="tr_hover">
                      <td>{{ item.AccountName }} </td>
                      <td>
                        <span class="fa fa-bell-o" v-if="item.AutoDispatchState"></span>
                        <span class="fa fa-star-o" v-if="item.IsServiceVIP"></span>
                      </td>
                      <td>{{ item.ServiceCount }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="text-center" colspan="3">{{ $t('沒有資料') }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="left-content-cs-list">
              <template v-if="左邊線上玩家列表.length > 0">
                <table>
                  <tbody>
                    <tr>
                      <th colspan="2">{{ sel客服Name }} {{ $t('服務會員列表') }}</th>
                    </tr>
                    <tr v-for="玩家 of 左邊線上玩家列表" :key="玩家.EventId" @click="開始會員聊天(玩家.Nick)" class="tr_hover">
                      <td>
                        {{ 玩家.Nick }}
                        <span class="badge"
                          :class="{ 'hide': 玩家.NoReturnCount == 0, 'color_blue': 玩家.是否出現接手事件按鈕, 'color_red': !玩家.是否出現接手事件按鈕 }">
                          {{ 玩家.NoReturnCount }}
                        </span>
                      </td>
                      <td>
                        <template v-if="玩家.倒數時間或最後時間.顯示倒數">{{ 玩家.倒數時間或最後時間.顯示字串 }}</template>
                        <template v-else>{{ 玩家.倒數時間或最後時間.顯示字串 }}</template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
          <div class="left-content" v-show="sel左邊Tool == 2">
            <CanMessageForm ref="canMessageForm" @addCanMessag="addCanMessag"></CanMessageForm>
          </div>
        </div>
        <div class="col-sm-6 col-xs-12" style="background: #F8F8FA;" v-show="messageForm == MessageForm.Chat">
          <div class="box box-warning direct-chat direct-chat-warning" v-show="聊天視窗_事件物件.EventId != ''">
            <div class="box-header defineHeader with-border-top with-border-bottom" style="background-color: #fff;">
              <h3 class="box-title">{{ 聊天會員對象 }} <i class="fa fa-copy" style="color:gray;cursor: pointer;"
                  @click="一鍵複製(聊天會員對象)"></i></h3>
              <br>
              <div class="box-title" style="font-size: 12px;color: #979797;">{{ $t('最後更新時間') }}：{{ 聊天視窗_格式化最後更新時間 }}
              </div>
              <div class="eventTools">
                <!-- <button class="tool" @click="Test()">測試</button> -->
                <button class="tool" v-if="MessageSystemStartService && 聊天視窗_事件物件.強制接手權限" @click="強制接手事件()">{{
    $t('接手事件')
  }}</button>
                <button class="tool" v-if="MessageSystemFinishService && 聊天視窗_事件物件.聊天視窗_強制分類和完成事件"
                  @click="openPopForceCompleteForm(聊天視窗_事件物件)">{{
    $t('強制結束')
  }}</button>
                <span class="tool" v-if="聊天視窗_事件物件.事件狀態是否為已結束"> <i class="fa fa-check"></i> {{ $t('已完成') }} </span>
                <button class="tool" v-if="聊天視窗_事件物件.聊天視窗_接手事件按鈕" @click="startService()">{{ $t('接手事件') }}</button>
                <button class="tool done" v-if="聊天視窗_事件物件.聊天視窗_分類和完成事件" @click="openPopCompleteForm(聊天視窗_事件物件)">{{
    $t('分類和完成事件')
  }}</button>
              </div>
            </div>
            <div class="box-body">
              <ChatMessageForm historyType="ChatHistory"> </ChatMessageForm>
              <SendMessageBtn ref="sendChatMessageBtn" @SendChatMessage="sendChatMessage" :是否可發訊息='聊天視窗_是否可發訊息'
                :提示訊息='聊天視窗_提示訊息' @UpdChatMessage="updChatMessage">
              </SendMessageBtn>
            </div>
          </div>
          <div class="body-align-center-middle2 with-border-top" v-show="聊天視窗_事件物件.EventId == ''">
            {{ $t('請選擇聊天室') }}
          </div>
        </div>
        <div class="col-sm-6 col-xs-12" style="background: #F8F8FA;" v-show="messageForm == MessageForm.Secret">
          <h3 class="secret-title with-border-top with-border-bottom">{{ $t('新增密語') }}</h3>
          <div class="body-align-center-middle">
            <div style="font-size: 16px;">{{ $t('密語對象') }}</div>
            <input type="text" :placeholder="$t('請輸入玩家暱稱')" class="form-control secret-player-input"
              v-model.trim="secretPlayer">
          </div>
          <div style="position: absolute;bottom: 0px;right: 0;left: 0px;">
            <SendMessageBtn ref="sendSecretMessageBtn" @SendChatMessage="sendSecretChatMessage" :是否可發訊息='true'
              :提示訊息="$t('請輸入內容')">
            </SendMessageBtn>
          </div>

        </div>
        <div class="col-sm-3 col-xs-12" style="border-top: 1px solid #E8E8E8;border-left: 1px solid #E8E8E8;">
          <div class="btn-group-message">
            <button type="button" class="btn" :class="{ active: selProcessStatus == -1 }"
              @click="selProcessStatus = -1">{{
    $t('全部') }}</button>
            <button type="button" class="btn" :class="{ active: selProcessStatus == -2 }"
              @click="selProcessStatus = -2">{{
    $t('處理中') }}</button>
            <button type="button" class="btn" :class="{ active: selProcessStatus == -3 }"
              @click="selProcessStatus = -3">{{
    $t('已完成') }}</button>
          </div>
          <div class="btn-group-message" style="margin-top: -9px;">
            <button type="button" class="btn" :class="{ active: sel事件 == 0 }" @click="sel事件 = 0">
              <span>{{ $t('所有事件') }}</span> <span class="badge color_blue" v-if="總未回數 > 0">
                {{ 總未回數 }}</span>
            </button>
            <button type="button" class="btn" :class="{ active: sel事件 == 1 }" @click="sel事件 = 1">
              <span>{{ $t('我的事件') }}</span> <span class="badge color_red" v-if="我的未回數 > 0">
                {{ 我的未回數 }}
              </span>
            </button>
          </div>
          <div class="right-content cal-list-height">
            <template v-if="右邊線上玩家列表.length > 0">
              <div v-for="玩家 of 右邊線上玩家列表" :key="玩家.EventId" @click="開始會員聊天(玩家.Nick, 玩家.EventId); consoleEvent(玩家)"
                class="rigth-event-div" :class="clickEventId == 玩家.EventId ? 'active' : ''">
                <div class="first-row">
                  <span class="player-name"> {{ 玩家.Nick }} </span>
                  <span class="vip" v-show="玩家.Rank">{{ 玩家.Rank }}</span>
                  &nbsp;&nbsp;
                  <span class="badge" style="vertical-align: top;"
                    :class="{ 'hide': 玩家.NoReturnCount == 0, 'color_blue': 玩家.是否出現接手事件按鈕, 'color_red': !玩家.是否出現接手事件按鈕 }">
                    {{ 玩家.NoReturnCount }}</span>
                  <div class="editBtn" v-show="玩家.聊天視窗_分類和完成事件">
                    <i class="fa fa-edit" style="cursor: pointer;" @click="openPopCompleteForm(玩家)"></i>
                  </div>
                  <div class="editBtn" v-show="MessageSystemFinishService && 玩家.聊天視窗_強制分類和完成事件">
                    <i class="fa fa-edit" style="cursor: pointer;" @click="openPopForceCompleteForm(玩家)"></i>
                  </div>
                </div>
                <div class="font-style-ellipsis last-message">
                  {{ 玩家.LastMsg.Msg }}
                </div>
                <div class="second-row">
                  <span v-if="玩家.倒數時間或最後時間.顯示倒數" class="wait_time">{{ 玩家.倒數時間或最後時間.秒數 }}{{ $t('秒') }}</span>
                  <span class="end_time" v-else>{{ 玩家.倒數時間或最後時間.顯示字串 }}</span>
                  <span v-if="selProcessStatus == -1">
                    ·{{ 事件狀態Tag(玩家.ProcessStatus, 玩家.EventType) }}
                  </span>
                  <span style="color:#f39c12;padding-left: 3px;" v-show="玩家.EventType">
                    <i class="fa fa-check-circle"></i>
                  </span>
                  <span class="serviceName" v-if="sel事件 == 0 && 玩家.顯示客服名稱">
                    {{ 玩家.CSName }}
                  </span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
    <PopCompleteForm ref="popCompleteForm" @FinishService="finishService" @ResetEventType="resetEventType">
    </PopCompleteForm>
    <PopForceCompleteForm ref="popForceCompleteForm" @EnforceFinishService="enforceFinishService"
      @ResetEventType="resetEventType">
    </PopForceCompleteForm>
  </div>
</template>
<script>
import SendMessageBtn from "./MessageSystemComponents/SendMessageBtn";
import ChatMessageForm from "./MessageSystemComponents/ChatMessageForm";
import PopCompleteForm from "./PopCompleteForm";
import PopForceCompleteForm from "./PopForceCompleteForm";
import ServerStatusForm from "@/views/projects/MessageSystem/MessageSystemComponents/ServerStatusForm";
import CanMessageForm from "./MessageSystemComponents/CanMessageForm";
import {
  訊息系統,
  ProcessStatus,
  檢查事件狀態,
  格式化最後時間,
  PlatformType,
  取得客服Account,
  MessageForm,
  ProjectSetting,
  InvokeCmd,
} from "@/store/MessageSystem/messageSystemConst";
import {
  FixResize,
} from "@/store/MessageSystem/messageSystemLib";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    SendMessageBtn,
    PopCompleteForm,
    moment,
    ChatMessageForm,
    PopForceCompleteForm,
    ServerStatusForm,
    CanMessageForm
  },
  data() {
    let data = {
      sel左邊Tool: 2,//1:客服狀態 2:罐頭訊息
      isDebug: process.env.VUE_APP_DEBUG === 'true',
      sel客服: "", //選中的客服
      selProcessStatus: -1, //選取的狀態 -1:全部；-2:處理中(狀態含1跟2)；-3:已結束(狀態3)
      sel事件: 0, //0:所有事件；1:我的事件,
      clickEventId: "",//點選事件，前端記錄當前點選的事件
      現在時間: new Date().getTime(),
      更新時間interval: 0,
      MessageForm,
      secretPlayer: "",//密語對象
      MessageSystemFinishService: false,
      MessageSystemStartService: false,
    };
    return data;
  },
  computed: {
    ...mapState({
      CSLoginUser: state => state.messageSystemServer.CSLoginUser,
      線上客服列表: state => state.messageSystemServer.線上客服列表,
      聊天會員對象: state => state.messageSystemServer.聊天會員對象,
      ServerIsOnline: state => state.messageSystemServer.ServerIsOnline,
      我的未回數: state => state.messageSystemServer.我的未回數,
      總未回數: state => state.messageSystemServer.總未回數,
      客服顯示對應表: state => state.messageSystemServer.客服顯示對應表,
      異常狀態: state => state.messageSystemServer.異常狀態,
      最後一次刷新時間: state => state.messageSystemServer.最後一次刷新時間,
      messageForm: state => state.messageSystemServer.messageForm,
    }),
    ...mapGetters({
      線上玩家列表: 'messageSystemServer/計算玩家列表',
    }),
    Sort線上客服列表() {
      return this.線上客服列表.sort((a, b) => {
        return a.AutoDispatchState ? -1 : 1;
      });
    },
    聊天視窗_事件物件() {
      let 最新事件 = {
        Platform: 0,
        EventId: "",
        Nick: "",
        Cs: "",
        State: true,
        ProcessStatus: 2,
        StartTime: 0,
        FirstMsgTime: 0,
        NoReturnCount: 1,
        LastMsg: {
          Sender: null,
          EventId: null,
          Msg: "",
          Time: 0,
          ChatMsgType: 1
        }
      };
      let startTime = 0;
      for (let 玩家資料 of this.線上玩家列表) {
        if (玩家資料.Nick != this.聊天會員對象) {
          continue;
        }
        if (玩家資料.ProcessStatus == ProcessStatus.None.value || 玩家資料.ProcessStatus == ProcessStatus.Assigned.value || 玩家資料.ProcessStatus == ProcessStatus.Processing.value) {
          return 玩家資料;
        } else {
          if (startTime < 玩家資料.StartTime) {
            最新事件 = 玩家資料;
            startTime = 玩家資料.StartTime;
          }
        }
      }
      return 最新事件;
    },
    聊天視窗_格式化最後更新時間() {
      return 格式化最後時間(
        this.現在時間,
        this.聊天視窗_事件物件,
        moment
      );
    },
    聊天視窗_Tag顏色() {
      if (this.聊天視窗_事件物件) {
        return PlatformType[this.聊天視窗_事件物件.Platform].Color;
      } else {
        return "bg-green";
      }
    },
    聊天視窗_Tag平台() {
      if (this.聊天視窗_事件物件) {
        return PlatformType[this.聊天視窗_事件物件.Platform].Type;
      }
      return "";
    },
    聊天視窗_是否可發訊息() {
      if (this.聊天視窗_事件物件) {
        return 檢查事件狀態.是否可發訊息(
          this.聊天視窗_事件物件,
          this.CSLoginUser
        );
      }
      return false;
    },
    聊天視窗_提示訊息() {
      if (this.聊天視窗_事件物件) {
        let msg = 檢查事件狀態.提示訊息(
          this.聊天視窗_事件物件,
          this.CSLoginUser,
        );
        return this.$t(msg, { CSName: this.聊天視窗_事件物件.CSName });
      }
      return "";
    },
    左邊線上玩家列表() {
      if (this.sel客服 == "") {
        return [];
      }
      let list = this.線上玩家列表.filter(item => {
        return (item.Cs == this.sel客服) && (
          item.ProcessStatus == ProcessStatus.Assigned.value ||
          item.ProcessStatus == ProcessStatus.Processing.value
        );
      });
      return list;
    },
    右邊線上玩家列表() {
      let list = [];
      if (this.sel事件 == 1) {
        list = this.線上玩家列表.filter(item => {
          return item.Cs == this.CSLoginUser;
        });
      } else {
        list = this.線上玩家列表;
      }
      switch (this.selProcessStatus) {
        case -1:
          list = list.filter(item => {
            return 檢查事件狀態.按鈕全部(item);
          });
          break;
        case -2:
          list = list.filter(item => {
            return 檢查事件狀態.按鈕處理中(item);
          });
          break;
        case -3:
          list = list.filter(item => {
            return 檢查事件狀態.按鈕已結束(item);
          });
          break;
      }

      //要顯示秒數的排前面
      //都要顯示的，秒數大的排前面
      //不顯示的，照最後一則訊息的時間排，愈接近現在時間的排前面
      list.sort((a, b) => {
        if (a.倒數時間或最後時間.顯示倒數 && !b.倒數時間或最後時間.顯示倒數) {
          return -1;
        } else if (!a.倒數時間或最後時間.顯示倒數 && b.倒數時間或最後時間.顯示倒數) {
          return 1;
        } else if (a.倒數時間或最後時間.顯示倒數 && b.倒數時間或最後時間.顯示倒數) {
          if (a.倒數時間或最後時間.秒數 > b.倒數時間或最後時間.秒數) {
            return -1;
          } else if (a.倒數時間或最後時間.秒數 < b.倒數時間或最後時間.秒數) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a.LastMsg.Time > b.LastMsg.Time) {
            return -1;
          } else if (a.LastMsg.Time < b.LastMsg.Time) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      return list;
    },
    sel客服Name() {
      return 取得客服Account(this.客服顯示對應表, this.sel客服);
    },
    是否顯示異常按鈕() {
      // +30 是30秒後
      return this.異常狀態 && this.最後一次刷新時間 < (Math.floor(new Date().getTime() / 1000) + 30);
    },
  },
  watch: {
    messageForm(val) {
      //清空畫面資料
      this.secretPlayer = '';
      if (this.$refs.sendChatMessageBtn) {
        this.$refs.sendChatMessageBtn.addCanMessag("");
      }
      if (this.$refs.sendSecretMessageBtn) {
        this.$refs.sendSecretMessageBtn.addCanMessag("");
      }
      let defType = "all";
      if (val == MessageForm.Secret) {
        defType = ProjectSetting.密語預設類別(this.$store.state.projectId);
      }
      this.$refs.canMessageForm.Public設定選取類別(defType);
    },
  },
  mounted() {
    this.init();
    this.更新時間interval = setInterval(() => {
      this.現在時間 = new Date().getTime() - this.$store.state.messageSystemServer.Client時間 + this.$store.state.messageSystemServer.Server時間;
      for (let 玩家 of this.線上玩家列表) {
        玩家.倒數時間或最後時間 = 檢查事件狀態.顯示倒數時間或最後時間(
          玩家,
          this.現在時間,
          moment
        );
      }
    }, 1000);
    window.addEventListener('resize', this.fixResize);
  },
  beforeDestroy() {
    this.$store.dispatch("messageSystemServer/開始聊天", { 聊天對象: "", 訊息系統: 訊息系統.會員系統 });
    clearInterval(this.更新時間interval);
    window.removeEventListener('resize', this.fixResize);
  },
  methods: {
    fixResize() {
      FixResize();
    },
    async init() {
      this.$nextTick(function () {
        this.MessageSystemFinishService = this.$user.hasPermission('MessageSystemFinishService');
        this.MessageSystemStartService = this.$user.hasPermission('MessageSystemStartService');
      });
    },
    testDisconned() {
      this.$store.dispatch("messageSystemServer/stop", {});
    },
    sendSecretChatMessage(msg) {
      let nick = this.secretPlayer;
      if (nick == "") {
        this.$eventBus.$emit("showAlert", this.$t('玩家暱稱不可為空'));
        return;
      }
      this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.SendMessage, nick, msg, true]);
      this.secretPlayer = "";
    },
    sendChatMessage(msg) {
      if (this.聊天會員對象 == "") {
        this.$eventBus.$emit("showAlert", this.$t('玩家暱稱不可為空'));
        return;
      }
      this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.SendMessage, this.聊天會員對象, msg, false]);
    },
    updChatMessage(msg) {
      this.$store.dispatch("messageSystemServer/更新暫存聊天訊息", { 聊天對象: this.聊天會員對象, 訊息系統: 訊息系統.會員系統, 訊息: msg });
    },
    finishService(eventId, eventType, remark) {
      this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.FinishService,
        eventId,
        eventType,
        remark
      ]).catch(err => {
        this.$eventBus.$emit("showAlert", err.toString());
      });
    },
    enforceFinishService(eventId, eventType, remark) {
      this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.EnforceFinishEvent,
        eventId,
        eventType,
        remark
      ]).catch(err => {
        this.$eventBus.$emit("showAlert", err.toString());
      });
    },
    resetEventType(eventId, eventType, remark) {
      this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.ResetEventType,
        eventId,
        eventType,
        remark
      ]).catch(err => {
        this.$eventBus.$emit("showAlert", err.toString());
      });
    },
    Test() {
      console.error(this.$store.getters["messageSystemServer/ChatMessage"]);
    },
    強制接手事件() {
      this.$eventBus.$emit("showAsyncConfirm", this.$t('確定要強制接手事件嗎？'), this.$t('強制接手事件'), () => {
        this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.StartService, this.聊天視窗_事件物件.EventId]);
      }, () => {
        //關閉按鈕按下後，要執行的事
      });
    },
    startService() {
      this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.StartService, this.聊天視窗_事件物件.EventId]);
    },
    consoleEvent(EventId) {
      // if (process.env.NODE_ENV == 'development') {
      //   console.error("詳細資訊", JSON.stringify(EventId));
      //   console.error("右邊列表-全部", 檢查事件狀態.按鈕全部(EventId), "true");
      //   console.error("右邊列表-處理中", 檢查事件狀態.按鈕處理中(EventId), "(1+2) || (3+4+5+6)&&待分類");
      //   console.error("右邊列表-已結束", 檢查事件狀態.按鈕已結束(EventId), "(3+4+5+6) && 已分類");
      //   console.error("中間-接手事件(強制)", EventId.強制接手權限
      //     , " (1+2) && 負責的客服不在線上");
      //   console.error("中間-強制結束、右邊編輯小標籤", (this.MessageSystemFinishService && EventId.聊天視窗_強制分類和完成事件)
      //     , " !((3+4+5+6) && 已分類) && Cs!=self");
      //   console.error("中間-已完成標籤", EventId.事件狀態是否為已結束
      //     , " (3+4+5+6) && 已分類");
      //   console.error("中間-接手事件(一般)", 檢查事件狀態.是否出現接手事件按鈕(EventId, this.CSLoginUser, this.線上客服列表)
      //     , " 0 || ( (1+2) && 負責的客服不在線上) ");
      //   console.error("中間-聊天視窗_分類和完成事件、右邊編輯小標籤", EventId.聊天視窗_分類和完成事件
      //     , " (2+((3+4+5+6) && 待分類) && Cs == self ");
      //   console.error("完成視窗-是否出現儲存草稿按鈕", EventId.ProcessStatus == ProcessStatus.Processing.value
      //     , " (2) ");
      //   console.error("完成視窗-完成鈕執行API", EventId.ProcessStatus == 2 ? "FinishService" : "ResetEventType"
      //     , "2 時 FinishService");
      //   console.error("強制完成視窗-是否出現儲存草稿按鈕", EventId.ProcessStatus == ProcessStatus.Processing.value
      //     , " (2) ");
      //   console.error("強制完成視窗-完成鈕執行API", [ProcessStatus.None.value, ProcessStatus.Assigned.value, ProcessStatus.Processing.value].includes(EventId.ProcessStatus) ? "FinishService" : "ResetEventType"
      //     , "(1+2+3) 時 FinishService");
      // }
    },
    開始會員聊天(聊天對象, EventId) {
      this.$store.dispatch("messageSystemServer/ChangeMessageForm", MessageForm.Chat);
      this.clickEventId = EventId;
      this.$store.dispatch("messageSystemServer/開始聊天", { 聊天對象, 訊息系統: 訊息系統.會員系統 });
      let msg = this.$store.state.messageSystemServer.聊天會員訊息暫存[聊天對象];
      msg = msg ? msg : "";
      this.$refs.sendChatMessageBtn && this.$refs.sendChatMessageBtn.InitMsg(msg);
    },
    事件狀態Tag(v, EventType) {
      let keys = Object.keys(ProcessStatus);
      let selKey = "";
      for (let key of keys) {
        if (ProcessStatus[key].value == v) {
          selKey = key;
          break;
          // return this.$t('事件狀態' + key);
          // None: { value: 0 },//未分派
          // Assigned: { value: 1 },//已分派
          // Processing: { value: 2 },//處理中
          // Finish: { value: 3 },//已完成
          // Finish_PlayerOffline: { value: 4 },  //已完成(玩家離線，系統自動結案)
          // Finish_Idle: { value: 5 },  //已完成(閒置30分鐘，系統自動結案)
          // Finish_Enforce: { value: 6 }  //已完成(強制結束)

          // return ProcessStatus[key].shortTag;
        }
      }
      if (selKey) {
        if (檢查事件狀態.狀態已結束List.includes(v) && !EventType) {
          return this.$t('待分類')
        } else {
          return this.$t('事件狀態' + selKey);
        }
      } else {
        return "未定義" + v;
      }
    },
    刷新畫面() {
      this.$store.dispatch("messageSystemServer/刷新畫面", "");
    },
    一鍵複製(textToCopy) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // 使用 Clipboard API 將文本複製到剪貼板
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            $(this.$refs.copyTip).fadeIn().delay(2000).fadeOut();
            console.log("文本已成功複製到剪貼板");
          })
          .catch((error) => {
            console.error("複製文本到剪貼板時發生錯誤:", error);
            this.$eventBus.$emit("showAlert", this.$t('複製文本到剪貼板時發生錯誤'));
          });
      } else {
        this.$eventBus.$emit("showAlert", this.$t('瀏覽器不支援ClipboardAPI'));
      }
    },
    addCanMessag(msg) {
      if (this.messageForm == MessageForm.Chat && this.$refs.sendChatMessageBtn) {
        this.$refs.sendChatMessageBtn.addCanMessag(msg);
      } else if (this.messageForm == MessageForm.Secret && this.$refs.sendSecretMessageBtn) {
        this.$refs.sendSecretMessageBtn.addCanMessag(msg);
      }
    },
    openPopForceCompleteForm({ EventId, EventType, Remark }) {
      this.$store.commit('messageSystemServer/Update強制分類EventId', EventId);
      this.$refs.popForceCompleteForm.show(EventType, Remark);
    },
    openPopCompleteForm({ EventId, EventType, Remark }) {
      this.$store.commit('messageSystemServer/Update分類EventId', EventId);
      this.$refs.popCompleteForm.show(EventType, Remark);
    },
    // this.$store.commit('xxx')
    // $refs.popCompleteForm.show
  },
  filters: {
    格式化時間: function (value) {
      return value + "," + new moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>
<style src="@/assets/css/default/message.css" lang="css" scoped />
<style scoped></style>