<template>
  <div id="RewardMessageEdit">
    <section class="content-header">
      <h1>信件發獎</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/RewardMessage">信件發獎</router-link></li>
        <li class="active">{{ id ? "編輯" : "新增" }}內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form @submit.prevent="submit" class="form-horizontal" name="rewardMessageForm" method="post">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發送名單：
                </label>
                <div class="col-sm-10">
                  <div class="form-group">
                    <div class="radio col-md-12">
                      <iCheckRedioButton name="sendRadio" checkValue="0"
                        message="全伺服器發送" v-model.number="data.發送名單選項"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="radio col-md-3">
                      <iCheckRedioButton name="sendRadio" checkValue="1"
                        message="單一玩家" v-model.number="data.發送名單選項"
                      />
                    </div>
                    <fieldset class="col-md-9" :disabled="data.發送名單選項!=1" :style="data.發送名單選項!=1 ? 'pointer-events:none':''">
                      <div style="display:flex; gap:.5em">
                        <input type="text" class="form-control" placeholder="輸入玩家暱稱" v-model.trim="player"
                          @keydown="(function(e){ if(e.key == 'Enter'){ e.preventDefault();addPlayer(); } })($event)"
                        />
                        <button type="button" class="btn btn-success" @click="addPlayer()" :disabled="player==''">新增</button>
                      </div>
                      <div class="mt-10" v-show="playerList.length > 0" style="display:flex; gap:.5em; align-items:flex-end;">
                        <ul class="player-list form-control">
                          <li v-for="(player, i) in playerList">
                            <span>{{ player }}</span>
                            <button type="button" class="btn btn-default text-red pull-right" style="padding:0 .5em;border-color:currentColor;" @click="playerList.splice(i, 1)">移除</button>
                          </li>
                        </ul>
                        <!-- <select class="form-control" name="" id="" multiple>
                          <option v-for="player in playerList" :value="player">{{ player }}</option>
                        </select> -->
                        <button type="button" class="btn btn-danger" @click="clearPlayers()">清空</button>
                      </div>
                    </fieldset>
                  </div>
                  <div class="form-group">
                    <div class="radio col-md-3 col">
                      <iCheckRedioButton name="sendRadio" message="匯入名單"
                        checkValue="3" v-model.number="data.發送名單選項"
                      />
                    </div>
                    <fieldset class="col-md-9 col" :disabled="data.發送名單選項!=3" :style="data.發送名單選項!=3 ? 'pointer-events:none':''">
                      <label class="btn btn-primary fa fa-file-alt" @click="importSN()">
                        選擇檔案
                      </label>
                      <button type="button" v-if="importList.length > 0" class="btn btn-success fa fa-list" @click="checkContent(data, true)" style="margin-left:.35em;">
                        查看名單
                      </button>
                      <span style="padding-left: 8px">
                        {{ importFileName }}
                      </span>
                    </fieldset>
                  </div>
                  <div class="form-group">
                    <div class="radio col-md-3">
                      <iCheckRedioButton name="sendRadio" message="指定條件"
                        checkValue="2" v-model.number="data.發送名單選項"
                      />
                    </div>
                    <fieldset class="col-md-9 col" :disabled="data.發送名單選項!=2" :style="data.發送名單選項!=2 ? 'pointer-events:none':''">
                      <div class="box-group">
                        <div class="col-md-3">
                          <label class="checkbox checkbox-inline">
                            <input type="checkbox" v-model="conditions[0].checked"
                              style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                            >
                            段位等級
                          </label>
                        </div>
                        <fieldset class="vip col-md-9" :required="conditions[0].checked" :disabled="!conditions[0].checked">
                          <select class="form-control" v-model="conditions[0].min">
                            <option v-for="(option, i) in gameTypes.Level" :key="i" :value="i">{{ option }} ({{ i }})</option>
                          </select>
                          <p>~</p>
                          <select class="form-control" v-model="conditions[0].max">
                            <option v-for="(option, i) in gameTypes.Level" :key="i" :value="i">{{ option }} ({{ i }})</option>
                          </select>
                        </fieldset>
                      </div>
                      <div class="box-group">
                        <div class="col-md-3">
                          <label class="checkbox checkbox-inline">
                            <input type="checkbox" v-model="conditions[1].checked"
                              style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                            >
                            雀幣
                          </label>
                        </div>
                        <fieldset class="level col-md-9" :required="conditions[1].checked" :disabled="!conditions[1].checked">
                          <input type="number" class="form-control" v-model.trim="conditions[1].min" min="0"/>
                          <p>~</p>
                          <input type="number" class="form-control" v-model.trim="conditions[1].max"/>
                        </fieldset>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 寄件者：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="data.寄件者">
                    <option v-for="(option, i) in def.mailSender" :key="i" :value="i">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 訊息主旨：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputTitle" class="form-control"
                    :maxlength="titleMax" :placeholder="`請輸入主旨，上限${titleMax}字`"
                    v-model.trim="data.信件標題" required
                  />
                  <p class="word-count">
                    {{ data.信件標題.length }} / {{ titleMax }}
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 訊息內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <textarea cols="100" rows="10" class="form-control"
                      :maxlength="contentMax" :placeholder="`請輸入內容，上限${contentMax}字。`"
                      v-model="data.信件內容" required
                    ></textarea>
                  </div>
                  <p class="word-count">
                    {{ data.信件內容.length }} / {{ contentMax }}
                  </p>
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 保留天數：
                </label>
                <div class="col-sm-10">
                  <input type="number" class="form-control" placeholder="保留天數"
                    min="0" v-model.number="data.getDayLimit" required
                  />
                </div>
              </div> -->
              <div class="form-group">
                <label class="col-sm-2 control-label" for="sendAwards">
                  <span class="text-red">*</span> 發送獎勵：
                </label>
                <div class="col-sm-10">
                  <div class="box-group sendAwards" v-for="(item, index) in data.發送獎勵列表" :key="index">
                    <select class="form-control" required v-model="item.type" @change="item.id=item.type=='1'?'0':'', item.count=1">
                      <option value="" selected hidden disabled>請選擇類型</option>
                      <option value="1">貨幣</option>
                      <option value="2">物品</option>
                    </select>
                    
                    <select v-if="item.type != '2'"  v-model="item.id" class="form-control" style="max-width:10em;">
                      <option value="" hidden disabled>請選擇類別</option>
                      <option v-if="item.type == '1'" v-for="(name, id) in def.gameCoins" :value="id" :key="name">{{ name }}</option>
                    </select>
                    <input v-else-if="item.type === '2'" type="text" v-model="item.id" list="prizeList"
                      class="form-control" style="max-width:10em;" placeholder="請輸入物品ID"
                    >
                    
                    <datalist id="prizeList">
                      <option v-for="(name, id) in gameTypes.Item" :value="id">{{name}} ({{id}})</option>
                    </datalist>

                    <input type="number" class="form-control inputNumber" placeholder="請輸入數量"
                      v-model.number="item.count" required min="1"
                    />
                    <button type="button" class="btn btn-danger" @click="removeReward(item, index)">
                      刪除
                    </button>
                  </div>
                  <button type="button" class="btn btn-warning fa fa-plus" v-show="data.發送獎勵列表.length < 4" @click="addReward()">
                    新增獎勵
                  </button>
                  <button class="btn btn-default pull-right" type="button" @click="getTypes(true)">
                    <i class="fa fa-refresh"></i> 重抓物品ID資訊
                  </button>
                </div>
              </div>

              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 發送時間：
                </label>
                <div class="radio col-sm-10">
                  <p>
                    <iCheckRedioButton name="timeRadio" message="立即發送" style="margin-right:1em"
                      checkValue="0" v-model.number="scheduled"
                    />
                    <iCheckRedioButton name="timeRadio" message="排程時間"
                      checkValue="1" v-model.number="scheduled"
                    />
                  </p>
                  <fieldset class="radio-date" v-show="data.isScheduled">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <DatePicker class="pull-right" :propValue.sync="startDate" />
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="startTimeOnly" class="form-control timepicker" @input="updateTime()" />
                    </div>
                  </fieldset>
                </div>
              </fieldset>
              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 到期時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <DatePicker class="pull-right" :propValue.sync="endDate" />
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="endTimeOnly" class="form-control timepicker" @input="updateTime()">
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="form-group" v-if="data.寄件者 == 2 && (data.發送名單選項 == 1 || data.發送名單選項 == 3) && data.isScheduled == false">
                <hr>
                <label class="col-sm-2 control-label">
                  服務紀錄：
                </label>
                <div class="col-sm-10">
                  <p>
                    <label class="checkbox checkbox-inline">
                      <input type="checkbox" v-model="isService"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >
                      新增服務紀錄
                    </label>
                  </p>
                  <table class="table table-bordered" v-if="isService">
                    <tbody>
                      <tr>
                        <th>服務類型</th>
                        <td>
                          <select id="服務類型" v-model.number="data.分類" class="form-control" required>
                            <option value="0" selected disabled hidden>請選擇類別</option>
                            <option v-for="name, id in serviceTypes" :value="id" :key="id">{{ name }}</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>內容</th>
                        <td><textarea id="內容" v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="button" v-if="id" @click="deleteMessage()" class="btn btn-danger btn-lg fa fa-trash" style="margin-right:.5em">
                  刪除草稿
                </button>
                <button type="submit" v-if="data.狀態 === 0" @click="data.isDraft=true" class="btn btn-warning btn-lg fa fa-save" style="margin-right:.5em">
                  儲存草稿
                </button>
                <button type="submit" @click="data.isDraft=false" class="btn btn-primary btn-lg fa fa-send-o">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <rewardMessageContent ref="rewardMessageContent" />
      <ImportData ref="ImportData" title="玩家" :checks="checks" @importData="importData" />

      <!-- <pre>{{ JSON.stringify(data, null, 2) }}
  {{ startDate }}
  {{ endDate }}
      </pre> -->
    </section>
  </div>
</template>
<style scoped lang="scss">
  .player-list{
    display: block;
    list-style: none;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    max-height: 10em;
    padding: 0;
    margin: 0;

    li {
      padding: .35em .8em;
    }
    li:nth-child(2n+1){
      background: rgba(#000, .025);
    }
  }
</style>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import rewardMessageContent from "@/views/projects/NMJ/RewardMessageContent";
import ImportData from "@/views/projects/NMJ/ImportData";
import { mapState } from "vuex";

class Reward{
  constructor(type = "", id = "", count = 1){
    this.type = type;
    this.id = id;
    this.count = count
  }
}
class Rule{
  constructor(field = 0, min = null, max = null){
    this.field = field;
    this.minValue = min;
    this.maxValue = max
  }
}

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton,
    rewardMessageContent,
    ImportData,
  },
  props: {
    id: "",
  },
  data() {
    return {
      def: define,
      loading: true,
      titleMax: 16,
      contentMax: 500,
      isService: false,
      serviceTypes: {},
      data: {
        發送名單選項: 0,
        玩家列表: [ /* list of player nicknames */ ],
        指定條件列表: [ /* list of rules */ ],
        狀態: 0,
        寄件者: 1, // def.mailSender
        信件標題: "",
        信件內容: "",
        發送獎勵列表: [ /* list of rewards */ ],
        isScheduled: true,
        isDraft: true,
        發送時間: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        到期時間: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
        /* 客服服務紀錄 */
        分類:0,
        內容:""
      },
      scheduled: 1,
      gameTypes: {},
      player: "",
      playerList: [],
      startDate: "",
      endDate: "",
      conditions: [
        { checked:true, min:"0", max:"0" },
        { checked:true, min:"0", max:"10000" },
      ],
      importFileName: "",
      importList: [],
      checks: ["玩家暱稱"],
    };
  },
  watch: {
    "scheduled": function (value){ this.data.isScheduled = !!value; this.updateTime()},
    "startDate": function(){ this.updateTime() },
    "endDate": function(){ this.updateTime() },
    "playerList": function(){ this.updatePlayerList() },
    "data.發送名單選項": function(){ this.updateConditions(); this.updatePlayerList() },
    "conditions": { deep: true, handler(){ this.updateConditions() } },
  },
  mounted() {
    let that = this;
    $(".timepicker").timepicker({ showInputs: false });
    $(".timepicker").unbind("focus");
    $(".timepicker").on("change", function(){ that.updateTime() } );
    this.getMessage();
    this.getTypes();
  },
  methods: {
    importSN() {
      this.$refs.ImportData.showSerialNumberEdit();
    },
    importData(data, fileName){
      console.log(data)
      this.importList = data.map(p => p.玩家暱稱.toString());
      this.data.玩家列表 = this.importList;
      this.importFileName = fileName;
    },
    getTypes(isReload){
      this.$store.dispatch("nmjMail/itemList", isReload).then(result => {
        this.gameTypes = result;
        if(!isReload) this.conditions[0].max = Math.max(...Object.keys(result.Level));
      })
      this.$store.dispatch("nmjServiceTypes/serviceTypeQuery").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
          }
        }
      });
    },
    getMessage() {
      this.data.信件編號 = this.id;
      if (this.data.信件編號) {
        this.$store.dispatch("nmjMail/query", this.data).then((result) => {
          this.data = result;
          this.data.isScheduled = new Date(this.data.發送時間).valueOf() >= Date.now();
          this.scheduled = +this.data.isScheduled;
          this.initTime();
          if (this.data.發送名單選項 == 1) this.playerList = this.data.玩家列表;
          else if (this.data.發送名單選項 == 3) this.importList = this.data.玩家列表;
          else if (this.data.發送名單選項 == 2) {
            for (let i = 0; i < this.conditions.length; i++){
              let obj = this.data.指定條件列表.find(el => el.field == i);
              if (obj){
                this.conditions[i].checked = true;
                this.conditions[i].min = obj.minValue;
                this.conditions[i].max = obj.maxValue;
              } else {
                this.conditions[i].checked = false;
              }
            }
          }
        });
      }
      else {
        console.log(this.startDate, this.endDate);
        this.initTime();
      }
    },
    updatePlayerList() {
      let arr = [];
      switch (this.data.發送名單選項){
        case 1: {
          arr = this.playerList || [];
        } break;
        case 3: {
          arr = this.importList || [];
        } break;
        default: arr = [];
      }
      this.data.玩家列表 = arr;
      // console.log("玩家列表:", arr)
    },
    initTime() {
      this.startDate = this.data.發送時間;
      this.endDate = this.data.到期時間;
      $("#startTimeOnly").val(this.global.getTime(this.startDate));
      $("#endTimeOnly").val(this.global.getTime(this.endDate));
      this.updateTime();
    },
    updateTime() {
      this.data.發送時間 = this.data.isScheduled ? new Date(`${this.startDate} ${$("#startTimeOnly").val()} UTC`) : new Date(0);
      this.data.到期時間 = new Date(`${this.endDate} ${$("#endTimeOnly").val()} UTC`);
    },
    updateConditions() {
      let res = [];
      if (this.data.發送名單選項 == 2) for (let i = 0, len = this.conditions.length; i < len; i++){
        let c = this.conditions[i];
        if (c.checked) res.push(new Rule(i, Math.min(c.min, c.max).toString(), Math.max(c.min, c.max).toString()))
      }
      this.data.指定條件列表 = res;
    },
    backList() {
      this.$router.push({
        name: "NmjRewardMessage",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    addReward() {
      if (this.data.發送獎勵列表.length >= 4) {
        this.$eventBus.$emit("showAlert", "已達新增上限4筆！");
        return;
      }
      this.data.發送獎勵列表.push(new Reward());
    },
    removeReward(item, index) {
      this.data.發送獎勵列表.splice(index, 1);
    },
    addPlayer() {
      if (this.player == ""){  return; }
      if (this.playerList.includes(this.player)){
        this.$eventBus.$emit("showAlert", `此玩家「${ this.player}」已經在名單上！`);
        return;
      }
      this.$store
        .dispatch("nmjPlayerData/query", {nickName: this.player, phoneNumber: ""})
        .then((result) => {
          console.log(result);
          if (result.message){
            this.$eventBus.$emit("showAlert", result.message);
            return;
          }
          if (result.status == '0'){
            this.$eventBus.$emit("showAlert", `此玩家「${ this.player}」不存在！`);
            return;
          }
          this.playerList.unshift(this.player);
          this.player = "";
        });
    },
    clearPlayers(){
      this.$eventBus.$emit("showConfirm", "確定清空名單?", (isOK) => {
        if (isOK) { this.playerList = []; }
      });
    },
    submit() {
      switch(this.data.發送名單選項){
        case 0: break;
        case 1: if (this.data.玩家列表.length <= 0){
          return this.$eventBus.$emit("showAlert", "請填新增玩家至發送名單！");
        } break;
        case 3: if (this.data.玩家列表.length <= 0){
          return this.$eventBus.$emit("showAlert", "請選擇指定名單檔案！");
        } break;
        case 2: if(this.data.指定條件列表.length <= 0){
          return this.$eventBus.$emit("showAlert", "請選指定條件！或用「全伺服器發送」");
        } break;
      }
      if (this.data.isScheduled && !this.global.isValidDay(this.data.發送時間) ) {
        this.$eventBus.$emit("showAlert", "請填寫訊息發送時間！");
        return;
      }
      if (this.data.isScheduled && !this.global.isValidFutureDay(this.data.發送時間)) {
        this.$eventBus.$emit("showAlert", "「訊息發送時間」請勿填寫過去時間！");
        return;
      }
      if (!this.global.isValidDay(this.data.到期時間)) {
        this.$eventBus.$emit("showAlert", "請填寫訊息到期時間！");
        return;
      }
      if (!this.global.isValidFutureDay(this.data.到期時間)) {
        this.$eventBus.$emit("showAlert", "「訊息到期時間」請勿填寫過去時間！");
        return;
      }
      if (this.data.isScheduled && !this.global.isValidDayRange(this.data.發送時間, this.data.到期時間)) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }
      if (this.data.寄件者 == 2 && (this.data.發送名單選項 == 1 || this.data.發送名單選項 == 3) && this.data.isScheduled == false){
        if (this.isService){
          if (this.data.分類 == ""){
            this.$eventBus.$emit("showAlert", "請選擇服務紀錄類別！");
            return;
          }
          if (this.data.內容 == ""){
            this.$eventBus.$emit("showAlert", "請填寫服務紀錄內容！");
            return;
          }
        }
        else {
          this.data.分類 = 0;
          this.data.內容 = "";
        }
      }

      this.data.發送時間 = this.data.發送時間.toJSON().split(".")[0];
      this.data.到期時間 = this.data.到期時間.toJSON().split(".")[0];

      const api = this.data.信件編號 ? "update" : "create";
      this.$store.dispatch(`nmjMail/${api}`, this.data).then(() => {
        this.backList();
      });
    },
    deleteMessage(){
      this.$store.dispatch(`nmjMail/delete`, this.data).then(() => {
        this.backList();
      });
    },
    // 查看內容
    checkContent(item, showPlayers = false) {
      this.$refs.rewardMessageContent.showRewardMessageContent(
        item,
        this.gameTypes,
        this.searchData,
        showPlayers
      );
    },
  },
};
</script>

<style>
#RewardMessageEdit .tox-statusbar__wordcount {
  display: none;
}
</style>
