<template>
	<div>
		<section class="content-header">
			<h1>
				{{ isCustomize ? $t('自定義罐頭訊息') : $t('罐頭訊息') }}
			</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li class="active">{{ isCustomize ? $t('自定義罐頭訊息') : $t('罐頭訊息') }}</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<button class="btn btn-block btn-warning btn-add" :disabled="!searchData.Project" type="button"
							@click="OpenAddForm()">
							<i class="fa fa-plus"></i> {{ $t('新增') }}
						</button>
						<div class="select-group">
							<select class="form-control" v-model="searchData.Project">
								<option v-for="item of projects" :key="item" :value="item">
									{{ $t(item) }}
								</option>
							</select>
						</div>
						<div class="select-group">
							<select class="form-control" v-model="searchData.CategoryID">
								<option value="0">{{ $t('全部') }}</option>
								<option v-for="item of filterCategories" :key="item.categoryID" :value="item.categoryID">{{
									item.name }}{{ item.enable == false ? $t('Scratch停用中') : '' }}
								</option>
							</select>
						</div>

						<div class="select-group">
							<select class="form-control" v-model="searchData.IsEnable">
								<option value="true">{{ $t('啟用') }}</option>
								<option value="false">{{ $t('停用') }}</option>
								<!-- <option v-for="item of filterCategories" :key="item.categoryID" :value="item.categoryID">{{
									item.name }}{{ item.enable == false ? $t('Scratch停用中') : '' }}
								</option> -->
							</select>
						</div>
						<div class="select-group">
							<input type="text" :placeholder="$t('搜尋問題和回覆')" class="form-control"
								v-model.trim="searchData.KeyWord" />
						</div>
						<div class="select-group" style="width:auto;">
							<button class="btn btn-primary" type="button" :disabled="!searchData.Project"
								@click="searchData.Page = 1; Query();">
								<i class="fa fa-search"></i> {{ $t('查詢') }}
							</button>
						</div>
						<div class="select-group">
							<button class="btn btn-info" type="button" @click="Exprot()">
								{{ $t('匯出') }}
							</button>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable">
							<tbody>
								<tr class="bg-gray">
									<th width="10%">{{ $t('類別') }}</th>
									<th width="20%">{{ $t('問題') }}</th>
									<th width="40%">{{ $t('回覆') }}</th>
									<th>{{ $t('排序') }}</th>
									<th class="text-nowrap text-center">{{ $t('啟用狀態') }}</th>
									<th class="text-nowrap text-center">{{ $t('修改日期') }}</th>
									<th class="text-nowrap text-center">{{ $t('功能') }}</th>
								</tr>
								<tr v-for="item of list" :key="item.id">
									<td class="text-nowrap"> {{ item.categoryName/*item.categoryID*/ }}</td>
									<td>
										<div style="white-space: pre-line;">
											{{ item.question }}
										</div>
									</td>
									<td>
										<div style="white-space: pre-line;">
											{{ item.answer }}
										</div>
									</td>
									<td class="text-nowrap text-center">
										<input type="number" class="form-control"
											style="display:inline-block;width: 100px;vertical-align: middle;margin-right: 10px;"
											v-model="item.sort" :disabled="item.enable == false">
										<button class="btn btn-primary" :disabled="item.原始sort == item.sort"
											@click="更新排序(item)">{{ $t('更新') }}</button>

									</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput" @click="啟停用(item)">
											<input :false-value="false" :true-value="true" name="ckbx-style-8"
												type="checkbox" v-model.trim="item.enable" />
											<label :for="'check' + item"></label>
										</div>
									</td>
									<td class="text-nowrap text-center" style="word-break:break-word;">
										{{ item.updateTime | date() }}
									</td>
									<td class="text-nowrap text-center">
										<button @click="OpenEditForm(item)" class="btn btn-primary">{{ $t('修改') }}</button>
										&nbsp;
										<button @click="DeleteItem(item)" class="btn btn-danger">{{ $t('刪除') }}</button>
									</td>
								</tr>
								<tr v-if="list.length == 0">
									<td class="text-center" colspan="7">{{ $t('沒有資料') }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border">
					<Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
				</div>
				<div class="overlay" ref="loading">
					<i class="fa fa-refresh fa-spin"></i>
				</div>
			</div>
			<MessageCanMsgEdit ref="MessageCanMsgEdit" :Categories="Categories" :Service="projects" @refresh="Query()">
			</MessageCanMsgEdit>
		</section>
	</div>
</template>
  
<script>
import MessageCanMsgEdit from "@/views/projects/FBRobot/MessageCanMsgEdit";
import { CsOmpAPI as CsAPI } from "@/store/MessageSystem/messageSystemLib";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import moment from "moment";
import { ProjectSetting } from "@/store/MessageSystem/messageSystemConst";
const XLSX = require('xlsx');
export default {
	components: {
		MessageCanMsgEdit, Pagination
	},
	data() {
		let projects = ProjectSetting.AllowCSSystemProjects().filter((item) => {
			return this.$user.hasPermission(`MCM-${item}`);
		});
		let pageSize = 20;
		return {
			Categories: [],
			list: [],
			pageSize,
			total: 0,
			projects,
			searchData: {
				Project: projects && projects[0],
				CategoryID: 0,
				KeyWord: "",
				IsEnable: true,
				Page: 1,
			},
		};
	},
	async mounted() {
		$(this.$refs.loading).show();
		try {
			this.Categories = [];
			for (let Project of this.projects) {
				let categories = (CsAPI(await api.CS.GetCategoryList({ Project }))).result;
				for (let i of categories) {
					this.Categories.push(i);
				}
			}
			await this.reload();
		} catch (e) {
			this.$eventBus.$emit("showAlert", e);
		}
		$(this.$refs.loading).fadeOut(400);
	},
	computed: {
		filterCategories() {
			let list = this.Categories.filter((item) => { return item.project == this.searchData.Project; });
			this.searchData.CategoryID = 0;
			return list;
		},
		isCustomize() {
			let bol = (this.$route.params && this.$route.params.isCustomize && this.$route.params.isCustomize == "true") ? true : false;
			return bol;
		},
	},
	watch: {
		async isCustomize(v1, v2) {
			$(this.$refs.loading).show();
			try {
				this.searchData.Project = this.projects[0];
				this.searchData.CategoryID = 0;
				this.searchData.KeyWord = "";
				this.searchData.IsEnable = true;
				this.searchData.Page = 1;
				await this.reload();
			} catch (e) {
				this.$eventBus.$emit("showAlert", e);
			}
			$(this.$refs.loading).fadeOut(400);
		}
	},
	methods: {
		async reload() {
			try {
				if (!this.searchData.Project) {
					throw this.$t('請申請分類管理與罐頭訊息權限')
				}
				let query = {
					Project: this.searchData.Project,
					CategoryID: this.searchData.CategoryID,
					KeyWord: this.searchData.KeyWord,
					IsEnable: this.searchData.IsEnable,
					Page: this.searchData.Page,
					isCustomize: this.isCustomize
				}

				let res = (CsAPI(await api.CS.GetCannedMsgList(query))).result;
				if (query.Page != 1 && res.cannedMsgList.length == 0) {
					query.Page = 1;
					res = (CsAPI(await api.CS.GetCannedMsgList(query))).result;
				}
				let list = res.cannedMsgList;
				this.$refs.pagination.setPage(
					this.searchData.Page,
					res.totalCount
				);
				this.total = res.totalCount;
				for (let i of list) {
					let category = this.Categories.find((item) => { return item.categoryID == i.categoryID });
					i.categoryName = category ? category.name : i.categoryID;
					i.原始sort = i.sort;
				}
				this.list = list;
			} catch (e) {
				this.list = [];
				this.searchData.Page = 1;
				this.$refs.pagination.setPage(
					this.searchData.Page,
					0
				);
				this.total = 0;
				throw e;
			}
		},
		OpenEditForm(item) {
			this.$refs.MessageCanMsgEdit.Open(item, this.isCustomize);
		},
		DeleteItem(item) {
			this.$eventBus.$emit("showAsyncConfirm", this.$t('刪除後無法復原，確定刪除嗎？'), this.$t('刪除確認'), async () => {
				$(this.$refs.loading).show();
				try {
					CsAPI(await api.CS.RemoveCannedMsg({
						ID: item.id
					}));
					this.$eventBus.$emit("showAlert", this.$t('刪除成功'));
					await this.reload();
				} catch (e) {
					this.$eventBus.$emit("showAlert", e);
				}
				$(this.$refs.loading).fadeOut(400);
			});
		},
		async onPageChange(pageIdx) {
			$(this.$refs.loading).show();
			try {
				this.searchData.Page = pageIdx;
				await this.reload();
			}
			catch (e) {
				this.$eventBus.$emit("showAlert", e);
			}
			$(this.$refs.loading).fadeOut(400);
		},
		async Query() {
			$(this.$refs.loading).show();
			try {
				await this.reload();
			} catch (e) {
				this.$eventBus.$emit("showAlert", e);
			}
			$(this.$refs.loading).fadeOut(400);
		},
		async Exprot() {
			$(this.$refs.loading).show();
			let pageIdx = 1;
			let maxPage = 20;
			let exportData = [];
			try {
				if (!this.searchData.Project) {
					throw this.$t('請申請分類管理與罐頭訊息權限')
				}
				while (pageIdx < maxPage) {
					let query = {
						Project: this.searchData.Project,
						CategoryID: this.searchData.CategoryID,
						Keyword: this.searchData.KeyWord,
						IsEnable: this.searchData.IsEnable,
						Page: pageIdx,
						isCustomize: this.isCustomize,
					};
					let res = (CsAPI(await api.CS.GetCannedMsgList(query))).result;
					let list = res.cannedMsgList;
					let 類別 = this.$t('類別');
					let 問題 = this.$t('問題');
					let 回覆 = this.$t('回覆');
					let 排序 = this.$t('排序');
					let 啟用狀態 = this.$t('啟用狀態');
					let 修改日期 = this.$t('修改日期');
					list.map((i) => {
						let tmp = {};
						let category = this.Categories.find((item) => { return item.categoryID == i.categoryID });
						tmp.id = i.id;
						tmp[類別] = category ? category.name : i.categoryID;
						tmp[問題] = i.question;
						tmp[回覆] = i.answer;
						tmp[排序] = i.sort;
						tmp[啟用狀態] = i.enable;
						tmp[修改日期] = moment.unix(i.updateTime).format("YYYY-MM-DD HH:mm:ss");
						exportData.push(tmp);
					});
					if (res.totalCount >= pageIdx * this.pageSize) {
						pageIdx++;
					} else {
						break;
					}
				}
				if (exportData.length == 0) {
					throw this.$t('無資料')
				}
				// 创建工作簿
				let workbook = XLSX.utils.book_new();
				let worksheet = XLSX.utils.json_to_sheet(exportData);
				XLSX.utils.book_append_sheet(workbook, worksheet, `罐頭訊息`);
				// 将工作簿导出为 Excel 文件
				XLSX.writeFile(workbook, `罐頭訊息-${moment().format('YYYY-MM-DD HH:mm')}.xlsx`);
			} catch (e) {
				this.$eventBus.$emit("showAlert", e);
			}
			$(this.$refs.loading).fadeOut(400);
		},
		OpenAddForm() {
			this.$refs.MessageCanMsgEdit.Open(
				{ id: -1, project: -1, categoryID: -1, question: "", answer: "", enable: true, sort: 0 },
				this.isCustomize);
		},
		async 啟停用(item) {
			$(this.$refs.loading).show();
			try {
				let res = CsAPI(await api.CS.UpdateCannedMsg({
					Id: item.id,
					CategoryID: item.categoryID,
					Question: item.question,
					Answer: item.answer,
					Enable: !item.enable,
					sort: !item.enable == false ? 0 : item.sort
				}));
				this.$eventBus.$emit("showAlert", this.$t('修改成功'));
				let find = this.list.find((item) => { return item.id == res.result.id });
				find.原始sort = res.result.sort;
				find.sort = res.result.sort;
				find.enable = res.result.enable;
			} catch (e) {
				this.$eventBus.$emit("showAlert", e);
				await this.reload();
			}
			$(this.$refs.loading).fadeOut(400);
		},
		async 更新排序(item) {
			if (parseInt(item.sort) + "" != item.sort + "") {
				this.$eventBus.$emit("showAlert", this.$t('排序欄位輸入值不正確'));
				return;
			}
			$(this.$refs.loading).show();
			try {
				let res = CsAPI(await api.CS.UpdateCannedMsg({
					Id: item.id,
					CategoryID: item.categoryID,
					Question: item.question,
					Answer: item.answer,
					Enable: item.enable,
					sort: item.sort
				}));
				this.$eventBus.$emit("showAlert", this.$t('修改成功'));
				let find = this.list.find((item) => { return item.id == res.result.id });
				find.原始sort = res.result.sort;
				find.sort = res.result.sort;
				find.enable = res.result.enable;
			} catch (e) {
				this.$eventBus.$emit("showAlert", e);
				await this.reload();
			}
			$(this.$refs.loading).fadeOut(400);
		}
	},
	filters: {
		date(time) {
			return moment.unix(time).format("YYYY-MM-DD HH:mm:ss")
		}
	}
}
</script>