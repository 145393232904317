<template>
  <div id="ClassTypeManager">
    <section class="content-header">
      <h1>分類管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">分類管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button type="button" class="btn btn-block btn-warning btn-add fa fa-plus" @click="addType()">
              新增
            </button>
            <div class="select-group">
              <select id="selectUnit" class="form-control" required v-model="moduleId" @change="queryAll">
                <option v-for="(name, key) in projectData" :value="key" :key="key">{{name}}</option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered classTypeTable" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;" width="50%">分類ID</th>
                  <th style="min-width: 150px;" width="50%">分類名稱</th>
                  <th style="min-width: 97px;" width="50%">module</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">排序</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <tr v-for="(item, index) in classTypes.datas" :key="index">
                  <td>
                    <p v-show="item.editor==0" class="m-0">{{ item.classId }}</p>
                    <input v-show="item.editor!=0" type="text" class="form-control" v-model="item.classId" required />
                  </td>
                  <td>
                    <p v-show="item.editor==0" class="m-0">{{ item.className }}</p>
                    <div v-show="item.editor!=0" v-for="(detail, index) in detailClassLang" :key="index">
                      <span>{{getLangName(detail.lang)}}：</span>
                      <input type="text" class="form-control" v-model="detail.className" required />
                    </div>
                  </td>
                  <td>
                    <select v-model="item.module" :disabled="item.editor==0">
                      <option v-for="(name, key) in projectData" :key="key" :value="key">{{name}}</option>
                    </select>
                  </td>
                  <td align="center">
                    <p v-show="item.editor==0" class="m-0">{{ item.sortId }}</p>
                    <input v-show="item.editor!=0" type="number" class="form-control text-center" v-model="item.sortId" required />
                  </td>
                  <td class="text-center" align="center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input type="checkbox" :true-value="0" :false-value="1" :id="'check'+index" v-model="item.isStop" @change="changeStatus(item)" name="ckbx-style-8" />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button v-show="item.editor==0" @click="change(item)" class="btn btn-primary">
                      修改
                    </button>
                    <button v-show="item.editor!=0" @click="save(item)" class="btn btn-success btn-save">
                      儲存
                    </button>
                    &nbsp;
                    <button v-show="item.editor==0" @click="deleteType(item, index)" class="btn btn-danger">
                      刪除
                    </button>
                    <button v-show="item.editor!=0" @click="cancel(item, index)" class="btn btn-default">
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      // projectId: this.$store.state.projectId,
      moduleId: "",
      langTypes: [{"id":"zh-tw","name":"繁體"}],
      detailClassLang: [],
      tempEdit: false,
    };
  },
  created() {
    this.getModuleQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      classTypes: state => state.nmjClassTypes.queryAll,
      response: state => state.nmjClassTypes.response,
      projectData: state => state.nmjClassTypes.moduleQueryAll,
    })
  },
  watch: {
    response(value) {
      if (value.status == "1") this.$eventBus.$emit("showAlert", "更新成功!!");
      else this.$eventBus.$emit("showAlert", "更新失敗!!");
      this.queryAll();
    },
    projectData() {
      if (this.projectData != null && Object.keys(this.projectData).length > 0)
        this.moduleId = Object.keys(this.projectData)[0];
      this.queryAll();
    },
  },
  methods: {
    // 顯示列表
    queryAll() {
      this.$store.dispatch("nmjClassTypes/classTypeQueryAll", {
        module: this.moduleId,
        isDelete: 0
      });
    },

    getModuleQueryAll() {
      this.$store.dispatch("nmjClassTypes/moduleQueryAll", {});
    },

    // 儲存更新
    workUpdate(item) {
      this.$store.dispatch("nmjClassTypes/classTypeUpdate", {
        master_model: item,
        detail_model: this.detailClassLang
      });
    },

    // 切換文字或輸入框
    change(item) {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      item.editor = item.editor == 1 ? 0 : 1;
      this.detailClassLang = [{
        classTypeDetailId: item.classTypeDetailId,
        lang: this.langTypes[0].id,
        className: item.className
      }]
    },

    // 儲存
    save(item) {
      this.tempEdit = false;
      
      item.sortId = parseInt(item.sortId);
      if (item.module == "") {
        this.$eventBus.$emit("showAlert", "請選擇module!!");
        return;
      }

      if (item.classTypeId == "") {
        this.$store.dispatch("nmjClassTypes/classTypeCreate", {
          master_model: item,
          detail_model: this.detailClassLang,
        });
      } else {
        this.workUpdate(item);
      }
    },

    // 新增類別
    addType() {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        return;
      }
      this.tempEdit = true;

      this.detailClassLang = [];
      let Id = utils.getTimeId();
      this.classTypes.datas.push({
        classTypeId: "",
        projectId: this.$store.state.projectId,
        className: "",
        module: this.moduleId,
        isStop: 0,
        sortId: 1,
        editor: "1",
      });
      for (var i = 0; i < this.langTypes.length; i++) {
        Id = Id + i + 1;
        this.detailClassLang.push({
          classTypeDetailId: Id,
          lang: this.langTypes[i].id,
          className: "",
        });
      }
    },

    cancel(item, index) {
      this.tempEdit = false;
      if (item.classTypeId == "") {
        this.classTypes.datas.splice(index, 1);
      } else {
        this.queryAll();
      }
    },

    // 改變狀態
    changeStatus(item) {
      this.$store.dispatch("nmjClassTypes/classTypeUpdate", {
        master_model: item
      });
    },

    // 刪除
    deleteType(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("nmjClassTypes/classTypeDelete", item);
        }
      });
    },

    getLangName(lang) {
      switch (lang) {
        case "zh-tw": return "繁";
        case "zh-cn": return "簡";
        case "en-us": return "英";
        default: return lang;
      }
    }
  }
};
</script>