/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    productQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcDeposit.productQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    itemQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcDeposit.itemQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcDeposit.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcDeposit.create(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcDeposit.update(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    disable({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcDeposit.disable(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.fcDeposit.queryAll(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    res.data.result.datas.forEach(item => {
                        items.push({
                            '交易時間': utils.formatTime(item.配點時間, 'YYYY/MM/DD HH:mm:ss'),
                            '玩家UID': item.id,
                            '訂單編號': item.交易ID,
                            '儲值平台': data.platform,
                            '儲值項目': item.產品類別,
                            '儲值品項': item.產品項目,
                            '儲值金額': item.點數,
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_儲值查詢列表.xlsx`,
                        '儲值查詢'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}