<template>
  <div id="NmjGameDataList">
    <section class="content-header">
      <h1>押注紀錄查詢</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">押注紀錄查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit.prevent="resetDataList()">
            <div class="form-group">
              <label class="control-label" for="inID">入口編號：</label>
              <div class="input-group"><input type="text" class="form-control" id="inID" list="listID" v-model.number="searchData.入口Id" /></div>
              <datalist id="listID">
                <option v-for="item in entryData" :value="item.入口Id">
                  ({{ String(item.入口Id).padStart(5, '0') }})
                  {{ item.活動備註 }}
                  {{ uiDate(item.入口開始時間).split(' ')[0]  }}
                </option>
              </datalist>
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary">
                查詢
              </button>
            </div>
          </form>
          <div class="top-box" v-show="this.datas.length > 0">
            <div class="form-group" style="width:20%; margin-right:1%; min-width:200px;">
              <label class="control-label" for="inLocation">活動名稱：</label>
              <div class="input-group"><input type="text" class="form-control" id="inLocation" v-model.trim="filters.活動名稱" placeholder="全部" /></div>
            </div>
            <div class="form-group" style="width:19%; margin-right:1%; min-width:200px;">
              <label class="control-label" for="inArea">賽區：</label>
              <div class="input-group"><input type="text" class="form-control" id="inArea" v-model.trim="filters.賽區" placeholder="全部" /></div>
            </div>
            <div class="form-group" style="width:19%; margin-right:1%; min-width:200px;">
              <label class="control-label" for="inSession">場次：</label>
              <div class="input-group"><input type="text" class="form-control" id="inSession" v-model.trim="filters.場次" placeholder="全部" /></div>
            </div>
            <div class="form-group" style="width:19%; margin-right:1%; min-width:200px;">
              <label class="control-label" for="inPlayer">參賽者：</label>
              <div class="input-group"><input type="text" class="form-control" id="inPlayer" v-model.trim="filters.參賽者" placeholder="全部" /></div>
            </div>
            <div class="form-group" style="width:19%; margin-right:0%; min-width:200px;">
              <label class="control-label" for="inCharacter">角色：</label>
              <div class="input-group"><input type="text" class="form-control" id="inCharacter" v-model.trim="filters.角色" placeholder="全部" /></div>
            </div>
            <div class="form-group" style="float:right; margin-right:0;">
              <label class="control-label" for="inPageSize">每頁顯示比數：</label>
              <div class="input-group" style="width:auto;">
                <select class="form-control" id="inPageSize" v-model.number="pageSize">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <!-- <pre>{{ datas[0] }}</pre> -->
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center table-striped">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 90px; width: 5%">入口編號</th>
                  <th style="min-width:150px; width:10%">活動名稱</th>
                  <th style="min-width: 75px; width: 5%">賽區</th>
                  <th style="min-width: 75px; width: 5%">場次</th>
                  <th style="min-width: 90px; width: 5%">押注額</th>
                  <th style="min-width: 90px; width: 5%">賠率</th>
                  <th style="min-width:120px; width:10%">參賽者</th>
                  <th style="min-width:120px; width:10%">角色</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="20">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0 && result.message">
                  <td colspan="20">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="20">查無資料</td>
                </tr>
                <template v-else v-for="(item, index) in pagedData">
                <tr>
                  <td v-text="String(item.entryID).padStart(5, '0')"></td>
                  <td v-text="item.活動地點"></td>
                  <td v-text="item.matchZoneIndex + 1"></td>
                  <td v-text="item.場次"></td>
                  <td v-text="item.betAmount"></td>
                  <td v-text="item.賠率"></td>
                  <td v-text="item.參賽者"></td>
                  <td v-text="item.playerName"></td>
                </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            :key="pageSize"
          />
          <!-- <pre>{{ datas }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  #NmjGameDataList{
    .top-box .form-group{
      width: auto;
      display: flex;
      white-space: nowrap;
      > .input-group{
        width: auto;
      }
    }
    .top-box > .input-group{
      margin-bottom: 10px;
    }
    .table td{
      line-height: 1.2;
    }
    .table .table-player{
      th, td{
        min-width: auto;
        padding: .1em .25em;
        &:nth-child(1), &:nth-child(3), &:nth-child(4){ min-width:4em; width:10%;}
      }

      tr:nth-child(2n){
        background: rgba(0,0,0,.03);
      } 
    }
    .mark{
      padding: 0;
    }
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import Pagination from "@/components/Pagination";


export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
  },
  data() {
    return {
      loading: false,
      searchData: {
        入口Id: "",
      },
      filters: {
        活動名稱: "",
        賽區: "",
        場次: "",
        參賽者: "",
        角色: "",
      },
      result: {},
      datas: [],
      total: 0, //總筆數
      pageNum: 1,
      pageSize: 20, //每頁顯示筆數

      entrySearchData: {
        開始時間: new moment(new Date(0)).format("YYYY-MM-DD 23:59:59"),
        結束時間: new moment().add(1, "month").format("YYYY-MM-DD 23:59:59"),
      },
      entryData: [],
    };
  },
  async created() {
    // this.getDataList();
    this.getEntryList();
  },
  computed:{
    pagedData(){
      let start = (this.pageNum - 1) * this.pageSize;
      let end = this.pageNum * this.pageSize;
      return this.filteredData.slice(start, end);
    },
    filteredData(){
      let filteredData = []
      if (this.datas.length > 0) filteredData = this.datas.filter(item => 
        item.活動地點.toLowerCase().indexOf(this.filters.活動名稱.toLowerCase()) >= 0 &&
        (item.matchZoneIndex + 1).toString().indexOf(this.filters.賽區.toLowerCase()) >= 0 &&
        item.場次.toLowerCase().indexOf(this.filters.場次.toLowerCase()) >= 0 &&
        item.參賽者.toLowerCase().indexOf(this.filters.參賽者.toLowerCase()) >= 0 &&
        item.playerName.toLowerCase().indexOf(this.filters.角色.toLowerCase()) >= 0
      );
      this.total = filteredData.length;
      return filteredData;
    }
  },
  watch:{
    pageSize(){
      this.pageNum = 1;
      this.$refs.pagination.setPage(this.pageNum, this.total);
    },
    total(){
      this.pageNum = 1;
      this.$refs.pagination.setPage(this.pageNum, this.total);
    },
  },
  methods: {
    getEntryList() {
      this.$store.dispatch("nmjGameBet/queryAllEntry", this.entrySearchData).then((result) => {
          this.entryData = result.datas || [];
        }, (msg) =>{

        });
    },
    getDataList() {
      this.$store
        .dispatch("nmjGameRecord/queryBet", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.datas || [];
          this.loading = false;
          // this.$refs.pagination.setPage(this.pageNum, this.total);
        });
    },
    resetDataList() {
      this.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
      this.$refs.pagination.setPage(this.pageNum, this.total);
      // this.getDataList();
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>