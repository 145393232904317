<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>精彩賽事</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li>精彩賽事</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" type="button" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增網址
            </button>
            <!-- <div class="select-group">
              <select id="selectUnit" class="form-control" v-model="searchData.類別" @change="clickSearch" required>
                <option value>全部</option>
                <option v-for="(item, index) in types.datas" :value="item.className" :key="index">
                  {{item.className}}
                </option>
              </select>
            </div> -->
            <!-- <div class="input-group">
              <input type="text" v-model="searchData.keyword" name="table_search" class="form-control pull-right" placeholder="搜尋關鍵字" />
              <div class="input-group-btn">
                <button type="button" @click="clickSearch()" class="btn btn-default fa fa-search"></button>
              </div>
            </div> -->
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ highlights.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered" id="NmjWebHighlight">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width:210px; width:10%">預覽</th>
                  <th style="min-width:340px; width:40%">youtube網址</th>
                  <th style="min-width:80px; width:5%">啟用狀態</th>
                  <th style="min-width:140px; width:5%">功能</th>
                </tr>
                <tr v-if="datas.length <= 0">
                  <td colspan="10" class="text-center">沒有資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="item.id">
                  <td>
                    <div style="width:192px; height:108px; margin:auto; overflow:hidden; background:#ddd;">
                      <iframe :src="checkEmbedLink(item.網址URL) ? item.網址URL : false"
                        frameborder="0" width="384" height="216" style="transform:scale(.5); transform-origin:top left;">
                      </iframe>
                    </div>
                  </td>
                  <td >
                    <p v-if="!item.isEdit" class="question">{{ item.名稱 }}</p>
                    <div v-else class="form-group">
                      <label for="名稱">名稱：</label>
                      <input id="名稱" v-model.lazy="item.名稱" type="text" class="form-control" required />
                    </div>
                    <p v-if="!item.isEdit" class="answer">{{ item.網址URL }}</p>
                    <div v-else class="form-group">
                      <label for="youtube網址">youtube網址：</label>
                      <input id="youtube網址" v-model="item.網址URL" @change="item.網址URL = sanitizeYoutubeLink($event.currentTarget.value)" type="text" class="form-control" required placeholder="EX: https://www.youtube.com/embed/影片ID" />
                      <span v-show="item.網址URL && !checkEmbedLink(item.網址URL)" class="text-red">連結不像YouTube影片，請確認是否符合規則：<i style="display:inline-block">https://www.youtube.com/embed/影片ID</i></span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="form-group" v-if="!isLoading || !item.isEdit">
                      <toggle-button :value="!!item.是否啟用" :labels="{checked:'啟用', unchecked:'關閉'}"  @change="item.是否啟用 = +$event.value; if(!isEditing) saveItem(item, index)" style="font-weight:normal !important;transform:scale(1.333);"/>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="form-group">
                      <button v-if="item.isEdit" @click="saveItem(item, index)" class="btn btn-success">
                        儲存
                      </button>
                      <button v-else @click="editItem(item, index)" class="btn btn-primary" :disabled="isEditing && !item.isEdit">
                        編輯
                      </button>
                      &nbsp;
                      <button v-if="item.isEdit" @click="cancelItem(item, index)" class="btn btn-default">
                        取消
                      </button>
                      <button v-else @click="deleteItem(item)" class="btn btn-danger" :disabled="isEditing && !item.isEdit">
                        刪除
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="10" class="text-center">
                    <p>YouTube連結參數可參考官方 <a href="https://developers.google.com/youtube/player_parameters?hl=zh-tw#Parameters" target="_blank" rel="noopener noreferrer nofollow">支援的參數文件</a></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="highlights.total > 0">
          <Pagination
            ref="pagination"
            :total="highlights.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!-- <pre style="line-height:1.1;">{{ datas }}</pre> -->
    </section>
  </div>
</template>

<style lang="scss">
  #NmjWebHighlight{
    .question{
      text-shadow: 0 0;
      background: #eee;
      border-bottom: none;
      padding: .35em 1em;
    }
    .answer{
      font-weight: normal;
      background: #fff;
      color: #555;
      border-top: none;
      padding: .4em 1em;
    }
    .form-group + .form-group{
      margin-top:5px;
    }
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import { ToggleButton } from "vue-js-toggle-button";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    ToggleButton,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      routeName: this.$route.name,
      searchData: {
        // projectId: this.$store.state.projectId,
        // classTypeId: "",
        // 類別: "",
        // module: "problem",
        // keyword: "",
        pageNum: 1,
        // 是否啟用: null,
        pageSize: 10, //每頁顯示筆數
      },
      datas: [],
      isEditing: false,
      isLoading: false,
      url: config.dkOption.url,
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    // 開箱文日期預設值
    if (this.projectId === 'omp_xinstars') {
      this.searchData.StartTime = new moment().subtract(360, "days").format("YYYY-MM-DD")
      this.searchData.EndTime = new moment().add(60, "days").format("YYYY-MM-DD")
    }
    
    this.getQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      types: (state) => state.nmjClassTypes.queryAll,
      highlights: (state) => state.nmjWebHighlight.queryAll,
      response: (state) => state.nmjWebHighlight.response,
    }),
  },
  watch: {
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getQueryAll();
    },
    highlights(value) {
      this.datas = [...value.datas];
      this.isLoading = false;
      // this.datas = value.datas.map(i => Object.assign(i, {isEdit:false}) );
      this.$refs.pagination.setPage(this.searchData.pageNum, this.highlights.total);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getQueryAll();
    },

    getQueryAll() {
      //列表
      this.isLoading = true;
      this.$store.dispatch("nmjWebHighlight/QueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    // clickSearch() {
    //   this.searchData.pageNum = 1;
    //   this.getQueryAll();
    // },

    //*---- 新增內容
    addNew() {
      if (this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      let data = {
        名稱: "",
        網址URL: "",
        是否啟用: 1
      };
      if (this.datas.length > 0){
        this.datas.unshift(data);
      }
      else{
        this.datas = [data];
      }
      this.editItem(this.datas[0], 0);
    },

    //*---- 修改內容
    editItem(item, index) {
      if (this.isEditing){
        return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      }
      this.isEditing = true;
      item.isEdit = true;
      this.$set(this.datas, index, item);

    },
    saveItem(item, index){
      if (item.名稱 == "") {
        return this.$eventBus.$emit("showAlert", "請填寫名稱");
      }
      if (!this.checkEmbedLink(item.網址URL)) {
        return this.$eventBus.$emit("showAlert", "YouTube聯結有誤，請確認格式");
      }

      delete item.isEdit;

      if (typeof item.id === "undefined") {
        this.$store.dispatch("nmjWebHighlight/Create", item);
      }
      else {
        this.$store.dispatch("nmjWebHighlight/Update", item);
      }
      this.isEditing = false;
    },
    cancelItem(item, index){
      this.getQueryAll();
      this.isEditing = false;
    },

    //*----預覽
    // previewItem(item) {
    //   let width = 1080;
    //   let height = 840;
    //   let left = (screen.width - width) / 2;
    //   let top = (screen.height - height) / 2;
    //   // console.log(width, height, left, top)
    //   window.open(
    //     `${process.env.VUE_APP_NMJWEB_PATH}/Service/${item.id}`,
    //     "preview",
    //     `popup,top=${top},left=${left},width=${width},height=${height}`
    //   );
    // },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjWebHighlight/Delete", item);
        }
      });
    },

    sanitizeYoutubeLink(url){
      let embed = "https://www.youtube.com/embed/";
      let match = url.match(/^(?:https?:\/\/(?:[a-z]+\.)?youtu\.?be(?:\.[a-z]+)?.*?[\/=])?([\w\-_]{11})(?:[?&\/]|$)/i);
      if (!match) return url;
      return embed + match[1] + new URL(url, embed).search;
    },
    checkEmbedLink(url){
      return /^https:\/\/www.youtube.com\/embed\/[\w\-_]{11}(?:\?|$)/.test(url);
    },

    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>