<template>
  <div id="leaderboard">
    <section class="content-header">
      <h1>搜尋貼文</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">搜尋貼文</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border pb-15 clearfix" id="BigData">

            <!-- 請選擇平台 v-for="(item, key) in kolTypes.datas"-->
            <div class="select-group">
              <select
                class="form-control"
                id="selectSocial"
                v-model="searchData.platform"
                @change="search"
              >
                <option disabled value="">請選擇平台</option>
                <option
                  v-for="(item, key) in selectPlatformsList"
                  :value="item.classId"
                  :key="key"
                >
                  {{ item.className }}
                </option>
              </select>
            </div>

            <!-- 請選擇類型-->
            <div class="select-group">
              <multiselect
                v-model="searchData.categorys"
                :options="selectTypeList"
                :multiple="true"
                placeholder="請選擇類型"
                :showLabels="false"
                :max="2"
                :hide-selected="true"
                :searchable="true"
              >
              </multiselect>
            </div>

            <!-- 搜尋 -->
            <div class="input-group searchInput atLeft">
              <input
                name="table_search"
                placeholder="搜尋貼文關鍵字，以空格分開，最多三個"
                type="text"
                class="form-control pull-right"
                v-model="searchData.keyWord"
                v-on:keyup.enter="keySearch()"
              />
              <div class="input-group-btn">
                <button v-on:click="keySearch()" 
                  class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <button data-btn="resetBtn"
              @click="searchReset()"
              class="btn btn-default btn-tools"
              type="button">
              <i class="fa fa-refresh"></i> 重設
            </button>
          </div>

          <div class="top-box mt-15">
            <!-- day -->
            <div class="btn-group ml-10 ml-0-sm">
              <button
                type="button"
                class="btn btn-default"
                v-for="(item, key) in dayAnalysis"
                :key="key"
                v-on:click="changeDays(item)"
                v-bind:class="{ active: searchData.dayAnalysisChoose == item.name }"
              >
                {{ item.name }}
              </button>
            </div>

            <!-- 自訂日期 -->
            <button
              v-on:click="searchData.isAdvancedSearch = !searchData.isAdvancedSearch"
              class="btn mb-10-md btn-default w100-sm ml-10 ml-0-sm mt-10-sm"
              type="button" >
              <i
                v-bind:class="{ 'fa-calendar-minus-o': searchData.isAdvancedSearch }"
                class="fa fa-calendar-plus-o"
              ></i>
              自訂日期
            </button>
             <!-- 進階時間 GetRestList v-on:restList="GetRestList" -->
              <div class="input-group advanceDate" v-show="searchData.isAdvancedSearch">
                <DateRangePicker 
                    v-bind:propEndDate.sync="searchData.advDateData.lte"
                    v-bind:propStartDate.sync="searchData.advDateData.gte"
                    v-on:restList="advDateSearch()"
                    propsIn="format2"
                  />
              </div>

            <!-- 資料總筆數 -->
            <div class="btn-group ml-10 mt-10">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total"> {{ leaderboardTotal }} </span>
              </p>
            </div>
          </div>
          
          <div class="nav-tabs-custom boxShadow-none clearfix mt-35">
            <ul class="nav nav-tabs nav-yellow">
              <li
                v-for="(tab, index) in platformTabs"
                :key="index"
                v-bind:class="{ active: tabIndex == 'tab' + index }"
                @click.prevent="changeSortTab(tab.value, index)">
                <a :href="'#tab' + index"> {{ tab.name }}</a>
              </li>
            </ul>

            <div class="tab-content">
              <div
                v-for="(tab, key) in platformTabs"
                :key="key"
                v-bind:class="{ active: tabIndex == 'tab' + key }"
                class="tab-pane overflowX-auto table-responsive"
                :id="'#tab' + key">
                <div
                  class="css-table post-table table"
                >
                  <div class="thead">
                    <div class="tr">
                      <div class="th" style="width: 5%; min-width: 60px">排名</div>
                      <div class="th" width="5%" style="min-width: 100px;">姓名</div>
                      <div class="th" width="40%" style="min-width: 300px">貼文</div>
                      <div class="th" style="width: 5%; min-width: 80px"
                         v-if="f_Like_count.includes(searchData.platform)">按讚數</div>
                      <div class="th" style="width: 5%; min-width: 80px"
                         v-if="f_dislike_count.includes(searchData.platform)">倒讚數</div>
                      <div class="th" style="width: 5%; min-width: 80px"
                        v-if="f_comment_counts.includes(searchData.platform)">留言數</div>
                      <div class="th" style="width: 5%; min-width: 80px"
                         v-if="f_view_count.includes(searchData.platform)">觀看數</div>
                      <div class="th" style="width: 5%; min-width: 80px"
                         v-if="f_share_count.includes(searchData.platform)">分享數</div>
                      <div class="th" style="width: 5%; min-width: 80px">互動率</div>
                      <div class="th" style="width: 10%; min-width: 95px">爬蟲時間</div>
                      <div class="th" style="width: 5%; min-width: 80px"
                        v-if="f_FunctionTab.includes(searchData.platform)">功能</div>
                    </div>
                  </div>
                  <div class="tbody" v-if="leaderboardTotal > 0">
                    <div
                      class="postCard tr cursor-auto"
                      v-for="(post, key) in leaderboardList"
                      :key="key"
                    >
                      <div class="td">
                        {{ post.rank }}
                      </div>

                      <div class="td  text-left" data-lable="姓名">
                        <span v-if="searchData.platform == 'line' ||
                          searchData.platform == 'yahoo'">
                          {{ post.publisher }}
                        </span>
                        <span v-else-if="searchData.platform == 'ptt'">
                          {{ post.author }}
                        </span>
                        <span v-else @click="showContent(post)" class="link">
                          {{ post.target_name }}</span
                        >
                      </div>
                      
                      <a href="javascript:;" class="card-body td" data-lable="貼文圖"
                        @click="OpenUrl(post)">
                        <div class="card-img jqImgFill">
                          <img
                            v-show="post.post_display_url != null"
                            :src="GetImgUrl(post)"
                          />
                          <i
                            v-show="post.post_display_url == null"
                            class="fakeImg fa fa-picture-o"
                          ></i>

                          <i
                            class="typeIcon fa"
                            v-bind:class="
                              !isVideo(post) ? 'fa-sticky-note' : 'fa-play'
                            "
                          ></i>
                        </div>
                        <p class="card-text" v-html="GetDataTitle(post)"></p>
                        <span class="card-date">{{
                          $utils.formatTime(
                            post.upload_date,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}</span>
                      </a>

                      <div class="td td-like" data-lable="按讚數"
                         v-if="f_Like_count.includes(searchData.platform)">
                        {{ post.like_count | currencyFormat}}
                      </div>

                      <div class="td td-like" data-lable="倒讚數"
                         v-if="f_dislike_count.includes(searchData.platform)">
                        {{ post.dislike_count | currencyFormat}}
                      </div>

                      <div class="td td-comment" data-lable="留言數"
                        v-if="f_comment_counts.includes(searchData.platform)">
                        {{ post.comment_counts | currencyFormat}}
                      </div>

                      <div class="td td-browse" data-lable="觀看數"
                         v-if="f_view_count.includes(searchData.platform)">
                        {{ post.view_count | currencyFormat}}
                      </div>

                      <div class="td td-browse" data-lable="分享數"
                         v-if="f_share_count.includes(searchData.platform)">
                         <span v-if="searchData.platform == 'fb'">
                           {{ post.shares | currencyFormat2}}
                        </span>
                        <span v-else>
                           {{ post.share_count | currencyFormat2}}
                        </span>

                      </div>

                      <div class="td" data-lable="互動率">
                       {{parseFloatNoRounding(post.avg_post_probability)}}%
                      </div>
                      <div class="td td-crawl time" data-lable="時間">
                        {{
                          $utils.formatTime(
                            post.last_update,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}
                      </div>

                      <div class="td text-center funTdVer" data-lable="功能"
                         v-if="f_FunctionTab.includes(searchData.platform)">
                        <button @click="postKPI(post)" class="btn btn-primary">
                          文章KPI
                        </button>
                        <br />
                        <button @click="kolKPI(post)" class="btn btn-warning"
                          v-if="searchData.platform !== 'ptt'">
                          網紅KPI</button>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div v-if="leaderboardTotal < 1" 
                class="mt-25 mb-20 tc">
                      暫無資料
                  </div>

              <div class="box-footer text-center no-border mt-20">
                <Pagination
                  :pageLimitCount="searchData.page_size"
                  :total="leaderboardTotal"
                  ref="pagination"
                  v-on:pageMethod="onPageChange"
                  v-show="leaderboardTotal > 0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <BigDataAlert ref="BigDataAlert" props-in="leaderboardList" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
import { mapState } from "vuex";
import BigDataAlert from "@/views/projects/spider/BigDataAlert";
import Multiselect from "vue-multiselect";
import DateRangePicker from '@/components/DateRangePicker';

export default {
  components: {
    Pagination,
    BigDataAlert,
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      dayAnalysis: [
        {
          name: "今天",
           value: { gte: new moment().format("YYYY-MM-DD") },
        },
        {
          name: "兩天",
            value: { 
              lte: new moment().format("YYYY-MM-DD"),
              gte: new moment().add(-1, "days").format("YYYY-MM-DD"),
            },
        },
        {
          name: "三天",
            value: { 
              lte: new moment().format("YYYY-MM-DD"),
              gte: new moment().add(-3, "days").format("YYYY-MM-DD"),
            },
        },
        {
          name: "7天",
          value: {
            lte: new moment().format("YYYY-MM-DD"),
            gte: new moment().add(-7, "days").format("YYYY-MM-DD"),
          },
        },
      ],
      
      //每一個平台各自的頁籤設定
      platformPost: [
        { 
          platform: "ig",
          tabs:[
            { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
          ]
        },
        { 
          platform: "yt",
          tabs:[
            { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
            { name: "觀看數", value: "view_count" },
          ]
        },
        { 
          platform: "fb",
          tabs:[
            { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
            { name: "分享數", value: "share_count" },
          ]
        },
        { 
          platform: "ptt",
          tabs:[
            { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
            { name: "倒讚數", value: "dislike_count" },
          ]
        },
        { 
          platform: "line",
          tabs:[
            { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
          ]
        },
        { 
          platform: "yahoo",
          tabs:[
            { name: "留言數", value: "comment_counts" },
          ]
        },
        { 
          platform: "tiktok",
          tabs:[
            { name: "留言數", value: "comment_count" },
            // { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
            // { name: "觀看數", value: "video_view_count" },
            { name: "觀看數", value: "video_count" },
            { name: "分享數", value: "share_count" },
          ]
        },
        { 
          platform: "xiaohongshu",
          tabs:[
            { name: "留言數", value: "comment_count" },
            { name: "按讚數", value: "like_count" },
            // { name: "分享數", value: "share_count" },
          ]
        },
        { 
          platform: "dcard",
          tabs:[
            { name: "留言數", value: "comment_counts" },
            { name: "按讚數", value: "like_count" },
          ]
        },
      ],
      tabIndex: "tab0",
      tabOldIndex: 2,
      tabNewIndex: 2,
      platformTabs: [],

      //各社群欄位設定:按讚數 倒讚數 留言數 觀看數 分享數 功能
      f_Like_count: ['ig','yt','fb','ptt','line','tiktok','xiaohongshu'],
      f_dislike_count: ['ptt'],
      f_comment_counts:  ['ig','yt','fb','ptt','line','yahoo','tiktok','xiaohongshu'],
      f_view_count: ['yt','tiktok'],
      f_share_count: ['fb','tiktok'],
      f_FunctionTab: ['ig','yt','fb','tiktok','xiaohongshu'],

      searchData: {
        keyWord: "",
        key: "",
        key2: "",
        key3: "",
        // page_num: Number,
        page_num: 1,
        page_size: 10,
        platform: "",
        PlatformType: "",
        categorys: [], //類型
        sort_by: "comment_counts",
        query_type: "and", //or , and
        with_content : true,
        // date_range: { 
        //    lte: new moment().format("YYYY-MM-DD"),
        //    gte: new moment().add(-1, "days").format("YYYY-MM-DD"),
        // }, //預設兩天
        date_range: {}, //
        advDateData : {},
        dayAnalysisChoose: "",
        isAdvancedSearch: false,
      },

      selectTypeList: [],
      selectPlatformsList: [],
      words: [],
      kolUser: {},
    };
  },
  computed: {
    ...mapState({
      kolTypes: (state) => state.classTypes.queryAll,
      leaderboardList: (state) => state.kolBigData.leaderboardList,
      leaderboardTotal: (state) => state.kolBigData.leaderboardTotal,
    }),
  },
  watch: {
    leaderboardList() {
      this.$refs.pagination.setPage(
        this.searchData.page_num, this.leaderboardTotal);
    },

    kolTypes() {
      let typeList = this.kolTypes.datas.filter(
        (type) => type.module == "KOLCategory"
      );
      typeList.forEach((category) => {
        this.selectTypeList.push(category.className);
      });

      this.selectPlatformsList = this.kolTypes.datas.filter(
        (type) => type.module == "KOLPlatform"
      );

      //set 類別
      if ( this.searchData.platform == "") {
        this.searchData.platform = this.selectPlatformsList[0].classId;
      }

      //set date
      if (Object.keys(this.searchData.date_range).length == 0) {
        this.searchData.date_range = 
          {
            lte: new moment().format("YYYY-MM-DD"),
            gte: new moment().add(-1, "days").format("YYYY-MM-DD"),
          };
        
        this.searchData.dayAnalysisChoose = "兩天";
      } 
      this.search();
    },
  },
  async created() {
    //搜尋之前，先檢查$route.params.searchData有無資料
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
      console.log(this.$route.params.searchData)
    }

    this.getClassTypes();
  },
  // updated() {
  //   this.$nextTick(function () {
  //     // 仅在渲染整个视图之后运行的代码
  //     $(".jqImgFill").imgLiquid();
  //   });
  // },
  methods: {
    search() {
      // this.searchData.page_num = 1;
      this.getDataList();
      this.platformTabsPost();
    },

    getDataList() {
      this.$store.dispatch(
          "kolBigData/kolleaderboard", this.searchData);
    },

    onPageChange(pageIdx, pageSize) {
      //pageIdx 頁碼   //pageSize 一頁幾筆
      this.searchData.page_num = pageIdx;
      this.searchData.page_size = pageSize;

      this.getDataList();
    },
    
    //分類
    getClassTypes() {
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    platformTabsPost() {
      let that = this;
      this.platformPost.forEach( function (item)  {
        if (item.platform === that.searchData.platform) {
          that.platformTabs = item.tabs;
          that.tabNewIndex = item.tabs.length;
        }
      });
      //計算tab位置是否多於新的平台的tabs的數量
      if (that.tabOldIndex > that.tabNewIndex) {
        let tabNow = parseInt(that.tabIndex.slice(-1)) + 1;
        if (tabNow > that.tabNewIndex ) {
          that.tabIndex =  "tab0";
        }
      }
      that.tabOldIndex = that.tabNewIndex;
    },

    keySearch(){
      this.words = [];
      this.searchData.key = "";
      this.searchData.key2= "";
      this.searchData.key3= "";
      this.words = this.searchData.keyWord.split(/\s+/); 
      
      this.searchData.key = this.words[0];
      this.searchData.key2 = this.words[1];
      this.searchData.key3 = this.words[2];
      // console.log(this.words);
      this.search();
    },

    selectInit() {
      let oldPlatform = this.searchData.platform;
      
      this.searchData = {
        keyWord: "",
        key: "",
        key2: "",
        key3: "",
        page_num: 1,
        page_size: 10,
        platform:  oldPlatform,
        // PlatformType: "",
        categorys: [], //類型
        sort_by: "comment_counts",
        query_type: "and", //or , and
        with_content : true,
        date_range: { 
          lte: new moment().format("YYYY-MM-DD"),
          gte: new moment().add(-1, "days").format("YYYY-MM-DD"),
        },
        advDateData : {},
        isAdvancedSearch: false,
      }
      this.words = [];
      this.searchData.dayAnalysisChoose = "兩天";
      // console.log('run init');
    },

    searchReset() {
      this.selectInit();
      this.search();
    },

    changeSortTab(tab, index) {
      this.tabIndex = "tab" + index;

      this.searchData.sort_by = tab;
      this.searchData.page_num = 1;

      this.getDataList();
    },

    changeDays(item) {
      this.searchData.dayAnalysisChoose = item.name;
      this.searchData.date_range = item.value;
      this.search();
    },

    advDateSearch() {
      this.searchData.dayAnalysisChoose = "";
      this.searchData.date_range = this.searchData.advDateData;
      this.search();
    },

    isVideo(item) {
      if (item.platform == "yt" || item.is_video) return true;
      return false;
    },

    OpenUrl(data) {
      let url = "";
      switch (data.platform) {
        case "ig":
          url = "https://instagram.com/p/" + data.shortcode;
          break;
        case "yt":
          url = "https://www.youtube.com/watch?v=" + data.video_id;
          break;
        default:
          url = data.post_url;
          break;
      }
      if (url != null && url != "") window.open(url);
    },

    showContent(item) {
      //顯示人物簡介
      this.$refs.BigDataAlert.showContent(item);
    },

    postKPI(item) {    
      this.$router.push({
        name: "SpiderBigDataPost",
        params: { 
          searchData: this.searchData, 
          id: item.main_id,
          post_id: item.post_id,
          back: "KOLLeaderboard"
        },
      });
    },

    kolKPI(item) {
      this.$router.push({
          name: "SpiderBigDataKOL",
          params: { searchData: this.searchData,
          id: item.main_id, 
          back: "KOLLeaderboard" 
        },
      });
    },
    

    GetImgUrl(data) {
      if (data.post_display_url != null && data.post_display_url != "") {
        let url = new URL(data.post_display_url);
        switch (data.platform) {
          case "ig":
            return (
              "https://dev88.newtalk.tw/kol/image" + url.pathname + url.search
            );
          default:
            return data.post_display_url;
        }
      }
    },

    GetDataTitle(datas) {
      switch (datas.platform) {
        case "ig":
        case "fb":
        case "dcard":
        case "ptt":
          return datas.post_title;
        case "yt":
          return datas.video_title;
        case "line":
        case "yahoo":
          return datas.title;
        case "tiktok":
          return datas.description;
        case "xiaohongshu":
          return datas.description;
        default:
          return "";
      }
    },

    parseFloatNoRounding(num) {
      if (isNaN(parseInt(num))) return 0;
      return Math.floor(num * 100) / 100;
    },
  },
};
</script>
