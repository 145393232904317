<template>
  <div id="AlbumPhotoManage">
    <section class="content-header">
      <h1>相簿管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>相簿列表</li>
        <li class="active">相簿管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title"></h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class name="announcementForm">
            <div class="box-body">
              <div class="form-group albumUploadWrapper clearfix">
                <label class="control-label" for="Pic">
                  <span class="text-red">*</span>請選擇上傳檔案：
                </label>

                <div class="buttonWrapper">
                  <input accept="image/*" type="file" @change="onFileChange($event)" multiple />
                  <div class="help-block text-red">(每個檔案上限5MB，最多可以選5個檔案)</div>
                </div>
              </div>

              <ul class="photo-album" id="albumPhtoWrapper">
                <li class="photoBox" v-for="(file, index) in fileList" :key="index">
                  <div class="pic jqimgFill" :data-id="file.id">
                    <img :src="file.imgFullFileName" draggable="false" />
                  </div>
                  <button class="btn btn-danger" @click="deleteFile(file)"><i class="fa fa-trash"></i></button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
#AlbumPhotoManage{
  .photo-album{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    align-items: flex-end;
    img{
      max-height:240px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
      object-fit: cover;
    }
  }
}
</style>

<script>
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";
import Sortable  from 'sortablejs';

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      currentTab: "setting",
      langTypes: {},
      idDonationListMode: false,
      backRouteName: "WaninFundAlbumList",
      module: "album",
      detailGameLang: [],
      classId: "",
      edit: {
        contentId: utils.getTimeId(), //Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        contentUrl: "", // 網址p
        contentDetailTopId: "", // 內容Id
        detailTopTitle: "", //標題
        detailTopInfo: "", //內容
        isTurnPlay: 0, //輪播
        publishTime: "",
        isStop: 0, // 狀態
        sortId: 0, // 排序
      },
      fileList: [],
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {
    $(".jqimgFill").imgLiquid();
    var el = document.getElementById("albumPhtoWrapper");
    Sortable.create(el, {
      animation: 300,
    });
  },
  computed: {
    ...mapState({
      classTypes: (state) => state.classTypes.queryAll,
      editData: (state) => state.game.query,
      detailQueryAll: (state) => state.game.detailQueryAll,
      projectData: (state) => state.module.queryAll,
      response: (state) => state.game.response,
      fileUploadResponse: (state) => state.file.response,
      imgUrlList: (state) => state.game.imgList,
    }),
  },
  watch: {
    editData() {
      this.edit = this.editData;
      if (this.edit.publishTime != "") {
        $(".datepicker").val(
          new Date(this.edit.publishTime).toLocaleDateString()
        );
      }
      this.GetEditDataLangData();
    },

    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetClassTypes();

      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetEditData();
      } else {
        this.detailGameLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.contentId + i + 1;
          this.detailGameLang.push({
            gameDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo,
          });
        }
      }
    },

    detailQueryAll() {
      let that = this;
      this.detailGameLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          const Id = this.edit.contentId + i + 1;
          let findLang = false;
          this.detailQueryAll.datas.some((details) => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.gameDetailId = details.gameDetailId;
                that.edit.detailTopTitle = details.title;
                that.edit.detailTopInfo = details.info;
              }
              that.detailGameLang.push({
                gameDetailId: details.gameDetailId,
                lang: details.lang,
                title: details.title,
                info: details.info,
              });
              findLang = true;
              return true;
            }
          });
          if (!findLang) {
            that.detailGameLang.push({
              gameDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: "",
            });
          }
        }
        this.GetFileList();
      }
    },

    classTypes() {
      this.OnTypeChnage();
    },

    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: this.backRouteName,
          params: { searchData: this.$route.params.searchData },
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },

    imgUrlList() {
      this.fileList = [];
      this.fileList = this.imgUrlList;
    },
  },
  methods: {
    GetEditData() {
      this.$store.dispatch("game/gameQuery", {
        contentId: this.$route.params.id,
      });
    },

    GetEditDataLangData() {
      this.$store.dispatch("game/gameDetailQueryAll", {
        contentId: this.$route.params.id,
      });
    },

    GetClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    GetClassId() {
      var classId = "donationlist";
      try {
        this.classTypes.datas.forEach((item) => {
          if (item.classTypeId == this.edit.classTypeId) {
            classId = item.classId;
          }
        });
      } catch (e) {
        return false;
      }
      return classId;
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetFileList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("game/gameImgList", {
        contentId: this.edit.contentId,
      });
    },

    //取得該語系資料
    GetEditDataDetailLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.gameDetailId = this.detailGameLang[i].gameDetailId;
          this.edit.detailTopTitle = this.detailGameLang[i].title;
          this.edit.detailTopInfo = this.detailGameLang[i].info;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      this.removeFile();
      this.SetLangData(this.editLang);
      this.GetEditDataDetailLang(lang);
      this.editLang = lang;
      this.GetFileList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailGameLang[i].title = this.edit.detailTopTitle;
          this.detailGameLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: this.backRouteName,
        params: { searchData: this.$route.params.searchData },
      });
    },

    onFileChange(evt) {
      this.SetLangData(this.editLang);
      this.GetEditDataDetailLang(this.editLang);

      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }
      }
      this.uploadFile(
        files,
        this.module,
        this.edit.contentId,
        true,
        this.fileList.length + 1
      );
    },

    uploadFile(files, folderName, id, multi = false, od = 1) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.edit.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("od", od);
      data.append("lang", "zh-tw");
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then((res) => {
        if (res.status != "0") this.GetFileList();
      });
    },

    deleteFile(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("file/fileDelete", item).then((res) => {
            if (res.status != "0") {
              this.GetFileList();
            }
          });
        }
      });
    },

    OnTypeChnage() {
      this.classId = this.GetClassId();
    },
    async Cancel() {
      this.backList();
    },
    //送出
    async Save() {
      //更新圖片Order
      var fileOrders = [];
      $("#albumPhtoWrapper .jqimgFill").each(function (idx, item) {
        fileOrders.push({ 
            ApplyId: $(item).data("id"),
            Od: idx + 1 
          });
      });
      
      var res = await this.$store.dispatch("file/updateFileInfoApply", fileOrders);
      if (res.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");
      }else {
        this.$eventBus.$emit("showAlert", "更新失敗!!");
      } 
    },
  },
};
</script>
