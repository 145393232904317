/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import define from '@/views/projects/FC/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcPlayerData.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.fcPlayerData.queryAll(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    res.data.result.datas.forEach(item => {
                        items.push({
                            '玩家UID': item.playUid || item.playUId,
                            '玩家暱稱': item.playNickname,
                            '等級': item.level,
                            '等級經驗值': item.exp,
                            'VIP等級': define.vipStatus[item.vipLevel],
                            'VIP經驗值': item.vipExp,
                            '金幣點數': item.gold,
                            '鑽石點數': item.diamond,
                            '創建帳號時間': item.createTime,
                            '上次登入時間': item.loginTime,
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家資料查詢列表.xlsx`,
                        '玩家資料查詢'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}