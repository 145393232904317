/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';

// actions
const actions = {
    queryEntry({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.queryEntry(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    queryAllEntry({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.queryAllEntry(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    createEntry({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.createEntry(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    updateEntry({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.updateEntry(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    queryEvent({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.queryEvent(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    queryAllEvent({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.queryAllEvent(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    createEvent({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.createEvent(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    updateEvent({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.updateEvent(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    queryCompetition({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.queryCompetition(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    queryAllCompetition({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.queryAllCompetition(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    createCompetition({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.createCompetition(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    updateCompetition({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.updateCompetition(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    updateResult({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.updateResult(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
    notifyGameServer({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjGameBet.notifyGameServer(data).then(res => {
                if (res.data.status === '1') resolve(res.data.result);
                else reject(res.data.message);
            });
        });
    },
}

export default {
    namespaced: true,
    actions,
}