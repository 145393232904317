/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
// import define from '@/views/projects/FC/_Define';

const dateOptions = {
    year:"numeric",
    month:"2-digit",
    day:"2-digit",
    hour:"2-digit",
    minute:"2-digit",
    second:"2-digit",
    hourCycle:"h23",
};
const state = {
    queryAll: {},
    history: {},
    deposit: {}
}

// getters
const getters = {
}

// actions
const actions = {
    // PlayerData =============================================================
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.query(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.nmjPlayerData.query(data).then(res => {
            console.log('export:', res)
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    let items = [];
                    res.data.result.forEach(item => {
                        items.push({
                            '玩家暱稱': item.nickName,
                            '綁定門號': item.門號綁定,
                            '會員狀態': item.會員狀態,
                            '段位等級': item.段位等級 + ' (' + item.level + ')',
                            '段位經驗值': item.levelExp,
                            '牌技': item.skill,
                            '雀幣': item.mahjongCoin,
                            '創建帳號時間': new Date(item.createTime*1000).toLocaleString('zh-TW', dateOptions),
                            '上次登入時間': new Date(item.lastPlayerLoginTime*1000).toLocaleString('zh-TW', dateOptions),
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家資料查詢列表.xlsx`,
                        '玩家資料查詢'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
    // PlayerHistory ==========================================================
    history({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.history(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setHistory', result);
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    exportHistory({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.nmjPlayerData.history(data).then(res => {
            console.log('export:', res)
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    let items = [];
                    console.log("here")
                    res.data.result.playerHistoryList.forEach(item => {
                        items.push({
                            '時間': new Date(item.時間).toLocaleString('zh-TW', dateOptions),
                            '玩家暱稱': item.玩家暱稱,
                            '種類': item.種類,
                            '項目': item.項目,
                            '數量': item.數量,
                            '當下經驗值': item.當下經驗值,
                            '當下牌技': item.當下牌技,
                            '當下雀幣': item.當下雀能,
                            '原因': item.原因,
                            'ID': item.ID
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家歷程查詢(${data.暱稱}).xlsx`,
                        '玩家歷程查詢'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        });
    },
    // PlayerDeposit ==========================================================
    deposit({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.deposit(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setDeposit', result);
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    // exportDeposit({ commit }, data){
    //     data = utils.deepCloneObj(data);
    //     data.pageNum = 0;
    //     data.pageSize = 0;
    //     api.nmjPlayerData.deposit(data).then(res => {
    //         console.log('export:', res)
    //         if (res.data.status != '1'){
    //             this._vm.$eventBus.$emit('showAlert', res.data.message);
    //             return;
    //         }
    //         if (res.data.result.total <= 0) {
    //             this._vm.$eventBus.$emit('showAlert', '查無資料');
    //             return;
    //         }
    //         const r = res.data.result;
    //         let items = [];
    //         const keys = [
    //             `總金額(TWD)：${r.totalPoint.點數合計}`, ,
    //             `總筆數：${r.total}`, ,
    //             `不重複人數：${r.noRepeatNum.不重複數}`,
    //         ];
    //         addRow(['','','','','','','','',''], keys);
    //         addRow(['訂單建立時間','訂單最後處理時間','玩家暱稱','儲值平台','訂單編號','平台商品ID','商品名稱','販售金額(TWD)','獲得雀幣'], keys);
    //         r.datas.forEach(item => {
    //             addRow([
    //                 new Date(item.訂單建立時間).toLocaleString('zh-TW', dateOptions),
    //                 new Date(item.訂單最後處理時間).toLocaleString('zh-TW', dateOptions),
    //                 item.暱稱,
    //                 /^apple/.test(item.平台商品ID) ? 'AppleStore' : 'GooglePlay',
    //                 item.訂單編號,
    //                 item.平台商品ID,
    //                 item.商品名稱,
    //                 item['販售金額(TWD)'],
    //                 item.獲得雀幣,
    //             ], keys);
    //         });
    //         let filename = data.交易ID ? data.交易ID : (data.暱稱 ? data.暱稱 : (data.平台 ? data.平台 : ''));
    //         if (filename) filename = '('+filename+')';
    //         utils.exportData(
    //             items,
    //             `${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家儲值查詢${filename}.xlsx`,
    //             '玩家儲值查詢'
    //         );
    //         function addRow(array, keys = []){
    //             let entries = [];
    //             for (let i = 0; i < array.length; i++){
    //                 let key = keys[i] || Array(i+1).fill(' ').join('');
    //                 entries[i] = [key, array[i]];
    //                 console.log(entries[i]);
    //             }
    //             items.push(Object.fromEntries(entries));
    //         }
    //     });
    // },
    exportDeposit({ commit }, data){
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.nmjPlayerData.deposit(data).then(res => {
            console.log('export:', res)
            if (res.data.status != '1'){
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                return;
            }
            if (res.data.result.total <= 0) {
                this._vm.$eventBus.$emit('showAlert', '查無資料');
                return;
            }
            const r = res.data.result;
            const totals = [
                `總金額(TWD)：${r.totalPoint.點數合計}`,
                `總筆數：${r.total}`,
                `不重複人數：${r.noRepeatNum.不重複數}`,
            ];
            let items = [];
            r.datas.forEach(item => {
                items.push({
                    '加總':'',
                    '訂單建立時間': new Date(item.訂單建立時間).toLocaleString('zh-TW', dateOptions),
                    '訂單最後處理時間': new Date(item.訂單最後處理時間).toLocaleString('zh-TW', dateOptions),
                    '玩家暱稱': item.暱稱,
                    '儲值平台': /^apple/.test(item.平台商品ID) ? 'AppleStore' : 'GooglePlay',
                    '訂單編號': item.訂單編號,
                    '平台商品ID': item.平台商品ID,
                    '商品名稱': item.商品名稱,
                    '販售金額(TWD)': item['販售金額(TWD)'],
                    '獲得雀幣': item.獲得雀幣,
                });
            });
            totals.forEach((item, i) => {
                if (items[i]) items[i]['加總'] = item;
                else items.push({'加總': item});
            });
            let filename = data.交易ID ? data.交易ID : (data.暱稱 ? data.暱稱 : (data.平台 ? data.平台 : ''));
            if (filename) filename = '('+filename+')';
            utils.exportData(
                items,
                `${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家儲值查詢${filename}.xlsx`,
                '玩家儲值查詢'
            );
        });
    },
    // PlayerControl ==========================================================
    control({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.control(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    reject(res.data.message);
                }
            });
        });
    },
    controlInfo({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.controlInfo(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    reject(res.data.message);
                }
            });
        });
    },
    controlList({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.controlList(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    reject(res.data.message);
                }
            });
        });
    },
    exportControl({ commit }, data) {
        console.log(data)
        let items = [];
        for (let item of Object.entries(data)){
            console.log(item)
            items.push({
                '玩家暱稱': item[0],
                '解果': item[1] == 0 ? '成功' : '失敗',
            });
        }
        utils.exportData(
            items,
            `${utils.formatTime(new Date(), 'YYYYMMDD')}_大量停權執行結果.xlsx`,
            '大量停權執行結果'
        );
    },
    
    // PlayerService ==========================================================
    service({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.queryService(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    reject(res.data.message);
                }
            });
        });
    },
    serviceCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.createService(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    reject(res.data.message);
                }
            });
        });
    },
    serviceDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjPlayerData.deleteService(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else {
                    reject(res.data.message);
                }
            });
        });
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
    setHistory(state, history) {
        state.history = history;
    },
    setDeposit(state, deposit) {
        state.deposit = deposit;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}