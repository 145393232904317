<template>
    <div>
        <section class="content-header">
            <h1> 新增-修改廠商品項</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'GoodsManagerList',
                        query: this.$route.query,
                    });">品項管理</a>
                </li>
                <li class="active">新增-修改廠商品項 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 廠商：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.廠商設定主檔編號" :disabled="isEdit"
                                        @change="change廠商設定主檔編號([])">
                                        <option :key="item.編號" v-for="item in 廠商設定List" :value="item.編號">
                                            {{ item.名稱 }}-{{ item.顯示名稱 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-show="!isEdit" style="margin-top: -15px">
                                <label class="col-sm-4 control-label">
                                </label>
                                <div class="col-sm-8">
                                    <span class="text-red">新增後無法修改</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    平台遊戲編號：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.平台遊戲編號" :disabled="isEdit">
                                        <option value="">不設定</option>
                                        <option :key="item.廠商編號" v-for="item in 廠商設定List" :value="item.廠商編號">
                                            {{ item.顯示名稱 }} {{ item.廠商編號 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-show="!isEdit" style="margin-top: -15px">
                                <label class="col-sm-4 control-label">
                                </label>
                                <div class="col-sm-8">
                                    <span class="text-red">新增後無法修改</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 商品模式：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.商品模式" :disabled="isEdit">
                                        <option :key="item.編號" v-for="item in 商品模式" :value="item.編號">
                                            {{ item.顯示名稱 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-show="!isEdit" style="margin-top: -15px">
                                <label class="col-sm-4 control-label">
                                </label>
                                <div class="col-sm-8">
                                    <span class="text-red">新增後無法修改</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span>第三方物品類別：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.第三方物品類別">
                                        <option :key="item" v-for="item in 第三方物品類別List" :value="item"
                                            :disabled="orgEdit.第三方物品類別 != '' && item == '預設'">
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" style="margin-top: -15px">
                                <label class="col-sm-4 control-label">
                                </label>
                                <div class="col-sm-8">
                                    一但設成預設以外的值後，無法改回<span class="text-red">預設</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label dropright">
                                    <span class="text-red">*</span> 品項名稱 <button type="button" class="btn dropdown-toggle"
                                        data-toggle="dropdown" aria-expanded="true">
                                        <span><i class="fa fa-fw fa-question"></i></span>
                                    </button>
                                    ：
                                    <div class="dropdown-menu common">
                                        顯示在「付款資訊頁-付款品項」<br />
                                        <hr />
                                        官網帶入點數=&lt;point&gt; <br />
                                        官網帶入價格=&lt;price&gt; <br />
                                        <br />
                                        範例： <br />
                                        付款品項顯示：yoe數位卡<font class="text-blue">50</font>點(單價$<font class="text-red">50</font>
                                        *1) <br />
                                        設定需設為：yoe數位卡<font class="text-blue">&lt;point&gt;</font>點(單價$<font class="text-red">
                                            &lt;price&gt;</font>*1)
                                        <br />
                                        <img src="/download/IPS_illustrate01.png" />
                                    </div>
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.品項名稱"
                                        v-AddArgInput="{ model: 'edit.品項名稱', format: AddArgFormat }" ref="品項名稱Input" />
                                    <span class="input-group-btn widthAuto">
                                        <button type="button" class="btn btn-info btn-flat"
                                            v-AddArgBtn="{ insertText: '<point>', ref: '品項名稱Input', model: 'edit.品項名稱' }">添加&lt;point&gt;</button>
                                    </span>
                                    <span class="input-group-btn widthAuto">
                                        <button type="button" class="btn btn-info btn-flat"
                                            v-AddArgBtn="{ insertText: '<price>', ref: '品項名稱Input', model: 'edit.品項名稱' }">添加&lt;price&gt;</button>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> ERP名稱：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.erP名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 商品代碼：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.商品代碼" :disabled="isEdit" />
                                </div>
                            </div>
                            <div class="form-group" v-show="!isEdit" style="margin-top: -15px">
                                <label class="col-sm-4 control-label">
                                </label>
                                <div class="col-sm-8">
                                    <span class="text-red">新增後無法修改</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    第三方物品編號：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.第三方物品編號" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    商品備註：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.商品備註" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 金額：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="number" v-model.trim.number="edit.金額"
                                        :disabled="isEdit" />
                                </div>
                            </div>
                            <div class="form-group" v-show="!isEdit" style="margin-top: -15px">
                                <label class="col-sm-4 control-label">
                                </label>
                                <div class="col-sm-8">
                                    <span class="text-red">新增後無法修改</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 販賣數量：
                                </label>
                                <div class="col-sm-8">
                                    <div class="input-group">
                                        <input class="form-control" type="text" v-model.trim="edit.販賣數量" />
                                        <span class="input-group-addon text-red">輸入-1=沒有設定限制</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 販售時間：
                                </label>
                                <div class="col-sm-8">
                                    <DateTimePicker v-bind:propDateTime.sync="edit.販賣開始時間"></DateTimePicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red"></span>
                                </label>
                                <div class="col-sm-8">
                                    ~
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red"></span>
                                </label>
                                <div class="col-sm-8">
                                    <DateTimePicker v-bind:propDateTime.sync="edit.販賣結束時間"></DateTimePicker>
                                </div>
                            </div>
                            <div v-for="item in edit.支付品號列表" :key="item.編號" class="form-group">
                                <label class="col-sm-4 control-label">
                                    <button type="button" class="btn dropdown-toggle btn-info" data-toggle="dropdown"
                                        aria-expanded="true"
                                        :class="(item.上層設定.啟用 && 金流設定主檔Key[item.上層設定.金流設定主檔編號].啟用 && 金流帳號Key[item.上層設定.金流帳號編號].啟用 && 金流支付設定Key[item.上層設定.金流支付設定編號].啟用) ? 'btn-info' : 'btn-danger'">
                                        <span>
                                            {{ 金流設定主檔Key[item.上層設定.金流設定主檔編號].金流名稱 }} -
                                            {{ 金流設定主檔Key[item.上層設定.金流設定主檔編號].金流代碼 }}
                                        </span>
                                    </button>
                                    <span>：</span>
                                    <div class="dropdown-menu">
                                        <PayInfo ref="upSetting" :上層設定="item.上層設定" :金流設定主檔Key="金流設定主檔Key" :金流帳號Key="金流帳號Key"
                                            :金流支付設定Key="金流支付設定Key" :地區列表Key="地區列表Key" :金流類型設定Key="金流類型設定Key">
                                        </PayInfo>
                                    </div>
                                </label>
                                <div class="col-sm-8">
                                    <div class="input-group margin" style="margin: 0px;">

                                        <span class="input-group-addon">
                                            是否使用&emsp;<input type="checkbox" v-model="item.是否使用">
                                        </span>
                                        <input type="text" class="form-control"
                                            :placeholder="支付品號placeholder(item.上層設定.支付品號, 金流支付設定Key[item.上層設定.金流支付設定編號].支付品號)"
                                            v-model="item.支付品號">
                                        <!--此為廠商層的品號，目前不使用-->
                                        <!-- <span class="input-group-btn">
                                            <button type="button" class="btn btn-info btn-flat" style="width:160px"
                                                @click="item.支付品號 = item.上層設定.支付品號">廠商設定：{{ item.上層設定.支付品號 }}</button>
                                        </span> -->
                                        <span class="input-group-btn">
                                            <button type="button" class="btn btn-info btn-flat"
                                                style="width:200px; text-align: left;"
                                                @click="item.支付品號 = 金流支付設定Key[item.上層設定.金流支付設定編號].支付品號">品號(金流層)：{{
                                                    金流支付設定Key[item.上層設定.金流支付設定編號].支付品號
                                                }}</button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import moment from "moment";
import api from "@/assets/js/app-api";
import { IPSTool, APILib, IsSelectedInOptions, IPSMixins } from './IPSTool2'
import { AddArgMixins } from '@/assets/js/Tool/AddArgMixins'
import DateTimePicker from "@/components/DateTimePicker";
import { mapState } from "vuex";
import PayInfo from "./components/PayInfo.vue";
import { 檢查輸入數值 } from "@/views/projects/ips/Arithmetic";
export default {
    mixins: [IPSMixins, AddArgMixins],
    components: {
        iCheckRedioButton,
        DateTimePicker,
        PayInfo
    },
    data() {
        return {
            AddArgFormat: AddArgMixins.AddArgFormatEnum.一個大於小於,
            廠商設定List: [],
            金流帳號List: [],
            金流支付List: [],
            廠商支付List: [],


            金流設定主檔Key: {},
            金流帳號Key: {},
            金流支付設定Key: {},

            isEdit: false,
            orgEdit: {
                第三方物品類別: "",
            },
            edit: {
                廠商設定主檔編號: -1,
                商品模式: 0,
                品項名稱: "",
                商品代碼: "",
                第三方物品編號: "",
                商品備註: "",
                erP名稱: "",
                金額: 0,
                販賣數量: -1,
                販賣開始時間: "",
                販賣結束時間: "",
                支付品號列表: [
                    // {
                    // "廠商支付編號":"廠商支付編號",
                    // "是否使用":1,
                    // "支付品號":"品項管理之支付品號"
                    // }
                ],
                // 指定金流: [
                // ],
                啟用: "1",
                平台遊戲編號: "",
                第三方物品類別: "預設",//長度不可超過10個字
            },
            第三方物品類別List: [
                "預設", "QPP遊e數位點", "QPP遊e數位卡", "QPP序號商品", "yoe直儲禮包"
            ],
        }
    },
    computed: {
        ...mapState({
            額外限制列表: state => state.IPSSetting.基礎設定檔.額外限制列表,
            商品模式: state => state.IPSSetting.基礎設定檔.商品模式,
        }),
        地區列表Key() {
            return this.$store.getters["IPSSetting/地區列表Key"];
        },
        金流類型設定Key() {
            return this.$store.getters["IPSSetting/金流類型設定Key"];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
            this.refresh();
        },
        async refresh() {
            try {
                this.廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;

                this.金流設定主檔Key = await this.$store.dispatch("IPSSetting/金流設定主檔Key_查詢", {});
                this.金流帳號Key = await this.$store.dispatch("IPSSetting/金流帳號Key_查詢", {});
                this.金流支付設定Key = await this.$store.dispatch("IPSSetting/金流支付設定Key_查詢2", {});

                let res = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;
                this.廠商支付List = IPSTool.deCodeData(res, "額外限制");

                let 編號 = parseInt(this.$route.params.goodId);
                if (編號 > 0) {
                    this.isEdit = true;
                    let res = APILib.IPS(await api.IPSSetting.廠商品項管理_查詢({ 編號 })).content;//await IPSTool.callAPIList.call(this, api.IPSSetting.廠商品項管理_查詢, { 編號 });
                    let data = res[0];
                    this.orgEdit.第三方物品類別 = data.第三方物品類別;
                    this.edit = { ...data };
                    if (!this.edit.第三方物品類別) {
                        this.edit.第三方物品類別 = "預設";
                    }
                    if (this.edit.平台遊戲編號 === null || this.edit.平台遊戲編號 == undefined) {
                        this.edit.平台遊戲編號 = "";
                    }
                    delete this.edit.建立時間;
                    try {
                        this.edit.支付品號列表 = JSON.parse(this.edit.支付品號列表);
                    } catch (e) {
                        this.edit.支付品號列表 = [];
                    }
                    this.change廠商設定主檔編號(this.edit.支付品號列表);
                } else {
                    this.edit.販賣開始時間 = new moment().format("YYYY-MM-DD 00:00:00");
                    this.edit.販賣結束時間 = "2999-12-31 23:59:59";
                }
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        backList() {
            this.$router.push({
                name: "GoodsManagerList",
                query: this.$route.query,
            });
        },
        change廠商設定主檔編號(支付品號列表) {
            //過瀘
            let list = this.廠商支付List.filter((item) => {
                return item.廠商設定主檔編號 + "" === this.edit.廠商設定主檔編號 + "";
            });
            let newList = [];
            list.forEach(element => {
                let obj = {
                    廠商支付編號: element.編號,
                    支付品號: this.isEdit ? "" : element.支付品號,
                    是否使用: this.isEdit ? 0 : 1,
                    上層設定: element
                }
                let 指定金流 = 支付品號列表.find(item => {
                    return item.廠商支付編號 + "" === element.編號 + "";
                });
                if (指定金流) {
                    obj.是否使用 = 指定金流.是否使用;
                    obj.支付品號 = 指定金流.支付品號;
                }
                newList.push(obj);
            });
            this.edit.支付品號列表 = newList;
        },
        async Save() {
            // console.error(this.edit.販賣開始時間);
            // console.error(this.edit.販賣結束時間);
            //檢查資料：
            if (this.edit.廠商設定主檔編號 <= 0 || !IsSelectedInOptions(this.edit.廠商設定主檔編號, this.廠商設定List, "編號")) {
                this.$eventBus.$emit("showAlert", "請選擇廠商");
                return;
            }
            if (this.edit.品項名稱 == "") {
                this.$eventBus.$emit("showAlert", "品項名稱不可為空");
                return;
            }
            if (this.edit.erP名稱 == "") {
                this.$eventBus.$emit("showAlert", "ERP名稱不可為空");
                return;
            }
            if (this.edit.商品代碼 == "") {
                this.$eventBus.$emit("showAlert", "商品代碼不可為空");
                return;
            }
            if (!檢查輸入數值(this.edit.金額, 0, 4, true)) {
                this.$eventBus.$emit("showAlert", "金額必須大於0且小數點位數不可超過4位");
                return;
            }
            if (this.edit.販賣數量 != -1 && !檢查輸入數值(this.edit.販賣數量, 0, 0)) {
                this.$eventBus.$emit("showAlert", "販賣數量輸入錯誤");
                return;
            }

            let 開始時間 = new moment(this.edit.販賣開始時間);
            let 結束時間 = new moment(this.edit.販賣結束時間);
            if (開始時間.diff(結束時間) >= 0) {
                this.$eventBus.$emit("showAlert", "開始時間不可大於結束時間");
                return;
            }

            //啟停用判別碼檢查：
            let 已使用判別碼列表 = [];
            for (let i of this.edit.支付品號列表) {
                if (i.是否使用) {
                    let tmp啟停用判別碼 = `${i.上層設定.廠商設定主檔編號}-${i.上層設定.金流設定主檔編號}-${i.上層設定.金流支付設定編號}`;

                    if (已使用判別碼列表.find(element => element == tmp啟停用判別碼)) {

                        let 金流名稱 = this.金流設定主檔Key[i.上層設定.金流設定主檔編號].金流名稱;
                        let 金流代碼 = this.金流設定主檔Key[i.上層設定.金流設定主檔編號].金流代碼;

                        this.$eventBus.$emit("showAlert", `同時只能啟用一個金流，請檢查：${金流名稱}-${金流代碼}`/*JSON.stringify(i)*/);
                        return;
                    }
                    已使用判別碼列表.push(tmp啟停用判別碼);
                }
            }
            //啟停用判別碼檢查End
            try {
                $(this.$refs.loading).show();
                let edit = JSON.parse(JSON.stringify(this.edit));
                if (edit.第三方物品類別 == "預設") {
                    edit.第三方物品類別 = "";
                }
                //編碼指定金流：
                for (let i of edit.支付品號列表) {
                    delete i.info;
                    i.是否使用 = i.是否使用 ? 1 : 0;
                }
                edit.支付品號列表 = JSON.stringify(edit.支付品號列表);
                delete edit.指定金流;
                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.廠商品項管理_修改(edit));
                } else {
                    APILib.IPS(await api.IPSSetting.廠商品項管理_新增(edit));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
            } catch (e) {
                $(this.$refs.loading).hide();
                this.$eventBus.$emit("showAlert", e);
            }
            this.backList();
        },
    },
};
</script>
<style scoped>
.form-horizontal .control-label {
    line-height: 34px;
    padding-top: 0px;
}

.col-md-10 {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
}

.dropdown-menu {
    top: auto;
    left: 280px;
    bottom: 0px;
    right: -300px;
    padding: 15px;
    white-space: nowrap;
}


.dropdown-menu.common {
    /* bottom: -480px; */
    line-height: 1.5;

    top: 0px;
    bottom: auto;
    right: -600px;
}

.input-group-btn.widthAuto {
    width: auto;
}
</style>
