import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    awardsItemQueryAll({ commit }, data) {
        api.awardsItem.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setAwardsItemQueryAll', res.data.result);
            }
        })
    },
    awardsItemQuery({ commit }, data) {
        api.awardsItem.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setAwardsItemQuery', res.data.result);
            }
        })
    },
    awardsItemCreate({ commit }, data) {
        api.awardsItem.create(data).then(res => {
            if (res.data.status === '1') {
                commit('setResponse', res.data);
            } else {
                this._vm.global.showResponse(this._vm, res, 'create');
            }
        })
    },
    awardsItemUpdate({ commit }, data) {
        api.awardsItem.update(data).then(res => {
            this._vm.global.showResponse(this._vm, res, 'update');
            commit('setResponse', res.data);
        })
    },
    awardsItemDelete({ commit }, data) {
        api.awardsItem.delete(data).then(res => {
            if (res.data.status === '1') {
                commit('setResponse', res.data);
            } else {
                this._vm.global.showResponse(this._vm, res, 'delete');
            }
        })
    },
}

// mutations
const mutations = {
    setAwardsItemQueryAll(state, awardsItemQueryAll) {
        state.queryAll = awardsItemQueryAll;
    },
    setAwardsItemQuery(state, awardsItemQuery) {
        state.query = awardsItemQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}