<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>簡訊自訂發送詳細</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊自訂發送</li>
        <li class="active">簡訊自訂發送詳細</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <button class="btn btn-block btn-default btn-cog" @click="backList()" type="button">
              返回
            </button>
            <div class="select-group" >
              <label for="" class="control-label">發送狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.結果"
                @change="resetDataList()"
              >
                <option :value="-1">全部</option>
                <option :value="1">完成</option>
                <option :value="2">失敗</option>
                <option :value="0">未發送</option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 20%;">門號</th>
                  <th class="text-nowrap" style="width: 20%;">發送狀態</th>
                  <th class="text-nowrap" style="width: 60%">簡訊內容</th>
                </tr>
                <template v-if="list.length > 0">
                  <tr :key="item.id" v-for="item in list">
                    <td class="text-nowrap text-center">{{ item.門號 }}</td>
                    <td class="text-nowrap text-center">{{ statusList[item.結果] }}{{ item.結果 == 2 ? '：' + item.回應碼 : '' }}</td>
                    <td class="text-nowrap" style="white-space: normal;">
                      <div style="word-break: break-all;">{{ item.簡訊內容 }}</div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        流水號: this.$route.params.id,
        結果: -1,
        pageNum: 1,
        pageSize: 10,
      },
      list: [],
      total: 0,
      comList: {},
      statusList: {
        '1': '完成',
        '2': '失敗',
        '0': '未發送',
      }
    }
  },
  created() {
    this.getDataList();
  },
  mounted() {},
  computed: {},
  methods: {
    getDataList() {
      this.loading = true;
      api.moloSMS.customize.phoneQueryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    backList() {
      this.$router.push({
        name: "SMSCustomize",
        params:{
          searchData: this.$route.params.searchData
        }
      })
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  }
}
</script>