<template>
  <div class="modal fade" data-backdrop="static" ref="popForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">填寫資料：{{ edit.訂單編號 }}</h4>
        </div>
        <div class="box-body">
          <div class="form-group">
            <label>是否捐贈</label>
            <div>
              <div class="state icheckbox_flat-green" :class="edit.是否捐贈 == 1 ? 'checked' : ''"
                @click="edit.是否捐贈 = edit.是否捐贈 == 1 ? 0 : 1"></div>
            </div>
          </div>

          <div class="form-group" v-if="edit.是否捐贈 == 1">
            <label>愛心條碼</label>
            <input type="text" class="form-control" placeholder="愛心條碼" v-model.trim="edit.愛心條碼">
          </div>

          <div class="form-group" v-if="edit.是否捐贈 == 0">
            <label>收件人</label>
            <input type="text" class="form-control" placeholder="收件人" v-model.trim="edit.收件人">
          </div>

          <div class="form-group" v-if="edit.是否捐贈 == 0">
            <label>收件地址</label>
            <input type="text" class="form-control" placeholder="收件地址" v-model.trim="edit.收件地址">
          </div>

          <div class="form-group" v-if="edit.是否捐贈 == 0">
            <label>連絡電話</label>
            <input type="text" class="form-control" placeholder="連絡電話" v-model.trim="edit.連絡電話">
          </div>

          <div class="form-group" v-if="edit.是否捐贈 == 0">
            <label>信箱</label>
            <input type="text" class="form-control" placeholder="信箱" v-model.trim="edit.信箱">
          </div>
          <div class="form-group">
            <label>備註</label>
            <input type="text" class="form-control" placeholder="備註" v-model.trim="edit.備註">
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" :disabled="loading" type="button" @click="save()">確認修改</button>
          <button class="btn btn-default pull-right" type="button" @click="hide()">關閉</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool'
import { mapState } from "vuex";
export default {
  data() {
    return {
      edit: {
        訂單編號: "",
        是否捐贈: 0,//--->不捐贈:0 捐贈:1，有發生捐贈時，收件人、收件地址、連絡電話、信箱 不用呼叫(會進行清空，也不會記錄)
        愛心條碼: "",//-->如果發生捐贈的話會用財政部的功能檢查愛心條碼的正確性
        收件人: "",//-->只檢查是否為空值，並不會檢查正確性
        收件地址: "",//-->只檢查是否為空值，並不會檢查正確性
        連絡電話: "",//-->只檢查是否為空值，並不會檢查正確性
        信箱: "",//-->只檢查是否為空值，並不會檢查正確性
        備註: "",
        // 是否修改: 1,//-->填寫後需確認這筆資料是確定要修改才補為1
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.invoice.loading,
    }),
  },
  methods: {
    async save() {
      let edit = {
        訂單編號: this.edit.訂單編號,
        是否捐贈: this.edit.是否捐贈,
        是否修改: 1
      };
      //檢查資料
      if (this.edit.是否捐贈 == 1) {
        if (this.edit.愛心條碼 == "") {
          this.$eventBus.$emit("showAlert", "愛心條碼不可為空"); return;
        }
        edit.愛心條碼 = this.edit.愛心條碼;
      } else {
        // if (this.edit.收件人 == "") {
        //   this.$eventBus.$emit("showAlert", "收件人不可為空"); return;
        // }
        // if (this.edit.收件地址 == "") {
        //   this.$eventBus.$emit("showAlert", "收件地址不可為空"); return;
        // }
        // if (this.edit.連絡電話 == "") {
        //   this.$eventBus.$emit("showAlert", "連絡電話不可為空"); return;
        // }
        // if (this.edit.信箱 == "") {
        //   this.$eventBus.$emit("showAlert", "信箱不可為空"); return;
        // }
        edit.收件人 = this.edit.收件人;
        edit.收件地址 = this.edit.收件地址;
        edit.連絡電話 = this.edit.連絡電話;
        edit.信箱 = this.edit.信箱;
      }
      if (this.edit.備註 == "") {
        //2023-03-09 因DBA那邊阻擋，備註若為空則擋住，否則server會回傳修改成功，但備註其實沒改掉
        this.$eventBus.$emit("showAlert", "備註不可為空"); return;
      }
      edit.備註 = this.edit.備註;

      try {
        this.$store.dispatch('invoice/loading', true);
        APILib.Invoice(await api.IPSSetting.upInvoiceJackpot([edit]));
        this.$eventBus.$emit("showAlert", "成功");
      } catch (e) {
        this.$eventBus.$emit("showAlert", e);
        return;
      } finally {
        this.$store.dispatch('invoice/loading', false);
      }
      this.hide();
      this.$emit('更新列表', this.edit.訂單編號);
    },
    async show({ 訂單編號, 是否捐贈, 愛心條碼, 收件人, 收件地址, 連絡電話, 信箱, 備註 }) {
      this.edit = {
        訂單編號,
        是否捐贈,
        愛心條碼,
        收件人,
        收件地址,
        連絡電話,
        信箱,
        備註
        // 是否修改: 1
      };
      $(this.$refs.popForm).modal("show");
    },
    hide() {
      if (this.loading) {
        this.$eventBus.$emit("showAlert", "請等執行完畢才關閉");
        return;
      }
      $(this.$refs.popForm).modal("hide");
    },
  },
}
</script>