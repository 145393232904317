/* eslint-disable */
import api from '@/assets/js/app-api';
import define from '@/views/projects/NMJ/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMarquee.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMarquee.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                } else reject(res.data.message)
            });
        });
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMarquee.create(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMarquee.update(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    delete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMarquee.delete(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}