/* eslint-disable */
import BandMode from "./BandMode";
import { NObject, NArray, NotSupportedException, Nullable, Random, Exception, NMath } from "./mscorlib";
class Bits extends NObject {
    constructor() {
        super();
        this.bytes = null;
        this.bytePtr = 0;
        this.bitPtr = 0;
        this.nbBits = 0;
        this.bytes = new Uint8Array(1024);
        for (let _ai = 0; _ai < this.bytes.length; ++_ai)
            this.bytes[_ai] = 0;
        this.bytePtr = 0;
        this.bitPtr = 0;
    }
    get BufferSize() {
        return this.bytePtr + ((this.bitPtr > 0) ? 1 : 0);
    }
    Advance(n) {
        this.bytePtr = this.bytePtr + (n >> 3);
        this.bitPtr = this.bitPtr + (n & 7);
        let flag = this.bitPtr > 7;
        if (flag) {
            this.bitPtr = this.bitPtr - 8;
            this.bytePtr = this.bytePtr + 1;
        }
    }
    Peek() {
        return (this.bytes[this.bytePtr] & 255) >> 7 - this.bitPtr & 1;
    }
    ReadFrom(newbytes, offset, len) {
        let flag = this.bytes.length < len;
        if (flag) {
            this.bytes = new Uint8Array(len);
        }
        for (let i = 0; i < len; i = i + 1) {
            this.bytes[i] = newbytes[offset + i];
        }
        this.bytePtr = 0;
        this.bitPtr = 0;
        this.nbBits = len * 8;
    }
    BitsRemaining() {
        return this.nbBits - (this.bytePtr * 8 + this.bitPtr);
    }
    Unpack(nbBits) {
        let num = 0;
        while (nbBits > 0) {
            num = num << 1;
            num = (num | ((this.bytes[this.bytePtr] & 255) >> 7 - this.bitPtr & 1));
            this.bitPtr = this.bitPtr + 1;
            let flag = this.bitPtr === 8;
            if (flag) {
                this.bitPtr = 0;
                this.bytePtr = this.bytePtr + 1;
            }
            nbBits = nbBits - 1;
        }
        return num;
    }
    Pack(data, nbBits) {
        while (this.bytePtr + (nbBits + this.bitPtr >> 3) >= this.bytes.length) {
            let num = this.bytes.length * 2;
            let destinationArray = new Uint8Array(num);
            for (let _ai = 0; _ai < destinationArray.length; ++_ai)
                destinationArray[_ai] = 0;
            NArray.Uint8ArrayCopy(this.bytes, 0, destinationArray, 0, this.bytes.length);
            this.bytes = destinationArray;
        }
        while (nbBits > 0) {
            let num2 = data >> nbBits - 1 & 1;
            let expr_79_cp_0 = this.bytes;
            let expr_79_cp_1 = this.bytePtr;
            expr_79_cp_0[expr_79_cp_1] = (expr_79_cp_0[expr_79_cp_1] | (num2 << 7 - this.bitPtr));
            this.bitPtr = this.bitPtr + 1;
            let flag = this.bitPtr === 8;
            if (flag) {
                this.bitPtr = 0;
                this.bytePtr = this.bytePtr + 1;
            }
            nbBits = nbBits - 1;
        }
    }
    InsertTerminator() {
        let flag = this.bitPtr > 0;
        if (flag) {
            this.Pack(0, 1);
        }
        while (this.bitPtr > 0) {
            this.Pack(1, 1);
        }
    }
    Write(buffer, offset, maxBytes) {
        let num = this.bitPtr;
        let num2 = this.bytePtr;
        let array = this.bytes;
        this.InsertTerminator();
        this.bitPtr = num;
        this.bytePtr = num2;
        this.bytes = array;
        let flag = maxBytes > this.BufferSize;
        if (flag) {
            maxBytes = this.BufferSize;
        }
        NArray.Uint8ArrayCopy(this.bytes, 0, buffer, offset, maxBytes);
        return maxBytes;
    }
    Reset() {
        NArray.Uint8ArrayClear(this.bytes, 0, this.bytes.length);
        this.bytePtr = 0;
        this.bitPtr = 0;
    }
}
Bits.DefaultBufferSize = 1024;
class CodebookSearch extends NObject {
    Quantify(target, ak, awk1, awk2, p, nsf, exc, es, r, bits, complexity) {
        throw new NotSupportedException();
    }
    Unquantify(exc, es, nsf, bits) {
        throw new NotSupportedException();
    }
    constructor() {
        super();
    }
}
class Codebook_Constants extends NObject {
    constructor() {
        super();
    }
}
Codebook_Constants.exc_20_32_table = [
    12, 32, 25, 46, 36, 33, 9, 14, -3, 6, 1, -8, 0, -10, -5, -7, -7, -7, -5,
    -5, 31, -27, 24, -32, -4, 10, -11, 21, -3, 19, 23, -9, 22, 24, -10, -1, -10, -13, -7,
    -11, 42, -33, 31, 19, -8, 0, -10, -16, 1, -21, -17, 10, -8, 14, 8, 4, 11, -2, 5,
    -2, -33, 11, -16, 33, 11, -4, 9, -4, 11, 2, 6, -5, 8, -5, 11, -4, -6, 26, -36,
    -16, 0, 4, -2, -8, 12, 6, -1, 34, -46, -22, 9, 9, 21, 9, 5, -66, -5, 26, 2,
    10, 13, 2, 19, 9, 12, -81, 3, 13, 13, 0, -14, 22, -35, 6, -7, -4, 6, -6, 10,
    -6, -31, 38, -33, 0, -10, -11, 5, -12, 12, -17, 5, 0, -6, 13, -9, 10, 8, 25, 33,
    2, -12, 8, -6, 10, -2, 21, 7, 17, 43, 5, 11, -7, -9, -20, -36, -20, -23, -4, -4,
    -3, 27, -9, -9, -49, -39, -38, -11, -9, 6, 5, 23, 25, 5, 3, 3, 4, 1, 2, -3,
    -1, 87, 39, 17, -21, -9, -19, -9, -15, -13, -14, -17, -11, -10, -11, -8, -6, -1, -3, -3,
    -1, -54, -34, -27, -8, -11, -4, -5, 0, 0, 4, 8, 6, 9, 7, 9, 7, 6, 5, 5,
    5, 48, 10, 19, -10, 12, -1, 9, -3, 2, 5, -3, 2, -2, -2, 0, -2, -26, 6, 9,
    -7, -16, -9, 2, 7, 7, -5, -43, 11, 22, -11, -9, 34, 37, -15, -13, -6, 1, -1, 1,
    1, -64, 56, 52, -11, -27, 5, 4, 3, 1, 2, 1, 3, -1, -4, -4, -10, -7, -4, -4,
    2, -1, -7, -7, -12, -10, -15, -9, -5, -5, -11, -16, -13, 6, 16, 4, -13, -16, -10, -4,
    2, -47, -13, 25, 47, 19, -14, -20, -8, -17, 0, -3, -13, 1, 6, -17, -14, 15, 1, 10,
    6, -24, 0, -10, 19, -69, -8, 14, 49, 17, -5, 33, -29, 3, -4, 0, 2, -8, 5, -6,
    2, 120, -56, -12, -47, 23, -9, 6, -5, 1, 2, -5, 1, -10, 4, -1, -1, 4, -1, 0,
    -3, 30, -52, -67, 30, 22, 11, -1, -4, 3, 0, 7, 2, 0, 1, -10, -4, -8, -13, 5,
    1, 1, -1, 5, 13, -9, -3, -10, -62, 22, 48, -4, -6, 2, 3, 5, 1, 1, 4, 1,
    13, 3, -20, 10, -9, 13, -2, -4, 9, -20, 44, -1, 20, -32, -67, 19, 0, 28, 11, 8,
    2, -11, 15, -19, -53, 31, 2, 34, 10, 6, -4, -58, 8, 10, 13, 14, 1, 12, 2, 0,
    0, -128, 37, -8, 44, -9, 26, -3, 18, 2, 6, 11, -1, 9, 1, 5, 3, 0, 1, 1,
    2, 12, 3, -2, -3, 7, 25, 9, 18, -6, -37, 3, -8, -16, 3, -10, -7, 17, -34, -44,
    11, 17, -15, -3, -16, -1, -13, 11, -46, -65, -2, 8, 13, 2, 4, 4, 5, 15, 5, 9,
    6, 8, 2, 8, 3, 10, -1, 3, -3, 6, -2, 3, 3, -5, 10, -11, 7, 6, -2, 6,
    -2, -9, 19, -12, 12, -28, 38, 29, -1, 12, 2, 5, 23, -10, 3, 4, -15, 21, -4, 3,
    3, 6, 17, -9, -4, -8, -20, 26, 5, -10, 6, 1, -19, 18, -15, -12, 47, -6, -2, -7,
    -9, -1, -17, -2, -2, -14, 30, -14, 2, -7, -4, -1, -12, 11, -25, 16, -3, -12, 11, -7,
    7, -17, 1, 19, -28, 31, -7, -10, 7, -10, 3, 12, 5, -16, 6, 24, 41, -29, -54, 0,
    1, 7, -1, 5, -6, 13, 10, -4, -8, 8, -9, -27, -53, -38, -1, 10, 19, 17, 16, 12,
    12, 0, 3, -7, -4, 13, 12, -31, -14, 6, -5, 3, 5, 17, 43, 50, 25, 10, 1, -6,
    -2
];
Codebook_Constants.exc_10_16_table = [
    22, 39, 14, 44, 11, 35, -2, 23, -4, 6, 46, -28, 13, -27, -23, 12, 4, 20, -5,
    9, 37, -18, -23, 23, 0, 9, -6, -20, 4, -1, -17, -5, -4, 17, 0, 1, 9, -2, 1,
    2, 2, -12, 8, -25, 39, 15, 9, 16, -55, -11, 9, 11, 5, 10, -2, -60, 8, 13, -6,
    11, -16, 27, -47, -12, 11, 1, 16, -7, 9, -3, -29, 9, -14, 25, -19, 34, 36, 12, 40,
    -10, -3, -24, -14, -37, -21, -35, -2, -36, 3, -6, 67, 28, 6, -17, -3, -12, -16, -15, -17,
    -7, -59, -36, -13, 1, 7, 1, 2, 10, 2, 11, 13, 10, 8, -2, 7, 3, 5, 4, 2,
    2, -3, -8, 4, -5, 6, 7, -42, 15, 35, -2, -46, 38, 28, -20, -9, 1, 7, -3, 0,
    -2, -5, -4, -2, -4, -8, -3, -8, -5, -7, -4, -15, -28, 52, 32, 5, -5, -17, -20, -10,
    -1
];
Codebook_Constants.exc_10_32_table = [
    7, 17, 17, 27, 25, 22, 12, 4, -3, 0, 28, -36, 39, -24, -15, 3, -9, 15, -5,
    10, 31, -28, 11, 31, -21, 9, -11, -11, -2, -7, -25, 14, -22, 31, 4, -14, 19, -12, 14,
    -5, 4, -7, 4, -5, 9, 0, -2, 42, -47, -16, 1, 8, 0, 9, 23, -57, 0, 28, -11,
    6, -31, 55, -45, 3, -5, 4, 2, -2, 4, -7, -3, 6, -2, 7, -3, 12, 5, 8, 54,
    -10, 8, -7, -8, -24, -25, -27, -14, -5, 8, 5, 44, 23, 5, -9, -11, -11, -13, -9, -12,
    -8, -29, -8, -22, 6, -15, 3, -12, -1, -5, -3, 34, -1, 29, -16, 17, -4, 12, 2, 1,
    4, -2, -4, 2, -1, 11, -3, -52, 28, 30, -9, -32, 25, 44, -20, -24, 4, 6, -1, 0,
    0, -3, 7, -4, -4, -7, -6, -9, -2, -10, -7, -25, -10, 22, 29, 13, -13, -22, -13, -4,
    0, -4, -16, 10, 15, -36, -24, 28, 25, -1, -3, 66, -33, -11, -15, 6, 0, 3, 4, -2,
    5, 24, -20, -47, 29, 19, -2, -4, -1, 0, -1, -2, 3, 1, 8, -11, 5, 5, -57, 28,
    28, 0, -16, 4, -4, 12, -6, -1, 2, -20, 61, -9, 24, -22, -42, 29, 6, 17, 8, 4,
    2, -65, 15, 8, 10, 5, 6, 5, 3, 2, -2, -3, 5, -9, 4, -5, 23, 13, 23, -3,
    -63, 3, -5, -4, -6, 0, -3, 23, -36, -46, 9, 5, 5, 8, 4, 9, -5, 1, -3, 10,
    1, -6, 10, -11, 24, -47, 31, 22, -12, 14, -10, 6, 11, -7, -7, 7, -31, 51, -12, -6,
    7, 6, -17, 9, -11, -20, 52, -19, 3, -6, -6, -8, -5, 23, -41, 37, 1, -21, 10, -14,
    8, 7, 5, -15, -15, 23, 39, -26, -33, 7, 2, -32, -30, -21, -8, 4, 12, 17, 15, 14,
    11
];
Codebook_Constants.exc_5_256_table = [
    -8, -37, 5, -43, 5, 73, 61, 39, 12, -3, -61, -32, 2, 42, 30, -3, 17, -27, 9,
    34, 20, -1, -5, 2, 23, -7, -46, 26, 53, -47, 20, -2, -33, -89, -51, -64, 27, 11, 15,
    -34, -5, -56, 25, -9, -1, -29, 1, 40, 67, -23, -16, 16, 33, 19, 7, 14, 85, 22, -10,
    -10, -12, -7, -1, 52, 89, 29, 11, -20, -37, -46, -15, 17, -24, -28, 24, 2, 1, 0, 23,
    -101, 23, 14, -1, -23, -18, 9, 5, -13, 38, 1, -28, -28, 4, 27, 51, -26, 34, -40, 35,
    47, 54, 38, -54, -26, -6, 42, -25, 13, -30, -36, 18, 41, -4, -33, 23, -32, -7, -4, 51,
    -3, 17, -52, 56, -47, 36, -2, -21, 36, 10, 8, -33, 31, 19, 9, -5, -40, 10, -9, -21,
    19, 18, -78, -18, -5, 0, -26, -36, -47, -51, -44, 18, 40, 27, -2, 29, 49, -26, 2, 32,
    -54, 30, -73, 54, 3, -5, 36, 22, 53, 10, -1, -84, -53, -29, -5, 3, -44, 53, -51, 4,
    22, 71, -35, -1, 33, -5, -27, -7, 36, 17, -23, -39, 16, -9, -55, -15, -20, 39, -35, 6,
    -39, -14, 18, 48, -64, -17, -15, 9, 39, 81, 37, -68, 37, 47, -21, -6, -104, 13, 6, 9,
    -2, 35, 8, -23, 18, 42, 45, 21, 33, -5, -49, 9, -6, -43, -56, 39, 2, -16, -25, 87,
    1, -3, -9, 17, -25, -11, -9, -1, 10, 2, -14, -14, 4, -1, -10, 28, -23, 40, -32, 26,
    -9, 26, 4, -27, -23, 3, 42, -60, 1, 49, -3, 27, 10, -52, -40, -2, 18, 45, -23, 17,
    -44, 3, -3, 17, -46, 52, -40, -47, 25, 75, 31, -49, 53, 30, -30, -32, -36, 38, -6, -15,
    -16, 54, -27, -48, 3, 38, -29, -32, -22, -14, -4, -23, -13, 32, -39, 9, 8, -45, -13, 34,
    -16, 49, 40, 32, 31, 28, 23, 23, 32, 47, 59, -68, 8, 62, 44, 25, -14, -24, -65, -16,
    36, 67, -25, -38, -21, 4, -33, -2, 42, 5, -63, 40, 11, 26, -42, -23, -61, 79, -31, 23,
    -20, 10, -32, 53, -25, -36, 10, -26, -5, 3, 0, -71, 5, -10, -37, 1, -24, 21, -54, -17,
    1, -29, -25, -15, -27, 32, 68, 45, -16, -37, -18, -5, 1, 0, -77, 71, -6, 3, -20, 71,
    -67, 29, -35, 10, -30, 19, 4, 16, 17, 5, 0, -14, 19, 2, 28, 26, 59, 3, 2, 24,
    39, 55, -50, -45, -18, -17, 33, -35, 14, -1, 1, 8, 87, -35, -29, 0, -27, 13, -7, 23,
    -13, 37, -40, 50, -35, 14, 19, -7, -14, 49, 54, -5, 22, -2, -29, -8, -27, 38, 13, 27,
    48, 12, -41, -21, -15, 28, 7, -16, -24, -19, -20, 11, -20, 9, 2, 13, 23, -20, 11, 27,
    -27, 71, -69, 8, 2, -6, 22, 12, 16, 16, 9, -16, -8, -17, 1, 25, 1, 40, -37, -33,
    66, 94, 53, 4, -22, -25, -41, -42, 25, 35, -16, -15, 57, 31, -29, -32, 21, 16, -60, 45,
    15, -1, 7, 57, -26, -47, -29, 11, 8, 15, 19, -105, -8, 54, 27, 10, -17, 6, -12, -1,
    -10, 4, 0, 23, -10, 31, 13, 11, 10, 12, -64, 23, -3, -8, -19, 16, 52, 24, -40, 16,
    10, 40, 5, 9, 0, -13, -7, -21, -8, -6, -7, -21, 59, 16, -53, 18, -60, 11, -47, 14,
    -18, 25, -13, -24, 4, -39, 16, -28, 54, 26, -67, 30, 27, -20, -52, 20, -12, 55, 12, 18,
    -16, 39, -14, -6, -26, 56, -88, -55, 12, 25, 26, -37, 6, 75, 0, -34, -81, 54, -30, 1,
    -7, 49, -23, -14, 21, 10, -62, -58, -57, -47, -34, 15, -4, 34, -78, 31, 25, -11, 7, 50,
    -10, 42, -63, 14, -36, -4, 57, 55, 57, 53, 42, -42, -1, 15, 40, 37, 15, 25, -11, 6,
    1, 31, -2, -6, -1, -7, -64, 34, 28, 30, -1, 3, 21, 0, -88, -12, -56, 25, -28, 40,
    8, -28, -14, 9, 12, 2, -6, -17, 22, 49, -6, -26, 14, 28, -20, 4, -12, 50, 35, 40,
    13, -38, -58, -29, 17, 30, 22, 60, 26, -54, -39, -12, 58, -28, -63, 10, -21, -8, -12, 26,
    -62, 6, -10, -11, -22, -6, -7, 4, 1, 18, 2, -70, 11, 14, 4, 13, 19, -24, -34, 24,
    67, 17, 51, -21, 13, 23, 54, -30, 48, 1, -13, 80, 26, -16, -2, 13, -4, 6, -30, 29,
    -24, 73, -58, 30, -27, 20, -2, -21, 41, 45, 30, -27, -3, -5, -18, -20, -49, -3, -35, 10,
    42, -19, -67, -53, -11, 9, 13, -15, -33, -51, -30, 15, 7, 25, -30, 4, 28, -22, -34, 54,
    -29, 39, -46, 20, 16, 34, -4, 47, 75, 1, -44, -55, -24, 7, -1, 9, -42, 50, -8, -36,
    41, 68, 0, -4, -10, -23, -15, -50, 64, 36, -9, -27, 12, 25, -38, -47, -37, 32, -49, 51,
    -36, 2, -4, 69, -26, 19, 7, 45, 67, 46, 13, -63, 46, 15, -47, 4, -41, 13, -6, 5,
    -21, 37, 26, -55, -7, 33, -1, -28, 10, -17, -64, -14, 0, -36, -17, 93, -3, -9, -66, 44,
    -21, 3, -12, 38, -6, -13, -12, 19, 13, 43, -43, -10, -12, 6, -5, 9, -49, 32, -5, 2,
    4, 5, 15, -16, 10, -21, 8, -62, -8, 64, 8, 79, -1, -66, -49, -18, 5, 40, -5, -30,
    -45, 1, -6, 21, -32, 93, -18, -30, -21, 32, 21, -18, 22, 8, 5, -41, -54, 80, 22, -10,
    -7, -8, -23, -64, 66, 56, -14, -30, -41, -46, -14, -29, -37, 27, -14, 42, -2, -9, -29, 34,
    14, 33, -14, 22, 4, 10, 26, 26, 28, 32, 23, -72, -32, 3, 0, -14, 35, -42, -78, -32,
    6, 29, -18, -45, -5, 7, -33, -45, -3, -22, -34, 8, -8, 4, -51, -25, -9, 59, -78, 21,
    -5, -25, -48, 66, -15, -17, -24, -49, -13, 25, -23, -64, -6, 40, -24, -19, -11, 57, -33, -8,
    1, 10, -52, -54, 28, 39, 49, 34, -11, -61, -41, -43, 10, 15, -15, 51, 30, 15, -51, 32,
    -34, -2, -34, 14, 18, 16, 1, 1, -3, -3, 1, 1, -18, 6, 16, 48, 12, -5, -42, 7,
    36, 48, 7, -20, -10, 7, 12, 2, 54, 39, -38, 37, 54, 4, -11, -8, -46, -10, 5, -10,
    -34, 46, -12, 29, -37, 39, 36, -11, 24, 56, 17, 14, 20, 25, 0, -25, -28, 55, -7, -5,
    27, 3, 9, -26, -8, 6, -24, -10, -30, -31, -34, 18, 4, 22, 21, 40, -1, -29, -37, -8,
    -21, 92, -29, 11, -3, 11, 73, 23, 22, 7, 4, -44, -9, -11, 21, -13, 11, 9, -78, -1,
    47, 114, -12, -37, -19, -5, -11, -22, 19, 12, -30, 7, 38, 45, -21, -8, -9, 55, -45, 56,
    -21, 7, 17, 46, -57, -87, -6, 27, 31, 31, 7, -56, -12, 46, 21, -5, -12, 36, 3, 3,
    -21, 43, 19, 12, -7, 9, -14, 0, -9, -33, -91, 7, 26, 3, -11, 64, 83, -31, -46, 25,
    2, 9, 5, 2, 2, -1, 20, -17, 10, -5, -27, -8, 20, 8, -19, 16, -21, -13, -31, 5,
    5, 42, 24, 9, 34, -20, 28, -61, 22, 11, -39, 64, -20, -1, -30, -9, -20, 24, -25, -24,
    -29, 22, -60, 6, -5, 41, -9, -87, 14, 34, 15, -57, 52, 69, 15, -3, -102, 58, 16, 3,
    6, 60, -75, -32, 26, 7, -57, -27, -32, -24, -21, -29, -16, 62, -46, 31, 30, -27, -15, 7,
    15
];
Codebook_Constants.exc_5_64_table = [
    1, 5, -15, 49, -66, -48, -4, 50, -44, 7, 37, 16, -18, 25, -26, -26, -15, 19, 19,
    -27, -47, 28, 57, 5, -17, -32, -41, 68, 21, -2, 64, 56, 8, -16, -13, -26, -9, -16, 11,
    6, -39, 25, -19, 22, -31, 20, -45, 55, -43, 10, -16, 47, -40, 40, -20, -51, 3, -17, -14,
    -15, -24, 53, -20, -46, 46, 27, -68, 32, 3, -18, -5, 9, -31, 16, -9, -10, -1, -23, 48,
    95, 47, 25, -41, -32, -3, 15, -25, -55, 36, 41, -27, 20, 5, 13, 14, -22, 5, 2, -23,
    18, 46, -15, 17, -18, -34, -5, -8, 27, -55, 73, 16, 2, -1, -17, 40, -78, 33, 0, 2,
    19, 4, 53, -16, -15, -16, -28, -3, -13, 49, 8, -7, -29, 27, -13, 32, 20, 32, -61, 16,
    14, 41, 44, 40, 24, 20, 7, 4, 48, -60, -77, 17, -6, -48, 65, -15, 32, -30, -71, -10,
    -3, -6, 10, -2, -7, -29, -56, 67, -30, 7, -5, 86, -6, -10, 0, 5, -31, 60, 34, -38,
    -3, 24, 10, -2, 30, 23, 24, -41, 12, 70, -43, 15, -17, 6, 13, 16, -13, 8, 30, -15,
    -8, 5, 23, -34, -98, -4, -13, 13, -48, -31, 70, 12, 31, 25, 24, -24, 26, -7, 33, -16,
    8, 5, -11, -14, -8, -65, 13, 10, -2, -9, 0, -3, -68, 5, 35, 7, 0, -31, -1, -17,
    -9, -9, 16, -37, -18, -1, 69, -48, -28, 22, -21, -11, 5, 49, 55, 23, -86, -36, 16, 2,
    13, 63, -51, 30, -11, 13, 24, -18, -6, 14, -19, 1, 41, 9, -5, 27, -36, -44, -34, -37,
    -21, -26, 31, -39, 15, 43, 5, -8, 29, 20, -8, -20, -52, -28, -1, 13, 26, -34, -10, -9,
    27, -8, 8, 27, -66, 4, 12, -22, 49, 10, -77, 32, -18, 3, -38, 12, -3, -1, 2, 2,
    0
];
Codebook_Constants.exc_8_128_table = [
    -14, 9, 13, -32, 2, -10, 31, -10, -8, -8, 6, -4, -1, 10, -64, 23, 6, 20, 13,
    6, 8, -22, 16, 34, 7, 42, -49, -28, 5, 26, 4, -15, 41, 34, 41, 32, 33, 24, 23,
    14, 8, 40, 34, 4, -24, -41, -19, -15, 13, -13, 33, -54, 24, 27, -44, 33, 27, -15, -15,
    24, -19, 14, -36, 14, -9, 24, -12, -4, 37, -5, 16, -34, 5, 10, 33, -15, -54, -16, 12,
    25, 12, 1, 2, 0, 3, -1, -4, -4, 11, 2, -56, 54, 27, -20, 13, -6, -46, -41, -33,
    -11, -5, 7, 12, 14, -14, -5, 8, 20, 6, 3, 4, -8, -5, -42, 11, 8, -14, 25, -2,
    2, 13, 11, -22, 39, -9, 9, 5, -45, -9, 7, -9, 12, -7, 34, -17, -102, 7, 2, -42,
    18, 35, -9, -34, 11, -5, -2, 3, 22, 46, -52, -25, -9, -94, 8, 11, -5, -5, -5, 4,
    -7, -35, -7, 54, 5, -32, 3, 24, -9, -22, 8, 65, 37, -1, -12, -23, -6, -9, -28, 55,
    -33, 14, -3, 2, 18, -60, 41, -17, 8, -16, 17, -11, 0, -11, 29, -28, 37, 9, -53, 33,
    -14, -9, 7, -25, -7, -11, 26, -32, -8, 24, -21, 22, -19, 19, -10, 29, -14, -10, -4, -3,
    -2, 3, -1, -4, -4, -5, -52, 10, 41, 6, -30, -4, 16, 32, 22, -27, -22, 32, -3, -28,
    -3, 3, -35, 6, 17, 23, 21, 8, 2, 4, -45, -17, 14, 23, -4, -31, -11, -3, 14, 1,
    19, -11, 2, 61, -8, 9, -12, 7, -10, 12, -3, -24, 99, -48, 23, 50, -37, -5, -23, 0,
    8, -14, 35, -64, -5, 46, -25, 13, -1, -49, -19, -15, 9, 34, 50, 25, 11, -6, -9, -16,
    -20, -32, -33, -32, -27, 10, -8, 12, -15, 56, -14, -32, 33, 3, -9, 1, 65, -9, -9, -10,
    -2, -6, -23, 9, 17, 3, -28, 13, -32, 4, -2, -10, 4, -16, 76, 12, -52, 6, 13, 33,
    -6, 4, -14, -9, -3, 1, -15, -16, 28, 1, -15, 11, 16, 9, 4, -21, -37, -40, -6, 22,
    12, -15, -23, -14, -17, -16, -9, -10, -9, 13, -39, 41, 5, -9, 16, -38, 25, 46, -47, 4,
    49, -14, 17, -2, 6, 18, 5, -6, -33, -22, 44, 50, -2, 1, 3, -6, 7, 7, -3, -21,
    38, -18, 34, -14, -41, 60, -13, 6, 16, -24, 35, 19, -13, -36, 24, 3, -17, -14, -10, 36,
    44, -44, -29, -3, 3, -54, -8, 12, 55, 26, 4, -2, -5, 2, -11, 22, -23, 2, 22, 1,
    -25, -39, 66, -49, 21, -8, -2, 10, -14, -60, 25, 6, 10, 27, -25, 16, 5, -2, -9, 26,
    -13, -20, 58, -2, 7, 52, -9, 2, 5, -4, -15, 23, -1, -38, 23, 8, 27, -6, 0, -27,
    -7, 39, -10, -14, 26, 11, -45, -12, 9, -5, 34, 4, -35, 10, 43, -22, -11, 56, -7, 20,
    1, 10, 1, -26, 9, 94, 11, -27, -14, -13, 1, -11, 0, 14, -5, -6, -10, -4, -15, -8,
    -41, 21, -5, 1, -28, -8, 22, -9, 33, -23, -4, -4, -12, 39, 4, -7, 3, -60, 80, 8,
    -17, 2, -6, 12, -5, 1, 9, 15, 27, 31, 30, 27, 23, 61, 47, 26, 10, -5, -8, -12,
    -13, 5, -18, 25, -15, -4, -15, -11, 12, -2, -2, -16, -2, -6, 24, 12, 11, -4, 9, 1,
    -9, 14, -45, 57, 12, 20, -35, 26, 11, -64, 32, -10, -10, 42, -4, -9, -16, 32, 24, 7,
    10, 52, -11, -57, 29, 0, 8, 0, -6, 17, -17, -56, -40, 7, 20, 18, 12, -6, 16, 5,
    7, -1, 9, 1, 10, 29, 12, 16, 13, -2, 23, 7, 9, -3, -4, -5, 18, -64, 13, 55,
    -25, 9, -9, 24, 14, -25, 15, -11, -40, -30, 37, 1, -19, 22, -5, -31, 13, -2, 0, 7,
    -4, 16, -67, 12, 66, -36, 24, -8, 18, -15, -23, 19, 0, -45, -7, 4, 3, -13, 13, 35,
    5, 13, 33, 10, 27, 23, 0, -7, -11, 43, -74, 36, -12, 2, 5, -8, 6, -33, 11, -16,
    -14, -5, -7, -3, 17, -34, 27, -16, 11, -9, 15, 33, -31, 8, -16, 7, -6, -7, 63, -55,
    -17, 11, -1, 20, -46, 34, -30, 6, 9, 19, 28, -9, 5, -24, -8, -23, -2, 31, -19, -16,
    -5, -15, -18, 0, 26, 18, 37, -5, -15, -2, 17, 5, -27, 21, -33, 44, 12, -27, -9, 17,
    11, 25, -21, -31, -7, 13, 33, -8, -25, -7, 7, -10, 4, -6, -9, 48, -82, -23, -8, 6,
    11, -23, 3, -3, 49, -29, 25, 31, 4, 14, 16, 9, -4, -18, 10, -26, 3, 5, -44, -9,
    9, -47, -55, 15, 9, 28, 1, 4, -3, 46, 6, -6, -38, -29, -31, -15, -6, 3, 0, 14,
    -6, 8, -54, -50, 33, -5, 1, -14, 33, -48, 26, -4, -5, -3, -5, -3, -5, -28, -22, 77,
    55, -1, 2, 10, 10, -9, -14, -66, -49, 11, -36, -6, -20, 10, -10, 16, 12, 4, -1, -16,
    45, -44, -50, 31, -2, 25, 42, 23, -32, -22, 0, 11, 20, -40, -35, -40, -36, -32, -26, -21,
    -13, 52, -22, 6, -24, -20, 17, -5, -8, 36, -25, -11, 21, -26, 6, 34, -8, 7, 20, -3,
    5, -25, -8, 18, -5, -9, -4, 1, -9, 20, 20, 39, 48, -24, 9, 5, -65, 22, 29, 4,
    3, -43, -11, 32, -6, 9, 19, -27, -10, -47, -14, 24, 10, -7, -36, -7, -1, -4, -5, -5,
    16, 53, 25, -26, -29, -4, -12, 45, -58, -34, 33, -5, 2, -1, 27, -48, 31, -15, 22, -5,
    4, 7, 7, -25, -3, 11, -22, 16, -12, 8, -3, 7, -11, 45, 14, -73, -19, 56, -46, 24,
    -20, 28, -12, -2, -1, -36, -3, -33, 19, -6, 7, 2, -15, 5, -31, -45, 8, 35, 13, 20,
    0, -9, 48, -13, -43, -3, -13, 2, -5, 72, -68, -27, 2, 1, -2, -7, 5, 36, 33, -40,
    -12, -4, -5, 23, 19
];
Codebook_Constants.gain_cdbk_nb = [
    -32, -32, -32, -28, -67, -5, -42, -6, -32, -57, -10, -54, -16, 27, -41, 19, -19, -40, -45,
    24, -21, -8, -14, -18, 1, 14, -58, -18, -88, -39, -38, 21, -18, -19, 20, -43, 10, 17, -48,
    -52, -58, -13, -44, -1, -11, -12, -11, -34, 14, 0, -46, -37, -35, -34, -25, 44, -30, 6, -4,
    -63, -31, 43, -41, -23, 30, -43, -43, 26, -14, -33, 1, -13, -13, 18, -37, -46, -73, -45, -36,
    24, -25, -36, -11, -20, -25, 12, -18, -36, -69, -59, -45, 6, 8, -22, -14, -24, -1, 13, -44,
    -39, -48, -26, -32, 31, -37, -33, 15, -46, -24, 30, -36, -41, 31, -23, -50, 22, -4, -22, 2,
    -21, -17, 30, -34, -7, -60, -28, -38, 42, -28, -44, -11, 21, -16, 8, -44, -39, -55, -43, -11,
    -35, 26, -9, 0, -34, -8, 121, -81, 7, -16, -22, -37, 33, -31, -27, -7, -36, -34, 70, -57,
    -37, -11, -48, -40, 17, -1, -33, 6, -6, -9, 0, -20, -21, 69, -33, -29, 33, -31, -55, 12,
    -1, -33, 27, -22, -50, -33, -47, -50, 54, 51, -1, -5, -44, -4, 22, -40, -39, -66, -25, -33,
    1, -26, -24, -23, -25, -11, 21, -45, -25, -45, -19, -43, 105, -16, 5, -21, 1, -16, 11, -33,
    -13, -99, -4, -37, 33, -15, -25, 37, -63, -36, 24, -31, -53, -56, -38, -41, -4, 4, -33, 13,
    -30, 49, 52, -94, -5, -30, -15, 1, 38, -40, -23, 12, -36, -17, 40, -47, -37, -41, -39, -49,
    34, 0, -18, -7, -4, -16, 17, -27, 30, 5, -62, 4, 48, -68, -43, 11, -11, -18, 19, -15,
    -23, -62, -39, -42, 10, -2, -21, -13, -13, -9, 13, -47, -23, -62, -24, -44, 60, -21, -18, -3,
    -52, -22, 22, -36, -75, 57, 16, -19, 3, 10, -29, 23, -38, -5, -62, -51, -51, 40, -18, -42,
    13, -24, -34, 14, -20, -56, -75, -26, -26, 32, 15, -26, 17, -29, -7, 28, -52, -12, -30, 5,
    -5, -48, -5, 2, 2, -43, 21, 16, 16, -25, -45, -32, -43, 18, -10, 9, 0, -1, -1, 7,
    -30, 19, -48, -4, -28, 25, -29, -22, 0, -31, -32, 17, -10, -64, -41, -62, -52, 15, 16, -30,
    -22, -32, -7, 9, -38
];
Codebook_Constants.gain_cdbk_lbr = [
    -32, -32, -32, -31, -58, -16, -41, -24, -43, -56, -22, -55, -13, 33, -41, -4, -39, -9, -41,
    15, -12, -8, -15, -12, 1, 2, -44, -22, -66, -42, -38, 28, -23, -21, 14, -37, 0, 21, -50,
    -53, -71, -27, -37, -1, -19, -19, -5, -28, 6, 65, -44, -33, -48, -33, -40, 57, -14, -17, 4,
    -45, -31, 38, -33, -23, 28, -40, -43, 29, -12, -34, 13, -23, -16, 15, -27, -14, -82, -15, -31,
    25, -32, -21, 5, -5, -47, -63, -51, -46, 12, 3, -28, -17, -29, -10, 14, -40
];
Codebook_Constants.hexc_10_32_table = [
    -3, -2, -1, 0, -4, 5, 35, -40, -9, 13, -44, 5, -27, -1, -7, 6, -11, 7, -8,
    7, 19, -14, 15, -4, 9, -10, 10, -8, 10, -9, -1, 1, 0, 0, 2, 5, -18, 22, -53,
    50, 1, -23, 50, -36, 15, 3, -13, 14, -10, 6, 1, 5, -3, 4, -2, 5, -32, 25, 5,
    -2, -1, -4, 1, 11, -29, 26, -6, -15, 30, -18, 0, 15, -17, 40, -41, 3, 9, -2, -2,
    3, -3, -1, -5, 2, 21, -6, -16, -21, 23, 2, 60, 15, 16, -16, -9, 14, 9, -1, 7,
    -9, 0, 1, 1, 0, -1, -6, 17, -28, 54, -45, -1, 1, -1, -6, -6, 2, 11, 26, -29,
    -2, 46, -21, 34, 12, -23, 32, -23, 16, -10, 3, 66, 19, -20, 24, 7, 11, -3, 0, -3,
    -1, -50, -46, 2, -18, -3, 4, -1, -2, 3, -3, -19, 41, -36, 9, 11, -24, 21, -16, 9,
    -3, -25, -3, 10, 18, -9, -2, -5, -1, -5, 6, -4, -3, 2, -26, 21, -19, 35, -15, 7,
    -13, 17, -19, 39, -43, 48, -31, 16, -9, 7, -2, -5, 3, -4, 9, -19, 27, -55, 63, -35,
    10, 26, -44, -2, 9, 4, 1, -6, 8, -9, 5, -8, -1, -3, -16, 45, -42, 5, 15, -16,
    10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -16, 24, -55, 47, -38, 27, -19, 7, -3,
    1, 16, 27, 20, -19, 18, 5, -7, 1, -5, 2, -6, 8, -22, 0, -3, -3, 8, -1, 7,
    -8, 1, -3, 5, 0, 17, -48, 58, -52, 29, -7, -2, 3, -10, 6, -26, 58, -31, 1, -6,
    3, 93, -29, 39, 3, 17, 5, 6, -1, -1, -1, 27, 13, 10, 19, -7, -34, 12, 10, -4,
    9, -76, 9, 8, -28, -2, -11, 2, -1, 3, 1, -83, 38, -39, 4, -16, -6, -2, -5, 5,
    -2
];
Codebook_Constants.hexc_table = [
    -24, 21, -20, 5, -5, -7, 14, -10, 2, -27, 16, -20, 0, -32, 26, 19, 8, -11, -41,
    31, 28, -27, -32, 34, 42, 34, -17, 22, -10, 13, -29, 18, -12, -26, -24, 11, 22, 5, -5,
    -5, 54, -68, -43, 57, -25, 24, 4, 4, 26, -8, -12, -17, 54, 30, -45, 1, 10, -15, 18,
    -41, 11, 68, -67, 37, -16, -24, -16, 38, -22, 6, -29, 30, 66, -27, 5, 7, -16, 13, 2,
    -12, -7, -3, -20, 36, 4, -28, 9, 3, 32, 48, 26, 39, 3, 0, 7, -21, -13, 5, -82,
    -7, 73, -20, 34, -9, -5, 1, -1, 10, -5, -10, -1, 9, 1, -9, 10, 0, -14, 11, -1,
    -2, -1, 11, 20, 96, -81, -22, -12, -9, -58, 9, 24, -30, 26, -35, 27, -12, 13, -18, 56,
    -59, 15, -7, 23, -15, -1, 6, -25, 14, -22, -20, 47, -11, 16, 2, 38, -23, -19, -30, -9,
    40, -11, 5, 4, -6, 8, 26, -21, -11, 127, 4, 1, 6, -9, 2, -7, -2, -3, 7, -5,
    10, -19, 7, -106, 91, -3, 9, -4, 21, -8, 26, -80, 8, 1, -2, -10, -17, -17, -27, 32,
    71, 6, -29, 11, -23, 54, -38, 29, -22, 39, 87, -31, -12, -20, 3, -2, -2, 2, 20, 0,
    -1, -35, 27, 9, -6, -12, 3, -12, -6, 13, 1, 14, -22, -59, -15, -17, -25, 13, -7, 7,
    3, 0, 1, -7, 6, -3, 61, -37, -23, -23, -29, 38, -31, 27, 1, -8, 2, -27, 23, -26,
    36, -34, 5, 24, -24, -6, 7, 3, -59, 78, -62, 44, -16, 1, 6, 0, 17, 8, 45, 0,
    -110, 6, 14, -2, 32, -77, -56, 62, -3, 3, -13, 4, -16, 102, -15, -36, -1, 9, -113, 6,
    23, 0, 9, 9, 5, -8, -1, -14, 5, -12, 121, -53, -27, -8, -9, 22, -13, 3, 2, -3,
    1, -2, -71, 95, 38, -19, 15, -16, -5, 71, 10, 2, -32, -13, -5, 15, -1, -2, -14, -85,
    30, 29, 6, 3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 2, -65, -56, -9, 18, 18, 23,
    -14, -2, 0, 12, -29, 26, -12, 1, 2, -12, -64, 90, -6, 4, 1, 5, -5, -110, -3, -31,
    22, -29, 9, 0, 8, -40, -5, 21, -5, -5, 13, 10, -18, 40, 1, 35, -20, 30, -28, 11,
    -6, 19, 7, 14, 18, -64, 9, -6, 16, 51, 68, 8, 16, 12, -8, 0, -9, 20, -22, 25,
    7, -4, -13, 41, -35, 93, -18, -54, 11, -1, 1, -9, 4, -66, 66, -31, 20, -22, 25, -23,
    11, 10, 9, 19, 15, 11, -5, -31, -10, -23, -28, -6, -6, -3, -4, 5, 3, -28, 22, -11,
    -42, 25, -25, -16, 41, 34, 47, -6, 2, 42, -19, -22, 5, -39, 32, 6, -35, 22, 17, -30,
    8, -26, -11, -11, 3, -12, 33, 33, -37, 21, -1, 6, -4, 3, 0, -5, 5, 12, -12, 57,
    27, -61, -3, 20, -17, 2, 0, 4, 0, -2, -33, -58, 81, -23, 39, -10, -5, 2, 6, -7,
    5, 4, -3, -2, -13, -23, -72, 107, 15, -5, 0, -7, -3, -6, 5, -4, 15, 47, 12, -31,
    25, -16, 8, 22, -25, -62, -56, -18, 14, 28, 12, 2, -11, 74, -66, 41, -20, -7, 16, -20,
    16, -8, 0, -16, 4, -19, 92, 12, -59, -14, -39, 49, -25, -16, 23, -27, 19, -3, -33, 19,
    85, -29, 6, -7, -10, 16, -7, -12, 1, -6, 2, 4, -2, 64, 10, -25, 41, -2, -31, 15,
    0, 110, 50, 69, 35, 28, 19, -10, 2, -43, -49, -56, -15, -16, 10, 3, 12, -1, -8, 1,
    26, -12, -1, 7, -11, -27, 41, 25, 1, -11, -18, 22, -7, -1, -47, -8, 23, -3, -17, -7,
    18, -125, 59, -5, 3, 18, 1, 2, 3, 27, -35, 65, -53, 50, -46, 37, -21, -28, 7, 14,
    -37, -5, -5, 12, 5, -8, 78, -19, 21, -6, -16, 8, -7, 5, 2, 7, 2, 10, -6, 12,
    -60, 44, 11, -36, -32, 31, 0, 2, -2, 2, 1, -3, 7, -10, 17, -21, 10, 6, -2, 19,
    -2, 59, -38, -86, 38, 8, -41, -30, -45, -33, 7, 15, 28, 29, -7, 24, -40, 7, 7, 5,
    -2, 9, 24, -23, -18, 6, -29, 30, 2, 28, 49, -11, -46, 10, 43, -13, -9, -1, -3, -7,
    -7, -17, -6, 97, -33, -21, 3, 5, 1, 12, -43, -8, 28, 7, -43, -7, 17, -20, 19, -1,
    2, -13, 9, 54, 34, 9, -28, -11, -9, -17, 110, -59, 44, -26, 0, 3, -12, -47, 73, -34,
    -43, 38, -33, 16, -5, -46, -4, -6, -2, -25, 19, -29, 28, -13, 5, 14, 27, -40, -43, 4,
    32, -13, -2, -35, -4, 112, -42, 9, -12, 37, -28, 17, 14, -19, 35, -39, 23, 3, -14, -1,
    -57, -5, 94, -9, 3, -39, 5, 30, -10, -32, 42, -13, -14, -97, -63, 30, -9, 1, -7, 12,
    5, 20, 17, -9, -36, -30, 25, 47, -9, -15, 12, -22, 98, -8, -50, 15, -27, 21, -16, -11,
    2, 12, -10, 10, -3, 33, 36, -96, 0, -17, 31, -9, 9, 3, -20, 13, -11, 8, -4, 10,
    -10, 9, 1, 112, -70, -27, 5, -21, 2, -57, -3, -29, 10, 19, -21, 21, -10, -66, -3, 91,
    -35, 30, -12, 0, -7, 59, -28, 26, 2, 14, -18, 1, 1, 11, 17, 20, -54, -59, 27, 4,
    29, 32, 5, 19, 12, -4, 1, 7, -10, 5, -2, 10, 0, 23, -5, 28, -104, 46, 11, 16,
    3, 29, 1, -8, -14, 1, 7, -50, 88, -62, 26, 8, -17, -14, 50, 0, 32, -12, -3, -27,
    18, -8, -5, 8, 3, -20, -11, 37, -12, 9, 33, 46, -101, -1, -4, 1, 6, -1, 28, -42,
    -15, 16, 5, -1, -2, -55, 85, 38, -9, -4, 11, -2, -9, -6, 3, -20, -10, -77, 89, 24,
    -3, -104, -57, -26, -31, -20, -6, -9, 14, 20, -23, 46, -15, -31, 28, 1, -15, -2, 6, -2,
    31, 45, -76, 23, -25
];
Codebook_Constants.high_lsp_cdbk = [
    39, 12, -14, -20, -29, -61, -67, -76, -32, -71, -67, 68, 77, 46, 34, 5, -13, -48, -46,
    -72, -81, -84, -60, -58, -40, -28, 82, 93, 68, 45, 29, 3, -19, -47, -28, -43, -35, -30, -8,
    -13, -39, -91, -91, -123, -96, 10, 10, -6, -18, -55, -60, -91, -56, -36, -27, -16, -48, -75, 40,
    28, -10, -28, 35, 9, 37, 19, 1, -20, -31, -41, -18, -25, -35, -68, -80, 45, 27, -1, 47,
    13, 0, -29, -35, -57, -50, -79, -73, -38, -19, 5, 35, 14, -10, -23, 16, -8, 5, -24, -40,
    -62, -23, -27, -22, -16, -18, -46, -72, -77, 43, 21, 33, 1, -80, -70, -70, -64, -56, -52, -39,
    -33, -31, -38, -19, -19, -15, 32, 33, -2, 7, -15, -15, -24, -23, -33, -41, -56, -24, -57, 5,
    89, 64, 41, 27, 5, -9, -47, -60, -97, -97, -124, -20, -9, -44, -73, 31, 29, -4, 64, 48,
    7, -35, -57, 0, -3, -26, -47, -3, -6, -40, -76, -79, -48, 12, 81, 55, 10, 9, -24, -43,
    -73, -57, -69, 16, 5, -28, -53, 18, 29, 20, 0, -4, -11, 6, -13, 23, 7, -17, -35, -37,
    -37, -30, -68, -63, 6, 24, -9, -14, 3, 21, -13, -27, -57, -49, -80, -24, -41, -5, -16, -5,
    1, 45, 25, 12, -7, 3, -15, -6, -16, -15, -8, 6, -13, -42, -81, -80, -87, 14, 1, -10,
    -3, -43, -69, -46, -24, -28, -29, 36, 6, -43, -56, -12, 12, 54, 79, 43, 9, 54, 22, 2,
    8, -12, -43, -46, -52, -38, -69, -89, -5, 75, 38, 33, 5, -13, -53, -62, -87, -89, -113, -99,
    -55, -34, -37, 62, 55, 33, 16, 21, -2, -17, -46, -29, -38, -38, -48, -39, -42, -36, -75, -72,
    -88, -48, -30, 21, 2, -15, -57, -64, -98, -84, -76, 25, 1, -46, -80, -12, 18, -7, 3, 34,
    6, 38, 31, 23, 4, -1, 20, 14, -15, -43, -78, -91, -24, 14, -3, 54, 16, 0, -27, -28,
    -44, -56, -83, -92, -89, -3, 34, 56, 41, 36, 22, 20, -8, -7, -35, -42, -62, -49, 3, 12,
    -10, -50, -87, -96, -66, 92, 70, 38, 9, -70, -71, -62, -42, -39, -43, -11, -7, -50, -79, -58,
    -50, -31, 32, 31, -6, -4, -25, 7, -17, -38, -70, -58, -27, -43, -83, -28, 59, 36, 20, 31,
    2, -27, -71, -80, -109, -98, -75, -33, -32, -31, -2, 33, 15, -6, 43, 33, -5, 0, -22, -10,
    -27, -34, -49, -11, -20, -41, -91, -100, -121, -39, 57, 41, 10, -19, -50, -38, -59, -60, -70, -18,
    -20, -8, -31, -8, -15, 1, -14, -26, -25, 33, 21, 32, 17, 1, -19, -19, -26, -58, -81, -35,
    -22, 45, 30, 11, -11, 3, -26, -48, -87, -67, -83, -58, 3, -1, -26, -20, 44, 10, 25, 39,
    5, -9, -35, -27, -38, 7, 10, 4, -9, -42, -85, -102, -127, 52, 44, 28, 10, -47, -61, -40,
    -39, -17, -1, -10, -33, -42, -74, -48, 21, -4, 70, 52, 10
];
Codebook_Constants.high_lsp_cdbk2 = [
    -36, -62, 6, -9, -10, -14, -56, 23, 1, -26, 23, -48, -17, 12, 8, -7, 23, 29, -36,
    -28, -6, -29, -17, -5, 40, 23, 10, 10, -46, -13, 36, 6, 4, -30, -29, 62, 32, -32, -1,
    22, -14, 1, -4, -22, -45, 2, 54, 4, -30, -57, -59, -12, 27, -3, -31, 8, -9, 5, 10,
    -14, 32, 66, 19, 9, 2, -25, -37, 23, -15, 18, -38, -31, 5, -9, -21, 15, 0, 22, 62,
    30, 15, -12, -14, -46, 77, 21, 33, 3, 34, 29, -19, 50, 2, 11, 9, -38, -12, -37, 62,
    1, -15, 54, 32, 6, 2, -24, 20, 35, -21, 2, 19, 24, -13, 55, 4, 9, 39, -19, 30,
    -1, -21, 73, 54, 33, 8, 18, 3, 15, 6, -19, -47, 6, -3, -48, -50, 1, 26, 20, 8,
    -23, -50, 65, -14, -55, -17, -31, -37, -28, 53, -1, -17, -53, 1, 57, 11, -8, -25, -30, -37,
    64, 5, -52, -45, 15, 23, 31, 15, 14, -25, 24, 33, -2, -44, -56, -18, 6, -21, -43, 4,
    -12, 17, -37, 20, -10, 34, 15, 2, 15, 55, 21, -11, -31, -6, 46, 25, 16, -9, -25, -8,
    -62, 28, 17, 20, -32, -29, 26, 30, 25, -19, 2, -16, -17, 26, -51, 2, 50, 42, 19, -66,
    23, 29, -2, 3, 19, -19, -37, 32, 15, 6, 30, -34, 13, 11, -5, 40, 31, 10, -42, 4,
    -9, 26, -9, -70, 17, -2, -23, 20, -22, -55, 51, -24, -31, 22, -22, 15, -13, 3, -10, -28,
    -16, 56, 4, -63, 11, -18, -15, -18, -38, -35, 16, -7, 34, -1, -21, -49, -47, 9, -37, 7,
    8, 69, 55, 20, 6, -33, -45, -10, -9, 6, -9, 12, 71, 15, -3, -42, -7, -24, 32, -35,
    -2, -42, -17, -5, 0, -2, -33, -54, 13, -12, -34, 47, 23, 19, 55, 7, -8, 74, 31, 14,
    16, -23, -26, 19, 12, -18, -49, -28, -31, -20, 2, -14, -20, -47, 78, 40, 13, -23, -11, 21,
    -6, 18, 1, 47, 5, 38, 35, 32, 46, 22, 8, 13, 16, -14, 18, 51, 19, 40, 39, 11,
    -26, -1, -17, 47, 2, -53, -15, 31, -22, 38, 21, -15, -16, 5, -33, 53, 15, -38, 86, 11,
    -3, -24, 49, 13, -4, -11, -18, 28, 20, -12, -27, -26, 35, -25, -35, -3, -20, -61, 30, 10,
    -55, -12, -22, -52, -54, -14, 19, -32, -12, 45, 15, -8, -48, -9, 11, -32, 8, -16, -34, -13,
    51, 18, 38, -2, -32, -17, 22, -2, -18, -28, -70, 59, 27, -28, -19, -10, -20, -9, -9, -8,
    -21, 21, -8, 35, -2, 45, -3, -9, 12, 0, 30, 7, -39, 43, 27, -38, -91, 30, 26, 19,
    -55, -4, 63, 14, -17, 13, 9, 13, 2, 7, 4, 6, 61, 72, -1, -17, 29, -1, -22, -17,
    8, -28, -37, 63, 44, 41, 3, 2, 14, 9, -6, 75, -8, -7, -12, -15, -12, 13, 9, -4,
    30, -22, -65, 15, 0, -45, 4, -4, 1, 5, 22, 11, 23
];
Codebook_Constants.NB_CDBK_SIZE = 64;
Codebook_Constants.NB_CDBK_SIZE_LOW1 = 64;
Codebook_Constants.NB_CDBK_SIZE_LOW2 = 64;
Codebook_Constants.NB_CDBK_SIZE_HIGH1 = 64;
Codebook_Constants.NB_CDBK_SIZE_HIGH2 = 64;
Codebook_Constants.cdbk_nb = [
    30, 19, 38, 34, 40, 32, 46, 43, 58, 43, 5, -18, -25, -40, -33, -55, -52, 20, 34,
    28, -20, -63, -97, -92, 61, 53, 47, 49, 53, 75, -14, -53, -77, -79, 0, -3, -5, 19, 22,
    26, -9, -53, -55, 66, 90, 72, 85, 68, 74, 52, -4, -41, -58, -31, -18, -31, 27, 32, 30,
    18, 24, 3, 8, 5, -12, -3, 26, 28, 74, 63, -2, -39, -67, -77, -106, -74, 59, 59, 73,
    65, 44, 40, 71, 72, 82, 83, 98, 88, 89, 60, -6, -31, -47, -48, -13, -39, -9, 7, 2,
    79, -1, -39, -60, -17, 87, 81, 65, 50, 45, 19, -21, -67, -91, -87, -41, -50, 7, 18, 39,
    74, 10, -31, -28, 39, 24, 13, 23, 5, 56, 45, 29, 10, -5, -13, -11, -35, -18, -8, -10,
    -8, -25, -71, -77, -21, 2, 16, 50, 63, 87, 87, 5, -32, -40, -51, -68, 0, 12, 6, 54,
    34, 5, -12, 32, 52, 68, 64, 69, 59, 65, 45, 14, -16, -31, -40, -65, -67, 41, 49, 47,
    37, -11, -52, -75, -84, -4, 57, 48, 42, 42, 33, -11, -51, -68, -6, 13, 0, 8, -8, 26,
    32, -23, -53, 0, 36, 56, 76, 97, 105, 111, 97, -1, -28, -39, -40, -43, -54, -44, -40, -18,
    35, 16, -20, -19, -28, -42, 29, 47, 38, 74, 45, 3, -29, -48, -62, -80, -104, -33, 56, 59,
    59, 10, 17, 46, 72, 84, 101, 117, 123, 123, 106, -7, -33, -49, -51, -70, -67, -27, -31, 70,
    67, -16, -62, -85, -20, 82, 71, 86, 80, 85, 74, -19, -58, -75, -45, -29, -33, -18, -25, 45,
    57, -12, -42, -5, 12, 28, 36, 52, 64, 81, 82, 13, -9, -27, -28, 22, 3, 2, 22, 26,
    6, -6, -44, -51, 2, 15, 10, 48, 43, 49, 34, -19, -62, -84, -89, -102, -24, 8, 17, 61,
    68, 39, 24, 23, 19, 16, -5, 12, 15, 27, 15, -8, -44, -49, -60, -18, -32, -28, 52, 54,
    62, -8, -48, -77, -70, 66, 101, 83, 63, 61, 37, -12, -50, -75, -64, 33, 17, 13, 25, 15,
    77, 1, -42, -29, 72, 64, 46, 49, 31, 61, 44, -8, -47, -54, -46, -30, 19, 20, -1, -16,
    0, 16, -12, -18, -9, -26, -27, -10, -22, 53, 45, -10, -47, -75, -82, -105, -109, 8, 25, 49,
    77, 50, 65, 114, 117, 124, 118, 115, 96, 90, 61, -9, -45, -63, -60, -75, -57, 8, 11, 20,
    29, 0, -35, -49, -43, 40, 47, 35, 40, 55, 38, -24, -76, -103, -112, -27, 3, 23, 34, 52,
    75, 8, -29, -43, 12, 63, 38, 35, 29, 24, 8, 25, 11, 1, -15, -18, -43, -7, 37, 40,
    21, -20, -56, -19, -19, -4, -2, 11, 29, 51, 63, -2, -44, -62, -75, -89, 30, 57, 51, 74,
    51, 50, 46, 68, 64, 65, 52, 63, 55, 65, 43, 18, -9, -26, -35, -55, -69, 3, 6, 8,
    17, -15, -61, -86, -97, 1, 86, 93, 74, 78, 67, -1, -38, -66, -48, 48, 39, 29, 25, 17,
    -1, 13, 13, 29, 39, 50, 51, 69, 82, 97, 98, -2, -36, -46, -27, -16, -30, -13, -4, -7,
    -4, 25, -5, -11, -6, -25, -21, 33, 12, 31, 29, -8, -38, -52, -63, -68, -89, -33, -1, 10,
    74, -2, -15, 59, 91, 105, 105, 101, 87, 84, 62, -7, -33, -50, -35, -54, -47, 25, 17, 82,
    81, -13, -56, -83, 21, 58, 31, 42, 25, 72, 65, -24, -66, -91, -56, 9, -2, 21, 10, 69,
    75, 2, -24, 11, 22, 25, 28, 38, 34, 48, 33, 7, -29, -26, 17, 15, -1, 14, 0, -2,
    0, -6, -41, -67, 6, -2, -9, 19, 2, 85, 74, -22, -67, -84, -71, -50, 3, 11, -9, 2,
    62
];
Codebook_Constants.cdbk_nb_low1 = [
    -34, -52, -15, 45, 2, 23, 21, 52, 24, -33, -9, -1, 9, -44, -41, -13, -17, 44, 22,
    -17, -6, -4, -1, 22, 38, 26, 16, 2, 50, 27, -35, -34, -9, -41, 6, 0, -16, -34, 51,
    8, -14, -31, -49, 15, -33, 45, 49, 33, -11, -37, -62, -54, 45, 11, -5, -72, 11, -1, -12,
    -11, 24, 27, -11, -43, 46, 43, 33, -12, -9, -1, 1, -4, -23, -57, -71, 11, 8, 16, 17,
    -8, -20, -31, -41, 53, 48, -16, 3, 65, -24, -8, -23, -32, -37, -32, -49, -10, -17, 6, 38,
    5, -9, -17, -46, 8, 52, 3, 6, 45, 40, 39, -7, -6, -34, -74, 31, 8, 1, -16, 43,
    68, -11, -19, -31, 4, 6, 0, -6, -17, -16, -38, -16, -30, 2, 9, -39, -16, -1, 43, -10,
    48, 3, 3, -16, -31, -3, 62, 68, 43, 13, 3, -10, 8, 20, -56, 12, 12, -2, -18, 22,
    -15, -40, -36, 1, 7, 41, 0, 1, 46, -6, -62, -4, -12, -2, -11, -83, -13, -2, 91, 33,
    -10, 0, 4, -11, -16, 79, 32, 37, 14, 9, 51, -21, -28, -56, -34, 0, 21, 9, -26, 11,
    28, -42, -54, -23, -2, -15, 31, 30, 8, -39, -66, -39, -36, 31, -28, -40, -46, 35, 40, 22,
    24, 33, 48, 23, -34, 14, 40, 32, 17, 27, -3, 25, 26, -13, -61, -17, 11, 4, 31, 60,
    -6, -26, -41, -64, 13, 16, -26, 54, 31, -11, -23, -9, -11, -34, -71, -21, -34, -35, 55, 50,
    29, -22, -27, -50, -38, 57, 33, 42, 57, 48, 26, 11, 0, -49, -31, 26, -4, -14, 5, 78,
    37, 17, 0, -49, -12, -23, 26, 14, 2, 2, -43, -17, -12, 10, -8, -4, 8, 18, 12, -6,
    20, -12, -6, -13, -25, 34, 15, 40, 49, 7, 8, 13, 20, 20, -19, -22, -2, -8, 2, 51,
    -51
];
Codebook_Constants.cdbk_nb_low2 = [
    -6, 53, -21, -24, 4, 26, 17, -4, -37, 25, 17, -36, -13, 31, 3, -6, 27, 15, -10,
    31, 28, 26, -10, -10, -40, 16, -7, 15, 13, 41, -9, 0, -4, 50, -6, -7, 14, 38, 22,
    0, -48, 2, 1, -13, -19, 32, -3, -60, 11, -17, -1, -24, -34, -1, 35, -5, -27, 28, 44,
    13, 25, 15, 42, -11, 15, 51, 35, -36, 20, 8, -4, -12, -29, 19, -47, 49, -15, -4, 16,
    -29, -39, 14, -30, 4, 25, -9, -5, -51, -14, -3, -40, -32, 38, 5, -9, -8, -4, -1, -22,
    71, -3, 14, 26, -18, -22, 24, -41, -25, -24, 6, 23, 19, -10, 39, -26, -27, 65, 45, 2,
    -7, -26, -8, 22, -12, 16, 15, 16, -35, -5, 33, -21, -8, 0, 23, 33, 34, 6, 21, 36,
    6, -7, -22, 8, -37, -14, 31, 38, 11, -4, -3, -39, -32, -8, 32, -23, -6, -12, 16, 20,
    -28, -4, 23, 13, -52, -1, 22, 6, -33, -40, -6, 4, -62, 13, 5, -26, 35, 39, 11, 2,
    57, -11, 9, -20, -28, -33, 52, -5, -6, -2, 22, -14, -16, -48, 35, 1, -58, 20, 13, 33,
    -1, -74, 56, -18, -22, -31, 12, 6, -14, 4, -2, -9, -47, 10, -3, 29, -17, -5, 61, 14,
    47, -12, 2, 72, -39, -17, 92, 64, -53, -51, -15, -30, -38, -41, -29, -28, 27, 9, 36, 9,
    -35, -42, 81, -21, 20, 25, -16, -5, -17, -35, 21, 15, -28, 48, 2, -2, 9, -19, 29, -40,
    30, -18, -18, 18, -16, -57, 15, -20, -12, -15, -37, -15, 33, -39, 21, -22, -13, 35, 11, 13,
    -38, -63, 29, 23, -27, 32, 18, 3, -26, 42, 33, -64, -66, -17, 16, 56, 2, 36, 3, 31,
    21, -41, -39, 8, -57, 14, 37, -2, 19, -36, -19, -23, -29, -16, 1, -3, -8, -10, 31, 64,
    -65
];
Codebook_Constants.cdbk_nb_high1 = [
    -26, -8, 29, 21, 4, 19, -39, 33, -7, -36, 56, 54, 48, 40, 29, -4, -24, -42, -66,
    -43, -60, 19, -2, 37, 41, -10, -37, -60, -64, 18, -22, 77, 73, 40, 25, 4, 19, -19, -66,
    -2, 11, 5, 21, 14, 26, -25, -86, -4, 18, 1, 26, -37, 10, 37, -1, 24, -12, -59, -11,
    20, -6, 34, -16, -16, 42, 19, -28, -51, 53, 32, 4, 10, 62, 21, -12, -34, 27, 4, -48,
    -48, -50, -49, 31, -7, -21, -42, -25, -4, -43, -22, 59, 2, 27, 12, -9, -6, -16, -8, -32,
    -58, -16, -29, -5, 41, 23, -30, -33, -46, -13, -10, -38, 52, 52, 1, -17, -9, 10, 26, -25,
    -6, 33, -20, 53, 55, 25, -32, -5, -42, 23, 21, 66, 5, -28, 20, 9, 75, 29, -7, -42,
    -39, 15, 3, -23, 21, 6, 11, 1, -29, 14, 63, 10, 54, 26, -24, -51, -49, 7, -23, -51,
    15, -66, 1, 60, 25, 10, 0, -30, -4, -15, 17, 19, 59, 40, 4, -5, 33, 6, -22, -58,
    -70, -5, 23, -6, 60, 44, -29, -16, -47, -29, 52, -19, 50, 28, 16, 35, 31, 36, 0, -21,
    6, 21, 27, 22, 42, 7, -66, -40, -8, 7, 19, 46, 0, -4, 60, 36, 45, -7, -29, -6,
    -32, -39, 2, 6, -9, 33, 20, -51, -34, 18, -6, 19, 6, 11, 5, -19, -29, -2, 42, -11,
    -45, -21, -55, 57, 37, 2, -14, -67, -16, -27, -38, 69, 48, 19, 2, -17, 20, -20, -16, -34,
    -17, -25, -61, 10, 73, 45, 16, -40, -64, -17, -29, -22, 56, 17, -39, 8, -11, 8, -25, -18,
    -13, -19, 8, 54, 57, 36, -17, -26, -4, 6, -21, 40, 42, -4, 20, 31, 53, 10, -34, -53,
    31, -17, 35, 0, 15, -6, -20, -63, -73, 22, 25, 29, 17, 8, -29, -39, -69, 18, 15, -15,
    -5
];
Codebook_Constants.cdbk_nb_high2 = [
    11, 47, 16, -9, -46, -32, 26, -64, 34, -5, 38, -7, 47, 20, 2, -73, -99, -3, -45,
    20, 70, -52, 15, -6, -7, -82, 31, 21, 47, 51, 39, -3, 9, 0, -41, -7, -15, -54, 2,
    0, 27, -31, 9, -45, -22, -38, -24, -24, 8, -33, 23, 5, 50, -36, -17, -18, -51, -2, 13,
    19, 43, 12, -15, -12, 61, 38, 38, 7, 13, 0, 6, -1, 3, 62, 9, 27, 22, -33, 38,
    -35, -9, 30, -43, -9, -32, -1, 4, -4, 1, -5, -11, -8, 38, 31, 11, -10, -42, -21, -37,
    1, 43, 15, -13, -35, -19, -18, 15, 23, -26, 59, 1, -21, 53, 8, -41, -50, -14, -28, 4,
    21, 25, -28, -40, 5, -40, -41, 4, 51, -33, -8, -8, 1, 17, -60, 12, 25, -41, 17, 34,
    43, 19, 45, 7, -37, 24, -15, 56, -2, 35, -10, 48, 4, -47, -2, 5, -5, -54, 5, -3,
    -33, -10, 30, -2, -44, -24, -38, 9, -9, 42, 4, 6, -56, 44, -16, 9, -40, -26, 18, -20,
    10, 28, -41, -21, -4, 13, -18, 32, -30, -3, 37, 15, 22, 28, 50, -40, 3, -29, -64, 7,
    51, -19, -11, 17, -27, -40, -64, 24, -12, -7, -27, 3, 37, 48, -1, 2, -9, -38, -34, 46,
    1, 27, -6, 19, -13, 26, 10, 34, 20, 25, 40, 50, -6, -7, 30, 9, -24, 0, -23, 71,
    -61, 22, 58, -34, -4, 2, -49, -33, 25, 30, -8, -6, -16, 77, 2, 38, -8, -35, -6, -30,
    56, 78, 31, 33, -20, 13, -39, 20, 22, 4, 21, -8, 4, -6, 10, -83, -41, 9, -25, -43,
    15, -7, -12, -34, -39, -37, -33, 19, 30, 16, -33, 42, -25, 25, -68, 44, -15, -11, -4, 23,
    50, 14, 4, -39, -43, 20, -30, 60, 9, -20, 7, 16, 19, -33, 37, 29, 16, -35, 7, 38,
    -27
];
Codebook_Constants.h0 = [
    3.596189E-05, -0.0001123515, -0.0001104587, 0.0002790277, 0.0002298438, -0.0005953563, -0.0003823631, 0.00113826, 0.0005308539, -0.001986177, -0.0006243724, 0.003235877, 0.0005743159, -0.004989147, -0.0002584767, 0.007367171, -0.0004857935, -0.01050689, 0.001894714,
    0.01459396, -0.004313674, -0.01994365, 0.00828756, 0.02716055, -0.01485397, -0.03764973, 0.026447, 0.05543245, -0.05095487, -0.09779096, 0.1382363, 0.4600981, 0.4600981, 0.1382363, -0.09779096, -0.05095487, 0.05543245, 0.026447, -0.03764973,
    -0.01485397, 0.02716055, 0.00828756, -0.01994365, -0.004313674, 0.01459396, 0.001894714, -0.01050689, -0.0004857935, 0.007367171, -0.0002584767, -0.004989147, 0.0005743159, 0.003235877, -0.0006243724, -0.001986177, 0.0005308539, 0.00113826, -0.0003823631, -0.0005953563,
    0.0002298438, 0.0002790277, -0.0001104587, -0.0001123515, 3.596189E-05
];
Codebook_Constants.h1 = [
    3.596189E-05, 0.0001123515, -0.0001104587, -0.0002790277, 0.0002298438, 0.0005953563, -0.0003823631, -0.00113826, 0.0005308539, 0.001986177, -0.0006243724, -0.003235877, 0.0005743159, 0.004989147, -0.0002584767, -0.007367171, -0.0004857935, 0.01050689, 0.001894714,
    -0.01459396, -0.004313674, 0.01994365, 0.00828756, -0.02716055, -0.01485397, 0.03764973, 0.026447, -0.05543245, -0.05095487, 0.09779096, 0.1382363, -0.4600981, 0.4600981, -0.1382363, -0.09779096, 0.05095487, 0.05543245, -0.026447, -0.03764973,
    0.01485397, 0.02716055, -0.00828756, -0.01994365, 0.004313674, 0.01459396, -0.001894714, -0.01050689, 0.0004857935, 0.007367171, 0.0002584767, -0.004989147, -0.0005743159, 0.003235877, 0.0006243724, -0.001986177, -0.0005308539, 0.00113826, 0.0003823631, -0.0005953563,
    -0.0002298438, 0.0002790277, 0.0001104587, -0.0001123515, -3.596189E-05
];
class Filters extends NObject {
    constructor() {
        super();
        this.last_pitch = 0;
        this.last_pitch_gain = null;
        this.smooth_gain = 0;
        this.xx = null;
        this.last_pitch_gain = new Array(3);
        this.xx = new Array(1024);
        for (let _ai = 0; _ai < this.xx.length; ++_ai)
            this.xx[_ai] = 0;
        this.last_pitch = 0;
        this.last_pitch_gain[0] = (this.last_pitch_gain[1] = (this.last_pitch_gain[2] = 0));
        this.smooth_gain = 1;
    }
    static Bw_lpc(gamma, lpc_in, lpc_out, order) {
        let num = 1;
        for (let i = 0; i < order + 1; i = i + 1) {
            lpc_out[i] = num * lpc_in[i];
            num = num * gamma;
        }
    }
    static Filter_mem2(x, xs, num, den, NOrY, ordOrYs, memOrN, msOrOrd, mem, ms) {
        if (arguments.length === 8 && (x === null || x instanceof Array) && (xs === null || xs.constructor === Number) && (num === null || num instanceof Array) && (den === null || den instanceof Array) && (NOrY === null || NOrY.constructor === Number) && (ordOrYs === null || ordOrYs.constructor === Number) && (memOrN === null || memOrN instanceof Array) && (msOrOrd === null || msOrOrd.constructor === Number)) {
            Filters.Filter_mem2_0(x, xs, num, den, NOrY, ordOrYs, memOrN, msOrOrd);
            return;
        }
        Filters.Filter_mem2_1(x, xs, num, den, NOrY, ordOrYs, memOrN, msOrOrd, mem, ms);
    }
    static Filter_mem2_0(x, xs, num, den, N, ord, mem, ms) {
        for (let i = 0; i < N; i = i + 1) {
            let num2 = x[xs + i];
            x[xs + i] = num[0] * num2 + mem[ms];
            let num3 = x[xs + i];
            for (let j = 0; j < ord - 1; j = j + 1) {
                mem[ms + j] = mem[ms + j + 1] + num[j + 1] * num2 - den[j + 1] * num3;
            }
            mem[ms + ord - 1] = num[ord] * num2 - den[ord] * num3;
        }
    }
    static Filter_mem2_1(x, xs, num, den, y, ys, N, ord, mem, ms) {
        for (let i = 0; i < N; i = i + 1) {
            let num2 = x[xs + i];
            y[ys + i] = num[0] * num2 + mem[0];
            let num3 = y[ys + i];
            for (let j = 0; j < ord - 1; j = j + 1) {
                mem[ms + j] = mem[ms + j + 1] + num[j + 1] * num2 - den[j + 1] * num3;
            }
            mem[ms + ord - 1] = num[ord] * num2 - den[ord] * num3;
        }
    }
    static Iir_mem2(x, xs, den, y, ys, N, ord, mem) {
        for (let i = 0; i < N; i = i + 1) {
            y[ys + i] = x[xs + i] + mem[0];
            for (let j = 0; j < ord - 1; j = j + 1) {
                mem[j] = mem[j + 1] - den[j + 1] * y[ys + i];
            }
            mem[ord - 1] = -den[ord] * y[ys + i];
        }
    }
    static Fir_mem2(x, xs, num, y, ys, N, ord, mem) {
        for (let i = 0; i < N; i = i + 1) {
            let num2 = x[xs + i];
            y[ys + i] = num[0] * num2 + mem[0];
            for (let j = 0; j < ord - 1; j = j + 1) {
                mem[j] = mem[j + 1] + num[j + 1] * num2;
            }
            mem[ord - 1] = num[ord] * num2;
        }
    }
    static Syn_percep_zero(xx_0, xxs, ak, awk1, awk2, y, N, ord) {
        let array = new Array(ord);
        Filters.Filter_mem2(xx_0, xxs, awk1, ak, y, 0, N, ord, array, 0);
        for (let i = 0; i < ord; i = i + 1) {
            array[i] = 0;
        }
        Filters.Iir_mem2(y, 0, awk2, y, 0, N, ord, array);
    }
    static Residue_percep_zero(xx_0, xxs, ak, awk1, awk2, y, N, ord) {
        let array = new Array(ord);
        Filters.Filter_mem2(xx_0, xxs, ak, awk1, y, 0, N, ord, array, 0);
        for (let i = 0; i < ord; i = i + 1) {
            array[i] = 0;
        }
        Filters.Fir_mem2(y, 0, awk2, y, 0, N, ord, array);
    }
    Fir_mem_up(x, a, y, N, M, mem) {
        for (let i = 0; i < N / 2; i = i + 1) {
            this.xx[2 * i] = x[N / 2 - 1 - i];
        }
        for (let i = 0; i < M - 1; i = i + 2) {
            this.xx[N + i] = mem[i + 1];
        }
        for (let i = 0; i < N; i = i + 4) {
            let num4;
            let num3;
            let num2;
            let num = num2 = (num3 = (num4 = 0));
            let num5 = this.xx[N - 4 - i];
            for (let j = 0; j < M; j = j + 4) {
                let num6 = a[j];
                let num7 = a[j + 1];
                let num8 = this.xx[N - 2 + j - i];
                num2 = num2 + num6 * num8;
                num = num + num7 * num8;
                num3 = num3 + num6 * num5;
                num4 = num4 + num7 * num5;
                num6 = a[j + 2];
                num7 = a[j + 3];
                num5 = this.xx[N + j - i];
                num2 = num2 + num6 * num5;
                num = num + num7 * num5;
                num3 = num3 + num6 * num8;
                num4 = num4 + num7 * num8;
            }
            y[i] = num2;
            y[i + 1] = num;
            y[i + 2] = num3;
            y[i + 3] = num4;
        }
        for (let i = 0; i < M - 1; i = i + 2) {
            mem[i + 1] = this.xx[i];
        }
    }
    Comb_filter(exc, esi, new_exc, nsi, nsf, pitch, pitch_gain, comb_gain) {
        let num = 0;
        let num2 = 0;
        let i;
        for (i = esi; i < esi + nsf; i = i + 1) {
            num = num + exc[i] * exc[i];
        }
        let num3 = 0.5 * Math.abs(pitch_gain[0] + pitch_gain[1] + pitch_gain[2] + this.last_pitch_gain[0] + this.last_pitch_gain[1] + this.last_pitch_gain[2]);
        let flag = num3 > 1.3;
        if (flag) {
            comb_gain = comb_gain * (1.3 / num3);
        }
        let flag2 = num3 < 0.5;
        if (flag2) {
            comb_gain = comb_gain * (2 * num3);
        }
        let num4 = 1 / nsf;
        let num5 = 0;
        i = 0;
        let num6 = esi;
        while (i < nsf) {
            num5 = num5 + num4;
            new_exc[nsi + i] = exc[num6] + comb_gain * num5 * (pitch_gain[0] * exc[num6 - pitch + 1] + pitch_gain[1] * exc[num6 - pitch] + pitch_gain[2] * exc[num6 - pitch - 1]) + comb_gain * (1 - num5) * (this.last_pitch_gain[0] * exc[num6 - this.last_pitch + 1] + this.last_pitch_gain[1] * exc[num6 - this.last_pitch] + this.last_pitch_gain[2] * exc[num6 - this.last_pitch - 1]);
            i = i + 1;
            num6 = num6 + 1;
        }
        this.last_pitch_gain[0] = pitch_gain[0];
        this.last_pitch_gain[1] = pitch_gain[1];
        this.last_pitch_gain[2] = pitch_gain[2];
        this.last_pitch = pitch;
        for (i = nsi; i < nsi + nsf; i = i + 1) {
            num2 = num2 + new_exc[i] * new_exc[i];
        }
        let num7 = Math.sqrt((num / (0.1 + num2)));
        let flag3 = num7 < 0.5;
        if (flag3) {
            num7 = 0.5;
        }
        let flag4 = num7 > 1;
        if (flag4) {
            num7 = 1;
        }
        for (i = nsi; i < nsi + nsf; i = i + 1) {
            this.smooth_gain = 0.96 * this.smooth_gain + 0.04 * num7;
            new_exc[i] *= this.smooth_gain;
        }
    }
    static Qmf_decomp(xx_0, aa, y1, y2, N, M, mem) {
        let array = new Array(M);
        let array2 = new Array(N + M - 1);
        let num = M - 1;
        let num2 = M >> 1;
        let i;
        for (i = 0; i < M; i = i + 1) {
            array[M - i - 1] = aa[i];
        }
        for (i = 0; i < M - 1; i = i + 1) {
            array2[i] = mem[M - i - 2];
        }
        for (i = 0; i < N; i = i + 1) {
            array2[i + M - 1] = xx_0[i];
        }
        i = 0;
        let num3 = 0;
        while (i < N) {
            y1[num3] = 0;
            y2[num3] = 0;
            for (let j = 0; j < num2; j = j + 1) {
                y1[num3] += array[j] * (array2[i + j] + array2[num + i - j]);
                y2[num3] -= array[j] * (array2[i + j] - array2[num + i - j]);
                j = j + 1;
                y1[num3] += array[j] * (array2[i + j] + array2[num + i - j]);
                y2[num3] += array[j] * (array2[i + j] - array2[num + i - j]);
            }
            i = i + 2;
            num3 = num3 + 1;
        }
        for (i = 0; i < M - 1; i = i + 1) {
            mem[i] = xx_0[N - i - 1];
        }
    }
}
class LspQuant extends NObject {
    constructor() {
        super();
    }
    Quant(lsp, qlsp, order, bits) {
        throw new NotSupportedException();
    }
    Unquant(lsp, order, bits) {
        throw new NotSupportedException();
    }
    UnpackPlus(lsp, tab, bits, k, ti, li) {
        let num = bits.Unpack(6);
        for (let i = 0; i < ti; i = i + 1) {
            lsp[i + li] += k * tab[num * ti + i];
        }
    }
    static Lsp_quant(x, xs, cdbk, nbVec, nbDim) {
        let num = 0;
        let num2 = 0;
        let num3 = 0;
        for (let i = 0; i < nbVec; i = i + 1) {
            let num4 = 0;
            for (let j = 0; j < nbDim; j = j + 1) {
                let num5 = x[xs + j] - cdbk[num3++];
                num4 = num4 + num5 * num5;
            }
            let flag = num4 < num || i === 0;
            if (flag) {
                num = num4;
                num2 = i;
            }
        }
        for (let j = 0; j < nbDim; j = j + 1) {
            x[xs + j] -= cdbk[num2 * nbDim + j];
        }
        return num2;
    }
    static Lsp_weight_quant(x, xs, weight, ws, cdbk, nbVec, nbDim) {
        let num = 0;
        let num2 = 0;
        let num3 = 0;
        for (let i = 0; i < nbVec; i = i + 1) {
            let num4 = 0;
            for (let j = 0; j < nbDim; j = j + 1) {
                let num5 = x[xs + j] - cdbk[num3++];
                num4 = num4 + weight[ws + j] * num5 * num5;
            }
            let flag = num4 < num || i === 0;
            if (flag) {
                num = num4;
                num2 = i;
            }
        }
        for (let j = 0; j < nbDim; j = j + 1) {
            x[xs + j] -= cdbk[num2 * nbDim + j];
        }
        return num2;
    }
}
LspQuant.MAX_LSP_SIZE = 20;
class HighLspQuant extends LspQuant {
    Quant(lsp, qlsp, order, bits) {
        let array = new Array(20);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] = lsp[i];
        }
        array[0] = 1 / (qlsp[1] - qlsp[0]);
        array[order - 1] = 1 / (qlsp[order - 1] - qlsp[order - 2]);
        for (let i = 1; i < order - 1; i = i + 1) {
            array[i] = Math.max(1 / (qlsp[i] - qlsp[i - 1]), 1 / (qlsp[i + 1] - qlsp[i]));
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] -= 0.3125 * i + 0.75;
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 256;
        }
        let data = LspQuant.Lsp_quant(qlsp, 0, Codebook_Constants.high_lsp_cdbk, 64, order);
        bits.Pack(data, 6);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 2;
        }
        data = LspQuant.Lsp_weight_quant(qlsp, 0, array, 0, Codebook_Constants.high_lsp_cdbk2, 64, order);
        bits.Pack(data, 6);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 0.0019531;
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] = lsp[i] - qlsp[i];
        }
    }
    Unquant(lsp, order, bits) {
        for (let i = 0; i < order; i = i + 1) {
            lsp[i] = 0.3125 * i + 0.75;
        }
        super.UnpackPlus(lsp, Codebook_Constants.high_lsp_cdbk, bits, 0.0039062, order, 0);
        super.UnpackPlus(lsp, Codebook_Constants.high_lsp_cdbk2, bits, 0.0019531, order, 0);
    }
    constructor() {
        super();
    }
}
class Inband extends NObject {
    constructor(stereo) {
        super();
        this.stereo = null;
        this.stereo = stereo;
    }
    SpeexInbandRequest(bits) {
        switch (bits.Unpack(4)) {
            case 0:
                bits.Advance(1);
                break;
            case 1:
                bits.Advance(1);
                break;
            case 2:
                bits.Advance(4);
                break;
            case 3:
                bits.Advance(4);
                break;
            case 4:
                bits.Advance(4);
                break;
            case 5:
                bits.Advance(4);
                break;
            case 6:
                bits.Advance(4);
                break;
            case 7:
                bits.Advance(4);
                break;
            case 8:
                bits.Advance(8);
                break;
            case 9:
                this.stereo.Init(bits);
                break;
            case 10:
                bits.Advance(16);
                break;
            case 11:
                bits.Advance(16);
                break;
            case 12:
                bits.Advance(32);
                break;
            case 13:
                bits.Advance(32);
                break;
            case 14:
                bits.Advance(64);
                break;
            case 15:
                bits.Advance(64);
                break;
        }
    }
    UserInbandRequest(bits) {
        let num = bits.Unpack(4);
        bits.Advance(5 + 8 * num);
    }
}
class LbrLspQuant extends LspQuant {
    Quant(lsp, qlsp, order, bits) {
        let array = new Array(20);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] = lsp[i];
        }
        array[0] = 1 / (qlsp[1] - qlsp[0]);
        array[order - 1] = 1 / (qlsp[order - 1] - qlsp[order - 2]);
        for (let i = 1; i < order - 1; i = i + 1) {
            let num = 1 / ((0.15 + qlsp[i] - qlsp[i - 1]) * (0.15 + qlsp[i] - qlsp[i - 1]));
            let num2 = 1 / ((0.15 + qlsp[i + 1] - qlsp[i]) * (0.15 + qlsp[i + 1] - qlsp[i]));
            array[i] = ((num > num2) ? num : num2);
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] -= new Nullable((0.25 * i + 0.25)).Value;
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 256;
        }
        let data = LspQuant.Lsp_quant(qlsp, 0, Codebook_Constants.cdbk_nb, 64, order);
        bits.Pack(data, 6);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 2;
        }
        data = LspQuant.Lsp_weight_quant(qlsp, 0, array, 0, Codebook_Constants.cdbk_nb_low1, 64, 5);
        bits.Pack(data, 6);
        data = LspQuant.Lsp_weight_quant(qlsp, 5, array, 5, Codebook_Constants.cdbk_nb_high1, 64, 5);
        bits.Pack(data, 6);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= new Nullable(0.0019531).Value;
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] = lsp[i] - qlsp[i];
        }
    }
    Unquant(lsp, order, bits) {
        for (let i = 0; i < order; i = i + 1) {
            lsp[i] = 0.25 * i + 0.25;
        }
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb, bits, 0.0039062, 10, 0);
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb_low1, bits, 0.0019531, 5, 0);
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb_high1, bits, 0.0019531, 5, 5);
    }
    constructor() {
        super();
    }
}
class Lsp extends NObject {
    constructor() {
        super();
        this.pw = null;
        this.pw = new Array(42);
        for (let _ai = 0; _ai < this.pw.length; ++_ai)
            this.pw[_ai] = 0;
    }
    static Cheb_poly_eva(coef, x, m) {
        let num = m >> 1;
        let array = new Array(num + 1);
        array[0] = 1;
        array[1] = x;
        let num2 = coef[num] + coef[num - 1] * x;
        x = x * 2;
        for (let i = 2; i <= num; i = i + 1) {
            array[i] = x * array[i - 1] - array[i - 2];
            num2 = num2 + coef[num - i] * array[i];
        }
        return num2;
    }
    static Lpc2lsp(a, lpcrdr, freq, nb, delta) {
        let num = 0;
        let num2 = 0;
        let num3 = lpcrdr / 2;
        let array = new Array(num3 + 1);
        let array2 = new Array(num3 + 1);
        let num4 = 0;
        let num5 = 0;
        let num6 = num4;
        let num7 = num5;
        array2[num4++] = 1;
        array[num5++] = 1;
        for (let i = 1; i <= num3; i = i + 1) {
            array2[num4++] = a[i] + a[lpcrdr + 1 - i] - array2[num6++];
            array[num5++] = a[i] - a[lpcrdr + 1 - i] + array[num7++];
        }
        num4 = 0;
        num5 = 0;
        for (let i = 0; i < num3; i = i + 1) {
            array2[num4] = 2 * array2[num4];
            array[num5] = 2 * array[num5];
            num4 = num4 + 1;
            num5 = num5 + 1;
        }
        let num8 = 0;
        let num9 = 1;
        for (let j = 0; j < lpcrdr; j = j + 1) {
            let flag = j % 2 > 0;
            let coef;
            if (flag) {
                coef = array;
            }
            else {
                coef = array2;
            }
            let num10 = Lsp.Cheb_poly_eva(coef, num9, lpcrdr);
            let num11 = 1;
            while (num11 === 1 && num8 >= -1.0) {
                let num12 = (delta * (1.0 - 0.9 * num9 * num9));
                let flag2 = Math.abs(num10) < 0.2;
                if (flag2) {
                    num12 = num12 * new Nullable(0.5).Value;
                }
                num8 = num9 - num12;
                let num13 = Lsp.Cheb_poly_eva(coef, num8, lpcrdr);
                let num14 = num13;
                let num15 = num8;
                let flag3 = (num13 * num10) < 0.0;
                if (flag3) {
                    num2 = num2 + 1;
                    for (let k = 0; k <= nb; k = k + 1) {
                        num = (num9 + num8) / 2;
                        let num16 = Lsp.Cheb_poly_eva(coef, num, lpcrdr);
                        let flag4 = (num16 * num10) > 0.0;
                        if (flag4) {
                            num10 = num16;
                            num9 = num;
                        }
                        else {
                            num8 = num;
                        }
                    }
                    freq[j] = num;
                    num9 = num;
                    num11 = 0;
                }
                else {
                    num10 = num14;
                    num9 = num15;
                }
            }
        }
        return num2;
    }
    Lsp2lpc(freq, ak, lpcrdr) {
        let num = 0;
        let num2 = lpcrdr / 2;
        for (let i = 0; i < 4 * num2 + 2; i = i + 1) {
            this.pw[i] = 0;
        }
        let num3 = 1;
        let num4 = 1;
        for (let j = 0; j <= lpcrdr; j = j + 1) {
            let num5 = 0;
            let i = 0;
            let num9;
            let num10;
            while (i < num2) {
                let num6 = i * 4;
                let num7 = num6 + 1;
                let num8 = num7 + 1;
                num = num8 + 1;
                num9 = num3 - 2 * freq[num5] * this.pw[num6] + this.pw[num7];
                num10 = num4 - 2 * freq[num5 + 1] * this.pw[num8] + this.pw[num];
                this.pw[num7] = this.pw[num6];
                this.pw[num] = this.pw[num8];
                this.pw[num6] = num3;
                this.pw[num8] = num4;
                num3 = num9;
                num4 = num10;
                i = i + 1;
                num5 = num5 + 2;
            }
            num9 = num3 + this.pw[num + 1];
            num10 = num4 - this.pw[num + 2];
            ak[j] = (num9 + num10) * 0.5;
            this.pw[num + 1] = num3;
            this.pw[num + 2] = num4;
            num3 = 0;
            num4 = 0;
        }
    }
    static Enforce_margin(lsp, len, margin) {
        let flag = lsp[0] < margin;
        if (flag) {
            lsp[0] = margin;
        }
        let flag2 = lsp[len - 1] > 3.14159274 - margin;
        if (flag2) {
            lsp[len - 1] = 3.14159274 - margin;
        }
        for (let i = 1; i < len - 1; i = i + 1) {
            let flag3 = lsp[i] < lsp[i - 1] + margin;
            if (flag3) {
                lsp[i] = lsp[i - 1] + margin;
            }
            let flag4 = lsp[i] > lsp[i + 1] - margin;
            if (flag4) {
                lsp[i] = 0.5 * (lsp[i] + lsp[i + 1] - margin);
            }
        }
    }
}
class Ltp extends NObject {
    Quant(target, sw, sws, ak, awk1, awk2, exc, es, start, end, pitch_coef, p, nsf, bits, exc2, e2s, r, complexity) {
        throw new NotSupportedException();
    }
    Unquant(exc, es, start, pitch_coef, nsf, gain_val, bits, count_lost, subframe_offset, last_pitch_gain) {
        throw new NotSupportedException();
    }
    static Inner_prod(x, xs, y, ys, len) {
        let num = 0;
        let num2 = 0;
        let num3 = 0;
        let num4 = 0;
        for (let i = 0; i < len; i = i + 4) {
            num = num + x[xs + i] * y[ys + i];
            num2 = num2 + x[xs + i + 1] * y[ys + i + 1];
            num3 = num3 + x[xs + i + 2] * y[ys + i + 2];
            num4 = num4 + x[xs + i + 3] * y[ys + i + 3];
        }
        return num + num2 + num3 + num4;
    }
    static Open_loop_nbest_pitch(sw, swIdx, start, end, len, pitch, gain, N) {
        let array = new Array(N);
        let array2 = new Array(end - start + 1);
        let array3 = new Array(end - start + 2);
        let array4 = new Array(end - start + 1);
        for (let i = 0; i < N; i = i + 1) {
            array[i] = -1;
            gain[i] = 0;
            pitch[i] = start;
        }
        array3[0] = Ltp.Inner_prod(sw, swIdx - start, sw, swIdx - start, len);
        let num = Ltp.Inner_prod(sw, swIdx, sw, swIdx, len);
        for (let i = start; i <= end; i = i + 1) {
            array3[i - start + 1] = array3[i - start] + sw[swIdx - i - 1] * sw[swIdx - i - 1] - sw[swIdx - i + len - 1] * sw[swIdx - i + len - 1];
            let flag = array3[i - start + 1] < 1;
            if (flag) {
                array3[i - start + 1] = 1;
            }
        }
        for (let i = start; i <= end; i = i + 1) {
            array2[i - start] = 0;
            array4[i - start] = 0;
        }
        for (let i = start; i <= end; i = i + 1) {
            array2[i - start] = Ltp.Inner_prod(sw, swIdx, sw, swIdx - i, len);
            array4[i - start] = array2[i - start] * array2[i - start] / (array3[i - start] + 1);
        }
        for (let i = start; i <= end; i = i + 1) {
            let flag2 = array4[i - start] > array[N - 1];
            if (flag2) {
                let num2 = array2[i - start] / (array3[i - start] + 10);
                let num3 = Math.sqrt((num2 * array2[i - start] / (num + 10)));
                let flag3 = num3 > num2;
                if (flag3) {
                    num3 = num2;
                }
                let flag4 = num3 < 0;
                if (flag4) {
                    num3 = 0;
                }
                for (let j = 0; j < N; j = j + 1) {
                    let flag5 = array4[i - start] > array[j];
                    if (flag5) {
                        for (let k = N - 1; k > j; k = k - 1) {
                            array[k] = array[k - 1];
                            pitch[k] = pitch[k - 1];
                            gain[k] = gain[k - 1];
                        }
                        array[j] = array4[i - start];
                        pitch[j] = i;
                        gain[j] = num3;
                        break;
                    }
                }
            }
        }
    }
    constructor() {
        super();
    }
}
class Ltp3Tap extends Ltp {
    constructor(gain_cdbk_0, gain_bits_1, pitch_bits_2) {
        super();
        this.gain = null;
        this.gain_cdbk = null;
        this.gain_bits = 0;
        this.pitch_bits = 0;
        this.e = null;
        this.gain = new Array(3);
        for (let _ai = 0; _ai < this.gain.length; ++_ai)
            this.gain[_ai] = 0;
        this.gain_cdbk = gain_cdbk_0;
        this.gain_bits = gain_bits_1;
        this.pitch_bits = pitch_bits_2;
        this.e = this.CreateJaggedArray(3, 128);
    }
    CreateJaggedArray(dim1, dim2) {
        let array = new Array(dim1);
        for (let i = 0; i < dim1; i = i + 1) {
            array[i] = new Array(dim2);
            for (let _ai = 0; _ai < array[i].length; ++_ai)
                array[i][_ai] = null;
            NArray.Clear(array[i], 0, dim2);
        }
        return array;
    }
    Quant(target, sw, sws, ak, awk1, awk2, exc, es, start, end, pitch_coef, p, nsf, bits, exc2, e2s, r, complexity) {
        let array = new Array(1);
        for (let _ai = 0; _ai < array.length; ++_ai)
            array[_ai] = 0;
        let num = 0;
        let data = 0;
        let num2 = 0;
        let num3 = -1;
        let num4 = complexity;
        let flag = num4 > 10;
        if (flag) {
            num4 = 10;
        }
        let array2 = new Array(num4);
        for (let _ai = 0; _ai < array2.length; ++_ai)
            array2[_ai] = 0;
        let array3 = new Array(num4);
        for (let _ai = 0; _ai < array3.length; ++_ai)
            array3[_ai] = 0;
        let flag2 = num4 === 0 || end < start;
        let result;
        if (flag2) {
            bits.Pack(0, this.pitch_bits);
            bits.Pack(0, this.gain_bits);
            for (let i = 0; i < nsf; i = i + 1) {
                exc[es + i] = 0;
            }
            result = start;
        }
        else {
            let array4 = new Array(nsf);
            let flag3 = num4 > end - start + 1;
            if (flag3) {
                num4 = end - start + 1;
            }
            Ltp.Open_loop_nbest_pitch(sw, sws, start, end, nsf, array2, array3, num4);
            for (let i = 0; i < num4; i = i + 1) {
                num = array2[i];
                for (let j = 0; j < nsf; j = j + 1) {
                    exc[es + j] = 0;
                }
                let num5 = this.Pitch_gain_search_3tap(target, ak, awk1, awk2, exc, es, num, p, nsf, bits, exc2, e2s, r, array);
                let flag4 = num5 < num3 || num3 < 0;
                if (flag4) {
                    for (let j = 0; j < nsf; j = j + 1) {
                        array4[j] = exc[es + j];
                    }
                    num3 = num5;
                    num2 = num;
                    data = array[0];
                }
            }
            bits.Pack(num2 - start, this.pitch_bits);
            bits.Pack(data, this.gain_bits);
            for (let i = 0; i < nsf; i = i + 1) {
                exc[es + i] = array4[i];
            }
            result = num;
        }
        return result;
    }
    Unquant(exc, es, start, pitch_coef, nsf, gain_val, bits, count_lost, subframe_offset, last_pitch_gain) {
        let num = bits.Unpack(this.pitch_bits);
        num = num + start;
        let num2 = bits.Unpack(this.gain_bits);
        this.gain[0] = 0.015625 * this.gain_cdbk[num2 * 3] + 0.5;
        this.gain[1] = 0.015625 * this.gain_cdbk[num2 * 3 + 1] + 0.5;
        this.gain[2] = 0.015625 * this.gain_cdbk[num2 * 3 + 2] + 0.5;
        let flag = count_lost !== 0 && num > subframe_offset;
        if (flag) {
            let num3 = Math.abs(this.gain[1]);
            let num4 = (count_lost < 4) ? last_pitch_gain : (0.4 * last_pitch_gain);
            let flag2 = num4 > 0.95;
            if (flag2) {
                num4 = 0.95;
            }
            let flag3 = this.gain[0] > 0;
            if (flag3) {
                num3 = num3 + this.gain[0];
            }
            else {
                num3 = num3 - 0.5 * this.gain[0];
            }
            let flag4 = this.gain[2] > 0;
            if (flag4) {
                num3 = num3 + this.gain[2];
            }
            else {
                num3 = num3 - 0.5 * this.gain[0];
            }
            let flag5 = num3 > num4;
            if (flag5) {
                let num5 = num4 / num3;
                for (let i = 0; i < 3; i = i + 1) {
                    this.gain[i] *= num5;
                }
            }
        }
        gain_val[0] = this.gain[0];
        gain_val[1] = this.gain[1];
        gain_val[2] = this.gain[2];
        for (let i = 0; i < 3; i = i + 1) {
            let num6 = num + 1 - i;
            let num7 = nsf;
            let flag6 = num7 > num6;
            if (flag6) {
                num7 = num6;
            }
            let num8 = nsf;
            let flag7 = num8 > num6 + num;
            if (flag7) {
                num8 = num6 + num;
            }
            for (let j = 0; j < num7; j = j + 1) {
                this.e[i][j] = exc[es + j - num6];
            }
            for (let j = num7; j < num8; j = j + 1) {
                this.e[i][j] = exc[es + j - num6 - num];
            }
            for (let j = num8; j < nsf; j = j + 1) {
                this.e[i][j] = 0;
            }
        }
        for (let i = 0; i < nsf; i = i + 1) {
            exc[es + i] = this.gain[0] * this.e[2][i] + this.gain[1] * this.e[1][i] + this.gain[2] * this.e[0][i];
        }
        return num;
    }
    Pitch_gain_search_3tap(target, ak, awk1, awk2, exc, es, pitch, p, nsf, bits, exc2, e2s, r, cdbk_index) {
        let array = new Array(3);
        let array2 = this.CreateJaggedArray(3, 3);
        let num = 1 << this.gain_bits;
        let array3 = this.CreateJaggedArray(3, nsf);
        this.e = this.CreateJaggedArray(3, nsf);
        for (let i = 2; i >= 0; i = i - 1) {
            let num2 = pitch + 1 - i;
            for (let j = 0; j < nsf; j = j + 1) {
                let flag = j - num2 < 0;
                if (flag) {
                    this.e[i][j] = exc2[e2s + j - num2];
                }
                else {
                    let flag2 = j - num2 - pitch < 0;
                    if (flag2) {
                        this.e[i][j] = exc2[e2s + j - num2 - pitch];
                    }
                    else {
                        this.e[i][j] = 0;
                    }
                }
            }
            let flag3 = i === 2;
            if (flag3) {
                Filters.Syn_percep_zero(this.e[i], 0, ak, awk1, awk2, array3[i], nsf, p);
            }
            else {
                for (let j = 0; j < nsf - 1; j = j + 1) {
                    array3[i][j + 1] = array3[i + 1][j];
                }
                array3[i][0] = 0;
                for (let j = 0; j < nsf; j = j + 1) {
                    array3[i][j] += this.e[i][0] * r[j];
                }
            }
        }
        for (let i = 0; i < 3; i = i + 1) {
            array[i] = Ltp.Inner_prod(array3[i], 0, target, 0, nsf);
        }
        for (let i = 0; i < 3; i = i + 1) {
            for (let j = 0; j <= i; j = j + 1) {
                array2[i][j] = (array2[j][i] = Ltp.Inner_prod(array3[i], 0, array3[j], 0, nsf));
            }
        }
        let array4 = new Array(9);
        let num3 = 0;
        let num4 = 0;
        array4[0] = array[2];
        array4[1] = array[1];
        array4[2] = array[0];
        array4[3] = array2[1][2];
        array4[4] = array2[0][1];
        array4[5] = array2[0][2];
        array4[6] = array2[2][2];
        array4[7] = array2[1][1];
        array4[8] = array2[0][0];
        for (let i = 0; i < num; i = i + 1) {
            let num5 = 0;
            let num6 = 3 * i;
            let num7 = 0.015625 * this.gain_cdbk[num6] + 0.5;
            let num8 = 0.015625 * this.gain_cdbk[num6 + 1] + 0.5;
            let num9 = 0.015625 * this.gain_cdbk[num6 + 2] + 0.5;
            num5 = num5 + array4[0] * num7;
            num5 = num5 + array4[1] * num8;
            num5 = num5 + array4[2] * num9;
            num5 = num5 - array4[3] * num7 * num8;
            num5 = num5 - array4[4] * num9 * num8;
            num5 = num5 - array4[5] * num9 * num7;
            num5 = num5 - 0.5 * array4[6] * num7 * num7;
            num5 = num5 - 0.5 * array4[7] * num8 * num8;
            num5 = num5 - 0.5 * array4[8] * num9 * num9;
            let flag4 = num5 > num4 || i === 0;
            if (flag4) {
                num4 = num5;
                num3 = i;
            }
        }
        this.gain[0] = 0.015625 * this.gain_cdbk[num3 * 3] + 0.5;
        this.gain[1] = 0.015625 * this.gain_cdbk[num3 * 3 + 1] + 0.5;
        this.gain[2] = 0.015625 * this.gain_cdbk[num3 * 3 + 2] + 0.5;
        cdbk_index[0] = num3;
        for (let i = 0; i < nsf; i = i + 1) {
            exc[es + i] = this.gain[0] * this.e[2][i] + this.gain[1] * this.e[1][i] + this.gain[2] * this.e[0][i];
        }
        let num10 = 0;
        let num11 = 0;
        for (let i = 0; i < nsf; i = i + 1) {
            num10 = num10 + target[i] * target[i];
        }
        for (let i = 0; i < nsf; i = i + 1) {
            num11 = num11 + (target[i] - this.gain[2] * array3[0][i] - this.gain[1] * array3[1][i] - this.gain[0] * array3[2][i]) * (target[i] - this.gain[2] * array3[0][i] - this.gain[1] * array3[1][i] - this.gain[0] * array3[2][i]);
        }
        return num11;
    }
}
class LtpForcedPitch extends Ltp {
    Quant(target, sw, sws, ak, awk1, awk2, exc, es, start, end, pitch_coef, p, nsf, bits, exc2, e2s, r, complexity) {
        let flag = pitch_coef > 0.99;
        if (flag) {
            pitch_coef = 0.99;
        }
        for (let i = 0; i < nsf; i = i + 1) {
            exc[es + i] = exc[es + i - start] * pitch_coef;
        }
        return start;
    }
    Unquant(exc, es, start, pitch_coef, nsf, gain_val, bits, count_lost, subframe_offset, last_pitch_gain) {
        let flag = pitch_coef > 0.99;
        if (flag) {
            pitch_coef = 0.99;
        }
        for (let i = 0; i < nsf; i = i + 1) {
            exc[es + i] = exc[es + i - start] * pitch_coef;
        }
        gain_val[0] = (gain_val[2] = 0);
        gain_val[1] = pitch_coef;
        return start;
    }
    constructor() {
        super();
    }
}
class NbCodec extends NObject {
    constructor() {
        super();
        this.m_lsp = null;
        this.filters = null;
        this.submodes = null;
        this.submodeID = 0;
        this.first = 0;
        this.frameSize = 0;
        this.subframeSize = 0;
        this.nbSubframes = 0;
        this.windowSize = 0;
        this.lpcSize = 0;
        this.bufSize = 0;
        this.min_pitch = 0;
        this.max_pitch = 0;
        this.gamma1 = 0;
        this.gamma2 = 0;
        this.lag_factor = 0;
        this.lpc_floor = 0;
        this.preemph = 0;
        this.pre_mem = 0;
        this.frmBuf = null;
        this.frmIdx = 0;
        this.excBuf = null;
        this.excIdx = 0;
        this.innov = null;
        this.lpc = null;
        this.qlsp = null;
        this.old_qlsp = null;
        this.interp_qlsp = null;
        this.interp_qlpc = null;
        this.mem_sp = null;
        this.pi_gain = null;
        this.awk1 = null;
        this.awk2 = null;
        this.awk3 = null;
        this.voc_m1 = 0;
        this.voc_m2 = 0;
        this.voc_mean = 0;
        this.voc_offset = 0;
        this.dtx_enabled = 0;
        this.m_lsp = new Lsp();
        this.filters = new Filters();
        this.Nbinit();
    }
    get FrameSize() {
        return this.frameSize;
    }
    get PiGain() {
        return this.pi_gain;
    }
    get Exc() {
        let array = new Array(this.frameSize);
        for (let _ai = 0; _ai < array.length; ++_ai)
            array[_ai] = 0;
        NArray.Copy(this.excBuf, this.excIdx, array, 0, this.frameSize);
        return array;
    }
    get Innov() {
        return this.innov;
    }
    Nbinit() {
        this.submodes = NbCodec.BuildNbSubModes();
        this.submodeID = 5;
        this.Init(160, 40, 10, 640);
    }
    Init(frameSize, subframeSize, lpcSize, bufSize) {
        this.first = 1;
        this.frameSize = frameSize;
        this.windowSize = frameSize * 3 / 2;
        this.subframeSize = subframeSize;
        this.nbSubframes = frameSize / subframeSize;
        this.lpcSize = lpcSize;
        this.bufSize = bufSize;
        this.min_pitch = 17;
        this.max_pitch = 144;
        this.preemph = 0;
        this.pre_mem = 0;
        this.gamma1 = 0.9;
        this.gamma2 = 0.6;
        this.lag_factor = 0.01;
        this.lpc_floor = 1.0001;
        this.frmBuf = new Array(bufSize);
        for (let _ai = 0; _ai < this.frmBuf.length; ++_ai)
            this.frmBuf[_ai] = 0;
        this.frmIdx = bufSize - this.windowSize;
        this.excBuf = new Array(bufSize);
        for (let _ai = 0; _ai < this.excBuf.length; ++_ai)
            this.excBuf[_ai] = 0;
        this.excIdx = bufSize - this.windowSize;
        this.innov = new Array(frameSize);
        for (let _ai = 0; _ai < this.innov.length; ++_ai)
            this.innov[_ai] = 0;
        this.lpc = new Array(lpcSize + 1);
        for (let _ai = 0; _ai < this.lpc.length; ++_ai)
            this.lpc[_ai] = 0;
        this.qlsp = new Array(lpcSize);
        for (let _ai = 0; _ai < this.qlsp.length; ++_ai)
            this.qlsp[_ai] = 0;
        this.old_qlsp = new Array(lpcSize);
        for (let _ai = 0; _ai < this.old_qlsp.length; ++_ai)
            this.old_qlsp[_ai] = 0;
        this.interp_qlsp = new Array(lpcSize);
        for (let _ai = 0; _ai < this.interp_qlsp.length; ++_ai)
            this.interp_qlsp[_ai] = 0;
        this.interp_qlpc = new Array(lpcSize + 1);
        for (let _ai = 0; _ai < this.interp_qlpc.length; ++_ai)
            this.interp_qlpc[_ai] = 0;
        this.mem_sp = new Array(5 * lpcSize);
        for (let _ai = 0; _ai < this.mem_sp.length; ++_ai)
            this.mem_sp[_ai] = 0;
        this.pi_gain = new Array(this.nbSubframes);
        for (let _ai = 0; _ai < this.pi_gain.length; ++_ai)
            this.pi_gain[_ai] = 0;
        this.awk1 = new Array(lpcSize + 1);
        for (let _ai = 0; _ai < this.awk1.length; ++_ai)
            this.awk1[_ai] = 0;
        this.awk2 = new Array(lpcSize + 1);
        for (let _ai = 0; _ai < this.awk2.length; ++_ai)
            this.awk2[_ai] = 0;
        this.awk3 = new Array(lpcSize + 1);
        for (let _ai = 0; _ai < this.awk3.length; ++_ai)
            this.awk3[_ai] = 0;
        this.voc_m1 = (this.voc_m2 = (this.voc_mean = 0));
        this.voc_offset = 0;
        this.dtx_enabled = 0;
    }
    static BuildNbSubModes() {
        let ltp = new Ltp3Tap(Codebook_Constants.gain_cdbk_nb, 7, 7);
        let ltp2 = new Ltp3Tap(Codebook_Constants.gain_cdbk_lbr, 5, 0);
        let ltp3 = new Ltp3Tap(Codebook_Constants.gain_cdbk_lbr, 5, 7);
        let ltp4 = new Ltp3Tap(Codebook_Constants.gain_cdbk_lbr, 5, 7);
        let ltp5 = new LtpForcedPitch();
        let innovation = new NoiseSearch();
        let innovation2 = new SplitShapeSearch(40, 10, 4, Codebook_Constants.exc_10_16_table, 4, 0);
        let innovation3 = new SplitShapeSearch(40, 10, 4, Codebook_Constants.exc_10_32_table, 5, 0);
        let innovation4 = new SplitShapeSearch(40, 5, 8, Codebook_Constants.exc_5_64_table, 6, 0);
        let innovation5 = new SplitShapeSearch(40, 8, 5, Codebook_Constants.exc_8_128_table, 7, 0);
        let innovation6 = new SplitShapeSearch(40, 5, 8, Codebook_Constants.exc_5_256_table, 8, 0);
        let innovation7 = new SplitShapeSearch(40, 20, 2, Codebook_Constants.exc_20_32_table, 5, 0);
        let lspQuant = new NbLspQuant();
        let lspQuant2 = new LbrLspQuant();
        let array = new Array(16);
        array[1] = new SubMode(0, 1, 0, 0, lspQuant2, ltp5, innovation, 0.7, 0.7, -1, 43);
        array[2] = new SubMode(0, 0, 0, 0, lspQuant2, ltp2, innovation2, 0.7, 0.5, 0.55, 119);
        array[3] = new SubMode(-1, 0, 1, 0, lspQuant2, ltp3, innovation3, 0.7, 0.55, 0.45, 160);
        array[4] = new SubMode(-1, 0, 1, 0, lspQuant2, ltp4, innovation5, 0.7, 0.63, 0.35, 220);
        array[5] = new SubMode(-1, 0, 3, 0, lspQuant, ltp, innovation4, 0.7, 0.65, 0.25, 300);
        array[6] = new SubMode(-1, 0, 3, 0, lspQuant, ltp, innovation6, 0.68, 0.65, 0.1, 364);
        array[7] = new SubMode(-1, 0, 3, 1, lspQuant, ltp, innovation4, 0.65, 0.65, -1, 492);
        array[8] = new SubMode(0, 1, 0, 0, lspQuant2, ltp5, innovation7, 0.7, 0.5, 0.65, 79);
        return array;
    }
}
NbCodec.VERY_SMALL = 0;
NbCodec.NB_FRAME_SIZE = [
    5, 43, 119, 160, 220, 300, 364, 492, 79, 1, 1, 1, 1, 1, 1, 1
];
NbCodec.NB_SUBMODES = 16;
NbCodec.NB_SUBMODE_BITS = 4;
NbCodec.exc_gain_quant_scal1 = [
    -0.35, 0.05
];
NbCodec.exc_gain_quant_scal3 = [
    -2.79475, -1.81066, -1.16985, -0.848119, -0.58719, -0.329818, -0.063266, 0.282826
];
class NbDecoder extends NbCodec {
    constructor() {
        super();
        this.innov2 = null;
        this.count_lost = 0;
        this.last_pitch = 0;
        this.last_pitch_gain = 0;
        this.pitch_gain_buf = null;
        this.pitch_gain_buf_idx = 0;
        this.last_ol_gain = 0;
        this.random = null;
        this.stereo = null;
        this.inband = null;
        this.enhanced = false;
        this.random = new Random(6);
        this.stereo = new Stereo();
        this.inband = new Inband(this.stereo);
        this.enhanced = true;
        this.Init(160, 40, 10, 640);
    }
    get Dtx() {
        return this.dtx_enabled > 0;
    }
    set PerceptualEnhancement(value) {
        this.enhanced = value;
    }
    get PerceptualEnhancement() {
        return this.enhanced;
    }
    Init(frameSize, subframeSize, lpcSize, bufSize) {
        super.Init(frameSize, subframeSize, lpcSize, bufSize);
        this.innov2 = new Array(40);
        for (let _ai = 0; _ai < this.innov2.length; ++_ai)
            this.innov2[_ai] = 0;
        this.count_lost = 0;
        this.last_pitch = 40;
        this.last_pitch_gain = 0;
        this.pitch_gain_buf = new Array(3);
        for (let _ai = 0; _ai < this.pitch_gain_buf.length; ++_ai)
            this.pitch_gain_buf[_ai] = 0;
        this.pitch_gain_buf_idx = 0;
        this.last_ol_gain = 0;
    }
    Decode(bits, xout) {
        let num = 0;
        let array = new Array(3);
        for (let _ai = 0; _ai < array.length; ++_ai)
            array[_ai] = 0;
        let num2 = 0;
        let num3 = 40;
        let num4 = 0;
        let num5 = 0;
        let flag = bits === null && this.dtx_enabled > 0;
        let result;
        if (flag) {
            this.submodeID = 0;
        }
        else {
            let Block_6 = 1;
            let Block_8 = 2;
            let Block_9 = 3;
            let Block_10 = 4;
            let Block_11 = 5;
            let Block_14 = 6;
            let Block_15 = 7;
            let _goto = 0;
            _GOTO_LOOP: while (true) {
                switch (_goto) {
                    default:
                        let flag2 = bits === null;
                        if (flag2) {
                            this.DecodeLost(xout);
                            result = 0;
                            return result;
                        }
                        let num6;
                        while (true) {
                            let flag3 = bits.BitsRemaining() < 5;
                            if (flag3) {
                                break;
                            }
                            let flag4 = bits.Unpack(1) > 0;
                            if (flag4) {
                                num6 = bits.Unpack(3);
                                let num7 = SbCodec.SB_FRAME_SIZE[num6];
                                let flag5 = num7 < 0;
                                if (flag5) {
                                    _goto = Block_6;
                                    continue _GOTO_LOOP;
                                }
                                num7 = num7 - 4;
                                bits.Advance(num7);
                                let flag6 = bits.Unpack(1) > 0;
                                if (flag6) {
                                    num6 = bits.Unpack(3);
                                    num7 = SbCodec.SB_FRAME_SIZE[num6];
                                    let flag7 = num7 < 0;
                                    if (flag7) {
                                        _goto = Block_8;
                                        continue _GOTO_LOOP;
                                    }
                                    num7 = num7 - 4;
                                    bits.Advance(num7);
                                    let flag8 = bits.Unpack(1) > 0;
                                    if (flag8) {
                                        _goto = Block_9;
                                        continue _GOTO_LOOP;
                                    }
                                }
                            }
                            let flag9 = bits.BitsRemaining() < 4;
                            if (flag9) {
                                _goto = Block_10;
                                continue _GOTO_LOOP;
                            }
                            num6 = bits.Unpack(4);
                            let flag10 = num6 === 15;
                            if (flag10) {
                                _goto = Block_11;
                                continue _GOTO_LOOP;
                            }
                            let flag11 = num6 === 14;
                            if (flag11) {
                                this.inband.SpeexInbandRequest(bits);
                            }
                            else {
                                let flag12 = num6 === 13;
                                if (flag12) {
                                    this.inband.UserInbandRequest(bits);
                                }
                                else {
                                    let flag13 = num6 > 8;
                                    if (flag13) {
                                        _goto = Block_14;
                                        continue _GOTO_LOOP;
                                    }
                                }
                            }
                            if (num6 <= 8) {
                                _goto = Block_15;
                                continue _GOTO_LOOP;
                            }
                        }
                        result = -1;
                        return result;
                    case 1:
                        throw new Exception("Invalid sideband mode encountered (1st sideband): " + num6);
                    case 2:
                        throw new Exception("Invalid sideband mode encountered. (2nd sideband): " + num6);
                    case 3:
                        throw new Exception("More than two sideband layers found");
                    case 4:
                        result = 1;
                        return result;
                    case 5:
                        result = 1;
                        return result;
                    case 6:
                        throw new Exception("Invalid mode encountered: " + num6);
                    case 7:
                        this.submodeID = num6;
                        break _GOTO_LOOP;
                }
            }
        }
        NArray.Copy(this.frmBuf, this.frameSize, this.frmBuf, 0, this.bufSize - this.frameSize);
        NArray.Copy(this.excBuf, this.frameSize, this.excBuf, 0, this.bufSize - this.frameSize);
        let flag14 = this.submodes[this.submodeID] === null;
        if (flag14) {
            Filters.Bw_lpc(0.93, this.interp_qlpc, this.lpc, 10);
            let num8 = 0;
            for (let i = 0; i < this.frameSize; i = i + 1) {
                num8 = num8 + this.innov[i] * this.innov[i];
            }
            num8 = Math.sqrt((num8 / this.frameSize));
            for (let i = this.excIdx; i < this.excIdx + this.frameSize; i = i + 1) {
                this.excBuf[i] = ((3 * num8) * (this.random.NextDouble() - 0.5));
            }
            this.first = 1;
            Filters.Iir_mem2(this.excBuf, this.excIdx, this.lpc, this.frmBuf, this.frmIdx, this.frameSize, this.lpcSize, this.mem_sp);
            xout[0] = this.frmBuf[this.frmIdx] + this.preemph * this.pre_mem;
            for (let i = 1; i < this.frameSize; i = i + 1) {
                xout[i] = this.frmBuf[this.frmIdx + i] + this.preemph * xout[i - 1];
            }
            this.pre_mem = xout[this.frameSize - 1];
            this.count_lost = 0;
            result = 0;
        }
        else {
            this.submodes[this.submodeID].LsqQuant.Unquant(this.qlsp, this.lpcSize, bits);
            let flag15 = this.count_lost > 0;
            if (flag15) {
                let num9 = 0;
                for (let i = 0; i < this.lpcSize; i = i + 1) {
                    num9 = num9 + Math.abs(this.old_qlsp[i] - this.qlsp[i]);
                }
                let num10 = (0.6 * Math.exp(-0.2 * num9));
                for (let i = 0; i < 2 * this.lpcSize; i = i + 1) {
                    this.mem_sp[i] *= num10;
                }
            }
            let flag16 = this.first !== 0 || this.count_lost > 0;
            if (flag16) {
                for (let i = 0; i < this.lpcSize; i = i + 1) {
                    this.old_qlsp[i] = this.qlsp[i];
                }
            }
            let flag17 = this.submodes[this.submodeID].LbrPitch !== -1;
            if (flag17) {
                num = this.min_pitch + bits.Unpack(7);
            }
            let flag18 = this.submodes[this.submodeID].ForcedPitchGain > 0;
            if (flag18) {
                let num11 = bits.Unpack(4);
                num2 = 0.066667 * num11;
            }
            let num12 = bits.Unpack(5);
            let num13 = Math.exp(num12 / 3.5);
            let flag19 = this.submodeID === 1;
            if (flag19) {
                let num14 = bits.Unpack(4);
                let flag20 = num14 === 15;
                if (flag20) {
                    this.dtx_enabled = 1;
                }
                else {
                    this.dtx_enabled = 0;
                }
            }
            let flag21 = this.submodeID > 1;
            if (flag21) {
                this.dtx_enabled = 0;
            }
            for (let j = 0; j < this.nbSubframes; j = j + 1) {
                let num15 = this.subframeSize * j;
                let num16 = this.frmIdx + num15;
                let num17 = this.excIdx + num15;
                let num18 = (1 + j) / this.nbSubframes;
                for (let i = 0; i < this.lpcSize; i = i + 1) {
                    this.interp_qlsp[i] = (1 - num18) * this.old_qlsp[i] + num18 * this.qlsp[i];
                }
                Lsp.Enforce_margin(this.interp_qlsp, this.lpcSize, 0.002);
                for (let i = 0; i < this.lpcSize; i = i + 1) {
                    this.interp_qlsp[i] = Math.cos(this.interp_qlsp[i]);
                }
                this.m_lsp.Lsp2lpc(this.interp_qlsp, this.interp_qlpc, this.lpcSize);
                let flag22 = this.enhanced;
                if (flag22) {
                    let num19 = 0.9;
                    let lpcEnhK = this.submodes[this.submodeID].LpcEnhK1;
                    let lpcEnhK2 = this.submodes[this.submodeID].LpcEnhK2;
                    let gamma = (1 - (1 - num19 * lpcEnhK) / (1 - num19 * lpcEnhK2)) / num19;
                    Filters.Bw_lpc(lpcEnhK, this.interp_qlpc, this.awk1, this.lpcSize);
                    Filters.Bw_lpc(lpcEnhK2, this.interp_qlpc, this.awk2, this.lpcSize);
                    Filters.Bw_lpc(gamma, this.interp_qlpc, this.awk3, this.lpcSize);
                }
                num18 = 1;
                this.pi_gain[j] = 0;
                for (let i = 0; i <= this.lpcSize; i = i + 1) {
                    this.pi_gain[j] += num18 * this.interp_qlpc[i];
                    num18 = -num18;
                }
                for (let i = 0; i < this.subframeSize; i = i + 1) {
                    this.excBuf[num17 + i] = 0;
                }
                let flag23 = this.submodes[this.submodeID].LbrPitch !== -1;
                let num20;
                if (flag23) {
                    let lbrPitch = this.submodes[this.submodeID].LbrPitch;
                    let flag24 = lbrPitch > 0;
                    if (flag24) {
                        num20 = num - lbrPitch + 1;
                        let flag25 = num20 < this.min_pitch;
                        if (flag25) {
                            num20 = this.min_pitch;
                        }
                        let num21 = num + lbrPitch;
                        let flag26 = num21 > this.max_pitch;
                        if (flag26) {
                            num21 = this.max_pitch;
                        }
                    }
                    else {
                        num20 = num;
                    }
                }
                else {
                    num20 = this.min_pitch;
                    let num21 = this.max_pitch;
                }
                let num22 = this.submodes[this.submodeID].Ltp.Unquant(this.excBuf, num17, num20, num2, this.subframeSize, array, bits, this.count_lost, num15, this.last_pitch_gain);
                let flag27 = this.count_lost !== 0 && num13 < this.last_ol_gain;
                if (flag27) {
                    let num23 = num13 / (this.last_ol_gain + 1);
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        this.excBuf[this.excIdx + i] *= num23;
                    }
                }
                num18 = Math.abs(array[0] + array[1] + array[2]);
                num18 = Math.abs(array[1]);
                let flag28 = array[0] > 0;
                if (flag28) {
                    num18 = num18 + array[0];
                }
                else {
                    num18 = num18 - 0.5 * array[0];
                }
                let flag29 = array[2] > 0;
                if (flag29) {
                    num18 = num18 + array[2];
                }
                else {
                    num18 = num18 - 0.5 * array[0];
                }
                num5 = num5 + num18;
                let flag30 = num18 > num4;
                if (flag30) {
                    num3 = num22;
                    num4 = num18;
                }
                let num24 = j * this.subframeSize;
                for (let i = num24; i < num24 + this.subframeSize; i = i + 1) {
                    this.innov[i] = 0;
                }
                let flag31 = this.submodes[this.submodeID].HaveSubframeGain === 3;
                let num26;
                if (flag31) {
                    let num25 = bits.Unpack(3);
                    num26 = (num13 * Math.exp(NbCodec.exc_gain_quant_scal3[num25]));
                }
                else {
                    let flag32 = this.submodes[this.submodeID].HaveSubframeGain === 1;
                    if (flag32) {
                        let num25 = bits.Unpack(1);
                        num26 = (num13 * Math.exp(NbCodec.exc_gain_quant_scal1[num25]));
                    }
                    else {
                        num26 = num13;
                    }
                }
                let flag33 = this.submodes[this.submodeID].Innovation !== null;
                if (flag33) {
                    this.submodes[this.submodeID].Innovation.Unquantify(this.innov, num24, this.subframeSize, bits);
                }
                for (let i = num24; i < num24 + this.subframeSize; i = i + 1) {
                    this.innov[i] *= num26;
                }
                let flag34 = this.submodeID === 1;
                if (flag34) {
                    let num27 = num2;
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        this.excBuf[num17 + i] = 0;
                    }
                    while (this.voc_offset < this.subframeSize) {
                        let flag35 = this.voc_offset >= 0;
                        if (flag35) {
                            this.excBuf[num17 + this.voc_offset] = Math.sqrt((1 * num));
                        }
                        this.voc_offset = this.voc_offset + num;
                    }
                    this.voc_offset = this.voc_offset - this.subframeSize;
                    num27 = 0.5 + 2 * (num27 - 0.6);
                    let flag36 = num27 < 0;
                    if (flag36) {
                        num27 = 0;
                    }
                    let flag37 = num27 > 1;
                    if (flag37) {
                        num27 = 1;
                    }
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        let voc_m = this.excBuf[num17 + i];
                        this.excBuf[num17 + i] = 0.8 * num27 * this.excBuf[num17 + i] * num13 + 0.6 * num27 * this.voc_m1 * num13 + 0.5 * num27 * this.innov[num24 + i] - 0.5 * num27 * this.voc_m2 + (1 - num27) * this.innov[num24 + i];
                        this.voc_m1 = voc_m;
                        this.voc_m2 = this.innov[num24 + i];
                        this.voc_mean = 0.95 * this.voc_mean + 0.05 * this.excBuf[num17 + i];
                        this.excBuf[num17 + i] -= this.voc_mean;
                    }
                }
                else {
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        this.excBuf[num17 + i] += this.innov[num24 + i];
                    }
                }
                let flag38 = this.submodes[this.submodeID].DoubleCodebook > 0;
                if (flag38) {
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        this.innov2[i] = 0;
                    }
                    this.submodes[this.submodeID].Innovation.Unquantify(this.innov2, 0, this.subframeSize, bits);
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        this.innov2[i] *= num26 * 0.454545438;
                    }
                    for (let i = 0; i < this.subframeSize; i = i + 1) {
                        this.excBuf[num17 + i] += this.innov2[i];
                    }
                }
                for (let i = 0; i < this.subframeSize; i = i + 1) {
                    this.frmBuf[num16 + i] = this.excBuf[num17 + i];
                }
                let flag39 = this.enhanced && this.submodes[this.submodeID].CombGain > 0;
                if (flag39) {
                    this.filters.Comb_filter(this.excBuf, num17, this.frmBuf, num16, this.subframeSize, num22, array, this.submodes[this.submodeID].CombGain);
                }
                let flag40 = this.enhanced;
                if (flag40) {
                    Filters.Filter_mem2(this.frmBuf, num16, this.awk2, this.awk1, this.subframeSize, this.lpcSize, this.mem_sp, this.lpcSize);
                    Filters.Filter_mem2(this.frmBuf, num16, this.awk3, this.interp_qlpc, this.subframeSize, this.lpcSize, this.mem_sp, 0);
                }
                else {
                    for (let i = 0; i < this.lpcSize; i = i + 1) {
                        this.mem_sp[this.lpcSize + i] = 0;
                    }
                    Filters.Iir_mem2(this.frmBuf, num16, this.interp_qlpc, this.frmBuf, num16, this.subframeSize, this.lpcSize, this.mem_sp);
                }
            }
            xout[0] = this.frmBuf[this.frmIdx] + this.preemph * this.pre_mem;
            for (let i = 1; i < this.frameSize; i = i + 1) {
                xout[i] = this.frmBuf[this.frmIdx + i] + this.preemph * xout[i - 1];
            }
            this.pre_mem = xout[this.frameSize - 1];
            for (let i = 0; i < this.lpcSize; i = i + 1) {
                this.old_qlsp[i] = this.qlsp[i];
            }
            this.first = 0;
            this.count_lost = 0;
            this.last_pitch = num3;
            this.last_pitch_gain = 0.25 * num5;
            let arg_F90_0 = this.pitch_gain_buf;
            let num28 = this.pitch_gain_buf_idx;
            this.pitch_gain_buf_idx = num28 + 1;
            arg_F90_0[num28] = this.last_pitch_gain;
            let flag41 = this.pitch_gain_buf_idx > 2;
            if (flag41) {
                this.pitch_gain_buf_idx = 0;
            }
            this.last_ol_gain = num13;
            result = 0;
        }
        return result;
    }
    DecodeLost(xout) {
        let num = Math.exp(-0.04 * this.count_lost * this.count_lost);
        let num2 = (this.pitch_gain_buf[0] < this.pitch_gain_buf[1]) ? ((this.pitch_gain_buf[1] < this.pitch_gain_buf[2]) ? this.pitch_gain_buf[1]
            : ((this.pitch_gain_buf[0] < this.pitch_gain_buf[2]) ? this.pitch_gain_buf[2] : this.pitch_gain_buf[0]))
            : ((this.pitch_gain_buf[2] < this.pitch_gain_buf[1]) ? this.pitch_gain_buf[1] : ((this.pitch_gain_buf[2] < this.pitch_gain_buf[0]) ? this.pitch_gain_buf[2]
                : this.pitch_gain_buf[0]));
        let flag = num2 < this.last_pitch_gain;
        if (flag) {
            this.last_pitch_gain = num2;
        }
        let num3 = this.last_pitch_gain;
        let flag2 = num3 > 0.95;
        if (flag2) {
            num3 = 0.95;
        }
        num3 = num3 * num;
        NArray.Copy(this.frmBuf, this.frameSize, this.frmBuf, 0, this.bufSize - this.frameSize);
        NArray.Copy(this.excBuf, this.frameSize, this.excBuf, 0, this.bufSize - this.frameSize);
        for (let i = 0; i < this.nbSubframes; i = i + 1) {
            let num4 = this.subframeSize * i;
            let num5 = this.frmIdx + num4;
            let num6 = this.excIdx + num4;
            let flag3 = this.enhanced;
            if (flag3) {
                let num7 = 0.9;
                let flag4 = this.submodes[this.submodeID] !== null;
                let num8;
                let num9;
                if (flag4) {
                    num8 = this.submodes[this.submodeID].LpcEnhK1;
                    num9 = this.submodes[this.submodeID].LpcEnhK2;
                }
                else {
                    num9 = (num8 = 0.7);
                }
                let gamma = (1 - (1 - num7 * num8) / (1 - num7 * num9)) / num7;
                Filters.Bw_lpc(num8, this.interp_qlpc, this.awk1, this.lpcSize);
                Filters.Bw_lpc(num9, this.interp_qlpc, this.awk2, this.lpcSize);
                Filters.Bw_lpc(gamma, this.interp_qlpc, this.awk3, this.lpcSize);
            }
            let num10 = 0;
            for (let j = 0; j < this.frameSize; j = j + 1) {
                num10 = num10 + this.innov[j] * this.innov[j];
            }
            num10 = Math.sqrt((num10 / this.frameSize));
            for (let j = 0; j < this.subframeSize; j = j + 1) {
                this.excBuf[num6 + j] = num3 * this.excBuf[num6 + j - this.last_pitch] + num * Math.sqrt((1 - num3)) * 3 * num10 * (this.random.NextDouble() - 0.5);
            }
            for (let j = 0; j < this.subframeSize; j = j + 1) {
                this.frmBuf[num5 + j] = this.excBuf[num6 + j];
            }
            let flag5 = this.enhanced;
            if (flag5) {
                Filters.Filter_mem2(this.frmBuf, num5, this.awk2, this.awk1, this.subframeSize, this.lpcSize, this.mem_sp, this.lpcSize);
                Filters.Filter_mem2(this.frmBuf, num5, this.awk3, this.interp_qlpc, this.subframeSize, this.lpcSize, this.mem_sp, 0);
            }
            else {
                for (let j = 0; j < this.lpcSize; j = j + 1) {
                    this.mem_sp[this.lpcSize + j] = 0;
                }
                Filters.Iir_mem2(this.frmBuf, num5, this.interp_qlpc, this.frmBuf, num5, this.subframeSize, this.lpcSize, this.mem_sp);
            }
        }
        xout[0] = this.frmBuf[0] + this.preemph * this.pre_mem;
        for (let j = 1; j < this.frameSize; j = j + 1) {
            xout[j] = this.frmBuf[j] + this.preemph * xout[j - 1];
        }
        this.pre_mem = xout[this.frameSize - 1];
        this.first = 0;
        this.count_lost = this.count_lost + 1;
        let arg_47E_0 = this.pitch_gain_buf;
        let num11 = this.pitch_gain_buf_idx;
        this.pitch_gain_buf_idx = num11 + 1;
        arg_47E_0[num11] = num3;
        let flag6 = this.pitch_gain_buf_idx > 2;
        if (flag6) {
            this.pitch_gain_buf_idx = 0;
        }
        return 0;
    }
    DecodeStereo(data, frameSize) {
        this.stereo.Decode(data, frameSize);
    }
}
class NbLspQuant extends LspQuant {
    Quant(lsp, qlsp, order, bits) {
        let array = new Array(20);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] = lsp[i];
        }
        array[0] = 1 / (qlsp[1] - qlsp[0]);
        array[order - 1] = 1 / (qlsp[order - 1] - qlsp[order - 2]);
        for (let i = 1; i < order - 1; i = i + 1) {
            let num = 1 / ((0.15 + qlsp[i] - qlsp[i - 1]) * (0.15 + qlsp[i] - qlsp[i - 1]));
            let num2 = 1 / ((0.15 + qlsp[i + 1] - qlsp[i]) * (0.15 + qlsp[i + 1] - qlsp[i]));
            array[i] = ((num > num2) ? num : num2);
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] -= new Nullable((0.25 * i + 0.25)).Value;
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 256;
        }
        let data = LspQuant.Lsp_quant(qlsp, 0, Codebook_Constants.cdbk_nb, 64, order);
        bits.Pack(data, 6);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= 2;
        }
        data = LspQuant.Lsp_weight_quant(qlsp, 0, array, 0, Codebook_Constants.cdbk_nb_low1, 64, 5);
        bits.Pack(data, 6);
        for (let i = 0; i < 5; i = i + 1) {
            qlsp[i] *= 2;
        }
        data = LspQuant.Lsp_weight_quant(qlsp, 0, array, 0, Codebook_Constants.cdbk_nb_low2, 64, 5);
        bits.Pack(data, 6);
        data = LspQuant.Lsp_weight_quant(qlsp, 5, array, 5, Codebook_Constants.cdbk_nb_high1, 64, 5);
        bits.Pack(data, 6);
        for (let i = 5; i < 10; i = i + 1) {
            qlsp[i] *= 2;
        }
        data = LspQuant.Lsp_weight_quant(qlsp, 5, array, 5, Codebook_Constants.cdbk_nb_high2, 64, 5);
        bits.Pack(data, 6);
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] *= new Nullable(0.00097656).Value;
        }
        for (let i = 0; i < order; i = i + 1) {
            qlsp[i] = lsp[i] - qlsp[i];
        }
    }
    Unquant(lsp, order, bits) {
        for (let i = 0; i < order; i = i + 1) {
            lsp[i] = 0.25 * i + 0.25;
        }
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb, bits, 0.0039062, 10, 0);
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb_low1, bits, 0.0019531, 5, 0);
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb_low2, bits, 0.00097656, 5, 0);
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb_high1, bits, 0.0019531, 5, 5);
        super.UnpackPlus(lsp, Codebook_Constants.cdbk_nb_high2, bits, 0.00097656, 5, 5);
    }
    constructor() {
        super();
    }
}
class NoiseSearch extends CodebookSearch {
    Quantify(target, ak, awk1, awk2, p, nsf, exc, es, r, bits, complexity) {
        let array = new Array(nsf);
        for (let _ai = 0; _ai < array.length; ++_ai)
            array[_ai] = 0;
        Filters.Residue_percep_zero(target, 0, ak, awk1, awk2, array, nsf, p);
        for (let i = 0; i < nsf; i = i + 1) {
            exc[es + i] += array[i];
        }
        for (let i = 0; i < nsf; i = i + 1) {
            target[i] = 0;
        }
    }
    Unquantify(exc, es, nsf, bits) {
        for (let i = 0; i < nsf; i = i + 1) {
            exc[es + i] += (3.0 * (new Random(6).NextDouble() - 0.5));
        }
    }
    constructor() {
        super();
    }
}
class SbCodec extends NbCodec {
    constructor(ultraWide) {
        super();
        this.fullFrameSize = 0;
        this.foldingGain = 0;
        this.high = null;
        this.y0 = null;
        this.y1 = null;
        this.x0d = null;
        this.g0_mem = null;
        this.g1_mem = null;
        if (ultraWide) {
            this.submodes = SbCodec.BuildUwbSubModes();
            this.submodeID = 1;
        }
        else {
            this.submodes = SbCodec.BuildWbSubModes();
            this.submodeID = 3;
        }
    }
    get FrameSize() {
        return this.fullFrameSize;
    }
    get Dtx() {
        return this.dtx_enabled > 0;
    }
    get Exc() {
        let array = new Array(this.fullFrameSize);
        for (let i = 0; i < this.frameSize; i = i + 1) {
            array[2 * i] = 2 * this.excBuf[this.excIdx + i];
        }
        return array;
    }
    get Innov() {
        return this.Exc;
    }
    Initn(frameSize, subframeSize, lpcSize, bufSize, foldingGain_0) {
        super.Init(frameSize, subframeSize, lpcSize, bufSize);
        this.fullFrameSize = 2 * frameSize;
        this.foldingGain = foldingGain_0;
        this.lag_factor = 0.002;
        this.high = new Array(this.fullFrameSize);
        for (let _ai = 0; _ai < this.high.length; ++_ai)
            this.high[_ai] = 0;
        this.y0 = new Array(this.fullFrameSize);
        for (let _ai = 0; _ai < this.y0.length; ++_ai)
            this.y0[_ai] = 0;
        this.y1 = new Array(this.fullFrameSize);
        for (let _ai = 0; _ai < this.y1.length; ++_ai)
            this.y1[_ai] = 0;
        this.x0d = new Array(frameSize);
        for (let _ai = 0; _ai < this.x0d.length; ++_ai)
            this.x0d[_ai] = 0;
        this.g0_mem = new Array(64);
        for (let _ai = 0; _ai < this.g0_mem.length; ++_ai)
            this.g0_mem[_ai] = 0;
        this.g1_mem = new Array(64);
        for (let _ai = 0; _ai < this.g1_mem.length; ++_ai)
            this.g1_mem[_ai] = 0;
    }
    static BuildWbSubModes() {
        let lspQuant = new HighLspQuant();
        let innovation = new SplitShapeSearch(40, 10, 4, Codebook_Constants.hexc_10_32_table, 5, 0);
        let innovation2 = new SplitShapeSearch(40, 8, 5, Codebook_Constants.hexc_table, 7, 1);
        let array = new Array(8);
        array[1] = new SubMode(0, 0, 1, 0, lspQuant, null, null, 0.75, 0.75, -1, 36);
        array[2] = new SubMode(0, 0, 1, 0, lspQuant, null, innovation, 0.85, 0.6, -1, 112);
        array[3] = new SubMode(0, 0, 1, 0, lspQuant, null, innovation2, 0.75, 0.7, -1, 192);
        array[4] = new SubMode(0, 0, 1, 1, lspQuant, null, innovation2, 0.75, 0.75, -1, 352);
        return array;
    }
    static BuildUwbSubModes() {
        let lspQuant = new HighLspQuant();
        let array = new Array(8);
        array[1] = new SubMode(0, 0, 1, 0, lspQuant, null, null, 0.75, 0.75, -1, 2);
        return array;
    }
}
SbCodec.SB_FRAME_SIZE = [
    4, 36, 112, 192, 352, -1, -1, -1
];
SbCodec.SB_SUBMODES = 8;
SbCodec.SB_SUBMODE_BITS = 3;
SbCodec.QMF_ORDER = 64;
export default class SpeexDecoder extends NObject {
    constructor(mode, enhanced = true) {
        super();
        this.sampleRate = 0;
        this.decodedData = null;
        this.bits = null;
        this.decoder = null;
        this.frameSize = 0;
        this.bits = new Bits();
        if (mode !== BandMode.Narrow) {
            this.decoder = new NbDecoder();
            this.sampleRate = 8000;
        }
        else {
            this.decoder = new NbDecoder();
            this.sampleRate = 8000;
        }
        this.decoder.PerceptualEnhancement = enhanced;
        this.frameSize = this.decoder.FrameSize;
        this.decodedData = new Array(this.sampleRate * 2);
        for (let _ai = 0; _ai < this.decodedData.length; ++_ai)
            this.decodedData[_ai] = 0;
    }
    get FrameSize() {
        return this.decoder.FrameSize;
    }
    get SampleRate() {
        return this.sampleRate;
    }
    Decode(inData, inOffset, inCount, outData, outOffset, lostFrame) {
        let flag = this.decodedData.length < outData.length * 2;
        if (flag) {
            this.decodedData = new Array(outData.length * 2);
            for (let _ai = 0; _ai < this.decodedData.length; ++_ai)
                this.decodedData[_ai] = 0;
        }
        let flag2 = lostFrame || inData === null;
        let result;
        if (flag2) {
            this.decoder.Decode(null, this.decodedData);
            let i = 0;
            while (i < this.frameSize) {
                outData[outOffset] = SpeexDecoder.ConvertToShort(this.decodedData[i]);
                i = i + 1;
                outOffset = outOffset + 1;
            }
            result = this.frameSize;
        }
        else {
            this.bits.ReadFrom(inData, inOffset, inCount);
            let num = 0;
            while (this.decoder.Decode(this.bits, this.decodedData) === 0) {
                let j = 0;
                while (j < this.frameSize) {
                    outData[outOffset] = SpeexDecoder.ConvertToShort(this.decodedData[j]);
                    j = j + 1;
                    outOffset = outOffset + 1;
                }
                num = num + this.frameSize;
            }
            result = num;
        }
        return result;
    }
    static ConvertToShort(value) {
        let flag = value > 32767;
        if (flag) {
            value = 32767;
        }
        else {
            let flag2 = value < -32768;
            if (flag2) {
                value = -32768;
            }
        }
        return NMath.Round(value);
    }
}
class SplitShapeSearch extends CodebookSearch {
    constructor(subframesize_0, subvect_size_1, nb_subvect_2, shape_cb_3, shape_bits_4, have_sign_5) {
        super();
        this.subframesize = 0;
        this.subvect_size = 0;
        this.nb_subvect = 0;
        this.shape_cb = null;
        this.shape_cb_size = 0;
        this.shape_bits = 0;
        this.have_sign = 0;
        this.ind = null;
        this.signs = null;
        this.E = null;
        this.t = null;
        this.r2 = null;
        this.e = null;
        this.ot = null;
        this.nt = null;
        this.nind = null;
        this.oind = null;
        this.subframesize = subframesize_0;
        this.subvect_size = subvect_size_1;
        this.nb_subvect = nb_subvect_2;
        this.shape_cb = shape_cb_3;
        this.shape_bits = shape_bits_4;
        this.have_sign = have_sign_5;
        this.ind = new Array(nb_subvect_2);
        for (let _ai = 0; _ai < this.ind.length; ++_ai)
            this.ind[_ai] = 0;
        this.signs = new Array(nb_subvect_2);
        for (let _ai = 0; _ai < this.signs.length; ++_ai)
            this.signs[_ai] = 0;
        this.shape_cb_size = 1 << shape_bits_4;
        this.ot = this.CreateJaggedArray(10, subframesize_0);
        this.nt = this.CreateJaggedArray(10, subframesize_0);
        this.oind = this.CreateJaggedArray(10, nb_subvect_2);
        this.nind = this.CreateJaggedArray(10, nb_subvect_2);
        for (let _ai = 0; _ai < this.nind.length; ++_ai)
            this.nind[_ai] = null;
        this.t = new Array(subframesize_0);
        for (let _ai = 0; _ai < this.t.length; ++_ai)
            this.t[_ai] = 0;
        this.e = new Array(subframesize_0);
        for (let _ai = 0; _ai < this.e.length; ++_ai)
            this.e[_ai] = 0;
        this.r2 = new Array(subframesize_0);
        for (let _ai = 0; _ai < this.r2.length; ++_ai)
            this.r2[_ai] = 0;
        this.E = new Array(this.shape_cb_size);
        for (let _ai = 0; _ai < this.E.length; ++_ai)
            this.E[_ai] = 0;
    }
    CreateJaggedArray(dim1, dim2) {
        let array = new Array(dim1);
        for (let i = 0; i < dim1; i = i + 1) {
            array[i] = new Array(dim2);
            for (let _ai = 0; _ai < array[i].length; ++_ai)
                array[i][_ai] = null;
            NArray.Clear(array[i], 0, dim2);
        }
        return array;
    }
    Quantify(target, ak, awk1, awk2, p, nsf, exc, es, r, bits, complexity) {
        let num = complexity;
        let flag = num > 10;
        if (flag) {
            num = 10;
        }
        let array = new Array(this.shape_cb_size * this.subvect_size);
        let array2 = new Array(num);
        for (let _ai = 0; _ai < array2.length; ++_ai)
            array2[_ai] = 0;
        let array3 = new Array(num);
        for (let _ai = 0; _ai < array3.length; ++_ai)
            array3[_ai] = 0;
        let array4 = new Array(num);
        let array5 = new Array(num);
        let array6 = new Array(num);
        let array7 = new Array(num);
        for (let i = 0; i < num; i = i + 1) {
            for (let j = 0; j < this.nb_subvect; j = j + 1) {
                this.nind[i][j] = (this.oind[i][j] = -1);
            }
        }
        for (let j = 0; j < num; j = j + 1) {
            for (let i = 0; i < nsf; i = i + 1) {
                this.ot[j][i] = target[i];
            }
        }
        for (let i = 0; i < this.shape_cb_size; i = i + 1) {
            let num2 = i * this.subvect_size;
            let num3 = i * this.subvect_size;
            for (let j = 0; j < this.subvect_size; j = j + 1) {
                array[num2 + j] = 0;
                for (let k = 0; k <= j; k = k + 1) {
                    array[num2 + j] += 0.03125 * this.shape_cb[num3 + k] * r[j - k];
                }
            }
            this.E[i] = 0;
            for (let j = 0; j < this.subvect_size; j = j + 1) {
                this.E[i] += array[num2 + j] * array[num2 + j];
            }
        }
        for (let j = 0; j < num; j = j + 1) {
            array5[j] = 0;
        }
        for (let i = 0; i < this.nb_subvect; i = i + 1) {
            let num4 = i * this.subvect_size;
            for (let j = 0; j < num; j = j + 1) {
                array4[j] = 2.14748365E+09;
            }
            for (let j = 0; j < num; j = j + 1) {
                array6[j] = (array7[j] = 0);
            }
            for (let j = 0; j < num; j = j + 1) {
                let num5 = 0;
                for (let l = num4; l < num4 + this.subvect_size; l = l + 1) {
                    num5 = num5 + this.ot[j][l] * this.ot[j][l];
                }
                num5 = num5 * 0.5;
                let flag2 = this.have_sign > 0;
                if (flag2) {
                    VQ.Nbest_sign(this.ot[j], num4, array, this.subvect_size, this.shape_cb_size, this.E, num, array2, array3);
                }
                else {
                    VQ.Nbest(this.ot[j], num4, array, this.subvect_size, this.shape_cb_size, this.E, num, array2, array3);
                }
                for (let k = 0; k < num; k = k + 1) {
                    let num6 = array5[j] + array3[k] + num5;
                    let flag3 = num6 < array4[num - 1];
                    if (flag3) {
                        for (let l = 0; l < num; l = l + 1) {
                            let flag4 = num6 < array4[l];
                            if (flag4) {
                                let m;
                                for (m = num - 1; m > l; m = m - 1) {
                                    array4[m] = array4[m - 1];
                                    array6[m] = array6[m - 1];
                                    array7[m] = array7[m - 1];
                                }
                                array4[l] = num6;
                                array6[m] = array2[k];
                                array7[m] = j;
                                break;
                            }
                        }
                    }
                }
                let flag5 = i === 0;
                if (flag5) {
                    break;
                }
            }
            for (let j = 0; j < num; j = j + 1) {
                for (let l = (i + 1) * this.subvect_size; l < nsf; l = l + 1) {
                    this.nt[j][l] = this.ot[array7[j]][l];
                }
                for (let l = 0; l < this.subvect_size; l = l + 1) {
                    let num7 = 1;
                    let num8 = array6[j];
                    let flag6 = num8 >= this.shape_cb_size;
                    if (flag6) {
                        num7 = -1;
                        num8 = num8 - this.shape_cb_size;
                    }
                    let n = this.subvect_size - l;
                    let num9 = num7 * 0.03125 * this.shape_cb[num8 * this.subvect_size + l];
                    let m = 0;
                    let num10 = num4 + this.subvect_size;
                    while (m < nsf - this.subvect_size * (i + 1)) {
                        this.nt[j][num10] -= num9 * r[m + n];
                        m = m + 1;
                        num10 = num10 + 1;
                    }
                }
                for (let n = 0; n < this.nb_subvect; n = n + 1) {
                    this.nind[j][n] = this.oind[array7[j]][n];
                }
                this.nind[j][i] = array6[j];
            }
            let array8 = this.ot;
            this.ot = this.nt;
            this.nt = array8;
            for (let j = 0; j < num; j = j + 1) {
                for (let l = 0; l < this.nb_subvect; l = l + 1) {
                    this.oind[j][l] = this.nind[j][l];
                }
            }
            for (let j = 0; j < num; j = j + 1) {
                array5[j] = array4[j];
            }
        }
        for (let i = 0; i < this.nb_subvect; i = i + 1) {
            this.ind[i] = this.nind[0][i];
            bits.Pack(this.ind[i], this.shape_bits + this.have_sign);
        }
        for (let i = 0; i < this.nb_subvect; i = i + 1) {
            let num11 = 1;
            let num12 = this.ind[i];
            let flag7 = num12 >= this.shape_cb_size;
            if (flag7) {
                num11 = -1;
                num12 = num12 - this.shape_cb_size;
            }
            for (let j = 0; j < this.subvect_size; j = j + 1) {
                this.e[this.subvect_size * i + j] = num11 * 0.03125 * this.shape_cb[num12 * this.subvect_size + j];
            }
        }
        for (let j = 0; j < nsf; j = j + 1) {
            exc[es + j] += this.e[j];
        }
        Filters.Syn_percep_zero(this.e, 0, ak, awk1, awk2, this.r2, nsf, p);
        for (let j = 0; j < nsf; j = j + 1) {
            target[j] -= this.r2[j];
        }
    }
    Unquantify(exc, es, nsf, bits) {
        for (let i = 0; i < this.nb_subvect; i = i + 1) {
            let flag = this.have_sign > 0;
            if (flag) {
                this.signs[i] = bits.Unpack(1);
            }
            else {
                this.signs[i] = 0;
            }
            this.ind[i] = bits.Unpack(this.shape_bits);
        }
        for (let i = 0; i < this.nb_subvect; i = i + 1) {
            let num = 1;
            let flag2 = this.signs[i] > 0;
            if (flag2) {
                num = -1;
            }
            for (let j = 0; j < this.subvect_size; j = j + 1) {
                exc[es + this.subvect_size * i + j] += num * 0.03125 * this.shape_cb[this.ind[i] * this.subvect_size + j];
            }
        }
    }
}
SplitShapeSearch.MAX_COMPLEXITY = 10;
class Stereo extends NObject {
    constructor() {
        super();
        this.balance = 0;
        this.e_ratio = 0;
        this.smooth_left = 0;
        this.smooth_right = 0;
        this.smooth_right = 1;
        this.smooth_left = 1;
        this.e_ratio = 0.5;
        this.balance = 1;
    }
    static Encode(bits, data, frameSize) {
        let num = 0;
        let num2 = 0;
        let num3 = 0;
        for (let i = 0; i < frameSize; i = i + 1) {
            num = num + data[2 * i] * data[2 * i];
            num2 = num2 + data[2 * i + 1] * data[2 * i + 1];
            data[i] = 0.5 * (data[2 * i] + data[2 * i + 1]);
            num3 = num3 + data[i] * data[i];
        }
        let num4 = (num + 1) / (num2 + 1);
        let ins = num3 / (1 + num + num2);
        bits.Pack(14, 5);
        bits.Pack(9, 4);
        num4 = (4.0 * NMath.Log(num4));
        let flag = num4 > 0;
        if (flag) {
            bits.Pack(0, 1);
        }
        else {
            bits.Pack(1, 1);
        }
        num4 = Math.floor((0.5 + Math.abs(num4)));
        let flag2 = num4 > 30;
        if (flag2) {
            num4 = 31;
        }
        bits.Pack(num4, 5);
        let data2 = VQ.Index(ins, Stereo.e_ratio_quant, 4);
        bits.Pack(data2, 2);
    }
    Decode(data, frameSize) {
        let num = 0;
        for (let i = frameSize - 1; i >= 0; i = i - 1) {
            num = num + data[i] * data[i];
        }
        let num2 = num / this.e_ratio;
        let num3 = num2 * this.balance / (1 + this.balance);
        let num4 = num2 - num3;
        num3 = Math.sqrt((num3 / (num + 0.01)));
        num4 = Math.sqrt((num4 / (num + 0.01)));
        for (let i = frameSize - 1; i >= 0; i = i - 1) {
            let num5 = data[i];
            this.smooth_left = 0.98 * this.smooth_left + 0.02 * num3;
            this.smooth_right = 0.98 * this.smooth_right + 0.02 * num4;
            data[2 * i] = this.smooth_left * num5;
            data[2 * i + 1] = this.smooth_right * num5;
        }
    }
    Init(bits) {
        let num = 1;
        let flag = bits.Unpack(1) > 0;
        if (flag) {
            num = -1;
        }
        let num2 = bits.Unpack(5);
        this.balance = Math.exp(num * 0.25 * num2);
        num2 = bits.Unpack(2);
        this.e_ratio = Stereo.e_ratio_quant[num2];
    }
}
Stereo.SPEEX_INBAND_STEREO = 9;
Stereo.e_ratio_quant = [
    0.25, 0.315, 0.397, 0.5
];
class SubMode extends NObject {
    constructor(lbrPitch, forcedPitchGain, haveSubframeGain, doubleCodebook, lspQuant, ltp, innovation, lpcEnhK1, lpcEnhK2, combGain, bitsPerFrame) {
        super();
        this.lbrPitch = 0;
        this.forcedPitchGain = 0;
        this.haveSubframeGain = 0;
        this.doubleCodebook = 0;
        this.lsqQuant = null;
        this.ltp = null;
        this.innovation = null;
        this.lpcEnhK1 = 0;
        this.lpcEnhK2 = 0;
        this.combGain = 0;
        this.bitsPerFrame = 0;
        this.lbrPitch = lbrPitch;
        this.forcedPitchGain = forcedPitchGain;
        this.haveSubframeGain = haveSubframeGain;
        this.doubleCodebook = doubleCodebook;
        this.lsqQuant = lspQuant;
        this.ltp = ltp;
        this.innovation = innovation;
        this.lpcEnhK1 = lpcEnhK1;
        this.lpcEnhK2 = lpcEnhK2;
        this.combGain = combGain;
        this.bitsPerFrame = bitsPerFrame;
    }
    get LbrPitch() {
        return this.lbrPitch;
    }
    get ForcedPitchGain() {
        return this.forcedPitchGain;
    }
    get HaveSubframeGain() {
        return this.haveSubframeGain;
    }
    get DoubleCodebook() {
        return this.doubleCodebook;
    }
    get LsqQuant() {
        return this.lsqQuant;
    }
    get Ltp() {
        return this.ltp;
    }
    get Innovation() {
        return this.innovation;
    }
    get LpcEnhK1() {
        return this.lpcEnhK1;
    }
    get LpcEnhK2() {
        return this.lpcEnhK2;
    }
    get CombGain() {
        return this.combGain;
    }
    get BitsPerFrame() {
        return this.bitsPerFrame;
    }
}
class VQ extends NObject {
    static Index(ins0, codebook, entriesOrLen, entries) {
        if (arguments.length === 3 && (ins0 === null || ins0.constructor === Number) && (codebook === null || codebook instanceof Array) && (entriesOrLen === null || entriesOrLen.constructor === Number)) {
            return VQ.Index_0(ins0, codebook, entriesOrLen);
        }
        return VQ.Index_1(ins0, codebook, entriesOrLen, entries);
    }
    static Index_0(ins0, codebook, entries) {
        let num = 0;
        let result = 0;
        for (let i = 0; i < entries; i = i + 1) {
            let num2 = ins0 - codebook[i];
            num2 = num2 * num2;
            let flag = i === 0 || num2 < num;
            if (flag) {
                num = num2;
                result = i;
            }
        }
        return result;
    }
    static Index_1(ins0, codebook, len, entries) {
        let num = 0;
        let num2 = 0;
        let result = 0;
        for (let i = 0; i < entries; i = i + 1) {
            let num3 = 0;
            for (let j = 0; j < len; j = j + 1) {
                let num4 = ins0[j] - codebook[num++];
                num3 = num3 + num4 * num4;
            }
            let flag = i === 0 || num3 < num2;
            if (flag) {
                num2 = num3;
                result = i;
            }
        }
        return result;
    }
    static Nbest(ins0, offset, codebook, len, entries, E, N, nbest, best_dist) {
        let num = 0;
        let num2 = 0;
        for (let i = 0; i < entries; i = i + 1) {
            let num3 = 0.5 * E[i];
            for (let j = 0; j < len; j = j + 1) {
                num3 = num3 - ins0[offset + j] * codebook[num++];
            }
            let flag = i < N || num3 < best_dist[N - 1];
            if (flag) {
                let num4 = N - 1;
                while (num4 >= 1 && (num4 > num2 || num3 < best_dist[num4 - 1])) {
                    best_dist[num4] = best_dist[num4 - 1];
                    nbest[num4] = nbest[num4 - 1];
                    num4 = num4 - 1;
                }
                best_dist[num4] = num3;
                nbest[num4] = i;
                num2 = num2 + 1;
            }
        }
    }
    static Nbest_sign(ins0, offset, codebook, len, entries, E, N, nbest, best_dist) {
        let num = 0;
        let num2 = 0;
        for (let i = 0; i < entries; i = i + 1) {
            let num3 = 0;
            for (let j = 0; j < len; j = j + 1) {
                num3 = num3 - ins0[offset + j] * codebook[num++];
            }
            let flag = num3 > 0;
            let num4;
            if (flag) {
                num4 = 1;
                num3 = -num3;
            }
            else {
                num4 = 0;
            }
            num3 = num3 + 0.5 * E[i];
            let flag2 = i < N || num3 < best_dist[N - 1];
            if (flag2) {
                let num5 = N - 1;
                while (num5 >= 1 && (num5 > num2 || num3 < best_dist[num5 - 1])) {
                    best_dist[num5] = best_dist[num5 - 1];
                    nbest[num5] = nbest[num5 - 1];
                    num5 = num5 - 1;
                }
                best_dist[num5] = num3;
                nbest[num5] = i;
                num2 = num2 + 1;
                let flag3 = num4 > 0;
                if (flag3) {
                    nbest[num5] += entries;
                }
            }
        }
    }
    constructor() {
        super();
    }
}
