<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲操作說明編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲操作說明列表</li>
        <li class="active">遊戲操作說明編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>版本名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.version"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>版本類型：
                </label>

                <div class="col-sm-10 btns">
                  <button
                    class="btn  mr-10"
                    type="button"
                    @click="type='電腦版'"
                    :class="{ 'btn-primary': type =='電腦版' }"
                  >
                    電腦版
                  </button>
                  <button
                    class="btn "
                    type="button"
                    @click="type='手機版'"
                    :class="{ 'btn-primary': type =='手機版' }"
                  >
                    手機版
                  </button>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span>{{type}}圖片：
                </label>

                <div class="col-sm-9">
                  <div v-if="type =='電腦版'">

                    <div class="pic d-inline chooseBox"
                      v-if="pcImg">
                      <div
                          class="img-close fa fa-fw fa-close"
                          title="刪除檔案"
                          @click="DeletePic(pcImg.id)"
                      ></div>
                      <img draggable="false" :src="pcImg.filesUrl" />
                    </div>

                    <input v-else
                      accept="image/*"
                      id="pc"
                      type="file"
                      @change="onFileChange($event, 'pc')"
                    />
                  </div>

                  <div v-else>
                    <div class="pic d-inline chooseBox" 
                      v-if="mobileImg">
                      <div
                          class="img-close fa fa-fw fa-close"
                          title="刪除檔案"
                          @click="DeletePic(mobileImg.id)"
                      ></div>
                      <img draggable="false" :src="mobileImg.filesUrl" />
                    </div>

                    <input v-else
                      accept="image/*"
                      id="mobile"
                      type="file"
                      @change="onFileChange($event, 'mobile')"
                    />
                  </div>

                  <div class="help-block">建議尺寸935*525</div>
                  <div class="help-block"  v-if="isCreate">(圖片綁定貼文，新增圖片即新增貼文)</div>
                  <div class="help-block"  v-else>重選檔案，請先刪除圖片</div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> {{type}}說明：
                </label>

                <div class="col-sm-10">
                  <div class="editor" >
                    <tinymce v-model="toggleContent" />
                  </div>
                  
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputSort">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputSort"
                    type="text"
                    v-model="edit.sort"
                  />
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">              
                <button
                  class="btn btn-primary btn-lg"
                  type="button"
                  @click="Save(false)"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      isCreate: false,
      // projectId: "omp_xinstars",

      lang: "zh-tw",
      type: '電腦版',  //電腦版, 手機版
      edit: {
        gid: "", //Id
        version: "", // 名稱
        content: "",  // pc 遊戲館限制
        content_mobile: "",  // mobile 遊戲館限制

        sort: 1, // 排序
        CreateUId:this.$store.state.loginUser.EmployeeId,
        CreateUName:this.$store.state.loginUser.username,
      },

      pcImg: null,
      mobileImg: null,

    };
  },
  created() {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetOperation();
    } else {
      this.isCreate = true;
      this.edit.gid = utils.getTimeId(); 
    }

  },
  computed: {
    ...mapState({
      response: (state) => state.xinGameIntro.response,
    }),
    toggleContent: {
      get() {
        return this.type == "電腦版" ? this.edit.content : this.edit.content_mobile;
      },
      set(value) {
        if (this.type == "電腦版") {
          this.edit.content = value;
        } else {
          this.edit.content_mobile = value;
        }
      }
    },
  },
  watch: {
    response(value) {},
  },
  methods: {
    //取得該遊戲操作說明資料
    GetOperation() {

      let _id = (this.isCreate)? this.edit.gid : this.$route.params.id;

      // console.log('isCreate:', this.isCreate ," ,$route.params.id:",this.$route.params.id
      //   ," ,edit.gid:",this.edit.gid);
      // console.log(_id);

      this.$store
        .dispatch("xinGameIntro/GameOperationQuery",
           {gid: _id})
        .then((result) => {
          console.log(result);
          this.edit = result;
          this.getInitImg(); //files 裡的圖片分類
        });
    },

    getImgs() {
        // let _id = this.isCreate ? this.edit.gid : this.$route.params.id;
        this.$store
            .dispatch("xinGameIntro/GameOperationQuery", { gid: this.edit.gid })
            .then(result => {
                this.edit.files = result.files;
                this.getInitImg();
            });
        
    },

    getInitImg() {
        const pcImgUrl = this.edit.files.find(
            (file) => file.info === "pc"
        );
        this.pcImg = pcImgUrl ? pcImgUrl : null;
        // console.log(this.pcImg);

        const mobileImgUrl = this.edit.files.find(
            (file) => file.info === "mobile"
        );
        this.mobileImg = mobileImgUrl ? mobileImgUrl : null;
        // console.log(this.mobileImg);
    },

    onFileChange(evt , item) {
      var files = evt.target.files;

      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 300000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 300KB，請重新選擇。"
          );
          return;
        }
      }

      let AdPic = document.getElementById(item);
      let Info = item ;  //Info: pc, mobile
      let folderName = "info"; //Module

      

      if (!this.isEdit) {
        //新增 creat 
        // console.log('run creat ');
        // console.log(this.edit);
        this.$store.dispatch("xinGameIntro/GameOperationCreat", this.edit)
        .then((res) => {
          this.isEdit = true;          
          this.uploadFile( 
            AdPic.files[0], 
            this.edit.gid, 
            Info ,
            folderName 
          );
        });
        
      } else {
        //編輯
        // console.log('run Edit');
        // console.log(this.edit);
        this.uploadFile( 
            AdPic.files[0], 
            this.edit.gid, 
            Info ,
            folderName 
          );
      }
    },

    uploadFile(files,  id, info, folderName, multi = false) {
      let data = new FormData();

      data.append("files", files);
      data.append("moduleId", id);
      data.append("Info", info);
      data.append("module", folderName);
      //data.append("multi", multi);

      this.$store
        .dispatch("xinGameIntro/xinUploadImg", data)
        .then((res) => {
          if (res.status != "1") {
            this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
          } 
          else {
             this.getImgs();
            // this.GetOperation()
          }
        });
    },

    DeletePic(item) {
        let delOb = { id: item };
        console.log(delOb);

        this.$store.dispatch("xinGameIntro/xinDelImg", delOb).then(() => {
            this.GetOperation();
        });
    },

    //送出
    async Save() {
      this.edit.sort = parseInt(this.edit.sort);
      // if (isNaN(this.edit.sort) || this.edit.sort == 0) this.edit.sort = 1;
        
      if (!this.edit.version) {
        this.$eventBus.$emit("showAlert", "請輸入版本名稱");
        return;
      }

      // if (!this.pcImg) {
      //   this.$eventBus.$emit("showAlert", "請上傳桌機版圖片!!");
      //   return;
      // } 

      if (!this.edit.content) {
        this.$eventBus.$emit("showAlert", "請輸入桌機版操作說明");
        return;
      }

      // if (!this.mobileImg) {
      //   this.$eventBus.$emit("showAlert", "請上傳手機版圖片!!");
      //   return;
      // } 

      if (!this.edit.content_mobile) {
        this.$eventBus.$emit("showAlert", "請輸入手機版操作說明");
        return;
      }

      

      console.log(this.edit);

      if (this.isEdit) {
        this.$store
          .dispatch("xinGameIntro/GameOperationUpdate", this.edit)
          .then((result) => {
            this.backList();
          });
      } 
      else {
        this.$store.dispatch("xinGameIntro/GameOperationCreat", this.edit)
        .then((result) => {
          this.backList();
        });

      }
    },

     //*---- 返回
    backList() {
      this.$router.push({
        name: "XinGameOperationList",
        params: { searchData: this.$route.params.searchData },
      });
    },
    
  },
};
</script>
<style scoped>
.chooseBox .img-close {
  right: -10px !important;
}

</style>