<template>
  <input class="form-control" ref="datepicker" type="text" :disabled="disabled" />
</template>

<script>
import moment from "moment";
// import utils from "@/assets/js/app-utils"; 沒使用到

//文件範例
//http://bsify.admui.com/daterangepicker/s

export default {
  props: {
    propStartDate: "",
    propEndDate: "",
    propTimePicker: false,
    propsIn: "",
    format: {type:String, default:"YYYY/MM/DD HH:mm:ss"}, //預設 輸出 日期格式
    displayFormat: {type:String, default: "YYYY/MM/DD hh:mm A"}, //預設 顯示 日期格式
    disabled: false
  },
  data() {
    return {
      // format: "YYYY/MM/DD",
      format2: "YYYY-MM-DD"
    };
  },
  mounted() {
    
    if (this.propsIn !== 'format2') {
      $(this.$refs.datepicker).daterangepicker({
        locale: {
          format: this.propTimePicker ? this.displayFormat : this.displayFormat.split(" ")[0],
        },
        timePicker: this.propTimePicker,
        timePicker24Hour: !/A/i.test(this.displayFormat),
        startDate: new moment(this.propStartDate),
        endDate: new moment(this.propEndDate)
      });
      $(this.$refs.datepicker).on("apply.daterangepicker", (evt, picker) => {
        let sdate = picker.startDate.format(this.format);
        let edate = picker.endDate.format(this.format);
        this.$emit("update:propStartDate", sdate);
        this.$emit("update:propEndDate", edate);
        this.$emit("update:date", sdate, edate);
        this.$emit("restList");
      });

    } else {
      $(this.$refs.datepicker).daterangepicker({
        locale: {
          format: this.propTimePicker ? "YYYY-MM-DD" : this.format2,
        },
        timePicker: this.propTimePicker,
        startDate: new moment(this.propStartDate),
        endDate: new moment(this.propEndDate)
      });
      $(this.$refs.datepicker).on("apply.daterangepicker", (evt, picker) => {
        let sdate = picker.startDate.format(this.format2);
        let edate = picker.endDate.format(this.format2);
        this.$emit("update:propStartDate", sdate);
        this.$emit("update:propEndDate", edate);
        this.$emit("update:date", sdate, edate);
        this.$emit("restList");
      });
    }


  },
  watch: {}
};
</script>