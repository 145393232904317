<template>
    <div id="RobotList" class="lableFloat">
        <section class="content-header">

            <select class="form-control select-group" 
                style="float: left; width: 180px; margin-right: 10px;"
                 v-model="searchData.專案" 
                 @change="search()">
                <option disabled="disabled" value>請選擇粉絲團</option>
                <option 
                     v-for="(item, index) in fanPageList"
                     :value="item.粉專名稱">
                    {{ item.粉專名稱 }}
                </option>
            </select>
            <h1> FB機器人 - 貼文列表</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/FBRobot/RobotList">FB機器人</router-link>
                </li>
                <li class="active">機器人貼文</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
                            <i class="fa fa-plus"></i> 新增貼文回覆
                        </button>

                        <div class="form-group clearfix">
                            <label class="control-label">搜尋啟用日期：</label>
                            <div class="input-group date">
                                <div class="input-group-addon">
                                    <i class="fa fa-calendar"></i>
                                </div>
                                <DateRangePicker :propEndDate.sync="searchData.結束時間"
                                    :propStartDate.sync="searchData.開始時間" propsIn="format2" />
                            </div>
                        </div>
                        <div class="input-group mr-10">
                            <input class="form-control" placeholder="請輸入貼文註解內容" type="text" v-model="searchData.貼文註解" />
                        </div>

                        <select class="form-control select-group" v-model="searchData.排序">
                            <option :value="item" v-for="(item, index) in filterSelect">
                                {{ item }}
                            </option>
                        </select>

                        <button @click="search()" class="btn btn-default" type="button">
                            查詢
                        </button>

                        <div class="text fr-improtant">
                            <p class="data-num">
                                <span v-if="hasData">總共{{postList.length}}筆貼文</span>
                                <span v-else>總共0筆貼文</span>
                            </p>
                        </div>

                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-bordered min1000">
                            <tbody>
                                <tr class="bg-gray">
                                    <th style="min-width: 50px">編號</th>
                                    <th style="min-width: 200px">貼文註解</th>
                                    <th style="min-width: 90px">留言回覆數</th>
                                    <th style="min-width: 90px">私訊回覆數</th>
                                    <th style="min-width: 80px">狀態</th>
                                    <th style="min-width: 100px">啟用日期</th>
                                    <th style="min-width: 100px">結束日期</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px">
                                        功能
                                    </th>
                                </tr>
                                <tr v-if="!hasData">
                                    <td class="text-center" colspan="9">此搜尋沒有符合的結果</td>
                                </tr>
                                <tr v-else :key="item.代號" v-for="(item, index) in postList">
                                    <td class="text-center">{{ index + 1}}
                                        <br>
                                        <span class="sr-only">{{item.代號}}</span>
                                    </td>
                                    <td>{{ item.貼文註解 | isAnyContent }}</td>
                                    <td class="text-center">{{ item.留言數 | isAnyContent}}</td>
                                    <td class="text-center">{{ item.私訊數 | isAnyContent}}</td>
                                    <td class="text-center toggle">
                                        {{getState(item.狀態)}}

                                    </td>
                                    <td class="text-center">{{ item.啟用時間 | isAnyContent}}</td>
                                    <td class="text-center">{{ item.結束時間 | isAnyContent }}</td>

                                    <td class="text-center">
                                        <button @click="editorItem(item)" class="btn btn-primary mb-5">
                                            編輯
                                        </button>
                                        &nbsp;
                                        <button @click="copy(item.代號)" v-if="item.狀態 !== -1"
                                            class="btn btn-warning mb-5">
                                            複製
                                        </button>
                                        &nbsp;
                                        <button @click="remove(item)" class="btn btn-danger mb-5">
                                            刪除
                                        </button>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="box-footer top-box text-center no-border clearfix">
                    <div class="select-group">
                        <select class="form-control page-size w-a" v-model.number="searchData.pageSize"
                            @change="pageDisplay()">
                            <option value="10">單頁顯示10項</option>
                            <option value="20">單頁顯示20項</option>
                            <option value="40">單頁顯示40項</option>
                            <option value="100">單頁顯示100項</option>
                        </select>
                    </div>
                    <Pagination :pageLimitCount="searchData.pageSize" :total="total" ref="pagination"
                        v-on:pageMethod="onPageChange" />
                </div>

            </div>
        </section>
    </div>
</template>

<script>
    /* eslint-disable */
    import moment from "moment";
    import Pagination from "@/components/Pagination";
    import DateRangePicker from "@/components/DateRangePicker";
    import api from "@/assets/js/app-api";
    import {
        mapState,
        mapActions
    } from "vuex";
    import utils from "@/assets/js/app-utils";

    export default {
        components: {
            Pagination,
            DateRangePicker,
        },
        data() {
            return {
                loading: true,
                total: 0,
                hasData: true,

                fanPageName: "",

                //post 
                searchData: {
                    projectId: "omp_FBRobot",
                    專案: "星城Online",  //預設-網銀Bot應用
                    貼文註解: "",
                    排序: "啟用時間",
                    pageNum: 1,
                    pageSize: 10,
                    狀態: -1, //0：下架 1：上架 2：草稿
                    //貼文狀態: -1, //0否  1是
                    開始時間: new moment().subtract(30, "days").format("YYYY-MM-DD"),
                    結束時間: new moment().add(90, "days").format("YYYY-MM-DD"),
                },

                singlePostData: {},
                啟用時間: new moment().format("YYYY-MM-DD 00:00:00"),
                結束時間: new moment().format("YYYY-MM-DD 23:59:59"),

                //search select
                filterSelect: ["留言數", "私訊數", "狀態", "啟用時間", "結束時間"],

                fanPageList:[  //正式
                    {粉專名稱: "星城Online"},
                    {粉專名稱: "星城好冰友"},
                    {粉專名稱: "Wanin 網銀國際"},
                ],
                // fanPageList:[  //測試
                //     {粉專名稱: "星城好冰友"},
                //     {粉專名稱: "壹貳叁肆伍"},
                //     {粉專名稱: "網銀Bot應用"},
                // ],
            };
        },

        async created() {
           
            if (this.$route.params.searchData != undefined) {
                this.searchData = this.$route.params.searchData;
            }
             console.log(this.searchData.專案);

            // if (this.$route.params.searchData != undefined) {
            //     this.searchData = this.$route.params.searchData.專案;
            // } else {
            //     this.searchData.專案 = "網銀Bot應用";
            // }

            this.getDataList();
            this.getFanPage();
        },

        computed: {
            ...mapState({
                //post list
                postList: state => state.fbRobot.postList,
                postTotal: state => state.fbRobot.postTotal,

                // fanPageList: state => state.fbRobot.fanPageList,

                //copy:for post detail
                detailQuery: (state) => state.fbRobot.postQuery,
            }),
        },
        watch: {
            detailQuery() {
                this.singlePostData = this.detailQuery.keyword;
            }
        },
        methods: {
            search() {
                this.searchData.pageNum = 1;
                this.getDataList();
            },

            getDataList() {
                this.$store.dispatch("fbRobot/search", this.searchData)
                    .then((result) => {
                        // console.log(result);
                        this.loading = false;
                        if (result.message == "查詢失敗") {
                            //更新資料狀態 postList
                            this.hasData = false;

                        } else {
                            this.hasData = true;
                            this.$refs.pagination.setPage(this.searchData.pageNum, this.postTotal);
                        }
                    })
                    .catch((error) => { // 请求失败处理
                        console.log(error);
                    });
            },

            onPageChange(pageIdx, pageSize) {
                this.searchData.pageNum = pageIdx;
                this.searchData.pageSize = pageSize;
                this.getDataList();
            },

            pageDisplay() {
                this.onPageChange(1, Number(this.searchData.pageSize));
            },

            getFanPage() {
                this.$store.dispatch("fbRobot/FanPageList", {
                    "綁定YN": 1   //列出已綁定
                })
            },


            // 0：下架 1：上架 2：草稿
            getState(state) {
                switch (state) {
                    case 0:
                        return '下架'
                        break;

                    case 1:
                        return '上架'
                        break;

                    case 2:
                        return "草稿";
                        break;
                }
            },

            copy(item) {
                this.$eventBus.$emit('showConfirm', '確定要複製這則貼文的關鍵字嗎?', (isOK) => {
                    this.$store.dispatch("fbRobot/copyPost", {
                            "專案": "網銀Bot應用",
                            代號: (Number(item)),
                        })
                        .then((result) => {
                            this.getDataList();
                        });
                });
            },


            addNew() {
                this.$router.push({
                    name: "FBPostKeywordList",
                    params: {
                        searchData: this.searchData,
                        fanPage: this.searchData.專案,
                    },
                });
                return;
            },

            editorItem(item) {
                this.$router.push({
                    name: "FBPostKeywordList",
                    params: {
                        searchData: this.searchData,
                        fanPage: this.searchData.專案,
                        id: item.代號
                    },
                });
            },

            remove(item) {
                this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
                    if (isOK) {
                        this.$store
                            .dispatch("fbRobot/delPost", {
                                opreateId: "omp_FBRobot",
                                "代號": item.代號
                            })
                            .then((result) => {
                                this.getDataList();
                            });
                    }
                });
            },
        },
    };

</script>
