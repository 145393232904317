<template>
	<div id="clientAnalyze" class="slot">
		<section class="content">
			<div class="box box-primary" style="background: none;">
        <div class="box-body">
          <div class="g_back" v-if="$route.params.id">
            <div @click="goBack()">
              <i class="fa fa-chevron-left"></i>
            </div>
            <div>
              <p>{{ query.遊戲名稱 }}遊戲介紹</p>
            </div>
          </div>
					<div class="top-box">
            <div class="left">
              <div class="input-group" v-if="!$route.params.id">
                <input
                  class="form-control pull-right"
                  name="table_search"
                  placeholder="搜尋關鍵字"
                  type="text"
                  v-model="searchData.文章標題"
                />
                <i class="fa fa-close" v-if="searchData.文章標題" @click="searchData.文章標題 = ''"></i>
                <div class="input-group-btn">
                  <button class="btn btn-default" type="button" @click="resetData()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div class="text">
                <p class="data-num">
                  <span>資料筆數：</span>
                  <span id="total">{{ total }}</span>
                </p>
                <div class="dropdown" style="display: inline;">
                  <button
                    class="btn btn-sort dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
                  >
                    <div class="icon-sort"></div>
                    排序依據
                  </button>
                  <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
                    <li>
                      <a
                      :class="['dropdown-item', searchData.排序條件 == 1 ? 'active' : '']"
                      href="javaScript:;"
                      @click="resetData(1, 1)"
                      >
                        最近發布
                      </a>
                    </li>
                    <li>
                      <a
                      :class="['dropdown-item', searchData.排序條件 == 2 ? 'active' : '']"
                      href="javaScript:;"
                      @click="resetData(2, 1)"
                      >
                        最早發布
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Empty v-if="total == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
          <div class="g-list table-responsive mt-20 analyze">
            <div
              v-for="(item, index) in list"
              class="g-card no-bg"
            >
              <AnalyzeCard :item="item"></AnalyzeCard>
            </div>
            <div class="loading-box" v-show="loading || isFirst">
              <div class="loading"></div>
            </div>
          </div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import AnalyzeCard from "@/components/slot/analyzeCard";
import Empty from "@/components/SlotEmpty";
import { mapState } from "vuex";

export default {
  components: {
    AnalyzeCard,
    Empty
  },
  data() {
    return {
      loading: false,
      dropdown: 'none',
      searchData: {
        遊戲編號: Number(this.$route.params.id) || -1, 
        文章標題: "", //文章標題關鍵字
        排序條件: 1, //1:發佈時間,2.點閱數
        排序順序: 1, //1:DESC 2:ASC
        PageNum: 1, 
        PageSize: 12, 
      },
      list: [],
      isFirst: true,
      isEnd: false,
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
      this.searchData.PageNum = 1;
    }
    this.getDataList();
    this.$store.dispatch("slot/gameQuery", { 編號: this.searchData.遊戲編號 })
  },
  computed: {
    ...mapState({
      query: state => state.slot.gameQuery,
      documentList: state => state.slot.documentQueryAll.datas,
      total: state => state.slot.documentQueryAll.total,
    }),
    totolPage() {
      return Math.ceil(this.total / this.searchData.PageSize)
    }
  },
  mounted() {
    $(window).on("scroll", () => {
      this.scrollEvent();
    })
  },
  watch: {
    $route(val) {
      this.searchData.遊戲編號 = Number(val.params.id) || -1;
      this.getDataList();
      this.$store.dispatch("slot/gameQuery", { 編號: this.searchData.遊戲編號 })
    },
    documentList() {
      this.list = this.list.concat(this.documentList);
      $(window).on("scroll", () => {
        this.scrollEvent();
      })
      this.$forceUpdate();
    }
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.$store.dispatch("slot/gameDocumentQueryAll", this.searchData)
        .then((res) => {
          this.loading = false;
          if(res.datas.length < this.searchData.PageSize ||
            this.totolPage == this.searchData.PageNum
          ) 
            this.isFirst = false;
        })
    },
    resetData(order, orderNum) {
      this.isEnd = false;
      this.list = [];
      if(order) this.searchData.排序條件 = order;
      if(orderNum) this.searchData.排序順序 = orderNum;
      this.searchData.PageNum = 1;
      this.dropdown = "none";
      this.getDataList();
    },
    goContent(item, page) {
      this.$router.push({
        name: page,
        params: {
          id: item.編號,
          backSearchData: this.searchData
        }
      });
    },
    goBack() {
      this.$router.push({
        name: 'slotClientGameList',
        params: {
          backSearchData: this.searchData
        }
      });
    },
    scrollEvent() {
      if(this.isEnd) return
      let sectionTop = $(window).scrollTop();
      let sectionH = $(window).height();
      let documentH = $(".loading-box").offset().top;

      if(this.searchData.PageNum < this.totolPage) {
        if(sectionTop + sectionH >= documentH - 150) {
          this.loading = true;
          this.searchData.PageNum = this.searchData.PageNum + 1;
          setTimeout(() => {
            this.getDataList();
          }, 500);
        }
      }
      if(this.searchData.PageNum == this.totolPage) {
        this.isEnd = true;
      }
    }
  },
  beforeDestroy() {
    $(window).off("scroll");
  },
};
</script>
<style lang="scss" scoped>
#clientGameList {
  .top-box {
    .input-group {
      position: relative;
      .fa-close {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;
      }
    }
  }
  .g-card {
    &:hover {
      box-shadow: 0px 0px 7px #b3b3b3;
    }
  }
}
</style>