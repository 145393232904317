<template>
	<div id="clientGameHallList" class="slot">
		<section class="content">
			<div class="box box-primary" style="background: none;">
        <div class="box-body">
					<div class="top-box">
            <div class="left">
              <div class="text" style="margin-left: 0;">
                <div class="dropdown" style="display: inline;">
                  <div class="btn btn-default" style="border-color: #333;">
                    {{ tag }}
                    ({{ total }})
                  </div>
                  <button
                    class="btn btn-sort dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
                  >
                    <div class="icon-sort"></div>
                    排序依據
                  </button>
                  <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
                    <li>
                      <a 
                        :class="['dropdown-item', searchData.排序順序 == 2 ? 'active' : '']"
                        href="#" 
                        @click="resetData(2)"
                      >
                        A to Z
                      </a>
                    </li>
                    <li>
                      <a
                        :class="['dropdown-item', searchData.排序順序 == 1 ? 'active' : '']"
                        href="#" 
                        @click="resetData(1)"
                      >
                      Z to A
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Empty v-if="total == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
          <div class="g-list hall table-responsive mt-20">
            <div
              v-for="(item, index) in list"
              class="g-card"
              style="cursor: pointer;"
            >
              <GameHallCard :item="item"></GameHallCard>
            </div>
            <div class="loading-box" v-if="searchData.PageNum < totolPage">
              <div class="loading"></div>
            </div>
          </div>
				</div>
			</div>
		</section>
    <slotPlayContent ref="slotPlayContent"></slotPlayContent>
	</div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import Empty from "@/components/SlotEmpty";
import slotPlayContent from "@/components/slot/slotPlayContent";
import GameHallCard from "@/components/slot/gameHallCard";

export default {
  components: {
    Empty,
    slotPlayContent,
    GameHallCard
  },
  data() {
    return {
      loading: false,
      dropdown: 'none',
      searchData: {
        標籤編號: this.$route.params.id,//查詢標籤
        PageNum: 1,
        PageSize: 12,
        排序條件: 2,//1:更新時間,2.遊戲館名稱
        排序順序: 2,//1:DESC,2:ASC
      },
      tag: "",
      list: [],
      isEnd: false
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
      this.searchData.PageNum = 1;
    }

    this.getDataList();
    this.getTagData();
  },
  computed: {
    ...mapState({
      gameHallList: state => state.slot.gameHallQueryAll.datas,
      total: state => state.slot.gameHallQueryAll.total,
    }),
    totolPage() {
      return Math.ceil(this.total / this.searchData.PageSize)
    }
  },
  mounted() {
    $(window).on("scroll", () => {
      this.scrollEvent();
    })
  },
  watch: {
    $route() {
      this.searchData.標籤編號 = this.$route.params.id;
      this.resetData();
      this.getTagData();
    },
    gameHallList() {
      this.list = this.list.concat(this.gameHallList);
      $(window).on("scroll", () => {
        this.scrollEvent();
      })
      this.$forceUpdate();
    }
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.$store.dispatch("slot/gameHallQueryAll", this.searchData)
        .then((res) => {
          this.loading = false;
        })
    },
    getTagData() {
      api.slotGame.tag.query({
        編號: Number(this.searchData.標籤編號),
      })
        .then((res) => {
          this.tag = res.data.result.標籤名稱
          this.$forceUpdate();
        })
    },
    resetData(order) {
      this.list = [];
      this.isEnd = false;
      this.searchData.PageNum = 1;
      if(order) this.searchData.排序順序 = order;
      this.dropdown = "none";
      this.getDataList();
    },
    goBack() {
      this.$router.push({
        name: "slotClientGameList",
        params: {
          searchData: this.$route.params.backSearchData,
        }
      });
    },
    goContent(data) {
      this.$router.push({
        name: "clientGameContent",
        params: {
          gameId: data.遊戲編號,
          id: data.編號,
          backSearchData: this.searchData,
          back: 'slotSearchTag',
          search: this.searchData.標籤編號
        }
      });
    },
    showPlay(id) {
      if(id) this.$refs.slotPlayContent.getData(id);
      else this.$refs.slotPlayContent.showContent = true;
    },
    scrollEvent() {
      if(this.isEnd) return
      let sectionTop = $(window).scrollTop();
      let sectionH = $(window).height();
      let documentH = $(".loading-box").offset().top;

      if(this.searchData.PageNum < this.totolPage) {
        if(sectionTop + sectionH >= documentH + 300) {
          this.searchData.PageNum = this.searchData.PageNum + 1;
          if(!this.loading) this.getDataList();
        }
      }
      if(this.searchData.PageNum == this.totolPage) {
        this.isEnd = true;
      }
    }
  },
  beforeDestroy() {
    $(window).off("scroll");
  },
};
</script>