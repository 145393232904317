<template>
    <div id="AnnouncementEdit">
        <section class="content-header">
            <h1>公告編輯</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>公告列表</li>
                <li class="active">公告編輯</li>
            </ol>
        </section>

        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">編輯內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button"><i class="fa fa-reply"></i> 返回列表</button>
                    </div>

                    <form class="form-horizontal" name="announcementForm">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-2 control-label text-nowrap"><span class="text-red">*</span> 主分類：</label>
                                <div class="col-sm-10">
                                    <select class="form-control" id="selectUnit" required="required" v-model="edit.相關公告編號" @change="changeType">
                                        <option disabled="disabled" value>請選擇主分類</option>
                                        <option :value="item.id" v-for="(item, index) in projectData.module"
                                            :key="index" v-show=" item.id == 'action' ||  item.id == 'news'">
                                            {{item.name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label text-nowrap"><span class="text-red">*</span> 子分類：</label>
                                <div class="col-sm-10">
                                    <select class="form-control" v-model="edit.classTypeId">
                                        <option disabled="disabled" value>請選擇子分類</option>
                                        <option :key="index" :value="item.classTypeId"
                                            v-for="(item, index) in newsTypes.datas">{{item.className}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" v-if="edit.projectId === 'omp_heti'">
                                <label class="col-sm-2 control-label" for="inputTitle">
                                    <span class="text-red">*</span> 標題：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="inputTitle" required="required" type="text"
                                        v-model="edit.detailTopTitle" />
                                </div>
                            </div>

                            <div class="form-group" v-if="edit.projectId !== 'omp_heti'">
                                <label class="col-sm-2 control-label" for="inputTitle">
                                    <span class="text-red">*</span> 標題：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="inputTitle" required="required" type="text"
                                        v-model="edit.detailTopTitle" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="editor">
                                    <span class="text-red">*</span> 內容：
                                </label>
                                <div class="col-sm-10">
                                    <div class="editor">
                                        <!-- 工具欄容器 -->
                                        <!-- <div class="toolbar-container"></div> -->
                                        <!-- 編輯器容器 -->
                                        <!-- <div class="CKEditor"></div> -->
                                        <tinymce v-model="edit.detailTopInfo" :propLang="editLang" />
                                    </div>
                                </div>
                            </div>

                            <template v-if="isSpecialPage">
                                <div class="form-group">
                                    <label class="col-sm-2 control-label"><span class="text-red">*</span> 起始日期：</label>
                                    <div class="col-sm-10">
                                        <div class="radio radio-inline" style="padding-left: 0;">
                                            <iCheckRedioButton checkValue="1" message="即日起" name="radioisNowOn" v-model="edit.isNowOn" />
                                        </div>
                                        <div class="radio radio-inline _time">
                                            <iCheckRedioButton checkValue="0" message="" name="radioisNowOn" v-model="edit.isNowOn" />
                                            <div class="radio-date">
                                                <div class="input-group date">
                                                    <div class="input-group-addon">
                                                        <i class="fa fa-calendar"></i>
                                                    </div>
                                                    <div class="pull-right">
                                                        <DatePicker :propValue.sync="local.startTime" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-sm-2 control-label">
                                        <span class="text-red">*</span> 活動結束時間：
                                    </label>
                                    <div class="col-sm-10">
                                        <div class="radio-date">
                                            <div class="input-group date">
                                                <div class="input-group-addon">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                                <div class="pull-right">
                                                    <DatePicker :propValue.sync="local.endTime" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    圖片：
                                </label>
                                <div class="col-sm-10">
                                    <div class="pic">
                                        <img draggable="false" :src="edit.filesUrl" />
                                    </div>
                                    <input accept="image/*" id="AnnouncementPic2" type="file"
                                        @change="onFileChange($event)" />
                                    <div class="help-block">
                                        <span class="text-red">{{picLimit}}</span>
                                    </div>
                                    <div class="row ann-imgChoose mt-10">
                                        <div class="col-sm-3 col-xs-6 chooseBox" v-for="(item, index) in imgList"
                                            :key="index" v-show="item.isMain == 1">
                                            <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div>
                                            <img :src="item.imgFullFileName" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">                                 
                                    檔案上傳：
                                </label>
                                <div class="col-sm-10">
                                    <div class="pic">
                                        <img draggable="false" :src="edit.filesUrl2" />
                                    </div>
                                    <input accept=".pdf" id="AnnouncementPic" type="file"
                                        @change="onFileChange2($event)" :multiple="true" />
                                    <span class="text-red">
                                        PDF格式，單一檔案上限10MB
                                    </span>

                                    <div class="row ann-imgChoose mt-10" style="    margin: 0;">
                                        <div class="chooseBox" v-for="(item, index) in imgList" :key="index"
                                            v-show="item.isMain == 0">
                                            <div
                                                style="    display: inline-block;    padding-right: 34px;    position: relative;">
                                                <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"
                                                    style="    top: 0;"></div>
                                                <div class="filename">{{item.imgName}}</div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="editor">
                                    <span class="text-red">*</span> 標籤：
                                </label>
                                <div class="col-sm-10">
                                    <multiselect placeholder="自行輸入標籤" label="Tag" track-by="Tag" required
                                        :options="tagList" :hide-selected="true" :internal-search="false"
                                        :multiple="true" :taggable="true" :close-on-select="false"
                                        :clear-on-select="false" v-model="tag" selectLabel="" @tag="addTag"
                                        max-elements="已達標籤上限">
                                        <!-- @search-change="updateTags" -->
                                        <span class="notice" slot="noOptions">列表為空</span>
                                        <span class="notice" slot="noResult">查無結果</span>
                                        <span class="notice maxElements" slot="maxElements">
                                            已達標籤上限
                                        </span>
                                        <template slot="option" slot-scope="props">
                                            <div class="option__desc">
                                                <span class="option__title">
                                                    {{ props.option.Tag }}
                                                </span>
                                            </div>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 發佈：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio-date">
                                        <div class="radio">
                                            <iCheckRedioButton checkValue="0" message="上架" name="radioMode"
                                                v-model="edit.isStop" />
                                        </div>
                                        <!-- <template v-if="edit.projectId !== 'omp_heti'"> -->
                                        <div class="input-group date">
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div class="pull-right">
                                                <DatePicker :propValue.sync="local.publishTime" />
                                            </div>
                                        </div>
                                        <div class="input-group time">
                                            <div class="input-group-addon">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                            <input class="form-control timepicker" type="text" />
                                        </div>
                                        <!-- </template> -->
                                    </div>
                                    <div class="radio">
                                        <iCheckRedioButton checkValue="1" message="下架" name="radioMode"
                                            v-model="edit.isStop" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="edit.projectId == 'omp_wanin'">
                                <label class="col-sm-2 control-label" for="inputTitle">
                                    網銀官網Link：
                                </label>
                                <div>
                                    <div class="col-sm-5">
                                        <input class="form-control" id="inputNewsId1" type="text"
                                            v-model="splitNewsIdList.link1" @change="ChangeNewsId($event)" />
                                    </div>
                                    <div class="col-sm-5">
                                        <input class="form-control" id="inputNewsId2" type="text"
                                            v-model="splitNewsIdList.link2" @change="ChangeNewsId($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-warning btn-lg" style="margin-right: 10px;" type="button"
                                    @click="Save(true)" v-if="edit.isStop == 1 ">預覽</button>
                                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">確認送出</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    /* eslint-disable */
    import DatePicker from "@/components/DatePicker";
    import CKEditor from "@/components/CKEditor";
    import iCheckCheckBox from "@/components/iCheck_CheckBox";
    import iCheckRedioButton from "@/components/iCheckRedioButton";
    import utils from "@/assets/js/app-utils";
    import {
        mapState,
        mapActions
    } from "vuex";
    import tinymce from "@/components/Tinymce";
    import config from "@/assets/js/app-config";
    import moment from "moment";
    import multiselect from "vue-multiselect";
    import api from "@/assets/js/app-api";

    export default {
        components: {
            iCheckCheckBox,
            iCheckRedioButton: iCheckRedioButton,
            CKEditor,
            DatePicker,
            tinymce,
            multiselect
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                timer: null,
                isEdit: false,
                editLang: "zh-tw",
                moduleId: "",
                edit: {
                    newsId: utils.getTimeId(), //公告Id
                    projectId: this.$store.state.projectId, // 專案Id
                    classTypeId: "", //分類Id
                    lang: this.editLang, // 語系
                    newsDetailId: "", // 內容Id
                    detailTopTitle: "", //主旨
                    detailTopInfo: "", // 內容
                    isStop: 1, // 發佈
                    isTop: 0, // 置頂
                    isNowOn: 1, //0:參考startTime ; 1:即日起
                    publishTime: "",
                    相關公告編號: '',
                    startTime: '',
                    endTime: '',
                },
                local: {
                    startTime: new moment().format("YYYY-MM-DD"),
                    endTime: new moment().format("YYYY-MM-DD"),
                    publishTime: new moment().format("YYYY-MM-DD"),
                },
                langTypes: {},
                timeStringOption: {
                    timeStyle: "short"
                },
                timepickerOption: {},
                detailNewsLang: [],
                imgList: [],
                fileList: [],
                isPreview: false,
                picLimit: "圖片建議尺寸 700 * 530。",
                url: config.dkOption.url,
                splitNewsIdList: {
                    link1: "",
                    link2: "",
                },
                isSpecialPage: this.$route.name == 'HetiSpecialOfferEdit' ? true : false,
                tag: [],
                tagList: []
            };
        },
        created() {
            this.checkSession();
            this.GetAllLang();


        },
        mounted() {
        
            this.timepickerOption.showInputs = false;
            this.GetTagList();
            
            $(".timepicker").timepicker(this.timepickerOption);
            //clear
            this.$store.dispatch("classTypes/classTypeQueryAll", {
                module: 'null',
                isDelete: 0,
                isStop: 0,
            });
        },
        computed: {
            ...mapState({
                newsTypes: (state) => state.classTypes.queryAll,
                news: (state) => state.news.query,
                newsList: (state) => state.news.queryAll,
                newsDetailQueryAll: (state) => state.news.detailQueryAll,
                response: (state) => state.news.response,
                projectData: (state) => state.module.queryAll,
                imgUrlList: (state) => state.news.detailImgList,
                fileUrlList: (state) => state.file.queryApplyAll,
            }),
        },
        watch: {
            news() {
                this.edit = this.news;
                if (this.edit.publishTime != "") {
                    let time = new Date(this.edit.publishTime).toLocaleDateString()
                    this.local.publishTime = new moment(time).format("YYYY-MM-DD")
                    // $(".datepicker").val(
                    //   new Date(this.edit.publishTime).toLocaleDateString()
                    // );
                    $(".timepicker").val(
                        new Date(this.edit.publishTime).toLocaleTimeString("en-US", this.timeStringOption)
                    );
                }
                if (this.edit.startTime != "" && this.edit.startTime !== null) {
                    let time = new Date(this.edit.startTime).toLocaleDateString()
                    this.local.startTime = new moment(time).format("YYYY-MM-DD")
                }
                if (this.edit.endTime != "" && this.edit.endTime !== null) {
                    let time = new Date(this.edit.endTime).toLocaleDateString()
                    this.local.endTime = new moment(time).format("YYYY-MM-DD")
                }
                this.GetAnnouncementLang();

            },

            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;

                this.GetNewsClassTypes();
                this.imgList = [];
                if (this.$route.params.id > 0) {
                    this.isEdit = true;
                    this.GetAnnouncement();
                } else {
                    this.detailNewsLang = [];
                    for (var i = 0; i < this.langTypes.length; i++) {
                        const detailId = this.edit.newsId + i + 1;
                        this.detailNewsLang.push({
                            newsDetailId: detailId,
                            lang: this.langTypes[i].id,
                            title: this.edit.detailTopTitle,
                            info: this.edit.detailTopInfo,
                            introduction: this.edit.introduction,
                            keyword: this.edit.keyword,
                            description: this.edit.description,

                        });
                    }
                }
                
            },
            newsDetailQueryAll() {
                let that = this;
                this.detailNewsLang = [];
                if (this.newsDetailQueryAll.total > 0) {
                    for (var i = 0; i < this.langTypes.length; i++) {
                        let findLang = false;
                        const Id = this.edit.newsId + i + 1;
                        this.newsDetailQueryAll.datas.some((newsDetail) => {
                            if (that.langTypes[i].id == newsDetail.lang) {
                                if (that.langTypes[i].id == this.editLang) {
                                    that.edit.newsDetailId = newsDetail.newsDetailId;
                                    that.edit.detailTopTitle = newsDetail.title;
                                    that.edit.detailTopInfo = newsDetail.info;
                                    that.edit.introduction = newsDetail.introduction;
                                    that.edit.keyword = newsDetail.keyword;
                                    that.edit.description = newsDetail.description;
                                }
                                if (this.isEdit){
                                    this.$store.dispatch("classTypes/classTypeQueryAll", {
                                        module: this.moduleId,
                                        isDelete: 0,
                                        isStop: 0,
                                    });
                                }                         
                                that.detailNewsLang.push({
                                    newsDetailId: newsDetail.newsDetailId,
                                    lang: newsDetail.lang,
                                    title: newsDetail.title,
                                    info: newsDetail.info,
                                });
                                findLang = true;
                                return true;
                            }
                        });

                        if (!findLang) {
                            that.detailNewsLang.push({
                                newsDetailId: Id,
                                lang: that.langTypes[i].id,
                                title: "",
                                info: "",
                            });
                        }
                    }
                    this.GetImgList();
                }
            },
            response(value) {
                if (value.status == "1") {
                    this.$eventBus.$emit("showAlert", "儲存成功!!");
                    if (!this.isPreview)
                        this.$router.push({
                            name: "XacAnnouncementList",
                            params: {
                                searchData: this.$route.params.searchData,
                            },
                        });
                } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
                if (this.isPreview) {                  
                    window.open(`${process.env.VUE_APP_Xac_PATH}/News/${this.edit.newsId}?preview=1`);
                }
            },
            imgUrlList() {
                this.imgList = [];
                this.imgList = this.imgUrlList;
            },
            fileUrlList() {
                this.fileList = [];
                this.fileList = this.fileList;
            },

        },
        methods: {
            //取得該公告資料
            GetAnnouncement() {
                this.$store.dispatch("news/newsQuery", {
                    newsId: this.$route.params.id,
                }).then((res) => {                 
                    this.moduleId=res.result.module;
                });
            },

            //取得該公告多語系資料
            GetAnnouncementLang() {
                this.$store.dispatch("news/newsDetailQueryAll", {
                    newsId: this.$route.params.id,
                });
            },

            //取得分類資料
            GetNewsClassTypes() {
                //分類

                // this.$store.dispatch("classTypes/classTypeQueryAll", {
                //     // module: this.isSpecialPage ? 'special_offer' : "news",
                //     module: "news",
                //     lang: this.editLang,
                //     isDelete: 0,
                //     isStop: 0,
                // });

            },

            //取標籤分類(for kf3)
            GetTagList() {
                api.kf3.tag.queryAll({
                    專案代號: this.edit.projectId,
                    關聯ID: this.$route.params.id || "test",
                    // 標籤編號: -1,
                    // 標籤名稱: "",
                    // 每頁數量: 0,
                    // 頁碼: 0
                }).then((res) => {
                    //console.log(res);
                    res.data.result.datas.forEach(item => this.tagList.push({
                        Tag: item.標籤名稱
                    }));
                    if (this.$route.params.id) res.data.result.datas.forEach(item => this.tag.push({
                        Tag: item.標籤名稱
                    }));
                })
            },

            GetAllLang() {
                this.$store.dispatch("module/moduleQueryAll", {});
            },

            GetImgList() {
                this.SetLangData(this.editLang);
                this.$store.dispatch("news/newsDetailImgList", {
                    newsDetailId: this.edit.newsDetailId,
                });
            },

            GetFileList() {
                this.SetLangData(this.editLang);
                this.$store.dispatch("file/queryApplyAll", {
                    module: 'news',
                    moduleId: this.edit.newsDetailId,
                }).then((res) => {
                    if (res.status != "0") this.fileList = res;
                });

            },

            //取得該語系資料
            GetDetailNewsLang(lang) {
                for (var i = 0; i < this.langTypes.length; i++) {
                    if (lang == this.langTypes[i].id) {
                        this.edit.newsDetailId = this.detailNewsLang[i].newsDetailId;
                        this.edit.detailTopTitle = this.detailNewsLang[i].title;
                        this.edit.detailTopInfo = this.detailNewsLang[i].info;
                        this.edit.introduction = this.detailNewsLang[i].introduction;
                        this.edit.keyword = this.detailNewsLang[i].keyword;
                        this.edit.description = this.detailNewsLang[i].description;

                    }
                }
            },

            //更換語系
            // ChangeLang(lang) {
            //   this.removeFile();
            //   this.removeFile2();
            //   this.SetLangData(this.editLang);
            //   this.GetDetailNewsLang(lang);
            //   this.editLang = lang;
            //   this.GetImgList();
            // },

            //儲存該語系資料
            SetLangData(lang) {
                for (var i = 0; i < this.langTypes.length; i++) {
                    if (this.langTypes[i].id == lang) {
                        this.detailNewsLang[i].title = this.edit.detailTopTitle;
                        this.detailNewsLang[i].info = this.edit.detailTopInfo;
                        this.detailNewsLang[i].introduction = this.edit.introduction;
                        this.detailNewsLang[i].keyword = this.edit.keyword;
                        this.detailNewsLang[i].description = this.edit.description;
                    }
                }
            },

            //檢查Session
            checkSession() {
                if (this.timer) clearInterval(this.timer);
                this.timer = setInterval(() => {
                    this.$store.dispatch("checkSession");
                }, 600000);
            },

            onFileChange(evt) {
                this.SetLangData(this.editLang);
                this.GetDetailNewsLang(this.editLang);
                var files = evt.target.files;
                //console.log(this.imgList.length)
                if (this.$route.params.id > 0 && this.imgUrlList.length != 0) {
                    if (this.imgUrlList[0].isMain == "1") {
                        this.$eventBus.$emit(
                            "showAlert",
                            "注意: 圖片僅限上傳一張, 請先刪除舊照片, 再進行新增。"
                        );
                        evt.target.value = "";
                        return;
                    }
                }

                if (this.$route.params.id  == undefined && this.imgList.length > 0){
                        this.$eventBus.$emit(
                            "showAlert",
                            "注意: 圖片僅限上傳一張, 請先刪除舊照片, 再進行新增。"
                        );
                        evt.target.value = "";
                        return;
                }


                if (files.length > 0) {
                    for (var i = 0; i < files.length; i++) {
                        var isCheckType = true; //是否檢查圖片副檔名
                        var fileSize = files[i].size;
                        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
                        var fileName = files[i].name;

                        if (isCheckType && !re.test(fileName)) {
                            this.$eventBus.$emit(
                                "showAlert",
                                "您選擇的檔案格式不符，請重新選擇。"
                            );
                            return;
                        }

                        if (fileSize > 5000000) {
                            this.$eventBus.$emit(
                                "showAlert",
                                "您選擇的檔案超過上限 5 MB，請重新選擇。"
                            );
                            return;
                        }
                    }
                }

                this.uploadFile(files, "news", this.edit.newsDetailId, true);
            },

            //檔案
            onFileChange2(evt) {
                this.SetLangData(this.editLang);
                this.GetDetailNewsLang(this.editLang);

                var files = evt.target.files;
                // if (files.length > 5) {
                //   this.$eventBus.$emit(
                //     "showAlert",
                //     "您選擇的檔案數量超過5張上限，請重新選擇。"
                //   );
                //   return;
                // }
                if (files.length > 0) {
                    for (var i = 0; i < files.length; i++) {
                        var isCheckType = true; //是否檢查圖片副檔名
                        var fileSize = files[i].size;
                        var re = /\.(pdf)$/i; //允許的副檔名
                        var fileName = files[i].name;

                        if (isCheckType && !re.test(fileName)) {
                            this.$eventBus.$emit(
                                "showAlert",
                                "您選擇的檔案格式不符，請重新選擇。"
                            );
                            return;
                        }

                        if (fileSize > 10000000) {
                            this.$eventBus.$emit(
                                "showAlert",
                                "您選擇的檔案超過上限 10 MB，請重新選擇。"
                            );
                            return;
                        }
                    }
                }

                this.uploadFile2(files, "news", this.edit.newsDetailId, true);
            },

            removeFile() {
                document.getElementById("AnnouncementPic").value = "";
            },
            removeFile2() {
                document.getElementById("AnnouncementPic2").value = "";
            },

            uploadFile(files, folderName, id, multi = false) {
                let data = new FormData();

                for (var i = 0; i < files.length; i++) {
                    data.append("files", files[i]);
                }

                data.append("projectId", 'omp_xac');
                data.append("module", folderName);
                data.append("moduleId", id);
                data.append("lang", this.editLang);
                data.append("isMain", "1"); //預設為主圖
                data.append("multi", multi);

                this.$store.dispatch("file/fileUpload", data).then((res) => {
                    if (res.status != "0") this.GetImgList();
                });
            },

            uploadFile2(files, folderName, id, multi = false) {
                let data = new FormData();

                for (var i = 0; i < files.length; i++) {
                    data.append("files", files[i]);
                }

                data.append("projectId", this.edit.projectId);
                data.append("module", folderName);
                data.append("moduleId", id);
                data.append("multi", multi);
                this.$store.dispatch("file/fileUpload", data).then((res) => {
                    if (res.status != "0") this.GetFileList2();
                });
            },

            SetPicIsMain(item) {
                this.$store.dispatch("file/setIsMain", item).then((res) => {
                    if (res.status != "0") {
                        this.imgList = [];
                        this.GetImgList();
                    }
                });
            },

            DeletePic(item) {
                this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                    if (isOK) {
                        this.$store.dispatch("file/fileDelete", item).then((res) => {
                            if (res.status != "0") {
                                this.GetImgList();
                                this.removeFile();
                            }
                        });
                    }
                });
            },

            DeletePic2(item) {
                this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                    if (isOK) {
                        this.$store.dispatch("file/fileDelete", item).then((res) => {
                            if (res.status != "0") {
                                this.GetFileList2();
                                this.removeFile2();
                            }
                        });
                    }
                });
            },

            GetSplitNewsIdList() {
                var newsIdString = this.edit.相關公告編號; // ex:20210720135039339,20210720135117942
                var arr = newsIdString.split(",");
                this.splitNewsIdList.link1 = arr[0];
                this.splitNewsIdList.link2 = arr[1];
            },
            ChangeNewsId(event) {
                let newsId = event.target.value;
                if (newsId) {
                    let query = {
                        newsId: newsId,
                        isDelete: 0,
                        isStop: 0,
                        skipCommit: true, // 略過commit
                    };
                    this.$store.dispatch("news/newsQuery", query).then((res) => {
                        if (res.status != "1") {
                            event.target.value = "";
                            this.$eventBus.$emit("showAlert", "公告編號：" + newsId + "不存在");
                            return;
                        }
                    });
                } else {
                    event.target.value = "";
                }
            },

            changeType(event) {
                let newsId = event.target.value;
                // this.$store.dispatch("news/newsQueryAll", {
                //   module:newsId,
                //   isDelete: 0,
                //   isStop: 0,
                // });
                this.$store.dispatch("classTypes/classTypeQueryAll", {
                    // module: this.isSpecialPage ? 'special_offer' : "news",
                    module: newsId,
                    isDelete: 0,
                    isStop: 0,
                });
            },
            //*---- 返回
            backList() {
                this.$router.push({
                    name: "XacAnnouncementList",
                    params: {
                        searchData: this.$route.params.searchData,
                    },
                });
            },

            formatTime: function (t) {
                return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
            },

            //送出
            async Save(isPreview) {
                this.isPreview = isPreview;
                this.SetLangData(this.editLang);
                this.edit.isStop = parseInt(this.edit.isStop);

                this.edit.publishTime = this.local.publishTime + " " + $(".timepicker").val();

                if (this.edit.相關公告編號 == "") {
                    this.$eventBus.$emit("showAlert", "請選擇主分類");
                    return;
                }
                if (this.edit.classTypeId == "") {
                    this.$eventBus.$emit("showAlert", "請選擇子分類");
                    return;
                }

                if (this.edit.detailTopTitle == "") {
                    this.$eventBus.$emit("showAlert", "主旨不能為空");
                    return;
                }

                if (this.edit.detailTopInfo == "") {
                    this.$eventBus.$emit("showAlert", "內容不能為空");
                    return;
                }

                if (this.edit.publishTime == "") {
                    this.$eventBus.$emit("showAlert", "發佈時間不可為空");
                    return;
                }
           
                this.edit.publishTime = new Date(this.edit.publishTime + " UTC");
                this.edit.startTime = new Date(this.local.startTime + " UTC");
                this.edit.endTime = new Date(this.local.endTime + " UTC");
                this.edit.isNowOn = parseInt(this.edit.isNowOn)

                if (this.edit.isStop == 1) {
                    this.edit.isTop = 0
                }

                if (this.isEdit) {
                    this.$store.dispatch("news/newsUpdate", {
                        master_model: this.edit,
                        detail_model: this.detailNewsLang,
                        tags: this.tag.map(e => {
                            return e.Tag
                        }),                        
                    });
                } else {
                    this.$store.dispatch("news/newsCreate", {
                        master_model: this.edit,
                        detail_model: this.detailNewsLang,
                        tags: this.tag.map(e => {
                            return e.Tag
                        }),
                    });
                    this.isEdit = true;
                }
            },
            isNewsInList() {
                if (this.isEdit) {
                    let index = this.newsList.datas.findIndex(
                        (news) => news.newsId == this.edit.newsId && news.isStop == 0
                    );
                    if (index != -1) return true;
                }
                return false;
            },
            getNewsIdOrderList(newsList) {
                let newsIdList = [];
                newsList.forEach((item, index) => {
                    // 排除自己
                    if (item.newsId != this.edit.newsId) {
                        newsIdList.push(item.newsId);
                    }
                });
                newsIdList.sort(function (a, b) {
                    return b - a
                });
                return newsIdList;
            },
            GetReateNewsId(queryNewsList, index1, index2) {
                if (!this.splitNewsIdList.link1 && !this.splitNewsIdList.link2) {
                    this.splitNewsIdList.link1 = queryNewsList[index1] ? queryNewsList[index1] : null;
                    this.splitNewsIdList.link2 = queryNewsList[index2] ? queryNewsList[index2] : null;
                } else if (!this.splitNewsIdList.link1) {
                    this.splitNewsIdList.link1 = queryNewsList[index1];
                    if (this.splitNewsIdList.link1 == this.splitNewsIdList.link2) {
                        this.splitNewsIdList.link1 = "";
                    }
                } else if (!this.splitNewsIdList.link2) {
                    this.splitNewsIdList.link2 = queryNewsList[index1];
                    if (this.splitNewsIdList.link1 == this.splitNewsIdList.link2) {
                        this.splitNewsIdList.link2 = "";
                    }
                }
            },
            addTag(newTag) {
                const tag = {
                    Tag: newTag.trim()
                };
                this.tagList.push(tag);
                this.tag.push(tag);
            },
        },
        beforeRouteLeave(to, from, next) {
            if (this.timer) clearInterval(this.timer);
            next();
        },
    };

</script>
<style>
    .radio.radio-inline._time .radio-date {
        position: absolute;
        top: 0;
        left: 50px;
    }

    .radio.radio-inline._time .radio-date .form-control {
        width: 182px;
    }

</style>
