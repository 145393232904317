<template>
    <div>
        <section class="content-header">
            <h1>商品資料設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">商品資料設定</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-header">
                    <div class="input-group-for-multiselect-wrap">
                        <span style="white-space:nowrap">產品：</span>
                        <multiselect class="multiselect-width" placeholder="請選取產品" v-model="searchData.廠商" :options="廠商List"
                            label="資料" track-by="編號" :show-labels="false" :allow-empty="false">
                        </multiselect>
                        <button class="input-group-addon" @click="openFirmPopForm()"><i class="fa fa-pencil"></i></button>
                    </div>
                    <label class="input-group">
                        <span class="input-group-span">商品編號：</span>
                        <input class="form-control" type="text" placeholder="請輸入 商品編號" v-model.trim="searchData.商品編號" />
                    </label>

                    <div class="input-group-for-multiselect-wrap">
                        <span style="white-space:nowrap">介接廠商：</span>
                        <multiselect class="multiselect-width" placeholder="請選取介接廠商" v-model="searchData.介接商"
                            :options="介接商List" label="資料" track-by="編號" :show-labels="false" :allow-empty="false">
                        </multiselect>
                        <button class="input-group-addon" @click="openFactoryPopForm()"><i
                                class="fa fa-pencil"></i></button>
                    </div>
                    <label class="input-group">
                        <span class="input-group-span">ERP品號：</span>
                        <input class="form-control" type="text" placeholder="請輸入 ERP品號" v-model.trim="searchData.ERP品號" />
                    </label>
                    <div class="input-group">
                        <button class=" btn btn-primary" @click="search()">
                            <i class="fa fa-search"></i>
                            查詢
                        </button>
                    </div>
                    <div class="input-group">
                        <button class=" btn btn-warning" @click="$refs.productEdit.openAddForm()"><i class="fa fa-plus"></i>
                            新增 </button>
                    </div>
                </div>
                <div class="box-body">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr class="bg-gray">
                                <th>產品</th>
                                <th>商品編號</th>
                                <th>商品名稱</th>
                                <th>顯示名稱</th>
                                <th>介接廠商</th>
                                <th>來源Key</th>
                                <th>分類</th>
                                <th>彈性點</th>
                                <th>ERP品號</th>
                                <th>ERP名稱</th>
                                <th>ERP庫別</th>
                                <th>帳務幣別</th>
                                <th>開立發票YN</th>
                                <th>停用/啟用</th>
                                <!-- <th>建立日期</th> -->
                                <th>備註</th>
                                <th>功能</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of list" :key="item.編號">
                                <td>{{ item.廠商編號 | filter廠商(廠商List) }}</td>
                                <td>{{ item.商品編號 }}</td>
                                <td>{{ item.商品名稱 }}</td>
                                <td>{{ item.顯示名稱 }}</td>
                                <td>{{ item.介接廠商編號 | filter介接商(介接商List) }}</td>
                                <td>
                                    <div v-for="(來源, sn) of item.來源Key_Array " :key="sn">{{ 來源 }}</div>
                                </td>
                                <td>{{ item.分類 | TransEnum(分類Enum) }}</td>
                                <td>{{ item.彈性點YN | TransYN() }}</td>
                                <td>{{ item.erP品號 }}</td>
                                <td>{{ item.erP名稱 }}</td>
                                <td>{{ item.erP庫別 | TransEnum(ERP庫別Enum) }}</td>
                                <td>{{ item.帳務幣別 }}</td>
                                <td>{{ item.開立發票YN | TransYN() }}</td>
                                <td>
                                    <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                        <input :false-value="true" :true-value="false" name="ckbx-style-8" type="checkbox"
                                            v-model="item.停用YN" />
                                        <label :for="'check' + item.編號"></label>
                                    </div>
                                </td>
                                <!-- <td>{{ item.建立日期 }}</td> -->
                                <td>{{ item.備註 }}</td>
                                <td>
                                    <button class="btn btn-danger" @click="$refs.productEdit.openUpdForm(item)">
                                        編輯
                                    </button>
                                </td>
                            </tr>
                            <tr v-show="list.length == 0">
                                <td colspan="15" style="text-align: center;">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="box-footer text-center no-border">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="searchData.pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading"><i class="fa fa-refresh fa-spin"></i></div>
            </div>
        </section>
        <ProductEdit ref="productEdit" @openFirmPopForm="openFirmPopForm()" @openFactoryPopForm="openFactoryPopForm()"
            @reload="reload">
        </ProductEdit>
        <OneClickPopForm ref="oneClickPopForm">
        </OneClickPopForm>
    </div>
</template>
    
<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import ProductEdit from "@/views/projects/ips/product/ProductEdit";
import OneClickPopForm from "@/views/projects/ips/product/OneClickPopForm";
import TransEnumMixins from '@/assets/js/Tool/TransEnumMixins';
import { 分類Enum, ERP庫別Enum } from "@/store/ips/product";
import Multiselect from "vue-multiselect";

export default {
    mixins: [TransEnumMixins],
    components: {
        Pagination,
        ProductEdit,
        OneClickPopForm,
        Multiselect
    },
    data() {
        let pageSize = 20;
        return {
            分類Enum,
            ERP庫別Enum,
            searchData: {
                廠商: { "編號": -1, "資料": "全部", "類別": "" },
                商品編號: "",//"10200004",
                介接商: { "編號": -1, "資料": "全部", "類別": "" },//-1,
                ERP品號: "",//"AH3000011"
                頁數: 1,
                pageSize,
            },
            list: [],
            total: 0,
        };
    },
    computed: {
        廠商List() {
            let list = this.$store.state.product.products[1];
            list = list ? JSON.parse(JSON.stringify(list)) : [];
            list.unshift({ 編號: -1, 資料: "全部", 類別: "" });

            if (this.searchData.廠商 && this.searchData.廠商.編號) {
                let find廠商 = list.find((廠商item) => { return 廠商item.編號 == this.searchData.廠商.編號; });
                if (find廠商) {
                    this.$set(this.searchData.廠商, '資料', find廠商.資料);
                }
            }
            return list;
        },
        介接商List() {
            let list = this.$store.state.product.products[2];
            list = list ? JSON.parse(JSON.stringify(list)) : [];
            list.unshift({ 編號: -1, 資料: "全部", 類別: "" });

            if (this.searchData.介接商 && this.searchData.介接商.編號) {
                let find介接商 = list.find((介接商item) => { return 介接商item.編號 == this.searchData.介接商.編號; });
                if (find介接商) {
                    this.$set(this.searchData.介接商, '資料', find介接商.資料);
                }
            }

            return list ? list : [];
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
                await this.$store.dispatch("product/GetProductCommonData", { 類別: 1 });
                await this.$store.dispatch("product/GetProductCommonData", { 類別: 2 });
                await this._search();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let res = await api.IPSSetting.ChangeProductDataType({
                    編號: item.編號,
                    停用YN: !item.停用YN
                });
                if (res && res.data && res.data.sucess && res.data.message) {
                    item.停用YN = !item.停用YN;/*res.data.message.停用YN*/ //todo:最好是改成 接收 server的回傳值
                } else {
                    let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                    throw errMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async onPageChange(pageIdx) {
            $(this.$refs.loading).show();
            this.searchData.頁數 = pageIdx;
            await this._search();
            $(this.$refs.loading).fadeOut(400);
        },
        async search() {
            $(this.$refs.loading).show();
            this.searchData.頁數 = 1;
            await this._search();
            $(this.$refs.loading).fadeOut(400);
        },
        async reload() {
            $(this.$refs.loading).show();
            await this._search();
            $(this.$refs.loading).fadeOut(400);
        },
        async _search() {
            let cmd = {
                商品編號: this.searchData.商品編號,//"10200004",
                ERP品號: this.searchData.ERP品號,
                頁數: this.searchData.頁數,
                每頁筆數: this.searchData.pageSize,
            };
            if (this.searchData.廠商.編號 != -1) {
                cmd.廠商編號 = this.searchData.廠商.編號;
            }
            if (this.searchData.介接商.編號 != -1) {
                cmd.介接廠商編號 = this.searchData.介接商.編號;
            }

            let list = [];
            let total = 0;

            try {
                let res = await api.IPSSetting.GetProductData(cmd);
                if (res && res.data && res.data.sucess) {
                    if (res.data && res.data.message) {
                        list = res.data.message.datas ? res.data.message.datas : [];
                        for (let i of list) {
                            if (!Array.isArray(i.來源Key_Array)) {
                                i.來源Key_Array = [];
                            }
                        }
                        total = res.data.message.total ? res.data.message.total : 0;
                    }
                } else {
                    let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                    throw errMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            this.list = list;
            this.$refs.pagination.setPage(
                this.searchData.頁數,
                total
            );
        },
        openFirmPopForm() {
            this.$refs.oneClickPopForm.show(1);
        },
        openFactoryPopForm() {
            this.$refs.oneClickPopForm.show(2);
        },
    },
    filters: {
        filter廠商(value, list) {
            let find = list.find((廠商item) => { return 廠商item.編號 == value; });
            return find ? find.資料 : "";
        },
        filter介接商(value, list) {
            let find = list.find((介接商item) => { return 介接商item.編號 == value; });
            return find ? find.資料 : "";
        },
        TransYN(value) {
            return value ? "Y" : "N";
        },
    }
};
</script>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.multiselect-width {
    width: 250px;
}

.input-group-for-multiselect-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    padding-right: 15px;
    margin-bottom: 5px;
}

.input-group-for-multiselect-wrap>>>.multiselect__single {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
}

.input-group-for-multiselect-wrap>>>.multiselect__input {
    margin: 0;
    white-space: nowrap;
}

.input-group-for-multiselect-wrap>.input-group-addon {
    border-radius: 0;
    border-color: #d2d6de;
    background-color: #fff;
}
</style>