<template>
  <div id="ServiceList02">

    <section class="content-header">
      <h1>服務範疇-真誠關懷/悅讀助學</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">服務範疇-真誠關懷/悅讀助學</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">

          <banner-picker img-help="圖片尺寸為 1400 * 400px，大小請勿超過 2Mb，選完要儲存！">
            標頭圖
          </banner-picker>

        </div>
      </div>
      <div class="box box-primary">
        <div class="box-body">

          <list-table show-list="index title file status" file-help="可上傳所有文件檔案類型">
            相關檔案
          </list-table>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ListTable from "./components/ListTable";
import BannerPicker from "./components/BannerPicker";

export default {
  components:{
    ListTable,
    BannerPicker,
  },
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      detailSalePointLang: [],
      edit: {
        //salePointId: utils.getTimeId(), //銷售據點Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        salePointUrl: "", // 網址
        salePointType: 0, //銷售據點類型
        salePointDetailId: "", // 內容Id
        detailTopTitle: "", //標題
        isStop: 0, // 狀態
        sortId: 0, // 排序
        mainImgFullFileName: "http://okevin.tw/wafun/images/service/service01.jpg", // Banner圖片預覽
        galleryImgFullFileName: [
          {file:"https://picsum.photos/id/1/350/130"},
          {file:"https://picsum.photos/id/19/350/130"},
          {file:"https://picsum.photos/id/42/350/130"}, // 獎項相簿圖片預覽
        ]
      }
    };
  },
  
  methods: {
    //取得該銷售據點資料
    GetSalePointData() {
      this.$store.dispatch("salePoint/salePointQuery", {
        salePointId: this.$route.params.id
      });
    },

    //取得該銷售據點多語系資料
    GetSalePointLangData() {
      this.$store.dispatch("salePoint/salePointDetailQueryAll", {
        salePointId: this.$route.params.id
      });
    },

    //取得分類資料
    GetSalePointClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "salepoint",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    // GetImgList() {
    //   this.SetLangData(this.editLang);
    //   this.$store.dispatch("news/newsDetailImgList", {
    //     newsDetailId: this.edit.newsDetailId
    //   });
    // },

    //取得該語系資料
    GetDetailSalePointLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.salePointDetailId = this.detailSalePointLang[
            i
          ].salePointDetailId;
          this.edit.detailTopTitle = this.detailSalePointLang[i].title;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      // this.removeFile();
      this.SetLangData(this.editLang);
      this.GetDetailSalePointLang(lang);
      this.editLang = lang;
      // this.GetImgList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailSalePointLang[i].title = this.edit.detailTopTitle;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "VideoList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt, fileNumber = 0) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files, fileNumber);
    },
    removeFile() {
      document.getElementById("Pic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.edit.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", "zh-tw");
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data);
    },

    //圖片預覽
    preview(input, fileNumber) {
      if (input && input[0]) {
        let that = this;
        var reader = new FileReader();

        reader.onload = function(e) {
          if ( !fileNumber || fileNumber === 0){
            that.edit.mainImgFullFileName = e.target.result;
          }
          else {
            that.edit.galleryImgFullFileName[fileNumber - 1].file = e.target.result;
          }
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      this.edit.salePointType = parseInt(this.edit.salePointType);
      this.SetLangData(this.editLang);

      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      let folderName = "salepoint";
      let override = false;

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "通路名稱不能為空");
        return;
      }

      if (this.edit.salePointUrl == "") {
        this.$eventBus.$emit("showAlert", "網址不能為空");
        return;
      }

      let AdPic = document.getElementById("Pic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.salePointId,
          false,
          override
        );
      } else if (this.edit.mainImgFullFileName == "") {
        this.$eventBus.$emit("showAlert", "請上傳圖片!!");
        return;
      } else {
        if (this.isEdit) {
          this.$store.dispatch("salePoint/salePointUpdate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        } else {
          this.$store.dispatch("salePoint/salePointCreate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        }
      }
    }
  }
}
</script>
