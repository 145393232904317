<template>
  <div class="modal fade" ref="popForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button" @click="hide()">
            <span aria-hidden="true">×</span>
          </button>

          <h4 class="modal-title" v-if="this.isEdit">修改退貨：{{ edit.訂單編號 }}</h4>
          <h4 class="modal-title" v-else>新增退貨：{{ edit.訂單編號 }}</h4>
        </div>
        <div class="box-body">
          <!-- <div class="form-group">
            <label><span class="text-red">*</span>國碼</label>
            <input type="text" v-model.trim="edit.國碼" class="form-control" placeholder="請輸入國碼">
          </div> -->
          <div class="form-group">
            <label><span class="text-red">*</span>門號</label>
            <input type="text" v-model.trim="edit.門號" class="form-control" placeholder="請輸入門號 ex:886919111111">
          </div>
          <div class="form-group">
            <label><span class="text-red">*</span>收款人</label>
            <input type="text" v-model.trim="edit.收款人" class="form-control" placeholder="請輸入收款人">
          </div>
          <div class="form-group">
            <label><span class="text-red">*</span>退貨金額</label>
            <input type="text" v-model.trim="edit.退貨金額" class="form-control" placeholder="請輸入退貨金額">
          </div>
          <div class="form-group">
            <label>銀行代碼</label>
            <input type="text" v-model.trim="edit.銀行代碼" class="form-control" placeholder="請輸入銀行代碼">
          </div>
          <div class="form-group">
            <label>銀行戶名</label>
            <input type="text" v-model.trim="edit.銀行戶名" class="form-control" placeholder="請輸入銀行戶名">
          </div>
          <div class="form-group">
            <label>銀行分行</label>
            <input type="text" v-model.trim="edit.銀行分行" class="form-control" placeholder="請輸入銀行分行">
          </div>
          <div class="form-group">
            <label>銀行帳號</label>
            <input type="text" v-model.trim="edit.銀行帳號" class="form-control" placeholder="請輸入銀行帳號">
          </div>
          <div class="form-group">
            <label>備註</label>
            <input type="text" v-model.trim="edit.備註" class="form-control" placeholder="請輸入備註">
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="save()">加入</button>
          <button class="btn btn-default pull-right" type="button" @click="hide()">關閉</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import { IPSTool } from '../IPSTool'
export default {
  data() {
    return {
      loading: false,
      isEdit: true,
      edit: {
        訂單編號: "",
        服務編號: "",
        // 國碼: 0,
        門號: 0,
        銀行代碼: "",
        銀行戶名: "",
        銀行分行: "",
        銀行帳號: "",
        收款人: "",
        退貨金額: 0,
        備註: "",
      },
    };
  },
  methods: {
    async save() {
      //檢查資料 Start
      // if (!IPSTool.檢查輸入金額(this.edit.國碼, 0)) {
      //   this.$eventBus.$emit("showAlert", "國碼必須為大於0的正整數");
      //   return;
      // }
      if (!IPSTool.檢查輸入金額(this.edit.門號, 0)) {
        this.$eventBus.$emit("showAlert", "門號必須為大於0的正整數");
        return;
      }
      if (this.edit.收款人 == "") {
        this.$eventBus.$emit("showAlert", "收款人不可為空");
        return;
      }
      if (!IPSTool.檢查輸入金額(this.edit.退貨金額, 0)) {
        this.$eventBus.$emit("showAlert", "退貨金額必須為大於0的正整數");
        return;
      }
      //檢查資料 End

      if (this.loading) {
        return;
      }
      this.loading = true;
      let edit = JSON.parse(JSON.stringify(this.edit));
      let res = {};
      if (this.isEdit) {
        res = await api.IPSSetting.RefundEdit(edit);
      } else {
        res = await api.IPSSetting.RefundAdd(edit);
      }
      IPSTool.apiEdit.call(this, res);
      this.$emit('更新退貨資料');
      this.loading = false;
      this.hide();
      // edit.限制細項 = this.$refs.comp.list;
      // this.$emit('saveLimitOption', edit);
    },
    async show(isEdit, { 訂單編號, 服務編號, 原始金額 }) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.isEdit = isEdit;
      if (this.isEdit) {
        let res = await IPSTool.callAPIList.call(this, api.IPSSetting.RefundSearch, { 訂單編號 });
        if (res && res.list && res.list[0]) {
          //有值
          this.edit = { ...res.list[0] };
        } else {
          //查詢出錯
          this.loading = false;
          return;
        }
      } else {
        this.edit.訂單編號 = 訂單編號;
        this.edit.服務編號 = 服務編號;
        // this.edit.國碼 = 886;
        this.edit.門號 = "";
        this.edit.銀行代碼 = "";
        this.edit.銀行戶名 = "";
        this.edit.銀行分行 = "";
        this.edit.銀行帳號 = "";
        this.edit.收款人 = "";
        this.edit.退貨金額 = 原始金額;
        this.edit.備註 = "";
      }
      $(this.$refs.popForm).modal("show");
      this.loading = false;
    },
    hide() {
      $(this.$refs.popForm).modal("hide");
    },
  },
}
</script>
<style scoped>
.form-control2 {
  display: inline;
  width: auto;
}
</style>