<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('社群管理-詳細') }}( {{ $t(`PlatformType-${PlatformType[$route.params.PlatformType].Type}`) }} )</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'MessageSocialManager',
                        params: $route.params,
                    });">{{ $t('社群管理') }}</a>
                </li>
                <li class="active">{{ $t('設定') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-bordered classTypeTable">
                            <tbody>
                                <tr class="bg-gray">
                                    <th>{{ $t('訊息') }}</th>
                                    <th>{{ $t('觸發時機') }}</th>
                                    <th>{{ $t('內容') }}</th>
                                    <th>{{ $t('狀態') }}</th>
                                    <th class="text-nowrap text-center">{{ $t('功能') }}</th>
                                </tr>
                                <tr v-for="item of list" :key="item.SystemChatType">
                                    <td>{{ $t(`SystemChatType-${item.SystemChatKey}-訊息`) }}</td>
                                    <td>
                                        {{ $t(`SystemChatType-${item.SystemChatKey}-觸發時機`, [item.ServiceTimeMsg]) }}
                                    </td>
                                    <td>{{ item.Msg }}</td>
                                    <td>
                                        <div class="ckbx-style-8 ckbx toggleInput" @click="啟停用(item)">
                                            <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                                type="checkbox" v-model.trim="item.enable" />
                                            <label :for="'check' + item"></label>
                                        </div>
                                    </td>
                                    <td><button @click="設定($t(`SystemChatType-${item.SystemChatKey}-訊息`), item)"
                                            class="btn btn-primary">{{ $t('設定') }}</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
        <MessageSocialDetailManagerPop ref="pop" :Service="$store.state.projectId" @refresh="refresh">
        </MessageSocialDetailManagerPop>
    </div>
</template>

<script>
import api from "@/assets/js/app-api";
import { SystemChatType, PlatformType } from "@/store/MessageSystem/messageSystemConst";
import MessageSocialDetailManagerPop from "@/views/projects/MessageSystem/MessageSocialDetailManagerPop";
import { mapState } from "vuex";
export default {
    components: {
        MessageSocialDetailManagerPop
    },
    data() {
        return {
            PlatformType: PlatformType,
            list: [
                //{ 訊息: "迎賓訊息", 觸發時機: "事件被分派/接手時", Msg: "1234567890000", enable: true }
            ],
            selectionStart: -1,
            selectionEnd: -1,
        };
    },
    computed: {
        ...mapState({
            projectId: state => {
                return state.projectId;
            },
            IsAllowCsProject(state) {
                return state.messageSystemServer.IsAllowCsProject;
            }
        })
    },
    watch: {
        async projectId(v) {
            this.getSystemChatMsg(v);
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.getSystemChatMsg(this.projectId);
        },
        async getSystemChatMsg(v) {
            let res = (await api.CS.GetSystemChatMsg({
                ServiceNo: this.$store.state.projectId,
                PlatformType: v//0:App；1:Web
            })).data;
            this.refresh(res);
            $(this.$refs.loading).hide();
        },
        refresh(res) {
            this.list = [];
            let keys = Object.keys(SystemChatType);
            for (let key of keys) {
                // let 設定值 = SystemChatType[key];
                if (res[key]) {
                    this.list.push({
                        SystemChatKey: key,
                        ServiceTimeMsg: res[key].ServiceTimeMsg ? res[key].ServiceTimeMsg : "",
                        SystemChatType: res[key].SystemChatType,
                        Msg: res[key].Msg,
                        MsgVIP: res[key].MsgVIP ? res[key].MsgVIP : "",
                        enable: res[key].Enable
                    });
                }
            }
        },
        async 啟停用({ SystemChatType, enable }) {
            $(this.$refs.loading).show();
            try {
                let res = (await api.CS.SetSystemChatMsgEnable({
                    ServiceNo: this.$store.state.projectId,
                    PlatformType: this.$route.params.PlatformType,//0:App；1:Web
                    SystemChatType: SystemChatType,
                    Enable: !enable
                })).data;
                this.$eventBus.$emit("showAlert", this.$t('修改成功'));
                this.refresh(res);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        設定(title, item) {
            this.$refs.pop.Open(title, this.$store.state.projectId, 0, item);
        },
    }
}
</script>
<style scoped></style>