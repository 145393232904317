/**
 * 帶入字串，將字串用換行或逗號符分隔，回傳陣列
 * @param {*} str 
 * @returns 分隔後的陣列
 */
export function parseStrToAry(str) {
    try {
        str = str.replace(/\s+/g, '');
        let tmp = str.replace(/[\r+\n+,]/g, ",");
        tmp = tmp.split(",");
        tmp = tmp.filter((item) => { return item })
        return tmp;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export const 移除特殊字元List = function (list, 自定義fn) {
    let len = list.length;
    let res = [];
    for (let i = 0; i < len; i++) {
        let 移除後的字串 = 移除特殊字元(list[i], 自定義fn);
        移除後的字串 ? res.push(移除後的字串) : "";
    }
    return res;
}
export const 移除特殊字元 = function (str, 自定義fn) {
    //可能造成程式錯誤的符號
    //移除\ ==> \\
    //移除常見正則字符 ==> .^$*+?|()[\]{}
    //移除空格(包含\r、\n、全型空白) ==> \s
    //移除/ ==> /
    //移除鍵盤可以key出的 ==> ~!@#%&_=:";',.<>
    //移除鍵盤可以key出的 - (它放最後面，不然很容易掛掉) ==> -
    try {
        str = str.replace(/[\\.^$*+?|()[\]{}\s/`~!@#%&_=:;,.<>"'-]/g, "");
        // str = str.replace(/[\\.^$*+?|()[\]{}\s/`~!@#%&_=:;,.<>\"\'-]/g, "");//單引號跟雙引號，在打包工具中不用脫溢
    } catch (e) {
        console.warn(`移除特殊字元，處理字串 ${str} 失敗，錯誤訊息:${e}`);
        return "";
    }
    try {
        str = 自定義fn ? 自定義fn(str) : str;
    } catch (e) {
        console.warn(`移除特殊字元-自訂義，處理字串 ${str} 失敗，錯誤訊息:${e}`);
    }

    return str;
}