<template>
    <div id="AnnouncementList">
        <section class="content-header">
            <h1>頻道管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">頻道管理</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <div class="btn-add" style="    display: flex;    width: 270px;">
                            <button @click="watchEnd()" class="btn btn-block btn-primary " style="    margin-right: 5px;" type="button">
                                <i class="fa fa-plus"></i> 完成巡邏
                            </button>
                            <button class="btn btn-block btn-success" :class="{waiting: loadingExcel }"
                                type="btn btn-block btn-success" :disabled="searchData.length == 0"
                                style="    margin-right: 5px;" @click="Exprot(1, searchData)">{{ $t('匯出') }}</button>
                            <button @click="addNew()" class="btn btn-block btn-warning " type="button">
                                <i class="fa fa-plus"></i> 新增
                            </button>
                        </div>
                        <div class="select-group">
                            <select class="form-control" id="selectUnit" v-model="searchData.巡邏人" @change="clickSearch">
                                <option value="" select>全部巡邏人</option>
                                <option value="尚未派發" >尚未派發</option>
                                <option v-for="(item, index) in channelMan.result" :value="item.人員名稱" :key="index">
                                    {{item.人員名稱}}</option>
                            </select>
                        </div>
                        <div class="select-group">
                            <select class="form-control" id="selectUnit2" required="required" v-model="searchData.留言板狀態"
                                @change="clickSearch">
                                <option value="-1" key="0" select>全部留言板狀態</option>
                                <option value="0" key="1">關閉</option>
                                <option value="1" key="2">開放</option>
                                <option value="2" key="3">部分關閉</option>
                            </select>
                        </div>
                        <div class="select-group">
                            <select class="form-control" id="selectUnit3" required="required" v-model="searchData.檢索狀態"
                                @change="clickSearch">                                
                                <option value="1" key="1">開啟檢索</option>
                                <option value="0" key="2">關閉檢索</option>
                            </select>
                        </div>
                        <div class="input-group">
                            <input class="form-control pull-right" name="table_search" placeholder="搜尋頻道名稱、遊戲ID"
                                type="text" v-model="searchData.搜尋關鍵字" />
                            <div class="input-group-btn">
                                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="text">
                            <p class="data-num">
                                <span v-if="searchShow = 1">資料總筆數：</span>
                                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                                <span id="total">{{ channelQueryAll.total }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <!-- <th class="text-nowrap" style="width: 10%;">channel ID</th> -->
                                    <th style="min-width: 40px;    width: 4%;" v-show="showcheck">
                                        <label style=" filter: grayscale(0);    opacity: .5;">
                                            <input class="inputBox" type="checkbox"  v-model="selectAll"  />
                                        </label>                                        
                                    </th>
                                    <th style="min-width: 90px; width: 10%">頻道名稱</th>
                                    <th style="min-width: 90px; width: 8%">遊戲ID</th>
                                    <th class="text-nowrap" style="width: 5%;">電話</th>
                                    <th class="text-nowrap" style="width: 4%;">版權使用規範</th>
                                    <th class="text-nowrap" style="width: 4%;">巡邏人</th>
                                    <th class="text-nowrap" style="width: 2%;">影片下架數</th>
                                    <th class="text-nowrap" style="width: 2%;">留言板狀態</th>
                                    <th class="text-nowrap" style="width: 10%;">備註</th>
                                    <th class="text-nowrap" style="width: 6%;">最後巡邏時間</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                                </tr>

                                <template v-if="channelQueryAll.total > 0">
                                    <tr :key="item.id" v-for="(item, index) in channelQueryAll.datas">
                                        <!-- <td style="text-align:center;"><a class="linkStyle" :href="item.頻道連結"
                                                target="_blank">{{item.channelId}}</a></td> -->
                                        <td style="text-align:center;    padding: 0;"  v-show="showcheck">
                                            <label class="label">
                                                <input class="inputBox" type="checkbox" name="checktime" :value="item.頻道管理編號" v-model="timeList" :pname="item.頻道名稱"  />
                                            </label>
                                        </td>
                                        <td style="text-align:center;"><a class="linkStyle" :href="item.頻道連結"
                                                target="_blank">{{item.頻道名稱}}</a></td>
                                        <td style="text-align:center;padding:0;" class="moretd">
                                            <div v-for="(people, index) in item.玩家資訊">
                                                {{people.玩家角色名稱}}
                                            </div>
                                        </td>
                                        <td style="text-align:center;" align="center" class="moretd">
                                            <div v-for="(people, index) in item.玩家資訊">
                                                {{people.玩家角色電話}}
                                            </div>
                                        </td>
                                        <td style="text-align:center;" align="center" class="moretd">
                                            <div v-for="(people, index) in item.玩家資訊">
                                                <span v-if="people.版權使用規範簡訊狀態 == 0">未寄送</span>
                                                <span v-if="people.版權使用規範簡訊狀態 == 1">已寄送</span>
                                                <span v-if="people.版權使用規範簡訊狀態 == 2">發送失敗</span>
                                            </div>
                                        </td>
                                        <td style="text-align:center;">{{item.巡邏人}}</td>
                                        <td style="text-align:center;">{{item.影片下架數}}</td>
                                        <td style="text-align:center;">
                                            <span v-if="item.留言板狀態 == 0">關閉</span>
                                            <span v-if="item.留言板狀態 == 1">開放</span>
                                            <span v-if="item.留言板狀態 == 2">部分關閉</span>
                                        </td>
                                        <td style="text-align:center;" align="center" v-html="changeN(item.備註)"></td>
                                        <td style="text-align:center;" align="center" v-html="FormatTime(item.巡邏時間)"></td>
                                        <td style="text-align:center;" class="text-center text-nowrap">
                                            <button @click="editorVideo(item)" class="btn btn-warning ">所有影片</button>
                                            &nbsp; &nbsp;
                                            <button @click="editorItem(item)" class="btn btn-primary">修改</button> &nbsp;
                                            &nbsp;
                                            <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="11" v-if="showcheck">沒有資料</td>
                                        <td class="text-center" colspan="10" v-else>沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="overlay" ref="loading"><i class="fa fa-refresh fa-spin"></i></div> -->
                </div>

                <div class="box-footer text-center no-border" v-show="channelQueryAll.total > 0">
                    <Pagination ref="pagination" :total="channelQueryAll.total" :pageLimitCount="searchData.pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
            </div>
        </section>
    </div>
</template>
<style lang="scss" scoped>
    #AnnouncementList {
        .pic {
            img {
                max-height: 80px;
                margin: 0 auto;
            }
        }
    }

    .label {
        position: relative;
        width: 100%;
        height: 45px;
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .inputBox {
        position: relative;
        width: 15px;
        height: 15px;
    }

    .linkStyle {
        cursor: pointer;
        color: black;
        border-bottom: 1px solid;
    }

    .table-bordered>tbody>tr>td {
        border: 1px solid #b8b8b8;
    }

    .moretd {
        padding: 0;

        div {
            border-top: 1px solid #ebebeb;
            padding: 6px 0;
            min-height: 35px;

            &:first-child {
                border-top: 0;
            }
        }
    }

    .btn-success.waiting {
        pointer-events: none;
        opacity: .5;
    }

</style>
<style scoped>

</style>
<script>
    /* eslint-disable */
    //import CKEditor from "@/components/CKEditor";
    import api from "@/assets/js/app-api";
    import Pagination from "@/components/Pagination";
    import utils from "@/assets/js/app-utils";
    import config from "@/assets/js/app-config";
    import moment from "moment";
    import DateRangePicker from "@/components/DateRangePicker";
    const XLSX = require('xlsx');
    import {
        mapState,
        mapActions
    } from "vuex";

    export default {
        components: {
            DateRangePicker,
            Pagination,
            DateRangePicker,
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                total: 0, //總筆數
                projectId: this.$store.state.projectId,
                moduleId: '',
                selectAll: false,
                searchData: {
                    留言板狀態: -1, //0:關閉 1:開放 2:部分關閉
                    檢索狀態: 1, //0:關閉 1:開放
                    搜尋關鍵字: "",
                    巡邏人: '',
                    pageNum: 1,
                    pageSize: 20
                },
                timeList:[],
                checkedNames: [],
                showcheck:false,
                loadingExcel: false,
                url: config.dkOption.url,
            };
        },
        async created() {
            if (this.$route.params.searchData != undefined) {
                this.searchData = this.$route.params.searchData;
            }
            await this.getchannelMan();
            this.getChannelQueryAll();
        },
        mounted() {
            this.loadingExcel = false;
        },
        computed: {
            ...mapState({
                channelMan: (state) => state.smm.QueryMan,
                channelQueryAll: (state) => state.smm.QueryAll,
                response: (state) => state.smm.response,
                projectData: (state) => state.module.queryAll,
            }),
        },
        watch: {
            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;
            },
            $route(val) {
                //this.searchData.module = 'news'
                this.getchannelMan();
                this.getChannelQueryAll();
            },
            selectAll(value) {
                if(value){
                    this.timeList = this.channelQueryAll.datas.map(item => item.頻道管理編號);
                }else{
                    this.timeList = [];
                }
            },
            response() {
                if (this.response.status == "1")
                    this.$eventBus.$emit("showAlert", "更新成功!");
                else this.$eventBus.$emit("showAlert", "更新失敗!");

                this.getChannelQueryAll();
            },
        },
        methods: {
            async onPageChange(pageIdx, pageSize) {
                this.searchData.pageNum = pageIdx;
                this.searchData.pageSize = pageSize;
                this.getChannelQueryAll();
            },
            changeN(name) {
                if (name != undefined) {
                    return name.replace(/\n/g, '<br>');
                }
            },          
            watchEnd() {
                //console.log(this.timeList);        
                if(this.searchData.巡邏人 == ''){
                   this.$eventBus.$emit("showAlert", "請先選擇巡邏人!");
                   return false;
                }
                if(this.timeList.length < 1){
                   this.$eventBus.$emit("showAlert", "請選擇頻道");
                   return false;
                }         
                //console.log(this.channelQueryAll.datas)       
                const selectedPeople = this.channelQueryAll.datas.filter(person => this.timeList.includes(person.頻道管理編號));
                const selectedNames = selectedPeople.map(person => person.頻道名稱);
                const outputString = selectedNames.map(item => `‧ ${item}`).join('<br />');

                if(this.timeList.length > 0){
                   this.$eventBus.$emit(
					"showConfirm",
					'你確定要完成以下頻道巡邏嗎? <br />'+outputString,
                        (isOK) => {
                            if (isOK) {
                                this.$store.dispatch("smm/channelTime", {
                                    頻道管理編號清單: this.timeList
                                });
                            }
                        }
                    );
                }
                return false;

               
            },
            handleCheckboxChange(){
                //this.checkedNames = this.people.filter(person => person.checked).map(person => person.name);
                //this.checkedNames = this.$refs.checkboxInput.map(input => input.checked ? input.value : '').filter(Boolean);
            },
            
            getChannelQueryAll() {
                //列表                
                this.$store.dispatch("smm/channelQueryAll", this.searchData,
                 ).then((res) => {
                    this.timeList=[];
                    if(this.searchData.巡邏人 == ''){
                        this.showcheck=false;                  
                    }else{
                        this.showcheck=true;
                    }
                    this.selectAll=false;
                });            

                if (this.$route.params.searchData != undefined) this.$refs.pagination.setPage(this.searchData.pageNum,
                    this.channelQueryAll.total);
            },

            async getchannelMan() {
                this.$store.dispatch("smm/channelMan", {
                    projectId: 'omp_smm',
                    是否自訂: false
                });
            },

            clickSearch() {
                this.searchData.pageNum = 1;
                this.searchData.留言板狀態 = parseInt(this.searchData.留言板狀態, 10);
                this.searchData.檢索狀態 = parseInt(this.searchData.檢索狀態, 10);
             
           
                if(this.searchData.巡邏人 == ''){
                    this.showcheck=false;
                    this.selectAll=false;
                     this.timeList=[];
                }

                this.getChannelQueryAll();
            },

            //*---- 新增內容
            addNew() {
                this.$router.push({
                    name: 'smmAnnouncementEdit',
                    params: {
                        searchData: this.searchData,
                    },
                });
                return;
            },
            //*---- 前往所有影片
            async editorVideo(item) {
                //console.log(item)
                this.$router.push({
                    name: 'smmVideoList',
                    params: {
                        id: item.頻道管理編號,
                        searchData: this.searchData,
                    },
                });
                return;
            },
            //*---- 修改內容
            async editorItem(item) {
                // console.log(item)
                this.$router.push({
                    name: 'smmAnnouncementEdit',
                    params: {
                        id: item.頻道管理編號,
                        searchData: this.searchData,
                        url: item.頻道連結
                    },
                });
                return;
            },

            //*---- 刪除
            deleteItem(item) {
                this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
                    if (isOK) {
                        this.$store.dispatch("smm/channelDelete", {
                            頻道管理編號: item.頻道管理編號
                        });
                    }
                });
            },
            checkPermission(permission) {
                // let userPermissionList = this.$store.state.loginUserPermission;
                // let num = -1;
                // let isCheck = false;
                // permission.split(",").some(element => {
                //   num = userPermissionList.indexOf(element);
                //   switch (num) {
                //     case -1:
                //       isCheck = false;
                //       break;
                //     default:
                //       isCheck = true;
                //       break;
                //   }
                //   if (isCheck) return true;
                // });
                return utils.checkByUserPermission(permission);
            },
            FormatTime(time) {
                if (time == undefined) return "";
                return new moment(time).format("YYYY/MM/DD");
            },
            async Exprot(pageIdx, searchData) {
                this.loadingExcel = true;
                // let pagesize=this.channelQueryAll.total;
                // let maxPage = Math.ceil( this.channelQueryAll.total / pagesize ) ;
                let exportData = [];
                let list = [];
                await this.$store.dispatch("smm/channelQueryAll", {
                    ProjectId: "omp_smm",
                    留言板狀態: -1,
                    搜尋關鍵字: "",
                    巡邏人: '',
                    pageNum: 1,
                    pageSize: 500
                }).then((res) => {
                    list = res.result.datas;
                    list.map((i) => {
                        let tmp = {};
                        tmp['頻道連結'] = i.頻道連結;
                        tmp['頻道名稱'] = i.頻道名稱;
                        tmp['玩家資訊'] = i['玩家資訊'].map(player => {
                            let st = '';
                            if (player["版權使用規範簡訊狀態"] === 0) {
                                st = '未寄送';
                            } else if (player["版權使用規範簡訊狀態"] === 1) {
                                st = '已寄送';
                            } else if (player["版權使用規範簡訊狀態"] === 2) {
                                st = '發送失敗';
                            }
                            return `[角色名稱:${player["玩家角色名稱"]} / 電話:${player["玩家角色電話"]} / 規範簡訊:${st}] \n`;
                        }).join(`  `);
                        tmp['巡邏人'] = i.巡邏人;
                        tmp['影片下架數'] = i.影片下架數;
                        if (i.留言板狀態 === 0) {
                            tmp['留言板狀態'] = '關閉';
                        } else if (i.留言板狀態 === 1) {
                            tmp['留言板狀態'] = '開放';
                        } else if (i.留言板狀態 === 2) {
                            tmp['留言板狀態'] = '部分關閉';
                        } else {
                            tmp['留言板狀態'] = '未知狀態';
                        }
                        tmp['備註'] = i.備註;
                        exportData.push(tmp);
                    });
                });
                // 創建                    
                let workbook = XLSX.utils.book_new();
                let worksheet = XLSX.utils.json_to_sheet(exportData);
                worksheet["!cols"] = [{
                        wpx: 200
                    },
                    {
                        wpx: 120
                    },
                    {
                        wpx: 500
                    },
                    {
                        wpx: 80
                    },
                    {
                        wpx: 50
                    },
                    {
                        wpx: 80
                    },
                    {
                        wpx: 300
                    }
                ];

                XLSX.utils.book_append_sheet(workbook, worksheet, `頻道列表`);
                // 導出 Excel 文件
                XLSX.writeFile(workbook, `頻道列表-${moment().format('YYYY-MM-DD HH:mm')}.xlsx`);
                this.getChannelQueryAll();
                this.loadingExcel = false;
            },
        },
    };

</script>
<style scoped>
    .ckbx-style-8 label:before {
        background: #999;
    }

</style>
