/**
 * 
 * @param {*} file 
 * @param {} options 
 * @returns 
 * 使用範例： 
 * options可不傳
 * options.analyze：一個function，解析資料用，可不傳
 * options.fileExtension：檢查副檔名，不傳預設是 ['xls', 'xlsx']，若不符合副檔名規則，會拋出例外，若不想檢查，可以帶空陣列
 * html
 * <input ref="invoiceTypeA" type="file" accept=".xls,.xlsx" />
 * js
    try {
        let res = await FileReaderPromise(this.$refs.invoiceTypeA, { analyze: this.analyze });
        console.log("結果",res);//res.analyzeData就是自定義analyze這個函式的回傳值
    } catch (e) {
        this.$eventBus.$emit(
            "showAlert",
            e.msg
        );
    }
   analyze(workbook) {
        let wsname = workbook.SheetNames[0]; //取第一張表
        let ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
        // console.error(ws);
        return ws;
    },
 */
import xlsx from "xlsx";
let FileReaderPromise = async function (input, options) {
    return new Promise((resolve, reject) => {
        let files = input.files;
        let file = files[0];
        let filename = (file && file.name) || "";

        options = options || {};
        let analyze = options.analyze || function (fileReader) {
            return null;
        }
        let fileExtension = options.fileExtension || ['xls', 'xlsx'];
        const fileReader = new FileReader();
        fileReader.onload = ev => {
            let analyzeData = null;
            try {
                let data = fileReader.result;

                let extension = filename.substring(filename.lastIndexOf('.') + 1);
                if (extension == 'xls' || extension == 'xlsx') {
                    let workbook = xlsx.read(data, {
                        type: "binary",
                        cellDates: true
                    });
                    analyzeData = analyze(workbook);
                } else {
                    analyzeData = analyze(data);
                }

            } catch (e) {
                reject(e);
            }
            resolve({ fileReader, analyzeData });
        };
        fileReader.onerror = reject;
        if (!files) {
            reject('請選取檔案');
        }
        if (files.length <= 0) {
            reject('請選取檔案');
        }

        //檢查副檔名
        if (fileExtension.length > 0) {
            let regxStr = new RegExp(".(" + fileExtension.join("|") + ")$");
            if (!regxStr.test(filename.toLowerCase())) {
                // if (!/\.(xls|xlsx)$/.test(filename.toLowerCase())) {
                reject('上傳格式不正確，請上傳' + fileExtension.join('、') + '格式');
            }
        }
        //txt檔，用readAsText(UTF-8)
        {
            let regxStr = new RegExp(".txt$");
            if (regxStr.test(filename.toLowerCase())) {
                fileReader.readAsText(file, "utf-8");
            } else {
                fileReader.readAsBinaryString(file);
            }
        }
    });
}
export default FileReaderPromise;