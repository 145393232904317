<template>
  <div class="modal fade" ref="ImportPaymentOwedDatas">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">匯入欠費資料</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped" ref="uploadForm">
              <tbody>
                <tr>
                  <td>
                    <div class="form-group">
                      <label class="control-label">服務名稱：</label>
                      <select class="selPaymentType form-control" required="required" v-model="importDataInfo.服務編號">
                        <option disabled="disabled" value>請選擇服務</option>
                        <option value="10001">台版星城</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group">
                      <label class="control-label">交易方式：</label>
                      <select class="selPaymentType form-control" required="required" v-model="importDataInfo.交易方式">
                        <option disabled="disabled" value>
                          請選擇交易方式
                        </option>
                        <option value="IOS">IOS</option>
                        <option value="Android">Android</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group name-group">
                      <div class="right">
                        <input ref="importData" type="file" accept=".xls,.xlsx" />
                      </div>
                    </div>
                    <br />
                    <div class="col-md-6">
                      <button @click="readExcel()" class="btn btn-block btn-danger btn-cog" type="button">
                        確定匯入
                      </button>
                    </div>
                    <div class="col-md-6">
                      <a href="/uploads/欠費資料範例.xlsx" class="btn btn-block btn-success btn-cog" type="button"
                        download>欠費資料範例</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <UploadProcess ref="uploadProcess"></UploadProcess>
            <button class="btn btn-block btn-danger btn-cog" style="display: none;" type="button"
              ref="closeUploadProcessBtn" @click="關閉UploadProcess()">
              匯入結束
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</template>

<script>
/* eslint-disable */
// import { mapState, mapActions } from "vuex";
import utils from "@/assets/js/app-utils";
import xlsx from "xlsx";
import FileReaderPromise from "@/assets/js/Tool/FileReaderPromise.js";
import moment from "moment";
import UploadProcess from "../../../components/UploadProcess.vue";

export default {
  components: {
    UploadProcess,
  },
  data() {
    return {
      importDataInfo: {
        服務編號: "",
        交易方式: "",
      },
      上傳狀態: {
        總筆數: 0,
        當前筆數: 0,
        logs: [],
      }
    };
  },
  watch: {},
  methods: {
    importDataBox() {
      this.$refs.importData.value = "";
      $(this.$refs.ImportPaymentOwedDatas).modal("show");
    },
    async readExcel() {
      if (this.importDataInfo.服務編號 == "" ||
        this.importDataInfo.服務編號 == null) {
        this.$eventBus.$emit("showAlert", "請選擇服務");
        return;
      }
      if (this.importDataInfo.交易方式 == "" ||
        this.importDataInfo.交易方式 == null) {
        this.$eventBus.$emit("showAlert", "請選擇交易方式");
        return;
      }
      try {
        let file = await FileReaderPromise(this.$refs.importData, { analyze: this.analyze });
        // 移到catch去處理
        // if (file.analyzeData.error) {
        //   this.$refs.importData.value = "";
        //   this.$eventBus.$emit("showAlert", file.analyzeData.errMessage);
        //   return;
        // }
        let 總筆數 = file.analyzeData.importData.length;

        //開始匯入
        $(this.$refs.uploadForm).hide();
        this.$refs.uploadProcess.start(總筆數);
        for (let o = 0; o < file.analyzeData.importData.length; o++) {
          await this.$store.dispatch("ipsPaymentOwed/create", file.analyzeData.importData[o]).then((res) => {
            if (res.data.status == "1") {
              //新增成功
            } else {
              //let errCode = res.data.stateCode;
              let errMsg = res.data.message;
              this.$refs.uploadProcess.addLog(`第${o + 1}筆，訂單編號${file.analyzeData.importData[o].訂單編號}上傳失敗:${errMsg}`);
            }
          }).catch(e => {
            this.$refs.uploadProcess.addLog(`第${o + 1}筆，訂單編號${file.analyzeData.importData[o].訂單編號}上傳失敗:${e}`);
          });
          this.$refs.uploadProcess.當前筆數 = o + 1;
        }
        $(this.$refs.closeUploadProcessBtn).show();
      } catch (e) {
        // console.error(e);
        this.$refs.importData.value = "";
        this.$eventBus.$emit("showAlert", e);
      }
    },
    關閉UploadProcess() {
      this.$refs.importData.value = "";
      $(this.$refs.uploadForm).show();
      this.$refs.uploadProcess.hide();
      $(this.$refs.closeUploadProcessBtn).hide();
    },
    analyze(workbook) {
      const wsname = workbook.SheetNames[0]; //取第一張表
      const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
      let importData = []; //清空
      let i = 0;
      try {
        for (i = 0; i < ws.length; i++) {
          ws[i].是否繳款 = parseInt(ws[i].是否繳款);
          if ((ws[i].訂單編號.trim() == "" || !ws[i].訂單編號) &&
            isNaN(ws[i].是否繳款))
            continue;
          if (!ws[i].訂單編號) {
            throw "第" + (i + 2) + "筆資料訂單編號為空值!!";
          }
          if (ws[i].是否繳款 != 0 && ws[i].是否繳款 != 1) {
            throw "第" + (i + 2) + "筆資料是否繳款值有問題!!";
          }
          if (!ws[i].退款時間) {
            throw "第" + (i + 2) + "筆資料退款時間為空值!!";
          }
          if (ws[i].凍結日期 && !moment(ws[i].凍結日期).isValid()) {
            throw "第" + (i + 2) + "筆資料凍結日期格式有問題!!";
          }
          if (ws[i].繳費時間 && !moment(ws[i].繳費時間).isValid()) {
            throw "第" + (i + 2) + "筆資料繳費時間格式有問題!!";
          }
          if (ws[i].退款時間 && !moment(ws[i].退款時間).isValid()) {
            throw "第" + (i + 2) + "筆資料退款時間格式有問題!!";
          }
          importData.push({
            訂單編號: ws[i].訂單編號,
            服務編號: this.importDataInfo.服務編號,
            交易方式: this.importDataInfo.交易方式,
            是否繳款: ws[i].是否繳款,
            凍結日期: ws[i].凍結日期 ? utils.formatTime(ws[i].凍結日期, "YYYY/MM/DD HH:mm:ss") : "",
            繳費時間: ws[i].繳費時間 ? utils.formatTime(ws[i].繳費時間, "YYYY/MM/DD HH:mm:ss") : "",
            退款時間: ws[i].退款時間 ? utils.formatTime(ws[i].退款時間, "YYYY/MM/DD HH:mm:ss") : "",
            備註: ws[i].備註,
          });
        }
      } catch (e) {
        if (typeof e == "string") {
          throw e;
        }
        let info = "";
        try {
          info = JSON.stringify(ws[i]);
        } catch (e2) {
          info = "解析該資料失敗";
        }
        let msg = `第${i + 2}筆資料有問題，${info}，可能發生原因：${e}`;
        throw msg;
      }
      return {
        importData
      };
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    },
  },
  components: { UploadProcess }
};
</script>