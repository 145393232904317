/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    orders: [],
    total: 0,
    people: 0,
    amount: 0,
}

// getters
const getters = {

}

// actions
const actions = {
    queryAll({ commit }, data) {
        api.payment.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setOrders', res.data.orders);
                commit('setTotal', res.data.total);
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    queryStatistics({ commit }, data) {
        api.payment.queryStatistics(data).then(res => {
            if (res.data.status == "1") {
                commit('setPeople', res.data.人數);
                if (data.projectId == "omp_heti") commit('setAmount', res.data.原始金額);
                else
                    commit('setAmount', res.data.配點金額);
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    checkOrder({ commit }, data) {
        return api.payment.checkOrder(data);
    },
    getOrderProducts({ commit }, data) {
        return api.payment.getOrderProducts(data);
    },
    export({ commit }, data) {
        let typeName = "儲值歷程";
        if (data.projectId == "omp_heti") typeName = "交易歷程";
        api.payment.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.orders.length <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                }
                appUtils.exportData(
                    res.data.orders,
                    appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
                    typeName
                );
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    }
}

// mutations
const mutations = {
    setOrders(state, orders) {
        state.orders = orders;
    },
    setTotal(state, total) {
        state.total = total;
    },
    setPeople(state, people) {
        state.people = people;
    },
    setAmount(state, amount) {
        state.amount = amount;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}