<template>
  <div id="MemberData">
    <section class="content-header">
      <h1 v-if="!id">申請刪除帳號管理</h1>
      <h1 v-else>申請刪除帳號 {{ id }}號</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li v-if="id" class="active"><router-link :to="{name:'XswMemberDelete'}">申請刪除帳號管理</router-link></li>
        <li v-else class="active">申請刪除帳號管理</li>
        <li v-if="id" class="active">申請刪除{{ id }}號</li>
      </ol>
    </section>
    <section v-if="!id" class="content" style="min-height:auto;">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box clearfix" @submit="$event.preventDefault(); getDataList()">
            <div class="input-group mb-10 mr-10">
              <input type="text" class="form-control" placeholder="申請人" v-model.trim="searchData.nickName" />
            </div>
            <div class="input-group mb-10 mr-10">
              <input type="text" class="form-control" placeholder="綁定門號" v-model.trim="searchData.phoneNumber" />
            </div>
            <div class="input-group pull-left mb-10 mr-10">
              <input type="text" class="form-control" placeholder="狀態" >
            </div>
            <div class="clear form-group pull-left m-0">
              <label class="control-label pull-left mt-7">發送時間：</label>
              <div class="input-group datetime mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="true"
                  :propEndDate.sync="searchData.結束時間"
                  :propStartDate.sync="searchData.開始時間"
                />
              </div>
            </div>
            <div class="form-group pull-left m-0">
              <label class="control-label pull-left mt-7">審核區間：</label>
              <div class="input-group datetime mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="true"
                  :propEndDate.sync="searchData.結束時間"
                  :propStartDate.sync="searchData.開始時間"
                />
              </div>
            </div>
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()" :disabled="datas.length === 0">
                匯出
              </button>
            </div>
            <!-- <button type="button" v-show="Object.keys($route.query).length > 0" @click="$router.go(-1)"
              class="btn btn-default pull-right mb-10 ml-10">
              <i class="fa fa-arrow-left"></i> 返回</button> -->
            <!-- <router-link :to="{ name: 'NmjPlayerSetting' }" class="btn btn-warning pull-right mb-10">大量停權</router-link> -->
          </form>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:120px; width: 5%">申請時間</th>
                  <th style="min-width: 90px; width: 5%">門號</th>
                  <th style="min-width:120px; width: 5%">申請人</th>
                  <th style="min-width: 90px; width: 5%">登入方式</th>
                  <th style="min-width: 80px; width: 5%">審核狀態</th>
                  <th style="min-width: 90px; width: 5%">備註</th>
                  <th style="min-width: 80px; width: 5%">審核通過時間</th>
                  <th style="min-width: 80px; width: 5%">附檔</th>
                  <th style="min-width: 80px; width: 5%">功能</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="result.message">
                  <td colspan="11">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="11">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(player, index) in datas" :key="index">
                  <td>{{ player.nickName }}</td>
                  <td>{{ player.playerID }}</td>
                  <td>{{ player.門號綁定 ? player.門號綁定 : "-" }}</td>
                  <td>{{ player.會員狀態 }}</td>
                  <td>{{ player.段位等級 }} <span style="color:#aaa">({{ player.level }})</span></td>
                  <td>{{ player.levelExp }}</td>
                  <td>{{ player.skill }}</td>
                  <td>{{ player.mahjongCoin }}</td>
                  <td>{{ uiDate(player.createTime * 1000) }}</td>
                  <td>{{ uiDate(player.lastPlayerLoginTime * 1000) }}</td>
                  <td>
                    <router-link :to="{ name: 'NmjPlayerHistory', query: { nick: player.nickName } }"
                      class="btn btn-primary">
                      <i class="fa fa-history fa-fw"></i> 玩家歷程
                    </router-link>
                    <router-link :to="{ name: 'NmjPlayerDeposit', query: { nick: player.nickName } }"
                      class="btn btn-success mt-5">
                      <i class="fa fa-dollar fa-fw"></i> 儲值歷程
                    </router-link>
                    <router-link :to="{ name: 'NmjRewardMessageHistory', query: { nick: player.nickName } }"
                      class="btn btn-warning mt-5">
                      <i class="fa fa-envelope fa-fw"></i> 信件歷程
                    </router-link>
                  </td>
                  <td>
                    <div style="width:max-content; margin:0 auto; text-align:left;">
                      <p v-if="player.suspensionTime * 1000 >= (Date.now())">
                        停權至{{ player.停權時間 }}</p>
                      <p v-if="player.banSpeechTime * 1000 >= (Date.now())">
                        禁言至{{ player.禁言時間 }}
                      </p>
                      <p v-if="player.banSpeechTime * 1000 < (Date.now()) && player.suspensionTime * 1000 < (Date.now())">
                        正常無控管
                      </p>
                    </div>
                    <p class="mt-15">
                      <button class="btn btn-info" @click="playerSetting(player)">修改</button>
                      <button
                        v-show="player.banSpeechTime * 1000 >= (Date.now()) || player.suspensionTime * 1000 >= (Date.now())"
                        class="btn btn-danger ml-5" @click="playerSetting(player, true)">解鎖</button>
                      <!-- <button class="btn btn-default ml-5">紀錄</button> -->
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    <section v-else class="content" style="min-height:auto;">
      <div class="box box-primary">
        <div class="box-header">
          <div class="top-box with-border m-0">
            <router-link class="btn btn-default pull-right" :to="{name:'XswMemberDelete'}"><i class="fa fa-reply"></i> 返回</router-link>
            <h2 class="pull-left mb-5 mt-5" style="font-size:1.5em;">完整資訊</h2> 
          </div>
        </div>
        <div class="form-horizontal">
          <div class="box-body">
            <div class="table-responsive mt-0">
              <table class="table table-bordered min-600">
                <tbody>
                  <tr>
                    <th>申請ID</th><td>{{ id }}</td>
                    <th>遊戲暱稱</th><td>給我大紅大紫</td>
                  </tr>
                  <tr>
                    <th>綁定門號</th><td>886926026250</td>
                    <th>申請時間</th><td>{{ uiDate('2024-03-18') }}</td>
                  </tr>
                  <tr>
                    <th>登入方式</th><td>立即玩_綁門號</td>
                    <th></th><td></td>
                  </tr>
                  <tr>
                    <th>狀態</th><td><select class="form-control"><option>狀態</option></select></td>
                    <th>真實姓名</th><td><input type="text" class="form-control"></td>
                  </tr>
                  <tr>
                    <th>身分證</th><td><input type="text" class="form-control"></td>
                    <th>聯絡電話</th><td><input type="text" class="form-control"></td>
                  </tr>
                  <tr>
                    <th>備註</th><td colspan="3"><textarea rows="5" class="form-control"></textarea></td>
                  </tr>
                  <tr>
                    <th>附件</th><td colspan="3">
                      <img>
                      <div style="display:flex; flex-wrap:wrap; gap:0.25em; align-items:center; justify-content:center;">
                        <button type="button" class="btn btn-warning">身分證正面</button>
                        <button type="button" class="btn btn-default">身分證反面</button>
                        <button type="button" class="btn btn-default">手機上期電信帳單</button>
                        <button type="button" class="btn btn-default">轉向</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box-footer">
          <button type="submit" @click="data.isDraft=false" class="btn btn-primary btn-lg fa fa-floppy-o">
            儲存
          </button>
        </div>
      </div>
    </section>


    <!-- <pre>{{ datas }}</pre> -->
    <!-- <pre>{{ serviceList }}</pre> -->
    <!-- <pre>{{ banList }}</pre> -->

    <PlayerSettingContent ref="PlayerSettingContent" v-on:updateList="getDataList(); getBanInfo()" />
    <PlayerServiceContent ref="PlayerServiceContent" v-on:updateList="getServiceInfo()" :types="serviceTypes" />
  </div>
</template>

<style lang="scss" scoped>
table.table>tbody>tr {
  word-break: break-word;
}
table.table>tbody>tr>th {
  background: rgba(0,0,0,.08);
  word-break: keep-all;
  text-align: right;
}
/*
  .table-grid{
    @supports(display:contents){
      tbody{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr) minmax(200px, 2fr));
      }
      tr{
        display: contents;
      }
    }
    // border-width: 1px 0 0 1px;
    th, td{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin: 0 -1px -1px 0;
      border-width: 0 1px 1px 0;
    }
    th{
      background: rgba(0,0,0,.08);
      text-align: right;
      // &::after{content: "："}
    }
    td{
      text-align: left;
      align-items: flex-start;
    }
  }
  */
</style>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import PlayerSettingContent from "@/views/projects/NMJ/PlayerSettingContent";
import PlayerServiceContent from "@/views/projects/NMJ/PlayerServiceContent";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    PlayerSettingContent,
    PlayerServiceContent,
    Pagination,
    router
  },
  props:{
    id: ""
  },
  data() {
    return {
      def: define,
      loading: false,
      searchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        nickName: "",
        phoneNumber: "",
        暱稱: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 23:59:59"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59")
      },
      result: {},
      datas: [],
      total: 0, //總筆數
      tab: 1,

      // banList
      banSearchData: {
        暱稱: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 23:59:59"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      banMessage: "", //錯誤訊息
      banLoading: false,
      banTotal: 0, //總筆數
      banList: [],

      // serviceList
      serviceSearchData: {
        暱稱: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 23:59:59"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      serviceMessage: "", //錯誤訊息
      serviceLoading: false,
      serviceTotal: 0, //總筆數
      serviceList: [],
      serviceTypes: {},
    };
  },
  async created() {
    if (this.$route.query.nick) {
      this.searchData.nickName = this.$route.query.nick;
      this.getDataList();
    }
    else if (this.$route.query.tel) {
      this.searchData.phoneNumber = this.$route.query.tel;
      this.getDataList();
    }
    this.getTypes();
    window.v = this
  },
  watch: {
    "searchData.暱稱": function (value) {
      this.banSearchData.暱稱 = value;
      this.serviceSearchData.暱稱 = value;
    },
    "banSearchData.暱稱": function () {
      this.banSearchData.pageNum = 1;
      this.getBanInfo();
    },
    "serviceSearchData.暱稱": function () {
      this.serviceSearchData.pageNum = 1;
      this.getServiceInfo();
    }
    // "searchData.nickName": function(value){ this.searchData.暱稱 = value },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 2) {
        this.banSearchData.pageNum = pageIdx;
        this.banSearchData.pageSize = pageSize;
        this.getBanInfo();
      } else {
        this.serviceSearchData.pageNum = pageIdx;
        this.serviceSearchData.pageSize = pageSize;
        this.getServiceInfo();
      }
    },
    getTypes() {
      this.$store.dispatch("nmjServiceTypes/serviceTypeQuery").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
            // console.log(item);
          }
        }
      });
    },
    getDataList() {
      if (!this.searchData.nickName && !this.searchData.phoneNumber) {
        this.$eventBus.$emit("showAlert", "請填寫玩家暱稱或門號！");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("nmjPlayerData/query", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.status == undefined ? result : [];
          this.total = result.total || 0;
          this.loading = false;
          if (this.datas.length > 0) {
            let query = {};
            if (this.searchData.nickName != "") query['nick'] = this.searchData.nickName;
            if (this.searchData.phoneNumber != "") query['tel'] = this.searchData.phoneNumber;
            // console.log(this.$route.query.nick, query.nick, this.$route.query.tel, query.tel)
            if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
              this.$router.replace({query:query});
            }
            this.searchData.暱稱 = this.datas[0].nickName;
          }
        });
    },
    getServiceInfo() {
      this.serviceLoading = true;
      this.$store
        .dispatch("nmjPlayerData/service", this.serviceSearchData)
        .then((result) => {
          // console.log("Service", result)
          this.result = result;
          let list = result.datas ? result.datas : [];
          this.serviceList = list;
          this.serviceTotal = result.total || 0;
          this.serviceMessage = "";
          this.serviceLoading = false;
        }, (msg) => {
          this.serviceList = [];
          this.serviceTotal = 0;
          this.serviceMessage = msg;
          this.serviceLoading = false;
        });
    },
    getBanInfo() {
      this.banLoading = true;
      this.$store
        .dispatch("nmjPlayerData/controlInfo", this.banSearchData)
        .then((result) => {
          // console.log(result)
          this.result = result;
          let list = result.datas ? result.datas : [];
          this.banList = list;
          this.banTotal = result.total || 0;
          this.banMessage = "";
          this.banLoading = false;
        }, (msg) => {
          this.banList = [];
          this.banTotal = 0;
          this.banMessage = msg;
          this.banLoading = false;
        });
    },
    banStatus停權(startTime, endTime) {
      let str = '停權 ';
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) str += ' 終止契約';
      else if (end.valueOf() - start.valueOf() > (3 * 24 * 60 * 60 * 1000)) str += ' 暫時凍結';
      else if (end.valueOf() === start.valueOf()) str += ' 解鎖';
      else str += '3天';
      return str
    },
    banStatus禁言(startTime, endTime) {
      let str = '禁言 ';
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) {
        str += ' 終止契約';
      }
      else if (end.valueOf() === start.valueOf()) str += ' 解鎖';
      else str += Math.round((end.valueOf() - start.valueOf()) / (60 * 1000)) + '分鐘';
      return str;
    },
    禁言期限(startTime, endTime) {
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) {
        return '永久';
      } else {
        return this.uiDate(endTime);
      }
    },
    playerSetting(player, unban) {
      this.$refs.PlayerSettingContent.showContent(player, unban);
    },
    playerService(player) {
      this.$refs.PlayerServiceContent.showContent(player);
    },
    deletePlayerService(item) {
      this.$store.dispatch("nmjPlayerData/serviceDelete", {
        "暱稱": item.暱稱,
        "日期": item.日期,
      }).then(res => {
        this.getServiceInfo();
      });
    },
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/export", this.searchData);
    },
    uiDate(date) {
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return '';
    }
  },
};
</script>