export default class {
    static reportTypes = {
        'AbusiveChat': '辱罵聊天',
        'InappropriateName': '不當名稱',
        'CheatingBottingHacking': '作弊',
        'Spam': '濫用廣告',
        'Others': '其他',
    };
    static contactTypes = {
        'LOBBY': '大廳',
        'SLOT MACHINES': '遊戲館',
        'PURCHASE': '儲值',
        'GIFTS': '獎勵',
        'CONNECTION': '連線',
        'SUGGESTIONS': '建議',
        'OTHERS': '其他',
    };
    static contactPlatforms = {
        'HD安卓新藍版': 'Android',
        'HDios': 'iOS',
        'HD舊安卓紅藍版': 'Editor',
    };
    static serviceStatus = {
        '0': '未回覆',
        '1': '草稿',
        '2': '已回覆',
    };
    static announcementStatus = {
        '-1': '已刪除',
        '0': '等待中',
        '1': '已發送',
        '2': '已過期',
    };
    static rewardStatus = {
        '-1': '已刪除',
        '0': '草稿',
        '1': '等待中',
        '2': '已發送',
    };
    static vipStatus = {
        1: 'BRONZE',
        2: 'SILVER',
        3: 'GOLD',
        4: 'PLATINUM',
        5: 'DIAMOND',
        6: 'ROYAL DIAMOND',
        7: 'BLACK DIAMOND',
    };
    static controlStatus = {
        1: '停權',
        2: '禁言',
        3: '解除',
    };
    static handleStatus = {
        0: '待處理',
        1: '已處理',
    };
    static deleteStatus = {
        0: '上架',
        1: '自刪',
        2: '下架',
    };
    static countryTypes = {
        '886': '台灣',
        '852': '香港',
        '853': '澳門',
        '66': '泰國',
        '86': '中國',
    };
    static SMSType = {
        0: "一般簡訊",
        1: "廣告帳戶",
        2: "系統帳號",
        3: "一般特殊",
        4: "廣告特殊",
        5: "特殊越南",
        7: "abenla",
        8: "活動簡訊",
    }
    static areaCodeList = [
        {code: "886", name: "台灣"},
        {code: "1", name: "美國"},
        {code: "44", name: "英國"},
        {code: "60", name: "馬來西亞"},
        {code: "61", name: "澳洲"},
        {code: "62", name: "印尼"},
        {code: "63", name: "菲律賓"},
        {code: "65", name: "新加坡"},
        {code: "66", name: "泰國"},
        {code: "81", name: "日本"},
        {code: "82", name: "韓國"},
        {code: "84", name: "越南"},
        {code: "852", name: "香港"},
        {code: "853", name: "澳門"},
        {code: "86", name: "中國大陸"},
        {code: "91", name: "印度"},
    ]
}
