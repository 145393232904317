import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    detailQueryAll: [],
    detailQuery: [],
    detailImgList: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    newsQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjWebNews.queryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setNewsQueryAll', res.data.result);
                    }
                }
                resolve(res.data);
            })
        }); 
    },
    newsQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjWebNews.query(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setNewsQuery', res.data.result);
                    }
                }
                resolve(res.data)
            })
        }); 
    },
    newsCreate({ commit }, data) {
        api.nmjWebNews.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    newsUpdate({ commit }, data) {
        api.nmjWebNews.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    newsDelete({ commit }, data) {
        api.nmjWebNews.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    newsDetailQueryAll({ commit }, data) {
        api.newsDetail.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailNewsQueryAll', res.data.result);
            }
        })
    },
    newsDetailQuery({ commit }, data) {
        api.newsDetail.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailNewsQuery', res.data.result);
            }
        })
    },
    newsDetailImgList({ commit }, data) {
        api.newsDetail.imgList(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailNewsImgList', res.data.result.imgUrlList);
            }
        })
    },
}

// mutations
const mutations = {
    setNewsQueryAll(state, newsQueryAll) {
        state.queryAll = newsQueryAll;
    },
    setNewsQuery(state, newsQuery) {
        state.query = newsQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setDetailNewsQueryAll(state, newsDetailQueryAll) {
        state.detailQueryAll = newsDetailQueryAll;
    },
    setDetailNewsQuery(state, newsDetailQuery) {
        state.detailQuery = newsDetailQuery;
    },
    setDetailNewsImgList(state, imgList) {
        state.detailImgList = imgList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}