<template>
	<div id="clientGameActivity" class="slot max1040">
		<section class="content">
			<div class="box box-primary" style="background: none;">
        <div class="box-body">
          <div class="g_back" @click="goBack()">
            <div>
              <i class="fa fa-chevron-left"></i>
            </div>
            <div>
              <p>{{ query.遊戲名稱 }}遊戲介紹</p>
              <p>{{ query.開發商名稱 }}</p>
            </div>
          </div>
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <li
                v-on:click="tab = 'event'"
                v-bind:class="{ active: tab == 'event' }"
              >
                <a href="javascript:;">機制項目</a>
              </li>
              <li
                v-on:click="tab = 'normal'"
                v-bind:class="{ active: tab == 'normal' }"
              >
                <a href="javascript:;">常態活動</a>
              </li>
              <li
                v-on:click="tab = 'abnormal'"
                v-bind:class="{ active: tab == 'abnormal' }"
              >
                <a href="javascript:;">非常態活動</a>
              </li>
            </ul>
          </div>
          <div class="flow-box">
            <template v-if="tab == 'event'">
              <Empty v-if="flowList.length == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
              <div v-else v-for="(item, m_index) in flowList" class="flow-item">
                <div class="flow-title">{{ item.流程名稱 }}</div>
                <ActivityTable :data="item.項目對應" :id="Number($route.params.id)"></ActivityTable>
              </div>
            </template>

            <!-- 常態活動 -->
            <template v-if="tab == 'normal'">
              <div class="table-responsive mt-10">
                <Empty v-if="normalList.length == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
                <table v-else class="table table-striped table-bordered table-normal" id="classTypes">
                  <tbody>
                    <tr style="background-color: #FEE94E;">
                      <th class="text-nowrap text-center" style="min-width: 100px; width: 30%;">時間</th>
                      <th class="text-nowrap text-center" style="min-width: 150px; width: 70%;">週期活動</th>
                    </tr>
                    <tr :key="index" v-for="(item, index) in normalList">
                      <td>{{ item.活動時間 }}</td>
                      <td class="text-left" v-html="item.內容"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            
            <!-- 非常態活動 -->
            <template v-if="tab == 'abnormal'">
              <div class="top-box">
                <div class="input-group mr-10">
                  <input
                    class="form-control pull-right"
                    name="table_search"
                    placeholder="活動名稱"
                    type="text"
                    v-model="searchData2.活動名稱"
                  />
                  <i class="fa fa-close" v-if="searchData2.活動名稱" @click="searchData2.活動名稱 = ''"></i>
                  <div class="input-group-btn">
                    <button class="btn btn-default" type="button" @click=resetData()>
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
                <div class="text">
                  <p class="data-num">
                    <span>資料筆數：</span>
                    <span id="total">{{ abNormalList.length }}</span>
                  </p>
                  <div class="dropdown" style="display: inline;">
                    <button
                      class="btn btn-sort dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
                    >
                      <div class="icon-sort"></div>
                      排序依據
                    </button>
                    <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
                      <li>
                        <a 
                          :class="['dropdown-item', searchData2.排序條件 == 2 ? 'active' : '']"
                          href="#" 
                          @click="resetData(2, 2)"
                        >
                          更新時間
                        </a>
                        <a 
                          :class="['dropdown-item', searchData2.排序條件 == 3 && searchData2.排序順序 == 1 ? 'active' : '']"
                          href="#" 
                          @click="resetData(3, 1)"
                        >
                          A to Z
                        </a>
                      </li>
                      <li>
                        <a
                          :class="['dropdown-item', searchData2.排序條件 == 3 && searchData2.排序順序 == 2 ? 'active' : '']"
                          href="#" 
                          @click="resetData(3, 2)"
                        >
                        Z to A
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive mt-10">
                <table class="table table-striped table-bordered table-normal" id="classTypes">
                  <Empty v-if="abNormalList.length == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
                  <tbody v-else>
                    <tr style="background-color: #FEE94E;">
                      <th class="text-nowrap text-center" style="min-width: 100px; width: 30%;">活動名稱</th>
                      <th class="text-nowrap text-center" style="min-width: 150px; width: 70%;">
                        {{ tab == 'normal' ? '活動說明' : '活動簡述' }}
                      </th>
                    </tr>
                    <tr :key="index" v-for="(item, index) in abNormalList">
                      <td class="td-hover" @click="goContent(item.編號)">{{ item.活動名稱 }}</td>
                      <td class="text-left">{{ item.活動說明 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
				</div>
			</div>
      <!--內容-->
			<div class="modal fade" ref="content" id="activityContent">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 v-if="tab == 'event'" class="modal-title text-center">{{ detail.項目名稱 }}</h4>
              <h4 v-if="tab == 'abnormal'" class="modal-title text-center">{{ data.活動名稱 }}</h4>
            </div>
						<div class="modal-body">
							<div v-if="tab == 'event'" v-html="detail.詳細說明"></div>
							<div v-else v-html="data.內容"></div>
              <p class="is-bottom">已經到底了</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    <slotPlayContent ref="slotPlayContent"></slotPlayContent>
	</div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import Empty from "@/components/SlotEmpty";
import slotPlayContent from "@/components/slot/slotPlayContent";
import ActivityTable from "@/components/slot/activityTable";
import { RFC_2822 } from "moment";

export default {
  components: {
    Empty,
    slotPlayContent,
    ActivityTable
  },
  data() {
    return {
      loading: false,
      tab: 'event',
      dropdown: 'none',
      searchData: {
        遊戲編號: Number(this.$route.params.id),
        PageNum: 1,
        PageSize: 12,
      },
      searchData2: {
        遊戲編號: Number(this.$route.params.id),
        活動名稱: "",
        排序條件: 2,//1:排序數字,2.更新時間,3:活動名稱
        排序順序: 2,//1:DESC 2:ASC
        PageNum: 1,
        PageSize: 12,
        
      },
      flowList: [],
      normalList: [],
      abNormalList: [],
      data: {},
      detail: {},
    };
  },
  async created() {
    this.getFlowList();
    this.getNormalList();
    this.getAbnormalList();
    this.$store.dispatch("slot/gameQuery", { 編號: this.searchData.遊戲編號 })
  },
  computed: {
    ...mapState({
      query: state => state.slot.gameQuery,
    }),
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    getFlowList() {
      api.slot.eventFlow.queryAll( this.searchData )
        .then((res) => {
          res.data.result.datas.forEach(item => {
            this.flowList.push({
              流程名稱: item.流程名稱,
              編號: item.編號,
              項目對應: JSON.parse(item.項目對應)
            })
          })
        })
    },
    getNormalList() {
      api.slot.eventNormal.queryAll( this.searchData )
        .then((res) => {
          this.normalList = res.data.result.datas;
        })
    },
    getAbnormalList() {
      api.slot.eventAbnormal.queryAll( this.searchData2 )
        .then((res) => {
          this.abNormalList = res.data.result.datas;
        })
    },
    resetData(type = 2, order = 1) {
      this.searchData2.排序條件 = type;
      this.searchData2.排序順序 = order;
      this.dropdown = "none";
      this.getAbnormalList();
    },
    goBack() {
      this.$router.push({
        name: "slotClientGameList",
        params: {
          searchData: this.$route.params.backSearchData,
        }
      });
    },
    goContent(id) {
      let type = this.tab == 'normal' ? 'eventNormal' : 'eventAbnormal'
      api.slot[type].query({ 編號: id })
        .then((res) => {
          this.data = res.data.result;
          $(this.$refs.content).modal("show");
        })
    },
  }
};
</script>