<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>最新消息列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li v-if="id" class="active"><router-link :to="{params:{ id:null, searchData:searchData }}">最新消息列表</router-link></li>
        <li v-else class="active">最新消息列表</li>
        <li v-if="id" class="active">{{ id != 'New' ? "編輯" : "新增" }}內容</li>
      </ol>
    </section>
    <section v-if="!id" class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <router-link :to="{params:{id:'New', searchData:searchData}}" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增訊息
            </router-link>
            <!-- <button @click="addNew()" type="button" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增訊息
            </button> -->
            <div class="select-group">
              <select id="selectUnit" class="form-control" v-model="searchData.classTypeId" @change="clickSearch" required>
                <option value>全部</option>
                <option v-for="(item, index) in newsTypes.datas" :value="item.classTypeId" :key="index">
                  {{item.className}}
                </option>
              </select>
            </div>
            <div class="input-group">
              <input type="text" v-model="searchData.keyword" name="table_search" class="form-control pull-right" placeholder="搜尋關鍵字" />
              <div class="input-group-btn">
                <button type="button" @click="clickSearch()" class="btn btn-default fa fa-search"></button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ news.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width:100px; width:20%">發布時間</th>
                  <th style="min-width:150px; width:40%" colspan="2">標題</th>
                  <th style="min-width:100px; width:10%">類別</th>
                  <th style="min-width:200px; width:10%">功能</th>
                </tr>
                <tr v-if="news.total <= 0">
                  <td colspan="10">沒有資料</td>
                </tr>
                <tr v-else v-for="(item, index) in news.datas" :key="item.id">
                  <td>{{ FormatTime(item.publishTime) }}</td>
                  <td class="text-right" style="border-right:0"><!-- {{ item.isTop === 1 ? '是':'否' }} -->
                    <i v-show="item.isTop === 1" class="fa fa-thumb-tack fa-lg" style="transform:rotate(45deg);"></i>
                  </td>
                  <td class="text-left" style="border-left:0">{{ item.detailTopTitle }}</td>
                  <td>{{ item.classTypeName }}</td>
                  <td>
                    <button @click="previewItem(item)" class="btn btn-success">預覽</button>
                    &nbsp;
                    <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                    &nbsp;
                    <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="news.total > 0">
          <Pagination
            ref="pagination"
            :total="news.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!-- <pre style="line-height:1.1;">{{ news.datas[0] }}</pre> -->
    </section>

    <section v-else class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <router-link :to="{params:{ id:null, tab:tab, searchData:searchData }}" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </router-link>
            <!-- <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button> -->
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 類別：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option v-for="(item, index) in newsTypes.datas" :key="index" :value="item.classTypeId">
                      {{item.className}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 公告標題：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputTitle" class="form-control" required
                    v-model="edit.detailTopTitle"
                  />
                  <p class="help-block">標題上限 {{ edit.detailTopTitle.length }}/50 字</p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 置頂：
                </label>
                <div class="col-sm-10">
                  <div class="radio-inline">
                    <iCheckRedioButton checkValue="1" v-model.number="edit.isTop" message="是" name="radioMode" />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton checkValue="0" v-model.number="edit.isTop" message="否" name="radioMode" />
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發布時間：
                </label>
                <div class="col-sm-10">
                  <DateAndTimePicker v-model="edit.publishTime" />
                  <!-- <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton checkValue="0" v-model="edit.isStop" message="上架" name="radioMode" />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="local.publishTime"/>
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input id="publishTime" class="form-control timepicker" type="text" />
                    </div>
                  </div>
                  <div class="radio">
                    <iCheckRedioButton checkValue="1" v-model="edit.isStop" message="下架" name="radioMode" />
                  </div> -->
                </div>
              </div>
              
              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="AdPic">
                  <span class="text-red">*</span> 圖片：
                </label>
                <div class="col-sm-10">
                  <input type="file" id="AdPic" accept="image/webp, image/jpeg, image/png" @change="onFileChange($event)" />
                  <p class="help-block text-red">※檔案尺寸900x500，小於300Kb，格式 webp、jpg</p>
                  <img class="help-block" :src="previewImg" draggable="false" alt="" style="max-height:200px;" :hidden="!previewImg" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內文：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce v-model="edit.detailTopInfo" :propLang="editLang" height="400" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="button" @click="Save(true)" class="btn btn-success btn-lg fa fa-eye" style="margin-right: 10px;">
                  預覽
                </button>
                <button type="button" @click="Save(false)" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>{{ edit }}</pre>
      <pre>{{ detailNewsLang[0] }}</pre> -->
    </section>
  </div>
</template>

<style lang="scss" scoped>
  .control-label{
    padding-right:0;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import tinymce from "@/components/Tinymce";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
    DateAndTimePicker,
    iCheckRedioButton,
    tinymce
  },
  props: {
    id: String,
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      routeName: this.$route.name,
      searchData: {
        // projectId: this.$store.state.projectId,
        classTypeId: "",
        module: "news",
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, //每頁顯示筆數
      },
      url: config.dkOption.url,
      hasIsHint: '',

      
      edit: {
        newsId: utils.getTimeId(), //公告Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        lang: "zh-tw", // 語系
        newsDetailId: "", // 內容Id
        detailTopTitle: "", //主旨
        detailTopInfo: "", // 內容
        isStop: 0, // 發佈
        isTop: 0, // 置頂
        isNowOn: 0, //0:參考startTime ; 1:即日起
        publishTime: new moment().add(1, "day").format("YYYY-MM-DDT12:00:00"),
        startTime: new moment().add(1, "day").format("YYYY-MM-DDT12:00:00"),
        endTime: new moment().add(1, "day").format("YYYY-MM-DDT12:00:00"),
      },
      previewImg: ""
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    // 開箱文日期預設值
    if (this.projectId === 'omp_xinstars') {
      this.searchData.StartTime = new moment().subtract(360, "days").format("YYYY-MM-DD")
      this.searchData.EndTime = new moment().add(60, "days").format("YYYY-MM-DD")
    }

    await this.getNewsClassTypes();
    this.getNewsQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      newsTypes: (state) => state.nmjClassTypes.queryAll,
      news: (state) => state.nmjWebNews.queryAll,
      response: (state) => state.nmjWebNews.response,
    }),
  },
  watch: {
    newsTypes() {},
    $route(val) {
      this.searchData.module = 'news';
      this.getNewsClassTypes();
      this.getNewsQueryAll();
    },
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getNewsQueryAll();
    },
    news() {
      this.$refs.pagination.setPage(this.searchData.pageNum, this.news.total);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getNewsQueryAll();
    },

    getNewsQueryAll() {
      //列表
      this.$store.dispatch("nmjWebNews/newsQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async getNewsClassTypes() {
      //分類
      this.$store.dispatch("nmjClassTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "news",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getNewsQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: 'XsgWebNews',
        params: {
          id: "New",
          searchData: this.searchData,
        },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: 'XsgWebNews',
        params: {
          id: item.newsId,
          searchData: this.searchData,
        },
      });
      return;
    },
    
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|jpeg|webp|png)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          return this.$eventBus.$emit("showAlert", "您選擇的檔案格式不符，請重新選擇。");
        }

        if (fileSize > 500 * 1024) {
          this.$eventBus.$emit("showAlert", "您選擇的檔案超過上限 500 KB，請重新選擇。");
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },
    removeFile() {
      document.getElementById("AdPic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("nmjClassTypes/fileUpload", data).then( res => {
        console.log(res)
        if (res.status == 1 && res.result && res.result[0] && res.result[0].filesUrl){
          this.edit.filesUrl = res.result[0].filesUrl;
          // console.log(this.edit)
          if (this.isEdit) {
            this.$store.dispatch("nmjWebBanner/bannerUpdate", this.edit).then(() => this.backList() );
          } else {
            this.$store.dispatch("nmjWebBanner/bannerCreate", this.edit).then(() => this.backList() );
          }
        }
        
      });
    },
    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          that.previewImg = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
      else if (/^data/.test(this.previewImg)) this.previewImg = this.edit.filesUrl;
    },

    //*----預覽
    previewItem(item) {
      let width = 1080;
      let height = 840;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 2;
      // console.log(width, height, left, top)
      window.open(
        `${process.env.VUE_APP_NMJWEB_PATH}/News/New?id=${item.newsId}&preview=1`,
        "preview",
        `popup,top=${top},left=${left},width=${width},height=${height}`
      );
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjWebNews/newsDelete", item);
        }
      });
    },

    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>