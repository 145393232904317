<template>
    <div>
        <section class="content-header">
            <h1> 新增/修改 金流帳號</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingList',
                        params: $route.params,
                    });">金流設定</a>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingAccountList',
                        params: $route.params,
                    });">金流帳號</a>
                </li>
                <li class="active">新增/修改 金流帳號</li>
            </ol>
        </section>

        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 名稱：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" v-model.trim="edit.名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 金流識別值：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" v-model.trim="edit.金流識別值" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool2'
export default {
    components: {
        iCheckRedioButton,
    },
    data() {
        return {
            isEdit: false,
            edit: {
                名稱: "",
                啟用: 1,
                金流設定主檔編號: this.$route.params.ipsId,
                金流識別值: "",
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                let list = APILib.IPS(await api.IPSSetting.金流帳號_查詢({})).content;
                let find = list.find((item) => { return (item.編號 == parseInt(this.$route.params.id) && item.金流設定主檔編號 == parseInt(this.$route.params.ipsId)) });
                this.isEdit = find ? true : false;
                if (find) {
                    delete find.建立時間;
                    this.edit = { ...find };
                }
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        backList() {
            this.$router.push({
                name: "IPSSettingAccountList",
                params: { ipsId: this.$route.params.ipsId },
            });
        },
        async Save() {
            if (!this.edit.名稱) {
                this.$eventBus.$emit("showAlert", "名稱不可為空");
                return false;
            }
            if (!this.edit.金流識別值) {
                this.$eventBus.$emit("showAlert", "金流識別值不可為空");
                return false;
            }
            try {
                $(this.$refs.loading).show();
                let obj = JSON.parse(JSON.stringify(this.edit));
                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.金流帳號_修改(obj));
                } else {
                    APILib.IPS(await api.IPSSetting.金流帳號_新增(obj));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
            } catch (e) {
                $(this.$refs.loading).hide();
                this.$eventBus.$emit("showAlert", e);
            }
            this.backList();
        },
    },
};
</script>
  