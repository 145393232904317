import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    reportList: [],
    response: '',
    comment: [],
    commentQuery: [],
    reply: [],
    replyQuery: [],
}

// getters
const getters = {
}

// actions
const actions = {
    search({ commit }, data) {
        api.qppReport.search(data).then(res => {
            if (res.data.status == "1") {
                commit('setReportList', res.data.content);
            }
        })
    },
    edit({ commit }, data) {
        api.qppReport.edit(data).then(res => {
            if (res.data.status == "1") {
                commit('setResponse', res.data.content);
            }
        })
    },

    comment({ commit }, data) {
        api.qppReport.comment(data).then(res => {
            if (res.data.status == "1") {
                commit('setComment', res.data.content);
            }
        })
    },
    commentQuery({ commit }, data) {
        api.qppReport.comment(data).then(res => {
            if (res.data.status == "1") {
                commit('setCommentQuery', res.data.content);
            }
        })
    },
    deleteComment({ commit }, data) {
        api.qppReport.deleteComment(data).then(res => {
            if (res.data.status == "1") {
                commit('setResponse', res.data.content);
            }
        })
    },

    reply({ commit }, data) {
        api.qppReport.reply(data).then(res => {
            if (res.data.status == "1") {
                commit('setReply', res.data.content);
            }
        })
    },
    replyQuery({ commit }, data) {
        api.qppReport.reply(data).then(res => {
            if (res.data.status == "1") {
                commit('setReplyQuery', res.data.content);
            }
        })
    },
    deleteReply({ commit }, data) {
        api.qppReport.deleteReply(data).then(res => {
            if (res.data.status == "1") {
                commit('setResponse', res.data.content);
            }
        })
    },
}

// mutations
const mutations = {
    setReportList(state, reportList) {
        state.reportList = reportList;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setComment(state, comment) {
        state.comment = comment;
    },
    setCommentQuery(state, commentQuery) {
        state.commentQuery = commentQuery;
    },
    setReply(state, reply) {
        state.reply = reply;
    },
    setReplyQuery(state, replyQuery) {
        state.replyQuery = replyQuery;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}