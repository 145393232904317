<template>
    <!--InvoicePrintList-->
    <div>
        <div class="box">
            <div class="box-body">
                <div class="top-box">
                    <div class="form-group">
                        <select class="form-control" v-model="查詢方式">
                            <option value="一般查詢">一般查詢</option>
                            <option value="訂單查詢">訂單查詢</option>
                            <option value="訂單購買門號查詢">訂單購買門號查詢</option>
                        </select>
                    </div>
                    <template v-if="查詢方式 == '一般查詢'">
                        <div class="form-group">
                            <label class="control-label">超商：</label>
                            <select class=" form-control" style="width: 150px;" v-model="searchData.列印廠商">
                                <!-- <option value="">全部</option> -->
                                <option value="全家">全家</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">年份：</label>
                            <select class="form-control" style="width: 80px;" v-model="searchData.發票年份">
                                <!-- <option value="">全部</option> -->
                                <option v-for="year of 年份options" :value="year" :key="year">{{ year }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">期數：</label>
                            <select class="form-control" style="width: 100px;" v-model="searchData.發票期數">
                                <option value="">全部</option>
                                <option value="1, 2月">1.2月</option>
                                <option value="3, 4月">3.4月</option>
                                <option value="5, 6月">5.6月</option>
                                <option value="7, 8月">7.8月</option>
                                <option value="9, 10月">9.10月</option>
                                <option value="11, 12月">11.12月</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">列印時間：</label>
                            <span class="control-label" style="padding-top:0px;width: 160px;">
                                <date-picker :config='{
                                    format: "YYYY-MM-DD",
                                    showTodayButton: true,
                                    showClear: true,
                                    useCurrent: false,
                                    sideBySide: true,
                                    locale: "zh-tw",
                                }' v-model="searchData.起始列印時間"></date-picker>
                            </span>
                            <span class="control-label" style="margin: 0px 5px;">
                                ~
                            </span>
                            <span class="control-label" style="padding-top:0px;width: 160px;">
                                <date-picker :config='{
                                    format: "YYYY-MM-DD",
                                    showTodayButton: true,
                                    showClear: true,
                                    useCurrent: false,
                                    sideBySide: true,
                                    locale: "zh-tw",
                                }' v-model="searchData.結束列印時間"></date-picker>
                            </span>
                        </div>
                    </template>
                    <template v-else-if="查詢方式 == '訂單查詢'">
                        <div class="form-group">
                            <input type="text" class="form-control" style="width:220px;" placeholder="訂單編號"
                                v-model.trim="searchData.訂單編號" />
                        </div>
                    </template>
                    <template v-else="查詢方式 == '訂單購買門號查詢'">
                        <div class="form-group">
                            <input type="text" class="form-control" style="width:220px;" placeholder="訂單購買門號"
                                v-model.trim="searchData.購買門號" />
                        </div>
                    </template>
                    <br />
                    <div class="form-group">
                        <button class="btn btn-primary" type="button" @click="onSearch(1, searchData)">
                            查詢
                        </button>

                        <button class="btn btn-success" :disabled="!(lastQueryCommand && total > 0)" type="button"
                            @click="匯出結果(lastQueryCommand)">
                            匯出查詢結果
                        </button>

                    </div>
                </div>
                <div class="table-responsive mt-10">
                    <table class="table table-hover table-bordered min-800">
                        <tbody>
                            <tr class="bg-gray text-nowrap">
                                <th>超商</th>
                                <th>門市</th>
                                <th>發票年份</th>
                                <th>發票期數</th>
                                <th>訂單編號</th>
                                <th>訂單購買門號</th>
                                <th>發票號碼</th>
                                <th>列印時間</th>
                                <th>列印狀態</th>
                                <th>備註</th>
                            </tr>
                            <tr v-for="(item, sn) in list" :key="item.編號">
                                <td class="text-center">{{ item.列印廠商 }}</td>
                                <td class="text-center">{{ item.列印門市 }}</td>
                                <td class="text-center">{{ item.發票年份 }}</td>
                                <td class="text-center">{{ item.發票期數 }}</td>
                                <td class="text-center">{{ item.訂單編號 }}</td>
                                <td class="text-center">{{ item.購買門號 }}</td>
                                <td class="text-center">{{ item.發票號碼 }}</td>
                                <td class="text-center">{{ item.列印時間 }}</td>
                                <td class="text-center">
                                    <span v-if="item.列印狀態 == 1">{{ item.列印狀態名稱 }}</span>
                                    <span class="text-green" v-else-if="item.列印狀態 == 2">{{ item.列印狀態名稱 }}</span>
                                    <span class="text-aqua" v-else-if="item.列印狀態 == 3 || item.列印狀態 == 4">{{ item.列印狀態名稱
                                    }}</span>
                                    <span class="text-red" v-else>{{ item.列印狀態名稱 }}</span>
                                </td>
                                <td class="text-center">{{ item.備註 }}</td>
                            </tr>
                            <tr v-if="list.length == 0">
                                <td class="text-center" colspan="10">沒有資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="box-footer text-center no-border">
                <select class="form-control page-size" v-model="pageSize">
                    <option :value=10>單頁顯示10項</option>
                    <option :value=20>單頁顯示20項</option>
                    <option :value=40>單頁顯示40項</option>
                    <option :value=100>單頁顯示100項</option>
                </select>
                <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
            </div>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import xlsx from "xlsx";

let defSearchData = {
    // 一般查詢：
    列印廠商: "全家",
    發票年份: "",
    發票期數: "",
    起始列印時間: new moment().add(-7, "days").format("YYYY-MM-DD"),
    結束列印時間: new moment().format("YYYY-MM-DD"),
    //訂單查詢
    訂單編號: "",
    // 訂單購買門號查詢
    購買門號: "",
};
export default {
    components: {
        datePicker,
        Pagination,
    },
    data() {
        let pageSize = 20;
        let data = {
            查詢方式: "一般查詢",//一般查詢,訂單查詢,訂單購買門號查詢
            pageSize,
            total: 0,
            年份options: [],
            list: [],
            lastQueryCommand: null
        };
        data.searchData = { ...defSearchData };
        return data;
    },
    watch: {
        查詢方式(value) {
            this.searchData = { ...defSearchData };
            switch (value) {
                case "一般查詢": {
                    this.searchData.發票年份 = this.年份options[0];
                    break;
                }
                case "訂單查詢":
                case "訂單購買門號查詢":
                    break;
            }
        },
        pageSize(v) {
            this.onPageChange(1);
        }
    },
    computed: {
    },
    mounted() {
        //初始年份選項
        let nowYear = new moment().format("YYYY");
        while (nowYear >= 2021) {
            this.年份options.push(nowYear);
            nowYear--;
        }
        this.searchData.發票年份 = this.年份options[0];
    },
    methods: {
        onPageChange(頁數) {
            this.onSearch(頁數, this.lastQueryCommand);
        },
        async autoSearch({ 查詢方式, 訂單編號, 購買門號 }) {
            this.查詢方式 = 查詢方式;//這行會觸發watch，造成資料被清掉，只好寫在nextTick
            this.$nextTick(() => {
                if (this.查詢方式 == "訂單查詢") {
                    this.searchData.訂單編號 = 訂單編號;
                } else if (this.查詢方式 == "訂單購買門號查詢") {
                    this.searchData.購買門號 = 購買門號;
                }
                this.onSearch(1, this.searchData);
            });
        },
        async onSearch(頁數, query) {
            if (!query) {
                return;
            }
            let send = {};
            if (this.查詢方式 == "訂單查詢") {
                send.訂單編號 = query.訂單編號;
            } else if (this.查詢方式 == "訂單購買門號查詢") {
                send.購買門號 = query.購買門號;
            } else {
                send = JSON.parse(JSON.stringify(query));
            }
            send.頁數 = 頁數;
            send.每頁筆數 = this.pageSize;
            this.$store.dispatch('invoice/loading', true);
            try {
                let res = await api.IPSSetting.GetInvoicePrintCourse(send);
                if (res && res.data) {
                    res = res.data;
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    throw errMsg;
                }
                if (res.list) {
                    this.list = this._transList(res.list);
                    this.total = res.total;
                } else {
                    this.list = [];
                    this.total = 0;
                }
                this.lastQueryCommand = send;
            } catch (e) {
                this.list = [];
                this.total = 0;
                this.lastQueryCommand = null;
                this.$eventBus.$emit("showAlert", e);
            } finally {
                this.$store.dispatch('invoice/loading', false);
            }
            this.$refs.pagination.setPage(
                頁數,
                this.total
            );
        },
        _transList(list) {
            list.map((item) => {
                switch (item.列印狀態) {
                    case 1: item.列印狀態名稱 = "一般"; break;
                    case 2: item.列印狀態名稱 = "重新列印"; break;
                    case 3: item.列印狀態名稱 = "重新授權"; break;//程式
                    case 4: item.列印狀態名稱 = "重新授權"; break;//人工
                    default:
                        item.列印狀態名稱 = "未知"; break;//
                }
            });
            return list;
        },
        開啟匯入頁面() {
            this.$refs.invoicePopImportForm.show();
        },
        async 匯出結果(query) {
            try {
                this.$store.dispatch('invoice/loading', true);
                let send = JSON.parse(JSON.stringify(query));
                delete send.頁數;
                delete send.每頁筆數;
                let res = await api.IPSSetting.GetInvoicePrintCourse(send);
                if (res && res.data) {
                    res = res.data;
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    throw errMsg;
                }
                if (res.list) {
                    res.list = this._transList(res.list);
                    let worksheet = xlsx.utils.json_to_sheet(res.list);
                    let new_workbook = xlsx.utils.book_new();
                    xlsx.utils.book_append_sheet(new_workbook, worksheet, "Sheet1");
                    xlsx.writeFile(new_workbook, `發票列印歷程- ${new moment().format("YYYY-MM-DD HH:mm")}.xlsx`);
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            } finally {
                this.$store.dispatch('invoice/loading', false);
            }
        },
    },
    filters: {
    }
};
</script>
  
<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.page-size {
    float: left;
    width: auto;
}

.box {
    border: 0px;
    box-shadow: none;
}

.form-group>button {
    margin-right: 15px;
}
</style>