<template>
	<div id="activity" class="slot max1040">
		<section class="content-header">
			<h1>活動機制</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li class="active">活動機制</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<div class="input-group">
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="遊戲名稱"
								type="text"
								v-model="searchData.遊戲名稱"
							/>
              <i class="fa fa-close" v-if="searchData.遊戲名稱" @click="searchData.遊戲名稱 = ''"></i>
							<div class="input-group-btn">
								<button v-on:click="resetDataList()" class="btn btn-default" type="button">
									<i class="fa fa-search"></i>
								</button>
							</div>
						</div>
						<div class="text">
							<p class="data-num">
								<span>資料總筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th class="text-nowrap text-center" style="max-width: 90px;">圖示</th>
									<th style="min-width: 150px;" width="50%">
										<span @click="changeSort()" style="cursor: pointer;">
											App名稱
											<i class="fa fa-sort"></i>
										</span>
									</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 40%;">功能</th>
								</tr>
								<tr v-if="total == 0">
									<td colspan="3"><Empty /></td>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td class="text-nowrap text-center">
										<div class="photo">
											<img :src="item.圖示" alt="">
										</div>
									</td>
									<td>{{ item.遊戲名稱 }}</td>
									<td class="text-center">
										<button
											class="btn btn-primary mr-10"
											@click="add(item)"
										>
											機制項目
										</button>
										<button
											class="btn btn-primary mr-10"
											@click="showContent('normal', item)"
										>
											常態活動
										</button>
										<button
											class="btn btn-primary"
											@click="showContent('abnormal', item)"
										>
											非常態活動
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border" v-if="total != 0">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="20">每頁筆數20</option>
            <option value="30">每頁筆數30</option>
            <option value="50">每頁筆數50</option>
          </select>
					<Pagination
						ref="pagination"
						:type2="true"
						:total="total"
						:pageLimitCount="searchData.pageSize"
						v-on:pageMethod="onPageChange"
					/>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
	import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";
	import Empty from "@/components/SlotEmpty";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
			Empty
		},
    delimiters: ["${", "}"],
    data() {
      return {
				id: utils.getTimeId(),
				isEdit: false,
				searchData: {
					開發商編號: 0,
					遊戲名稱: "",//關鍵字比對
					// 是否啟用: 1,
					pageNum: 1,
					pageSize: 20,
					排序條件: 2,//1:更新時間,2:建立時間,3.遊戲名稱
					排序順序: 1,//1:DESC,2:ASC
				},
				list: [],
				total: 0,
      };
    },
    created() {
			this.getDataList();
    },
    mounted() {},
    computed: {
		},
    watch: {},
    methods: {
			getDataList() {
				api.slotGame.game.queryAll(this.searchData)
					.then((res) => {
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
					})
			},
			resetDataList() {
				this.searchData.pageNum = 1;
				this.getDataList();
			},
			changeSort() {
				if(this.searchData.排序條件 != 3) {
					this.searchData.排序條件 = 3;
					this.searchData.排序順序 = 2;
				} else {
					this.searchData.排序順序 = this.searchData.排序順序 == 1 ? 2 : 1;
				}
				this.resetDataList();
			},
			add(item) {
				this.$router.push({
					name: 'slotActivityItem',
					params: {
						id: item.編號,
						backSearchData: this.searchData,
						name: item.遊戲名稱
					},
				});
			},
			showContent(type, item) {
				this.$router.push({
					name: 'slotActivityList',
					params: {
						type: type,
						id: item.編號,
						name: item.遊戲名稱,
						backSearchData: this.searchData,
					},
				});
			},
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>