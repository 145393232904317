<template>
  <div id="AlbumList">
    <section class="content-header">
      <h1>相簿管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>相簿管理</li>
        <li class="active">相簿管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增訊息
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isShow"
                @change="clickSearch"
              >
                <option value="-1">全部</option>
                <option value="0">下架</option>
                <option value="1">上架</option>
                <!-- <option
                  :value="item.classId"
                  v-for="(item, index) in albumTypes.datas"
                  :key="index"
                >{{item.className}}</option> -->
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.nameKeyWord"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ album.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">相簿名稱</th>
                  <th class="text-nowrap" style="width: 10%;">文章id</th>
                  <th style="min-width: 90px; width: 5%">封面圖</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="album.total > 0">
                  <tr :key="item.id" v-for="item in album.photoAlbumList">
                    <td align="center">{{ item.名稱 }}</td>
                    <td align="center">{{ item.功能模組編號 }}</td>
                    <td>
                      <div class="pic jqimgFill">
                        <img draggable="false" :src="getMainPhoto(item.相片列表)" />
                      </div>
                    </td>
                    <td class="text-center" align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          :false-value="0"
                          :id="'check'+item.編號"
                          :true-value="1"
                          @change="changeStatus(item)"
                          name="ckbx-style-8"
                          type="checkbox"
                          v-model="item.是否顯示"
                        />
                        <label :for="'check'+item.編號"></label>
                      </div>
                    </td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <!-- <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                      >刪除</button> -->
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="album.total > 0">
          <Pagination
            ref="pagination"
            :total="album.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      searchData: {
        projectId: this.$store.state.projectId,
        module: "",
        classTypeId: "",
        lang: "zh-tw",
        isShow: 1,
        page: 1,
        pageSize: 10, //每頁顯示筆數
        nameKeyWord: "",
      },
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.getNewsClassTypes();
    this.getNewsQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      albumTypes: (state) => state.classTypes.queryAll,
      album: (state) => state.album.queryAll,
      response: (state) => state.album.response,
    }),
  },
  watch: {
    albumTypes() {},
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getNewsQueryAll();
    },
    album() {
      this.$refs.pagination.setPage(this.searchData.page, this.album.total);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getNewsQueryAll();
    },

    getNewsQueryAll() {
      //列表
      this.searchData.isShow = parseInt(this.searchData.isShow);
      this.$store.dispatch("album/albumQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.page, this.total);
    },

    async getNewsClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "album",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    clickSearch() {
      this.searchData.page = 1;
      this.getNewsQueryAll();
    },

    //*---- 新增內容
    addNew() {
      if (this.projectId == "omp_xinbo") {
        this.$store.dispatch("album/albumQuery", {
          classTypeId: "",
          module: "news",
          isDelete: 0,
          isStop: 0,
        });
      }

      this.$router.push({
        name: "AlbumEdit",
        params: {
          searchData: this.searchData,
        },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "AlbumEdit",
        params: {
          id: item.編號,
          searchData: this.searchData,
        },
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("news/newsDelete", item);
        }
      });
    },
    
    checkPermission(permission) {
      // let userPermissionList = this.$store.state.loginUserPermission;
      // let num = -1;
      // let isCheck = false;
      // permission.split(",").some(element => {
      //   num = userPermissionList.indexOf(element);
      //   switch (num) {
      //     case -1:
      //       isCheck = false;
      //       break;
      //     default:
      //       isCheck = true;
      //       break;
      //   }
      //   if (isCheck) return true;
      // });
      return utils.checkByUserPermission(permission);
    },

    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },

    getMainPhoto(photoList) {
      var mainPhotoUrl = "";
      $.each(photoList, function(idx){
        if(photoList[idx].isMain != 0) mainPhotoUrl = photoList[idx].filesUrl;
      });
      
      return mainPhotoUrl;
    },

    // 改變狀態
    changeStatus(item) {
      var data = new FormData();
      data.append("MasterModel.專案編號", item.專案編號);
      data.append("MasterModel.編號", item.編號);
      data.append("MasterModel.排序", item.排序);
      data.append("MasterModel.是否顯示", item.是否顯示);

      this.$store.dispatch("album/albumUpdate", data);
    },
    
  },
};
</script>
