import api from "@/assets/js/app-api";
// import utils from '@/assets/js/app-utils';

// initial state
// shape: [{ id, quantity }]
const state = {
    response: '',
    postList: [],
    postTotal: [],
    fanPageList: [], //已經被抓到的

    postQuery: [],
    fbList: [], 
    
    fanList: [], //登入後抓到的
}

// getters
const getters = {
}

// actions
const actions = {
    //post
    search({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.queryAll(data).then(res => {
                if (res.data.status == "1") {
                    commit('setPostList', res.data.result.datas);
                    commit('setPostTotal', res.data.result.total);
                } 
                resolve(res.data);
            })
        })
    },
    fbAll({ commit }, data) {
        api.fbRobot.fbAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setFBList', res.data.result.datas);
            } else {
                commit('setFBList', []);
            }
        })
    },

    query({ commit }, data) {
        api.fbRobot.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setPostQuery', res.data.result);
            } else {
                commit('setPostQuery', []);
            }
        })
    },
    edit({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.update(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.create(data).then(res => {
                if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                }

                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    delPost({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.delPost(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    this._vm.$eventBus.$emit("showAlert", "刪除成功");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "刪除失敗");
                }
                resolve(res.data.result);
            });
        });
    },
    copyPost({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.copyPost(data).then(res => {
                if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                }
                commit('setResponse', res.data);
                resolve(res.data);
            });
        });
    },

    FanPageList({ commit }, data) {
        api.fbRobot.FanPageList(data).then(res => {
            if (res.data.status == "1") {
                commit('setFanPageList', res.data.result.datas);
            } else {
                commit('setFanPageList', []);
            }
        })
    },

    //keywords
    queryKeywords({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.queryKeywords(data).then(res => {
                resolve(res.data.result);
            });
        });
    },

    createKeywords({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.createKeywords(data).then(res => {
                if (res.data.status == "1") {
                    this._vm.$eventBus.$emit("showAlert", "新增完成");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "新增失敗");
                }

                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    updateKeywords({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.updateKeywords(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'updateKeywords', null, true);
                // this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                //     this._vm.$eventBus.$emit("showAlert", "更新完成");
                // } else {
                //     this._vm.$eventBus.$emit("showAlert", "更新失敗");
                }
            });
        });
    },
    delKeywords({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.delKeywords(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    this._vm.$eventBus.$emit("showAlert", "刪除成功");
                } else {
                    this._vm.$eventBus.$emit("showAlert", "刪除失敗");
                }
                resolve(res.data.result);
            });
        });
    },

    //fan page
    getFan({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.getFan(data).then(res => {
                if (res.data.status == "1") {
                    commit('setFanList', res.data.result.datas);
                } 
                resolve(res.data);
            });
        });
    },
    getAuth({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fbRobot.getAuth(data).then(res => {
                resolve(res.data.result);
            });
        });
    },
}

// mutations
const mutations = {
    setPostList(state, postList) {
        state.postList = postList;
    },
    setPostTotal(state, postTotal) {
        state.postTotal = postTotal;
    },
    setPostQuery(state, postQuery) {
        state.postQuery = postQuery;
    },
    setFBList(state, fbList) {
        state.fbList = fbList;
    },
    setFanList(state, fanList) {
        state.fanList = fanList;
    },
    setResponse(state, response) {
        state.response = response;
    },

    setFanPageList(state, fanPageList) {
        state.fanPageList = fanPageList;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}