<template>
    <div>
        <section class="content-header">
            <h1>風控記錄</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">風控記錄</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-header">
                    <div class="input-group-for-multiselect-wrap">
                        <span style="white-space:nowrap">廠商：</span>
                        <multiselect class="multiselect-width" placeholder="請選取廠商" v-model="searchData.廠商"
                            :options="廠商Opts" label="名稱" track-by="廠商代碼" :show-labels="false" :allow-empty="false">
                        </multiselect>
                    </div>
                    <div class="input-group">
                        <label>交易方式：
                            <select class="form-control" style="float:none;" v-model="searchData.交易方式">
                                <option :value="-1">全部</option>
                                <option v-for="item in  金流設定List" :value="item.編號" :key="item.金流代碼">
                                    {{ item.金流名稱 }}({{ item.金流代碼 }})
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="input-group">
                        <label>儲值類型：
                            <select class="form-control" style="float:none;" v-model="searchData.儲值類型">
                                <option :value="-1">全部</option>
                                <option v-for="(val, k) in 儲值類型Opts" :value="val" :key="val">
                                    {{ val }}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="input-group" style="display: none;">
                        <label>類別：
                            <select class="form-control" style="float:none;" v-model="searchData.型態">
                                <!-- <option :value="-1">全部</option> -->
                                <option v-for="(value, k) in 型態Enum" :value="value" :key="value">{{ k }}</option>
                            </select>
                        </label>
                    </div>
                    <label class="input-group">
                        <span class="input-group-span">識別值：</span>
                        <input class="form-control" type="text" placeholder="請輸入 識別值" v-model.trim="searchData.識別值" />
                    </label>
                    <div class="input-group">
                        <button class=" btn btn-primary" @click="search()">
                            <i class="fa fa-search"></i> 查詢
                        </button>
                    </div>

                    <!-- <div class="input-group">
                        <button class=" btn btn-primary" @click="測試()">
                            測試
                        </button>
                    </div> -->
                    <div v-if="envData.isEnv && delPermission">
                        <textarea style="width:500px" v-model.trim="envData.data" />
                        <button class=" btn btn-primary" @click="delEnv()"> 刪除黑名單(dev only) </button>
                    </div>
                </div>
                <div class="box-body">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr class="bg-gray">
                                <th>規則型態</th>
                                <th>玩家識別值</th>
                                <th>廠商</th>
                                <th>交易方式</th>
                                <th>儲值類型</th>
                                <th>消失金額</th>
                                <th>銷定時間</th>
                                <th>解除日期</th>
                                <th>鎖定時IP</th>
                                <th>功能</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of list"
                                :key="item.規則型態 + '-' + item.廠商 + '-' + item.玩家識別值 + '-' + item.交易方式 + '-' + item.儲值類型">
                                <td>{{ trans規則型態(item.規則型態) }}</td>
                                <td>{{ item.玩家識別值 }}</td>
                                <td>{{ item.廠商 }}</td>
                                <td>{{ item.交易方式 }}</td>
                                <td>{{ item.儲值類型 }}</td>
                                <td>{{ item.消失金額 }}</td>
                                <td>{{ item.銷定時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
                                <td>{{ item.解除日期 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
                                <td>{{ item.鎖定時IP }}</td>
                                <td>
                                    <!-- <button class="btn btn-danger" @click="openLiftForm(item)"> 解除 </button> -->
                                </td>
                            </tr>
                            <tr v-show="list.length == 0">
                                <td colspan="10" style="text-align: center;">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="overlay" ref="loading"><i class="fa fa-refresh fa-spin"></i></div>
            </div>
        </section>
    </div>
</template>

<script>
import api from "@/assets/js/app-api";
import TransEnumMixins from '@/assets/js/Tool/TransEnumMixins'
import PopForm from "@/views/Template/PopFormTemplate";
import { APILib } from '@/views/projects/ips/IPSTool2';
import Multiselect from "vue-multiselect";
import datePicker from "vue-bootstrap-datetimepicker";
import RiskLib from '@/views/projects/ips/Risk/RiskLib';
let 型態Enum = {
    帳號: "帳號",
    // IP: "IP",//暫時沒這個東西
}

export default {
    mixins: [TransEnumMixins],
    components: {
        PopForm,
        Multiselect,
        datePicker
    },
    data() {
        return {
            金流設定List: [],//交易方式用
            金流支付List: [],//儲值類型用
            型態Enum,
            envData: {
                isEnv: process.env.NODE_ENV == 'development',//'development'
                data: `{"型態":"帳號","廠商編號":"10001","識別值":"jontest","交易方式":"Gash","儲值類型":"BNK82216"}`,
            },
            searchData: {
                型態: 型態Enum.帳號,
                廠商: "",
                識別值: "",
                交易方式: "",//這裡記金流設定的編號
                儲值類型: "",
            },
            廠商List: [],
            廠商Opts: [],
            list: [
            ],
        };
    },
    computed: {
        儲值類型Opts() {
            let 編號 = this.searchData.交易方式;
            let tmp = {};
            this.金流支付List.forEach(item => {
                if (item.金流設定主檔編號 === 編號) {
                    tmp[item.支付方式代碼] = item.支付方式代碼;
                }
            });
            let filter = Object.keys(tmp);
            // if (filter[0]) {
            //     this.searchData.儲值類型 = filter[0];
            // } else {
            this.searchData.儲值類型 = -1;
            // }
            return filter;
        },
        delPermission() {
            return this.$user.hasPermission("API_DelPaymentLimiteBlack");
        },
    },
    watch: {
        廠商List: {
            handler(v) {
                this.廠商Opts = [];
                // this.廠商Opts.unshift({
                //     廠商代碼: "-1",
                //     廠商編號: "-1",
                //     名稱: "全部"
                // });
                for (let i of v) {
                    // if (i.廠商編號 != "10001") {
                    //     continue;
                    // }
                    this.廠商Opts.push(i);
                }
                this.searchData.廠商 = this.廠商Opts[0];
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                this.金流支付List = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;
                this.廠商List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                this.金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                this.searchData.交易方式 = -1;//this.金流設定List[0].編號;
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.金流設定List = [];
                this.searchData.交易方式 = "";
                this.金流支付List = [];
                this.廠商List = [];
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async search() {
            $(this.$refs.loading).show();
            try {
                if (!this.searchData.廠商.廠商編號) {
                    throw "請選取廠商";
                }
                if (this.searchData.廠商.廠商編號 == -1) {
                    throw "請選取廠商";
                }
                let cmd = {
                    廠商編號: this.searchData.廠商.廠商編號,
                    型態: this.searchData.型態,//"帳號",
                    識別值: this.searchData.識別值,//"000零",
                    交易方式: this.searchData.交易方式 == -1 ? "" : this.searchData.交易方式,//"Gash",
                    儲值類型: this.searchData.儲值類型 == -1 ? "" : this.searchData.儲值類型,//"BNK82216"
                };
                await this._search(cmd);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        trans規則型態: RiskLib.trans規則型態,
        async _search(cmd) {
            this.list = [];
            let res = await api.IPSSetting.risk.GetPaymentLimiteBlack(cmd);
            if (res && res.data && res.data.status == 1) {
                for (let i of res.data.content) {
                    let 廠商 = this.廠商List.find((item) => { return item.廠商編號 == i.廠商編號 })
                    let 廠商名稱 = 廠商 ? 廠商.名稱 : i.廠商編號;
                    this.$set(i, '廠商', 廠商名稱);
                }
                this.list = res.data.content;
            } else {
                let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                throw errMsg;
            }
        },
        async delEnv() {
            try {
                let cmd = JSON.parse(this.envData.data);
                let res = await api.IPSSetting.risk.DelPaymentLimiteBlack(cmd);
                if (res && res.data && res.data.status == 1) {
                    throw "執行成功" + res.data.content;
                } else {
                    let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                    throw "執行失敗" + errMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        }
        // async 測試() {
        //     console.error("enter...")
        //     let res = await api.IPSSetting.risk.SetPaymentLimiteBlack({
        //         規則型態: "Key符合時多少以上消失",
        //         識別值: "RDTest",
        //         廠商編號: "10001",
        //         交易方式: "Gash",
        //         儲值類型: "BNK82216",
        //         消失金額: 1600.0,
        //         銷定時間: "2023-09-19T16:15:55.228",
        //         解除日期: "2023-09-21T00:00:00",
        //         鎖定時IP: "::1"
        //     });
        //     console.error(res);
        // },
    },
    filters: {
    }
};
</script>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.multiselect-width {
    width: 250px;
}
</style>