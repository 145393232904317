<template>
  <div id="RewardMessageHistory">
    <section class="content-header">
      <h1>給獎訊息設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/FC/RewardMessage">給獎訊息設定</router-link>
        </li>
        <li class="active">歷程明細</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <div class="input-group date">
              <div class="input-group-addon">
                <i class="fa fa-calendar"></i>
              </div>
              <div class="pull-right">
                <DateRangePicker
                  :propEndDate.sync="searchData.local.endTime"
                  :propStartDate.sync="searchData.local.startTime"
                />
              </div>
            </div>
            <div class="btns">
              <button
                @click="resetDataList()"
                class="btn btn-primary"
                type="button"
              >
                查詢
              </button>
            </div>
            <div class="text">
              <p>總發送筆數：{{ total }}</p>
              <p>成功收件筆數：{{ sendCount }}</p>
              <p>成功領取筆數：{{ getCount }}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px">日期</th>
                  <th class="text-nowrap">收件筆數</th>
                  <th class="text-nowrap">領取筆數</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="3">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="3">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ item.sendDay | dateFormat("YYYY/MM/DD") }}
                  </td>
                  <td>{{ item.sendCount | currencyFormat }}</td>
                  <td>{{ item.getCount | currencyFormat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  props: {
    id: "",
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
      },
      datas: [],
      sendCount: 0,
      getCount: 0,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.searchData.prizeMessageId = this.id;
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} UTC`
      );
      this.$store
        .dispatch("fcReward/historyQueryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.sendCount = result.sendCount;
          this.getCount = result.getCount;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    backList() {
      this.$router.push({
        name: "RewardMessage",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>