/*
說明：在畫面建立一個輸入文字框跟按鈕，點擊按鈕後，會將指定字串塞進輸入文字框
使用方式：
1. import程式碼
    ```
    import { AddArgMixins } from '@/assets/js/Tool/AddArgMixins'
    ```
2. 引入Vue的mixin
    ```
    export default {
        mixins: [AddArgMixins],
    }
    ```
3. 建立輸入文字框：
    v-model：值要輸入，指定要綁定data
    使用自定義標籤：v-AddArgInput，此自定義標籤需帶參數：
    - model: 需跟輸入文字框的v-model值一致
    - format:刪除字串判斷用，需跟按鈕的insertText格式一致，可輸入的參數可參考 AddArgFormatEnum，或參考行34，自己寫正則
    為ref屬性指定名稱
    ```
    <input type="text" v-model.trim="edit.廠商產品名稱" v-AddArgInput="{ model: 'edit.廠商產品名稱', format: '<.*?>' }" ref="廠商Input">
    ```
4. 建立按鈕：
    使用自定義標籤：v-AddArgBtn，此自定義標籤需帶參數：
    - insertText：按下按鈕後，要添加的字串
    - ref：按下按鈕後，要在那個輸入文字框添加字串
    - model: 需跟輸入文字框的v-model值一致
    ```
    <button type="button" v-AddArgBtn="{ insertText: '<儲值金額>', ref: '廠商Input',model: 'edit.廠商產品名稱' }">添加&lt;儲值金額&gt;</button>
    ```
*/

function doDelClientId(text, selectionStart, selectionEnd, reg) {
    let regex = new RegExp(`(${reg})`);// /(<.*?>)/;//let regex = /(\{\{.*?\}\})/;
    let words = text.split(regex);
    let regex2 = new RegExp(`${reg}`);// /<.*?>/;//let regex2 = /\{\{.*?\}\}/; // 用於匹配 {{xxx}} 格式的正則表達式
    let start = 0;
    let spliceStart = -1;
    let spliceEnd = -1;
    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const end = start + word.length;
        let normal = word.match(regex2);//是一般字串
        if (start >= selectionEnd || end < selectionStart) {
            // console.error(word, "not in range", start, end, selectionEnd, selectionStart)
        } else if (start >= selectionEnd || end == selectionStart) {
            if (normal && selectionEnd == selectionStart) {
                // console.error(word, "in range && in special A")
                if (spliceStart == -1) {
                    spliceStart = start;
                } else {
                    spliceStart = start < spliceStart ? start : spliceStart;
                }
                spliceEnd = end > spliceEnd ? end : spliceEnd;
            } else {
                // console.error(word, "in range && not special")
            }
        } else {
            if (normal) {
                // console.error(word, "in range && in special B")
                if (spliceStart == -1) {
                    spliceStart = start;
                } else {
                    spliceStart = start < spliceStart ? start : spliceStart;
                }
                spliceEnd = end > spliceEnd ? end : spliceEnd;
            } else {
                // console.error(word, "in range && not special")
                // newStr += word;
            }
        }
        start = start + word.length;//text.indexOf(word);
    }
    let resultStr = '';
    if (spliceStart > -1 && spliceEnd > -1) {
        spliceStart = spliceStart < selectionStart ? spliceStart : selectionStart;
        spliceEnd = spliceEnd > selectionEnd ? spliceEnd : selectionEnd;
        resultStr = text.substring(0, spliceStart) + text.substring(spliceEnd, text.length);
    }
    return {
        spliceStart,
        spliceEnd,
        resultStr,
    };
}
function replaceModelData(vnode, model, 內容) {
    let tmpModel = vnode.context;
    let models = model.split('.');
    for (let i = 0; i < (models.length - 1); i++) {
        tmpModel = tmpModel[models[i]];
    }
    let key = models[models.length - 1];
    tmpModel[key] = 內容;
}
export const AddArgMixins = {
    AddArgFormatEnum: {
        一個大刮號: "{.*?}",
        兩個大刮號: "{{.*?}}",
        一個大於小於: "<.*?>",
    },
    directives: {
        AddArgBtn: {
            bind: function (buttonDom, binding, vnode) {
                buttonDom.addEventListener("click", (event) => {
                    let el = vnode.context.$refs[binding.value.ref];
                    let 要插入的內容 = binding.value.insertText;
                    let 內容 = el.value;
                    let selectionStart = el.dataset.selectionStart >= 0 ? el.dataset.selectionStart : 0;
                    let selectionEnd = el.dataset.selectionEnd >= 0 ? el.dataset.selectionEnd : 0;

                    let head = 內容.slice(0, selectionStart);
                    let tail = 內容.slice(selectionEnd);
                    內容 = head + 要插入的內容 + tail;
                    //把值塞到v-model中
                    replaceModelData(vnode, binding.value.model, 內容);
                    // 塞完之後，把input的選取指標放到塞入的結尾
                    vnode.context.$nextTick(function () {
                        el.focus();
                        let index = (head + 要插入的內容).length;
                        el.selectionStart = index;
                        el.selectionEnd = index;
                    });
                });
            },
            inserted: function (el) {
                // console.error("inserted");
            },
            update: function (el) {
                // console.error("update");
            }
        },
        AddArgInput: {
            bind: function (el, binding, vnode) {
                // console.error("format...", binding.value.format)

                el.addEventListener('blur', function () {
                    el.dataset.selectionStart = el.selectionStart;
                    el.dataset.selectionEnd = el.selectionEnd;
                });
                el.addEventListener('keydown', function (event) {
                    if (event.keyCode === 8) {
                        const text = event.target.value;
                        let {
                            spliceStart,
                            spliceEnd,
                            resultStr
                        } = doDelClientId(text, event.target.selectionStart, event.target.selectionEnd, binding.value.format);
                        if (spliceStart > -1 && spliceEnd > -1) {
                            // 防止刪除鍵的默認行為
                            event.preventDefault();
                            replaceModelData(vnode, binding.value.model, resultStr);

                            // this.edit.內容 = resultStr;
                            vnode.context.$nextTick(function () {
                                el.focus();
                                event.target.selectionStart = spliceStart;//spliceStart;
                                event.target.selectionEnd = spliceStart;//spliceStart;
                            });
                        }
                    }
                });
            },
            inserted: function (el, binding) {
                // console.error("inserted", el, binding);
            },
            update: function (el, binding) {
                // el.focus();
                // console.error("update", el, binding);
            }
        },
    }
};