export const Mul = function (arg1, arg2) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    } catch (e) {
        //
    }
    try {
        m += s2.split(".")[1].length;
    } catch (e) {
        //
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

export const Validator = function () {
    this.cache = [];
};

Validator.prototype.add = function (item, rules) {
    let self = this;
    rules.forEach(function (rule) {
        let strategy = rule.strategy;
        let arg = rule.arg ? rule.arg : [];
        let errormsg = rule.errorMsg;

        self.cache.push(

            {

                strategy() {
                    // let strategyName = rule.strategy;//strategySet.shift();
                    // rule.arg = rule.arg ? rule.arg : [];
                    return strategy.apply(self, [item, ...arg]);
                },
                message: errormsg
            }
        );
    });
    return self;
};

Validator.prototype.start = function () {
    let errorMsg;
    this.cache.some((items) => {
        let isSuccess = items.strategy();
        if (!isSuccess) {
            errorMsg = items.message;
            return true;
        }
    });
    return errorMsg;
};
export const Strategies = {
    //不可為空
    inNotEmpty: function (val) {
        if (val === undefined) {
            return false;
        }
        if (val === null) {
            return false;
        }
        try {
            if ((val).toString().trim() == '') {
                return false;
            }
        } catch (e) {
            return false;
        }
        return !(val == '');
    },
    //必須為空
    isEmpty: function (val) {
        return (val === '');
    },
    // minLength: function (val, length) {
    //     return !(val.length < length);
    // },
    //字串長度不可超過length碼
    maxLength: function (val, length) {
        return !(val.toString().length > length);
    },
    // isMobileNumber: function (val) {
    //     return (!/^[09]{2}[0-9]{8}$/.test(val));
    // },
    RegTest: function (val, regExpObj) {
        return (regExpObj.test(val));
    },
    //非零正整數，即>0的整數
    NZeroPositiveInteger: function (val) {
        return (/^[1-9]\d*$/.test(val));
    },
    //正整數，即>=0的整數
    ZeroPositiveInteger: function (val) {
        if (val.toString() === "0") {
            return true;
        }
        return (/^[1-9]\d*$/.test(val));
    },
    //非零正浮點數，即>0的浮點數
    //eqMin為false時  min>val；decimal：小數點位數
    //eqMin為true時   min>=val；decimal：小數點位數
    CheckFloat: function (val, min, decimal = 4, eqMin = false) {
        if (isNaN(parseInt(val))) {
            return false;
        }
        let pow = Math.pow(10, decimal);
        val = Mul(val, pow);
        min = Mul(min, pow);

        let n = parseInt(val);
        if (n + "" != val + "") {
            return false;
        }
        if (n < min) {
            return false;
        }
        if (!eqMin && n == min) {
            return false;
        }
        return true;
    },
};