/* eslint-disable */
// import api from "@/assets/js/app-api";
function fack(fackdata) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res({ data: fackdata });
        }, 1000);
    })
}
let SendWinningSMS失敗 = (data) => {
    //5秒後送錯誤
    return new Promise((res, rej) => {
        setTimeout(() => {
            let fackdata = {
                "content": null,
                "redircetUrl": null,
                "orderId": "",
                "amount": 0,
                "orderStatus": "",
                "orderCode": "",
                "paymentDetail": "",
                "ipsMessage": "",
                "debugMessage": "",
                "redircetUrlMsgData": null,
                "startTime": null,
                "endTime": null,
                "status": "0",
                "stateCode": "",
                "message": "訂單編號:1000220221130000015，不存在或為不可寄送對象;",
                "errorDetail": null
            }
            res({ data: fackdata });
        }, 5000);
    }
    )
};
let SendWinningSMS成功 = (data) => {
    //5秒後送成功
    return new Promise((res, rej) => {
        setTimeout(() => {
            let fackdata = {
                "content": "",
                "redircetUrl": null,
                "orderId": "",
                "amount": 0.0,
                "orderStatus": "",
                "orderCode": "",
                "paymentDetail": "",
                "ipsMessage": "",
                "debugMessage": "",
                "redircetUrlMsgData": null,
                "startTime": null,
                "endTime": null,
                "status": "1",
                "stateCode": "",
                "message": "",
                "errorDetail": null
            }
            res({ data: fackdata });
        }, 5000);
    }
    )
};

let upInvoiceJackpot成功 = (data) => {
    //5秒後送成功
    return new Promise((res, rej) => {
        setTimeout(() => {
            let fackdata = {
                "content": "",
                "redircetUrl": null,
                "orderId": "",
                "amount": 0.0,
                "orderStatus": "",
                "orderCode": "",
                "paymentDetail": "",
                "ipsMessage": "",
                "debugMessage": "",
                "redircetUrlMsgData": null,
                "startTime": null,
                "endTime": null,
                "status": "1",
                "stateCode": "",
                "message": "",
                "errorDetail": null
            };
            res({ data: fackdata });
        }, 5000);
    })
};
let getInvoiceJackpot = (data) => {
    //5秒後送成功
    return new Promise((res, rej) => {
        setTimeout(() => {
            let fackdata = {
                "list": [
                    {
                        "歸戶類型": "A",
                        "發票年份": "2023",
                        "發票期數": "3, 4月",
                        "購買日期": "2023/04/10",
                        "發票號碼": "ED14361088",
                        "中獎類別": "D",
                        "獎項": "雲端500",
                        "是否捐贈": 0,
                        "愛心條碼": null,
                        "訂單編號": "1000220221130000016",
                        "服務編號": "10002",
                        "暱稱": "YOE-886937613823",
                        "門號": "0972917802",
                        "收件人": null,
                        "收件地址": null,
                        "連絡電話": null,
                        "信箱": "",
                        "填寫日期": "",
                        "備註": null,
                        "建立日期": "2023/06/08 09:21:01",
                        "廠商代碼": "24438615",
                        "兌換碼": "753364",
                        "列印時間": "2023-06-12",
                        "是否列印": true,
                        "是否寄送簡訊": true,
                        "是否逾期": false,
                        "全家列印QRCODE": "5Z+RiRjHrWq5Qf00mug67yiPG97bpAVa63VFHU7vPjg="
                    },
                    {
                        "歸戶類型": "A",
                        "發票年份": "2023",
                        "發票期數": "3, 4月",
                        "購買日期": "2023/04/10",
                        "發票號碼": "ED14361086",
                        "中獎類別": "D",
                        "獎項": "雲端500",
                        "是否捐贈": 0,
                        "愛心條碼": null,
                        "訂單編號": "1000220221130000014",
                        "服務編號": "10002",
                        "暱稱": "YOE-886921185260",
                        "門號": "0921185260",
                        "收件人": null,
                        "收件地址": null,
                        "連絡電話": null,
                        "信箱": "",
                        "填寫日期": "",
                        "備註": null,
                        "建立日期": "2023/06/08 09:21:01",
                        "廠商代碼": "24438615",
                        "兌換碼": "753364",
                        "列印時間": null,
                        "是否列印": false,
                        "是否寄送簡訊": true,
                        "是否逾期": false,
                        "全家列印QRCODE": "REuIh2zy4Sk/Iid0s/bN+znwrAoKd/KMdMUtXUZVT20="
                    },
                    {
                        "歸戶類型": "A",
                        "發票年份": "2023",
                        "發票期數": "3, 4月",
                        "購買日期": "2023/04/10",
                        "發票號碼": "AA00000003",
                        "中獎類別": "C",
                        "獎項": "雲端100萬",
                        "是否捐贈": 1,
                        "愛心條碼": "919",
                        "訂單編號": "1000220221130000010",
                        "服務編號": "10002",
                        "暱稱": "YOE-886937613823",
                        "門號": "0937613823",
                        "收件人": null,
                        "收件地址": null,
                        "連絡電話": null,
                        "信箱": "",
                        "填寫日期": "",
                        "備註": null,
                        "建立日期": "2023/06/08 09:58:49",
                        "廠商代碼": "24438615",
                        "兌換碼": null,
                        "列印時間": null,
                        "是否列印": false,
                        "是否寄送簡訊": false,
                        "是否逾期": true,
                        "全家列印QRCODE": ""
                    },
                ],
                "total": 3,
                "status": "1",
                "stateCode": "",
                "message": "",
                "errorDetail": null
            };
            res({ data: fackdata });
        }, 1000);
    })
}
let ResetPrintStatus = (data) => {
    return new Promise((res, rej) => {
        setTimeout(() => {
            let fackdata = {
                "content": "",
                "redircetUrl": null,
                "orderId": "",
                "amount": 0.0,
                "orderStatus": "",
                "orderCode": "",
                "paymentDetail": "",
                "ipsMessage": "",
                "debugMessage": "",
                "redircetUrlMsgData": null,
                "startTime": null,
                "endTime": null,
                "status": "1",
                "stateCode": "",
                "message": "",
                "errorDetail": null
            }
            res({ data: fackdata });
        }, 1000);
    })
}
// api.IPSSetting.SendWinningSMS = SendWinningSMS成功;
// api.IPSSetting.SendWinningSMS = SendWinningSMS失敗;

let CheckOrder失敗 = function () {
    return fack({
        "content": null,
        "redircetUrl": null,
        "orderId": "",
        "amount": 0.0,
        "orderStatus": "",
        "orderCode": "",
        "paymentDetail": "",
        "ipsMessage": "",
        "debugMessage": "",
        "redircetUrlOrderInfo": "",
        "redircetUrlMsgData": null,
        "startTime": null,
        "endTime": null,
        "status": "0",
        "stateCode": "0001",
        "message": "",
        "errorDetail": {
            "errorCode": "0001",
            "thirdErrorCode": "P101",
            "errorMsg": "",
            "category": "Hilife",
            "categoryName": "萊爾富便利商店"
        }
    })
}

let CheckOrder成功 = function () {
    return fack({
        "content": "33345",
        "redircetUrl": null,
        "orderId": "",
        "amount": 0,
        "orderStatus": "S",
        "orderCode": "",
        "paymentDetail": "",
        "ipsMessage": "",
        "debugMessage": "",
        "redircetUrlOrderInfo": "{\"訂單編號\":\"1000120230728000079\",\"商品代碼\":\"10180710\",\"原始金額\":24700.0000,\"玩家識別值\":\"吉祥如意\",\"上游廠商訂單編號\":\"\"}",
        "redircetUrlMsgData": null,
        "startTime": null,
        "endTime": null,
        "status": "1",
        "stateCode": "",
        "message": "點",
        "errorDetail": null
    })
}

let VerifyEInvoice成功 = function () {
    return fack("驗證成功，連線正常");
}
let VerifyEInvoice失敗 = function () {
    return fack("條碼未正確或不存在");
}

let QueryOrderList重驗證列表1 = function () {
    return fack(
        {
            "content": {
                "orders": [
                    {
                        "訂單編號": "1000120230816000071",
                        "交易方式": "FamilyMart",
                        "儲值類型": "FamiPortStoreNow",
                        "商品代碼": "14600001",
                        "廠商訂單編號": "36761733701",
                        "上游廠商訂單編號": "",
                        "玩家識別值": "jontest",
                        "訂單狀態": "N",
                        "幣別": "TWD",
                        "原始金額": 300.0000,
                        "交易金額": 0.0000,
                        "配點金額": 0.0000,
                        "通知配點狀態": -1,
                        "配點回應代碼": "",
                        "使用者IP": "::1",
                        "備註": "",
                        "交易時間": null,
                        "配點時間": null,
                        "建立日期": "2023-08-16T17:58:52.163",
                        "玩家暱稱": "jontest",
                        "退貨YN": false,
                        "訂單明細": null,
                        "平台識別": "jontest",
                        "配發模式": null,
                        "廠商識別碼": null,
                        "服務編號": null,
                        "門號": null,
                        "email": null,
                        "發票類別": 0,
                        "發票號碼": null,
                        "發票抬頭": null,
                        "發票地址": null,
                        "統一編號": null,
                        "載具條碼": null,
                        "愛心條碼": null,
                        "載具類別": 0,
                        "防偽隨機碼": null,
                        "收件姓名": null
                    }
                ],
                "total": 1,
                "stateCode": null,
                "status": "1",
                "message": "",
                "errorDetail": null
            },
            "redircetUrl": null,
            "orderId": "",
            "amount": 0.0,
            "orderStatus": "",
            "orderCode": "",
            "paymentDetail": "",
            "ipsMessage": "",
            "debugMessage": "",
            "redircetUrlOrderInfo": null,
            "redircetUrlMsgData": null,
            "startTime": null,
            "endTime": null,
            "status": "1",
            "stateCode": "",
            "message": "",
            "errorDetail": null
        }
    )
}
//按下重驗證後，重新取得該筆列表()
let QueryOrderList重驗證列表2 = function () {
    return fack(
        {
            "content": {
                "orders": [
                    {
                        "訂單編號": "1000120230816000071",
                        "交易方式": "FamilyMart",
                        "儲值類型": "FamiPortStoreNow",
                        "商品代碼": "14600001",
                        "廠商訂單編號": "36761733701",
                        "上游廠商訂單編號": "",
                        "玩家識別值": "jontest",
                        "訂單狀態": "F",
                        "幣別": "TWD",
                        "原始金額": 300.0000,
                        "交易金額": 0.0000,
                        "配點金額": 0.0000,
                        "通知配點狀態": -1,
                        "配點回應代碼": "",
                        "使用者IP": "::1",
                        "備註": "",
                        "交易時間": null,
                        "配點時間": null,
                        "建立日期": "2023-08-16T17:58:52.163",
                        "玩家暱稱": "jontest",
                        "退貨YN": false,
                        "訂單明細": null,
                        "平台識別": "jontest",
                        "配發模式": null,
                        "廠商識別碼": null,
                        "服務編號": null,
                        "門號": null,
                        "email": null,
                        "發票類別": 0,
                        "發票號碼": null,
                        "發票抬頭": null,
                        "發票地址": null,
                        "統一編號": null,
                        "載具條碼": null,
                        "愛心條碼": null,
                        "載具類別": 0,
                        "防偽隨機碼": null,
                        "收件姓名": null
                    }
                ],
                "total": 1,
                "stateCode": null,
                "status": "1",
                "message": "",
                "errorDetail": null
            },
            "redircetUrl": null,
            "orderId": "",
            "amount": 0.0,
            "orderStatus": "",
            "orderCode": "",
            "paymentDetail": "",
            "ipsMessage": "",
            "debugMessage": "",
            "redircetUrlOrderInfo": null,
            "redircetUrlMsgData": null,
            "startTime": null,
            "endTime": null,
            "status": "1",
            "stateCode": "",
            "message": "",
            "errorDetail": null
        }
    )
}
let CheckOrder重驗證 = function () {
    return fack(
        {
            "content": null,
            "redircetUrl": null,
            "orderId": "",
            "amount": 0.0,
            "orderStatus": "",
            "orderCode": "",
            "paymentDetail": "",
            "ipsMessage": "",
            "debugMessage": "",
            "redircetUrlOrderInfo": "",
            "redircetUrlMsgData": null,
            "startTime": null,
            "endTime": null,
            "status": "0",
            "stateCode": "9014",
            "message": "",
            "errorDetail": {
                "errorCode": "9014",
                "thirdErrorCode": "",
                "errorMsg": "",
                "category": "IpsSystem",
                "categoryName": "IpsSystem"
            }
        }
    )
}
export default function (api) {
    // api.IPSSetting.getInvoiceJackpot = getInvoiceJackpot;
    // api.IPSSetting.upInvoiceJackpot = upInvoiceJackpot成功;
    // api.IPSSetting.ResetPrintStatus = ResetPrintStatus;
    // api.IPSSetting.VerifyEInvoice = VerifyEInvoice成功;
    // api.IPSSetting.VerifyEInvoice = VerifyEInvoice失敗;

    // {
    //     api.IPSSetting.CheckOrder = CheckOrder失敗;
    // }
    // {
    //     api.IPSSetting.CheckOrder = CheckOrder成功;
    // }
    // {
    //     api.IPSSetting.CheckOrder = CheckOrder重驗證;
    //     api.IPSSetting.QueryOrderList1 = QueryOrderList重驗證列表1;
    //     api.IPSSetting.QueryOrderList2 = QueryOrderList重驗證列表2;
    // }
    return api;
};