<template>
  <div id="ClassTypeManager">
    <section class="content-header">
      <h1>分類管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">分類管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="addType()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="moduleId"
                @change="queryAll"
              >
                <option
                  :value="item.id"
                  v-for="(item, index) in projectData.module"
                  :key="index"
                >{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered classTypeTable" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;" width="50%">分類ID</th>
                  <th style="min-width: 150px;" width="50%">分類名稱</th>
                  <th style="min-width: 97px;" width="50%">module</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">排序</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>
                <tr :key="index" v-for="(item, index) in classTypes.datas">
                  <td>
                    <p class="m-0" v-show="item.editor==0">{{ item.classId }}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.classId"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td>
                    <p class="m-0" v-show="item.editor==0">{{ item.className }}</p>
                    <div
                      v-for="(detail, index) in detailClassLang"
                      :key="index"
                      v-show="item.editor!=0"
                    >
                      <p>{{getLangName(detail.lang)}}：</p>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                        v-model="detail.className"
                      />
                    </div>
                  </td>
                  <td>
                    <select v-model="item.module" :disabled="item.editor==0">
                      <option
                        :value="item.id"
                        :key="index"
                        v-for="(item, index) in projectData.module"
                      >{{item.name}}</option>
                    </select>
                  </td>
                  <td align="center">
                    <p class="m-0" v-show="item.editor==0">{{ item.sortId }}</p>
                    <input
                      class="form-control text-center"
                      required="required"
                      type="number"
                      v-model="item.sortId"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td class="text-center" align="center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :false-value="1"
                        :id="'check'+index"
                        :true-value="0"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.isStop"
                      />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button @click="change(item)" class="btn btn-primary" v-show="item.editor==0">修改</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor!=0"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-show="item.editor==0"
                    >刪除</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor!=0"
                    >取消</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "../assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      // projectId: this.$store.state.projectId,
      moduleId: "",
      langTypes: {},
      detailClassLang: [],
      tempEdit: false,
    };
  },
  created() {
    this.getModuleQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      classTypes: state => state.classTypes.queryAll,
      response: state => state.classTypes.response,
      projectData: state => state.module.queryAll,
      detailQueryAll: state => state.classTypes.detailQueryAll
    })
  },
  watch: {
    response(value) {
      if (value.status == "1") this.$eventBus.$emit("showAlert", "更新成功!!");
      else this.$eventBus.$emit("showAlert", "更新失敗!!");
      this.queryAll();
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      if (this.projectData != null && this.projectData.module.length > 0)
        this.moduleId = this.projectData.module[0].id;
      this.queryAll();
      console.log(this.queryAll);
    },
    detailQueryAll() {
      let that = this;
      let Id = utils.getTimeId();
      this.detailClassLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          let findLang = false;
          Id = Id + i + 1;
          this.detailQueryAll.datas.some(detail => {
            if (that.langTypes[i].id == detail.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.classTypeDetailId = detail.classTypeDetailId;
                that.edit.className = detail.className;
              }
              that.detailClassLang.push({
                classTypeDetailId: detail.classTypeDetailId,
                lang: detail.lang,
                className: detail.className
              });
              findLang = true;
              return true;
            }
          });

          if (!findLang) {
            that.detailClassLang.push({
              classTypeDetailId: Id,
              lang: that.langTypes[i].id,
              className: ""
            });
          }
        }
      }
    }
  },
  methods: {
    // 顯示列表
    queryAll() {
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.moduleId,
        isDelete: 0
      });
    },

    detailLangData(classTypeId) {
      this.$store.dispatch("classTypes/classTypeDetailQueryAll", {
        classTypeId: classTypeId
      });
    },

    getModuleQueryAll() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    // 儲存更新
    workUpdate(item) {
      this.$store.dispatch("classTypes/classTypeUpdate", {
        master_model: item,
        detail_model: this.detailClassLang
      });
    },

    // 切換文字或輸入框
    change(item) {
      if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      item.editor = item.editor == 1 ? 0 : 1;
      this.detailLangData(item.classTypeId);
    },

    // 儲存
    save(item) {
      this.tempEdit = false;
      
      item.sortId = parseInt(item.sortId);
      if (item.module == "") {
        this.$eventBus.$emit("showAlert", "請選擇module!!");
        return;
      }

      if (item.classTypeId == "") {
        this.$store.dispatch("classTypes/classTypeCreate", {
          master_model: item,
          detail_model: this.detailClassLang
        });
      } else {
        this.workUpdate(item);
      }
    },

    // 新增類別
    addType() {
       if(this.tempEdit){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = true;

      this.detailClassLang = [];
      let Id = utils.getTimeId();
      this.classTypes.datas.push({
        classTypeId: "",
        projectId: this.$store.state.projectId,
        className: "",
        module: this.moduleId,
        isStop: 0,
        sortId: -1,
        editor: "1"
      });
      for (var i = 0; i < this.langTypes.length; i++) {
        Id = Id + i + 1;
        this.detailClassLang.push({
          classTypeDetailId: Id,
          lang: this.langTypes[i].id,
          className: ""
        });
      }
    },

    cancel(item, index) {
      this.tempEdit = false;
      if (item.classTypeId == "") {
        this.classTypes.datas.splice(index, 1);
      } else {
        this.queryAll();
      }
    },

    // 改變狀態
    changeStatus(item) {
      this.$store.dispatch("classTypes/classTypeUpdate", {
        master_model: item
      });
    },

    // 刪除
    deleteType(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("classTypes/classTypeDelete", item);
        }
      });
    },

    getLangName(lang) {
      switch (lang) {
        case "zh-tw":
          return "繁";
        case "zh-cn":
          return "簡";
        case "en-us":
          return "英";
      }
    }
  }
};
</script>
