<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="videoAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span>關鍵字內容</span>
          </h4>
        </div>
 
        <div class="modal-body">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="text-right" style="    width: 120px;    vertical-align: baseline;    padding-top: 15px;"><span class="text-red">*</span>關鍵字類別：</td>
                <td>
                    <select class="form-control" id="selectUnit" required="required" >
                          <!-- <option value="-1" key="0" select>全部</option> -->
                          <option value="0" key="1">Youtube</option>
                          <!-- <option value="1" key="2">Other</option> -->
                    </select>
                </td>
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;"><span class="text-red">*</span>關鍵字：</td>
                <td>
                  <input
                    class="form-control"
                    type="text" 
                    v-model="keyword.關鍵字"                   
                  >
                </td>
              </tr>
            </tbody>
          </table> 
        </div>
        <div class="modal-footer">
          <button @click="submit()" class="btn btn-primary" type="button">
            儲存
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
         
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
     .linkStyle {
        cursor: pointer;
        color: black;
        border-bottom: 1px solid;
    }

</style>


<script>
/* eslint-disable */

export default {
  data() {
    return {
      //  classTypeId: this.$parent.searchData.classTypeId,
        title: "",
        inputstatus:"",
        url: "",
        keyword:[]
    };
  },
  methods: {
    openBox(item) {
      this.keyword={ ...item };
      $(this.$refs.videoAlert).modal("show");
    },
    insertText(text) {
        this.keyword.追蹤狀態 = text;
    },
    hideAlert() {
      $(this.$refs.videoAlert).modal("hide");
      this.resetData();
    },
    resetData() {
      this.keyword.title = "";
      this.keyword.url = "";
    },
    submit() {
      this.$store.dispatch("smm/KeywordsUpdate", {
          關鍵字管理編號:this.keyword.關鍵字管理編號,
          關鍵字: this.keyword.關鍵字,
      }).then((res) => {
          this.hideAlert();
          if(res.status == 1){
            //this.$eventBus.$emit("showAlert", "儲存成功!!");
            alert("更新成功!")
            this.$emit('submit','success');
          } 
      });

    },
  },
};
</script>

<style scoped>
table tbody tr td {
  border: none;
}
</style>