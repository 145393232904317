import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    QueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjWebQuestion.queryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setQueryAll', res.data.result);
                    }
                } else {
                    commit('setQueryAll', {total:0, datas:[]});
                }
            })
        }); 
    },
    Query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjWebQuestion.query(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setQuery', res.data.result);
                    }
                } else {
                    commit('setQuery', {total:0, datas:[]});
                }
            })
        }); 
    },
    Create({ commit }, data) {
        api.nmjWebQuestion.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    Update({ commit }, data) {
        api.nmjWebQuestion.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    Delete({ commit }, data) {
        api.nmjWebQuestion.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, QueryAll) {
        state.queryAll = QueryAll;
    },
    setQuery(state, Query) {
        state.query = Query;
    },
    setResponse(state, response) {
        state.response = response;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}