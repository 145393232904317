<template>
  <FormDialog ref="memberOfficialDialog" :title="$t('角色轉正')"
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>{{$t('角色暱稱')}}</th>
            <td>{{ data.暱稱 }}</td>
          </tr>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-model="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{$t('內容')}}</th>
            <td><textarea v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  props:[
    'types'
  ],
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        暱稱:"",
        內容:"儲值成功未轉正，手動調整轉正流程",
        分類:""
      },
      player: null,
    };
  },
  mounted(){
  },
  methods: {
    async show(player) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.data.暱稱 = player;
      this.$refs.memberOfficialDialog.show();
    },
    submit(){
      this.$store.dispatch("xsw/會員轉正", this.data).then(() => {
        this.$refs.memberOfficialDialog.hide();
        this.$eventBus.$emit("showAlert", "異動成功！");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
