import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    moduleQueryAll({ commit }, data) {
        api.module.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setModuleQueryAll', res.data.result);
            }
        })
    },
    moduleQuery({ commit }, data) {
        api.module.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setModuleQuery', res.data.result);
            }
        })
    },
}

// mutations
const mutations = {
    setModuleQueryAll(state, moduleQueryAll) {
        state.queryAll = moduleQueryAll;
    },
    setModuleQuery(state, moduleQuery) {
        state.query = moduleQuery;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}