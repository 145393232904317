<template>
  <div id="CustomizeList">
    <section class="content-header">
      <h1>帳號服務群組設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>帳號服務群組設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">
            <div class="form-group fr" style="margin-bottom: 0;">
              <button class="btn btn-warning w100-md" type="button" @click="showModal()">新增群組</button>
            </div>            
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 10%;">群祖名稱</th>
                  <th class="text-nowrap" style="width: 20%;">群組說明</th>
                  <th class="text-nowrap" style="width: 15%;">指定服務</th>
                  <th class="text-nowrap" style="width: 8%;">建立人員</th>
                  <th class="text-nowrap" style="width: 15%;">建立時間</th>
                  <th class="text-nowrap text-center" style="width: 15%; min-width: 120px;">功能</th>
                </tr>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="7">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="7">沒有資料</td>
                  </tr>
                </template>
                <template v-else v-for="(item, index) in list">
                  <tr>
                    <td class="text-center">{{ (searchData.pageNum - 1) * searchData.pageSize + index + 1 }}</td>
                    <td class="text-center">{{ item.群組名稱 }}</td>
                    <td class="text-center">{{ item.群組說明 }}</td>
                    <td class="text-center">
                      <div
                        v-for="service in item.對應列表"
                        v-show="service.細項名稱"
                      >
                        {{ service.細項名稱 }}
                      </div>
                    </td>
                    <td class="text-center">{{ item.建立者姓名 }}</td>
                    <td class="text-center">{{ item.建立時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="editItem(item)"
                      >
                        編輯
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-danger"
                        type="button"
                        @click="deleteItem(item)"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!--新增-->
			<div class="modal fade" ref="create" id="serviceList" data-backdrop="static">
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-body">
              <div class="mb-20">
                <button aria-label="Close" class="close" type="button" @click="closeModal">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form class="form-horizontal" name="Form">
								<div class="box-body">
                  <div class="form-group">
										<div class="col-sm-11">
											<select
                        class="form-control"
                        id="selectUnit"
                        v-model="data.群組ID"
                      >
                        <option
                          v-for="(group, index) in groupList"
                          :value="group.gId"
                        >
                          {{ group.groupName }}
                        </option>
                      </select>
										</div>
									</div>
                </div>
              </form>
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <tbody>
                    <tr class="bg-gray">
                      <th class="text-nowrap" style="width: 50px;">勾選</th>
                      <th class="text-nowrap">服務</th>
                    </tr>
                    <template v-if="accountList.length > 0">
                      <tr v-for="account in accountList">
                        <td class="text-nowrap">
                          <div class="checkbox">
                            <label>
                              <iCheckCheckBox
                                :propValue="account"
                                :propChecked="account.checked"
                                v-on:update:checked="checkChange"
                              />
                            </label>
                          </div>
                        </td>
                        <td class="text-nowrap">{{ account.accName }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
						</div>
						<div class="modal-footer text-center">
							<button
								class="btn btn-primary"
								type="button"
								@click="save()"
							>
								確定送出
							</button>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import iCheckCheckBox from "@/components/iCheckBox";

export default {
  components: {
    Pagination,
    iCheckCheckBox
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      isEdit: false,
      searchData: {
        對應類別: "簡訊群組服務",
        pageNum: 1, 
        pageSize: 10, 
      },
      groupList: [],
      resetDate: new Date().getTime(),
      list: [],
      total: 0,
      accountList: [],
      checkIds: [],
      data: {
        對應類別: '簡訊群組服務',
        群組ID: '',
        對應列表: [],
        checked: []
      },
      resetDate: new Date().getTime(),
    }
  },
  created() {
    this.getGroupList();
    this.getDataList();
    this.getAccountList();
  },
  mounted() {},
  computed: {},
  methods: {
    getGroupList() {
      api.login.group({
        ProjectId: 'omp_sms'
      })
        .then((res) => {
          this.loading = false;
          this.groupList = res.data.result;
          this.data.群組ID = this.groupList[0].gId;
        })
    },
    getDataList() {
      this.loading = true;
      api.moloSMS.mapping.queryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    getAccountList() {
      api.moloSMS.user.queryAll({
        IsOpen: 1, //帳號 1:啟用 0:停用
        IsScmOpen: -1, //互動簡訊 1:啟用 0:停用
      })
        .then((res) => {
          this.accountList = res.data.result;
          this.accountList.unshift({
            id: '*',
            accName: "全開",
            isOpen: '1',
          })
          this.accountList.forEach(item => {
            item.checked = false;
          })
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    resetChecked() {
      this.accountList.forEach(item => item.checked = 0);
    },
    resetData() {
      this.isEdit = false
      this.data = {
        對應類別: '簡訊群組服務',
        群組ID: this.groupList[0].gId,
        對應列表: [],
        checked: [],
      }
      this.checkIds = [];
      this.resetChecked();
      this.$forceUpdate();
    },
    checkChange(data) {
      const id = data.value.id
      if (data.checked && !this.checkIds.includes(id)) {
        this.checkIds.push(id);
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
        }
      }
    },
    showModal() {
      $(this.$refs.create).modal("show");
    },
    closeModal() {
      this.resetData();
      $(this.$refs.create).modal("hide");
    },
    save() {
      this.checkIds.forEach(item => {
        this.data.對應列表.push({
          細項ID: item
        })
      })

      let type = this.isEdit ? 'update' : 'create';
      api.moloSMS.mapping[type](this.data)
        .then((res) => {
          this.global.showResponse(
            this, 
            res, 
            type, 
            () => {
              this.closeModal();
              this.getDataList()
            }
          )
        })
    },
    editItem(data) {
      this.isEdit = true;
      this.data.流水號 = data.流水號;
      this.data.群組ID = data.群組ID;
      this.data.checked = data.對應列表;

      this.accountList.forEach(item => {
        let Index = this.data.checked.findIndex(check => check.細項ID == item.id)
        if(Index > -1) item.checked = true;
        else item.checked = false;
        this.$forceUpdate()
      })

      this.showModal();
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定刪除此項目?", (isOK) => {
        if (isOK) {
          api.moloSMS.mapping.delete({
            流水號: item.流水號,
          }).then((res) => {
            this.global.showResponse(this, res, 'delete', this.getDataList)
          })
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  }
}
</script>
<style lang="scss">
#CustomizeList {
  .modal-dialog {
    .table-responsive {
      max-height: 60vh;
    }
  }
}
</style>