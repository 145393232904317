<template>
  <div id="NmjGameDataList">
    <section class="content-header">
      <h1>押注系統管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/GameBet">押注系統管理</router-link></li>
        <li :class="{'active':!currentEntry}" :style="currentEntry ? 'cursor:pointer' : 'pointer-events:none'" @click="currentEntry = null; currentEvent = null; isEditing = false; getEntryList()">入口管理</li>
        <li :class="{'active':!currentEvent}" :style="currentEvent ? 'cursor:pointer' : 'pointer-events:none'" @click="currentEvent = null; isEditing = false;" v-show="currentEntry">活動管理</li>
        <li class="active" v-show="currentEvent">賽區編輯</li>
      </ol>
    </section>
    <section class="content" v-if="currentEntry === null">
      <h2 class="text-big">入口管理</h2>
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit.prevent="resetEntryList()">
            <div class="form-group" style="width:auto;">
              <label class="control-label">查詢時間：</label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker :propStartDate.sync="entrySearchData.開始時間" :propEndDate.sync="entrySearchData.結束時間" :propTimePicker="true" />
              </div>
            </div>
            <button type="submit" class="btn btn-primary mb-10">查詢</button>
            <button type="button" @click="addEntry()" class="btn btn-block btn-warning btn-add mb-10" :disabled="isEditing">
              <i class="fa fa-plus"></i> 新增入口
            </button>
          </form>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 90px; width: 5%">入口編號</th>
                  <th style="min-width:150px; width:10%">入口開始</th>
                  <th style="min-width:150px; width:10%">入口關閉</th>
                  <th style="min-width:200px; width:20%">活動備註</th>
                  <th style="min-width:190px; width: 5%">操作</th>
                  <th style="min-width: 90px; width: 5%">資料送出</th>
                </tr>
                <tr v-if="entryLoading"><td colspan="20">查詢中</td></tr>
                <tr v-else-if="entryData.length === 0 && entryMessage"><td colspan="20">{{ entryMessage }}</td></tr>
                <tr v-else-if="entryData.length === 0"><td colspan="20">無資料</td></tr>
                <tr v-else v-for="(item, index) in entryData" :class="{'text-gray':isEditing && !item.isEdit}">
                  <td>{{ item.isNew ? '' : String(item.入口Id).padStart(5,'0') }}</td>
                  <td :colspan="item.isEdit ? '2':'1'">
                    <span v-if="!item.isEdit">{{ uiDate(item.入口開始時間) }}</span>
                    <div v-else>
                      <div class="input-group date">
                        <div class="input-group-addon fa fa-calendar"></div>
                        <DateRangePicker :propStartDate.sync="item.入口開始時間" :propEndDate.sync="item.入口關閉時間" :propTimePicker="true" />
                      </div>
                    </div>
                  </td>
                  <td v-if="!item.isEdit">
                    <span>{{ uiDate(item.入口關閉時間) }}</span>
                  </td>
                  <td>
                    <span v-if="!item.isEdit">{{ item.活動備註 }}</span>
                    <input v-else type="text" class="form-control" v-model="item.活動備註" placeholder="活動備註 (僅辨識用)" />
                  </td>
                  <td>
                    <button class="btn btn-success" v-if="item.isEdit" @click="saveEntry(item, index)">儲存</button>
                    <button class="btn btn-primary" v-else :disabled="isEditing" @click="editEntry(item, index)">入口編輯</button>
                    <button class="btn btn-default ml-5" v-if="item.isEdit" @click="cancelEntry(item, index)">取消</button>
                    <button class="btn btn-warning ml-5" v-else :disabled="isEditing" @click="getEventList(item.入口Id)">活動管理</button>
                  </td>
                  <td>
                    <button v-if="item.通知遊戲" class="btn btn-orange" :disabled="isEditing" @click="notifyGameServer(item.入口Id)">送出</button>
                    <span v-else-if="!item.isNew" class="text-gray">無更新</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination ref="entryPagination" :total="entryTotal" :pageLimitCount="entrySearchData.pageSize" v-on:pageMethod="onEntryPageChange" />
          <!-- <pre style="text-align:left;">{{ this.entryData }}</pre> -->
        </div>
      </div>
    </section>
    <section class="content" v-else-if="currentEvent === null">
      <div class="top-box">
        <h2 class="text-big pull-left mt-10 mb-10">活動管理 <small class="text-muted">(入口編號: {{String(currentEntry).padStart(5,'0')}})</small></h2>
        <button class="btn btn-default pull-right" @click="currentEntry = null; isEditing = false; getEntryList()">
          <i class="fa fa-reply"></i> 返回入口管理
        </button>
        <p class="text-red pull-right mt-10 mb-10 mr-10" v-if="isChanged" v-text="noticeMsg"></p>
      </div>
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addEvent()" type="button" class="btn btn-block btn-warning btn-add mb-10" :disabled="isEditing">
              <i class="fa fa-plus"></i> 新增活動
            </button>
          </div>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width:100px; width:10%">活動名稱</th>
                  <th style="min-width:150px; width:20%">標語1</th>
                  <th style="min-width:150px; width:20%">標語2</th>
                  <th style="min-width: 80px; width: 5%">押注額</th>
                  <th style="min-width: 80px; width: 5%">賠率</th>
                  <th style="min-width:150px; width:20%">活動連結</th>
                  <th style="min-width: 80px; width: 5%">活動狀態</th>
                  <th style="min-width:190px; width: 5%">操作</th>
                </tr>
                <tr v-if="eventLoading"><td colspan="20">查詢中</td></tr>
                <tr v-else-if="eventData.length === 0 && eventMessage"><td colspan="20">{{ eventMessage }}</td></tr>
                <tr v-else-if="eventData.length === 0"><td colspan="20">無資料</td></tr>
                <template v-else v-for="(item, index) in eventData" :class="{'text-gray':isEditing && !item.isEdit}">
                  <tr v-if="item.isEdit">
                    <td :rowspan="item.isNew ? '2' : '1'">
                      <textarea class="form-control" v-model="item.活動地點" rows="3" placeholder="活動名稱"></textarea>
                    </td>
                    <td :rowspan="item.isNew ? '2' : '1'">
                      <textarea class="form-control" v-model="item.標語1" rows="3" placeholder="標語1" maxlength="25"></textarea>
                    </td>
                    <td :rowspan="item.isNew ? '2' : '1'">
                      <textarea class="form-control" v-model="item.標語2" rows="3" placeholder="標語2" maxlength="30"></textarea>
                    </td>
                    <td rowspan="1">
                      <input v-if="item.isNew" type="number" class="form-control" v-model="item.押注額" placeholder="押注額" min="0" step="1000">
                      <span v-else>{{ item.押注額 }}</span>
                    </td>
                    <td rowspan="1">
                      <input v-if="item.isNew" type="number" class="form-control" v-model="item.賠率" placeholder="賠率" min="0">
                      <span v-else>{{ item.賠率 }}</span>
                    </td>
                    <td :rowspan="item.isNew ? '2' : '1'">
                      <textarea class="form-control" v-model="item.活動連結" rows="3"></textarea>
                    </td>
                    <td :rowspan="item.isNew ? '2' : '1'" :class="{'text-red':item.活動狀態===false}">{{ item.活動狀態 === null ? '-' : (item.活動狀態 ? '已開啟' : '已關閉') }}</td>
                    <td :rowspan="item.isNew ? '2' : '1'">
                      <button class="btn btn-success" v-if="item.isEdit" @click="saveEvent(item, index)">儲存</button>
                      <button class="btn btn-default ml-5" v-if="item.isEdit" @click="cancelEvent(item, index)">取消</button>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{ item.活動地點 }}</td>
                    <td>{{ item.標語1 }}</td>
                    <td>{{ item.標語2 }}</td>
                    <td>{{ item.押注額 }}</td>
                    <td>{{ item.賠率 }}</td>
                    <td><a :href="item.活動連結" rel="noreferrer" target="_blank">{{ item.活動連結 }}</a></td>
                    <td :class="{'text-red':item.活動狀態===false}">{{ item.活動狀態 === null ? '-' : (item.活動狀態 ? '已開啟' : '已關閉') }}</td>
                    <td>
                      <button class="btn btn-primary" :disabled="isEditing" @click="editEvent(item, index)">編輯活動</button>
                      <button class="btn btn-warning ml-5" :disabled="isEditing" @click="getCompetitionList(item.活動Id)">編輯賽區</button>
                    </td>
                  </tr>
                  <tr v-if="item.isEdit && item.isNew">
                    <td colspan="2" class="text-red" style="min-width: 240px;">※壓住額及賠率送出後將不可調整</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="box-footer text-center no-border">
          <pre style="text-align:left;">{{ eventData }}</pre>
        </div> -->
      </div>
    </section>
    <section class="content" v-else>
      <div class="top-box">
        <h2 class="text-big pull-left mt-10 mb-10">編輯賽區 <small class="text-muted">({{ this.eventData.find(i=>i.活動Id == this.currentEvent).活動地點 }})</small></h2>
        <button type="button" class="btn btn-default pull-right" @click="currentEvent = null; isEditing = false;">
          <i class="fa fa-reply"></i> 返回活動管理
        </button>
        <button v-if="this.eventData.find(i=>i.活動Id == this.currentEvent).活動狀態 !== false" @click="setEventClose()" type="button" class="btn btn-danger pull-right mr-10">
          <i class="fa fa-minus-circle"></i> 關閉活動
        </button>
        <button v-else @click="setEventOpen()" type="button" class="btn btn-success pull-right mr-10">
          <i class="fa fa-check-circle"></i> 開啟活動
        </button>
        <p class="text-red pull-right mt-10 mb-10 mr-10" v-if="isChanged" v-text="noticeMsg"></p>
      </div>
      <div class="box box-primary">
        <div class="box-body">
          <table v-if="competitionLoading" class="table table-bordered text-center mb-10"><tbody><tr><td>查詢中</td></tr></tbody></table>
          <table v-else-if="competitionData.length === 0 && competitionMessage" class="table table-bordered text-center mb-10"><tbody><tr><td>{{ competitionMessage }}</td></tr></tbody></table>
          <table v-else-if="competitionData.length === 0" class="table table-bordered text-center mb-10"><tr><td>無資料</td></tr></table>
          <div v-else v-for="(competition, competitionIndex) in competitionData" class="table-responsive mb-20 mt-10">
            <table v-if="competition != null" class="table table-bordered table-condensed table-striped text-center">
              <tbody>
                <tr :style="`background:hsl(${(competitionIndex+1)*36}, 50%, 90%);`">
                  <th colspan="20" class="text-big" :style="`padding:.15em .25em;`">{{ '賽區 ' + (competitionIndex+1) }}</th>
                </tr>
                <tr class="text-nowrap" :style="`background:hsl(${(competitionIndex+1)*36}, 50%, 80%);`">
                  <th style="min-width: 80px; width: 5%">場次</th>
                  <th style="min-width:100px; width:10%">玩家名稱1</th>
                  <th style="min-width:100px; width:10%">玩家名稱2</th>
                  <th style="min-width:100px; width:10%">玩家名稱3</th>
                  <th style="min-width:100px; width:10%">玩家名稱4</th>
                  <th style="min-width:180px; width:10%" colspan="2">押注結束時間</th>
                  <th style="min-width:150px; width: 5%">比賽結果</th>
                </tr>
                <tr v-for="(item, index) in competition" :class="{'bg-gray-light':isEditing && !item.isEdit}">
                  <td>
                    <span v-if="!(item.isEdit && !item.是否已送出)">{{ item.場次 }}</span>
                    <input v-else type="text" class="form-control" v-model="item.場次" :placeholder="`第${index+1}場`">
                  </td>
                  <td>
                    <span v-if="!(item.isEdit && !item.是否已送出)">{{ item.參賽玩家[0] }}</span>
                    <input v-else type="text" class="form-control" v-model="item.參賽玩家[0]" placeholder="玩家1" :maxlength="isCJK(item.參賽玩家[0]) ? 6:12" >
                  </td>
                  <td>
                    <span v-if="!(item.isEdit && !item.是否已送出)">{{ item.參賽玩家[1] }}</span>
                    <input v-else type="text" class="form-control" v-model="item.參賽玩家[1]" placeholder="玩家2" :maxlength="isCJK(item.參賽玩家[1]) ? 6:12">
                  </td>
                  <td>
                    <span v-if="!(item.isEdit && !item.是否已送出)">{{ item.參賽玩家[2] }}</span>
                    <input v-else type="text" class="form-control" v-model="item.參賽玩家[2]" placeholder="玩家3" :maxlength="isCJK(item.參賽玩家[2]) ? 6:12">
                  </td>
                  <td>
                    <span v-if="!(item.isEdit && !item.是否已送出)">{{ item.參賽玩家[3] }}</span>
                    <input v-else type="text" class="form-control" v-model="item.參賽玩家[3]" placeholder="玩家4" :maxlength="isCJK(item.參賽玩家[3]) ? 6:12">
                  </td>
                  <td :colspan="item.isEdit ? '2' : '1'" style="min-width:80px">
                    <span v-if="!item.isEdit">{{ (new Date(item.押注結束時間)).toLocaleTimeString('zh-TW',{hourCycle:'h23',hour:'2-digit',minute:'2-digit'}) }}</span>
                    <label v-else class="input-group time m-0">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="time" class="form-control timepicker">
                    </label>
                  </td>
                  <td v-if="!item.isEdit" style="min-width:80px">
                    <button class="btn btn-primary" v-if="item.結果 == ''" :disabled="isEditing" @click="editCompetition(item, index, competitionIndex)">{{ item.是否已送出?'編輯時間':'編輯資訊' }}</button>
                  </td>
                  <td v-if="item.isEdit">
                    <button class="btn btn-success" v-if="item.isEdit" @click="saveCompetition(item, index, competitionIndex)">儲存</button>
                    <button class="btn btn-default ml-5" v-if="item.isEdit" @click="cancelCompetition(item, index, competitionIndex)">取消</button>
                  </td>
                  <td v-else>
                      <span v-if="item.結果 == '' && !item.是否已送出" class="text-muted">資訊未送出</span>
                      <span v-else-if="item.結果 != ''" :class="{'text-red':item.結果 == '取消比賽'}">{{ item.結果 }}</span>
                      <select v-else class="form-control" name="" id="" @change="setEventWinner(item, index, competitionIndex)" v-model="item.結果">
                        <option value="" selected disabled hidden>選擇結果</option>
                        <option v-for="(player, i) of item.參賽玩家" :value="i">{{ player }}</option>
                        <option value="-1" class="text-red">取消比賽</option>
                      </select>
                  </td>
                </tr>
                <tr v-show="eventData.find(i=>i.活動Id == currentEvent).活動狀態 !== false">
                  <td></td>
                  <td colspan="4"><span class="text-red" v-show="isEditing && competition.find(i=>i.isNew)">※玩家名稱限制英文12字，中文6字</span></td>
                  <td colspan="2"><span class="text-red" v-show="isEditing && competition.find(i=>i.isEdit)">※日期為當天，並僅限未來時間</span></td>
                  <td style="padding:.25em;">
                    <button @click="addCompetition(competitionIndex)" class="btn btn-success btn-sm" :disabled="isEditing">
                      <i class="fa fa-plus"></i> 新增場次
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="top-box" v-show="competitionData.length < 10 && eventData.find(i=>i.活動Id == currentEvent).活動狀態 !== false">
            <button @click="addCompetition()" type="button" class="btn btn-block btn-warning mb-10 mt-20" :disabled="isEditing">
              <i class="fa fa-plus"></i> 新增賽區
            </button>
          </div>
        </div>
        <!-- <div class="box-footer text-center no-border">
          <pre style="text-align:left;">{{ competitionData }}</pre>
        </div> -->
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  #NmjGameDataList{
    .top-box > .input-group{
      margin-bottom: 10px;
    }
    .table td{
      line-height: 1.2;
    }
    .table .table-player{
      th, td{
        min-width: auto;
        padding: .1em .25em;
        &:nth-child(1), &:nth-child(3), &:nth-child(4){ min-width:4em; width:10%;}
      }

      tr:nth-child(2n){
        background: rgba(0,0,0,.03);
      } 
    }
    .mark{
      padding: 0;
    }
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import Pagination from "@/components/Pagination";


export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
  },
  props: {
    entry: "",
    event: "",
  },
  data() {
    return {
      entryLoading: false,
      entryMessage: "",
      entrySearchData: {
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 23:59:59"),
        結束時間: new moment().add(1, "month").format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
      },
      entryData: [], //資料
      entryTotal: 0, //總筆數
      currentEntry: null,

      eventLoading: false,
      eventMessage: "",
      eventData: [], //資料
      eventTotal: 0, //總筆數
      currentEvent: null,

      competitionLoading: false,
      competitionMessage: "",
      competitionData: [], //資料
      competitionTotal: 0, //總筆數

      isEditing: false,
      isChanged: false,
      editBackup: [],
      noticeMsg: "※有未同步遊戲的資訊，記得回入口按「送出」",
    };
  },
  async created() {
    this.getEntryList();
    if (this.entry) this.getEventList(Number(this.entry));
    if (this.entry && this.event) this.getCompetitionList(Number(this.event));
  },
  computed:{
    
  },
  watch:{
    currentEntry(value){
      this.$router.replace({name:'NmjGameBet', params:{entry:this.currentEntry, event:this.currentEvent}});
    },
    currentEvent(value){
      this.$router.replace({name:'NmjGameBet', params:{entry:this.currentEntry, event:this.currentEvent}});
    }
  },
  methods: {
    async onEntryPageChange(pageIdx, pageSize) {
      this.entrySearchData.pageNum = pageIdx;
      this.entrySearchData.pageSize = pageSize;
      this.getEntryList();
    },
    resetEntryList(){
      this.onEntryPageChange(1, this.entrySearchData.pageSize);
    },
    getEntryList() {
      this.entrySearchData.開始時間 = this.entrySearchData.開始時間.replace(/\//g, '-');
      this.entrySearchData.結束時間 = this.entrySearchData.結束時間.replace(/\//g, '-');
      this.isEditing = false;
      this.entryLoading = true;
      this.$store.dispatch("nmjGameBet/queryAllEntry", this.entrySearchData).then((result) => {
          this.entryData = result.datas || [];
          this.entryTotal = result.total;
          this.entryLoading = false;
          this.entryMessage = "";
          this.$refs.entryPagination.setPage(this.entrySearchData.pageNum, this.entryTotal);
        }, (msg) =>{
          this.entryData = [];
          this.entryTotal = 0;
          this.entryLoading = false;
          this.entryMessage = msg;
        });
    },
    addEntry(){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      this.entryData.unshift({
        入口開始時間: new moment().add(1, "days").format("YYYY/MM/DD 08:00:00"),
        入口關閉時間: new moment().add(1, "days").format("YYYY/MM/DD 22:00:00"),
        活動備註: "",
        isNew: true,
      });
      this.editEntry(this.entryData[0], 0);
    },
    editEntry(item, index){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      this.isEditing = true;
      this.editBackup = Object.assign({}, item);
      item.isEdit = true;
      this.$set(this.entryData, index, item)
    },
    cancelEntry(item, index){
      if (item.isNew) this.entryData.shift();
      else this.$set(this.entryData, index, this.editBackup);
      this.isEditing = false;
    },
    saveEntry(item, index){
      delete item.isNew;
      delete item.isEdit;
      this.isEditing = false;
      let api = item.入口Id ? 'updateEntry' : 'createEntry';
      this.$store.dispatch("nmjGameBet/" + api, item).then((result) => {
        this.$eventBus.$emit("showAlert", "更新成功！");
        this.getEntryList();
      }, (msg) => {
        this.$eventBus.$emit("showAlert", msg);
        this.getEntryList();
      });
    },
    notifyGameServer(entryID){
      this.$store.dispatch("nmjGameBet/notifyGameServer", {入口Id: entryID}).then((result) => {
        this.$eventBus.$emit("showAlert", "送出成功！");
        this.getEntryList();
      }, (msg) =>{
        this.$eventBus.$emit("showAlert", msg);
      });
    },

    getEventList(entryID) {
      this.currentEntry = entryID;
      this.isEditing = false;
      this.eventLoading = true;
      this.$store.dispatch("nmjGameBet/queryAllEvent", {入口Id: entryID}).then((result) => {
          this.isChanged =  this.entryData.find(i=>i.入口Id == entryID).通知遊戲 || false;
          this.eventData = result.datas || [];
          this.eventTotal = result.total;
          this.eventLoading = false;
          this.eventMessage = "";
        }, (msg) =>{
          this.eventData = [];
          this.eventTotal = 0;
          this.eventLoading = false;
          this.eventMessage = msg;
        });
    },
    addEvent(){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      this.eventData.unshift({
        入口Id: this.currentEntry,
        活動地點: "",
        標語1: "",
        標語2: "",
        押注額: 1000,
        賠率: 1,
        活動連結: "",
        活動狀態: null,
        isNew: true,
      });
      this.editEvent(this.eventData[0], 0);
    },
    editEvent(item, index){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      this.isEditing = true;
      this.editBackup = Object.assign({}, item);
      item.isEdit = true;
      this.$set(this.eventData, index, item)
    },
    cancelEvent(item, index){
      if (item.isNew) this.eventData.shift();
      else this.$set(this.eventData, index, this.editBackup);
      this.isEditing = false;
    },
    saveEvent(item, index){
      item.押注額 = Number(item.押注額);
      item.賠率 = Number(item.賠率);
      let errMsg = "";
      if (item.活動地點 == "") errMsg += "請填寫活動名稱<br>";
      if (item.標語1 == "") errMsg += "請填寫標語1<br>";
      if (item.標語2 == "") errMsg += "請填寫標語2<br>";
      if (item.活動連結 == "") errMsg += "請填寫活動連結<br>";
      if (item.押注額 < 0) errMsg += "押注額不可為負數<br>";
      if (item.賠率 < 0) errMsg += "賠率不可為負數<br>";
      if (errMsg) return this.$eventBus.$emit("showAlert", errMsg);
      
      delete item.isNew;
      delete item.isEdit;
      this.isEditing = false;
      let api = item.活動Id ? 'updateEvent' : 'createEvent';
      this.$store.dispatch("nmjGameBet/" + api, item).then((result) => {
        this.getEventList(this.currentEntry);
        if (!this.isChanged){
          this.getEntryList();
          this.isChanged = true;
        }
        this.$eventBus.$emit("showAlert", `更新成功！<br><span class="text-red">${this.noticeMsg}</span>`);
      }, (msg) => {
        this.$eventBus.$emit("showAlert", msg);
        this.getEventList(this.currentEntry);
      });
    },

    getCompetitionList(eventID) {
      this.currentEvent = eventID;
      this.isEditing = false;
      this.competitionLoading = true;
      this.$store.dispatch("nmjGameBet/queryAllCompetition", {活動Id: eventID}).then((result) => {
          let arr = [];
          for(let item of result.datas || []){
            if (arr[item.賽區] == null || arr[item.賽區] == undefined) arr[item.賽區] = [item];
            else arr[item.賽區].push(item);
          }
          this.competitionData = arr;
          this.competitionTotal = result.total;
          this.competitionLoading = false;
          this.competitionMessage = "";
        }, (msg) =>{
          this.competitionData = [];
          this.competitionTotal = 0;
          this.competitionLoading = false;
          this.competitionMessage = msg;
        });
    },
    addCompetition(competitionIndex){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      if (competitionIndex == undefined){
        competitionIndex = this.competitionData.length;
        this.competitionData.push([]);
      }
      let stepSize = 5*60*1000; // 5 minutes
      let time = Math.ceil((new Date()).valueOf() / stepSize) * stepSize;
      this.competitionData[competitionIndex].push({
        入口Id: this.currentEntry,
        活動Id: this.currentEvent,
        賽區: competitionIndex,
        場次: "第" + (this.competitionData[competitionIndex].length+1) + "場",
        參賽玩家: ["","","",""],
        押注結束時間: new moment(time).add(5, 'minutes').format("YYYY/MM/DD HH:mm:ss"),
        結果: "",
        是否已送出: false,
        isNew: true,
      });
      let i = this.competitionData[competitionIndex].length-1;
      this.editCompetition(this.competitionData[competitionIndex][i], i, competitionIndex);
    },
    editCompetition(item, index, competitionIndex){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      this.isEditing = true;
      this.editBackup = Object.assign({}, item);
      item.isEdit = true;
      this.$set(this.competitionData[competitionIndex], index, item);
      this.$nextTick(function(){
          $('#time').timepicker({
            minuteStep: 5,
            disableFocus: true,
            showInputs: false,
            showMeridian: false,
            defaultTime: this.global.getTime(item.押注結束時間)
          }).on('changeTime.timepicker', (e) => {
            let date = new Date();
            date.setHours(e.time.hours, e.time.minutes, 0, 0);
            item.押注結束時間 = new moment(date).format("YYYY/MM/DD HH:mm:ss");
            this.$set(this.competitionData[competitionIndex], index, item); 
          })
      })
    },
    cancelCompetition(item, index, competitionIndex){
      if (item.isNew){
        this.competitionData[competitionIndex].pop();
        if (this.competitionData[competitionIndex].length == 0) this.competitionData.pop();
      }
      else{
        this.$set(this.competitionData[competitionIndex], index, this.editBackup);
      }
      this.isEditing = false;
    },
    saveCompetition(item, index, competitionIndex){
      let errMsg = ""
      if (item.場次 == "") errMsg += "請填寫場次。<br>";
      if (item.參賽玩家.some(i=>i=="")) errMsg += "請填寫所有參賽玩家名稱。<br>";
      if (!this.global.isValidFutureDay(new Date(item.押注結束時間))) errMsg += "時間已過期，請填寫未來時間。<br>";
      if (errMsg) return this.$eventBus.$emit("showAlert", errMsg);

      delete item.isNew;
      delete item.isEdit;
      this.isEditing = false;
      let api = item.場次Id ? 'updateCompetition' : 'createCompetition';
      this.$store.dispatch("nmjGameBet/" + api, Object.assign(item, {入口Id: this.currentEntry})).then((result) => {
        this.getCompetitionList(this.currentEvent);
        if (!this.isChanged){
          this.getEntryList();
          this.isChanged = true;
        }
        this.$eventBus.$emit("showAlert", `更新成功！<br><span class="text-red">${this.noticeMsg}</span>`);
      }, (msg) => {
        this.$eventBus.$emit("showAlert", msg);
        this.getCompetitionList(this.currentEvent);
      });
    },
    setEventWinner(item, index, competitionIndex){
      let 玩家Index = Number(item.結果);
      item.結果 = item.參賽玩家[玩家Index] || '取消比賽';
      this.$eventBus.$emit("showConfirm", `確定設定比賽結果為「${item.結果}」?<br><span class='text-red'>※確認後無法更改</span><style>
        #attention .close{display:none}
        #attention-dismiss{ text-indent: -1000vw; line-height:0; }
        #attention-dismiss::after{ content:'取消'; display:block; text-indent:0; line-height:1.42857143}
      </style>`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjGameBet/updateResult", {
            場次Id: item.場次Id,
            賽區Index: item.賽區,
            賽事Index: index,
            玩家Index: 玩家Index,
            結果: item.結果,
          }).then((result) => {
            this.$eventBus.$emit("showAlert", `更新成功！`);
            this.getCompetitionList(this.currentEvent);
          }, (msg) => {
            this.$eventBus.$emit("showAlert", msg);
            this.getCompetitionList(this.currentEvent);
            item.結果 = "";
          });
        } else{
          item.結果 = "";
          this.getCompetitionList(this.currentEvent);
        }
      });
    },
    setEventClose(){
      let eventObj = this.eventData.find(i=>i.活動Id == this.currentEvent);
      if (!this.competitionData.flat().every(item=>item.結果!="")){
        this.$eventBus.$emit("showAlert", `所有賽事必須選完結果才能關閉活動！`);
        return;
      }
      this.$eventBus.$emit("showConfirm", `確定關閉此活動「${eventObj.活動地點}」?<br><style>
        #attention .close{display:none}
        #attention-dismiss{ text-indent: -1000vw; line-height:0; }
        #attention-dismiss::after{ content:'取消'; display:block; text-indent:0; line-height:1.42857143}
      </style>`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjGameBet/updateEvent", Object.assign(eventObj, {活動狀態: false})).then((result) => {
            this.currentEvent = null;
            this.isEditing = false;
            this.getEventList(this.currentEntry);
          }, (msg) => {
            this.$eventBus.$emit("showAlert", msg);
            this.getEventList(this.currentEntry);
          });
        }
      });
    },
    setEventOpen(){
      let eventObj = this.eventData.find(i=>i.活動Id == this.currentEvent);
      this.$store.dispatch("nmjGameBet/updateEvent", Object.assign(eventObj, {活動狀態: true})).then((result) => {
        this.isEditing = false;
        this.getEventList(this.currentEntry);
        this.$eventBus.$emit("showAlert", `更新成功！`);
      }, (msg) => {
        this.$eventBus.$emit("showAlert", msg);
        this.getEventList(this.currentEntry);
      });
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
    isCJK(string){
      return /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/.test(string);
    }
  },
};
</script>