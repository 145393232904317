<template>
  <div id="ForumReportList">
    <section class="content-header">
      <h1>論壇檢舉訊息</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">論壇檢舉訊息</li>
      </ol>
    </section>
    <section class="content forum-report">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group clearfix">
              <label class="control-label">檢舉時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                  :propTimePicker="true"
                  v-on:restList="GetRestList"
                />
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="檢舉人門號"
                type="text"
                v-model="searchData.reportId"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="被檢舉人門號"
                type="text"
                v-model="searchData.reportedId"
              />
            </div>
            <div class="btns">
              <button @click="clickSearch()" class="btn btn-default" type="button">查詢</button>
              <button @click="submitReport()" class="btn btn-danger" type="button">
                <i class="fa fa-minus-circle"></i> 禁言
              </button>
              <button @click="haveRead()" class="btn btn-primary" type="button">
                <i class="fa fa-check"></i> 已讀
              </button>
            </div>
            <p class="text text-red" style="width:100%">* 禁言天數不輸入或大於3650天，為永久禁言。</p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1200">
              <tbody>
                <tr class="bg-gray">
                  <th>
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propChecked="checkAllStatus"
                          v-model="checkAllStatus"
                          v-on:input="checkAll()"
                        />
                      </label>
                    </div>
                  </th>
                  <th>編號</th>
                  <th style="min-width: 180px;">檢舉時間</th>
                  <th style="min-width: 120px;">檢舉人門號</th>
                  <th style="min-width: 120px;">被檢舉人門號</th>
                  <th style="min-width: 100px;">文章ID</th>
                  <th style="min-width: 200px;">檢舉內容</th>
                  <th style="min-width: 200px; width: 20%;">禁言備註</th>
                  <th style="min-width: 80px;">禁言天數</th>
                </tr>
                <tr v-if="reportTotal === 0">
                  <td class="text-center" colspan="10">沒有資料</td>
                </tr>
                <tr :key="index" v-else v-for="(item, index) in reportList">
                  <td class="text-center">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propChecked="item.checked"
                          v-model="item.checked"
                          v-on:input="checkItem(item)"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-center">{{ index+1 }}</td>
                  <td class="text-center">{{ item.reportDate.split('.')[0] }}</td>
                  <td>{{ item.reportId }}</td>
                  <td>{{ item.reportedId }}</td>
                  <td>
                    <span
                      @click="showContent(item.reportTargetId)"
                      class="link"
                    >{{ item.reportTargetId }}</span>
                  </td>
                  <td>{{ item.reportData }}</td>
                  <td>
                    <input class="form-control" type="text" v-model="item.note" />
                  </td>
                  <td>
                    <input
                      class="form-control text-center"
                      min="1"
                      placeholder="輸入天數"
                      style="min-width: 100px;"
                      type="number"
                      v-model="item.days"
                      v-on:input="itemDays(item)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <select
            @change="pageDisplay()"
            class="form-control page-size"
            v-model="searchData.pageSize"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="reportTotal"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            v-show="reportTotal > 0"
          />
        </div>
      </div>
    </section>
    <forumReportAlert
      :propsArticleId="getArticleId"
      :propsIDList="getMuteId"
      :propsList="getMuteList"
      :propsSerNum="checkedList"
      ref="forumReportAlert"
      v-on:restList="GetRestList"
    />
    <forumContent :propsCategoryNo="categoryNo" :propsID="itemID" ref="forumContent" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import iCheckCheckBox from '@/components/iCheck_CheckBox';
import iCheckMultiCheckBox from '@/components/iCheckMultiCheckBox';
import forumReportAlert from '@/views/projects/qpp/ForumReportAlert';
import forumContent from '@/views/projects/qpp/ForumContent';

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    iCheckMultiCheckBox,
    forumReportAlert,
    forumContent,
  },
  data() {
    return {
      editorName: this.$store.state.loginUser.EmployeeName,
      checkAllStatus: 0,
      itemID: '',
      categoryNo: '',
      searchData: {
        processYn: 0, // -1或未帶=全搜尋，0=未讀，1=已讀
        reportId: '',
        reportedId: '',
        page: 1,
        pageSize: 10,
        startTime: new moment()
          .subtract(7, 'd')
          .format('YYYY-MM-DD 00:00:00.000'),
        endTime: new moment().format('YYYY-MM-DD 23:59:59.999'),
        startRow: 0,
        endRow: 0,
        dateNumber: '',
      },
      checkedList: [], // 勾選清單
      muteIdList: [], // 禁言ID (電話號碼)
      getMuteList: [], // 禁言者詳細清單
      getMuteId: [], // 整理不重複的 禁言ID (電話號碼)
      articleIdList: [], // 禁言文章ID
      getArticleId: [], // 整理不重複的 禁言文章ID
    };
  },
  mounted() {
    this.queryAll();
  },
  computed: {
    ...mapState({
      reportList: (state) => state.qppReport.reportList.reportData,
      reportTotal: (state) => state.qppReport.reportList.totalNumber,
      response: (state) => state.qppReport.response,
    }),
  },
  watch: {
    reportList() {
      this.$refs.pagination.setPage(this.searchData.page, this.reportTotal);
    },
  },
  methods: {
    itemDays(item) {
      if (item.days == 0) {
        this.$eventBus.$emit('showAlert', '天數不得為0！');
        item.days = '';
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;

      this.searchData.endRow = pageIdx * pageSize;
      this.searchData.startRow = this.searchData.endRow + 1 - pageSize;

      this.resetList(); //重置清單
      this.queryAll();
    },
    pageDisplay() {
      let page = this.searchData.pageSize;
      this.onPageChange(1, Number(page));
    },
    clickSearch() {
      this.checkAllStatus = 0; //取消全選
      this.searchData.muteId = this.searchData.targetMobile;
      this.onPageChange(1, this.searchData.pageSize);
      if (this.searchData.muteId != '') {
        this.$store.dispatch('qppReport/search', this.searchData);
      }
    },
    //重置清單
    resetList() {
      this.muteIdList = [];
      this.checkedList = [];
      this.getMuteList = [];
      this.articleIdList = [];
    },
    GetRestList() {
      this.queryAll();
    },
    queryAll() {
      this.checkAllStatus = 0; //取消全選
      this.searchData.endRow = this.searchData.page * this.searchData.pageSize;
      this.searchData.startRow =
        this.searchData.endRow + 1 - this.searchData.pageSize;

      this.searchData.expiryDate = this.searchData.endTime; // 禁言到期日
      this.searchData.startDate = this.searchData.startTime; // 禁言開始日
      this.$store.dispatch('qppReport/search', this.searchData);
    },
    checkAll() {
      if (this.reportList != undefined) {
        for (var i = 0; i < this.reportList.length; i++) {
          if (this.checkAllStatus === 1) {
            this.reportList[i].checked = 1;
          } else {
            this.reportList[i].checked = 0;
          }
        }
      }
    },
    checkItem(item) {
      let id = item.serialNumber;
      let muteId = item.reportedId;
      let articleId = item.reportTargetId;
      let days = item.days == '' || item.days == undefined ? 0 : item.days;

      if (item.checked === 1) {
        if (this.checkedList.indexOf(id) === -1) {
          this.checkedList.push(id);
          this.getMuteList.push(item);
        }
        if (this.checkedList.indexOf(muteId) === -1) {
          this.muteIdList.push(muteId);
        }
        if (this.checkedList.indexOf(articleId) === -1) {
          this.articleIdList.push(articleId);
        }
      } else {
        let index = this.checkedList.indexOf(id);
        this.muteIdList.splice(index, 1); //被檢舉者ID (reportedId)
        this.checkedList.splice(index, 1); //編號ID (serialNumber)
        this.articleIdList.splice(index, 1); //文章ID (reportTargetId)
        this.getMuteList.splice(index, 1); //全部
      }
    },
    submitReport() {
      if (this.getMuteList.length === 0) {
        this.$eventBus.$emit('showAlert', '您尚未選擇禁言對象！');
        return;
      }
      this.$refs.forumReportAlert.showReportAlert();

      let muteRes = Array.from(new Set(this.muteIdList));
      let idRes = Array.from(new Set(this.articleIdList));
      this.getMuteId = muteRes;
      this.getArticleId = idRes;
    },

    //已讀
    haveRead() {
      let data = {
        serialNumber: this.checkedList,
        reportTargetId: Array.from(new Set(this.articleIdList)),
      };
      this.$store.dispatch('qppReport/edit', data);
      this.queryAll();
    },

    //顯示文章內容
    showContent(id) {
      if (id.indexOf('P') === 0) {
        //個人
        this.categoryNo = 0;
      } else if (id.indexOf('C') === 0) {
        //回覆
        this.categoryNo = 'C';
      } else if (id.indexOf('R') === 0) {
        //留言
        this.categoryNo = 'R';
      } else {
        //官方
        this.categoryNo = 1;
      }
      this.itemID = id;

      if (this.itemID === id) {
        this.$refs.forumContent.showForumContent();
      }
    },
  },
};
</script>
