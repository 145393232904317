<template>
    <div>
        <section class="content-header">
            <h1> 廠商直儲金流</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li class="active">廠商直儲金流</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">

                        <div class="form-group">
                            <label class="control-label">廠商：</label>
                            <select class="selPaymentType form-control" v-model="searchData.廠商設定主檔編號">
                                <option value="-1">
                                    全部
                                </option>
                                <option v-for="item in 廠商設定List" :key="item.編號" :value="item.編號">
                                    {{ item.名稱 }}-{{ item.顯示名稱 }}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="form-group">
                            <label class="control-label">儲值地區：</label>
                            <select class="selPaymentType form-control" v-model="searchData.儲值地區">
                                <option value="-1">
                                    全部
                                </option>
                                <option v-for="item in 地區列表" :key="item.編號" :value="item.編號">{{ item.地區 }}</option>
                            </select>
                        </div> -->


                        <div class="form-group">
                            <label class="control-label">直儲模式：</label>
                            <select class="selPaymentType form-control" v-model="searchData.直儲模式">
                                <option value="-1">
                                    全部
                                </option>
                                <option v-for="item in 直儲模式" :key="item.編號" :value="item.編號">{{ item.名稱 }}
                                </option>
                            </select>
                        </div>


                        <div class="form-group">
                            <button @click="onSearch(1, searchData)" class="btn btn-primary" type="button">
                                查詢
                            </button>
                        </div>
                        <button class="btn btn-block btn-warning btn-add" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增品項
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">廠商</th>
                                    <th class="text-nowrap">直儲名稱</th>
                                    <!-- <th class="text-nowrap">儲值地區</th> -->
                                    <th class="text-nowrap">商品代碼</th>
                                    <th class="text-nowrap">廠商產品名稱</th>
                                    <th class="text-nowrap">直儲模式</th>
                                    <th class="text-nowrap">金流</th>
                                    <th class="text-nowrap">支付方式</th>
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.編號">
                                        <td class="text-center">{{ get廠商Name(item) }}</td>
                                        <td class="text-center">{{ item.直儲名稱 }}</td>
                                        <!-- <td class="text-center">{{ item }} -->
                                        <!-- {{ get儲值地區(item.儲值地區.所屬地區) }} -->
                                        <!-- </td> -->
                                        <td class="text-center">{{ item.商品代碼 }}</td>
                                        <td class="text-center">{{ item.廠商產品名稱 }}</td>
                                        <td class="text-center">{{ get直儲Name(item) }}</td>
                                        <td class="text-center">
                                            {{ get金流Name(item) }}
                                        </td>
                                        <td class="text-center">
                                            {{ get支付Name(item) }}
                                        </td>
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="9">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="box-footer text-center no-border" v-show="total > 0">
                        <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                            v-on:pageMethod="onPageChange" />
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
import api from "@/assets/js/app-api";
import { IPSTool, APILib } from './IPSTool2'
export default {
    components: {
        Pagination,
    },
    data() {
        let pageSize = 20;
        return {
            廠商設定List: [],
            金流設定List: [],
            金流支付List: [],
            廠商支付設定List: [],
            pageSize,
            total: 0,
            searchData: {
                廠商設定主檔編號: -1,
                直儲模式: -1,
            },
            list: [],
            lastQueryCommand: null,
        };
    },
    computed: {
        ...mapState({
            直儲模式: state => state.IPSSetting.基礎設定檔.直儲模式,
            地區列表: state => state.IPSSetting.基礎設定檔.地區列表,
        })
    },
    mounted() {
        this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
        this.init();
    },
    methods: {
        async init() {
            try {
                this.廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                this.金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                this.金流支付List = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;

                let res = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({})).content;//await IPSTool.callAPIList.call(this, api.IPSSetting.廠商支付設定_查詢, {});
                this.廠商支付設定List = IPSTool.deCodeData(res, "額外限制");

                this.searchData.廠商設定主檔編號 = this.$route.query.廠商設定主檔編號 ? this.$route.query.廠商設定主檔編號 : -1;
                this.searchData.直儲模式 = this.$route.query.直儲模式 ? this.$route.query.直儲模式 : -1;
                await this.onSearch(this.$route.query.pageIdx ? this.$route.query.pageIdx : 1, this.searchData);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        async onPageChange(pageIdx) {
            this.onSearch(pageIdx, this.lastQueryCommand);
        },
        async onSearch(pageIdx, query) {
            if (!query) {
                return;
            }
            let send = JSON.parse(JSON.stringify(query));
            send.pageIdx = pageIdx;
            send.起始行 = (pageIdx - 1) * this.pageSize + 1;
            send.結束行 = pageIdx * this.pageSize;
            if (send.廠商設定主檔編號 == -1) {
                delete send.廠商設定主檔編號;
            }
            if (send.直儲模式 == -1) {
                delete send.直儲模式;
            }
            $(this.$refs.loading).show();
            try {
                let res = APILib.IPS(await api.IPSSetting.廠商直儲金流_查詢(send));
                this.list = res.content;
                this.list = IPSTool.deCodeData(this.list, "額外限制");
                this.list = IPSTool.deCodeData(this.list, "儲值金額");
                //為列表添加地區
                this.list.forEach(element => {
                    element.儲值地區 = this.金流設定List.find(item => item.編號 == element.金流設定主檔編號);
                });
                this.total = res.total;
                this.lastQueryCommand = send;
            } catch (e) {
                this.list = [];
                this.total = 0;
                this.lastQueryCommand = null;
                this.$eventBus.$emit("showAlert", e);
            }
            this.$refs.pagination.setPage(
                pageIdx,
                this.total
            );
            $(this.$refs.loading).hide();
        },
        addItem() {
            this.$router.push({
                name: "FirmPayFlowEdit",
                query: this.lastQueryCommand,
                params: { firmPayFlowId: -1 },
            });
        },
        editItem({ 編號 }) {
            this.$router.push({
                name: "FirmPayFlowEdit",
                query: this.lastQueryCommand,
                params: { firmPayFlowId: 編號 },
            });
        },
        get廠商Name({ 廠商設定主檔編號 }) {
            try {
                return this.廠商設定List.find(item => item.編號 == 廠商設定主檔編號).顯示名稱;
            } catch (e) {
                return "";
            }
        },
        // get儲值地區(所屬地區) {
        // return this.地區列表.find(item => item.編號 == 所屬地區).地區;
        // },
        get直儲Name({ 直儲模式 }) {
            return this.直儲模式.find(item => item.編號 == 直儲模式).名稱;
        },
        get金流Name({ 廠商支付編號 }) {
            let find = this.廠商支付設定List.find((item) => {
                return item.編號 == 廠商支付編號;
            })
            if (find) {
                return this.金流設定List.find(item => item.編號 == find.金流設定主檔編號).金流名稱;
            }
        },
        get支付Name({ 廠商支付編號 }) {
            let find = this.廠商支付設定List.find((item) => {
                return item.編號 == 廠商支付編號;
            })
            if (find) {
                return this.金流支付List.find(item => item.編號 == find.金流支付設定編號).支付名稱;
            }
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                delete obj.儲值地區;
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                obj = IPSTool.enCodeData(obj, "額外限制");
                obj = IPSTool.enCodeData(obj, "儲值金額");

                APILib.IPS(await api.IPSSetting.廠商直儲金流_修改(obj));
                this.onSearch(this.lastQueryCommand.pageIdx, this.lastQueryCommand);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
    },
};
</script>
<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.selPaymentType {
    width: 200px;
}
</style>