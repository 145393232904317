<template>
	<div id="ClassTypeManager" class="slot">
		<section class="content-header">
			<h1>項目設定</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li>活動機制</li>
				<li>機制項目</li>
				<li class="active">項目設定</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
          <h4 id="title">{{ $route.params.name }}</h4>
					<div class="top-box mt-20 mb-20">
						<button @click="backList()" class="btn btn-block btn-default btn-add" type="button">
							返回
						</button>
						<button @click="add()" class="btn btn-block btn-warning btn-add mr-10" type="button">
							新增
						</button>

						<button :class="['btn btn-nav', searchData.項目 == 1 ? 'is-active' : '']" @click="searchData.項目 = 1">主項目</button>
						<button :class="['btn btn-nav', searchData.項目 == 2 ? 'is-active' : '']" @click="searchData.項目 = 2">次項目</button>
						<button :class="['btn btn-nav', searchData.項目 == 3 ? 'is-active' : '']" @click="searchData.項目 = 3">延伸項目</button>
					</div>
					<div class="text">
						<p class="data-num">
							<span>資料筆數：</span>
							<span id="total">{{ total }}</span>
						</p>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th style="min-width: 150px;" width="50%">
										<span @click="changeSort()" style="cursor: pointer;">
											名稱
											<i class="fa fa-sort"></i>
										</span>
									</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td>{{ item.項目名稱 }}</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border">
					<Pagination
						ref="pagination"
						:total="1"
						:pageLimitCount="1"
						/>
						<!-- v-on:pageMethod="onPageChange" -->
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
  import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
		},
    delimiters: ["${", "}"],
    data() {
      return {
				searchData: {
					遊戲編號: Number(this.$route.params.id),
					項目: 1,//1：主項目 2：次項目 3：延伸項目
					// 是否啟用: 0,
					PageNum: 1,
					PageSize: 10,
					排序條件: 1,//1:建立時間,2:項目名稱
					排序順序: 1,//1:DESC,2:ASC
				},
				list: [],
				total: 0,
				type: 1,
				isEdit: false,
				editData: {
					url: '',
					name: '',
					company: '',
				},
				companyList: [
					"Triple Sevens: Casino Games",
					"SpinX Games Limited",
				],
				testList: [
					{
						url: 'https://d1icd6shlvmxi6.cloudfront.net/gsc/9T1WIA/7b/23/94/7b23949df54e41df95ca82f09741fb09/images/e2_%E9%81%8A%E6%88%B2%E5%88%97%E8%A1%A8/p0.png?pageId=505ab22e-15e6-4415-9b31-bc9269a13358',
						name: 'Cash Frenzy',
						company: 'Triple Sevens: Casino Games',
						count: 1,
						time: '2022-10-25 10:54:00',
						isStop: 0,
					},
					{
						url: 'https://d1icd6shlvmxi6.cloudfront.net/gsc/9T1WIA/7b/23/94/7b23949df54e41df95ca82f09741fb09/images/e2_%E9%81%8A%E6%88%B2%E5%88%97%E8%A1%A8/p2.png?pageId=505ab22e-15e6-4415-9b31-bc9269a13358',
						name: 'Slotomania',
						company: 'SpinX Games Limited',
						count: 2,
						time: '2022-10-25 10:54:00',
						isStop: 1,
					}
				]
      };
    },
    created() {
			if (this.$route.params.searchData != undefined) {
				this.searchData = this.$route.params.searchData;
			}
			this.getDataList();
    },
    mounted() {},
    computed: {},
    watch: {
			'searchData.項目'() {
				console.log('aaa');
				this.searchData.排序條件 = 1;
				this.searchData.排序順序 = 1;
				this.resetDataList(this.searchData.項目);
			}
		},
    methods: {
			getDataList() {
				api.slotEvent.item.queryAll(this.searchData)
					.then((res) => {
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
					})
			},
			resetDataList(tab) {
				if(tab) this.searchData.項目 = tab;
				this.getDataList();
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}資料？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}此資料`,
					isOK => {
						if (isOK) {
							api.slotEvent.item.state({
								編號: item.編號,
								是否啟用: item.是否啟用 == 1  ? 0 : 1
							})
								.then((res) => {
									if(res.data.status == '1') {
										this.$eventBus.$emit("showAlert", "更新成功!");
										this.getDataList();
									} else {
										this.$eventBus.$emit("showAlert", res.data.message);
									}
								})
						}
					});
			},
			changeSort() {
				if(this.searchData.排序條件 != 2) {
					this.searchData.排序條件 = 2;
					this.searchData.排序順序 = 2;
				} else {
					this.searchData.排序順序 = this.searchData.排序順序 == 1 ? 2 : 1;
				}
				this.resetDataList();
			},
			add() {
				this.$router.push({
					name: 'slotActivityItemSetEdit',
					params: {
						gameId: this.$route.params.id,
						name: this.$route.params.name,
						backSearchData: this.searchData,
					},
				});
			},
			edit(item) {
				this.$router.push({
					name: 'slotActivityItemSetEdit',
					params: {
						gameId: this.$route.params.id,
						id: item.編號,
						name: this.$route.params.name,
						backSearchData: this.searchData,
					},
				});
			},
			backList() {
				this.$router.push({
					name: 'slotActivityItem',
					params: {
						id: this.$route.params.id,
						name: this.$route.params.name
					},
				});
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
	.btn-nav {
		height: 40px;
		background: none;
    border-bottom: 1px solid #b6b6b6 !important;
    border-radius: 0 !important;
		padding: 0 25px !important;
	}
	.btn-nav.is-active {
		background: #f4f4f4;
    border-bottom: 4px solid #b6b6b6 !important;
	}
</style>