<template>
    <div class="BigDataPost">
        <section class="content-header">
            <h1>貼文資訊</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>網紅列表</li>
                <li>KPI</li>
                <li class="active">貼文資訊</li>
            </ol>
        </section>

        <section class="content" v-if="postkpiData.post_data">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <!-- <h4 class="pull-left" id="title">貼文數據</h4> -->
                        <button @click="backKOL()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回
                        </button>
                    </div>

                    <div class="row">
                        <div class="col-md-5">
                            <div class="video-container" v-if="postkpiData.post_data.platform == 'yt'">
                                <iframe width="640" height="360" :src="
                    'https://www.youtube.com/embed/' +
                    postkpiData.post_data.video_id
                  " frameborder="0" allowfullscreen></iframe>
                            </div>

                            <div v-else class="post-cardImg">
                                <img :src="postkpiData.post_data.post_display_url=='' || !postkpiData.post_data.post_display_ur ?
                  require('@/assets/no-image.png'):
                  postkpiData.post_data.post_display_url  " />
                            </div>

                            <p class="title fs18 mt-10 mb-5">
                                <a class="postLink" :href="GetLink(postkpiData)" target="_blank">
                                    {{
                  GetPostTitle(postkpiData.post_data.platform)
                }}
                                    <i class="c-BigDataLinkIcon fa fa-link"></i></a><br />
                                <small class="text-muted fs14">發佈日期:
                                    {{
                    $utils.formatTime(
                      postkpiData.post_data.upload_date,
                      "YYYY-MM-DD HH:mm"
                    )
                  }}
                                </small>
                            </p>

                            <p class="fs15">
                                <a href="javascript:;" @click="backKOL()">
                                    {{ postkpiData.channel_name }}
                                </a>

                                <span class="text-muted">粉絲數: {{ postkpiData.post_data.follow | currencyFormat}}
                                </span>
                            </p>
                        </div>
                        <div class="col-md-7">
                            <p v-html="postkpiData.post_data.description"></p>
                            <div class="label-wrappger" v-if="
                              postkpiData.post_data.tags != null &&
                              postkpiData.post_data.tags.length > 0
                            ">
                                <p class="title mb-2">貼文標籤:</p>
                                <span class="label label-success" v-for="(item, index) in postkpiData.post_data.tags"
                                    :key="index">{{ item }}</span>
                            </div>
                        </div>
                    </div>

                    <hr class="divider mb-10" />

                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">數據分析</h4>
                    </div>

                    <!-- date search -->
                    <div class="top-box mt-15">
                        <div class="CrawlTime fr">
                            最新更新時間：
                            {{
                $utils.formatTime(
                  postkpiData.post_data.last_update,
                  "YYYY-MM-DD HH:mm"
                )
              }}
                        </div>
                    </div>

                    <div class="dataCardWrapper clearfix">
                        <div class="dataCard">
                            <h5 class="card-title">按讚數</h5>
                            <p class="card-text">{{ postkpiData.like_count | currencyFormat}}</p>
                        </div>

                        <div class="dataCard">
                            <h5 class="card-title">觀看數</h5>
                            <p class="card-text">{{ postkpiData.view_count | currencyFormat}}</p>
                        </div>

                        <div class="dataCard">
                            <h5 class="card-title">留言數</h5>
                            <p class="card-text">{{ postkpiData.comment_count | currencyFormat}}</p>
                        </div>

                        <div class="dataCard">
                            <h5 class="card-title">互動數</h5>
                            <i data-toggle="tooltip" data-placement="top" title="按讚數+留言數">?</i>
                            <p class="card-text">{{ postkpiData.avg_post_interactions.toFixed(0) | currencyFormat }}</p>
                        </div>

                        <div class="dataCard">
                            <h5 class="card-title">互動率</h5>
                            <i data-toggle="tooltip" data-placement="top" title="(按讚數+留言數)/影片數/粉絲數*100%">?</i>
                            <p class="card-text">
                                {{ parseFloatNoRounding(postkpiData.avg_post_probability) }}%
                            </p>
                        </div>
                    </div>

                    <div class="nav-tabs-custom boxShadow-none clearfix overflowX-auto">
                        <div class="tab-content notabStyle">
                            <div class="tab-pane active">
                                <highcharts :options="chartOptions"></highcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    /* eslint-disable */
    import moment from "moment";
    import {
        mapState,
        mapActions
    } from "vuex";
    import DateRangePicker from "@/components/DateRangePicker";
    import HighchartsVue from "highcharts-vue";

    export default {
        components: {
            DateRangePicker,
            HighchartsVue,
        },
        data() {
            return {
                dayAnalysis: ["3", "7", "14"],
                dayAnalysisChoose: "day7",

                tabAnalysisName: ["按讚數", "觀看數", "留言數"],
                tabAnalysis: "tab0",
                tabPostName: ["近期貼文", "熱門貼文"],
                tabPost: "tab0",
                tabLayout: "table",

                chartOptions: {
                    chart: {
                        type: "spline"
                    },
                    title: {
                        text: ""
                    },
                    xAxis: {
                        title: {
                            text: "日期"
                        },
                        categories: [],
                    },
                    yAxis: {
                        title: {
                            text: "次數"
                        },
                    },
                    legend: {
                        layout: 'horizontal', //horizontal , vertical
                        align: 'center',
                        verticalAlign: 'top',
                        y: -17,
                        margin: 25,
                        itemStyle: {
                            fontSize: "14px",
                        },
                    },

                    series: [{
                            color: '#bb6767',
                            name: "按讚數",
                            data: [],
                        },
                        {
                            color: '#65aaea',
                            name: "觀看數",
                            data: [],
                        },
                        {
                            color: '#93aa61',
                            name: "留言數",
                            data: [],
                        },
                    ],
                    tooltip: {},
                },

                searchData: {
                    post_id: this.$route.params.post_id,
                    days: 9999,
                },
            };
        },
        created() {
            this.GetPostKpiData();
        },
        mounted() {
            $("[data-toggle='tooltip']").tooltip();
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapState({
                postkpiData: (state) => state.kolBigData.postkpiData,
            }),

        },
        watch: {
            postkpiData() {
                this.SetGraphValue(0);
            },
        },
        methods: {
            GetPostTitle(i) {
                let _socialPostTitle = "";
                switch (i) {
                    case 'yt':
                        _socialPostTitle = this.postkpiData.post_data.video_title;
                        break;
                    case 'tiktok':
                        _socialPostTitle = this.postkpiData.post_data.description;
                        break;
                    default:
                        _socialPostTitle = this.postkpiData.post_data.post_title;
                        break;
                }
                return _socialPostTitle;
            },
            GetPostKpiData() {
                this.$store.dispatch("kolBigData/postkpi", this.searchData);
            },

            changeDays(day) {
                this.dayAnalysisChoose = "day" + day;
                this.searchData.days = day;
                this.GetPostKpiData();
            },

            SetGraphValue(index) {
                this.chartOptions.xAxis.categories = this.postkpiData.async_day_list;
                this.tabAnalysis = "tab" + index;

                this.chartOptions.series[0].data = this.postkpiData.like_list;
                this.chartOptions.series[1].data = this.postkpiData.view_list;
                this.chartOptions.series[2].data = this.postkpiData.comment_list;

                // switch (index) {
                //   case 0:
                //     this.chartOptions.series[0].data = this.postkpiData.like_list;
                //     break;
                //   case 1:
                //     this.chartOptions.series[0].data = this.postkpiData.view_list;
                //     break;
                //   case 2:
                //     this.chartOptions.series[0].data = this.postkpiData.comment_list;
                //     break;
                // }
            },

            isVideo(item) {
                if (item.platform == "yt" || item.is_video) return true;
                return false;
            },

            parseFloatNoRounding(num) {
                if (isNaN(parseInt(num))) return 0;
                return Math.floor(num * 100) / 100;
            },
            GetLink(data) {
                switch (data.post_data.platform) {
                    case "ig":
                        return "https://instagram.com/p/" + data.post_data.shortcode;
                    case "yt":
                        return "https://www.youtube.com/watch?v=" + data.post_data.video_id;
                    case "fb":
                        console.log("bollean:" + (data.post_data.post_display_url == ""));
                        if (data.post_data.is_video) {
                            return "https://www.facebook.com/watch/live/?ref=watch_permalink&v=" + data.post_data
                                .post_id;
                        } else {
                            return data.post_data.post_url;
                        }


                        default:
                            return data.post_data.post_url;
                }
            },

            // 返回
            backKOL() {
                this.$router.push({
                    name: this.$route.params.back || "SpiderBigDataKOL",
                    params: {
                        searchData: this.$route.params.searchData,
                        kolSearchData: this.$route.params.kolSearchData,
                        id: this.$route.params.id,
                        datas: this.$route.params.datas,

                    },
                });
            },
        },
    };

</script>
