/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    contactUsQueryAll({ commit }, data) {
        api.contactUs.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setContactUsQueryAll', res.data.result);
            }
        })
    },
    contactUsQuery({ commit }, data) {
        api.contactUs.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setContactUsQuery', res.data.result);
            }
        })
    },
    contactUsUpdate({ commit }, data) {
        api.contactUs.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    contactUsExport({ commit }, data) {
        api.contactUs.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                } else {
                    let datas = res.data.result.datas;

                    appUtils.exportData(
                        datas,
                        appUtils.formatTime(new Date(), "YYYYMMDD") + "_聯絡我們列表" + ".xlsx",
                        "聯絡我們列表"
                    );
                }

            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
}

// mutations
const mutations = {
    setContactUsQueryAll(state, contactUsQueryAll) {
        state.queryAll = contactUsQueryAll;
    },
    setContactUsQuery(state, contactUsQuery) {
        state.query = contactUsQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}