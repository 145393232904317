<template>
	<div id="playList" class="slot max1040">
		<section class="content-header">
			<h1>玩法列表</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>玩法管理</li>
				<li class="active">玩法列表</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<button @click="add()" class="btn btn-block btn-warning btn-add" type="button">
							<i class="fa fa-plus"></i> 新增
						</button>
						<div class="input-group">
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="玩法名稱"
								type="text"
								v-model="searchData.玩法名稱"
							/>
							<i class="fa fa-close" v-if="searchData.玩法名稱 != ''" @click="searchData.玩法名稱 = ''"></i>
							<div class="input-group-btn">
								<button v-on:click="resetDataList()" class="btn btn-default" type="button">
									<i class="fa fa-search"></i>
								</button>
							</div>
						</div>
						<div class="text">
							<p class="data-num">
								<span>資料筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th style="min-width: 150px;" width="50%">
										<span @click="changeSort()" style="cursor: pointer;">
											玩法名稱
											<i class="fa fa-sort" ></i>
										</span>
									</th>
									<th style="min-width: 150px;" width="50%">建立時間</th>
									<th style="min-width: 150px;" width="50%">更新時間</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
								</tr>
								<tr v-if="total == 0">
									<td colspan="5"><Empty /></td>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td>{{ item.玩法名稱 }}</td>
									<td class="text-nowrap text-center">{{ item.建立時間 | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
									<td class="text-nowrap text-center">{{ item.更新時間 | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item, true)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border" v-if="total != 0">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="20">單頁顯示20項</option>
            <option value="30">單頁顯示30項</option>
            <option value="50">單頁顯示50項</option>
          </select>
					<Pagination
						ref="pagination"
						:total="total"
						:pageLimitCount="searchData.pageSize"
						v-on:pageMethod="onPageChange"
						:type2="true"
					/>
        </div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
  import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";
	import Empty from "@/components/SlotEmpty";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
			Empty
		},
    delimiters: ["${", "}"],
    data() {
      return {
				isEdit: false,
				searchData: {
					玩法名稱: "",
					pageNum: 1,
					pageSize: 20,
					排序條件: 3, //1:建立時間,2.玩法名稱,3.更新時間
					排序順序: 1, //1:DESC,2:ASC
				},
				list: [],
				total: 0,
				editData: {},
      };
    },
    created() {
			if (this.$route.params.searchData != undefined) {
				this.searchData = this.$route.params.searchData;
			}
			this.getDataList();
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
			getDataList() {
				api.slotGame.play.queryAll(this.searchData)
					.then((res) => {
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
						this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
					})
			},
			resetDataList() {
				this.searchData.pageNum = 1;
				this.getDataList();
			},
			changeSort() {
				if(this.searchData.排序條件 != 2) {
					this.searchData.排序條件 = 2;
					this.searchData.排序順序 = 2;
				} else {
					this.searchData.排序順序 = this.searchData.排序順序 == 1 ? 2 : 1;
				}
				this.resetDataList();
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}玩法？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}此資料`,
				isOK => {
					if (isOK) {
						api.slotGame.play.state({
							編號: item.編號,
							是否啟用: item.是否啟用 == 1 ? 0 : 1,
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
					}
				});
			},
			add() {
				this.$router.push({
					name: 'slotPlayEdit',
					params: {
						backSearchData: this.searchData
					},
				});
			},
			edit(item) {
				this.$router.push({
					name: 'slotPlayEdit',
					params: {
						id: item.編號,
						backSearchData: this.searchData
					},
				});
			},
			cancel() {
				this.isEdit = false;
				this.editData = {};
			},
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>