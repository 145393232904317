<template>
  <!--序號產生-->
  <div class="modal fade" ref="fcImportData">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">匯入{{ title }}資料</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <div class="form-group name-group">
                      <div class="right">
                        <input
                          id="batchCancelOrder"
                          ref="batchCancelOrder"
                          type="file"
                        />
                      </div>
                    </div>
                    <button
                      @click="readExcel()"
                      class="btn btn-block btn-danger btn-cog"
                      type="button"
                    >
                      確定匯入
                    </button>
                    <a
                      :href="`/uploads/${title}範例.xlsx`"
                      class="btn btn-block btn-success btn-cog"
                      type="button"
                      download
                    >
                      匯入{{ title }}範例
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import xlsx from "xlsx";

export default {
  props: {
    title: String,
    checks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      edit: {
        snCount: 0,
        snCodeLen: 0,
      },
    };
  },
  methods: {
    showSerialNumberEdit() {
      $("#batchCancelOrder").val("");
      $(this.$refs.fcImportData).modal("show");
    },
    readExcel() {
      //excel匯入
      var that = this;
      const files = that.$refs.batchCancelOrder.files;
      if (files.length <= 0) {
        this.$eventBus.$emit("showAlert", "尚未選擇檔案!!");
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
            cellDates: true,
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容

          if (ws.length === 0) {
            this.$eventBus.$emit("showAlert", "無資料內容可匯入");
            return;
          }

          let outputs = []; //清空
          let error = false;
          let errMessage = "";
          for (let i = 0; i < ws.length && !error; i++) {
            for (let j = 0; j < this.checks.length; j++) {
              const check = this.checks[j];
              if (!ws[i][check] && isNaN(ws[i][check])) {
                error = true;
                errMessage = `第${i + 1}筆資料內容有空值!!`;
                break;
              }
            }
            outputs.push(ws[i]);
          }

          if (error) {
            this.$eventBus.$emit("showAlert", errMessage);
            return;
          }

          this.$store
            .dispatch("fcReward/import", {
              datas: outputs,
              name: files[0].name,
            })
            .then(() => {
              this.$eventBus.$emit("showAlert", "匯入成功!!");
              $(this.$refs.fcImportData).modal("hide");
            });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    },
  },
};
</script>