<template>
    <div id="AnnouncementEdit">
        <section class="content-header">
            <h1>相關消息編輯</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>相關消息列表</li>
                <li class="active">相關消息編輯</li>
            </ol>
        </section>

        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">編輯內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button"><i class="fa fa-reply"></i> 返回列表</button>
                    </div>

                    <form class="form-horizontal" name="announcementForm">
                        <div class="box-body">
                          

                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="inputTitle">
                                    <span class="text-red">*</span> 消息標題：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="inputTitle" required="required" type="text"
                                        v-model="edit.detailTopTitle" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label text-nowrap"><span class="text-red">*</span> 網址：</label>
                                <div class="col-sm-10">
                                        <input class="form-control" id="inputTitle" required="required" type="text"
                                           v-model="edit.相關公告編號" />
                                </div>
                            </div>                                                       

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 發佈：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio-date">
                                        <div class="radio">
                                            <iCheckRedioButton checkValue="0" message="上架" name="radioMode"
                                                v-model="edit.isStop" />
                                        </div>
                                        <!-- <template v-if="edit.projectId !== 'omp_heti'"> -->
                                        <div class="input-group date">
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div class="pull-right">
                                                <DatePicker :propValue.sync="local.publishTime" />
                                            </div>
                                        </div>
                                        <div class="input-group time">
                                            <div class="input-group-addon">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                            <input class="form-control timepicker" type="text" />
                                        </div>
                                        <!-- </template> -->
                                    </div>
                                    <div class="radio">
                                        <iCheckRedioButton checkValue="1" message="下架" name="radioMode"
                                            v-model="edit.isStop" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save(false)">確認送出</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    /* eslint-disable */
    import DatePicker from "@/components/DatePicker";
    import CKEditor from "@/components/CKEditor";
    import iCheckCheckBox from "@/components/iCheck_CheckBox";
    import iCheckRedioButton from "@/components/iCheckRedioButton";
    import utils from "@/assets/js/app-utils";
    import {
        mapState,
        mapActions
    } from "vuex";
    import tinymce from "@/components/Tinymce";
    import config from "@/assets/js/app-config";
    import moment from "moment";
    import multiselect from "vue-multiselect";
    import api from "@/assets/js/app-api";

    export default {
        components: {
            iCheckCheckBox,
            iCheckRedioButton: iCheckRedioButton,
            CKEditor,
            DatePicker,
            tinymce,
            multiselect
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                timer: null,
                isEdit: false,
                editLang: "zh-tw",
                moduleId: "esgnews",
                edit: {
                    newsId: utils.getTimeId(), //公告Id
                    projectId: this.$store.state.projectId, // 專案Id
                    classTypeId: "", //分類Id
                    module:'esgnews',
                    lang: this.editLang, // 語系
                    newsDetailId: "", // 內容Id
                    detailTopTitle: "", //主旨
                    detailTopInfo: "", // 內容
                    isStop: 1, // 發佈
                    isTop: 0, // 置頂
                    isNowOn: 1, //0:參考startTime ; 1:即日起
                    publishTime: "",
                    相關公告編號: '',
                    startTime: '',
                    endTime: '',
                },
                local: {
                    startTime: new moment().format("YYYY-MM-DD"),
                    endTime: new moment().format("YYYY-MM-DD"),
                    publishTime: new moment().format("YYYY-MM-DD"),
                },
                langTypes: {},
                timeStringOption: {
                    timeStyle: "short"
                },
                timepickerOption: {},
                detailNewsLang: [],
                isPreview: false,
                picLimit: "圖片建議尺寸 700 * 530。",
                url: config.dkOption.url,
                splitNewsIdList: {
                    link1: "",
                    link2: "",
                },
            };
        },
        created() {
            this.checkSession();
            this.GetAllLang();


        },
        mounted() {
        
            this.timepickerOption.showInputs = false;
               
            $(".timepicker").timepicker(this.timepickerOption);
            //clear
            this.$store.dispatch("classTypes/classTypeQueryAll", {
                module: 'esgnews',
                isDelete: 0,
                isStop: 0,
            });
        },
        computed: {
            ...mapState({
                newsTypes: (state) => state.classTypes.queryAll,
                news: (state) => state.news.query,
                newsList: (state) => state.news.queryAll,
                newsDetailQueryAll: (state) => state.news.detailQueryAll,
                response: (state) => state.news.response,
                projectData: (state) => state.module.queryAll,
                imgUrlList: (state) => state.news.detailImgList,
                fileUrlList: (state) => state.file.queryApplyAll,
            }),
        },
        watch: {
            news() {
                this.edit = this.news;
                if (this.edit.publishTime != "") {
                    let time = new Date(this.edit.publishTime).toLocaleDateString()
                    this.local.publishTime = new moment(time).format("YYYY-MM-DD")
                    // $(".datepicker").val(
                    //   new Date(this.edit.publishTime).toLocaleDateString()
                    // );
                    $(".timepicker").val(
                        new Date(this.edit.publishTime).toLocaleTimeString("en-US", this.timeStringOption)
                    );
                }
                if (this.edit.startTime != "" && this.edit.startTime !== null) {
                    let time = new Date(this.edit.startTime).toLocaleDateString()
                    this.local.startTime = new moment(time).format("YYYY-MM-DD")
                }
                if (this.edit.endTime != "" && this.edit.endTime !== null) {
                    let time = new Date(this.edit.endTime).toLocaleDateString()
                    this.local.endTime = new moment(time).format("YYYY-MM-DD")
                }
                this.GetAnnouncementLang();

            },

            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;

              //  this.GetNewsClassTypes();
                this.imgList = [];
                if (this.$route.params.id > 0) {
                    this.isEdit = true;
                    this.GetAnnouncement();
                } else {
                    this.detailNewsLang = [];
                    for (var i = 0; i < this.langTypes.length; i++) {
                        const detailId = this.edit.newsId + i + 1;
                        this.detailNewsLang.push({
                            newsDetailId: detailId,
                            lang: this.langTypes[i].id,
                            title: this.edit.detailTopTitle,
                            info: this.edit.detailTopInfo,
                            introduction: this.edit.introduction,
                            keyword: this.edit.keyword,
                            description: this.edit.description,

                        });
                    }
                }
                
            },
            newsDetailQueryAll() {
                let that = this;
                this.detailNewsLang = [];
                if (this.newsDetailQueryAll.total > 0) {
                    for (var i = 0; i < this.langTypes.length; i++) {
                        let findLang = false;
                        const Id = this.edit.newsId + i + 1;
                        this.newsDetailQueryAll.datas.some((newsDetail) => {
                            if (that.langTypes[i].id == newsDetail.lang) {
                                if (that.langTypes[i].id == this.editLang) {
                                    that.edit.newsDetailId = newsDetail.newsDetailId;
                                    that.edit.detailTopTitle = newsDetail.title;
                                    that.edit.detailTopInfo = newsDetail.info;
                                    that.edit.introduction = newsDetail.introduction;
                                    that.edit.keyword = newsDetail.keyword;
                                    that.edit.description = newsDetail.description;
                                }
                                if (this.isEdit){
                                    this.$store.dispatch("classTypes/classTypeQueryAll", {
                                        module: this.moduleId,
                                        isDelete: 0,
                                        isStop: 0,
                                    });
                                }                         
                                that.detailNewsLang.push({
                                    newsDetailId: newsDetail.newsDetailId,
                                    lang: newsDetail.lang,
                                    title: newsDetail.title,
                                });
                                findLang = true;
                                return true;
                            }
                        });

                        if (!findLang) {
                            that.detailNewsLang.push({
                                newsDetailId: Id,
                                lang: that.langTypes[i].id,
                                title: "",
                                info: "",
                            });
                        }
                    }

                }
            },
            response(value) {
                if (value.status == "1") {
                    this.$eventBus.$emit("showAlert", "儲存成功!!");
                    if (!this.isPreview)
                        this.$router.push({
                            name: "WaninAnnouncementList",
                            params: {
                                searchData: this.$route.params.searchData,
                            },
                        });
                } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
            
            },
            imgUrlList() {
                this.imgList = [];
                this.imgList = this.imgUrlList;
            },
            fileUrlList() {
                this.fileList = [];
                this.fileList = this.fileList;
            },

        },
        methods: {
            //取得該公告資料
            GetAnnouncement() {
                this.$store.dispatch("news/newsQuery", {
                    newsId: this.$route.params.id,
                }).then((res) => {                 
                    this.moduleId=res.result.module;
                });
            },

            //取得該公告多語系資料
            GetAnnouncementLang() {
                this.$store.dispatch("news/newsDetailQueryAll", {
                    newsId: this.$route.params.id,
                });
            },
          
            GetAllLang() {
                this.$store.dispatch("module/moduleQueryAll", {});
            },

            //取得該語系資料
            GetDetailNewsLang(lang) {
                for (var i = 0; i < this.langTypes.length; i++) {
                    if (lang == this.langTypes[i].id) {
                        this.edit.newsDetailId = this.detailNewsLang[i].newsDetailId;
                        this.edit.detailTopTitle = this.detailNewsLang[i].title;
                        this.edit.detailTopInfo = this.detailNewsLang[i].info;
                        this.edit.introduction = this.detailNewsLang[i].introduction;
                        this.edit.keyword = this.detailNewsLang[i].keyword;
                        this.edit.description = this.detailNewsLang[i].description;

                    }
                }
            },

            //更換語系
            // ChangeLang(lang) {
            //   this.removeFile();
            //   this.removeFile2();
            //   this.SetLangData(this.editLang);
            //   this.GetDetailNewsLang(lang);
            //   this.editLang = lang;
            //   this.GetImgList();
            // },

            //儲存該語系資料
            SetLangData(lang) {
                for (var i = 0; i < this.langTypes.length; i++) {
                    if (this.langTypes[i].id == lang) {
                        this.detailNewsLang[i].title = this.edit.detailTopTitle;
                        this.detailNewsLang[i].info = '';
                        this.detailNewsLang[i].introduction = '';
                        this.detailNewsLang[i].keyword = '';
                        this.detailNewsLang[i].description = '';
                    }
                }
            },

            //檢查Session
            checkSession() {
                if (this.timer) clearInterval(this.timer);
                this.timer = setInterval(() => {
                    this.$store.dispatch("checkSession");
                }, 600000);
            },
            
            GetSplitNewsIdList() {
                var newsIdString = this.edit.相關公告編號; // ex:20210720135039339,20210720135117942
                var arr = newsIdString.split(",");
                this.splitNewsIdList.link1 = arr[0];
                this.splitNewsIdList.link2 = arr[1];
            },
            ChangeNewsId(event) {
                let newsId = event.target.value;
                if (newsId) {
                    let query = {
                        newsId: newsId,
                        isDelete: 0,
                        isStop: 0,
                        skipCommit: true, // 略過commit
                    };
                    this.$store.dispatch("news/newsQuery", query).then((res) => {
                        if (res.status != "1") {
                            event.target.value = "";
                            this.$eventBus.$emit("showAlert", "公告編號：" + newsId + "不存在");
                            return;
                        }
                    });
                } else {
                    event.target.value = "";
                }
            },

            changeType(event) {
                let newsId = event.target.value;
                // this.$store.dispatch("news/newsQueryAll", {
                //   module:newsId,
                //   isDelete: 0,
                //   isStop: 0,
                // });
                this.$store.dispatch("classTypes/classTypeQueryAll", {
                    // module: this.isSpecialPage ? 'special_offer' : "news",
                    module: newsId,
                    isDelete: 0,
                    isStop: 0,
                });
            },
            //*---- 返回
            backList() {
                this.$router.push({
                    name: "WaninAnnouncementList",
                    params: {
                        searchData: this.$route.params.searchData,
                    },
                });
            },

            formatTime: function (t) {
                return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
            },

            //送出
            async Save(isPreview) {
           
                this.SetLangData(this.editLang);
                this.edit.isStop = parseInt(this.edit.isStop);

                this.edit.publishTime = this.local.publishTime + " " + $(".timepicker").val();


                if (this.edit.detailTopTitle == "") {
                    this.$eventBus.$emit("showAlert", "主旨不能為空");
                    return;
                }

                if (this.edit.publishTime == "") {
                    this.$eventBus.$emit("showAlert", "發佈時間不可為空");
                    return;
                }
           
                this.edit.publishTime = new Date(this.edit.publishTime + " UTC");
                this.edit.startTime = new Date(this.local.startTime + " UTC");
                this.edit.endTime = new Date(this.local.endTime + " UTC");
                this.edit.isNowOn = parseInt(this.edit.isNowOn)

                if (this.edit.isStop == 1) {
                    this.edit.isTop = 0
                }

                if (this.isEdit) {
                    this.$store.dispatch("news/newsUpdate", {
                        master_model: this.edit,
                        detail_model: this.detailNewsLang,                  
                    });
                } else {
                    this.$store.dispatch("news/newsCreate", {
                        master_model: this.edit,
                        detail_model: this.detailNewsLang,
                    });
                    this.isEdit = true;
                }
            },
         
        },
        beforeRouteLeave(to, from, next) {
            if (this.timer) clearInterval(this.timer);
            next();
        },
    };

</script>
<style>
    .radio.radio-inline._time .radio-date {
        position: absolute;
        top: 0;
        left: 50px;
    }

    .radio.radio-inline._time .radio-date .form-control {
        width: 182px;
    }

</style>
