<template>
    <div id="BlackQuery">
        <section class="content-header">
            <h1>發票驗證維護</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">發票驗證維護</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-header">
                    <div class="form-group">驗證功能：
                        <template v-if="驗證發票API狀態 == 'True' || 驗證發票API狀態 == 'False'">
                            <span :class="驗證發票API狀態 != 'False' ? 'font-disable' : ''">停止驗證</span>
                            <div class="ckbx-style-8 ckbx toggleInput" style="padding-left: 5px;padding-right: 5px;"
                                @click="upd驗證發票API狀態()">
                                <input :false-value="'False'" :true-value="'True'" name="ckbx-style-8" type="checkbox"
                                    v-model="驗證發票API狀態" />
                                <label :for="'check'" class="mystyle">
                                </label>
                            </div>
                            <span :class="驗證發票API狀態 != 'True' ? 'font-disable' : ''">開啟驗證</span>
                        </template>
                        <template v-else>
                            狀態錯誤
                        </template>
                    </div>
                    <div class="form-group">
                        <div style="padding-left: 100px;">
                            <button type="button" class="btn btn-primary" @click="VerifyEInvoice()">驗證API</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="box-body">
                    <table class="table table-hover">
                        <thead>
                            <tr class="bg-gray">
                                <th>編號</th>
                                <th>停用驗證時間</th>
                                <th>備註</th>
                                <th>操作者</th>
                                <th>功能</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-show="list.length == 0">
                                <td colspan="5" style="text-align: center;">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="box-footer text-center no-border">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="searchData.pageSize"
                        v-on:pageMethod="onPageChange" />
                </div> -->
                <div class="overlay" ref="loading"><i class="fa fa-refresh fa-spin"></i></div>
            </div>
        </section>
    </div>
</template>
    
<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
export default {
    components: {
        Pagination,
    },
    data() {
        let pageSize = 20;
        return {
            驗證發票API狀態: "Wait",//Wait：等server回傳, True開 , False關
            searchData: {
                pageSize,
            },
            list: [
            ],
            total: 0
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this._refresh驗證發票API();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async onPageChange() {

        },
        async _refresh驗證發票API() {
            this.驗證發票API狀態 = "Wait";
            let st = await api.IPSSetting.GetInvoiceOpenYn({});
            if (st && st.data && (st.data.content == "True" || st.data.content == "False")) {
                this.驗證發票API狀態 = st.data.content;
            } else {
                throw ((st && st.data && st.data.message) ? st.data.message : "未知");
            }
        },
        async upd驗證發票API狀態() {
            $(this.$refs.loading).show();
            let OpenYn = this.驗證發票API狀態 == 'False' ? 'Y' : 'N';
            try {
                let st = await api.IPSSetting.updateInvoiceOpenYn('OpenYn=' + OpenYn);
                if (st && st.data && (st.data.content == "True" || st.data.content == "False")) {
                    this.驗證發票API狀態 = st.data.content;
                } else {
                    throw ((st && st.data && st.data.message) ? st.data.message : "未知");
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut(400);
        },
        async VerifyEInvoice() {
            try {
                $(this.$refs.loading).show();
                let res = await api.IPSSetting.VerifyEInvoice({
                    type: 1,
                    code: "919",
                });
                if (res.data) {
                    this.$eventBus.$emit("showAlert", res.data);
                } else {
                    throw res;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", `未知:${e}`);
            }
            $(this.$refs.loading).fadeOut(400);
        }
    },
};
</script>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style lang="css" scoped>
.font-disable {
    color: #bbb;
}
</style>