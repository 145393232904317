<template>
  <!-- 給獎訊息設定 > 查看發送內容 -->
  <div class="modal fade" ref="rewardMessageContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">發送內容</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td width="100px">發送時間</td>
                  <td>
                    {{
                      message.sendStartTime | dateFormat("YYYY/MM/DD HH:mm:ss")
                    }}
                  </td>
                </tr>
                <tr>
                  <td>有效時間</td>
                  <td>
                    {{
                      message.sendEndTime | dateFormat("YYYY/MM/DD HH:mm:ss")
                    }}
                  </td>
                </tr>
                <tr>
                  <td>訊息主旨</td>
                  <td>{{ message.messageTitle }}</td>
                </tr>
                <tr>
                  <td>訊息內容</td>
                  <td v-if="message.messageNote.startsWith('<')">
                    <div v-html="message.messageNote"></div>
                  </td>
                  <td v-else style="white-space: pre-line">
                    {{ message.messageNote }}
                  </td>
                </tr>
                <tr>
                  <td>發送名單</td>
                  <td>
                    <div style="max-height: 250px; overflow: auto">
                      <div
                        v-for="(player, index) in message.players"
                        :key="index"
                      >
                        <div v-if="player.sendType === 1">
                          {{ player.playUId }}
                        </div>
                        <div v-else-if="player.sendType === 2">
                          {{ player.playUId }}
                        </div>
                        <div v-else-if="player.sendType === 3">
                          <div v-if="player.ruleType === 1">
                            等級 = {{ player.ruleValue1 }} -
                            {{ player.ruleValue2 }}
                          </div>
                          <div v-else-if="player.ruleType === 2">
                            VIP = {{ define.vipStatus[player.ruleValue1] }} -
                            {{ define.vipStatus[player.ruleValue2] }}
                          </div>
                          <div v-else-if="player.ruleType === 3">
                            俱樂部名稱 = {{ player.ruleValue1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>發送物品</td>
                  <td>
                    <div style="max-height: 250px; overflow: auto">
                      <div v-for="(item, index) in message.items" :key="index">
                        <div v-if="item.prizeTypeName">
                          <span>{{ item.prizeTypeName }}</span>
                          <span v-if="item.prizeSubType">
                            - {{ item.prizeSubType }}
                          </span>
                          <span> - {{ item.prizeItemName }}</span>
                          <span> = {{ item.amount }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            type="button"
            v-if="isEdit"
            @click="editMessage"
          >
            編輯
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      isEdit: false,
      message: { messageNote: "" },
    };
  },
  methods: {
    async showRewardMessageContent(message, searchData) {
      this.isEdit = message.messageStatus === 1;
      this.message = message;
      this.searchData = searchData;
      this.getMessage();
      $(this.$refs.rewardMessageContent).modal("show");
    },
    getMessage() {
      this.$store.dispatch("fcReward/query", this.message).then((result) => {
        this.message = result;
      });
    },
    editMessage() {
      $(this.$refs.rewardMessageContent).modal("hide");
      this.$router.push({
        name: "RewardMessageEdit",
        params: {
          id: this.message.prizeMessageId,
          searchData: this.searchData,
        },
      });
    },
  },
};
</script>
