<template>
  <!-- 辯論圖示 -->
  <div class="modal fade newtalk normal" ref="voteContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">辯論圖示</h4>
        </div>
        <div class="modal-body">
          <ul>
            <li>
              <div class="blue">正方</div>
              <div class="gray">
                <div class="fl">
                  預設圖示：
                  <span
                    class="pointer ml-10"
                    v-for="(name, index) in positiveImgs"
                    :key="index"
                  >
                    <img
                      width="50px"
                      class="blue"
                      :class="{ 'is-active': name === positiveSideName }"
                      :name="name"
                      :src="require(`@/assets/img/${name}`)"
                      @click="changeImage(name, 'positive')"
                    />
                  </span>
                </div>
                <div class="fl ml-20">
                  上傳圖示：
                  <input
                    accept="image/*"
                    type="file"
                    id="positiveSide"
                    name="positiveSide"
                    required
                    @change="changeFile($event, 'positive')"
                  />
                </div>
              </div>
              <img class="mt-25" width="100px" :src="positiveSideUrl" />
            </li>
            <li class="mt-30">
              <div class="red">反方</div>
              <div class="gray">
                <div class="fl">
                  預設圖示：
                  <span
                    class="pointer ml-10"
                    v-for="(name, index) in negativeImgs"
                    :key="index"
                  >
                    <img
                      width="50px"
                      class="red"
                      :class="{ 'is-active': name === negativeSideName }"
                      :name="name"
                      :src="require(`@/assets/img/${name}`)"
                      @click="changeImage(name, 'negative')"
                    />
                  </span>
                </div>
                <div class="fl ml-20">
                  上傳圖示：
                  <input
                    accept="image/*"
                    type="file"
                    id="negativeSide"
                    name="negativeSide"
                    required
                    @change="changeFile($event, 'negative')"
                  />
                </div>
              </div>
              <img class="mt-25" width="100px" :src="negativeSideUrl" />
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button @click="submit()" class="btn btn-primary" type="button">
            確定
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import utils from "@/assets/js/app-utils";

export default {
  data() {
    return {
      projectId: this.$store.state.projectId,
      lang: "zh-tw",
      positiveImgs: ["correct.png", "like.png"],
      negativeImgs: ["uncorrect.png", "dislike.png"],
      positiveSideUrl: "",
      positiveSideName: "",
      negativeSideUrl: "",
      negativeSideName: "",
      images: {},
      data: {},
      upload: 0,
    };
  },
  methods: {
    showContent(data) {
      this.images = {};
      this.data = data || {};
      this.positiveSideUrl = this.data.positiveSideUrl;
      this.positiveSideName = this.data.positiveSideName;
      this.negativeSideUrl = this.data.negativeSideUrl;
      this.negativeSideName = this.data.negativeSideName;
      this.showAlert();
    },
    showAlert() {
      $(this.$refs.voteContent).modal("show");
    },
    hideAlert() {
      $(this.$refs.voteContent).modal("hide");
    },
    changeImage(name, key) {
      this[`${key}SideUrl`] = require(`@/assets/img/${name}`);
      this[`${key}SideName`] = name;
    },
    changeFile(event, key) {
      const files = event.target.files;
      if (files.length > 0) {
        const file = files[0];
        const name = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target.result.startsWith("data:image")) {
            this[`${key}SideUrl`] = e.target.result;
            this[`${key}SideName`] = "";
            this.$forceUpdate();
          }
        };
        reader.readAsDataURL(file);
        this.images[key] = { file, name };
      }
    },
    uploadFile(key, files, folderName, id, multi = false, override = false) {
      const data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);
      this.$store.dispatch("file/fileUpload", data).then((result) => {
        if (result.status == "1") {
          this[`${key}SideUrl`] = result.url;
          this[`${key}SideName`] = files.name;
          this.upload--;
          if (this.upload === 0) {
            this.submitData();
          }
        } else {
          this.$eventBus.$emit("showAlert", "檔案上傳失敗！");
        }
      });
    },
    submit() {
      const keys = Object.keys(this.images);
      if (keys.length) {
        this.upload = keys.length;
        keys.forEach((key, index) => {
          const image = this.images[key];
          this.uploadFile(key, image.file, image.name, utils.getTimeId());
        });
      } else {
        this.submitData();
      }
    },
    submitData() {
      this.data.positiveSideUrl = this.positiveSideUrl;
      this.data.positiveSideName = this.positiveSideName;
      this.data.negativeSideUrl = this.negativeSideUrl;
      this.data.negativeSideName = this.negativeSideName;
      this.hideAlert();
      this.$emit("updateContent");
    },
  },
};
</script>
