/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    Create({ commit }, data) {
        api.phoneVerify.create(data).then(res => {
            if (res.data.status == "1") {
                commit('setResponse', res.data);
            }else{
                this._vm.$eventBus.$emit("showAlert", res.data.errorMsg);
            }
        })
    },
    QueryAll({ commit }, data) {
        api.phoneVerify.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setPhoneVerifyQueryAll', res.data.content);
            }
        })
    },
    Export({ commit }, data) {
        api.phoneVerify.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.content.total <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                }
                let datas = res.data.content.datas;


                appUtils.exportData(
                    datas,
                    appUtils.formatTime(new Date(), "YYYYMMDD") + "_molo進線歷程" + ".xlsx",
                    "sheet1"
                );
            } else {
                //let errCode = res.data.errorCode;
                let errMsg = res.data.errorMsg;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
}

// mutations
const mutations = {
    setPhoneVerifyQueryAll(state, phoneVerifyQueryAll) {
        state.queryAll = phoneVerifyQueryAll;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}