<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>捐款徵信編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>捐款徵信列表</li>
        <li class="active">捐款徵信編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" 
                  @change="OnTypeChnage()"
                  v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option
                      :key="index"
                      :value="item.classTypeId"
                      v-for="(item, index) in classTypes.datas"
                    >{{item.className}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 年度：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="year">
                    <option
                      :key="index"
                      :value="item.id"
                      v-for="(item, index) in yearOptions"
                    >{{item.label}}</option>
                  </select>
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"
                      @click="ChangeLang(item.id)"
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div> -->

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div> -->
              <template v-if="classId=='donationlist'">
                <div class="form-group">
                  <label class="col-sm-2 control-label" for="inputFile">已有檔案：</label>
                  <div class="col-sm-10 text">
                    <table class="table table-bordered">
                      <tbody>
                        <tr :key="index" v-for="index in 12">
                          <td>{{ index }} 月份</td>
                          <template v-if="donationFileList[index]==null">
                            <td></td>
                            <td>
                              <input
                                accept="application/pdf"
                                type="file"
                                @change="onFileChange($event, index)"
                              />                            
                            </td>
                          </template>
                          <template v-else>
                            <td>
                              <a
                                :href="donationFileList[index].filesUrl"
                                target="_blank"
                              >{{donationFileList[index].name}}</a>
                            </td>
                            <td>
                              <button
                                @click="deleteFile(donationFileList[index])"
                                class="btn btn-danger"
                                type="button"
                              >刪除</button>
                            </td>
                          </template>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    檔案：
                  </label>
                  <div class="col-sm-10">
                    <div class="pic">
                      <img draggable="false" :src="edit.filesUrl" />
                    </div>
                    <input
                      accept="application/pdf"
                      type="file"
                      @change="onFileChange($event)"
                    />
                    <div class="help-block">
                      文件檔案上傳格式：
                      <span class="text-red">(上限5MB)</span>
                      <br />1.PDF。
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-2 control-label" for="inputFile">已有檔案：</label>
                  <div class="col-sm-10 text">
                    <table class="table table-bordered">
                      <tbody>
                        <tr :key="index" v-for="(file, index) in fileList">
                          <td>
                            <a :href="file.filesUrl" target="_blank">{{file.name}}</a>
                          </td>
                          <td>
                            <button
                              @click="deleteFile(file)"
                              class="btn btn-danger"
                              type="button"
                            >刪除</button>
                          </td>
                           <td>
                            <input class="form-control" type="text" v-model="file.newName"  placeholder="請填入新檔名"/>
                          </td>
                           <button
                              @click="updateFile(file)"
                              class="btn btn-warning"
                              type="button"
                            >更新檔名</button>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";
import moment from 'moment';

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      idDonationListMode: false,
      backRouteName: "DonationList",
      module: "donation",
      detailGameLang: [],
      classId : "",
      year: "",
      edit: {
        contentId: utils.getTimeId(), //Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        contentUrl: "", // 網址p
        contentDetailTopId: "", // 內容Id
        detailTopTitle: "", //標題
        detailTopInfo: "", //內容
        isTurnPlay: 0, //輪播
        publishTime:"",
        isStop: 0, // 狀態
        sortId: 0, // 排序,
        fileNewName:"",
      },
      fileList: [],
      donationFileList: []
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {},
  computed: {
    ...mapState({
      classTypes: state => state.classTypes.queryAll,
      editData: state => state.game.query,
      detailQueryAll: state => state.game.detailQueryAll,
      projectData: state => state.module.queryAll,
      response: state => state.game.response,
      fileUploadResponse: state => state.file.response,
      imgUrlList: state => state.file.queryAll
    }),
    yearOptions() {
      let currentTime = new Date()
      let currentYear = currentTime.getFullYear();
      let yearData = [];
      let oldDataYear = 2012; // 舊資料開始年度
      this.year = currentYear;
      for(var yearOption = currentYear; yearOption >= oldDataYear; yearOption--){
        yearData.push({
          id: yearOption,
          label: yearOption.toString()
        }); 
      }
      return yearData;
    }
  },
  watch: {
    editData() {
      this.edit = this.editData;
      if( this.edit.publishTime == null ){
        this.year = new moment().year();
      }else{
        this.year = new moment(this.edit.publishTime).year();
      }
      this.GetEditDataLangData();
      this.OnTypeChnage();
    },

    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetClassTypes();

      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetEditData();
      } else {
        this.detailGameLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.contentId + i + 1;
          this.detailGameLang.push({
            gameDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo
          });
        }
      }
    },

    detailQueryAll() {
      let that = this;
      this.detailGameLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          const Id = this.edit.contentId + i + 1;
          let findLang = false;
          this.detailQueryAll.datas.some(details => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.gameDetailId = details.gameDetailId;
                that.edit.detailTopTitle = details.title;
                that.edit.detailTopInfo = details.info;
              }
              that.detailGameLang.push({
                gameDetailId: details.gameDetailId,
                lang: details.lang,
                title: details.title,
                info: details.info
              });
              findLang = true;
              return true;
            }
          });
          if (!findLang) {
            that.detailGameLang.push({
              gameDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: ""
            });
          }
        }
        this.GetFileList();
      }
    },

    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: this.backRouteName,
          params: { searchData: this.$route.params.searchData }
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },

    imgUrlList() {
      this.fileList = [];
      this.donationFileList = [];
      this.fileList = this.imgUrlList;
      if (this.classId == "donationlist") {
        this.fileList.forEach(item => {
          this.donationFileList[item.od] = item;
        });
      }
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "上傳失敗!!");
      } else {
        this.$eventBus.$emit("showAlert", "上傳成功!!");
        this.GetFileList();
      }
    }
  },
  methods: {
    GetEditData() {
      this.$store.dispatch("game/gameQuery", {
        contentId: this.$route.params.id
      });
    },

    GetEditDataLangData() {
      this.$store.dispatch("game/gameDetailQueryAll", {
        contentId: this.$route.params.id
      });
    },

    GetClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    GetClassId(){
      var classId = "donationlist";
      try{
          this.classTypes.datas.forEach(item=>{
            if( item.classTypeId == this.edit.classTypeId) {
               classId = item.classId;
            }
          });
        }catch(e){
          return false;
        }
      return classId;
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetFileList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("file/queryAll", {
        module: this.module,
        moduleId: this.edit.contentId,
        projectId: this.edit.projectId
      });
    },

    //取得該語系資料
    GetEditDataDetailLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.gameDetailId = this.detailGameLang[i].gameDetailId;
          this.edit.detailTopTitle = this.detailGameLang[i].title;
          this.edit.detailTopInfo = this.detailGameLang[i].info;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      this.removeFile();
      this.SetLangData(this.editLang);
      this.GetEditDataDetailLang(lang);
      this.editLang = lang;
      this.GetFileList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailGameLang[i].title = this.edit.detailTopTitle;
          this.detailGameLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: this.backRouteName,
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt, od = 1) {
      this.SetLangData(this.editLang);
      this.GetEditDataDetailLang(this.editLang);

      var files = evt.target.files;
      if (files.length > 1) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過1份上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          //var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var re = /\.(pdf)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }
      }
      if( this.classId == "donationlist"){
        //判斷該月份已經有資料就更新
        if( this.donationFileList[od] ){
          this.uploadFile(files, this.module, this.edit.contentId, false, od);
        }else{
          this.uploadFile(files, this.module, this.edit.contentId, true, od);
        }
      }else{
        // 預算或決算報告
        this.uploadFile(files, this.module, this.edit.contentId, true, od);
      }
    },

    uploadFile(files, folderName, id, multi = false, od = 1) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("upoladOption.projectId", this.edit.projectId);
      data.append("upoladOption.module", folderName);
      data.append("upoladOption.moduleId", id);
      data.append("upoladOption.od", od);
      data.append("upoladOption.lang", "zh-tw");
      data.append("upoladOption.multi", multi);

      this.$store.dispatch("file/fileUploadByOrder", data);
    },  

    deleteFile(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("file/fileDelete", item).then(res => {
            if (res.status != "0") {
              this.GetFileList();
            }
          });
        }
      });
    },
    updateFile(item) {
      console.log(item);
      if(!item.newName){
        this.$eventBus.$emit("showAlert", "檔名不能為空");
        return;
      }

      item.name = item.newName;
      item.skipCommit = true;
      this.$eventBus.$emit("showConfirm", "確定要更新嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("file/updateFile", item).then(res => {
            if (res.status != "0") {
              item.newName = "";
              this.$eventBus.$emit("showAlert", "更新成功");
              this.GetFileList();
            }
          });
        }
      });
    },
    OnTypeChnage(){
      this.classId = this.GetClassId();
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      this.SetLangData(this.editLang);
      this.edit.publishTime = this.year.toString() + "-01-01T01:00:00";
      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      if (this.isEdit) {
        this.$store.dispatch("game/gameUpdate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      } else {
        this.$store.dispatch("game/gameCreate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      }
    }
  }
};
</script>