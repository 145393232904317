<template>
  <div id="PlayerHistoryList">
    <section class="content-header">
      <h1>玩家歷程查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">玩家歷程查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group" style="width: auto">
              <label class="control-label">操作時間：</label>
              <div class="input-group dateOnly">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="玩家UID"
                type="text"
                v-model.trim="searchData.playUId"
              />
            </div>
            <div class="btns">
              <button
                type="button"
                class="btn btn-primary"
                @click="getDataList()"
              >
                查詢
              </button>
              <button
                type="button"
                class="btn btn-default"
                @click="exportOrders()"
              >
                匯出
              </button>
            </div>
          </div>
          <div class="mt-10">
            <div class="text-center" v-if="!loading && result.message">
              {{ result.message }}
            </div>
            <div class="text-center" v-else-if="!loading && datas.length === 0">
              查詢無任何資料
            </div>
            <div class="table-responsive" v-else-if="texts.length">
              <table
                class="table table-bordered text-center min-1000"
                v-for="(text, index) in texts"
                :key="index"
              >
                <tbody>
                  <tr class="bg-gray">
                    <th colspan="8">
                      {{ text.title }}
                      ({{ text.id }})
                    </th>
                  </tr>
                  <tr class="bg-gray">
                    <td>所在位置</td>
                    <td>操作時間</td>
                    <td>當下等級</td>
                    <td>當下金幣</td>
                    <td>當下鑽石</td>
                    <td>金幣減少額</td>
                    <td>金幣增加額</td>
                    <td style="width: 35%">備註</td>
                  </tr>
                  <tr v-for="(item, index) in text.items" :key="index">
                    <td>{{ item.position }}</td>
                    <td>{{ item.time }}</td>
                    <td>{{ item.rank | currencyFormat }}</td>
                    <td>{{ item.gold | currencyFormat }}</td>
                    <td>{{ item.diamond | currencyFormat }}</td>
                    <td>{{ item.goldMinus | currencyFormat }}</td>
                    <td>{{ item.goldPlus | currencyFormat }}</td>
                    <td>{{ item.note }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="box-footer text-center no-border"></div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD 00:00:00"),
          endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
        playUId: "",
      },
      result: {},
      datas: [],
      texts: [],
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.searchData.playUId = this.$route.params.id;
    if (this.searchData.playUId) {
      this.getDataList();
    }
  },
  methods: {
    getDataList() {
      if (!this.searchData.playUId) {
        this.$eventBus.$emit("showAlert", "請填寫玩家UID！");
        return;
      }
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} UTC`
      );
      this.$store
        .dispatch("fcPlayerHistory/queryAll", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.parseDatas();
        });
    },
    parseDatas() {
      this.texts = [];
      this.datas.forEach((data) => {
        if (data.fileText) {
          const text = {};
          // const lines = data.fileText.split(/\r\n/g);
          text.id = data.playUId;
          text.title = new moment(data.date).format("YYYY/MM/DD")+"遊戲歷程" ;
          text.items = [];
          data.fileText.forEach((line, index) => {
            if (line) {
              text.items.push({
                        position: line.service,
                        time: line.time,
                        rank: line.level,
                        gold: line.gold,
                        diamond: line.diamond,
                        goldMinus: line.costcount,
                        goldPlus: line.getcount,
                        note: line.message
                      });
            }
          });
          if (text.id) {
            this.texts.push(text);
          }
        }
      });
    },
    exportOrders() {
      if (!this.searchData.playUId) {
        this.$eventBus.$emit("showAlert", "請填寫玩家UID！");
        return;
      }
      this.$store.dispatch("fcPlayerHistory/export", this.texts);
    },
  },
};
</script>
