<template>
    <table>
        <thead>
            <tr>
                <th class="text-center" style="width: 100px;">{{ $t('時間') }}</th>
                <th class="text-center" style="width: 20%;">{{ th[0] }}</th>
                <th class="text-center" style="width: 20%;">{{ th[1] }}</th>
                <th style="width: 50%;">{{ $t('內容') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr class="cursor" v-for="(item, sn) of list" :key="item.SendId" @click='$emit("onFocus", item)'
                :class="focusId == item.SendId ? 'focus' : ''">
                <td class="text-center">
                    <span class="timer">{{ item.時間 }}</span>
                    <i class="fa fa-fw fa-envelope tool" v-show="MessageSystemEnable" :title="$t('密語')"
                        @click='onSecretBtnClick($event, item)'></i>
                    <i class="fa fa-fw fa-cloud-download tool" :title="$t('下載歷程')"
                        @click='onDownloadBtnClick($event, item)'></i>
                </td>
                <td class="text-center">{{ item[td[0]] }}</td>
                <td class="text-center">{{ item[td[1]] }}</td>
                <td :title="item.Content">
                    <img v-show="item.MsgType == MsgTypeEnum.Photo"
                        :src="item.MsgType == MsgTypeEnum.Photo ? item.Content : ''" alt="" style="max-width: 50px;"
                        @mouseenter='mouseenter($event, item)' @mouseout='mouseout($event, item)'
                        @click.stop='$emit("popUpImg", item)'>
                    <button class="btn control-btn" v-show="item.MsgType == MsgTypeEnum.Voice"
                        @click='$emit("playAudio", item.Content)'>{{ $t('播放音訊')
                        }}</button>
                    <span v-show="item.MsgType == MsgTypeEnum.Sticker">
                        &lt;{{ $t('貼圖') }}&gt;{{ item.Content }}
                    </span>
                    <span v-show="item.MsgType == MsgTypeEnum.Text">
                        {{ item.Content }}
                    </span>
                </td>
            </tr>
            <tr v-if="list.length == 0">
                <td colspan="4" style="text-align: center;">{{ $t('無資料') }}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
// import moment from "moment";
import { MsgTypeEnum/*, GetMonitoringHistory*/ } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
// const XLSX = require('xlsx');
export default {
    props: ['list', 'focusId', 'th', 'td', "MessageSystemEnable"],
    components: {
    },
    data() {
        return {
            MsgTypeEnum
        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        onSecretBtnClick(e, item) {
            e.preventDefault();
            e.stopPropagation();
            this.$emit("onSecretBtnClick", item)
        },
        onDownloadBtnClick(e, item) {
            e.preventDefault();
            e.stopPropagation();
            this.$emit("onDownloadBtnClick", item)
        },
        mouseenter(e, item) {
            this.$emit("mouseenter", e, item)
        },
        mouseout(e, item) {
            this.$emit("mouseout", e, item)

        }
    },
    beforeDestroy() {
    },
}
</script>
<style lang="scss" scoped>
@import "~/src/assets/scss/channel.scss";
</style>