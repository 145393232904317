<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>相關消息</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active" >相關消息</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增消息
            </button>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ news.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
             
                <tr class="bg-gray" >
                  <th class="text-nowrap" style="width: 10%;"
                    v-if="projectId !== 'omp_xinstars'"
                    >ID</th>
                  <th class="text-nowrap" style="width: 40%;">消息標題</th>
                  <th class="text-nowrap" style="width: 15%;">建立日期</th>
                  <th class="text-nowrap" style="width: 15%;">發佈日期</th>    
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="news.total > 0">
                  <tr :key="item.id" v-for="(item, index) in news.datas">
                    <td align="center"  v-if="projectId !== 'omp_xinstars'">
                      <span
                        v-if="
                          projectId == 'omp_heti' && item.isStop == 0 
                        "
                        style="cursor: pointer;"
                        class="text-primary"
                        @click="previewItem(item)"
                      >
                        {{ item.newsId }}
                      </span>
                      <span v-else>{{ item.newsId }}</span>
                    </td>  
                    <td>{{ item.detailTopTitle }}</td>
                             <td align="center" >{{ FormatTime(item.createTime) }}</td>
                    <td align="center">{{ FormatTime(item.publishTime) }}</td>           
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                                      
                    <td class="text-center text-nowrap">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <!-- <button
                        @click="previewItem(item)"
                        class="btn btn-warning"
                        v-if="item.isStop == '1'"
                      >預覽</button> -->
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="news.total > 0">
          <Pagination
            ref="pagination"
            :total="news.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
  #AnnouncementList{
    .pic{
      img{
        max-height: 80px;
        margin: 0 auto;
      }
    }
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    DateRangePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      isSpecialPage: this.$route.name == 'HetiSpecialOffer' ? true : false,
      routeName: this.$route.name,
      moduleId:'',
      searchData: {
        // projectId: this.$store.state.projectId,
        classTypeId: "",
        module: "esgnews",
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, //每頁顯示筆數
      },
      url: config.dkOption.url,
      hasIsHint: '',
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    // 開箱文日期預設值
   /* if (this.projectId === 'omp_xinstars') {
      this.searchData.StartTime = new moment().subtract(360, "days").format("YYYY-MM-DD")
      this.searchData.EndTime = new moment().add(60, "days").format("YYYY-MM-DD")
    }

    if(this.projectId == 'omp_heti' && this.isSpecialPage) {
      this.searchData.module = 'special_offer'
      this.getHint();
    }*/
    await this.getNewsClassTypes();
    this.getNewsQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      newsTypes: (state) => state.classTypes.queryAll,
      news: (state) => state.news.queryAll,
      response: (state) => state.news.response,
      projectData: (state) => state.module.queryAll,
    }),
  },
  watch: {
    newsTypes() {

    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetNewsClassTypes();
   
    },
    $route(val) {
      this.searchData.module = 'esgnews'
      this.isSpecialPage =  false,
      this.getNewsClassTypes();
      this.getNewsQueryAll();
    },
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getNewsQueryAll();
    },
    news() {
      this.$refs.pagination.setPage(this.searchData.pageNum, this.news.total);
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getNewsQueryAll();
    },

    getNewsQueryAll() {
    
      //列表
      this.$store.dispatch("news/newsQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async getNewsClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "esgnews" ,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    // getHint() {
    //   let data = {
    //     projectId: "omp_heti",
    //     module: "special_offer",
    //     classId: "discount_info",
    //     lang: "zh-tw"
    //   }
    //   api.heti.GetHint(data).then(({data}) => {
    //     if(data.status == '1') {
    //       this.hasIsHint = data.result.newsId
    //     }
    //   })
    // },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getNewsQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: 'WaninAnnouncementEdit',
        params: {
          searchData: this.searchData,
        },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: 'WaninAnnouncementEdit',
        params: {
          id: item.newsId,
          searchData: this.searchData,
        },
      });
      return;
    },

    updateItem(item) {
      item.newsDetailId = item.newsDetailTopId;
      var detailNewsLang = []
      detailNewsLang.push({
        info: item.detailTopInfo,
        lang: "zh-tw",
        newsDetailId: item.newsDetailTopId,
        title: item.detailTopTitle
      })
      this.$store.dispatch("news/newsUpdate", {
          master_model: item,
          detail_model: detailNewsLang
          ,
        });
    },

    cancelTop(item) {
      item.isTop = 0;
      this.updateItem(item);
    },


    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("news/newsDelete", item);
        }
      });
    },
    checkPermission(permission) {
      // let userPermissionList = this.$store.state.loginUserPermission;
      // let num = -1;
      // let isCheck = false;
      // permission.split(",").some(element => {
      //   num = userPermissionList.indexOf(element);
      //   switch (num) {
      //     case -1:
      //       isCheck = false;
      //       break;
      //     default:
      //       isCheck = true;
      //       break;
      //   }
      //   if (isCheck) return true;
      // });
      return utils.checkByUserPermission(permission);
    },
    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
<style scoped>
.ckbx-style-8 label:before {
  background: #999;
}
</style>