<template>
    <div>
        <section class="content-header">
            <h1>{{ $t('分類管理') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('分類管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <button class="btn btn-block btn-warning btn-add" type="button" :disabled="selService == ''"
                            @click="開啟新增列()">
                            <i class="fa fa-plus"></i> {{ $t('新增') }}
                        </button>
                        <div class="select-group">
                            <select class="form-control" v-model="selService" @change="queryAll" :disabled="是否有資料修改中">
                                <option v-for="item of projects" :key="item" :value="item">
                                    {{ $t(item) }}
                                </option>
                            </select>
                        </div>
                        <!-- <div class="select-group">
                            <select class="form-control" v-model="selModule" @change="queryAll" :disabled="是否有資料修改中">
                                <option v-for="item of Module" :key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-bordered classTypeTable">
                            <tbody>
                                <tr class="bg-gray">
                                    <th style="width:35%">{{ $t('分類ID') }}</th>
                                    <th style="width:35%">{{ $t('分類名稱') }}</th>
                                    <th style="width:10%" class="text-nowrap text-center">{{ $t('排序') }}</th>
                                    <th style="width:10%" class="text-nowrap text-center">{{ $t('啟用狀態') }}</th>
                                    <th style="width:10%" class="text-nowrap text-center">{{ $t('功能') }}</th>
                                </tr>
                                <tr v-for="item of list" :key="item.categoryID">
                                    <template v-if="item.editor == 0">
                                        <td> {{ item.keyName }} </td>
                                        <td> {{ item.name }} </td>
                                        <td align="center"> {{ item.sort }} </td>
                                        <td class="text-center" align="center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="啟停用(item)">
                                                <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                                    type="checkbox" v-model.trim="item.enable" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button @click="修改(item)" class="btn btn-primary">{{ $t('修改') }}</button>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            <input class="form-control" type="text" v-model.trim="editor.keyName" />
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" v-model.trim="editor.name" />
                                        </td>
                                        <td align="center">
                                            <input class="form-control text-center" type="number"
                                                v-model.trim="editor.sort" />
                                        </td>
                                        <td class="text-center" align="center">
                                            <div class="ckbx-style-8 ckbx toggleInput"
                                                @click="editor.enable = !editor.enable">
                                                <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                                    type="checkbox" v-model.trim="editor.enable" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button @click="儲存(item)" class="btn btn-success btn-save">{{ $t('儲存')
                                            }}</button>
                                            <button @click="取消(item)" class="btn btn-default">{{ $t('取消') }}</button>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { CsOmpAPI as CsAPI, 檢查Sort輸入值 } from "@/store/MessageSystem/messageSystemLib";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import { ProjectSetting } from "@/store/MessageSystem/messageSystemConst";
export default {
    data() {
        return {
            selService: "",
            list: [
            ],
            editor: {
                //editor:0 察看；1:修改 2:新增
                categoryID: -1, editor: 0, keyName: "", name: "", sort: 0, enable: true
            },
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapState({
            projects(state) {
                return ProjectSetting.AllowCSSystemProjects().filter((item) => {
                    return this.$user.hasPermission(`MCM-${item}`);
                });
            },
        }),
        是否有資料修改中() {
            let index = this.list.findIndex((i) => {
                return i.editor != 0;
            });
            return index >= 0;
        }
    },
    watch: {
        projects: {
            handler(v) {
                if (v && v.length >= 1) {
                    this.selService = v[0];
                }
            },
            immediate: true
        },
        selService: {
            async handler(v) {
                if (v) {
                    $(this.$refs.loading).show();
                    await this.reset();
                    $(this.$refs.loading).hide();
                }
            },
            immediate: true
        },
    },
    methods: {
        async init() {
            $(this.$refs.loading).hide();
        },
        async reset() {
            let res = (CsAPI(await api.CS.GetCategoryList({ Project: this.selService }))).result;
            for (let i of res) {
                i.editor = 0;
            }
            this.list = res;
        },
        // 顯示列表
        queryAll() {
            // todo:之後有第二個Service
            // this.$store.dispatch("classTypes/classTypeQueryAll", {
            //   module: this.moduleId,
            //   isDelete: 0
            // });
        },
        async 啟停用(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", this.$t('請先儲存上一次欲修改添加的項目'));
                return;
            }
            $(this.$refs.loading).show();
            try {
                CsAPI(await api.CS.UpdateCategory({
                    Id: item.categoryID,
                    KeyName: item.keyName,
                    Name: item.name,
                    Sort: item.sort,
                    Enable: !item.enable
                }));
                this.$eventBus.$emit("showAlert", this.$t('修改成功'));
                await this.reset();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        修改(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", this.$t('請先儲存上一次欲修改添加的項目'));
                return;
            }
            this.editor = { ...item };
            item.editor = 1;
        },
        async 儲存(item) {
            if (this.editor.keyName == "") {
                this.$eventBus.$emit("showAlert", this.$t('分類ID不可為空'));
                return;
            }
            if (this.editor.name == "") {
                this.$eventBus.$emit("showAlert", this.$t('分類名稱不可為空'));
                return;
            }
            if (!檢查Sort輸入值(this.editor.sort)) {
                this.$eventBus.$emit("showAlert", this.$t('請檢查排序是否輸入正整數'));
                return;
            }
            $(this.$refs.loading).show();
            try {
                if (item.editor == 2) {
                    //新增
                    CsAPI(await api.CS.AddCategory({
                        KeyName: this.editor.keyName,
                        Name: this.editor.name,
                        Project: this.selService,
                        Sort: this.editor.sort,
                        Enable: this.editor.enable
                    }));
                } else {
                    //修改
                    CsAPI(await api.CS.UpdateCategory({
                        Id: item.categoryID,
                        KeyName: this.editor.keyName,
                        Name: this.editor.name,
                        Sort: this.editor.sort,
                        Enable: this.editor.enable
                    }));
                }
                this.$eventBus.$emit("showAlert", this.$t('執行成功'));
                await this.reset();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        取消(item) {
            if (item.editor == 2) {
                this.從列表中移除指定item(item);
            } else {
                item.editor = 0;
            }
        },
        從列表中移除指定item(item) {
            let index = this.list.findIndex((i) => {
                return item.categoryID === i.categoryID;
            })
            if (index >= 0) {
                this.list.splice(index, 1);
            }
        },
        開啟新增列() {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", this.$t('請先儲存上一次欲修改添加的項目'));
                return;
            }
            let maxSortNum = this.取得列表中最大的Sort編號();
            let item = {
                categoryID: -1,
                editor: 2,
                keyName: "",
                name: "",
                sort: maxSortNum,
                enable: true,
            };
            this.list.unshift(item);
            this.editor = { ...item };
        },
        取得列表中最大的Sort編號() {
            let maxSortNum = 0;
            for (let i of this.list) {
                maxSortNum = maxSortNum < i.sort ? i.sort : maxSortNum;
            }
            return maxSortNum + 1;
        },
    }
}
</script>