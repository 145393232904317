<template>
  <div id="BeforeShareList" class="searchList">
    <section class="content-header">
      <h1>事前分享序號</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">事前分享序號</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model.number="searchData.PrizeType"
                @change="resetDataList()"
              >
                <option value="">分享次數</option>
                <option value="0">首次登入</option>
                <option value="1">分享1次</option>
                <option value="3">分享3次</option>
                <option value="5">分享5次</option>
              </select>
            </div>
            <div class="input-group search-group">
              <input
                type="text"
                class="form-control pull-right"
                placeholder="搜尋門號、序號"
                v-model="searchData.PhoneNumber"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>#</th>
                  <th>分享次數</th>
                  <th class="text-nowrap" style="min-width: 150px">門號</th>
                  <th class="text-nowrap" style="min-width: 150px">序號</th>
                  <th class="text-nowrap" style="min-width: 150px">
                    序號派發時間
                  </th>
                  <th class="text-nowrap" style="min-width: 150px; width: 15%;">功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="5">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="5">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ item.prizeType == 0 ? `首次登入` : `分享${item.prizeType}次` }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.phoneNumber }}
                  </td>
                  <td class="text-nowrap">
                    <input v-if="item.editor && item.editor!=0" type="text" v-model="item.prizeNo">
                    <span v-else>{{ item.prizeNo }}</span>
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-center">
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="!item.editor ||item.editor==0"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor==1"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor==1"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <select
            class="form-control page-size"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import { APILib } from "../ips/IPSTool";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        PrizeType: '',
        PhoneNumber: '',
        keyword: "",
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment().subtract(3, "month").format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
      },
      datas: [],
      tempEdit: ""
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime.split(' ')[0]} 00:00:00 UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime.split(' ')[0]} 23:59:59 UTC`
      );
      this.searchData.PrizeType = this.searchData.PrizeType.toString()
      this.$store
        .dispatch("KF3Before/shareQueryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    change(item) {
      if(this.tempEdit != ""){
        this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
         return;
      }
      this.tempEdit = item.prizeNo;

      item.editor = 1;
      this.$forceUpdate();
    },
    save(item) {
      console.log(item);
      api.kf3.before.update(item)
        .then((res) => {
          if(res.data.status == "1") {
            this.tempEdit = "";
            item.editor = 0;
            this.$eventBus.$emit("showAlert", "修改成功");
            this.$forceUpdate();
            return
          }
          this.$eventBus.$emit("showAlert", res.data.message);
        })
    },
    cancel(item) {
      item.prizeNo = this.tempEdit;
      this.tempEdit = "";
      item.editor = 0;
      this.$forceUpdate();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
