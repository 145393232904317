<template>
  <div class="modal fade" ref="channelAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span>頻道資訊</span>
          </h4>
        </div>
 
        <div class="modal-body">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="text-right" style="width: 120px;">頻道名稱：</td>
                <td><a class="linkStyle" :href="channelItem.頻道連結" target="_blank"><span>{{channelItem.頻道名稱}}</span> <i class="fa fa-link"></i></a></td>                
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">玩家資訊：<br />(ID/電話) </td>
                <td class="infos">
                  <div class="infobox" v-for="(item , index) in channelItem.玩家資訊"  :key="index">
                      <div class="infofiled">{{item.玩家角色名稱}}</div>
                      <div class="infofiled">{{item.玩家角色電話}}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-right" style="width: 120px;">下架數：</td>
                <td><span>{{channelItem.影片下架數}}</span></td>
              </tr>
                <tr>
                <td class="text-right" style="width: 120px;">留言板：</td>
                <td>
                        <span v-if="channelItem.留言板狀態 == 0" >關閉</span>
                        <span v-if="channelItem.留言板狀態 == 1" >開放</span>
                        <span v-if="channelItem.留言板狀態 == 2" >部分關閉</span>
                  </td>
              </tr>
                       
              <tr>
                <td class="text-right" style="width: 120px;">備註：</td>
                <td><span>{{channelItem.備註}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button @click="gochannel()" class="btn btn-warning " type="button">
            前往編輯
          </button>
          <button
            class="btn btn-primary pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
     .linkStyle {
        cursor: pointer;
        color: black;
     
        span{
          border-bottom: 1px solid;
        }
    }
    .infos{
      border: 0;
      padding: 10px 0;
      .infobox{
            display: flex;
            border: 1px solid #c8c8c8;
            padding: 0 0;
            margin-bottom: 5px;
            &:last-child{
                margin-bottom: 0;
            }
            .infofiled{
              width: 50%;
              border-right: 1px solid #c8c8c8;
              padding: 4px 8px;
              &:last-child{
                border: 0;
              }
            }
      }
    }

</style>


<script>
/* eslint-disable */

export default {
  data() {
    return {
      //  classTypeId: this.$parent.searchData.classTypeId,
        title: "",
        channelItem:[]
    };
  },
  methods: {
    openBox(item) {
      this.channelItem={ ...item };
      $(this.$refs.channelAlert).modal("show");
    },
    insertText(text) {
        this.channelItem.追蹤狀態 = text;
    },
    hideAlert() {
      $(this.$refs.channelAlert).modal("hide");
      this.resetData();
    },
    resetData() {
      this.channelItem.title = "";
      this.channelItem.url = "";
    },
    gochannel() {
          this.hideAlert();
          const routeUrl = this.$router.resolve({
              name: 'smmAnnouncementEdit',
              params: {
                  id: this.channelItem.頻道管理編號,
                  searchData: this.searchData,
              },
          });

          window.open(routeUrl.href, '_blank');

          return;

      // this.$store.dispatch("smm/videoUpdate", {
      //     ProjectId: 'omp_smm',
      //     videoId: this.channelItem.videoId,
      //     狀態: parseInt(this.channelItem.狀態,10), //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
      //     遊戲角色名稱: this.channelItem.遊戲角色名稱,
      //     追蹤狀態: this.channelItem.追蹤狀態,
      //     備註: this.channelItem.備註
      // }).then((res) => {
      //     this.hideAlert();
      //     if(res.status == 1){
      //       this.$eventBus.$emit("showAlert", "儲存成功!!");
      //       this.$emit('gochannel')
      //     } 
      // });


      // this.$store
      //     .dispatch(`link/linkCreate`, this.data)
      //     .then(() => {
      //       this.resetData();
      //       this.$emit('updateList');
      //       this.hideAlert();
      //     });
    },
  },
};
</script>

<style scoped>
table tbody tr td {
  border: none;
}
</style>