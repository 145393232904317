<template>
  <div id="activityEdit" class="slot max1040">
    <section class="content-header">
      <h1>{{ type == 'normal' ? '常態活動' : '非常態活動'}}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲管理</li>
        <li>活動機制</li>
        <li class="active">{{ type == 'normal' ? '常態活動' : '非常態活動'}}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ $route.params.name }}</h4>
          </div>

          <div class="form-horizontal" name="announcementForm">
            <div class="box-body">
							<div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span>
                  {{ type == 'normal' ? '時間：' : '活動名稱：'}}
                </label>
                <div class="col-sm-10">
                  <template v-if="type == 'normal'">
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      maxlength="50"
                      v-model="data.活動時間"
                    />
                    <span>{{ data.活動時間.length }} / 50</span>
                  </template>
                  <template v-else>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      maxlength="50"
                      v-model="data.活動名稱"
                    />
                    <span>{{ data.活動名稱.length }} / 50</span>
                  </template>
                </div>
              </div>

              <div class="form-group" v-if="type == 'abnormal'">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 活動簡述：
                </label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    required="required"
                    type="text"
                    rows="4"
                    maxlength="200"
                    v-model="data.活動說明"
                  />
									<span>{{ data.活動說明.length }} / 200</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span>
                  {{ type == 'normal' ? '週期活動：' : '詳細說明：' }}
                </label>
                <div class="col-sm-10">
									<div class="editor">
                    <tinymce v-model="data.內容" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
								<button
									class="btn btn-warning pull-left mr-10"
                  @click="preview()"
								>
									預覽
								</button>
								<button
									class="btn btn-primary pull-left"
                  @click="save()"
								>
									儲存
								</button>
								<button
									class="btn btn-default pull-right"
									@click="backList()"
								>
									返回
								</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--預覽-->
			<div class="modal fade" ref="preview">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
							<h4 class="modal-title text-center">預覽</h4>
						</div>
						<div class="modal-body">
              <table class="table table-striped table-bordered" id="classTypes">
                <tbody>
                  <tr style="background-color: #FEE94E;">
                    <th class="text-nowrap text-center" style="min-width: 100px; width: 30%;">
                      {{ type == 'normal' ? '時間' : '活動名稱' }}
                    </th>
                    <th class="text-nowrap text-center" style="min-width: 150px; width: 70%;">
                      {{ type == 'normal' ? '週期活動' : '活動名稱' }}
                    </th>
                  </tr>
                  <tr>
                    <td>{{ data.活動名稱 || data.活動時間 }}</td>
                    <td v-if="type == 'normal'" v-html="data.內容"></td>
                    <td v-else>{{ data.活動說明 }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped table-bordered" id="classTypes" v-if="type == 'abnormal'">
                <tbody>
                  <tr style="background-color: #FEE94E;">
                    <th class="text-nowrap text-center">詳細說明</th>
                  </tr>
                  <tr>
                    <td v-html="data.內容"></td>
                  </tr>
                </tbody>
              </table>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import api from "@/assets/js/app-api";

export default {
  components: {
		tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      id: Number(this.$route.params.id) || '',
      isEdit: false,
      type: this.$route.params.type,
      data: {
        遊戲編號: Number(this.$route.params.gameId),
        活動時間: '',
        活動名稱: '',
        活動說明: '',
        內容: '',
        是否啟用: 1
      }
		};
  },
  created() {
    if(this.id != '') {
      this.isEdit = true;
      this.getData();
    }
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    getData() {
      api.slotEvent[this.type].query({ 編號: this.id })
        .then((res) => {
          this.data = res.data.result;
        })
    },
    save() {
      let state = this.isEdit ? 'update' : 'create'
      api.slotEvent[this.type][state](this.data)
        .then((res) => {
          console.log(res.data);
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", "更新成功!");
            this.backList();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        })
    },
    preview() {
      $(this.$refs.preview).modal("show");
    },
		backList() {
			this.$router.push({
				name: 'slotActivityList',
				params: {
					type: this.$route.params.type,
          id: this.$route.params.gameId,
						name: this.$route.params.name
				},
			});
		}
	},
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>