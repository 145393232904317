/* eslint-disable */
import api from '@/assets/js/app-api';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    getDefaultProvider({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smsSystem.defaultProvider.getData(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else reject("查無資料")
            });
        });
    },
    setDefaultProvider({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smsSystem.defaultProvider.SetData(data).then(res => {
                resolve(res.data);
            });
        });
    },
    
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
