<template>
    <div>
        <section class="content-header">
            <h1> 廠商設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li class="active">廠商設定</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">
                        </h4>
                        <button class="btn btn-block btn-warning btn-add" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">名稱</th>
                                    <th class="text-nowrap">顯示名稱</th>
                                    <th class="text-nowrap">廠商編號</th>
                                    <th class="text-nowrap">廠商代碼</th>
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.編號">
                                        <td class="text-center">{{ item.名稱 }}</td>
                                        <td class="text-center">{{ item.顯示名稱 }}</td>
                                        <td class="text-center">{{ item.廠商編號 }}</td>
                                        <td class="text-center">{{ item.廠商代碼 }}</td>
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                            <button class="btn btn-danger" @click="gotoFirmPaySetting(item)">支付設定</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="6">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool2'

export default {
    data() {
        return {
            list: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                this.list = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        addItem() {
            this.$router.push({
                name: "FirmSettingEdit",
                params: { firmId: -1 },
            });
        },
        editItem(item) {
            this.$router.push({
                name: "FirmSettingEdit",
                params: { firmId: item.編號 },
            });
        },
        gotoFirmPaySetting(item) {
            this.$router.push({
                name: "FirmPaySettingList",
                params: { firmId: item.編號 },
            });
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                delete obj.建立時間;
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                APILib.IPS(await api.IPSSetting.廠商設定主檔_修改(obj));
                this.list = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        }
    },
};
</script>
  