/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
    queryPatch: [],
}

// getters
const getters = {
}

// actions
const actions = {
    exchangeCodeQueryAll({ commit }, data) {
        api.exchangeCode.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setExchangeCodeQueryAll', res.data.result);
            }
        })
    },
    exchangeCodeCreate({ commit }, data) {
        api.exchangeCode.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeCodeUpdate({ commit }, data) {
        api.exchangeCode.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeCodeDelete({ commit }, data) {
        api.exchangeCode.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeCodeExport({ commit }, data) {
        api.exchangeCode.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                }
                let datas = res.data.result.datas;
                let exportDatas = [];
                for (var i = 0; i < datas.length; i++) {
                    let usedTime = datas[i].usedTime;
                    let createTime = datas[i].createTime;

                    if (usedTime == null || usedTime.substring(0, 4) == "0001") usedTime = "";
                    else usedTime = appUtils.formatTime(usedTime, "YYYY/MM/DD HH:mm:ss");

                    if (createTime == null || createTime.substring(0, 4) == "0001") createTime = "";
                    else createTime = appUtils.formatTime(createTime, "YYYY/MM/DD HH:mm:ss");

                    exportDatas.push({
                        獎項: datas[i].awardsTitle,
                        使用者: datas[i].uId,
                        ID: datas[i].id,
                        序號: datas[i].sn,
                        對應序號: datas[i].vendorSN,
                        有無被兌換過: datas[i].isUsed == '1' ? "已兌換" : datas[i].isUsed == '2' ? "待確認" : "未兌換",
                        兌換時間: usedTime,
                        建立日期: createTime,
                    })
                }


                appUtils.exportData(
                    exportDatas,
                    appUtils.formatTime(new Date(), "YYYYMMDD") + "_序號列表" + ".xlsx",
                    "序號列表"
                );
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    exchangeCodeImport({ commit }, data) {
        api.exchangeCode.import(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeCodeDeletePatch({ commit }, data) {
        api.exchangeCode.deletePatch(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeCodeQueryPatch({ commit }, data) {
        api.exchangeCode.queryPatch(data).then(res => {
            if (res.data.status == "1") {
                commit('setExchangeCodeQueryPatch', res.data.result);
            }
        })
    },
}

// mutations
const mutations = {
    setExchangeCodeQueryAll(state, exchangeCodeQueryAll) {
        state.queryAll = exchangeCodeQueryAll;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setExchangeCodeQueryPatch(state, exchangeCodeQueryPatch) {
        state.queryPatch = exchangeCodeQueryPatch;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}