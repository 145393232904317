// import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    loading: true,
}

// getters
const getters = {
}

// actions
const actions = {
    loading({ commit }, data) {
        commit('setLoading', data);
    },
}

// mutations
const mutations = {
    setLoading(state, data) {
        state.loading = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}