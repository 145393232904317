<template>
  <div id="imagesList">
    <section class="content-header">
      <h1>圖片列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>圖片管理</li>
        <li class="active">圖片列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增圖片
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isStop"
                @change="clickSearch"
              >
                <!-- 
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in bannerTypes.datas"
                  :key="index"
                >
                  {{ item.className }}
                </option> -->
                <option value="-1">全部</option>
                <option value="0">顯示</option>
                <option value="1">隱藏</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.displayTitle"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="(searchShow = 1)">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ fileDisplayData.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 15%">圖片名稱</th>
                  <th style="min-width: 90px; width: 20%">主圖</th>
                  <th class="text-nowrap" style="width: 10%">建立日期</th>
                  <th class="text-nowrap" style="width: 5%">狀態</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 5%"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="fileDisplayData.total > 0">
                  <tr :key="item.id" v-for="(item, index) in fileDisplayData.files_display_list">
                    <td align="center">{{ item.displayTitle }}</td>
                    <td>
                      <div class="pic jqimgFill">
                        <!-- 430 * 330 -->
                        <img :src="item.mainFilePath" draggable="false" />
                      </div>
                    </td>
                    
                    <td align="center">{{ convertUnixTimeToDate(item.createTime) }}</td>
                    <td align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                        <input
                          :false-value="1"
                          :id="'check'+index"
                          :true-value="0"
                          @change="changeStatus(item)"
                          name="ckbx-style-8"
                          type="checkbox"
                          v-model="item.isStop"
                        />
                        <label :for="'check'+index"></label>
                      </div>                      
                    </td>

                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">
                        修改
                      </button>
                      &nbsp;
                      <button @click="deleteItem(item)" class="btn btn-danger">
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="fileDisplayData.total > 0"
        >
          <Pagination
            ref="pagination"
            :total="fileDisplayData.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      // imagePath: process.env.VUE_APP_NEWTALK_IMAGE_PATH,
      searchData: {
        isStop: -1,
        page: 1,
        pageSize: 10, //每頁顯示筆數
      },
      hasIsOn: '',
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetBannerClassTypes();
    this.GetFileDisplayList();
  },
  mounted() {},
  computed: {
    ...mapState({
      bannerTypes: (state) => state.classTypes.queryAll,
      fileDisplayData: (state) => state.newtalk.fileDisplayList,
    }),
  },
  watch: {
    bannerTypes() {
      if(this.projectId == 'omp_heti' && this.searchData.classTypeId == '') {
        this.bannerTypes.datas.forEach(item => {
          if(item.classId == 'img_home') this.searchData.classTypeId = item.classTypeId
        })
        this.GetFileDisplayList()
      }
    },
    fileDisplayData() {
      this.$refs.pagination.setPage(
        this.searchData.page,
        this.fileDisplayData.total
      );
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetFileDisplayList();
    },

    GetFileDisplayList() {
      const formData = new FormData();
      if (this.searchData.isStop != -1) formData.append("isStop", this.searchData.isStop);
      formData.append("page", this.searchData.page);
      formData.append("pageSize", this.searchData.pageSize);
      this.$store.dispatch("newtalk/getFileDisplayList", formData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.page, this.total);
    },

    async GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "images",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    clickSearch() {
      this.searchData.page = 1;
      this.searchData.isStop = parseInt(this.searchData.isStop);
      this.GetFileDisplayList();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "ImagesEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "ImagesEdit",
        params: { id: item.displayId, searchData: this.searchData },
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          const formData = new FormData();
          formData.append("displayId", item.displayId);
          this.$store.dispatch("newtalk/deleteFileDisplayData", formData).then((res) => {
            if (res.isSuccess) {
              this.GetFileDisplayList();
            }
          });
        }
      });
    },

    // 改變狀態
    changeStatus(item) {
      const formData = new FormData();
      formData.append("isStop", item.isStop);
      formData.append("displayId", item.displayId);
      this.$store.dispatch("newtalk/updateFileDisplayData", formData);
    },
    
    convertUnixTimeToDate(unixTime){
      var date = new Date(unixTime * 1000);
      return date.toLocaleString();
    }
  },
};
</script>
<style scoped>
.ckbx-style-8 label:before {
  background: #999;
}
</style>
