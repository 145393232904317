<template>
  <div class="modal fade" id="addAlert" ref="CollectAddAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">收藏
            <span class="fr mr-10 mr-0-sm  mb-10-sm fs14 ">
              找不到? 
              <b @click="AddCollect()" class="link text-line" data-dismiss="modal">新增群組</b>
            </span>
          </h4>
        </div>

        <div class="modal-body">
          <multiselect
            v-model="collections"
            :options="collectionType"
            :multiple="true"
            placeholder="請選擇要加入收藏群組"
            :showLabels="false"
            :hide-selected="true"
            :searchable="true"
          >
          </multiselect>
        </div>
        <div class="modal-footer tc">
          <button class="btn btn-success" data-dismiss="modal" type="button">
            儲存
          </button>
          <button class="btn btn-default" data-dismiss="modal" type="button">
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      peopleData: [],
      collections: '',
      collectionType: ['奧運好手','新銳運動員', 'Hedy的收藏']
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    showContent(item) {
      this.peopleData = item;
      $(this.$refs.CollectAddAlert).modal("show");
    },
    showSuccess(content) {
      $(this.$refs.CollectAddAlert).modal("hide");
      this.$emit("kolList");
    },
    AddCollect() {
      this.$router.push({
        name: "CollectTypeManager",
        //params: { searchData: this.searchData },
      });
      return;
    },
  },
};
</script>
