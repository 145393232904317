<template>
    <div>
        <PopForm ref="editForm" class="popform">
            <template v-slot:slot-title>
                {{ title }} 設定
            </template>
            <template v-slot:slot-body>
                <div style="display: flex; flex-wrap: wrap;justify-content: space-between;">
                    <div class=" input-group">
                        <input type="text" class="form-control" placeholder="搜尋" v-model.trim="search" :disabled="是否有資料修改中">
                        <span class="input-group-addon" :class="{ disableBackground: 是否有資料修改中 }"><i
                                class="fa fa-search"></i></span>
                        <span class="input-group-span" style="padding-left: 10px;"
                            v-if="是否有資料修改中">欲啟用搜尋功能需先儲存正在編輯的項目!</span>
                    </div>
                    <div class="input-group">
                        <button class=" btn btn-warning" @click="開啟新增列()"><i class="fa fa-plus"></i>
                            新增 </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered" style="margin-top: 15px;">
                    <tbody>
                        <tr>
                            <th class="text-left">SN</th>
                            <th class="text-left" style="width:470px;">名稱</th>
                            <th class="text-left" style="width:126px">功能</th>
                        </tr>
                        <tr v-for="(item) in filterList" :key="item.編號">
                            <template v-if="item.editor == 0">
                                <td> {{ item.編號 }} </td>
                                <td> {{ item.資料 }} </td>
                                <td class="text-right text-nowrap">
                                    <!-- <button v-if="是否有一鍵複製功能" @click="$emit('copyData', item)"
                                        class="btn btn-primary">一鍵複製</button> -->
                                    <button class="btn btn-danger" @click="修改(item)">修改</button>
                                    <!-- <button class="btn btn-danger" @click="刪除(item)">刪除</button> -->
                                </td>
                            </template>
                            <template v-else>
                                <td> {{ item.編號 }} </td>
                                <td>
                                    <input class="form-control" type="text" v-model.trim="edit.資料" />
                                </td>
                                <td class="text-right">
                                    <button @click="儲存(item)" class="btn btn-success btn-save">儲存</button>
                                    <button @click="取消(item)" class="btn btn-default">取消</button>
                                </td>
                            </template>
                        </tr>
                        <tr v-if="filterList.length == 0">
                            <td colspan="3" class="text-center">
                                無資料
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:slot-footer>
                <button class="btn" @click="$refs.editForm.hide();">關閉</button>
            </template>
        </PopForm>
    </div>
</template>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.popform>>>.modalWidth {
    width: 800px;
}

.input-group-addon.disableBackground {
    background-color: #eee;
    cursor: not-allowed;

}
</style>
<script>
import PopForm from "@/views/Template/PopFormTemplate";
import { ConstOneClickType } from "@/store/ips/product";
import api from "@/assets/js/app-api";
export default {
    components: {
        PopForm,
    },
    data() {
        return {
            類別: -1,//-1:初始化 1:產品；2:介接廠商
            search: "",
            list: [],
            edit: {
                資料: "",
                //editor:0 察看；1:修改 2:新增
            }
        }
    },
    mounted() {
    },
    computed: {
        filterList() {
            let list = this.list;
            if (this.search != "") {
                let reg = new RegExp(this.search, "i");
                list = list.filter((item) => {
                    return item.資料.toString().search(reg) >= 0;
                });
            }
            return list;
        },
        是否有資料修改中() {
            let index = this.list.findIndex((i) => {
                return i.editor != 0;
            });
            return index >= 0;
        },
        title() {
            let find = ConstOneClickType.find((item) => { return item.類別 == this.類別; });
            return find ? find.名稱 : "";
        },
    },
    watch: {
        類別(v1, v2) {
            if (v1 == v2) { return; }
            let list = this.$store.state.product.products[v1];
            list = list ? list : [];
            this.更新list(list);
        },
        ["$store.state.product.products"]: {
            handler(v) {
                let list = this.$store.state.product.products[this.類別];
                list = list ? list : [];

                // let list = v.filter((item) => { return item.類別 == this.類別 });
                this.更新list(list);
            },
            deep: true
        }
    },
    methods: {
        更新list(list) {
            let tmpList = [];
            for (let i of list) {
                let item = {};
                this.$set(item, "editor", 0);
                this.$set(item, "資料", i.資料);
                this.$set(item, "編號", i.編號);
                this.$set(item, "類別", i.類別);
                tmpList.push(item);
            }
            this.list = tmpList;
        },
        async show(類別) {
            this.$refs.editForm.show();
            this.$refs.editForm.loading(true);
            this.類別 = 類別;
            await this._reset();
            this.$refs.editForm.loading(false);
        },
        開啟新增列() {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
                return;
            }
            this.search = "";
            let item = {
                資料: "",
                editor: 2,
            };
            this.list.unshift(item);
            this.edit.資料 = item.資料;
        },
        async _reset() {
            await this.$store.dispatch("product/GetProductCommonData", { 類別: this.類別 });
        },
        修改(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
                return;
            }
            item.editor = 1;
            this.edit.資料 = item.資料;
        },
        async 儲存(item) {
            if (!this.edit.資料) {
                return this.$eventBus.$emit("showAlert", "名稱不可為空");
            }
            this.$refs.editForm.loading(true);
            try {
                let res = null;
                if (item.editor == 2) {
                    res = await api.IPSSetting.AddProductCommonData({
                        類別: this.類別,
                        資料: this.edit.資料
                    });
                } else {
                    res = await api.IPSSetting.UpdateProductCommonData({
                        編號: item.編號,
                        類別: item.類別,
                        資料: this.edit.資料
                    });
                }
                if (res && res.data && res.data.sucess) {
                    this.$eventBus.$emit("showAlert", "執行成功");
                } else {
                    let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                    throw errMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            await this._reset();
            this.$refs.editForm.loading(false);
        },
        取消(item) {
            if (item.editor == 2) {
                this.從列表中移除指定item(item);
            } else {
                item.editor = 0;
            }
        },
        從列表中移除指定item(item) {
            let index = this.list.findIndex((i) => {
                return item.資料 === i.資料;
            })
            if (index >= 0) {
                this.list.splice(index, 1);
            }
        },
        // async 刪除(item) {
        //     if (this.是否有資料修改中) {
        //         this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
        //         return;
        //     }
        //     this.$refs.editForm.loading(true);
        //     try {
        //         //刪除 todo:
        //         // InvoiceAPI(await api.IPSSetting.RemoveCommonInformation({
        //         //     編號: item.編號,
        //         // }));
        //         this.$eventBus.$emit("showAlert", "執行成功");
        //         await this._reset();
        //     } catch (e) {
        //         this.$eventBus.$emit("showAlert", e);
        //     }
        //     this.$refs.editForm.loading(false);
        // },
    },
};
</script>