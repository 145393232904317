import { render, staticRenderFns } from "./AlbumPhotoManage.vue?vue&type=template&id=32404da7"
import script from "./AlbumPhotoManage.vue?vue&type=script&lang=js"
export * from "./AlbumPhotoManage.vue?vue&type=script&lang=js"
import style0 from "./AlbumPhotoManage.vue?vue&type=style&index=0&id=32404da7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports