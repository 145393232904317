<template>
    <div id="ClassTypeManager">
        <section class="content-header">
            <h1>{{ $t('客服歷程') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('客服歷程') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <label>{{ $t('開始日期') }}：</label>
                        <div class="searchBox">
                            <DateRangePicker :propEndDate.sync="searchData.ETime" :propStartDate.sync="searchData.STime"
                                v-on:update:date="updatedate" propsIn="format2" />
                        </div>
                        <div class="state iradio_flat-blue" :checked="搜尋類型 == '搜尋事件'" @click="搜尋類型 = '搜尋事件'">
                        </div>
                        {{ $t('搜尋事件') }}
                        <div class="state iradio_flat-blue" :checked="搜尋類型 == '搜尋內容'" @click="搜尋類型 = '搜尋內容'"></div>
                        {{ $t('搜尋內容') }}
                        <br />
                        <template v-if="搜尋類型 == '搜尋事件'">
                            <div class="searchBox">
                                <input class="form-control" type="text" :placeholder="$t('請輸入暱稱')"
                                    v-model.trim="searchData.Player" />
                            </div>
                            <div class="searchBox">
                                <multiselect :placeholder="$t('請輸入客服人員')" v-model="searchData.Cs" :options="客服列表"
                                    label="show" track-by="Cs">

                                    <!-- <template slot="singleLabel" slot-scope="props"><span class="Aoption__title">{{
                                        props.option.show
                                    }}</span></template> -->
                                    <!-- <template slot="option" slot-scope="props"><img class="option__image"
                                            :src="props.option.img" alt="No Man’s Sky">
                                        <div class="option__desc"><span class="option__title">{{
                                            props.option.title
                                        }}</span><span class="option__small">{{ props.option.desc }}</span>
                                        </div>
                                    </template> -->
                                </multiselect>
                            </div>
                        </template>
                        <template v-else>
                            <div class="searchBox">
                                <select class="form-control" v-model="搜尋暱稱或客服">
                                    <option value="暱稱">{{ $t('暱稱') }}</option>
                                    <option value="客服">{{ $t('客服人員') }}</option>
                                </select>
                            </div>
                            <div class="searchBox">
                                <input class="form-control" style="width:150px;" type="text" v-if="搜尋暱稱或客服 == '暱稱'"
                                    :placeholder="$t('請輸入暱稱')" v-model.trim="searchData.Player" />
                                <multiselect v-if="搜尋暱稱或客服 == '客服'" placeholder="請輸入客服人員" v-model="searchData.Cs"
                                    :options="客服列表" label="show" track-by="Cs">
                                </multiselect>
                            </div>
                        </template>
                        <div class="searchBox" v-if="搜尋類型 == '搜尋事件'">
                            <select class="form-control" v-model="searchEventData.FinishReason">
                                <option value="">{{ $t('全部事件類別') }}</option>
                                <option v-for="item of 自動完成事件類型" :value="item.id">{{ $t(item.name) }}</option>
                            </select>
                        </div>
                        <div class="searchBox" v-if="搜尋類型 == '搜尋事件'">
                            <select class="form-control" v-model="searchEventData.EventType">
                                <option value="">{{ $t('全部事件原因') }}</option>
                                <option v-for="item of 固定事件類型" :value="item.id">{{ $t(item.name) }}</option>
                            </select>
                        </div>
                        <div class="searchBox" v-if="搜尋類型 == '搜尋事件'">
                            <select class="form-control" v-model="searchEventData.ProcessStatus">
                                <option value="">{{ $t('全部狀態') }}</option>
                                <!-- <option v-for="item of ProcessStatus" :value="item.value">{{ item.tag }}({{
                                    item.value
                                }})</option> -->
                                <option :value=0>{{ $t('未分派') }}</option>
                                <option :value=1>{{ $t('已分派') }}</option>
                                <option :value=2>{{ $t('處理中') }}</option>
                                <option :value=3>{{ $t('已完成') }}</option>
                                <!-- 
                                <option :value=4>已完成(玩家離線，系統自動結案)4</option>
                                <option :value=5>已完成(閒置30分鐘，系統自動結案)5</option> -->
                            </select>
                        </div>
                        <div class="searchBox" v-if="搜尋類型 == '搜尋內容'">
                            <input class="form-control" style="width:300px;" type="text" :placeholder="$t('內容搜尋')"
                                v-model.trim="searchMessageData.Keyword" />
                        </div>
                        <div class="searchBox">
                            <button type="button" class="btn btn-block btn-primary"
                                @click="onSearch(1, search條件, 搜尋類型)">
                                <i class="fa fa-search"></i>
                                {{ $t('查詢') }}</button>
                        </div>
                        {{ $t('資料總筆數') }}：{{ total }}
                        <!-- <div class="searchBox">
                            <button type="button" class="btn btn-block btn-warning">
                                匯出</button>
                        </div> -->
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered"
                            v-if="eventList.length > 0 || (messageList.length == 0 && 搜尋類型 == '搜尋事件')">
                            <tbody>
                                <tr class=" bg-gray">
                                    <th class="text-nowrap">{{ $t('ID') }}</th>
                                    <th class="text-nowrap">{{ $t('事件類別') }}</th>
                                    <th class="text-nowrap">{{ $t('事件原因') }}</th>
                                    <th class="text-nowrap">{{ $t('狀態') }}</th>
                                    <th class="text-nowrap">{{ $t('玩家暱稱') }}</th>
                                    <th class="text-nowrap">{{ $t('客服人員') }}</th>
                                    <th class="text-nowrap">{{ $t('建立時間') }}</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        {{ $t('功能') }}
                                    </th>
                                </tr>
                                <tr v-for="item of eventList" :key="item.EventId">
                                    <td>{{ item.EventId }}</td>
                                    <td>{{ $t(item.FinishReasonKey) }}</td>
                                    <td>{{ $t(item.EventTypeKey) }}</td>
                                    <td>{{ $t(`事件狀態${item.事件狀態Key}`) }}</td>
                                    <td>{{ item.Player }}</td>
                                    <td>{{ filter客服(item.Cs) }}</td>
                                    <td>{{ 格式化時間(item.CreateTime) }}</td>
                                    <td> <button type="button" class="btn btn-block btn-primary"
                                            @click="openDetailForm(item.EventId, item.ServiceNo)">{{ $t('詳細')
                                            }}</button>
                                    </td>
                                </tr>
                                <tr v-if="eventList.length == 0">
                                    <td class="text-center" colspan="8">{{ $t('沒有資料') }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-hover table-bordered"
                            v-if="messageList.length > 0 || (eventList.length == 0 && 搜尋類型 == '搜尋內容')">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">{{ $t('ID') }}</th>
                                    <th class="text-nowrap">{{ $t('發話方') }}</th>
                                    <th class="text-nowrap">{{ $t('內容') }}</th>
                                    <th class="text-nowrap">{{ $t('建立時間') }}</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        {{ $t('功能') }}
                                    </th>
                                </tr>

                                <tr v-for="  item   of   messageList  " :key="item.ChatId">
                                    <td>{{ item.EventId }}</td>
                                    <td>{{ item.Sender }}</td>
                                    <td>{{ item.Msg }}</td>
                                    <td>{{ 格式化時間(item.CreateTime) }}</td>
                                    <td> <button type="button" class="btn btn-block btn-primary"
                                            @click="openDetailForm(item.EventId, item.ServiceNo)">{{ $t('詳細')
                                            }}</button>
                                    </td>
                                </tr>
                                <tr v-if="messageList.length == 0">
                                    <td class="text-center" colspan="7">{{ $t('沒有資料') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
        <PopLogForm ref="popLogForm" :客服顯示對應表="客服顯示對應表"></PopLogForm>
    </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import PopLogForm from "./PopLogForm.vue";
import { 自動完成事件類型, PlatformType, ProcessStatus } from "@/store/MessageSystem/messageSystemConst";
import { formatCostTime } from "@/store/MessageSystem/messageSystemLib";
import Multiselect from "vue-multiselect";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
export default {
    components: {
        DateRangePicker, Pagination, PopLogForm, Multiselect, formatCostTime
    },
    data() {
        return {
            ProcessStatus,
            自動完成事件類型,
            搜尋類型: '搜尋事件',//搜尋內容
            搜尋暱稱或客服: '暱稱',
            searchData: {
                ServiceNo: "",
                STime: moment().format("YYYY-MM-DD"),
                ETime: moment().format("YYYY-MM-DD"),
                Cs: "",
                Player: "",
                // Page: 1
            },
            searchEventData: {
                ProcessStatus: "",//""時不傳
                EventType: "",//""時不傳
                FinishReason: "",//""時不傳
            },
            searchMessageData: {
                Keyword: ""
            },
            客服列表: [],
            total: 0,
            pageSize: 20,
            eventList: [],
            messageList: [],
            lastQuery搜尋類型: null,
            lastQueryCommand: null,
        };
    },
    mounted() {
    },
    computed: {
        ...mapState({
            客服顯示對應表: state => state.messageSystemServer.客服顯示對應表,
            stateProjectId: state => state.projectId,
        }),
        固定事件類型() {
            let list = JSON.parse(JSON.stringify(this.$store.getters["messageSystemServer/固定事件類型"]));
            list.push({ id: "ViolationNotice", name: "ViolationNotice" });
            return list;
        },
        search條件() {
            let tmp = {};
            if (this.搜尋類型 == '搜尋事件') {
                tmp = { ...this.searchData, ...this.searchEventData };
            } else {
                tmp = { ...this.searchData, ...this.searchMessageData };
            }
            tmp.Cs = (tmp.Cs && tmp.Cs.Cs) || "";
            return tmp;
        },
    },
    watch: {
        stateProjectId: {
            async handler(v) {
                this.searchData.ServiceNo = v;
                //初始化
                try {
                    await this.$store.dispatch("messageSystemServer/載入客服顯示對應表", { ServiceNo: v });
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                this.客服列表 = [...this.客服顯示對應表];
                this.客服列表.unshift({
                    Cs: "",
                    Name: this.$t('全部'),
                });
                for (let i of this.客服列表) {
                    this.$set(i, "show", `${i.Name} ${i.Cs}`);
                }
                $(this.$refs.loading).hide();
            },
            immediate: true
        },
        搜尋類型() {
            this.searchData.Cs = "";
            this.searchData.Player = "";
        },
        搜尋暱稱或客服() {
            this.searchData.Cs = "";
            this.searchData.Player = "";
        },
    },
    methods: {
        updatedate(a, b) {
            this.searchData.STime = a;
            this.searchData.ETime = b;
        },
        onPageChange(pageIdx) {
            this.onSearch(pageIdx, this.lastQueryCommand, this.lastQuery搜尋類型);
        },
        async onSearch(pageIdx, query, type) {
            if (!query || !type) {
                return;
            }

            //檢查條件：
            if (type == "搜尋內容" && query.Keyword == "") {
                this.$eventBus.$emit("showAlert", this.$t('內容搜尋欄位不可為空'));
                return;
            }

            let send = JSON.parse(JSON.stringify(query));
            send.Page = pageIdx;

            if (send.Cs == "") {
                delete send.Cs;
            }
            if (send.Player == "") {
                delete send.Player;
            }
            // console.error("ProcessStatus", send.ProcessStatus);
            if (send.ProcessStatus === "") {
                delete send.ProcessStatus;
            }
            if (send.EventType == "") {
                delete send.EventType;
            }
            if (send.FinishReason == "") {
                delete send.FinishReason;
            }
            if (send.FinishReason == "Normal") {
                send.FinishReason = "null";
            }
            $(this.$refs.loading).show();
            try {
                let res = {};
                if (type == "搜尋事件") {
                    res = (await api.CS.SearchEvent(send)).data;
                    for (let i of res.Data) {
                        i.ServiceNo = query.ServiceNo;
                        i.事件狀態Key = this.filter狀態(i.ProcessStatus)
                        i.FinishReasonKey = this.filterFinishReason(i.FinishReason)
                        i.EventTypeKey = this.filterEventType(i.EventType)
                    }
                    this.eventList = res.Data;
                    this.messageList = [];
                } else {
                    res = (await api.CS.SearchMessage(send)).data;
                    this.messageList = res.Data;
                    for (let i of this.messageList) {
                        let sender = i.ChatMsgType == 1 ? this.filter客服(i.Cs) + this.$t('Scratch客服') : i.Player + this.$t('Scratch玩家');
                        this.$set(i, "Sender", sender);
                    }
                    for (let i of res.Data) {
                        i.ServiceNo = query.ServiceNo;
                    }
                    this.eventList = [];
                }
                this.total = res.DataCount;
                this.lastQueryCommand = send;
                this.lastQuery搜尋類型 = type;
            } catch (e) {
                this.eventList = [];
                this.messageList = [];
                this.total = 0;
                this.lastQueryCommand = null;
                this.lastQuery搜尋類型 = null;
                this.$eventBus.$emit("showAlert", e);
            }
            this.$refs.pagination.setPage(
                pageIdx,
                this.total
            );
            $(this.$refs.loading).hide();
        },
        async openDetailForm(EventId, ServiceNo) {
            $(this.$refs.loading).show();
            try {
                let resDetail = (await api.CS.GetEventDetailData({ EventId })).data;
                let 事件狀態Key = this.filter狀態(resDetail.ProcessStatus)

                let timeAry = formatCostTime(resDetail.SpendTime);
                let costTimeStr = timeAry[1] > 0 ? this.$t(`花費時間格式化_2`, timeAry) : this.$t(`花費時間格式化_1`, timeAry)
                this.$refs.popLogForm.show(this.$t('詳細內容'), {
                    ID: resDetail.EventId,
                    狀態: this.$t(`事件狀態${事件狀態Key}`),
                    來源: PlatformType[resDetail.Platform] && PlatformType[resDetail.Platform].Type,//"Web",
                    事件類別: this.$t(this.filterFinishReason(resDetail.FinishReason)),//"其他",
                    事件原因: this.$t(this.filterEventType(resDetail.EventType)),//"其他",
                    玩家暱稱: resDetail.Player,//"Ellyyy",
                    客服人員: this.filter客服(resDetail.Cs),//"小花",
                    建立時間: this.格式化時間(resDetail.CreateTime),//"2022/10/20 18:21:00",
                    花費時間: costTimeStr,//formatCostTime(resDetail.SpendTime),//"11分",
                    // 平均回應時間: resDetail.AvgResTime,//暫不製作
                    備註: resDetail.Remark
                }, EventId, ServiceNo);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        格式化時間(value) {
            return /*value + "," +*/ new moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
        filterEventType(v) {
            let f = this.固定事件類型.find(item => { return item.id == v });
            if (f) {
                return f.name;
            }
            return v;
        },
        filterFinishReason(v) {
            if (v == "") {
                v = "Normal";
            }
            let f = 自動完成事件類型.find(item => { return item.id == v });
            if (f) {
                return f.name;
            }
            return v;
        },
        filter狀態(v) {
            let keys = Object.keys(this.ProcessStatus);
            for (let key of keys) {
                if (this.ProcessStatus[key].value == v) {
                    return key;
                }
            }
            return '';
        },
        filter客服(v) {
            if (v == "") {
                return "";
            }
            let find = (this.客服列表.find(客服 => {
                return 客服.Cs == v;
            }));
            return find ? find.show : v;

        },
    }
};
</script>
<style scoped>
.searchBox {
    display: inline-block;
    padding-right: 12px;
    padding-bottom: 6px;
}

.iradio_flat-blue {
    margin-left: 0px;
}

.iradio_flat-blue[checked] {
    background-position: -110px 0;
}
</style>
<style lang="scss" scoped>
.searchBox {
    // .multiselect {
    //     box-sizing: border-box;
    // }

    .multiselect__tags {
        width: 250px;
        // height: 20px;
        // box-sizing: border-box;
    }

    .multiselect__spinner {
        // height: 20px;
    }

    .multiselect__single {
        font-size: 14px;
    }

    .multiselect__input {
        font-size: 14px;
    }
}

.dropdown-menu>li>button {

    border-color: white;
    background-color: white;
    text-align: left;
    padding-left: 20px;
}

.dropdown-menu>li>button[active] {
    border-color: white;
    background-color: #ddd;
}
</style>