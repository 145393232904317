<template>
    <div id="AnnouncementList">
        <section class="content-header" style="    display: flex;    flex-wrap: wrap;    align-items: flex-end;">
            <h1>留言追蹤</h1>
            <span style="        margin-left: 8px;color: red;">*包含:需要爬蟲的影片內所有留言</span>
        </section>
        <section class="content">

            <MsgAlert ref="MsgAlert" @submit="getCommentQueryAll" />
            <channelAlert ref="channelAlert" />

            <div id="CustomerService">
                <div class="nav-tabs-custom tab-warning">
                    <ul class="nav nav-tabs">
                        <li :class="{ active: searchDataVideo.module === '例行' }">
                            <a aria-expanded="true" data-toggle="tab" href="#usual" @click="resetDataList('例行', '')">
                                例行檢索
                            </a>
                        </li>
                        <li :class="{ active: searchDataVideo.module === '追蹤中' }">
                            <a aria-expanded="false" data-toggle="tab" href="#watch" @click="resetDataList('追蹤中', '')">
                                追蹤中留言
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <!-- 例行檢索 -->
                        <div class="tab-pane" :class="{ active: searchDataVideo.module === '例行' }">
                            <div class="">
                                <div class="box-body" style="padding: 0;    border: 0;">
                                    <div class="top-box" style="    position: relative;">

                                        <DaterangepickerForMessage ref="datePicker"
                                            @ApplyDaterangepicker="applyDaterangepicker">
                                        </DaterangepickerForMessage>

                                        <div class="select-group">
                                            <select class="form-control" id="selectUnit" v-model="searchDataVideo.巡邏人"
                                                @change="clickSearch">
                                                <option value="" select>全部巡邏人</option>
                                                <option value="尚未派發" >尚未派發</option>
                                                <option v-for="(item, index) in channelMan.result" :value="item.人員名稱"
                                                    :key="index">
                                                    {{item.人員名稱}}</option>
                                            </select>
                                        </div>
                                        <div class="select-group">
                                            <select class="form-control" id="selectUnit" required="required"
                                                v-model="searchDataVideo.狀態" @change="clickSearch">
                                                <!-- <option disabled="disabled" value>請選擇分類</option> -->
                                                <option value="-1" key="1">全部狀態</option>
                                                <option value="0" key="2">未處理</option>
                                                <option value="1" key="3">不處理</option>
                                                <option value="2" key="4">待追蹤</option>
                                                <option value="3" key="5">已結案</option>
                                            </select>
                                        </div>
                                        <div class="input-group">
                                            <input class="form-control pull-right" name="table_search"
                                                placeholder="搜尋關鍵字" type="text" v-model="searchDataVideo.搜尋關鍵字" />
                                            <div class="input-group-btn">
                                                <button v-on:click="clickSearch()" class="btn btn-default"
                                                    type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="text">
                                            <p class="data-num">
                                                <span v-if="searchShow = 1">資料總筆數：</span>
                                                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                                                <span id="total">{{ edit.total }}</span>
                                            </p>
                                        </div>
                                        <div class="final" style="      position: absolute;    right: 0px;    top: 6px;    color: #ff4f4f;    font-size: 16px;">最後檢索時間: {{FormatTime(edit.最後檢索時間)}}</div>
                                    </div>
                                    <div class="table-responsive mt-10">
                                        <table class="table table-hover table-bordered min-800">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <th class="text-center" style="min-width: 90px; width: 13%">頻道名稱</th>
                                                    <th style="min-width: 90px; width: 20%">影片標題</th>
                                                    <th style="min-width: 90px; width: 6%">留言作者</th>
                                                    <th class="text-nowrap" style="width: 12%;">留言內容</th>
                                                    <th class="text-nowrap" style="width: 10%;">發布日期</th>
                                                    <th class="text-nowrap" style="width: 4%;">巡邏人</th>
                                                    <th class="text-nowrap" style="width: 4%;">狀態</th>
                                                    <th class="text-nowrap" style="width: 10%;">備註</th>
                                                    <th class="text-nowrap text-center" style="min-width: 100px; width: 4%;">功能</th>
                                                </tr>
                                                <template v-if="edit.total > 0 ">
                                                    <tr :key="'editDatas' + index" v-for="(item, index) in edit.datas">
                                                        <td style="text-align:center;">
                                                            <span v-if="item.頻道管理編號 != null"
                                                                @click="openChannel(item.頻道管理編號)" class="linkStyle" v-html="item.頻道名稱">
                                                            </span>
                                                            <span v-else="item.頻道管理編號 != null" v-html="item.頻道名稱">
                                                            </span>
                                                        </td>
                                                        <td style="text-align:left;"><a class="linkStyle"
                                                                :href="`https://www.youtube.com/watch?v=`+item.videoId"
                                                                target="_blank">{{item.影片標題}}</a></td>
                                                        <td style="text-align:center;"><span>{{item.留言作者}}</span></td> 
                                                        <td style="text-align:left;"><div class="needHightLight" v-html="changeN(item.留言內容)"></div></td>                                                           
                                                        <td style="text-align:center;">{{FormatTime(item.留言發布日期)}}</td>    
                                                        <td style="text-align:center;"><span>{{item.巡邏人}}</span></td>                                                     
                                                         <td style="text-align:center;">
                                                            <span v-if="item.狀態 == 0">未處理</span>
                                                            <span v-if="item.狀態 == 1">不處理</span>
                                                            <span v-if="item.狀態 == 2">待追蹤</span>
                                                            <span v-if="item.狀態 == 3">已結案</span>
                                                        </td>
                                                         <td style="text-align:center;"><span>{{item.備註}}</span></td>
                                                        <td style="text-align:center;" class="text-center text-nowrap">
                                                            <button @click="openVideo(item)"
                                                                class="btn btn-primary">詳細</button>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                        <td class="text-center" colspan="10">沒有資料</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="box-footer text-center no-border" v-show="edit.total > 0">
                                    <Pagination ref="pagination" :total="edit.total"
                                        :pageLimitCount="searchDataVideo.pageSize" v-on:pageMethod="onPageChange" />
                                </div>
                            </div>

                        </div>
                        <!-- 追蹤中 -->
                        <div class="tab-pane" :class="{ active: searchDataVideo.module === '追蹤中' }">
                            <div class="">
                                <div class="box-body" style="padding: 0;    border: 0;">
                                    <div class="top-box" style="    position: relative;">
                                         <!-- 
                                        <div class="select-group">
                                            <select class="form-control" id="selectUnit" required="required"
                                                v-model="searchDataVideo.狀態" @change="clickSearch">
                                                <option value="-1" key="1">不分關鍵字群組</option>
                                                <option value="0" key="2">關鍵字群組A</option>
                                                <option value="1" key="3">關鍵字群組B</option>
                                            </select>
                                        </div>-->
                                        <div class="input-group">
                                            <input class="form-control pull-right" name="table_search"
                                                placeholder="搜尋關鍵字" type="text" v-model="searchDataVideo.搜尋關鍵字" />
                                            <div class="input-group-btn">
                                                <button v-on:click="clickSearch()" class="btn btn-default"
                                                    type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="text">
                                            <p class="data-num">
                                                <span v-if="searchShow = 1">資料總筆數：</span>
                                                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                                                <span id="total">{{ edit.total }}</span>
                                            </p>
                                        </div>
                                        <div class="final" style="      position: absolute;    right: 0px;    top: 6px;    color: #ff4f4f;    font-size: 16px;">最後檢索時間: {{FormatTime(edit.最後檢索時間)}}</div>
                                    </div>
                                    <div class="table-responsive mt-10">
                                        <table class="table table-hover table-bordered min-800">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <th class="text-center" style="min-width: 90px; width: 13%">頻道名稱</th>
                                                    <th style="min-width: 90px; width: 20%">影片標題</th>
                                                    <th style="min-width: 90px; width: 6%">留言作者</th>
                                                    <th class="text-nowrap" style="width: 12%;">留言內容</th>
                                                    <th class="text-nowrap" style="width: 10%;">發布日期</th>                                                    
                                                    <th class="text-nowrap" style="width: 8%;">追蹤狀態</th>
                                                    <th class="text-nowrap" style="width: 10%;">備註</th>
                                                    <th class="text-nowrap text-center" style="min-width: 100px; width: 4%;">功能</th>
                                                </tr>
                                                <template v-if="edit.total > 0 ">
                                                    <tr :key="'editDatas' + index" v-for="(item, index) in edit.datas">
                                                        <td style="text-align:center;">
                                                            <span v-if="item.頻道管理編號 != null"
                                                                @click="openChannel(item.頻道管理編號)" class="linkStyle">
                                                                {{item.頻道名稱}}
                                                            </span>
                                                            <span v-else="item.頻道管理編號 != null">
                                                                {{item.頻道名稱}}
                                                            </span>
                                                        </td>
                                                        <td style="text-align:left;"><a class="linkStyle"
                                                                :href="`https://www.youtube.com/watch?v=`+item.videoId"
                                                                target="_blank">{{item.影片標題}}</a></td>
                                                        <td style="text-align:center;"><span>{{item.留言作者}}22</span></td>
                                                        <td style="text-align:left;"><span class="needHightLight" v-html="changeN(item.留言內容)"></span></td>                                                           
                                                        <td style="text-align:center;">{{FormatTime(item.留言發布日期)}}</td>   
                                                        <td style="text-align:center;"><span>{{item.追蹤狀態}}</span></td>
                                                        <td style="text-align:center;"><span>{{item.備註}}</span></td>
                                                        <td style="text-align:center;" class="text-center text-nowrap">
                                                            <button @click="openVideo(item)"
                                                                class="btn btn-primary">詳細</button>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                        <td class="text-center" colspan="10">沒有資料</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="box-footer text-center no-border" v-show="edit.total > 0">
                                    <Pagination ref="pagination" :total="edit.total"
                                        :pageLimitCount="searchDataVideo.pageSize" v-on:pageMethod="onPageChange" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- /.tab-content -->
                </div>
            </div>

        </section>
    </div>
</template>
<style lang="scss" scoped>
    #AnnouncementList {
        .pic {
            img {
                max-height: 80px;
                margin: 0 auto;
            }
        }
    }
    .needHightLight  span{
        color: red;
    }
  .linkStyle {
        cursor: pointer;
        color: black;
        border-bottom: 1px solid;
       /deep/ span{
            color: red;
        }
    }

    .table-bordered>tbody>tr>td {
        border: 1px solid #cfcfcf;
    }

    .moretd {
        div {
            border-top: 1px solid #ebebeb;
            padding: 6px 0;

            &:first-child {
                border-top: 0;
            }
        }
    }
.ranges li.active {
    background-color: white;
    border: 1px solid white;
    color: #08c;
}
</style>
<style>
    .needHightLight > span{
        color: red;
    }
</style>
<script>
    /* eslint-disable */
    //import CKEditor from "@/components/CKEditor";
    import api from "@/assets/js/app-api";
    import Pagination from "@/components/Pagination";
    import utils from "@/assets/js/app-utils";
    import config from "@/assets/js/app-config";
    import moment from "moment";

    import DateRangePicker from "@/components/DateRangePicker";
    import MsgAlert from "@/views/projects/smm/MsgBox";
    import channelAlert from "@/views/projects/smm/videoBoxChannel";
    import DaterangepickerForMessage from "./DaterangepickerForMessage";
    import Multiselect from "vue-multiselect";
    import "vue-multiselect/dist/vue-multiselect.min.css";

    import {
        mapState,
        mapActions
    } from "vuex";

    export default {
        components: {
            DateRangePicker,
            Pagination,
            DateRangePicker,
            MsgAlert,
            channelAlert,
            DaterangepickerForMessage,
            Multiselect
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                total: 0, //總筆數
                projectId: this.$store.state.projectId,
                moduleId: '',
                頻道名稱: '',
                searchDataVideo: {
                    module: "例行",
                    頻道管理編號: "",
                    巡邏人: "",
                    狀態: -1, //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
                    搜尋關鍵字: "",
                    開始時間: "",
                    結束時間: "",
                    pageNum: 1,
                    pageSize: 20
                },
                edit: {
                    
                },
                url: config.dkOption.url,


            };
        },
        async created() {
            if (this.$route.params.searchDataVideo != undefined) {
                this.searchDataVideo = this.$route.params.searchDataVideo;
            }
            // 取得今天的日期
            const today = new Date();
            this.searchDataVideo.結束時間 = this.formatDate(today) + ' 23:59';

            // 取得七天前的日期
            const yesterday7 = new Date();
            yesterday7.setDate(today.getDate() - 7);
            this.searchDataVideo.開始時間 = this.formatDate(yesterday7)+ ' 00:00';


            // 開箱文日期預設值
            /* if (this.projectId === 'omp_xinstars') {
               this.searchDataVideo.StartTime = new moment().subtract(360, "days").format("YYYY-MM-DD")
               this.searchDataVideo.EndTime = new moment().add(60, "days").format("YYYY-MM-DD")
             }

             if(this.projectId == 'omp_heti' && this.isSpecialPage) {
               this.searchDataVideo.module = 'special_offer'
               this.getHint();
             }*/
            await this.getchannelMan();
            this.getCommentQueryAll();
        },
        mounted() {},
        computed: {
            ...mapState({
                channelMan: (state) => state.smm.QueryMan,
                response: (state) => state.smm.response,
                projectData: (state) => state.module.queryAll,
            }),
        },
        watch: {

            getVideoQuery() {
                //this.$store.dispatch("link/linkQueryAll", this.searchDataVideo);
            },
            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;
            },
            $route(val) {
                //    this.searchDataVideo.module = 'news'
                this.getchannelMan();
                this.getCommentQueryAll();
            },
            response() {
                if (this.response.status == "1")
                    this.$eventBus.$emit("showAlert", "更新成功!");
                else this.$eventBus.$emit("showAlert", "更新失敗!");
                this.getCommentQueryAll();
            },

        },
        methods: {
            async onPageChange(pageIdx, pageSize) {
                this.searchDataVideo.pageNum = pageIdx;
                this.searchDataVideo.pageSize = pageSize;
                // this.searchDataVideo.開始時間 = this.FormatTime(start);
                // this.searchDataVideo.結束時間 = this.FormatTime(end);
                this.getCommentQueryAll();
            },
            async getchannelMan() {
                this.$store.dispatch("smm/channelMan", {
                    projectId: 'omp_smm',
                });
            },
            applyDaterangepicker(start, end) {   
                this.searchDataVideo.pageNum = 1;      
                this.searchDataVideo.開始時間 = this.FormatTime(start);
                this.searchDataVideo.結束時間 = this.FormatTime(end);
                this.getCommentQueryAll();
            },
            getCommentQueryAll(name) {
                //列表
                
                if(name == "success"){
                    
                }
                this.$store.dispatch("smm/commentQueryAll", {
                    //頻道管理編號: this.$route.params.id 
                    狀態: parseInt(this.searchDataVideo.狀態, 10), //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
                    搜尋關鍵字: this.searchDataVideo.搜尋關鍵字,
                    巡邏人: this.searchDataVideo.巡邏人,
                    檢索狀態:1,
                    開始時間: this.searchDataVideo.開始時間,
                    結束時間: this.searchDataVideo.結束時間,
                    pageNum: this.searchDataVideo.pageNum,
                    pageSize: this.searchDataVideo.pageSize
                }).then((res) => {
                    this.edit = res.result;
                });
                //console.log(this.edit)
                //if (this.$route.params.searchDataVideo != undefined)
                //this.$refs.pagination.setPage(this.searchDataVideo.pageNum, this.total);
            },

            resetDataList(moduleType, reactionType) {
                this.searchDataVideo.module = moduleType;
                this.searchDataVideo.pageNum = 1;
                this.searchDataVideo.檢索狀態=1;
                this.searchDataVideo.開始時間='';
                this.searchDataVideo.結束時間='';
                this.searchDataVideo.巡邏人='';
                this.searchDataVideo.搜尋關鍵字='';
                if( this.searchDataVideo.module == "追蹤中"){
                    this.searchDataVideo.狀態=2;
                }else{
                    this.searchDataVideo.狀態=-1;
                    // 取得今天的日期
                    const today = new Date();
                    this.searchDataVideo.結束時間 = this.formatDate(today) + ' 23:59';
                    // 取得昨天的日期
                    const yesterday = new Date();
                    yesterday.setDate(today.getDate() - 7);
                    this.searchDataVideo.開始時間 = this.formatDate(yesterday)+ ' 00:00';
                    $(".ranges li[data-range-key=近七日]").click();
                }
                this.getCommentQueryAll();
                // this.getDataList(moduleType, reactionType);
            },
            clickSearch() {
                this.searchDataVideo.pageNum = 1;
                this.getCommentQueryAll();
            },

            openVideo(item) {
                this.$refs.MsgAlert.openBox(item);
            },

            openChannel(id) {
                this.$store.dispatch("smm/channelQuery", {
                    頻道管理編號: id
                }).then((res) => {
                    this.$refs.channelAlert.openBox(res.result);
                });

            },
            changeN(name) {
                return name.replace(/\n/g, '<br>');
            },
            FormatTime(time) {
                if (time == undefined) return "";
                return new moment(time).format("YYYY/MM/DD HH:mm:ss");
            },
            formatDate(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}/${month}/${day}`;
            }
        },
    };

</script>
<style scoped>
    .ckbx-style-8 label:before {
        background: #999;
    }

</style>
