<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>{{GetContentName()}}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>內容管理</li>
        <li class="active">{{GetContentName()}}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增內容
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.classTypeId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in newsTypes.datas"
                  :key="index"
                >{{item.className}}</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ news.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">分類</th>
                  <th style="min-width: 90px; width: 5%">主圖</th>
                  <th style="min-width: 90px; width:3%">置頂</th>
                  <th class="text-nowrap" style="width: 10%;">主旨</th>
                  <th class="text-nowrap" style="width: 10%;">發佈日期</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap" style="width: 3%;">建立者工號</th>
                  <th class="text-nowrap" style="width: 3%;">建立者</th>
                  <th class="text-nowrap" style="width: 10%;">建立日期</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="news.total > 0">
                  <tr :key="item.id" v-for="item in news.datas">
                    <td>{{ item.classTypeName }}</td>
                    <td>
                      <div class="pic jqimgFill">
                        <img draggable="false" :src="item.mainImgFullFileName" />
                      </div>
                    </td>
                    <td align="center">{{ item.isTop == '1'? '是':'否' }}</td>
                    <td>{{ item.detailTopTitle }}</td>
                    <td>{{ FormatTime(item.publishTime) }}</td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td align="center">{{ item.createUId }}</td>
                    <td align="center">{{ item.createUName }}</td>
                    <td>{{ item.createTime }}</td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <button
                        @click="previewItem(item)"
                        class="btn btn-warning"
                        v-if="item.isStop =='1' && projectId == 'omp_dk'"
                      >預覽</button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="news.total > 0">
          <Pagination
            ref="pagination"
            :total="news.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
// import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState } from "vuex";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      searchData: {
        classTypeId: "",
        module: "content_" + this.$route.params.module,
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10 //每頁顯示筆數
      },
      url: config.dkOption.url
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.getNewsClassTypes();
    this.getNewsQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      newsTypes: state => state.classTypes.queryAll,
      news: state => state.news.queryAll,
      response: state => state.news.response
    })
  },
  watch: {
    newsTypes() {},
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.getNewsQueryAll();
    },
    news() {
      this.$refs.pagination.setPage(this.searchData.pageNum, this.news.total);
    },
    "$route.params.module": async function(module) {
      this.searchData.module = "content_" + module;

      await this.getNewsClassTypes();
      this.getNewsQueryAll();
    }
  },
  methods: {
    GetContentName() {
      switch (this.$route.params.module) {
        case "introduction":
          return "俠客攻略";
        case "service":
          return "客服中心";
        case "Game":
          return "遊戲介紹";
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getNewsQueryAll();
    },

    getNewsQueryAll() {
      //列表
      this.$store.dispatch("news/newsQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async getNewsClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: this.searchData.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getNewsQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "ContentEdit",
        params: { searchData: this.searchData }
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "ContentEdit",
        params: { id: item.newsId, searchData: this.searchData }
      });
      return;
    },

    //*----預覽

    previewItem(item) {
      switch (this.projectId) {
        case "omp_dk":
          switch (this.$route.params.module) {
            case "service":
              window.open(
                this.url + "Service/Index?sid=" +
                  item.newsId +
                  "&preview=1"
              );
              break;
            case "Game":
              window.open(
                this.url + "Games/Index?gid=" +
                  item.newsId +
                  "&preview=1"
              );
              break;
          }
          break;
      }
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("news/newsDelete", item);
        }
      });
    },
    checkPermission(permission) {
      // let userPermissionList = this.$store.state.loginUserPermission;
      // let num = -1;
      // let isCheck = false;
      // permission.split(",").some(element => {
      //   num = userPermissionList.indexOf(element);
      //   switch (num) {
      //     case -1:
      //       isCheck = false;
      //       break;
      //     default:
      //       isCheck = true;
      //       break;
      //   }
      //   if (isCheck) return true;
      // });
      return utils.checkByUserPermission(permission);
    },
    FormatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    }
  }
};
</script>
