/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import define from '@/views/projects/FC/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcPlayerSetting.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcPlayerSetting.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcPlayerSetting.create(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.fcPlayerSetting.queryAll(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    res.data.result.datas.forEach(item => {
                        items.push({
                            '玩家UID': item.playUId,
                            '控管類型': define.controlStatus[item.blockType],
                            '控管期限': new Date(item.blockEndTime).getFullYear() === 2200 ? '永久' : item.blockEndTime ? utils.formatTime(item.blockEndTime, 'YYYY/MM/DD HH:mm:ss') : '',
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家控管設定列表.xlsx`,
                        '玩家控管設定'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
