/* eslint-disable */
import api from '@/assets/js/app-api';
import define from '@/views/projects/NMJ/_Define';

const state = {
    query: [],
    queryAll: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    bannerQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjWebBanner.query(data).then(res => {
                console.log(res.data)
                if (res.data.status == "1") {
                    commit('setBannerQuery', res.data.result);
                    resolve(res.data.result);
                }
                else reject(res.data.message);
            })
        })
    },
    bannerQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjWebBanner.queryAll(data).then(res => {
                console.log(res.data)
                if (res.data.status == "1") {
                    commit('setBannerQueryAll', res.data.result);
                    resolve(res.data.result);
                }
                else reject(res.data.message);
            })
        })
    },
    bannerCreate({ commit }, data) {
        api.nmjWebBanner.create(data).then(res => {
            commit('setResponse', res.data);
            return res.data;
        })
    },
    bannerUpdate({ commit }, data) {
        api.nmjWebBanner.update(data).then(res => {
            commit('setResponse', res.data);
            return res.data;
        })
    },
    bannerDelete({ commit }, data) {
        api.nmjWebBanner.delete(data).then(res => {
            commit('setResponse', res.data);
            return res.data;
        })
    },
    bannerImgList({ commit }, data) {
        api.nmjWebBanner.imgList(data).then(res => {
            if (res.data.status == "1") {
                commit('setImgList', res.data.result.imgUrlList);
            }
        })
    },
}

// mutations
const mutations = {
    setBannerQuery(state, bannerQuery) {
        state.query = bannerQuery;
    },
    setBannerQueryAll(state, bannerQueryAll) {
        bannerQueryAll.datas.forEach(banner =>{
            banner.isLinkOpen = parseInt(banner.isLinkOpen);
        })
        state.queryAll = bannerQueryAll;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setImgList(state, imgList) {
        state.imgList = imgList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}