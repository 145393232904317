<template>
    <div class="mymodal" ref="PopForm">
        <div style="width:800px;margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box box-info">
                <div class="box-header with-border">
                    <h3 class="box-title">匯入發票</h3>
                    <button type="button" class="close" @click="Close"
                        :disabled="(this.uploadST == 1 || this.uploadST == 2)"><span aria-hidden="true">×</span></button>
                </div>
                <div class="form-horizontal">
                    <div class="box-body">
                        <div class="form-group">
                            <label>請選擇要匯入的檔案</label>
                            <input type="file" accept=".xls,.xlsx" ref="importData" :disabled="uploadST != 0">
                            <span class="warning">資料中一但有其中一筆有問題，則整批失敗</span>
                        </div>
                    </div>
                    <div class="box-footer">
                        <a href="/uploads/發票匯入範例.xlsx" class="btn btn-block btn-success btn-cog"
                            style="display: inline-block;        width: 120px;        margin-right: 15px;" type="button"
                            download>發票匯入範例</a>
                        <button type="button" class="btn" style="margin-right: 15px;" @click="Close"
                            :disabled="(this.uploadST == 1 || this.uploadST == 2)">關閉</button>
                        <button type="button" class="btn btn-info" @click="readExcel"
                            :disabled="uploadST != 0">確定匯入</button>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
import xlsx from "xlsx";
import FileReaderPromise from "@/assets/js/Tool/FileReaderPromise.js";
import {
    Get商品明細總計,
    Get發票聯別And類型,
    發票種類Enum,
    稅別Enum, 取得發票單筆資料檢查器, InvoiceAPI, 四捨五入, 取得商品檢查器,
    發票聯別Enum,
} from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
import { Validator } from "@/assets/js/Tool/Validator.js";
export default {
    data() {
        return {
            uploadST: 0//0:選擇檔案 1:解析檔案 2:上傳檔案中
        };
    },
    computed: {
    },
    mounted() {
        $(this.$refs.PopForm).hide();
    },
    methods: {
        Open() {
            this.uploadST = 0;
            this.$refs.importData.value = "";
            $(this.$refs.PopForm).show();
            $(this.$refs.loading).hide();
        },
        Close() {
            $(this.$refs.PopForm).hide();
        },
        async readExcel() {
            let datas = [];
            let count = 0;
            try {
                this.uploadST = 1;//解析檔案
                let file = await FileReaderPromise(this.$refs.importData, {
                    analyze: function (workbook) {
                        let sheet = workbook.SheetNames[0];
                        return xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
                            raw: false,//所有欄位都轉為字串
                        }); //生成json表格內容
                    }
                });
                for (let i of file.analyzeData) {
                    count++;
                    let 發票開立時間 = i["發票開立時間*"] ? i["發票開立時間*"] : false;
                    if (!發票開立時間) {
                        throw `第${count}筆，發票開立時間錯誤`;
                    }
                    let 發票種類 = i["發票種類*"] && 發票種類Enum[i["發票種類*"]] ? 發票種類Enum[i["發票種類*"]] : false;
                    if (!發票種類) {
                        throw `第${count}筆，發票種類錯誤`;
                    }
                    let { 聯別: 發票聯別, 類型: 發票類別 } = Get發票聯別And類型(發票種類);
                    let 營業稅別 = i["營業稅別*"] && 稅別Enum[i["營業稅別*"]] ? 稅別Enum[i["營業稅別*"]] : false;
                    if (!營業稅別) {
                        throw `第${count}筆，營業稅別錯誤`;
                    }

                    let data = {
                        發票日期: 發票開立時間,
                        發票聯別,
                        發票類別,
                        稅別: 營業稅別,
                        稅額: i["營業稅*"] ? i["營業稅*"] : "",
                        載具條碼: i["載具條碼"] ? i["載具條碼"] : "",
                        愛心碼: i["愛心碼"] ? i["愛心碼"] : "",
                        統一編號: i["公司統編"] ? i["公司統編"] : "",// 22099131
                        買方: i["公司名稱"] ? i["公司名稱"] : "",
                        備註: i["備註"] ? i["備註"] : "",
                        明細: [],
                    };
                    data.銷售總額 = 0;
                    for (let j = 1; j < 100; j++) {//最多99筆
                        let 品名 = i[`品名(${j})`];
                        let 數量 = i[`數量(${j})`];
                        let 單價 = i[`單價(${j})`];
                        if (品名 === undefined && 數量 === undefined && 單價 == undefined) {
                            continue;
                        }
                        let 商品檢查error = 取得商品檢查器(new Validator(), 品名, 數量, 單價, (j - 1)).start();
                        if (商品檢查error) {
                            throw `第${count}筆，${商品檢查error}`;
                        }
                        品名 = (品名).toString().trim();
                        數量 = (數量).toString().trim();
                        單價 = (單價).toString().trim();

                        data.明細.push({
                            品名: 品名,
                            數量: 數量,
                            單價: 單價,
                            金額: 四捨五入(parseInt(數量), parseFloat(單價))
                        });
                        // data.銷售總額 = data.銷售總額 + 四捨五入(parseInt(數量), parseFloat(單價));
                    }
                    if (data.發票聯別 == 發票聯別Enum.二聯) {
                        data.銷售總額 = Get商品明細總計(data.明細) - parseInt(data.稅額);
                    } else {
                        data.銷售總額 = Get商品明細總計(data.明細);
                    }

                    data.發票金額 = data.銷售總額 + parseInt(data.稅額);
                    let errorMsg = 取得發票單筆資料檢查器(new Validator(), data).start();
                    if (errorMsg) {
                        throw `第${count}筆，${errorMsg}`;
                    }
                    datas.push(data);
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.uploadST = 0;
                this.$refs.importData.value = "";
                return;
            }
            try {
                this.uploadST = 2;//上傳檔案
                let message = InvoiceAPI(await api.IPSSetting.AddManualInvoice(datas));
                throw message = message ? message : "成功";
            } catch (e) {
                try {
                    let msg = '';
                    let error = JSON.parse(e);
                    for (let i of error) {
                        msg = msg + `第${i.資料行數 + 1}筆，${i.錯誤資訊}<BR>`;
                    }
                    this.$eventBus.$emit("showAlert", msg);
                } catch (parseJson失敗) {
                    // console.error("parseJson失敗", e);
                    this.$eventBus.$emit("showAlert", e);
                }
            }
            this.$refs.importData.value = "";
            this.uploadST = 0;
        }
    }
}
</script>
<style scoped>
.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.mymodal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

.warning {
    color: red;
}
</style>