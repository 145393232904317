/* eslint-disable */
import api from '@/assets/js/app-api';
import dfn from "@/views/projects/xin/_VideoDefine";

// actions
const actions = {
    精華影片列表查詢({commit},data){ return callAPI.call(this, dfn.permission.精華影片列表查詢, data) },
    精華影片上下架({commit},data){ return callAPI.call(this, dfn.permission.精華影片上下架, data) },
}

function callAPI(command, data = {}){
    return new Promise((resolve, reject) => {
        if (!this._vm.$user.hasPermission(command)){
            let msg = `使用者無開通「${command}」API權限`;
            this._vm.$eventBus.$emit('showAlert', msg);
            reject(msg);
        }
        api.XinStarsTw
            .post({permission:command,lang:"zh-TW", data:data})
            .then(res => {
            if (res.data.status === '1'){
                resolve(res.data.result);
            } else {
                reject(res.data.message);
            }
        }).catch( err => {
            this._vm.$eventBus.$emit('showAlert', err.message);
            reject(err.message);
        });
    });
}

export default {
    namespaced: true,
    actions,
}
