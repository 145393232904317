<template>
  <div>
    <div ref="alertBox" class="modal fade" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" type="button" @click="closeAlert">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> {{ alert.title }}
            </h4>
          </div>
          <div class="modal-body">
            <p style="white-space: pre-line;">{{ alert.message }}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" type="button" @click="closeAlert">
              {{ $t(`確定`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div ref="confirmBox" class="modal fade" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" type="button" @click="closeConfirm">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> {{ confirm.title }}
            </h4>
          </div>
          <div class="modal-body">
            <p style="white-space: pre-line">{{ confirm.message }}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" type="button" @click="doConfirm"> {{ $t(`確定`) }}</button>
            <button class="btn btn-default pull-right" type="button" @click="closeConfirm"> {{ $t(`關閉`) }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 使用方式：
 *  1. 只有一顆確認鈕
 * 	this.$eventBus.$emit("showAsyncConfirm", "message", "title", () => {
 *    //確定按鈕按下後，要執行的事
 *	});
 *  2. 確認跟關閉鈕
 * 	this.$eventBus.$emit("showAsyncConfirm", "message", "title", () => {
 *    //確定按鈕按下後，要執行的事
 *	}, () => {
 *    //關閉按鈕按下後，要執行的事
 *	}); 
 */
export default {
  components: {
  },
  data() {
    return {
      alert: {
        title: "",
        message: "",
        fn: null,
      },
      confirm: {
        title: "",
        message: "",
        closeFn: null,
        confirmFn: null,
      },
    };
  },
  mounted() {
    this.$eventBus.$on("showAsyncAlert", this.showAlert);
    this.$eventBus.$on("showAsyncConfirm", this.showConfirm);
    this.$eventBus.$on("showAsyncErrorCodeAlert", this.showAsyncErrorCodeAlert);
  },
  methods: {
    showAsyncErrorCodeAlert(errCode, 翻譯參數 = {}, title = "提示", fn = null) {
      let msg = `${this.$t(errCode, 翻譯參數)}(${errCode})`;
      this.showAlert(msg, title, fn)
    },
    showAlert(message, title, fn = null) {
      $(this.$refs.alertBox).modal("show");
      title = title ? title : "提示";
      if (title == "提示") {
        title = this.$t(title);
      }
      this.alert.title = title;
      this.alert.message = message;
      this.alert.fn = fn;
    },
    closeAlert() {
      $(this.$refs.alertBox).modal("hide");
      if (this.alert.fn) {
        this.alert.fn();
      }
    },
    showConfirm(message, title, confirmFn = null, closeFn = null) {
      $(this.$refs.confirmBox).modal("show");
      this.confirm.title = title ? title : "提示";
      this.confirm.message = message;
      this.confirm.confirmFn = typeof (confirmFn) == "function" ? confirmFn : null;
      this.confirm.closeFn = typeof (closeFn) == "function" ? closeFn : null;
    },
    closeConfirm() {
      $(this.$refs.confirmBox).modal("hide");
      if (this.confirm.closeFn) {
        this.confirm.closeFn();
      }
    },
    doConfirm() {
      $(this.$refs.confirmBox).modal("hide");
      if (this.confirm.confirmFn) {
        this.confirm.confirmFn();
      }
    }
  },
  destroyed() {
    this.alert.fn = null;
    this.confirm.fn = null;
    this.$eventBus.$off("showAsyncAlert", this.showAlert);
    this.$eventBus.$off("showAsyncConfirm", this.showConfirm);
  }
};
</script>