<template>
  <div id="Survey">
    <section class="content-header">
      <h1>滿意度調查</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>滿意度調查</li>
        <li class="active">滿意度調查</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group clearfix" style="margin-right: 0px">
              <label class="control-label">建立日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.end_time"
                  :propStartDate.sync="searchData.start_time"
                  v-on:restList="resetDataList()"
                />
              </div>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model.trim="searchData.type"
                @change="resetDataList()"
              >
                <option value="請選擇類別" v-if="selectEventList.length < 1">
                  請選擇類別
                </option>
                <option
                  :value="item"
                  v-for="(item, index) in selectEventList"
                  :key="index"
                  v-else
                >
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control select-title"
                v-model.trim="searchData.survey_id"
                @change="resetDataList()"
              >
                <option value="0" v-if="selectTypeList.length < 1">
                  請選擇標題
                </option>
                <option
                  :value="item.id"
                  v-for="(item, index) in selectTypeList"
                  :key="index"
                  v-else
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model.trim="searchData.score"
                @change="resetDataList()"
              >
                <option value="-1">全部分數</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋關鍵字"
                v-model.trim="searchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
            <div class="form-group fr w100-md mt-15-md">
              <button
                class="btn btn-success w100-md"
                type="button"
                v-if="total > 0"
                @click="exportOrders()"
              >
                匯出
              </button>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th>類別</th>
                  <th>標題</th>
                  <th>分數</th>
                  <th style="width: 30%;">建議</th>
                  <th>送出網址</th>
                  <th>建立日期</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="6">查詢中</td>
                </tr>
                <template v-else-if="total > 0">
                  <tr v-for="(item, index) in surveyList" :key="index">
                    <td>{{ item.type }}</td>
                    <td>{{ filterIdToName(item.survey_id) }}</td>
                    <td>{{ item.score }}</td>
                    <td>{{ item.suggest }}</td>
                    <td>{{ item.source_url }}</td>
                    <td>{{ item.ctime }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="6">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.page_size"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
            v-if="surveyList.length > 0"
          />
        </div>
      </div>
    </section>

    <form
      id="exportFile"
      :action="exportUrl"
      enctype="application/x-www-form-urlencoded"
      method="post"
    >
      <input type="hidden" name="type" :value="searchData.type" />
      <input type="hidden" name="survey_id" :value="searchData.survey_id" />
      <input type="hidden" name="score" :value="searchData.score" />
      <input type="hidden" name="page" :value="searchData.page" />
      <input type="hidden" name="page_size" :value="searchData.page_size" />
      <input type="hidden" name="start_time" :value="searchData.start_time" />
      <input type="hidden" name="end_time" :value="searchData.end_time" />
    </form>
  </div>
</template>

<style scoped>
#Survey .top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}
@media (min-width: 992px) {
  #Survey .top-box .select-group {
    width: auto;
    min-width: 150px;
    margin-right: 10px;
  }
}
</style>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";

export default {
  components: {
    Pagination,
    DateRangePicker,
  },
  name: "app",
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      projectId: this.$store.state.projectId,
      searchData: {
        type: "請選擇類別",
        survey_id: 0,
        score: -1,
        page: 1,
        page_size: 20,
        start_time: new moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD 00:00:00"),
        end_time: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      selectEventList: [],
      selectTypeList: [],
      surveyList: [],
      surveyId: "",
      surveyTitle: "",
      exportUrl: process.env.VUE_APP_VOTEWEB_PATH + "/api_survey/export_list",
    };
  },
  watch: {
    selectEventList(val) {
      if (val.length > 0 && this.selectTypeList.length > 0) {
        this.getDataList();
      }
    },
    selectTypeList(val) {
      if (val.length > 0 && this.selectEventList.length > 0) {
        this.getDataList();
      }
    },
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getSelectEvents();
    this.getSelectTypes();
  },
  methods: {
    getSelectEvents() {
      this.$store.dispatch("newtalk/surveyEvents").then((res) => {
        if (res.isSuccess) {
          this.selectEventList = res.data;

          // 預設最新一筆
          let length = this.selectEventList.length;
          this.searchData.type = this.selectEventList[length - 1];
          this.$forceUpdate();
        }
      });
    },
    getSelectTypes() {
      this.$store.dispatch("newtalk/surveyTypes").then((res) => {
        if (res.isSuccess) {
          this.selectTypeList = res.data;

          // 預設最新一筆
          let length = this.selectTypeList.length;
          let id = this.selectTypeList[length - 1].id;
          let title = this.selectTypeList[length - 1].title;
          this.surveyTitle = title;
          this.searchData.survey_id = id;
          this.$forceUpdate();
        }
      });
    },
    filterIdToName(id) {
      let datas = this.selectTypeList.filter(function (item) {
        return item.id == id;
      });

      let title = datas.length > 0 ? datas[0].title : "";
      if (title) return title;
    },
    getDataList() {
      this.loading = true;
      this.$store
        .dispatch("newtalk/surveyQueryAll", this.searchData)
        .then((res) => {
          if (res.isSuccess) {
            this.total = res.data.counts;
            this.loading = false;

            this.surveyList = res.data.list;
            this.$forceUpdate();
            if (this.$refs.pagination) {
              this.$refs.pagination.setPage(this.searchData.page, this.total);
            }
          }
        });
    },
    resetDataList() {
      this.searchData.page = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.page_size = pageSize;
      this.getDataList();
    },
    exportOrders() {
      document.getElementById("exportFile").submit();
    },
  },
};
</script>