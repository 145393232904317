<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>{{ id ? "編輯" : "新增" }}消息</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li>最新消息列表</li>
        <li class="active">{{ id ? "編輯" : "新增" }}消息</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增" }}內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 類別：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option v-for="(item, index) in newsTypes.datas" :key="index" :value="item.classTypeId">
                      {{item.className}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 公告標題：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputTitle" class="form-control" required
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 置頂：
                </label>
                <div class="col-sm-10">
                  <div class="radio-inline">
                    <iCheckRedioButton checkValue="1" v-model.number="edit.isTop" message="是" name="radioMode" />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton checkValue="0" v-model.number="edit.isTop" message="否" name="radioMode" />
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發布時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <!-- <div class="radio">
                      <iCheckRedioButton checkValue="0" v-model="edit.isStop" message="上架" name="radioMode" />
                    </div> -->
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="local.publishTime"/>
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input id="publishTime" class="form-control timepicker" type="text" />
                    </div>
                  </div>
                  <!-- <div class="radio">
                    <iCheckRedioButton checkValue="1" v-model="edit.isStop" message="下架" name="radioMode" />
                  </div> -->
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內文：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce v-model="edit.detailTopInfo" :propLang="editLang" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="button" @click="Save(true)" class="btn btn-success btn-lg fa fa-eye" style="margin-right: 10px;">
                  預覽
                </button>
                <button type="button" @click="Save(false)" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <pre>{{ edit }}</pre>
      <pre>{{ detailNewsLang[0] }}</pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import moment from "moment";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    DatePicker,
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: !!this.id,
      editLang: "zh-tw",
      edit: {
        newsId: utils.getTimeId(), //公告Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        lang: "zh-tw", // 語系
        newsDetailId: "", // 內容Id
        detailTopTitle: "", //主旨
        detailTopInfo: "", // 內容
        isStop: 0, // 發佈
        isTop: 0, // 置頂
        isNowOn: 0, //0:參考startTime ; 1:即日起
        publishTime: new moment().add(1, "day").format("YYYY-MM-DDT12:00:00"),
        startTime: new moment().add(1, "day").format("YYYY-MM-DDT12:00:00"),
        endTime: new moment().add(1, "day").format("YYYY-MM-DDT12:00:00"),
      },
      local: {
        publishTime: "",
        startTime: "",
        endTime: "",
      },
      langTypes: {},
      timeStringOption: {timeStyle: "short"},
      timepickerOption: {},
      detailNewsLang: [{
        lang: "zh-tw",
        title: "",
        info: "",
      }],
      imgList: [],
      isPreview: false,
      picLimit: "圖片建議尺寸 350 * 250。",
    };
  },
  created() {
    this.GetAllLang();
  },
  mounted() {
    window.v = this;
    let that = this;

    this.timepickerOption.showInputs = false;
    $(".timepicker").timepicker(this.timepickerOption);
    $(".timepicker").on("change", function(){ that.updateTime() } );

    if (this.edit.projectId == "omp_xinbo") {
      this.$store.dispatch("nmjWebNews/newsQueryAll", {
        classTypeId: "",
        module: "news",
        isDelete: 0,
        isStop: 0,
      });
    }

    if (this.isEdit) this.GetAnnouncement();
    else this.initTime();
  },
  computed: {
    ...mapState({
      newsTypes: (state) => state.nmjClassTypes.queryAll,
      news: (state) => state.nmjWebNews.query,
      response: (state) => state.nmjWebNews.response,
    }),
  },
  watch: {
    "edit.detailTopTitle": function(value){ this.detailNewsLang[0].title = value; },
    "edit.detailTopInfo": function(value){ this.detailNewsLang[0].info = value; },
    "local.publishTime": function(){ this.updateTime() },

    news() {
      this.edit = this.news;
      this.initTime();
    },
    response(value) {
      if (value.status == "1") {
        if (this.isPreview) this.preview();
        else {
          this.$eventBus.$emit("showAlert", "儲存成功!!");
          this.$router.push({
            name: "NmjWebNews",
            params: {
              searchData: this.$route.params.searchData,
            },
          });
        } 
      }
      else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    },
  },
  methods: {
    initTime() {
      this.local.publishTime = this.edit.publishTime;
      $("#publishTime").val(this.global.getTime(this.local.publishTime));
      this.updateTime();
    },
    updateTime() {
      this.edit.publishTime = new Date(`${this.local.publishTime} ${$("#publishTime").val()} UTC`);
      this.edit.startTime = new Date(`${this.local.publishTime} ${$("#publishTime").val()} UTC`);
      this.edit.endTime = new Date(`${this.local.publishTime} ${$("#publishTime").val()} UTC`);
    },
    //取得該公告資料
    GetAnnouncement() {
      this.$store.dispatch("nmjWebNews/newsQuery", {
        newsId: this.$route.params.id,
      });
    },

    GetAllLang() {
      this.$store.dispatch("nmjClassTypes/moduleQueryAll", {});
    },

    //*----預覽
    preview() {
      let width = 1080;
      let height = 840;
      let left = (screen.width - width) / 2;
      let top = (screen.height - height) / 2;
      // console.log(width, height, left, top)
      window.open(
        `${process.env.VUE_APP_NMJWEB_PATH}/News/New?id=${this.edit.newsId}&preview=1`,
        "preview",
        `popup,top=${top},left=${left},width=${width},height=${height}`
      );
    },
    
    //*---- 返回
    backList() {
      this.$router.push({
        name: "NmjWebNews",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },

    formatTime: function (t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },

    //送出
    async Save(isPreview) {
      this.isPreview = isPreview;
      // this.SetLangData(this.editLang);
      this.edit.isStop = parseInt(this.edit.isStop);
      
      if (this.edit.classTypeId == "") {
        return this.$eventBus.$emit("showAlert", "請選擇分類");
      }

      if (this.edit.detailTopTitle == "") {
        return this.$eventBus.$emit("showAlert", "主旨不能為空");
      }

      if (this.edit.detailTopInfo == "") {
        return this.$eventBus.$emit("showAlert", "內容不能為空");
      }

      if (!this.edit.publishTime) {
        return this.$eventBus.$emit("showAlert", "發佈時間不可為空");
      }
      
      if(this.edit.isStop == 1) {
        this.edit.isTop = 0
      }

      if (this.isEdit) {
        this.$store.dispatch("nmjWebNews/newsUpdate", {
          master_model: this.edit,
          detail_model: this.detailNewsLang,
        });
      } else {
        this.$store.dispatch("nmjWebNews/newsCreate", {
          master_model: this.edit,
          detail_model: this.detailNewsLang,
        });
        this.isEdit = true;
      }
    },
  },
};
</script>