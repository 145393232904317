<template>
  <div>
    <section class="content-header">
      <h1>道具列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>序號管理</li>
        <li class="active">道具列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="OpenSetting()" class="btn btn-block btn-warning btn-cog" type="button">
              <i class="fa fa-plus"></i> 新增道具
            </button>
            <button @click="ItemsType()" class="btn btn-block btn-warning btn-cog" type="button">
              道具種類設定
            </button>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button class="btn btn-default" type="button" v-on:click="clickSearch()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ exchangeItems.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 10%;">道具類別代碼</th>
                  <th class="text-nowrap" style="width: 10%;">道具類別名稱</th>
                  <th class="text-nowrap" style="width: 7%;">道具代碼</th>
                  <th class="text-nowrap" style="max-width: 15%">道具名稱</th>
                  <th class="text-nowrap" style="max-width: 15%;">道具說明</th>
                  <th class="text-nowrap" style="max-width: 15%;">說明</th>
                  <th class="text-nowrap" style="width: 5%;">狀態</th>
                  <th class="text-nowrap" style="width: 15%;">建立時間</th>
                  <th class="text-nowrap text-center" style=" width:5%;">功能</th>
                </tr>
                <template v-if="exchangeItems.total > 0">
                  <tr :key="item.id" v-for="item in exchangeItems.datas">
                    <td align="center">{{ item.itemTypeCode || '不分類' }}</td>
                    <td align="center">{{ item.itemTypeTitle || '不分類' }}</td>
                    <td align="center">{{ item.itemCode }}</td>
                    <td align="center">{{ item.itemTitle }}</td>
                    <td align="center">{{ item.itemInfo }}</td>
                    <td align="center">{{ item.itemNote }}</td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td align="center">{{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">
                      <button @click="OpenSetting(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <!--<button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                      >刪除</button>-->
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="9">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="exchangeItems.total > 0">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="exchangeItems.total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <ItemsEdit ref="ItemsEdit" v-on:refreshList="GetItemsQueryAll" />
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination';
import ItemsEdit from '@/views/SNManager/ItemsEdit';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Pagination,
    ItemsEdit,
  },
  delimiters: ['${', '}'],
  name: 'app',
  props: {
    id: '',
  },
  data() {
    return {
      total: 0, //總筆數
      searchData: {
        keyword: '',
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, //每頁顯示筆數
      },
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetItemsQueryAll();
  },
  computed: {
    ...mapState({
      exchangeItems: (state) => state.exchangeItems.queryAll,
    }),
  },
  watch: {
    response(value) {
      if (value.status == '1') {
        this.$eventBus.$emit('showAlert', '成功!!');
        this.GetItemsQueryAll();
      } else {
        if (value.message == '') this.$eventBus.$emit('showAlert', '失敗!!');
        else this.$eventBus.$emit('showAlert', value.message);
      }
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetItemsQueryAll();
    },

    GetItemsQueryAll() {
      //道具列表
      this.$store.dispatch(
        'exchangeItems/exchangeItemsQueryAll',
        this.searchData
      );
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetItemsQueryAll();
    },

    OpenSetting(item) {
      this.$refs.ItemsEdit.showExchangeItemsEdit(item);
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('exchangeItems/exchangeItemsDelete', item);
        }
      });
    },

    ItemsType() {
      this.$router.push({
        name: "ItemsType",
        params: { searchData: this.searchData }
      });
    },
  },
};
</script>
