<template>
  <div class="c-BannerPicker">
    <h3 class="text-center" v-if="this.$slots.default"><slot></slot></h3>
    <img class="banner-img" :src="edit.mainImgFullFileName" alt="" draggable="false"/>
    <div class="flex-menu mt-10">
      <div class="flex-1">
        <input accept="image/*" type="file" @change="onFileChange($event)" />
        <div class="help-block">
          <span class="text-red" v-text="imgHelp">圖片尺寸為 1400 * 400px，大小請勿超過 2Mb，選完要儲存！</span>
        </div>
      </div>
      <button class="btn btn-primary btn-save"><i class="fa fa-thumbs-up"></i> 儲存更新</button>
    </div>
  </div>
</template>

<style lang="scss">
  .c-BannerPicker{
    max-width: 840px;
    margin: 0 auto;

    .flex-menu{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .flex-1{
        flex:1;
      }
    }
    .btn-save{
      min-width:120px;
    }
    @media (max-width: 600px){
      .flex-menu{
        flex-direction: column;
      }
      .btn-save{
        width: 100%;
      }
    }

    .banner-img{
      max-height: 240px;
      max-width: 840px;
      width: 100%;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }
  }
</style>

<script>
export default {
  props:{
    imgHelp: "",
  },
  data() {
    return {
      edit:{
        mainImgFullFileName: "http://okevin.tw/wafun/images/service/service03.jpg"
      }
    }
  },
  methods:{
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },

    //圖片預覽
    preview(input) {
      if (input && input[0]) {
        let that = this;
        var reader = new FileReader();

        reader.onload = function(e) {
          that.edit.mainImgFullFileName = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },
  }
}
</script>