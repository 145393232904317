import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    detailQueryAll: [],
    detailQuery: [],
    detailImgList: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    albumQueryAll({ commit }, data) {
        api.album.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setalbum', res.data.result);
            }
        })
    },
    albumQuery({ commit }, data) {
        api.album.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setalbumQuery', res.data.result);
            }
        })
    },
    albumCreate({ commit }, data) {
        api.album.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    albumUpdate({ commit }, data) {
        api.album.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    albumDelete({ commit }, data) {
        api.album.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    albumUpdatevideo({ commit }, data) {
        api.album.updatevideos(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    albumUpdatevideo2({ commit }, data) {
        api.album.updatevideos(data).then(res => {
            commit('setResponse', res.data);
        })
    },

    // albumDetailQueryAll({ commit }, data) {
    //     api.albumDetail.queryAll(data).then(res => {
    //         if (res.data.status == "1") {
    //             commit('setDetailalbum', res.data.result);
    //         }
    //     })
    // },
    // albumDetailQuery({ commit }, data) {
    //     api.albumDetail.query(data).then(res => {
    //         if (res.data.status == "1") {
    //             commit('setDetailalbumQuery', res.data.result);
    //         }
    //     })
    // },
    // albumDetailImgList({ commit }, data) {
    //     api.albumDetail.imgList(data).then(res => {
    //         if (res.data.status == "1") {
    //             commit('setDetailalbumImgList', res.data.result.imgUrlList);
    //         }
    //     })
    // },
}

// mutations
const mutations = {
    setalbum(state, album) {
        state.queryAll = album;
    },
    setalbumQuery(state, albumQuery) {
        state.query = albumQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    // setDetailalbum(state, albumDetailQueryAll) {
    //     state.detailQueryAll = albumDetailQueryAll;
    // },
    // setDetailalbumQuery(state, albumDetailQuery) {
    //     state.detailQuery = albumDetailQuery;
    // },
    // setDetailalbumImgList(state, imgList) {
    //     state.detailImgList = imgList;
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}