<template>
    <div class="modal fade" ref="popForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button" @click="hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title">新增金額</h4>
                </div>
                <div class="modal-body">
                    <div class="form-horizontal">

                        <table>
                            <tr>
                                <td>
                                    <label class="control-label">
                                        <span class="text-red">*</span> 輸入金額：
                                    </label>
                                </td>
                                <td>
                                    <input class="form-control" type="text" v-model.trim="money" />
                                </td>
                            </tr>
                        </table>

                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" v-on:click="addOption">加入</button>
                    <button class="btn btn-default pull-right" type="button" @click="hide()">關閉</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            money: 0
        }
    },
    methods: {
        show() {
            $(this.$refs.popForm).modal("show");
            this.money = "";
        },
        hide() {
            $(this.$refs.popForm).modal("hide");
        },
        addOption() {
            this.$emit('addOption', this.money);
            this.hide();
        },
    }
}
</script>
