<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>交易歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>交易歷程</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <label class="control-label">交易日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    v-bind:propStartDate.sync="searchData.startTime"
                    v-bind:propEndDate.sync="searchData.endTime"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">訂單狀態：</label>
              <select
                class="selPaymentType form-control"
                required="required"
                v-model="searchData.orderStatus"
              >
                <option value="null">全部</option>
                <option
                  :value="item.value"
                  v-for="(item, index) in getCurrentPaymentStatusOptions"
                  :key="index"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label">訂單編號：</label>
              <input
                type="text"
                class="selPaymentType form-control"
                v-model="searchData.orderID"
              />
            </div>
            <div class="form-group">
              <label class="control-label">票券序號：</label>
              <input
                type="text"
                class="selPaymentType form-control"
                v-model="productSN"
              />
            </div>
            <div class="form-group">
              <label class="control-label">門號：</label>
              <input
                type="text"
                class="selPaymentType form-control"
                v-model="searchData.userId"
              />
            </div>
            <div class="form-group">
              <button
                @click="clickSearch"
                class="btn btn-default"
                type="button"
              >
                查詢
              </button>
            </div>
            <div class="form-group" v-if="orders.length > 0">
              <button
                @click="exportOrders"
                class="btn btn-success"
                type="button"
              >
                匯出
              </button>
            </div>
            <p class="data-num">
              <span>總筆數：</span>
              <span id="total" style="margin-right: 10px">{{ total }}</span>
              <span>不重複人數：</span>
              <span id="people" style="margin-right: 10px">{{ people }}</span>
              <span>總金額：</span>
              <span id="amount" style="margin-right: 10px">{{ amount }}</span>
            </p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="text-nowrap">訂單編號</th>
                  <th style="text-nowrap">門號</th>
                  <th class="text-nowrap">訂單狀態</th>
                  <th class="text-nowrap">優惠券配發狀態</th>
                  <th class="text-nowrap">總金額</th>
                  <th class="text-nowrap">建立日期</th>
                  <th class="text-nowrap">優惠券配發時間</th>
                  <th class="text-nowrap">發票資訊</th>
                  <th class="text-nowrap">訂單資訊</th>
                  <th class="text-nowrap">備註</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 10%"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="orders.length > 0">
                  <tr :key="item.id" v-for="item in orders">
                    <td class="text-center">{{ item.訂單編號 }}</td>
                    <td class="text-center">{{ item.玩家識別值 }}</td>
                    <td class="text-center">{{ GetStatus(item.訂單狀態) }}</td>
                    <td class="text-center">
                      {{ GetOrderResult(item.通知配點狀態) }}
                    </td>
                    <td class="text-center">{{ item.原始金額 }}</td>
                    <td class="text-center">
                      {{ formatTime(item.建立日期, "YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td class="text-center">
                      {{ formatTime(item.配點時間, "YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td class="text-center">
                      <button
                        v-if="item.發票號碼 != null"
                        @click="openInvoiceInfo(item)"
                        class="btn btn-info"
                      >
                        {{ item.發票號碼 }}
                      </button>
                      <button v-else class="btn btn-secondary" disabled>
                        未開立
                      </button>
                    </td>
                    <td class="text-center">
                      <button
                        @click="
                          openTicketDetail(item.訂單編號, item.通知配點狀態)
                        "
                        class="btn btn-info"
                      >
                        票券明細
                      </button>
                    </td>
                    <td class="text-center">{{ item.備註 }}</td>
                    <td class="text-center">
                      <button
                        v-show="isRequireCheck(item)"
                        @click="checkOrder(item)"
                        class="btn btn-primary"
                      >
                        重新驗證
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <TicketDetail ref="ticketDetail" />
        <InvoiceInfo ref="invoiceInfo" />
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import InvoiceInfo from "@/components/InvoiceInfo";
import TicketDetail from "@/views/projects/heti/TicketDetail";

export default {
  components: {
    Pagination,
    DateRangePicker,
    InvoiceInfo,
    TicketDetail,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      pageSize: 10,
      orderStatusOptions: [],
      productSN: "",
      searchData: {
        serviceID: "10004",
        paymentType: "ECPay",
        orderID: "",
        vendorOrderID: "",
        userId: "",
        orderStatus: null,
        givePointResult: -2,
        startTime: new moment().format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        startNo: 1,
        endNo: 10,
      },
    };
  },
  async created() {},
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.loginUser,
      orders: (state) => state.payment.orders,
      total: (state) => state.payment.total,
      people: (state) => state.payment.people,
      amount: (state) => state.payment.amount,
    }),
    getCurrentPaymentStatusOptions() {
      return [
        { text: "交易未完成(N)", value: "N" },
        { text: "交易成功(S)", value: "S" },
        { text: "交易失敗(F)", value: "F" },
      ];
    },
  },
  methods: {
    async onPageChange(pageIdx) {
      this.searchData.startNo = (pageIdx - 1) * this.pageSize + 1;
      this.searchData.endNo = pageIdx * this.pageSize;
      this.queryAll();
    },
    queryAll() {
      if (this.searchData.orderStatus == "null")
        this.searchData.orderStatus = null;

      let tmpSearchData = utils.deepCloneObj(this.searchData);
      if (tmpSearchData.orderID != "") {
        tmpSearchData.startTime = "";
        tmpSearchData.endTime = "";
      }
      this.$store.dispatch("payment/queryAll", tmpSearchData);
      this.$store.dispatch("payment/queryStatistics", tmpSearchData);
    },
    getOrderProduct() {
      this.$store
        .dispatch("payment/getOrderProducts", { productSN: this.productSN })
        .then((res) => {
          if (res.data.status == "1" && res.data.content.length > 0) {
            this.searchData.orderID = res.data.content[0].訂單編號;
            this.queryAll();
          } else {
            this.$eventBus.$emit("showAlert", "查無票券序號");
          }
        });
    },
    clickSearch() {
      if (this.productSN != "") this.getOrderProduct();
      else this.queryAll();
    },
    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      if (tmpSearchData.orderID != "") {
        tmpSearchData.startTime = "";
        tmpSearchData.endTime = "";
      }
      tmpSearchData.startNo = 0;
      tmpSearchData.endNo = 0;
      this.$store.dispatch("payment/export", tmpSearchData);
    },
    checkOrder(order) {
      let request = {
        GameType: "HETI",
        PaymentType: order.交易方式,
        OrderNumber: order.訂單編號,
        note: "後台重新驗證、操作者:" + this.user.employeeName,
      };
      this.$store.dispatch("payment/checkOrder", request).then((res) => {
        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", "操作成功");
          this.queryAll();
        } else {
          let code = res.data.stateCode;
          let msg = res.data.message;
          this.$eventBus.$emit("showAlert", "操作失敗(" + code + ")、" + msg);
        }
      });
    },
    checkPermission(permission) {
      return utils.checkByUserPermission(permission);
    },
    isRequireCheck(order) {
      if (order.訂單狀態 == "F") return false;
      if (order.通知配點狀態 == "-1" || order.通知配點狀態 == "2") return true;
      return false;
    },
    openTicketDetail(orderId, orderResult) {
      this.$refs.ticketDetail.showTicketDetailBox(orderId, orderResult);
    },
    openInvoiceInfo(info) {
      let invoiceInfo = {
        門號: info.門號,
        Email: info.Email,
        發票類別: info.發票類別,
        發票抬頭: info.發票抬頭,
        發票地址: info.發票地址,
        統一編號: info.統一編號,
        載具條碼: info.載具條碼,
        愛心條碼: info.愛心條碼,
        載具類別: info.載具類別,
        收件姓名: info.收件姓名,
      };

      this.$refs.invoiceInfo.showInvoiceInfoBox(invoiceInfo);
    },
    formatTime(time) {
      if (time == undefined) return "";
      return new moment(time).format("YYYY/MM/DD HH:mm:ss");
    },

    GetOrderResult(state) {
      switch (state) {
        case -1:
          return "未配發";
        case 1:
          return "已配發";
        case 2:
          return "配發中";
      }
    },

    GetStatus(value) {
      switch (value) {
        case "N":
          return "待確認";
        case "S":
          return "成功";
        case "F":
          return "失敗";
      }
    },
  },
};
</script>

<style scoped>
.top-box .form-group {
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}

.top-box .input-group {
  min-width: 250px;
}

.selPaymentType {
  width: 150px;
}
</style>