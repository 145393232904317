<template>
  <div>
    <div class="modal fade" ref="playerSettingContent">
      <div class="modal-dialog">
        <div class="modal-content" v-if="tab == 1">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ player ? (unban ? $t('玩家解鎖') : $t('玩家控管')) : $t('執行大量停權') }}</h4>
          </div>
          <form class="form-horizontal" name="playerSettingForm" @submit.prevent="submit">
            <div class="modal-body">
              <div class="table-responsive mt-0">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th>{{ unban ? $t('解鎖對象') : $t('控管對象') }}</th>
                      <td v-if="player">{{ data.玩家暱稱[0] }}</td>

                      <td v-else>
                        <!-- <input type="text" v-model.trim="playerList" placeholder="輸入玩家暱稱，中間用逗號分隔" class="form-control" /> -->
                        <button type="button" class="btn btn-primary fa fa-file-alt mt-0" @click="importSN()">
                          {{ $t('匯入名單') }}
                        </button>
                        <!-- <button type="button" v-if="importList.length > 0" class="btn btn-success fa fa-list" @click="checkContent(data, true)" style="margin-left:.35em;">
                          查看名單
                        </button> -->
                        <span style="padding-left: 8px">
                          {{ importFileName }}
                        </span>
                        <div v-if="pagedTable.length > 0">
                          <table class="table table-bordered mt-10 mb-10">
                            <thead>
                              <tr class="bg-gray-light">
                                <th colspan="10" style="padding-block: .25em;">{{ $t('玩家名單') }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="row in pagedTable">
                                <td v-for="col in row" style="padding:.1em .5em;width:25%">{{ col }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="text-center" v-show="pageTotal > 1">
                            <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal"
                              v-on:pageMethod="onPageChange" />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr v-if="player">
                      <th>{{ $t('綁定門號') }}</th>
                      <td>{{ player.門號綁定 ? player.門號綁定 : "-" }}</td>
                    </tr>
                    
                    <tr>
                      <th>{{ unban ? $t('解鎖類型') : $t('控管類型') }}</th>
                      <td>
                        <iCheckRedioButton v-model.number="data.動作" checkValue="0" :message="$t('停權')" name="blockType"
                          class="radio-inline" />
                        <iCheckRedioButton v-model.number="data.動作" checkValue="1" :message="$t('禁言')" name="blockType"
                          class="radio-inline ml-25" />
                        <iCheckRedioButton v-model.number="data.動作" checkValue="2" message="限制頭圖上傳" name="blockType"
                          class="radio-inline ml-25" />
                        <iCheckRedioButton v-model.number="data.動作" checkValue="3" message="限制兌換" name="blockType"
                          class="radio-inline ml-25" />
                      </td>
                    </tr>

                    <!-- <tr data-title="限制">
                      <th>{{ unban ? "限制解除" : "限制鎖定" }}</th>
                      <td>
                        <div class="checkbox-inline mr-10">
                          <label class="check">
                              <iCheckCheckBox 
                                  v-bind:propChecked="data.限制"
                              /> {{ unban ? "星幣解除禁止" : "星幣轉出鎖定" }} 
                          </label>
                        </div>
                        <div class="checkbox-inline">
                            <label class="check">
                                <iCheckCheckBox 
                                    v-bind:propChecked="data.限制"
                                /> {{ unban ? "雙平台購買解除禁止" : "雙平台購買鎖定" }} 
                            </label>
                        </div>
                      </td>
                    </tr> -->

                    <tr>
                      <th>{{ unban ? '解鎖' : '期限' }}</th>
                      <td v-if="unban">
                        <iCheckRedioButton v-model="data.解鎖" :checkValue="true" :message="$t('立即解鎖')" name="unban"
                          class="radio radioCenter" />
                      </td>
                      <td v-else>
                        <template v-if="data.動作 == 0">
                          <div class="radio-group">
                            <iCheckRedioButton v-model.number="data.類型" checkValue="0" :message="''" name="timeType"
                              class="radio radioCenter" />
                            <input type="number" class="form-control" v-model.number="data.期限" min="1">
                            <span>{{ $t('天') }}</span>
                          </div>
                          <iCheckRedioButton v-model.number="data.類型" checkValue="2" message="永久停權"
                            name="timeType" class="radio radioCenter" />
                          <!-- <iCheckRedioButton v-model.number="data.類型" checkValue="1" :message="$t('暫時凍結_永久')"
                            name="timeType" class="radio radioCenter" />
                          <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('終止契約_永久')"
                            name="timeType" class="radio radioCenter" /> -->
                        </template>

                        <template v-if="data.動作==1">
                          <div class="radio-group">
                            <iCheckRedioButton v-model.number="data.類型" checkValue="0" :message="''" name="timeType"
                              class="radio radioCenter" />
                            <input type="number" class="form-control" v-model.number="data.期限" min="1">
                            <span>{{ $t('分鐘') }}</span>
                          </div>
                          <iCheckRedioButton v-model.number="data.類型" checkValue="2" :message="$t('永久禁言')"
                            name="timeType" class="radio radioCenter" />
                        </template>

                        <template v-if="data.動作==2">
                          <div class="radio-group">
                            <iCheckRedioButton v-model.number="data.類型" checkValue="0" :message="''" name="timeType"
                              class="radio radioCenter" />
                            <input type="number" class="form-control" v-model.number="data.期限" min="1">
                            <span>天</span>
                          </div>
                          <iCheckRedioButton v-model.number="data.類型" checkValue="2" message="暫時凍結(永久)"
                            name="timeType" class="radio radioCenter" />
                        </template>

                        <template v-if="data.動作==3">
                          <iCheckRedioButton v-model.number="data.類型" checkValue="2" message="暫時凍結(永久)"
                            name="timeType" class="radio radioCenter" />
                        </template>

                      </td>
                    </tr>

                    <tr>
                      <th>原因</th>
                      <td>
                        <textarea class="form-control" type="text" v-model.trim="data.原因"
                          :placeholder="data.動作 == 0 ? $t('必填') : $t('選填')" rows="3"
                          :required="data.動作 == 0"></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <pre>{{ player }}</pre> -->
            </div>
            <div class="modal-footer">
              <span class="ban-info-tip mr-5" v-if="player && data.動作 == 0  && player.suspensionTime * 1000 >= (Date.now())">
                {{ $t('停權中至X', [formatTimestamp(player.suspensionTime)]) }}
              </span>
              <span class="ban-info-tip mr-5" v-if="player && data.動作 == 1 &&  player.banSpeechTime * 1000 >= (Date.now())">
                {{ $t('禁言中至X', [formatTimestamp(player.banSpeechTime)]) }}
              </span>
              <span class="ban-info-tip mr-5" v-if="player && data.動作 == 2 &&  player.限制上傳頭圖時間 * 1000 >= (Date.now())">
                限制上傳頭圖(至 {{ formatTimestamp(player.限制上傳頭圖時間) }} )  <br>
              </span>
              <span class="ban-info-tip mr-5" v-if="player && data.動作 == 3 &&  player.限制兌換時間 * 1000 >= (Date.now())">
                限制兌換(至 {{ formatTimestamp(player.限制兌換時間) }} )
              </span>
              <button type="submit" class="btn btn-primary mb-5">{{ $t('確認送出') }}</button>
              <button type="button" class="btn btn-default pull-right" data-dismiss="modal">&emsp;{{ $t('取消')
                }}&emsp;</button>
            </div>
          </form>
        </div>
        
        <div class="modal-content" v-if="tab == 2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ $t('大量停權結果') }}</h4>
          </div>
          <div class="modal-body">
            <p style="display:flex; align-items:center; justify-content:space-between;">
              <span>
                <button class="btn btn-primary mr-10" @click="exportOrders()">{{ $t('匯出') }}</button>
                {{ $t('執行結果') }}: {{ resultSuccess }} / {{ resultTotal }}
              </span>
              <input type="text" v-model="filterString" class="form-control" :placeholder="$t('暱稱搜尋')"
                style="width:50%" />
            </p>
            <table class="table table-bordered mt-10 mb-10">
              <thead>
                <tr class="bg-gray-light">
                  <th colspan="10" style="padding-block: .25em;">{{ $t('玩家名單') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in resultPaged">
                  <td style="padding:.1em .5em;width:35%"><router-link
                      :to="{ name: 'NmjPlayerData', query: { nick: row.name1 } }" data-dismiss="modal">{{ row.name1
                      }}</router-link></td>
                  <td style="padding:.1em .5em;width:15%" :class="row.status1 == 1 ? 'text-green' : 'text-red'">{{
          row.status1 == 1 ? $t('成功') : $t('失敗') }}</td>
                  <td style="padding:.1em .5em;width:35%"><router-link
                      :to="{ name: 'NmjPlayerData', query: { nick: row.name2 } }" data-dismiss="modal">{{ row.name2
                      }}</router-link></td>
                  <td style="padding:.1em .5em;width:15%" :class="row.status2 == 1 ? 'text-green' : 'text-red'"
                    v-show="row.name2">{{ row.status2 == 1 ? $t('成功') : $t('失敗') }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal"
                v-on:pageMethod="onPageChange" />
            </div>
            <!-- <pre>{{ resultPaged }}</pre> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" data-dismiss="modal">&emsp;{{ $t('關閉')
              }}&emsp;</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <ImportData ref="ImportData" :title="$t('玩家')" :checks="checks" @importData="importData" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import ImportData from "@/views/projects/NMJ/ImportData";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DatePicker,
    iCheckRedioButton,
    ImportData,
    Pagination,
  },
  data() {
    return {
      player: null,
      unban: false,
      tab: 1,
      // playerList: "",
      data: {
        玩家暱稱: [], //大量停權玩家暱稱array帶入多筆暱稱
        動作: 0,
        類型: 2,
        原因: "",
        解鎖: false, //預設false,選擇解鎖改成true
        // 期限: 1,//動作1，解鎖false，類型為0時，才有作用(也就是禁言 控管 期限類型選擇輸入時間)
      },
      期限: 1,//動作1，解鎖false，類型為0時，才有作用(也就是禁言 控管 期限類型選擇輸入時間)
      resultData: {},
      filterString: "",
      pageNum: 1,
      pageSize: 5,
      pageTotal: 0,
      importFileName: "",
      importList: [],
      checks: ["玩家暱稱"],
    };
  },
  watch: {
    "filterString": function () { this.pageNum = 1 }
  },
  computed: {
    "playerTable": function () {
      let search = ""; //this.filterString.trim();
      let re = new RegExp(search, "i");
      let list = search == '' ?
        this.data.玩家暱稱 :
        this.data.玩家暱稱.filter(s => re.test(s));

      let arr = [];
      for (let i = 0, len = list.length; i < len;) {
        let row = [];
        for (let j = 0; j < 3; j++) {
          row.push(list[i++])
        }
        arr.push(row);
      }
      this.pageTotal = arr.length;
      return arr;
    },
    "pagedTable": function () {
      return this.playerTable.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    "resultTable": function () {
      let search = this.filterString.trim();
      let re = new RegExp(search, "i");
      let list = search == '' ?
        Object.keys(this.resultData) :
        Object.keys(this.resultData).filter(s => re.test(s));
      let arr = [];
      for (let i = 0, len = list.length; i < len; i += 2) {
        arr.push({
          name1: list[i],
          status1: 1 - this.resultData[list[i]],
          name2: list[i + 1] || null,
          status2: 1 - this.resultData[list[i + 1]] || null,
        });
      }
      this.pageSize = 10;
      this.pageTotal = arr.length;
      return arr;
    },
    "resultPaged": function () {
      return this.resultTable.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
    "resultTotal": function () {
      return Object.values(this.resultData).length;
    },
    "resultSuccess": function () {
      return Object.values(this.resultData).reduce((a, i) => a + (1 - i), 0);
    },
  },
  methods: {
    async showContent(player, unban, 預設勾選禁言 = false) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.tab = 1;
      this.player = player || null;
      this.unban = unban || false;
      this.data.解鎖 = this.unban;
      if (this.player) {
        this.data.玩家暱稱 = [player.nickName];
      }
      if (預設勾選禁言) {
        this.data.動作 = 1;
      }
      $(this.$refs.playerSettingContent).modal("show");
    },
    async showResult(resultData) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.tab = 2;
      this.resultData = resultData;
      $(this.$refs.playerSettingContent).modal("show");
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
    },
    importSN() {
      this.$refs.ImportData.showSerialNumberEdit();
    },
    importData(data, fileName) {
      console.log(data)
      this.data.玩家暱稱 = data.map(p => p.玩家暱稱.toString());
      this.importFileName = fileName;
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp * 1000).toLocaleString();
    },
    
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/exportControl", this.resultData);
    },
    submit() {
      this.$store.dispatch("nmjPlayerData/control", this.data).then(() => {
        $(this.$refs.playerSettingContent).modal("hide");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.radio-group>input {
  margin: 0 5px;
  max-width: 100px;
}

.radio-group>span {
  white-space: nowrap;
}

.ban-info-tip {
  line-height: 34px;
  vertical-align: middle;
  float: left;
}
</style>
