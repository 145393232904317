<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>服務範疇列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>服務範疇管理</li>
        <li class="active">服務範疇列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增服務範疇
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.classTypeId"
                @change="clickSearch"
              >
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in classTypes.datas"
                  :key="index"
                >{{item.className}}</option>
              </select>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ games.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th style="min-width: 150px;">分類</th>
                  <th style="min-width: 150px;">名稱</th>
                  <th style="min-width: 180px;">建立日期</th>
                  <th style="min-width: 65px;">狀態</th>
                  <th style="min-width: 150px;">動態排序</th>
                  <th style="min-width: 150px;">功能</th>
                </tr>
                <template v-if="games.total > 0">
                  <tr :key="item.id" v-for="item in games.datas">
                    <td align="center">{{ item.classTypeName }}</td>
                    <td align="center">{{ item.detailTopTitle }}</td>
                    <td align="center">{{ item.createTime }}</td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.sortId" />
                      <button
                        @click="updateGameOd(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                      >更新</button>
                    </td>
                    <td class="text-center text-nowrap">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="games.total > 0">
          <Pagination
            ref="pagination"
            :total="games.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數

      searchData: {
        // projectId: this.$store.state.projectId,
        module: "service",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10 //每頁顯示筆數
      },
      oldList: [],
      sortList: {}
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetGameClassTypes();
  },
  mounted() {},
  computed: {
    ...mapState({
      classTypes: state => state.classTypes.queryAll,
      games: state => state.game.queryAll,
      response: state => state.game.response
    })
  },
  watch: {
    classTypes() {
     if (this.classTypes.datas.length) {
        this.searchData.classTypeId = this.searchData.classTypeId ? this.searchData.classTypeId : this.classTypes.datas[0].classTypeId;
      }
      this.GetGameQueryAll();
    },
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetGameQueryAll();
    },
    games() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.games.total
      );
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetGameQueryAll();
    },

    GetGameQueryAll() {
      //列表
      this.$store.dispatch("game/gameQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async GetGameClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: this.searchData.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    async updateGameOd(item) {
      item.sortId = parseInt(item.sortId);
      item.isTurnPlay = parseInt(item.isTurnPlay);

      // 檢查排序值並重排
      await this.CheckSortIdList(item);
      let res = await this.$store.dispatch("game/gameUpdateListSort", this.sortList);
      if (res.status != "1") {
        this.$eventBus.$emit("showAlert", "更新失敗!");
      } else {
        this.$eventBus.$emit("showAlert", "更新成功!");
      }
      this.GetGameQueryAll();
    },
    async CheckSortIdList(item){
      // 拿掉分頁條件+針對上架 取得所有資料
      let newSerarch = Object.assign({},this.searchData);
      newSerarch.pageNum = 0;
      newSerarch.pageSize = 0;
      newSerarch.isStop = 0; 
      newSerarch.skipCommit = true; // 略過commit

      await this.$store.dispatch("game/gameQueryAll", newSerarch).then((res)=>{
        if(res.status == "1") {
           // 使用資料比對有無重複
           if(res.result.total > 0){
             this.oldList = res.result.datas;
           }
        }
      });

      // 判斷重複值
      const findSameData = this.oldList.find(data => 
        data.sortId == item.sortId && data.contentId != item.contentId
      );
      if(findSameData != null){
        const findOldData = this.oldList.find(data => data.contentId == item.contentId);
        // 要更新的Id舊排序值與重複Id排序值交換
        this.oldList.forEach(el => {
          if(el.contentId == findSameData.contentId){
            el.sortId = parseInt(findOldData.sortId);
          }
        });
      }
      this.oldList.forEach(el => {
        if(el.contentId == item.contentId){
          el.sortId = item.sortId;
        }
      });
      
      // 重排依照:排序值小-大, 建立日期新-舊
      this.oldList.sort(function(a, b){
        if(a.sortId === b.sortId){
          return b.createTime - a.createTime;     
        }
        return a.sortId - b.sortId;
      });
      this.oldList.every(async (newItem, index) =>{
        newItem.sortId = index+1;
      });
      this.sortList.skipCommit = true;
      this.sortList.sortDatas = this.oldList;
    },
    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetGameQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "WaninFundServiceEdit",
        params: { searchData: this.searchData }
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "WaninFundServiceEdit",
        params: { id: item.contentId, searchData: this.searchData }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("game/gameDelete", item);
        }
      });
    }
  }
};
</script>
