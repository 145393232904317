<template>
  <div id="NmjAnnouncementList">
    <section class="content-header">
      <h1>官方公告發送</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">官方公告發送</li>
      </ol>
    </section>
    <section class="content customer-service">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: searchData.typeName === '系統' }">
            <a
              aria-expanded="true"
              data-toggle="tab"
              href="#problem"
              @click="resetDataList('系統', -9)"
            >
              系統公告
            </a>
          </li>
          <li :class="{ active: searchData.typeName === '緊急' }">
            <a
              aria-expanded="false"
              data-toggle="tab"
              href="#report"
              @click="resetDataList('緊急', -9)"
            >
              緊急公告
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- 系統公告 -->
          <div
            class="tab-pane"
            :class="{ active: searchData.typeName === '系統' }"
          >
            <div class="top-box">
              <button
                @click="addContent()"
                class="btn btn-block btn-warning btn-add"
                type="button"
              >
                <i class="fa fa-plus"></i> 新增
              </button>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model.number="searchData.status"
                  @change="resetDataList('系統')"
                >
                  <option value="-9">所有狀態</option>
                  <option value="0">等待中</option>
                  <option value="1">已發送</option>
                  <option value="2">已過期</option>
                  <option value="-1">已刪除</option>
                </select>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered text-center min-1000">
                <tbody>
                  <tr class="bg-gray">
                    <th>編號</th>
                    <th style="min-width: 150px">發送時間</th>
                    <th style="min-width: 150px">截止時間</th>
                    <th>發送平台</th>
                    <th style="min-width: 200px">訊息主旨</th>
                    <th>訊息內容</th>
                    <th>狀態</th>
                    <th>操作者</th>
                    <th>功能</th>
                  </tr>
                  <tr v-if="loading"><td colspan="9">查詢中</td></tr>
                  <tr v-else-if="datas.length === 0"><td colspan="9">查詢無任何資料</td></tr>
                  <tr v-else v-for="(item, index) in datas" :key="index" :class="{ 'bg-gray-light': item.status === -1 }">
                    <td>{{ datas.length - index }}</td>
                    <td>{{ item.sendStartTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td>{{ item.sendEndTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td>{{ item.顯示平台 }}</td>
                    <td>{{ item.公告名稱 }}</td>
                    <td><button @click="checkContent(item)" class="btn btn-primary">查看</button></td>
                    <td>{{ define.announcementStatus[item.status] }}</td>
                    <td>{{ item.建立者 }}</td>
                    <td><button v-if="item.status !== -1" @click="deleteContent(item)" class="btn btn-danger">刪除</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 緊急公告 -->
          <div
            class="tab-pane"
            :class="{ active: searchData.typeName === '緊急' }"
          >
            <div class="top-box">
              <button
                @click="addContent()"
                class="btn btn-block btn-warning btn-add"
                type="button"
              >
                <i class="fa fa-plus"></i> 新增
              </button>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model.number="searchData.status"
                  @change="resetDataList('緊急')"
                >
                  <option value="-9">所有狀態</option>
                  <option value="0">等待中</option>
                  <option value="1">已發送</option>
                  <option value="2">已過期</option>
                </select>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered text-center min-1000">
                <tbody>
                  <tr class="bg-gray">
                    <th>編號</th>
                    <th style="min-width: 150px">發送時間</th>
                    <th style="min-width: 150px">截止時間</th>
                    <th>發送平台</th>
                    <th>訊息內容</th>
                    <th>狀態</th>
                    <th>操作者</th>
                  </tr>
                  <tr v-if="loading"><td colspan="7">查詢中</td></tr>
                  <tr v-else-if="datas.length === 0"><td colspan="7">查詢無任何資料</td></tr>
                  <tr v-else v-for="(item, index) in datas" :key="index" :class="{ 'bg-gray-light': item.status === -1 }">
                    <td>{{ datas.length - index }}</td>
                    <td>{{ item.sendStartTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td>{{ item.sendEndTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td>{{ item.顯示平台 }}</td>
                    <td><button @click="checkContent(item)" class="btn btn-primary">查看</button></td>
                    <td>{{ define.announcementStatus[item.status] }}</td>
                    <td>{{ item.建立者 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 頁數 -->
          <div class="box text-center no-border no-shadow mt-20">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              ref="pagination"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <!-- /.tab-content -->
      </div>
    </section>
    <announcementContent ref="announcementContent" />
  </div>
</template>

<script>
/* eslint-disable */
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import announcementContent from "@/views/projects/FC/AnnouncementContent";

export default {
  components: {
    DateRangePicker,
    Pagination,
    announcementContent,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        typeName: "系統",
        status: -9,
      },
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    resetDataList(typeName, status) {
      this.searchData.pageNum = 1;
      this.getDataList(typeName, status);
    },
    getDataList(typeName, status) {
      this.searchData.typeName = typeName || this.searchData.typeName;
      this.searchData.status = status || this.searchData.status;
      this.$store
        .dispatch("nmjAnnouncement/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // 新增內容
    addContent() {
      this.$router.push({
        name: "NmjAnnouncementEdit",
        params: {
          type: this.searchData.typeName,
          searchData: this.searchData,
        },
      });
    },
    // 查看內容
    checkContent(item) {
      this.$refs.announcementContent.showContent(item, this.searchData);
    },
    // 刪除內容
    deleteContent(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjAnnouncement/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },
  },
};
</script>