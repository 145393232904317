<template>
  <div>
    <div class="modal-header">
      <button
        aria-label="Close"
        class="close"
        data-dismiss="modal"
        type="button"
        @click="$parent.getDataList()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="modal-title" v-if="loading"></h4>
      <h4 class="modal-title blue" v-else>
        [{{ getText(item) }}] - {{ item.reportTargetId }}
      </h4>
      <h4
        class="modal-title link"
        v-if="data.articleTitle"
        v-html="data.articleTitle"
        @click="linkItem(data)"
      ></h4>
    </div>
    <div class="modal-body" v-if="loading">Loading...</div>
    <template v-else-if="getText(item) == '話題'">
      <div class="modal-body" v-if="!item.content">查無資料</div>
      <div class="modal-body" v-else v-html="item.content"></div>
    </template>
    <template v-else>
      <div class="modal-body" v-if="!data.content">查無資料</div>
      <div class="modal-body" v-else v-html="data.content"></div>
    </template>
    <div class="tags" v-if="data.tags">
      <a v-for="(item, index) in data.tags" :key="index">
        #{{ item.Tag }}
      </a>
    </div>
    <template v-if="getText(item) == '話題'"></template>
    <template v-else>
      <hr v-if="item.content" />
      <div
        class="modal-body bg-gray-light ml-20 mr-20 mb-20"
        v-if="item.content"
      >
        <span
          class="link"
          v-if="item.TagReplyId && item.commentFloor && item.TagReplyFloor"
          @click="showReplyTag(item)"
        >
          @{{ item.commentFloor }}-{{ item.TagReplyFloor }}樓
        </span>
        <span class="ml-5" v-html="item.content"></span>
      </div>
    </template>
    <div class="modal-footer">
      <div style="float: left;">
        <span>檢舉原因：{{ item.reportData == "其他" ? `${item.reportData}(${item.note})` : item.reportData }}</span>
        <br>
        <span class="text-red fl" v-if="item.isDelete">
          (此{{ item.text }}已被刪除)
        </span>
      </div>
      <button
        type="button"
        class="btn btn-primary"
        @click="readItem(item)"
      >
        已讀
      </button>
      <button
        type="button"
        class="btn btn-danger"
        v-if="!item.isDelete && getDataList"
        @click="showDelete(item)"
      >
        刪除
      </button>
      <button
        class="btn btn-default pull-right"
        data-dismiss="modal"
        type="button"
        @click="$parent.getDataList()"
      >
        關閉
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import api from '@/assets/js/app-api';
import announcementAlert from "@/views/projects/Newtalk/AnnouncementAlert";

export default {
  props: ["item", "index", "getDataList"],
  components: {
    announcementAlert,
  },
  data() {
    return {
      loading: true,
      targetId: "",
      data: {},
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    item() {
      this.getData();
    }
  },
  methods: {
    getText(item) {
      switch (item.articleType) {
        case 1:
          return "話題";
        case 2:
          return "個人";
        case 3:
          return "留言";
        case 4:
          return "回覆";
        default:
          return "";
      }
    },
    getData() {
      this.loading = true;
      this.$store
        .dispatch(`newtalk/reportQuery`, this.item)
        .then((result) => {
          this.data = result || {};
          this.loading = false;
          this.data.title = `[${this.item.text} - ${this.data.reportTargetId}]`;
          this.data.articleTitle =
            this.data.parentTitle || this.data.reportArticleTitle;
          this.data.tags = this.data.parentTags;
          this.data.content = this.data.parentContent;
          this.item.content = this.data.reportContent;
          this.setArticle(result.reportArticleId);
        });
    },
    setArticle(id) {
      this.$store
        .dispatch(`newtalk/articleQuery`, {
          articleId: id
        })
        .then((result) => {
          this.data.articleTitle = result.articleTitle
          this.data.articleId = result.articleId
          this.data.newtalkType = result.newtalkType
          this.$forceUpdate();
        });
    },
    showReplyTag(item) {
      this.data.tagTitle = `[${item.commentFloor}-${item.TagReplyFloor}樓]`;
      this.data.tagNickName = item.TagNickName || "無暱稱";
      this.data.tagContent = item.TagReplyContent || item.content;
      $(this.$refs.tagContent).modal("show");
      this.$forceUpdate();
    },
    showDelete(item, index) {
      item.index = this.index
      this.$parent.showDelete(item);
    },
    linkItem(item) {
      const name = item.pollsCategory === '民調' ? 'poll' : item.newtalkType === "投票調查" ? "vote" : "post";
      const path = item.newtalkType === "投票調查" ?
        process.env.VUE_APP_VOTEWEB_PATH : process.env.VUE_APP_NEWTALKWEB_PATH
      if (item.isDelete === 0) {
        window.open(
          `${path}/${name}/${item.articleId || item.reportArticleId}`
        );
      } else {
        this.previewItem(item);
      }
    },
    previewItem(item) {
      const name = item.pollsCategory === "民調" ? "poll" : item.newtalkType === "投票調查" ? "vote" : "post";
      const path = item.newtalkType === "投票調查" ?
        process.env.VUE_APP_VOTEWEB_PATH : process.env.VUE_APP_NEWTALKWEB_PATH
      window.open(
        `${path}/${name}/${
          item.articleId || item.reportArticleId
        }?preview=1`
      );
    },
    readItem(item) {
      let _array = [];
      _array.push(item.serialNumber)

      api.newtalk.report.update({
        serialNumber: _array
      }).then((result) => {
        if(this.index == this.$parent.datas.length - 1) {
          this.$parent.hideContent();
        }
        this.$parent.readReport(this.index);
      });
    },
  },
};
</script>

<style>
div {
  word-break: break-all;
}
</style>
