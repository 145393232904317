<template>
  <div class="modal fade" ref="PaymentOwedSettingTime">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">{{timeTypeName}}設定</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>訂單編號</td>
                  <td>{{ edit.訂單編號 }}</td>
                </tr>
                <tr>
                  <td>服務編號</td>
                  <td>{{ data.服務編號 }}</td>
                </tr>
                <tr>
                  <td>交易方式</td>
                  <td>{{ data.交易方式 }}</td>
                </tr>
                <tr>
                  <td>儲值角色</td>
                  <td>{{ data.儲值角色 }}</td>
                </tr>
                <tr>
                  <td>{{ timeTypeName }}</td>
                  <td class="form-inline">
                    <DateTimePicker
                      v-bind:propDateTime.sync="datetime"
                    ></DateTimePicker>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="save()">
            確定
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            取消
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import DateTimePicker from "@/components/DateTimePicker";
import { mapState } from "vuex";

export default {
  components: {
    DateTimePicker,
  },
  data() {
    return {
      data: {},
      timeTypeName: "凍結日期",
      datetime: "",
      edit: {
        訂單編號: "",
        凍結日期: "",
        繳費時間: "",
      },
    };
  },
  computed: {
    ...mapState({
      response: (state) => state.ipsPaymentOwed.response,
    }),
  },
  watch: {
    response() {
      if (this.response.status == "1") {
        this.refreshList();
        $(this.$refs.PaymentOwedSettingTime).modal("hide");
      }
    },
  },
  created() {},
  methods: {
    showSettingTimeBox(type, data) {
      switch (type) {
        case "1":
          this.timeTypeName = "凍結日期";
          this.datetime = data.凍結日期;
          break;
        case "2":
          this.timeTypeName = "繳費時間";
          this.datetime = data.繳費時間;
          break;
      }

      this.data = data;
      this.edit.訂單編號 = data.訂單編號;

      $(this.$refs.PaymentOwedSettingTime).modal("show");
      // $(this.$refs.editSaveHabitsButton).click(() => {
      //   $(this.$refs.editSaveHabitsButton).off("click");
      //   $(this.$refs.PaymentOwedSettingTime).modal("hide");
      //   this.updateHabits(this.edit);
      // });
    },
    save() {
      if (this.timeTypeName == "凍結日期") {
        this.edit.凍結日期 = this.datetime;
      } else this.edit.繳費時間 = this.datetime;

      this.$store.dispatch("ipsPaymentOwed/update", this.edit);
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    },
  },
};
</script>

<style scoped>
.table-responsive {
  overflow-x: inherit;
}
</style>