<template>
  <div id="BeforeLoginList" class="searchList">
    <section class="content-header">
      <h1>事前登入門號</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">事前登入門號</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary ">
        <div class="box-body">
          <div class="top-box" style="margin-bottom: 10px;">
            <button
              @click="showCreat()"
              class="btn btn-block btn-warning btn-add ml-5"
              type="button"
            >
              新增門號
            </button>
            <button
              @click="exportOrders()"
              class="btn btn-block btn-success btn-add"
              type="button"
            >
              門號匯出
            </button>
            <div class="date-group" style="width: auto;">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propsIn="'format2'"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.missionType"
                @change="resetDataList()"
              >
                <option value>分享次數</option>
                <option value="first">首次登入</option>
                <option value="1">分享1次</option>
                <option value="3">分享3次</option>
                <option value="5">分享5次</option>
              </select>
            </div>
            
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.phoneType"
                @change="resetDataList()"
              >
                <option value>手機型號</option>
                <option value="後台補登">後台補登</option>
                <option value="Android">Android</option>
                <option value="ios">ios</option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.pageSource"
                @change="resetDataList()"
              >
                <option value>頁面來源</option>
                <option value="後台補登">後台補登</option>
                <option value="形象頁">形象頁</option>
                <option value="預約頁">預約頁</option>
              </select>
            </div>

            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.Phone"
                @change="resetDataList()"
              >
                <option value>地區</option>
                <option value="886">台灣</option>
                <option value="852">香港</option>
                <option value="853">澳門</option>
                <option value="86">中國</option>
              </select>
            </div>
            <div class="input-group search-group">
              <input
                type="text"
                class="form-control pull-right"
                placeholder="搜尋門號"
                v-model="searchData.identityKey"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>#</th>
                  <th>地區</th>
                  <th>分享次數</th>
                  <th class="text-nowrap" style="min-width: 150px">門號</th>                  
                  <th>手機型號</th>
                  <th>頁面來源</th>
                  <th class="text-nowrap" style="min-width: 150px">
                    登入門號時間
                  </th>
                </tr>
                <tr v-if="loading">
                  <td colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ define.countryTypes[item.phone] }}
                  </td>
                  <td>
                     {{item.missionType == 'first' ? '首次登入': '分享'+item.missionType+'次' }}
                  </td>
                  <td class="text-nowrap">
                    {{item.identityKey}}
                  </td>
                  <td style="">
                       {{item.phoneType}}
                  </td>
                  <td>
                       {{item.pageSource}}
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <select
            class="form-control page-size"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <!--新增門號-->
    <div class="modal fade" ref="createPhone">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">新增門號</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>使用者</td>
                    <td style="display: flex;">
                      <select
                        class="form-control"
                        v-model="addData.CountryCode"
                        style="width: 30%;"
                      >
                        <option value="">地區</option>
                        <option value="886">台灣</option>
                        <option value="852">香港</option>
                        <option value="853">澳門</option>
                        <option value="86">中國</option>
                      </select>
                      <input class="form-control ml-5" required="required" placeholder="門號" type="text" v-model="addData.Phone" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button" @click="create()">確定</button>
            <button class="btn btn-default pull-right" data-dismiss="modal" type="button">取消</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import appUtils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment().subtract(3, "month").format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
       // CountryCode: "",
        Phone: "",
        missionType:"",
        pageSource: "",
        phoneType: "",
        Phone: "",
      },
      addData: {
        CountryCode: "",
        Phone: ""
      },
      datas: [],
      allDatas: [],
    };
  },
   watch: {
    'searchData.local.endTime': function(newVal, oldVal) {
       this.getDataList();
       this.getAllList();
    }
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();

  },
  methods: {
    getDataList() {

      this.searchData.startTime = new moment(this.searchData.local.startTime).format("YYYY-MM-DD");   
      this.searchData.endTime = new moment(this.searchData.local.endTime).format("YYYY-MM-DD");
      this.$store
        .dispatch("DemBefore/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          this.getAllList();
        });
    },
    getAllList() {
        let alldata = { ...this.searchData };
        alldata.pageSize=this.total;
        this.$store.dispatch("DemBefore/queryAll",alldata)
        .then((result) => {
          this.allDatas = result.datas || [];
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
      this.getAllList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    exportOrders() {
      let typeName = "事前登入門號";
      let exportData = [];
      
      this.allDatas.forEach(item => {
        let dateString = item.createTime.replace('T', ' ').split('.')[0];
        exportData.push({
          地區: this.define.countryTypes[item.phone] ,
          分享次數:item.missionType == 'first' ? '首次登入': '分享'+item.missionType+'次',
          門號: item.identityKey,
          手機型號: item.phoneType,
          頁面來源: item.pageSource,
          登入門號時間: dateString
        })
      });

      appUtils.exportData(
        exportData,
        appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
        typeName
      );
    },
    showCreat() {
      this.addData.CountryCode = "";
      this.addData.Phone = "";
      $(this.$refs.createPhone).modal("show");
    },
    create() {
      if(this.addData.CountryCode == "") {
        this.$eventBus.$emit("showAlert", "請選擇地區");
        return
      }
      if(this.addData.Phone == "") {
        this.$eventBus.$emit("showAlert", "門號不得為空");
        return
      }

      api.dem.before.create(this.addData)
        .then((res) => {
          if(res.data.status == "1") {
            this.resetDataList();
            this.$eventBus.$emit("showAlert", res.data.message);
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
          $(this.$refs.createPhone).modal("hide");
        })
    }
  },
};
</script>
