import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    QueryAll: [],
    Query: [],
    QueryMan: [],
    detailQuery: [],
    response: "",
    imgList: [],
}

// getters
const getters = {
}

// actions
const actions = {
    channelQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.channelQueryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setChannelQueryAll', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    channelQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.channelQuery(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setchannelQuery', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    channelMan({ commit }, data) {
        api.smm.channelMan(data).then(res => {
            commit('setchannelMan', res.data);
        })
    },    
    channelCreate({ commit }, data) {
        api.smm.channelCreate(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    channelUpdate({ commit }, data) {
        api.smm.channelUpdate(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    channelTime({ commit }, data) {
        api.smm.channelTime(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    channelDelete({ commit }, data) {
        api.smm.channelDelete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    videoQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.videoQueryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setvideoQueryAll', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    videoQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.videoQuery(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setvideoQuery', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    commentQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.commentQueryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setcommentQueryAll', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    commentQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.commentQuery(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setcommentQuery', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    videoUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.videoUpdate(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setResponse', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    commentUpdate({ commit }, data) {
        api.smm.commentUpdate(data).then(res => {
            commit('setResponse', res.data);
        })
        // return new Promise((resolve, reject) => {
        //     api.smm.commentUpdate(data).then(res => {
        //         if (res.data.status == "1") {
        //             if(!data.skipCommit){
        //                 commit('setResponse', res.data.result);
        //             }
        //         }
        //         resolve(res.data);
        //     })
        // });
    },
    KeywordsCreate({ commit }, data) {
        api.smm.KeywordsCreate(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    KeywordsUpdate({ commit }, data) {
        api.smm.KeywordsUpdate(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    KeywordsDelete({ commit }, data) {
        api.smm.KeywordsDelete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    // channelQueryAll({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         api.smm.channelQueryAll(data).then(res => {
    //             if (res.data.status == "1") {
    //                 if(!data.skipCommit){
    //                     commit('setChannelQueryAll', res.data.result);
    //                 }
    //             }
    //             resolve(res.data);
    //         })
    //     });
    // },
    KeywordsQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.KeywordsQueryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setKeywordsQueryAll', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    KeywordsQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.smm.KeywordsQuery(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setKeywordsQuery', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
}

// mutations
const mutations = {
    setvideoQueryAll(state, videoQueryAll) {
        state.videoQueryAll = videoQueryAll;
    },
    setvideoQuery(state, videoQuery) {
        state.videoQuery = videoQuery;
    },
    setKeywordsQueryAll(state, KeywordsQueryAll) {
        state.KeywordsQueryAll = KeywordsQueryAll;
    },
    setKeywordsQuery(state, KeywordsQuery) {
        state.KeywordsQuery = KeywordsQuery;
    },
    setcommentQueryAll(state, commentQueryAll) {
        state.commentQueryAll = commentQueryAll;
    },
    setcommentQuery(state, commentQuery) {
        state.commentQuery = commentQuery;
    },
    setChannelQueryAll(state, channelQueryAll) {
        state.QueryAll = channelQueryAll;
    },
    setchannelQuery(state, channelQuery) {
        state.Query = channelQuery;
    },
    setchannelMan(state, channelMan) {
        state.QueryMan = channelMan;
    },
    setResponse(state, response) {
        state.response = response;
    },
    // setDetailGameQuery(state, gameDetailQuery) {
    //     state.detailQuery = gameDetailQuery;
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}