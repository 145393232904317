<template>
  <div id="ClassTypeManager">
    <section class="content-header">
      <h1>更新CSV</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">更新CSV</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button @click="updateCSV()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa"></i> 更新CSV
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  delimiters: ["${", "}"],
  data() {
    return {
      datas: []
    };
  },
  created() {
    
  },
  mounted() {
      
  },
  methods: {
    // 顯示列表
    async updateCSV() {
        try {
            this.datas = [];
            var res = await api.asgard.udpateCSV();
            res = res.data;
            if( res.status != "1" ){
                this.$eventBus.$emit("showAlert", res.message);
                return;
            }
            this.$eventBus.$emit("showAlert", res.result);
        } catch (error) {
            this.$eventBus.$emit("showAlert", error);
        }
    },
  }
};
</script>