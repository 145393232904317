import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    exchangeItemsQueryAll({ commit }, data) {
        api.exchangeItems.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setExchangeItemsQueryAll', res.data.result);
            }
        })
    },
    exchangeItemsQuery({ commit }, data) {
        api.exchangeItems.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setExchangeItemsQuery', res.data.result);
            }
        })
    },
    exchangeItemsCreate({ commit }, data) {
        api.exchangeItems.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeItemsUpdate({ commit }, data) {
        api.exchangeItems.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    exchangeItemsDelete({ commit }, data) {
        api.exchangeItems.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
}

// mutations
const mutations = {
    setExchangeItemsQueryAll(state, exchangeItemsQueryAll) {
        state.queryAll = exchangeItemsQueryAll;
    },
    setExchangeItemsQuery(state, exchangeItemsQuery) {
        state.query = exchangeItemsQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}