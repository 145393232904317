<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>萬元大獎抽獎名單</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">萬元大獎抽獎名單</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="date-group" style="width: auto;">
              <label class="control-label">日期：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.EndTime"
                    :propStartDate.sync="searchData.StartTime"
                  />
                </div>
              </div>
            </div>
            <div class="select-group">
              <label class="control-label">活動：</label>
              <select
                class="form-control"
                v-model.number="searchData.Type"
                @change="clickSearch()"
              >
                <option value="lottery">翻牌活動</option>
                <option value="returnLottery">老拉新活動(2023)</option>
              </select>
            </div>
            <div class="input-group sW">
              <label class="control-label">輸入ID：</label>
              <input
                class="form-control pull-right"
                placeholder="搜尋ID"
                type="text"
                v-model="UId"
              />
            </div>

            <div class="input-group text">
              <label class="control-label">輸入門號：</label>
              <input
                class="form-control pull-right"
                placeholder="搜尋門號"
                type="text"
                v-model="phone"
              />
            </div>

            <div class="btns">
              <button @click="clickSearch()" class="btn btn-primary" type="button">查詢</button>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="width: 30%">遊戲ID</th>
                  <th style="width: 30%">門號</th>
                  <th style="width: 40%">時間</th>
                </tr>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="11">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="total === 0">
                  <tr>
                    <td class="text-center" colspan="11">沒有資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in list" :key="index">
                    <td align="center">{{ item.playerCode }}</td>
                    <td align="center">{{ item.phone }}</td>
                    <td align="center">{{ FormatTime(item.createTime) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import DateRangePicker from "@/components/DateRangePicker";
import moment from "moment";
import api from "@/assets/js/app-api";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      loading: true,
      total: 0, // 總筆數
      UId: "",
      phone: "",
      searchData: {
        PlayerCode: "",
        Type: "returnLottery", // lottery,returnLottery
        pageNum: 1,
        pageSize: 10,
        StartTime: new moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD 00:00:00"),
        EndTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      list: []
    };
  },
  mounted() {
    this.getQueryAll()
  },
  watch: {
    activitys() {
      if (this.activitys.datas.length) {
        this.searchData.activityId = this.activitys.datas[0].activityId;
      }
      this.GetSNQueryAll();
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getQueryAll();
    },

    getQueryAll() {
      this.searchData.PlayerCode = this.UId != "" ? this.UId : ""
      console.log(this.searchData);
      api.mftdBefore.LotteryQueryAll(this.searchData).then(({data}) => {
        if(data.status == '1') {
          if(this.phone != "") {
            this.list = data.result.datas.filter(item => {
              return item.phone == this.phone
            })
            this.total = this.list.length
          } else {
            this.list = data.result.datas
            this.total = data.result.total
          }
          this.loading = false
        }
      })
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.loading = true
      this.getQueryAll();
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return utils.formatTime(time, "YYYY/MM/DD HH:mm:ss");
    },
  }
};
</script>
