<template>
    <!--InvoicePrizeList-->
    <div>
        <div class="box">
            <div class="box-body">
                <div class="top-box">
                    <div class="form-group">
                        <select class="form-control" v-model="查詢方式">
                            <option value="一般查詢">一般查詢</option>
                            <option value="訂單查詢">訂單查詢</option>
                            <option value="識別值查詢">識別值查詢</option>
                            <option value="訂單購買門號查詢">訂單購買門號查詢</option>
                        </select>
                    </div>
                    <template v-if="查詢方式 == '一般查詢'">
                        <div class="form-group">
                            <label class="control-label">廠商：</label>
                            <select class=" form-control" style="width: 150px;" v-model="searchData.服務編號">
                                <option value="">全部</option>
                                <option v-for="item of 廠商options" :value="item.廠商編號" :key="item.廠商編號">{{ item.顯示名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">年份：</label>
                            <select class="form-control" style="width: 80px;" v-model="searchData.發票年份">
                                <!-- <option value="">全部</option> -->
                                <option v-for="year of 年份options" :value="year" :key="year">{{ year }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">獎項：</label>
                            <select class="form-control" style="width: 250px;" v-model="searchData.中獎類別">
                                <option value="">全部</option>
                                <option value="A">特別獎-1000萬(A)</option>
                                <option value="B">雲端發票專屬兩千元獎-2,000元(B)</option>
                                <option value="C">雲端發票專屬百萬元獎-100萬元(C)</option>
                                <option value="D">雲端發票專屬五百元獎-500元(D)</option>
                                <option value="E">雲端發票專屬八百元獎-800元(E)</option>
                                <option value="0">特獎-200萬元(0)</option>
                                <option value="1">頭獎-20萬元(1)</option>
                                <option value="2">二獎-4萬元(2)</option>
                                <option value="3">三獎-1萬元(3)</option>
                                <option value="4">四獎-4,000元(4)</option>
                                <option value="5">五獎-1000元(5)</option>
                                <option value="6">六獎-200元(6)</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="control-label">期數：</label>
                            <select class="form-control" style="width: 100px;" v-model="searchData.發票期數">
                                <option value="">全部</option>
                                <option value="1, 2月">1.2月</option>
                                <option value="3, 4月">3.4月</option>
                                <option value="5, 6月">5.6月</option>
                                <option value="7, 8月">7.8月</option>
                                <option value="9, 10月">9.10月</option>
                                <option value="11, 12月">11.12月</option>
                            </select>
                        </div>
                        <div class="form-group" style="margin-right:0px;">
                            <label class="control-label">歸戶類型：</label>
                            <select class="form-control" style="width: 80px;" v-model="searchData.歸戶類型">
                                <option value="">全部</option>
                                <option value="A">A檔</option>
                                <option value="X">X檔</option>
                                <option value="Z">Z檔</option>
                            </select>
                        </div>
                        <div class="btn-group" style="margin-right:15px;">
                            <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"
                                aria-expanded="true">
                                <span><i class="fa fa-fw fa-question"></i></span>
                            </button>
                            <div class="dropdown-menu" style="padding: 15px;">
                                <table class="text-nowrap">
                                    <tr>
                                        <td>
                                            <dt>Y檔,無歸戶</dt>
                                        </td>
                                        <td>索取紙本發票(我們公司無此項目)</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <dt>A檔,無歸戶</dt>
                                        </td>
                                        <td>通知中獎人,提供兌換碼請玩家至全家列印</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <dt>X檔,已歸戶</dt>
                                        </td>
                                        <td>未設定自動匯款</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <dt>Z檔,已歸戶</dt>
                                        </td>
                                        <td>已設定自動匯款</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <dt>Z檔</dt>
                                        </td>
                                        <td>設定捐贈碼捐贈</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="form-group" style="margin-right:0px;">
                            <label class="control-label">是否列印：</label>
                            <select class="form-control" style="width: 90px;" v-model="searchData.是否列印">
                                <option value="">全部</option>
                                <option :value="true">已列印</option>
                                <option :value="false">未列印</option>
                            </select>
                        </div>
                    </template>
                    <template v-else-if="查詢方式 == '訂單查詢'">
                        <div class="form-group">
                            <input type="text" class="form-control" style="width:220px;" placeholder="訂單編號"
                                v-model.trim="searchData.訂單編號" />
                        </div>
                    </template>
                    <template v-else-if="查詢方式 == '識別值查詢'">
                        <div class="form-group">
                            <input type="text" class="form-control" style="width:220px;" placeholder="識別值，用,分隔，可查詢多個"
                                v-model.trim="searchData.暱稱" />
                        </div>
                    </template>

                    <template v-else="查詢方式 == '訂單購買門號查詢'">
                        <div class="form-group">
                            <input type="text" class="form-control" style="width:220px;" placeholder="訂單購買門號"
                                v-model.trim="searchData.門號" />
                        </div>
                    </template>
                    <br />
                    <div class="form-group">
                        <button class="btn btn-primary" type="button" @click="onSearch(1, searchData)">
                            查詢
                        </button>
                        <template v-if="lastQueryCommand && total > 0">
                            <button class="btn btn-success" type="button">
                                <a download style="color:white;" :href="匯出結果網址"> 匯出查詢結果</a>
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn btn-success disabled" type="button">
                                匯出查詢結果
                            </button>
                        </template>

                        <button class="btn btn-warning" type="button" @click="開啟匯入頁面()">
                            匯入中獎發票
                        </button>
                        <button class="btn btn-info" type="button" @click="開啟寄送簡訊頁面()">
                            A檔寄送簡訊
                        </button>
                    </div>
                </div>
                <div class="table-responsive mt-10">
                    <table class="table table-hover table-bordered min-800">
                        <tbody>
                            <tr class="bg-gray text-nowrap">
                                <th>廠商</th>
                                <th>發票年份</th>
                                <th>發票期數</th>
                                <th>訂單編號</th>
                                <th>訂單購買門號</th>
                                <th>識別值</th>
                                <th>購買日期</th>
                                <th>發票號碼</th>
                                <th>獎項</th>
                                <th>歸戶/類型</th>
                                <!-- 
                                    <th>填寫日期</th>
                                    <th>地址資料填寫</th>
                                    <th>是否捐贈</th> 
                                    -->
                                <!-- 新增兌換碼、是否寄送簡訊、是否列印 -->
                                <!-- <th>兌換碼</th> -->
                                <th>簡訊</th>
                                <th>發票列印</th>
                                <th>備註</th>
                                <th>詳細資料</th>
                            </tr>
                            <tr v-for="item in list" :key="item.訂單編號">
                                <td class="text-center">{{ 顯示廠商(item.服務編號) }}</td>
                                <td class="text-center">{{ item.發票年份 }}</td>
                                <td class="text-center">{{ item.發票期數 }}</td>
                                <td class="text-center">{{ item.訂單編號 }} </td>
                                <td class="text-center">{{ item.門號 }}</td>
                                <td class="text-center">{{ item.暱稱 }}</td>
                                <td class="text-center">{{ item.購買日期 }}</td>
                                <td class="text-center">{{ item.發票號碼 }}</td>
                                <td class="text-center">{{ item.獎項 }}</td>
                                <td class="text-center">{{ item.歸戶類型 }}</td>
                                <!-- 
                                    <td class="text-center">{{ item.填寫日期 }}</td>
                                    <td class="text-center">{{ item | 地址資料填寫 }}</td>
                                    <td class="text-center">{{ item.是否捐贈 | 顯示捐贈 }}</td>
                                    -->
                                <!-- 新增兌換碼、是否寄送簡訊、是否列印 -->
                                <!-- <td class="text-center">{{ item.兌換碼 }}</td> --><!--兌換碼目前先隱藏，之後設權限處理-->
                                <td class="text-center">
                                    <span v-if="item.是否寄送簡訊">已寄送</span>
                                    <span v-else>-</span>
                                </td>
                                <td class="text-center text-nowrap">
                                    <span v-if="item.歸戶類型 == 'A'">
                                        <template v-if="item.可領獎狀態 == 0">
                                            <button class="btn btn-info" @click="sendSMS(item)">
                                                寄送簡訊
                                            </button>
                                            <br />
                                        </template>
                                        <span v-if="item.是否列印" class="text-green">
                                            <button type="button" class="btn btn-warning btn-xs"
                                                @click="確認重置(item)">重置</button>
                                            <br />
                                            已列印
                                            <br />
                                            {{ item.列印時間 }}
                                        </span>
                                        <template v-else>
                                            <span v-if="item.可領獎狀態 == 0" class="text-light-blue">未列印</span>
                                            <span v-if="item.可領獎狀態 == 1" class="text-green">尚未至領獎日</span>
                                            <span v-if="item.可領獎狀態 == 2" class="text-red">已逾期</span>
                                        </template>
                                    </span>
                                    <template v-else>
                                        -
                                    </template>
                                </td>

                                <td class="text-center">{{ item.備註 }}</td>
                                <td>
                                    <button class="btn btn-danger" @click="開啟發票詳細頁面(item)">查看</button>
                                    <button class="btn btn-info" v-show="item.歸戶類型 == 'A'"
                                        @click="switcPrintTab({ 查詢方式: '訂單查詢', 訂單編號: item.訂單編號, 門號: item.門號 })">列印歷程</button>
                                </td>
                            </tr>
                            <tr v-if="list.length == 0">
                                <td class="text-center" colspan="14">沒有資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="box-footer text-center no-border">
                <select class="form-control page-size" v-model="pageSize">
                    <option :value=10>單頁顯示10項</option>
                    <option :value=20>單頁顯示20項</option>
                    <option :value=40>單頁顯示40項</option>
                    <option :value=100>單頁顯示100項</option>
                </select>
                <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
            </div>
        </div>
        <InvoicePopDetailForm ref="invoicePopDetailForm" @更新列表="onPageRefresh">
        </InvoicePopDetailForm>
        <InvoicePopImportForm ref="invoicePopImportForm"></InvoicePopImportForm>
        <InvoicePopSMSForm ref="invoicePopSMSForm" :廠商options="廠商options" :年份options="年份options"></InvoicePopSMSForm>
        <InvoicePopResetForm ref="invoicePopResetForm" @更新列表="onPageRefresh"></InvoicePopResetForm>
    </div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import InvoicePopDetailForm from "@/views/projects/ips/InvoicePopDetailForm.vue";
import InvoicePopImportForm from "@/views/projects/ips/InvoicePopImportForm.vue";
import InvoicePopSMSForm from "@/views/projects/ips/Invoice/InvoicePopSMSForm.vue";
import InvoicePopResetForm from "@/views/projects/ips/Invoice/InvoicePopResetForm.vue";

let defSearchData = {
    // 一般查詢：
    服務編號: "",
    發票年份: "",
    中獎類別: "",
    發票期數: "",
    歸戶類型: "",
    是否列印: "",
    //訂單查詢
    訂單編號: "",
    //識別值查詢
    暱稱: "",
    // 訂單購買門號查詢
    門號: "",
};
export default {
    components: {
        Pagination,
        InvoicePopDetailForm,
        InvoicePopImportForm,
        InvoicePopSMSForm,
        InvoicePopResetForm,
    },
    data() {
        let pageSize = 20;
        let data = {
            查詢方式: "一般查詢",//一般查詢,訂單查詢,識別值查詢
            pageSize,
            total: 0,
            年份options: [],
            廠商options: [
                {
                    廠商編號: "10001",
                    顯示名稱: "星城Online",
                },

                {
                    廠商編號: "10002",
                    顯示名稱: "遊e卡官網",
                },
                {
                    廠商編號: "10004",
                    顯示名稱: "賀緹酒店",
                },
                {
                    廠商編號: "10003",
                    顯示名稱: "越南福利站",
                },
                {
                    廠商編號: "10014",
                    顯示名稱: "yoegames",
                },
                {
                    廠商編號: "10015",
                    顯示名稱: "BanaBana",
                },
            ],
            serverList: [],
            list: [],
            lastQueryCommand: null
        };
        data.searchData = { ...defSearchData };
        return data;
    },
    watch: {
        查詢方式(value) {
            this.searchData = { ...defSearchData };
            switch (value) {
                case "一般查詢": {
                    this.searchData.發票年份 = this.年份options[0];
                    break;
                }
                case "訂單查詢":
                case "識別值查詢":
                case "訂單購買門號查詢":
                    break;
            }
        },
        pageSize(v) {
            this.onPageChange(1);
        }
    },
    computed: {
        匯出結果網址() {
            let 下載參數 = {};
            if (this.lastQueryCommand) {
                下載參數 = {
                    ServiceId: this.lastQueryCommand.服務編號,
                    InvoiceYear: this.lastQueryCommand.發票年份,
                    JackpotType: this.lastQueryCommand.中獎類別,
                    InvoiceRate: this.lastQueryCommand.發票期數,
                    HomeType: this.lastQueryCommand.歸戶類型,
                    OrderId: this.lastQueryCommand.訂單編號,
                    nickName: this.lastQueryCommand.暱稱,
                    Phone: this.lastQueryCommand.門號,
                    // IsPrint: this.lastQueryCommand.是否列印,
                };

                if (this.lastQueryCommand.是否列印 === true) {
                    下載參數.IsPrint = true;
                } else if (this.lastQueryCommand.是否列印 === false) {
                    下載參數.IsPrint = false;
                } else {
                    //全部的話，下載參數不傳
                    // delete 下載參數.是否列印;
                }

            }
            let 參數陣列 = [];
            let keys = Object.keys(下載參數);
            for (let key of keys) {
                參數陣列.push(`${key}=${下載參數[key]}`);
            }
            return api.IPSSetting.DownloadInvoiveExcel() + "?" + 參數陣列.join("&");
        }
    },
    mounted() {
        //初始年份選項
        let nowYear = parseInt(new moment().format("YYYY"));
        while (nowYear >= 2021) {
            this.年份options.push(nowYear);
            nowYear--;
        }
        this.searchData.發票年份 = this.年份options[0];
    },
    methods: {
        async sendSMS({ 訂單編號 }) {
            try {
                this.$store.dispatch('invoice/loading', true);
                let res = await api.IPSSetting.SendWinningSMS([{ 訂單編號, 是否重寄: true }]);
                if (res && res.data && res.data.message) {
                    throw res.data.message;
                } else {
                    throw "成功";
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.$store.dispatch('invoice/loading', false);
            }
        },
        onPageChange(pageIdx) {
            this.onSearch(pageIdx, this.lastQueryCommand);
        },
        onPageRefresh(訂單編號) {
            this.onSearch(this.$refs.pagination.page, this.lastQueryCommand);
        },
        switcPrintTab({ 查詢方式, 訂單編號, 門號 }) {
            this.$emit("switcPrintTab", { 查詢方式, 訂單編號, 門號 });
        },
        async onSearch(pageIdx, query) {
            if (!query) {
                return;
            }
            this.$store.dispatch('invoice/loading', true);
            let send = JSON.parse(JSON.stringify(query));
            send.起始行 = (pageIdx - 1) * this.pageSize + 1;
            send.結束行 = pageIdx * this.pageSize;
            if (query.是否列印 === true) {
                send.是否列印 = true;
            } else if (query.是否列印 === false) {
                send.是否列印 = false;
            } else {
                delete send.是否列印;
            }
            try {
                let res = await api.IPSSetting.getInvoiceJackpot(send);
                if (res && res.data) {
                    res = res.data;
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    throw errMsg;
                }
                if (res.list) {
                    this.list = res.list;
                    this.total = res.total;
                } else {
                    this.list = [];
                    this.total = 0;
                }
                this.lastQueryCommand = query;
            } catch (e) {
                this.list = [];
                this.total = 0;
                this.lastQueryCommand = null;
                this.$eventBus.$emit("showAlert", e);
            } finally {
                this.$store.dispatch('invoice/loading', false);
                this.$refs.pagination.setPage(
                    pageIdx,
                    this.total
                );
            }
        },
        開啟發票詳細頁面(item) {
            this.$refs.invoicePopDetailForm.show(item);
        },
        開啟匯入頁面() {
            this.$refs.invoicePopImportForm.show();
        },
        開啟寄送簡訊頁面() {
            this.$refs.invoicePopSMSForm.show({
                服務編號: this.searchData.服務編號,
                發票年份: this.searchData.發票年份,
                中獎類別: this.searchData.中獎類別,
                發票期數: this.searchData.發票期數
            });
        },
        顯示廠商(廠商編號) {
            let f = this.廠商options.find(item => {
                return item.廠商編號 == 廠商編號;
            })
            return f ? f.顯示名稱 : 廠商編號;
        },
        確認重置(item) {
            console.error("click", JSON.stringify(item));

            this.$refs.invoicePopResetForm.show(item);
        }
    },
    filters: {
        地址資料填寫(item) {
            switch (item.歸戶類型) {
                case 'A':
                    if (item.是否捐贈 == 1) {
                        return '捐贈'
                    } else {
                        if (item.收件人 == "" && item.收件地址 == "" && item.連絡電話 == "" && (item.信箱 === null || item.信箱 == "")) {
                            return '';
                        } else {
                            return '已填寫需寄送';
                        }
                    }
                case 'X':
                    return '已歸戶';
                case 'Z':
                    return '已歸戶';
                default:
                    return '';
            }
        },
        顯示捐贈(是否捐贈) {
            return (是否捐贈 == 1) ? 'V' : 'X';
        },
    }
};
</script>

<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.page-size {
    float: left;
    width: auto;
}

.box {
    border: 0px;
    box-shadow: none;
}

.form-group>button {
    margin-right: 15px;
}
</style>