<template>
    <div class="mymodal" ref="PopForm">
        <div style="width:600px;margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box box-info">
                <div class="box-header with-border">
                    <h3 class="box-title">{{ isEdit ? $t('修改') : $t('新增') }} {{ edit.isCustomize ? $t('自定義罐頭訊息') :
                        $t('罐頭訊息') }}
                    </h3>
                    <button type="button" class="close" @click="Close()"><span aria-hidden="true">×</span></button>
                </div>
                <form class="form-horizontal">
                    <div class="box-body">
                        <div class="form-group">
                            <label class="col-sm-2 control-label"><span class="red">*</span>{{ $t('專案') }}：</label>
                            <div class="col-sm-10">
                                <select class="form-control" v-model="edit.project" :disabled="isEdit">
                                    <option value="-1" v-if="!isEdit">{{ $t('請選擇專案') }}</option>
                                    <option v-for="item of Service" :key="item" :value="item">{{ $t(item) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group" v-if="!isEdit" style="margin-top: -15px;margin-bottom: 10px;">
                            <label class="col-sm-2 control-label"></label>
                            <div class="col-sm-10">
                                <span class="red">{{ $t('新增後，專案無法修改') }}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label"><span class="red">*</span>{{ $t('類別') }}：</label>
                            <div class="col-sm-10">
                                <select class="form-control" v-model="edit.categoryID">
                                    <option value="-1" v-if="!isEdit">{{ $t('請選擇問題類別') }}</option>
                                    <option v-for="item of filterCategories" :key="item.categoryID"
                                        :value="item.categoryID">{{
                                            item.name }}{{ item.enable == false ? $t('Scratch停用中') : '' }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label"><span class="red">*</span>{{ $t('問題') }}：</label>
                            <div class="col-sm-10">
                                <textarea class="form-control" style="height: 100px;" v-model.trim="edit.question"
                                    placeholder=""></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label"><span class="red">*</span>{{ $t('回覆') }}：</label>
                            <div class="col-sm-10">
                                <textarea class="form-control" style="height: 100px;" v-model.trim="edit.answer"
                                    placeholder=""></textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label"><span class="red">*</span>{{ $t('排序') }}：</label>
                            <div class="col-sm-10">
                                <input type="number" class="form-control" v-model.trim.number="edit.sort"
                                    :disabled="edit.enable == false">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label"><span class="red">*</span>{{ $t('狀態') }}：</label>
                            <div style="padding-top: 7px;">
                                <div class="ckbx-style-8 ckbx toggleInput" @click="edit.enable = !edit.enable">
                                    <input :false-value="false" :true-value="true" name="ckbx-style-8" type="checkbox"
                                        v-model="edit.enable" />
                                    <label :for="'check'"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-footer">
                        <button type="button" class="btn" @click="Close()">{{ $t('取消') }}</button>
                        <button type="button" class="btn btn-info" @click="Save()">{{ $t('確認送出') }}</button>
                    </div>
                </form>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { CsOmpAPI as CsAPI } from "@/store/MessageSystem/messageSystemLib";
import api from "@/assets/js/app-api";
export default {
    props: ['Service', 'Categories'],
    components: {
    },
    data() {
        return {
            isEdit: true,//是否編輯
            edit: {
                id: -1,
                project: "",
                categoryID: -1,
                question: "",
                answer: "",
                enable: 1,
                sort: 0,
                isCustomize: false,
            },
        };
    },
    computed: {
        filterCategories() {
            let list = this.Categories.filter((item) => { return item.project == this.edit.project; });
            let find = list.find((item) => { return item.categoryID == this.edit.categoryID });
            if (!find) {
                this.edit.categoryID = -1;
            }
            return list;
        },
    },
    watch: {
        "edit.enable"(v) {
            if (v == false) {
                this.edit.sort = 0;
            }
        }
    },
    created() { },
    mounted() {
        $(this.$refs.PopForm).hide();
    },
    methods: {
        Open({ id, project, categoryID, question, answer, enable, sort }, isCustomize) {
            this.isEdit = (id > 0) ? true : false;
            this.edit.id = id;
            this.edit.project = project;
            this.edit.categoryID = categoryID;
            this.edit.question = question;
            this.edit.answer = answer;
            this.edit.enable = enable;
            this.edit.sort = sort;
            this.edit.isCustomize = isCustomize;
            $(this.$refs.PopForm).show();
            $(this.$refs.loading).hide();
        },
        Close() {
            $(this.$refs.PopForm).hide();
        },
        async Save() {
            if (!this.edit.project || this.edit.project <= 0) {
                this.$eventBus.$emit("showAlert", this.$t('請選擇專案'));
                return;
            }
            if (!this.edit.categoryID || this.edit.categoryID <= 0) {
                this.$eventBus.$emit("showAlert", this.$t('請選擇類別'));
                return;
            }
            if (this.edit.question == "") {
                this.$eventBus.$emit("showAlert", this.$t('問題欄位不可為空'));
                return;
            }
            if (this.edit.answer == "") {
                this.$eventBus.$emit("showAlert", this.$t('回覆欄位不可為空'));
                return;
            }
            if (parseInt(this.edit.sort) + "" != this.edit.sort + "") {
                this.$eventBus.$emit("showAlert", this.$t('排序欄位輸入值不正確'));
                return;
            }
            $(this.$refs.loading).show();
            try {
                if (this.isEdit) {
                    //修改
                    CsAPI(await api.CS.UpdateCannedMsg({
                        Id: this.edit.id,
                        CategoryID: this.edit.categoryID,
                        Question: this.edit.question,
                        Answer: this.edit.answer,
                        Enable: this.edit.enable,
                        sort: this.edit.sort,
                        isCustomize: this.edit.isCustomize,
                    }));
                    throw this.$t('修改成功');
                } else {
                    //新增
                    CsAPI(await api.CS.AddCannedMsg({
                        Project: this.edit.project,
                        CategoryID: this.edit.categoryID,
                        Question: this.edit.question,
                        Answer: this.edit.answer,
                        Enable: this.edit.enable,//todo:測試新增時是否有作用
                        sort: this.edit.sort,
                        isCustomize: this.edit.isCustomize,
                    }));
                    throw this.$t('新增成功');
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.PopForm).hide();
            this.$emit("refresh");
        }
    },
};
</script>
<style scoped>
thead tr th {
    text-align: left;
    position: sticky;
    background-color: white;
    top: 0;
}

.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.iradio_flat-blue {
    margin-left: 0px;
}

.iradio_flat-blue[checked] {
    background-position: -110px 0;
}

.col-sm-10 {
    padding-left: 0px;
}

.col-sm-2 {
    padding-right: 0px;
}

.red {
    color: red;
}

.btn {
    margin: 10px;
}
</style>