<template>
    <div id="ForumMuteSetting">
      <section class="content-header">
        <h1>隱藏留言</h1>
        <ol class="breadcrumb">
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li class="active">隱藏留言</li>
        </ol>
      </section>
      <section class="content forum-mute">
        <div class="box box-primary max1200">
          <div class="box-body">
            <div class="top-box">
							<button
								@click="addMute()"
								class="btn btn-block btn-warning btn-add"
								type="button"
							>
								<i class="fa fa-plus"></i> 新增隱藏UId
							</button>
              <div class="form-group clearfix">
                <label class="control-label">開始日期：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <DateRangePicker
                    :propEndDate.sync="searchData.hideEndTime"
                    :propStartDate.sync="searchData.hideStartTime"
                  />
                </div>
              </div>
              <div class="select-group">
                <input
                    class="form-control"
                    type="text"
                    placeholder="UId"
                    v-model.trim="searchData.mobile"
                />
                </div>
              <button
                class="btn btn-default"
                type="button"
                @click="resetDataList()"
              >
                查詢
              </button>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered min1000">
                <tbody>
                  <tr class="bg-gray">
                    <th>編號</th>
                    <th>新增時間</th>
                    <th>被隱藏UId</th>
                    <th>隱藏原因</th>
                    <th>功能</th>
                  </tr>
                  <tr v-if="loading">
                    <td class="text-center" colspan="12">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td class="text-center" colspan="12">查詢無任何資料</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-center">{{ item.hideSetTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">
											{{ item.mobile }}
											<br>
											({{ item.nickName }})
										</td>
                    <td class="text-center" v-if="!item.isEdit">{{ item.hideNote }}</td>
                    <td class="text-center" v-else>
											<input
												class="form-control"
												type="text"
												v-model="item.hideNote"
											>
										</td>
                    <td class="text-center">
											<button
												class="btn btn-primary"
												v-if="!item.isEdit"
												@click="change(item)"
											>
												修改
											</button>
											&nbsp;
											<button
												class="btn btn-danger"
												v-if="!item.isEdit"
												@click="remove(item)"
											>
												刪除
											</button>
											&nbsp;
											<button
												class="btn btn-success"
												v-if="item.isEdit"
												@click="update(item)"
											>
												儲存
											</button>
											&nbsp;
											<button
												class="btn btn-default"
												v-if="item.isEdit"
												@click="cancel(item)"
											>
												取消
											</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-footer text-center no-border clearfix"></div>
        </div>
      </section>
			<MemberMuteAlert ref="memberMuteAlert" v-on:updateList="resetDataList"></MemberMuteAlert>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import moment from "moment";
  import DateRangePicker from "@/components/DateRangePicker";
	import MemberMuteAlert from "@/views/projects/Newtalk/MemberMuteAlert";
  
  export default {
    components: {
      DateRangePicker,
			MemberMuteAlert,
    },
    data() {
      return {
        loading: true,
        total: 0,
        searchData: {
            mobile: '',
            hideStartTime: new moment()
            .subtract(7, "days")
            .format("YYYY/MM/DD"),
            hideEndTime: new moment().format("YYYY/MM/DD"),
            page: 1,
        },
        datas: [],
				isEdit: false,
				orginItem: [],
      };
    },
    mounted() {
      this.searchData = this.$route.params.searchData || this.searchData;
      this.getDataList();
    },
    methods: {
      getDataList() {
        this.$store
          .dispatch("newtalk/memberMuteQueryAll", this.searchData)
          .then((result) => {
            this.datas = result.memberList || [];
            this.total = result.totalCount;
            this.loading = false;
          });
      },
      resetDataList() {
        this.searchData.pageNum = 1;
        this.getDataList();
      },
			addMute() {
				this.$refs.memberMuteAlert.showAlert();
			},
			remove(item) {
				this.$eventBus.$emit(
					"showConfirm",
					"刪除後無法復原，確認刪除？",
					(isOK) => {
						if (isOK) {
							this.$store
								.dispatch("newtalk/memberMuteDelete", {
									mobile: item.mobile
								})
								.then((result) => {
									this.getDataList();
								});
						}
					}
				);
			},
			update(item) {
				item.isEdit = true;
				this.$store.dispatch("newtalk/memberMuteUpdate", {
					mobile: item.mobile,
    			hideNote: item.hideNote,
				}).then((result) => {
					this.getDataList();
				});
			},
			change(item) {
				item.isEdit = true;
				this.orginItem = item.hideNote
				this.$forceUpdate();
			},
			cancel(item) {
				item.isEdit = false;
				item.hideNote = this.orginItem
				this.$forceUpdate();
			},
    },
  };
  </script>
  