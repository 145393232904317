<template>
    <div class="mymodal" ref="PopForm">
        <div style="width:800px;margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box box-info">
                <div class="box-header with-border">
                    <h3 class="box-title">常用代碼設定</h3>
                    <button type="button" class="close" @click="Close()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="form-horizontal">
                    <div class="box-body">
                        <div class="form-group"
                            style="float:left;margin-top: 0px;margin-right: 0px;margin-bottom: 15px;margin-left: 0px;">
                            <select class=" form-control" v-model="selType" :disabled="是否有資料修改中 || 是否有一鍵複製功能">
                                <option v-for="(item, key) in 手開發票常用資料類別Enum" :value="item">{{ key }}</option>
                            </select>
                        </div>
                        <div class="form-group"
                            style="float:left;margin-top: 0px;margin-right: 0px;margin-bottom: 15px;margin-left: 0px;">
                            <div class="box-tools">
                                <div class="input-group" style="width:200px;">
                                    <input type="text" class="form-control" placeholder="搜尋" v-model="search"
                                        :disabled="是否有資料修改中">
                                    <span class="input-group-addon" :class="{ disableBackground: 是否有資料修改中 }"><i
                                            class="fa fa-search"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group"
                            style="float:left;margin-top: 0px;margin-right: 0px;margin-bottom: 15px;margin-left: 10px;"
                            v-if="是否有資料修改中">
                            <span style="line-height: 34px;">欲啟用搜尋功能需先儲存正在編輯的項目!</span>
                        </div>
                        <button class="btn btn-warning btn-add" style="float:right;margin-bottom: 10px;" type="button"
                            @click="開啟新增列()">新增
                        </button>
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <th style="width:38%">{{ selType | 一鍵複製key欄位顯示 }}</th>
                                    <th style="width:38%">{{ selType | 一鍵複製value欄位顯示 }}</th>
                                    <th class="text-nowrap text-center">功能</th>
                                </tr>
                                <tr v-for="(item) in filterList" :key="item.編號">
                                    <template v-if="item.editor == 0">
                                        <td> {{ item.名稱 }} </td>
                                        <td> {{ item.值 }} </td>
                                        <td class="text-right text-nowrap">
                                            <button v-if="是否有一鍵複製功能"
                                                @click="$emit('companyClick', item.類別, item.值, item.名稱)"
                                                class="btn btn-primary">一鍵複製</button>
                                            <button class="btn bg-purple" @click="修改(item)">修改</button>
                                            <button class="btn btn-danger" @click="刪除(item)">刪除</button>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            <input class="form-control" type="text" v-model.trim="edit.名稱" />
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" v-model.trim="edit.值" />
                                        </td>
                                        <td class="text-right">
                                            <button @click="儲存(item)" class="btn btn-success btn-save">儲存</button>
                                            <button @click="取消(item)" class="btn btn-default">取消</button>
                                        </td>
                                    </template>
                                </tr>
                                <tr v-if="filterList.length == 0">
                                    <td colspan="3" class="text-center">
                                        無資料
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/assets/js/app-api";
import { 手開發票常用資料類別Enum, TransEnum, InvoiceAPI } from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
import { 取得愛心碼檢查器, 取得載具條碼檢查器, 取得統一編號檢查器 } from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
import { Strategies, Validator } from "@/assets/js/Tool/Validator.js";
export default {
    components: {
    },
    data() {
        return {
            是否有一鍵複製功能: false,
            手開發票常用資料類別Enum,
            list: [],
            edit: {
                //editor:0 察看；1:修改 2:新增
                值: "", 類別: 1, editor: 0, 名稱: ""
            },
            search: "",
            selType: -1,//手開發票常用資料類別Enum.統一編號
        };
    },
    computed: {
        filterList() {
            let list = [...this.list];
            if (this.search != "") {
                let reg = new RegExp(this.search, "i");
                list = list.filter((item) => {
                    return item.值.toString().search(reg) >= 0 || item.名稱.search(reg) >= 0;
                });
            }

            list = list.filter((item) => {
                return item.類別 == this.selType;
            });

            return list;
        },
        是否有資料修改中() {
            let index = this.list.findIndex((i) => {
                return i.editor != 0;
            });
            return index >= 0;
        }
    },
    mounted() {
        $(this.$refs.PopForm).hide();
        $(this.$refs.loading).hide();
    },
    watch: {
        async selType(nowV, oldV) {
            $(this.$refs.loading).show();
            await this.reset();
            $(this.$refs.loading).hide();
        },
    },
    methods: {
        Close() {
            $(this.$refs.PopForm).hide();
        },
        Open(是否有一鍵複製功能, 類別 = 手開發票常用資料類別Enum.統一編號) {
            this.是否有一鍵複製功能 = 是否有一鍵複製功能;
            this.selType = 類別;
            this.search = "";
            $(this.$refs.PopForm).show();
        },
        修改(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
                return;
            }
            item.editor = 1;
            this.edit = { ...item };
        },
        async reset() {
            try {
                let res = await api.IPSSetting.GetCommonInformationList({ 類別: this.selType });
                if (res && res.data && res.data.sucess) {
                    let data = res.data.message.datas;
                    this.list = data.map(({ 編號, value: 值, key: 名稱 }) => ({ editor: 0, 編號, 類別: this.selType, 值, 名稱 }));
                } else {
                    throw (res && res.data && res.data.errMsg) ? res.data.errMsg : "無資料";
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.list = [];
            }
        },
        async 儲存(item) {
            let validator = new Validator();
            try {
                validator.add(this.edit.名稱, [{
                    strategy: Strategies.inNotEmpty,
                    errorMsg: '名稱不為空'
                }]);

                switch (this.selType) {
                    case 手開發票常用資料類別Enum.統一編號:
                        validator = 取得統一編號檢查器(validator, this.edit.值, this.edit.名稱); break;
                    case 手開發票常用資料類別Enum.愛心碼:
                        validator = 取得愛心碼檢查器(validator, this.edit.值); break;
                    case 手開發票常用資料類別Enum.載具條碼:
                        validator = 取得載具條碼檢查器(validator, this.edit.值); break;
                    default:
                        throw "類別錯誤";
                }
                let errorMsg = validator.start();
                if (errorMsg) {
                    throw errorMsg;
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).show();
            try {
                if (item.editor == 2) {
                    //新增
                    InvoiceAPI(await api.IPSSetting.AddCommonInformation({
                        類別: this.selType,
                        key: this.edit.名稱,
                        value: this.edit.值
                    }));
                } else {
                    //修改
                    InvoiceAPI(await api.IPSSetting.UpdateCommonInformation({
                        編號: item.編號,
                        key: this.edit.名稱,
                        value: this.edit.值
                    }));
                }
                this.$eventBus.$emit("showAlert", "執行成功");
                await this.reset();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        async 刪除(item) {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
                return;
            }
            $(this.$refs.loading).show();
            try {
                //刪除
                InvoiceAPI(await api.IPSSetting.RemoveCommonInformation({
                    編號: item.編號,
                }));
                this.$eventBus.$emit("showAlert", "執行成功");
                await this.reset();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        },
        取消(item) {
            if (item.editor == 2) {
                this.從列表中移除指定item(item);
            } else {
                item.editor = 0;
            }
        },
        從列表中移除指定item(item) {
            let index = this.list.findIndex((i) => {
                return item.值 === i.值;
            })
            if (index >= 0) {
                this.list.splice(index, 1);
            }
        },
        開啟新增列() {
            if (this.是否有資料修改中) {
                this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
                return;
            }
            let item = {
                類別: this.selType == 0 ? 1 : this.selType,
                值: "",
                名稱: "",
                editor: 2,
            };
            this.list.unshift(item);
            this.edit = { ...item };
        },
    },
    filters: {
        TransEnum,
        一鍵複製key欄位顯示(selType) {
            switch (selType) {
                case 手開發票常用資料類別Enum.統一編號:
                    return "公司名稱";
                default:
                    return "名稱";
            }
        },
        一鍵複製value欄位顯示(selType) {
            switch (selType) {
                case 手開發票常用資料類別Enum.統一編號:
                    return "公司統編";
                case 手開發票常用資料類別Enum.愛心碼:
                    return "愛心碼";
                case 手開發票常用資料類別Enum.載具條碼:
                    return "載具條碼";
                default:
                    return "值";
            }
        }
    }
}
</script>
<style scoped>
.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.input-group .disableBackground {
    background-color: #eee;


    cursor: not-allowed;

}
</style>