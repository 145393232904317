<template>
    <div class="invoicePage" :class="!item.買方 ? `page` : ``">
        <div class="font-company">網銀國際股份有限公司</div>
        <div class="font-title1" v-if="item.列印狀態 == 手開發票列印狀態Enum.已列印">電子發票證明聯補印</div>
        <div class="font-title2" v-else>電子發票證明聯</div>
        <div class="font-title2">{{ item.民國 }}年{{ item.期數 }}月</div>
        <div class="font-title2">{{ item.發票號碼.substring(0, 2) }}-{{ item.發票號碼.substring(2) }}</div>
        <div class="font-info" style="margin-top:16px ;">{{ item.時間 }}</div>
        <div class="font-info">
            <span class="font-left">隨機碼:{{ item.隨機碼 }}</span>
            <span>總計:{{ item.發票金額 }}</span>
        </div>
        <div class="font-info">
            <span class="font-left">賣方:{{ 網銀統編 }}</span>
            <span v-show="item.買方">買方:{{ item.買方 }}</span>
        </div>
        <div class="div-code" :id="'barcode_' + item.發票號碼"></div>
        <div class="div-code">
            <span :id="'qr1code_' + item.發票號碼"> </span>
            <span style="display: inline-block; width:36px;"></span>
            <span :id="'qr2code_' + item.發票號碼"> </span>
        </div>
        <div class="font-info">請妥善保管您的發票及銷貨明細，辦</div>
        <div class="font-info">理相關作業，請持發票及銷貨明細</div>
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "@/views/projects/ips/JsBarcode.code39.min.js";// https://github.com/lindell/JsBarcode#readme
import "@/views/projects/ips/jquery-qrcode-0.18.0.js";// https://larsjung.de/jquery-qrcode/
import { 網銀統編, 手開發票列印狀態Enum } from "@/views/projects/ips/InvoiceElectronicProcessConst.js";
function 取得民國(發票時間) {
    let 西元 = new moment.unix(發票時間).format("YYYY");
    let 民國 = parseInt(西元) - 1911;
    return 民國;
}
function paddingLeft(str, length) {
    if (str.length >= length)
        return str;
    else
        return paddingLeft("0" + str, length);
}
export default {
    props: ['item'],
    data() {
        return {
            手開發票列印狀態Enum,
            網銀統編,
        }
    },
    mounted() {
        this.$nextTick().then(() => {
            this.setBarCode();
            // console.error("c1", "nextTick");
            return true;
        });
    },
    methods: {
        setBarCode() {
            let invoiceData = this.item;
            try {
                const canvas = document.createElement("CANVAS");
                JsBarcode(canvas, invoiceData.barcode, {
                    xmlDocument: document,
                    format: "CODE39",
                    width: 1,
                    height: 40,
                    displayValue: false,
                    margin: 0,
                    // fontSize: 15
                });
                let barcodeDom = $("#" + 'barcode_' + invoiceData.發票號碼)[0];
                barcodeDom.innerHTML = '';
                barcodeDom.appendChild(canvas);

                // 左方二維條碼使用 QR Code V6 (41x41) (含)以上版本，並採用 Level L (容錯率 7%)以上 之防錯標準
                let qr1codeDom = $("#" + 'qr1code_' + invoiceData.發票號碼)[0];
                let tryTimes = 2;
                let isSucces = false;
                // console.error("start");
                while (!isSucces && tryTimes >= 0) {
                    try {
                        qr1codeDom.innerHTML = "";//將原有的清空
                        $(qr1codeDom).qrcode({
                            size: 150,//QRCode寬、高
                            minVersion: 6,
                            maxVersion: 6,
                            ecLevel: 'L',
                            text: this.createQRCodeStr1(invoiceData.org, tryTimes)
                        });
                        isSucces = true;
                        // console.error("成功", tryTimes)
                    } catch (e) {
                        // console.error("失敗", tryTimes)
                        // console.error("error create qrcode", e);
                        tryTimes--;
                    }
                }
                // console.error("trytimes", tryTimes)

                let qr2codeDom = $("#" + 'qr2code_' + invoiceData.發票號碼)[0];
                try {
                    qr2codeDom.innerHTML = "";//將原有的清空
                    $(qr2codeDom).qrcode({
                        size: 150,
                        minVersion: 6,
                        maxVersion: 6,
                        ecLevel: 'L',
                        text: this.createQRCodeStr2(invoiceData.org, tryTimes)
                    });
                } catch (e) {
                    console.error(`${invoiceData.發票號碼} 第二個QRCode Error`);
                    $(qr2codeDom).qrcode({
                        size: 150,
                        minVersion: 6,
                        maxVersion: 6,
                        ecLevel: 'L',
                        text: ""
                    });
                }
            } catch (e) {
                console.error(`請檢查${invoiceData.發票號碼}`, e);
                console.error(e);
            }
        },
        createQRCodeStr1({ 發票號碼, 發票時間, 防偽隨機碼, 銷售總額, 發票金額, 買方, 商品明細, 加密驗證資訊, 統一編號 }, 記載筆數) {
            let str = "";
            str += 發票號碼;//1. 發票字軌 (10 位):記錄發票完整十碼號碼。
            str += 取得民國(發票時間) + new moment.unix(發票時間).format("MMDD")// 2. 發票開立日期 (7 位):記錄發票三碼民國年、二碼月份、二碼日期。
            str += 防偽隨機碼;// 3. 隨機碼 (4 位):記錄發票上隨機碼四碼。
            str += paddingLeft((parseInt(銷售總額)).toString(16), 8);// 4. 銷售額 (8 位):記錄發票上未稅之金額總計八碼,將金額轉換以十六進
            // 位方式記載。若營業人銷售系統無法順利將稅項分離計算,則以
            // 00000000 記載。
            str += paddingLeft((parseInt(發票金額)).toString(16), 8);// 5. 總計額 (8 位):記錄發票上含稅總金額總計八碼,將金額轉換以十六進
            // 位方式記載。
            str += (買方 ? 統一編號 : "00000000");// 6. 買方統一編號 (8 位):記錄發票上買受人統一編號,若買受人為一般消
            // 費者則以 00000000 記載。
            str += 網銀統編;// 7. 賣方統一編號 (8 位):記錄發票上賣方統一編號。
            str += 加密驗證資訊;// 8. 加密驗證資訊 (24 位):將發票字軌十碼及隨機碼四碼以字串方式合併
            // 後使用 AES 加密並採用 Base64 編碼轉換。
            // 以上欄位總計 77 碼。下述資訊為接續以上資訊繼續延伸記錄,且每個
            // 欄位前皆以間隔符號“:”(冒號)區隔各記載事項,若左方二維條碼不敷記載,
            // 則繼續記載於右方二維條碼。
            // 9. 營業人自行使用區 (10 位):提供營業人自行放置所需資訊,若不使用則
            str += ":**********";// 以 10 個“*”符號呈現。


            let 左邊QRCode記載筆數 = 商品明細.length >= 記載筆數 ? 記載筆數 : 商品明細.length;
            let QRCode記載筆數 = 商品明細.length >= 2 ? 2 : 商品明細.length;
            str += ":" + QRCode記載筆數;//10.二維條碼記載完整品目筆數:記錄左右兩個二維條碼記載消費品目筆數,以十進位方式記載。
            str += ":" + 商品明細.length;//11.該張發票交易品目總筆數:記錄該張發票記載總消費品目筆數,以十進位方式記載。
            str += ":" + 1;//編碼

            for (let itemCount = 0; itemCount < 左邊QRCode記載筆數; itemCount++) {
                let i = 商品明細[itemCount];
                str += ":" + i.品名;
                str += ":" + i.數量;
                str += ":" + i.單價;
            }
            console.log("qrcode1", str);
            return str;
        },
        createQRCodeStr2({ 商品明細 }, 第一張記載筆數) {
            let str = "**";
            for (let itemCount = 第一張記載筆數; itemCount < 商品明細.length && itemCount < 2; itemCount++) {
                let i = 商品明細[itemCount];
                str += ":" + i.品名;
                str += ":" + i.數量;
                str += ":" + i.單價;
            }
            // 13.品名:商品名稱,請避免使用間隔符號“:”(冒號)於品名。
            // 14.數量:商品數量,在中文編碼前,以十進位方式記載。
            // 15.單價:商品單價,在中文編碼前,以十進位方式記載。
            console.log("qrcode2", str);
            return str;
        },
    }
}
</script>
<style scoped>
@media print {
    @page {
        /*長寬不調了，直接拿印表機對*/
        /* size: A4 landscape; */
        /*隱藏頁首頁尾*/
        margin: 0px;
        width: 430px;
    }
}

.page {
    /**水平顯示或垂直顯示用 */
    /* display: inline-block; */
    /* page-break-inside: avoid; */
    /* 若順著向下排列時會切開此元素，則直接換頁 */
    page-break-after: always;
    /* 碰到此元素會直接換頁 */
    background-color: white;
    box-sizing: border-box;
    width: 430px;
    /* border: 1px solid black; */
    /* overflow: hidden; */
    /* height: 600px; */
    padding-top: 55px;
    padding-left: 25px;
    padding-right: 65px;
    /* padding-bottom: 55px; */
}

/*取消強制換頁*/
.page:last-of-type {
    page-break-after: auto;
}

.invoicePage {
    overflow: visible;
    height: 600px;
}

.font-company {
    font-size: 34px;
    text-align: center;
    margin: -5px;
}

.font-title1 {
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transform: scaleX(0.8) translate(-81px, 0px);
    width: 470px;
    /** 
    1. font-title2 的寬度是376
    2. scaleX設0.8
    3. width=376/0.8=470 
    4. translate的-81是開background直接對位置
    */
    white-space: nowrap;
}

.font-title2 {
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    margin: -18px;
}

.font-info {
    font-size: 21px;
    margin-top: -8px;
    margin-bottom: -5px;
}

.font-left {
    width: 50%;
    display: inline-block;
}

.div-code {
    margin: 5px 0px;
    text-align: center;
}
</style>