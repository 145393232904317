/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import define from '@/views/projects/NMJ/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjService.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
                else reject(res.message);
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjService.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
                else reject(res.message);
            });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjService.update(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
                else reject(res.message);
            });
        });
    },
    export({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.nmjService.queryAll(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    if (data.module == "玩家回報"){
                        res.data.result.datas.forEach(item => {
                            items.push({
                                '編號': item.reactionId,
                                '回報時間': utils.formatTime(item.reactionTime, 'YYYY/MM/DD HH:mm:ss'),
                                '玩家暱稱': item.reactionNickname,
                                '回報來源/平台': item.platform,
                                '遊玩裝置型號': item.phoneModel,
                                '裝置版本': item.systemVersion,
                                '遊戲版本': item.appVersion,
                                '問題類別': item.reactionType,
                                '處理人員': item.replyUName,
                                '處理進度': define.serviceState[item.status],
                                '回覆時間': item.replyTime ? utils.formatTime(item.replyTime, 'YYYY/MM/DD HH:mm:ss') : '',
                            });
                        });
                    } else /* 檢舉 */ {
                        res.data.result.datas.forEach(item => {
                            items.push({
                                '編號': item.reactionId,
                                '回報時間': utils.formatTime(item.reactionTime, 'YYYY/MM/DD HH:mm:ss'),
                                '玩家暱稱': item.reactionNickname,
                                '被檢舉玩家暱稱	': item.sufferUId,
                                '檢舉類別': item.reactionType,
                                '處理人員': item.replyUName,
                                '處理進度': item.status == '1' ? '不需回復' : define.serviceState[item.status],
                                '回覆時間': item.replyTime ? utils.formatTime(item.replyTime, 'YYYY/MM/DD HH:mm:ss') : '',
                            });
                        });
                    }
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_客服處理${data.module}列表.xlsx`,
                        data.module
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}