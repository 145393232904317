/* eslint-disable */
import api from '@/assets/js/app-api';

const state = {
    queryAll: {},
    importList: {},
}

// getters
const getters = {
}

// actions
const actions = {
    historyQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.historyQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    typeQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.typeQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    itemListQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.itemListQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    itemListCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.itemListCreate(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    itemQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.itemQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    itemCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.itemCreate(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    itemDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.itemDelete(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.create(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.update(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    delete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcReward.delete(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    import({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('setImportList', data);
            resolve(data);
        });
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
    setImportList(state, data) {
        state.importList = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}