<template>
    <div>
        <section class="content-header">
            <h1>歷程紀錄</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">歷程紀錄</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <label>資料表名稱：</label>
                        <select class="form-control" style="width:150px;" v-model="searchData.資料表名">
                            <option value=""> 全部 </option>
                            <option v-for="item of 資料表List">{{ item.資料表名 }}</option>
                        </select>


                        <label>異動者：</label>
                        <select class="form-control" style="width:150px;" v-model="searchData.異動者">
                            <option value=""> 全部 </option>
                            <option v-for="item of 異動者List">{{ item.異動者 }}</option>
                        </select>

                        <label>異動時間：</label>

                        <div class="form-group" style="width: 160px;margin-right: 0px;display: inline-block;">
                            <date-picker :config='{
                            format: "YYYY/MM/DD HH:mm:ss",
                            showTodayButton: true,
                            showClear: true,
                            useCurrent: false,
                            sideBySide: true,
                            locale: "zh-tw",
                        }' v-model="searchData.異動時間起始"></date-picker>
                            <!-- <DateTimePicker v-bind:propDateTime.sync="searchData.異動時間起始"></DateTimePicker> -->
                        </div>
                        <span style="margin: 0px 5px;">~</span>
                        <div class="form-group" style="width: 160px;margin-right: 5px;display: inline-block;">
                            <date-picker :config='{
                            format: "YYYY/MM/DD HH:mm:ss",
                            showTodayButton: true,
                            showClear: true,
                            useCurrent: false,
                            sideBySide: true,
                            locale: "zh-tw",
                        }' v-model="searchData.異動時間結束"></date-picker>
                            <!-- <DateTimePicker v-bind:propDateTime.sync="searchData.異動時間結束"></DateTimePicker> -->
                        </div>
                        <button type="button" class="btn btn-primary mybtn" @click="onSearch(1, searchData)">查詢</button>
                    </div>
                    <div class=" table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">編號</th>
                                    <th class="text-nowrap">資料表名</th>
                                    <th class="text-nowrap">異動種類</th>
                                    <th class="text-nowrap">原始資料</th>
                                    <th class="text-nowrap">新資料值</th>
                                    <th class="text-nowrap">異動時間</th>
                                    <th class="text-nowrap">異動者</th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="(item) in list" :key="item.編號">
                                        <td class="text-left">{{ item.編號 }}</td>
                                        <td class="text-left">{{ item.資料表名 }}</td>
                                        <td class="text-left">{{ item.異動種類 }}</td>
                                        <td class="text-left">
                                            <ul>
                                                <li v-for="(v, k) of item.原資料值Obj" :key="k">
                                                    <span>{{ k }}：</span>
                                                    <span :class="{ red: !(item.新資料值Obj[k] && item.新資料值Obj[k] == v) }">
                                                        {{ v }}
                                                    </span>
                                                </li>
                                            </ul>
                                            <!--{{ item.原資料值 }}-->
                                        </td>
                                        <td class="text-left">
                                            <ul>
                                                <li v-for="(v, k) of item.新資料值Obj" :key="k">
                                                    <span>{{ k }}：</span>
                                                    <span :class="{ red: !(item.原資料值Obj[k] && item.原資料值Obj[k] == v) }">
                                                        {{ v }}
                                                    </span>
                                                </li>
                                            </ul>
                                            <!-- {{ item.新資料值 }} -->
                                        </td>
                                        <td class="text-left">{{ item.異動時間 }}</td>
                                        <td class="text-left">{{ item.異動者 }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="7">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="total > 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import api from "@/assets/js/app-api";
import { /*IPSTool,*/ APILib } from './IPSTool2'
import datePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import Pagination from "@/components/Pagination";
export default {
    components: {
        datePicker, Pagination
    },
    data() {
        let pageSize = 10;
        return {
            pageSize,
            total: 0,
            searchData: {
                異動者: "",
                資料表名: "",
                異動時間起始: moment().format("YYYY/MM/DD 00:00:00"),
                異動時間結束: moment().format("YYYY/MM/DD 23:59:59"),
            },
            list: [],
            異動者List: [],
            資料表List: [],
        };
    },
    watch: {},
    computed: {
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.異動者List = APILib.IPS(await api.IPSSetting.HistoryManageUserList({})).content;
            this.資料表List = APILib.IPS(await api.IPSSetting.HistoryManageTableList({})).content;
            await this.onSearch(1, this.searchData);
            $(this.$refs.loading).hide();
        },
        onPageChange(pageIdx) {
            this.onSearch(pageIdx, this.lastQueryCommand);
        },
        // onPageRefresh(條件1) {
        //     this.searchData.條件1 = 條件1;
        //     this.onSearch(1, this.searchData);
        // },
        async onSearch(pageIdx, query) {
            if (moment(this.searchData.異動時間起始) >= moment(this.searchData.異動時間結束)) {
                this.$eventBus.$emit("showAlert", "請重新選取時間區間");
                return;
            }
            if (!query) {
                return;
            }
            let send = JSON.parse(JSON.stringify(query));
            send.起始行 = (pageIdx - 1) * this.pageSize + 1;
            send.結束行 = pageIdx * this.pageSize;
            if (send.異動者 == "") {
                delete send.異動者;
            }
            if (send.資料表名 == "") {
                delete send.資料表名;
            }
            $(this.$refs.loading).show();
            try {
                let res = APILib.IPS(await api.IPSSetting.HistoryManage_Search(send));
                this.list = res.content;
                this.total = res.total;
                for (let item of this.list) {
                    this.$set(item, "原資料值Obj", this.parseObject(item.原資料值));
                    this.$set(item, "新資料值Obj", this.parseObject(item.新資料值));
                }
                this.lastQueryCommand = send;
            } catch (e) {
                this.list = [];
                this.total = 0;
                this.lastQueryCommand = null;
                this.$eventBus.$emit("showAlert", e);
            }
            this.$refs.pagination.setPage(
                pageIdx,
                this.total
            );
            $(this.$refs.loading).hide();
        },
        parseObject(str) {
            let obj = {};
            try {
                obj = str == "" ? {} : JSON.parse(str);
            } catch (e) {
                obj = { 解析失敗: str };
            }
            return obj;
        }
    },
};
</script>

<style scoped>
.form-control {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
}

/**
.col-md-10 複寫DateTimePicker的樣式用
*/
.col-md-10 {
    width: 100px;
    float: none;
    display: inline-block;
    width: 190px;
}

.red {
    color: red;
}

.mybtn {
    vertical-align: baseline
}
</style>