<template>
    <div :style="{ display: visible }">
        <!-- <div class="box-header with-border">
            <h3 class="box-title">匯入進度</h3>
        </div> -->


        <div style="padding:10px;">
            <table class="table table-bordered">
                <tbody>
                    <th style="white-space:nowrap;">匯入進度</th>
                    <th>當前筆數/總筆數</th>
                    <tr>
                        <td style="width:80%;">
                            <div class="progress progress-xs">
                                <div class="progress-bar progress-bar-danger" :style="{ width: 百分比 }"></div>
                            </div>
                        </td>
                        <td style="text-align: center;"><span class="badge bg-red">{{
                            當前筆數
                        }}/{{ 總筆數 }}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="padding:10px;overflow-y: scroll;height:100px;">
            <div v-for="(log, i) of logs" :key="i">{{ log }}</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: "none",
            當前筆數: 0,
            總筆數: 0,
            logs: [],
        };
    },
    methods: {
        start(總筆數) {
            this.logs = [];
            this.visible = "";
            this.當前筆數 = 0;
            this.總筆數 = 總筆數;
        },
        addLog(log) {
            this.logs.push(log);
        },
        hide() {
            this.visible = "none";
        }
    },
    computed: {
        百分比() {
            if (this.總筆數 == 0) {
                return "0%";
            }
            return Math.floor((this.當前筆數 / this.總筆數) * 100) + "%";
        }
    },
}
</script>