<template>
  <div id="SNDetail">
    <section class="content-header">
      <h1>序號明細</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>序號管理</li>
        <li>獎項列表</li>
        <li class="active">序號明細</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-activityManger">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog w100-md"
              type="button"
            >
              <i class="fa fa-reply"></i> 獎項列表
            </button>
            
            <button 
              v-if="projectId == 'omp_mftd' || projectId == 'omp_heti'"
              @click="addSN()" 
              class="btn btn-block btn-warning btn-cog" 
              type="button"
            >
              <i class="fa fa-plus"></i> 新增序號
            </button>
            <button
              v-else
              @click="importSN()"
              class="btn btn-block btn-success btn-cog w100-md"
              type="button"
            >
              <i class="fa fa-file"></i> 匯入序號
            </button>

            <div class="select-group">
              <label class="control-label">獎項：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.awardsId"
                @change="clickSearch"
              >
                <option
                  :value="item.awardsId"
                  v-for="(item, index) in awards.datas"
                  :key="index"
                >{{item.awardsTitle}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">序號批次：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.patchId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.patchId"
                  v-for="(item, index) in queryPatch.datas"
                  :key="index"
                >{{item.patchId}}</option>
              </select>
            </div>

            <div class="select-group sW">
              <label class="control-label">輸入使用者：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋使用者"
                type="text"
                v-model="searchData.uId"
              />
            </div>

            <div class="input-group sW">
              <label class="control-label">輸入序號：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋序號"
                type="text"
                v-model="searchData.sn"
              />
              <div class="input-group-btn verAlign-bottom">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="input-group search-group">
              <div class="input-group-btn">
                <button
                  @click="DeletePatch()"
                  class="btn btn-block btn-danger btn-cog"
                  type="button"
                >
                  <i class="fa fa-trash"></i> 批次刪除
                </button>
              </div>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ exchangeCode.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 6%;">ID</th>
                  <th class="text-nowrap" style="width: 10%;">序號批次</th>
                  <th class="text-nowrap" style="width: 10%;">序號</th>
                  <th class="text-nowrap" style="width: 8%;">原廠序號</th>
                  <th class="text-nowrap" style="width: 8%;">使用者</th>
                  <th class="text-nowrap" style="width: 10%;">使用日期</th>
                  <th class="text-nowrap" style="width: 3%;">是否使用</th>
                  <th class="text-nowrap" style="width: 10%;">備註</th>
                  <th class="text-nowrap" style="width: 10%;">建立者</th>
                  <th class="text-nowrap" style="width: 10%;">建立時間</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 8%;">功能</th>
                </tr>
                <template v-if="exchangeCode.total > 0">
                  <tr :key="item.id" v-for="item in exchangeCode.datas">
                    <td align="center">{{ item.id }}</td>
                    <td align="center">{{ item.patchId }}</td>
                    <td align="center">{{ item.sn }}</td>
                    <td align="center">{{ item.vendorSN}}</td>
                    <td align="center">{{ item.uId}}</td>
                    <td align="center">{{ FormatTime(item.usedTime) }}</td>
                    <td align="center">
                      {{
                        item.isUsed == "1"
                          ? "已使用"
                          : item.isUsed == "2"
                          ? "待確認"
                          : "未使用"
                      }}
                    </td>
                    <td align="center">{{ item.note }}</td>
                    <td align="center">{{ item.createUName + " " + item.createUId }}</td>
                    <td align="center">{{ FormatTime(item.createTime) }}</td>
                    <td class="text-center">
                      <button @click="editItem(item)" class="btn btn-danger">修改</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="11">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="exchangeCode.total > 0">
          <Pagination
            ref="pagination"
            :total="exchangeCode.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>

      <publicSerialNumber ref="publicSerialNumber" />
      <settingSerialNumber ref="settingSerialNumber" />
      <EditSN ref="EditSN" />
      <importData ref="importData" />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import publicSerialNumber from "@/views/SNManager/PublicSerialNumber";
import settingSerialNumber from "@/views/SNManager/SettingSerialNumber";
import EditSN from "@/views/SNManager/EditSN";
import importData from "@/views/SNManager/importData";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
    DateRangePicker,
    DatePicker: DatePicker,
    publicSerialNumber,
    settingSerialNumber,
    importData,
    EditSN
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId, // 專案Id
      searchData: {
        sn: "",
        uId: "",
        activityId: this.$route.params.activityId,
        awardsId: this.$route.params.awardsId,
        patchId: "",
        pageNum: 1,
        isDelete: 0,
        startTime: "",
        endTime: "",
        pageSize: 10 //每頁顯示筆數
      }
    };
  },
  created() {
    this.GetSNQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      exchangeCode: state => state.exchangeCode.queryAll,
      response: state => state.exchangeCode.response,
      award: (state) => state.award.query,
      awards: state => state.award.queryAll,
      queryPatch: state => state.exchangeCode.queryPatch
    })
  },
  watch: {
    exchangeCode() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.exchangeCode.total
      );
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
        this.searchData.patchId = "";
        this.GetSNQueryAll();
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetSNQueryAll();
    },
    GetSNQueryAll() {
      //列表
      // this.searchData.isUsed = parseInt(this.searchData.isUsed);

      this.$store.dispatch(
        "exchangeCode/exchangeCodeQueryAll",
        this.searchData
      );

      this.GetAwardQuery();
      this.GetAwardQueryAll();
      this.GetPatchQueryAll();

      // if (this.$route.params.searchData != undefined)
      //   this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    GetAwardQuery() {
      //獎項
      this.$store.dispatch("award/awardQuery", {
        awardsId: this.$route.params.awardsId,
      });
    },

    GetAwardQueryAll() {
      //獎項列表
      this.$store.dispatch("award/awardQueryAll", {
        activityId: this.searchData.activityId,
        isStop: 0,
        isDelete: 0
      });
    },

    GetPatchQueryAll() {
      //序號批次
      this.$store.dispatch("exchangeCode/exchangeCodeQueryPatch", {
        activityId: this.searchData.activityId,
        awardsId: this.searchData.awardsId
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetSNQueryAll();
    },

    //*---- 新增內容
    addSN() {
      if (this.award.ruleType === "公開序號") {
        this.$refs.publicSerialNumber.showSerialNumberEdit(this.searchData);
      } else {
        this.$refs.settingSerialNumber.showSerialNumberEdit(this.searchData);
      }
    },

    importSN() {
      this.$refs.importData.showSerialNumberEdit(this.searchData);
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "AwardList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("activity/activityDelete", item);
        }
      });
    },

    //*---- 修改
    editItem(item) {
      this.$refs.EditSN.showSNEdit(item);
    },

    //批次刪除
    DeletePatch() {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch(
            "exchangeCode/exchangeCodeDeletePatch",
            this.searchData
          );
        }
      });
    },

    checkTime(item) {
      return new moment(new Date()).isAfter(item.startTime);
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return new moment(time).format("YYYY/MM/DD hh:ss:mm");
    }
  }
};
</script>

<style scoped>
.top-box .form-group {
  vertical-align: top;
  margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}

.top-box .input-group {
  min-width: 250px;
}
</style>
