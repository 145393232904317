<template>
    <div class="payStyle" v-if="上層設定">
        <ul>
            <li>廠商支付</li>
            <li>啟停用：
                <span v-if="上層設定.啟用 == 1">啟用</span>
                <span v-else class="text-red">停用</span>
            </li>
        </ul>
        <ul>
            <li>金流設定</li>
            <li>金流名稱：{{ 金流設定主檔Key[上層設定.金流設定主檔編號].金流名稱 }}</li>
            <li>金流代碼：{{ 金流設定主檔Key[上層設定.金流設定主檔編號].金流代碼 }}</li>
            <li>啟停用：
                <span v-if="金流設定主檔Key[上層設定.金流設定主檔編號].啟用 == 1">啟用</span>
                <span v-else class="text-red">停用</span>
            </li>
        </ul>
        <ul>
            <li>金流帳號</li>
            <li>名稱：{{ 金流帳號Key[上層設定.金流帳號編號].名稱 }} </li>
            <li>金流識別值：{{ 金流帳號Key[上層設定.金流帳號編號].金流識別值 }} </li>
            <li>啟停用：
                <span v-if="金流帳號Key[上層設定.金流帳號編號].啟用 == 1">啟用</span>
                <span v-else class="text-red">停用</span>
            </li>
        </ul>
        <ul>
            <li>金流支付</li>
            <li>支付名稱：{{ 金流支付設定Key[上層設定.金流支付設定編號].支付名稱 }} </li>
            <li>支付方式代碼：{{ 金流支付設定Key[上層設定.金流支付設定編號].支付方式代碼 }} </li>
            <li>地區：{{ 地區列表Key[金流支付設定Key[上層設定.金流支付設定編號].地區].地區 }}</li>
            <li>限制幣別：{{ 地區列表Key[金流支付設定Key[上層設定.金流支付設定編號].限制幣別].幣別 }} </li>
            <li>金流類型：{{ 金流類型設定Key[金流支付設定Key[上層設定.金流支付設定編號].金流類型].類型 }} </li>
            <li>是否開立發票：{{ 金流支付設定Key[上層設定.金流支付設定編號].是否開立發票 == 1 ? '是' : '否' }} </li>
            <li>啟停用：
                <span v-if="金流支付設定Key[上層設定.金流支付設定編號].啟用 == 1">啟用</span>
                <span v-else class="text-red">停用</span>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: ["上層設定", "金流設定主檔Key", "金流帳號Key", "金流支付設定Key", "地區列表Key", "金流類型設定Key"],
    // data() {
    //     return {
    //     }
    // },
    // computed: {
    // },
    // watch: {

    // },
    // methods: {
    // },
};
</script>
<style scoped>
ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.payStyle>ul>li:first-child {
    padding: 5px 0px 5px 0px;
    color: #3c8dbc;
}

.payStyle>ul {
    border-bottom: 1px solid #f4f4f4;
}

.payStyle {
    max-height: 420px;
    overflow-y: auto;
}
</style>