<template>
  <fieldset class="input-grid">
    <legend :class="{required: required || (required==null && langs.find(x=>x.required)) || false}" v-if="label">{{ label }}：</legend>
    <label v-for="lang in langs" class="input-group" :class="{required: required || (required==null && lang.required) || false}">
      <span class="input-group-addon" :title="lang.id">{{ lang.name }}</span>
      <input class="form-control"
        :disabled="disabled || (disabled==null && lang.disabled) || false"
        :required="required || (required==null && lang.required) || false"
        :readonly="readonly || (readonly==null && lang.readonly) || false"
        :placeholder="required || (required==null && lang.required) ? '*必填':''"
        @input="onInput($event, lang.id)"
        
      >
    </label>
  </fieldset>
</template>
<style scoped>
  .input-grid{
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, max(200px, 25% - 10px)), 1fr));
  }
</style>
<style scoped lang="scss">
  legend{
    font-size: larger;
    margin-bottom: 1px;
    &.required::before{
      content: "* ";
      color: #dd4b39;
    }
  }
  .input-group{
    margin: 0;
  }
  .input-group-addon, .form-control{
    padding-inline: 5px;
  }
  .input-group .input-group-addon{
    background-color: #f8f8f8;
  }
</style>
<script>
/* eslint-disable */

export default {
  props: {
    langs: {type:Array, default:()=>[{id:"zh-TW",name:"繁體中文"}]},
    prop: {type:String, default:"value"},
    label: {type:String, default:"" },
    disabled: {type:Boolean, default: null},
    required: {type:Boolean, default: null},
    readonly: {type:Boolean, default: null},
    value: {type:Array, default:()=>[]},
  },
  data() {
    return {
      datas: [],
    };
  },
  mounted() {
    this.datas = this.modelValue
  },
  computed:{
    modelValue: {
      get() { this.datas = this.value; return this.value },
      set(value) { this.$emit('input', value); }
    },
    
  },
  methods: {
    onInput(event, lang){
      let value = event.target.value;
      let langItem = this.datas.find(x => x.lang === lang);
      if (langItem){
        this.$set(langItem, this.prop, value);
      }
      else {
        this.$set(this.datas, this.datas.length, {lang:lang, [this.prop]: value});
      }
    },
  },
};
</script>