<template>
    <!-- 新增禁言 -->
    <div class="modal fade" ref="memberMuteAdd">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">新增隱藏留言</h4>
          </div>
          <div class="modal-body">
            <form class="form-horizontal">
							<div class="form-group">
								<label class="col-sm-3 control-label" for="inputMuteId">
									<span class="text-red">*</span> UId：
								</label>
								<div class="col-sm-8">
									<input
										class="form-control"
										required="required"
										type="text"
										v-model="data.mobile"
									/>
								</div>
							</div>
							<div class="form-group">
								<label class="col-sm-3 control-label" for="inputMuteDay">
										隱藏原因：
								</label>
								<div class="col-sm-8">
									<input
										class="form-control"
										min="1"
										v-model="data.hideNote"
									/>
								</div>
							</div>
            </form>
          </div>
          <div class="modal-footer">
            <button @click="submit()" class="btn btn-primary" type="button">
              新增
            </button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  
  export default {
    props: ["actionType", "checkDatas"],
    data() {
      return {
				data: {
					mobile: "",
    			hideNote: ""
				}
      };
    },
    methods: {
      showAlert() {
        this.data.mobile = "";
        this.data.hideNote = "";
        $(this.$refs.memberMuteAdd).modal("show");
      },
      hideAlert() {
        $(this.$refs.memberMuteAdd).modal("hide");
      },
      submit() {
				if(this.data.mobile === '') {
          this.$eventBus.$emit('showAlert', '請輸入UId');
					return
				}
				this.$store
          .dispatch(`newtalk/memberMuteAdd`, this.data)
          .then(() => {
            this.$emit('updateList');
            this.hideAlert();
          });
				return
      },
    },
  };
  </script>
  