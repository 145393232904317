import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    linkQueryAll({ commit }, data) {
        api.link.queryList(data).then(res => {
            if (res.data.status == "1") {
                commit('setLinkQueryAll', res.data.result);
            }
        })
    },
    linkQuery({ commit }, data) {
        api.link.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setLinkQuery', res.data.result);
            }
        })
    },
    linkCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.link.create(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'create', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    linkUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.link.update(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
    linkDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.link.delete(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'delete', null, true);
                if (res.data.status === '1') {
                    resolve(res.data.content);
                }
            });
        });
    },
}

// mutations
const mutations = {
    setLinkQueryAll(state, linkQueryAll) {
        state.queryAll = linkQueryAll;
    },
    setLinkQuery(state, linkQuery) {
        state.query = linkQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}