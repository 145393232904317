<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>遊戲操作說明列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">遊戲操作說明管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <router-link v-if="can新增" class="btn btn-block btn-warning btn-add mb-10" :to="{name:'AWH5GameOperationEdit', params:{id: 'New'}}">
              <i class="fa fa-plus"></i> 新增說明
            </router-link>
            <button v-if="can清暫存" type="button" class="btn btn-block btn-default btn-add mb-10 mr-10" @click="clearCatch()">
              <i class="fa fa-refresh"></i> 清除Cache
            </button>

            <div class="select-group mb-10">
              <select class="form-control" v-model="lang">
                <option v-for="lang in langs" :value="lang.id">{{ lang.id + ' ' + lang.name }}</option>
              </select> 
            </div>

            <div class="text">
              <p class="data-num">資料總筆數: <span id="total">{{ total }}</span></p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 5%">版本</th>
                  <th style="min-width:150px; width:10%">圖片 (內容第一張圖)</th>
                  <th style="min-width:200px; width:20%">內容</th>
                  <th style="min-width: 80px; width: 3%">排序</th>
                  <th style="min-width: 80px; width: 3%">功能</th>
                </tr>
                <tr v-if="!total"><td colspan="9">查詢無任何資料</td></tr>
                <tr v-else v-for="(item) in content" :key="item.id">
                  <td>{{ item.version }}</td>
                  <td><img :src="item.image" alt="" style="max-height:120px;" /></td>
                  <td><div v-html="item.content" class="textEllipsis text-left"></div></td>
                  <td class="text-nowrap">
                    <fieldset :disabled="!can修改" style="display:flex; flex-direction:column; gap:5px">
                      <input type="number" class="form-control" v-model.number="item.sort" min="1" :disabled="item.isStop == 1" style="padding-right:0; text-align:center;" />
                      <button type="button" class="btn btn-warning btn-add btn-upDate" @click="updateOrder(item)" :disabled="item.isStop == 1">
                        <i class="fa fa-arrows-v"></i> 更新
                      </button>
                    </fieldset>
                  </td>
                  <td>
                    <div style="display:flex;  flex-direction:column; gap:5px">
                      <router-link v-if="can修改" class="btn btn-primary" :to="{name:'AWH5GameOperationEdit', params:{id: item.gid}}">
                        <i class="fa fa-fw fa-pencil"></i> 修改
                      </router-link>
                      <button v-if="can複製" class="btn btn-warning" @click="copyItem(item.gid)">
                        <i class="fa fa-fw fa-copy"></i> 複製
                      </button>
                      <button v-if="can刪除" class="btn btn-danger" @click="deleteItem(item)">
                        <i class="fa fa-fw fa-close"></i> 刪除
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-show="total" class="box-footer text-center no-border">
          <Pagination ref="pagination" :total="total" :pageLimitCount="searchData.pageSize" :type2="true"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <!-- <pre>{{ datas }}</pre> -->
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";


export default {
  components: {
    Pagination,
  },
  props: {
    id: "",
  },
  data() {
    return {
      langs: types.langs,
      lang: types.langs[0].id,

      searchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      total: 0, //總筆數
      datas: [],

      can列表: this.$user.hasPermission(dfn.permission.遊戲操作說明列表查詢),
      can查詢: this.$user.hasPermission(dfn.permission.遊戲操作說明查詢),
      can新增: this.$user.hasPermission(dfn.permission.遊戲操作說明新增),
      can修改: this.$user.hasPermission(dfn.permission.遊戲操作說明修改),
      can刪除: this.$user.hasPermission(dfn.permission.遊戲操作說明刪除),
      can複製: this.$user.hasPermission(dfn.permission.遊戲操作說明複製),
      can清暫存: this.$user.hasPermission(dfn.permission.遊戲操作說明清暫存),
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
  },
  computed:{
    content(){
      for (let item of this.datas){
        let content = item.languageList.find(x => x.language_type == this.lang) || item.languageList[0];
        item.content =  content.content || "";
        let re = /<img.*?\ssrc="([^"]+)"[^>]*?>/;
        let match = re.exec(item.content);
        if (match){
          item.image = match[1];
          item.content = item.content.replace(re, '');
        } else {
          item.image = '';
        }
        // console.log(item.content)
      }
      return this.datas;
    }
  },  
  methods: {
    getDataList() {
      //列表
      this.$store.dispatch("xsw/遊戲操作說明列表查詢", this.searchData).then((result) => {
          let list = result.datas || [];
          
          this.datas = list;
          this.total = result.total;
          // console.log(result)
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          // this.clearCatch();
        });

    },

    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    //*---- 排序
    updateOrder(item) {
      this.$store.dispatch("xsw/遊戲操作說明修改", item).then((result) => {
        this.$eventBus.$emit("showAlert", "排序更新成功！");
        this.getDataList();
      });
    },

    //*---- 復製
    copyItem(id) {
      this.$eventBus.$emit("showConfirm", "確定要複製嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲操作說明複製", { gid: id }).then((result) => {
            this.$eventBus.$emit("showAlert", "複製成功！");
            this.getDataList();
          });
        }
      });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("xsw/遊戲操作說明刪除", item).then((result) => {
            this.$eventBus.$emit("showAlert", "刪除成功！");
            this.getDataList();
          });
        }
      });
    },

    // clear 
    clearCatch() {
      this.$store.dispatch("xsw/遊戲操作說明清暫存").then((result) => {
        console.log("已清除Catch");
        this.$eventBus.$emit("showAlert", "已清除Catch");
      });
    },
    
  },
};
</script>

<style scoped>

  .textEllipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    position: relative;
    overflow: hidden;
  }

  .textEllipsis>>>img {
    max-width: 100%;
    /* max-height: 120px; */
    height: auto;
    width: auto;
  }
</style>