<template>
  <div id="ActivityItemControl" class="slot">
    <section class="content-header">
      <h1>項目設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲管理</li>
        <li>活動機制</li>
        <li>機制項目</li>
        <li class="active">項目設定</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <h4 id="title">
            {{ $route.params.name }}
          </h4>
          <div class="top-box with-border"></div>

          <div class="form-horizontal" name="announcementForm">
            <div class="box-body">
							<div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 流程(系統)名稱：
                </label>
                <div class="col-sm-7">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="data.流程名稱"
                    maxlength="50"
                  />
									<span>{{ data.流程名稱.length }} / 50</span>
                </div>
								<div class="col-sm-3">
									<button class="btn btn-primary mr-5" :disabled="!data.流程名稱" @click="save()">儲存</button>
									<button class="btn btn-warning mr-5" :disabled="!data.流程名稱" @click="review()">預覽</button>
									<button class="btn btn-default" @click="backList()">返回</button>
								</div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 項目對應：
                </label>
                <div class="col-sm-3">
                  <!-- 主項目 -->
                  <select
                    class="form-control"
                    v-model="mainItem"
                    :disabled="!isEdit"
                  >
                    <option disabled value="">請選擇項目</option>
                    <option
                      :value="item"
                      v-for="(item, index) in mainList"
                    >
                      {{ item.項目名稱 }}
                    </option>
                  </select>
									<i
                    class="fa fa-plus"
                    v-if="mainItem != '' && mainCanChoose()"
                    @click="check('main')"
                  />
                </div>
								<div class="col-sm-3">
                  <!-- 次項目 -->
                  <select
                    class="form-control"
                    v-model="subItem"
                    :disabled="
                      mainItem == '' ||
                      mainCanChoose()
                    "
                  >
                    <option value="">請選擇項目</option>
                    <option
                      :value="item"
                      v-for="(item, index) in subList"
                    >
                      {{ item.項目名稱 }}
                    </option>
                  </select>
									<i
                    class="fa fa-plus"
                    v-if="subItem != '' && subCanChoose()"
                    @click="check('sub')"
                  />
                </div>
								<div class="col-sm-3">
                  <!-- 延伸項目 -->
                  <select
                    class="form-control"
                    v-model="extendItem"
                    :disabled="
                      mainItem == '' ||
                      mainCanChoose() || 
                      (subItem != '' && subCanChoose())
                    "
                  >
                    <option value="">請選擇項目</option>
                    <option
                      :value="item"
                      v-for="(item, index) in extendList"
                    >
                      {{ item.項目名稱 }}
                    </option>
                  </select>
									<i
                    class="fa fa-plus"
                    v-if="extendItem != '' && extendCanChoose()"
                    @click="check('extend')"
                  />
                </div>
              </div>
            </div>
          </div>
					<div class="form-group">
						<div class="col-sm-2"></div>
						<div class="col-sm-9">
              <div class="flow-header">
                <div class="text-nowrap text-center">主項目</div>
                <div class="text-nowrap text-center">次項目</div>
                <div class="text-nowrap text-center">延伸機制或活動</div>
              </div>
              <draggable
                v-model="data.項目對應"
                group="main"
                @start="drag=true"
                @end="drag=false"
                @sort="save()"
                v-if="data.項目對應 && !loading"
              >
                <div v-for="(main, m_index) in data.項目對應" class="flow-list">
                  <div class="main-flow">
                    <i class="icon-tow" v-if="data.項目對應.length > 1"></i>
                    {{ main.title }}
                    <i
                      class="fa fa-minus"
                      @click="deleteItem(data.項目對應, m_index, 'main')"
                    />
                  </div>
                  <draggable
                    v-model="main.sub"
                    :group="`sub${m_index}`"
                    @start="drag=true"
                    @end="drag=false"
                    @sort="save()"
                    class="child-flow"
                  >
                    <div v-for="(sub, s_index) in main.sub">
                      <!-- 次項目 -->
                      <div class="sub-flow">
                        <i class="icon-tow" v-if="main.sub.length > 1 && sub.title != ''"></i>
                        {{ sub.title }}
                        <i
                          class="fa fa-minus"
                          @click="deleteItem(main.sub, s_index, 'sub')"
                          v-if="sub.title != ''"
                        />
                      </div>
                      <div class="child-flow" v-if="sub.title == ''">
                        <div v-for="(extend, e_index) in sub.extend">
                          <div class="extend-flow">
                            <i class="icon-tow"></i>
                            {{ extend.title }}
                            <i
                              class="fa fa-minus"
                              @click="deleteItem(main.sub, s_index, 'extend')"
                            />
                          </div>
                        </div>
                      </div>
                      <draggable
                        v-else
                        v-model="sub.extend"
                        :group="`extend${s_index}`"
                        @start="drag=true"
                        @end="drag=false"
                        @sort="save()"
                        class="child-flow mb-10"
                      >
                        <div v-for="(extend, e_index) in sub.extend">
                          <!-- 延伸項目 -->
                          <div class="extend-flow">
                            <i class="icon-tow" v-if="sub.extend.length > 1"></i>
                            {{ extend.title }}
                            <i
                              class="fa fa-minus"
                              @click="deleteItem(sub.extend, e_index, 'extend')"
                            />
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </draggable>
                </div>
              </draggable>
						</div>
					</div>
        </div>
      </div>
    </section>
    <!--預覽-->
    <div class="modal fade" ref="review" id="activityContent">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-center">預覽</h4>
          </div>
          <div class="modal-body">
            <div class="flow-box">
              <div class="flow-item">
                <ActivityTable ref="activityTable" :data="reviewData" :isEdit="true" :id="data.遊戲編號"></ActivityTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import api from "@/assets/js/app-api";
import tinymce from "@/components/Tinymce";
import draggable from "vuedraggable";
import ActivityTable from "@/components/slot/activityTable";

export default {
  components: {
		tinymce,
    draggable,
    ActivityTable
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      id: Number(this.$route.params.id),
      isEdit: false,
      data: {
        遊戲編號: Number(this.$route.params.gameId),
        流程名稱: "",
        項目對應: [],
        是否啟用: 1
      },
      mainItem: "",
      subItem: "",
      extendItem: "",
      mainList: [],
      subList: [],
      extendList: [],
      list:[],
      reviewData: [],
		};
  },
  created() {
    if(this.id) {
      this.isEdit = true;
      this.getData();
    }
    this.getItemList();
  },
  mounted() {},
  computed: {},
  watch: {
    mainItem() {
      this.subItem = '';
      this.extendItem = '';
    },
    subItem() {
      this.extendItem = '';
    },
  },
  methods: {
    getData() {
      api.slotEvent.flow.query({ 編號: this.id })
        .then((res) =>{
          this.data = res.data.result;
          this.data.項目對應 = JSON.parse(this.data.項目對應);
        })
    },
    async getItemList() {
      for(let i=1; i<=3; i++) {
        let data = {
          遊戲編號: Number(this.data.遊戲編號),
          項目: i,//1：主項目 2：次項目 3：延伸項目
        }
        let res = await api.slotEvent.item.queryAll(data)

        if(i == 1) this.mainList = res.data.result.datas
        if(i == 2) this.subList = res.data.result.datas
        if(i == 3) this.extendList = res.data.result.datas
      }
    },
    mainCanChoose() {
      if(this.loading) return false
      if(this.data.項目對應.length == 0) return true
      let isChoose = this.data.項目對應.findIndex(item => item.title == this.mainItem.項目名稱)
      if(this.mainItem != '' && isChoose < 0) return true
      else return false
    },
    subCanChoose() {
      if(this.loading) return false
      let main = this.data.項目對應.findIndex(item => item.title == this.mainItem.項目名稱)

      let sub = this.data.項目對應[main].sub ?
        this.data.項目對應[main].sub.findIndex(item => item.title == this.subItem.項目名稱) : -1;
      if(sub >= 0) return false

      else return true
    },
    extendCanChoose() {
      if(this.loading) return false
      let main = this.data.項目對應.findIndex(item => item.title == this.mainItem.項目名稱);

      if(!this.data.項目對應[main].sub) return true
      let subList = this.data.項目對應[main].sub

      if(!this.subItem.項目名稱) {
        let isChoose = subList.findIndex(item => item.id == 0 && item.extend[0].title == this.extendItem.項目名稱) >= 0;
        return !isChoose
      }

      let sub = subList.findIndex(item => item.title == this.subItem.項目名稱);
      let isChoose = subList[sub].extend.findIndex(item => item.title == this.extendItem.項目名稱) >= 0;
      return !isChoose
    },
    check(type) {
      let mainIndex = this.data.項目對應.findIndex(item => item.title == this.mainItem.項目名稱);
      
      if(type == 'main' && mainIndex < 0) {
        this.data.項目對應.push({
          title: this.mainItem.項目名稱,
          id: this.mainItem.編號,
          sub: [],
          detail: this.mainItem.詳細說明,
        });
        this.save();
        return
      }

      let subIndex = this.data.項目對應[mainIndex].sub ? 
        this.data.項目對應[mainIndex].sub.findIndex(item => item.title == this.subItem.項目名稱) : -1;
      if(type == 'sub' && subIndex < 0 ) {
        if(subIndex < 0) {
          this.data.項目對應[mainIndex].sub.push({ 
            title: this.subItem.項目名稱,
            id: this.subItem.編號,
            extend: [],
            detail: this.subItem.詳細說明,
          });
          this.save();
          return
        }
      }

      if(type == 'extend' && subIndex < 0 ) {
        this.data.項目對應[mainIndex].sub.push({ 
          title: '',
          id: 0,
          extend: [{
            title: this.extendItem.項目名稱,
            id: this.extendItem.編號,
            detail: this.extendItem.詳細說明,
          }] 
        });
        this.save();
        return
      }

      let extendIndex = this.data.項目對應[mainIndex].sub[subIndex].extend ? 
        this.data.項目對應[mainIndex].sub[subIndex].extend.findIndex(item => item.title == this.extendItem.項目名稱) : -1;
      if(type == 'extend' && extendIndex < 0 ) {
        if(extendIndex < 0) {
          this.data.項目對應[mainIndex].sub[subIndex].extend.push({ 
            title: this.extendItem.項目名稱,
            id: this.extendItem.編號 ,
            detail: this.extendItem.詳細說明,
          });
          this.save();
          return
        }
      }

      this.$forceUpdate();
    },
    deleteItem(list, item, type) {
      let message = type == 'main' ?
        `刪除主項目將連帶刪除次項目、延伸項目，確認要刪除此筆資料？` : type == 'sub' ?
        `刪除次項目將連帶刪除延伸項目，確認要刪除此筆資料？` : `確認要刪除此筆資料？`
      this.$eventBus.$emit("showConfirm", message,
				isOK => {
					if (isOK) {
            list.splice(item, 1);
            this.save();
					}
				});
    },
    save() {
      this.loading = true;
      let type = this.isEdit ? 'update' : 'create';
      this.data.項目對應 = JSON.stringify(this.data.項目對應)
      api.slotEvent.flow[type](this.data)
        .then((res) => {
          this.data.項目對應 = JSON.parse(this.data.項目對應);
          if(res.data.status == '1') {
            this.loading = false;
            if(!this.isEdit) {
              this.isEdit = true;
              this.data.編號 = Number(res.data.result);
              this.$forceUpdate();
              return
            }
          }
        })
    },
    backList() {
			this.$router.push({
				name: 'slotActivityItem',
				params: {
          id: this.$route.params.gameId,
          name: this.$route.params.name,
				},
			});
		},
    review() {
      this.reviewData = this.data.項目對應;
      // 待優化
      setTimeout(() => {
        this.$refs.activityTable.deleteStopItem();
        $(this.$refs.review).modal("show");
      }, 100);
      setTimeout(() => {
        this.getData()
      }, 100);
    }
  },
};
</script>