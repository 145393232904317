<template>
  <div id="SMSState">
    <section class="content-header">
      <h1>簡訊狀態查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊狀態查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">

            <div class="date-group">
              <label class="control-label">時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    propsIn="format2"
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                  />
                </div>
              </div>
            </div>    
            
            <div class="text-red display-ib mr-10" style="padding-top: 7px;">
              <p>* 查詢日期區間以30天為限</p>
            </div>

            <div class="input-group display-ib mr-10" style="width: auto;">
              <div class="checkbox">
                <label>
                  <iCheckCheckBox
                    v-bind:propChecked="searchData.不分區碼YN"
                    v-on:update:checked="searchData.不分區碼YN=$event['checked']"
                  />
                </label>
                <span>不分區碼(隱藏區碼欄位)</span>
              </div>
            </div>

            <div class="input-group display-ib">
              <div class="checkbox">
                <label>
                  <iCheckCheckBox
                    v-bind:propChecked="searchData.海外YN"
                    v-on:update:checked="searchData.海外YN=$event['checked']"
                  />
                </label>
                <span>僅顯示海外簡訊</span>
              </div>
            </div>
          </div>

          <div class="top-box topBox-announceList">
            <div class="select-group" >
              <label for="" class="control-label">區碼：</label>
              <select
                class="form-control"
                v-model="searchData.區碼"
              >
                <option value="">全部</option>
                <option
                  v-for="(areaCode, index) in define.areaCodeList"
                  :value="areaCode.code"
                >
                  {{ areaCode.code }}({{ areaCode.name }})
                </option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">簡訊商：</label>
              <select
                class="form-control"
                v-model="searchData.簡訊商代碼"
              >
                <option value>全部</option>
                <template
                  v-for="(com, index) in comList"
                >
                  <option :value="com.簡訊商門號代碼">
                    {{ com.簡訊商門號說明 }}
                  </option>
                </template>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">服務列表：</label>
              <select
                class="form-control"
                v-model="searchData.帳號"
              >
                <option value>全部</option>
                <option
                  v-for="(account, index) in accountList"
                  :value="account.accId"
                >
                  {{ account.accName }}
                </option>
              </select>
            </div>

            <div class="select-group">
              <label for="" class="control-label">發送狀態：</label>
              <select
                class="form-control"
                v-model="searchData.發送狀態"
              >
              <option value>全部</option>
                <option
                  v-for="(status, index) in statusList"
                  :value="status.狀態"
                >
                  {{ status.狀態中文 }}
                </option>
              </select>
            </div>

            <div class="btns">
              <button class="btn btn-primary" type="button" @click="getDataList()">查詢</button>
            </div>
            
          </div>

          <div class="top-box">
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span>{{ total.sendTotal }}</span>
              </p>
            </div>
            
            <div class="text">
              <p class="data-num">
                <span>發送總筆數：</span>
                <span>{{ total.sendTotal }}</span>
              </p>
            </div>

            <div class="text">
              <p class="data-num">
                <span>發送總則數：</span>
                <span>{{ total.mesTotal }}</span>
              </p>
            </div>

            <div class="text">
              <p class="data-num">
                <span>到達筆數：</span>
                <span>{{ total.deliveredTotal }}</span>
              </p>
            </div>

            <div class="text">
              <p class="data-num">
                <span>到達則數：</span>
                <span>{{ total.deliveredMesTotal }}</span>
              </p>
            </div>
          </div>


          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 10%;" v-show="!codeShow">區碼</th>
                  <th class="text-nowrap" style="width: 15%">簡訊商</th>
                  <th class="text-nowrap" style="width: 10%;">服務</th>
                  <th class="text-nowrap" style="width: 15%;">發送狀態</th>
                  <th class="text-nowrap" style="width: 10%;">發送筆數</th>
                  <th class="text-nowrap" style="width: 10%;">發送則數</th>
                </tr>
                <template v-if="loading">
									<td class="text-center" colspan="7">查詢中</td>
                </template>
                <template v-else-if="data.length == 0">
                  <tr>
                    <td class="text-center" colspan="7">查詢無任何資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="item.id" v-for="(item, index) in data">
                    <td class="text-center">{{ ((searchData.頁碼 - 1) * searchData.每頁數量) + index + 1 }}</td>
                    <td class="text-center" v-show="!codeShow">{{ item.區碼 }}</td>
                    <td class="text-center">{{ comListName[item.簡訊商代碼] }}</td>
                    <td class="text-center">{{ item.名稱 }}</td>
                    <td class="text-center">{{ item.狀態 }}</td>
                    <td class="text-center">{{ item.筆數 }}</td>
                    <td class="text-center">{{ item.則數 }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="box-footer text-center no-border" v-show="news.total > 0">
          <Pagination
            ref="pagination"
            :total="news.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        簡訊商代碼: "",
        帳號: "",
        海外YN: 0,
        區碼: "",
        發送狀態: "",
        不分區碼YN: 0,
        頁碼: 1,
        每頁數量: 10,
        開始時間: new moment().subtract(7, "days").format("YYYY-MM-DD"),
        結束時間: new moment().format("YYYY-MM-DD"),
      },
      statusList: [],
      comList: [],
      comListName: [],
      accountList: [],
      data: [],
      total: {},
      codeShow: false,
    };
  },
  created() {
    this.getStatusList();
    this.getComList();
    this.getAccountList();
    this.getDataList();
  },
  mounted() {
  },
  methods: {
    getStatusList() {
      api.moloSMS.status()
        .then((res) => {
          this.statusList = res.data.result
        })
    },
    getComList() {
      api.moloSMS.user.SmsComTypeQueryAll()
        .then((res) => {
          this.comList = res.data.result;
          res.data.result.forEach(item => {
            this.comListName[item.簡訊商門號代碼] = item.簡訊商門號說明;
          });
        })
    },
    getAccountList() {
      api.moloSMS.user.queryAll({
        IsOpen: 1, //帳號 1:啟用 0:停用
        IsScmOpen: -1, //互動簡訊 1:啟用 0:停用
      })
        .then((res) => {
          this.accountList = res.data.result;
        })
    },
    getDataList() {
      this.loading = true;
      if(moment(this.searchData.結束時間).diff(moment(this.searchData.開始時間), 'days') > 30) {
        this.$eventBus.$emit("showAlert", "查詢日期區間以30天為限");
        return
      }
      api.moloSMS.service(this.searchData)
      .then((res) => {
        this.loading = false;
        this.data = res.data.result.datas;
        this.total = res.data.result;
        if(this.searchData.不分區碼YN) this.codeShow = true;
        else this.codeShow = false;
      })
    },
  },
};
</script>
<style lang="scss" scoped>
#SMSState {
  .date-group {
    display: flex;
    align-items: center;
  }
  .display-ib {
    float: none;
    display: inline-block;
  }
}
</style>