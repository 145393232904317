<template>
  <div id="NewtalkVoteResult" class="newtalk">
    <section class="content-header">
      <h1>投票結果</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/Newtalk/VoteList">投票管理</router-link>
        </li>
        <li class="active">投票結果</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title"></h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <div class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <h4 class="text-red" v-if="data.remainDayCount === 0">
                  [已截止]
                </h4>
                <h3 class="link ml-10" @click="linkItem(data)">
                  {{ data.articleTitle }}
                </h3>
              </div>

              <div class="form-group" style="font-size: 16px;">
                <label class="col-sm-2 control-label"> 投票時間： </label>
                <div class="col-sm-10 mt-5">
                  <div>
                    {{ data.pollsStartTime | dateFormat("YYYY/MM/DD HH:mm") }}
                    -
                    {{ data.pollsEndTime | dateFormat("YYYY/MM/DD HH:mm") }}
                  </div>
                </div>
              </div>

              <div class="form-group" style="font-size: 16px;">
                <label class="col-sm-2 control-label"> 投票人數： </label>
                <div class="col-sm-10 mt-5">
                  <div>
                    {{ (data.votePeopleCount || 0) | currencyFormat }}
                  </div>
                </div>
              </div>

              <div class="form-group" style="font-size: 16px;">
                <label class="col-sm-2 control-label"> 投票內文： </label>
                <div class="col-sm-10 mt-5">
                  <div v-html="data.articleContent"></div>
                </div>
              </div>
            </div>

            <div class="box-body">
              <div class="table-responsive">
                <table
                  class="table mt-30 mb-30"
                  v-for="(poll, index) in data.polls"
                  :key="index"
                >
                  <tr>
                    <th style="min-width: 60px; width: 60px"></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="question">
                      <h4>Q{{ index + 1 }}</h4>
                    </td>
                    <td>
                      <h4>{{ poll.title }}</h4>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <table class="table table-bordered table-striped tc">
                        <tbody>
                          <tr class="bg-gray">
                            <th style="min-width: 80px">序號</th>
                            <th style="min-width: 150px">選項</th>
                            <th style="min-width: 100px">票數</th>
                            <th style="min-width: 100px">比例</th>
                          </tr>
                          <tr
                            v-for="(option, oIndex) in poll.options"
                            :key="oIndex"
                          >
                            <td style="min-width: 80px; width: 80px">
                              {{ oIndex + 1 }}
                            </td>
                            <td style="min-width: 150px">
                              {{ option.title }}
                            </td>
                            <td style="min-width: 100px">
                              {{ option.voteCount | currencyFormat }}
                            </td>
                            <td style="min-width: 100px">
                              {{ getVotePercentage(option) }}%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <forumContent ref="forumContent" />
  </div>
</template>

<script>
/* eslint-disable */
import forumContent from "@/views/projects/Newtalk/ForumContent";

export default {
  components: {
    forumContent,
  },
  data() {
    return {
      id: this.$route.params.id,
      data: {
        categoryNo: 99, // 99: 投票
        articleId: "",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id) {
        this.data.articleId = this.id;
        this.$store
          .dispatch("newtalk/articleQuery", this.data)
          .then((result) => {
            this.data = result || {};
          });
      }
    },
    getVotePercentage(option) {
      return (
        (option.voteCount / (this.data.votePeopleCount || 1)) * 100
      ).toFixed(1);
    },
    linkItem(item) {
      this.$refs.forumContent.linkItem(item);
    },
    backList() {
      this.$router.push({
        name: "NewtalkVoteList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>

<style>
div {
  word-break: break-all;
}
</style>
