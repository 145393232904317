<template>
  <div id="VersionSettings">
    <section class="content-header">
      <h1>版本管理設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">版本管理設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box" v-if="maintain.maintainETime">
            <div class="form-group" style="width: auto">
              <label class="control-label">系統維護：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="maintain.maintainETime"
                    :propStartDate.sync="maintain.maintainSTime"
                  />
                </div>
              </div>
            </div>
            <div class="btns">
              <button
                @click="maintainUpdate"
                class="btn btn-primary"
                type="button"
              >
                更新
              </button>
            </div>
          </div>
          <div class="top-box">
            <button
              @click="addType()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">編號</th>
                  <th class="text-nowrap" style="min-width: 120px">平台</th>
                  <th class="text-nowrap" style="min-width: 100px">版本號</th>
                  <th style="min-width: 80px">是否強更</th>
                  <th style="min-width: 100px">停用/啟用</th>
                  <th style="min-width: 150px">建立/更新時間</th>
                  <th class="text-nowrap">更新者</th>
                  <th style="min-width: 140px">功能</th>
                </tr>
                <!-- 排序要降序 -->
                <tr v-if="loading">
                  <td colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>{{ datas.length - index }}</td>
                  <td>
                    <p v-if="!item.isCreate">
                      {{ item.platform }}
                    </p>
                    <select class="form-control" v-else v-model="item.platform">
                      <option value="Android">Android</option>
                      <option value="iOS">iOS</option>
                    </select>
                  </td>
                  <td>
                    <p v-if="!item.isEdit">
                      {{ item.versionNo }}
                    </p>
                    <input
                      class="form-control"
                      type="text"
                      v-else
                      v-model.trim="item.versionNo"
                    />
                  </td>
                  <td>
                    <p v-if="!item.isEdit">{{ item.isHard ? "是" : "否" }}</p>
                    <select
                      class="form-control"
                      v-model.number="item.isHard"
                      v-else
                    >
                      <option value="1">是</option>
                      <option value="0">否</option>
                    </select>
                  </td>
                  <td class="text-center" align="center">
                    <p v-if="item.isEdit">–</p>
                    <div class="ckbx-style-8 ckbx toggleInput" v-else>
                      <input
                        type="checkbox"
                        name="ckbx-style-8"
                        :id="`check${index}`"
                        :false-value="1"
                        :true-value="0"
                        v-model="item.isStop"
                        @change="toggle(item)"
                      />
                      <label :for="`check${index}`"></label>
                    </div>
                  </td>
                  <td>
                    {{ item.updateTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td>{{ item.updateUName }}</td>
                  <td>
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="!item.isEdit"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.isEdit"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="cancel(item)"
                      class="btn btn-default"
                      v-show="item.isEdit"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
      },
      datas: [],
      item: {},
      orginItem: {},
      maintain: {},
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.maintainQuery();
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.$store
        .dispatch("fcVersion/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.item.isCreate = false;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // 切換文字或輸入框
    change(item) {
      this.cancel(this.item);
      item.isEdit = true;
      this.item = item;
      this.orginItem.versionNo = item.versionNo;
      this.orginItem.isHard = item.isHard;
      this.$forceUpdate();
    },
    // 儲存
    save(item) {
      if (item.versionNo === "") {
        this.$eventBus.$emit("showAlert", "版號不得為空！");
        return;
      }
      if (
        this.orginItem.versionNo !== item.versionNo ||
        this.orginItem.isHard !== item.isHard ||
        item.isCreate
      ) {
        this.$store
          .dispatch(`fcVersion/${item.isCreate ? "create" : "update"}`, item)
          .then(() => {
            this.getDataList();
          });
      } else {
        this.$eventBus.$emit("showAlert", "資料沒有變動！");
      }
    },
    // 新增類別
    addType() {
      if (this.datas.length && this.datas[0].isCreate) {
        this.$eventBus.$emit("showAlert", "您尚未儲存新增項目！");
        return;
      }
      this.cancel(this.item);
      this.item = {
        platform: "Android",
        versionNo: "",
        updateTime: new Date(),
        updateUName: this.$store.state.loginUser.EmployeeName,
        isHard: 0,
        isStop: 1,
        isEdit: true,
        isCreate: true,
      };
      this.datas.splice(0, 0, this.item);
    },
    cancel(item) {
      item.isEdit = false;
      if (item.isCreate) {
        this.datas.splice(0, 1);
        item.isCreate = false;
      } else {
        item.versionNo = this.orginItem.versionNo;
        item.isHard = this.orginItem.isHard;
        this.$forceUpdate();
      }
    },
    toggle(item) {
      const action = item.isStop ? "停用" : "啟用";
      this.$eventBus.$emit("showConfirm", `確定要${action}嗎?`, (isOK) => {
        if (isOK) {
          this.$store.dispatch("fcVersion/update", item).then(() => {
            this.getDataList();
          });
        } else {
          item.isStop = item.isStop ? 0 : 1;
        }
      });
    },
    maintainQuery() {
      this.$store.dispatch("fcVersion/maintainQuery").then((result) => {
        this.maintain = result;
        this.maintain.maintainSTime = new moment(this.maintain.maintainSTime);
        this.maintain.maintainETime = new moment(this.maintain.maintainETime);
      });
    },
    maintainUpdate() {
      this.maintain.maintainSTime = new Date(
        `${this.maintain.maintainSTime} UTC`
      );
      this.maintain.maintainETime = new Date(
        `${this.maintain.maintainETime} UTC`
      );
      this.$store
        .dispatch("fcVersion/maintainUpdate", this.maintain)
        .then(() => {
          this.maintainQuery();
        });
    },
  },
};
</script>
