<template>
    <ul class="nav navbar-nav">
        <template v-if="MessageSystemEnable">
            <li class="dropdown messages-menu" :style="{ display: ServerIsOnline != 'Connected' ? 'none' : '' }">
                <div style="height: 50px; display: flex;  justify-content: center;  align-items: center;">
                    <div class="dropdown-toggle dispach dispach-open" style="display: flex;" data-toggle="dropdown"
                        v-if="IsServiceVIP">
                        <span style="font-size: 8px;padding-right: 5px;">●</span>
                        <span>&emsp;{{ $t('VIP自動派發中') }}</span>
                    </div>
                    <div class="dropdown-toggle dispach dispach-open" style="display: flex;" data-toggle="dropdown"
                        v-else-if="autoDispatch">
                        <span style="font-size: 8px;padding-right: 5px;">●</span>
                        <span>&emsp;{{ $t('自動派發中') }}</span>
                    </div>
                    <div class="dropdown-toggle dispach dispach-close" style="display: flex;" data-toggle="dropdown"
                        v-else>
                        <span style="font-size: 8px;padding-right: 5px;">●</span>
                        <span>&emsp;{{ $t('關閉派發') }}</span>
                    </div>
                    <ul class="dropdown-menu"
                        style="background-color: transparent;top: 70%;right: -8px;left: auto;border: none;">
                        <div class="mwt_border">
                            <span class="arrow_t_int"></span>
                            <span class="arrow_t_out"></span>
                            <div style="white-space: nowrap;color: white;">
                                <table class="tool-layout">
                                    <tr>
                                        <td>{{ $t('允許派發') }}：</td>
                                        <td>
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updAutoDispatch($event)">
                                                <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                                    type="checkbox" v-model="autoDispatch" />
                                                <label :for="'check'" :class="IsServiceVIP ? 'disabled' : ''"></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-show="MessageSystemVIPService" style="padding-top: 10px;">
                                        <td>{{ $t('VIP客服') }}：</td>
                                        <td>
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updServiceVIP($event)">
                                                <input :false-value="false" :true-value="true" name="ckbx-style-8"
                                                    type="checkbox" v-model="IsServiceVIP" />
                                                <label :for="'check'" :class="autoDispatch ? 'disabled' : ''"></label>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </ul>
                </div>
            </li>
        </template>
        <template v-if="ChannelManagerEnable">
            <li class="dropdown messages-menu">
                <router-link to="/ChannelMonitor/ChannelManager" class="dropdown-toggle" style="height: 50px;"
                    :title="$t('頻道管理')">
                    <i class="fa fa-television"></i>
                </router-link>
            </li>
        </template>
        <template v-if="MessageSystemEnable">
            <li class="dropdown messages-menu">
                <a style="height: 50px;" href="#" class="dropdown-toggle" :title="$t('密語')"
                    @click.prevent="updChatForm(MessageForm.Secret)">
                    <i class="fa fa-envelope"></i>
                </a>
            </li>
            <li class="dropdown messages-menu">
                <a style="height: 50px;" href="#" class="dropdown-toggle" :title="$t('訊息系統')"
                    @click.prevent="updChatForm(MessageForm.Chat)">
                    <i class=" fa fa-comments"></i>
                    <div class="unRead-div" v-show="我的未回數 > 0">
                        <span class="unRead color_red"> {{ 我的未回數 }}</span>
                    </div>
                </a>
            </li>
            <li class="dropdown messages-menu">
                <router-link to="/MessageSystem/GroupSystem" class="dropdown-toggle" style="height: 50px;"
                    :title="$t('客服群組')">
                    <i class="fa fa-group"></i>
                    <div class="unRead-div" v-show="客服未回數 > 0">
                        <span class="unRead color_blue"> {{ 客服未回數 }}</span>
                    </div>
                </router-link>
            </li>
        </template>
    </ul>
</template>
<script>
import { mapState } from "vuex";
import {
    MessageForm,
    ProjectSetting,
    InvokeCmd,
} from "@/store/MessageSystem/messageSystemConst";
export default {
    components: {

    },
    props: {
        MSProject: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            MessageForm,
            MessageSystemVIPService: false,
            MessageSystemEnable: false,
            ChannelManagerEnable: false,
        };
    },
    computed: {
        ...mapState({
            ServerIsOnline: state => state.messageSystemServer.ServerIsOnline,
            我的未回數: (state) => {
                let title = state.messageSystemServer.我的未回數 > 0 ? `(${state.messageSystemServer.我的未回數})網銀國際 營運整合工具` : `網銀國際 營運整合工具`;
                document.title = title;
                return state.messageSystemServer.我的未回數
            },
            客服未回數(state) {
                return state.messageSystemServer.ChatCsUnRead.length;
            },
            autoDispatch: state => state.messageSystemServer.autoDispatch,
            IsServiceVIP: state => state.messageSystemServer.IsServiceVIP,
        }),
    },
    mounted() {
        // this.$store.dispatch("messageSystemServer/Login", { projectId: this.$store.state.projectId });
    },
    watch: {
        MSProject: {
            handler(newProject, oldProject) {
                this.$store.dispatch("messageSystemServer/Login", { projectId: newProject });
                this.MessageSystemVIPService = this.$user.hasPermission('MessageSystemVIPService');


                // ($user.hasPermission('ChannelManager') || $user.hasPermission('MessageSystem')

                this.MessageSystemEnable = ProjectSetting.MessageSystemEnable(newProject) && this.$user.hasPermission('MessageSystem');
                this.ChannelManagerEnable = ProjectSetting.ChannelManagerEnable(newProject) && this.$user.hasPermission('ChannelManager');
            },
            immediate: true
        }
    },
    beforeDestroy() {
        this.$store.dispatch("messageSystemServer/stop", {});
        document.title = '網銀國際 營運整合工具';
    },
    methods: {
        updAutoDispatch(event) {
            event.stopPropagation();
            if (this.IsServiceVIP) {
                //防呆，IsServiceVIP為true時，不能再開啟ChangeAutoDispatch
            } else {
                this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.ChangeAutoDispatch, !this.autoDispatch]);
            }
        },
        updServiceVIP(event) {
            event.stopPropagation();
            if (this.autoDispatch) {
                //防呆，autoDispatch為true時，不能再開啟IsServiceVIP
            } else {
                this.$store.dispatch("messageSystemServer/invoke", [InvokeCmd.ChangeVipMode, !this.IsServiceVIP]);
            }
        },
        updChatForm(messageForm) {
            this.$store.dispatch("messageSystemServer/ChangeMessageForm", messageForm);
            this.$router.push({
                name: "MessageSystem",
                params: {},
            }).catch((res) => {
                // 重複點同一個路徑會拋出錯誤，先接住就好，不處理
                // console.log(res);
            });
        }
    },
};
</script>
<style scoped>
.unRead-div {
    position: relative;
    top: -1.2em;
    right: -0.5em;
}

.unRead {
    /* background-color: #fd5050;
    border-radius: 50%; */


    background-color: #fd5050;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-family: "Noto Sans TC", "Heiti TC", "Microsoft JhengHei", 微軟正黑體, Verdana;
    font-size: 8px;
    /* font-weight: 700; */
    height: 16px;
    line-height: 8px;
    margin-left: 0px;
    margin-top: -2px;
    /* min-width: 10px; */
    padding-bottom: 3px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    text-align: center;
    text-wrap: nowrap;
    transform: matrix(0.9, 0, 0, 0.9, 0, 0);
    vertical-align: top;
    white-space-collapse: collapse;
    width: 16px;
    word-break: break-all;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* margin-left: 0px;
    height: 24px;
    width: 24px px;
    border-radius: 50%;
    padding-top: 5px;
    padding-left: 0;
    padding-right: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    margin-top: -2px; */
}

.unRead.color_red {
    background-color: #fd5050;
}

.unRead.color_blue {
    background-color: #169bd5;
}

.skin-black .main-header .navbar .navbar-custom-menu .navbar-nav>li>a,
.skin-black .main-header .navbar .navbar-right>li>a {
    border-left: 0px solid #eee;
    font-size: 20px;
}

.skin-black .main-header .navbar .nav>li>a:focus {
    color: #333
}


.ckbx-style-8 label:before {
    background-color: #bfbfbf;
}

.ckbx-style-8 input[type="checkbox"]:checked+label:before {
    background-color: #169bd5;
}

/** 自動派發區塊 */
.dispach {
    line-height: 28px;
    border-radius: 30px;
    padding: 0px 13px;
    cursor: pointer;
}

.dispach-open {
    background-color: #d0eae7;
    color: #236a50;
}

.dispach-close {
    background-color: #bfbfbf;
    color: #333;
}

.mwt_border {
    width: auto;
    height: auto;
    text-align: center;
    background: #fff;
    position: relative;
    /* border: solid 1px #333; */
    margin: 8px;
    padding: 10px;
    background-color: #236a50;
    border-radius: 5px;
}

.mwt_border .arrow_t_out {
    width: 0px;
    height: 0px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent #236a50 transparent;
    position: absolute;
    top: -29px;
    /* left: 80px; */
    right: 10px;
}

.tool-layout>tr>td:first-child {
    padding-right: 5px;
}

.tool-layout>tr:not(:first-child)>td {
    padding-top: 10px;
}

.disabled {
    cursor: not-allowed;
}
</style>