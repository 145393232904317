<template>
  <div id="ElectionGroupList" class="normal">
    <section class="content-header">
      <h1>政黨列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>政黨管理</li>
        <li class="active">政黨列表</li>
      </ol>
    </section>
    <section class="content forum-announcement">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="addNew()"
            >
              <i class="fa fa-plus"></i> 新增政黨
            </button>
            <div class="select-group">
              <select
                class="form-control"
                required="required"
              >
                <option value="0">全部</option>
              </select>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px">黨名</th>
                  <th style="min-width: 100px; width: 5%;">主圖</th>
                  <th style="min-width: 150px">建立時間</th>
                  <th style="min-width: 80px">狀態</th>
                  <th style="min-width: 100px">排序</th>
                  <th style="min-width: 150px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="6">查詢中</td>
                </tr>
                <tr v-else-if="total === 0">
                  <td class="text-center" colspan="6">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in list" :key="index">
                  <td class="text-nowrap text-center">{{ item.partyName }}</td>
                  <td class="text-nowrap text-center">
                    <img :src="item.imageUrl" alt="">
                  </td>
                  <td class="text-nowrap text-center">{{ new Date(Number(item.createTime)*1000) | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                  <td class="text-nowrap text-center">{{ item.status == "1" ? "上架" : "下架" }}</td>
                  <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.order" min="1" :disabled="item.status == 0" />
                      <button
                        @click="updateData(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        v-if="item.status != 0"
                      >
                        更新
                      </button>
                    </td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary"
                      @click="edit(item)"
                    >
                      修改
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-danger"
                      @click="deleteData(item)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      searchData: {
        page: 1,
        pageSize: 10,
        // projectId: this.$store.state.projectId, // 專案Id
      }
    };
  },
  created() {
    this.getDataList();
    this.getAllDataList();
  },
  computed: {
    ...mapState({
      list: state => state.electionGroup.queryAll.datas,
      total: state => state.electionGroup.queryAll.total,
    })
  },
  mounted() {
  },
  methods: {
    getDataList() {
      this.loading = true;

      let data = new FormData();
      data.append("page", this.searchData.page);
      data.append("pageSize", this.searchData.pageSize);

      this.$store
        .dispatch(`electionGroup/queryAll`, data)
        .then((res) => {
          this.loading = false;
        })
    },
    getAllDataList() {
      let data = new FormData();
      data.append("page", 1);
      data.append("pageSize", 999);

      api.newtalk.election.group.queryAll(data)
        .then((res) => {
          console.log(res.data);
        })
    },
    addNew() {
      this.$router.push({
        name: "NewtalkElectionGroupEdit",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    edit(item) {
      this.$router.push({
        name: "NewtalkElectionGroupEdit",
        params: {
          id: item.id,
          searchData: this.$route.params.searchData,
        },
      });
    },
    deleteData(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.updateData(item, true);
        }
      });
    },
    updateData(item, isDelete) {
      let message = isDelete ? "刪除" : "更新";
      
      let data = new FormData();
      data.append("id", item.id);
      data.append("partyName", item.partyName);
      data.append("module_id", item.module_id);
      data.append("imageUrl", item.imageUrl);
      data.append("order", item.order);
      data.append("status", item.status);
      if(isDelete) data.append("isDelete", 1);

      this.$store
        .dispatch(`electionGroup/update`, data)
        .then((res) => {
          if(!res.data.isSuccess) {
          this.$eventBus.$emit("showAlert", res.data.errorMessage || `${message}失敗!`);
          return
        }
        this.$eventBus.$emit("showAlert", `${message}成功!`);
        this.getDataList();
        })
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
