<template>
    <div id="AnnouncementList">
        <section class="content-header">
            <h1>頻道管理-{{頻道名稱}}</h1>

        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button"><i
                                class="fa fa-reply"></i> 返回列表</button>

                        <div class="select-group">
                            <select class="form-control" id="selectUnit" required="required"
                                v-model="searchDataVideo.狀態" @change="clickSearch">
                                <!-- <option disabled="disabled" value>請選擇分類</option> -->
                                <option value="-1" key="1">全部狀態</option>
                                <option value="0" key="2">未處理</option>
                                <option value="1" key="3">不處理</option>
                                <option value="2" key="4">待追蹤</option>
                                <option value="3" key="5">已結案</option>
                            </select>
                        </div>
                        <div class="input-group">
                            <input class="form-control pull-right" name="table_search" placeholder="搜尋影片標題" type="text"
                                v-model="searchDataVideo.搜尋關鍵字" />
                            <div class="input-group-btn">
                                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>

                        <div class="text">
                            <p class="data-num">
                                <span v-if="searchShow = 1">資料總筆數：</span>
                                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                                <span id="total">{{ edit.total }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th style="min-width: 90px; width: 20%">影片標題</th>
                                    <th style="min-width: 90px; width: 20%">影片簡介</th>
                                    <th class="text-nowrap" style="width: 10%;">發布日期</th>
                                    <th class="text-nowrap" style="width: 5%;">狀態</th>
                                    <th class="text-nowrap" style="width: 20%;">備註</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 5%;">功能</th>
                                </tr>
                                <template v-if="edit.total > 0 ">
                                    <tr :key="'editDatas' + index" v-for="(item, index) in edit.datas">
                                        <td style="text-align:left;"><a class="linkStyle"
                                                :href="`https://www.youtube.com/watch?v=`+item.videoId"
                                                target="_blank" v-html="item.影片標題"></a></td>
                                        <td style="text-align:left;"><span class="detailStyle" v-html="changeN(item.影片簡介)"></span></td>
                                        <td style="text-align:center;">{{FormatTime(item.影片發布日期)}}</td>
                                        <td style="text-align:center;">
                                            <span v-if="item.狀態 == 0">未處理</span>
                                            <span v-if="item.狀態 == 1">不處理</span>
                                            <span v-if="item.狀態 == 2">待追蹤</span>
                                            <span v-if="item.狀態 == 3">已結案</span>
                                        </td>
                                        <td style="text-align:center;"><span>{{item.備註}}</span></td>
                                        <td style="text-align:center;" class="text-center text-nowrap">
                                            <button @click="openVideo(item)" class="btn btn-primary">詳細</button>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="10">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="edit.total > 0">
                    <Pagination ref="pagination" :total="edit.total" :pageLimitCount="searchDataVideo.pageSize"
                        v-on:pageMethod="onPageChange" />
                </div>
            </div>

            <videoAlert ref="videoAlert" @submit="getvideoQueryAll" />

        </section>
    </div>
</template>
<style lang="scss" scoped>
    #AnnouncementList {
        .pic {
            img {
                max-height: 80px;
                margin: 0 auto;
            }
        }
    }

    .table-bordered>tbody>tr>td {
        border: 1px solid #cfcfcf;
    }

    .moretd {
        div {
            border-top: 1px solid #ebebeb;
            padding: 6px 0;

            &:first-child {
                border-top: 0;
            }
        }
    }

    .linkStyle {
        cursor: pointer;
        color: black;
        border-bottom: 1px solid;
       /deep/ span{
            color: red;
        }
    }
.detailStyle{
       /deep/ span{
            color: red;
        }
    }
</style>

<script>
    /* eslint-disable */
    //import CKEditor from "@/components/CKEditor";
    import api from "@/assets/js/app-api";
    import Pagination from "@/components/Pagination";
    import utils from "@/assets/js/app-utils";
    import config from "@/assets/js/app-config";
    import moment from "moment";
    import DateRangePicker from "@/components/DateRangePicker";
    import videoAlert from "@/views/projects/smm/videoBox";
    import {
        mapState,
        mapActions
    } from "vuex";

    export default {
        components: {
            DateRangePicker,
            Pagination,
            DateRangePicker,
            videoAlert
        },
        delimiters: ["${", "}"],
        name: "app",
        props: {
            id: "",
        },
        data() {
            return {
                total: 0, //總筆數
                projectId: this.$store.state.projectId,
                moduleId: '',
                頻道名稱: '',
                searchDataVideo: {
                    頻道管理編號: "",
                    巡邏人: "",
                    狀態: -1, //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
                    搜尋關鍵字: "",
                    // 開始時間: "2023-11-06 16:50:03",
                    //  結束時間: "2023-11-06 18:50:03",
                    pageNum: 1,
                    pageSize: 20
                },
                edit: {
                    projectId: this.$store.state.projectId, // 專案Id
                    頻道管理編號: this.$route.params.id,
                    巡邏人: "",
                    狀態: -1, //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
                    搜尋關鍵字: "",
                    // 開始時間: "2023-11-06 16:50:03",
                    // 結束時間: "2023-11-06 18:50:03",
                    total: 0,
                    pageNum: 1,
                    pageSize: 10
                },
                url: config.dkOption.url,
            };
        },
        async created() {
            if (this.$route.params.searchDataVideo != undefined) {
                this.searchDataVideo = this.$route.params.searchDataVideo;
            }
            //await this.getNewsClassTypes();
            this.getvideoQueryAll();
        },
        mounted() {
            console.log("moun")
            this.getChannelName();
        },
        computed: {
            ...mapState({
                //  videoQueryAll: (state) => state.smm.videoQueryAll,
                response: (state) => state.news.response,
                projectData: (state) => state.module.queryAll,
            }),
        },
        watch: {
            newsTypes() {

            },
            getVideoQuery() {
                //this.$store.dispatch("link/linkQueryAll", this.searchDataVideo);
            },
            projectData() {
                this.langTypes = this.projectData.lang;
                this.editLang = this.langTypes[0].id;
                this.edit.lang = this.langTypes[0].id;

                this.GetNewsClassTypes();

            },
            $route(val) {
                // this.searchDataVideo.module = 'news'
                // this.isSpecialPage =  false,
                // this.getNewsClassTypes();
                this.getvideoQueryAll();
            },
            response() {
                if (this.response.status == "1")
                    this.$eventBus.$emit("showAlert", "更新成功!");
                else this.$eventBus.$emit("showAlert", "更新失敗!");
                this.getvideoQueryAll();
            },
            // news() {
            //   this.$refs.pagination.setPage(this.searchDataVideo.pageNum, this.videoQueryAll.total);
            // },
        },
        methods: {
            async onPageChange(pageIdx, pageSize) {
                this.searchDataVideo.pageNum = pageIdx;
                this.searchDataVideo.pageSize = pageSize;
                this.getvideoQueryAll();
            },
            getChannelName(){
                this.$store.dispatch("smm/channelQuery", {
                    頻道管理編號: this.$route.params.id,
                }).then((res) => {
                    this.頻道名稱=res.result.頻道名稱;
                });
            },
            getvideoQueryAll() {
                //列表
                //this.$store.dispatch("smm/videoQueryAll", this.searchDataVideo);

                this.$store.dispatch("smm/videoQueryAll", {
                    頻道管理編號: this.$route.params.id,
                    狀態: parseInt(this.searchDataVideo.狀態, 10) , //-1:全部,0:未處理,1:不處理,2:待追蹤,3:已結案
                    搜尋關鍵字: this.searchDataVideo.搜尋關鍵字,
                    pageNum: this.searchDataVideo.pageNum,
                    pageSize: this.searchDataVideo.pageSize
                }).then((res) => {
                    this.edit = res.result;
                });
                //console.log(this.edit)
                //if (this.$route.params.searchDataVideo != undefined)
                //this.$refs.pagination.setPage(this.searchDataVideo.pageNum, this.total);
            },

            // async getNewsClassTypes() {
            //   //分類
            //   this.$store.dispatch("classTypes/classTypeQueryAll", {
            //     projectId: this.searchDataVideo.projectId,
            //     module: "news" ,
            //     lang: "zh-tw",
            //     isDelete: 0,
            //     isStop: 0,
            //   });
            // },
            changeN(name) {
                return name.replace(/\n/g, '<br>');
            },
            clickSearch() {
                this.searchDataVideo.pageNum = 1;
                this.getvideoQueryAll();
            },
            //*---- 返回
            backList() {
                this.$router.push({
                    name: "smmAnnouncementList",
                    params: {
                        searchDataVideo: this.$route.params.searchDataVideo,                 
                        searchData: this.$route.params.searchData,
                    },
                });
            },
            openVideo(item) {
                this.$refs.videoAlert.openBox(item);
            },
            FormatTime(time) {
                if (time == undefined) return "";
                return new moment(time).format("YYYY/MM/DD HH:mm:ss");
            },
        },
    };

</script>
<style scoped>
    .ckbx-style-8 label:before {
        background: #999;
    }

</style>
