<template>
  <div id="CustomizeEdit">
    <section class="content-header">
      <h1>簡訊自訂發送新增/編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊自訂發送</li>
        <li class="active">簡訊自訂發送新增/編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">新增/編輯</h4>
            <button class="btn btn-block btn-default btn-cog" @click="backList()" type="button">
              返回
            </button>
          </div>

          <div class="form-horizontal">
            <div class="box-body">

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 發送簡訊商：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    v-model="data.簡訊商代碼"
                  >
                    <option
                      v-for="sendCom in sendComList"
                      :value="sendCom.簡訊商代碼"
                    >
                      {{ sendCom.簡訊商名稱 }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group mt-20 mb-20" v-if="!isEdit">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 輸入門號：
                </label>
                <div class="col-sm-8">
                  <div 
                    class="radio"
                    style="display: flex; align-items: center;"
                  >
                    <iCheckRedioButton
                      :checkValue="0"
                      message="手動，門號"
                      name="radioPhone"
                      v-model="isMutilPhone"
                    />
                    <span class="text-red ml-10">
                      *門號規則如國內：0952123456或886912333444，國外：60703755644
                    </span>
                  </div>
                  <div class="mt-10">
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="請輸入門號，多筆請用逗號(,)或換行"
                      v-model="phoneNumberInput"
                    />
                  </div>
                  <div
                    class="radio"
                    style="display: flex; align-items: center;"
                  >
                    <iCheckRedioButton
                      :checkValue="1"
                      message="匯入，上傳"
                      name="radioPhone"
                      v-model="isMutilPhone"
                    />
                    <div class="input-group ml-10">
                      <input
                        accept=".xls,.xlsx"
                        id="file"
                        type="file"
                        @change="readExcel($event)"
                      />
                    </div>
                    <a
                      href="/uploads/多筆發送範例.xlsx"
                      type="button"
                      style="text-decoration: underline;"
                      download
                      >
                      多筆發送範例 
                    </a>
                  </div>
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 簡訊內容：
                </label>
                <div class="col-sm-8">
                  <textarea
                    id="textArea"
                    class="form-control"
                    rows="3"
                    v-model="data.發送內容"
                  />
                  <div class="mt-10 mb-10">
                    <button class="btn btn-default" @click="messageModal()">
                      插入罐頭訊息
                    </button>
                  </div>
                  <span class="text-red">
                    * 多筆發送如欲套用品名/序號，請將#NAME#/#CODE#(大寫)放在您欲取代品名/序號的位置。
                    例如：恭喜您獲得#NAME# 兌換碼：#CODE#。</span>
                </div>
              </div>

              <!-- <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  備註：
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="data.備註"
                  />
                </div>
              </div> -->

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 發送：
                </label>
                <div class="col-sm-8">
                  <div class="radio">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="立即發送"
                      name="radioMode"
                      v-model="data.是否預約"
                    />
                  </div>
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        :checkValue="1"
                        message="預約發送"
                        name="radioMode"
                        v-model="data.是否預約"
                      />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker :propValue.sync="time"/>
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker" type="text" />
                      <div class="timepicker-tips">
                        <div class="text-red">*00:00/24:00 請選擇AM12:00</div>
                        <div class="text-red">*12:00       請選擇PM12:00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btns text-center mt-20" style="margin-top: 60px !important;">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-8">
                  <button class="btn btn-primary btn-lg" @click="save()" type="button">
                    完成送出
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!--新增-->
			<div class="modal fade" ref="message" id="CustomizeMessage" data-backdrop="static">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title text-center">罐頭訊息</h4>
						</div>
						<div class="modal-body">
              <div class="top-box">
                <div class="select-group" >
                  <select
                    class="form-control"
                    id="selectUnit"
                    v-model="searchData.classTypeId"
                    @change="getMessageList()"
                  >
                    <option value="">全部類型</option>
                    <option 
                      v-for="category in categoryList"
                      :value="category.classTypeId"
                    >
                      {{ category.className }}
                    </option>
                  </select>
                </div>
                <div class="input-group">
                  <input
                    class="form-control pull-right"
                    name="table_search"
                    placeholder="請輸入關鍵字"
                    type="text"
                    v-model="searchData.keyword"
                  />
                  <i class="fas fa-times" v-if="searchData.keyword.length > 0" @click="cancelKeyword()"></i>
                  <i class="fa fa-search" @click="getMessageList()"></i>
                </div>
              </div>
              <div class="message-list mt-20">
                <ul class="list-unstyled">
                  <li class="text-center" v-show="messageList.length == 0">沒有資料</li>
                  <template
                    v-for="item in messageList"
                    v-show="messageList.length > 0"
                  >
                    <li
                      @click="addMessage(item.detailTopTitle)"
                      v-if="item.isStop == 0"
                    >
                      <span>{{ item.detailTopTitle }}</span>
                    </li>
                  </template>
                </ul>
              </div>
						</div>
						<div class="modal-footer text-center">
							<button class="btn btn-default" data-dismiss="modal" type="button" @click="cancelMessage()">關閉</button>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import moment from "moment";
import xlsx from "xlsx";

export default {
  components: {
    DatePicker,
    iCheckRedioButton,
  },
  data() {
    return {
      isEdit: false,
      searchData: {
        流水號: this.$route.params.id,
      },
      data: {
        // 發送對象列表: [], 
        發送內容: "", 
        是否預約: 0, 
        預約發送時間: new moment().format("YYYY-MM-DD"), 
        備註: "", 
        簡訊商代碼: "",
      },
      phoneNumberList: [],
      isMutilPhone: 0,
      time: new moment().format("YYYY-MM-DD"),
      timepickerOption: {},
      timeStringOption: {
        timeStyle: "short"
      },
      categoryList: [],
      searchData: {
        module: "canned", //固定值
        keyword: "",
        classTypeId: "",
        isDelete: 0, //固定值
        pageNum: 1,
        pageSize: 999,
      },
      messageList: [],
      phoneNumberInput: "",
      sendComList: []
    };
  },
  computed: {
    phoneNumber() {
      let list = []
      let phone = this.phoneNumberInput.replace(/[,，]/g, '\n')
      phone = phone.split('\n');
      phone = phone.filter(el => el);
      phone.forEach(item => {
        list.push({'門號': item})
      })
      return list
    }
  },
  mounted() {
    this.getSendComList();
    this.getMessageClass();

    if(this.$route.params.id) {
      this.isEdit = true;
      this.searchData.流水號 = Number(this.$route.params.id);
      this.getData();
    }

    this.timepickerOption.showInputs = false;
    $(".timepicker").timepicker(this.timepickerOption);
  },
  methods: {
    getData() {
      this.loading = false;
      api.moloSMS.customize.query(this.searchData)
        .then((res) => {
          this.loading = true;
          this.data = res.data.result;
          if(this.data.是否預約 == 1) {
            this.time = new moment(res.data.result.預約發送時間).format("YYYY-MM-DD")
            $(".timepicker").val(
                new Date(res.data.result.預約發送時間).toLocaleTimeString("en-US", this.timeStringOption)
            );
          }
        })
    },
    getSendComList() {
      api.moloSMS.sendComList().then(({data}) => {
        this.sendComList = data.result;
      })
    },
    getMessageClass() {
      api.classType.queryAll({
        Module: "canned", //固定值
        IsStop: 0, //固定值
        IsDelete: 0, //固定值
      }).then(({data}) => {
        this.categoryList = data.result.datas;
        this.getMessageList();
      })
    },
    getMessageList() {
      api.moloSMS.message.queryAll(this.searchData)
        .then(({data}) => {
          this.messageList = data.result.datas;
        })
    },
    cancelKeyword() {
      this.searchData.keyword = "";
    },
    readExcel(e) {
      const files = e.target.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          message: "上传格式不正确，请上传xls或者xlsx格式",
          type: "warning"
        });
        return false;
      }

      this.phoneNumberList = [];
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; //取第一张表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
					console.log(ws);
          ws.forEach(item => {
            this.phoneNumberList.push(item)
          })
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    messageModal() {
      $(this.$refs.message).modal("show");
      setTimeout(() => {
        var cursorPos = $('#textArea').prop('selectionStart');
        var v = $('#textArea').val();
        var textBefore = v.substring(0,  cursorPos );
        var textAfter  = v.substring( cursorPos, v.length );
        this.data.發送內容 = textBefore+ '*message*' +textAfter;
      }, 200);
    },
    addMessage(message) {
      this.data.發送內容 = this.data.發送內容.replace('*message*', message);
      $(this.$refs.message).modal("hide");
    },
    cancelMessage() {
      this.data.發送內容 = this.data.發送內容.replace('*message*', '');
    },
    save() {
      if(!this.isEdit) {
        this.data.發送對象列表 = this.isMutilPhone == 0 ? this.phoneNumber : this.phoneNumberList; 
      } else {
        this.data.流水號 = this.searchData.流水號;
      }
      this.data.預約發送時間 = this.time + " " + $(".timepicker").val();
      this.data.預約發送時間 = new moment(this.data.預約發送時間).format("YYYY-MM-DDTHH:mm:ss")

      // console.log(this.data);
      // return
      if(this.data.發送內容 == "") {
        this.$eventBus.$emit("showAlert", "請輸入簡訊內容");
        return
      }

      let type = this.isEdit ? 'update' : 'create'
      api.moloSMS.customize[type](this.data)
        .then((res) => {
          console.log(res.data);
          this.global.showResponse(this, res, type, this.backList)
        })
    },
    backList() {
      this.$router.push({
        name: "SMSCustomize",
        params:{
          searchData: this.$route.params.searchData
        }
      })
    }
  },
};
</script>

<style lang="scss">
#CustomizeMessage {
  .top-box {
    display: flex;
    justify-content: center;
  }

  .input-group {
    width: calc(100% - 200px);
  }

  .form-control {
    border-radius: 50px;
  }

  .fa-times {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 43px;
    background: #D2D2D2;
    padding: 3px 4px;
    color: white;
    border-radius: 50px;
    z-index: 3;
    cursor: pointer;
  }

  .fa-search {
    font-size: 20px;
    position: absolute;
    top: 6px;
    right: 15px;
    z-index: 3;
    cursor: pointer;
  }

  .message-list {
    width: 90%;
    margin: 0px auto;
    font-size: 16px;

    > ul {
      max-height: 50vh;
      overflow: auto;
      > li {
        padding: 8px;
        cursor: pointer;

        &:nth-child(even) {
          background-color: #EBF7F3;
        }

        &:hover {
          background-color: #ededed;
        }
      }
    }
  }
}

#CustomizeEdit {
  .timepicker-tips {
    position: absolute;
    top: 40px;
    left: 0;
    width: 200px;
  }
}
</style>