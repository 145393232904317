<template>
  <div>
    <form class="top-box" @submit.prevent="clickSearch()">
      <button type="button" class="btn btn-block btn-warning btn-add mb-10" @click="add()">
        <i class="fa fa-plus"></i> 新增內容
      </button>
    </form>

    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <tbody>
          <tr class="bg-gray">
            <th style="min-width: 80px; width: 5%">排序</th>
            <th style="min-width:240px; width:90%">內容</th>
            <th style="min-width: 80px; width: 5%">動作</th>
          </tr>
          <tr v-if="message"><td colspan="10">{{ message }}</td></tr>
          <tr v-else-if="total <= 0"><td colspan="10">查詢無任何資料</td></tr>
          <tr v-else v-for="(item, index) in datas" :key="item.gid">
            <td class="text-nowrap">
              <div style="display:flex; flex-direction:column; gap:5px">
                <input type="number" class="form-control p-5" v-model.number="item.排序" min="1" max="" :disabled="!item.是否發佈" style="padding-right:0; text-align:center;" />
                <button type="button" class="btn btn-warning btn-add btn-upDate" @click="updateOrder(item)" :disabled="!item.是否發佈">
                  <i class="fa fa-fw fa-arrows-v"></i>更新
                </button>
              </div>
            </td>
            <td class="text-left">
              <div v-if="!item.isEdit">
                <img :src="getImage(item,'desktop')" alt="" style="max-height:100px;">
                <img :src="getImage(item,'mobile')" alt="" style="max-height:100px;">
                <div>
                  <h4>{{ getTitle(item) }}</h4>
                  <p>{{ getSubtitle(item) }}</p>
                </div>
              </div>
              <form v-else class="form-horizontal box-body">
                <fieldset class="form-group">
                  <legend>上傳 Banner：</legend>
                  <div>
                    <label class="pull-left control-label required" for="bannerPc">桌機版：</label>
                    <ImageSelect id="bannerPc"
                      :gid="item.bannerId" module="Banner" info="desktop"
                      accept="image/jpeg, image/png, image/gif" :maxsize="500*1024"
                      v-model="item.files"
                    >
                      <p class="help-block">※僅限 JPG、PNG、GIF 格式，尺寸為 00px&times;00px，檔案上限為 500KB。</p>
                    </ImageSelect>
                  </div>
                  <div>
                    <label class="pull-left control-label required" for="bannerMobile">手機版：</label>
                    <ImageSelect id="bannerMobile"
                      :gid="item.bannerId" module="Banner" info="mobile"
                      accept="image/jpeg, image/png, image/gif" :maxsize="500*1024"
                      v-model="item.files"
                    >
                      <p class="help-block">※僅限 JPG、PNG、GIF 格式，尺寸為 00px&times;00px，檔案上限為 500KB。</p>
                    </ImageSelect>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <legend class="mt-20">是否壓上標題或按鈕：</legend>
                  <div style="grid-column: 1/-1;">
                    <div class="mb-10">
                      <span class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="0" message="無" name="radio選項" v-model="item.選項" />
                      </span>
                      <span class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="1" message="標題" name="radio選項" v-model="item.選項" />
                      </span>
                      <span class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="2" message="按鈕" name="radio選項" v-model="item.選項" />
                      </span>
                    </div>
                    <div v-if="item.選項 == 1">
                      <MultilangInput label="大標題" class="mb-10" :langs="langs" v-model="item.大標題" prop="bannerTitle"></MultilangInput>
                      <MultilangInput label="小標題 (選填)" class="mb-10" :langs="langs" v-model="item.小標題" prop="bannerTitle" :required="false"></MultilangInput>
                    </div>
                    <div v-if="item.選項 == 2" style="display:flex; gap:.5em">
                      <label class="control-label required" for="btnLabel">按鈕文字：</label>
                      <input type="text" class="form-control" id="btnLabel" v-model="item.按鈕文字" maxlength="10" placeholder="統一使用英文 (限10個字元以內)">
                      <label class="control-label" for="btnLabel">{{ item.按鈕文字.length }} / 10</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <legend class="mt-20">指定連結：</legend>
                      <div class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="0" message="遊戲介紹頁：" name="radio指定連結" v-model="item.指定連結" @input="item.連結參數 = ''" />
                        <input type="text" list="gameList" class="form-control" v-model="item.連結參數" placeholder="請輸入遊戲名稱">
                        <datalist id="gameList">
                          <option v-for="game in gameList" :value="game.name">{{ /* game.name */ }}</option>
                        </datalist>
                      </div>
                      <div class="radio-inline mr-10">
                        <iCheckRedioButton :checkValue="1" message="網址：" name="radio指定連結" v-model="item.指定連結" />
                        <input type="text" class="form-control" id="btnLabel" v-model="item.連結參數" placeholder="https://">
                      </div>
                    <!-- <div class="mb-10">
                    </div>
                    <div v-if="item.選項 == 2" style="display:flex; gap:.5em">
                      <label class="control-label required" for="btnLabel">按鈕文字：</label>
                      <label class="control-label" for="btnLabel">{{ item.按鈕文字.length }} / 10</label>
                    </div> -->
                </fieldset>
              </form>
              <!-- <fieldset class="ckbx-style-8 ckbx toggleInput">
                <input type="checkbox" :true-value="true" :false-value="false" :id="'check'+index" v-model="item.是否發佈" @change="toggle(item)" />
                <label :for="'check'+index"></label>
              </fieldset> -->
            </td>
            <td>
              <fieldset style="display:flex; flex-direction:column; gap:5px" :disabled="isEditing && !item.isEdit">
                <button v-show="!item.isEdit" @click="edit(item)" class="btn btn-primary">
                  <i class="fa fa-fw fa-pencil"></i>修改
                </button>
                <button v-show="item.isEdit" @click="save(item)" class="btn btn-success">
                  <i class="fa fa-fw fa-save"></i>儲存
                </button>
                <button v-show="!item.isEdit" @click="deleteType(item, index)" class="btn btn-danger">
                  <i class="fa fa-fw fa-close"></i>刪除
                </button>
                <button v-show="item.isEdit" @click="cancel(item, index)" class="btn btn-default">
                  <i class="fa fa-fw fa-reply"></i>取消
                </button>
              </fieldset>
              <!-- <div>
                <button class="btn btn-primary" :to="{name:'AWH5GameIntroEdit',params:{id: item.gid}}">
                  <i class="fa fa-fw fa-pencil"></i>修改
                </button>
                <button class="btn btn-danger" @click="deleteItem(item)">
                  <i class="fa fa-fw fa-close"></i>刪除
                </button>
              </div> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="total" class="box-footer text-center no-border">
      <Pagination
        ref="pagination"
        :total="total "
        :pageLimitCount="searchData.pageSize"
        v-on:pageMethod="onPageChange"
        :type2="true"
      />
    </div>

    <pre>{{ datas }}</pre>
  </div>
</template>

<style scoped>
.form-horizontal{
  display:grid;
  gap: 0 1em;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 50% - 1em)), 1fr));

}
fieldset.form-group{
  display: contents;
}
.form-group::before, .form-group::after{
  content: none;
}
.table .form-group .help-block{
  display: block;
}
legend{
  font-weight: bold;
  font-size: larger;
  border: none;
  text-align: left;
  margin: 0;
  grid-column: 1 / -1;
}
[class|="col"]{
  padding-inline: 0;
}
label{
  flex: 1 0 auto;
}
label.required::before{
  content: "* ";
  color: #dd4b39;
}
</style>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import dfn from "@/views/projects/XSW/_Define";
import types from "@/views/projects/AWH5/_Define";
import ImageSelect from "@/views/projects/XSG/components/ImageSelect";
import MultilangInput from "@/views/projects/AWH5/components/MultilangInput";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    ImageSelect,
    MultilangInput,
    iCheckRedioButton,
  },
  props: {

  },
  data() {
    return {
      langs: types.projectData.langs,
      projectId: this.$store.state.projectId,
    
      searchData: {
        BannerId: "",
        開始時間: "",//new moment("2024-01-01").format("YYYY/MM/DD 00:00:00"),
        結束時間: "",//new moment().format("YYYY/MM/DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },

      datas: [],
      total: 0, //總筆數
      message: "",
      
      can列表: this.$user.hasPermission(dfn.permission.官網Banner列表查詢),
      can查詢: this.$user.hasPermission(dfn.permission.官網Banner查詢),
      can新增: this.$user.hasPermission(dfn.permission.官網Banner新增),
      can修改: this.$user.hasPermission(dfn.permission.官網Banner修改),
      can刪除: this.$user.hasPermission(dfn.permission.官網Banner刪除),

      isEditing: false,
      editBackup: null,

      gameList: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
  },
  mounted() {
    this.getDataList();
  },
  computed: {

    getImage() {
      return (item, info) => {
        const image = item.files.find((file) => file.info === info);
        return image ? image.filesUrl : "";
      };
    },
    getTitle(){
      return (item) => {
        const title = item.大標題.find(x => x.lang == "zh-TW");
        return title ? title.bannerTitle : "";
      };
    },
    getSubtitle(){
      return (item) => {
        const title = item.小標題.find(x => x.lang == "zh-TW");
        return title ? title.bannerTitle : "";
      };
    },

    getName() {
      return (item) => {
        let lang = item.languageList.find(lang => lang.language_type === "en-US");
        if (!lang) lang = item.languageList[0];
        return lang ? lang.name : "";
      }
    },
  },

  methods: {
    getDataList() {
      this.message = "查詢中";
      //列表
      this.$store.dispatch("xsw/官網Banner列表查詢", this.searchData).then((result) => {
        if (result) {
          this.datas = result.datas || [];
          // if (this.datas.length > 0) this.datas.forEach(item => item.files.sort((a, b) => b.id - a.id));
          this.total = result.total;
          this.message = "";
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }
      }, (err) => {
          this.datas = [];
          this.total = 0;
          this.message = err;
      });
    },
    getGameList(){
      if (!this.$user.hasPermission(dfn.permission.官網Banner列表查詢)) return;

      this.$store.dispatch("xsw/遊戲介紹列表查詢", {
          是否發佈: null,
          遊戲分類: "",
          遊戲名稱: "",
          語系: "tw",
          pageNum: 0,
          pageSize: 0
        }).then((result) => {
        if (result) {
          let data = result.datas || [];
          this.gameList = data.map(x => {return {id:x.遊戲館, name:x.languageList[0].name }});
        }
      });
    },

    add(){
      let newItem = {
        bannerId: utils.getTimeId,
        "選項": 2,
        "按鈕文字": "NEW",
        "指定連結": 1,
        "連結參數": "https://www.xin-stars.com/",
        "大標題": [],
        "小標題": [],
      }

    },
    edit(item){
      if (this.isEditing) return this.$eventBus.$emit("showAlert", "請先儲存上一次欲修改/添加的項目!");
      if (this.gameList.length == 0) this.getGameList();
      this.editBackup = Object.assign({}, item);
      this.isEditing = true;
      item.isEdit = true;
    },
    cancel(item, index){
      this.$set(this.datas, index, this.editBackup);
      item = this.editBackup;
      this.isEditing = false;
    },
    save(item){

    },
    delete(item){

    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

  },
};
</script>
