<template>
  <div id="PlayerSettingList">
    <section class="content-header">
      <h1>大量停權結果</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/PlayerData">玩家資料查詢</router-link></li>
        <li class="active">大量停權結果</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <router-link :to="{name:'NmjPlayerData'}" class="btn btn-default">
              <i class="fa fa-arrow-left"></i>
              玩家資料
            </router-link>
            <button type="button" @click="updateData()" class="btn btn-block btn-warning btn-add">
              <i class="fa fa-plus"></i>
              執行大量停權
            </button>
            <!-- <div class="select-group">
              <select class="form-control" v-model="searchData.blockType" @change="resetDataList()">
                <option value="">控管類型</option>
                <option value="1">停權</option>
                <option value="2">禁言</option>
              </select>
            </div>
            <div class="input-group">
              <input type="text" class="form-control" v-model.trim="searchData.playUId" placeholder="玩家UID" />
            </div>
            <div class="btns">
              <button type="button" class="btn btn-primary" @click="resetDataList()">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()">
                匯出
              </button>
            </div> -->
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <!-- <th style="width:4%"></th> -->
                  <th style="min-width: 92px; width:10%">操作<wbr>時間</th>
                  <th style="min-width: 92px; width: 5%">操作<wbr>人員</th>
                  <th style="min-width: 92px; width: 5%">類型</th>
                  <th style="min-width: 92px; width:10%">期限</th>
                  <th style="min-width: 92px; width:10%">原因</th>
                  <th style="min-width: 75px; width: 5%">執行結果</th>
                  <th style="min-width: 75px; width: 5%">名單</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="message">
                  <td colspan="11">{{ message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="11">查無控管紀錄</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <!-- <td>{{index + 1 }}</td> -->
                  <td>{{ item.起始日 }}</td>
                  <td>{{ item.操作者 }}</td>
                  <td>{{typeStatus(item.動作,item.類型, item.期限)}}
                  <!--  <br>
                  <span class="sr-only"> {{item}}</span> -->
                  </td>

                  <td>
                    {{ item.類型 ? "永久" :  item.終止日 }}</td>
                  <td>{{ item.原因 }}</td>
                  <td>{{ item.success }} / {{ item.total }}</td>
                  <td>
                    <button class="btn btn-primary" @click="showResult(item.大量停權結果名單)" >查看</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
          <!-- <pre> {{ datas }} </pre> -->
      </div>
    </section>
    <playerSettingContent
      ref="playerSettingContent"
      v-on:updateList="getDataList()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import playerSettingContent from "@/views/projects/NMJ/PlayerSettingContent";

export default {
  components: {
    DateRangePicker,
    Pagination,
    playerSettingContent,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        pageNum: 1,
        // pageSize: 4, //每頁顯示筆數
        pageSize: 20, //每頁顯示筆數
      },
      message: "",
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.$store
        .dispatch("nmjPlayerData/controlList", this.searchData)
        .then((result) => {
          this.result = result;
          let list = result.datas || [];
          this.datas = list.map(item => {
            let obj = JSON.parse(item.內容);
            obj['total'] = Object.values(obj['大量停權結果名單']).length;
            obj['success'] = Object.values(obj['大量停權結果名單']).reduce((a,i) => a + (1 - i), 0);
            return obj;
          });
          this.total = result.total;
          this.loading = false;
          this.message = "";
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        }, (msg) => {
          this.datas = [];
          this.total = 0;
          this.loading = false;
          this.message = msg;
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateData() {
      this.$refs.playerSettingContent.showContent();
    },
    showResult(result) {
      this.$refs.playerSettingContent.showResult(result);
    },
    banStatus(type, startTime, endTime){
      let str = type == 0 ? '停權 ' : '禁言 ';
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) str += '終止契約';
      else if (end.valueOf() - start.valueOf() > (3 * 24 * 60 * 60 * 1000)) str +=  '暫時凍結';
      else if (end.valueOf() === start.valueOf()) str += ' 解鎖';
      else str += '3天';
      return str
    },
    typeStatus(action,type,time) {
      //action: 0, //0=停權,1=禁言,2=限制頭圖上傳,3=限制兌換
      //type: 2, //0=自訂天數/分鐘, 2=永久 
      //time   //期限
      let ActionTime = '';
      const defaultTime = time || 3;

      const actions = [
        { name: '停權', unit: '天', default: defaultTime },
        { name: '禁言', unit: '分鐘' },
        { name: '限制頭圖上傳', unit: '天', default: defaultTime },
        { name: '限制兌換', unit: '' }
      ];

      const currentAction = actions[action];
      if (!currentAction) return '';

      const duration = type ? '永久' : `${time || currentAction.default}${currentAction.unit}`;

      ActionTime = `${currentAction.name} ${duration}`;
      return ActionTime;
    }
  },
};
</script>
