<template>
  <div id="articleEdit" class="slot max1040">
    <section class="content-header">
      <h1>遊戲內容</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>分析管理 </li>
        <li>文章列表</li>
        <li class="active">文章內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">文章內容</h4>
          </div>

          <div class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width: none;">

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> App：
                </label>
                <div class="col-sm-10">
                  <div></div>
                  <select
                    class="form-control"
                    v-model="addData.遊戲編號"
                  >
                    <option :value="0">請選擇App</option>
                    <option
                      v-for="(game, index) in gameList"
                      :value="game.編號"
                    >
                      {{ game.遊戲名稱 }}
                    </option>
                  </select>
                </div>
              </div>

							<div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 文章標題：
                </label>
                <div class="col-sm-10">
                  <template>
                    <input
                      class="form-control"
                      id="inputTitle"
                      required="required"
                      type="text"
                      v-model.trim="addData.文章標題"
                      maxlength="50"
                    />
                    <span>{{ addData.文章標題.length }} / 50</span>
                  </template>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 文章封面：
                </label>
                <div class="col-sm-10">
                  <div style="display: flex;" class="mb-20">
                    <div>
                      <img :src="addData.封面" style="max-width: 320px;"/>
                      <input
                        accept="image/*"
                        id="AnnouncementPic"
                        type="file"
                        @change="onFileChange($event)"
                      />
                      <span class="text-red">上限為 5 MB 的 PNG、JPG 檔，直式或橫式圖片，最長邊不超過 320 px。</span>
                      
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 文章摘要：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model.trim="addData.文章摘要"
                    maxlength="100"
                  />
									<span>{{ addData.文章摘要.length }} / 100</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 內文：
                </label>
                <div class="col-sm-10">
                  <span>※ 在內文中，段落標題設定為 h2 ，會在前台成為段落選單的選項。</span>
									<div class="editor mt-5">
                    <tinymce v-model="addData.內文"/>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  發布時間：
                </label>
                <div class="col-sm-10">
                  <p style="margin-top: 6px;" v-if="!addData.發佈時間">未發布</p>
                  <p style="margin-top: 6px;">{{ addData.發佈時間 | dateFormat("YYYY/MM/DD HH:mm:ss") }}</p>
                </div>
              </div>

            </div>
            <div class="box-footer">
              <div class="btns">
								<button
									class="btn btn-warning pull-left mr-10"
                  @click="preview()"
								>
                  預覽
								</button>
								<button
									class="btn btn-primary pull-left mr-10"
									@click="save(0)"
                  :disabled="
                    addData.遊戲編號 == 0 ||
                    addData.文章標題 == '' ||
                    addData.封面 == '' ||
                    addData.文章摘要 == '' ||
                    addData.內文 == ''
                  "
                  v-if="!addData.發佈時間"
								>
									草稿
								</button>
                <button
									class="btn btn-primary pull-left"
									@click="save(1)"
                  :disabled="
                    addData.遊戲編號 == 0 ||
                    addData.文章標題 == '' ||
                    addData.封面 == '' ||
                    addData.文章摘要 == '' ||
                    addData.內文 == ''
                  "
								>
									發佈
								</button>
								<button
									class="btn btn-default pull-right"
									@click="backList()"
								>
									返回
								</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--預覽-->
			<div class="modal fade" ref="preview" id="detailAlert">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
							<h4 class="modal-title text-center">預覽</h4>
						</div>
						<div class="modal-body">
              <form class="form-horizontal" name="announcementForm">
								<div class="box-body">
									
									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 遊戲：
										</label>
										<div class="col-sm-10" style="padding-top: 7px;">
											{{ setGameName(addData.遊戲編號) }}
										</div>
									</div>
                  
                  <div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 文章標題：
										</label>
										<div class="col-sm-10" style="padding-top: 7px;">
											{{ addData.文章標題 }}
										</div>
									</div>
                  
                  <div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 文章封面：
										</label>
										<div class="col-sm-10" style="padding-top: 7px;">
                      <img
												v-show="addData.圖示 != ''"
												:src="addData.封面"
												style="height: 60px; cursor: pointer;"
											/>
										</div>
									</div>

                  <div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 文章摘要：
										</label>
										<div class="col-sm-10" style="padding-top: 7px;">
											{{ addData.文章摘要 }}
										</div>
									</div>

                  <div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 內文：
										</label>
                    <div class="mb-5" v-html="addData.內文"></div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";

export default {
  components: {
    tinymce,
		iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      isEdit: false,
      id: Number(this.$route.params.id) || "",
      fileId: utils.getTimeId(),
      gameList: [],
      addData: {
        遊戲編號: 0,
        文章標題: "",
        封面: "",
        文章摘要: "",
        內文: "",
        是否啟用: 1,
        存檔型態: 0, //0:草稿,1:發佈
      },
      視覺型態: "1",
		};
  },
  created() {
    this.getGameList();
    if(this.id != "") {
      this.getData();
      this.isEdit = true;
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      imgUrlList: state => state.game.imgList
    }),
  },
  watch: {
    imgUrlList() {
      this.addData.封面 = this.imgUrlList[0].imgFullFileName;
      this.$forceUpdate();
    },
  },
  methods: {
    getGameList() {
      api.slotGame.game.queryAll({
        開發商編號: 0,
        排序條件: 3,//1:更新時間,2:建立時間,3.遊戲名稱
        排序順序: 2,//1:DESC,2:ASC
      })
        .then((res) => {
          this.gameList = res.data.result.datas;
        })
    },
    getData() {
      api.slotGame.document.query({ 編號: this.id })
        .then((res) => {
          this.addData = res.data.result
        })
    },
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片格式不符，請選擇 png 或 jpg。"
            );
            return;
          }
          console.log(fileSize);
          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片超出 5MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files, 'slotGame', this.fileId);
    },
    uploadFile(files, folderName, id, multi = false) {
    let data = new FormData();

    for (var i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    data.append("projectId", this.$store.state.projectId);
    data.append("module", folderName);
    data.append("moduleId", id);
    data.append("lang", "zh-tw");
    data.append("multi", multi);

    this.$store.dispatch("file/fileUpload", data).then(res => {
      if (res.status != "0") this.GetImgList();
    });
    },
    GetImgList() {
      this.$store.dispatch("game/gameImgList", {
        contentId: this.fileId
      });
    },
    save(SaveType) {
      this.addData.存檔型態 = SaveType;

      let type = this.isEdit ? 'update' : 'create'
      let message = this.isEdit ? '更新' : '新增'
      api.slotGame.document[type](this.addData)
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", `${message}成功`);
            this.backList()
          } else {
            this.$eventBus.$emit("showAlert", `${message}失敗`);
          }
        })
    },
    preview() {
      $(this.$refs.preview).modal("show");
    },
    setGameName(id) {
      let num = this.gameList.findIndex(item => item.編號 == id);
      return num > -1 ? this.gameList[num].遊戲名稱 : ""
    },
		backList() {
			this.$router.push({
				name: 'slotArticleList',
				params: {
					searchData: this.$route.params.searchData
				},
			});
		}
	},
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>