<template>
    <div>
        <PopForm ref="editForm" class="popform">
            <template v-slot:slot-title>
                新增/編輯資料
            </template>
            <template v-slot:slot-body>
                <span style="white-space:nowrap"><span class="text-red">*</span>產品：</span>
                <div class="input-group-for-multiselect-wrap">
                    <multiselect placeholder="請選取產品" v-model="edit.廠商" :options="廠商List" label="資料" track-by="編號"
                        :show-labels="false" :allow-empty="false">
                    </multiselect>
                    <button class="input-group-addon" @click="$emit('openFirmPopForm');"><i
                            class="fa fa-pencil"></i></button>
                </div>
                <label class="form-group"><span class="text-red">*</span>商品編號：
                    <input class="form-control" type="text" placeholder="請輸入 商品編號" v-model.trim="edit.商品編號" />
                </label>
                <label class="form-group"><span class="text-red">*</span>商品名稱：
                    <input class="form-control" type="text" placeholder="請輸入 商品名稱" v-model.trim="edit.商品名稱" />
                </label>
                <label class="form-group">顯示名稱：
                    <input class="form-control" type="text" placeholder="請輸入 顯示名稱" v-model.trim="edit.顯示名稱" />
                </label>
                <label class="form-group"><span class="text-red">*</span>分類：
                    <select class="form-control" v-model="edit.分類">
                        <option v-for="(value, k) in 分類Enum" :value="value" :key="value">{{ k }}</option>
                        <!-- <option :value="0">品項</option> -->
                        <!-- <option :value="1">金流</option> -->
                    </select>
                </label>

                <label class="form-group"><span class="text-red">*</span>彈性點：
                    <select class="form-control" v-model="edit.彈性點YN">
                        <option :value="true">Y</option>
                        <option :value="false">N</option>
                    </select>
                </label>
                <label class="form-group">ERP品號：
                    <input class="form-control" type="text" placeholder="請輸入 ERP品號" v-model.trim="edit.ERP品號" />
                </label>

                <label class="form-group">ERP名稱：
                    <input class="form-control" type="text" placeholder="請輸入 ERP名稱" v-model.trim="edit.ERP名稱" />
                </label>

                <label class="form-group">庫別：
                    <select class="form-control" v-model="edit.ERP庫別">
                        <option value="">不設定</option>
                        <option v-for="(v, k) of ERP庫別Enum" :value="v" :key="v">{{ k }}</option>
                        <!-- 
                        <option value="A13">A13非庫存倉</option>
                        <option value="A1301">A1301向序倉</option>
                        <option value="A01">A01台中倉</option>
                        <option value="A02">A02台北倉(遊E卡)</option>
                        <option value="A03">A03哲元倉</option>
                        <option value="A07">A07星城倉</option>
                        <option value="A08">A08台中倉(28FA5)</option>
                        <option value="A20">A20美卡倉</option>
                        <option value="A021">A021台北倉_虛擬</option>
                         -->
                    </select>
                </label>

                <label class="form-group"><span class="text-red">*</span>帳務幣別：
                    <select class="form-control" v-model="edit.帳務幣別">
                        <option v-for='item of $store.getters["IPSSetting/幣別列表"]' :value="item" :key="item"> {{ item }}
                        </option>
                        <!-- <option value="TWD">TWD</option> -->
                    </select>
                </label>
                <label class="form-group"><span class="text-red">*</span>介接廠商： </label>
                <button class="form-group btn btn-warning btn-xs" v-if="edit編號 == -1" @click="add介接廠商()">
                    <i class="fa fa-plus"></i> 新增
                </button>
                <fieldset v-for="(item, sn) of edit.介接廠商" :key="sn">
                    <legend>
                        <div class="input-group-for-multiselect-wrap">
                            <multiselect class="multiselect-width" placeholder="請選擇介接廠商" v-model="item.介接商"
                                :options="介接商List" label="資料" track-by="編號" :show-labels="false" :allow-empty="false">
                            </multiselect>
                            <button class="input-group-addon" style="border-left:0" @click="$emit('openFactoryPopForm');"><i
                                    class="fa fa-pencil"></i></button>
                            <button class="input-group-addon" @click="del介接廠商(item, sn)" v-if="edit編號 == -1">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </legend>
                    <label class="form-group"><span class="text-red">*</span>開立發票YN：
                        <select class="form-control" v-model="item.開立發票YN">
                            <option :value="true">Y</option>
                            <option :value="false">N</option>
                        </select>
                    </label>
                    <label class="form-group"><span class="text-red">*</span>停用/啟用：
                        <select class="form-control" v-model="item.停用YN">
                            <option :value="true">停用</option>
                            <option :value="false">啟用</option>
                        </select>
                    </label>
                    <label class="form-group"><span class="text-red">*</span>來源Key： </label>
                    <button class="form-group btn btn-warning btn-xs" @click="add來源Key(item)">
                        <i class="fa fa-plus"></i> 新增
                    </button>
                    <label class="form-group" style="display:flex" v-for="(來源, 來源sn) in item.來源Key" :key="來源sn">
                        <input class="form-control" style="" type="text" placeholder="請輸入 來源Key"
                            v-model.trim="item.來源Key[來源sn]" />
                        <button class="input-group-addon" @click="del來源Key(item.來源Key, 來源sn)">
                            <i class="fa fa-close"></i>
                        </button>
                    </label>
                </fieldset>
                <label class="form-group">備註：
                    <textarea class="form-control" placeholder="請輸入 備註" v-model.trim="edit.備註" />
                </label>
            </template>
            <template v-slot:slot-footer>
                <button class="btn" @click="closeForm()">關閉</button>
                <button class="btn btn-primary" @click="save()">儲存</button>
            </template>
        </PopForm>
    </div>
</template>
<script>
import PopForm from "@/views/Template/PopFormTemplate";
import api from "@/assets/js/app-api";
import { 分類Enum, ERP庫別Enum } from "@/store/ips/product";
import Multiselect from "vue-multiselect";
import TransEnumMixins from '@/assets/js/Tool/TransEnumMixins';
let editInit = {
    廠商: "",
    商品編號: "",
    商品名稱: "",
    顯示名稱: "",
    分類: 0,
    彈性點YN: true,
    ERP品號: "",
    ERP名稱: "",
    ERP庫別: "A13",
    帳務幣別: "TWD",
    備註: "",
    介接廠商: [
        // {
        //     介接商: "",//{}
        //     來源Key: [""],
        //     開立發票YN: true,
        //     停用YN: true,
        // }
    ],
}
export default {
    mixins: [TransEnumMixins],
    components: {
        PopForm,
        Multiselect,
    },
    data() {
        let edit = { ...editInit }
        return {
            分類Enum,
            ERP庫別Enum,
            edit編號: -1,//修改時，該項目的唯一編號
            edit,
            廠商List: [],
            介接商List: [],
        }
    },
    watch: {
        ["$store.state.product.products"]: {
            handler(v) {
                if (v[1]) {
                    this.廠商List = JSON.parse(JSON.stringify(v[1]));
                    if (this.edit.廠商) {
                        let find廠商 = this.廠商List.find((廠商item) => { return 廠商item.編號 == this.edit.廠商.編號; });
                        if (find廠商) {
                            this.$set(this.edit.廠商, '資料', find廠商.資料);
                        } else {
                            this.edit.廠商 = "";
                        }
                    } else {
                        this.edit.廠商 = "";
                    }
                }
                if (v[2]) {
                    this.介接商List = JSON.parse(JSON.stringify(v[2]));
                    for (let 介接廠商 of this.edit.介接廠商) {
                        if (介接廠商.介接商 && 介接廠商.介接商.編號) {
                            let find介接商 = this.介接商List.find((介接商item) => { return 介接商item.編號 == 介接廠商.介接商.編號; });
                            if (find介接商) {
                                this.$set(介接廠商.介接商, '資料', find介接商.資料);
                            } else {
                                介接廠商.介接商 = "";
                            }
                        } else {
                            介接廠商.介接商 = "";
                        }
                    }
                }
            },
            deep: true
        }
    },
    mounted() {
    },
    methods: {
        closeForm() {
            this.$refs.editForm.hide();
        },
        openAddForm() {
            this.edit編號 = -1;
            this.edit = { ...editInit };
            this.edit.介接廠商 = [];
            this.edit.介接廠商.push({
                介接商: "",
                來源Key: [""],
                開立發票YN: false,
                停用YN: false,
            })
            this.$refs.editForm.show();
        },
        openUpdForm(item) {
            this.edit編號 = item.編號;
            let find廠商 = this.廠商List.find((廠商item) => { return 廠商item.編號 == item.廠商編號; });
            if (find廠商) {
                this.$set(this.edit, '廠商', {});
                this.$set(this.edit.廠商, '編號', find廠商.編號);
                this.$set(this.edit.廠商, '資料', find廠商.資料);
                this.$set(this.edit.廠商, '類別', find廠商.類別);
            } else {
                this.edit.廠商 = "";
            }
            this.edit.商品編號 = item.商品編號;
            this.edit.商品名稱 = item.商品名稱;
            this.edit.顯示名稱 = item.顯示名稱;
            this.edit.分類 = item.分類;
            this.edit.彈性點YN = item.彈性點YN;
            this.edit.ERP品號 = item.erP品號;
            this.edit.ERP名稱 = item.erP名稱;
            this.edit.ERP庫別 = item.erP庫別;
            this.edit.帳務幣別 = item.帳務幣別;
            this.edit.備註 = item.備註;
            this.edit.介接廠商 = [];
            let find介接商 = this.介接商List.find((介接商item) => { return 介接商item.編號 == item.介接廠商編號; });
            this.edit.介接廠商.push({
                介接商: find介接商 ? {
                    編號: find介接商.編號,
                    資料: find介接商.資料,
                    類別: find介接商.類別
                } : "",
                來源Key: JSON.parse(JSON.stringify(item.來源Key_Array)),
                開立發票YN: item.開立發票YN,
                停用YN: item.停用YN,
            });
            this.$refs.editForm.show();
        },
        add介接廠商() {
            this.edit.介接廠商.push({
                介接商: "",
                來源Key: [""],
                開立發票YN: false,
                停用YN: false,
            })
        },
        del介接廠商(item, sn) {
            if (this.edit.介接廠商.length <= 1) {
                return this.$eventBus.$emit("showAlert", "至少要有一筆介接廠商");
            }
            this.edit.介接廠商.splice(sn, 1);
        },
        add來源Key(item) {
            item.來源Key.push("");
        },
        del來源Key(來源, sn) {
            if (來源.length <= 1) {
                return this.$eventBus.$emit("showAlert", "至少要有一筆來源Key資料");
            }
            來源.splice(sn, 1);
        },
        async save() {
            try {
                {
                    //檢查資料
                    if (!(this.edit.廠商 && this.edit.廠商.編號 > 0)) {
                        return this.$eventBus.$emit("showAlert", "請選擇產品");
                    }
                    if (!this.edit.商品編號) {
                        return this.$eventBus.$emit("showAlert", "請輸入商品編號");
                    }
                    if (!this.edit.商品名稱) {
                        return this.$eventBus.$emit("showAlert", "請輸入商品名稱");
                    }
                    // if (!this.edit.ERP品號) {
                    //     return this.$eventBus.$emit("showAlert", "請輸入ERP品號");
                    // }
                    // if (!this.edit.ERP名稱) {
                    //     return this.$eventBus.$emit("showAlert", "請輸入ERP名稱");
                    // }
                    if (this.edit.介接廠商.length == 0) {
                        return this.$eventBus.$emit("showAlert", "請至少選擇一個介接廠商");
                    }
                    let len = this.edit.介接廠商.length;
                    for (let i = 0; i < len; i++) {
                        let 介接廠商 = this.edit.介接廠商[i];
                        if (!(介接廠商.介接商 && 介接廠商.介接商.編號 > 0)) {
                            return this.$eventBus.$emit("showAlert", `第${(i + 1)}筆請選擇介接廠商`);
                        }

                        if (介接廠商.來源Key.length <= 0) {
                            return this.$eventBus.$emit("showAlert", `第${(i + 1)}筆請輸入來源Key`);
                        }

                        let len2 = 介接廠商.來源Key.length;
                        for (let j = 0; j < len2; j++) {
                            if (!介接廠商.來源Key[j]) {
                                return this.$eventBus.$emit("showAlert", `第${(i + 1)}筆的第${(j + 1)}筆資料請輸入來源Key`);
                            }
                        }
                    }
                }

                this.$refs.editForm.loading(true);
                if (this.edit編號 == -1) {
                    let pen = 0;
                    for (let 介接廠商 of this.edit.介接廠商) {
                        pen = pen + 1;
                        let cmd = {
                            廠商編號: this.edit.廠商.編號,
                            商品編號: this.edit.商品編號,
                            商品名稱: this.edit.商品名稱,
                            顯示名稱: this.edit.顯示名稱,
                            介接廠商編號: 介接廠商.介接商.編號,
                            來源Key_Array: 介接廠商.來源Key,
                            分類: this.edit.分類,
                            彈性點YN: this.edit.彈性點YN,
                            ERP品號: this.edit.ERP品號,
                            ERP名稱: this.edit.ERP名稱,
                            ERP庫別: this.edit.ERP庫別,
                            帳務幣別: this.edit.帳務幣別,
                            開立發票YN: 介接廠商.開立發票YN,
                            停用YN: 介接廠商.停用YN,
                            備註: this.edit.備註
                        }
                        let res = await api.IPSSetting.AddProductData(cmd);
                        if (res && res.data && res.data.sucess) {
                            //
                        } else {
                            let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                            throw { close: true, msg: `第${pen}筆新增失敗，錯誤訊息：${errMsg}` };
                        }
                    }
                    throw { close: true, msg: `新增成功:共${this.edit.介接廠商.length}筆` };
                } else {
                    let 介接廠商 = this.edit.介接廠商[0];
                    let cmd = {
                        編號: this.edit編號,
                        廠商編號: this.edit.廠商.編號,
                        商品編號: this.edit.商品編號,
                        商品名稱: this.edit.商品名稱,
                        顯示名稱: this.edit.顯示名稱,
                        介接廠商編號: 介接廠商.介接商.編號,
                        來源Key_Array: 介接廠商.來源Key,
                        分類: this.edit.分類,
                        彈性點YN: this.edit.彈性點YN,
                        ERP品號: this.edit.ERP品號,
                        ERP名稱: this.edit.ERP名稱,
                        ERP庫別: this.edit.ERP庫別,
                        帳務幣別: this.edit.帳務幣別,
                        開立發票YN: 介接廠商.開立發票YN,
                        停用YN: 介接廠商.停用YN,
                        備註: this.edit.備註
                    }
                    let res = await api.IPSSetting.UpdateProductData(cmd);
                    if (res && res.data && res.data.sucess) {
                        throw { close: true, msg: `修改成功` };
                    } else {
                        let errMsg = res && res.data && res.data.errMsg ? res.data.errMsg : "未知";
                        throw { close: false, msg: `修改失敗，錯誤訊息：${errMsg}` };
                    }
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e.msg ? e.msg : e);
                this.$refs.editForm.loading(false);
                if (e.close === false) {
                    //不做任何事
                } else {
                    //關閉
                    this.closeForm();
                }
                this.$emit('reload');
            }
        }
    },
};
</script>
<style src="@/assets/css/default/ips.css" lang="css" scoped />
<style scoped>
.popform>>>.modalWidth {
    width: 800px;
}

.multiselect-width {
    width: 233px;
}

.input-group-for-multiselect-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    margin-bottom: 5px
}

.input-group-for-multiselect-wrap>>>.multiselect__single {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
}

.input-group-for-multiselect-wrap>>>.multiselect__input {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}

.input-group-for-multiselect-wrap>.input-group-addon {
    border-radius: 0;
    border-color: #d2d6de;
    background-color: #fff;
}

fieldset {
    border: 1px solid #367fa9;
    border-radius: 10PX;
    padding: 10px 15px 15px 15px;
    margin-bottom: 15px;
    color: #c9c9c9;
}

legend {
    width: auto;
    margin-bottom: 0;
    font-size: 1em;
    border: 0 solid #e5e5e5;
    color: #c9c9c9;
}
</style>