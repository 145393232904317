<template>
  <div id="MftdContactUsList">
    <section class="content-header">
      <h1>聯繫客服列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">聯繫客服列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="input-group mr-20">
              <label class="control-label">連絡電話：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋連絡電話"
                type="text"
                v-model="searchData.phoneNumber"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">角色名稱：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋角色名稱"
                type="text"
                v-model="searchData.name"
              />
            </div>

            <div class="input-group mr-20">
              <label class="control-label">填單開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker :propValue.sync="searchData.local.startTime" />
              </div>
            </div>

            <div class="input-group">
              <label class="control-label">填單結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker :propValue.sync="searchData.local.endTime" />
              </div>
            </div>

            <div class="text mt-25">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>

            <div class="form-group fl mr-20 mt-25 w100-md mt-15-md">
              <button
                @click="resetDataList()"
                class="btn btn-warning w100-md"
                type="button"
              >
                查詢
              </button>
            </div>

            <div class="form-group fl mr-20 mt-25 w100-md mt-15-md">
              <button
                @click="resetSearch()"
                class="btn btn-danger w100-md"
                type="button"
              >
                清除
              </button>
            </div>

            <div class="form-group fl mt-25 w100-md mt-15-md">
              <button
                @click="exportOrders()"
                class="btn btn-success w100-md"
                type="button"
                v-if="total > 0"
              >
                匯出
              </button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 1%">編號</th>
                  <th class="text-nowrap" style="min-width: 150px">填單時間</th>
                  <th class="text-nowrap" style="width: 10%">角色名稱</th>
                  <th class="text-nowrap" style="width: 10%">玩家ID</th>
                  <th class="text-nowrap" style="min-width: 150px">Email</th>
                  <th class="text-nowrap" style="min-width: 120px">連絡電話</th>
                  <th class="text-nowrap" style="width: 15%">主旨</th>
                  <th class="text-nowrap" style="width: 5%">狀態</th>
                  <th class="text-nowrap" style="width: 7%">處理人員</th>
                  <th class="text-nowrap text-center">功能</th>
                </tr>
                <tr v-if="loading">
                  <td align="center" colspan="10">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td align="center" colspan="10">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td align="center">{{ index + 1 }}</td>
                  <td align="center">{{ item.createTime }}</td>
                  <td align="center">{{ item.name }}</td>
                  <td align="center">{{ item.companyName }}</td>
                  <td align="center">{{ item.email }}</td>
                  <td align="center">{{ item.phoneNumber }}</td>
                  <td>{{ item.subject }}</td>
                  <td align="center">
                    {{ define.handleStatus[item.status] }}
                  </td>
                  <td align="center">{{ item.updateUName }}</td>
                  <td class="text-center">
                    <button @click="updateData(item)" class="btn btn-primary">
                      編輯
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DatePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment().format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
        phoneNumber: "",
        name: "",
        status: -1,
      },
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} 00:00:00 UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} 23:59:59 UTC`
      );
      this.$store
        .dispatch("mftdBefore/contactQueryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    exportOrders() {
      this.$store.dispatch("mftdBefore/contactExport", this.searchData);
    },
    updateData(item) {
      this.$router.push({
        name: "MftdContactUsEdit",
        params: {
          id: item.contactUsId,
          searchData: this.searchData,
        },
      });
    },
    resetSearch() {
      this.searchData = {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20,
        local: {
          startTime: new moment().format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
        phoneNumber: "",
        name: "",
        status: -1,
      };
    },
  },
};
</script>
