/* eslint-disable */
import api from '@/assets/js/app-api';

const state = {
    queryAll: {},
    history: {}
}

// getters
const getters = {
}

// actions
const actions = {
    itemList({ commit }, isReload){
        if (isReload){
            return new Promise((resolve, reject) => {
                api.nmjMail.itemReload().then(res => {
                    if (res.data.status === '1') {
                        const result = res.data.result;
                        resolve(result);
                    }
                    else reject(res.data.message);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                api.nmjMail.itemList().then(res => {
                    if (res.data.status === '1') {
                        const result = res.data.result;
                        resolve(result);
                    }
                    else reject(res.data.message);
                });
            });
        }
    },
    query({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMail.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
                else reject(res.data.message);
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMail.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
                else reject(res.data.message);
            });
        });
    },
    create({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMail.create(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
                else reject(res.data.message);
            });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMail.update(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
                else reject(res.data.message);
            });
        });
    },
    delete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMail.delete(data).then(res => {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
                else reject(res.data.message);
            });
        });
    },
    history({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjMail.history(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setHistory', result);
                    resolve(result);
                }
                else reject(res.data.message);
            });
        });
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
    setHistory(state, history) {
        state.history = history;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}