import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    bannerQueryAll({ commit }, data) {
        api.banner.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setBannerQueryAll', res.data.result);
            }
        })
    },
    bannerQuery({ commit }, data) {
        api.banner.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setBannerQuery', res.data.result);
            }
        })
    },
    bannerCreate({ commit }, data) {
        api.banner.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    bannerUpdate({ commit }, data) {
        api.banner.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    bannerDelete({ commit }, data) {
        api.banner.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    bannerImgList({ commit }, data) {
        api.banner.imgList(data).then(res => {
            if (res.data.status == "1") {
                commit('setImgList', res.data.result.imgUrlList);
            }
        })
    },
}

// mutations
const mutations = {
    setBannerQueryAll(state, bannerQueryAll) {
        bannerQueryAll.datas.forEach(banner =>{
            banner.isLinkOpen = parseInt(banner.isLinkOpen);
        })
        state.queryAll = bannerQueryAll;
    },
    setBannerQuery(state, bannerQuery) {
        state.query = bannerQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setImgList(state, imgList) {
        state.imgList = imgList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}