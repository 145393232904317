<template>
    <div style="background-color: white;">
        <template v-for="item of list">
            <div class="page" v-if="item.買方">
                <!--發票-->
                <InvoiceElectronicProcessPrintMain :item="item" ref="childComp"></InvoiceElectronicProcessPrintMain>
                <!--明細-->
                <InvoiceElectronicProcessPrintItem :item="item" ref="childComp"></InvoiceElectronicProcessPrintItem>
            </div>
            <template v-else>
                <!--發票-->
                <InvoiceElectronicProcessPrintMain :item="item" ref="childComp"></InvoiceElectronicProcessPrintMain>
                <!--明細-->
                <InvoiceElectronicProcessPrintItem :item="item" ref="childComp"></InvoiceElectronicProcessPrintItem>
            </template>
        </template>
    </div>
</template>
<script>
import moment from "moment";
import InvoiceElectronicProcessPrintMain from "@/views/projects/ips/InvoiceElectronicProcessPrintMain.vue";
import InvoiceElectronicProcessPrintItem from "@/views/projects/ips/InvoiceElectronicProcessPrintItem.vue";
import { 稅別Enum, 網銀統編, 手開發票列印狀態Enum, 四捨五入 } from "@/views/projects/ips/InvoiceElectronicProcessConst.js";

function paddingLeft(str, length) {
    if (str.length >= length)
        return str;
    else
        return paddingLeft("0" + str, length);
}
function 取得民國(發票時間) {
    let 西元 = new moment.unix(發票時間).format("YYYY");
    let 民國 = parseInt(西元) - 1911;
    return 民國;
}
function 取得期數(發票時間) {
    let month = parseInt(new moment.unix(發票時間).format("MM"));
    if (month % 2 == 0) {
        month = month - 1;
    }
    return paddingLeft(month + "", 2) + "-" + paddingLeft((month + 1) + "", 2);
}
function 取得期別ForBarCode(發票時間) {
    let 民國 = 取得民國(發票時間);
    let month = parseInt(new moment.unix(發票時間).format("MM"));
    if (month % 2 == 1) {
        month = month + 1;
    }
    return 民國 + paddingLeft(month + "", 2);
}
export default {
    components: {
        InvoiceElectronicProcessPrintMain,
        InvoiceElectronicProcessPrintItem
    },
    data() {
        return {
            debug: true,
            手開發票列印狀態Enum,
            網銀統編,
            稅別Enum,
            list: [],
        }
    },
    mounted() {
        try {
            let datas = localStorage.getItem('printerData');
            datas = JSON.parse(datas);
            for (let data of datas) {
                data.發票時間 = parseInt(data.發票時間.toString().substring(0, data.發票時間.toString().length - 3));//毫秒轉成秒
                let printData = {
                    編號: data.編號,
                    barcode: this.createBarCodeStr(data),
                    // qrcode1: this.createQRCodeStr1(data),
                    // qrcode2: this.createQRCodeStr2(data),
                    列印狀態: data.列印狀態,
                    民國: 取得民國(data.發票時間),//112
                    期數: 取得期數(data.發票時間),//"01-02"
                    發票號碼: data.發票號碼,
                    時間: new moment.unix(data.發票時間).format("YYYY-MM-DD 23:59:59"),
                    隨機碼: data.防偽隨機碼,
                    發票金額: data.發票金額,
                    賣方: 網銀統編,
                    買方: data.統一編號,//一般不印 買受人為營業機關或團體，要印
                    備註: data.備註,
                    營業稅別: data.營業稅別,
                    營業稅: data.營業稅,
                    商品明細: [],
                    銷售總額: data.銷售總額,
                    加密驗證資訊: data.加密驗證資訊,
                    org: data,
                }
                for (let 商品 of data.商品明細) {
                    printData.商品明細.push({
                        品名: 商品.品名,
                        數量: 商品.數量,
                        單價: 商品.單價,
                        金額: 四捨五入(商品.數量, 商品.單價)
                    })
                }
                this.list.push(printData);
            }
        } catch (e) {
            console.error(e);
        }
        this.$nextTick(() => {
            const promises = this.$refs.childComp.map(childComponent => {
                return childComponent.$nextTick();
            });
            Promise.all(promises).then(() => {
                // 所有子组件的 nextTick 完成后，执行父组件的逻辑
                setTimeout(() => {
                    window.print();
                    alert("列印完畢");
                    window.close();
                }, 3000);
            });
        });
    },
    methods: {
        createBarCodeStr({ 發票號碼, 防偽隨機碼, 發票時間 }) {
            let str = "";
            str += 取得期別ForBarCode(發票時間);// 1. 發票期別 (5):記錄發票開立當期後月之三碼民國年份二碼月份。//
            str += 發票號碼;// 2. 發票字軌號碼 (10):記錄發票完整十碼號碼。
            str += 防偽隨機碼;// 3. 隨機碼 (4):記錄發票上四碼隨機碼。
            return str;
        },
    }
}
</script>
<style scoped>
@media print {
    @page {
        /*長寬不調了，直接拿印表機對*/
        /* size: A4 landscape; */
        /*隱藏頁首頁尾*/
        margin: 0px;
        width: 430px;
    }
}

.page {
    /**水平顯示或垂直顯示用 */
    /* display: inline-block; */
    /* page-break-inside: avoid; */
    /* 若順著向下排列時會切開此元素，則直接換頁 */
    page-break-after: always;
    /* 碰到此元素會直接換頁 */
    background-color: white;
    box-sizing: border-box;
    width: 430px;
    /* border: 1px solid black; */
    padding-top: 55px;
    padding-left: 25px;
    padding-right: 65px;
}

/*取消強制換頁*/
.page:last-of-type {
    page-break-after: auto;
}
</style>