<template>
  <div id="WaninAdvertisementList">
    <section class="content-header">
      <h1>廣告列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>廣告管理</li>
        <li class="active">廣告列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增圖片
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.classTypeId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in bannerTypes.datas"
                  :key="index"
                >
                  {{ item.className }}
                </option>
              </select>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="(searchShow = 1)">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ banners.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">分類</th>
                  <th style="min-width: 90px; width: 5%">主圖</th>
                  <th style="min-width: 90px; width: 13%">名稱</th>
                  <th class="text-nowrap" style="width: 10%">建立日期</th>
                  <th class="text-nowrap" style="width: 5%">
                    {{ projectId == "omp_fantacity" ? "正式" : "" }}狀態
                  </th>
                  <th class="text-nowrap" style="width: 10%">發布時間</th>
                  <th
                    class="text-nowrap"
                    style="width: 5%"
                    v-if="projectId == 'omp_fantacity'"
                  >
                    測試狀態
                  </th>
                  <!-- <th class="text-nowrap" style="width: 5%">排序</th> -->
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 5%"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="banners.total > 0">
                  <tr :key="item.id" v-for="item in banners.datas">
                    <td>{{ item.className }}</td>
                    <td>
                      <div class="pic jqimgFill">
                        <!-- 430 * 330 -->
                        <img :src="item.filesUrl" v-if="item.filesUrl != 'https://storage.wanin.tw/omp_wanin//'" draggable="false" />
                      </div>
                    </td>
                    <td>{{ item.bannerTitle }}</td>
                    <td align="center">{{ item.createTime }}</td>
                    <td align="center">{{ GetStatus(item, false) }}</td>
                    <td align="center" v-if="projectId == 'omp_fantacity'">
                      {{ GetStatus(item, true) }}
                    </td>
                    <!-- <td class="td-sort text-nowrap">
                      <input
                        type="number"
                        v-model="item.orderNum"
                        min="1"
                        :disabled="item.isStop == 1"
                      />
                      <button
                        @click="updateBannerOd(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        v-if="item.isStop != 1"
                      >
                        更新
                      </button>
                    </td> -->
                    <td align="center">{{ item.formalStartTime }}</td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">
                        修改
                      </button>
                      &nbsp;
                      <button @click="deleteItem(item)" class="btn btn-danger">
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="banners.total > 0"
        >
          <Pagination
            ref="pagination"
            :total="banners.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        // projectId: this.$store.state.projectId,
        module: "Giving",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetBannerClassTypes();
    this.GetBannerQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      bannerTypes: (state) => state.classTypes.queryAll,
      banners: (state) => state.banner.queryAll,
      response: (state) => state.banner.response,
    }),
  },
  watch: {
    bannerTypes() {},
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetBannerQueryAll();
    },
    banners() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.banners.total
      );
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetBannerQueryAll();
    },

    GetBannerQueryAll() {
      //列表
      this.$store.dispatch("banner/bannerQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "Giving",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    updateBannerOd(item) {
      let data = this.searchData;

      api.banner.queryAll({
        module: "banner",
        classTypeId: item.classTypeId,
        Lang: data.lang,
        isDelete: 0,
        isStop:0,
        pageNum: 1,
        pageSize: 1, //每頁顯示筆數
      }).then((res) => {
        if (res.data.status == "1") {
          let count = parseInt(res.data.result.total);
          item.orderNum = parseInt(item.orderNum);
          if (item.orderNum == 0 || isNaN(item.orderNum)) item.orderNum = 1;
          // item.isStop = parseInt(item.isStop);
          // if (item.orderNum > count) {
          //   this.$eventBus.$emit(
          //     "showAlert",
          //     "排序不可大於最大排序(" + count + ")!!"
          //   );
          //   return;
          // }

          this.$store.dispatch("banner/bannerUpdate", item);
        }
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetBannerQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "WaninAdvertisementEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "WaninAdvertisementEdit",
        params: { id: item.bannerId, searchData: this.searchData },
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("banner/bannerDelete", item);
        }
      });
    },

    GetStatus(item, isTest) {
      if (this.projectId == "omp_fantacity") {
        if (!isTest) {
          if (
            new moment().isBefore(item.formalStartTime) ||
            new moment().isAfter(item.formalEndTime)
          )
            return "下架";
          else return "上架";
        } else {
          if (
            new moment().isBefore(item.testStartTime) ||
            new moment().isAfter(item.testEndTime)
          )
            return "下架";
          else return "上架";
        }
      } else {
        return item.isStop == "1" ? "下架" : "上架";
      }
    },
  },
};
</script>
