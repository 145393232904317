<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>跑馬燈設定</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">跑馬燈設定</li>
      </ol>
    </section>
    <section class="content customer-service">
      <div class="box box-primary">
        <!-- <pre>{{ searchData }}</pre> -->
          <!-- 活動公告設定 -->
          <div class="box-body">
            <div class="top-box">
              <button type="button" @click="addNew()" class="btn btn-block btn-warning btn-add fa fa-plus"> 新增跑馬燈</button>
              <div class="select-group">
                <select class="form-control" v-model.number="searchData.類型" @change="resetDataList()">
                  <option value="-1">全部類型</option>
                  <option v-for="(option,i) in def.marqueeType" :value="i">{{ option }}</option>
                </select>
              </div>
              <div class="select-group">
                <select class="form-control" v-model.number="searchData.狀態" @change="resetDataList()">
                  <option value="-1">全部狀態</option>
                  <option value="0">草稿</option>
                  <option value="1">立即發送</option>
                  <option value="3">排程發送</option>
                  <option value="2">已刪除</option>
                  <!-- <option v-for="(option,i) in def.marqueeState" :value="i">{{ option }}</option> -->
                </select>
              </div>
              <!-- <div class="btns">
                  <button type="button" @click="resetDataList()" class="btn btn-primary">查詢</button>
              </div> -->
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered table-center min-800 AdListTable text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 60px; width: 5%; word-break:keep-all">編號</th>
                    <th style="min-width: 80px; width: 5%; word-break:keep-all">狀態</th>
                    <th style="min-width: 80px; width: 5%; word-break:keep-all">平台</th>
                    <th style="min-width: 80px; width: 5%; word-break:keep-all">類型</th>
                    <th style="min-width:160px; width:20%; word-break:keep-all">內容</th>
                    <th style="min-width: 80px; width: 5%; word-break:keep-all">循環<wbr>時間<wbr>(分)</th>
                    <!-- <th style="min-width: 88px; width: 5%; word-break:keep-all">排序</th> -->
                    <th style="min-width: 92px; width:10%; word-break:keep-all">上線<wbr>時間</th>
                    <th style="min-width: 92px; width:10%; word-break:keep-all">下線<wbr>時間</th>
                    <th style="min-width:140px; width: 5%; word-break:keep-all">功能</th>
                  </tr>
                </thead>
                <tbody v-if="total > 0">
                  <tr v-for="(item, index) in datas" :key="item.id" :class="{'bg-gray-light':/已過期|已刪除|已發送/.test(GetStatus(item))}">
                    <td>{{ item.id }}</td>
                    <td>
                      <span :data-class="{'text-red':/已過期|已刪除|已發送/.test(GetStatus(item))}">{{ GetStatus(item) }}</span>
                    </td>
                    <td>{{ GetPlatfrom(item.顯示平台) }}</td>
                    <td>{{ def.marqueeType[item.類型] }}</td>
                    <td><span class="text-clamp" onclick="this.classList.toggle('text-clamp')">{{ item.內容 }}</span></td>
                    <td>{{ item.狀態 != 1 ? item.循環時間 : "-" }}</td>
                    <!-- <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.排序" min="1" :max="total" />
                      <button type="button" @click="updateSort(item)" class="btn btn-warning btn-add btn-upDate">更新</button>
                    </td> -->
                    <td>{{ uiDate(item.開始時間) }}</td>
                    <td>{{ item.狀態 != 1 ? uiDate(item.結束時間) : "-" }}</td>
                    <td>
                      <template v-if="!/已過期|已刪除|已發送/.test(GetStatus(item))">
                      <button type="button" @click="editorItem(item)" class="btn btn-primary">修改</button>&nbsp;
                      <button type="button" @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="11">查詢沒有資料</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="box text-center no-border no-shadow mt-20">
              <Pagination
                :pageLimitCount="searchData.pageSize"
                :total="total"
                ref="pagination"
                v-on:pageMethod="onPageChange"
              />
            </div>
            <!-- <pre>{{ datas }}</pre> -->
          </div>

          <!-- 頁數 -->
      </div>
    </section>
  </div>
</template>
<style scoped>
.text-clamp{
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import { ToggleButton } from "vue-js-toggle-button";
import { mapState } from "vuex";
import { get } from "sortablejs";

export default {
  components: {
    DateRangePicker,
    Pagination,
    ToggleButton
  },
  data() {
    return {
      def: define,
      loading: true,
      message: "",
      total: 0, //總筆數
      datas: [],
      searchData: {
        類型: -1, // def.marqueeType
        狀態: -1, // def.marqueeState
        // 平台: "ALL",
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
        // 開始時間: new moment().subtract(7, "days").format("YYYY-MM-DDT00:00:01"),
        // 結束時間: new moment().add(0, "days").format("YYYY-MM-DDT23:59:59"),
      },
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  mounted() {
    window.v = this;
  },
  // computed: {
  //   ...mapState({
  //     event: (state) => state.fcEventAnnouncement.queryAll,
  //   }),
  // },
  // watch: {
  //   bannerTypes() {},
  //   response() {
  //     if (this.response.狀態 == "1")
  //       this.$eventBus.$emit("showAlert", "更新成功!");
  //     else this.$eventBus.$emit("showAlert", "更新失敗!");

  //     this.getDataList();
  //   },
  //   event() {
  //     this.$refs.pagination.setPage(
  //       this.searchData.pageNum,
  //       this.fcEventAnnouncement.total
  //     );
  //   },
  // },
  methods: {
    resetDataList() {
      // this.searchData.開始時間 = new Date(0).toJSON();
      // this.searchData.結束時間 = new moment().add(10, "days").format("YYYY-MM-DDT23:59:59");
      
      this.searchData.pageNum = 1;
      // console.log(this.searchData.分類, this.searchData.狀態)
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      // this.searchData.開始時間 = this.searchData.開始時間.replace(/\//g, "-").replace(" ", "T").replace(/(Z|\+.*)?$/, "");
      // this.searchData.結束時間 = this.searchData.結束時間.replace(/\//g, "-").replace(" ", "T").replace(/(Z|\+.*)?$/, "");
      this.$store
      .dispatch("nmjMarquee/queryAll", this.searchData)
      .then((result) => {
        this.result = result;
        this.datas = result.datas || [];
        this.total = result.total;
        this.loading = false;
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);

      },
      (reject) => {
          this.total = 0;
          this.loading = false;
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },

    updateSort(item) {
      // item.排序 = parseInt(item.排序);
      if (item.排序 == 0 || isNaN(item.排序)) item.排序 = 1;
      if (item.排序 > this.total)
        return this.$eventBus.$emit( "showAlert", "排序不可大於最大排序(" + this.total + ")!!");

      this.$store.dispatch("nmjMarquee/update", item).then(() => {
        this.getDataList()
      });;
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "NmjMarqueeEdit",
        params: {
          type: this.searchData.分類,
          searchData: this.searchData,
        },
      });
    },
    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "NmjMarqueeEdit",
        params: { 
          id: item.id,
          type: this.searchData.分類,
          searchData: this.searchData,
        }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjMarquee/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },

    //*---- 公告狀態
    //0:草稿    1:已發送  2:已刪除
    //3:等待上線  4:進行中  5:已過期
    GetStatus(item) {
      let type = +item.狀態;
      if (typeof type != "number") type = 0;
      if (type == 0) return "草稿";
      if (type == 1) return "已發送";
      if (type == 2) return "已刪除";
      if (type == 3) {
        if (Date.now() < new Date(item.開始時間)) return "等待上線";
        if (Date.now() < new Date(item.結束時間)) return "進行中";
        return "已過期";
      }
    },
    GetPlatfrom(item) {
      let arr = item.split(",");
      if (arr.length == 0) return "無";
      if (arr.length == Object.keys(this.def.gamePlatform).length) return "全部";
      return arr.map(i => this.def.gamePlatform[i]).join(", ");
    },
    uiDate(date){
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>