/* eslint-disable */
import api from '@/assets/js/app-api';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    // shareQueryAll({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         api.dem.queryAll.shareQueryAll(data).then(res => {
    //             if (res.data.status === '1') {
    //                 const result = res.data.result;
    //                 commit('setQueryAll', result);
    //                 resolve(result);
    //             }
    //         });
    //     });
    // },
    GamerQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.dem.before.GamerQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setGamerQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.dem.before.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
    setGamerQueryAll(state, GamerQueryAll) {
        state.GamerQueryAll = GamerQueryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}