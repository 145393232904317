<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>服務範疇編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>服務範疇列表</li>
        <li class="active">服務範疇編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option
                      :key="index"
                      :value="item.classTypeId"
                      v-for="(item, index) in gameTypes.datas"
                    >{{item.className}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"
                      @click="ChangeLang(item.id)"
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div>
              
              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="Pic">
                  <span class="text-red">*</span>遊戲簡介：
                </label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    placeholder="遊戲簡介...."
                    v-model="edit.detailTopInfo"
                    style="height:150px;"
                  ></textarea>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputLink">
                  <span class="text-red">*</span> 網址：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputLink"
                    required="required"
                    type="text"
                    v-model="edit.contentUrl"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <!--<span class="text-red">*</span> -->
                  檔案：
                </label>
                <div class="col-sm-10">
                  <div class="pic">
                    <img draggable="false" :src="edit.filesUrl" />
                  </div>
                  <input
                    accept="application/pdf"
                    id="Pic"
                    type="file"
                    @change="onFileChange($event)"
                    multiple
                  />
                  <div class="help-block">
                    文件檔案上傳格式：
                    <span class="text-red">(上限5MB)</span>
                    <!-- <br />1.WORD：DOC、DOCX。
                    <br />2.EXCEL：XLS、XLSX。
                    <br />3.POWERPOINT：PPT、PPTX。 -->
                    <br />1.PDF。
                    <!-- <br />5.TXT。
                    <br />6.XPS。
                    <br />7.ZIP。
                    <br />(每個檔案上限20MB，最多可以選5個檔案) -->
                  </div>

                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile">已有檔案：</label>
                <div class="col-sm-10 text">
                  <table class="table table-bordered">
                    <tbody>
                      <tr :key="index" v-for="(file, index) in fileList">
                        <td>
                          <a :href=file.imgFullFileName target="_blank">{{file.imgName}}</a>
                        </td>
                        <td>
                          <button
                            @click="deleteFile(file)"
                            class="btn btn-danger"
                            type="button"
                          >刪除</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="上架"
                        name="radioMode"
                        v-model="edit.isStop"
                      />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker" type="text" />
                    </div>
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      module:"service",
      detailGameLang: [],
      edit: {
        contentId: utils.getTimeId(), //Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        contentUrl: "", // 網址
        contentDetailTopId: "", // 內容Id
        detailTopTitle: "", //標題
        detailTopInfo: "", //內容
        isTurnPlay: 0, //輪播
        isStop: 0, // 狀態
        sortId: 0 // 排序
      },
      fileList: [],
      timeStringOption: {timeStyle: "short"}
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false
    });
  },
  computed: {
    ...mapState({
      gameTypes: state => state.classTypes.queryAll,
      game: state => state.game.query,
      detailQueryAll: state => state.game.detailQueryAll,
      projectData: state => state.module.queryAll,
      response: state => state.game.response,
      fileUploadResponse: state => state.file.response,
      imgUrlList: state => state.game.imgList
    })
  },
  watch: {
    game() {
      this.edit = this.game;
      if (this.edit.publishTime != "") {
        $(".datepicker").val(
          new Date(this.edit.publishTime).toLocaleDateString()
        );
        $(".timepicker").val(
          new Date(this.edit.publishTime).toLocaleTimeString("en-US", this.timeStringOption)
        );
      }
      this.GetGameLangData();
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetGameClassTypes();
      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetGameData();
      } else {
        this.detailGameLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.contentId + i + 1;
          this.detailGameLang.push({
            gameDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo
          });
        }
      }
    },
    detailQueryAll() {
      let that = this;
      this.detailGameLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          const Id = this.edit.contentId + i + 1;
          let findLang = false;
          this.detailQueryAll.datas.some(details => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.gameDetailId = details.gameDetailId;
                that.edit.detailTopTitle = details.title;
                that.edit.detailTopInfo = details.info;
              }
              that.detailGameLang.push({
                gameDetailId: details.gameDetailId,
                lang: details.lang,
                title: details.title,
                info: details.info
              });
              findLang = true;
              return true;
            }
          });
          if (!findLang) {
            that.detailGameLang.push({
              gameDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: ""
            });
          }
        }
        this.GetFileList();
      }
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: "WaninFundServiceList",
          params: { searchData: this.$route.params.searchData }
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    imgUrlList() {
      this.fileList = [];
      this.fileList = this.imgUrlList;
    }
  },
  methods: {
    //取得該銷售據點資料
    GetGameData() {
      this.$store.dispatch("game/gameQuery", {
        contentId: this.$route.params.id
      });
    },

    //取得該銷售據點多語系資料
    GetGameLangData() {
      this.$store.dispatch("game/gameDetailQueryAll", {
        contentId: this.$route.params.id
      });
    },

    //取得分類資料
    GetGameClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetFileList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("game/gameImgList", {
        contentId: this.edit.contentId
      });
    },

    //取得該語系資料
    GetDetailGameLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.gameDetailId = this.detailGameLang[i].gameDetailId;
          this.edit.detailTopTitle = this.detailGameLang[i].title;
          this.edit.detailTopInfo = this.detailGameLang[i].info;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      this.removeFile();
      this.SetLangData(this.editLang);
      this.GetDetailGameLang(lang);
      this.editLang = lang;
      this.GetFileList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailGameLang[i].title = this.edit.detailTopTitle;
          this.detailGameLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "WaninFundServiceList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt) {
      this.SetLangData(this.editLang);
      this.GetDetailGameLang(this.editLang);

      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          //var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var re = /\.(pdf)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files, "service", this.edit.contentId, false);
    },
    uploadFile(files, folderName, id, multi = false) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.edit.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", "zh-tw");
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then(res => {
        if (res.status != "0") this.GetFileList();
      });
    },

    deleteFile(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("file/fileDelete", item).then(res => {
            if (res.status != "0") {
              this.GetFileList();
            }
          });
        }
      });
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      this.SetLangData(this.editLang);
      this.edit.publishTime = $(".datepicker").val() + " " + $(".timepicker").val();
      this.edit.publishTime = new Date(this.edit.publishTime + " UTC");
      this.edit.years = this.edit.publishTime.getFullYear()

      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "名稱不能為空");
        return;
      }

      if (this.edit.contentUrl == "" && this.fileList.length == 0) {
        this.$eventBus.$emit("showAlert", "網址與檔案擇一選填");
        return;
      }

      if (this.isEdit) {
        this.$store.dispatch("game/gameUpdate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      } else {
        this.$store.dispatch("game/gameCreate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      }
    }
  }
};
</script>