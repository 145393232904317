<template>
	<div id="activityItem" class="slot hasPageSize">
		<section class="content-header">
			<h1>機制項目</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li>活動機制</li>
				<li class="active">機制項目</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
          <h4 id="title">{{ $route.params.name }}</h4>
					<div class="top-box">
						<button @click="backList()" class="btn btn-block btn-default btn-add" type="button">
							返回
						</button>
						<button @click="newControl()" class="btn btn-block btn-warning btn-add mr-10" type="button">
							新增對應
						</button>
						<button @click="setting()" class="btn btn-block btn-primary btn-add mr-10" type="button">
							項目設定
						</button>
						<div class="pull-right" style="line-height: 34px;">※ 請先完成「項目設定」，再「新增（編輯）對應」關係。</div>
						<div class="text" style="margin-left: 0;">
							<p class="data-num">
								<span>資料總筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-20">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th style="min-width: 150px;" width="50%">名稱</th>
									<th style="min-width: 150px;">建立時間</th>
									<th style="min-width: 150px;">更新時間</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">排序</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td>{{ item.流程名稱 }}</td>
									<td class="text-nowrap text-center">{{ item.建立時間 | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
									<td class="text-nowrap text-center">{{ item.更新時間 | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
									<td class="td-sort text-nowrap text-center">
										<input
                      class="form-control text-center mr-10"
                      required="required"
                      type="number"
                      v-model="item.排序數字"
											style="
												width: 60px;
												display: inline-block;
												vertical-align: middle;
											"	
                    />
										<button
											class="btn btn-warning btn-add btn-upDate"
											type="button"
											@click="updateOrder(item)"
										>更新</button>
									</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item, true)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="editControl(item)" class="btn btn-primary">編輯對應</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.PageSize"
            @change="resetDataList()"
          >
            <option value="20">每頁筆數20</option>
            <option value="30">每頁筆數30</option>
            <option value="50">每頁筆數50</option>
          </select>
					<Pagination
						ref="pagination"
						:total="total"
						:pageLimitCount="searchData.PageSize"
						v-on:pageMethod="onPageChange"
					/>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
  import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
		},
    delimiters: ["${", "}"],
    data() {
      return {
				isEdit: false,
				searchData: {
					遊戲編號: Number(this.$route.params.id),
					// 是否啟用: 0,
					PageNum: 1,
					PageSize: 20,
				},
				list: [],
				total: 0,
      };
    },
    created() {
			this.getDataList();
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
			getDataList() {
				api.slotEvent.flow.queryAll(this.searchData)
					.then((res) => {
						console.log(res);
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
					})
			},
			resetDataList() {
				this.searchData.PageNum = 1;
				this.getDataList();
			},
			updateOrder(item) {
				item.遊戲編號 = this.searchData.遊戲編號;
				item.排序數字 = Number(item.排序數字);
				api.slotEvent.flow.order({
					編號: item.編號,
					遊戲編號: item.遊戲編號,
					排序數字: Number(item.排序數字),
				})
					.then((res) => {
						if(res.data.status == '1') {
							this.$eventBus.$emit("showAlert", "更新成功!");
							this.getDataList();
						} else {
							this.$eventBus.$emit("showAlert", res.data.message);
						}
					})
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}資料？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}此資料`,
				isOK => {
					if (isOK) {
						api.slotEvent.flow.state({
							編號: item.編號,
							遊戲編號: this.searchData.遊戲編號,//必傳
							是否啟用: item.是否啟用 == 1 ? 0 : 1,
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
					}
				});
			},
			backList() {
				this.$router.push({
					name: 'slotActivity',
					// params: {
					// 	searchData: this.searchData,
					// },
				});
			},
			setting() {
				this.$router.push({
					name: 'slotActivityItemSet',
					params: {
						id: this.searchData.遊戲編號,
						name: this.$route.params.name
					},
				});
			},
			newControl() {
				this.$router.push({
					name: 'slotActivityItemControl',
					params: {
						gameId: this.searchData.遊戲編號,
						name: this.$route.params.name,
					},
				});
			},
			editControl(item) {
				this.$router.push({
					name: 'slotActivityItemControl',
					params: {
						gameId: this.searchData.遊戲編號,
						name: this.$route.params.name,
						id: item.編號
					},
				});
			},
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>