<template>
  <div id="NewtalkElectionHistorical" class="normal">
    <section class="content-header">
      <h1>{{ type == 'President' ? '總統' : '縣市長' }}大選歷史數據</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">{{ type == 'President' ? '總統' : '縣市長' }}大選歷史數據</li>
      </ol>
      <!-- 總統大選類別 -->
      <div class="mt-10" v-if="type == 'President'">
        <button
          :class="['btn btn-cog mr-5', category == '總統' ? 'btn-primary' : 'btn-default']"
          style="width: auto"
          type="button"
          @click="category = '總統'"
        >
          總統
        </button>
        <button
          :class="['btn btn-cog mr-5', category == '立委' ? 'btn-primary' : 'btn-default']"
          style="width: auto"
          type="button"
          @click="category = '立委'"
        >
          立委
        </button>
        <button
          :class="['btn btn-cog', category == '政黨' ? 'btn-primary' : 'btn-default']"
          style="width: auto"
          type="button"
          @click="category = '政黨'"
        >
          政黨
        </button>
      </div>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <!-- 年份 -->
            <div class="select-group">
              <select
                class="form-control"
                required="required"
                v-model.trim="searchData.year"
              >
                <option
                  v-for="year in yearList"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
            </div>
            <!-- 區域 -->
            <div
              class="select-group"
              v-if="
                category == '總統' ||
                category == '縣市長' ||
                category == '立委'
              "
            >
              <select
                class="form-control"
                required="required"
                v-model.trim="searchData.area"
                @change="setCounty()"
              >
                <template v-for="(data, key) in menu">
                  <option
                    :value="key"
                    v-if="!((category == '縣市長' || category == '總統') && key == '原住民')"
                  >
                    {{ key }}
                  </option>
                </template>
              </select>
            </div>
            <!-- 縣市 -->
            <div
              class="select-group"
              v-if="
                category == '總統' ||
                category == '縣市長' ||
                category == '立委'
              "
            >
              <select
                class="form-control"
                required="required"
                @change="setConstituency()"
                v-model.trim="searchData.county"
              >
                <option
                  :value="county.代碼"
                  v-for="(county, key) in countyList"
                  :key="county.代碼"
                >
                  {{ county.縣市 }}
                </option>
              </select>
            </div>
            <!-- 選區 -->
            <div
              class="select-group"
              v-if="category == '立委' && searchData.area != '原住民'"
            >
              <select
                class="form-control"
                required="required"
                v-model.trim="searchData.constituency"
                @change="resetDataList()"
              >
                <option
                  :value="index+1"
                  v-for="(constituency, index) in constituency"
                  :key="index+1"
                >
                  {{ constituency }}
                </option>
              </select>
            </div>
            <!-- 黨派 -->
            <div class="select-group" v-if="category == '政黨'">
              <select
                class="form-control"
                required="required"
                v-model="selectedGroup"
              >
                <option
                  :value="index"
                  v-for="(gorop, index) in mayorDatas.candidateList"
                  :key="index"
                >
                  {{ gorop.partyName }}
                </option>
              </select>
            </div>
            <div class="btns">
              <button
                class="btn btn-default btn-cog"
                style="width: auto"
                type="button"
                @click="resetSearch()"
              >
                清除篩選
              </button>
              <button
                class="btn btn-success btn-cog"
                style="width: auto"
                type="button"
                @click="resetDataList()"
                v-if="searchData.year === yearList[0]"
              >
                <i class="fa fa-refresh"></i>
                資料刷新 ({{ cecTime }})
              </button>
            </div>
          </div>
          <div class="mt-10" v-if="category == '政黨' && mayorDatas.candidateList">
            得票數：{{ mayorDatas.candidateList[selectedGroup].tickets }}票
            得票率：{{ mayorDatas.candidateList[selectedGroup].rate }}%
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered table-list">
              <tbody>
                <tr class="bg-gray">
                  <th style="width: 5%">號次</th>
                  <th style="width: 15%;" v-if="category != '政黨'">頭像</th>
                  <th style="width: 20%">候選人</th>
                  <th style="width: 10%" v-if="category != '政黨'">黨徽</th>
                  <th style="width: 25%" v-if="category != '政黨'">所屬政黨</th>
                  <th style="width: 10%" v-if="category != '政黨'">得票數</th>
                  <th style="width: 10%" v-if="category != '政黨'">得票率</th>
                  <th style="width: 10%">最終結果</th>
                  <th style="width: 20%" v-if="category == '政黨'">備註</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" :colspan="category == '政黨' ? 4 : 6">查詢中</td>
                </tr>
                <tr
                  v-else-if="
                    dataList &&
                    dataList.length === 0
                  "
                >
                  <td class="text-center" :colspan="category == '政黨' ? 4 : 6">查詢無任何資料</td>
                </tr>
                <tr
                  v-else-if="dataList"
                  v-for="(mayor, index) in dataList"
                  :key="'mayor' + index"
                >
                  <!-- electStatus:
                  自然當選: normal
                  婦女保障當選: woman
                  被排擠未當選: excluded
                  同票待決: same
                  未達選罷法票數: yet -->
                  <td class="text-nowrap">
                    {{ mayor.candidateNum }}
                  </td>
                  <td class="text-nowrap text-center" v-if="category != '政黨'">
                    <template v-if="category == '總統' && mayor.candidate">
                      <div
                        class="pic imgFill"
                        style="width: 100px;"
                      >
                        <img
                          :src="mayor.candidate[0].image"
                          :alt="mayor.candidate[0].name"
                          @error="imgUrlAlt"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <div class="pic imgFill">
                        <img
                          :src="mayor.candidateImage"
                          :alt="mayor.candidateName"
                          @error="imgUrlAlt"
                        />
                      </div>
                    </template>
                  </td>
                  <td class="text-nowrap">
                    <template v-if="category == '總統'">
                      <div v-for="President in mayor.candidate">
                        {{ President.name }}
                      </div>
                    </template>
                    <template v-else>
                      {{ mayor.candidateName }}
                    </template>
                  </td>
                  <td class="text-nowrap text-center" v-if="category != '政黨'">
                    <img
                      style="width: 50px"
                      :src="mayor.candidatePartyImage"
                      :alt="mayor.candidatePartyName"
                    />
                  </td>
                  <td class="text-nowrap" v-if="category != '政黨'">
                    {{ mayor.candidatePartyName }}
                  </td>
                  <td class="text-nowrap text-right" v-if="category != '政黨'">
                    {{ numberWithCommas(mayor.tickets) }}票
                  </td>
                  <td class="text-nowrap text-right" v-if="category != '政黨'">
                    {{ +(Math.round(mayor.rate + 'e+2') + 'e-2') }}%
                  </td>
                  <td class="text-nowrap text-center">
                    <img
                      :src="require('@/assets/img/icon-mark.png')"
                      alt="當選"
                      v-if="mayor.electStatus"
                    />
                  </td>
                  <td class="text-nowrap text-center" v-if="category == '政黨'">{{ electStatus[mayor.electStatus] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <template v-if="category == '縣市長'">
        <h2 class="page-header">各縣市政黨圖表</h2>
        <div class="nav-tabs-custom" id="cityChart">
          <ul class="nav nav-tabs">
            <li :class="{'active': tabName == 'partisan_1'}">
              <a
                href="#partisan_1"
                data-toggle="tab"
                @click="showTab('partisan_1')"
                >候選人得票數 (長條圖)</a
              >
            </li>
            <li :class="{'active': tabName == 'partisan_2'}">
              <a
                href="#partisan_2"
                data-toggle="tab"
                @click="showTab('partisan_2')"
                >候選人得票數 (頭像版)</a
              >
            </li>
            <li :class="{'active': tabName == 'partisan_3'}">
              <a
                href="#partisan_3"
                data-toggle="tab"
                @click="showTab('partisan_3')"
                >政黨得票率</a
              >
            </li>
            <!-- 本次先隱藏
            <li>
              <a
                href="#partisan_4"
                data-toggle="tab"
                @click="showTab('partisan_4')"
                >議員席次分配 <span class="text-red">(今年與往年比較)</span></a
              >
            </li> -->
            <li :class="{'active': tabName == 'partisan_5'}">
              <a
                href="#partisan_5"
                data-toggle="tab"
                @click="showTab('partisan_5')"
                >政黨近年比較圖 <span class="text-red">(今年與往年比較)</span></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div :class="['tab-pane', tabName == 'partisan_1' ? 'active' : '']" id="partisan_1">
              <div class="chart-area" id="partisan_1_chart">
                <div class="partisan_assign" id="badge_votes">
                  <div class="box-header">
                    <h3 class="box-title" v-if="countyNameList[searchData.county]">
                      {{ countyNameList[searchData.county]['縣市'] || '' }}候選人得票數
                    </h3>
                  </div>
                  <ElectionBarChart
                    ref="electionBarChart"
                    v-show="tabName === 'partisan_1'"
                  />
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'partisan_1_chart',
                    countyNameList[searchData.county].縣市 + '候選人得票數-長條圖'
                  )
                "
              >
                匯出圖片
              </button>
            </div>
            <div :class="['tab-pane', tabName == 'partisan_2' ? 'active' : '']" id="partisan_2">
              <div class="chart-area" id="partisan_2_chart">
                <div id="got_elected">
                  <div class="box-header">
                    <h3 class="box-title" v-if="countyNameList[searchData.county]">
                      {{ countyNameList[searchData.county].縣市 }}候選人得票數
                    </h3>
                  </div>
                  <div class="partisan_assign">
                    <div
                      :class="[
                        'item',
                        mayor.electStatus === 'normal' ||
                        mayor.electStatus === 'woman'
                          ? 'is-winner'
                          : '',
                      ]"
                      v-for="mayor in mayorDatas.candidateList"
                      :key="'no' + mayor.candidateNum"
                    >
                      <div class="photo">
                        <div
                          class="pic"
                          :style="
                            'background-image: url(' +
                            mayor.candidateImage +
                            '?time=' +
                            new Date().valueOf() +
                            ')'
                          "
                        ></div>
                      </div>
                      <p class="name">
                        {{ mayor.candidateName }}
                        <i class="badge">
                          <img
                            :src="
                              mayor.candidatePartyImage +
                              '?time=' +
                              new Date().valueOf()
                            "
                            :alt="mayor.candidatePartyName"
                          />
                        </i>
                      </p>
                      <p class="tickets">
                        {{ numberWithCommas(mayor.tickets) }}票
                        <i
                          class="icon-mark"
                          v-if="
                            mayor.electStatus === 'normal' ||
                            mayor.electStatus === 'woman'
                          "
                        >
                          <img
                            :src="require('@/assets/img/icon-mark.png')"
                            alt="當選"
                          />
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'partisan_2_chart',
                    countyNameList[searchData.county].縣市 + '候選人得票數-頭像版'
                  )
                "
              >
                匯出圖片
              </button>
            </div>
            <div :class="['tab-pane', tabName == 'partisan_3' ? 'active' : '']" id="partisan_3">
              <div class="chart-area" id="partisan_3_chart">
                <h3 class="box-title sr-only" v-if="countyNameList[searchData.county]">
                  {{ countyNameList[searchData.county].縣市 }}政黨得票率
                </h3>
                <div class="partisan_assign" id="vote_rate">
                  <ElectionPieChart
                    :chartTitle="'政黨得票率'"
                    :year="searchData.year"
                    ref="electionPieChart"
                  />
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'partisan_3',
                    countyNameList[searchData.county].縣市 + '政黨得票率'
                  )
                "
              >
                匯出圖片
              </button>
            </div>
            <!-- 本次先隱藏
            <div class="tab-pane" id="partisan_4">
              <h3 class="box-title sr-only">
                {{ getCountyName(searchData.county) }}議員席次分配
              </h3>
              <div class="partisan_assign" id="parliament">
                <ElectionPieChart
                  :chartTitle="getCountyName(searchData.county) + '議員席次分配'"
                  :multiple="true"
                  ref="electionPieChart_seats"
                />
                <div class="legend">
                  <div class="item" v-for="item in seatsBadgeList" :key="item">
                    <i
                      class="icon"
                      :style="'background-color:' + switchBadgeColor(item)"
                    ></i>
                    <p>{{ item }}</p>
                  </div>
                  <p class="note">
                    本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                  </p>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'partisan_4',
                    getCountyName(searchData.county) + '議員席次分配'
                  )
                "
              >
                匯出圖片
              </button>
            </div> -->
            <div :class="['tab-pane', tabName == 'partisan_5' ? 'active' : '']" id="partisan_5">
              <div class="partisan_assign has-photo" id="comparison">
                
                <div class="flex-block">
                  <div class="flex-item">
                    <div class="chart-area" id="partisan_5_chart_b">
                      <div>
                        <h3 class="box-title" v-if="countyNameList[searchData.county]">
                          {{ countyNameList[searchData.county].縣市 }}政黨近年比較圖
                        </h3>
                        <ElectionPieChart
                          :chartTitle="prevYear"
                          :multiple="true"
                          :hasPhoto="true"
                          ref="electionPieChart_partyPrev"
                        />
                        <p class="tickets" v-if="cityPartyVotesList[0]">
                          總有效票數：{{ cityPartyVotesList[0].totalTickets }}
                        </p>
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="
                        downloadPhoto(
                          'partisan_5_chart_b',
                          prevYear + countyNameList[searchData.county].縣市 + '政黨近年比較圖'
                        )
                      "
                    >
                      匯出圖片
                    </button>
                  </div>
                  <i
                    class="icon-right"
                    :style="'background-image: url(' + iconRight + ')'"
                  ></i>
                  <div class="flex-item">
                    <div class="chart-area" id="partisan_5_chart_a">
                      <div>
                        <h3 class="box-title" v-if="countyNameList[searchData.county]">
                          {{ countyNameList[searchData.county].縣市 }}政黨近年比較圖
                        </h3>
                        <ElectionPieChart
                          :chartTitle="thisYear"
                          :multiple="true"
                          :hasPhoto="true"
                          ref="electionPieChart_partyThis"
                        />
                        <p class="tickets" v-if="cityPartyVotesList[1]">
                          總有效票數：{{ cityPartyVotesList[1].totalTickets }}
                        </p>
                      </div>
                      <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="
                        downloadPhoto(
                          'partisan_5_chart_a',
                          thisYear + countyNameList[searchData.county].縣市 + '政黨近年比較圖'
                        )
                      "
                    >
                      匯出圖片
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <h2 class="page-header">全國圖表</h2>
        <div class="nav-tabs-custom" id="gobalChart">
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#national_1" data-toggle="tab">政黨得票率</a>
            </li>
            <li>
              <a href="#national_2" data-toggle="tab">縣市長選舉政黨版圖</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="national_1">
              <div class="partisan_assign" id="global_rate">
                <div class="flex-block">
                  <div class="flex-item">
                    <div class="chart-area" id="national_1_chart_b">
                      <div>
                        <h3 class="box-title">政黨得票率</h3>
                        <Election3dChart
                          :chartTitle="prevYear"
                          ref="election3dChart_prev"
                        />
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="downloadPhoto('national_1_chart_b', `${prevYear}全國圖表-政黨得票率`)"
                    >
                      匯出圖片
                    </button>
                  </div>
                  <i
                    class="icon-right"
                    :style="'background-image: url(' + iconRight + ')'"
                  ></i>
                  <div class="flex-item">
                    <div class="chart-area" id="national_1_chart_a">
                      <div>
                        <h3 class="box-title">政黨得票率</h3>
                        <Election3dChart
                          :chartTitle="thisYear"
                          ref="election3dChart_this"
                        />
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="downloadPhoto('national_1_chart_a', `${thisYear}全國圖表-政黨得票率`)"
                    >
                      匯出圖片
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="national_2">
              <div class="partisan_assign" id="global_territory">
                <div
                class="year-group"
                v-for="(seat, index) in partySeats"
                :key="seat.year"
                >
                  <div class="chart-area" :id="`national_2_chart_${seat.year == prevYear ? 'b' : 'a'}`">
                    <div>
                      <h3 class="box-title">縣市長選舉政黨版圖</h3>
                      <div class="year-item">
                        <div class="year">{{ seat.year }}</div>
                        <div class="badges">
                          <div
                            :class="['item', switchBadgeId(subSeat.name)]"
                            v-for="(subSeat, index) in seat.data"
                            :key="seat.year + subSeat.name + index"
                          >
                            <i class="icon">
                              <img
                                :src="
                                  'https://dev77.newtalk.tw/images/election/parties/' +
                                  (subSeat.name === '其他黨派'
                                    ? '無黨籍及未經政黨推薦'
                                    : subSeat.name) +
                                  '.png?time=' +
                                  new Date().valueOf()
                                "
                                :alt="subSeat.name"
                              />
                            </i>
                            <table class="table">
                              <thead>
                                <tr class="subtitle">
                                  <th>
                                    <template
                                      v-if="subSeat.name == '無黨籍及未經政黨推薦'"
                                    >
                                      無黨籍<br />& 其他
                                    </template>
                                    <template v-else-if="subSeat.name == '中國國民黨'">
                                      國民黨
                                    </template>
                                    <template v-else-if="subSeat.name == '民主進步黨'">
                                      民進黨
                                    </template>
                                    <template v-else-if="subSeat.name == '台灣民眾黨'">
                                      民眾黨
                                    </template>
                                    <template v-else>
                                      {{ subSeat.name }}
                                    </template>
                                  </th>
                                  <th class="seat">{{ subSeat.total }} 席</th>
                                </tr>
                                <tr>
                                  <td>六都</td>
                                  <td class="seat">
                                    {{ subSeat.municipality || 0 }} 席
                                  </td>
                                </tr>
                                <tr>
                                  <td>地方縣市</td>
                                  <td class="seat">{{ subSeat.others || 0 }} 席</td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                        <div class="map">
                          <ElectionMapChart :datas="seat.map" ref="electionMapChart" />
                        </div>
                      </div>
                      <div class="legend">
                        <p class="note">
                          本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                        </p>
                        <p class="text-red">嘉義市長選舉延後至12月18日</p>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-default btn-download"
                    @click="
                      downloadPhoto(
                        `national_2_chart_${seat.year == prevYear ? 'b' : 'a'}`,
                        `${seat.year}全國圖表-縣市長選舉政黨版圖`
                      )
                    "
                  >
                    匯出圖片
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="category == '總統'">
        <h2 class="page-header">全國圖表</h2>
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li :class="{'active': tabName == 'president_1'}">
              <a
                href="#president_1"
                data-toggle="tab"
                @click="showTab('president_1')"
                >候選人得票數</a
              >
            </li>
            <li :class="{'active': tabName == 'president_2'}">
              <a
                href="#president_2"
                data-toggle="tab"
                @click="showTab('president_2')"
                >政黨得票率<span class="text-red">(今年與往年比較)</span></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div :class="['tab-pane', tabName == 'president_1' ? 'active' : '']" id="president_1">
              <div class="chart-area" id="president_1_chart">
                <div id="got_elected">
                  <div class="partisan_assign">
                    <div
                      :class="[
                        'item',
                        mayor.electStatus === 'normal' ||
                        mayor.electStatus === 'woman'
                          ? 'is-winner'
                          : '',
                      ]"
                      v-for="mayor in mayorDatas.candidateList"
                      :key="'no' + mayor.candidateNum"
                    >
                      <div class="photo">
                        <div
                          v-if="mayor.candidate"
                          class="pic"
                          :style="
                            'background-image: url(' +
                            mayor.candidate[0].image +
                            '?time=' +
                            new Date().valueOf() +
                            ')'
                          "
                        ></div>
                      </div>
                      <p class="name">
                        <span v-for="item in mayor.candidate">
                          {{ item.name }} 
                        </span> 
                        <i class="badge">
                          <img
                            :src="
                              mayor.candidatePartyImage +
                              '?time=' +
                              new Date().valueOf()
                            "
                            :alt="mayor.candidatePartyName"
                          />
                        </i>
                      </p>
                      <p class="tickets" v-if="mayorDatas.electionProfileAllCity[mayor.candidatePartyName]">
                        {{ numberWithCommas(mayorDatas.electionProfileAllCity[mayor.candidatePartyName].allTickets) }}票
                        <i
                          class="icon-mark"
                          v-if="
                            mayor.electStatus === 'normal' ||
                            mayor.electStatus === 'woman'
                          "
                        >
                          <img
                            :src="require('@/assets/img/icon-mark.png')"
                            alt="當選"
                          />
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'president_1_chart',
                    `總統候選人得票數-頭像版`
                  )
                "
              >
                匯出圖片
              </button>
            </div>
            <div  :class="['tab-pane', tabName == 'president_2' ? 'active' : '']" id="president_2">
              <div>
                <div class="partisan_assign has-photo" id="comparison">
                  <div class="flex-block">
                    <div class="flex-item">
                      <div class="chart-area" id="president_2_chart_b">
                        <div>
                          <h3 class="box-title">中華民國總統選舉</h3>
                          <ElectionPieChart
                            :chartTitle="prevYear"
                            :multiple="true"
                            :hasPhoto="true"
                            ref="electionPieChart_president2_Prev"
                          />
                          <p class="tickets" v-if="chartData.president[0]">
                            總有效票數：{{ chartData.president[0].totalTickets }}
                          </p>
                        </div>
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-default btn-download"
                        @click="downloadPhoto('president_2_chart_b',`${prevYear}政黨得票率`)"
                      >
                        匯出圖片
                      </button>
                    </div>
                    <i
                      class="icon-right"
                      :style="'background-image: url(' + iconRight + ')'"
                    ></i>
                    <div class="flex-item">
                      <div class="chart-area" id="president_2_chart_a">
                        <div>
                          <h3 class="box-title">中華民國總統選舉</h3>
                          <ElectionPieChart
                            :chartTitle="thisYear"
                            :multiple="true"
                            :hasPhoto="true"
                            ref="electionPieChart_president2_This"
                          />
                          <p class="tickets" v-if="chartData.president[1]">
                            總有效票數：{{ chartData.president[1].totalTickets }}
                          </p>
                        </div>
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-default btn-download"
                        @click="downloadPhoto('president_2_chart_a',`${thisYear}政黨得票率`)"
                      >
                        匯出圖片
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="category == '立委'">
        <!-- 山地原住民立委圖表 -->
        <h2 class="page-header">{{ searchData.area == "原住民" ? "原住民" : "各縣市" }}區域立委圖表</h2>
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li :class="{'active': tabName == 'legislator_1'}">
              <a
                href="#legislator_1"
                data-toggle="tab"
                @click="showTab('legislator_1')"
                >
                  <span v-if="searchData.area != '原住民'">區域立委得票數(長條圖)</span>
                  <span v-else>
                    {{ searchData.county == '00000-01' ? "山地" : "平地" }}立委得票數(長條圖)
                  </span>
                </a>
            </li>
            <li :class="{'active': tabName == 'legislator_2'}">
              <a
                href="#legislator_2"
                data-toggle="tab"
                @click="showTab('legislator_2')"
                >
                  <span v-if="searchData.area != '原住民'">區域立委得票數(有頭像版)</span>
                  <span v-else>
                    {{ searchData.county == '00000-01' ? "山地" : "平地" }}立委得票數(有頭像版)
                  </span>
                </a>
            </li>
            <li :class="{'active': tabName == 'legislator_3'}">
              <a
                href="#legislator_3"
                data-toggle="tab"
                @click="showTab('legislator_3')"
                >
                  <span v-if="searchData.area != '原住民'">
                    {{ countyNameList[searchData.county]["縣市"] }}立委席次分配<span class="text-red">(今年與往年比較)</span>
                  </span>
                  <span v-else>
                    原住民立委席次分配<span class="text-red">(今年與往年比較)</span>
                  </span>
                
                </a>
            </li>
          </ul>
          <div class="tab-content">
            <div :class="['tab-pane', tabName == 'legislator_1' ? 'active' : '']" id="legislator_1">
              <div class="chart-area" id="legislator_1_chart">
                <div class="partisan_assign" id="badge_votes">
                  <div class="box-header">
                    <h3 class="box-title" v-if="searchData.area != '原住民'">
                      {{ searchData.year }}{{ constituency[searchData.constituency - 1] }}立委得票數
                    </h3>
                    <h3 class="box-title" v-else>
                      {{ searchData.year }}{{ searchData.county == '00000-01' ? "山地" : "平地" }}原住民立委得票數
                    </h3>
                  </div>
                  <ElectionBarChart
                    ref="electionBarChart"
                    v-show="tabName === 'legislator_1'"
                  />
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'legislator_1_chart',
                    searchData.area != '原住民' ? 
                    `${constituency[searchData.constituency - 1]}立委得票數-長條圖` :
                    searchData.county == '00000-01' ? `山地原住民立委得票數-長條圖`:
                    `平地原住民立委得票數-長條圖`
                  )
                "
              >
                匯出圖片
              </button>
            </div>
            <div :class="['tab-pane', tabName == 'legislator_2' ? 'active' : '']" id="legislator_2">
              <div class="chart-area" id="legislator_2_chart">
                <div id="got_elected">
                  <div class="box-header">
                    <h3 class="box-title" v-if="searchData.area != '原住民'">
                      {{ searchData.year }}{{ constituency[searchData.constituency - 1] }}立委得票數
                    </h3>
                    <h3 class="box-title" v-else>
                      {{ searchData.year }}{{ searchData.county == '00000-01' ? "山地" : "平地" }}原住民立委得票數
                    </h3>
                  </div>
                  <div
                    class="partisan_assign"
                    :class="[setNameHeight(), searchData.area == '原住民' ? 'icon_in_photo' : '']"
                    :style="legislatorSize(mayorDatas.candidateList)"
                  >
                    <div
                      :class="[
                        'item',
                        mayor.electStatus === 'normal' ||
                        mayor.electStatus === 'woman'
                          ? 'is-winner'
                          : '',
                      ]"
                      v-for="mayor in mayorDatas.candidateList"
                      :key="'no' + mayor.candidateNum"
                    >
                      <div class="photo">
                        <div
                          class="pic"
                          :style="
                            'background-image: url(' +
                            mayor.candidateImage +
                            '?time=' +
                            new Date().valueOf() +
                            ')'
                          "
                        ></div>
                      </div>
                      <p class="name" :class="setNameSize(mayor.candidateName)">
                        {{ mayor.candidateName }}
                        <i class="badge">
                          <img
                            :src="
                              mayor.candidatePartyImage +
                              '?time=' +
                              new Date().valueOf()
                            "
                            :alt="mayor.candidatePartyName"
                          />
                        </i>
                      </p>
                      <p class="tickets">
                        {{ numberWithCommas(mayor.tickets) }}票
                        <i
                          class="icon-mark"
                          v-if="
                            mayor.electStatus === 'normal' ||
                            mayor.electStatus === 'woman'
                          "
                        >
                          <img
                            :src="require('@/assets/img/icon-mark.png')"
                            alt="當選"
                          />
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-default btn-download"
                @click="
                  downloadPhoto(
                    'legislator_2_chart',
                    searchData.area != '原住民' ? 
                    `${constituency[searchData.constituency - 1]}立委得票數-頭像版` :
                    searchData.county == '00000-01' ? `山地原住民立委得票數-頭像版`:
                    `平地原住民立委得票數-頭像版`
                  )
                "
              >
                匯出圖片
              </button>
            </div>
            <div :class="['tab-pane', tabName == 'legislator_3' ? 'active' : '']" id="legislator_3">
              <div class="partisan_assign has-photo" id="comparison">
                <div class="flex-block">
                  <div class="flex-item">
                    <div class="chart-area" id="legislator_3_chart_b">
                      <div>
                        <h3 class="box-title" v-if="searchData.area != '原住民'">
                          {{ countyNameList[searchData.county]["縣市"] }}立委席次分配(席)
                        </h3>
                        <h3 class="box-title" v-else>
                          原住民立委席次分配(席)
                        </h3>
                        <ElectionPieChart
                          :chartTitle="prevYear"
                          :multiple="true"
                          :isSeat="true"
                          ref="electionPieChart_legislator3_Prev"
                        />
                        <p class="tickets" v-if="chartData.legislatorCounty[0]">
                          總有效票數：{{ chartData.legislatorCounty[0].totalTickets }}
                        </p>
                      </div>
                      <div class="legend">
                        <p class="note">
                          本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="
                        downloadPhoto(
                          'legislator_3_chart_b',
                          searchData.area != '原住民' ? 
                          `${prevYear}${countyNameList[searchData.county]['縣市']}立委席次分配` :`原住民立委席次分配`
                        )
                      "
                    >
                      匯出圖片
                    </button>
                  </div>
                  <i
                    class="icon-right"
                    :style="'background-image: url(' + iconRight + ')'"
                  ></i>
                  <div class="flex-item">
                    <div class="chart-area" id="legislator_3_chart_a">
                      <div>
                        <h3 class="box-title" v-if="searchData.area != '原住民'">
                          {{ countyNameList[searchData.county]["縣市"] }}立委席次分配(席)
                        </h3>
                        <h3 class="box-title" v-else>
                          原住民立委席次分配(席)
                        </h3>
                        <ElectionPieChart
                          :chartTitle="2024"
                          :multiple="true"
                          :isSeat="true"
                          ref="electionPieChart_legislator3_This"
                        />
                        <p class="tickets" v-if="chartData.legislatorCounty[1]">
                          總有效票數：{{ chartData.legislatorCounty[1].totalTickets }}
                        </p>
                      </div>
                      <div class="legend">
                        <p class="note">
                          本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="
                        downloadPhoto(
                          'legislator_3_chart_a',
                          searchData.area != '原住民' ? 
                          `${thisYear}${countyNameList[searchData.county]['縣市']}立委席次分配` :`原住民立委席次分配`
                        )
                      "
                    >
                      匯出圖片
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="page-header">全國圖表</h2>
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li class="active">
              <a>全國立委席次分配(席)<span class="text-red">(今年與往年比較)</span></a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="legislator_4">
              <div class="partisan_assign has-photo">
                <div class="flex-block">
                  <div class="flex-item">
                    <div class="chart-area" id="legislator_4_chart_b">
                      <div>
                        <h3 class="box-title">全國立委席次分配(席)</h3>
                        <ElectionPieChart
                          :chartTitle="prevYear"
                          :multiple="true"
                          :isSeat="true"
                          ref="electionPieChart_legislator4_Prev"
                        />
                      </div>
                      <div class="legend">
                        <p class="note">
                          本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="downloadPhoto('legislator_4_chart_b',`${prevYear}全國立委席次分配(席)`)"
                    >
                      匯出圖片
                    </button>
                  </div>
                  <i
                    class="icon-right"
                    :style="'background-image: url(' + iconRight + ')'"
                  ></i>
                  <div class="flex-item">
                    <div class="chart-area" id="legislator_4_chart_a">
                      <div>
                        <h3 class="box-title">全國立委席次分配(席)</h3>
                        <ElectionPieChart
                          :chartTitle="thisYear"
                          :multiple="true"
                          :isSeat="true"
                          ref="electionPieChart_legislator4_This"
                        />
                      </div>
                      <div class="legend">
                        <p class="note">
                          本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-default btn-download"
                      @click="downloadPhoto('legislator_4_chart_a',`${thisYear}全國立委席次分配(席)`)"
                    >
                      匯出圖片
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="category == '政黨'">
        <h2 class="page-header">不分區政黨圖表</h2>
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li class="active">
              <a
                href="#group_1"
                data-toggle="tab"
                @click="showTab('group_1')"
                >不分區政黨席次分配<span class="text-red">(今年與往年比較)</span></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="group_1">
                <div class="partisan_assign has-photo" id="comparison">
                  <h3 class="box-title">
                    不分區立委席次分配(席)
                  </h3>
                  <div class="flex-block">
                    <div class="flex-item">
                      <div class="chart-area" id="group_1_chart_b">
                        <div>
                          <h3 class="box-title">不分區立委席次分配(席)</h3>
                          <ElectionPieChart
                            :chartTitle="prevYear"
                            :multiple = "true"
                            :isSeat = "true"
                            ref="electionPieChart_party_Prev"
                          />
                        </div>
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-default btn-download"
                        @click="downloadPhoto('group_1_chart_b',`${prevYear}不分區立委席次分配(席)`)
                        "
                      >
                        匯出圖片
                      </button>
                    </div>
                    <i
                      class="icon-right"
                      :style="'background-image: url(' + iconRight + ')'"
                    ></i>
                    <div class="flex-item">
                      <div class="chart-area" id="group_1_chart_a">
                        <div>
                          <h3 class="box-title">不分區立委席次分配(席)</h3>
                          <ElectionPieChart
                            :chartTitle="thisYear"
                            :multiple = "true"
                            :isSeat = "true"
                            ref="electionPieChart_party_This"
                          />
                        </div>
                        <div class="legend">
                          <p class="note">
                            本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        class="btn btn-default btn-download"
                        @click="downloadPhoto('group_1_chart_a',`${thisYear}不分區立委席次分配(席)`)
                        "
                      >
                        匯出圖片
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<style lang="scss">
#NewtalkElectionHistorical {
  .highcharts-container {
    margin: auto;
  }
  .tab-pane {
    text-align: center;
    .chart-area {
      display: inline-block;
    }
  }
  #president_1 {
    .partisan_assign {
      .item {
        width: 270px !important;
        .photo {
          width: 260px !important;
        }
      }
    }
  }
  #president_2 {
    .partisan_assign {
      min-width: 1400px;
    }
  }
  #president_2 .highcharts-tracker,
  #partisan_5 .highcharts-tracker {
    > span {
      overflow: visible !important;
      > div {
        display: flex;
        align-items: center;
        margin-top: -30px !important;
        text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
        white-space: pre;
        .pic {
          min-width: 50px;
          min-height: 50px;
          margin-right: 5px;
        }
      }
      .no-pic {
        margin-top: -20px !important;
      }
    }
  }
  #president_2 .highcharts-tracker {
    > span {
      > div {
        .pic {
          display: inline-block;
          min-width: 65px;
          min-height: 50px;
          margin-right: 5px;
        }
      }
    }
  }
  #legislator_2 {
    .partisan_assign {
      &.icon_in_photo {
        .name {
          .badge {
            top: -41px;
            right: 15px;
          }
        }
      }
      &._h2 {
        .name {
          height: 67px;
          line-height: 2;
          &._l2 {
            line-height: 1.2;
          }
        }
      }
      &._h3 {
        .name {
          height: 90px;
          line-height: 4;
          &._l3 {
            line-height: normal;
          }
          &._l2 {
            line-height: 1.5;
            padding: 25px 0 !important;
          }
        }
      }
    }
  }
  .imgFill {
    position: relative;
    overflow: hidden;
    > img {
      display: block;
      width: 100%;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }
  .table {
    table-layout: fixed;
    .pic {
      img {
        margin: auto;
      }
    }
  }
  .table-list {
    min-width: 800px;
    .pic {
      margin: auto;
      width: 80px;
      height: 90px;
      border-radius: 10px;
    }
  }
  #got_elected {
    display: table;
    margin: auto;
    padding-bottom: 20px;
    .partisan_assign {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-width: 800px;
      max-width: 1200px;
      text-align: center;
      .item {
        width: 200px;
        border: 1px solid #555;
        margin: 0 -1px -1px 0;
        > * {
          padding: 10px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
      }
      .photo {
        width: 180px;
        height: 205px;
        margin: 10px auto 0 auto;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
          background: url("../../../assets/anonymous_120.jpg") center no-repeat;
          background-size: cover;
          border-radius: 12px;
        }
        .pic {
          width: 101%;
          height: 101%;
          position: absolute;
          left: -0.5%;
          top: -0.5%;
          z-index: 1;
          background-position: center;
          background-size: cover;
        }
        .icon-mark {
          display: none;
          width: 40px;
          height: 40px;
          position: absolute;
          right: 10px;
          bottom: 60px;
          z-index: 5;
          @media (max-width: 1199px) {
            width: 30px;
            height: 30px;
            bottom: 45px;
          }
        }
      }
      .name {
        border-bottom: 1px solid #555;
        padding: 15px 10px !important;
        position: relative;
        .badge {
          display: block;
          width: 35px;
          height: 35px;
          padding: 0;
          position: absolute;
          right: 4px;
          top: 10px;
          z-index: 5;
          border-radius: 50%;
          background-color: #fff;
          @media (max-width: 1199px) {
            width: 30px;
            height: 30px;
          }
          img {
            display: block;
            max-width: 100%;
          }
        }
      }
      .tickets {
        position: relative;
        .icon-mark {
          position: absolute;
          top: 5px;
          right: 4px;
          width: 35px;
          height: 35px;
        }
      }
      .is-winner {
        .icon-mark {
          display: block;
        }
      }
    }
  }
  #president_1 {
    #got_elected {
      .partisan_assign {
        .item {
          .photo {
            &::before {
              content: normal;
            }
          }
        }
      }
    }
  } 
  .nav-tabs-custom {
    min-height: 510px;
  }
  .tab-content > {
    .tab-pane {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      .chart-area {
        position: relative;
  
        >div {
        &::before {
          content: '';
          background: url('../../../assets/img/logo-news.png');
          content: '';
          background: url(/img/logo-news.663d67da.png) no-repeat;
          background-size: cover;
          width: 162px;
          height: 25px;
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
        }

          padding-top: 50px;
        }
      }
    }
  }
  .btn-download {
    display: block !important;
    margin: 10px auto 0 auto;
  }
  .box-title {
    width: 100%;
    margin-top: 0;
    font-size: 30px;
    font-weight: normal;
    font-family: Microsoft JhengHei;
    text-align: center;
  }
  .legend {
    width: 100%;
    text-align: center;
    padding: 10px 0 !important;
    p {
      margin: 0;
      &.note {
        color: #666666;
        font: 15px Microsoft JhengHei;
      }
    }
    .item {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px;
      .icon,
      p {
        display: inline-block;
        vertical-align: middle;
      }
      .icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        border-radius: 50%;
      }
      p {
        color: #333333;
        font: 18px Microsoft JhengHei;
      }
    }
  }
}
.partisan_assign {
  display: table;
  min-width: 1000px;
  margin: auto;
  text-align: center;
  .title {
    font-weight: bold;
    font-size: 30px;
  }
  .tickets {
    font-size: 20px;
  }
}
.flex-block {
  display: flex;
  align-items: center;
  > .flex-item {
    width: 50%;
  }
  .icon-right {
    content: "";
    display: block;
    min-width: 60px;
    height: 60px;
    background: center no-repeat;
    background-size: contain;
    transform: translateY(calc(50% - 30px));
  }
}
#global_rate {
  .flex-block {
    width: 1100px;
  }
}
#global_territory {
  display: flex;
  flex-wrap: wrap;
  width: 1300px;
  min-height: 640px;
  .year-group {
    max-width: 50%;
    .chart-area {
      .year-item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding-left: 20px;
        position: relative;
        &:last-of-type {
          &:after {
            content: "";
            display: block;
            width: 3px;
            height: 96%;
            position: absolute;
            left: 0;
            top: 2%;
            background-color: #dbdbdb;
          }
        }
        .year {
          width: 100%;
          margin-bottom: 20px;
          font-size: 28px;
          text-align: left;
        }
        .badges {
          width: 155px;
          padding-left: 10px;
          p {
            margin-bottom: 0;
          }
          .item {
            margin-bottom: 20px;
            padding: 10px;
            border-radius: 10px;
            background-color: #dbdbdb;
            font-size: 15px;
            font-weight: normal;
            position: relative;
            text-align: right;
            .icon {
              position: absolute;
              left: -10px;
              top: -10px;
              z-index: 2;
            }
            .table {
              > thead > tr {
                > th,
                > td {
                  padding: 0;
                  border: none;
                  font-weight: normal;
                  text-align: right;
                  &:first-of-type {
                    width: 75px;
                  }
                }
                > th {
                  vertical-align: bottom;
                  &.seat {
                    color: red;
                  }
                }
                > td {
                  color: #fff;
                  text-shadow: 0 0 1px #000, 1px 1px 3px #000;
                  &.seat {
                    color: yellow;
                  }
                }
              }
              .subtitle {
                font-size: 18px;
              }
            }
            &.KMT {
              background-color: #a7c7ff;
            }
            &.DPP {
              background-color: #83e7b4;
            }
            &.TPP {
              background-color: #b2ebef;
            }
            &.NPP {
              background-color: #ffdf7d;
            }
            &.PFP {
              background-color: #ffbb80;
            }
            &.no-party {
              .subtitle {
                > th {
                  line-height: 20px;
                  padding-bottom: 5px;
                }
              }
            }
            &.others {
              .icon {
                display: none;
              }
            }
          }
        }
        .map {
          width: calc(100% - 155px);
        }
      }
    }
  }
  .note {
    width: 100%;
    color: #666666;
    font-size: 15px;
    text-align: center;
    font-family: Microsoft JhengHei;
  }
}
</style>

<script>
/* eslint-disable */
import api from '@/assets/js/app-api';
import ElectionPieChart from "@/views/projects/Newtalk/ElectionPieChart";
import ElectionBarChart from "@/views/projects/Newtalk/ElectionBarChart";
import Election3dChart from "@/views/projects/Newtalk/Election3dChart";
import ElectionMapChart from "@/views/projects/Newtalk/ElectionMapChart";
import html2canvas from "html2canvas";
import { mapState } from "vuex";

// 【色系規則】
// 政黨得票率/政黨近年比較圖 : 1.藍 綠 2.若是超過10%在使用該政黨顏色 3.其他灰色
// 議員/立委席次分配 : 1.藍 綠 2.若是超過4%在使用該政黨顏色 3.其他灰色
// 政黨近年比較圖 : 超過10%黨派有顏色，超過15%才有顯示頭像
// 2022全部：國民黨/民進黨/民眾黨/黃珊珊/鄭寶清，皆直接顯示

export default {
  components: {
    ElectionPieChart,
    ElectionBarChart,
    Election3dChart,
    ElectionMapChart,
  },
  data() {
    return {
      loading: true,
      type: this.$route.params.type,
      category: this.$route.params.type == "President" ? "總統" : "縣市長",
      waitingResponse: false,
      total: 0,
      classData: {
        module: "vote",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      },
      tabName: "",
      searchData: {
        area: "六都",
        county: "63000",
        year: this.$route.params.type == "President" ? "2024" : "2022",
      },
      selectedGroup: 0,
      cityList: ["六都", "北部", "中部", "南部", "東部", "離島", "原住民"],
      countyList: [],
      countyNameList: {},
      constituency: {},
      list: [],
      mayorDatas: [],
      cecTime: "",
      iconRight: require("@/assets/img/icon-right.png"),

      // 縣市長數據
      hasCandidate: false,
      thisMayorDatas: [], //今年
      prevMayorDatas: [], //往年

      // 議員席次分配
      hasCouncilman: false,
      thisCouncilmanList: [], //今年
      prevCouncilmanList: [], //往年
      thisYear: "",
      prevYear: "",
      councilmanSeatsList: [
        { name: 2018, data: [] },
        { name: 2022, data: [] },
      ],

      // 政黨近年比較圖 (今年與往年比較)
      cityPartyVotesList: [
        { year: 2018, data: [] },
        { year: 2022, data: [] },
      ],
      searchCityParty: false,

      // 全國-政黨版圖
      partySeats: [
        { year: 2018, data: [], map: [] },
        { year: 2022, data: [], map: [] },
      ],
      hasPartySeats: false,

      // 全國-政黨得票率
      partyPreferenceList: [
        { name: 2018, data: [] },
        { name: 2022, data: [] },
      ],
      hasPartyVotes: false,
      chartData:{
        president: [],
        legislatorCounty: [],
        legislatorAll: [],
        party: [],
      },
      electStatus: {
        "normal": "自然當選",
        "woman": "婦女保障當選",
        "excluded": "被排擠未當選", 
        "same": "同票待決", 
        "yet": "未達選罷法第70條", 
      }
    };
  },
  computed: {
    yearList() {
      if(this.type == "CountyMayor") return ["2022", "2018"]
      else return ["2024", "2020"]
    },
    dataList() {
      if(this.category == "政黨") return this.mayorDatas.candidateList[this.selectedGroup].representers
      else return this.mayorDatas.candidateList
    },
    ...mapState({
      menu: state => state.electionPerson.menu,
      countyQueryAll: state => state.electionPerson.countyQueryAll,
      goropList: state => state.electionGroup.queryAll,
    })
  },
  watch: {
    $route(val) {
      this.type = val.params.type;
      if(this.type == "CountyMayor") this.category = "縣市長";
      else this.category = "總統";
      this.searchData.year = this.yearList[0];
      this.thisYear = this.yearList[0];
      this.prevYear = this.yearList[1];
      this.resetDataList();
    },
    "searchData.year"(val) {
      const Index = this.yearList.findIndex(item => item == val);
      this.thisYear = Index == this.yearList.length - 1 ? this.yearList[Index - 1] : val;
      this.prevYear = Index == 0 ? this.yearList[1] : val;
      this.resetDataList();
    },
    category(val) {
      this.resetSearch();
    }
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.thisYear = this.yearList[0];
    this.prevYear = this.yearList[1];
    this.searchData.year = this.thisYear;
    this.getMenu();
    this.getGroupList();
    // this.setCountyList("municipality");
  },
  methods: {
    switchBadgeColor(name) {
      switch (name) {
        case "中國國民黨":
        case "國民黨":
        case "kmt":
          return "#000095";
        case "民主進步黨":
        case "民進黨":
        case "dpp":
          return "#1B9431";
        case "台灣民眾黨":
        case "tpp":
          return "#28C8C8";
        case "時代力量":
        case "npp":
          return "#F9BE01";
        case "親民黨":
        case "pfp":
          return "#FF6310";
        case "無黨籍及未經政黨推薦":
        case "無黨籍與其他黨派":
        case "無黨籍":
          return "#999999";
        default:
          return "#d4d4d4";
      }
    },
    switchBadgeId(name) {
      switch (name) {
        case "中國國民黨":
          return "KMT";
        case "民主進步黨":
          return "DPP";
        case "台灣民眾黨":
          return "TPP";
        case "時代力量":
          return "NPP";
        case "親民黨":
          return "PFP";
        case "無黨籍及未經政黨推薦":
        case "無黨籍與其他黨派":
          return "no-party";
        case "其他黨派":
          return "others";
      }
    },
    legislatorSize(data) {
      if(!data) return
      if(data.length <= 6) return
      let count = Math.ceil(data.length / 2);
      let w = count * 200 ;
      return `max-width: ${w}px;`;
    },
    getMenu() {
      let data = new FormData();
      data.append("year", 2024);
      this.$store.dispatch(`electionPerson/menu`, data)
        .then((res) => {
          this.setCounty();
        })
    },
    // 設定縣市選單
    setCounty() {
      this.countyList = this.menu[this.searchData.area];
      this.countyNameList = {};
      this.menu[this.searchData.area].forEach((data) => {
        this.countyNameList[data.代碼] = data;
      })
      this.searchData.county = this.countyList[0].代碼;
      this.setConstituency();
    },
    // 設定選區選單
    setConstituency() {
      if(this.searchData.area != "原住民") {
        const county = this.searchData.county;
        this.searchData.constituency = "";
        this.constituency = this.countyNameList[county]["選區"];
        this.searchData.constituency = "1";
      }
      this.resetDataList();
    },
    downloadPhoto(id, filename) {
      html2canvas(document.getElementById(id), {
        background: '#fff',
        allowTaint: false,
        useCORS: true,
        quality: 0.95,
      }).then(function (canvas) {
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = filename + ".png";
        a.click();
        a.remove();
      });
    },
    showTab(name) {
      this.tabName = name;
      this.$forceUpdate();
    },
    imgUrlAlt(event) {
      event.target.src = require("@/assets/anonymous_120.jpg");
    },
    numberWithCommas(number) {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    resetDataList() {
      this.clearDatas();
      this.getDataList(); //取得縣市長資料
      // this.getCouncilmanData(); // 取得議員席次分配
    },
    resetSearch() {
      this.searchData.area = "六都";
      this.searchData.year = this.yearList[0];
      if(this.category == "立委") this.searchData.constituency = "1";
      if(this.category == "政黨") this.selectedGroup = "0";
      this.setCounty();
      this.clearDatas();
      this.resetDataList();
    },
    clearDatas() {
      this.hasCandidate = false;
      this.hasPartyVotes = false;
      this.mayorDatas = [];
      this.thisMayorDatas = [];
      this.prevMayorDatas = [];
      this.thisCouncilmanList = [];
      this.prevCouncilmanList = [];
    },
    // 取黨派列表
    getGroupList() {
      let data = new FormData();
      data.append("page", 1);
      data.append("pageSize", 999);

      this.$store.dispatch(`electionGroup/queryAll`, data)
    },

    getDataList(year) {
      this.loading = true;

      let county = this.searchData.area == "原住民" ? this.searchData.county.split('-')[0] : this.searchData.county
      let constituency = this.searchData.area == "原住民" ? this.searchData.county.split('-')[1] : this.searchData.constituency
      const formData = new FormData();
      formData.append("year", year || this.searchData.year);

      let type
      let tabName

      if(this.category == "總統") {
        type = "getPresidentData";
        tabName = "president_1"
        formData.append("constituency", "00");
        formData.append("cityCode", county);
      }
      if(this.category == "縣市長") {
        type = "getMayorData";
        tabName = "partisan_1"
        formData.append("cityCode", county);
      }
      if(this.category == "立委") {
        type = "getLegislatorData";
        tabName = "legislator_1"
        formData.append("constituency", String(constituency).padStart(2, "0"));
        formData.append("cityCode", county);
      }
      if(this.category == "政黨") {
        type = "getPartyData";
        tabName = "legislator_1"
      }

      this.$store.dispatch(`newtalk/${type}`, formData).then((result) => {
        if (result.isSuccess && result.data) {
          if (!this.hasCandidate) {
            this.hasCandidate = true;
            if (this.searchData.year === this.prevYear) {
              this.prevMayorDatas = result.data;
            } else {
              this.thisMayorDatas = result.data;
            }
            this.mayorDatas = result.data || [];

            const cecTime = result.data.cecTime;
            if (cecTime) {
              const timeLength = cecTime.length;
              this.cecTime = cecTime
                .slice(0, timeLength - 3)
                .replace(/-/g, ".");
              this.$forceUpdate();
            }

            if(this.category == "縣市長") {
              this.setCandidateDatas(); //重組：候選人得票數 (長條圖)
              this.setElectionProfileDatas(); // 重組：候選人得票數 (圓餅圖)
            }

            if(this.category == "立委") {
              this.setCandidateDatas(); //重組：候選人得票數 (長條圖)
            }
            this.showTab(tabName); // 預設第一個tag

            this.getDataList(this.prevYear);
          } else {
            if (year === this.thisYear) {
              this.thisMayorDatas = result.data;
            } else {
              this.prevMayorDatas = result.data;
            }
          }
          if(this.category == "縣市長") this.setMayorChart();
          if(this.category == "總統") this.setPieData('president', 10, true, this.$refs.electionPieChart_president2_Prev, this.$refs.electionPieChart_president2_This);
          if(this.category == "政黨") this.setPieData('party', 5, false, this.$refs.electionPieChart_party_Prev, this.$refs.electionPieChart_party_This);
          if(this.category == "立委") {
            this.setPieData('legislatorCounty', 4, false, this.$refs.electionPieChart_legislator3_Prev, this.$refs.electionPieChart_legislator3_This);
            this.setPieData('legislatorAll', 10, true, this.$refs.electionPieChart_legislator4_Prev, this.$refs.electionPieChart_legislator4_This);
          }
        }
        this.loading = false;
        this.$forceUpdate();
      });
    },
    setMayorChart() {
      // 重組：全國-政黨得票率資料
      if (
        this.prevMayorDatas.electionProfileAllCity &&
        this.thisMayorDatas.electionProfileAllCity &&
        !this.hasPartyVotes
      ) {
        this.setPartyVotesDatas();
      }

      // 重組：全國-政黨版圖資料
      if (
        this.prevMayorDatas.partyPreferenceMap &&
        this.thisMayorDatas.partyPreferenceMap &&
        !this.hasPartySeats
      ) {
        this.setPartyMapDatas();
      }

      // 重組：政黨近年比較圖 (今年與往年比較)
      if (
        this.prevMayorDatas.electionProfile &&
        this.thisMayorDatas.electionProfile
      ) {
        this.setCityPartyVotes();
      }
    },
    // 重組：候選人得票數 (長條圖)
    setCandidateDatas() {
      let candidateList = [];
      const candidateDatas = this.mayorDatas.candidateList || [];
      candidateDatas.forEach((item) => {
        let name_ = item.candidateName == '李我要單列族名我的布農族名字是SavungazValincinan' ?
          `李我要單列族名我的布農族名字是<br/>SavungazValincinan` : item.candidateName;
          
        candidateList.push({
          y: Number(item.tickets),
          color: this.switchBadgeColor(item.candidatePartyName),
          name: name_,
          tickets: this.numberWithCommas(item.tickets),
        });
      });

      if (this.$refs.electionBarChart) {
        this.$refs.electionBarChart.setSeriesDataList(candidateList);
      }
    },
    // 重組：候選人得票數 (圓餅圖)
    setElectionProfileDatas() {
      if(!this.mayorDatas.electionProfile) return
      let electionProfile = [];
      const electionDatas = this.mayorDatas.electionProfile;
      Object.keys(electionDatas).forEach((key) => {
        if (key === "totalTickets") return;
        electionProfile.push({
          y: electionDatas[key].allTickets,
          color: this.switchBadgeColor(key),
          name: key,
          rate: electionDatas[key].rate,
          tickets: this.numberWithCommas(electionDatas[key].allTickets),
        });
      });

      if (this.$refs.electionPieChart) {
        this.$refs.electionPieChart.setSeriesDataList(electionProfile);
      }
    },

    // 重組：全國-政黨得票率資料
    setPartyVotesDatas() {
      this.partyPreferenceList = [
        { name: 2018, data: [] },
        { name: 2022, data: [] },
      ];

      // 整理成圖表所需格式
      this.partyVotesSeriesDatas(this.prevMayorDatas.electionProfileAllCity, 0);
      this.partyVotesSeriesDatas(this.thisMayorDatas.electionProfileAllCity, 1);
      this.hasPartyVotes = true;
    },
    partyVotesSeriesDatas(datas, index) {
      this.partyPreferenceList[index].data = [];
      const partyDatas = Object(datas);
      Object.keys(partyDatas).forEach((key) => {
        if (key === "totalTickets") return;
        this.partyPreferenceList[index].data.push({
          y: partyDatas[key].allTickets,
          badge: key,
          name: key,
          rate: partyDatas[key].rate,
          tickets: this.numberWithCommas(partyDatas[key].allTickets),
        });
      });

      // 更新：全國圖表-政黨得票率(今年與往年比較)
      const chart =
        index === 0
          ? this.$refs.election3dChart_prev
          : this.$refs.election3dChart_this;
      if (chart) {
        chart.setSeriesDataList(this.partyPreferenceList[index]);
      }
    },


    setPieData(name, percent, allData, prevName, thisName) {
      this.chartData[name] = [
        { name: this.prevYear, data: [] },
        { name: this.thisYear, data: [] },
      ];

      let type = allData ? "electionProfileAllCity" : "electionProfile";
      this.setDataFormat(name, percent, this.prevMayorDatas[type], 0, prevName, thisName);
      this.setDataFormat(name, percent, this.thisMayorDatas[type], 1, prevName, thisName);
    },

    setDataFormat(name, percent, data, index, prevName, thisName) {
      const datas = Object(data);
      Object.keys(datas).forEach((key) => {
        if (key === "totalTickets") {
          // 總有效票數
          this.chartData[name][index].totalTickets = this.numberWithCommas(
            datas[key]
          );
          return;
        }
        this.searchCandidateData(datas, percent, name, key, index);
      })

      // 更新圖表
      const chart =
        index === 0
          ? prevName
          : thisName;
      if (chart) {
        chart.setSeriesDataList(this.chartData[name][index].data);
      }
    },
    searchCandidateData(datas, percent, name, badge, index) {
      let info = {};
      let specialInfo = {};
      let finalTickets = Number(datas[badge].allTickets);
      let finalRate = Number(datas[badge].rate);
      const candidateList =
        index === 0
          ? this.prevMayorDatas.candidateList
          : this.thisMayorDatas.candidateList;

      candidateList.forEach((item, i) => {
        const image = item.candidateImage
          ? item.candidateImage + "?time=" + new Date().valueOf()
          : require("@/assets/no-image2.png");
        if (item.candidatePartyName === badge) {
          if (
            Number(item.rate) > percent ||
            badge === "中國國民黨" ||
            badge === "民主進步黨" ||
            badge === "台灣民眾黨" ||
            this.category == "總統"
          ) {
            if (badge === "無黨籍及未經政黨推薦") return
            if(Array.isArray(item.candidate)) {
              info = {
                name: item.candidate[0].name,
                image: item.candidate[0].image,
              };
            } else {
              info = {
                name: item.candidateName,
                image: image,
              };
            }
          }
        }        
      })
      if (specialInfo.badge === badge) {
        // 同黨派移除特定人員
        if (Number(finalRate) > 0) {
          this.chartData[name][index].data.push({
            y: Number(finalRate),
            color: this.switchBadgeColor(badge),
            rate: Math.round(finalRate * 100) / 100,
            badge: badge,
            name: badge,
            tickets: this.numberWithCommas(finalTickets),
            category: this.category,
          });
        }
      } else {
        // 整理成圖表所需格式
        const seat = datas[badge].electedCandidateTotal;
        const rate = datas[badge].electedCandidateRate || datas[badge].rate;

        if(this.category != "總統" && (!seat || seat == 0)) return
        if(this.category != "總統" && badge !== "中國國民黨" && badge !== "民主進步黨" && rate < percent) {
          const Index = this.chartData[name][index].data.findIndex(item => item.badge.indexOf('無黨籍') > -1);
          if(Index > -1) {
            this.chartData[name][index].data[Index].seat+=seat;
            this.chartData[name][index].data[Index].rate+=rate;
            this.chartData[name][index].data[Index].y+=rate;
            if(badge.indexOf('無黨籍') == -1) {
              this.chartData[name][index].data[Index].name = "無黨籍和其他";
              this.chartData[name][index].data[Index].color = "#d4d4d4";
            }
            return
          } else {
            this.chartData[name][index].data.push({
              y: rate,
              color: "#999",
              candidate: "無黨籍",
              seat: seat,
              rate: rate,
              badge: "無黨籍",
              name: "無黨籍",
              tickets: this.numberWithCommas(datas[badge].allTickets),
              image: info.image,
              category: this.category,
            });
          }
          return
        }

        this.chartData[name][index].data.push({
          y: rate,
          color: this.switchBadgeColor(badge),
          candidate: info.name,
          seat: seat,
          rate: rate,
          badge: badge,
          name: badge.indexOf('無黨籍') > -1 ? "無黨籍" : badge,
          tickets: this.numberWithCommas(datas[badge].allTickets),
          image: info.image,
          category: this.category,
        });
      }
    },



    // 重組：政黨近年比較圖 (今年與往年比較)
    setCityPartyVotes() {
      this.cityPartyVotesList = [
        { year: 2018, data: [] },
        { year: 2022, data: [] },
      ];

      // 整理成圖表所需格式
      let type = this.category == "總統" || this.category == "立委" ? "electionProfileAllCity" : "electionProfile"
      this.cityParyVotesSeries(this.prevMayorDatas[type], 0);
      this.cityParyVotesSeries(this.thisMayorDatas[type], 1);
    },
    cityParyVotesSeries(datas, index) {
      const cityVotesDatas = Object(datas);
      Object.keys(cityVotesDatas).forEach((key) => {
        if (key === "totalTickets") {
          // 總有效票數
          this.cityPartyVotesList[index].totalTickets = this.numberWithCommas(
            cityVotesDatas[key]
          );
          return;
        }
        this.searchCandidateInfo(cityVotesDatas, key, 15, index);
      });

      // 更新：政黨近年比較圖 (今年與往年比較)
      const chart =
        index === 0
          ? this.$refs.electionPieChart_partyPrev
          : this.$refs.electionPieChart_partyThis;
      if (chart) {
        chart.setSeriesDataList(this.cityPartyVotesList[index].data);
      }
    },
    searchCandidateInfo(datas, badge, percent, index) {
      let info = {};
      let specialInfo = {};
      let finalTickets = Number(datas[badge].allTickets);
      let finalRate = Number(datas[badge].rate);
      const candidateList =
        index === 0
          ? this.prevMayorDatas.candidateList
          : this.thisMayorDatas.candidateList;
      const isTaipei = this.searchData.county === "63000";
      const isTaoyuan = this.searchData.county === "68000";

      candidateList.forEach((item, i) => {
        const image = item.candidateImage
          ? item.candidateImage + "?time=" + new Date().valueOf()
          : require("@/assets/no-image2.png");
        // 額外獨立顯示：1.台北市 無黨籍 黃珊珊  2.桃園市 無黨籍 鄭寶清  3. 無政黨並大於15%
        if (item.candidatePartyName === badge) {
          const isNoPartyOverRate = Number(item.rate) > percent;
          if (
            this.category == "縣市長" &&
            ((isTaipei && item.candidateName === "黃珊珊") ||
            (isTaoyuan && item.candidateName === "鄭寶清") ||
            isNoPartyOverRate)
          ) {
            finalTickets = finalTickets - Number(item.tickets);
            finalRate = finalRate - Number(item.rate);

            // 額外建立特定人員
            specialInfo = {
              y: Number(item.tickets),
              color: this.switchBadgeColor(badge),
              candidate: item.candidateName,
              rate: Math.round(item.rate * 100) / 100,
              badge: item.candidatePartyName,
              name: badge + "(" + item.candidateName + ")",
              tickets: this.numberWithCommas(Number(item.tickets)),
              image: image,
            };
            this.cityPartyVotesList[index].data.push(specialInfo);
          }

          // 以黨名撈取對應參選人資訊，大於15%才顯示頭像暱稱
          // 額外獨立顯示：1.國民黨 2.民進黨 3.民眾黨
          if (
            Number(item.rate) > percent ||
            badge === "中國國民黨" ||
            badge === "民主進步黨" ||
            badge === "台灣民眾黨"
          ) {
            if (badge === "無黨籍及未經政黨推薦") return;
            if(Array.isArray(item.candidate)) {
              info = {
                name: item.candidate[0].name,
                image: item.candidate,
              };
            } else {
              info = {
                name: item.candidateName,
                image: image,
              };
            }
          }
        }
      });
      if (specialInfo.badge === badge) {
        // 同黨派移除特定人員
        if (Number(finalTickets) > 0) {
          this.cityPartyVotesList[index].data.push({
            y: Number(finalTickets),
            color: this.switchBadgeColor(badge),
            rate: Math.round(finalRate * 100) / 100,
            badge: badge,
            name: badge,
            tickets: this.numberWithCommas(finalTickets),
          });
        }
      } else {
        if((this.category == "政黨" || this.category == "立委") && (!datas[badge].electedCandidateTotal || datas[badge].electedCandidateTotal == 0)) return
        // 整理成圖表所需格式
        this.cityPartyVotesList[index].data.push({
          y: Number(datas[badge].allTickets),
          color: this.switchBadgeColor(badge),
          candidate: info.name,
          rate: datas[badge].rate,
          badge: badge,
          name: badge,
          tickets: this.numberWithCommas(datas[badge].allTickets),
          image: info.image,
        });
      }
    },

    // 重組：全國-政黨版圖資料
    setPartyMapDatas() {
      this.partyMapSeriesDatas(
        this.prevMayorDatas.partyPreferenceMap,
        this.prevMayorDatas.partyPreference,
        0
      );
      this.partyMapSeriesDatas(
        this.thisMayorDatas.partyPreferenceMap,
        this.thisMayorDatas.partyPreference,
        1
      );
      this.hasPartySeats = true;
      this.$forceUpdate();
    },
    partyMapSeriesDatas(mapDatas, seatsDatas, index) {
      // 地圖資料
      let mapList = [];
      const partySeatsMap = mapDatas;
      Object.keys(partySeatsMap).forEach((key) => {
        mapList.push({
          id: key,
          badge: partySeatsMap[key]["00"],
        });
      });
      if (mapList.length > 0) {
        const partySeats = seatsDatas;
        Object.keys(partySeats).forEach((key) => {
          this.partySeats[index].map = mapList;
          this.partySeats[index].data.push({
            name: key,
            municipality: partySeats[key].municipality || 0,
            others: partySeats[key].others || 0,
            total: partySeats[key].total,
          });
        });
      }
    },

    // 取得議員席次分配
    getCouncilmanData(year) {
      const formData = new FormData();
      formData.append("year", year || this.searchData.year);
      formData.append("cityCode", this.searchData.county);
      this.$store
        .dispatch("newtalk/getCouncilmanData", formData)
        .then((result) => {
          if (result.isSuccess) {
            if (year === "2022" || this.searchData.year === "2022") {
              this.thisCouncilmanList = result.data;
            } else {
              this.prevCouncilmanList = result.data;
            }

            // 獲取另一個年份的資料
            if (!this.hasCouncilman) {
              const searchYear =
                this.searchData.year === "2022" ? "2018" : "2022";
              this.hasCouncilman = true;
              this.getCouncilmanData(searchYear);
            } else {
              // 重組：議員席次分配資料
              this.setCouncilmanDatas();
            }
          }
        });
    },
    // 重組：議員席次分配資料
    setCouncilmanDatas() {
      this.seatsBadgeList = [];
      this.councilmanSeatsList = [
        { name: 2018, data: [] },
        { name: 2022, data: [] },
      ];

      // 整理成圖表所需格式
      this.seatsSeriesDatas(this.prevCouncilmanList.electionProfile, 0);
      this.seatsSeriesDatas(this.thisCouncilmanList.electionProfile, 1);

      // 更新：議員席次分配(今年與往年比較)
      if (this.$refs.electionPieChart_seats) {
        this.$refs.electionPieChart_seats.setSeriesDataList(
          this.councilmanSeatsList
        );
      }
    },
    seatsSeriesDatas(datas, index) {
      const seatsDatas = Object(datas);
      Object.keys(seatsDatas).forEach((key) => {
        if (key === "totalTickets") return;
        this.councilmanSeatsList[index].data.push({
          y: seatsDatas[key].allTickets,
          color: this.switchBadgeColor(key),
          rate: seatsDatas[key].rate,
          badge: key,
          name: key,
        });
      });

      // 兩個年份的黨派整理，排除重複的
      const that = this;
      const badgeList = this.councilmanSeatsList[index].data;
      badgeList.forEach(function (item) {
        if (that.seatsBadgeList.indexOf(item.badge) == -1) {
          that.seatsBadgeList.push(item.badge);
        }
      });
    },
    setNameSize(data) {
      if(!data) return
      const count = data.replace(/[^\x00-\xff]/g,"OO").length;
      return count > 44 ? '_l3' : count > 22 ? '_l2' : ''
    },
    setNameHeight() {
      const isLine2 = $('.partisan_assign .item .name').hasClass("_l2");
      const isLine3 = $('.partisan_assign .item .name').hasClass("_l3");
      return isLine3 ? '_h3' : isLine2 ? '_h2' : ''
    }
  },
};
</script>
