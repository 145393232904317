<template>
    <div id="ClassTypeManager">
        <section class="content-header">
            <h1>{{ $t('統計表') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('統計表') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-default">
                        <div class="box-body">
                            <div class="col-md-6">
                                <!--左上Start-->
                                <div>
                                    <div class="box-header with-border">
                                        <DaterangepickerForMessage ref="datePicker"
                                            @ApplyDaterangepicker="applyDaterangepicker">
                                        </DaterangepickerForMessage>
                                        <div class="state icheckbox_flat-green" :class="this.排除自動關閉事件 ? 'checked' : ''"
                                            @click="切換排除自動關閉事件()"></div>
                                        <span style="line-height: 34px;vertical-align: middle;">
                                            {{ $t('排除自動關閉的事件') }}
                                        </span>
                                    </div>
                                    <div class="box-body">
                                        <div style="height: 10px;"></div>
                                        <div class="row">
                                            <div class="col-lg-6 col-xs-6">
                                                <div class="small-box bg-aqua cusor_hand"
                                                    @click='重置圖表(ChartTypeEnum.事件);'>
                                                    <template v-if="進行事件數 == 'none'">
                                                        <div class="inner">
                                                            <h3>{{ 完成事件數 }}</h3>
                                                            <p>{{ $t('完成事件數') }}</p>
                                                            <div class="icon" style="right:100px">
                                                                <i class="fa fa-fw fa-weixin"></i>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="inner">
                                                            <h3>{{ 進行事件數 }} / {{ 完成事件數 }}</h3>
                                                            <p>{{ $t('進行事件數') }} / {{ $t('完成事件數') }}</p>
                                                            <div class="icon" style="right:100px">
                                                                <i class="fa fa-fw fa-weixin"></i>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-xs-6">
                                                <div class="small-box bg-aqua cusor_hand"
                                                    @click='重置圖表(ChartTypeEnum.時間);'>
                                                    <div class="inner">
                                                        <h3>{{ filterAvgFinishTime(平均完成時間) }}</h3>
                                                        <p>{{ $t('平均完成時間') }}</p>
                                                    </div>
                                                    <div class="icon" style="right:100px">
                                                        <i class="fa fa-fw fa-check-square"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--左上End-->
                                <!--左下Start-->
                                <div class="box-header with-border">
                                    <i class="fa fa-bar-chart"></i>
                                    <h3 class="box-title">{{ $t('客服成員統計') }}</h3>
                                </div>
                                <div class="box-body">
                                    <div style="display: flex;">
                                        <div v-if="是否即時資料"
                                            style="line-height: 34px;white-space: nowrap;padding-right: 5px;">
                                            <div class="state icheckbox_flat-green" :class="this.是否執勤中 ? 'checked' : ''"
                                                @click="切換執勤中()"></div>
                                            {{ $t('執勤中') }}
                                        </div>
                                        <div style="width:100%">
                                            <multiselect v-model="Cs" :options="客服列表" :multiple="true"
                                                :close-on-select="false" :clear-on-select="false"
                                                :preserve-search="true" :placeholder="$t('請輸入姓名')" label="show"
                                                track-by="Cs" :preselect-first="false">
                                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span
                                                        class="multiselect__single"
                                                        v-if="values.length == 客服列表.length && 客服列表.length != 0">
                                                        {{ $t('已選取所有客服') }}</span></template>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <div style="height: 300px; overflow-y: scroll;margin-top: 10px;">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr style="background-color:white">
                                                    <th v-if="!是否即時資料">{{ $t('比較') }}</th>
                                                    <th style="">{{ $t('姓名') }}</th>
                                                    <th v-if="是否即時資料" @click="改變排序欄位('ProcessingCount')">
                                                        <i class="fa fa-fw fa-long-arrow-up"
                                                            v-if="當前排序 == 'ProcessingCount遞增'"></i>
                                                        <i class="fa fa-fw fa-long-arrow-down"
                                                            v-if="當前排序 == 'ProcessingCount遞減'"></i>
                                                        <i class="fa fa-fw fa-arrows-v" style="color:gray"
                                                            v-if="排序欄位 != 'ProcessingCount'"></i>
                                                        {{ $t('進行中') }}
                                                    </th>
                                                    <th @click="改變排序欄位('FinishCount')">
                                                        <i class="fa fa-fw fa-long-arrow-up"
                                                            v-if="當前排序 == 'FinishCount遞增'"></i>
                                                        <i class="fa fa-fw fa-long-arrow-down"
                                                            v-if="當前排序 == 'FinishCount遞減'"></i>
                                                        <i class="fa fa-fw fa-arrows-v" style="color:gray"
                                                            v-if="排序欄位 != 'FinishCount'"></i>
                                                        {{ $t('已完成') }}
                                                    </th>
                                                    <th @click="改變排序欄位('AvgFinishTime')">
                                                        <i class="fa fa-fw fa-long-arrow-up"
                                                            v-if="當前排序 == 'AvgFinishTime遞增'"></i>
                                                        <i class="fa fa-fw fa-long-arrow-down"
                                                            v-if="當前排序 == 'AvgFinishTime遞減'"></i>
                                                        <i class="fa fa-fw fa-arrows-v" style="color:gray"
                                                            v-if="排序欄位 != 'AvgFinishTime'"></i>
                                                        {{ $t('平均完成時間') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item of filter客服成員統計表" :key="item.Cs"
                                                    @click.stop="加入比較(item)" :class="!是否即時資料 ? 'cusor_hand' : ''">
                                                    <td v-if="!是否即時資料">
                                                        <div class="state icheckbox_flat-green"
                                                            :class="item.compare ? 'checked' : ''">
                                                        </div>
                                                    </td>
                                                    <td>{{ filter客服(item.Cs) }}</td>
                                                    <td v-if="是否即時資料">{{ item.ProcessingCount }}</td>
                                                    <td>{{ item.FinishCount }}</td>
                                                    <td>{{ filterAvgFinishTime(item.AvgFinishTime) }}</td>
                                                </tr>
                                                <tr v-if="filter客服成員統計表.length == 0">
                                                    <td colspan="5">{{ $t('無資料') }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!--左下End-->
                            </div>
                            <div class="col-md-6">
                                <!--右上Start-->
                                <div>
                                    <div class="box-header with-border">
                                        <i class=" fa fa-bar-chart"></i>
                                        <h3 class="box-title">{{ $t('圖表') }}</h3>
                                    </div>
                                    <div class="box-body">
                                        <highcharts :options="圖表物件"></highcharts>
                                    </div>
                                </div>
                                <!--右上End-->
                                <!--右下Start-->
                                <!--右下End-->
                            </div>
                        </div>
                        <div class="overlay" ref="loading">
                            <i class="fa fa-refresh fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import moment from "moment";
import DaterangepickerForMessage from "./DaterangepickerForMessage";
import { formatCostTime, CsAPI } from "@/store/MessageSystem/messageSystemLib";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
export default {
    components: {
        DaterangepickerForMessage, Multiselect, formatCostTime
    },
    data() {
        let ChartTypeEnum = {
            事件: 1,
            時間: 2
        };
        let data = {
            ChartTypeEnum,
            排序欄位: "ProcessingCount",//	FinishCount	AvgFinishTime
            排序順序: "遞增",//遞減
            完成事件數: "----",
            進行事件數: "none",//表示不顯示
            平均完成時間: 0,
            是否執勤中: false,
            排除自動關閉事件: false,
            圖表類型: ChartTypeEnum.事件,//顯示當前圖表的類型，事件或時間
            圖表物件: {
                credits: false,
                chart: { type: "spline"/*, height: 300*/ },
                title: { text: "無資料" },
                xAxis: {
                    title: { text: "日期" },
                    categories: []
                },
                yAxis: {
                    title: { text: "事件數" },
                    labels: {}
                },
                series: [{ data: [] },],
                tooltip: {
                },
            },
            Cs: [],
            客服成員統計表_列表: [
                // AvgFinishTime : 221
                // Cs :  "客服1"
                // FinishCount : 11
                // Online : true
                // ProcessingCount : 3
            ],
            圖表資料: {
                總表_事件: { Chart_Key: [], Chart_Val: [] },
                總表_時間: { Chart_Key: [], Chart_Val: [] },
                客服_事件: [],
                客服_時間: [],
            },
            是否即時資料: true,//時間區間選擇即時的資料，不可做比較
            客服列表: [],
        };
        return data;
    },
    computed: {
        ...mapState({
            客服顯示對應表: state => state.messageSystemServer.客服顯示對應表,
            stateProjectId: state => state.projectId,
        }),
        當前排序() {
            return this.排序欄位 + this.排序順序;
        },
        filter客服成員統計表() {
            //過瀘是否執勤中
            let filter = this.客服成員統計表_列表.filter((item) => {
                //若有勾選執勤中，則filter Online的，否則就全部回傳
                if (this.是否執勤中) {
                    return item.Online;
                } else {
                    return true;
                }
            });
            if (this.Cs.length != 0) {
                let finds = [];
                for (let i of this.Cs) {
                    finds.push(i.Cs);
                }
                filter = filter.filter((item) => {
                    return finds.includes(item.Cs);
                });
            }
            return filter;
        },
    },
    watch: {
        "$i18n.locale"(val) {
            this.重置圖表(this.圖表類型);
        },
        排序欄位(val) {
            this.重新排序();
        },
        排序順序(val) {
            this.重新排序();
        },
        排除自動關閉事件(val) {
            this.onSearch(this.getSearchData());
        },
        stateProjectId: {
            async handler(v) {
                try {
                    await this.$store.dispatch("messageSystemServer/載入客服顯示對應表", { ServiceNo: v });
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                this.客服列表 = [...this.客服顯示對應表];
                for (let i of this.客服列表) {
                    this.$set(i, "show", `${i.Name} ${i.Cs}`);
                }
                this.重新排序();
                this.onSearch(this.getSearchData());
            },
            immediate: true
        },
    },
    mounted() {
    },
    beforeDestroy() {

    },
    methods: {
        applyDaterangepicker() {
            this.onSearch(this.getSearchData());
        },
        改變排序欄位(欄位) {
            if (this.當前排序 == 欄位 + "遞增") {
                this.排序順序 = "遞減";
            } else {
                this.排序欄位 = 欄位;
                this.排序順序 = "遞增";
            }
        },
        重新排序() {
            let 欄位 = this.排序欄位;
            if (this.排序順序 == "遞增") {
                this.客服成員統計表_列表.sort((a, b) => {
                    return parseInt(a[欄位]) - parseInt(b[欄位]);
                });
            } else {
                this.客服成員統計表_列表.sort((a, b) => {
                    return parseInt(b[欄位]) - parseInt(a[欄位]);
                });
            }
        },
        切換執勤中() {
            this.是否執勤中 = !this.是否執勤中;
        },
        切換排除自動關閉事件() {
            this.排除自動關閉事件 = !this.排除自動關閉事件;
        },
        getSearchData() {
            if (moment().format(this.$refs.datePicker.format) == this.$refs.datePicker.結束時間) {
                return {
                    ServiceNo: this.stateProjectId,
                    DayTime: 1,// int DayTime 指定查詢的天數，即時帶1，自訂帶0
                    RemoveIdleData: this.排除自動關閉事件
                };
            } else {
                return {
                    ServiceNo: this.stateProjectId,
                    DayTime: 0,// int DayTime 指定查詢的天數，即時帶1，自訂帶0
                    STime: this.$refs.datePicker.開始時間,//開始日期 Ex: “2022-07-01”
                    ETime: this.$refs.datePicker.結束時間,//結束日期 Ex: “2022-07-31”
                    RemoveIdleData: this.排除自動關閉事件
                };
            }
        },
        async onSearch(query) {
            if (!query) {
                return;
            }
            let send = JSON.parse(JSON.stringify(query));
            $(this.$refs.loading).show();
            try {
                let res = (CsAPI(await api.CS.GetOverallPerformance(send))).data;
                res.CsData = res.CsData ? res.CsData : [];
                let processingCount = 0;
                let finishCount = 0;
                for (let i of res.CsData) {
                    // Cs: "julie", ProcessingCount: 2, FinishCount: 4, Online: true
                    i.ProcessingCount = i.ProcessingCount ? i.ProcessingCount : 0;
                    i.FinishCount = i.FinishCount ? i.FinishCount : 0;
                    i.AvgFinishTime = i.AvgFinishTime ? i.AvgFinishTime : 0;
                    // i.Online = i.Online ? i.Online : false;
                    i.compare = false;
                    processingCount = processingCount + i.ProcessingCount;
                    finishCount = finishCount + i.FinishCount;
                }
                this.客服成員統計表_列表 = res.CsData ? res.CsData : [];
                this.是否即時資料 = (query.DayTime == 1);
                //若是即時資料，會隱藏ProcessingCount這個欄位，因此要把它改成依FinishCount排序
                (!this.是否即時資料 && this.排序欄位 == 'ProcessingCount') ? this.改變排序欄位('FinishCount') : this.重新排序();
                this.完成事件數 = finishCount;
                this.進行事件數 = send.DayTime == 1 ? processingCount : "none"


                this.平均完成時間 = res.AvgFinishTime;
                //清空客服資料
                this.圖表資料.客服_事件 = [];
                this.圖表資料.客服_時間 = [];
                this.圖表資料.總表_事件 = { Chart_Key: res.FinishEventArr.Chart_Key, Chart_Val: res.FinishEventArr.Chart_Val };
                this.圖表資料.總表_時間 = { Chart_Key: res.AvgFinishTimeArr.Chart_Key, Chart_Val: res.AvgFinishTimeArr.Chart_Val };

                this.重置圖表(this.圖表類型);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).fadeOut();
        },
        async 加入比較(item) {
            if (this.是否即時資料) {
                return;
            }
            item.compare = !item.compare;
            if (item.compare) {
                $(this.$refs.loading).show();
                try {
                    let send = JSON.parse(JSON.stringify(this.getSearchData()));
                    send.Cs = item.Cs;
                    let res = (CsAPI(await api.CS.GetCsPerformance(send))).data;
                    let show = this.filter客服(item.Cs);
                    this.圖表資料.客服_時間.push({ show, Cs: item.Cs, Chart_Key: res.AvgFinishTimeArr.Chart_Key, Chart_Val: res.AvgFinishTimeArr.Chart_Val });
                    this.圖表資料.客服_事件.push({ show, Cs: item.Cs, Chart_Key: res.FinishEventArr.Chart_Key, Chart_Val: res.FinishEventArr.Chart_Val });
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                $(this.$refs.loading).fadeOut();
            } else {
                let removeId_1 = this.圖表資料.客服_時間.findIndex((i) => {
                    return i.Cs === item.Cs;
                });
                this.圖表資料.客服_時間.splice(removeId_1, 1);
                let removeId_2 = this.圖表資料.客服_事件.findIndex((i) => {
                    return i.Cs === item.Cs;
                });
                this.圖表資料.客服_事件.splice(removeId_2, 1);
            }
            this.重置圖表(this.圖表類型);
        },
        重置圖表(type) {
            this.圖表類型 = type;
            let title = this.$t('事件');
            let yTitle = this.$t('事件數件');
            let 總表資料 = this.圖表資料.總表_事件;
            let 客服資料 = this.圖表資料.客服_事件;
            this.圖表物件.tooltip.pointFormatter = null;
            this.圖表物件.yAxis.labels.formatter = null;
            if (type == this.ChartTypeEnum.時間) {
                title = this.$t('時間');
                yTitle = this.$t('平均時間');
                總表資料 = this.圖表資料.總表_時間;
                客服資料 = this.圖表資料.客服_時間;
                let me = this;
                this.圖表物件.tooltip.pointFormatter = function () {
                    let timeAry = formatCostTime(this.y, false);
                    let costTimeStr = timeAry[1] > 0 ? me.$t(`花費時間格式化_2`, timeAry) : me.$t(`花費時間格式化_1`, timeAry)

                    return '<span style="color: ' + this.series.color + '">\u25CF</span> ' +
                        this.series.name + ': <b>' + costTimeStr + '</b><br/>'
                };
                this.圖表物件.yAxis.labels.formatter = function () {
                    let timeAry = formatCostTime(this.value, false);
                    let costTimeStr = timeAry[1] > 0 ? me.$t(`花費時間格式化_2`, timeAry) : me.$t(`花費時間格式化_1`, timeAry)

                    return costTimeStr;//formatCostTime(this.value, false);
                }
            }
            this.圖表物件.series = [];
            this.圖表物件.title.text = title;
            this.圖表物件.yAxis.title.text = yTitle;
            if (客服資料.length == 0) {
                //當圖表中，沒客服資料時，才顯示總資料
                this.圖表物件.series.push({ name: this.$t('總資料'), data: 總表資料.Chart_Val, visible: true })
            }
            this.圖表物件.xAxis.categories = 總表資料.Chart_Key;

            for (let i of 客服資料) {
                this.圖表物件.series.push({ name: i.show, data: i.Chart_Val });
            }
        },
        filter客服(v) {
            let find = (this.客服列表.find(客服 => {
                return 客服.Cs == v;
            }));
            return find ? find.show : v;

        },
        filterAvgFinishTime(v) {
            let timeAry = formatCostTime(v, false);
            let costTimeStr = timeAry[1] > 0 ? this.$t(`花費時間格式化_2`, timeAry) : this.$t(`花費時間格式化_1`, timeAry);
            return costTimeStr;//formatCostTime(v, false);
        }
    },
};
</script>
<style scoped>
.small-box.icon.myIcon {
    right: 100px;
}

.box-header {
    padding-left: 0px;
    height: 58px;
}

.box-body {
    padding-left: 0px;
    padding-right: 0px;
}

.table th {
    text-align: left;
}

thead tr th {
    position: sticky;
    background-color: white;
    top: 0;
}

.cusor_hand {
    cursor: pointer;
}
</style>
<style lang="scss">
.multiselect {
    height: auto;
}
</style>