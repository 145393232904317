<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>序號管理</li>
        <li class="active">活動列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-activityManger">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增活動
            </button>

            <div class="date-group">
              <label class="control-label">活動期間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                  />
                </div>
              </div>
            </div>

            <div class="input-group search-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋活動代碼、活動名稱"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button class="btn btn-default" type="button" v-on:click="clickSearch()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ activity.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 3%">原廠活動代碼</th>
                  <th style="min-width: 90px; width: 3%">活動代碼</th>
                  <th style="min-width: 90px; width: 5%">活動名稱</th>
                  <th class="text-nowrap" style="width: 5%;">規則</th>
                  <th class="text-nowrap" style="width: 3%;">狀態</th>
                  <th class="text-nowrap" style="width: 10%;">活動開始時間</th>
                  <th class="text-nowrap" style="width: 10%;">活動結束時間</th>
                  <th class="text-nowrap" style="width: 10%;">建立者</th>
                  <th class="text-nowrap" style="width: 10%;">建立時間</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 10%;">功能</th>
                </tr>
                <template v-if="activity.total > 0">
                  <tr :key="item.id" v-for="item in activity.datas">
                    <td align="center">{{ item.vendorActivityCode }}</td>
                    <td align="center">{{ item.activityCode }}</td>
                    <td align="center">{{ item.activityTitle }}</td>
                    <td align="center">
                      {{
                        item.ruleType == ""
                          ? "無"
                          : item.ruleType == "1帳1次"
                          ? "1帳多次"
                          : item.ruleType
                      }}
                    </td>
                    <td align="center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td align="center">{{ item.startTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td align="center">{{ item.endTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td align="center">{{ item.createUName }}&emsp;{{ item.createUId }}</td>
                    <td align="center">{{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                      &nbsp;
                      <button
                        @click="settingAwards(item)"
                        class="btn btn-primary"
                      >獎項設定</button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                        v-if="!checkTime(item)"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="activity.total > 0">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="activity.total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    DateRangePicker,
    Pagination
  },
  delimiters: ['${', '}'],
  name: 'app',
  props: {
    id: '',
  },
  data() {
    return {
      total: 0, //總筆數
      searchData: {
        keyword: '',
        pageNum: 1,
        isDelete: 0,
        startTime: '',
        endTime: '',
        pageSize: 10, //每頁顯示筆數
        local: {
          startTime: new moment()
            .subtract(3, "months")
            .format("YYYY-MM-DD 00:00:00"),
          endTime: new moment()
            .add(3, "months")
            .format("YYYY-MM-DD 23:59:59"),
        },
      },
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetActivityQueryAll();
  },
  computed: {
    ...mapState({
      activity: (state) => state.activity.queryAll,
      response: (state) => state.activity.response,
    }),
  },
  watch: {
    activity() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.activity.total
      );
    },
    response(value) {
      if (value.status == '1') {
        this.$eventBus.$emit('showAlert', '成功!!');
      } else {
        if (value.message == '') this.$eventBus.$emit('showAlert', '失敗!!');
        else this.$eventBus.$emit('showAlert', value.message);
      }
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetActivityQueryAll();
    },

    GetActivityQueryAll() {
      //列表
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} UTC`
      );
      this.$store.dispatch('activity/activityQueryAll', this.searchData);

      if (this.$route.params.searchData != undefined) {
        this.searchData = this.$route.params.searchData;
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
      }
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetActivityQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: 'ActivityEdit',
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    editorItem(item) {
      this.$router.push({
        name: 'ActivityEdit',
        params: { id: item.activityId, searchData: this.searchData },
      });
      return;
    },

    //*---- 設定獎項
    settingAwards(item) {
      this.$router.push({
        name: 'AwardList',
        params: { activityId: item.activityId, searchData: this.searchData },
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('activity/activityDelete', item);
        }
      });
    },

    checkTime(item) {
      return new moment(new Date()).isAfter(item.startTime);
    },
  },
};
</script>

<style scoped>
.top-box .form-group {
  vertical-align: top;
  margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}

.top-box .input-group {
  min-width: 250px;
}
</style>
