<template>
    <div class="modal fade" data-backdrop="static" ref="popForm">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Close" class="close" type="button" @click="hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title">A檔寄送簡訊</h4>
                </div>
                <div class="box-body">
                    <div class="form-group">
                        <label> 廠商： </label>
                        <select class="form-control" v-model="searchData.服務編號" :disabled="processST != processSTEnum.初始">
                            <!-- <option value="">全部</option> -->
                            <option v-for="item of filter廠商options" :value="item.廠商編號" :key="item.廠商編號">{{ item.顯示名稱 }}
                            </option>
                        </select>

                    </div>
                    <div class="form-group">
                        <label>年份：</label>
                        <select class="form-control" v-model="searchData.發票年份" :disabled="processST != processSTEnum.初始">
                            <!-- <option value="">全部</option> -->
                            <option v-for="year of 年份options" :value="year" :key="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>獎項：</label>
                        <select class="form-control" v-model="searchData.中獎類別" :disabled="processST != processSTEnum.初始">
                            <option value="">全部</option>
                            <option value="A">特別獎-1000萬(A)</option>
                            <option value="B">雲端發票專屬兩千元獎-2,000元(B)</option>
                            <option value="C">雲端發票專屬百萬元獎-100萬元(C)</option>
                            <option value="D">雲端發票專屬五百元獎-500元(D)</option>
                            <option value="E">雲端發票專屬八百元獎-800元(E)</option>
                            <option value="0">特獎-200萬元(0)</option>
                            <option value="1">頭獎-20萬元(1)</option>
                            <option value="2">二獎-4萬元(2)</option>
                            <option value="3">三獎-1萬元(3)</option>
                            <option value="4">四獎-4,000元(4)</option>
                            <option value="5">五獎-1000元(5)</option>
                            <option value="6">六獎-200元(6)</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>期數：</label>
                        <select class="form-control" v-model="searchData.發票期數" :disabled="processST != processSTEnum.初始">
                            <!-- <option value="">全部</option> -->
                            <option value="1, 2月">1.2月</option>
                            <option value="3, 4月">3.4月</option>
                            <option value="5, 6月">5.6月</option>
                            <option value="7, 8月">7.8月</option>
                            <option value="9, 10月">9.10月</option>
                            <option value="11, 12月">11.12月</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>歸戶類型：</label>
                        <select class="form-control" disabled v-model="searchData.歸戶類型">
                            <option value="">全部</option>
                            <option value="A">A檔</option>
                            <option value="X">X檔</option>
                            <option value="Z">Z檔</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>是否列印：</label>
                        <select class="form-control" disabled v-model="searchData.是否列印">
                            <option value="">全部</option>
                            <option :value="true">已列印</option>
                            <option :value="false">未列印</option>
                        </select>
                    </div>
                </div>
                <div class="box-body">
                    <button type="button" class="btn btn-info pull-right" :disabled="processST != processSTEnum.初始"
                        @click="send()">確認寄送</button>
                </div>
                <div class="box-footer">
                    <table class="table table-condensed" style="border:0px">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="progress progress-xs">
                                        <div class="progress-bar progress-bar-danger"
                                            :style="{ width: processPercent + '%' }">
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 60px"><span class="badge bg-red">{{ processPercent }}%</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="max-height: 300px; overflow: scroll;">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>訂單編號</th>
                                    <th>處理狀態</th>
                                    <th>錯誤訊息</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-show="list.length == 0">
                                    <td colspan="3">無資料</td>
                                </tr>
                                <tr v-for="item of list" :key="item.訂單編號">
                                    <td class="text-nowrap">{{ item.訂單編號 }}</td>
                                    <td class="text-nowrap">
                                        <span class="text-red" v-if="item.st == 'U'">尚未處理</span>
                                        <span class="text-red" v-else-if="item.st == 'P'">處理中</span>
                                        <span v-else-if="item.st == 'S'">成功</span>
                                        <span class="text-red" v-else>失敗</span>
                                    </td>
                                    <td class="text-nowrap">{{ item.log }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="form-group logDiv">
                        <div v-for="(item, i) of logs" :key="i + item" style="white-space: pre-line;"> <label>{{ item
                        }}</label></div>
                    </div>
                    <div class="modal-footer" style="display: none;" ref="switchFormBtn">
                        <button class="btn btn-default pull-right" type="button" @click="匯入結束()">匯入結束</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from "@/assets/js/app-api";
let processSTEnum = {
    "初始": 0,
    "處理中": 1,
};
export default {
    components: {
    },
    props: ["廠商options", "年份options"],
    data() {
        return {
            processSTEnum,
            processST: processSTEnum.初始,
            searchData: {
                服務編號: "",
                發票年份: "",
                中獎類別: "",
                發票期數: "1, 2月",
                歸戶類型: "A",//固定設成A
                是否列印: false,//固定設成false
            },
            list: [],
            processPercent: 0,
        };
    },
    computed: {
        總筆數() {
            return this.list.length;
        },
        filter廠商options() {
            return this.廠商options.filter((item) => {
                return item.廠商編號 == "10001" || item.廠商編號 == "10002";
            });
        }
    },
    // mounted() {
    // },
    methods: {
        show({ 服務編號, 發票年份, 中獎類別, 發票期數 }) {
            $(this.$refs.popForm).modal("show");
            this.processST = processSTEnum.初始;
            this.processPercent = 0;
            this.searchData.服務編號 = this.filter廠商options[0].廠商編號;
            this.searchData.發票年份 = 發票年份 == "" ? this.年份options[0] : 發票年份;
            this.searchData.中獎類別 = 中獎類別;
            this.searchData.發票期數 = 發票期數 == "" ? "1, 2月" : 發票期數;
        },
        hide() {
            if (this.processST != processSTEnum.初始) {
                this.$eventBus.$emit("showAlert", "請等批次處理執行完畢才關閉");
                return;
            }
            $(this.$refs.popForm).modal("hide");
        },
        async send() {
            this.processST = this.processSTEnum.處理中;
            this.processPercent = 0;
            try {
                await this.onSearch(this.searchData);
                let len = this.list.length;
                let count = 0;
                for (let i = 0; i < len; i++) {
                    this.list[i].st = 'P';//狀態改成處理中
                    try {
                        if (this.list[i].備註 != "") {
                            throw "有輸入備註，不寄送簡訊"
                        }
                        // 是否重寄:true  <--不檢查是否有寄送過，直接寄
                        // 是否重寄:false  <--會檢查是否有寄送過，沒寄送過才寄
                        let res = await api.IPSSetting.SendWinningSMS([{ 訂單編號: this.list[i].訂單編號, 是否重寄: false }]);
                        if (res && res.data && res.data.message) {
                            throw res.data.message;
                        } else {
                            this.list[i].st = 'S';//狀態改成成功                                
                        }
                    } catch (e) {
                        this.list[i].st = 'F';//狀態改成失敗
                        this.list[i].log = e;
                    } finally {
                        count++;
                        this.processPercent = Math.round((count / len) * 100);
                    }
                }
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            } finally {
                this.processST = this.processSTEnum.初始;
                this.processPercent = 100;
            }
        },
        async onSearch(query) {
            let send = JSON.parse(JSON.stringify(query));
            try {
                let res = await api.IPSSetting.getInvoiceJackpot(send);
                if (res && res.data) {
                    res = res.data;
                } else {
                    let errMsg = res && res.data && res.data.message ? res.data.message : "未知";
                    throw errMsg;
                }
                if (res.list) {
                    res.list.map((item) => {
                        item.st = 'U';//未定
                        item.log = '';
                    });
                    this.list = res.list;
                } else {
                    this.list = [];
                }
            } catch (e) {
                this.list = [];
                throw e;
            }
        },
    },
};
</script>

<style scoped>
.form-group>label {
    display: inline-block;
    width: 30%;
    text-align: right;
}

/* .form-group>sapn {
    display: inline-block;
    width: 50%;
} */

.form-control {
    width: 70%;
    display: inline-block;
}
</style>