<template>
  <div id="CustomizeList">
    <section class="content-header">
      <h1>簡訊黑名單查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊黑名單查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <!-- <div class="date-group" style="width: auto;">
              <label class="control-label">時間：</label>
              <div class="input-group date datetime" style="width: 250px;">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-left">
                  <DateRangePicker
                    :propTimePicker="false"
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                    :key="resetDate"
                  />
                </div>
              </div>
              <span class="text-red">※日期非必填，沒填時為查全部區間</span>
            </div> -->

            <div class="select-group">
              <label for="" class="control-label">封鎖服務列表</label>
              <select
                class="form-control"
                v-model="searchData.來源"
                @change="resetDataList()"
              >
                <option value>全部</option>
                <option
                  v-for="(account, index) in accountList"
                  :value="account.id"
                >
                  {{ account.accName }}
                </option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">區碼：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.區碼"
                @change="resetDataList()"
              >
                <option value="">全部</option>
                <option
                  v-for="(areaCode, index) in define.areaCodeList"
                  :value="areaCode.code"
                >
                  {{ areaCode.code }}({{ areaCode.name }})
                </option>
              </select>
            </div>

            <div class="input-group " >
              <label for="" class="control-label">門號</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="請輸入手機門號"
                type="text"
                v-model="searchData.門號"
              />
            </div>

            <div class="btns">
              <button class="btn btn-default mr-10" type="button" @click="resetDataList()">查詢</button>
            </div>

            <div class="form-group fr snmMT w100-md mt-15-md">
              <button class="btn btn-warning w100-md" type="button" @click="showModal()">新增黑名單</button>
            </div>
            
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 5%;">區碼</th>
                  <th class="text-nowrap" style="width: 5%;">手機號碼</th>
                  <th class="text-nowrap" style="width: 5%;">封鎖服務類型</th>
                  <th class="text-nowrap" style="width: 15%;">建立時間</th>
                  <th class="text-nowrap text-center" style="width: 10%;">功能</th>
                </tr>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="6">沒有資料</td>
                  </tr>
                </template>
                <template v-else v-for="(item, index) in list">
                  <tr>
                    <td class="text-center">{{ (searchData.pageNum - 1) * searchData.pageSize + index + 1 }}</td>
                    <td class="text-center">{{ item.區碼 }}</td>
                    <td class="text-center">{{ item.門號 }}</td>
                    <td class="text-center">{{ item.來源名稱 }}</td>
                    <td class="text-center">{{ item.建立時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-danger"
                        type="button"
                        @click="deleteItem(item)"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <!--新增-->
			<div class="modal fade" ref="create" id="serviceList">
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-body">
              <div class="mb-20">
                <button aria-label="Close" class="close" type="button" @click="closeModal">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form class="form-horizontal" name="Form">
								<div class="box-body">
                  <div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											區碼：
										</label>
										<div class="col-sm-10">
											<select
                        class="form-control"
                        id="selectUnit"
                        v-model="data.區碼"
                      >
                        <option
                          v-for="(areaCode, index) in define.areaCodeList"
                          :value="areaCode.code"
                        >
                          {{ areaCode.code }}({{ areaCode.name }})
                        </option>
                      </select>
										</div>
									</div>

                  <div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											門號：
										</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input
													class="form-control"
													id="inputTitle"
													required="required"
													type="text"
													placeholder="請輸入手機門號"
                          v-model="data.門號"
												/>
											</div>
										</div>
									</div>
                </div>
              </form>
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <tbody>
                    <tr class="bg-gray">
                      <th class="text-nowrap" style="width: 50px;">勾選</th>
                      <th class="text-nowrap">區碼服務</th>
                    </tr>
                    <template v-if="accountList.length > 0">
                      <tr v-for="account in accountList">
                        <td class="text-nowrap">
                          <div class="checkbox">
                            <label>
                              <iCheckCheckBox
                                :propValue="account"
                                :propChecked="account.checked"
                                v-on:update:checked="checkChange"
                              />
                            </label>
                          </div>
                        </td>
                        <td class="text-nowrap">{{ account.accName }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
						</div>
						<div class="modal-footer text-center">
							<button
								class="btn btn-primary"
								type="button"
								@click="save()"
							>
								確定送出
							</button>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import iCheckCheckBox from "@/components/iCheck_CheckBox";

export default {
  components: {
    Pagination,
    iCheckCheckBox
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        pageNum: 1, 
        pageSize: 10, 
        區碼: "",
        門號: "",
        來源: ""
      },
      resetDate: new Date().getTime(),
      list: [],
      total: 0,
      accountList: [],
      data: {
        區碼: "",
        門號: "",
      },
    }
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    this.getDataList();
    setTimeout(() => {
      this.getAccountList();
    }, 100);
  },
  mounted() {},
  computed: {},
  methods: {
    getDataList() {
      this.searchData.開始時間 = new Date(this.searchData.開始時間);
      this.searchData.結束時間 = new Date(this.searchData.結束時間);
      
      this.loading = true;
      api.moloSMS.block.queryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    getAccountList() {
      api.moloSMS.user.queryAll({
        IsOpen: 1, //帳號 1:啟用 0:停用
        IsScmOpen: -1, //互動簡訊 1:啟用 0:停用
      })
        .then((res) => {
          this.accountList = res.data.result;
          this.accountList.unshift({
            id: '*',
            accName: "全封鎖",
            isOpen: '1',
          })
          this.accountList.forEach(item => {
            item.checked = false;
          })
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    resetData() {
      this.data.區碼 = this.define.areaCodeList[0].code;
      this.data.門號 = "";
      this.checkIds = [];
      $(".icheckbox_flat-blue").each(function() {
        $(this).removeClass('checked');
      });
      this.$forceUpdate();
    },
    checkChange(data) {
      const id = data.value.id
      if (data.checked && !this.checkIds.includes(id)) {
        this.checkIds.push(id);
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
        }
      }
    },
    showModal() {
      this.resetData();
      $(this.$refs.create).modal("show");
    },
    closeModal() {
      this.resetData()
      $(this.$refs.create).modal("hide");
    },
    save() {
      api.moloSMS.block.create({
        區碼: this.data.區碼,
        門號: this.data.門號,
        來源集合: this.checkIds
      })
        .then((res) => {
          this.closeModal();
          this.global.showResponse(
            this, 
            res, 
            'create', 
            this.getDataList()
          )
        })
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定刪除此門號的封鎖?", (isOK) => {
        if (isOK) {
          api.moloSMS.block.delete({
            流水號: item.流水號,
          }).then((res) => {
            this.global.showResponse(this, res, 'delete', this.getDataList)
          })
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  }
}
</script>
<style lang="scss">
#CustomizeList {
  .modal-dialog {
    .table-responsive {
      max-height: 60vh;
    }
  }
}
</style>