<template>
    <div class="modal fade" ref="popForm">
        <div class="modal-dialog complete_style">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('強制完成事件') }}</h4>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>{{ $t('完成後無法復原，確定要強制完成事件嗎？') }}</label>
                        <br />
                        <label>{{ $t('事件原因') }}：</label>
                        <select v-model="eventType">
                            <option value="-1">{{ $t('請選擇事件原因') }}</option>
                            <option v-for="item of 固定事件類型" :value="item.id" :key="item.id">{{ $t(item.name) }}</option>
                            <!-- <option value="Game">遊戲機制</option>
                            <option value="Credit">點數儲值</option>
                            <option value="Event">活動相關</option>
                            <option value="Account">帳號相關</option>
                            <option value="Record">紀錄查詢</option>
                            <option value="Report">異常回報</option>
                            <option value="Violate">違規檢舉</option>
                            <option value="Complain">抱怨謾罵</option>
                            <option value="Ambiguous">問題不清楚</option>
                            <option value="Other">其他</option> -->
                        </select>
                        <textarea class="form-control" rows="3" :placeholder="$t('強制結束原因')" v-model="remark"></textarea>
                    </div>
                </div>
                <div class="footer">
                    <button class="btn btn-default pull-right close" data-dismiss="modal">{{ $t('取消')
                    }}</button>
                    <button class="btn sendbtn" @click="send()">{{ $t('完成') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import {
    ProcessStatus,
} from "@/store/MessageSystem/messageSystemConst";
export default {
    data() {
        return {
            message: "",
            remark: "",
            eventType: -1,
            fn: null,
        };
    },
    computed: {
        ...mapGetters({
            固定事件類型: 'messageSystemServer/固定事件類型',
            線上玩家列表: 'messageSystemServer/計算玩家列表',
        }),
        ...mapState({
            CSLoginUser: state => state.messageSystemServer.CSLoginUser,
            線上客服列表: state => state.messageSystemServer.線上客服列表,
            分類EventId: state => state.messageSystemServer.強制分類EventId,
        }),
    },
    methods: {
        get分類Event() {
            if (!this.線上玩家列表) {
                return null;
            }
            let 事件 = this.線上玩家列表.find((事件) => { return 事件.EventId == this.分類EventId });
            if (!事件) {
                return null;
            }
            if (!事件.聊天視窗_強制分類和完成事件) {
                return null;
            }
            return 事件;
        },
        客服是否在線上(事件客服) {
            let 客服是否在線 = this.線上客服列表.find((item) => { return item.Account == 事件客服 });
            return 客服是否在線 ? true : false;
        },
        send() {
            let 分類Event = this.get分類Event();

            if (!分類Event) {
                $(this.$refs.popForm).modal("hide");
                this.$eventBus.$emit("showAlert", this.$t('執行失敗'));
                return;
            }
            if (this.eventType == -1) {
                this.$eventBus.$emit("showAlert", this.$t('請選擇事件原因'));
                return;
            }
            let list = [ProcessStatus.None.value, ProcessStatus.Assigned.value, ProcessStatus.Processing.value];

            let isWarning = 分類Event.Cs && 分類Event.Cs != this.CSLoginUser && this.客服是否在線上(分類Event.Cs);

            if (isWarning) {
                this.$eventBus.$emit("showAsyncConfirm", "其他客服處理中，確定操作嗎?", this.$t('強制完成事件'), () => {
                    //因為異步…分類Event可能會變不存在，所以這邊再判斷一次
                    let 分類Event = this.get分類Event();
                    if (!分類Event) {
                        $(this.$refs.popForm).modal("hide");
                        this.$eventBus.$emit("showAlert", this.$t('執行失敗'));
                        return;
                    }
                    //因為異步…分類Event可能會變不存在End
                    if (list.includes(分類Event.ProcessStatus)) {
                        this.$emit("EnforceFinishService", 分類Event.EventId, this.eventType, this.remark);
                    } else {
                        this.$emit("ResetEventType", 分類Event.EventId, this.eventType, this.remark);
                    }
                    $(this.$refs.popForm).modal("hide");
                }, () => {
                    //關閉按鈕按下後，要執行的事
                });
            } else {
                if (list.includes(分類Event.ProcessStatus)) {
                    this.$emit("EnforceFinishService", 分類Event.EventId, this.eventType, this.remark);
                } else {
                    this.$emit("ResetEventType", 分類Event.EventId, this.eventType, this.remark);
                }
                $(this.$refs.popForm).modal("hide");
            }
        },
        show(EventType, Remark) {
            $(this.$refs.popForm).modal("show");
            this.eventType = !EventType ? -1 : EventType;
            this.remark = Remark;
        },
    }
}
</script>
<style scoped>
.footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 60px;
}
</style>