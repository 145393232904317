<template>
  <div id="RewardMessageHistory">
    <section class="content-header">
      <h1>玩家信件查詢</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li>玩家信件查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li><router-link to="/NMJ/RewardMessage">信件發獎</router-link></li>
          <li class="active"><a>玩家信件查詢</a></li>
        </ul>
        <div class="box-body">
          <form class="top-box" @submit="$event.preventDefault(); resetDataList();">
            <div class="form-group" style="width: auto">
              <label class="control-label">發送時間：</label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="true"
                  :propEndDate.sync="local.endTime"
                  :propStartDate.sync="local.startTime"
                />
              </div>
            </div>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="玩家暱稱" v-model.trim="searchData.Name"/>
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary">
                查詢
              </button>
            </div>
            <button type="button" v-show="Object.keys($route.query).length > 0" @click="$router.go(-1)" class="btn btn-default pull-right fa fa-arrow-left"> 返回</button>
          </form>
          <div class="table-responsive mt-0">
            <!-- <pre>{{ searchData }}</pre> -->
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width:130px; width:10%">玩家暱稱</th>
                  <th style="min-width: 75px; width: 5%">信件編號</th>
                  <th style="min-width: 92px; width:10%">發送時間</th>
                  <th style="min-width: 92px; width:10%">到期時間</th>
                  <th style="min-width: 92px; width:10%">刪除時間</th>
                  <th style="min-width:130px; width:10%">寄信人</th>
                  <th style="min-width:130px; width:15%">標題</th>
                  <th style="min-width: 75px; width: 5%">狀態</th>
                  <th style="min-width: 75px; width: 5%">內容</th>
                </tr>
                <tr v-if="message"><td colspan="10">{{ message }}</td></tr>
                <tr v-else v-for="(item, index) in pagedData" :key="index" :class="{ 'bg-gray-light': item.狀態 === 3 }">
                  <td><router-link :to="{name:'NmjPlayerData',query:{nick:item.暱稱}}">{{ item.暱稱 }}</router-link></td>
                  <td>{{ item.信件編號 }}</td>
                  <td>{{ uiDate(item.發送時間) }}</td>
                  <td>{{ uiDate(item.到期時間) }}</td>
                  <td>{{ uiDate(item.刪除時間) || "-" }}</td>
                  <td>{{ item.寄件人 }}</td>
                  <td>{{ item.信件標題 }}</td>
                  <td>{{ item.領取狀態 }}</td>
                  <!-- <td :class="{ 'text-red': item.狀態 === 0 }">
                    {{ def.mailState[item.狀態] }}
                  </td> -->
                  <td>
                    <button class="btn btn-primary" @click="checkContent(item)">
                      查看
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <!-- <pre>{{ datas[0] }}</pre> -->
      </div>
    </section>
    <rewardMessageContent ref="rewardMessageContent" />
  </div>
</template>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import rewardMessageContent from "@/views/projects/NMJ/RewardMessageContent";

export default {
  components: {
    DateRangePicker,
    rewardMessageContent,
    Pagination
},
  props: {
    id: "",
  },
  data() {
    return {
      def: define,
      loading: false,
      gameTypes: {},
      total: 0, //總筆數
      message: "請輸入查詢玩家暱稱!",
      searchData: {
        Name: "",
        pageNum: 1,
        pageSize: 20, //每頁顯示筆數
      },
      local: {
        startTime: new moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      datas: [],
      sendCount: 0,
      getCount: 0,
    };
  },
  async created() {
    this.$store.dispatch("nmjMail/itemList").then(result => {
      this.gameTypes = result;
      Object.assign(this.gameTypes.Item, define.gameCoins);
    });
    if (this.$route.query.nick) {
      this.searchData.Name = this.$route.query.nick;
      this.getDataList();
    }
  },
  mounted() {
    
  },
  computed:{
    pagedData(){
      let start = (this.searchData.pageNum - 1) * this.searchData.pageSize;
      let end = this.searchData.pageNum * this.searchData.pageSize;
      return this.datas.slice(start, end);
    }
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.searchData.prizeMessageId = this.id;
      this.searchData.StartTime = new Date(`${this.local.startTime} UTC`);
      this.searchData.EndTime = new Date(`${this.local.endTime} UTC`);
      this.$store
        .dispatch("nmjMail/history", this.searchData)
        .then((result) => {
          this.datas = result.mailList || [];
          this.datas.map(item => item['暱稱'] = this.searchData.Name)
          this.total = result.total;
          this.sendCount = result.sendCount;
          this.getCount = result.getCount;
          this.message = "";
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          if (this.datas.length > 0 && this.$route.query.nick != this.searchData.Name){
            this.$router.replace({query:{ nick: this.searchData.Name }});
          }
        }, (message) => {
          this.message = message;
          this.loading = false;
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    // 查看內容
    checkContent(item, showPlayers = false) {
      this.$refs.rewardMessageContent.showRewardMessageContent(
        item,
        this.gameTypes,
        this.searchData,
        showPlayers
      );
    },
    uiDate(date = Date.now()){
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      else return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
    }
  },
};
</script>