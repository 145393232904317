<template>
	<div id="clientGameActivity" class="slot">
		<section class="content">
			<div class="box box-primary" style="background: none;">
        <div class="box-body" style="padding: 0;">
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <li
                v-on:click="tab = 'app'"
                v-bind:class="{ active: tab == 'app' }"
              >
                <a href="javascript:;">App ({{ total.app }})</a>
              </li>
              <li
                v-on:click="tab = 'game'"
                v-bind:class="{ active: tab == 'game' }"
              >
                <a href="javascript:;">遊戲 ({{ total.game }})</a>
              </li>
              <li
                v-on:click="tab = 'analyze'"
                v-bind:class="{ active: tab == 'analyze' }"
              >
                <a href="javascript:;">集成分析({{ total.analyze }})</a>
              </li>
            </ul>
          </div>
          <div class="top-box">
            <div class="left">
              <div class="text" style="margin-left: 0;">
                <div class="dropdown">
                  <button
                    class="btn btn-sort dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
                  >
                    <div class="icon-sort"></div>
                    排序依據
                  </button>
                  <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
                    <li>
                      <a 
                        :class="['dropdown-item', order == 1 ? 'active' : '']"
                        href="#" 
                        @click="resetData(1)"
                        v-if="tab == 'app'"
                      >
                        更新時間
                      </a>
                    </li>
                    <li>
                      <a 
                        :class="['dropdown-item', order == 2 ? 'active' : '']"
                        href="#" 
                        @click="resetData(2)"
                        v-if="tab == 'app'"
                      >
                        建立時間
                      </a>
                    </li>
                    <li>
                      <a 
                        :class="['dropdown-item', order == 2 ? 'active' : '']"
                        href="#" 
                        @click="resetData(2)"
                        v-if="tab == 'game'"
                      >
                        A to Z
                      </a>
                    </li>
                    <li>
                      <a
                        :class="['dropdown-item', order == 1 ? 'active' : '']"
                        href="#" 
                        @click="resetData(1)"
                        v-if="tab == 'game'"
                      >
                        Z to A
                      </a>
                    </li>
                    <li>
                      <a
                        :class="['dropdown-item', order == 1 ? 'active' : '']"
                        href="#" 
                        @click="resetData(1)"
                        v-if="tab == 'analyze'"
                      >
                        最近發布
                      </a>
                    </li>
                    <li>
                      <a
                        :class="['dropdown-item', order == 1 ? 'active' : '']"
                        href="#" 
                        @click="resetData(1)"
                        v-if="tab == 'analyze'"
                      >
                        最早發布
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
				</div>
        <Empty v-if="list.length == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
        <div v-else :class="['g-list table-responsive mt-20', tab == 'game' ? 'hall' : tab == 'analyze' ? 'analyze' : '']">
          <div
            v-for="(item, index) in list"
            :class="['g-card', tab == 'analyze' ? 'no-bg' : '']"
            style="cursor: pointer;"
          >
          <GameCard :item="item" v-if="tab == 'app'"></GameCard>
          <GameHallCard :item="item" v-if="tab == 'game'"></GameHallCard>
          <AnalyzeCard :item="item" v-if="tab == 'analyze'"></AnalyzeCard>
          </div>
          <div class="loading-box" v-if="searchData.PageNum < totolPage">
            <div class="loading"></div>
          </div>
        </div>
			</div>
		</section>
    <slotPlayContent ref="slotPlayContent"></slotPlayContent>
	</div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import Empty from "@/components/SlotEmpty";
import slotPlayContent from "@/components/slot/slotPlayContent";
import GameHallCard from "@/components/slot/gameHallCard";
import GameCard from "@/components/slot/gameCard";
import AnalyzeCard from "@/components/slot/analyzeCard";

export default {
  components: {
    Empty,
    slotPlayContent,
    GameHallCard,
    GameCard,
    AnalyzeCard,
  },
  data() {
    return {
      loading: false,
      keyword: this.$route.params.keyword,
      searchData: {
        app: {
          開發商編號: 0,
          遊戲名稱: this.$route.params.keyword, //關鍵字比對
          PageNum: this.PageNum,
          PageSize: this.PageSize,
          排序條件: this.order, //1:更新時間,2:建立時間,3.遊戲名稱
          排序順序: 1, //1:DESC,2:ASC
        },
        game: {
          關鍵字: this.$route.params.keyword,//關鍵字比對
          PageNum: this.PageNum,
          PageSize: this.PageSize,
          排序條件: 2,//1:更新時間,2.遊戲館名稱
          排序順序: this.order,//1:DESC,2:ASC
        },
        analyze: {
          遊戲編號: -1, 
          文章標題: this.$route.params.keyword, //文章標題關鍵字
          排序條件: 1, //1:發佈時間,2.點閱數
          排序順序: 1, //1:DESC 2:ASC
          PageNum: this.PageNum, 
          PageSize: this.PageSize, 
        }
      },
      PageNum: 1,
      PageSize: 12,
      total: {
        app: 0,
        game: 0,
        analyze: 0
      },
      dropdown: 'none',
      tab: "app",
      isEnd: false,
      gameList: [],
      gameHallList: [],
      list: [],
      order: 1,
      isNewSearch: true,
    };
  },
  async created() {
    // if (this.$route.params.searchData != undefined) {
    //   this.searchData = this.$route.params.searchData;
    //   this.PageNum = 1;
    // }

    this.getDataList();
    this.getTotal();
  },
  computed: {
    ...mapState({
      gameQuery: state => state.slot.gameQueryAll.datas,
      gameHallQuery: state => state.slot.gameHallQueryAll.datas,
    }),
    totolPage() {
      let total = this.tab == 'app' ?
        this.total.app : this.tab == 'game' ?
        this.total.game : this.total.analyze;
      return Math.ceil(total / this.searchData.PageSize)
    }
  },
  mounted() {
    $(window).on("scroll", () => {
      this.scrollEvent();
    })
  },
  watch: {
    $route() {
      this.searchData.app.遊戲名稱 = this.$route.params.keyword;
      this.searchData.game.遊戲館名稱 = this.$route.params.keyword;
      this.searchData.analyze.文章標題 = this.$route.params.keyword;
      this.resetData();
      setTimeout(() => {
        this.getTotal();
      }, 100);
    },
    tab() {
      if(this.tab == 'app') this.order = 1;
      if(this.tab == 'game') this.order = 2;
      if(this.tab == 'analyze') this.order = 3;
      this.resetData();
    },
  },
  methods: {
    getDataList() {
      this.loading = true;
      let type
      let data

      if(this.tab == 'app') {
        type = 'game';
        data = 'app';
      }
      if(this.tab == 'game') {
        type = 'gameHall';
        data = 'game';
      }
      if(this.tab == 'analyze') {
        type = 'document';
        data = 'analyze';
      }

      api.slot[type].queryAll(this.searchData[data])
        .then((res) => {
          if(res.data.result.total > 0) {
            this.list = this.list.concat(res.data.result.datas);
            this.loading = false;
            $(window).on("scroll", () => {
              this.scrollEvent();
            })
          }
          this.isNewSearch = false;
        })
    },
    getTotal() {
      api.slot.search.query({
        搜尋內容: this.$route.params.keyword
      }).then((res) => {
        this.total.app = res.data.result.遊戲筆數;
        this.total.game = res.data.result.遊戲館筆數;
        this.total.analyze = res.data.result.集成分析筆數;
        
        if(this.total.app == 0 && this.total.game == 0) this.tab = 'analyze';
        else if(this.total.app == 0) this.tab = 'game';
        else this.tab = 'app';
        this.$forceUpdate();
      })
    },
    resetData(order) {
      this.dropdown = 'none';
      this.list = [];
      this.PageNum = 1;
      this.isEnd = false;
      if(order) this.order = order;
      this.getDataList();
    },
    goBack() {
      this.$router.push({
        name: "slotClientGameList",
        params: {
          searchData: this.$route.params.backSearchData,
        }
      });
    },
    goContent(data) {
      this.$router.push({
        name: this.tab == 'app' ? "slotClientGameHallList" : "clientGameContent",
        params: {
          gameId: data.遊戲編號,
          id: data.編號,
          backSearchData: this.searchData,
          back: 'slotSearch',
          search: this.keyword
        }
      });
    },
    showPlay(id) {
      if(id) this.$refs.slotPlayContent.getData(id);
      else this.$refs.slotPlayContent.showContent = true;
    },
    scrollEvent() {
      if(this.isEnd && this.totolPage == 1) return
      let sectionTop = $(window).scrollTop();
      let sectionH = $(window).height();
      let documentH = $(".loading-box").offset().top;

      if(this.PageNum < this.totolPage) {
        if(sectionTop + sectionH >= documentH + 300) {
          this.PageNum = this.PageNum + 1;
          this.getDataList();
        }
      }
      if(this.PageNum == this.totolPage) {
        this.isEnd = true;
      }
    }
  },
  beforeDestroy() {
    $(window).off("scroll");
  },
};
</script>