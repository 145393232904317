import { render, staticRenderFns } from "./InvoicePopImportForm.vue?vue&type=template&id=b47e6520&scoped=true"
import script from "./InvoicePopImportForm.vue?vue&type=script&lang=js"
export * from "./InvoicePopImportForm.vue?vue&type=script&lang=js"
import style0 from "./InvoicePopImportForm.vue?vue&type=style&index=0&id=b47e6520&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b47e6520",
  null
  
)

export default component.exports