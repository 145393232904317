<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>帳號管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>帳號管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="select-group" >
              <label for="" class="control-label">互動簡訊狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.IsScmOpen"
                @change="getDataList()"
              >
                <option :value="-1">全部</option>
                <option :value="1">啟用</option>
                <option :value="0">停用</option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">帳號狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.IsOpen"
                @change="getDataList()"
              >
                <!-- <option :value="-1">全部</option> -->
                <option :value="1">啟用</option>
                <option :value="0">停用</option>
              </select>
            </div>

            <!-- <div class="btns">
              <button class="btn btn-primary" type="button" @click="getDataList()">查詢</button>
            </div> -->

            <div class="form-group fr snmMT w100-md mt-15-md">
              <button class="btn btn-warning w100-md" type="button" @click="add()">新增</button>
            </div>
            
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">使用者代號</th>
                  <th class="text-nowrap" style="width: 10%">帳號</th>
                  <th class="text-nowrap" style="width: 10%;">服務名稱</th>
                  <th class="text-nowrap" style="width: 10%;">型態</th>
                  <th class="text-nowrap" style="width: 15%;">指定門號</th>
                  <th class="text-nowrap" style="width: 8%;">帳號狀態</th>
                  <th class="text-nowrap" style="width: 8%;">互動狀態</th>
                  <th class="text-nowrap" style="width: 200px;">備註</th>
                  <th class="text-nowrap text-center" style="width: 10%;">功能</th>
                </tr>
                <template v-if="list.length > 0">
                  <tr :key="item.id" v-for="(item, index) in list">
                    <template
                      v-if="
                        listKeyword == '' ||
                        (listKeyword != '' &&
                        (item.accId == listKeyword ||
                        item.accName == listKeyword ||
                        comList[item.smsCom] == listKeyword))
                      "
                    >
                      <td class="text-nowrap text-center">{{ item.id }}</td>
                      <td class="text-nowrap text-center">{{ item.accId }}</td>
                      <td class="text-nowrap text-center">{{ item.accName }}</td>
                      <td class="text-nowrap text-center">{{ define.SMSType[item.accType] }}</td>
                      <td class="text-nowrap text-center">{{ comList[item.smsCom] }}</td>
                      <td class="text-nowrap text-center">
                        <div class="ckbx-style-8 ckbx toggleInput">
                          <input
                            :false-value="0"
                            :id="'open'+index"
                            :true-value="1"
                            name="ckbx-style-8"
                            type="checkbox"
                            v-model="item.isOpen"
                            disabled
                          />
                          <label :for="'open'+index" @click="changeStatus(item, 'isOpen')"></label>
                        </div>
                      </td>
                      <td class="text-nowrap text-center">
                        {{ item.scmOpen == "1" ? "啟用" : "停用" }}
                      </td>
                      <td>{{ item.note }}</td>
                      <td class="text-nowrap text-center">
                        <button
                          class="btn btn-primary w100-md"
                          type="button"
                          @click="edit(item)"
                        >
                          編輯
                        </button>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";

export default {
  components: {},
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      loading: false,
      searchData: {
        IsOpen: 1,//帳號 1:啟用 0:停用
        IsScmOpen: -1,//互動簡訊 1:啟用 0:停用
      },
      keyword: "",
      listKeyword: "",
      list: [],
      comList: {},
    }
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getDataList();
    this.getComList();
  },
  mounted() {},
  computed: {},
  methods: {
    getDataList() {
      this.listKeyword = this.keyword;
      api.moloSMS.user.queryAll(this.searchData)
        .then((res) => {
          this.loading = true;
          this.list = res.data.result;
        })
    },
    getComList() {
      api.moloSMS.user.SmsComTypeQueryAll()
        .then((res) => {
          res.data.result.forEach(item => {
            this.comList[item.簡訊商門號代碼] = item.簡訊商門號說明;
          });
          this.$forceUpdate();
        })
    },
    add() {
      this.$router.push({
        name: "SMSAccountEdit",
      })
    },
    edit(item) {
      this.$router.push({
        name: "SMSAccountEdit",
        params: { 
          id: item.accName,
          data: item,
          searchData: this.searchData
        }
      })
    },
    changeStatus(item, type) {
      this.$eventBus.$emit("showConfirm", `確定啟用/停用${type == 'scmOpen' ? '互動' : ''}帳號`,
      isOK => {
        if (isOK) {
          let data = item
          if(type == 'isOpen') data.isOpen = data.isOpen == '0' ? '1' : '0'; 
          if(type == 'scmOpen') data.scmOpen = data.scmOpen == '0' ? '1' : '0';
						api.moloSMS.user.update(item)
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
                  if(type == 'isOpen') data.isOpen = data.isOpen == '0' ? '1' : '0'; 
                  if(type == 'scmOpen') data.scmOpen = data.scmOpen == '0' ? '1' : '0';
								}
							})
					}
				});
    },
  }
}
</script>