<template>
  <div>
    <FormDialog ref="rebateDialog" :title="result ? $t('回饋金配發結果') : $t(player ? '配發回饋金' : '批次配發回饋金')" @submit="submit">
      <table v-if="!result" class="table table-bordered">
        <tbody>
          <tr>
            <th>{{ $t(player ? '玩家暱稱' : '匯入名單') }}</th>
            <td>
              <span v-if="player">{{ data.暱稱[0] }}</span>
              <div v-else>
                <button type="button" class="btn btn-primary mt-0" @click="importShow()" style="vertical-align:baseline;">
                  <i class="fa fa-file-alt"></i> {{$t('匯入名單')}}
                </button>
                <span class="ml-10">{{ importFileName }}</span> 
                <div v-if="pagedTable.length > 0" class="mt-10">
                  <table class="table table-data table-bordered">
                    <tbody>
                      <tr v-for="row in pagedTable" style="line-height:1.2;">
                        <!-- <td v-for="col in row" style="padding:.2em .3em">{{ col }}</td> -->
                        <template v-for="col in row">
                          <th>{{ col.暱稱 }}</th>
                          <td>{{ col.回饋金 }}</td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center mt-5" v-show="pageTotal > pageSize">
                    <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal"
                      v-on:pageMethod="onPageChange" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="player">
            <th>{{$t('配發回饋金')}}</th>
            <td><input type="number" class="form-control" v-model.number="data.回饋金[0]" min="0" required></td>
          </tr>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-model="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{$t('配發原因')}}</th>
            <td>
              <textarea class="form-control" type="text" v-model.trim="data.內容"
                placeholder="" rows="3" required></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p v-if="player">{{ $t(result.成功.length > 0 ? "配點成功" : "配點失敗") }}</p>
        <div v-else class="nav-tabs tab-warning">
          <ul class="nav nav-tabs">
            <li :class="{ active: importList == result.成功 }"><a @click="importList = result.成功" style="cursor:pointer;">{{$t('成功')}}: {{ result.成功.length }}</a></li>
            <li :class="{ active: importList == result.失敗 }"><a @click="importList = result.失敗" style="cursor:pointer;">{{$t('失敗')}}: {{ result.失敗.length }}</a></li>
          </ul>
          <div class="tab-content">
            <table class="table table-data table-bordered mt-10 mb-10">
              <tbody>
                <tr v-if="playerTable.length == 0"><td class="text-center">{{$t('無資料')}}</td></tr>
                <tr v-else v-for="row in pagedTable" style="line-height:1.2;">
                  <template v-for="col in row">
                    <th>{{ col.暱稱 }}</th>
                    <td>{{ col.數量 }}</td>
                  </template>
                </tr>
              </tbody>
            </table>
            <div class="text-center mt-5 mb-5" v-show="playerTable.length > pageSize">
              <Pagination ref="pagination" :pageLimitCount="pageSize" :total="playerTable.length"
                v-on:pageMethod="onPageChange" />
            </div>
          </div>
        </div>
      </div>
      <template v-if="result" v-slot:footer><button type="button" data-dismiss="modal" class="btn btn-default">{{$t('確定')}}</button></template>
    </FormDialog>
    <ImportData ref="ImportData" title="回饋金配發" :checks="checks" @importData="importData" />
  </div>
</template>

<style scoped lang="scss">

  .table-data {
    border-color: #ddd;
    th, td { padding:.2em .3em; border-color:#ddd; }
    th { border-right-style:dotted; text-align:right; background:rgba(#e8e8e8, .25); width:2%;}
    td { border-left-style:none; width:1%;}
  }
</style>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";
import ImportData from "@/views/projects/XSW/ImportData";
import Pagination from "@/components/Pagination";

export default {
  components: {
    FormDialog,
    ImportData,
    Pagination,
  },
  props:['types'],
  data() {
    return {
      player: null,
      result: null,
      data: {
        暱稱: [], //批次配發玩家暱稱array帶入多筆暱稱
        回饋金: [], //批次配發回饋金array帶入多筆數字
        分類:"",
        內容:"",
      },
      pageNum: 1,
      pageSize: 5,
      pageCol: 2,
      pageTotal: 0,
      importFileName: "",
      importList: [],
      checks: ["玩家暱稱", "配發回饋金"],

    }
  },
  watch: {
    "filterString": function () { this.pageNum = 1 }
  },
  computed: {
    "playerTable": function () {
      let list = this.importList;
      let arr = [];
      for (let i = 0, len = list.length; i < len;) {
        let row = [];
        for (let j = 0; j < this.pageCol; j++) {
          if (list[i]) row.push(list[i++]);
        }
        arr.push(row);
      }
      this.pageTotal = arr.length;
      return arr;
    },
    "pagedTable": function () {
      return this.playerTable.slice((this.pageNum - 1) * this.pageSize, (this.pageNum) * this.pageSize);
    },
  },
  methods: {
    async show(player) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player || null;
      if (this.player) {
        this.data.暱稱 = [player];
      }
      this.$refs.rebateDialog.show();
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
    },
    importShow() {
      this.$refs.ImportData.show();
    },
    importData(data, fileName) {
      this.importList = data.map(p => { return { 暱稱: p.玩家暱稱.toString(), 回饋金: Number(p.配發回饋金) }});
      this.data.暱稱 = this.importList.map(item => item.暱稱);
      this.data.回饋金 = this.importList.map(item => item.回饋金);
      this.importFileName = fileName;
    },
    submit() {
      this.$store.dispatch("xsw/會員配發回饋金", this.data).then((res) => {
        this.$emit("updateList");
        this.pageCol = 3;
        this.pageSize = 10;
        this.result = res;
        this.importList = this.result.成功;
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>