/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    queryTxt({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcPlayerHistory.queryTxt(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.fcPlayerHistory.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                } else {
                    resolve(res.data);
                }
            });
        });
    },
    export({ commit }, texts) {
        if (texts.length === 0) {
            this._vm.$eventBus.$emit('showAlert', '無資料可匯出，請先進行查詢');
        } else {
            const items = [];
            const playUId = texts[0].id;
            texts.forEach(text => {
                text.items.forEach(item => {
                    const date = text.title.slice(0, 10);
                    items.push({
                        '所在位置': item.position,
                        '操作日期': date,
                        '操作時間': item.time,
                        '當下等級': item.rank,
                        '當下金幣': item.gold,
                        '當下鑽石': item.diamond,
                        '金幣減少額': item.goldMinus,
                        '金幣增加額': item.goldPlus,
                        '備註': item.note,
                    });
                });
            });
            utils.exportData(
                items,
                `${playUId}_${utils.formatTime(new Date(), 'YYYYMMDD')}_玩家歷程查詢.xlsx`,
                `${playUId}_玩家歷程查詢`
            );
        }
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
