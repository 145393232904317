<template>
    <div class="modal fade" ref="popForm">
        <div class="modal-dialog">
            <div class="modal-content" style="width:900px;">
                <div class="modal-header">
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button" @click="hide()">
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 class="modal-title">{{ $t('詳細內容') }}</h4>
                </div>
                <div class="box-body">
                    <div style="width:30%;display: inline-block;float: left;">
                        <table style="margin:0 auto; width:100%;  ">
                            <tr v-for="(item, key) of edit" :key="key">
                                <td class="text-right" style="padding-right:10px;width:80px;"><label>{{ $t(key) }}</label>
                                </td>
                                <td>{{ item }}</td>
                            </tr>
                            <tr>
                                <td colspan="2" align="right">
                                    <button type="button" class="btn btn-primary" @click="exportData()">
                                        {{ $t('匯出') }}</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style="width:70%;display: inline-block;">
                        <div class="box box-warning direct-chat direct-chat-warning">
                            <ChatMessageForm ref="chatMessageForm" @LoadNextToken="LoadNextToken">
                            </ChatMessageForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { saveCSV } from "@/assets/js/Tool/exportCSV.js";
import ChatMessageForm from "./MessageSystemComponents/ChatMessageForm";
import { 取得客服Account } from "@/store/MessageSystem/messageSystemConst";
import api from "@/assets/js/app-api";
export default {
    props: ['客服顯示對應表'],
    components: {
        ChatMessageForm,
    },
    data() {
        return {
            title: "",
            edit: {
            },
            內容: [],
            token: {
                EventId: "",
                ServiceNo: "",
                NextToken: 0//0:沒載過；-1:載入中；-2:沒下筆資料
            }
        };
    },
    methods: {
        async show(title, edit, EventId, ServiceNo) {
            this.title = title;
            this.edit = edit;
            this.$refs.chatMessageForm.messages = [];
            this.loadHistory(EventId, ServiceNo, 0);
            $(this.$refs.popForm).modal("show");
        },
        hide() {
            $(this.$refs.popForm).modal("hide");
        },
        loadHistory(EventId, ServiceNo, NextToken) {
            if (NextToken == -1) {
                return;
            }
            if (NextToken == -2) {
                this.$refs.chatMessageForm.showTopTime(this.$t('無歷史訊息'));
                return;
            }
            this.token.NextToken = -1;
            this.$refs.chatMessageForm.showTopTime(this.$t('歷史訊息載入中'));

            let send = { EventId, ServiceNo };
            if (NextToken != 0) {
                send.NextToken = NextToken;
            }
            api.CS.GetEventHistoryMsg(send).then((res) => {
                this.token.EventId = EventId;
                this.token.ServiceNo = ServiceNo;
                this.token.NextToken = res.data.NextToken ? res.data.NextToken : -2;
                for (let i of res.data.MsgArr) {
                    i.SenderName = i.ChatMsgType == 1 ? 取得客服Account(this.客服顯示對應表, i.Sender) : i.Sender;
                    this.$refs.chatMessageForm.messages.push(i);
                }
            }).catch((res) => {
                this.token.NextToken = -2;
            });
        },
        LoadNextToken() {
            this.loadHistory(this.token.EventId, this.token.ServiceNo, this.token.NextToken);
        },
        exportData() {
            let title = this.title;
            let keys = ['key', 'value'];
            let editKeys = Object.keys(this.edit);
            let csv = [];
            for (let key of editKeys) {
                csv.push([key, this.edit[key]]);
            }
            let messages = this.$refs.chatMessageForm.messages;
            let 內容 = [];
            for (let msg of messages) {
                內容.push(JSON.stringify(msg));
            }
            csv.push(['內容', 內容.join("\n")])
            saveCSV(title, keys, csv);
        },
    },
}
</script>