<template>
  <div id="RewardMessageEdit">
    <section class="content-header">
      <h1>{{ id != undefined ? "編輯" : "新增" }}跑馬燈內容</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/Marquee">跑馬燈設定</router-link></li>
        <li class="active">{{ id != undefined ? "編輯" : "新增" }}內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id != undefined ? "編輯" : "新增" }}內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form @submit.prevent="submit" class="form-horizontal" name="rewardMessageForm" method="post">
            <div class="box-body">
              <fieldset class="form-group" :disabled="id != undefined">
                <label class="col-sm-3 control-label" for="inputPlatform">
                  <span class="text-red">*</span> 顯示平台({{ id != undefined ? '不可更改' : '可複選' }})：
                </label>
                <div class="form-group col-sm-9">
                  <div class="checkbox checkbox-inline" v-for="(platform, i) in def.gamePlatform">
                    <label>
                      <input type="checkbox" v-model="platforms[i]" @change="updatePlatforms()"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >
                      &nbsp;{{ platform }}
                    </label>
                  </div>
                </div>
              </fieldset>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 類型：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model.number="data.類型">
                    <option v-for="(option, i) in def.marqueeType" :key="i" :value="i">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 跑馬燈內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <textarea cols="100" rows="5" class="form-control"
                      :maxlength="contentMax" :placeholder="`請輸入內容，上限${contentMax}字。`"
                      v-model="data.內容" required
                    ></textarea>
                  </div>
                  <p class="word-count">
                    {{ data.內容.length }} / {{ contentMax }}
                  </p>
                </div>
              </div>

              <fieldset class="form-group">
                <label class="col-sm-2 control-label" for="effectiveTime">
                  <span class="text-red">*</span> 開始時間：
                </label>
                <div class="radio col-sm-10">
                  <p>
                    <iCheckRedioButton name="timeRadio" message="立即發送" style="margin-right:1em"
                      checkValue="0" v-model.number="data.發送狀態"
                    />
                    <iCheckRedioButton name="timeRadio" message="排程時間"
                      checkValue="1" v-model.number="data.發送狀態"
                    />
                  </p>
                  <fieldset class="radio-date" v-show="data.發送狀態 != 0">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <DatePicker class="pull-right" :propValue.sync="startDate" />
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="startTimeOnly" class="form-control timepicker" @input="updateTime()" />
                    </div>
                  </fieldset>
                </div>
              </fieldset>
              <div v-show="data.發送狀態 != 0">
                <fieldset class="form-group">
                  <label class="col-sm-2 control-label" for="effectiveTime">
                    <span class="text-red">*</span> 結束時間：
                  </label>
                  <div class="col-sm-10">
                    <div class="radio-date">
                      <div class="input-group date">
                        <div class="input-group-addon fa fa-calendar"></div>
                        <DatePicker class="pull-right" :propValue.sync="endDate" />
                      </div>
                      <div class="input-group time">
                        <div class="input-group-addon fa fa-clock-o"></div>
                        <input type="text" id="endTimeOnly" class="form-control timepicker" @input="updateTime()">
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    <span class="text-red">*</span> 循環時間：
                  </label>
                  <div class="col-sm-4" style="display:flex; align-items:center; gap:.5em">
                    <input type="number" class="form-control" v-model="data.循環時間">
                    分
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="button" v-if="id" @click="deleteMessage()" class="btn btn-danger btn-lg fa fa-trash" style="margin-right:.5em">
                  刪除
                </button>
                <button type="submit" @click="data.發送狀態 = 2" class="btn btn-warning btn-lg fa fa-save" style="margin-right:.5em">
                  儲存草稿
                </button>
                <button type="submit" class="btn btn-primary btn-lg fa fa-send-o">
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- <pre>{{ JSON.stringify(data, null, 2) }}
  {{ startDate }}
  {{ endDate }}
      </pre> -->
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton,
  },
  props: {
    id: "",
  },
  data() {
    return {
      def: define,
      loading: true,
      titleMax: 16,
      contentMax: 200,
      data: {
        類型: 0,
        內容: "",
        循環時間: 60,
        發送狀態: 0,
        顯示平台: "0,1,2",
        開始時間: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        結束時間: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
      },
      platforms: [true,true,true],
      startDate: "",
      endDate: "",
    };
  },
  watch: {
    "startDate": function(){ this.updateTime() },
    "endDate": function(){ this.updateTime() },
    "data.發送狀態": function(){ this.updateTime() },
  },
  mounted() {
    let that = this;
    $(".timepicker").timepicker({ showInputs: false });
    $(".timepicker").unbind("focus");
    $(".timepicker").on("change", function(){ that.updateTime() } );
    this.getMessage();
  },
  methods: {
    getMessage() {
      if (this.id) {
        this.$store.dispatch("nmjMarquee/query", {id: this.id}).then((result) => {
          Object.assign(this.data, result);
          if (this.data.顯示平台 != null){
            let arrIn = this.data.顯示平台.split(',');
            let arrOut = [];
            for (let key of Object.keys(this.def.gamePlatform)){
              arrOut[key] = arrIn.includes(key);
            }
            this.platforms = arrOut;
            // console.log(arrIn, arrOut)
          }
          this.data.發送狀態 = +(new Date(this.data.開始時間).valueOf() >= Date.now());
          this.initTime();
        });
      }
      else {
        this.initTime();
      }
    },
    initTime() {
      this.startDate = this.data.開始時間;
      this.endDate = this.data.結束時間;
      $("#startTimeOnly").val(this.global.getTime(this.startDate));
      $("#endTimeOnly").val(this.global.getTime(this.endDate));
      this.updateTime();
    },
    updateTime() {
      this.data.開始時間 = !!this.data.發送狀態 ? new Date(`${this.startDate} ${$("#startTimeOnly").val()} UTC`) : new Date();
      this.data.結束時間 = new Date(`${this.endDate} ${$("#endTimeOnly").val()} UTC`);
    },
    updatePlatforms(){
      let arr = [];
      for (let i = 0, len = this.platforms.length; i < len; i++){
        if (!!this.platforms[i] == true) arr.push(i);
      }
      this.data.顯示平台 = arr.join(",");
      // console.log(arr, this.data.顯示平台)
    },
    backList() {
      this.$router.push({
        name: "NmjMarquee",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    submit() {
      if (this.data.發送狀態 == 1 && !this.global.isValidDay(this.data.開始時間) ) {
        this.$eventBus.$emit("showAlert", "請填寫訊息開始時間！");
        return;
      }
      if (this.data.發送狀態 == 1 && !this.global.isValidFutureDay(this.data.開始時間)) {
        this.$eventBus.$emit("showAlert", "「訊息開始時間」請勿填寫過去時間！");
        return;
      }
      if (!this.global.isValidDay(this.data.結束時間)) {
        this.$eventBus.$emit("showAlert", "請填寫訊息結束時間！");
        return;
      }
      if (!this.global.isValidFutureDay(this.data.結束時間)) {
        this.$eventBus.$emit("showAlert", "「訊息結束時間」請勿填寫過去時間！");
        return;
      }
      if (this.data.發送狀態 == 1 && !this.global.isValidDayRange(this.data.開始時間, this.data.結束時間)) {
        this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
        return;
      }
      this.data.開始時間 = this.data.開始時間.toJSON().split(".")[0];
      this.data.結束時間 = this.data.結束時間.toJSON().split(".")[0];
      const api = this.data.id ? "update" : "create";
      this.$store.dispatch(`nmjMarquee/${api}`, this.data).then(() => {
        this.backList();
      });
    },
    deleteMessage(){
      this.$store.dispatch(`nmjMarquee/delete`, this.data).then(() => {
        this.backList();
      });
    },
  },
};
</script>

<style>
#RewardMessageEdit .tox-statusbar__wordcount {
  display: none;
}
</style>