<template>
  <div id="gameContentEdit" class="slot">
    <section class="content-header">
      <h1>遊戲內容</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲管理</li>
        <li>活動列表</li>
        <li class="active">遊戲內容</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ $route.params.name }}內容說明</h4>
          </div>

          <div class="form-horizontal" name="announcementForm">
            <div class="box-body" style="max-width: none;">
							<div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 名稱：
                </label>
                <div class="col-sm-10">
                  <template 
                    v-if="
                      addData.名稱 == 'Info' ||
                      addData.名稱 == 'MG 盤面' ||
                      addData.名稱 == 'Loading 頁' 
                    "
                  >
                    <p style="margin-bottom: 0; padding-top: 7px;">
                      {{ addData.名稱 }}
                    </p>
                    <p class="text-red" v-if="addData.名稱 == 'Info'">
                      Info 的區塊視覺，也用於遊戲館卡片，滑入放大時顯示的視覺。
                    </p>
                  </template>
                  <template v-else>
                    <input
                      class="form-control"
                      id="inputTitle"
                      required="required"
                      type="text"
                      v-model.trim="addData.名稱"
                      maxlength="50"
                    />
                    <span>{{ addData.名稱.length }} / 50</span>
                  </template>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 預覽視覺：
                </label>
                <div class="col-sm-10">
                  {{ addData.視覺型態 }}
                  <div style="display: flex;" class="mb-20">
                    <div class="radio mr-10">
                      <iCheckRedioButton
                        checkValue="1"
                        message="圖片"
                        name="radioMode"
                        v-model="視覺型態"
                      />
                    </div>
                    <div>
                      <input
                        accept="image/*"
                        id="AnnouncementPic"
                        type="file"
                        @change="onFileChange($event)"
                      />
                      <span class="text-red">上限為 5 MB 的 PNG、JPG 檔，直式或橫式圖片，最長邊不超過 320 px。</span>
                      <div style="max-width: 320px;">
                        <img :src="addData.預覽視覺集合[0].視覺路徑" />
                      </div>
                    </div>
                  </div>

                  <div style="display: flex;" class="mb-20">
                    <div class="radio mr-10">
                      <iCheckRedioButton
                        checkValue="2"
                        message="影片"
                        name="radioMode"
                        v-model="視覺型態"
                      />
                    </div>
                    <div style="width: calc(100% - 61px);">
                      <input
                        class="form-control"
                        id="inputTitle"
                        required="required"
                        type="text"
                        v-model.trim="addData.預覽視覺集合[1].視覺路徑"
                        :disabled="視覺型態 != 2"
                      />
                      <span class="text-red">請貼上 YT 分享 > 複製網址的格式，例如：https://youtu.be/OOOOOOO</span>
                      <div>
                        <iframe
                          v-if="addData.預覽視覺集合[1].視覺路徑"
                          class="mt-10"
                          :src="`https://www.youtube.com/embed/${addData.預覽視覺集合[1].視覺路徑.split('youtu.be/')[1]}`"
                          title="YouTube video player"
                          frameborder="0"
                          allow="acc:elerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                          width="560"
                          height="315"
                          >
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  預覽說明：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model.trim="addData.預覽說明"
                    maxlength="50"
                  />
									<span>{{ addData.預覽說明.length }} / 50</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 詳細說明：
                </label>
                <div class="col-sm-10">
									<div class="editor mt-5">
                    <tinymce v-model="addData.詳細說明"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
								<button
									class="btn btn-warning pull-left mr-10"
                  @click="preview()"
								>
                  預覽詳細說明
								</button>
								<button
									class="btn btn-primary pull-left"
									@click="save()"
                  :disabled="
                    addData.名稱 == '' ||
                    addData.詳細說明 == '' ||
                    (視覺型態 == '1' && addData.預覽視覺集合[0].視覺路徑 == '') ||
                    (視覺型態 == '2' && addData.預覽視覺集合[1].視覺路徑 == '')
                  "
								>
									儲存
								</button>
								<button
									class="btn btn-default pull-right"
									@click="backList()"
								>
									返回
								</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--預覽-->
			<div class="modal fade" ref="preview" id="detailAlert">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span aria-hidden="true">×</span>
              </button>
							<h4 class="modal-title text-center">{{ addData.名稱 }}</h4>
						</div>
						<div class="modal-body">
              <p v-html="addData.詳細說明"></p>
						</div>
					</div>
				</div>
			</div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import api from "@/assets/js/app-api";

export default {
  components: {
    tinymce,
		iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      isEdit: false,
      id: Number(this.$route.params.id) || "",
      fileId: utils.getTimeId(),
      addData: {
        遊戲編號: Number(this.$route.params.GameId),//必傳
        遊戲館編號: Number(this.$route.params.GameHallId),//必傳
        名稱: "",
        視覺型態: "1",//1:圖片 2:影片
        視覺路徑: "",
        預覽視覺集合:[
          {
            視覺型態: "1",
            視覺路徑: "",
            是否啟用: 1
          },
          {
            視覺型態: "2",
            視覺路徑: "",
            是否啟用: 0
          }
        ],
        預覽說明: "",//可以再討論是否這樣處理
        詳細說明: "",//可以再討論是否這樣處理
        是否啟用: 1,
      },
      視覺型態: "1",
		};
  },
  created() {
    if(this.id != "") {
      this.getData();
      this.isEdit = true;
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      imgUrlList: state => state.game.imgList
    }),
  },
  watch: {
    imgUrlList() {
      this.addData.預覽視覺集合[0].視覺路徑 = this.imgUrlList[0].imgFullFileName;
      this.$forceUpdate();
    },
  },
  methods: {
    getData() {
      api.slotGame.hallContent.query({ 編號: this.id })
        .then((res) => {
          this.addData = res.data.result;
          this.addData.遊戲編號 = Number(this.$route.params.GameId);
          if(!this.addData.預覽視覺集合) {
            this.addData.預覽視覺集合 = [
              {
                視覺型態: "1",
                視覺路徑: "",
                是否啟用: 1
              },
              {
                視覺型態: "2",
                視覺路徑: "",
                是否啟用: 0
              }
            ]
          } else {
            this.視覺型態 = this.addData.預覽視覺集合[0].是否啟用 == 1 ? '1' : '2';
          }
        })
    },
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片格式不符，請選擇 png 或 jpg。"
            );
            return;
          }
          console.log(fileSize);
          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片超出 5MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files, 'slotGame', this.fileId);
      },
      uploadFile(files, folderName, id, multi = false) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.$store.state.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", "zh-tw");
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then(res => {
        if (res.status != "0") this.GetImgList();
      });
      },
      GetImgList() {
      this.$store.dispatch("game/gameImgList", {
        contentId: this.fileId
      });
    },
    save() {
      this.addData.預覽視覺集合[0].是否啟用 = this.視覺型態 == '1' ? 1 : 0;
      this.addData.預覽視覺集合[1].是否啟用 = this.視覺型態 == '2' ? 1 : 0;
      if(this.isEdit) {
        api.slotGame.hallContent.update(this.addData)
          .then((res) => {
            if(res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "更新成功");
              this.backList()
            } else {
              this.$eventBus.$emit("showAlert", "更新失敗");
            }
          })
      } else {
        api.slotGame.hallContent.create(this.addData)
          .then((res) => {
            if(res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "新增成功");
              this.backList()
            } else {
              this.$eventBus.$emit("showAlert", "新增失敗");
            }
          })
      }
    },
    preview() {
      $(this.$refs.preview).modal("show");
    },
		backList() {
			this.$router.push({
				name: 'slotGameContent',
				params: {
					GameId: this.$route.params.GameId,
          id: this.$route.params.GameHallId,
          name: this.$route.params.name
				},
			});
		}
	},
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>