<template>
  <div id="RedeemPrizesInfo">
    <section class="content-header">
      <h1>獎項兌換資訊</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>獎項兌換列表</li>
        <li class="active">獎項兌換資訊</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">獎項兌換資訊</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red"></span> 玩家識別值：
                </label>
                <div class="col-sm-10">
                  <label>{{redeemPrize.玩家識別值}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red"></span> 活動：
                </label>
                <div class="col-sm-10">
                  <label>{{redeemPrize.活動編號}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 獎項：
                </label>
                <div class="col-sm-10">
                  <label>{{redeemPrize.獎項名稱}}</label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 真實姓名：
                </label>
                <div class="col-sm-4">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="redeemPrize.真實姓名"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 身份證號碼：
                </label>
                <div class="col-sm-4">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="redeemPrize.身份證號碼"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 連絡電話：
                </label>
                <div class="col-sm-4">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="redeemPrize.連絡電話"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 郵遞區號：
                </label>
                <div class="col-sm-2">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="redeemPrize.郵遞區號"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 地址：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="redeemPrize.地址"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="redeemPrizeor">
                  <span class="text-red"></span> 備註：
                </label>
                <div class="col-sm-10">
                  <div class="redeemPrizeor">
                    <textarea v-model="redeemPrize.備註" style="width: 100%; height: 200px"></textarea>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-2">
                  <select class="form-control" v-model="redeemPrize.狀態">
                    <option value="0">未填單</option>
                    <option value="1">待處理</option>
                    <option value="2">已處理</option>
                    <option value="3">停止兌換</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save()">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
    tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {};
  },
  created() {
    this.GetQuery();
  },
  mounted() {},
  computed: {
    ...mapState({
      redeemPrize: state => state.redeemPrize.query,
      response: state => state.redeemPrize.response
    })
  },
  watch: {
    redeemPrize() {},
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        this.backList();
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    }
  },
  methods: {
    //取得資料
    GetQuery() {
      this.$store.dispatch("redeemPrize/redeemPrizeQuery", {
        兌換Id: this.$route.params.id
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "RedeemPrizesList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    formatTime: function(t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },

    //送出
    Save() {
      this.$store.dispatch("redeemPrize/redeemPrizeUpdate", this.redeemPrize);
    }
  }
};
</script>
