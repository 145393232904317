// import moment from "moment";
export const WriteLog = {};
(function (model) {
    let info = [];
    //{t:觸發時間,e:事件}
    //e:可以傳下列關鍵字：
    //"send"，送資料給server 
    //"rec":從server那收到的資料 
    //"action"：前端觸發actions
    //"log-"想顯示但不記錄的
    model.clear = function () {
        info = [];
    }
    model.add = function ({ e, detail }) {
        // console.log("log", e, JSON.stringify(detail));
        if (e == "send" || e == "rec" || e == "action" || e == "log") {
            info.push({ t: new Date().getTime(), e, detail });
        }
    }
    model.download = function () {
        let data = JSON.stringify(info);
        info = [];
        return data;
    }
})(WriteLog);

//讀取log，
//1. 把.env.development的VUE_APP_DEBUG設成true
//2. 把log放到public/log.json
export const ReadLog = {};
(function (model) {
    let log = null;
    let connection = null;
    model.read = function ({ commit, state }) {
        if (log && log.length > 0) {
            let json = log.shift();
            // console.log(new moment(json.t).format("YYYY-MM-DD HH:mm:ss"), json);
            console.error(json.t)
            if (json.e == 'rec') {
                let cmd = json.detail.cmd;
                try {
                    if (cmd == "UpdateCsList") {
                        let data = json.detail.data;
                        connection[cmd](commit, state, data);
                    } else if (cmd == "ReceiveChatMessage") {
                        let data = json.detail.data;
                        connection[cmd](commit, state, data);
                    } else {
                        let data = JSON.stringify(json.detail.data);
                        connection[cmd](commit, state, data);
                    }
                } catch (e) {
                    console.error("cmd=", cmd)
                }
            }
        }
        return null;
    }
    model.load = function (_connection, url = "./log.json") {
        connection = _connection;
        fetch(url).then(response => {
            return response.json()
        }).then(jsonData => {
            log = jsonData;
            console.error("DEBUG資料載入完成");
            // 在此處理讀取到的 JSON 資料
        }).catch(error => {
            console.error("error", error)
            // 處理錯誤情況
        });
    }
})(ReadLog);