<template>
  <div id="MIDAnnouncementList">
    <section class="content-header">
      <h1>公告列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公告列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增公告
            </button>
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.noteType"
                @change="resetDataList()"
              >
                <option value>全部</option>
                <option value="系統公告">系統公告</option>
              </select>
            </div>

            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">編號</th>
                  <th class="text-nowrap" style="width: 10%">分類</th>
                  <th class="text-nowrap" style="width: 20%">內容</th>
                  <th class="text-nowrap" style="width: 15%;">開始日期</th>
                  <th class="text-nowrap" style="width: 15%;">結束日期</th>
                  <th class="text-nowrap" style="width: 10%;">狀態</th>
                  <th class="text-nowrap" style="width: 15%; min-width: 200px;">功能</th>
                </tr>
                <template v-if="loading">
									<td class="text-center" colspan="10">查詢中</td>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="10">查詢無任何資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="index" v-for="(item, index) in data">
                    <td class="text-center">{{ searchData.pageSize * (searchData.pageNum - 1) + index + 1 }}</td>
                    <td class="text-center">{{ item.noteType }}</td>
                    <td class="text-center">{{ item.note }}</td>
                    <td class="text-center">{{ item.publishSTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">{{ item.publishETime | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">{{ item.isStop == '1'? '下架':'上架' }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-primary mr-10"
                        type="button"
                        @click="editItem(item)"
                      >
                        修改
                      </button>
                      &nbsp;
                      <button
                        class="btn btn-danger mr-10"
                        type="button"
                        @click="deleteItem(item)"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0 && !loading">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    DateRangePicker,
    Pagination,
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        noteType: "",  
        isStop: -1,  
        pageNum: 1,  
        pageSize: 10,  
      },
      data: [],
      total: 0,
    };
  },
  created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getDataList();
  },
  mounted() { },
  methods: {
    getDataList() {
      this.loading = true;

      api.mid.article.queryAll(this.searchData)
        .then((res) => {
          this.loading = false;
          this.data = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    addNew() {
      this.$router.push({
        name: "MIDAnnouncementEdit",
        params: {
          searchData: this.searchData,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: "MIDAnnouncementEdit",
        params: {
          id: item.id,
          searchData: this.searchData,
        },
      });
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.mid.article.delete({ id: item.id })
            .then((res) => {
              setTimeout(() => {
                if(res.data.status == "1") {
                  this.$eventBus.$emit("showAlert", "刪除成功!");
                  this.getDataList();
                } else {
                  this.$eventBus.$emit("showAlert", res.data.message ||　"刪除失敗!");
                }
              }, 100);
            })
        }
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>