<template>
  <!-- 給獎訊息設定 > 查看發送內容 -->
  <div class="modal fade" ref="rewardMessageContent">
    <div class="modal-dialog">
      <div class="modal-content nav-tabs-custom tab-warning">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <template v-if="tabNum==1">發送內容</template>
            <template v-if="tabNum==2">發送明細</template>
          </h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table v-if="tabNum == 1" class="table table-bordered">
              <tbody>
                <tr>
                  <td style="width:100px">發送時間</td>
                  <td>{{ uiDate(message.發送時間) }}</td>
                </tr>
                <tr>
                  <td>到期時間</td>
                  <td>{{ uiDate(message.到期時間) }}</td>
                </tr>
                <tr>
                  <td>信件標題</td>
                  <td>{{ message.信件標題 }}</td>
                </tr>
                <tr>
                  <td>信件內容</td>
                  <td>
                    <div v-if="/^</.test(message.信件內容)" v-html="message.信件內容"></div>
                    <div v-else style="white-space:pre-wrap;" v-text="message.信件內容"></div>
                  </td>
                </tr>
                <tr v-if="message.發送獎勵列表.length > 0">
                  <td>附件</td>
                  <td>
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr class="text-bold bg-gray">
                          <th style="padding:.1em;"><b>物品 (ID)</b></th>
                          <th style="padding:.1em;"><b>數量</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in message.發送獎勵列表">
                          <td style="padding:.1em;">{{ gameTypes.Item[item.id] }} <span style="color:#bbb">({{item.id}})</span></td>
                          <td style="padding:.1em;">{{ item.count }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              <!-- <tr><td colspan="2"><pre style="white-space:pre-wrap;">{{ message }}</pre></td></tr> -->
            </table>
            <div v-if="tabNum == 2">
              <div v-if="message.發送名單選項 == 0">
                <p>
                  發送數量: 全伺服器發送
                </p>
              </div>
              <div v-else>
                <p v-if="message.發送名單選項 == 2">
                  發送條件: &nbsp;
                  <span v-for="(item, i) in message.指定條件列表">
                    <span v-if="item.field == 0">
                      {{ gameTypes.Level[item.minValue] }}<small style="color:#aaa">({{ item.minValue }})</small> ~
                      {{ gameTypes.Level[item.maxValue] }}<small style="color:#aaa">({{ item.maxValue }})</small>
                    </span>
                    <span v-if="item.field == 1">
                      {{ item.minValue }} ~ {{ item.maxValue }}雀幣
                    </span>
                    {{ i < message.指定條件列表.length - 1 ? "，" : "" }}
                  </span>
                </p>
                <p style="display:flex; align-items:center; justify-content:space-between;">
                  <span>發送數量: {{ message.玩家列表.length }} </span>
                  <input type="text" v-model="filterString" class="form-control" placeholder="暱稱搜尋" style="width:50%"/>
                </p>
                <table class="table table-bordered">
                  <thead>
                    <tr class="bg-gray-light">
                      <th colspan="10">玩家暱稱</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in pagedTable">
                      <td v-for="col in row" style="padding:.1em .5em;width:25%">
                        <router-link data-dismiss="modal" :to="{name:'NmjRewardMessageHistory', query:{nick: col}}">{{ col }}</router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="box-footer text-center no-border">
                  <Pagination ref="pagination" :pageLimitCount="pageSize" :total="pageTotal" v-on:pageMethod="onPageChange" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-default pull-right">
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import define from "@/views/projects/NMJ/_Define";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      def: define,
      message: {
        發送時間: '',
        到期時間: '',
        信件標題: '',
        信件內容: '',
        發送獎勵列表: [],
      },
      gameTypes: {},
      filterString: "",
      pageNum: 1,
      pageSize: 10,
      pageTotal: 0,
      tabNum: 1,
    };
  },
  computed:{
    "playerTable": function(){
      let search = this.filterString.trim();
      let re = new RegExp(search, "i");
      let list = search == '' ?
        this.message.玩家列表 :
        this.message.玩家列表.filter(s => re.test(s));
        
      let arr = [];
      for (let i = 0, len=list.length; i < len; ){
        let row = [];
        for (let j = 0; j < 4; j++){
          row.push(list[i++])
        }
        arr.push(row);
      }
      this.pageTotal = arr.length;
      return arr;
    },
    "pagedTable": function(){
      return this.playerTable.slice((this.pageNum-1) * this.pageSize, (this.pageNum) * this.pageSize);
    }
  },
  watch:{
    "filterString": function(){ this.pageNum = 1 }
  },
  methods: {
    async showRewardMessageContent(message, gameTypes, searchData, showPlayers = false) {
      if (Array.isArray(message.附件)){ message.發送獎勵列表 = message.附件; delete message.附件};
      if (typeof message.信件內文 == "string"){message.信件內容 = message.信件內文; delete message.信件內文};
      this.message = message;
      this.gameTypes = gameTypes;
      this.searchData = searchData;
      this.tabNum = showPlayers ? 2 : 1;
      $(this.$refs.rewardMessageContent).modal("show");
    },
    async onPageChange(pageIdx, pageSize) {
      this.pageNum = pageIdx;
      this.pageSize = pageSize;
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
};
</script>
