<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>簡訊明細查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>簡訊明細查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="date-group" style="width: auto;">
              <label class="control-label">時間：</label>
              <div class="input-group date datetime">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propTimePicker="true"
                    :propEndDate.sync="searchData.結束時間"
                    :propStartDate.sync="searchData.開始時間"
                    :key="resetDate"
                  />
                </div>
              </div>
            </div>

            <div class="select-group" style="width: auto;">
              <label for="" class="control-label">區碼：</label>
              <select
                class="form-control"
                v-model="searchData.區碼"
              >
                <option value="">全部</option>
                <option
                  v-for="(areaCode, index) in define.areaCodeList"
                  :value="areaCode.code"
                >
                  {{ areaCode.code }}({{ areaCode.name }})
                </option>
              </select>
            </div>
            <div class="input-group mr-10">
              <label for="" class="control-label">門號：範例(925151184)</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="輸入手機門號"
                type="text"
                v-model="searchData.門號"
              />
            </div>   
            <div class="ml-10 text-red">
              <p>* 一般查詢輸入門號，區間以30天為限</p>
              <p>* 全部查詢不輸入門號，區間以24小時內為限</p>
            </div>
          </div>
          
          <div class="top-box topBox-announceList">
            <div class="select-group" style="width: 200px;">
              <label for="" class="control-label">服務列表：</label>
              <select
                class="form-control"
                v-model="searchData.帳號"
              >
                <option value v-if="openAll">全部</option>
                <option
                  v-for="(service, index) in user.SmsServiceList.服務列表"
                  :value="service.AccId"
                >
                  {{ service.AccName }}
                </option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">發送簡訊商：</label>
              <select
                class="form-control"
                id="selectUnit"
                v-model="searchData.簡訊商代碼"
              >
                <option value>全部</option>
                <option
                  v-for="sendCom in sendComList"
                  :value="sendCom.簡訊商門號代碼"
                >
                  {{ sendCom.簡訊商門號說明 }}
                </option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">查詢資料表：</label>
              <select
                class="form-control"
                v-model="searchData.查詢類別"
              >
                <option :value="1">簡訊暫存</option>
                <option :value="2">發送紀錄</option>
              </select>
            </div>

            <div class="select-group" >
              <label for="" class="control-label">發送狀態：</label>
              <select
                class="form-control"
                v-model="searchData.狀態"
              >
              <option value>全部</option>
                <option
                  v-for="(status, index) in statusList"
                  :value="status.狀態"
                >
                  {{ status.狀態中文 }}
                </option>
              </select>
            </div>

            <div class="btns">
              <button class="btn btn-primary mr-10" type="button" @click="resetDataList()">查詢</button>
              <button class="btn btn-primary mr-10" type="button" @click="resetDataList(true)">重新查詢</button>
              <!-- <button class="btn btn-warning" type="button">重設簡訊商</button> -->
              <div class="text pull-right">
                <p class="data-num">
                  <span>發送總筆數：</span>
                  <span id="total">{{ total }}</span>
                </p>
              </div>
            </div>

          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="width: 5%;">區碼</th>
                  <th class="text-nowrap" style="width: 10%">手機號碼</th>
                  <th class="text-nowrap" style="width: 10%;">簡訊商</th>
                  <th class="text-nowrap" style="width: 8%;">服務</th>
                  <th class="text-nowrap" style="width: 10%;">發送狀態</th>
                  <th class="text-nowrap" style="width: 10%;">建立時間</th>
                  <th class="text-nowrap" style="width: 10%;">到達時間</th>
                  <th class="text-nowrap" style="width: 15%;">備註</th>
                </tr>

                <template v-if="loading">
									<td class="text-center" colspan="10">查詢中</td>
                </template>
                <template v-else-if="total == 0">
                  <tr>
                    <td class="text-center" colspan="10">查詢無任何資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="index" v-for="(item, index) in data">
                    <td class="text-center">{{ item.區碼 }}</td>
                    <td class="text-center">{{ item.門號 }}</td>
                    <td class="text-center">{{ comList[item.簡訊商代碼] }}</td>
                    <td class="text-center">{{ getAccountName(item.來源) }}</td>
                    <td class="text-center">{{ item.狀態 }}</td>
                    <td class="text-center">{{ item.建立時間 | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">{{ item.到達時間 | dateFormat("YYYY/MM/DD HH:mm:ss") }}</td>
                    <td class="text-center">{{ item.備註 }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0 && !loading">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.每頁數量"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from '@/components/DateRangePicker';
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        開始時間: new moment().subtract(1, "day"),
        結束時間: new moment(),
        查詢類別: 2, //1. 發送紀錄、2. 簡訊暫存
        簡訊商代碼: "",
        特殊模式YN: 0,
        狀態: "",
        狀態排除: 0,
        包含狀態: "",
        不包含狀態: "",
        發送序號: null,
        區碼: "",
        門號: "",
        帳號: "",
        頁碼: 1,
        每頁數量: 10
      },
      statusList: [],
      accountList: [],
      comList: [],
      data: [],
      total: 0,
      resetDate: new Date().getTime(),
      openAll: false,
      sendComList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.loginUser,
    }),
  },
  created() {
    this.getSendComList();
    this.setServiceList();
    this.getStatusList();
    this.getAccountList();
    this.getComList();
  },
  mounted() {  },
  methods: {
    getSendComList() {
      api.moloSMS.user.SmsComTypeQueryAll()
        .then((res) => {
          this.sendComList = res.data.result;
        })
    },
    getStatusList() {
      api.moloSMS.status()
        .then((res) => {
          this.statusList = res.data.result
        })
    },
    getAccountList() {
      api.moloSMS.user.queryAll({
        IsOpen: -1, //帳號 1:啟用 0:停用
        IsScmOpen: -1, //互動簡訊 1:啟用 0:停用
      })
        .then((res) => {
          this.accountList = res.data.result;
          this.getDataList();
        })
    },
    getComList() {
      api.moloSMS.user.SmsComTypeQueryAll()
        .then((res) => {
          res.data.result.forEach(item => {
            this.comList[item.簡訊商門號代碼] = item.簡訊商門號說明
          });
        })
    },
    setServiceList() {
      this.openAll = this.user.SmsServiceList.全開 == 1;
      if(!this.openAll) this.searchData.帳號 = this.user.SmsServiceList.服務列表[0].Id;
    },
    getDataList() {
      this.loading = true;
      this.$forceUpdate();
      this.searchData.開始時間 = new moment(this.searchData.開始時間).format("YYYY/MM/DD HH:mm:ss");
      this.searchData.結束時間 = new moment(this.searchData.結束時間).format("YYYY/MM/DD HH:mm:ss");
      api.moloSMS.details(this.searchData)
        .then((res) => {
          this.loading = false;
          this.data = res.data.result.datas;
          this.total = res.data.result.total;
        })
    },
    resetDataList(clear) {
      if(clear) {
        this.searchData.開始時間 = new moment().subtract(30, "minute"),
        this.searchData.結束時間 = new moment(),
        this.resetDate = new Date().getTime();
        this.searchData.區碼 = "";
        this.searchData.門號 = "";
        this.searchData.帳號 = "";
        this.searchData.查詢類別 = 2;
        this.$forceUpdate();
      } else {
        let time
        let startTime = new moment(this.searchData.開始時間).format("YYYY/MM/DD HH:mm:ss")
        if(this.searchData.門號) time = new moment(this.searchData.結束時間).subtract(30, "day").format("YYYY/MM/DD HH:mm:ss");
        else time = new moment(this.searchData.結束時間).subtract(1, "day").format("YYYY/MM/DD HH:mm:ss");
        if(moment(startTime).isBefore(time)) {
          let message = this.searchData.門號 ? '30天' : '24小時'
          this.$eventBus.$emit("showAlert", `查詢區間以${message}為限`);
          return
        }
      }
      this.searchData.頁碼 = 1;
      this.getDataList();
    },
    getAccountName(id) {
      return this.accountList.find(item => item.id == id).accName
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.頁碼 = pageIdx;
      this.searchData.每頁數量 = pageSize;
      this.getDataList();
    },
  },
};
</script>