<template>
    <div>
        <section class="content-header">
            <h1> 品項管理</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li class="active">品項管理 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box ">
                        <div class="form-group">
                            <label class="control-label">廠商：</label>
                            <select class="selPaymentType form-control" v-model="searchData.廠商設定主檔編號">
                                <option value="-1">
                                    全部
                                </option>
                                <option v-for="item in 廠商設定List" :key="item.編號" :value="item.編號">
                                    {{ item.名稱 }}-{{ item.顯示名稱 }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <button @click="onSearch(1, searchData)" class="btn btn-primary" type="button">
                                查詢
                            </button>
                        </div>
                        <button class="btn btn-block btn-warning btn-add" @click="addItem()">
                            <i class="fa fa-plus"></i> 新增品項
                        </button>
                    </div>
                    <div class="table-responsive mt-10">
                        <table class="table table-hover table-bordered min-800">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-nowrap">品項名稱</th>
                                    <th class="text-nowrap">商品代碼</th>
                                    <!-- <th class="text-nowrap">地區</th> -->
                                    <th class="text-nowrap">金額</th>
                                    <th class="text-nowrap">販賣數量<br />(-1表示無限制)</th>
                                    <th class="text-nowrap">停用/啟用</th>
                                    <th class="text-nowrap text-center" style="min-width: 150px; width: 10%">
                                        功能
                                    </th>
                                </tr>
                                <template v-if="list.length > 0">
                                    <tr v-for="item in list" :key="item.編號">
                                        <td class="text-center">{{ item.品項名稱 }}</td>
                                        <td class="text-center">{{ item.商品代碼 }}</td>
                                        <!-- <td class="text-center">{{ item.地區 }} ???</td> -->
                                        <td class="text-center">{{ item.金額 }}</td>
                                        <td class="text-center">{{ item.販賣數量 }}</td>
                                        <td class="text-center">
                                            <div class="ckbx-style-8 ckbx toggleInput" @click="updEnable(item)">
                                                <input :false-value="0" :true-value="1" name="ckbx-style-8" type="checkbox"
                                                    v-model="item.啟用" />
                                                <label :for="'check' + item"></label>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <button class="btn btn-danger" @click="editItem(item)">編輯</button>
                                        </td>
                                    </tr>
                                </template>

                                <template v-else>
                                    <tr>
                                        <td class="text-center" colspan="7">沒有資料</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer text-center no-border" v-show="total > 0">
                    <Pagination ref="pagination" :total="total" :pageLimitCount="pageSize" v-on:pageMethod="onPageChange" />
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import { APILib } from './IPSTool2'
export default {
    components: {
        Pagination,
    },
    data() {
        let pageSize = 20;
        return {
            廠商設定List: [],
            pageSize,
            total: 0,
            searchData: {
                // 起始行: 1,//起始行
                // 結束行: pageSize,//結束行
                廠商設定主檔編號: -1,//
            },
            list: [],
            lastQueryCommand: null
        };
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                this.廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                this.searchData.廠商設定主檔編號 = this.$route.query.廠商設定主檔編號 ? this.$route.query.廠商設定主檔編號 : -1;
                await this.onSearch(this.$route.query.pageIdx ? this.$route.query.pageIdx : 1, this.searchData);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
        },
        async onPageChange(pageIdx) {
            this.onSearch(pageIdx, this.lastQueryCommand);
        },
        async onSearch(pageIdx, query) {
            if (!query) {
                return;
            }
            try {
                let send = JSON.parse(JSON.stringify(query));
                send.pageIdx = pageIdx;
                send.起始行 = (pageIdx - 1) * this.pageSize + 1;
                send.結束行 = pageIdx * this.pageSize;
                if (send.廠商設定主檔編號 == -1) {
                    delete send.廠商設定主檔編號;
                }
                $(this.$refs.loading).show();
                let res = APILib.IPS(await api.IPSSetting.廠商品項管理_查詢(send));
                this.list = res.content;
                this.total = res.total;
                this.lastQueryCommand = send;
            } catch (e) {
                this.list = [];
                this.total = 0;
                this.lastQueryCommand = null;
                this.$eventBus.$emit("showAlert", e);
            }
            this.$refs.pagination.setPage(
                pageIdx,
                this.total
            );
            $(this.$refs.loading).hide();
        },
        addItem() {
            this.$router.push({
                name: "GoodsManagerEdit",
                query: this.lastQueryCommand,
                params: { goodId: -1 },
            });
        },
        editItem({ 編號 }) {
            this.$router.push({
                name: "GoodsManagerEdit",
                query: this.lastQueryCommand,
                params: { goodId: 編號 },
            });
        },
        async updEnable(item) {
            $(this.$refs.loading).show();
            try {
                let obj = JSON.parse(JSON.stringify(item));
                obj.啟用 = obj.啟用 == 1 ? 0 : 1;
                delete obj.建立時間;
                APILib.IPS(await api.IPSSetting.廠商品項管理_修改(obj));
                this.onSearch(this.lastQueryCommand.pageIdx, this.lastQueryCommand);
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
            }
            $(this.$refs.loading).hide();
        }
    },
};
</script>
<style scoped>
.top-box .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
    padding-top: 7px;
    float: left;
}

.top-box .input-group {
    min-width: 250px;
}

.selPaymentType {
    width: 300px;
}
</style>