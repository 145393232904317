import api from "@/assets/js/app-api";
import store from '@/store.js';
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    Delete({ commit }, data) {
        api.XinStarsTw.PlatformBuyDel({ phones: data }).then(res => {
            if (res.data.status == "1") {
                this._vm.$eventBus.$emit("showAlert", "成功");
            } else {
                this._vm.$eventBus.$emit("showAlert", "失敗門號: " + res.data.result.toString());
            }
            let message = "門號: " + data.toString();
            if (res.data.status != "1") message += ", 失敗門號: " + res.data.result.toString();
            api.XinStarsTw.AddLog({ cmd: "CmdXinStarsOperationLog", log: JSON.stringify({ type: "雙平台刪除額度", message: message, isSussess: res.data.status == "1", editor: store.state.loginUser.userid }) });
        })
    }
}

// mutations
const mutations = {
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}