<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">活動歷程</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">
            <div class="select-group">
              <label class="control-label">開始時間：</label>
              <DatePicker v-bind:propValue.sync="searchData.開始時間" />
            </div>

            <div class="select-group">
              <label class="control-label">結束時間：</label>
              <DatePicker v-bind:propValue.sync="searchData.結束時間" />
            </div>

            <div class="select-group">
              <label class="control-label">活動類型：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.event_type"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option value="1">新創</option>
                <option value="2">每日報到</option>
                <option value="3-5">連續登入五天</option>
                <option value="3-10">連續登入十天</option>
                <option value="3-15">連續登入十五天</option>
                <option value="3-20">連續登入二十天</option>
                <option value="3-28">連續登入二十八天</option>
                <option value="4">盟友</option>
                <option value="5">i禮贈</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">獎項：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.award_type"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option value="1">銘謝惠顧</option>
                <option value="2-1">(綁定)超級米勒藥水選擇箱</option>
                <option value="2-2">(綁定)掉落率卷軸 20%</option>
                <option value="2-3">(綁定)全能聖水 7日</option>
                <option value="2-4">(綁定)戰場的符文 7日兌換箱</option>
                <option value="3-1">特色保溫瓶</option>
                <option value="3-2">造型小夜燈</option>
                <option value="3-3">精美海報</option>
                <option value="3-4">周邊小物組</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">寶箱領取狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.get_award_status"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option value="1">未領取</option>
                <option value="2">已領取</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">獎項配發狀態：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.allot_status"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option value="1">未配發</option>
                <option value="2">已配發</option>
              </select>
            </div>

            <div class="input-group sW">
              <label class="control-label">輸入MoloId：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋MoloId"
                type="text"
                v-model="searchData.moloId"
              />
              <div class="input-group-btn verAlign-bottom">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ activityRecord.total }}</span>
              </p>
            </div>

            <div class="form-group fr snmMT w100-md mt-15-md">
              <button @click="exportOrders" class="btn btn-success w100-md" type="button">匯出</button>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">編號</th>
                  <th style="min-width: 90px; width: 5%">活動編號</th>
                  <th style="min-width: 90px; width: 10%">活動類型</th>
                  <th style="min-width: 90px; width: 10%">molo id</th>
                  <th class="text-nowrap" style="width: 10%;">是否寶箱領取</th>
                  <th class="text-nowrap" style="width: 10%;">獎項名稱</th>
                  <th class="text-nowrap" style="width: 10%;">獎項序號</th>
                  <th class="text-nowrap" style="width: 10%;">是否獎項配發</th>
                  <th style="min-width: 90px; width: 8%">領取寶箱時間</th>
                  <th class="text-nowrap" style="width: 10%;">新建時間</th>
                </tr>
                <template v-if="activityRecord.total > 0">
                  <tr :key="item.id" v-for="(item, index) in activityRecord.datas">
                    <td align="center">{{ index+1 }}</td>
                    <td align="center">{{ item.event_id }}</td>
                    <td align="center">{{ GetEventType(item.event_type) }}</td>
                    <td align="center">{{ item.molo_id }}</td>
                    <td align="center">{{ GetStatus(item.get_award_status) }}</td>
                    <td align="center">{{ item.award_name }}</td>
                    <td align="center">{{ item.award_code }}</td>
                    <td align="center">{{GetStatus(item.allot_status)}}</td>
                    <td align="center">{{ item.get_chest_time }}</td>
                    <td align="center">{{ FormatTime(item.create_time) }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="11">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="activityRecord.total > 0">
          <Pagination
            ref="pagination"
            :total="activityRecord.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DatePicker,
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數

      searchData: {
        event_id: "",
        event_type: "",
        moloId: "",
        get_award_status: "",
        award_type: "",
        allot_status: "",
        開始時間: new moment().format("YYYY/MM/DD"),
        結束時間: new moment().format("YYYY/MM/DD"),
        pageNum: 1,
        pageSize: 10 //每頁顯示筆數
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetActivityQueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      activityRecord: state => state.event.queryAll
    })
  },
  watch: {
    activityRecord() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.activityRecord.total
      );
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetActivityQueryAll();
    },

    GetActivityQueryAll() {
      //活動列表
      this.$store.dispatch("event/eventQueryAll", this.searchData);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetActivityQueryAll();
    },

    GetEventType(type) {
      if (type == "1") return "新創";
      if (type == "2") return "每日報到";
      if (type == "3-5") return "連續登入五天";
      if (type == "3-10") return "連續登入十天";
      if (type == "3-15") return "連續登入十五天";
      if (type == "3-20") return "連續登入二十天";
      if (type == "3-28") return "連續登入二十八天";
      if (type == "4") return "盟友 ";
      if (type == "5") return "i禮贈 ";
    },

    GetStatus(status) {
      if (status == "1") return "否";
      if (status == "2") return "是";
      return "";
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return utils.formatTime(time, "YYYY/MM/DD HH:ss:mm");
    },

    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("event/eventExport", tmpSearchData);
    }
  }
};
</script>
