<template>
  <div id="PlayerDataList">
    <section class="content-header">
      <h1>玩家儲值查詢</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">玩家儲值查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <form class="top-box" @submit="$event.preventDefault(); resetDataList()">
            <div class="form-group mb-10" style="width:max-content;max-width:100%;">
              <label class="control-label">訂單時間：</label>
              <div class="input-group date">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propTimePicker="true"
                  :propEndDate.sync="local.endTime"
                  :propStartDate.sync="local.startTime"
                />
              </div>
            </div>
            <div class="select-group">
              <select class="form-control" v-model.number="searchData.平台" @change="resetDataList()">
                <option value="ALL">所有平台</option>
                <option value="GOOGLE">GooglePlay</option>
                <option value="IOS">AppleStore</option>
              </select>
            </div>
            <div class="clear input-group mb-10">
              <input type="text" v-model.trim="searchData.暱稱" class="form-control" placeholder="玩家暱稱"/>
            </div>
            <div class="input-group mb-10">
              <input type="text" v-model.trim="searchData.交易ID" class="form-control" placeholder="訂單編號"/>
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary" @click="">
                查詢
              </button>
              <button type="button" class="btn btn-default" @click="exportOrders()" :disabled="datas.length === 0">
                匯出
              </button>
            </div>
            <button type="button" v-show="Object.keys($route.query).length > 0" @click="$router.go(-1)" class="btn btn-default pull-right fa fa-arrow-left"> 返回</button>
          </form>
          <div class="total-data" style="">
              <b>總金額(TWD)：{{ totalMoney }}</b>
              <b>總筆數：{{ total }}</b>
              <b>不重複人數：{{ totalUnique }}</b>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-hover text-center">
              <thead>
                <tr class="bg-gray">
                  <!-- <th style="min-width: 92px;width:10%">訂單<wbr>建立時間</th> -->
                  <th style="min-width: 92px;width:10%">訂單最後<wbr>處理時間</th>
                  <th style="min-width: 92px;width:10%">玩家暱稱</th>
                  <!-- <th style="min-width:120px;width:15%">訂單狀態</th> -->
                  <th style="min-width: 92px;width: 5%">儲值平台</th>
                  <th style="min-width:120px;width:15%">訂單編號</th>
                  <th style="min-width:120px;width:15%">平台<wbr>商品ID</th>
                  <th style="min-width: 92px;width: 5%">商品名稱</th>
                  <th style="min-width: 80px;width: 5%">販售金額<wbr>(TWD)</th>
                  <th style="min-width: 80px;width: 5%">獲得雀幣</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="10">查詢中</td>
                </tr>
                <tr v-else-if="datas.length <= 0">
                  <td colspan="10">查詢無資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <!-- <td>{{ uiDate(item.訂單建立時間) }}</td> -->
                  <td>{{ uiDate(item.訂單最後處理時間) }}</td>
                  <td><router-link :to="{name:'NmjPlayerData', query:{nick:item.暱稱}}">{{ item.暱稱 }}</router-link></td>
                  <!-- <td>{{ item.項目 }}</td> -->
                  <td>{{/^apple/.test(item.平台商品ID) ? 'AppleStore' : 'GooglePlay' }}</td>
                  <td>{{ item.訂單編號 }}</td>
                  <td>{{ item.平台商品ID }}</td>
                  <td>{{ item.商品名稱 }}</td>
                  <td>{{ item['販售金額(TWD)']  }}</td>
                  <td>{{ item.獲得雀幣 }}</td>
                  <!-- <td><button class="btn btn-primary" @click="gameDataList(item)">牌局</button></td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box text-center no-border no-shadow mt-20">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              ref="pagination"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <div class="box-footer text-center no-border">
        </div>
        <!-- <pre>{{ datas[0] }}</pre> -->
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.total-data{
  display:flex; 
  flex-wrap:wrap;
  padding:.5em;
  color:#333;
  >*{
    width: max-content;
    margin-right: 3em;
    @media (max-width:599px){
      width: 100%;
    }
  }
}
table.table > tbody > tr:nth-child(2n+1) td{
  background-color: rgba(0,0,0,.01);
}
  table.table > tbody > tr > td {
    word-break: break-word;
  }
  table.table > thead > tr > th{
    word-break: keep-all;
    font-weight: normal;
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import define from "@/views/projects/NMJ/_Define";

export default {
  components: {
    DateRangePicker,
    Pagination
  },
  data() {
    return {
      def: define,
      loading: false,
      backBtn: false,
      total: 0, //總筆數
      totalMoney: 0, //總金額(TWD)
      totalUnique: 0, //不重複人數
      searchData: {
        平台: "ALL",
        暱稱: "",
        交易ID: "",
        開始時間: "2023-01-01 00:00:00",
        結束時間: "2023-08-25 23:59:59",
        pageNum: 1,
        pageSize: 20
      },
      local: {
        startTime: new moment().subtract(7, "days").format("YYYY/MM/DD 00:00:00"),
        endTime: new moment().format("YYYY/MM/DD 23:59:59"),
      },
      datas: [],
    };
  },
  async created() {
    if (this.$route.query.nick){
      this.searchData.暱稱 = this.$route.query.nick;
    }
    this.getDataList();
  },
  mounted() {
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    resetDataList(){
      this.searchData.pageNum = 1;
      this.getDataList();
    },

    getDataList() {
      // if (!this.searchData.暱稱) {
      //   this.$eventBus.$emit("showAlert", "請填寫玩家暱稱！");
      //   return;
      // }
      if (this.searchData.交易ID){
        this.searchData.開始時間 = new moment(new Date(0)).format("YYYY/MM/DD HH:mm:ss");
        this.searchData.結束時間 = new moment(new Date()).add(1, "month").format("YYYY/MM/DD HH:mm:ss");
      } else {
        this.searchData.開始時間 = new moment(this.local.startTime).format("YYYY/MM/DD HH:mm:ss");
        this.searchData.結束時間 = new moment(this.local.endTime).format("YYYY/MM/DD HH:mm:ss");
      }
      this.loading = true;
      this.$store
        .dispatch("nmjPlayerData/deposit", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total || 0;
          this.totalMoney = result.totalPoint ? result.totalPoint.點數合計 : 0;
          this.totalUnique = result.noRepeatNum ? result.noRepeatNum.不重複數 : 0;
          this.loading = false;
          let query = {};
          if (this.searchData.暱稱 != "") query['nick'] = this.searchData.暱稱;
          if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
            this.$router.replace({query:query});
          }
        }, (res) => {
          this.datas = [];
          this.total = 0;
          this.loading = false;
        });
    },
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/exportDeposit", this.searchData);
    },
    uiDate(date){
      let d = new Date(date);
      if (d == "Invalid Date") return date;
      return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
    }
  },
};
</script>
