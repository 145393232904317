<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="announcementReplyAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h3 class="modal-title text-primary">
            <a @click="linkItem(item)">
              <span v-if="type == 1"> {{ item.articleTitle }}</span>
              <span v-else-if="type == 2"> {{ item.commentContent }}</span>
              <span v-else> {{ item.contentText }}</span>
            </a>
          </h3>
        </div>
        <div class="modal-body">
          <h4 style="margin-top: 0;">新增回覆</h4>
          <textarea
            v-model="content"
            rows="5"
            placeholder="請輸入回覆內容"
            style="width: 100%; border-color: #ccc; padding: 10px;"
          >
          </textarea>
        </div>
        <div class="modal-footer">
          <button @click="submit()" class="btn btn-primary" type="button">
            回覆
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
            @click="content = ''"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  props: ["actionType", "checkDatas"],
  data() {
    return {
      editorName: this.$store.state.loginUser.EmployeeName,
      item: [],
      deleteReason: '惡意洗版',
      type: '',
      content: '',
      isLoading: false,
    };
  },
  methods: {
    showAlert(item, type) {
      this.item = item
      this.type = type
      $(this.$refs.announcementReplyAlert).modal("show");
    },
    hideAlert() {
      $(this.$refs.announcementReplyAlert).modal("hide");
      alert('回覆成功')
      this.$emit("updateList");
    },
    linkItem(item) {
      window.open(
        `${process.env.VUE_APP_NEWTALKWEB_PATH}/post/${
          item.articleId || item.reportArticleId
        }`
      );
    },
    submit() {
      if(this.isLoading) {
        return
      }
      this.isLoading = true;
      let actionType = this.type == 1 ? 'comment' : 'reply'
      let targetId = this.item.commentId ? this.item.commentId : this.item.ReplyTopicId
      let data = this.type == 1 ?
      {
        articleId: this.item.articleId,
        commentContent: this.content
      }
      :
      {
        targetType: 3,
        targetId: targetId,
        tagReplyId: this.item.replyId | '',
        replyContent: this.content,
      }
      this.$store
        .dispatch(`newtalk/${actionType}Add`, data)
        .then(() => {
          this.isLoading = false;
          this.content = '';
          this.hideAlert();
        });
    },
  },
};
</script>
