import moment from "moment";
export const 網銀統編 = "24438615";
export const TransEnum = function (v, list) {
    let keys = Object.keys(list);
    for (let key of keys) {
        if (list[key] == v) {
            return key;
        }
    }
    return "";
}

export const 發票聯別全部 = -1;
//發票聯別(同server設定)
export const 發票聯別Enum = {
    二聯: 2,
    三聯: 3,
}
export const 發票類別全部 = -1;
//發票類別(同server設定)
export const 發票類別Enum = {
    紙本: 0,
    載具: 1,
    捐贈: 2,
    交換: 3
}
export const Get發票聯別And類型 = function (發票種類) {
    let tmp = 發票種類.split("_");
    let 聯別 = parseInt(tmp[0]);
    let 類型 = parseInt(tmp[1]);
    return {
        聯別,
        類型
    };
}
export const Get發票種類 = function (類別, 類型) {
    return 類別 + "_" + 類型;
}

// 二聯：紙本、載具、捐贈 三聯：紙本、交換
export const 發票種類全部 = -1;
export const 發票種類二聯全部 = Get發票種類(發票聯別Enum.二聯, -1);
export const 發票種類三聯全部 = Get發票種類(發票聯別Enum.三聯, -1);
export const 發票種類Enum = {
    二聯紙本: Get發票種類(發票聯別Enum.二聯, 發票類別Enum.紙本),
    二聯載具: Get發票種類(發票聯別Enum.二聯, 發票類別Enum.載具),
    二聯捐贈: Get發票種類(發票聯別Enum.二聯, 發票類別Enum.捐贈),
    三聯紙本: Get發票種類(發票聯別Enum.三聯, 發票類別Enum.紙本),
    三聯交換: Get發票種類(發票聯別Enum.三聯, 發票類別Enum.交換)
}

export const Server發票聯別全部 = -1;
//發票聯別(同server設定)
export const Server發票聯別Enum = {
    二聯: 2,
    三聯: 3,
}

//手開發票狀態(同server設定)
export const 手開發票狀態全部 = -1;
export const 手開發票狀態Enum = {
    一般: 0,
    已註銷: 1,
    已作廢: 2,
    已折讓: 3
}
//稅別(同server設定)
export const 稅別Enum = {
    應稅: 1,
    零稅率: 2,
    免稅: 3,
}



//手開發票常用資料類別(同server設定)
export const 手開發票常用資料類別Enum = {
    統一編號: 1,
    愛心碼: 2,
    載具條碼: 3,
}


export const 時間條件Enum = {
    建立時間: 1,
    發票時間: 2
}

export const 匯入工具全部 = -1;
export const 匯入工具Enum = {
    // 全部:-1,
    是: true,
    否: false
}


//手開發票列印狀態(同server設定)
export const 手開發票列印狀態Enum = {
    未曾列印: 0,
    已列印: 1
}
//批次修改操作類型(同server設定)
export const 批次修改操作類型Enum = {
    匯入排程: 1,//server叫鎖定資料
    列印發票: 2//server叫設為已列印
}

export const InvoiceAPI = function (res) {
    if (res && res.data && res.data.sucess) {
        return res.data.message;
    } else {
        res = (res.data && res.data.errMsg) ? res.data.errMsg : res;
        throw res;
    }
}

import { Strategies, Mul } from "@/assets/js/Tool/Validator.js";
export const 四捨五入 = function (數量, 單價) {
    return Math.round(Mul(數量, 單價));
}
export const Get商品明細總計 = function (商品明細) {
    let sum = 0;
    商品明細.forEach((element) => {
        sum += 四捨五入(element.數量, element.單價);
    });
    return sum;
}
export const Get總計 = function (銷售額總計, 營業稅) {
    return 銷售額總計 + (parseInt(營業稅) >= 0 ? parseInt(營業稅) : 0);
}
export const 取得愛心碼檢查器 = function (validator, 愛心碼) {
    validator.add(愛心碼, [{
        strategy: Strategies.inNotEmpty,
        errorMsg: '愛心碼不為空'
    }, {
        strategy: Strategies.RegTest,
        errorMsg: '愛心碼需數字且介於3~7碼',
        arg: [new RegExp('^[0-9]{3,7}$')]//^\d{3,7}$
    }]);
}
export const 取得載具條碼檢查器 = function (validator, 載具條碼) {
    validator.add(載具條碼, [{
        strategy: Strategies.inNotEmpty,
        errorMsg: '載具條碼不為空'
    }, {
        strategy: Strategies.maxLength,
        errorMsg: '載具條碼不得大於16',
        arg: [16]
    }]);
}
export const 取得統一編號檢查器 = function (validator, 統一編號, 買方) {
    validator.add(統一編號, [{
        strategy: Strategies.inNotEmpty,
        errorMsg: '公司統編不為空'
    }, {
        strategy: Strategies.RegTest,
        errorMsg: '公司統編需數字8碼',
        arg: [new RegExp('^[0-9]{8}$')]
    }]);

    validator.add(買方, [{
        strategy: Strategies.inNotEmpty,
        errorMsg: '公司名稱不為空'
    }]);
}
export const 取得商品檢查器 = function (validator, 品名, 數量, 單價, 項目編號) {
    validator.add(品名, [{
        strategy: Strategies.inNotEmpty,
        errorMsg: `第${(項目編號 + 1)}項商品，品名不可為空`,
    }]);
    validator.add(品名, [{
        strategy: Strategies.maxLength,
        errorMsg: `第${(項目編號 + 1)}項商品，品名長度不可超過16個字`,
        arg: [16]
    }]);
    validator.add(數量, [{
        strategy: Strategies.NZeroPositiveInteger,
        errorMsg: `第${(項目編號 + 1)}項商品，數量輸入不正確`,
    }]);
    validator.add(單價, [{
        strategy: Strategies.CheckFloat,
        errorMsg: `第${(項目編號 + 1)}項商品，單價輸入不正確`,
        arg: [0, 3, false]//最小值0;浮點數3位;值不可等於0
    }]);
    return validator;
}
let 發票種類檢查器 = {};
發票種類檢查器[發票種類Enum.二聯捐贈] = (validator, query) => {
    取得愛心碼檢查器(validator, query.愛心碼);
    validator.add(query.載具條碼, [{
        strategy: Strategies.isEmpty,
        errorMsg: '載具條碼必須為空'
    }]);
    validator.add(query.統一編號, [{
        strategy: Strategies.isEmpty,
        errorMsg: '統一編號必須為空'
    }]);
    validator.add(query.買方, [{
        strategy: Strategies.isEmpty,
        errorMsg: '公司名稱必須為空'
    }]);
}
發票種類檢查器[發票種類Enum.二聯載具] = (validator, query) => {
    取得載具條碼檢查器(validator, query.載具條碼);
    validator.add(query.愛心碼, [{
        strategy: Strategies.isEmpty,
        errorMsg: '愛心碼必須為空'
    }]);
    validator.add(query.統一編號, [{
        strategy: Strategies.isEmpty,
        errorMsg: '統一編號必須為空'
    }]);
    validator.add(query.買方, [{
        strategy: Strategies.isEmpty,
        errorMsg: '公司名稱必須為空'
    }]);
}
發票種類檢查器[發票種類Enum.二聯紙本] = (validator, query) => {
    validator.add(query.統一編號, [{
        strategy: Strategies.isEmpty,
        errorMsg: '統一編號必須為空'
    }]);
    validator.add(query.買方, [{
        strategy: Strategies.isEmpty,
        errorMsg: '公司名稱必須為空'
    }]);
    validator.add(query.愛心碼, [{
        strategy: Strategies.isEmpty,
        errorMsg: '愛心碼必須為空'
    }]);
    validator.add(query.載具條碼, [{
        strategy: Strategies.isEmpty,
        errorMsg: '載具條碼必須為空'
    }]);
}
發票種類檢查器[發票種類Enum.三聯紙本] = (validator, query) => {
    取得統一編號檢查器(validator, query.統一編號, query.買方);
    // validator.add(query.統一編號, [{
    //     strategy: Strategies.isEmpty,
    //     errorMsg: '統一編號必須為空'
    // }]);
    // validator.add(query.買方, [{
    //     strategy: Strategies.isEmpty,
    //     errorMsg: '公司名稱必須為空'
    // }]);
    validator.add(query.愛心碼, [{
        strategy: Strategies.isEmpty,
        errorMsg: '愛心碼必須為空'
    }]);
    validator.add(query.載具條碼, [{
        strategy: Strategies.isEmpty,
        errorMsg: '載具條碼必須為空'
    }]);
}
發票種類檢查器[發票種類Enum.三聯交換] = 發票種類檢查器[發票種類Enum.三聯紙本];
export const 取得發票單筆資料檢查器 = function (validator, query) {
    validator.add(query.發票日期, [{
        strategy: function (val) {
            return moment(val, "YYYY-MM-DD").format("YYYY-MM-DD") === val + "";
        },
        errorMsg: '發票開立時間輸入錯誤',
    }]);
    let 發票種類 = Get發票種類(query.發票聯別, query.發票類別);
    validator.add(發票種類, [{
        strategy: function (val) {
            for (let k in 發票種類Enum) {
                if (發票種類Enum[k] == val) {
                    return true;
                }
            }
            return false;
        },
        errorMsg: '發票種類輸入錯誤!'
    }]);


    if (發票種類檢查器[發票種類]) {
        發票種類檢查器[發票種類](validator, query);
    } else {
        //
        validator.add(發票種類, [{
            strategy: function (val) {
                return false;
            },
            errorMsg: '發票種類輸入錯誤!(請RD加該檢查種類)'
        }]);
    }

    validator.add(query.備註, [{
        strategy: Strategies.maxLength,
        errorMsg: '備註長度不可超過70',
        arg: [70]
    }]);

    validator.add(query.明細, [{
        strategy: function (val) {
            return (val && val.length > 0);
        },
        errorMsg: '至少輸入一項商品',
    }]);
    for (let i = 0; i < query.明細.length; i++) {
        let 項目 = query.明細[i];
        let 項目編號 = i;
        取得商品檢查器(validator, 項目.品名, 項目.數量, 項目.單價, 項目編號);
    }
    validator.add(query.稅額, [{
        strategy: Strategies.ZeroPositiveInteger,
        errorMsg: `稅額輸入不正確`,
    }]);
    return validator;
}