<template>
  <div>
    <highcharts ref="highcharts" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: [
    "multiple",
    "datas",
    "chartTitle",
    "year",
    "hasPhoto",
    "totalTickets",
    "onlyNum",
    "isSeat"
  ],
  data() {
    return {
      chartOptions: {},
    };
  },
  methods: {
    switchBadgeColor(name) {
      return this.$parent.switchBadgeColor(name);
    },
    setChartOptions() {
      const hasPhoto = this.hasPhoto;
      const multiple = this.multiple;
      const chartTitle = this.chartTitle;
      const isSeat = this.isSeat;
      this.chartOptions = {
        chart: {
          width: 700,
          height: hasPhoto ? 500 : "auto",
          // height: "auto",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          marginTop: 60,
          paddingBottom: multiple ? 0 : 200,
        },
        title: {
          text: chartTitle,
          style: {
            fontSize: "30px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        subtitle:
          hasPhoto || multiple
            ? null
            : {
                text: "本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。",
                style: {
                  fontSize: "15px",
                  fontFamily: "Microsoft JhengHei",
                },
                verticalAlign: "bottom",
                y: 30,
                enabled: true,
              },
        tooltip: {
          outside: true,
          formatter: function () {
            return (
              isSeat ? 
              "<b>" +
              this.key +
              "</b>" +
              "： " +
              this.point.seat +
              "席 (" +
              this.point.rate +
              "%)" :
              "<b>" +
              this.key +
              "</b>" +
              "： " +
              this.point.tickets +
              "票 (" +
              this.point.rate +
              "%)"
            );
          },
          style: {
            fontSize: "15px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              distance: hasPhoto ? 20 : -50,
              connectorPadding: 10,
              allowOverlap: false,
              formatter: function () {
                return isSeat ? this.point.seat : this.point.rate + "%";
              },
              style: {
                color: "black",
                fontSize: "15px",
                fontFamily: "Microsoft JhengHei",
              },
            },
            showInLegend: true,
          },
        },
        legend: {
          itemStyle: {
            fontSize: "18px",
            font: "18px Microsoft JhengHei",
          },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "downloadJPEG",
                "downloadPNG",
                "downloadSVG",
                "downloadPDF",
              ],
            },
          },
          enabled: false,
        },
        series: [],
      };
    },
    setSeriesDataList(datas) {
      let rate = 100
      let other_index = -1
      console.log(datas);
      datas.forEach((item, index) => {
        item.name = item.category != "總統" && this.$parent.category != "縣市長" ? `${item.name}(${item.seat}席)` : item.name;
        if(item.badge != "無黨籍、其他") rate-=item.rate
          else other_index = index
      })
      if(other_index != -1) datas[other_index].rate = rate.toFixed(2);
      this.setChartOptions();

      let subDataList = [
        {
          type: "pie",
          size: 250,
          name: this.multiple ? this.chartTitle : this.year,
          showInLegend: true,
          data: datas,
        },
      ];
      if (this.hasPhoto) {
        subDataList[0].dataLabels = {
          enabled: true,
          useHTML: true,
          formatter: function () {
            const hasPic = this.point.image;
            const hasPicClass = !hasPic ? "no-pic" : "";
            let img = hasPic
              ? '<div class="pic imgFill"><img src="' +
                this.point.image +
                '" /></div><div class="text">' +
                this.point.candidate +
                "<br />"
              : "<div class='text'>";
            return (
              "<div class='" +
              hasPicClass +
              "'>" +
              img +
              this.point.tickets +
              "票<br />" +
              this.point.rate +
              "%" +
              "</div></div>"
            );
          },
          style: {
            fontSize: "15px",
            fontFamily: "Microsoft JhengHei",
          },
        };
      }
      this.chartOptions.series = [];
      this.chartOptions.series = subDataList;
      // this.setChartHeight();
    },
    // setChartHeight() {
    //   const chart = this.$refs.highcharts.chart;
    //   var box = chart.plotBox;
    //   chart.setSize(600, box.height + 100);
    //   chart.hasUserSize = null;
    // },
  },
};
</script>
