<template>
    <div class="mymodal" ref="BatchForm">
        <div style="width:600px;margin-top: 30px;margin-left: auto;margin-right: auto;">
            <div class="box box-info">
                <div class="box-header with-border">
                    <h3 class="box-title">批次處理</h3>
                    <button type="button" class="close" @click="close()"><span aria-hidden="true">×</span></button>
                </div>
                <form class="form-horizontal">
                    <div class="box-body">
                        <div style="max-height: 300px; overflow: scroll;">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>訂單編號</th>
                                        <th>儲值角色</th>
                                        <th>金額</th>
                                        <th>處理狀態</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item of batchList" :key="item.訂單編號">
                                        <td style="white-space:nowrap;">{{ item.訂單編號 }}</td>
                                        <td style="white-space:nowrap;">{{ item.儲值角色 }}</td>
                                        <td style="white-space:nowrap;">{{ item.金額 }}</td>
                                        <td style="white-space:nowrap;">{{ item.log }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <template>
                            <div class="form-group">
                                <label for="inputEmail3" class="col-sm-2 control-label">是否繳款：</label>

                                <div class="col-sm-10" style="padding-top:7px;" v-if="是否全部未處理">
                                    <div class="state iradio_flat-blue" :class="edit.是否繳款 ? '' : 'checked'"
                                        @click="edit.是否繳款 = false">
                                    </div>否
                                    <div class="state iradio_flat-blue" :class="edit.是否繳款 ? 'checked' : ''"
                                        @click="edit.是否繳款 = true">
                                    </div>是
                                </div>
                                <template v-else-if="edit.是否繳款">
                                    <label class="control-label"> 是</label>
                                </template>
                                <template v-else>
                                    <label class="control-label"> 否</label>
                                </template>

                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">凍結日期：</label>
                                <template v-if="是否全部未處理">
                                    <DateTimePicker v-bind:propDateTime.sync="edit.凍結日期"></DateTimePicker>
                                </template>
                                <template v-else>
                                    <label class="control-label"> {{ edit.凍結日期 }}</label>
                                </template>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">繳費時間：</label>

                                <template v-if="是否全部未處理">
                                    <DateTimePicker v-bind:propDateTime.sync="edit.繳費時間"></DateTimePicker>
                                </template>
                                <template v-else>
                                    <label class="control-label"> {{ edit.繳費時間 }}</label>
                                </template>

                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">備註：</label>
                                <template v-if="是否全部未處理">
                                    <div class="col-sm-10">
                                        <textarea class="form-control" v-model.trim="edit.備註" placeholder=""></textarea>
                                    </div>
                                </template>
                                <template v-else>
                                    <label class="control-label"> {{ edit.備註 }}</label>
                                </template>
                            </div>
                        </template>
                    </div>

                    <div class="box-footer">
                        <button type="button" class="btn btn-info pull-right" v-if="是否全部未處理" @click="save()">確認送出</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import DateTimePicker from "@/components/DateTimePicker";
export default {
    components: {
        DateTimePicker,
    },
    data() {
        return {
            batchList: [],
            edit: {
                是否繳款: true,
                凍結日期: null,
                繳費時間: null,
                備註: "",
            },
        };
    },
    computed: {
        是否全部未處理() {
            let flag = true;
            for (let i of this.batchList) {
                if (i.st != 0) {
                    flag = false; break;
                }
            }
            return flag;
        },
        是否有處理中的項目() {
            let flag = false;
            for (let i of this.batchList) {
                if (i.st == 1) {
                    flag = true; break;
                }
            }
            return flag;
        },
        是否全部處理完畢() {
            let flag = true;
            for (let i of this.batchList) {
                if (i.st != 2) {
                    flag = false; break;
                }
            }
            return flag;
        },
    },
    watch: {
    },
    created() { },
    mounted() {
        $(this.$refs.BatchForm).hide();
    },
    methods: {
        open(batchList) {
            this.batchList = batchList;
            for (let i of this.batchList) {
                i.st = 0;
                i.log = "";
            }
            this.edit.是否繳款 = true;
            this.edit.凍結日期 = null;
            this.edit.繳費時間 = null;
            this.edit.備註 = "";

            $(this.$refs.BatchForm).show();
        },
        close() {
            if (this.是否有處理中的項目) {
                this.$eventBus.$emit("showAlert", "資料處理中，請勿關閉");
                return;
            }
            $(this.$refs.BatchForm).hide();
        },
        async save() {
            let isError = false;
            for (let i of this.batchList) {
                i.st = 1;//狀態改成處理中
                let cmd = { ...this.edit };
                cmd.是否繳款 = this.edit.是否繳款 ? 1 : 0;
                cmd.訂單編號 = i.訂單編號;
                try {
                    let res = await this.$store.dispatch("ipsPaymentOwed/updateAsync", cmd)
                    if (res.data.status == "1") {
                        i.log = "成功";
                    } else {
                        i.log = "失敗:" + res.data.message;
                        isError = true;
                    }
                } catch (e) {
                    i.log = e;
                    isError = true;
                }
                i.st = 2;//狀態改成完成
            }
            this.$emit('refreshList');
            isError ? this.$eventBus.$emit("showAlert", "執行結果有誤，請檢查處理狀態") : this.$eventBus.$emit("showAlert", "執行成功")
        }
    },
};
</script>
<style scoped>
thead tr th {
    text-align: left;
    position: sticky;
    background-color: white;
    top: 0;
}

.mymodal {
    transition: opacity .15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;

    z-index: 1040;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
}

.iradio_flat-blue {
    margin-left: 0px;
}

.iradio_flat-blue[checked] {
    background-position: -110px 0;
}
</style>