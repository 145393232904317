/* eslint-disable */
import api from '@/assets/js/app-api';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    // query({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         api.nmjAnnouncement.query(data).then(res => {
    //             if (res.data.status === '1') {
    //                 resolve(res.data.result);
    //             }
    //         });
    //     });
    // },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjHistory.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    resolve(result);
                } else reject("查無資料")
            });
        });
    },
    getTypes({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjHistory.getTypes(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    }
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}