<template>
  <!--公開序號產生-->
  <div class="modal fade" ref="publicSerialNumber">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">公開序號產生</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>序號</td>
                  <td>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model.trim="edit.assignSN"
                    />
                  </td>
                </tr>
                <tr>
                  <td>使用次數</td>
                  <td>
                    <input
                      class="form-control"
                      min="1"
                      required="required"
                      type="number"
                      v-model.number="edit.snUseCount"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            type="button"
            @click="UpdateSerialNumber()"
          >
            產生序號
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      edit: {
        assignSN: "",
        snUseCount: 1,
      },
    };
  },
  computed: {
    ...mapState({
      award: (state) => state.award.query,
    }),
  },
  methods: {
    showSerialNumberEdit(award) {
      if (!award || !award.awardsId) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }
      this.GetAwardQuery(award.awardsId);
      this.edit.assignSN = "";
      this.edit.snUseCount = 1;
      $(this.$refs.publicSerialNumber).modal("show");
    },
    GetAwardQuery(awardsId) {
      this.$store.dispatch("award/awardQuery", {
        awardsId: awardsId,
      });
    },
    UpdateSerialNumber() {
      if (!this.edit.assignSN) {
        this.$eventBus.$emit("showAlert", "序號不可為空值!");
        return;
      }
      if (this.edit.snUseCount <= 0) {
        this.$eventBus.$emit("showAlert", "使用次數不可小於0!");
        return;
      }
      this.$store.dispatch("exchangeCode/exchangeCodeCreate", {
        ruleType: this.award.ruleType,
        awardsId: this.award.awardsId,
        assignSN: this.edit.assignSN,
        snUseCount: this.edit.snUseCount,
      });
    },
  },
};
</script>