<template>
  <div id="CustomerServiceEdit">
    <section class="content-header">
      <h1>{{ type }}</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/NMJ/CustomerService">客服處理</router-link></li>
        <li class="active">{{ type }}</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form class="form-horizontal" name="customerServiceForm">
            <div class="box-body">
              <div class="table-responsive mt-10">
                <table v-show="type === '玩家回報'" class="table table-bordered table-striped min-600">
                  <tbody>
                    <tr class="bg-gray">
                      <td colspan="4">玩家基本資料</td>
                    </tr>
                    <tr>
                      <td style="width:15%" class="text-nowrap">玩家暱稱</td>
                      <td style="width:35%"><router-link :to="{name:'NmjPlayerData', query:{nick: data.reactionNickname}}">{{ data.reactionNickname}}</router-link></td>
                      <td style="width:15%" class="text-nowrap">綁定門號</td>
                      <td style="width:35%">{{ userData.account ? userData.account : "-" }}</td>
                    </tr>
                    <tr>
                      <td class="text-nowrap">回報時間</td>
                      <td>{{ uiDate(data.reactionTime) }}</td>
                      <td class="text-nowrap">處理進度</td>
                      <td>{{ def.serviceState[data.status]  }}</td>
                      <!-- <td class="text-nowrap">登入方式</td>
                      <td>{{ data.platform }}</td> -->
                      <!-- <td>網路環境</td><td>{{ data.network }}</td> -->
                    </tr>
                    <tr>
                      <td class="text-nowrap">問題類別</td>
                      <td>{{ data.reactionType }}</td>
                      <td class="text-nowrap">遊戲裝置</td>
                      <td>{{ data.phoneModel }}</td>
                    </tr>
                    <tr>
                      <td class="text-nowrap">遊戲版本</td>
                      <td>{{ data.appVersion }}</td>
                      <td class="text-nowrap">裝置系統版本</td>
                      <td>{{ data.systemVersion }}</td>
                    </tr>
                    <tr>
                      <td class="text-nowrap">玩家Email</td>
                      <td colspan="3">
                        <a :href="`mailto:${data.email}`">{{ data.email }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td class="bg-gray" colspan="4">玩家問題內容</td>
                    </tr>
                    <tr>
                      <td colspan="4">{{ data.reactionNote }}</td>
                    </tr>
                    <tr v-if="images.length > 0">
                      <td colspan="4">
                        <div style="display:flex; flex-wrap:wrap; justify-content:flex-start;">
                          <a v-for="img in images" :href="img" target="_blank" rel="noopener nofollow"
                            style="display:inline-block; margin:.25em; cursor:zoom-in; flex:0 1 auto;">
                            <img :src="img" alt="" style="max-height:8em; max-width:100%; height:auto;">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table v-show="type === '檢舉'" class="table table-bordered table-striped min-600">
                  <tbody>
                    <tr class="bg-gray">
                      <td colspan="4">玩家檢舉基本資料</td>
                    </tr>
                    <tr>
                      <td style="width:15%" class="text-nowrap">玩家暱稱</td>
                      <td style="width:35%"><router-link :to="{name:'NmjPlayerData', query:{nick: data.reactionNickname}}">{{ data.reactionNickname}}</router-link></td>
                      <td style="width:15%" class="text-nowrap">被檢舉玩家暱稱</td>
                      <td style="width:35%"><router-link :to="{name:'NmjPlayerData', query:{nick: data.sufferUId}}">{{ data.sufferUId}}</router-link></td>
                    </tr>
                    <tr>
                      <td class="text-nowrap">回報時間</td>
                      <td>{{ uiDate(data.reactionTime) }}</td>
                      <td class="text-nowrap">檢舉類別</td>
                      <td>{{ data.reactionType }}</td>
                    </tr>
                    <tr>
                      <td class="text-nowrap">聯絡信箱</td>
                      <td colspan="1">
                        <a :href="`mailto:${data.email}`">
                          {{ data.email }}
                        </a>
                      </td>
                      <td class="text-nowrap">處理進度</td>
                      <td>{{ def.serviceState[data.status]  }}</td>
                    </tr>
                    <tr>
                      <td class="bg-gray" colspan="4">玩家檢舉內容</td>
                    </tr>
                    <tr>
                      <td colspan="4">{{ data.reactionNote }}</td>
                    </tr>
                    <tr v-if="images.length > 0">
                      <td colspan="4">
                        <div style="display:flex; flex-wrap:wrap; justify-content:flex-start;">
                          <a v-for="img in images" :href="img" target="_blank" rel="noopener nofollow"
                            style="display:inline-block; margin:.25em; cursor:zoom-in; flex:0 1 auto;">
                            <img :src="img" alt="" style="max-height:8em; max-width:100%; height:auto;">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive mt-30" v-if="data.status !== 3">
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr class="bg-gray">
                      <td colspan="4">客服回饋</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <div class="editor">
                          <div v-if="data.status === 2" v-html="data.replyNote"></div>
                          <tinymce v-else v-model="data.replyNote" :propLang="editLang" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          <div class="box-footer">
            <div class="btns" v-if="data.status < 2">
              <button type="button" @click="save(1)" v-if="type!='檢舉'" class="btn btn-warning btn-lg" style="margin-right: 10px">
                儲存
              </button>
              <button type="button" @click="save(3)" v-else class="btn btn-danger btn-lg" style="margin-right: 10px">
                不需回覆
              </button>
              <button type="submit" @click="save(2)" class="btn btn-primary btn-lg">
                確認送出
              </button>
            </div>
          </div>
          <!-- <pre>
            {{ JSON.stringify(data, null, 2) }}
            {{ JSON.stringify(userData, null, 2) }}
          </pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    tinymce,
  },
  props: {
    id: "",
    type: "",
  },
  data() {
    return {
      def: define,
      timer: null,
      editLang: "zh-tw",
      param: {},
      data: {},
      userData: {},
    };
  },
  computed:{
    images: function(){
      return this.data.fileUrl ? this.data.fileUrl.split(',') : [];
    }
  },
  mounted() {
    this.checkSession();
    this.getService();
  },
  methods: {
    getService() {
      this.param.reactionId = this.id;
      this.$store.dispatch("nmjService/query", this.param).then((result) => {
        this.data = result;
        this.$store.dispatch("nmjPlayerData/query", {nickName: result.reactionNickname, phoneNumber:""}).then((user) => {
          this.userData = user[0] || {};
        });
      });
    },
    checkSession() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.$store.dispatch("checkSession");
      }, 600000);
    },
    backList() {
      this.$router.push({
        name: "NmjCustomerService",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    save(status) {
      if (this.data.replyNote || status == 3) {
        this.data.status = status;
        this.$store.dispatch("nmjService/update", this.data).then(() => {
          this.backList();
        });
      } else {
        this.$eventBus.$emit("showAlert", "客服回饋不得為空！");
      }
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>
