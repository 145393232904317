<template>
  <div id="BonusAndRefundList">
    <section class="content-header">
      <h1>配點紀錄列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">配點紀錄列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">

          <div class="top-box topBox-activityManger">
            <button @click="importSN()" class="btn btn-block btn-success btn-cog w100-md" type="button">
              <i class="fa fa-file"></i> 匯入補點帳號
            </button>
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增配發帳號
            </button>
            <div class="form-group clearfix">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.startTime" />
              </div>
            </div>

            <div class="form-group clearfix">
              <label class="control-label">結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.endTime" />
              </div>
            </div>

            <div class="input-group search-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋遊戲帳號"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ bonusAndRefund.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 10%">項次</th>
                  <th style="min-width: 90px; width: 20%">遊戲帳號</th>
                  <th style="min-width: 90px; width: 10%">配發點數</th>
                  <th class="text-nowrap" style="width: 20%;">配發原因</th>
                  <th class="text-nowrap" style="width: 10%;">配發類型</th>
                  <th class="text-nowrap" style="width: 10%;">操作者</th>
                  <th class="text-nowrap" style="width: 20%;">操作時間</th>
                </tr>
                <template v-if="bonusAndRefund.total > 0">
                  <tr :key="item.id" v-for="item in bonusAndRefund.datas">
                    <td align="center">{{ item.rowid }}</td>
                    <td align="center">{{ item.molokey }}</td>
                    <td align="center">{{ item.pointAmount }}</td>
                    <td align="center">{{ item.note }}</td>
                    <td align="center">{{ item.pointAmount > 0? '補點':'扣點' }}</td>
                    <td align="center">{{ item.createUName }}</td>
                    <td align="center">{{ item.createTime }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="7">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="bonusAndRefund.total > 0">
          <Pagination
            ref="pagination"
            :total="bonusAndRefund.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
      <bonusAndRefundImport ref="bonusAndRefundImport" v-on:refreshList="GetBonusAndRefundQueryAll" />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import DatePicker from "@/components/DatePicker";
import bonusAndRefundImport from "@/views/BonusAndRefundImport";
// import utils from "@/assets/js/app-utils";
// import config from "@/assets/js/app-config";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination,
    DateRangePicker,
    DatePicker: DatePicker,
    bonusAndRefundImport
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數

      searchData: {
        keyword: "",
        pageNum: 1,
        // addOrDeduct: 0,
        startTime: "",
        endTime: "",
        pageSize: 10 //每頁顯示筆數
      }
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.GetBonusAndRefundQueryAll();
  },
  computed: {
    ...mapState({
      bonusAndRefund: state => state.bonusAndRefund.queryAll,
      response: state => state.bonusAndRefund.response
    })
  },
  watch: {
    bonusAndRefund() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.bonusAndRefund.total
      );
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetBonusAndRefundQueryAll();
    },

    GetBonusAndRefundQueryAll() {
      //列表
      this.$store.dispatch("bonusAndRefund/bonusAndRefundQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined) {
        this.searchData = this.$route.params.searchData;
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
      }
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetBonusAndRefundQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "BonusAndRefundEdit",
        params: { searchData: this.searchData }
      });
      return;
    },

    importSN() {
      this.$refs.bonusAndRefundImport.showSerialNumberEdit();
      this.GetBonusAndRefundQueryAll();
    },

    // //*---- 修改內容
    // editorItem(item) {
    //   this.$router.push({
    //     name: "BonusAndRefundEdit",
    //     params: { id: item.activityId, searchData: this.searchData }
    //   });
    //   return;
    // },

    checkTime(item) {
      return new moment(new Date()).isAfter(item.startTime);
    }
  }
};
</script>

<style scoped>
.top-box .form-group {
  vertical-align: top;
  margin: 0 15px 5px 0;
}

.top-box .form-group .control-label {
  padding-top: 7px;
  float: left;
}

.top-box .input-group {
  min-width: 250px;
}
</style>
