<template>
	<div id="clientGameContent" class="slot max1369">
		<section class="content">
			<div class="box box-primary" style="background: none;">
        <div class="box-body">
          <div class="g_back" @click="goBack()">
            <div>
              <i class="fa fa-chevron-left"></i>
            </div>
            <div>
              <p>{{ query.遊戲名稱 }} 遊戲介紹</p>
              <p>{{ gameQuery.開發商名稱 }}</p>
            </div>
          </div>
          <div class="top-box">
            <button class="btn btn-default" @click="showPlay()">
              <i class="fa fa-book"></i>
              所有玩法定義
            </button>
            <div class="photo" @click="showImg()">
              <img :src="query.圖示" alt="">
            </div>
            <div class="info">
              <div class="name">{{ query.遊戲館名稱 }}</div>
              <div class="tag-list" v-if="query.標籤集合">
                <router-link
                  :to="`/slot/searchTag/${tag.編號}`"
                  v-for="(tag, index) in query.標籤集合"
                  class="tag btn btn-success mr-5"
                  >
                  {{ tag.標籤名稱 }}
                </router-link>
              </div>
              <div class="play-list">
                <p>適用玩法定義：</p>
                <button
                  class="btn btn-default mr-10"
                  v-for="(play, index) in query.玩法集合"
                  @click="showPlay(play.編號)"
                >
                  {{ play.玩法名稱 }}
                </button>
              </div>
              <a :href="query.影片連結" v-if="query.影片連結" target="_blank" class="btn btn-primary mt-10">影片連結</a>
            </div>
          </div>

          <!-- <Empty v-if="total == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" /> -->
          <div class="g-list table-responsive mt-20" style="padding-bottom: 0;">
            <div
              v-for="(item, index) in list"
              class="g-card"
              style="cursor: pointer;"
              @click="showReportAlert(item.編號)"
            >
              <div class="content">
                <div class="g-name">{{ item.名稱 }}</div>

                <template v-for="(media, index) in item.預覽視覺集合">
                  <div class="g-photo" v-if="media.視覺型態 == '1' && media.是否啟用 == 1">
                    <img :src="media.視覺路徑" alt="">
                  </div>

                  <div class="g-photo" v-if="media.視覺型態 == '2' && media.是否啟用 == 1">
                    <iframe
                      :src="`https://www.youtube.com/embed/${media.視覺路徑.split('youtu.be/')[1]}`"
                      title="YouTube video player"
                      frameborder="0"
                      allow="acc:elerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </template>

                <p>{{ item.預覽說明 }}</p>
              </div>
            </div>
          </div>
          <div class="loading-box" v-show="loading">
            <div class="loading"></div>
          </div>
				</div>
			</div>
		</section>
    <slotPlayContent ref="slotPlayContent"></slotPlayContent>

    <div class="modal fade" id="imgAlert" ref="imgAlert">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <img :src="query.圖示" alt="">
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <div class="modal fade" id="detailAlert" ref="detailAlert" v-if="total != 0">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-center">{{ content.名稱 }}</h4>
          </div>
          <div class="modal-body">
            <p v-html="content.詳細說明"></p>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="cardLoading" v-show="cardLoading">
      <span>讀取中...</span>
    </div>
	</div>
</template>

<script>
/* eslint-disable */
// import api from "../assets/js/app-api";
import { mapState } from "vuex";
import slotPlayContent from "@/components/slot/slotPlayContent";
import Empty from "@/components/SlotEmpty";

export default {
  components: {
    slotPlayContent,
    Empty
  },
  data() {
    return {
      loading: false,
      cardLoading: false,
      dropdown: 'none',
      searchData: {
        遊戲館編號: Number(this.$route.params.id),
        PageNum: 1,
        PageSize: 6,
      },
      list: [],
      isEnd: false,
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.$store.dispatch("slot/gameQuery", { 編號: Number(this.$route.params.gameId) })
    this.$store.dispatch("slot/gameHallQuery", { 編號: Number(this.searchData.遊戲館編號) })
    this.getDataList();
  },
  computed: {
    ...mapState({
      gameQuery: state => state.slot.gameQuery,
      query: state => state.slot.gameHallQuery,
      gameHallList: state => state.slot.hallContentQueryAll.datas,
      total: state => state.slot.hallContentQueryAll.total,
      content: state => state.slot.hallContentQuery,
    }),
    totolPage() {
      return Math.ceil(this.total / this.searchData.PageSize)
    }
  },
  mounted() {
    $(window).on("scroll", () => {
      this.scrollEvent();
    })
  },
  watch: {
    gameHallList() {
      this.list = this.list.concat(this.gameHallList);
      this.$forceUpdate();
    }
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.isEnd = false;
      this.$store.dispatch("slot/hallContentQueryAll", this.searchData)
        .then((res) => {
          this.loading = false;
          if(this.totolPage == this.searchData.PageSize) this.isEnd = true;
        })
    },
    // resetData(order = 1) {
    //   this.searchData.PageNum = 1;
    //   this.searchData.排序順序 = order;
    //   this.dropdown = "none";
    //   this.getDataList();
    // },
    goBack() {
      if(this.$route.params.back == 'slotSearch') {
        this.$router.push({
          name: this.$route.params.back,
          params: {
            keyword: this.$route.params.search,
            searchData: this.$route.params.backSearchData,
          }
        });
      } else {
        this.$router.push({
          name: this.$route.params.back,
          params: {
            id: this.$route.params.back == 'slotSearchTag' ? 
            this.$route.params.search : this.$route.params.gameId,
            searchData: this.$route.params.backSearchData,
          }
        });
      }
    },
    showImg() {
      $(this.$refs.imgAlert).modal('show');
    },
    showPlay(id) {
      if(id) this.$refs.slotPlayContent.getData(id);
      else this.$refs.slotPlayContent.showContent = true;
    },
    showReportAlert(id) {
      this.cardLoading = true;
      this.$store.dispatch("slot/hallContentQuery", { 編號: id })
        .then((res) => {
          $(this.$refs.detailAlert).modal('show');
          this.cardLoading = false;
        })
    },
    scrollEvent() {
      if(this.isEnd) return
      let sectionTop = $(window).scrollTop();
      let sectionH = $(window).height();
      let documentH = $(".loading-box").offset().top;

      if(!this.loading) {
        if(this.searchData.PageNum < this.totolPage) {
          if(sectionTop + sectionH >= documentH + 50) {
            this.loading = true;
            this.searchData.PageNum = this.searchData.PageNum + 1;
            setTimeout(() => {
              this.getDataList();
            }, 500);
          }
        }
        if(this.searchData.PageNum == this.totolPage) {
          this.isEnd = true;
        }
      }
    }
  },
  beforeDestroy() {
    $(window).off("scroll");
  },
};
</script>