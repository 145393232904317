import api from "@/assets/js/app-api";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    awardQueryAll({ commit }, data) {
        api.award.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setAwardQueryAll', res.data.result);
            }
        })
    },
    awardQuery({ commit }, data) {
        api.award.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setAwardQuery', res.data.result);
            }
        })
    },
    awardCreate({ commit }, data) {
        api.award.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    awardUpdate({ commit }, data) {
        api.award.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    awardDelete({ commit }, data) {
        api.exchangeCode.delete(data).then(response => {
            if (response.data.status == "1") {
                api.award.delete(data).then(res => {
                    commit('setResponse', res.data);
                    if (response.data.status == "1") this._vm.$eventBus.$emit("showAlert", "成功!!");
                    else {
                        this._vm.$eventBus.$emit("showAlert", "失敗!!");
                    }
                })
            } else {
                // commit('setResponse', res.data);
                this._vm.$eventBus.$emit("showAlert", "失敗!!");
            }
        })

    },
}

// mutations
const mutations = {
    setAwardQueryAll(state, awardQueryAll) {
        state.queryAll = awardQueryAll;
    },
    setAwardQuery(state, awardQuery) {
        state.query = awardQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}