<template>
  <div>
    <highcharts ref="highcharts" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["datas"],
  data() {
    return {
      chartOptions: {
        chart: {
          width: 1000,
          height: "auto",
          type: "bar",
          backgroundColor: "rgba(0,0,0,0)",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "本表數據由Newtalk新聞統計，最終選舉結果請查閱中選會官方資料。",
          style: {
            fontSize: "15px",
            fontFamily: "Microsoft JhengHei",
          },
          verticalAlign: "bottom",
          enabled: true,
        },
        xAxis: {
          type: "category",
          crosshair: true,
          labels: {
            rotation: 0,
            style: {
              fontWeight: "normal",
              fontSize: "17px",
              fontFamily: "Microsoft JhengHei",
            },
          },
        },
        yAxis: {
          type: "logarithmic",
          gridLineDashStyle: "longdash",
          crosshair: true,
          title: {
            text: "票（張）",
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            grouping: false,
            shadow: false,
            borderWidth: 2,
            borderColor: "#e1e1e1",
          },
        },
        credits: { enabled: false },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "downloadJPEG",
                "downloadPNG",
                "downloadSVG",
                "downloadPDF",
              ],
            },
          },
          enabled: false,
        },
        series: [{ data: [] }],
      },
    };
  },
  methods: {
    switchBadgeColor(name) {
      return this.$parent.switchBadgeColor(name);
    },
    setSeriesDataList(datas) {
      let subDataList = datas;
      let dataLabels = {
        enabled: true,
        rotation: 0,
        align: "center",
        format: "{point.tickets}",
        y: 0,
        x: 25,
        style: {
          fontSize: "13px",
          fontFamily: "Microsoft JhengHei",
          textShadow: "0 0 0px #000",
        },
      };
      subDataList.forEach((item, index) => {
        item.dataLabels = dataLabels;
      });
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[0].data = subDataList;
    },
    // setChartHeight() {
    //   const chart = this.$refs.highcharts.chart;
    //   var h = $("#cityChart").height(),
    //     box = chart.plotBox,
    //     len = chart.series[0].data.length;
    //   chart.setSize(800, box + len * 50);
    //   chart.hasUserSize = null;
    // },
  },
};
</script>
