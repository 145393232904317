<template>
  <div class="modal fade" ref="playerSettingContent">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            {{ this.item.blockId ? "編輯" : "新增" }}內容
          </h4>
        </div>
        <form
          class="form-horizontal"
          name="playerSettingForm"
          method="post"
          @submit.prevent="submit"
        >
          <div class="modal-body">
            <div class="table-responsive mt-10">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td width="90px">控管對象</td>
                    <td>
                      <input
                        class="form-control"
                        style="width: 66%"
                        type="text"
                        required
                        v-model.trim="item.playUId"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>控管原因</td>
                    <td>
                      <input
                        class="form-control"
                        style="width: 66%"
                        type="text"
                        placeholder="選填"
                        v-model.trim="item.note"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>控管類型</td>
                    <td>
                      <iCheckRedioButton
                        checkValue="1"
                        message="停權"
                        name="blockType"
                        style="margin-left: 20px"
                        v-model="item.blockType"
                      />
                      <iCheckRedioButton
                        checkValue="2"
                        message="禁言"
                        name="blockType"
                        style="margin-left: 50px"
                        v-model="item.blockType"
                      />
                      <iCheckRedioButton
                        class="hide"
                        checkValue="3"
                        message="解除"
                        name="blockType"
                        style="margin-left: 50px"
                        v-model="item.blockType"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>控管期限</td>
                    <td>
                      <iCheckRedioButton
                        checkValue="1"
                        message="永久"
                        name="timeType"
                        style="margin-left: 20px"
                        v-model.number="item.timeType"
                      />
                      <iCheckRedioButton
                        checkValue="2"
                        message="限時"
                        name="timeType"
                        style="margin-left: 50px"
                        v-model.number="item.timeType"
                      />
                      <iCheckRedioButton
                        class="hide"
                        checkValue="0"
                        message="無"
                        name="timeType"
                        style="margin-left: 50px"
                        v-model.number="item.timeType"
                      />
                      <div class="radio-date" style="float: right">
                        <div class="input-group date">
                          <div class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                          </div>
                          <div class="pull-right">
                            <DatePicker :propValue.sync="endTime" />
                          </div>
                        </div>
                        <div class="input-group time">
                          <div class="input-group-addon">
                            <i class="fa fa-clock-o"></i>
                          </div>
                          <input
                            class="form-control timepicker"
                            type="text"
                            id="endTimeOnly"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">確認送出</button>
            <button
              class="btn btn-default pull-right"
              data-dismiss="modal"
              type="button"
            >
              關閉
            </button>
          </div>
        </form>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import iCheckRedioButton from "@/components/iCheckRedioButton";

export default {
  components: {
    DatePicker,
    iCheckRedioButton,
  },
  data() {
    return {
      endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      item: {},
    };
  },
  methods: {
    async showContent(item) {
      this.item = item
        ? JSON.parse(JSON.stringify(item))
        : {
            blockType: "1",
            timeType: 1,
          };
      this.updateTime();
      $(this.$refs.playerSettingContent).modal("show");
    },
    updateTime() {
      $(".timepicker").timepicker({
        showInputs: false,
      });
      $(".timepicker").unbind("focus");
      if (this.item.timeType === 1) {
        this.item.blockEndTime = new Date();
      }
      this.endTime = this.item.blockEndTime || this.endTime;
      $("#endTimeOnly").val(this.global.getTime(this.endTime));
    },
    submit() {
      const endTimeOnly = $("#endTimeOnly").val();
      this.endTime = new Date(`${this.endTime.split(" ")[0]} ${endTimeOnly}`);
      if (this.item.timeType === 1) {
        this.item.blockEndTime = new Date(2200, 1, 1);
      } else if (this.item.timeType === 2) {
        if (endTimeOnly === "" || !this.global.isValidDay(this.endTime)) {
          this.$eventBus.$emit("showAlert", "請填寫控管期限！");
          return;
        }
        if (!this.global.isValidFutureDay(this.endTime)) {
          this.$eventBus.$emit("showAlert", "「控管期限」請勿填寫過去時間！");
          return;
        }
        this.item.blockEndTime = new Date(`${this.endTime} UTC`);
      } else {
        this.item.blockEndTime = null;
      }
      this.$store.dispatch("fcPlayerSetting/create", this.item).then(() => {
        $(this.$refs.playerSettingContent).modal("hide");
        this.$emit("updateList");
      });
    },
  },
};
</script>
