<template>
  <div id="PlayerDataList">
    <section class="content-header">
      <h1>玩家資料查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">玩家資料查詢</li>
      </ol>
    </section>
    <section class="content" style="min-height: auto">
      <div class="box box-primary">
        <div class="box-body">
          <form
            class="top-box"
            @submit="
              $event.preventDefault();
              getDataList();
            "
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="玩家暱稱"
                v-model.trim="searchData.nickName"
              />
            </div>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="手機門號"
                v-model.trim="searchData.phoneNumber"
              />
            </div>
            <div class="btns mb-10">
              <button type="submit" class="btn btn-primary">查詢</button>
              <button
                type="button"
                class="btn btn-default"
                @click="exportOrders()"
                :disabled="datas.length === 0"
              >
                匯出
              </button>
            </div>
            <button
              type="button"
              v-show="Object.keys($route.query).length > 0"
              @click="$router.go(-1)"
              class="btn btn-default pull-right mb-10 ml-10"
            >
              <i class="fa fa-arrow-left"></i> 返回
            </button>
            <router-link
              :to="{ name: 'NmjPlayerSetting' }"
              class="btn btn-warning pull-right mb-10"
              >大量停權</router-link
            >
          </form>
          <div class="table-responsive mt-0">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width: 120px; width: 5%">玩家<wbr />暱稱</th>
                  <th style="min-width: 90px; width: 5%">玩家<wbr />ID</th>
                  <th style="min-width: 120px; width: 5%">綁定<wbr />門號</th>
                  <th style="min-width: 90px; width: 5%">會員<wbr />狀態</th>
                  <th style="min-width: 80px; width: 5%">段位<wbr />等級</th>
                  <th style="min-width: 90px; width: 5%">段位<wbr />經驗值</th>
                  <th style="min-width: 80px; width: 5%">牌技</th>
                  <th style="min-width: 80px; width: 5%">雀幣</th>
                  <th style="min-width: 80px; width: 5%">雀銀</th>
                  <th style="min-width:105px; width: 5%">創建<wbr>帳號<wbr>時間</th>
                  <th style="min-width:105px; width: 5%">上次<wbr>登入<wbr>時間</th>
                  <th style="min-width:140px; width: 5%">玩家<wbr>歷程</th>
                  <th style="min-width:140px; width: 5%">玩家<wbr>控管<wbr>狀態</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td colspan="15">查詢中</td>
                </tr>
                <tr v-else-if="result.message">
                  <td colspan="15">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="15">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(player, index) in datas" :key="index">
                  <td>{{ player.nickName }}</td>
                  <td>{{ player.playerID }}</td>
                  <td>{{ player.門號綁定 ? player.門號綁定 : "-" }}</td>
                  <td>{{ player.會員狀態 }}</td>
                  <td>
                    {{ player.段位等級 }}
                    <span style="color: #aaa">({{ player.level }})</span>
                  </td>
                  <td>{{ player.levelExp }}</td>
                  <td>{{ player.skill }}</td>
                  <td>{{ player.mahjongCoin }}</td>
                  <td>{{ player.mahjongSilver }}</td>
                  <td>{{ uiDate(player.createTime * 1000) }}</td>
                  <td>{{ uiDate(player.lastPlayerLoginTime * 1000) }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'NmjPlayerHistory',
                        query: { nick: player.nickName },
                      }"
                      class="btn btn-primary"
                    >
                      <i class="fa fa-history fa-fw"></i> 玩家歷程
                    </router-link>
                    <router-link
                      :to="{
                        name: 'NmjPlayerDeposit',
                        query: { nick: player.nickName },
                      }"
                      class="btn btn-success mt-5"
                    >
                      <i class="fa fa-dollar fa-fw"></i> 儲值歷程
                    </router-link>
                    <router-link
                      :to="{
                        name: 'NmjRewardMessageHistory',
                        query: { nick: player.nickName },
                      }"
                      class="btn btn-warning mt-5"
                    >
                      <i class="fa fa-envelope fa-fw"></i> 信件歷程
                    </router-link>
                  </td>
                  <td>
                    <template v-for="(label, key) in controlLabels">
                      <p v-if="player[key] * 1000 >= Date.now()">
                        {{ label }}至{{ formatTimestamp(player[key]) }}
                      </p>
                    </template>
                    <p v-if="isControl(player)">正常無控管</p>
                    <p class="mt-15">
                      <button
                        class="btn btn-info"
                        @click="playerSetting(player)"
                      >
                        修改
                      </button>
                      <button
                        v-show="!isControl(player)"
                        class="btn btn-danger ml-5"
                        @click="playerSetting(player, true)"
                      >
                        解鎖
                      </button>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="datas.length > 0">
        <h2 class="text-big" style="color: #333">
          玩家紀錄：
          <span v-if="datas.length == 1">{{ datas[0].nickName }}</span>
          <span v-else>
            <select
              class="form-control"
              v-model="searchData.暱稱"
              style="display: inline-block; width: auto"
            >
              <option
                v-for="(player, index) in datas"
                :key="index"
                :value="player.nickName"
              >
                {{ player.nickName }}
              </option>
            </select>
          </span>
        </h2>
        <div class="nav-tabs-custom tab-warning">
          <ul class="nav nav-tabs">
            <li :class="{ active: tab == 1 }">
              <a
                @click="tab = 1"
                aria-expanded="true"
                data-toggle="tab"
                style="cursor: pointer"
                >服務紀錄</a
              >
            </li>
            <li :class="{ active: tab == 2 }">
              <a
                @click="tab = 2"
                aria-expanded="true"
                data-toggle="tab"
                style="cursor: pointer"
                >控管紀錄</a
              >
            </li>
          </ul>

          <div class="tab-content" v-if="tab == 1">
            <div class="top-box">
              <button
                type="button"
                @click="playerService(searchData.暱稱)"
                class="btn btn-warning pull-right mb-10 ml-10"
              >
                <i class="fa fa-plus"></i> 新增
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 100px; width: 5%">玩家<wbr />暱稱</th>
                    <th style="min-width: 100px; width: 10%">
                      操作<wbr />時間
                    </th>
                    <th style="min-width: 110px; width: 5%">操作<wbr />人員</th>
                    <th style="min-width: 110px; width: 5%">類型</th>
                    <th style="min-width: 150px; width: 15%">內容</th>
                    <th style="min-width: 75px; width: 5%">功能</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="serviceLoading">
                    <td colspan="11">查詢中</td>
                  </tr>
                  <tr v-else-if="serviceMessage">
                    <td colspan="11">{{ serviceMessage }}</td>
                  </tr>
                  <tr v-else-if="serviceList.length === 0">
                    <td colspan="11">查無控管紀錄</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(item, index) in serviceList"
                    :key="item.日期"
                  >
                    <td>{{ item.暱稱 }}</td>
                    <td>{{ uiDate(item.日期) }}</td>
                    <td>{{ item.發佈者 }}</td>
                    <td>{{ serviceTypes[item.分類] }}</td>
                    <td>{{ item.內容 }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="deletePlayerService(item)"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div v-if="serviceTotal > serviceSearchData.pageSize" class="text-center mt-10">
              <Pagination
                :pageLimitCount="serviceSearchData.pageSize"
                :total="serviceTotal"
                ref="pagination"
                v-on:pageMethod="onPageChange"
              />
            </div> -->
          </div>

          <div class="tab-content" v-if="tab == 2">
            <div class="table-responsive">
              <table class="table table-bordered text-center">
                <thead>
                  <tr class="bg-gray">
                    <th style="min-width: 100px; width: 5%">玩家<wbr />暱稱</th>
                    <th style="min-width: 100px; width: 10%">
                      操作<wbr />時間
                    </th>
                    <th style="min-width: 110px; width: 5%">操作<wbr />人員</th>
                    <th style="min-width: 110px; width: 5%">類型</th>
                    <th style="min-width: 100px; width: 10%">期限</th>
                    <th style="min-width: 150px; width: 15%">原因</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="banLoading">
                    <td colspan="11">查詢中</td>
                  </tr>
                  <tr v-else-if="banMessage">
                    <td colspan="11">{{ banMessage }}</td>
                  </tr>
                  <tr v-else-if="banList.length === 0">
                    <td colspan="11">查無控管紀錄</td>
                  </tr>
                  <tr v-else v-for="(item, index) in banList" :key="index">
                    <td>{{ item.暱稱 }}</td>
                    <td>{{ uiDate(item.起始日) }}</td>
                    <td>{{ item.操作者 }}</td>

                    <td data-title="類型-期限">
                      {{
                        typeStatus(
                          item.動作,
                          item.類型,
                          item.起始日,
                          item.終止日
                        )
                      }}
                    </td>

                    <td>{{ controlTerm(item.類型, item.終止日) }}</td>
                    <!--  <template v-if="item.動作 == 0">
                      <td>{{ banStatus停權(item.起始日, item.終止日) }}</td>
                      <td>{{ /3天|解鎖/.test(banStatus停權(item.起始日, item.終止日)) ? uiDate(item.終止日) : '永久' }}</td>
                    </template>
                    <template v-else>
                      <td>{{ banStatus禁言(item.起始日, item.終止日) }}</td>
                      <td>{{ 禁言期限(item.起始日, item.終止日) }}</td>
                    </template> -->
                    <td>{{ item.原因 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="banTotal > banSearchData.pageSize"
              class="text-center mt-10"
            >
              <Pagination
                :pageLimitCount="banSearchData.pageSize"
                :total="banTotal"
                ref="pagination"
                v-on:pageMethod="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <pre>{{ datas }}</pre> -->
    <!-- <pre>{{ serviceList }}</pre> -->
    <!-- <pre>{{ banList }}</pre> -->

    <PlayerSettingContent
      ref="PlayerSettingContent"
      v-on:updateList="
        getDataList();
        getBanInfo();
      "
    />
    <PlayerServiceContent
      ref="PlayerServiceContent"
      v-on:updateList="getServiceInfo()"
      :types="serviceTypes"
    />
  </div>
</template>

<style lang="scss" scoped>
table.table > tbody > tr > td {
  word-break: break-word;
}

table.table > thead > tr > th {
  word-break: keep-all;
}
</style>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import PlayerSettingContent from "@/views/projects/NMJ/PlayerSettingContent";
import PlayerServiceContent from "@/views/projects/NMJ/PlayerServiceContent";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    PlayerSettingContent,
    PlayerServiceContent,
    Pagination,
    router,
    moment,
  },
  data() {
    return {
      def: define,
      loading: false,
      total: 0, //總筆數
      searchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        nickName: "",
        phoneNumber: "",
        // phoneNumber: "0978378260",
        暱稱: "",
      },
      result: {},
      datas: [],
      tab: 1,

      // banList
      banSearchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        暱稱: "",
      },
      banMessage: "",
      banLoading: false,
      banTotal: 0,
      banList: [],

      controlLabels: {
        suspensionTime: "停權",
        banSpeechTime: "禁言",
        限制兌換時間: "限制兌換",
        限制上傳頭圖時間: "限制上傳頭圖",
      },

      // serviceList
      serviceSearchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        暱稱: "",
      },
      serviceMessage: "",
      serviceLoading: false,
      serviceTotal: 0,
      serviceList: [],
      serviceTypes: {},
    };
  },
  async created() {
    if (this.$route.query.nick) {
      this.searchData.nickName = this.$route.query.nick;
      this.getDataList();
    } else if (this.$route.query.tel) {
      this.searchData.phoneNumber = this.$route.query.tel;
      this.getDataList();
    }
    this.getTypes();
  },
  watch: {
    "searchData.暱稱": function (value) {
      this.banSearchData.暱稱 = value;
      this.serviceSearchData.暱稱 = value;
    },
    "banSearchData.暱稱": function () {
      this.banSearchData.pageNum = 1;
      this.getBanInfo();
    },
    "serviceSearchData.暱稱": function () {
      this.serviceSearchData.pageNum = 1;
      this.getServiceInfo();
    },
    // "searchData.nickName": function(value){ this.searchData.暱稱 = value },
  },
  computed: {
    isControl(player) {
      return (player) => {
        const now = Date.now();
        // console.log('banSpeechTime:',player.banSpeechTime , ' ,nowTime:',now)
        return (
          player.banSpeechTime * 1000 < now &&
          player.suspensionTime * 1000 < now &&
          player.限制兌換時間 * 1000 < now &&
          player.限制上傳頭圖時間 * 1000 < now
        );
      };
    },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 2) {
        this.banSearchData.pageNum = pageIdx;
        this.banSearchData.pageSize = pageSize;
        this.getBanInfo();
      } else {
        this.serviceSearchData.pageNum = pageIdx;
        this.serviceSearchData.pageSize = pageSize;
        this.getServiceInfo();
      }
    },
    getTypes() {
      this.$store
        .dispatch("nmjServiceTypes/serviceTypeQuery")
        .then((result) => {
          for (let item of result) {
            if (item.啟用YN == 0) {
              // 0 == 啟用
              this.$set(this.serviceTypes, item.編號, item.名稱);
              // console.log(item);
            }
          }
        });
    },
    getDataList() {
      if (!this.searchData.nickName && !this.searchData.phoneNumber) {
        this.$eventBus.$emit("showAlert", "請填寫玩家暱稱或門號！");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("nmjPlayerData/query", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.status == undefined ? result : [];
          this.total = result.total || 0;
          this.loading = false;
          if (this.datas.length > 0) {
            let query = {};
            if (this.searchData.nickName != "")
              query["nick"] = this.searchData.nickName;
            if (this.searchData.phoneNumber != "")
              query["tel"] = this.searchData.phoneNumber;
            // console.log(this.$route.query.nick, query.nick, this.$route.query.tel, query.tel)
            if (JSON.stringify(query) != JSON.stringify(this.$route.query)) {
              this.$router.replace({ query: query });
            }
            this.searchData.暱稱 = this.datas[0].nickName;
          }
        });
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp * 1000).toLocaleString();
    },
    getServiceInfo() {
      this.serviceLoading = true;
      this.$store
        .dispatch("nmjPlayerData/service", this.serviceSearchData)
        .then(
          (result) => {
            // console.log("Service", result)
            this.result = result;
            let list = result.datas ? result.datas : [];
            this.serviceList = list;
            this.serviceTotal = result.total || 0;
            this.serviceMessage = "";
            this.serviceLoading = false;
          },
          (msg) => {
            this.serviceList = [];
            this.serviceTotal = 0;
            this.serviceMessage = msg;
            this.serviceLoading = false;
          }
        );
    },
    getBanInfo() {
      this.banLoading = true;
      this.$store
        .dispatch("nmjPlayerData/controlInfo", this.banSearchData)
        .then(
          (result) => {
            // console.log(result)
            this.result = result;
            let list = result.datas ? result.datas : [];
            this.banList = list;
            this.banTotal = result.total || 0;
            this.banMessage = "";
            this.banLoading = false;
          },
          (msg) => {
            this.banList = [];
            this.banTotal = 0;
            this.banMessage = msg;
            this.banLoading = false;
          }
        );
    },
    banStatus停權(startTime, endTime) {
      let str = "停權 ";
      let start = new Date(startTime),
        end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > 10 * 366 * 24 * 60 * 60 * 1000)
        str += " 終止契約";
      else if (end.valueOf() - start.valueOf() > 3 * 24 * 60 * 60 * 1000)
        str += " 暫時凍結";
      else if (end.valueOf() === start.valueOf()) str += " 解鎖";
      else str += "3天";
      return str;
    },
    banStatus禁言(startTime, endTime) {
      let str = "禁言 ";
      let start = new Date(startTime),
        end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > 10 * 366 * 24 * 60 * 60 * 1000) {
        str += " 終止契約";
      } else if (end.valueOf() === start.valueOf()) str += " 解鎖";
      else
        str +=
          Math.round((end.valueOf() - start.valueOf()) / (60 * 1000)) + "分鐘";
      return str;
    },

    typeStatus(action, type, timeStart, timeEnd) {
      //action: 0, //0=停權,1=禁言,2=限制頭圖上傳,3=限制兌換
      //type: 2, //0=自訂天數/分鐘, 2=永久
      //time   //期限
      let ActionTime = "";
      let tStart = moment(timeStart);
      let tEnd = moment(timeEnd);
      let duration = moment.duration(tEnd.diff(tStart));
      let timeDifference;

      if (duration.asDays() >= 1) {
        timeDifference = `${Math.floor(duration.asDays())}`;
      } else {
        timeDifference = `${Math.floor(duration.asMinutes())} `;
      }

      
      const actions = [
        { name: "停權", unit: "天" },
        { name: "禁言", unit: "分鐘" },
        { name: "限制頭圖上傳", unit: "天" },
        { name: "限制兌換", unit: "" },
      ];

      const currentAction = actions[action];
      if (!currentAction) return "";

      // console.log('type:',type, ' ,timeDifference:',timeDifference )
        
      let durationText;
      if (type) {
          durationText = timeDifference == 0 ? '解鎖' : '永久';
      } else {
          durationText = timeDifference + currentAction.unit;
      }

      // durationText = type ? "解鎖" : timeDifference + currentAction.unit;

      ActionTime = `${currentAction.name} ${durationText}`;
      return ActionTime;
    },
    controlTerm(type, time) {
      let ActionTime = "";
      if (type) {
        ActionTime = `永久`;
      } else {
        ActionTime = time;
      }
      return ActionTime;
    },

    禁言期限(startTime, endTime) {
      let start = new Date(startTime),
        end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > 10 * 366 * 24 * 60 * 60 * 1000) {
        return "永久";
      } else {
        return this.uiDate(endTime);
      }
    },
    playerSetting(player, unban) {
      this.$refs.PlayerSettingContent.showContent(player, unban);
    },
    playerService(player) {
      this.$refs.PlayerServiceContent.showContent(player);
    },
    deletePlayerService(item) {
      this.$store
        .dispatch("nmjPlayerData/serviceDelete", {
          暱稱: item.暱稱,
          日期: item.日期,
        })
        .then((res) => {
          this.getServiceInfo();
        });
    },
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/export", this.searchData);
    },
    uiDate(date) {
      let d = new Date(date);
      if (d && date)
        return d.toLocaleString("zh-TW", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hourCycle: "h23",
        });
      else return "";
    },
  },
};
</script>