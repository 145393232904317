import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    serviceTypeQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjServiceType.query(data).then(res => {
                if (res.data.status == "1") {
                    commit('setServiceTypeQuery', res.data.result);
                    resolve(res.data.result);
                }
                else reject(res.data.message)
            })
        })
    },
    serviceTypeCreate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjServiceType.create(data).then(res => {
                if (res.data.status == "1") {
                    commit('setResponse', res.data);
                    resolve(res.data.result);
                }
                else reject(res.data.message)
            })
        })
    },
    serviceTypeUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.nmjServiceType.update(data).then(res => {
                if (res.data.status == "1") {
                    commit('setResponse', res.data);
                    resolve(res.data.result);
                }
                else reject(res.data.message)
            })
        });
    },
    // serviceTypeDelete({ commit }, data) {
    //     api.nmjServiceType.delete(data).then(res => {
    //         commit('setResponse', res.data);
    //     })
    // },
}

// mutations
const mutations = {
    setServiceTypeQuery(state, serviceTypeQuery) { //類別單筆
        state.query = serviceTypeQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}