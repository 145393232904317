<template>
  <div class="modal fade" ref="videoModal" @click="hideAlert()">
    <div class="modal-dialog">
      <div class="modal-content" >
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            @click="hideAlert()"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title text-center">
           {{ user.暱稱 }} 影片播放
          </h4>
        </div>
        <div class="modal-body">
         <div class="videoWrapper">
          <video  controls loop muted autoplay id="Video" v-if="user.影片連結">
            <source :src="user.影片連結" 
              type="video/mp4">
          </video>
        </div>

        <p class="text-center">
          倍數:{{ user.倍數 }}、分數:{{ user.分數 }}、等級:{{ user.等級 }}
        </p>
        <!-- {{user.影片連結}} -->
        
        </div>
        <div class="modal-footer text-center">
          <button
            class="btn btn-primary"
            data-dismiss="modal"
            type="button"
            @click="hideAlert()"
          >
            關閉影片
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {

  data() {
    return {
      user:{},
    };
  },
  methods: {
    show(item) { 
      this.user = item;
      this.$nextTick(() => {
        $(this.$refs.videoModal).modal("show");
      });
    },
    hideAlert() {
      const Video =  document.getElementById('Video');
      Video.pause();
      $(this.$refs.videoModal).modal("hide");
      this.user = {};
    },
  },
};
</script>


<style lang="scss" scoped>
  .videoWrapper {
    width: 90%;
    margin: 0 auto 30px auto;
    position: relative;

    video {
      width: 100%;
    }
  }

  .modal-dialog {
    max-width: 1000px !important;
    width: 90% !important;
    
  }

 
</style>