<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>每日簽到歷程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動管理</li>
        <li class="active">每日簽到</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box topBox-announceList">

            <div class="input-group mr-10">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.startTime" />
              </div>
            </div>

            <div class="input-group mr-10">
              <label class="control-label">結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker v-bind:propValue.sync="searchData.endTime" />
              </div>
            </div>

            <!-- <div class="select-group">
              <label class="control-label">專案類型：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.projectId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option value="omp_dk">黑 online</option>
              </select>
            </div> -->

            <div class="select-group">
              <label class="control-label">活動類型：</label>
              <select
                class="form-control"
                id="selectEvent"
                required="required"
                v-model="searchData.eventId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  v-for="(item, index) in eventOptionList"
                  :key="index"
                  :value="item.value"
                >{{item.name}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">簽到類型：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.signInType"
                @change="clickSearch"
              >
                <option 
                  :value="item.value"
                  v-for="(item, index) in signInTypeOptions"
                  :key="index"
                >{{item.text}}</option>
              </select>
            </div>

            <div class="select-group">
              <label class="control-label">獎項類型：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.gameType"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  v-for="(item, index) in gameTypeOptionList"
                  :key="index"
                  :value="item.value"
                >{{item.name}}</option>
              </select>
            </div>

            <div class="input-group sW">
              <label class="control-label">輸入MoloId：</label>
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋MoloId"
                type="text"
                v-model="searchData.identityKey"
              />
              <div class="input-group-btn verAlign-bottom">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <div class="text snmMT">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ dailySignInList.total }}</span>
              </p>
            </div>

            <!-- <div class="form-group fr snmMT w100-md mt-15-md">
              <button @click="exportOrders" class="btn btn-success w100-md" type="button">匯出</button>
            </div> -->
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">編號</th>
                  <!-- <th style="min-width: 90px; width: 5%">專案類型</th> -->
                  <th style="min-width: 90px; width: 10%">活動類型</th>
                  <th style="min-width: 90px; width: 10%">簽到類型</th>                  
                  <th style="min-width: 90px; width: 10%">識別值</th>
                  <th style="min-width: 90px; width: 10%">獎項類別</th>
                  <th style="min-width: 90px; width: 10%">虛寶代號</th>
                  <th style="min-width: 90px; width: 10%">獎項名稱</th>
                  <th class="text-nowrap" style="width: 10%;">新建時間</th>
                </tr>
                <template v-if="dailySignInList.total > 0">
                  <tr :key="item.id" v-for="(item, index) in dailySignInList.datas">
                    <td align="center">{{ index+1 }}</td>
                    <!-- <td align="center">{{ item.projectId }}</td> -->
                    <td align="center">{{ TranslateEventType(item.eventId) }}</td>
                    <td align="center">{{ TranslateSignInType(item.signInType) }}</td>
                    <td align="center">{{ item.identityKey }}</td>
                    <td align="center">{{ item.gameType }}</td>
                    <td align="center">{{ item.itemCode }}</td>
                    <td align="center">{{ item.memo }}</td>
                    <td align="center">{{ FormatTime(item.createTime) }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="8">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="dailySignInList.total > 0">
          <Pagination
            ref="pagination"
            :total="dailySignInList.total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import eventInfo from '@/static/json/eventOption.json';

export default {
  components: {
    DatePicker,
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      total: 0, //總筆數
      eventOptionList: [],
      gameTypeOptionList: [],
      searchData: {
        projectId: this.$store.state.projectId,
        eventId: "",
        signInType: -1,
        gameType: "",
        identityKey: "",
        startTime: new moment(),
        endTime: new moment(),
        pageNum: 1,
        pageSize: 10 //每頁顯示筆數
      },
      eventList: [],
      selectedEventInfo: []
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getEventList();
    this.getGameTypeList();
    this.GetDailySignInList();
  },
  mounted() {},
  computed: {
    ...mapState({
      dailySignInList: state => state.event.getDailySignInList
    }),
    // 簽到類型是共用，固定寫在程式中
    signInTypeOptions(){
      return [
        {"value":-1,"text":"全部"},
        {"value":1,"text":"正常簽到"},
        {"value":2,"text":"儲值補簽"},
      ]
    }
  },
  watch: {
    dailySignInList() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.dailySignInList.total
      );
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getGameTypeList();
      this.GetDailySignInList();
    },

    getEventList() {
      var id = this.searchData.projectId;
      var index = eventInfo.findIndex(function(obj){
        return obj.projectId == id;
      });
      var eventOptions = [];
      if (index != -1){
        this.eventList = eventInfo[index].events;
        //取得活動列表
        $.each(this.eventList, function(idx, val){
          eventOptions.push({ name: val.name, value: val.id});
        });
        this.eventOptionList = eventOptions;
        this.searchData.eventId = eventOptions[eventOptions.length-1].value;
      }
    },

    getGameTypeList(){
      var eventId = this.searchData.eventId;
      var eventInfo = [];
      // 用選取活動id找出對應獎項
      $.each(this.eventList, function(idx, val){
        if (eventId == val.id){
          eventInfo = val;
        }
      });

      this.selectedEventInfo = eventInfo;
      // 有資料才取獎項
      if (eventInfo && eventInfo.awardOptionList){
        this.gameTypeOptionList = this.selectedEventInfo.awardOptionList;
        var gameType = this.searchData.gameType;
        
        var index = eventInfo.awardOptionList.findIndex(function(obj){
          return obj.value == gameType;
        });
        // 獎項id不再獎項列表中則將獎項改為預設值
        if (index == -1) this.searchData.gameType = "";
      }else{
        this.searchData.gameType = "";
        this.gameTypeOptionList = [];
      }
    },

    GetDailySignInList() {
      //活動列表
      if (this.searchData.startTime != "")
        this.searchData.startTime = new moment(
          this.searchData.startTime
        ).format("YYYY/MM/DD 00:00:00");
      if (this.searchData.endTime != "")
        this.searchData.endTime = new moment(
          this.searchData.endTime
        ).format("YYYY/MM/DD 23:59:59");

      this.signInType = parseInt(this.signInType);
      this.$store.dispatch("event/getDailySignInList", this.searchData);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.getGameTypeList();
      this.GetDailySignInList();
    },

    TranslateEventType(eventId) {
      var eventName = "";
      $.each(this.eventList, function(idx, val){
        if (eventId == val.id){
          eventName = val.name;
          return false;
        }
      });
      return eventName;
      // if (this.selectedEventInfo && this.selectedEventInfo.name){
      //   return this.selectedEventInfo.name;
      // }
      // return eventId;
    },

    TranslateSignInType(type) {
      if (type == 1) return "正常簽到";
      if (type == 2) return "儲值補簽";
      return "";
    },

    FormatTime(time) {
      if (time == undefined || time.substring(0, 4) == "0001") return "";
      return utils.formatTime(time, "YYYY/MM/DD HH:mm:ss");
    },

    exportOrders() {
      let tmpSearchData = utils.deepCloneObj(this.searchData);
      tmpSearchData.pageNum = 0;
      tmpSearchData.pageSize = 0;
      this.$store.dispatch("event/eventExport", tmpSearchData);
    }
  }
};
</script>

<style scoped>
.mr-10 {
  margin-right: 10px;
}

.fl {
  float: left;
}
</style>
