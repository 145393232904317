<template>
  <div id="MemberData">
    <section class="content-header">
      <h1>回饋金批次派發</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">回饋金批次派發</li>
      </ol>
    </section>
    <section class="content">
      <div class="box tab-warning">
        <!-- <ul class="nav nav-tabs">
          <li :class="{ active: tab == 1 }">
            <a @click="tab = 1" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">回饋金查詢</a>
          </li>
          <li :class="{ active: tab == 2 }">
            <a @click="tab = 2" aria-expanded="true" data-toggle="tab" style="cursor:pointer;">批次派發歷程</a>
          </li>
        </ul> -->
        <!-- <div class="box-body" v-if="tab == 1">
          <form class="top-box clearfix" @submit="$event.preventDefault(); getDataList()">
            <div class="input-group mb-10 mr-10">
              <input type="text" class="form-control" placeholder="玩家暱稱" v-model.trim="searchData.nickName" />
            </div>
            <div class="pull-left mb-10 mr-10">
              <button type="submit" class="btn btn-primary mr-10">
                查詢
              </button>
            </div>
            <button type="button" class="btn btn-warning pull-right mb-10 ml-10" @click="memberRebate(searchData.暱稱 || true)">
              <i class="fa fa-plus"></i> 新增
            </button>
          </form>
          <div class="table-responsive">
            <table class="table table-bordered text-center max850">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:120px; width: 5%">玩家帳號</th>
                  <th style="min-width:120px; width:10%">玩家暱稱</th>
                  <th style="min-width: 75px; width: 5%">玩家動態</th>
                  <th style="min-width:120px; width: 5%">剩餘點數</th>
                  <th style="min-width:120px; width: 5%">剩餘回饋金</th>
                  <th style="min-width:120px; width: 5%">保險箱餘額</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="serviceLoading">
                  <td colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="serviceMessage">
                  <td colspan="11">{{ serviceMessage }}</td>
                </tr>
                <tr v-else-if="serviceList.length === 0">
                  <td colspan="11">查無回饋金紀錄</td>
                </tr>
                <tr v-else v-for="(item, index) in serviceList" :key="item.日期">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ uiDate(item.日期) }}</td>
                  <td>{{ item.發佈者 }}</td>
                  <td>{{ serviceTypes[item.分類] }}</td>
                  <td>{{ item.內容 }}</td>
                  <td>
                    <button type="button" class="btn btn-danger" @click="deletePlayerService(item)">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
        <div class="box-body" v-if="tab == 1">
          <form class="top-box clearfix" @submit.prevent="resetEntryList()">
            <div class="form-group m-0">
              <label class="control-label pull-left mt-7">查詢區間：</label>
              <div class="input-group date datetime pull-left mb-10 mr-10">
                <div class="input-group-addon fa fa-calendar"></div>
                <DateRangePicker
                  :propStartDate.sync="banSearchData.開始時間"
                  :propEndDate.sync="banSearchData.結束時間"
                  :propTimePicker="true"
                  displayFormat="YYYY/MM/DD HH:mm"
                />
              </div>
              <button type="submit" class="btn btn-primary pull-left mb-10">查詢</button>
            </div>
            <button type="button" @click="memberRebate(false)" class="btn btn-warning pull-right mb-10 ml-10">
              <i class="fa fa-plus"></i> 新增
            </button>
          </form>
          <div class="table-responsive">
            <table class="table table-bordered text-center">
              <thead>
                <tr class="bg-gray">
                  <th style="min-width:120px; width:10%">操作時間</th>
                  <th style="min-width:120px; width:10%">檔案名稱</th>
                  <th style="min-width: 75px; width: 5%">總筆數</th>
                  <th style="min-width: 75px; width: 5%">成功紀錄</th>
                  <th style="min-width: 75px; width: 5%">失敗紀錄</th>
                  <th style="min-width: 75px; width: 5%">未定紀錄</th>
                  <th style="min-width:150px; width:20%">最後一筆成功紀錄</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="banLoading">
                  <td colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="banMessage">
                  <td colspan="11">{{ banMessage }}</td>
                </tr>
                <tr v-else-if="banList.length === 0">
                  <td colspan="11">查無控管紀錄</td>
                </tr>
                <tr v-else v-for="(item, index) in banList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.暱稱 }}</td>
                  <td>{{ uiDate(item.起始日) }}</td>
                  <td>{{ item.操作者 }}</td>
                  <template v-if="item.動作 == 0">
                    <!---停權-->
                    <td>{{ banStatus停權(item.起始日, item.終止日) }}</td>
                    <td>{{ /3天|解鎖/.test(banStatus停權(item.起始日, item.終止日)) ? uiDate(item.終止日) : '永久' }}</td>
                  </template>
                  <template v-else>
                    <!---禁言-->
                    <td>{{ banStatus禁言(item.起始日, item.終止日) }}</td>
                    <td>{{ 禁言期限(item.起始日, item.終止日) }}</td>
                  </template>
                  <td>{{ item.原因 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="banTotal > banSearchData.pageSize" class="text-center mt-10">
            <Pagination :pageLimitCount="banSearchData.pageSize" :total="banTotal" ref="pagination"
              v-on:pageMethod="onPageChange" />
          </div>
        </div>
      </div>
    </section>


    <!-- <pre>{{ datas }}</pre> -->
    <!-- <pre>{{ serviceList }}</pre> -->
    <!-- <pre>{{ banList }}</pre> -->

    <!-- <PlayerSettingContent ref="MemberRebateDialog" v-on:updateList="getDataList(); getBanInfo()" /> -->
    <MemberRebateDialog ref="MemberRebateDialog" v-on:updateList="getServiceInfo()" :types="serviceTypes" />
  </div>
</template>

<style lang="scss" scoped>
table.table>tbody>tr {
  word-break: break-word;
}
table.table>thead>tr>th {
  word-break: keep-all;
}
</style>

<script>
/* eslint-disable */
import define from "@/views/projects/NMJ/_Define";
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import MemberRebateDialog from "@/views/projects/XSW/components/MemberRebateDialog";
import Pagination from "@/components/Pagination";
import iCheckCheckBox from "@/components/iCheckBox";
import router from "../../../router";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    MemberRebateDialog,
    Pagination,
    router
  },
  props:{
    name: ""
  },
  data() {
    return {
      def: define,
      loading: false,
      searchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        nickName: "",
        phoneNumber: "",
        暱稱: "",
      },
      result: {},
      datas: [],
      total: 0, //總筆數
      tab: 1,

      // banList
      banSearchData: {
        暱稱: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      banMessage: "", //錯誤訊息
      banLoading: false,
      banTotal: 0, //總筆數
      banList: [],

      // serviceList
      serviceSearchData: {
        暱稱: "",
        開始時間: new moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00"),
        結束時間: new moment().format("YYYY-MM-DD 23:59:59"),
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      serviceMessage: "", //錯誤訊息
      serviceLoading: false,
      serviceTotal: 0, //總筆數
      serviceList: [],
      serviceTypes: {},
    };
  },
  async created() {
    if (this.$route.query.nick) {
      this.searchData.nickName = this.$route.query.nick;
      this.getDataList();
    }
    else if (this.$route.query.tel) {
      this.searchData.phoneNumber = this.$route.query.tel;
      this.getDataList();
    }
    this.getTypes();
    window.v = this
  },
  watch: {
    "searchData.暱稱": function (value) {
      this.banSearchData.暱稱 = value;
      this.serviceSearchData.暱稱 = value;
    },
    "banSearchData.暱稱": function () {
      this.banSearchData.pageNum = 1;
      this.getBanInfo();
    },
    "serviceSearchData.暱稱": function () {
      this.serviceSearchData.pageNum = 1;
      this.getServiceInfo();
    }
    // "searchData.nickName": function(value){ this.searchData.暱稱 = value },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      if (this.tab == 2) {
        this.banSearchData.pageNum = pageIdx;
        this.banSearchData.pageSize = pageSize;
        this.getBanInfo();
      } else {
        this.serviceSearchData.pageNum = pageIdx;
        this.serviceSearchData.pageSize = pageSize;
        this.getServiceInfo();
      }
    },
    getTypes() {
      this.$store.dispatch("nmjServiceTypes/serviceTypeQuery").then((result) => {
        for (let item of result) {
          if (item.啟用YN == 0) { // 0 == 啟用
            this.$set(this.serviceTypes, item.編號, item.名稱);
            // console.log(item);
          }
        }
      });
    },
    getDataList() {
      if (!this.searchData.nickName && !this.searchData.phoneNumber) {
        this.$eventBus.$emit("showAlert", "請填寫玩家暱稱或門號！");
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("nmjPlayerData/query", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.status == undefined ? result : [];
          this.total = result.total || 0;
          this.loading = false;
          if (this.datas.length > 0) {
            let query = {};
            if (this.searchData.nickName != "") query['nick'] = this.searchData.nickName;
            if (this.searchData.phoneNumber != "") query['tel'] = this.searchData.phoneNumber;
            // console.log(this.$route.query.nick, query.nick, this.$route.query.tel, query.tel)
            if (JSON.stringify(query) != JSON.stringify(this.$route.query)){
              this.$router.replace({query:query});
            }
            this.searchData.暱稱 = this.datas[0].nickName;
          }
        });
    },
    getServiceInfo() {
      this.serviceLoading = true;
      this.$store
        .dispatch("nmjPlayerData/service", this.serviceSearchData)
        .then((result) => {
          // console.log("Service", result)
          this.result = result;
          let list = result.datas ? result.datas : [];
          this.serviceList = list;
          this.serviceTotal = result.total || 0;
          this.serviceMessage = "";
          this.serviceLoading = false;
        }, (msg) => {
          this.serviceList = [];
          this.serviceTotal = 0;
          this.serviceMessage = msg;
          this.serviceLoading = false;
        });
    },
    getBanInfo() {
      this.banLoading = true;
      this.$store
        .dispatch("nmjPlayerData/controlInfo", this.banSearchData)
        .then((result) => {
          // console.log(result)
          this.result = result;
          let list = result.datas ? result.datas : [];
          this.banList = list;
          this.banTotal = result.total || 0;
          this.banMessage = "";
          this.banLoading = false;
        }, (msg) => {
          this.banList = [];
          this.banTotal = 0;
          this.banMessage = msg;
          this.banLoading = false;
        });
    },
    banStatus停權(startTime, endTime) {
      let str = '停權 ';
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) str += ' 終止契約';
      else if (end.valueOf() - start.valueOf() > (3 * 24 * 60 * 60 * 1000)) str += ' 暫時凍結';
      else if (end.valueOf() === start.valueOf()) str += ' 解鎖';
      else str += '3天';
      return str
    },
    banStatus禁言(startTime, endTime) {
      let str = '禁言 ';
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) {
        str += ' 終止契約';
      }
      else if (end.valueOf() === start.valueOf()) str += ' 解鎖';
      else str += Math.round((end.valueOf() - start.valueOf()) / (60 * 1000)) + '分鐘';
      return str;
    },
    禁言期限(startTime, endTime) {
      let start = new Date(startTime), end = new Date(endTime);
      if (end.valueOf() - start.valueOf() > (10 * 366 * 24 * 60 * 60 * 1000)) {
        return '永久';
      } else {
        return this.uiDate(endTime);
      }
    },
    memberRebate(player = ""){
      this.$refs.MemberRebateDialog.show(player);
    },
    playerSetting(player, unban) {
      this.$refs.MemberRebateDialog.showContent(player, unban);
    },
    playerService(player) {
      this.$refs.MemberRebateDialog.showContent(player);
    },
    deletePlayerService(item) {
      this.$store.dispatch("nmjPlayerData/serviceDelete", {
        "暱稱": item.暱稱,
        "日期": item.日期,
      }).then(res => {
        this.getServiceInfo();
      });
    },
    exportOrders() {
      this.$store.dispatch("nmjPlayerData/export", this.searchData);
    },
    uiDate(date) {
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return '';
    }
  },
};
</script>