<template>
  <div id="ItemsType">
    <section class="content-header">
      <h1>道具種類設定</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/ItemsList">道具列表</router-link>
        </li>
        <li class="active">道具種類設定</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              @click="addData()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th style="width: 15%">道具類別代碼</th>
                  <th style="width: 15%">道具類別名稱</th>
                  <th style="min-width: 150px">更新時間</th>
                  <th class="text-nowrap">更新者</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%"
                  >
                    啟用狀態
                  </th>
                  <th style="min-width: 150px; width: 20%">功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="6">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="6">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    <p v-if="!item.isEdit">
                      {{ item.itemTypeCode }}
                    </p>
                    <input
                      type="text"
                      class="form-control"
                      v-else
                      v-model.trim="item.itemTypeCode"
                    />
                  </td>
                  <td>
                    <p v-if="!item.isEdit">
                      {{ item.itemTypeTitle }}
                    </p>
                    <input
                      type="text"
                      class="form-control"
                      v-else
                      v-model.trim="item.itemTypeTitle"
                    />
                  </td>
                  <td>
                    {{ item.updateTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td>{{ item.updateUName }}</td>
                  <td align="center" class="text-center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :id="'check' + index"
                        :true-value="0"
                        :false-value="1"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model.number="item.isStop"
                        @change="submitData(item)"
                      />
                      <label :for="'check' + index"></label>
                    </div>
                  </td>
                  <td>
                    <button
                      @click="changeData(item)"
                      class="btn btn-primary"
                      v-show="!item.isEdit"
                    >
                      修改
                    </button>
                    &nbsp;
                    <button
                      @click="deleteData(item)"
                      class="btn btn-danger"
                      v-show="!item.isEdit"
                    >
                      刪除
                    </button>
                    <button
                      @click="saveData(item)"
                      class="btn btn-success btn-save"
                      v-show="item.isEdit"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="cancelData(item)"
                      class="btn btn-default"
                      v-show="item.isEdit"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchDataEdit.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, // 總筆數
      searchDataEdit: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, // 每頁顯示筆數
      },
      datas: [],
      item: {},
      orginItem: {},
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchDataEdit.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.$store
        .dispatch("exchanges/queryAll", this.searchDataEdit)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.item.isCreate = false;
          this.loading = false;
          this.$refs.pagination.setPage(
            this.searchDataEdit.pageNum,
            this.total
          );
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchDataEdit.pageNum = pageIdx;
      this.searchDataEdit.pageSize = pageSize;
      this.getDataList();
    },
    changeData(item) {
      this.cancelData(this.item);
      item.isEdit = true;
      this.item = item;
      this.orginItem.itemTypeCode = item.itemTypeCode;
      this.orginItem.itemTypeTitle = item.itemTypeTitle;
      this.$forceUpdate();
    },
    saveData(item) {
      if (item.itemTypeCode === "") {
        this.$eventBus.$emit("showAlert", "道具類別代碼不得為空！");
        return;
      }
      if (item.itemTypeTitle === "") {
        this.$eventBus.$emit("showAlert", "道具類別名稱不得為空！");
        return;
      }
      this.submitData(item);
    },
    submitData(item) {
      this.$store
        .dispatch(`exchanges/${item.isCreate ? "create" : "update"}`, item)
        .then(() => {
          this.getDataList();
        });
    },
    addData() {
      if (this.datas.length && this.datas[0].isCreate) {
        this.$eventBus.$emit("showAlert", "您尚未儲存新增項目！");
        return;
      }
      this.cancelData(this.item);
      this.item = {
        itemTypeCode: "",
        itemTypeTitle: "",
        updateTime: new Date(),
        updateUName: this.$store.state.loginUser.EmployeeName,
        isStop: 0,
        isEdit: true,
        isCreate: true,
      };
      this.datas.splice(0, 0, this.item);
    },
    cancelData(item) {
      item.isEdit = false;
      if (item.isCreate) {
        this.datas.splice(0, 1);
        item.isCreate = false;
      } else {
        item.itemTypeCode = this.orginItem.itemTypeCode;
        item.itemTypeTitle = this.orginItem.itemTypeTitle;
        this.$forceUpdate();
      }
    },
    deleteData(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("exchanges/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },
    backList() {
      this.$router.push({
        name: "ItemsList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>