<template>
	<div id="gamehallList" class="slot">
		<section class="content-header">
			<h1>遊戲列表</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li class="active">遊戲列表</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<button @click="add()" class="btn btn-block btn-warning btn-add" type="button">
							<i class="fa fa-plus"></i> 新增
						</button>
						<div class="select-group">
              <label class="control-label">App：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
								v-model="searchData.遊戲編號"
								@click="resetDataList()"
              >
                <option :value="-1">全部</option>
								<option
									v-for="(game, index) in gameList"
									:value="game.編號"
								>
									{{ game.遊戲名稱 }}
								</option>
              </select>
            </div>
						
						<div class="select-group">
              <label class="control-label">標籤：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.標籤編號"
								@click="resetDataList()"
              >
                <option value>全部</option>
                <option
                  :value="tag.編號"
                  v-for="(tag, index) in tagList"
                  :key="index"
									v-show="tag.被標記數 > 0"
                >{{tag.標籤名稱}}</option>
              </select>
            </div>

						<div class="input-group mr-10">
              <label class="control-label">搜尋玩法：</label>
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="請輸入玩法"
								type="text"
								v-model="searchData.玩法名稱"
							/>
							<i class="fa fa-close" v-if="searchData.玩法名稱 != ''" @click="searchData.玩法名稱 = ''"></i>
						</div>

						<div class="input-group">
              <label class="control-label">搜尋關鍵字：</label>
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="請輸入關鍵字"
								type="text"
								v-model="searchData.遊戲館名稱"
							/>
							<i class="fa fa-close" v-if="searchData.遊戲館名稱 != ''" @click="searchData.遊戲館名稱 = ''"></i>
						</div>
						<div class="text" style="margin-top: 23px;">
							<button
								v-on:click="resetDataList()"
								class="btn btn-primary mr-10"
								type="button"
							>
								查詢
							</button>
							<p class="data-num">
								<span>資料總筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th style="min-width: 97px;">圖示</th>
									<th style="min-width: 150px;" width="50%">
										<span @click="changeSort()" style="cursor: pointer;">
											遊戲名稱
											<i class="fa fa-sort" ></i>
										</span>
									</th>
									<th style="min-width: 150px;" width="50%">App名稱</th>
									<th style="min-width: 150px;" width="50%">玩法</th>
									<th style="min-width: 150px;" width="50%">標籤</th>
									<th style="min-width: 150px;" width="50%">建立時間</th>
									<th style="min-width: 150px;" width="50%">更新時間</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 250px; width: 20%;">功能</th>
								</tr>
								<tr v-if="total == 0">
									<td colspan="8"><Empty /></td>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td class="text-nowrap text-center">
										<div class="photo">
											<img :src="item.圖示" alt="">
										</div>
									</td>
									<td>{{ item.遊戲館名稱 }}</td>
									<td>{{ item.遊戲名稱 }}</td>
									<td class="text-nowrap text-center">
										<div
											v-for="play in item.玩法集合"
											class="mb-5"
										>
											{{ play.玩法名稱 }}
										</div>
									</td>
									<td class="text-nowrap text-center">
										<button
											v-for="tag in item.標籤集合"
											class="tag btn btn-success mb-5"
											style="display: block;"
											:disabled="tag.是否啟用 == '0'"
										>
											{{ tag.標籤名稱 }}
										</button>
									</td>
									<td class="text-nowrap text-center">{{ item.建立時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
									<td class="text-nowrap text-center">{{ item.更新時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item)"></label>
										</div>
									</td>
									<td class="text-center">
										<button class="btn btn-primary mr-10" @click="edit(item)">基本資料</button>
										<button class="btn btn-primary" @click="showContent(item)">內容說明</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border" v-if="total != 0">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="20">每頁筆數20</option>
            <option value="30">每頁筆數30</option>
            <option value="50">每頁筆數50</option>
          </select>
					<Pagination
						ref="pagination"
						:total="total"
						:pageLimitCount="searchData.pageSize"
						v-on:pageMethod="onPageChange"
						:type2="true"
						/>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
	import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";
	import Empty from "@/components/SlotEmpty";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
			Empty
		},
    delimiters: ["${", "}"],
    data() {
      return {
				isEdit: false,
				searchData: {
					遊戲編號: -1,
					遊戲館名稱: "",//關鍵字比對
					// 是否啟用: 1,
					標籤編號: "",
					玩法名稱: "",
					pageNum: 1,
					pageSize: 20,
					排序條件: 1, //1:更新時間,2.遊戲館名稱
					排序順序: 1, //1:DESC,2:ASC
				},
				tagList: [],
				gameList: [],
				list: [],
				total: 0,
      };
    },
    created() {
			if (this.$route.params.searchData != undefined) {
				this.searchData = this.$route.params.searchData;
				this.searchData.排序條件 = 1;
				this.searchData.排序順序 = 1;
			}
			this.getTagList();
			this.getGameList();
			this.getDataList();
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
			getTagList() {
				api.slotGame.tag.queryAll({
					排序條件: 2, //1:建立時間,2.標籤名稱,3.被標記數,/4.更新時間
					排序順序: 1, //1:DESC,2:ASC
				})
					.then((res) => {
						this.tagList = res.data.result.datas;
					})
			},
			getGameList() {
				api.slotGame.game.queryAll({
					開發商編號: 0,
					排序條件: 3,//1:更新時間,2:建立時間,3.遊戲名稱
					排序順序: 2,//1:DESC,2:ASC
				})
					.then((res) => {
						this.gameList = res.data.result.datas;
					})
			},
			getDataList() {
				this.searchData.標籤編號 = String(this.searchData.標籤編號)
				api.slotGame.gameHall.queryAll(this.searchData)
					.then((res) => {
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
						setTimeout(() => {
							this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
						}, 500);
					})
			},
			resetDataList() {
				this.searchData.pageNum = 1;
				this.getDataList();
			},
			changeSort() {
				if(this.searchData.排序條件 != 3) {
					this.searchData.排序條件 = 3;
					this.searchData.排序順序 = 2;
				} else {
					this.searchData.排序順序 = this.searchData.排序順序 == 1 ? 2 : 1;
				}
				this.resetDataList();
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}開發商？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}已建立的相關資料
						包含遊戲、遊戲館、集成分析。`,
				isOK => {
					if (isOK) {
						api.slotGame.gameHall.state({
							編號: item.編號,
							是否啟用: item.是否啟用 == 1 ? 0 : 1
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.getDataList();
									setTimeout(() => {
										this.$eventBus.$emit("showAlert", "更新成功!");
									}, 200);
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
							}
						});
			},
			add() {
				this.$router.push({
					name: 'slotGameInfo',
					backSearchData: this.searchData,
				});
			},
			edit(item) {
				this.$router.push({
					name: 'slotGameInfo',
					params: {
						id: item.編號,
						backSearchData: this.searchData,
					}
				});
			},
			showContent(item) {
				this.$router.push({
					name: 'slotGameContent',
					params: {
						GameId: item.遊戲編號,
						id: item.編號,
						backSearchData: this.searchData,
						name: item.遊戲館名稱,
					},
				});
			},
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	#gamehallList .tag {
		display: block;
		margin: 0 auto;
	}
	#gamehallList .input-group .fa-close {
		right: 10px;
    top: 70%;
	}
	.btn-tag {
		font-size: 12px !important;
		background: #333;
		color: #fff;
		padding: 2px 15px !important;
	}
	.modal-content {
		border-radius: 10px;
	}
</style>