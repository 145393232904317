<template>
  <div class="c-editorTable">
    <div class="top-box with-border">
      <h4 class="text text-title" v-if="isEdit">編輯內容</h4>
      <h4 class="text text-title" v-else>新增內容</h4>
    </div>
    <form class="form-horizontal" >
      <div class="box-body">
        <div v-for="(layout, row) in editorLayout" :key="row" class="form-group">
          <label class="col-sm-2 control-label text-nowrap" :for="layout.name">
            <span class="text-red">*</span> {{ layout.name }}：
          </label>

          <div class="col-sm-10">
            <div v-for="(field, col) in layout.fields" :key="col">
              <template v-if="field.type=='text'">
                <input v-model="edit[field.content]" :id="layout.name" type="text" required="required" class="form-control">
              </template>

              <select v-else-if="field.type=='select'" name="" :id="layout.name" class="form-control" v-model="edit[field.content]">
                <option value disabled="disabled" selected="selected" hidden>請選擇{{ layout.name }}</option>
                <option v-for="(item, index) in bannerTypes.datas" :key="index" :value="item.classTypeId" v-text="item.className" ></option>
              </select>

              <template v-else-if="field.type=='file'">
                <div class="pic">
                  <img draggable="false" :src="edit.filesUrl" />
                </div>
                <input accept="image/*" :id="layout.name" type="file" @change="onFileChange($event)" />
              </template>

              <div v-else-if="field.type=='checkbox'" class="checkbox-list">
                <label style="padding:0">
                  <input type="checkbox" v-model="edit[field.content]" class="contactUs-checkbox">
                  <span><i class="fa fa-check"></i></span> <!-- 假按鈕要在<input>後 -->
                  {{ field.label }}
                </label>
              </div>

              <div v-else-if="field.type=='radio'" class="radio-list">
                <label v-for="(item, index) in field.value.split(':')" :key="index">
                  <input type="radio" :name="`Radio${row}-${col}`" :value="item.trim()" class="contactUs-radio" v-model="edit[field.content]">
                  <span></span> <!-- 假按鈕要在<input>後 -->
                  {{ field.label.split(':')[index].trim() }} 
                </label>
              </div>
              
              <div v-else-if="field.type=='help'" v-text="field.value" class="help-block text-red"></div>

            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="btns">
          <button class="btn btn-danger btn-lg" type="button" @click="ResetData">取消</button>
           &nbsp;
          <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
  .c-EditorTable{
    h4{margin: 0;}

    
    
    
  }
  .contactUs-checkbox {
    opacity: 0;
    position: absolute;
    + *{
      display:inline-block;
      width:1.5em;
      height: 1.5em;
      font-size: 1em;
      background: #ccc;
      color: #fff;
      border-radius: .333em;
      text-align: center;
      &>*{opacity: 0;}
    }

    &:checked + *{
      background: #0af;
      &>*{opacity: 1;}
    }
  }
 
  .contactUs-radio{
    opacity: 0;
    position: absolute;
    + *{
      display:inline-block;
      width:1.5em;
      height: 1.5em;
      font-size: 1em;
      background: #fff;
      color: #fff;
      border-radius: 50%;
      border: .3em solid #ccc;
      text-align: center;
      &>*{opacity: 0;}
    }

    &:checked + *{
      border-color: #0af;
      background: #fff radial-gradient(closest-side, #0af ,#0af 45%, #fff 55%);
      &>*{opacity: 1;}
    }
  }
  .radio-list{
    padding-top: 7px;
    > * + *{
      margin-left: 2em;
    }
    *{
      vertical-align: top;
    }
  }
  .checkbox-list{
    padding-top: 7px;
  }

</style>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";

export default {
  props:['editorLayout'],

  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",      
      edit: {
        bannerId: utils.getTimeId(), //廣告Id
        classTypeId: "", //分類Id
        bannerTitle: "", // 名稱
        bannerLink: "", // 網址
        isStop: 0, // 狀態
        isLinkOpen: 1, // 另開視窗
        orderNum: 0, // 排序
        filesUrl: "" // 圖片
      }
    };
  },
  computed: {
    ...mapState({
      bannerTypes: state => state.classTypes.queryAll,
      banner: state => state.banner.query,
      response: state => state.banner.response,
      fileUploadResponse: state => state.file.response
    })
  },
  watch: {
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      } else {
        if (this.isEdit) {
          this.$store.dispatch("banner/bannerUpdate", this.edit);
        } else {
          this.$store.dispatch("banner/bannerCreate", this.edit);
        }
      }
    }
  },
  methods:{
    getData(editData) {
      this.edit = Object.assign({}, editData);
      this.isEdit = true;
    },
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpe?g|png|gif)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      this.preview(files);
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data);
    },
    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          that.edit.filesUrl = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },

    // TEXT FORMATING //
    formatBoolean(boolean, value = "yes : no"){
      return boolean ? value.split(":")[0].trim() :value.split(":")[1].trim();
    },
    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      
      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      let folderName = "friendlyLink";
      let override = false;

      if (this.edit.bannerTitle == "") {
        this.$eventBus.$emit("showAlert", "標題不能為空");
        return;
      }
      if (this.edit.bannerLink == "") {
        this.$eventBus.$emit("showAlert", "網址不能為空");
        return;
      }

      let AdPic = document.getElementById("圖示");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.bannerId,
          false,
          override
        );
      } else if (this.edit.filesUrl == "") {
        this.$eventBus.$emit("showAlert", "請上傳圖片!!");
        return;
      } else {
        if (this.isEdit) {
           if(this.edit.isLinkOpen) {
             this.edit.isLinkOpen = 1;
           } else {
             this.edit.isLinkOpen = 0;
           }
          this.$store.dispatch("banner/bannerUpdate", this.edit);
        } else {
           this.$store.dispatch("banner/bannerCreate", this.edit);
        }
        this.ResetData();
      }
    },
    ResetData(){
      this.edit =  {
        bannerId: utils.getTimeId(),
        classTypeId: "",
        bannerTitle: "",
        bannerLink: "",
        isStop: 0,
        isLinkOpen: 1,
        orderNum: 0,
        filesUrl: ""
      }
      this.isEdit = false;
    }
  },
}
</script>