<template>
  <div>
    <section class="content-header">
      <h1>相簿編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>相簿列表</li>
        <li class="active">相簿編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" @change="OnTypeChnage()" v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option
                      :key="index"
                      :value="item.classTypeId"
                      v-for="(item, index) in classTypes.datas"
                    >{{item.className}}</option>
                  </select>
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-2 control-label" for="inputAuthor">
                  <span class="text-red">*</span> 年份：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    id="selectUnit"
                    required="required"
                    v-model="edit.years"
                    @change="clickSearch"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, index) in yearOptions"
                      :key="index"
                    >{{item.label}}</option>
                  </select>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputAuthor">
                  <span class="text-red">*</span> 作者名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputAuthor"
                    required="required"
                    type="text"
                    v-model="edit.detailTopInfo"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputContent">
                  <span class="text-red">*</span>作品名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputContent"
                    required="required"
                    type="text"
                    v-model="edit.detailTopTitle"
                  />
                </div>
              </div>

              <div class="form-group" v-if="this.edit.classId == 'short_video'">
                <label class="col-sm-2 control-label" for="inputCosplayCharacter">
                  <span class="text-red">*</span>作品連結：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputCosplayCharacter"
                    required="required"
                    type="text"
                    v-model="edit.videoUrl"
                  />
                </div>
              </div>

              <div class="form-group" v-if="this.edit.classId != 'short_video'">
                <label class="col-sm-2 control-label">
                  <!--<span class="text-red">*</span> -->
                  圖片：
                </label>
                <div class="col-sm-10" v-if="addFileList.length > 0">

                  <div class="row ann-imgChoose mt-10">
                    <div
                      class="col-sm-12 chooseBox"
                      v-for="(item, index) in addFileList"
                      :key="index"
                    >
                      <div class="form-group mt-10">
                        <label class="col-sm-2 control-label">
                          圖片名稱：
                        </label>
                        <div class="col-sm-6">
                          <input
                            class="form-control"
                            name="imgTitle"
                            type="text"
                            v-model="item.title"
                          />
                        </div>
                        <div>
                          <button
                            class="btn ml-10"
                            :class="[index == 0 ? 'btn-default' : 'btn-primary']"
                            type="button"
                            @click="imageShiftUp(index)"
                            v-if="addFileList.length > 1"
                            :disabled="index == 0"
                          >
                            <i class="fa fa-arrow-up"></i>
                          </button>
                          <button
                            class="btn ml-10"
                            :class="[index == addFileList.length - 1 ? 'btn-default' : 'btn-primary']"
                            type="button"
                            @click="imageShiftDown(index)"
                            v-if="addFileList.length > 1"
                            :disabled="index == addFileList.length - 1"
                          >
                            <i class="fa fa-arrow-down"></i>
                          </button>
                          <button
                            class="btn btn-danger ml-10"
                            type="button"
                            @click="deleteImageApply(item, index)"
                            v-if="addFileList.length > 1"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-2 control-label">
                          圖片介紹：
                        </label>
                        <div class="col-sm-6">
                          <input
                            class="form-control"
                            name="imgInfo"
                            type="text"
                            v-model="item.info"
                          />
                        </div>
                      </div>
                      <div class="form-group" v-if="isCos()">
                        <label class="col-sm-2 control-label">
                          圖片類型：
                        </label>
                        <div class="col-sm-6">
                          <div class="radio-date">
                            <div class="radio text-left">
                              <iCheckRedioButton
                                checkValue="0"
                                message="橫式"
                                :name="`radioMode_${index}`"
                                v-model="item.alt"
                              />
                            </div>
                            <div class="radio text-left">
                              <iCheckRedioButton
                                checkValue="1"
                                message="直式"
                                :name="`radioMode_${index}`"
                                v-model="item.alt"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-2 control-label">
                          作品上傳：
                        </label>
                        <div class="col-sm-10">
                          <input
                            accept="image/*"
                            id="Pic"
                            type="file"
                            @change="onFileChange($event, item)"
                            />
                          <div class="help-block" v-if="!item.imgFullFileName || item.imgFullFileName == ''">
                            <span class="text-red">建議尺寸 1024*768</span>
                          </div>
                          <div style="width: 250px;" class="mt-10">
                            <img :src="item.imgFullFileName" name="imgUrl"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      class="btn btn-warning mt-10 mb-10"
                      type="button"
                      @click="addOption()"
                    >
                      <i class="fa fa-plus"></i> 其他作品
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="0"
                        message="顯示"
                        name="radioMode"
                        v-model="edit.isStop"
                      />
                    </div>
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="隱藏"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      idDonationListMode: false,
      backRouteName: "ArtCreationList",
      module: "art_activities",
      detailGameLang: [],
      classId: "",
      cosplayGame: "",
      cosplayCharacter: "",
      edit: {
        contentId: utils.getTimeId(), //Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        videoUrl: "", // 網址p
        contentDetailTopId: "", // 內容Id
        detailTopTitle: "", //作者名稱
        detailTopInfo: "", //作品名稱
        isTurnPlay: 0, //輪播
        publishTime: "",
        isStop: 0, // 狀態
        sortId: 1, // 排序
        // years: new Date().getFullYear(), //年份
      },
      originalFileData: [],
      addFileList: [],
      newImg: false,
      updateImg: {},
    };
  },
  created() {
    this.GetProjectInfo();
    this.GetFileList();
  },
  mounted() {
    this.edit.classTypeId = this.$route.params.searchData.classTypeId;
    $(".timepicker").timepicker({
      showInputs: false,
    });
  },
  computed: {
    ...mapState({
      classTypes: (state) => state.classTypes.queryAll,
      editData: (state) => state.game.query,
      detailQueryAll: (state) => state.game.detailQueryAll,
      projectData: (state) => state.module.queryAll,
      response: (state) => state.game.response,
      fileUploadResponse: (state) => state.file.response,
      fileList: (state) => state.game.imgList,
    }),
  },
  watch: {
    fileList(val) {
      if(this.fileList.length == 0) {
        // new
        this.addFileList.push({
          title: "",
          info:"",
          od: 1,
          imgFullFileName: "",
          // alt: "0"
        })
        return
      }

      if(!this.newImg || this.updateImg) {
        this.addFileList = this.fileList;
        return
      }

      let data = this.addFileList[this.addFileList.length - 1];
      let o_data = this.fileList[this.fileList.length - 1];
      data.filesId = o_data.filesId;
      data.id = o_data.id;
      data.imgFullFileName = o_data.imgFullFileName;
      data.imgName = o_data.imgName;
      data.isMain = o_data.isMain;
      data.od = o_data.od;
      this.newImg = false;
      
      this.$forceUpdate();
    },
    editData() {
      this.edit = this.editData;
      if (this.edit.publishTime != "") {
        $(".datepicker").val(
          new Date(this.edit.publishTime).toLocaleDateString()
        );
        $(".timepicker").val(
          new Date(this.edit.publishTime).toLocaleTimeString("en-US", {
            timeStyle: "short",
          })
        );
      }
      this.GetEditDataLangData();
    },

    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetClassTypes();

      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetEditData();
      } else {
        this.detailGameLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.contentId + i + 1;
          this.detailGameLang.push({
            gameDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo,
          });
        }
      }
    },

    detailQueryAll() {
      let that = this;
      this.detailGameLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          const Id = this.edit.contentId + i + 1;
          let findLang = false;
          this.detailQueryAll.datas.some((details) => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.gameDetailId = details.gameDetailId;
                that.edit.detailTopTitle = details.title;
                that.edit.detailTopInfo = details.info;
              }
              that.detailGameLang.push({
                gameDetailId: details.gameDetailId,
                lang: details.lang,
                title: details.title,
                info: details.info,
              });
              findLang = true;
              return true;
            }
          });
          if (!findLang) {
            that.detailGameLang.push({
              gameDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: "",
            });
          }
        }
        this.GetFileList();
      }
    },

    classTypes() {
      this.OnTypeChnage();
    },

    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: this.backRouteName,
          params: { searchData: this.$route.params.searchData },
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },

    // imgUrlList() {
    //   this.fileList = [];
    //   this.fileList = this.imgUrlList;
    // },
  },
  methods: {
    GetEditData() {
      this.$store.dispatch("game/gameQuery", {
        contentId: this.$route.params.id,
      });
    },

    GetEditDataLangData() {
      this.$store.dispatch("game/gameDetailQueryAll", {
        contentId: this.$route.params.id,
      });
    },

    GetClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    GetClassId() {
      var classId = "";
      try {
        this.classTypes.datas.forEach((item) => {
          if (item.classTypeId == this.edit.classTypeId) {
            classId = item.classId;
          }
        });
      } catch (err) {
        console.log(err);
      }
      return classId;
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetFileList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("game/gameImgList", {
        contentId: this.edit.contentId,
      });
    },

    //取得該語系資料
    GetEditDataDetailLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.gameDetailId = this.detailGameLang[i].gameDetailId;
          this.edit.detailTopTitle = this.detailGameLang[i].title;
          this.edit.detailTopInfo = this.detailGameLang[i].info;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      this.SetLangData(this.editLang);
      this.GetEditDataDetailLang(lang);
      this.editLang = lang;
      this.GetFileList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailGameLang[i].title = this.edit.detailTopTitle;
          this.detailGameLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: this.backRouteName,
        params: { searchData: this.$route.params.searchData },
      });
    },

    onFileChange(evt, item) {
      this.SetLangData(this.editLang);
      this.GetEditDataDetailLang(this.editLang);
      this.newImg = true;
      this.updateImg = item.info != "" || item.title != "" ? true : false;

      var files = evt.target.files;

      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }
      }
      this.uploadFile(
        files,
        this.module,
        this.edit.contentId,
        true,
        item
      );
    },

    uploadFile(files, folderName, id, multi = false,  item) {
      let data = new FormData();
      console.log('aaaa');
      console.log(item);

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("upoladOption.projectId", this.edit.projectId);
      data.append("upoladOption.module", folderName);
      data.append("upoladOption.moduleId", id);
      data.append("upoladOption.od", item.od);
      data.append("upoladOption.lang", "zh-tw");
      data.append("upoladOption.multi", multi);
      data.append("upoladOption.isMain", 0);
      data.append("upoladOption.title", item.title || "");
      data.append("upoladOption.info", item.info || "");
      data.append("uploadOption.alt", item.alt);

      this.$store.dispatch("file/fileUploadByOrder", data).then((res) => {
        if (res.status != "0") {
          if(item.imgFullFileName == '') {
            this.GetFileList();
          } else {
            this.$store.dispatch("file/fileDelete", item).then((res) => {
              this.GetFileList();
            });
          }
        }
      });
    },

    deleteImageApply(item, index) {
      if(item.imgFullFileName == "") {
        this.addFileList.splice(index, 1);
        return
      }
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("file/fileDelete", item).then((res) => {
            if (res.status != "0") {
              this.addFileList.splice(index, 1);
            }
          });
        }
      });
    },

    deleteAllImageApply(item) {
      this.$store.dispatch("file/filesDelete", item).then((res) => {
        if (res.status != "0") {
          this.GetFileList();
        }
      });
    },

    updateImageInfo() {
      if (this.addFileList.length > 0){
        let isDefaultImage = 0;
        let isMainImage = 0;
        var imgList = [];

        $.each(this.addFileList, function(index, item){
          isMainImage = (index == 0) ? 1 : 0;
          imgList.push({
            ApplyId: item.id,
            Od: index+1,
            IsMain: isMainImage,
            FilesId: item.filesId,
            Title: item.title,
            Info: item.info,
            IsDefault: isDefaultImage,
            alt: item.alt
          });
        });

        this.$store.dispatch("file/updateFileInfoApply", imgList); // 修改檔案應用資訊(排序、主圖)
        this.$store.dispatch("file/updateFiles", imgList); // 修改檔案資訊(標題、圖片介紹)
      } else {
        this.$eventBus.$emit("showAlert", "請上傳主圖");
        return;
      }
    },

    addOption() {
      this.addFileList.push({
        od: this.addFileList.length + 1,
        imgFullFileName: ""
      })
    },

    // 圖片上移
    imageShiftUp(index){
      this.addFileList[index].od = this.addFileList[index].od - 1;
      this.addFileList[index - 1].od = this.addFileList[index].od + 1;
      let previousIndex = index-1;
      let currentImage = this.addFileList[index];
      let previousImage = this.addFileList[previousIndex];
      this.addFileList[previousIndex] = currentImage;
      this.addFileList[index] = previousImage;
      this.updateImageInfo();
      this.$forceUpdate(); // 強制刷新頁面
    },

    // 圖片下移
    imageShiftDown(index){
      this.addFileList[index].od = this.addFileList[index].od + 1;
      this.addFileList[index + 1].od = this.addFileList[index].od - 1;
      let lastIndex = index+1;
      let currentImage = this.addFileList[index];
      let lastImage = this.addFileList[lastIndex];
      this.addFileList[lastIndex] = currentImage;
      this.addFileList[index] = lastImage;
      this.updateImageInfo();
      this.$forceUpdate(); // 強制刷新頁面
    },

    OnTypeChnage() {
      this.edit.classId = this.GetClassId();
    },

    isCos() {
      let num = this.classTypes.datas.findIndex(item => item.classTypeId == this.edit.classTypeId);
      if(num <= -1) return false
      if(this.classTypes.datas[num].className.indexOf('Cos') >= 0) return true
      else return false
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      this.SetLangData(this.editLang);
      
      this.edit.publishTime =
        $(".datepicker").val() + " " + $(".timepicker").val();

      if(this.isCos()) {
        this.addFileList.forEach(item => {
          if(!item.alt) {
            item.alt = "0"
          }
        })
      }

      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "作者名稱不能為空");
        return;
      }

      if (this.edit.detailTopInfo == "") {
        this.$eventBus.$emit("showAlert", "作品名稱不能為空");
        return;
      }


      if (this.edit.classId == "short_video") {
        if (!$.trim(this.edit.videoUrl)) {
          this.$eventBus.$emit("showAlert", "作品連結不能為空");
          return;
        }
        // 如果原本是有圖片的類別需要清空
        if (this.addFileList.length > 0) {
          this.deleteAllImageApply(this.addFileList);
        }
      }else{
        if(this.addFileList.every(item => item.imgFullFileName == "")) {
          this.$eventBus.$emit("showAlert", "圖片不能為空");
          return;
        }
        this.edit.videoUrl = "";
        this.updateImageInfo();
      }

      this.edit.publishTime = new Date(this.edit.publishTime + " UTC");
      this.edit.sortId = parseInt(this.edit.sortId);
      
      if (this.isEdit) {
        this.$store.dispatch("game/gameUpdate", {
          master_model: this.edit,
          detail_model: this.detailGameLang,
        });
      } else {
        this.$store.dispatch("game/gameCreate", {
          master_model: this.edit,
          detail_model: this.detailGameLang,
        });
      }
    },
  },
};
</script>
<style scoped>
.chooseBox {
  border: 1px solid #f4f4f4;
}
</style>