<template>
  <!-- 新增禁言 -->
  <div class="modal fade" ref="announcementAlert">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title" v-if="checkDatas">
            <span>是否刪除以下文章？</span>
            <span>(共計 {{ checkDatas.length }} 筆)</span>
          </h4>
          <h4 class="modal-title" v-else>
            <span>是否刪除此文章？</span>
          </h4>
        </div>
        <div class="modal-body">
          <ul v-if="checkDatas">
            <li
              v-for="(item, index) in checkDatas"
              :key="index"
              v-html="item.contentText || item.articleTitle"
            ></li>
          </ul>
          <div style="padding: 15px 0;">
            <label for="" class="col-sm-2">刪除原因</label>
            <select v-model="deleteReason" class="col-sm-7">
              <option value="廣告、商業宣傳">廣告、商業宣傳</option>
              <option value="惡意洗版">惡意洗版</option>
              <option value="垃圾內容或誤導性內容">垃圾內容或誤導性內容</option>
              <option value="色情內容">色情內容</option>
              <option value="暴力或令人反感的內容">暴力或令人反感的內容</option>
              <option value="仇恨或惡意內容">仇恨或惡意內容</option>
              <option value="侵犯智慧財產權">侵犯智慧財產權</option>
              <option value="詐騙或詐欺">詐騙或詐欺</option>
              <option value="其他">其他</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="submit()" class="btn btn-primary" type="button">
            確定
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  props: ["actionType", "checkDatas"],
  data() {
    return {
      editorName: this.$store.state.loginUser.EmployeeName,
      deleteReason: '廣告、商業宣傳',
      comment: []
    };
  },
  methods: {
    showAlert(item) {
      if(item) {
        this.comment[0] = item;
        this.deleteReason = item.reportData || this.deleteReason
      } 
      $(this.$refs.announcementAlert).modal("show");
    },
    hideAlert() {
      $(this.$refs.announcementAlert).modal("hide");
      this.$emit("updateList");
    },
    submit() {
      if(!this.actionType) {
        this.comment[0].reportData = this.deleteReason
        this.comment[0].deleteReason = this.deleteReason
        this.$parent.deleteItem(this.comment[0]);
        this.hideAlert();
      } else {
        let data = this.checkDatas.length > 0 ? this.checkDatas :this.comment
        data.forEach(item => {
          item.deleteReason = this.deleteReason
        });
        this.$store
          .dispatch(`newtalk/${this.actionType}DisableAll`, data)
          .then(() => {
            this.hideAlert();
          });
      }
    },
  },
};
</script>
