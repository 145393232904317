<template>
  <FormDialog ref="memberHonorDialog" :title="$t(isAddHonor ? '添加' : '回收') + ' ' + $t('榮譽值')"
    @submit="submit">
    <div class="table-responsive mt-0">
      <table class="table table-bordered">
        <tbody>
          <tr v-if="isAddHonor !== null">
            <th><span :class="isAddHonor ? 'text-green' : 'text-red'">{{ $t(isAddHonor ? '增加' : '扣除') }}</span> {{$t('榮譽值')}}</th>
            <td><input type="number" v-model.number="data.榮譽值" class="form-control" min="0" :max="isAddHonor == false ? player.榮譽值 : false"></td>
          </tr>
          <tr>
            <th>{{$t('服務類型')}}</th>
            <td>
              <select v-model="data.分類" class="form-control" required>
                <option value="" selected disabled hidden>{{$t('請選擇類別')}}</option>
                <option v-for="name, id in types" :value="name" :key="id">{{ name }}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>{{$t('內容')}}</th>
            <td><textarea v-model="data.內容" class="form-control" min="1" rows="5" required></textarea></td>
          </tr>
        </tbody>
      </table>
    </div>
  </FormDialog>
</template>

<script>
/* eslint-disable */
import FormDialog from "./FormDialog.vue";

export default {
  props:[
    'types'
  ],
  components: {
    FormDialog,
  },
  data() {
    return {
      data: {
        暱稱:"",
        榮譽值:"",
        內容:"",
        分類:""
      },
      player: null,
      isAddHonor: null, //榮譽正負
    };
  },
  mounted(){
  },
  methods: {
    async show(player, isAddHonor) {
      Object.assign(this.$data, this.$options.data()); // 重設data
      this.player = player;
      this.data.暱稱 = player.暱稱;
      this.isAddHonor = isAddHonor !== undefined ? isAddHonor : null;
      this.$refs.memberHonorDialog.show();
    },
    submit(){
      let data = this.data;
      if (this.isAddHonor === false && this.榮譽值 > this.player.榮譽值){
        this.$eventBus.$emit("showAlert", "送出失敗，剩餘榮譽值不得少於0");
        return;
      }
      data.榮譽值 = this.isAddHonor ? data.榮譽值 : data.榮譽值 * -1;
      this.$store.dispatch("xsw/會員榮譽值異動", data).then(() => {
        this.$refs.memberHonorDialog.hide();
        this.$eventBus.$emit("showAlert", "異動成功！");
        this.$emit("updateList");
      }, (message) => {
        this.$eventBus.$emit("showAlert", message);
      });
    },
  },
};
</script>
