<template>
  <div id="CustomerService">
    <section class="content-header">
      <h1>客服處理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">客服處理</li>
      </ol>
    </section>
    <section class="content customer-service">
      <div class="nav-tabs-custom tab-warning">
        <ul class="nav nav-tabs">
          <li :class="{ active: searchData.module === '聯絡我們' }">
            <a
              aria-expanded="true"
              data-toggle="tab"
              href="#problem"
              @click="resetDataList('聯絡我們', '問題類別')"
            >
              聯絡我們
            </a>
          </li>
          <li :class="{ active: searchData.module === '檢舉' }">
            <a
              aria-expanded="false"
              data-toggle="tab"
              href="#report"
              @click="resetDataList('檢舉', '檢舉類別')"
            >
              檢舉
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <!-- 聯絡我們 -->
          <div
            class="tab-pane"
            :class="{ active: searchData.module === '聯絡我們' }"
          >
            <div class="top-box">
              <div class="form-group">
                <label class="control-label">聯絡時間：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.local.contactEndTime"
                      :propStartDate.sync="searchData.local.contactStartTime"
                    />
                  </div>
                </div>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="searchData.reactionType"
                  @change="resetDataList('聯絡我們')"
                >
                  <option value="">問題類別</option>
                  <option value="LOBBY">大廳</option>
                  <option value="SLOT MACHINES">遊戲館</option>
                  <option value="PURCHASE">儲值</option>
                  <option value="GIFTS">獎勵</option>
                  <option value="CONNECTION">連線</option>
                  <option value="SUGGESTIONS">建議</option>
                  <option value="OTHERS">其他</option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="searchData.platform"
                  @change="resetDataList('聯絡我們')"
                >
                  <option value="">所有平台</option>
                  <option value="HD安卓新藍版">Android</option>
                  <option value="HDios">iOS</option>
                  <option value="HD舊安卓紅藍版">Editor</option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model.number="searchData.status"
                  @change="resetDataList('聯絡我們')"
                >
                  <option value="-1">處理進度</option>
                  <option value="2">已回覆</option>
                  <option value="0">未回覆</option>
                  <option value="1">草稿</option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="玩家UID"
                  type="text"
                  v-model.trim="searchData.reactionUId"
                />
              </div>
              <div class="btns">
                <button
                  @click="resetDataList('聯絡我們')"
                  class="btn btn-primary"
                  type="button"
                >
                  查詢
                </button>
                <button
                  class="btn btn-default"
                  type="button"
                  @click="exportOrders"
                >
                  匯出
                </button>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered text-center min-1000">
                <tbody>
                  <tr class="bg-gray">
                    <th>編號</th>
                    <th style="min-width: 150px">聯絡時間</th>
                    <th class="text-nowrap" style="min-width: 100px">
                      玩家UID
                    </th>
                    <th>平台</th>
                    <th style="width: 10%">手機型號</th>
                    <th>系統版本</th>
                    <th>網路環境</th>
                    <th>APP版本</th>
                    <th>問題類別</th>
                    <th>客服人員</th>
                    <th style="min-width: 150px">回覆時間</th>
                    <th>處理進度</th>
                    <th>功能</th>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="13">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td colspan="13">查詢無任何資料</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="index">
                    <td>{{ datas.length - index }}</td>
                    <td>
                      {{
                        item.reactionTime | dateFormat("YYYY/MM/DD HH:mm:ss")
                      }}
                    </td>
                    <td>{{ item.reactionUId }}</td>
                    <td>{{ define.contactPlatforms[item.platform] }}</td>
                    <td>{{ item.phoneModel }}</td>
                    <td>{{ item.systemVersion }}</td>
                    <td>{{ item.network }}</td>
                    <td class="text-nowrap">{{ item.appVersion }}</td>
                    <td>{{ define.contactTypes[item.reactionType] }}</td>
                    <td>{{ item.replyUName }}</td>
                    <td>
                      {{ item.replyTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td :class="{ 'text-red': item.status === 1 }">
                      {{ define.serviceStatus[item.status] }}
                    </td>
                    <td>
                      <button
                        @click="checkContent(item)"
                        class="btn btn-primary"
                      >
                        查看
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 檢舉 -->
          <div
            class="tab-pane"
            :class="{ active: searchData.module === '檢舉' }"
          >
            <div class="top-box">
              <div class="form-group">
                <label class="control-label">檢舉時間：</label>
                <div class="input-group date">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right">
                    <DateRangePicker
                      :propTimePicker="true"
                      :propEndDate.sync="searchData.local.reportEndTime"
                      :propStartDate.sync="searchData.local.reportStartTime"
                    />
                  </div>
                </div>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model="searchData.reactionType"
                  @change="resetDataList('檢舉')"
                >
                  <option value="">檢舉類別</option>
                  <option value="AbusiveChat">辱罵聊天</option>
                  <option value="InappropriateName">不當名稱</option>
                  <option value="CheatingBottingHacking">作弊</option>
                  <option value="Spam">濫用廣告</option>
                  <option value="Others">其他</option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  v-model.number="searchData.status"
                  @change="resetDataList('檢舉')"
                >
                  <option value="-1">處理進度</option>
                  <option value="2">已回覆</option>
                  <option value="0">未回覆</option>
                  <option value="1">草稿</option>
                </select>
              </div>
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="玩家UID"
                  type="text"
                  v-model.trim="searchData.reactionUId"
                />
              </div>
              <div class="btns">
                <button
                  @click="resetDataList('檢舉')"
                  class="btn btn-primary"
                  type="button"
                >
                  查詢
                </button>
              </div>
            </div>
            <div class="table-responsive mt-10">
              <table class="table table-bordered text-center min-1000">
                <tbody>
                  <tr class="bg-gray">
                    <th>編號</th>
                    <th style="min-width: 150px">檢舉時間</th>
                    <th class="text-nowrap" style="min-width: 100px">
                      玩家UID
                    </th>
                    <th class="text-nowrap" style="min-width: 150px">
                      被檢舉玩家UID
                    </th>
                    <th>檢舉類別</th>
                    <th>客服人員</th>
                    <th style="min-width: 150px">回覆時間</th>
                    <th>處理進度</th>
                    <th>功能</th>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="9">查詢中</td>
                  </tr>
                  <tr v-else-if="datas.length === 0">
                    <td colspan="9">查詢無任何資料</td>
                  </tr>
                  <tr v-else v-for="(item, index) in datas" :key="index">
                    <td>{{ datas.length - index }}</td>
                    <td>
                      {{
                        item.reactionTime | dateFormat("YYYY/MM/DD HH:mm:ss")
                      }}
                    </td>
                    <td>{{ item.reactionUId }}</td>
                    <td>{{ item.sufferUId }}</td>
                    <td>{{ define.reportTypes[item.reactionType] }}</td>
                    <td>{{ item.replyUName }}</td>
                    <td>
                      {{ item.replyTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                    </td>
                    <td :class="{ 'text-red': item.status === 1 }">
                      {{ define.serviceStatus[item.status] }}
                    </td>
                    <td>
                      <button
                        @click="checkContent(item)"
                        class="btn btn-primary"
                      >
                        查看
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- 頁數 -->
          <div class="box text-center no-border no-shadow mt-20">
            <Pagination
              :pageLimitCount="searchData.pageSize"
              :total="total"
              ref="pagination"
              v-on:pageMethod="onPageChange"
            />
          </div>
        </div>
        <!-- /.tab-content -->
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          contactStartTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          contactEndTime: new moment().format("YYYY-MM-DD 23:59:59"),
          reportStartTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          reportEndTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
        module: "聯絡我們",
        reactionType: "",
        platform: "",
        status: -1,
        reactionUId: "",
      },
      datas: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    resetDataList(moduleType, reactionType) {
      this.searchData.pageNum = 1;
      this.getDataList(moduleType, reactionType);
    },
    getDataList(moduleType, reactionType) {
      this.searchData.module = moduleType || this.searchData.module;
      if (reactionType) {
        this.searchData.reactionType = "";
        this.searchData.platform = "";
      }
      this.updateTime();
      this.$store
        .dispatch("fcService/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    updateTime() {
      if (this.searchData.module === "聯絡我們") {
        this.searchData.startTime = new Date(
          `${this.searchData.local.contactStartTime} UTC`
        );
        this.searchData.endTime = new Date(
          `${this.searchData.local.contactEndTime} UTC`
        );
      } else {
        this.searchData.startTime = new Date(
          `${this.searchData.local.reportStartTime} UTC`
        );
        this.searchData.endTime = new Date(
          `${this.searchData.local.reportEndTime} UTC`
        );
      }
    },
    // 查看內容
    checkContent(item) {
      this.$router.push({
        name: "CustomerServiceEdit",
        params: {
          id: item.reactionId,
          type: this.searchData.module,
          searchData: this.searchData,
        },
      });
    },
    exportOrders() {
      this.$store.dispatch("fcService/export", this.searchData);
    },
  },
};
</script>