<template>
  <div id="BeforePrizeList" class="searchList">
    <section class="content-header">
      <h1>事前紅包抽獎</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">事前紅包抽獎</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker :propValue.sync="searchData.local.startTime" />
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">結束日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DatePicker :propValue.sync="searchData.local.endTime" />
              </div>
            </div>
            <div class="select-group" style="width: auto">
              <select
                class="form-control"
                v-model="searchData.prizeNo"
                @change="resetDataList()"
              >
                <option value>全部獎項</option>
                <option value="1">300星幣</option>
                <option value="2">500星幣</option>
                <option value="3">1000星幣</option>
                <option value="4">10000星幣</option>
                <option value="5">100000星幣</option>
                <option value="6">True Money 現金卡1000泰銖</option>
                <option value="7">Apple Watch Series 7</option>
                <option value="8">iPhone 13 (128GB²)</option>
              </select>
            </div>
            <div class="input-group search-group">
              <input
                type="text"
                class="form-control pull-right"
                placeholder="搜尋門號"
                v-model="searchData.phoneNumber"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：{{ total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th>#</th>
                  <th class="text-nowrap" style="min-width: 150px">門號</th>
                  <th class="text-nowrap" style="min-width: 150px">獎項名稱</th>
                  <th class="text-nowrap" style="min-width: 150px">抽獎時間</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="4">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="4">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.phoneNumber }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.prizeName }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <select
            class="form-control page-size"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DatePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment().format("YYYY-MM-DD"),
          endTime: new moment().format("YYYY-MM-DD"),
        },
        phoneNumber: "",
        prizeNo: "",
      },
      datas: [],
    };
  },
  mounted() {
    this.searchData.local.startTime = "2022-02-01";
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} 00:00:00 UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} 23:59:59 UTC`
      );
      this.$store
        .dispatch("starCastle/prizeQueryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
