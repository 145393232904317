@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>Banner管理</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li v-if="id" class="active"><router-link :to="{params:{ id:null }}">Banner管理</router-link></li>
        <li v-else class="active">Banner管理</li>
        <li v-if="id" class="active">{{ id }}</li>
      </ol>
    </section>

    <section v-if="!id" class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box">
            <router-link :to="{params:{ id:'New' }}" class="btn btn-block btn-warning btn-add fa fa-plus">新增</router-link>
            <div class="select-group">
              <select class="form-control" v-model="filterStatus" @change="clickSearch" required>
                <option value>所有狀態</option>
                <option value="進行中">進行中</option>
                <option value="等待上線">等待上線</option>
                <option value="已過期">已過期</option>
              </select>
            </div>
            <div class="text"><p>資料總筆數：{{ filteredBanners.length }}</p></div>
          </div>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width:135px; width: 5%">排序</th>
                  <th style="min-width:120px; width: 5%">主圖</th>
                  <th style="min-width:120px; width:10%">名稱</th>
                  <th style="min-width: 75px; width: 5%">狀態</th>
                  <th style="min-width:100px; width:10%">開始時間</th>
                  <th style="min-width:100px; width:10%">結束時間</th>
                  <th style="min-width:135px; width: 5%">功能</th>
                </tr>
                <tr v-if="filteredBanners.length <= 0"><td class="text-center" colspan="10">無資料</td></tr>
                <tr v-else v-for="(item, index) in pagedBanners" :key="item.id">
                  <td>
                      <input type="number" class="form-control sort" v-model="item.orderNum" min="1" :max="banners.total" :disabled="item.isStop == 1" />
                      <button type="button" class="btn btn-warning ml-5" @click="updateBannerOd(item)" v-if="item.isStop != 1">更新</button>
                  </td>
                  <td><div class="pic"><img :src="item.filesUrl" draggable="false" alt="" /></div></td>
                  <td>{{ item.note }}</td>
                  <td>{{ GetStatus(item, false) }}</td>
                  <td>{{ uiDate(item.formalStartTime) }}</td>
                  <td>{{ uiDate(item.formalEndTime) }}</td>
                  <td>
                    <button @click="editorItem(item)" class="btn btn-primary">修改</button>
                    <button @click="deleteItem(item)" class="btn btn-danger ml-5">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="box-footer text-center no-border">
            <Pagination ref="pagination" :total="filteredBanners.length" :pageLimitCount="searchData.pageSize" v-on:pageMethod="onPageChange" />
          </div>
        </div>
      </div>
    </section>
    <section v-else class="content">
      <div class="box">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id == "New" ? "新增" : "編輯" }}內容</h4>
            <router-link :to="{params:{ id:null }}" class="btn btn-block btn-default btn-cog">
              <i class="fa fa-reply"></i> 返回列表
            </router-link>
          </div>
          <form class="form-horizontal" name="announcementForm" @submit="$event.preventDefault(); Save()">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputTitle">名稱：</label>
                <div class="col-sm-9">
                  <input type="text" id="inputTitle" class="form-control" v-model="edit.note" required placeholder="辨識用，前端用於圖片alt" /></div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputContent">內容：</label>
                <div class="col-sm-9">
                  <textarea id="inputContent" class="form-control" v-model="edit.note" rows="3" placeholder=""></textarea>
                </div>
              </div>
              <div class="form-group image-part">
                <label class="col-sm-3 control-label required" for="AdPic">圖片：</label>
                <div class="col-sm-9">
                  <input type="file" id="AdPic" accept="image/webp, image/png, image/gif" @change="onFileChange($event)" />
                  <p class="help-block text-red">※檔案尺寸 960&times;540px，小於{{this.imgMaxSize}}Kb，格式 webp、png、gif</p>
                  <img :src="previewImg" draggable="false" alt="" style="max-height:200px;" />
                </div>
              </div>

              <div class="form-group bannerlink-part">
                <label class="col-sm-3 control-label required" for="inputLink">連結：</label>
                <div class="col-sm-9">
                  <input type="url" id="inputLink" class="form-control" v-model="edit.bannerLink" required />
                  <label class="mt-7">
                    <input type="checkbox" :checked="!!edit.isLinkOpen" @change="edit.isLinkOpen = +$event.currentTarget.checked">
                    另開視窗
                  </label>
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 置頂：
                </label>
                <div class="col-sm-9">
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.isStop" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.isStop" />
                  </div>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-3 control-label required" for="inputOrderNum">排序：</label>
                <div class="col-sm-9">
                  <input type="number" id="inputOrderNum" class="form-control" v-model.number="edit.orderNum" min="0" />
                </div>
              </div>

              <!-- <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-9">
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="0" message="上架" name="radioMode" v-model="edit.isStop" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="1" message="下架" name="radioMode" v-model="edit.isStop" />
                  </div>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-3 control-label required">上架時間：</label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.testStartTime" format="YYYY/MM/DD HH:mm:ss" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label required">下架時間：</label>
                <div class="col-sm-9">
                  <DateAndTimePicker v-model="edit.testEndTime" format="YYYY/MM/DD HH:mm:ss" />
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="col-sm-3 control-label" for="inputNote">
                  備註：
                </label>
                <div class="col-sm-9">
                  <textarea id="inputNote" class="form-control" v-model="edit.note" rows="3" required placeholder=""></textarea>
                </div>
              </div> -->
            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
          <!-- <pre>{{ edit }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
/*
  .input-group{
    width: max-content;
    margin: 0 auto;
    .form-control{
      padding-inline: .35em;
      text-align: center;
    }
  }
  */
  input[type="checkbox"]{
    position: relative;
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub;
    margin-top: -.2em;
    accent-color: #3498db;
    cursor: pointer;
  }
  label.required::before{content: "* "; color: #dd4b39 }
  .form-control.sort{
    display: inline-block;
    width: 54px;
    padding: 5px;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
  }
</style>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
// import define from "@/views/projects/XSG/_Define";
import Pagination from "@/components/Pagination";
import DateAndTimePicker from "@/components/DateAndTimePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
    DateAndTimePicker,
    iCheckCheckBox,
    iCheckRedioButton
  },
  props: {
    id: "",
  },
  data() {
    return {
      bannerType: "",
      // def: define,
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        // projectId: this.$store.state.projectId,
        module: "banner",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      coverMode: false, // 蓋板廣告(賀緹)
      hasIsOn: '',
      filterStatus: "",
      previewImg: "",
      imgMaxSize: 200 /* kb */,

      edit: {
        bannerId: utils.getTimeId(), //廣告Id
        classTypeId: "", //分類Id
        bannerTitle: "", 
        bannerContent: "", // 內文
        bannerLink: "", // 網址 => 標題
        note: "",
        isStop: 0, // 狀態
        isLinkOpen: 0, // 另開視窗
        orderNum: 1, // 排序
        filesUrl: "", // 圖片
        formalStartTime: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        formalEndTime: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
        testStartTime: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        testEndTime: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),

        tags: ["test", 'readonly'],
        art: [],
        type: [],
      },
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetBannerClassTypes();
    this.GetBannerQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      bannerTypes: (state) => state.nmjClassTypes.queryAll,
      banners: (state) => state.nmjWebBanner.queryAll,
      response: (state) => state.nmjWebBanner.response,
    }),
    filteredBanners(){
      let filtered = [];
      if (!this.banners.datas) return filtered;
      if (!this.filterStatus) filtered = this.banners.datas;
      else for (let item of this.banners.datas){
        if (this.GetStatus(item) == this.filterStatus) filtered.push(item);
      } 
      return filtered;
    },
    pagedBanners(){
      return this.filteredBanners.slice((this.searchData.pageNum-1) * this.searchData.pageSize, (this.searchData.pageNum) * this.searchData.pageSize)
    }
  },
  watch: {
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetBannerQueryAll();
    },
    // banners() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.filteredBanners.length
    //   );
    // },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      // this.GetBannerQueryAll();
    },
    async onFileChange(event){
      let file = event.target.files[0];
      if (file.size > (this.imgMaxSize * 1024)){
        this.$eventBus.$emit("showAlert", `上傳圖片不可大於${this.imgMaxSize}Kb`);
        // event.target.value = "";
        // this.previewImg = "";
        return;
      }
      console.log(file)
      const reader = new FileReader();
      reader.onload = () => { this.previewImg = reader.result};
      reader.readAsDataURL(file);
    },

    GetBannerQueryAll() {
      //列表
      let getAll = Object.assign({}, this.searchData);
      delete getAll.pageNum;
      delete getAll.pageSize;
      console.log(getAll)
      this.$store.dispatch("nmjWebBanner/bannerQueryAll", getAll);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, filteredBanners.length);
    },

    async GetBannerClassTypes() {
      //分類
      this.$store.dispatch("nmjClassTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "banner",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    updateBannerOd(item) {
      let data = this.searchData;

      api.nmjWebBanner.queryAll({
        module: "banner",
        classTypeId: item.classTypeId,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
        pageNum: 1,
        pageSize: 1, //每頁顯示筆數
      }).then((res) => {
        if (res.data.status == "1") {
          let count = parseInt(res.data.result.total);
          item.orderNum = parseInt(item.orderNum);
          if (item.orderNum == 0 || isNaN(item.orderNum)) item.orderNum = 1;
          // item.isStop = parseInt(item.isStop);
          if (item.orderNum > count) {
            this.$eventBus.$emit(
              "showAlert",
              "排序不可大於最大排序(" + count + ")!!"
            );
            return;
          }

          this.$store.dispatch("nmjWebBanner/bannerUpdate", item);
        }
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetBannerQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "NmjWebBannerEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({ params: { id: item.bannerId } });
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjWebBanner/bannerDelete", item).then(()=>{
            this.GetBannerQueryAll();
          });
        }
      });
    },
    GetStatus(item) {
      if (!item.formalStartTime || !item.formalEndTime) return "無設時間";
      if (new moment().isBefore(item.formalStartTime)) return "等待上線";
      if (new moment().isAfter(item.formalEndTime)) return "已過期";
      return "進行中";
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>