<template>
  <div id="PlayerDataList">
    <section class="content-header">
      <h1>玩家資料查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">玩家資料查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <label class="control-label ml-25">
                <iCheckCheckBox
                  :propValue="1"
                  :propChecked="timeTypes.includes(1)"
                  v-on:update:checked="checkChange"
                />&nbsp;創建帳號時間：
              </label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propEndDate.sync="searchData.local.createETime"
                    :propStartDate.sync="searchData.local.createSTime"
                    :propTimePicker="true"
                  />
                </div>
              </div>
            </div>
            <div class="clear form-group">
              <label class="control-label ml-25">
                <iCheckCheckBox
                  :propValue="2"
                  :propChecked="timeTypes.includes(2)"
                  v-on:update:checked="checkChange"
                />&nbsp;上次登入時間：
              </label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propEndDate.sync="searchData.local.loginETime"
                    :propStartDate.sync="searchData.local.loginSTime"
                    :propTimePicker="true"
                  />
                </div>
              </div>
            </div>
            <div class="clear input-group">
              <input
                class="form-control"
                placeholder="玩家UID"
                type="text"
                v-model.trim="searchData.playUId"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="玩家暱稱"
                type="text"
                v-model.trim="searchData.playNickname"
              />
            </div>
            <div class="btns">
              <button
                @click="resetDataList()"
                class="btn btn-primary"
                type="button"
              >
                查詢
              </button>
              <button
                class="btn btn-default"
                type="button"
                @click="exportOrders()"
              >
                匯出
              </button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap" style="min-width: 100px">玩家UID</th>
                  <th class="text-nowrap" style="min-width: 100px">玩家暱稱</th>
                  <th>等級</th>
                  <th class="text-nowrap">等級經驗值</th>
                  <th>VIP等級</th>
                  <th class="text-nowrap">VIP經驗值</th>
                  <th class="text-nowrap">金幣點數</th>
                  <th>鑽石點數</th>
                  <th class="text-nowrap" style="min-width: 150px">
                    創建帳號時間
                  </th>
                  <th class="text-nowrap" style="min-width: 150px">
                    上次登入時間
                  </th>
                  <th>玩家歷程</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="result.message">
                  <td colspan="11">{{ result.message }}</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="11">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-nowrap">
                    {{ item.playUid || item.playUId }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.playNickname }}
                  </td>
                  <td>
                    {{ item.level | currencyFormat }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.exp | currencyFormat }}
                  </td>
                  <td>
                    {{ define.vipStatus[item.vipLevel] }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.vipExp | currencyFormat }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.gold | currencyFormat }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.diamond | currencyFormat }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.createTime }}
                  </td>
                  <td class="text-nowrap">
                    {{ item.loginTime }}
                  </td>
                  <td>
                    <button
                      class="btn btn-primary"
                      @click="playerHistoryList(item)"
                    >
                      歷程
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DateRangePicker,
    iCheckCheckBox,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          createSTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          createETime: new moment().format("YYYY-MM-DD 23:59:59"),
          loginSTime: new moment()
            .subtract(6, "days")
            .format("YYYY-MM-DD 00:00:00"),
          loginETime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
        playUId: "",
        playNickname: "",
      },
      result: {},
      datas: [],
      timeTypes: [],
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    setTimeout(() => {
      this.timeTypes = [1];
      this.getDataList();
    }, 100);
  },
  methods: {
    getDataList() {
      this.updateTime();
      this.$store
        .dispatch("fcPlayerData/queryAll", this.searchData)
        .then((result) => {
          this.result = result;
          this.datas = result.datas || [];
          this.total = result.total;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    updateTime() {
      this.searchData.createSTime = null;
      this.searchData.createETime = null;
      this.searchData.loginSTime = null;
      this.searchData.loginETime = null;
      if (this.timeTypes.includes(1)) {
        this.searchData.createSTime = new Date(
          `${this.searchData.local.createSTime} UTC`
        );
        this.searchData.createETime = new Date(
          `${this.searchData.local.createETime} UTC`
        );
      }
      if (this.timeTypes.includes(2)) {
        this.searchData.loginSTime = new Date(
          `${this.searchData.local.loginSTime} UTC`
        );
        this.searchData.loginETime = new Date(
          `${this.searchData.local.loginETime} UTC`
        );
      }
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    checkChange(data) {
      if (data.checked && !this.timeTypes.includes(data.value)) {
        this.timeTypes.push(data.value);
      } else if (!data.checked && this.timeTypes.includes(data.value)) {
        const index = this.timeTypes.indexOf(data.value);
        if (index !== -1) this.timeTypes.splice(index, 1);
      }
    },
    exportOrders() {
      this.$store.dispatch("fcPlayerData/export", this.searchData);
    },
    playerHistoryList(item) {
      this.$router.push({
        name: "PlayerHistoryList",
        params: {
          id: item.playUid || item.playUId,
        },
      });
    },
  },
};
</script>