<template>
  <header class="main-header">
    <a class="logo" href="/#/Home">
      <h1 class="sr-only">網銀國際 OMP 營運管理平台</h1>
    </a>

    <nav class="navbar navbar-static-top">
      <a class="sidebar-toggle" data-toggle="push-menu" href="#" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>

      <h2 class="name-websit" v-if="projectId != 'omp_slot'">營運整合工具</h2>

      <div class="header-search" v-if="projectId == 'omp_slot'">
        <form @submit.prevent="search()">
          <input type="text" placeholder="全站搜尋(APP、遊戲、集成分析" v-model="keyword">
          <i class="fa fa-search" @click="search()"></i>
          <i class="fa fa-close" v-if="keyword != ''" @click="keyword = ''"></i>
        </form>
      </div>

      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown">
            <MessageSystemSideTool :MSProject="projectId"
              v-if="($user.hasPermission('ChannelManager') || $user.hasPermission('MessageSystem')) && $store.state.jwtToken != ''">
            </MessageSystemSideTool>
          </li>
          <li class="dropdown project-select"
            v-if="projectId === 'omp_nmj' && !/^(stage|production)$/.test(environment)">
            <select class="form-control" v-model="environment" @change="changeEnvironment">
              <option value="development">測試版API</option>
              <option value="test">審核版API</option>
            </select>
          </li>
          <li class="dropdown project-select" v-if="langs.length > 0">
            <select class="form-control" :value="lang" @change="changeLang">
              <option v-for="lang of langs" :key="lang.id" :value="lang.id">{{ lang.name }}
              </option>
            </select>
          </li>
          <li class="dropdown project-select">
            <select class="form-control" id="selectUnit" required="required" v-model="projectId"
              @change="changeProject">
              <option :key="index" :value="item.projectId" v-for="(item, index) in projects">{{ $t(item.projectName) }}
              </option>
            </select>
          </li>
          <li class="dropdown user user-menu">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#">
              <img alt="User Image" class="user-image" src="../assets/user-160x160.jpg" />
              <span class="hidden-xs">{{ loginUser.EmployeeName }}</span>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <img alt="User Image" class="img-circle" src="../assets/user-160x160.jpg" />
                <p>
                  {{ loginUser.DeptName }} - {{ loginUser.EmployeeName }}
                  <br />
                  {{ loginUser.EmpTitleName }}
                  <!-- <small class="mt-5">2018/12/01 到職</small> -->
                </p>
              </li>
              <li class="user-footer">
                <!-- <div class="pull-left">
                  <router-link class="btn btn-default btn-flat" to="/">個人資料</router-link>
                </div>-->
                <div class="text-center">
                  <button class="btn btn-default btn-flat mr-10" v-on:click="logout">登出</button>
                  <button class="btn btn-danger btn-flat" v-on:click="midify" v-if="loginUser.IsOutsider == 1">
                    編輯
                  </button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <midify :proplogin="true" ref="midify" />
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable */
import api from "../assets/js/app-api";
import { mapState, mapActions } from "vuex";
import midify from "@/views/Login/midify";
import MessageSystemSideTool from "@/views/projects/MessageSystem/MessageSystemSideTool.vue";
import { loadLanguageAsync, loadLangByProject, ProjectLang } from '@/assets/js/i18nFunc';
export default {
  components: {
    midify,
    MessageSystemSideTool
  },
  data() {
    return {
      projectId: "",
      keyword: "",
      lang: "未設定",
      langs: [
        // { id: "zh-TW", name: 'TW' }, { id: "th-TH", name: 'TH' }
      ],
      environment: process.env.NODE_ENV,
    };
  },
  mounted() {
    this.projectId = this.stateProjectId;
    this.langs = ProjectLang.getLangSetting(this.projectId).langList;
    loadLangByProject(this.projectId);
    // console.log("MOUNT:",this.environment);
    if (this.projectId !== "omp_nmj" && this.environment === 'test') {
      location.replace(location.origin + '/' + location.hash);
    }
  },
  computed: mapState({
    loginUser: state => state.loginUser,
    projects: state => state.loginUserMenu,
    stateProjectId: state => state.projectId,
  }),
  watch: {
    stateProjectId() {
      this.projectId = this.stateProjectId;
      this.langs = ProjectLang.getLangSetting(this.projectId).langList;
    },
    ["$root.$options.i18n.locale"](v) {
      this.lang = v;
    }
  },
  methods: {
    changeProject() {
      this.$router.push({ name: 'gotoProject', params: { projectId: this.projectId } });
      console.log(this.projectId, this.environment);
    },
    changeLang(e) {
      loadLanguageAsync(e.target.value, this.projectId);
    },
    changeEnvironment() {
      location.assign(location.origin + (this.environment === 'test' ? '/nmjtest/' : '/') + location.hash);
    },
    logout() {
      this.$store.dispatch("messageSystemServer/是否要通知Server斷掉其它Client", true);
      this.$store.dispatch("loginOut");
      this.$router.push("/login");
      api.login.logout();
    },
    midify() {
      this.$refs.midify.showMidifyBox();
    },
    search() {
      if (!this.keyword) return
      this.$router.push({
        name: 'slotSearch',
        params: {
          keyword: this.keyword
        },
      });
    },
  }
};
</script>
