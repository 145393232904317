<template>
    <div id="ForumMuteSetting">
        <section class="content-header">
            <h1>流量查詢</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">流量查詢</li>
            </ol>
        </section>
        <section class="content forum-mute">
            <div class="box box-primary max850">
                <div class="box-body">
                    <div class="top-box">
                        <div class="form-group clearfix">
                            <label class="control-label">開始日期：</label>
                            <div class="input-group date">
                                <div class="input-group-addon">
                                    <i class="fa fa-calendar"></i>
                                </div>

                                <DateRangePicker v-bind:propEndDate.sync="searchData.eTime"
                                    v-bind:propStartDate.sync="searchData.sTime" v-on:restList="advDateSearch()"
                                    propsIn="format2" />
                            </div>
                        </div>

                        <select class="form-control select-group" v-model="searchData.game" @change="search()">
                            <option disabled value="_">請選擇遊戲館</option>
                            <option v-for="(item, key) in gameList" :value="item.value" :key="key">
                                {{ item.text }}
                            </option>
                        </select>

                        <button @click="search()" class="btn btn-primary" type="button">
                            <i class="fa fa-search"></i> 查詢
                        </button>

                        <span class="data-num mt-15 ml-10">
                            <span>筆數：</span>
                            <span id="total"> {{ xinTotal }} </span>
                        </span>

                        <button @click="exportData()" class="btn btn-block btn-success btn-add mt-10-sm" type="button">
                            <i class="fa fa-upload"></i> 匯出
                        </button>
                    </div>

                    <div class="table-responsive mt-10">
                        <table class="table table-bordered table-hover min1000">
                            <tbody>
                                <tr class="bg-gray">
                                    <th style="min-width: 150px;">日期</th>
                                    <th style="min-width: 150px;">總流量</th>
                                </tr>
                                <tr v-if="xinTotal === 0">
                                    <td class="text-center" colspan="2">查無資料</td>
                                </tr>
                                <tr v-for="(item, index) in xinList" :key="index">
                                    <td class="text-center">{{ item.date }}</td>

                                    <td class="text-right">
                                        {{ item.amount | currencyFormat }}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-right" colspan="3">
                                        合計
                                        <b>{{
                                            calcTotalAmount | currencyFormat
                                        }}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import DateRangePicker from "@/components/DateRangePicker";

export default {
    components: {
        DateRangePicker
    },
    data() {
        return {

            gameList: [
                { value: "星城總流量", text: "星城總流量" },  //全部星城的遊戲, 不只4個
                // { value: "", text: "全部遊戲" },  //先保留，以後用
                { value: "式神繪卷", text: "式神繪卷" },
                { value: "逗陣捕魚季", text: "逗陣捕魚季" },
                { value: "神鑰", text: "神鑰" },
                { value: "81 Keys SLOT", text: "81 Keys SLOT" },
                { value: "OVERLORD", text: "OVERLORD" },
                { value: "動物朋友", text: "動物朋友" }
                // { value: "8878978605", text: "式神繪卷" },
                // { value: "19980982498", text: "逗陣捕魚季" },
                // { value: "34827754500", text: "神鑰" },
                // { value: "43679573000", text: "81 Keys SLOT" }
            ],

            searchData: {
                // sTime: new moment().subtract(365, "d").format("YYYY-MM-DD"),
                // eTime: new moment().subtract(365, "d").format("YYYY-MM-DD"),
                sTime: new moment().format("YYYY-MM-DD"),
                eTime: new moment().format("YYYY-MM-DD"),
                game: "星城總流量",
            },
            totalAmount: 0,
        };
    },
    mounted() {
        this.search();
    },

    computed: {
        ...mapState({
            xinList: state => state.xinAccount.xinList,
            xinTotal: state => state.xinAccount.xinTotal
        }),

        calcTotalAmount() {
            this.totalAmount = 0;
            this.xinList.map(item => {
                this.totalAmount += Number(item.amount);
                // console.log( item.game + item.date + " ,金額:" + Number(item.amount)  + " ,等於=" + this.totalAmount)
            });
            return this.totalAmount;
        }
    },

    methods: {
        search() {
            this.totalAmount = 0;
            this.$store.dispatch("xinAccount/search", this.searchData);
        },


        advDateSearch() {
            this.totalAmount = 0;
            this.search();
        },

        exportData() {
            this.$store.dispatch("xinAccount/export", this.searchData);
        },

    }
};
</script>
