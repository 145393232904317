<template>
	<div id="ClassTypeManager" class="slot searchList">
		<section class="content-header">
			<h1>玩法分類</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li class="active">玩法分類</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<button @click="add()" class="btn btn-block btn-warning btn-add" type="button">
							<i class="fa fa-plus"></i> 新增
						</button>
						<div class="input-group">
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="搜尋關鍵字"
								type="text"
							/>
							<div class="input-group-btn">
								<!-- <button v-on:click="clickSearch()" class="btn btn-default" type="button">
									<i class="fa fa-search"></i>
								</button> -->
								<button class="btn btn-default" type="button">
									<i class="fa fa-search"></i>
								</button>
							</div>
						</div>
						<div class="text">
							<p class="data-num">
								<span>資料筆數：</span>
								<span id="total">1</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th style="min-width: 150px;" width="50%">分類名稱</th>
									<th style="min-width: 97px;">玩法數</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
								</tr>
								<tr :key="index" v-for="(item, index) in testList">
									<td>{{ item.name }}</td>
									<td class="text-nowrap text-center">{{ item.count }}</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="1"
												:id="'check'+index"
												:true-value="0"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.isStop"
											/>
											<label :for="'check'+index"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border">
          <select
            class="form-control page-size"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="1"
            :total="1"
            ref="pagination"
          />
        </div>
			</div>
			<!--新增-->
			<div class="modal fade" ref="create">
				<div class="modal-dialog modal-sm">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title text-center">新增名稱</h4>
						</div>
						<div class="modal-body">
							<input v-if="!isEdit" type="text" class="form-control" placeholder="開發商名稱">
							<input v-else-if="editData.name" type="text" class="form-control" placeholder="開發商名稱" v-model="editData.name">
						</div>
						<div class="modal-footer text-center">
							<button class="btn btn-default" data-dismiss="modal" type="button" @click="cancel()">取消</button>
							<button class="btn btn-primary" type="button" @click="submit()">儲存</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
  // import api from "../assets/js/app-api";
	import Pagination from "@/components/Pagination";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
		},
    delimiters: ["${", "}"],
    data() {
      return {
				isEdit: false,
				editData: {},
				testList: [
					{
						name: 'Century Games Pte. Ltd.',
						count: 7,
						time: '2022-10-25 10:54:00',
						isStop: 0,
					},
					{
						name: 'GSN Games, Inc',
						count: 9,
						time: '2022-10-25 10:54:00',
						isStop: 1,
					},
					{
						name: 'Murka Games Limited',
						count: 4,
						time: '2022-10-25 10:54:00',
						isStop: 1,
					},
					{
						name: 'Playtika',
						count: 5,
						time: '2022-10-25 10:54:00',
						isStop: 1,
					},
					{
						name: 'Playtika UK – House of Fun  Limited',
						count: 3,
						time: '2022-10-25 10:54:00',
						isStop: 1,
					}
				]
      };
    },
    created() {
    },
    mounted() {},
    computed: {},
    watch: {},
    methods: {
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要停用開發商？
							已建立的遊戲在前台將隱藏`,
				isOK => {
					if (isOK) {
						console.log('ss');
					}
				});
			},
			add() {
				$(this.$refs.create).modal("show");
			},
			edit(item) {
				this.isEdit = true;
				this.editData = item;
				$(this.$refs.create).modal("show");
			},
			cancel() {
				this.isEdit = false;
				this.editData = {};
			}
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>