<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>首頁輪播</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>輪播列表</li>
        <li class="active">輪播編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">        
              <!--div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"                     
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div-->
            <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle4">
                  <span class="text-red">*</span> 後台標題：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle4"
                    required="required"
                    type="text"        placeholder="僅在後台顯示"     
                          v-model="title"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputcolor1"> 標題顏色(英)：</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputcolor1"
                    style="    padding: 0;    width: 35px;"
                    type="color"
                    v-model="subject1Color"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle1"> 標題第一行(英)：</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle1"                    
                    type="text"
                    v-model="edit.subject1"
                  />
                </div>
              </div>
                            <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle2"> 標題第二行(英)：</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle2"
                    
                    type="text"
                    v-model="edit.subject2"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputcolor2"> 中文標題顏色：</label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputcolor2"
                    style="    padding: 0;    width: 35px;"
                    type="color"
                    v-model="subject2Color"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle3">
                中文標題：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle3"
                  
                    type="text"
                     v-model="edit.subject3"
                  />
                </div>
              </div>


              <div class="form-group">
                <label class="col-sm-2 control-label" for="Pic">
                  說明：
                </label>
                <div class="col-sm-10">
                  <textarea
                    class="form-control"
                    required="required"
                    placeholder="內容...."
                    v-model="info"
                    style="height:150px;"
                  ></textarea>
                </div>
              </div>


              <div class="form-group thetype">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 背景類型：
                  <div class="noedit">不可更改</div>
                </label>
                <div class="col-sm-10">
                 <div class="radio">
                    <iCheckRedioButton
                      checkValue=1
                      message="圖片"
                      name="fileMode"
                      v-model="edit.contentUrlType"
                    />
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue=2
                      message="影片"
                      name="fileMode"
                      v-model="edit.contentUrlType"
                    />
                  </div>
             
                </div>
              </div>



              <div class="form-group image-part" v-if="edit.contentUrlType == 1">
                <label class="col-sm-2 control-label" for="AdPic">
                  <span class="text-red">*</span> 
                  大圖
                </label>
                <div class="col-sm-9">
                  <div class="pic">
                    <img style="max-width: 30%;" draggable="false" :src="edit.contentUrl" />
                  </div>
                  <input
                    accept="image/*"
                    id="AdPic"
                    type="file"
                    @change="onFileChange($event,'main')"
                  />
                  <div class="help-block">
                    <span class="text-red" >大圖建議尺寸 {{imageSize}}px。</span>
                  </div>
                </div>
              </div>


              <div class="form-group" v-if="edit.contentUrlType == 2">
                <label class="col-sm-2 control-label" for="videoUrl">
                        <span class="text-red">*</span> 
                  影片連結：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="videoUrl"
                    type="text"
                    v-model="edit.contentUrl"
                  />
                </div>
              </div>

              <div class="form-group image-part" >
                <label class="col-sm-2 control-label" for="AdPic2">
                  小圖：
                </label>
                <div class="col-sm-9">
                  <div class="pic">
                    <img style="max-width: 30%;" draggable="false" :src="edit.smallImgFullFileName" />
                  </div>
                  <input
                    accept="image/*"
                    id="AdPic2"
                    type="file"
                    @change="onFileChange($event,'small')"
                  />
                  <div class="help-block">
                    <span class="text-red" >小圖建議尺寸 {{awardsImgSize}}px。</span>
                  </div>
                </div>        
              </div>                       


              <!--div class="form-group" v-if="edit.classTypeId != awardsClassType">
                <label class="col-sm-2 control-label" for="inputLink">
                  網址：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputLink"
                    required="required"
                    type="text"
                    v-model="edit.contentUrl"
                  />
                </div>
              </div-->

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                     <!--div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker" type="text" />
                    </div-->
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div>

            </div>

            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
  .thetype .noedit{
    display: none;
  }
  .thetype.noedit .noedit{
    display: block;
    color: #dd4b39;
    font-size: 14px;
    position: absolute;
    right: 29px;
  }
  .thetype.noedit{
  pointer-events: none;
  }


</style>
<script>
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      projectId: 'omp_xac',
      isEdit: false,
      backPage : "XacCarouselList",
      module: "carousel",
      editLang: "zh-tw",
      langTypes: {},
      detailGameLang: [],
      //contentUrlType:1,
      awardsClassType: "",
      imageSize: "2500 * 1330",
      awardsImgSize: "940 * 300",
      title: "", //標題
      info: "", //內容
      subject1Color:'#343434',
      subject2Color:'#008bd3',
      edit: {
        contentId: utils.getTimeId(), //Id
        projectId: 'omp_xac', // 專案Id
        classTypeId: "show", //分類Id
        contentUrl: "", // 圖片或影片的路徑
        contentUrlType:1,
        subject1:"",//主標題1
        subject2:"",//主標題2 
        subject3:"",//後台標題
        smallImgFullFileName:"",//小圖完整路徑
        //videoUrl: "", // 影片網址
        //filesUrl:"",
        //contentDetailTopId: "", // 內容Id
     //   Title: "", //標題
    //    Info: "", //內容
      //  publishTime: "",
        //isTurnPlay: 0, //輪播
        isStop: 0, // 狀態
       // sortId: 0 // 排序
      },
      imgList: [],
      timeStringOption: {timeStyle: "short"}
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {
    $(".timepicker").timepicker({
      showInputs: false
    });
  },
  computed: {
    ...mapState({
   //   gameTypes: state => state.classTypes.queryAll,
      game: state => state.game.query,
      detailQueryAll: state => state.game.detailQueryAll,
      projectData: state => state.module.queryAll,
      response: state => state.game.response,
      fileUploadResponse: state => state.file.response,
      //imgUrlList: state => state.game.imgList
    })
  },
  watch: {
    game() {
      this.edit = this.game;
      this.title= this.game.detailTopTitle;
      this.info= this.game.detailTopInfo;


      var colorMatch1 = this.edit.subject1.match(/-#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/);
      var colorMatch2 = this.edit.subject2.match(/-#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/);
      var colorMatch3 = this.edit.subject3.match(/-#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/);
      if (colorMatch1) {
          var extractedColor1 = colorMatch1[0];
          this.edit.subject1=this.edit.subject1.replace(extractedColor1, '');
          this.subject1Color=colorMatch1[0].replace('-', '');
      } else {
          console.log("未找到匹配的颜色");
      }
      if (colorMatch2) {
          var extractedColor2 = colorMatch2[0];
          this.edit.subject2=this.edit.subject2.replace(extractedColor2, '');
          this.subject1Color=colorMatch2[0].replace('-', '');
          console.log(extractedColor2)
      } else {
          console.log("未找到匹配的颜色");
      }
      if (colorMatch3) {
          var extractedColor3 = colorMatch3[0];
          this.edit.subject3=this.edit.subject3.replace(extractedColor3, '');
          this.subject2Color=colorMatch3[0].replace('-', '');
       
      } else {
          console.log("未找到匹配的颜色");
      }          

      if(this.game.detailTopTitle != ""){
         $(".thetype").addClass("noedit");
      }
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetGameData();
        
      } else {
        this.detailGameLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.contentId + i + 1;
          this.detailGameLang.push({
            gameDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.title,
            info: this.info
          });
        }
      }
    },
    detailQueryAll() {
      let that = this;
      this.detailGameLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          const Id = this.edit.contentId + i + 1;
          let findLang = false;
          this.detailQueryAll.datas.some(details => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.gameDetailId = details.gameDetailId;
                that.title = details.title;
                that.info = details.info;
              }
              that.detailGameLang.push({
                gameDetailId: details.gameDetailId,
                lang: details.lang,
                title: details.title,
                info: details.info
              });
              findLang = true;
              return true;
            }
          });
          if (!findLang) {
            that.detailGameLang.push({
              gameDetailId: Id,
              lang: that.langTypes[i].id,
              title: "",
              info: ""
            });
          }
        }
        //this.GetImgList();
      }
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      }
      // } else {
      //   if (this.isEdit) {
      //     this.$store.dispatch("banner/bannerUpdate", this.edit);
      //   } else {
      //     this.$store.dispatch("banner/bannerCreate", this.edit);
      //   }
      // }
    },
    
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");
        this.backList();        
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
      
    },
    imgUrlList() {
      this.imgList = [];
      this.imgList = this.imgUrlList;
    },
   /* gameTypes(){
      if(this.gameTypes.datas.length > 0) {
        let findIndex = this.gameTypes.datas.findIndex(element => element.classId == "awards");
        if(findIndex != -1)  this.awardsClassType = this.gameTypes.datas[findIndex].classTypeId;
      }
    }*/
  },
  methods: {
    //取得資料
    GetGameData() {
      this.$store.dispatch("game/gameQuery", {
        contentId: this.$route.params.id
      });
      

    },
    //取得該銷售據點多語系資料
    /*GetGameLangData() {
      this.$store.dispatch("game/gameDetailQueryAll", {
        contentId: this.$route.params.id
      });
    },*/

    //取得分類資料
   /* GetGameClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: this.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },*/
  
    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetImgList() {
     // this.SetLangData(this.editLang);
      this.$store.dispatch("game/gameImgList", {
        contentId: this.edit.contentId
      });
    },

    //取得該語系資料
    GetDetailGameLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.gameDetailId = this.detailGameLang[i].gameDetailId;
          this.title = this.detailGameLang[i].title;
          this.info = this.detailGameLang[i].info;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      // this.removeFile();
      // this.SetLangData(this.editLang);
      // this.GetDetailGameLang(lang);
      // this.editLang = lang;
      //this.GetImgList();
    },

    //儲存該語系資料
    SetLangData(lang) {
 
      if (this.isEdit) {
        
        this.detailGameLang.push({
            gameDetailId: this.contentId,
            lang: this.editLang,
            title: this.title,
            info: this.info
        });

      }else{

        for (var i = 0; i < this.langTypes.length; i++) {
            if (this.langTypes[i].id == lang) {
              this.detailGameLang[i].title = this.title;
              this.detailGameLang[i].info = this.info;
            }
        }

      }
   
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: this.backPage,
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt,from) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;
      
        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
         // this.removeFile();
          return;
        }
      }
   
       if(from == "small"){
          this.uploadFile(files[0], this.module, this.edit.contentId, false,false,'small');       
      }else{
        if(this.edit.contentUrlType != 2){
          this.uploadFile(files[0], this.module, this.edit.contentId, false,false,'contentUrl');     
        }            
      }
        //檔案上傳
        // this.uploadFile(
        //   files[0],
        //   this.module,
        //   this.edit.contentId,
        //   false,
        //   false
        // );
      //this.preview(files,from);
    },
    
    // removeFile() {
    //   document.getElementById("AdPic").value = "";
    // },

    uploadFile(files, folderName, id, multi = false, override = false,from) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", 'xac_home');
      data.append("moduleId", id);
      data.append("lang", this.editLang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data).then(res => {
        if (res.status != "0"){
          if(from == 'small'){
            this.edit.smallImgFullFileName = res.url;
          }else{
            this.edit.contentUrl = res.url;
          }             
        }
      });

    },

    //圖片預覽
    // preview(input,from) {
    //   let that = this;

    //   if (input && input[0]) {
    //     var reader = new FileReader();
    //     reader.onload = function (e) {
    //       if(from == "small"){
    //         that.edit.smallImgFullFileName = e.target.result;
    //       }else{
    //         if(that.edit.contentUrlType != 2){
    //           that.edit.contentUrl = e.target.result;
    //         }            
    //       }
          
    //     };
        
    //     reader.readAsDataURL(input[0]);
    //   }
    // },

    //小圖預覽
  /*  previewS(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          that.edit.smallImgFullFileName = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },*/

    //送出
    async Save() {
      console.log(this.subject1Color)
      console.log(this.subject2Color)

      this.edit.subject1=this.edit.subject1+'-'+this.subject1Color;
      this.edit.subject2=this.edit.subject2+'-'+this.subject1Color;
      this.edit.subject3=this.edit.subject3+'-'+this.subject2Color;

      this.edit.isStop = parseInt(this.edit.isStop);
      this.SetLangData(this.editLang);
   //   this.edit.publishTime = $(".datepicker").val() + " " + $(".timepicker").val();
    //  this.edit.publishTime = new Date(this.edit.publishTime + " UTC");
      this.edit.contentUrlType=parseInt( this.edit.contentUrlType,10 );

     /* if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }*/
     if (this.title == "" ) {
        this.$eventBus.$emit("showAlert", "後台標題不能為空");
        return;
      }

      // if (this.title == "") {
      //   this.$eventBus.$emit("showAlert", "標題不能為空");
      //   return;
      // }

     /* if (this.info == "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return;
      }*/

      if (this.edit.contentUrlType == 1 && this.edit.contentUrl == "") {
        this.$eventBus.$emit("showAlert", "請新增大圖");
        return;
      }

      if (this.edit.contentUrlType == 2 && this.edit.contentUrl == "") {
        this.$eventBus.$emit("showAlert", "影片網址不能為空");
        return;
      }


      //this.uploadFile(files, this.module, this.edit.contentUrl, true);


      // if (this.edit.smallImgFullFileName == "") {
      //   this.$eventBus.$emit("showAlert", "請新增小圖");
      //   return;
      // }
  
      if (this.isEdit) {
        this.$store.dispatch("game/gameUpdate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      } else {
        this.$store.dispatch("game/gameCreate", {
          master_model: this.edit,
          detail_model: this.detailGameLang
        });
      }
    }
  }
};
</script>