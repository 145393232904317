<template>
  <div>
    <div class="g-img" @click="goContent(item)">
      <img :src="item.封面" alt="">
    </div>
    <div class="g-name mt-5 mb-10" @click="goContent(item)">{{ item.文章標題 }}</div>
    <div class="g-detail" @click="goContent(item)">{{ item.文章摘要 }}</div>
    <div class="g-link" @click="goApp(item.遊戲編號)">
      <div class="c-img mr-10">
        <img :src="item.圖示" alt="">
      </div>
      <div>
        <p class="c-name">{{ item.遊戲名稱 }}</p>
        <p class="g-time">{{ item.發佈時間 | dateTimeFormat }}發佈</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props:['item'],
  methods: {
    goContent(item) {
      this.$router.push({
        name: 'slotAnalyze',
        params: {
          id: item.編號,
          backSearchData: this.searchData
        }
      });
    },
    goApp(id) {
      this.$router.push({
        name: 'slotClientGameHallList',
        params: {
          id: id,
        }
      });
    }
    // showPlay(id) {
    //   if(id) this.$refs.slotPlayContent.getData(id);
    //   else this.$refs.slotPlayContent.showContent = true;
    // },
    // imgSize(index) {
    //   let h = $('#img_' + index).height();
    //   let w = $('#img_' + index).width();
    //   if(h > w) return 'height: 100%;'
    // },
  },
};
</script>