<template>
  <multiselect
    v-model="modelValue"
    :options="options"
    :option-height="100"
    :name="name"
    @tag="addTag"
    :disabled="readonly"
    :class="{readonly: readonly}"
    class="tagselect"

    multiple hide-selected
    :taggable="taggable"
    :label="label"
    :trackBy="label"
    :placeholder="readonly ? '無標籤' : (taggable ? '選擇或新增標籤' : '選擇標籤')"
    selectLabel="選擇按 Enter"
    deselectLabel="移除按 Enter"
    selectedLabel="已選擇"
    tagPlaceholder="建立新標籤按 Enter "
    :showNoOptions="false"
  >
    <template v-slot:tag="{ option, remove }">
      <div class="multiselect__tag" :style="{background:colorHash(typeof option === 'object' ? option[label] : option)}">
        <span>{{ typeof option === 'object' ? option[label] : option }}</span>
        <i class="multiselect__tag-icon" @click.prevent @mousedown.prevent.stop="remove(option, $event)" />
      </div>
    </template>
    <template v-slot:noResult>
      無找到相關標籤，請修改搜尋關鍵字
    </template>
  </multiselect>
</template>

<style scoped>
.tagselect>>>.multiselect__tag{
  line-height: 1.25;
  padding-right: 28px;
  margin-right: 4px;
  margin-bottom: 3px;
  vertical-align: bottom;
  text-shadow: 1px 1px 1px rgba(0,0,0,.5);
} 
.tagselect>>>.multiselect__tag-icon{
  background: rgba(0,0,0,.25);
  color: rgba(255,255,255,.5);
  line-height: inherit;
  padding-block: inherit;
  &::after{ color: inherit }
  &:hover{ 
    color: #fff;
    background: rgba(0,0,0,.5);
  }
}
.tagselect>>>.multiselect__tags{
  padding-top: 3px;
}
.tagselect>>>.multiselect__tags-wrap{
  display: inline-block;
  margin-right: -4px;
}
.tagselect>>>.multiselect__input{
  padding-top: 3px;
  margin-bottom: 6px;
}
.tagselect>>>.multiselect__placeholder, 
.tagselect>>>.multiselect__input::placeholder {
  padding-top: 3px;
  color: #999
}

.tagselect.readonly{
  opacity: 1;
  background: none;
}
.tagselect.readonly>>>.multiselect__tags{
  border-color: transparent;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.tagselect.readonly>>>.multiselect__tag{
  padding-right: 10px;
}
.tagselect.readonly>>>.multiselect__select,
.tagselect.readonly>>>.multiselect__tag-icon{
  display: none;
}
</style>

<script>
/* eslint-disable */
// import define from "@/views/projects/XSG/_Define";
import multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  components: {
    multiselect,
  },
  props: {
    value: Array,
    options: {type:Array, default:()=>[] },
    readonly: Boolean,
    name: {type:String, default:''},
    label: {type:String, default:'label'},
    trackBy: {type:String, default:''},
    name: {type:String, default:''},
    taggable: {type:Boolean, default:false}
  },
  data() {
    return {

    };
  },
  computed: {
    modelValue: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    }
  },
  methods:{
    addTag(tag){
      if (!this.modelValue.includes(tag)) this.modelValue.push(tag);
    },
    colorHash(string){
      if (typeof string !== "string") string = string.toString();
      string = string.padEnd(16, "=");
      let hash = 0;
      for (let i = 0; i < string.length; i++){
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash &= hash;
      }
      // hash = Math.abs(hash);
      return `hsl(${hash % 360}, ${40 + (hash%20)}%, ${30 + (hash%10)}%)`;
      hash |= 0x303030;
      hash &= 0x7F7F7F;
      return "#" + hash.toString(16).slice(-6).padStart(6,"0");
    }
  }
};
</script>
