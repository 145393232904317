<template>
  <div id="BigData">
    <section class="content-header">
      <h1>網紅大數據</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">網紅大數據</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="clearfix"
            >
              <!-- 新增 -->
              <button
                @click="addNew()"
                class="btn btn-block btn-warning btn-add ml-10 ml-0-sm"
                type="button">
                <i class="fa fa-plus"></i> 新增
              </button>

              <button
                @click="exportOrders()"
                class="btn btn-block btn-primary btn-add"
                type="button" >
                <i class="fa fa-upload"></i> 匯出
              </button>

              <!-- 請選擇收藏群組 -->
              <!-- <div class="select-group">
                <select
                  class="form-control"
                  id="selectPlatforms"
                  v-model="searchData.CollectType"
                >
                  <option value="">全部收藏群組</option>
                  <option
                    v-for="(item, key) in selectCollectList"
                    :value="item"
                    :key="key"
                  >
                    {{ item }}
                  </option>
                </select>
              </div> -->

              <!-- 請選擇平台 -->
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectPlatforms"
                  v-model="searchData.PlatformType"
                  @change="selectChange($event)"
                >
                  <option value="">全部社群</option>
                  <option
                    v-for="(item, key) in selectPlatformsList"
                    :value="item.classId"
                    :key="key"
                  >
                    {{ item.className }}
                  </option>
                </select>
              </div>

              <!-- 請選擇類型 -->
              <div class="select-group">
                <multiselect
                  v-model="searchData.categorys"
                  :options="selectTypeList"
                  :multiple="true"
                  placeholder="請選擇類型"
                  :showLabels="false"
                  :max="2"
                  :hide-selected="true"
                  :searchable="true"
                >
                </multiselect>
              </div>

              <!-- 搜尋 -->
              <div class="input-group atLeft">
                <input
                  name="table_search"
                  placeholder="搜尋網紅名稱"
                  type="text"
                  class="form-control pull-right"
                  v-model="searchData.KolName"
                  @keyup.enter="search"
                />
              </div>

              <!-- 搜尋 -->
              <button type="button" 
                class="btn btn-tools mb-10-md ml-0 btn-default" 
                @click="search">
                <i class="fa fa-search"></i> 搜尋
              </button>

               <!-- 進階搜尋 -->
              <button 
                v-on:click="isAdvancedSearch = !isAdvancedSearch"
                class="btn btn-tools mb-10-md btn-default"
                type="button" >
                <i
                  v-bind:class="{ 'fa-search-minus': isAdvancedSearch }"
                  class="fa fa-search-plus"
                ></i>
                進階
              </button>

              <button data-btn="重設"
                @click="searchReset()"
                class="btn btn-default btn-tools"
                type="button">
                <i class="fa fa-refresh"></i> 重設
              </button>

            <!-- ====  advancedSearch  ==== -->
              <div
                v-bind:class="{ 'is-advanced': isAdvancedSearch == true }"
                v-show="isAdvancedSearch"
                class="advancedSearch clearfix clear pt-10"
              >
                <!-- 性別 -->
                <div class="select-group sex-group">
                  <select
                    ref="select"
                    class="form-control"
                    id="selectSex"
                    @change="selectChange($event)"
                    v-model="searchData.gender"
                  >
                    <option
                      v-for="(item, key) in selectSexList"
                      :value="item.value"
                      :key="key"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>

                <!-- 粉絲數 -->
                <div class="select-group">
                  <select
                    class="form-control"
                    id="selectFans"
                    v-model="searchData.sort.range.follow"
                    @change="selectChange($event)"
                  >
                    <option value="0" disabled>請選擇粉絲數</option>
                    <option
                      v-for="(item, key) in selectFansList"
                      :value="item.value"
                      :key="key"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>

                <!-- 看全部資料 -->
                <div class="select-group checkbox-group">
                  <input
                    id='checkKpiData' 
                    type="checkbox"
                    v-model="isShowAll"
                    @change="changeKpiData()"
                  />
                  <label for='checkKpiData'>搜尋全部資料(不含KPI)</label>
                </div>

                <!-- 標籤屬性 -->
                <!-- <div class="select-group">
                  <select
                    class="form-control"
                    id="selectAttribute"
                    @change="selectChange($event)"
                    v-model="searchData.subcategory"
                  >
                    <option value="" disabled>請選標籤</option>
                    <option
                      v-for="(item, key) in selectAttributeList"
                      :value="item.className"
                      :key="key"
                    >
                      {{ item.className }}
                    </option>
                  </select> 
                  <multiselect
                    v-model="searchData.subcategorys"
                    :options="subcategorysOption"
                    :multiple="true"
                    :showLabels="false"
                    placeholder="請選擇標籤"
                    :max="1"
                    :hide-selected="true"
                    :searchable="true"
                  >
                  </multiselect>
                </div>-->

                <!-- 找不到 -->
                <span class="fr mt-10 mt-0-sm w100-sm mb-10-sm">
                  找不到?
                  <b @click="addNew()" class="link text-line">手動新增</b>
                </span>
              </div>
            </div>

            <!-- 請選擇排序 -->
            <div class="select-group sort-group mt-10">
              <select
                class="form-control"
                id="selectSort"
                v-model="searchData.sort_by"
                @change="search"
              >
                <option
                  :value="item.value"
                  :key="index"
                  v-for="(item, index) in kpiSortOption"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <!-- 請選擇頁數 -->
            <!-- <option disabled>請選擇單頁顯示數目</option>-->
            <select
              @change="pageDisplay()"
              class="form-control select-group mt-10 page-size"
              v-model="searchData.pageSize"
              placeholder="請選擇單頁顯示數目">
              
              <option value="10">單頁顯示10項</option>
              <option value="20">單頁顯示20項</option>
              <option value="40">單頁顯示40項</option>
              <option value="100">單頁顯示100項</option>
            </select>

            <!-- 自訂顯示筆數 -->
            <div class="input-group mt-10 page-size" style="width: 125px">
              <input
                name="table_search"
                placeholder="自訂顯示筆數"
                type="number"
                class="form-control pull-right"
                v-model="customPageSize"
                @keyup.enter="search"
              />
            </div>

            <!-- 資料總筆數 -->
            <div class="text mt-10">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total"> {{ kolTotal }} </span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered AdListTable" id="groupSetTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="max-width: 100px;min-width: 100px" width="5%"
                    >主圖</th>
                  <th style="min-width: 120px" width="10%">姓名</th>
                  <th style="min-width: 50px" width="4%">性別</th>
                  <th style="min-width: 60px" width="8%">類型</th>
                  <!-- <th style="min-width: 80px" width="9%">標籤</th> -->
                  <th style="min-width: 450px" width="32%">(30天)社群資訊</th>
                  <th style="min-width: 50px" width="4%">狀態</th>
                  <th style="min-width: 90px" width="4%">更新時間</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 80px"
                    width="8%"
                  >
                    功能
                  </th>
                </tr>

                <tr :key="item.target_id" v-for="item in kolList">
                  <td>
                    <div class="main-img jqImgFill imgLink"  
                      @click="showImg(item)">
                      <img
                        class="imgLink"
                        :src="item.main_img"
                        :alt="item.target_id"
                      />
                    </div>
                    
                  </td>
                  <td>
                    <span class="sr-only">
                      {{ item.target_id }}
                    </span>
                    <span @click="showContent(item)" class="link">
                      {{ item.target_name }}</span
                    >
                  </td>
                  <td align="center">{{ GetGender(item.gender) }}</td>
                  <td align="center">
                    {{ array2string(item.categorys) }}
                  </td>

                  <td data-label="mainData">
                    <div class="data-wrapper">
                      <div
                        class="data-box"
                        v-bind:class="{ isShow: item.isShow }"
                      >
                        <span
                          class="tc w100"
                          v-if="item.channel_data.length == 0"
                          >暫無資料</span
                        >
                        <ul
                          class="data-list"
                          v-for="(channel, index) in item.channel_data"
                          :key="index"
                          v-bind:class="channel.platform"
                          v-show="channel.is_visibility"
                        >
                          <li>
                            <a :href="channel.channel_url" target="_blank" :title="channel.channel_name">
                              <i
                                class="media-icon fa"
                                v-bind:class="channel.platform"
                              ></i>
                              <span class="data-value">
                                {{ pareToFloor(channel.follow) | currencyFormat2}}
                              </span></a
                            >
                          </li>
                          <li>
                            <span class="data-name">互動率</span>
                            <span class="data-value"
                              >{{
                                parseFloatNoRounding(
                                  channel.avg_post_probability
                                )
                              }}%</span
                            >
                          </li>
                          <li>
                            <span class="data-name">漲粉率</span>
                            <span class="data-value"
                              >{{
                                parseFloatNoRounding(
                                  channel.fans_up_probability
                                )
                              }}%</span
                            >
                          </li>
                          <li>
                            <span class="data-name">觀看率</span>
                            <span class="data-value"
                              >{{
                                parseFloatNoRounding(channel.avg_view_content)
                              }}%</span
                            >
                          </li>
                        </ul>
                      </div>

                      <button
                        v-if="!item.channel_data.length == 0"
                        class="data-toogle"
                        v-on:click="channelToggle(item)"
                      >
                        <i class="fa fa-angle-down"></i>
                      </button>
                    </div>
                  </td>

                  <td align="center">
                    {{ item.is_visibility == 0 ? "下架" : "上架" }}
                  </td>

                  <td align="center">{{
                     $utils.formatTime( item.crawler_time,"YYYY-MM-DD HH:mm:ss"
                  )}} </td>

                  <td class="text-center funTdVer">
                    <button @click="LinkKPI(item)" class="btn bg-maroon">
                      KPI
                    </button>
                    <br />
                    <button @click="editorItem(item)" class="btn btn-primary">
                      修改</button
                    ><br />
                    <!-- <button class="btn btn-warning" @click="addCollect(item)">
                      收藏</button
                    ><br /> -->
                    &nbsp;
                    <!-- <button @click="deleteItem(item)" class="btn btn-danger">
                      刪除
                    </button> -->
                  </td>
                </tr>

                <tr v-if="!kolTotal">
                    <td class="text-center" colspan="8">沒有資料</td>
                  </tr>
              </tbody>
            </table>

            <div class="box-footer text-center no-border">
              <Pagination
                :pageLimitCount="searchData.pageSize"
                :total="kolTotal"
                ref="pagination"
                v-on:pageMethod="onPageChange"
                v-show="kolTotal > 0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <BigDataAlert ref="BigDataAlert" props-in="kolList" />
    <CollectAddAlert ref="CollectAddAlert" />

    <BigDataImg ref="BigDataImg" />
  </div>
</template>


<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import BigDataAlert from "@/views/projects/spider/BigDataAlert";
import CollectAddAlert from "@/views/projects/spider/CollectAddAlert";
import BigDataImg from "@/views/projects/spider/BigDataImg";
import select2Multiple from "@/components/select2Multiple";
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import iCheckCheckBox from "@/components/iCheck_CheckBox";

export default {
  components: {
    Pagination,
    BigDataAlert,
    CollectAddAlert,
    BigDataImg,
    "select2-multiple": select2Multiple,
    Multiselect,
    iCheckCheckBox,
  },
  data() {
    return {
      isAdvancedSearch: true,
      customPageSize: "",
      selectLabelArray: [],

      selectPlatformsList: [],  //ex: ig, fb
      selectPlatformsModel: "",

      selectTypeList: [],

      selectSexList: [
        { value: "", text: "不區分性別" },
        { value: "male", text: "男" },
        { value: "fmale", text: "女" },
        { value: "transgender", text: "其他" },
      ],

      pageSizeList: [
        { value: "10", text: "單頁顯示10項" },
        { value: "20", text: "單頁顯示20項" },
        { value: "40", text: "單頁顯示40項" },
        { value: "100", text: "單頁顯示100項" },
      ],

      selectFansList: [
        { value: { lte: 500000000 }, name: "不區分粉絲數" },
        { value: { gte: 0, lte: 10000 }, name: "1萬以下" },
        { value: { gte: 10000, lte: 50000 }, name: "1萬~5萬" },
        { value: { gte: 50000, lte: 100000 }, name: "5萬~10萬" },
        { value: { gte: 500000, lte: 1000000 }, name: "50萬~100萬" },
        { value: { gte: 1000000 }, name: "100萬以上" },
      ],

      subcategorysOption: [],

      isShowAll: false,

      searchData: {
        KolID: "", //KOL編號
        KolName: "", //KOL名稱
        PlatformType: "", //平台(yt,fb,ig)
        CollectType: "",
        categorys: [],
        subcategorys: [],
        gender: [], //性別
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        sort: { range: { max_follow: { lte: 999999 } } },
        sort_by: false, //默認以main_id相同的channel follow 加總排序,
        kpi_data: true, //true: 只秀出有資料。false:無社群資料的也可以。
      },

      kpiSortOption: [
        // { value: false, name: "請選擇KPI排序" },
        { value: { max_follow: "desc" }, name: "粉絲數" },
        { value: { avg_post_probability: "desc" }, name: "互動率" },
        { value: { fans_up_probability: "desc" }, name: "漲粉率" },
        { value: { avg_view_content: "desc" }, name: "觀看率" },
      ],

      channelData: {
        main_id: "",
        platform: "",
      },

      channelClass: {
        ig: false,
        fb: false,
        yt: false,
        tw: false, //twitch
        tt: false, //twitter
        tiktok: false,
      },
    };
  },

  async created() {
    this.selectInit();
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.getClassTypes();
    this.getDataList();
  },

  computed: {
    ...mapState({
      kolList: (state) => state.kolBigData.kolList,
      kolTotal: (state) => state.kolBigData.kolTotal,
      kolTypes: (state) => state.classTypes.queryAll,
    }),
  },

  watch: {
    kolList() {
      this.$refs.pagination.setPage(this.searchData.pageNum, this.kolTotal);
    },
    kolTypes() {
      this.selectTypeList = [];
      let typeList = this.kolTypes.datas.filter(
        (type) => type.module == "KOLCategory"
      );
      typeList.forEach((category) => {
        this.selectTypeList.push(category.className);
      });

      this.selectPlatformsList = this.kolTypes.datas.filter(
        (type) => type.module == "KOLPlatform"
      );
    
      let subcategorys = this.kolTypes.datas.filter(
        (type) => type.module == "KOLSubCategory"
      );

      //set 類別
      if ( this.searchData.PlatformType == "") {
        this.searchData.PlatformType = this.selectPlatformsList[0].classId;
      }

      this.subcategorysOption = [];
      subcategorys.forEach((sub) => {
        this.subcategorysOption.push(sub.className);
      });
    },
  },
  updated() {
    this.$nextTick(function () {
      // 仅在渲染整个视图之后运行的代码
      $(".jqImgFill").imgLiquid();
    });
  },

  methods: {
    search() {
      this.searchData.pageNum = 1;
      if (this.customPageSize != "" && !isNaN(parseInt(this.customPageSize))) {
        this.searchData.pageSize = parseInt(this.customPageSize);
      }
      this.getDataList();
    },

    getDataList() {
      this.$store.dispatch("kolBigData/search", this.searchData);
    },

    getClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    // async onPageChange(pageIdx, pageSize) {
    onPageChange(pageIdx, pageSize) {
      //pageIdx 頁碼   //pageSize 一頁幾筆
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;

      this.getDataList();
    },

    pageDisplay() {
      this.onPageChange(1, Number(this.searchData.pageSize));
    },

    exportOrders() {
      this.$store.dispatch("kolBigData/export", this.searchData);
    },

    showContent(item) {
      //顯示人物簡介
      this.$refs.BigDataAlert.showContent(item);
    },
    addCollect(item) {
      //加入
      this.$refs.CollectAddAlert.showContent(item);
    },
    showImg(item) {
      //顯示照片
      this.$refs.BigDataImg.showContent(item);
    },

    channelToggle(item) {
      item.isShow = !item.isShow ? true : false;
      this.$forceUpdate();
    },

    LinkKPI(item) {
      this.$router.push({
        name: "SpiderBigDataKOL",
        params: { 
          searchData: this.searchData, 
          id: item.target_id 
        },
      });
    },

    searchReset() {
      this.selectLabelArray = [];
      this.selectInit();
      this.search();
    },

    deleteChooseLabel(index) {
      let id = this.selectLabelArray[index].id;
      switch (id) {
        case "selectPlatforms":
          this.searchData.PlatformType = "";
          break;
        case "selectType":
          this.searchData.category = "";
          break;
        case "selectFans":
          this.searchData.sort.range.follow = { lte: 500000000 };
          break;
        case "selectAttribute":
          this.searchData.subcategory = "";
          break;
      }
      this.selectLabelArray.splice(index, 1);
    },

    selectChange(e) {
      let id = e.target.id;
      let label = e.target.value;
      let text = e.target[e.target.selectedIndex].text;
      let labelArray = this.selectLabelArray;

      if (!labelArray.length == 0) {
        labelArray.forEach(function (val, index) {
          let hadNmae = val.id;
          if (hadNmae == id) {
            labelArray.splice(index, 1);
          } else {
          }
        });
      }
      labelArray.push({ id: id, label: text });
    },

    selectInit() {
      this.isShowAll =  false,
      //search select init
      this.searchData = {
        KolID: "", //KOL編號
        KolName: "", //KOL名稱
        PlatformType: "", //平台(yt,fb,ig)
        CollectType:"",
        categorys: [],
        subcategorys: [],
        gender: "", //性別
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        customPageSize: "",
        sort: { range: { follow: { lte: 999999 } } },
        sort_by: this.kpiSortOption[0].value,
        kpi_data: true,
      };
      // this.customPageSize = "";
    },

    array2string(items) {
      if (items == null || items == []) return "暫無資料";
      return items.join("、 ");
    },

    GetGender(sex) {
      switch (sex) {
        case "male":
          return "男";
        case "fmale":
          return "女";
        case "transgender":
          return "其它";
        default:
          return sex;
      }
    },

    changeKpiData(check) {
      if (this.isShowAll == true) {
        this.searchData.kpi_data = false;
        this.searchData.PlatformType = "";
        // this.searchData.KolName = '1';
      } else {
        this.searchData.kpi_data = true;
        // this.searchData.KolName = '2';
      }
    },

    parseFloatNoRounding(num) {
      if (isNaN(parseInt(num))) return 0;
      return Math.floor(num * 100) / 100;
    },

    addNew() {
      this.$router.push({
        name: "SpiderBigDataEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    editorItem(item) {
      this.$router.push({
        name: "SpiderBigDataEdit",
        params: {
          id: item.target_id,
          searchData: this.searchData,
        },
      });
      return;
    },

    // 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
        }
      });
    },

    pareToFloor(num) {
      if (isNaN(parseInt(num))) return 0;
      return Math.floor(num);
    },
    
  },
};
</script>
