<template>
  <!--序號產生-->
  <div class="modal fade" ref="bonusAndRefundImport">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">匯入配點資料</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <div class="form-group name-group">
                      <div class="right">
                        <input id="batchCancelOrder" ref="batchCancelOrder" type="file" />
                      </div>
                    </div>
                    <button
                      @click="readExcel()"
                      class="btn btn-block btn-danger btn-cog"
                      type="button"
                    >確定匯入</button>
                    <a
                      href="/uploads/配點範例.xlsx"
                      class="btn btn-block btn-success btn-cog"
                      type="button"
                      download
                    >配點範例</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from "vuex";
import xlsx from "xlsx";

export default {
  data() {
    return {
      edit: {
        snCount: 0,
        snCodeLen: 0
      }
    };
  },
  computed: {
    ...mapState({
      // award: state => state.award.query,
      // response: state => state.award.response,
      // exchangeCodeResponse: state => state.exchangeCode.response
    })
  },
  watch: {
    // response(value) {
    //   if (value.status == "1") {
    //   } else {
    //     if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
    //     else this.$eventBus.$emit("showAlert", value.message);
    //   }
    // },
    // exchangeCodeResponse(value) {
    //   if (value.status == "1") {
    //     this.$eventBus.$emit("showAlert", "成功!!");
    //     // $(this.$refs.serialNumber).modal("hide");
    //     this.refreshList();
    //   } else {
    //     if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
    //     else this.$eventBus.$emit("showAlert", value.message);
    //   }
    // },
    // activity() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.activity.total
    //   );
    // }
  },
  methods: {
    showSerialNumberEdit() {
      $("#batchCancelOrder").val("");

    //   if (award == "" || award == undefined || award.awardsId == "") {
    //     this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
    //     return;
    //   }

      // await this.getEmpHabitsInfo();
    //   this.GetAwardQuery(award.awardsId);

      $(this.$refs.bonusAndRefundImport).modal("show");
    },
    // GetAwardQuery(awardsId) {
    //   //活動列表
    //   this.$store.dispatch("award/awardQuery", {
    //     awardsId: awardsId
    //   });
    // },
    readExcel() {
      //excel匯入
      var that = this;
      const files = that.$refs.batchCancelOrder.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$eventBus.$emit(
          "showAlert",
          "上傳格式不正確，請上傳xls或者xlsx格式"
        );
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary",
            cellDates: true
          });
          const wsname = workbook.SheetNames[0]; //取第一張表
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格內容
          let outputs = []; //清空
          let error = false;
          let errMessage = "";
          for (var i = 0; i < ws.length; i++) {
            if(!ws[i].遊戲帳號 || !ws[i].配發點數 || !ws[i].配發原因) {
              error = true;
              errMessage = "第"+(i+1)+"筆資料內容為空值!!";
              break;
            }
            outputs.push({
              Molokey: ws[i].遊戲帳號,
              PointAmount: ws[i].配發點數,
              Note: ws[i].配發原因, 
            });
          }

          if (error) {
            this.$eventBus.$emit("showAlert", errMessage);
            return;
          }

          this.$store.dispatch("bonusAndRefund/bonusAndRefundImport", {
            datas: outputs,
            // awardsId: this.award.awardsId
          });

          // api.actionwork
          //   .import({
          //     datas: outputs
          //   })
          //   .then(res => {
          //     if (res.data.status == "1") {
          //       that.$eventBus.$emit("showAlert", "序號匯入成功!!");
          //     } else {
          //       that.$eventBus.$emit("showAlert", res.data.message);
          //     }
          //     that.workShowAll();
          //   });
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>