<template>
  <div id="MediaList" class="searchList">
    <section class="content-header">
      <h1>音樂管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">音樂管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max1200">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="showContent()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增音樂
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center">
              <tbody>
                <tr class="bg-gray">
                  <th style="width: 7%">#</th>
                  <th style="width: 40%">檔案名稱</th>
                  <th style="min-width: 150px">有效日期</th>
                  <th style="width: 10%">播放順序</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%"
                  >
                    啟用狀態
                  </th>
                  <th style="min-width: 150px; width: 15%">功能</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="6">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="6">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <a target="_blank" :href="item.filesUrl">
                      {{ item.fileOrgName }}
                    </a>
                  </td>
                  <td>
                    <span>{{ item.startTime | dateFormat("YYYY/MM/DD") }}</span>
                    -
                    <span v-if="new Date(item.endTime).getFullYear() === 2200">
                      永久有效
                    </span>
                    <span v-else>
                      {{ item.endTime | dateFormat("YYYY/MM/DD") }}
                    </span>
                  </td>
                  <td>{{ item.orderNum }}</td>
                  <td align="center" class="text-center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :id="'check' + index"
                        :true-value="0"
                        :false-value="1"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model.number="item.isStop"
                        @change="submitData(item)"
                      />
                      <label :for="'check' + index"></label>
                    </div>
                  </td>
                  <td>
                    <button @click="showContent(item)" class="btn btn-primary">
                      修改
                    </button>
                    &nbsp;
                    <button @click="deleteData(item)" class="btn btn-danger">
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <mediaContent ref="mediaContent" v-on:updateList="getDataList()" />
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import mediaContent from "@/views/MediaContent";

export default {
  components: {
    Pagination,
    mediaContent,
  },
  data() {
    return {
      projectId: this.$store.state.projectId, // 專案Id
      loading: true,
      total: 0, // 總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10, // 每頁顯示筆數
      },
      datas: [],
      item: {},
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      this.$store.dispatch("media/queryAll", this.searchData).then((result) => {
        this.datas = result.datas || [];
        this.total = result.total;
        this.loading = false;
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    showContent(item) {
      this.$refs.mediaContent.showContent(item);
    },
    submitData(item) {
      this.$store.dispatch("media/update", item).then(() => {
        this.getDataList();
      });
    },
    deleteData(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("media/delete", item).then(() => {
            this.getDataList();
          });
        }
      });
    },
  },
};
</script>