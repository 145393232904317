import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryList: [],
    categoryquery: [],
    detailQuery: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    categoryquery({ commit }, data) {
        api.qppArticle.Categoryquery(data).then(res => {
            if (res.data.status == "1") {
                commit('setCategoryquery', res.data.content);
            }
        })
    },
    QueryList({ commit }, data) {
        api.qppArticle.QueryList(data).then(res => {
            if (res.data.status == "1") {
                commit('setQueryList', res.data.content);
            }
        })
    },
    DetailQuery({ commit }, data) {
        api.qppArticle.DetailQuery(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailQuery', res.data.content);
            } else {
                this._vm.$eventBus.$emit("showAlert", "查無資料!!");
                commit('setDetailQuery', res.data.content);
            }
        })
    },
    DeleteQuery({ commit }, data) {
        api.qppArticle.DeleteQuery(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    ArticleAdd({ commit }, data) {
        api.qppArticle.Add(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    ArticleEdit({ commit }, data) {
        api.qppArticle.Edit(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    SetTop({ commit }, data) {
        api.qppArticle.SetTop(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    DeleteTop({ commit }, data) {
        api.qppArticle.DeleteTop(data).then(res => {
            commit('setResponse', res.data);
        })
    },
}

// mutations
const mutations = {
    setCategoryquery(state, categoryquery) {
        state.categoryquery = categoryquery;
    },
    setQueryList(state, queryList) {
        state.queryList = queryList;
    },
    setDetailQuery(state, detailQuery) {
        state.detailQuery = detailQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}