<template>
    <div>
        <section class="content-header">
            <h1> 新增-修改廠商支付設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    廠商管理
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'FirmSettingList',
                        params: $route.params,
                    });">廠商設定</a>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'FirmPaySettingList',
                        params: $route.params,
                    });">廠商支付金流管理</a>
                </li>
                <li class="active">新增-修改廠商支付設定 </li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 廠商名稱：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" v-model.trim="edit.showInfo.廠商" disabled />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 金流名稱：
                                </label>
                                <div class="col-sm-10">
                                    <select class="selPaymentType form-control" v-model="edit.金流設定主檔編號">
                                        <option v-for="item in 金流設定List" :key="item.編號" :value="item.編號">
                                            {{ item.金流名稱 }}-{{ item.金流代碼 }}
                                        </option>
                                    </select>
                                    <div class="payStyle" v-if="金流設定資料">
                                        <ul>
                                            <li>金流名稱：{{ 金流設定資料.金流名稱 }}</li>
                                            <li>金流代碼：{{ 金流設定資料.金流代碼 }}</li>
                                            <!-- <li>所屬地區：{{ 地區列表Key[金流設定資料.所屬地區].地區 }}</li> -->
                                            <li>啟停用：
                                                <span v-if="金流設定資料.啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 金流帳號：
                                </label>
                                <div class="col-sm-10">
                                    <select class="selPaymentType form-control" v-model="edit.金流帳號編號">
                                        <option v-for="item in filter金流帳號List" :key="item.編號" :value="item.編號"
                                            :class="(item.啟用 == 0) ? 'disabled' : ''">
                                            {{ item.名稱 }}-{{ item.金流識別值 }}{{ (item.啟用 == 0) ? "(帳號停用中)" : "" }}
                                        </option>
                                    </select>
                                    <div class="payStyle" v-if="金流帳號資料">
                                        <ul>
                                            <li>名稱：{{ 金流帳號資料.名稱 }} </li>
                                            <li>金流識別值：{{ 金流帳號資料.金流識別值 }} </li>
                                            <li>啟停用：
                                                <span v-if="金流帳號資料.啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 支付方式：
                                </label>
                                <div class="col-sm-10">
                                    <select class="selPaymentType form-control" v-model="edit.金流支付設定編號">
                                        <option v-for="item in filter金流支付List" :key="item.編號" :value="item.編號">
                                            {{ item.支付名稱 }}
                                        </option>
                                    </select>
                                    <div class="payStyle" v-if="上層設定">
                                        <ul>
                                            <li>支付名稱：{{ 上層設定.支付名稱 }}</li>
                                            <li>支付代碼：{{ 上層設定.支付方式代碼 }}</li>
                                            <li>地區：{{ 地區列表Key[上層設定.地區].地區 }}</li>
                                            <li>限制幣別：{{ 地區列表Key[上層設定.限制幣別].幣別 }}</li>
                                            <li>金流類型：{{ 金流類型設定Key[上層設定.金流類型].類型 }}</li>
                                            <li>是否開立發票：{{ 上層設定.是否開立發票 == '1' ? '是' : '否' }}</li>
                                            <li>啟停用：
                                                <span v-if="上層設定.啟用 == 1">啟用</span>
                                                <span v-else class="text-red">停用</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-if="上層設定 && 上層設定.是否支援支付品號 == 1">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red"></span> 支付品號：
                                </label>
                                <div class="col-sm-10">
                                    <div class="input-group margin" style="margin: 0px;">
                                        <input type="text" class="form-control" v-model.trim="edit.支付品號">

                                        <span class="input-group-btn">
                                            <button type="button" class="btn btn-info btn-flat" style="width:200px"
                                                @click="edit.支付品號 = 上層設定.支付品號">上層設定：{{ 上層設定.支付品號 }}</button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" v-show="限制開關">
                                <!-- {{ edit.額外限制 }} -->
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 額外限制：
                                </label>
                                <div class="col-sm-10">
                                    <LimitControler ref="comp" :額外限制List.sync="edit.額外限制"></LimitControler>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 順序：
                                </label>
                                <div class="col-sm-10 control-label" style="text-align: left;">
                                    {{ edit.順序 == null ? 0 : edit.順序 }} <span class="text-red">順序請至列表修改</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode"
                                            v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode"
                                            v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import LimitControler from "./components/LimitControler2.vue";
import api from "@/assets/js/app-api";
import { APILib, IsSelectedInOptions } from './IPSTool2';
import { mapState } from "vuex";
export default {
    components: {
        iCheckRedioButton,
        LimitControler,
    },
    data() {
        return {
            廠商設定List: [],
            金流設定List: [],
            金流支付List: [],
            金流帳號List: [],
            isEdit: false,
            上層設定: null,
            // 上層設定: {
            //     支付品號: "",//int，必填，來源 廠商直儲金流_金流支付設定_查詢
            //     每日上限: 0,//int，必填，來源 廠商直儲金流_金流支付設定_查詢
            //     每月上限: 0,//int，必填，來源 廠商直儲金流_金流支付設定_查詢
            //     單筆上限: 0,//int，必填，來源 廠商直儲金流_金流支付設定_查詢
            //     單筆下限: 0,//int，必填，來源 廠商直儲金流_金流支付設定_查詢
            // },
            edit: {
                showInfo: { 廠商: "" },
                啟用: 1,
                廠商設定主檔編號: parseInt(this.$route.params.firmPayId) * -1,
                支付品號: "",
                金流帳號編號: null,
                金流支付設定編號: null,
                金流設定主檔編號: null,
                server端的額外限制編號: "-",//複製一份用，避免前端修改資料時動到，最後送出時，比對用
                額外限制: [],
                順序: 0,
            },
        };
    },
    computed: {
        ...mapState({
            額外限制列表: state => state.IPSSetting.基礎設定檔.額外限制列表,
        }),
        filter金流帳號List() {
            return this.金流帳號List.filter((item) => { return item.金流設定主檔編號 + "" === this.edit.金流設定主檔編號 + ""; })
        },
        filter金流支付List() {
            return this.金流支付List.filter((item) => { return item.金流設定主檔編號 + "" === this.edit.金流設定主檔編號 + ""; })
        },
        金流設定資料() {
            let val = this.edit.金流設定主檔編號;
            return this.金流設定List.find((item) => {
                return item.編號 + "" === val + "";
            });
        },
        金流帳號資料() {
            let val = this.edit.金流帳號編號;
            return this.filter金流帳號List.find((item) => {
                return item.編號 + "" === val + "";
            });
        },
        金流類型設定Key() {
            return this.$store.getters["IPSSetting/金流類型設定Key"];
        },
        地區列表Key() {
            return this.$store.getters["IPSSetting/地區列表Key"];
        },
        限制開關() {
            if (this.上層設定 && this.金流類型設定Key[this.上層設定.金流類型] && this.金流類型設定Key[this.上層設定.金流類型].限額開關 == 1) {
                return true;
            }
            return false;
        },
    },
    watch: {
        "edit.金流支付設定編號"(val) {
            let tmp = this.filter金流支付List.find((item) => {
                return item.編號 + "" === val + "";
            });
            if (!tmp) {
                this.$refs.comp.set上層限制集合([]);
                this.上層設定 = null;
                return;
            }
            $(this.$refs.loading).show();
            this.$store.dispatch("IPSSetting/額外限制List_查詢2", { 額外限制: tmp.server端的額外限制編號, 額外限制列表: this.額外限制列表 }).then((額外限制) => {
                tmp.額外限制 = 額外限制;
                this.$refs.comp.set上層限制集合([額外限制]);
                this.上層設定 = tmp;
                $(this.$refs.loading).hide();
            });
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
                let 編號 = this.$route.params.firmPayId;
                this.廠商設定List = APILib.IPS(await api.IPSSetting.廠商設定主檔_查詢({})).content;
                this.金流設定List = APILib.IPS(await api.IPSSetting.金流設定主檔_查詢({})).content;
                this.金流支付List = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({})).content;
                for (let i of this.金流支付List) {
                    this.$set(i, "server端的額外限制編號", i.額外限制);
                }
                this.金流帳號List = APILib.IPS(await api.IPSSetting.金流帳號_查詢({})).content;

                if (編號 < 0) {
                    this.edit.廠商設定主檔編號 = 編號 * -1;
                    this.edit.showInfo = {
                        廠商: this.get廠商Name(編號 * -1)
                    }
                } else {
                    this.isEdit = true;
                    let find = APILib.IPS(await api.IPSSetting.廠商支付設定_查詢({ 編號 })).content[0];
                    if (find) {
                        // find.額外限制 = "35,";
                        find.server端的額外限制編號 = find.額外限制;
                        find.額外限制 = await this.$store.dispatch("IPSSetting/額外限制List_查詢2", { 額外限制: find.額外限制, 額外限制列表: this.額外限制列表 });
                        find.showInfo = {
                            廠商: this.get廠商Name(find.廠商設定主檔編號)
                        }
                        delete find.建立時間;
                        this.edit = { ...find };
                    } else {
                        throw "找不到資料";
                    }
                }
                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        backList() {
            this.$router.push({
                name: "FirmPaySettingList",
                params: this.$route.params,
            });
        },
        async Save() {
            try {
                let obj = JSON.parse(JSON.stringify(this.edit));
                if (obj.廠商設定主檔編號 <= 0) {
                    throw "廠商不可為空";
                }
                if (obj.金流設定主檔編號 <= 0 || !IsSelectedInOptions(obj.金流設定主檔編號, this.金流設定List, "編號")) {
                    throw "金流不可為空";
                }
                if (obj.金流帳號編號 <= 0 || !IsSelectedInOptions(obj.金流帳號編號, this.filter金流帳號List, "編號")) {
                    throw "金流帳號不可為空";
                }
                if (obj.金流支付設定編號 <= 0 || !IsSelectedInOptions(obj.金流支付設定編號, this.filter金流支付List, "編號")) {
                    throw "支付方式不可為空";
                }
                if (this.限制開關 && !this.$refs.comp.Check(obj.額外限制)) {
                    throw "請檢查額外限制";
                }
                $(this.$refs.loading).show();
                if (this.限制開關) {
                    obj.額外限制 = await this.$refs.comp.Save(this.edit.server端的額外限制編號, obj.額外限制);
                } else {
                    obj.額外限制 = this.server端的額外限制編號;
                }
                delete obj.showInfo;
                delete obj.server端的額外限制編號;
                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.廠商支付設定_修改(obj));
                } else {
                    APILib.IPS(await api.IPSSetting.廠商支付設定_新增(obj));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
                this.backList();
            } catch (e) {
                $(this.$refs.loading).hide();
                this.$eventBus.$emit("showAlert", e);
            }
        },
        get廠商Name(廠商設定主檔編號) {
            let find = this.廠商設定List.find((item) => {
                return item.編號 === 廠商設定主檔編號;
            });
            return find ? find.名稱 + "-" + find.顯示名稱 : "";
        },
        get金流名稱(金流設定主檔編號) {
            let find = this.金流設定List.find((item) => {
                return item.編號 === 金流設定主檔編號;
            });
            return find ? find.金流名稱 + "-" + find.金流代碼 : "";
        },
        get金流帳號(金流帳號編號) {
            let find = this.金流帳號List.find((item) => {
                return item.編號 === 金流帳號編號;
            });
            return find ? find.名稱 + "-" + find.金流識別值 : "";
        },
    },
};
</script>
<style scoped>
.form-control {
    display: inline;
    /* width: auto; */
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.payStyle>ul>li:first-child {
    padding: 5px 0px 5px 0px;
    color: #3c8dbc;
}

.payStyle>ul {
    border-bottom: 1px solid #f4f4f4;
}

.ColorRed {
    color: red;
}
</style>