<template>
  <div>
    <div class="channel box">
      <div class="layout-main" :class="fontSize">
        <div class="layout-channel-div">
          <h1>{{ $t('頻道管理') }}</h1>
          <i class="fa fa-warning" style="color:red;cursor: pointer;" :title="$t('下載事件記錄')" v-show="是否顯示異常按鈕"
            @click='$store.dispatch("messageSystemServer/刷新畫面", "");'></i>
          <i class="fa fa-check-square" style="color:red;cursor: pointer;" title="" v-show="isDebug"
            @click='$store.dispatch("messageSystemServer/ReadLog", "")'></i>
          <div class="layout-main" v-if="!(ServerIsOnline == 'Connected' && $user.hasPermission('ChannelManager'))">
            <div v-if="!$user.hasPermission('ChannelManager')">{{ $t('請申請權限') }}</div>
            <template v-else>
              <div>{{ $t(`ServerStatus-${ServerIsOnline}`) }}</div>
              <div v-show="ServerIsOnline == 'Disconnected'">{{ $t(`請重新整理以建立連線`) }}</div>
            </template>
          </div>
          <!-- 左邊-管理 -->
          <div class="copyTipParent" v-show="ServerIsOnline == 'Connected' && $user.hasPermission('ChannelManager')">
            <span class="copyTip" ref="copyTip"><i class="fa fa-check"></i> {{ $t('複製成功') }}</span>
            <div class="section-nav all-radius">
              <span class="span" :title="navStr">
                {{ navStr }}
              </span>
              <button class="btn control-btn" v-if="玩家控管功能" style="margin-right: 10px;" :disabled="!nick"
                @click="showPlayer()">
                {{ $t('玩家控管') }}
              </button>
              <button class="btn control-btn" :title="nick" :disabled="!nick" @click="一鍵複製(nick)">
                <i class="fa fa-copy"></i>
              </button>
            </div>
          </div>
          <div class="section-channels" v-show="ServerIsOnline == 'Connected' && $user.hasPermission('ChannelManager')">
            <div class="public-channel top-radius" ref="resizeDivPublic_1">
              <div class="caption top-radius">{{ $t('公開頻道') }}</div>
              <div class="scrollDiv" ref="scrollFormPublic">
                <ChannelManagerTable :list="監頻訊息_公開" :focusId="focusId" @onFocus="onFocus"
                  @onSecretBtnClick="onSecretBtnClick" @onDownloadBtnClick="onDownloadBtnClick" @popUpImg="popUpImg"
                  :th="[$t('頻道'), $t('發話人')]" :td='["Channel", "Sender"]'
                  @mouseenter="(e, item) => { mouseenter(e, item, true); }" @mouseout="mouseout" @playAudio="playAudio"
                  :MessageSystemEnable="MessageSystemEnable">
                </ChannelManagerTable>
              </div>
            </div>
            <div class="private-channel top-radius" ref="resizeDivPrivate_1">
              <div class="caption top-radius">{{ $t('密語頻道') }}</div>
              <div class="scrollDiv" ref="scrollFormPrivate">
                <ChannelManagerTable :list="監頻訊息_密語" :focusId="focusId" @onFocus="onFocus"
                  @onSecretBtnClick="onSecretBtnClick" @onDownloadBtnClick="onDownloadBtnClick" @popUpImg="popUpImg"
                  :th="[$t('發話人'), $t('接收人')]" :td='["Sender", "Receiver"]'
                  @mouseenter="(e, item) => { mouseenter(e, item, false); }" @mouseout="mouseout" @playAudio="playAudio"
                  :MessageSystemEnable="MessageSystemEnable">
                </ChannelManagerTable>
              </div>
            </div>
            <div class="public-filter-channel" ref="resizeDivPublic_2">
              <div class="caption cursor" style="user-select: none;">{{ $t('過濾關鍵字') }}</div>
              <div class="scrollDiv" ref="scrollFilterFormPublic">
                <ChannelManagerTable :list="監頻訊息_過濾_公開" :focusId="focusId" @onFocus="onFocus"
                  @onSecretBtnClick="onSecretBtnClick" @onDownloadBtnClick="onDownloadBtnClick" @popUpImg="popUpImg"
                  :th="[$t('頻道'), $t('發話人')]" :td='["Channel", "Sender"]' @playAudio="playAudio"
                  :MessageSystemEnable="MessageSystemEnable">
                </ChannelManagerTable>
              </div>
            </div>
            <div class="private-filter-channel" ref="resizeDivPrivate_2">
              <div class="caption cursor" style="user-select: none;">{{ $t('過濾關鍵字') }}</div>
              <div class="scrollDiv" ref="scrollFilterFormPrivate">
                <ChannelManagerTable :list="監頻訊息_過濾_密語" :focusId="focusId" @onFocus="onFocus"
                  @onSecretBtnClick="onSecretBtnClick" @onDownloadBtnClick="onDownloadBtnClick" @popUpImg="popUpImg"
                  :th="[$t('發話人'), $t('接收人')]" :td='["Sender", "Receiver"]' @playAudio="playAudio"
                  :MessageSystemEnable="MessageSystemEnable">
                </ChannelManagerTable>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-tool-div" v-show="ToolBar.頻道篩選 || ToolBar.字體大小">
          <h1 style="visibility: hidden;">C</h1><!--佔位用-->
          <!-- 右邊-功能列 -->
          <div class="fieldset all-radius" v-show="ToolBar.字體大小">
            <div class="legend">{{ $t('字體') }}</div>
            <ul class="options">
              <li>
                <input type="radio" name="font-size" id="fs小" value="fonst-size-small" v-model="fontSize">
                <label for="fs小">
                  <span class="checkBox"></span>
                  <span class="checkSpace"></span>
                  <span class="set-fonst-size-small">{{ $t('小') }}</span>
                </label>
              </li>
              <li>
                <input type="radio" name="font-size" id="fs中" value="fonst-size-middle" v-model="fontSize">
                <label for="fs中">
                  <span class="checkBox"></span>
                  <span class="checkSpace"></span>
                  <span class="set-fonst-size-middle">{{ $t('中') }}</span>
                </label>
              </li>
              <li>
                <input type="radio" name="font-size" id="fs大" value="fonst-size-large" v-model="fontSize">
                <label for="fs大">
                  <span class="checkBox"></span>
                  <span class="checkSpace"></span>
                  <span class="set-fonst-size-large">{{ $t('大') }}</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="fieldset all-radius" v-show="ToolBar.頻道篩選">
            <!-- {{ 頻道列表 }} -->
            <div class="legend">{{ $t('公開頻道篩選') }}
              <span class="selAll" @click="selAllPublicChannels(頻道列表)">{{ $t('全選') }}</span>
            </div>
            <ul class="options">
              <li v-for=" item  of  頻道列表.publicTypes " :key="item">
                <input type="checkbox" name="keyword-filter" :value="item" v-model="checkedPublicChannels"
                  :id="'ChannelType' + item">
                <label :for="'ChannelType' + item">
                  <span class="checkBox"></span>
                  <span class="checkSpace"></span>
                  <div>{{ $t('ChannelType' + item) }}</div>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PopUpImg ref="popUpImg"></PopUpImg>
      <PlayerSettingContent ref="playerSettingContent" @updateList="$eventBus.$emit('showAlert', $t('完成'));"
        class="popform" />
      <SecretMsgPannel ref="secretMsgPannel"></SecretMsgPannel>
      <DownloadPannel ref="downloadPannel"></DownloadPannel>
      <div class="overlay" ref="loading">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <div class="imgToolTip" :style="imgToolTip.style" ref="imgToolTip">
        <img :src="imgToolTip.src" alt="">
      </div>
    </div>
    <!-- <MemberModerateDialog ref="MemberModerateDialog" :types="serviceTypes" /> -->
  </div>
</template>
<script>
import { ProjectSetting, } from "@/store/MessageSystem/messageSystemConst";
import { FixResize } from "@/store/MessageSystem/messageSystemLib";
import { mapState } from "vuex";
import { MsgTypeEnum, ChannelTypeEnum } from "@/views/projects/ChannelMonitor/ChannelMonitorLib";
import PlayerSettingContent from "@/views/projects/NMJ/PlayerSettingContent";//麻將的會員控管
// import MemberModerateDialog from "@/views/projects/XSW/components/MemberModerateDialog";//馬來的會員控管
import PopUpImg from "@/views/projects/ChannelMonitor/components/PopUpImg";
import SecretMsgPannel from "@/views/projects/ChannelMonitor/components/SecretMsgPannel";
import DownloadPannel from "@/views/projects/ChannelMonitor/components/DownloadPannel";
import ChannelManagerTable from "@/views/projects/ChannelMonitor/components/ChannelManagerTable";
import { H5AudioPlayer } from "@/store/MessageSystem/H5AudioPlayer";
//安裝調整div大小功能
let installedDiv = [];//記錄已安裝的div，用來移除監聽用
function installResizeDiv(resizableDiv1, resizableDiv2) {
  // 添加鼠标按下事件监听器
  let dragDiv = $(resizableDiv2).find("div").get(0);
  function onMouseDown(e) {
    // 记录鼠标按下时的位置
    let startY = e.clientY;

    // 获取当前 DIV 的宽度和高度
    let startHeight1 = parseInt(document.defaultView.getComputedStyle(resizableDiv1).height, 10);
    let startHeight2 = parseInt(document.defaultView.getComputedStyle(resizableDiv2).height, 10);
    let totalHeight = startHeight1 + startHeight2;
    let childHeight2 = $($(resizableDiv2).find("div").get(0)).outerHeight();
    // 添加鼠标移动事件监听器
    function handleMouseMove(e) {
      // 计算鼠标移动的距离
      let deltaY = e.clientY - startY;
      let tmpDiv1Height = startHeight1 + deltaY;//div1目前高度
      let tmpDiv2Height = startHeight2 - deltaY;//div2目前高度
      if (tmpDiv2Height >= childHeight2 && tmpDiv1Height >= childHeight2) {//限定範圍
        // 设置 DIV 的新宽度和高度
        let percent = Math.floor(((startHeight1 + deltaY) / totalHeight) * 100);
        resizableDiv1.style.height = percent + '%';
        resizableDiv2.style.height = (100 - percent) + '%';
      }
    }
    // 添加鼠标释放事件监听器
    function handleMouseUp() {
      // 移除鼠标移动和鼠标释放事件监听器
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    // 添加鼠标移动和鼠标释放事件监听器
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }
  dragDiv.addEventListener('mousedown', onMouseDown);
  installedDiv.push({ dragDiv, onMouseDown });
}
function removeResizeDiv() {
  for (let { dragDiv, onMouseDown } of installedDiv) {
    dragDiv.removeEventListener('mousedown', onMouseDown);
  }
  installedDiv = [];
}

function scrollToBottm(target) {
  setTimeout(() => {
    if (!target) {
      return;
    }
    target.scrollTo({
      top: (target.scrollHeight - target.clientHeight),
      behavior: "smooth"
    });
  }, 500);
}

//玩家控管功能
const PlayerFn = {
  async omp_nmj() {
    let cmd = {
      nickName: this.nick,
      pageNum: 1,
      pageSize: 10,
      phoneNumber: "",
      暱稱: "",
    };
    let res = await this.$store.dispatch("nmjPlayerData/query", cmd);
    if (res && res.length != 1) {
      throw this.$t('查詢到多筆相同暱稱的玩家，請至玩家資料查詢');
    }
    let player = res && res[0];
    if (player) {
      this.$refs.playerSettingContent.showContent(player, undefined, true);
    } else {
      throw this.$t('找不到玩家')
    }
  },
  // async omp_xin_my_web() {
  //   if (Object.keys(this.serviceTypes).length == 0) {
  //     //空的話，可能是第一次呼叫，幫它建置
  //     let result = await this.$store.dispatch("xsw/服務類別查詢")
  //     for (let item of result) {
  //       if (item.啟用YN == 0) { // 0 == 啟用
  //         this.$set(this.serviceTypes, item.編號, item.名稱);
  //       }
  //     }
  //   }

  //   let res = await this.$store.dispatch("xsw/會員查詢", { nickName: this.nick, phoneNumber: "" })
  //   if (res && res.length != 1) {
  //     throw this.$t('查詢到多筆相同暱稱的玩家，請至玩家資料查詢');
  //   }
  //   let player = res && res[0];
  //   console.log(player)
  //   // this.$refs.MemberModerateDialog.show(player, undefined);
  // }
}
export default {
  components: {
    PlayerSettingContent,
    // MemberModerateDialog,
    PopUpImg,
    ChannelManagerTable,
    SecretMsgPannel,
    DownloadPannel,
  },
  data() {
    return {
      fontSize: "fonst-size-small",
      isDebug: process.env.VUE_APP_DEBUG === 'true',
      navStr: "",
      nick: "",
      focusId: "",
      isPlaying: false,//音訊是否播放中
      imgToolTip: {
        style: {
          left: 0,
          top: 0,
        },
        src: "",
      },
      checkedPublicChannels: [],

      //
      serviceTypes: {},
    };
  },
  computed: {
    ...mapState({
      監頻訊息_公開(state) {
        let list_from_server = state.messageSystemServer.監頻訊息_公開;
        return list_from_server.filter((item) => {
          return this.checkedPublicChannels.includes(item.ChannelType);
        });
      },
      監頻訊息_密語: state => state.messageSystemServer.監頻訊息_密語,
      ServerIsOnline: state => state.messageSystemServer.ServerIsOnline,
      關鍵字_公開: state => state.messageSystemServer.關鍵字_公開,
      關鍵字_密語: state => state.messageSystemServer.關鍵字_密語,
      最後一次刷新時間: state => state.messageSystemServer.最後一次刷新時間,
      異常狀態: state => state.messageSystemServer.異常狀態,
      SampleRate: state => state.messageSystemServer.SampleRate,
    }),
    頻道列表() {
      let list = ProjectSetting.ChannelTypes(this.$store.state.projectId);
      return list;
    },
    ToolBar() {
      return ProjectSetting.工具列設定(this.$store.state.projectId);
    },
    MessageSystemEnable() {
      let projectId = this.$store.state.projectId;
      return ProjectSetting.MessageSystemEnable(projectId)
    },
    監頻訊息_過濾_公開() {
      if (this.關鍵字_公開) {
        let regex = new RegExp(this.關鍵字_公開);
        return this.監頻訊息_公開.filter((item) => {
          if (item.MsgType == MsgTypeEnum.Text) {
            return regex.test(item.移除特殊符號Content);
          }
          return false;
        });
      }
      return [];
    },
    監頻訊息_過濾_密語() {
      if (this.關鍵字_密語) {
        let regex = new RegExp(this.關鍵字_密語);
        return this.監頻訊息_密語.filter((item) => {
          if (item.MsgType == MsgTypeEnum.Text) {
            return regex.test(item.移除特殊符號Content);
          }
          return false;
        });
      }
      return [];
    },
    MsgTypeEnum() {
      return MsgTypeEnum;
    },
    ChannelTypeEnum() {
      return ChannelTypeEnum;
    },
    是否顯示異常按鈕() {
      // +30 是30秒後
      return this.異常狀態 && this.最後一次刷新時間 < (Math.floor(new Date().getTime() / 1000) + 30);
    },
    玩家控管功能() {
      return PlayerFn[this.$store.state.projectId];
    }
  },
  watch: {
    頻道列表: {
      handler(v) {
        //預設全選
        this.selAllPublicChannels(v, true);
      },
      immediate: true
    },
    監頻訊息_公開() {
      scrollToBottm(this.$refs.scrollFormPublic);
    },
    監頻訊息_密語() {
      scrollToBottm(this.$refs.scrollFormPrivate);
    },
    監頻訊息_過濾_公開() {
      scrollToBottm(this.$refs.scrollFilterFormPublic);
    },
    監頻訊息_過濾_密語() {
      scrollToBottm(this.$refs.scrollFilterFormPrivate);
    },
  },

  async mounted() {
    this.$eventBus.$on("on密語回覆", this.on密語回覆);

    window.addEventListener('resize', this.fixResize);
    this.fixResize();
    //安裝調整div大小功能
    this.$nextTick().then(() => {
      installResizeDiv(this.$refs.resizeDivPublic_1, this.$refs.resizeDivPublic_2);
      installResizeDiv(this.$refs.resizeDivPrivate_1, this.$refs.resizeDivPrivate_2);
    });

    $(this.$refs.loading).hide();
    scrollToBottm(this.$refs.scrollFormPublic);
    scrollToBottm(this.$refs.scrollFormPrivate);
    scrollToBottm(this.$refs.scrollFilterFormPublic);
    scrollToBottm(this.$refs.scrollFilterFormPrivate);
    $(this.$refs.imgToolTip).hide();
  },
  methods: {
    on密語回覆(json) {
      this.$eventBus.$emit("showAlert", json.Res ? "密語成功" : "密語失敗");
    },
    async playAudio(audioUrl) {
      try {
        await H5AudioPlayer(audioUrl, this.SampleRate);
      } catch (e) {
        this.$eventBus.$emit("showAlert", `${this.$t('播放音訊出錯：')}：${e}`);
      }
    },
    fixResize() {
      FixResize((winHeight) => {
        document.documentElement.style.setProperty('--g_channel_heigth', `${winHeight - 50}px`);
      });
    },
    popUpImg({ Content }) {
      this.$refs.popUpImg.Open(Content);
    },
    onFocus({ Channel,
      ChannelType,
      Content,
      Keyword,
      MsgType,
      Receiver,
      SendId,
      SendTime,
      Sender,
      ServiceNo }) {
      this.navStr = ChannelType == ChannelTypeEnum.Public ? `[${Channel}]${Sender}：${Content}` : `${Sender}→${Receiver}：${Content}`;
      this.nick = Sender;
      this.focusId = SendId;
    },
    onSecretBtnClick({ Channel,
      ChannelType,
      Content,
      Keyword,
      MsgType,
      Receiver,
      SendId,
      SendTime,
      Sender,
      ServiceNo }) {
      this.$refs.secretMsgPannel.show(Sender);
    },
    onDownloadBtnClick(info) {
      this.$refs.downloadPannel.show(info);
    },
    async showPlayer() {
      if (!this.nick) {
        this.$eventBus.$emit("showAlert", this.$t('請重新點選'));
      }
      $(this.$refs.loading).show();
      await this.玩家控管功能.call(this).catch((reason) => {
        this.$eventBus.$emit("showAlert", reason);
      });
      $(this.$refs.loading).fadeOut(400);
    },
    async 一鍵複製(textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        $(this.$refs.copyTip).fadeIn().delay(2000).fadeOut();
      } catch (e) {
        this.$eventBus.$emit("showAlert", e);
      }
    },
    mouseenter(e, item, isLeft) {
      $(this.$refs.imgToolTip).fadeIn(400);
      this.imgToolTip.src = item.Content;
      //左邊的列表，圖偏右；右邊的列表，圖偏左(150是圖片寬度)
      let left = isLeft ? (e.clientX + 10) : (e.clientX - 10 - 150);
      this.imgToolTip.style.left = left + "px";
      this.imgToolTip.style.top = (e.clientY + 10) + "px";
    },
    mouseout(e, item) {
      $(this.$refs.imgToolTip).hide();
    },
    selAllPublicChannels(頻道列表, isRest) {
      if (!isRest && 頻道列表.publicTypes.length == this.checkedPublicChannels) {
        //這是擋已經全選了又全選，避免觸發後續的重新filter、拉捲軸等動作
        return;
      }
      this.checkedPublicChannels = [];
      for (let i of 頻道列表.publicTypes) {
        this.checkedPublicChannels.push(i);
      }
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("on密語回覆", this.on密語回覆);
    window.removeEventListener('resize', this.fixResize);
    removeResizeDiv();
  },
}
</script>
<style lang="scss" scoped>
@import "~/src/assets/scss/channel.scss";
</style>
<style scoped>
.box {
  border-top: none;
  margin-bottom: 0;
  border-radius: 0;
}

.box .overlay,
.overlay-wrapper .overlay {
  background: rgba(255, 255, 255, 0.2);
}

.popform {
  color: #333333;
}

.copyTipParent {

  position: relative;
}

.copyTip {
  border-radius: 5px;
  background-color: #D0EAE7;
  color: #236a50;
  border-width: 0px;
  border-style: solid;
  padding: 5px 20px;
  display: none;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  position: absolute;
  z-index: 10;
  right: 0;
  top: -40px;
}

.imgToolTip {
  position: fixed;
  background-color: #322f2f;
  width: 150px;
  height: 150px;
  z-index: 500;
}
</style>