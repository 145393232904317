<template>
  <!--序號產生-->
  <div class="modal fade" ref="exchangeItems">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">道具編輯</h4>
        </div>
        <div class="modal-body">
          <div class="table-responsive mt-10">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>道具類別名稱</td>
                  <td>
                    <select class="form-control" v-model="edit.itemTypeId">
                      <option value>不分類</option>
                      <option
                        v-for="(item, index) in datas"
                        :key="index"
                        :value="item.itemTypeId"
                      >
                        {{ item.itemTypeTitle }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>道具代碼</td>
                  <td>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="edit.itemCode"
                    />
                  </td>
                </tr>
                <tr>
                  <td>道具名稱</td>
                  <td>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="edit.itemTitle"
                    />
                  </td>
                </tr>
                <tr>
                  <td>道具說明</td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control"
                      rows="4"
                      maxlength="500"
                      placeholder="上限500字"
                      v-model="edit.itemInfo"
                    />
                  </td>
                </tr>
                <tr>
                  <td>說明</td>
                  <td>
                    <textarea
                      type="text"
                      class="form-control"
                      rows="4"
                      maxlength="500"
                      placeholder="上限500字"
                      v-model="edit.itemNote"
                    />
                  </td>
                </tr>
                <tr>
                  <td>狀態</td>
                  <td>
                    <div class="radio-inline">
                      <iCheckRedioButton
                        checkValue="0"
                        message="上架"
                        name="radioMode"
                        v-model.number="edit.isStop"
                      />
                    </div>
                    <div class="radio radio-inline">
                      <iCheckRedioButton
                        checkValue="1"
                        message="下架"
                        name="radioMode"
                        v-model.number="edit.isStop"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" @click="UpdateExchangeItems()">確認</button>
          <button class="btn btn-default pull-right" data-dismiss="modal" type="button">取消</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
import iCheckRedioButton from "@/components/iCheckRedioButton";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    iCheckRedioButton
  },
  data() {
    return {
      editMode: false,
      edit: {
        itemCode: "",
        itemTitle: "",
        itemInfo: "",
        itemNote: "",
        isStop: 0,
      },
      datas: [],
    };
  },
  computed: {
    ...mapState({
      exchangeItems: state => state.exchangeItems.query,
      response: state => state.exchangeItems.response
    })
  },
  watch: {
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "成功!!");
        $(this.$refs.exchangeItems).modal("hide");
        this.refreshList();
      } else {
        if (value.message == "") this.$eventBus.$emit("showAlert", "失敗!!");
        else this.$eventBus.$emit("showAlert", value.message);
      }
    },
    exchangeItems() {
      this.edit = this.exchangeItems;
      this.edit.itemTypeId = this.edit.itemTypeId || "";
    }
  },
  methods: {
    showExchangeItemsEdit(item) {
      if (item == "" || item == undefined) {
        this.edit = {};
        this.edit.itemTypeId = "";
        this.edit.itemCode = "";
        this.edit.itemTitle = "";
        this.edit.itemInfo = "";
        this.edit.isStop = 0;
        this.editMode = false;
      } else {
        this.editMode = true;
        this.GetItemsQuery(item);
      }
      this.GetItemsType();
      $(this.$refs.exchangeItems).modal("show");
    },
    GetItemsType() {
      this.$store
        .dispatch("exchanges/queryAll", { isStop: 0 })
        .then((result) => {
          this.datas = result.datas || [];
        });
    },
    GetItemsQuery(item) {
      //道具
      this.$store.dispatch("exchangeItems/exchangeItemsQuery", {
        itemId: item.itemId
      });
    },
    UpdateExchangeItems() {
      if (!this.edit.itemCode) {
        this.$eventBus.$emit("showAlert", "道具代碼不可為空!");
        return;
      }

      if (!this.edit.itemTitle) {
        this.$eventBus.$emit("showAlert", "道具名稱不可為空!");
        return;
      }

      if (this.editMode) {
        this.$store.dispatch("exchangeItems/exchangeItemsUpdate", this.edit);
      } else {
        this.$store.dispatch("exchangeItems/exchangeItemsCreate", this.edit);
      }
    },
    refreshList() {
      this.$emit("refreshList");
      return;
    }
  }
};
</script>