import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    muteList: [],
    muteQuery: [],
    muteResponse: "",
}

// getters
const getters = {
}

// actions
const actions = {
    search({ commit }, data) {
        api.qppMute.search(data).then(res => {
            if (res.data.status == "1") {
                commit('setMuteList', res.data.content);
            }
        })
    },
    query({ commit }, data) {
        api.qppMute.search(data).then(res => {
            commit('setMuteQuery', res.data);
        })
    },
    create({ commit }, data) {
        api.qppMute.create(data).then(res => {
            commit('setMuteResponse', res.data);
        })
    },
    delete({ commit }, data) {
        api.qppMute.delete(data).then(res => {
            commit('setMuteResponse', res.data.content);
        })
    },
}

// mutations
const mutations = {
    setMuteList(state, muteList) {
        state.muteList = muteList;
    },
    setMuteQuery(state, muteQuery) {
        state.muteQuery = muteQuery;
    },
    setMuteResponse(state, muteResponse) {
        state.muteResponse = muteResponse;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}