import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    detailQueryAll: [],
    detailQuery: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    classTypeQueryAll({ commit }, data) {
        api.classType.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setClassTypeQueryAll', res.data.result);
            }
        })
    },
    classTypeQuery({ commit }, data) {
        api.classType.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setClassTypeQuery', res.data.result);
            }
        })
    },
    classTypeCreate({ commit }, data) {
        api.classType.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    classTypeUpdate({ commit }, data) {
        api.classType.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    classTypeDelete({ commit }, data) {
        api.classType.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    classTypeDetailQueryAll({ commit }, data) {
        api.classTypeDetail.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailClassTypeQueryAll', res.data.result);
            }
        })
    },
    classTypeDetailQuery({ commit }, data) {
        api.classTypeDetail.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailClassTypeQuery', res.data.result);
            }
        })
    },
}

// mutations
const mutations = {
    setClassTypeQueryAll(state, classTypeQueryAll) { //類別多筆
        var tmp = classTypeQueryAll.datas;
        if (tmp != null) {
            for (var i = 0; i < tmp.length; i++) {
                tmp[i]['editor'] = 0;
            }
            classTypeQueryAll.datas = tmp;
            state.queryAll = classTypeQueryAll;
        } else state.queryAll = classTypeQueryAll;
    },
    setClassTypeQuery(state, classTypeQuery) { //類別單筆
        state.query = classTypeQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setDetailClassTypeQueryAll(state, classTypeDetailQueryAll) {
        state.detailQueryAll = classTypeDetailQueryAll;
    },
    setDetailClassTypeQuery(state, classTypeDetailQuery) {
        state.detailQuery = classTypeDetailQuery;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}