<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>遊戲參與編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">遊戲參與編輯</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <!-- <div class="form-group" style="">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.classTypeId">
                    <option disabled="disabled" value>請選擇分類</option>
                    <option
                      :key="index" selected="selected"
                      :value="item.classTypeId"
                      v-for="(item, index) in bannerTypes.datas" 
                    >{{item.className}}</option>
                  </select>
                </div>
              </div>  -->
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 名稱：<br />(僅在後台顯示)
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.bannerTitle"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="AdPic">
                  <span class="text-red">*</span> 圖片：
                </label>
                <div class="col-sm-9">
                  <div class="pic" style=" background-color: #d0d0d0;     max-width: 33%;    position: relative;">
                    <img draggable="false" :src="edit.filesUrl" />
                  </div>
                  <input accept="image/*" id="AdPic" type="file" @change="onFileChange($event)" />
                  <div class="help-block">
                    <span class="text-red" id="note1">建議尺寸500*500</span>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="上架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="下架"
                      name="radioMode"
                      v-model="edit.isStop"
                    />
                  </div>
                </div>
              </div> -->
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState } from "vuex";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      isEdit: false,
      projectId: 'omp_xac',// 專案Id
      lang: "zh-tw",
      edit: {
        bannerId: utils.getTimeId(), //廣告Id
        classTypeId: "all", //分類Id
        bannerTitle: "", // 名稱
        bannerLink: "", // 網址
        module:'game',
        isStop: 0, // 狀態
        isLinkOpen: 1, // 另開視窗
        orderNum: 0, // 排序
        filesUrl: "" // 圖片
      }
    };
  },
  created() {},
  mounted() {
    this.GetBannerClassTypes();
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetAdvertisement();
    }
    this.ShowNote();
  },
  computed: {
    ...mapState({
      bannerTypes: state => state.classTypes.queryAll,
      banner: state => state.banner.query,
      response: state => state.banner.response,
      fileUploadResponse: state => state.file.response
    })
  },
  watch: {
    banner() {
      this.edit = this.banner;
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");
        this.$router.push({
          name: "XacGamePartnerList",
          params: { searchData: this.$route.params.searchData }
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      } else {
        if (this.isEdit) {
          this.$store.dispatch("banner/bannerUpdate", this.edit);
        } else {
          this.$store.dispatch("banner/bannerCreate", this.edit);
        }
      }
    }
  },
  methods: {
    //取得該廣告資料
    GetAdvertisement() {
      this.$store.dispatch("banner/bannerQuery", {
        bannerId: this.$route.params.id,
        module: "game"
      });
    },

    //取得分類資料
    GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "game",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XacGamePartnerList",
        params: { searchData: this.$route.params.searchData }
      });
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },
    removeFile() {
      document.getElementById("AdPic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", 'game');
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data);
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          that.edit.filesUrl = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);

      // if (this.edit.classTypeId == "") {
      //   this.$eventBus.$emit("showAlert", "請選擇分類");
      //   return;
      // }

      const classId = 'all';

      let folderName = "game";
      let override = false;

      if (this.edit.bannerTitle == "") {
        this.$eventBus.$emit("showAlert", "名稱不能為空");
        return;
      }

      if (classId.indexOf("override") != -1) {
        override = true;
        folderName = classId.split("_")[1];
      }

      let AdPic = document.getElementById("AdPic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.bannerId,
          false,
          override
        );
      } else if (this.edit.filesUrl == "") {
        this.$eventBus.$emit("showAlert", "請上傳圖片!!");
        return;
      } else {
        if (this.isEdit) {
          this.$store.dispatch("banner/bannerUpdate", this.edit);
        } else {
          this.$store.dispatch("banner/bannerCreate", this.edit);
        }
      }
    },

    ShowNote() {
      // switch (this.projectId) {
      //   case "omp_dk":
      //     $("#note1").text("首頁輪播圖片建議尺寸 498 * 248，請勿小於該尺寸。");
      //     $("#note2").text("推薦遊戲圖片建議尺寸 190 * 85，請勿小於該尺寸。");
      //     break;
      //   case "omp_yoe":
      //     $("#note1").text("EDM 1200 * 550，請勿小於該尺寸。");
      //     $("#note2").text("活動專區 400 * 270，請勿小於該尺寸。");
      //     break;
      //   case "omp_jx2":
      //     $("#note1").text("首頁輪播圖片建議尺寸 915 * 402，請勿小於該尺寸。");
      //     $("#note2").hide();
      //     break;
      // }
    }
  }
};
</script>