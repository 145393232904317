/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
const state = {
  queryAll: [],
  query: [],
  response: "",
}

// getters
const getters = {
}

// actions
const actions = {
  queryAll({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.group.queryAll(data).then(res => {
        if (res.data.isSuccess) {
            const result = res.data.data;
            commit('setGroupQueryAll', result);
            resolve(result);
        }
      });
    });
  },
  query({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.group.query(data).then(res => {
        if (res.data.isSuccess) {
            const result = res.data.data;
            commit('setGroupQuery', result);
            resolve(result);
        }
      });
    });
  },
  add({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.group.add(data).then(res => {
        resolve(res);
      });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.group.update(data).then(res => {
        resolve(res);
      });
    });
  },
  imageUpload({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.group.queryAll(data).then(res => {
        if (res.data.isSuccess) {
            const result = res.data.data;
            commit('setGroupQueryAll', result);
            resolve(result);
        }
      });
    });
  },
  // queryAll({ commit }, data) {
  //   api.newtalk.election.group.queryAll(data).then(res => {
  //       if (res.data.status == "1") {
  //           commit('setGroupQueryAll', res.data.result);
  //       }
  //   })
  // },
}

// mutations
const mutations = {
  setGroupQueryAll(state, groupQueryAll) {
      state.queryAll = groupQueryAll;
  },
  setGroupQuery(state, groupQuery) {
    state.query = groupQuery;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}