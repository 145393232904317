<template>
    <div>
        <section class="content-header">
            <h1>發票中獎專區</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">發票中獎專區</li>
            </ol>
        </section>
        <section class="content">
            <div class="nav-tabs-custom box" style="border-top:0px">
                <ul class="nav nav-tabs">
                    <li :class="{ active: 當前頁籤 == TabEnum.tab_prize }"><a href=""
                            @click.prevent="switchTab(TabEnum.tab_prize)">發票中獎專區</a>
                    </li>
                    <li :class="{ active: 當前頁籤 == TabEnum.tab_print }"><a href=""
                            @click.prevent="switchTab(TabEnum.tab_print)">發票列印歷程</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane" :class="{ active: 當前頁籤 == TabEnum.tab_prize }">
                        <InvoicePrizeList @switcPrintTab="switcPrintTab"></InvoicePrizeList>
                    </div>
                    <div class="tab-pane" :class="{ active: 當前頁籤 == TabEnum.tab_print }">
                        <InvoicePrintList ref="invoicePrintList"></InvoicePrintList>
                    </div>
                </div>
                <div class="overlay" v-show="loading">
                    <i class=" fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
import InvoicePrizeList from "@/views/projects/ips/Invoice/InvoicePrizeList";
import InvoicePrintList from "@/views/projects/ips/Invoice/InvoicePrintList";
import { mapState } from "vuex";
export const TabEnum = {
    tab_prize: "發票中獎專區",
    tab_print: "發票列印歷程",
}
export default {
    components: {
        InvoicePrizeList,
        InvoicePrintList
    },
    data() {
        return {
            TabEnum,
            當前頁籤: TabEnum.tab_prize
        };
    },
    watch: {
    },
    computed: {
        ...mapState({
            loading: (state) => state.invoice.loading,
        }),
    },
    mounted() {
        this.$store.dispatch('invoice/loading', false);
    },
    methods: {
        switchTab(頁籤) {
            this.當前頁籤 = 頁籤;
        },
        switcPrintTab({ 查詢方式, 訂單編號, 訂單購買門號 }) {
            this.當前頁籤 = TabEnum.tab_print;
            this.$refs.invoicePrintList.autoSearch({ 查詢方式, 訂單編號, 訂單購買門號 });
        }
    },
    filters: {
    }
};
</script>
  
<style scoped></style>