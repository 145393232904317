<template>
<div class="c-listTable">

  <div class="top-box">
    <div class="select-group">
      <select v-model="searchData.classTypeId" @change="clickSearch" class="form-control" required="required">
        <option value>全部</option>
        <option v-for="(item, index) in bannerTypes.datas" :value="item.classTypeId" :key="index">
          {{item.className}}
        </option>
      </select>
    </div>
    <div class="text">
      <p class="data-num">
        <span v-if="searchShow = 1">資料總筆數：</span>
        <span v-if="searchShow != 1">搜尋結果筆數：</span>
        <span id="total">{{ banners.total }}</span>
      </p>
    </div>
  </div>


  <div class="table-responsive">
    <table class="table table-hover table-bordered">
      <thead>
        <tr class="bg-gray text-nowrap">
          <th v-for="(layout, index) in tableLayout" :key="index" v-text="layout.name"></th>
        </tr>
      </thead>
      <tbody v-if="banners.total > 0">
        <tr v-for="(item, row) in banners.datas" :key="row">
          <td v-for="(layout, col) in tableLayout"  :key="col">

            <!-- TYPES: text, number, image, url, boolean, date,  -->

            <div v-if="layout.type=='text'" v-text="item[layout.content]" class="text-ellipsis"></div>

            <picture v-else-if="layout.type=='image'"><img :src="item[layout.content]" :alt="item[layout.content]"></picture>

            <a v-else-if="layout.type=='url'" :href="item[layout.content]" v-text="item[layout.content]" target="_blank" class="text-ellipsis"></a>

            <div v-else-if="layout.type=='number'" v-text="item[layout.content]" class="text-center text-nowrap"></div>

            <div v-else-if="layout.type=='boolean'" v-text="formatBoolean(item[layout.content], layout.value)" class="text-center text-nowrap"></div>

            <div v-else-if="layout.type=='date'" v-text="formatDate(item[layout.content], layout.value)" class="text-center text-nowrap"></div>

            <div v-else-if="layout.type=='html'" v-html="layout.value"></div>

            <div v-else-if="layout.type=='options'" class="flex-menu flex-center">
              <div v-for="(option, index) in layout.value" :key="index">

                <div v-if="option.move" class="sort-wrapper">
                  <input type="number" v-model="item[layout.content]">
                  <button v-text="option.move" title="排序更新" @click="moveItem(item)" class="btn btn-warning">排序</button>
                </div>
                
                <button v-else-if="option.edit" title="編輯資料" v-text="option.edit" @click="editItem(item)" class="btn btn-primary">修改</button>

                <button v-else-if="option.remove" title="刪除資料" v-text="option.remove" @click="removeItem(item)" class="btn btn-danger">刪除</button>

              </div>
            </div>

          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr><td :colspan="tableLayout.length" class="text-center">沒有資料</td></tr>
      </tbody>
    </table>
  </div>
  <div class="box-footer text-center no-border" v-show="banners.total > 0">
      <Pagination
        ref="pagination"
        :total="banners.total"
        :pageLimitCount="searchData.pageSize"
        v-on:pageMethod="onPageChange"
      />
  </div>
  <Editor ref="editor" :editorLayout="editorLayout" />

</div>
</template>

<style lang="scss">
  .c-listTable{
    .btn-add{
      width: 112px;
    }
    .table{
      margin-top: 10px;
    }
    picture{
      display: block;
      min-width: 6em;
      
    }
    img{
      display: block;
      max-height: 5em;
      margin: 0 auto;
    }
    .flex-menu{
      display: flex;
      margin:0 auto;
      align-items: flex-start;
      *{
        margin-left: 4px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    .flex-appart {
      justify-content: space-between;
    }
    .flex-center {
      justify-content: center;
    }
    .text-ellipsis {
      display: -webkit-box;
      max-height: 80px;
      min-width: 6em;
      max-width: 32em;
      margin:0 auto;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
    .sort-wrapper{
      display: flex;

      input[type="number"]{
        border: 1px solid #ddd;
        border-radius: 3px 0 0 3px;
        padding: 6px 0 6px .333em;
        width: 54px;
        max-width: 100%;
        &:hover, &:focus{
          border-color: #ccc;
          box-shadow: inset 0 0 1px 1px #eee;
          outline: none;
        }
        & + button{
          margin-left: -1px;
          border-radius: 0 3px 3px 0;
        }
      }
    }
  }
</style>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/Pagination";
import Editor from "./Editor.vue";

export default {
  components: {
    Pagination,
    Editor
  },
  props:{
    tableLayout: Array,
    editorLayout: Array
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetBannerClassTypes();
    this.GetBannerQueryAll();
  },
  computed: {
    ...mapState({
      bannerTypes: state => state.classTypes.queryAll,
      banners: state => state.banner.queryAll,
      response: state => state.banner.response
    })
  },
  watch: {
    bannerTypes() {
     if (this.bannerTypes.datas.length) {
        this.searchData.classTypeId = this.bannerTypes.datas[0].classTypeId;
      }
      this.GetBannerQueryAll();
    },
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetBannerQueryAll();
    },
    banners() {
      this.$refs.pagination.setPage(
        this.searchData.pageNum,
        this.banners.total
      );
    }
  },
  data(){
    return{
      total: 0, //總筆數
      searchData: {
        module: "friendlyLink",
        keywoard:"",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10 //每頁顯示筆數
      },
      editData: {}
      // TYPES: text, number, image, url, boolean, date, html, options
      /*  tableLayout:[
            {
              name: "表格欄位標頭名稱",
              content: "指定物件裡的哪一筆資料",
              type: "選擇資料的呈現方式", 
              value: "可帶入的參數"
            },{
              name: "第二個欄位...",
              content:"哪一筆資料",
              type: "呈現方式",
              value: "參數"
            },{...}
          ],
      */
      // type 呈現方式:
      /*  
          text: 文字,    沒參數
          number: 數字,  沒參數
          image: 圖片,   沒參數
          url: 連結      沒參數
          boolean:布林    參數: "是文字 : 否文字"
          date:日期/時間  參數: toLocaleDateString("zh-tw", 寫這個設定物件 )
          html:HTML      參數: html字串
          options:功能   參數: [{功能名稱:"按鈕上文字"},{move:"排序"},{edit:"編輯"},{remove:"刪除"},]
      */
    }
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.GetBannerQueryAll();
    },

    GetBannerQueryAll() {
      //列表
      this.$store.dispatch("banner/bannerQueryAll", this.searchData);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    async GetBannerClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: this.searchData.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },
    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetBannerQueryAll();
    },

    // DATA EDITING //

    removeItem(item){
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("banner/bannerDelete", item);
        }
      });
    },
    editItem(item){
      this.$refs.editor.getData(item); // 參考
    },
    moveItem(item) {
      item.orderNum = parseInt(item.orderNum);
      this.$store.dispatch("banner/bannerUpdate", item);
    },
    // TEXT FORMATING //

    formatBoolean(boolean, value = "yes : no"){
      return boolean ? value.split(":")[0].trim() :value.split(":")[1].trim();
    },
    formatDate(date, options = {year:"numeric",month:"2-digit",day:"2-digit"}){
      if (!isNaN(Date.parse(date))){
        return new Date(date).toLocaleDateString("zh-tw", options);
      }
      else return date;
    },
  },
}
</script>