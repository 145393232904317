<template>
  <div id="SMSProvider">
    <section class="content-header">
      <h1>帳號管理新增/編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">帳號管理新增/編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">新增/編輯</h4>
            <button class="btn btn-block btn-default btn-cog" @click="backList()" type="button">
              返回
            </button>
          </div>

          <div class="form-horizontal">
            <div class="box-body">

              <div class="form-group mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 帳號：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    v-model="data.accId"
                    :disabled="isEdit"
                  />
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 服務名稱：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    v-model="data.accName"
                  />
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 密碼：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    v-model="data.accPassword"
                  />
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 型態：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    v-model="data.accType"
                  >
                    <option value="0">一般</option>
                    <option value="1">廣告簡訊</option>
                    <option value="8">活動簡訊</option>
                    <option value="5">特殊越南</option>
                    <option value="7">abenla</option>
                  </select>
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 指定門號：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    v-model="data.smsCom"
                  >
                    <option value="0">系統預設發送</option>
                    <template
                      v-for="(com, index) in comList"
                    >
                      <option
                        v-if="com.可指定發送 == 1"
                        :value="com.簡訊商門號代碼"
                      >
                        {{ com.簡訊商門號說明 }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> IP：
                </label>
                <div class="col-sm-8">
                  <p class="text-red">※IP與IP之間請用逗號區分，修改後五分鐘後生效。</p>
                  <textarea
                    class="form-control"
                    v-model="data.ip"
                    rows="3"
                  />
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span></span> 備註：
                </label>
                <div class="col-sm-8">
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="data.note"
                  >
                  </textarea>
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 互動簡訊：
                </label>
                <div class="col-sm-8">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="啟用"
                      name="radioMode"
                      v-model="data.scmOpen"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="停用"
                      name="radioMode"
                      v-model="data.scmOpen"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group mt-20 mb-20" v-if="data.scmOpen == '1'">
                <div class="col-sm-2"></div>
                <div class="col-sm-8" style="border: 4px solid #e6e6e6;">
                  <div class="form-group mt-20 mb-20">
                    <label class="col-sm-2 control-label text-nowrap">
                      <span></span> 互動通知連結：
                    </label>
                    <div class="col-sm-8">
                      <input
                        class="form-control"
                        v-model="data.scmPassUrl"
                      />
                    </div>
                  </div>
    
                  <div class="form-group mt-20 mb-20">
                    <label class="col-sm-2 control-label text-nowrap">
                      <span></span> 前置代號：
                    </label>
                    <div class="col-sm-8">
                      <input
                        class="form-control"
                        v-model="data.scmCode"
                      />
                    </div>
                  </div>
    
                  <div class="form-group mt-20 mb-20">
                    <label class="col-sm-2 control-label text-nowrap">
                      <span></span> 預設互動簡訊商：
                    </label>
                    <div class="col-sm-8">
                      <select
                        class="form-control"
                        id="selectUnit"
                        v-model="data.scmCom"
                      >
                        <option value="">系統指定</option>
                        <option value="1">遠傳</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mt-20 mb-20">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 帳號狀態：
                </label>
                <div class="col-sm-8">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="啟用"
                      name="radioMode1"
                      v-model="data.isOpen"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      checkValue="0"
                      message="停用"
                      name="radioMode1"
                      v-model="data.isOpen"
                    />
                  </div>
                </div>
              </div>

              <div class="btns text-center">
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-8">
                  <button class="btn btn-primary btn-lg" @click="save()" type="button">
                    確定送出
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckRedioButton,
  },
  data() {
    return {
      isEdit: false,
      data: {
        accId: "", //帳號
        accPassword: "", //密碼
        accName: "", //服務名稱
        isOpen: "1", //啟用 0:否 1:是
        accType: "0", //型態 0=一般 1= 廣告帳戶 2=系統帳號 3=一般特殊 4=廣告特殊 5=特殊越南 7=abenla 8=活動簡訊
        ip: "", //ip
        Note: "", //備註
        smsCom: "0", //指定簡訊商門號
        scmOpen: "0", //開啟互動 1:開啟 0:關閉
        scmPassUrl: "", //互動通知URL
        scmCode: "", //前置代號
        scmCom: "", //預設互動簡訊商
      },
      comList: []
    };
  },
  created() {
    this.getComList();
  },
  mounted() {
    if(this.$route.params.data) {
      this.isEdit = true;
      this.data = this.$route.params.data
    }
  },
  methods: {
    getComList() {
      api.moloSMS.user.SmsComTypeQueryAll()
        .then((res) => {
          console.log(res.data);
          this.comList = res.data.result;
        })
    },
    save() {
      if(this.data.accId == "") {
        this.$eventBus.$emit("showAlert", "請輸入帳號");
        return
      }
      if(this.data.accName == "") {
        this.$eventBus.$emit("showAlert", "請輸入服務名稱");
        return
      }
      if(this.data.accPassword == "") {
        this.$eventBus.$emit("showAlert", "請輸入密碼");
        return
      }

      let type = this.isEdit ? 'update' : 'add'
      api.moloSMS.user[type](this.data)
        .then((res) => {
          console.log(res.data);
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", "更新成功!!");
            this.$router.push({ name: "SMSAccount" });
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        })
    },
    backList() {
      this.$router.push({
        name: "SMSAccount",
        params:{
          searchData: this.$route.params.searchData
        }
      })
    }
  },
};
</script>
