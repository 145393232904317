<template>
  <div id="ForumReportList" class="normal">
    <section class="content-header">
      <h1>討論區檢舉訊息</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">討論區檢舉訊息</li>
      </ol>
    </section>
    <section class="content forum-report">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group clearfix">
              <label class="control-label">檢舉時間：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                  :propTimePicker="true"
                />
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="檢舉人ID"
                type="text"
                v-model="searchData.reportId"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control mr-10"
                placeholder="被檢舉人ID"
                type="text"
                v-model="searchData.reportedId"
              />
            </div>
            <div class="btns">
              <button
                @click="resetDataList()"
                class="btn btn-default"
                type="button"
              >
                查詢
              </button>
              <button
                @click="muteAccount()"
                class="btn btn-danger"
                type="button"
              >
                <i class="fa fa-minus-circle"></i> 禁言
              </button>
              <button
                @click="readReport()"
                class="btn btn-primary"
                type="button"
              >
                <i class="fa fa-check"></i> 已讀
              </button>
            </div>
            <p class="text text-red" style="width: 100%">
              * 禁言天數不輸入或大於3650天，為永久禁言。
            </p>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-1200">
              <tbody>
                <tr class="bg-gray">
                  <th>
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th>編號</th>
                  <th style="min-width: 180px">檢舉時間</th>
                  <th style="min-width: 120px">檢舉人ID</th>
                  <th style="min-width: 120px">被檢舉人ID</th>
                  <th style="min-width: 50px">類型</th>
                  <th style="min-width: 100px">文章ID</th>
                  <th style="min-width: 200px">檢舉內容</th>
                  <th style="min-width: 200px; width: 20%">禁言備註</th>
                  <th style="min-width: 80px">禁言天數</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="10">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="10">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">
                    {{ item.reportDate | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td>
                    <div>{{ item.reportId }}</div>
                    <div class="mt-5" v-if="item.reportNickName">
                      ({{ item.reportNickName }})
                    </div>
                  </td>
                  <td>
                    <div>{{ item.reportedId }}</div>
                    <div class="mt-5" v-if="item.beReportedNickName">
                      ({{ item.beReportedNickName }})
                    </div>
                  </td>
                  <td class="text-center">
                    <span :class="{ 'text-red': item.isDelete }">
                      {{ getText(item) }}
                    </span>
                  </td>
                  <td>
                    <span class="link" @click="showContent(index)">
                      {{ item.reportTargetId }}
                    </span>
                  </td>
                  <td>{{ item.reportData }}</td>
                  <td>
                    <input
                      class="form-control"
                      type="text"
                      v-model="item.note"
                    />
                  </td>
                  <td>
                    <input
                      class="form-control text-center"
                      min="1"
                      placeholder="輸入天數"
                      style="min-width: 100px"
                      type="number"
                      v-model.number="item.dateNumber"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border clearfix">
          <select
            class="form-control page-size"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="10">單頁顯示10項</option>
            <option value="20">單頁顯示20項</option>
            <option value="40">單頁顯示40項</option>
            <option value="100">單頁顯示100項</option>
          </select>
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <forumReportAlert
      ref="forumReportAlert"
      :muteDatas="muteDatas"
      :checkIds="checkIds"
      :targetIds="targetIds"
      v-on:updateList="getDataList"
    />
    <!-- <forumContent ref="forumContent" :getDataList="getDataList" :list="datas" :page="'report'" /> -->
    <!-- 內容 -->
    <div class="modal fade" ref="forumContent" id="forumContent" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide"
                v-for="(data, index) in datas"
              >
                <forumReportContent
                  ref="forumReportContent"
                  :item="data"
                  :index="index"
                  :getDataList="getDataList"
                />
              </div>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
    <announcementAlert
      ref="announcementAlert"
    />
  </div>
</template>

<style lang="scss" scoped>
#ForumReportList #forumContent .modal-content {
  background: transparent;
  box-shadow: none;
}

#ForumReportList #forumContent .modal-content .swiper-slide {
  background: #fff;
}

#ForumReportList #forumContent .swiper-button-next {
  width: 50px;
  right: -15%;
  color: #fff;
  opacity: 1;
  font-weight: bolder;
}

#ForumReportList #forumContent .swiper-button-prev {
  width: 50px;
  left: -15%;
  color: #fff;
  opacity: 1;
  font-weight: bolder;
}

#ForumReportList #forumContent .swiper-button-disabled {
  opacity: .5;
  pointer-events: auto;
}
</style>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import forumReportAlert from "@/views/projects/Newtalk/ForumReportAlert";
import forumContent from "@/views/projects/Newtalk/ForumContent";
import forumReportContent from "@/views/projects/Newtalk/ForumReportContent";
import Swiper from 'swiper/swiper-bundle.min.mjs';
import announcementAlert from "@/views/projects/Newtalk/AnnouncementAlert";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    forumReportAlert,
    forumContent,
    forumReportContent,
    announcementAlert,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 10,
        processYn: 0, // -1或未帶=全搜尋，0=未讀，1=已讀
        reportId: "",
        reportedId: "",
        startTime: new moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        startRow: 0,
        endRow: 0,
      },
      datas: [],
      muteDatas: [],
      muteIds: [],
      checkIds: [],
      targetIds: [],
      isCheckAll: false,
      mySwiper: null,
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();

    this.mySwiper = new Swiper(".swiper", {
      autoplay: {
        delay: 500000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      observer: true, 
      observeParents: true
    });
  },
  methods: {
    getDataList() {
      this.clearCheckAll();
      this.setPageRow(this.searchData.pageNum, this.searchData.pageSize);
      this.$store
        .dispatch("newtalk/reportQueryAll", this.searchData)
        .then((result) => {
          this.datas = result.reportData || [];
          this.total = result.totalNumber;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    setPageRow(pageIdx, pageSize) {
      this.searchData.endRow = pageIdx * pageSize;
      this.searchData.startRow = this.searchData.endRow + 1 - pageSize;
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    getText(item) {
      switch (item.articleType) {
        case 1:
          return "話題";
        case 2:
          return "個人";
        case 3:
          return "留言";
        case 4:
          return "回覆";
        default:
          return "";
      }
    },
    clearCheckAll() {
      this.muteDatas = [];
      this.muteIds = [];
      this.checkIds = [];
      this.targetIds = [];
      this.checkAll({ checked: 0 });
      this.$forceUpdate();
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.datas.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    checkChange(data) {
      if (data.checked && !this.checkIds.includes(data.value.serialNumber)) {
        this.checkIds.push(data.value.serialNumber);
        this.targetIds.push(data.value.reportTargetId);
        if (!this.muteIds.includes(data.value.reportedId)) {
          this.muteIds.push(data.value.reportedId);
          this.muteDatas.push(data.value);
        }
      } else if (
        !data.checked &&
        this.checkIds.includes(data.value.serialNumber)
      ) {
        const index = this.checkIds.indexOf(data.value.serialNumber);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.targetIds.splice(index, 1);
        }
        if (this.muteIds.includes(data.value.reportedId)) {
          const index = this.muteIds.indexOf(data.value.reportedId);
          if (index !== -1) {
            this.muteIds.splice(index, 1);
            this.muteDatas.splice(index, 1);
          }
        }
      }
    },
    hideContent(index) {
      $(this.$refs.forumContent).modal("hide");
    },
    showContent(index) {
      this.mySwiper.slideTo(index, 100, false);
      $(this.$refs.forumContent).modal("show");
      // this.$refs.forumContent.showForumContent(item);
    },
    showDelete(item) {
      this.$refs.announcementAlert.showAlert(item);
    },
    deleteItem(item) {
      const type = item.replyId || item.articleType === 4 ?
       "reply" : item.articleType === 1 ?
       "article" : "comment";
      item.articleId = item.articleId ? item.articleId : item.commentId || item.reportTargetId;
      item.commentId = item.commentId || item.reportTargetId;
      item.replyId = item.replyId || item.reportTargetId;
      this.$store.dispatch(`newtalk/${type}Disable`, item).then(() => {
        this.datas[item.index].isDelete = 1;
      });
    },
    muteAccount() {
      if (this.muteIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇禁言對象！");
        return;
      }
      this.$refs.forumReportAlert.showReportAlert();
    },
    readReport(index) {
      this.datas.splice(index, 1);
      this.$forceUpdate();
    }
  },
};
</script>
