import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    detailQueryAll: [],
    detailQuery: [],
    response: "",
    imgList: [],
}

// getters
const getters = {
}

// actions
const actions = {
    gameQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.game.queryAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setGameQueryAll', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    gameQuery({ commit }, data) {
        api.game.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setGameQuery', res.data.result);
            }
        })
    },
    gameCreate({ commit }, data) {
        api.game.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    gameUpdate({ commit }, data) {
        api.game.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    gameUpdateSort({ commit }, data) {
        api.game.updateSort(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    gameUpdateListSort({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.game.updateListSort(data.sortDatas).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                      commit('setResponse', res.data);
                    }
                }
                resolve(res.data);
            })
        });
    },
    gameDelete({ commit }, data) {
        api.game.delete(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    gameDetailQueryAll({ commit }, data) {
        api.gameDetail.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailGameQueryAll', res.data.result);
            }
        })
    },
    gameDetailQuery({ commit }, data) {
        api.gameDetail.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setDetailGameQuery', res.data.result);
            }
        })
    },
    gameImgList({ commit }, data) {
        api.game.imgList(data).then(res => {
            if (res.data.status == "1") {
                commit('setImgList', res.data.result.imgUrlList);
            }
        })
    },
}

// mutations
const mutations = {
    setGameQueryAll(state, gameQueryAll) {
        state.queryAll = gameQueryAll;
    },
    setGameQuery(state, gameQuery) {
        state.query = gameQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setDetailGameQueryAll(state, gameDetailQueryAll) {
        state.detailQueryAll = gameDetailQueryAll;
    },
    setDetailGameQuery(state, gameDetailQuery) {
        state.detailQuery = gameDetailQuery;
    },
    setImgList(state, imgList) {
        state.imgList = imgList;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}