<template>
  <div id="VersionSettings">
    <section class="content-header">
      <h1>儲值品項管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/FC/TopupRecordList">儲值查詢</router-link>
        </li>
        <li class="active">儲值品項管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              @click="addType()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">編號</th>
                  <th style="min-width: 150px">品項產品ID</th>
                  <th>品項名稱</th>
                  <th>品項價格</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%"
                  >
                    啟用狀態
                  </th>
                  <th style="min-width: 150px; width: 20%">功能</th>
                </tr>
                <!-- 排序要降序 -->
                <tr v-if="datas.length === 0">
                  <td colspan="6">查詢無任何資料</td>
                </tr>
                <tr v-else :key="index" v-for="(item, index) in datas">
                  <td>{{ datas.length - index }}</td>
                  <td>
                    <p v-if="!item.isEdit">{{ item.產品項目 }}</p>
                    <input
                      class="form-control"
                      type="text"
                      v-else
                      v-model.trim="item.產品項目"
                    />
                  </td>
                  <td>
                    <p v-if="!item.isEdit">{{ item.產品類別 }}</p>
                    <input
                      class="form-control"
                      type="text"
                      v-else
                      v-model.trim="item.產品類別"
                    />
                  </td>
                  <td>
                    <p v-if="!item.isEdit">{{ item.點數 | currencyFormat }}</p>
                    <input
                      class="form-control"
                      type="text"
                      v-else
                      v-model.trim="item.點數"
                    />
                  </td>
                  <td class="text-center" align="center">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        type="checkbox"
                        name="ckbx-style-8"
                        :id="`check${index}`"
                        :false-value="1"
                        :true-value="0"
                        v-model.number="item.status"
                      />
                      <label :for="`check${index}`"></label>
                    </div>
                  </td>
                  <td>
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-show="!item.isEdit"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.isEdit"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="cancel(item)"
                      class="btn btn-default"
                      v-show="item.isEdit"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchDataEdit.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      total: 0, //總筆數
      searchDataEdit: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
      },
      datas: [],
      item: {},
      orginItem: {},
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    resetDataList() {
      this.searchDataEdit.pageNum = 1;
      this.getDataList();
    },
    getDataList() {
      // TODO: 儲值品項管理，此功能暫時不做
      const today = new Date();
      this.searchDataEdit.endTime = new Date(`${today} UTC`);
      this.searchDataEdit.startTime = new Date(
        `${today.setDate(today.getDate() - 1)} UTC`
      );
      this.searchDataEdit.platform = "Android";
      this.$store
        .dispatch("fcDeposit/itemQueryAll", this.searchDataEdit)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchDataEdit.pageNum = pageIdx;
      this.searchDataEdit.pageSize = pageSize;
      this.getDataList();
    },
    // 切換文字或輸入框
    change(item) {
      this.cancel(this.item);
      item.isEdit = true;
      this.item = item;
      this.orginItem.產品項目 = item.產品項目;
      this.orginItem.產品類別 = item.產品類別;
      this.orginItem.點數 = item.點數;
      this.$forceUpdate();
    },
    // 儲存
    save(item) {
      if (item.產品項目 === "") {
        this.$eventBus.$emit("showAlert", "品項產品ID不得為空！");
        return;
      }
      if (item.產品類別 === "") {
        this.$eventBus.$emit("showAlert", "品項名稱不得為空！");
        return;
      }
      if (item.點數 === "") {
        this.$eventBus.$emit("showAlert", "品項價格不得為空！");
        return;
      }
      if (
        this.orginItem.產品項目 !== item.產品項目 ||
        this.orginItem.產品類別 !== item.產品類別 ||
        this.orginItem.點數 !== item.點數 ||
        item.isCreate
      ) {
        this.getDataList();
      } else {
        this.$eventBus.$emit("showAlert", "資料沒有變動！");
      }
    },
    // 新增類別
    addType() {
      if (this.datas.length && this.datas[0].isCreate) {
        this.$eventBus.$emit("showAlert", "您尚未儲存新增項目！");
        return;
      }
      this.cancel(this.item);
      this.item = {
        產品項目: "",
        產品類別: "",
        點數: "",
        stauts: 0,
        isEdit: true,
        isCreate: true,
      };
      this.datas.splice(0, 0, this.item);
    },
    cancel(item) {
      item.isEdit = false;
      if (item.isCreate) {
        this.datas.splice(0, 1);
        item.isCreate = false;
      } else {
        item.產品項目 = this.orginItem.產品項目;
        item.產品類別 = this.orginItem.產品類別;
        item.點數 = this.orginItem.點數;
        this.$forceUpdate();
      }
    },
    backList() {
      this.$router.push({
        name: "TopupRecordList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>