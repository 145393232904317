<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>{{ id ? "編輯" : "新增"}}BANNER</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li>BANNER管理列表</li>
        <li class="active">{{ id ? "編輯" : "新增"}}BANNER</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">{{ id ? "編輯" : "新增"}}內容</h4>
            <button type="button" @click="backList()" class="btn btn-block btn-default btn-cog fa fa-reply">
              返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm" @submit="$event.preventDefault(); Save()">
            <div class="box-body">
              
              <div class="form-group" v-show="bannerTypes.total > 1">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select class="form-control select-part" v-model="edit.classTypeId">
                    <option selected disabled>請選擇分類</option>
                    <option v-for="(item, index) in bannerTypes.datas" :key="index" :value="item.classTypeId" :class_id="item.classId">
                      {{ item.className }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 名稱：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputTitle" class="form-control" v-model="edit.note" required placeholder="自己辨識用，不出現在前端" />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  BN標題：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputTitle" class="form-control" v-model="edit.bannerTitle" placeholder="選填，建議10字內" />
                </div>
              </div>

              <div class="form-group bannercontent-part">
                <label class="col-sm-2 control-label" for="bannercontent">
                  BN內文：
                </label>
                <div class="col-sm-10">
                  <textarea id="bannercontent" class="form-control" placeholder="選填，建議50字內"
                    v-model="edit.bannerContent">
                  </textarea>
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="AdPic">
                  <span class="text-red">*</span> 圖片：
                </label>
                <div class="col-sm-10">
                  <input type="file" id="AdPic" accept="image/jpeg, image/png, image/gif" @change="onFileChange($event)" />
                  <div class="help-block">
                    <span class="text-red">※檔案尺寸960&times;540，小於2Mb，格式JPG、PNG、GIF</span>
                  </div>
                  <div class="pic">
                    <img :src="previewImg" draggable="false" alt="" style="max-height:200px;" />
                  </div>
                </div>
              </div>

              <div class="form-group bannerlink-part">
                <label class="col-sm-2 control-label" for="inputLink">
                  <span class="text-red">*</span> 連結：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputLink" class="form-control" v-model="edit.bannerLink" required />
                </div>
                <label class="col-sm-2 control-label"></label>
                <div class="col-sm-10">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" :checked="!!edit.isLinkOpen" @change="edit.isLinkOpen = +$event.currentTarget.checked"
                        style="position:relative; width:1.5em; height:1.5em; vertical-align:sub; margin-top:-.2em; accent-color:#3498db; cursor:pointer;"
                      >
                      <!-- <iCheckCheckBox
                        v-bind:propChecked="edit.isLinkOpen"
                        v-on:update:checked="edit.isLinkOpen = $event['checked']"
                      /> -->
                      另開視窗
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputLink">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input type="text" id="inputOrderNum" class="form-control" v-model.number="edit.orderNum" />
                </div>
              </div>

              <!-- <div class="form-group" v-if="projectId != 'omp_fantacity' && !coverMode">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="0" message="上架" name="radioMode" v-model="edit.isStop" />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton checkValue="1" message="下架" name="radioMode" v-model="edit.isStop" />
                  </div>
                </div>
              </div> -->

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 上架時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <div class="pull-right">
                        <DatePicker ref="formalStartDate" :propValue.sync="startDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="formalStartTime" class="form-control timepicker" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 下架時間：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="input-group date">
                      <div class="input-group-addon fa fa-calendar"></div>
                      <div class="pull-right">
                        <DatePicker ref="formalEndDate" :propValue.sync="endDate" />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon fa fa-clock-o"></div>
                      <input type="text" id="formalEndTime" class="form-control timepicker" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="box-footer">
              <div class="btns">
                <button type="submit" class="btn btn-primary btn-lg fa fa-save">
                  確認送出
                </button>
              </div>
            </div>
          </form>
          <!-- <pre>{{ edit }}</pre> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import DatePicker from "@/components/DatePicker";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    DatePicker,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      projectId: this.$store.state.projectId, // 專案Id
      lang: "zh-tw",
      edit: {
        bannerId: utils.getTimeId(), //廣告Id
        classTypeId: "", //分類Id
        bannerTitle: "", 
        bannerContent: "", // 內文
        bannerLink: "", // 網址 => 標題
        note: "",
        isStop: 0, // 狀態
        isLinkOpen: 0, // 另開視窗
        orderNum: 1, // 排序
        filesUrl: "", // 圖片
        formalStartTime: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        formalEndTime: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
        testStartTime: new moment().add(1, "days").format("YYYY-MM-DDT00:00:00"),
        testEndTime: new moment().add(8, "days").format("YYYY-MM-DDT23:59:59"),
      },
      previewImg: "",
      startDate: "",
      endDate: "",
    };
  },
  created() {},
  mounted() {
    window.v = this;
    let that = this;

    $(".timepicker").timepicker({
      showInputs: false,
    });
    $(".timepicker").on("change", function(){ that.updateTime() } );

    this.GetBannerClassTypes();
    if (this.$route.params.id > 0) {
      this.isEdit = true;
      this.GetAdvertisement();
    }
    this.initTime();
  },
  computed: {
    ...mapState({
      bannerTypes: (state) => state.nmjClassTypes.queryAll,
      banner: (state) => state.nmjWebBanner.query,
      response: (state) => state.nmjWebBanner.response,
      fileUploadResponse: (state) => state.file.response,
    }),
  },
  watch: {
    "startDate": function(){ this.updateTime() },
    "endDate": function(){ this.updateTime() },
    banner() {
      this.edit = this.banner;
      this.previewImg = this.banner.filesUrl;
      this.initTime();
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");
        this.backList();
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      } else {
        if (this.isEdit) {
          this.$store.dispatch("nmjWebBanner/bannerUpdate", this.edit);
        } else {
          this.$store.dispatch("nmjWebBanner/bannerCreate", this.edit);
        }
      }
    },
  },
  methods: {
    initTime() {
      this.startDate = this.edit.formalStartTime;
      this.endDate = this.edit.formalEndTime;
      $("#formalStartTime").val(this.global.getTime(this.startDate));
      $("#formalEndTime").val(this.global.getTime(this.endDate));
      this.updateTime();
    },
    updateTime() {
      this.edit.formalStartTime = new Date(`${this.startDate} ${$("#formalStartTime").val()} UTC`);
      this.edit.formalEndTime = new Date(`${this.endDate} ${$("#formalEndTime").val()} UTC`);
      this.edit.testStartTime = new Date(`${this.startDate} ${$("#formalStartTime").val()} UTC`);
      this.edit.testEndTime = new Date(`${this.endDate} ${$("#formalEndTime").val()} UTC`);
    },


    //取得該廣告資料
    GetAdvertisement() {
      this.$store.dispatch("nmjWebBanner/bannerQuery", {
        bannerId: this.$route.params.id,
        module: "banner",
      });
    },

    //取得分類資料
    GetBannerClassTypes() {
      //分類
      this.$store.dispatch("nmjClassTypes/classTypeQueryAll", {
        module: "banner",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      }).then(
        (res) => {
          console.log(res)
        }
      );
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "NmjWebBanner",
        params: { searchData: this.$route.params.searchData },
      });
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          return this.$eventBus.$emit("showAlert", "您選擇的檔案格式不符，請重新選擇。");
        }

        if (fileSize > 2000000) {
          this.$eventBus.$emit("showAlert", "您選擇的檔案超過上限 2 MB，請重新選擇。");
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },

    removeFile() {
      document.getElementById("AdPic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.lang);
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("nmjClassTypes/fileUpload", data).then( res => {
        console.log(res)
        if (res.status == 1 && res.result && res.result[0] && res.result[0].filesUrl){
          this.edit.filesUrl = res.result[0].filesUrl;
          // console.log(this.edit)
          if (this.isEdit) {
            this.$store.dispatch("nmjWebBanner/bannerUpdate", this.edit).then(() => this.backList() );
          } else {
            this.$store.dispatch("nmjWebBanner/bannerCreate", this.edit).then(() => this.backList() );
          }
        }
        
      });
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          that.previewImg = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
      else if (/^data/.test(this.previewImg)) this.previewImg = this.edit.filesUrl;
    },

    //送出
    async Save() {
      // this.edit.isStop = parseInt(this.edit.isStop);
      if (isNaN(this.edit.orderNum) || this.edit.orderNum == 0){
        this.edit.orderNum = 1;
      }
      if (this.bannerTypes.total == 1){
        this.edit.classTypeId = this.bannerTypes.datas[0].classTypeId;
      }
      if (!this.edit.classTypeId){
        return this.$eventBus.$emit("showAlert", "請選擇分類");
      }
      if (!this.edit.note){
        return this.$eventBus.$emit("showAlert", "請選填寫名稱");
      }
      if (!this.edit.bannerLink){
        return this.$eventBus.$emit("showAlert", "請選填寫連結");
      }

      let folderName = "banner";
      let override = false;

      if (!this.edit.formalStartTime) {
        return this.$eventBus.$emit("showAlert", "正式開始時間不能為空");
      }

      if (!this.edit.formalEndTime) {
        return this.$eventBus.$emit("showAlert", "正式結束時間不能為空");
      }

      let AdPic = document.getElementById("AdPic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.bannerId,
          false,
          override
        );
      }
      else if (!this.edit.filesUrl) {
        return this.$eventBus.$emit("showAlert", "請上傳圖片!!");
      }
      else {
        if (this.isEdit) {
          this.$store.dispatch("nmjWebBanner/bannerUpdate", this.edit).then(() => this.backList() );
        } else {
          this.$store.dispatch("nmjWebBanner/bannerCreate", this.edit).then(() => this.backList() );
        }
      }
    },
  },
};
</script>
