@@ -1,292 +0,0 @@
<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>BANNER管理列表</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li class="active">BANNER管理列表</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button type="button" @click="addNew()" class="btn btn-block btn-warning btn-add fa fa-plus">
              新增
            </button>
            <div class="select-group">
              <select id="selectUnit" class="form-control" v-if="bannerTypes.total > 1" v-model="searchData.classTypeId" @change="clickSearch" required>
                <option value>所有分類</option>
                <option v-for="(item, index) in bannerTypes.datas" :key="index" :value="item.classTypeId">
                  {{ item.className }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select class="form-control" v-model="filterStatus" @change="clickSearch" required>
                <option value>所有狀態</option>
                <option value="進行中">進行中</option>
                <option value="等待上線">等待上線</option>
                <option value="已過期">已過期</option>
              </select>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="(searchShow = 1)">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ filteredBanners.length }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10 text-center">
            <table class="table table-hover table-bordered AdListTable">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 80px; width: 5%" v-show="bannerTypes.total > 1">分類</th>
                  <th style="min-width:140px; width: 5%">排序</th>
                  <th style="min-width:120px; width:10%">主圖</th>
                  <th style="min-width:120px; width:10%">名稱</th>
                  <th style="min-width: 80px; width: 5%">狀態</th>
                  <th style="min-width:100px; width:10%">開始時間</th>
                  <th style="min-width:100px; width:10%">結束時間</th>
                  <th style="min-width:140px; width: 5%">功能</th>
                </tr>
                <tr v-if="filteredBanners.length <= 0">
                  <td class="text-center" colspan="10">無資料</td>
                </tr>
                <tr v-else v-for="(item, index) in pagedBanners" :key="item.id">
                  <td v-show="bannerTypes.total > 1">{{ item.className }}</td>
                  <td class="td-sort">
                    <input type="number" v-model="item.orderNum" min="1" :max="banners.total" :disabled="item.isStop == 1"
                      style="max-width:54px; padding:5px;" />
                    <button @click="updateBannerOd(item)" class="btn btn-warning btn-add btn-upDate" type="button" v-if="item.isStop != 1">更新</button>
                  </td>
                  <td>
                    <div class="pic jqimgFill">
                      <img :src="item.filesUrl" draggable="false" alt="" />
                    </div>
                  </td>
                  <td>{{ item.note }}</td>
                  <td>{{ GetStatus(item, false) }}</td>
                  <td>{{ uiDate(item.formalStartTime) }}</td>
                  <td>{{ uiDate(item.formalEndTime) }}</td>
                  <td>
                    <button @click="editorItem(item)" class="btn btn-primary">修改</button> &nbsp;
                    <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination ref="pagination"
            :total="filteredBanners.length"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <!-- <pre style="line-height:1.1;">{{ filteredBanners[0] }}</pre> -->
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    Pagination,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      total: 0, //總筆數
      projectId: this.$store.state.projectId,

      searchData: {
        // projectId: this.$store.state.projectId,
        module: "banner",
        classTypeId: "",
        Lang: "zh-tw",
        isDelete: 0,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
      coverMode: false, // 蓋板廣告(賀緹)
      hasIsOn: '',
      filterStatus: "",
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetBannerClassTypes();
    this.GetBannerQueryAll();
  },
  mounted() {
    window.v = this;
  },
  computed: {
    ...mapState({
      bannerTypes: (state) => state.nmjClassTypes.queryAll,
      banners: (state) => state.nmjWebBanner.queryAll,
      response: (state) => state.nmjWebBanner.response,
    }),
    filteredBanners(){
      let filtered = [];
      if (!this.banners.datas) return filtered;
      if (!this.filterStatus) filtered = this.banners.datas;
      else for (let item of this.banners.datas){
        if (this.GetStatus(item) == this.filterStatus) filtered.push(item);
      } 
      return filtered;
    },
    pagedBanners(){
      return this.filteredBanners.slice((this.searchData.pageNum-1) * this.searchData.pageSize, (this.searchData.pageNum) * this.searchData.pageSize)
    }
  },
  watch: {
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.GetBannerQueryAll();
    },
    // banners() {
    //   this.$refs.pagination.setPage(
    //     this.searchData.pageNum,
    //     this.filteredBanners.length
    //   );
    // },
  },
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      // this.GetBannerQueryAll();
    },

    GetBannerQueryAll() {
      //列表
      let getAll = Object.assign({}, this.searchData);
      delete getAll.pageNum;
      delete getAll.pageSize;
      console.log(getAll)
      this.$store.dispatch("nmjWebBanner/bannerQueryAll", getAll);

      if (this.$route.params.searchData != undefined)
        this.$refs.pagination.setPage(this.searchData.pageNum, filteredBanners.length);
    },

    async GetBannerClassTypes() {
      //分類
      this.$store.dispatch("nmjClassTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: "banner",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    updateBannerOd(item) {
      let data = this.searchData;

      api.nmjWebBanner.queryAll({
        module: "banner",
        classTypeId: item.classTypeId,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
        pageNum: 1,
        pageSize: 1, //每頁顯示筆數
      }).then((res) => {
        if (res.data.status == "1") {
          let count = parseInt(res.data.result.total);
          item.orderNum = parseInt(item.orderNum);
          if (item.orderNum == 0 || isNaN(item.orderNum)) item.orderNum = 1;
          // item.isStop = parseInt(item.isStop);
          if (item.orderNum > count) {
            this.$eventBus.$emit(
              "showAlert",
              "排序不可大於最大排序(" + count + ")!!"
            );
            return;
          }

          this.$store.dispatch("nmjWebBanner/bannerUpdate", item);
        }
      });
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.GetBannerQueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "NmjWebBannerEdit",
        params: { searchData: this.searchData },
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "NmjWebBannerEdit",
        params: { id: item.bannerId, searchData: this.searchData },
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          this.$store.dispatch("nmjWebBanner/bannerDelete", item).then(()=>{
            this.GetBannerQueryAll();
          });
        }
      });
    },
    GetStatus(item) {
      if (!item.formalStartTime || !item.formalEndTime) return "無設時間";
      if (new moment().isBefore(item.formalStartTime)) return "等待上線";
      if (new moment().isAfter(item.formalEndTime)) return "已過期";
      return "進行中";
    },
    uiDate(date){
      let d = new Date(date);
      if (date && d) return d.toLocaleString('zh-TW', {
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit",
        second:"2-digit",
        hourCycle:"h23",
      });
      else return '';
    },
  },
};
</script>