<template>
  <div class="modal fade" id="imgAlert" ref="BigDataImg">
    <div class="modal-dialog">
      <div class="modal-content">
        <button
          aria-label="Close"
          class="close c-outClose"
          data-dismiss="modal"
          type="button"
        >
          <span aria-hidden="true">×</span>
        </button>
        <div class="modal-body tc">
          <img :src="peopleImg.main_img" />
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      peopleImg: [],
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    showContent(item) {
      this.peopleImg = item;
      $(this.$refs.BigDataImg).modal("show");
    },
    showSuccess(content) {
      $(this.$refs.BigDataImg).modal("hide");
      this.$emit("kolList");
    },
  },
};
</script>
