<template>
  <div>
    <highcharts
      ref="highcharts"
      :options="chartOptions"
      v-if="!loading"
    ></highcharts>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["datas", "chartTitle"],
  data() {
    return {
      loading: true,
      seriesDataList: [],
      chartOptions: {},
    };
  },
  methods: {
    switchBadgeColor(name) {
      return this.$parent.switchBadgeColor(name);
    },
    setChartOptions() {
      const chartTitle = this.chartTitle;
      this.chartOptions = {
        chart: {
          type: "pie",
          width: 550,
          height: "auto",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
        },
        title: {
          text: chartTitle,
          style: {
            fontSize: "30px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        tooltip: {
          outside: true,
          formatter: function () {
            return (
              "<b>" +
              this.key +
              "</b>" +
              "： " +
              this.point.tickets +
              "票 (" +
              this.point.rate +
              "%)"
            );
          },
          style: {
            fontSize: "15px",
            fontFamily: "Microsoft JhengHei",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.tickets}票<br />{point.rate}%",
              style: {
                fontSize: "15px",
                fontFamily: "Microsoft JhengHei",
              },
            },
            showInLegend: true,
          },
        },
        legend: {
          itemStyle: {
            fontSize: "18px",
            font: "18px Microsoft JhengHei",
          },
        },
        credits: {
          enabled: false,
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                "downloadJPEG",
                "downloadPNG",
                "downloadSVG",
                "downloadPDF",
              ],
            },
          },
          enabled: false,
        },
        series: [],
      };
    },
    setSeriesDataList(datas) {
      this.loading = true;
      this.setChartOptions();

      let subDataList = datas;
      //替換object的key名
      datas.data.forEach((item) => {
        item.color = this.switchBadgeColor(item.badge);
      });
      subDataList.size = 250;
      subDataList.type = "pie";
      subDataList.showInLegend = true;
      this.chartOptions.series = [subDataList];
      this.loading = false;
    },
  },
};
</script>
