/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    list: [],
    total: 0,
    people: 0,
    amount: 0,
    response: ""
}

// getters
const getters = {

}

// actions
const actions = {
    queryAll({ commit }, data) {
        api.ipsPaymentOwed.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setList', res.data.content.list);
                commit('setTotal', res.data.content.total);
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    statistics({ commit }, data) {
        api.ipsPaymentOwed.statistics(data).then(res => {
            if (res.data.status == "1") {
                commit('setAmount', parseInt(res.data.content.總金額));
            } else {
                commit('setAmount', 0);
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    update({ commit }, data) {
        api.ipsPaymentOwed.update(data).then(res => {
            if (res.data.status == "1") {
                this._vm.$eventBus.$emit("showAlert", "修改完成");
                commit('setResponse', res.data);
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
    updateAsync({ commit }, data) {
        return api.ipsPaymentOwed.update(data);
    },
    create({ commit }, data) {
        return api.ipsPaymentOwed.create(data);
    },
    export({ commit }, data) {
        let typeName = "購點欠費紀錄";
        api.ipsPaymentOwed.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.content.list.length <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                }
                appUtils.exportData(
                    res.data.content.list,
                    appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
                    typeName
                );
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    }
}

// mutations
const mutations = {
    setList(state, list) {
        state.list = list;
    },
    setTotal(state, total) {
        state.total = total;
    },
    setAmount(state, amount) {
        state.amount = amount;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}