<template>
  <div id="AlbumEdit">
    <section class="content-header">
      <h1>相簿編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>相簿列表</li>
        <li class="active">相簿編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <!-- <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 語系切換：
                </label>
                <div class="col-sm-10">
                  <div class="btn-group">
                    <button
                      type="button"
                      :class="[item.id == editLang ? 'btn btn-default active':'btn btn-default' ]"
                      @click="ChangeLang(item.id)"
                      v-for="(item, index) in langTypes"
                      :key="index"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div> -->

              <div :class="[this.PermissionEdit ? 'form-group':'form-group hidestyle' ]" >
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 相簿名稱：
                </label>
                <div class="col-sm-10">
                  <input :disabled="!this.PermissionEdit" 
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.名稱"
                  />
                </div>
              </div>

               <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 功能模組：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.功能模組" :disabled="!this.PermissionEdit" >
                    <option disabled="disabled" value>請選擇類別</option>
                    <option value="2D美術" >2D美術</option>
                    <option value="3D美術" >3D美術</option>
                    <option value="動作美術" >動作美術</option>
                    <option value="娛樂美術" >娛樂美術</option>
                  </select>
                </div>
              </div> 

              <!--div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 分類編號
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.功能模組編號"
                  />
                </div>
              </div-->

              <div class="form-group" v-if="this.filetype == 'image' ">
                <label class="col-sm-2 control-label">
                  <!--<span class="text-red">*</span> -->
                  圖片：
                </label>
                <div class="col-sm-10">
                  <div class="pic">
                    <img draggable="false" :src="edit.filesUrl" />
                  </div>
                  <input
                    accept="image/*"
                    id="AnnouncementPic"
                    type="file"
                    @change="onFileChange($event)"
                    multiple
                  />
                  <div class="help-block">
                    <span class="text-red">{{ picLimit }}</span>
                  </div>

                  <div class="row ann-imgChoose mt-10" style="margin:0 auto;">
                    <dnd-zone
                      vertical-search
                    >
                      <dnd-container
                        :dnd-model="edit.相片列表"
                        dnd-id="list-example"
                        class="row"
                      >
                        <template v-for="card in edit.相片列表">
                          <dnd-item :dnd-model="card" :key="card.applyId" :dnd-id="card.applyId">
                            <div class="col-sm-3 col-xs-6 chooseBox">
                              <div
                                class="img-close fa fa-fw fa-close" @click="DeletePic(card)"
                                style="height: 25px; width: 25px; line-height: 20px;"
                              ></div>

                              <img :src="card.filesUrl" >
                              <!-- <div class="radio">
                                <input
                                  type="radio"
                                  :id="'mainImg'+card.applyId"
                                  name="mainImg"
                                  v-bind:checked="card.isMain == 1"
                                  v-on:change="SetPicIsMain(card)"
                                />主圖
                              </div> -->

                              <select class="form-control selectType" style="margin-top: 10px;" v-model="card.title" @change="onFileChangeType($event)" :name="card.filesId">
                                <option disabled="disabled" value selected>請選擇類別</option>
                                <option value="角色" v-if="edit.功能模組 == '2D美術' ">角色</option>
                                <option value="場景" v-if="edit.功能模組 == '2D美術' || edit.功能模組 == '3D美術'  ">場景</option>
                                <option value="武器" v-if="edit.功能模組 == '3D美術' ">武器</option>
                                <option value="運輸載具" v-if="edit.功能模組 == '3D美術' ">運輸載具</option>
                                <option value="寫實角色" v-if="edit.功能模組 == '3D美術' ">寫實角色</option>
                                <option value="UI" v-if="edit.功能模組 == '娛樂美術' ">UI</option>
                                <option value="ICON" v-if="edit.功能模組 == '娛樂美術' ">ICON</option>
                                <option value="角色" v-if="edit.功能模組 == '娛樂美術' ">角色</option>
                              </select>

                            </div>
                          </dnd-item>
                        </template>
                      </dnd-container>
                    </dnd-zone>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="this.filetype == 'video' ">
                <label class="col-sm-2 control-label">
                  影片：<br />(來源Youtube)
                  <div id="addNewVideo" @click="addInputFields" style=" 
                     background-color: #3c8dbc;    color: white;    width: 80px;  
                       text-align: center;    display: block;    padding: 8px;   
                        border: 0;    border-radius: 7px;    margin-right: 0;    margin-left: auto;    margin-top: 6px;  
                          cursor: pointer;">新增影片</div>
                </label>
                <div class="col-sm-10">
                  <div class="youtubeLine" >
                  <div v-for="(inputFields, index) in edit.相片列表" :key="index" class="cardstyle">                                            
                      <div class="t1">
                            <div class="n1">影片名稱</div>
                            <input v-model="inputFields.title"  class="form-control youtube_mame" type="text" />
                      </div>       
                        <div class="t2" >
                            <div class="n1">影片網址</div>
                            <input  v-model="inputFields.filesUrl"  class="form-control youtube_text" type="text" placeholder="直接填寫youtube網址" />
                      </div>   
                      <div class="img-close fa fa-fw fa-close" @click="removeInputFields(index)" ></div>
                  </div>     
                  </div>  
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="1"
                        message="上架"
                        name="radioMode"
                        v-model="edit.是否顯示"
                      />
                    </div>
                    <div :class="[this.PermissionEdit ? 'radio':'radio hidestyle' ]" >
                      <iCheckRedioButton
                        checkValue="0"
                        message="下架"
                        name="radioMode"
                        v-model="edit.是否顯示"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary btn-lg" type="button"
                  @click="Save(false)"
                >
                  確認送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.hidestyle{
  opacity: 0;
  pointer-events: none;
  height: 0;
}
.chooseBox img{
    height: 150px;
    width: 100%;
    object-fit: contain;
}
.youtubeLine .img-close{
      height: 25px;
    width: 27px;
    line-height: 25px;
    position: absolute;
    right: -10px;
    top: 27px;
    cursor: pointer;
        color: white;
    border: 1px solid #bc3c67;
    background-color: #bc3c67;
        border-radius: 2px;
    text-align: center;
}
.youtubeLine .t1{
 width: 28%;
}
.youtubeLine .t2{
 width: 70%;
}
.youtubeLine .n1{
display: block;margin-bottom: 2px;
}
.cardstyle{
display: flex;     position: relative;   width: 100%;    justify-content: space-between;      padding-right: 20px; margin-bottom: 15px;
}
.youtubeLine .img-add{
      height: 25px;
    width: 27px;
    line-height: 25px;
    position: absolute;
    right: -10px;
    top: 27px;
    cursor: pointer;
    color: white;
    border: 1px solid #3c8dbc;
    background-color: #3c8dbc;
    border-radius: 2px;
    text-align: center;
}
</style>
<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    DatePicker,
    tinymce,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      filetype:'',
      edit: {
        名稱: "",
        編號: 0,
        專案編號: this.$store.state.projectId,
        分類編號: "",
        功能模組: "news",
        功能模組編號: "",
        排序: "",
        是否顯示: "1",
        建立人員: "",
        語系: "zh-tw",
        相片列表: [],
        語系資訊編號: "",
      },
      imageFolder: "album",
      langTypes: {},
      timeStringOption: { timeStyle: "short" },
      timepickerOption: {},
      detailNewsLang: [],
      imgList: [],
      Videos:[],
      isPreview: false,
      picLimit: "建議尺寸1920*1080，1MB以內 ; 拖曳圖片可調整排序圖片",
      url: config.dkOption.url,
      PermissionEdit:false
    };
  },
  created() {
    this.GetAllLang();
  },
  mounted() {
    this.filetype=this.$route.params.mine;
    const getmenu = this.$store.state.loginUserMenu.find(item => item.projectId === "omp_xac");
    const checkPermission = getmenu.permissions.find(item => item === "aMax");
    if(checkPermission != null){
      this.PermissionEdit=true;
    }
  },
  computed: {
    ...mapState({
      album: (state) => state.album.query,
      newsTypes: (state) => state.classTypes.queryAll,
      newsList: (state) => state.news.queryAll,
      response: (state) => state.album.response,
      projectData: (state) => state.module.queryAll,
    }),
  },
  watch: {
    album() {
      this.edit = this.album;
      
      if(this.filetype == 'video'){
          this.edit.相片列表= this.edit.相片列表.filter(photo => photo.mine === "video");
      }else{
        this.edit.相片列表= this.edit.相片列表.filter(photo => photo.mine != "video");
      }
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;
      this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetAlbum();
      } else {
        this.detailNewsLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.newsId + i + 1;
          this.detailNewsLang.push({
            newsDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle,
            info: this.edit.detailTopInfo,
          });
        }
      }
    },

    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "儲存成功!!");
        if (!this.isPreview)
          this.$router.push({
            name: "XacAlbumList",
            params: {
              searchData: this.$route.params.searchData,
            },
          });
      } else this.$eventBus.$emit("showAlert", "儲存失敗!!");
    },
  },
  methods: {
    //取得該公告資料
    GetAlbum() {
      this.$store.dispatch("album/albumQuery", {
        id: parseInt(this.$route.params.id),
      });
    },

    GetAllLang() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    GetImgList() {
      this.SetLangData(this.editLang);
      this.$store.dispatch("news/newsDetailImgList", {
        newsDetailId: this.edit.newsDetailId,
      });
    },

    //取得該語系資料
    // GetDetailNewsLang(lang) {
    //   for (var i = 0; i < this.langTypes.length; i++) {
    //     if (lang == this.langTypes[i].id) {
    //       this.edit.newsDetailId = this.detailNewsLang[i].newsDetailId;
    //       this.edit.detailTopTitle = this.detailNewsLang[i].title;
    //       this.edit.detailTopInfo = this.detailNewsLang[i].info;
    //     }
    //   }
    // },

    //更換語系
    // ChangeLang(lang) {
    //   this.removeFile();
    //   this.SetLangData(this.editLang);
    //   this.GetDetailNewsLang(lang);
    //   this.editLang = lang;
    //   this.GetImgList();
    // },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailNewsLang[i].title = this.edit.detailTopTitle;
          this.detailNewsLang[i].info = this.edit.detailTopInfo;
        }
      }
    },

    onFileChangeType(evt){
       this.$store.dispatch("file/updateFile", 
        {
          filesId: evt.target.getAttribute('name'),
          title: evt.target.value,
        }
        );
    },

    onVideoAdd(evt) {
      var VideoList=[];
       for (var i = 0; i < this.edit.相片列表.length; i++) {
          var newVideo = {"VideoUrl": this.edit.相片列表[i].filesUrl, "Title":this.edit.相片列表[i].title};
          VideoList.push(newVideo); 
      }      
      var videoInfo = {
        MasterModel: {
          "編號":parseInt(this.edit.編號),
          "專案編號":"omp_xac"
        },
        Videos: VideoList 
      };
       this.$store.dispatch("album/albumUpdatevideo",videoInfo);

    },
    addInputFields() {
        this.edit.相片列表.push(['', '']);
    },
    removeInputFields(index) {
        this.edit.相片列表.splice(index, 1);
    },

    onFileChange(evt) {
      // this.SetLangData(this.editLang);
      // this.GetDetailNewsLang(this.editLang);
      console.log("upload")
      var files = evt.target.files;
      if (files.length > 50) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var reader = new FileReader();
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案格式不符，請重新選擇。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "您選擇的檔案超過上限 5 MB，請重新選擇。"
            );
            return;
          }
        }

        if (this.isEdit) {
          this.uploadFile(files, this.imageFolder, this.edit.編號, true);
        } 
       
      }
    },

    getMainPic() {
      this.edit.相片列表.forEach((item, index) => {
        if (item.isMain == 1) {
          radiobtn = document.getElementById("mainImg" + item.applyId);
          radiobtn.checked = true;
          return;
        }
      });
    },

    // 取消圖片
    cancelImg(lastModified) {},

    changeFileOrder() {
      this.edit.相片列表;
    },

    removeFile() {
      document.getElementById("AnnouncementPic").value = "";
    },

    uploadFile(files, folderName, id, multi = false) {

      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.edit.專案編號);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", this.editLang);
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then((res) => {
        if (res.status != "0") this.GetAlbum();
      });
    },

    SetPicIsMain(item) {
      item = this.TransImgData(item);
      this.$store.dispatch("file/setIsMain", item).then((res) => {
        if (res.status != "0") {
          this.imgList = [];
          // this.GetAlbum();
        }
      });
    },

    DeletePic(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          item = this.TransImgData(item);
          this.$store.dispatch("file/fileDelete", item).then((res) => {
            if (res.status != "0") {
              this.GetAlbum();
              this.removeFile();
            }
          });
        }
      });
    },

    TransImgData(item) {
      var imgObj = {
        id: item.applyId,
        imgFullFileName: item.filesUrl,
        imgName: item.name,
        isMain: item.isMain.toString(),
        od: item.od,
        projectId: item.projectId,
      };

      return imgObj;
    },

    checkIfNewsIdExist() {
      let isNewsExist = false
      this.newsList.datas.forEach((item, index) => {
        if (item.newsId == this.edit.功能模組編號) {
          isNewsExist = true;
        }
      });
      return isNewsExist;
    },

    getNewsList() {
      //新聞列表   
      this.$store.dispatch("news/newsQueryAll", {
        newsId: this.edit.功能模組編號,
        projectId: this.edit.專案編號,
        module: this.edit.功能模組
      });
    },

    //*---- 返回
    backList() {
      this.$router.push({
        name: "XacAlbumList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },

    //送出
    Save(isPreview) {
      this.isPreview = isPreview;
      // this.SetLangData(this.editLang);
      this.edit.是否顯示;
  
      if (this.edit.名稱 == "") {
        this.$eventBus.$emit("showAlert", "相簿名稱不可為空");
        return;
      }

      if (this.isEdit) {
        if(this.filetype == 'video'){

          let textnull=0;
          $(".youtube_text").each(function(){
            if($(this).val() == null || $(this).val() == '' ){
              textnull++;
            }       
          });
          if(textnull > 0 ){
             this.$eventBus.$emit("showAlert", "影片網址不可為空");
             return;
          }
          this.onVideoAdd();

        }else{
          
          var img_ary = [];
          if(this.edit.相片列表.length > 0){
            this.edit.相片列表.forEach((item, index) => {
              img_ary.push({
                ApplyId: item.applyId,
                Od: index+1
              })
            });
            this.$store.dispatch("file/updateFileInfoApply", img_ary);
        }
        }
              
        
        var data = new FormData();
        data.append("MasterModel.專案編號", this.edit.專案編號);
        data.append("MasterModel.功能模組", this.edit.功能模組);
        //data.append("MasterModel.功能模組編號", this.edit.功能模組編號);
        data.append("MasterModel.編號", parseInt(this.edit.編號));
        // data.append("MasterModel.排序", this.edit.排序);
        data.append("MasterModel.是否顯示", this.edit.是否顯示);
        data.append("DetailModel[0].語系資訊編號", this.edit.語系資訊編號);
        data.append("DetailModel[0].名稱", this.edit.名稱);

        this.$store.dispatch("album/albumUpdate", data);
      } else {
        var data = new FormData();
        data.append("MasterModel.專案編號", this.edit.專案編號);
        data.append("MasterModel.功能模組", this.edit.功能模組);
        //data.append("MasterModel.功能模組編號", this.edit.功能模組編號);
        data.append("DetailModel[0].語系", this.edit.語系);
        data.append("DetailModel[0].名稱", this.edit.名稱);
         this.$store.dispatch("album/albumCreate", data);
      }
    },
    isNewsInList() {
      if (this.isEdit) {
        let index = this.newsList.datas.findIndex(
          (news) => news.newsId == this.edit.newsId && news.isStop == 0
        );
        if (index != -1) return true;
      }
      return false;
    },
  },
};
</script>
