<template>
  <div class="login-wrapper">
    <div class="login-box">
      <div class="login-logo">
        <div class="logo">
          <h1 class="sr-only">網銀國際 OMP 營運管理平台</h1>
        </div>
      </div>
      <div class="login-box-body">
        <div class="h3 login-box-msg">營運管理平台</div>
        <form>
          <div class="form-group has-feedback">
            <input class="form-control" placeholder="帳號" type="text" v-model="userName" @change="getMailStatus()" />
            <!-- <input class="form-control" placeholder="帳號" type="text" v-model="userName" /> -->
            <span class="glyphicon glyphicon-user form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback">
            <input class="form-control" placeholder="密碼" type="password" v-model="password" />
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback" v-if="verifyStatus == 1">
            <input class="form-control" placeholder="驗證碼" type="text" v-model="verify" />
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <button
            class="btn btn-block btn-flat"
            type="submit"
            v-on:click.stop.prevent="login()"
          >登入</button>
          <div
            class="link text-center mt-10"
            style="text-decoration: underline;"
            @click="forgetPwd()"
            v-if="isOutsider == 1"
          >
            忘記密碼?
          </div>
          <p class="text-red mt-10">建議使用Chrome瀏覽器，並且將螢幕解析度調整為1280x900以上！</p>
        </form>
        <p>網銀國際(股)有限公司 研發處開發</p>
      </div>
      <p class="text-center mt-10">
        Copyright © 2023
        <a href="https://www.wanin.tw" target="_blank">WANIN</a> All Rights Reserved.
      </p>
    </div>
    <!-- 密碼到期  提醒彈窗 -->
    <div class="modal fade" id="attention-date" style="z-index: 1040;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              @click="goLogin()"
              type="button"
              v-if="islogin"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> 注意
            </h4>
          </div>
          <div class="modal-body">
            <p v-if="days > 0">
              您的密碼將在
              <span class="text-red">{{days}}</span>天後到期，請盡快更新密碼！
            </p>
            <p v-else>您的密碼已到期，請更新密碼！</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success" type="button" @click="midify()">前往更改密碼</button>
            <button
              class="btn btn-default pull-right"
              id="attention-dismiss"
              data-dismiss="modal"
              @click="goLogin()"
              type="button"
              v-if="islogin"
            >取消</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <midify ref="midify" :proplogin="false" />
  </div>
</template>

<script>
/* eslint-disable */
import api from '../assets/js/app-api';
import { mapState, mapActions } from 'vuex';
import moment from "moment";
import midify from "@/views/Login/midify";

export default {
  inject: ['reload'],
  components: {
    midify,
  },
  data() {
    return {
      userName: '',
      password: '',
      verify: '',
      days: 10,
      islogin: true,
      isOutsider: 0,
      verifyStatus: 0,
      isVerify : false,
    };
  },
  mounted() {
    $("body").removeClass().addClass("hold-transition login-page");
    this.$store.dispatch("loginOut");

    // this.login();
  },
  computed: mapState({
    getUser: (state) => state.loginUser,
    loginResponse: (state) => state.loginResponse,
  }),
  watch: {
    getUser(val) {
      if (val != '') {
        this.getExpired();
      }
    },
    loginResponse() {
      if (this.loginResponse.status != '1') {
        if(this.loginResponse.message == '安全性的疑慮，已寄送驗證碼至您的mail') {
          let mail = JSON.parse(this.loginResponse.result).empEmail;
          if(mail == '') this.$eventBus.$emit("showAlert", "未設定信箱無法寄送驗證碼，請聯繫相關人員處理");
          else this.$eventBus.$emit('showAlert', this.loginResponse.message + '：' + mail);
          this.verifyStatus = 1;
        } else {
          this.$eventBus.$emit('showAlert', this.loginResponse.message);
        }
      }
    },
  },
  methods: {
    getMailStatus(login = false) {
      // return
      api.login
        .mailStatus({userid: this.userName})
        .then((res) => {
          let data = JSON.parse(res.data.result)
          this.isVerify = true;
          this.isOutsider = data.isOutsider;
          this.verifyStatus = data.verifyStatus;
          //verifyStatus => 0：無啟動，1：有啟動且已發送mail驗證碼，2：無mail

          if(this.verifyStatus == 1) {
            this.$eventBus.$emit("showAlert", `
              先前輸入錯誤密碼達三次，提醒此次登入需輸入驗證碼<br>
              驗證碼已送到您的mail：${data.empEmail}
            `);
          }
          
          if(login && this.verifyStatus == 0) this.login();
        })
    },
    login() {
      if(!this.isVerify) {
        this.getMailStatus(true)
        return
      }
      if(this.verifyStatus == 1 && this.verify == '') {
        this.$eventBus.$emit("showAlert", "請輸入驗證碼");
        return
      }

      this.$store.dispatch('loginUser', {
        userid: this.userName,
        password: this.password,
        VerifyCode: this.verify,
      });
    },
    getExpired() {
      if (this.getUser.ChangePwdDate != "") {
        this.days = new moment(this.getUser.ChangePwdDate).diff(
          new moment(),
          "days"
        );

        if (this.days > 0 && this.days <= 10) {
          this.islogin = true;
          $("#attention-date").modal("show");
        } else if (this.days <= 0) {
          this.islogin = false;
          $("#attention-date").modal("show");
        } else {
          this.$router.push("/");
        }
      } else {
        this.$router.push('/');
      }
    },
    goLogin() {
      this.$router.push("/");
    },
    midify() {
      this.$refs.midify.showMidifyBox();
    },
    forgetPwd() {
      api.login.forgetPassword({userid: this.userName})
        .then((res) => {
          if(res.data.status == "1") {
            this.$eventBus.$emit("showAlert", `已將暫時密碼寄送到您的mail：${JSON.parse(res.data.result)}，請改用暫時密碼登入`);
            return
          }
          if(res.data.message == "無設定email寄送通知") {
            this.$eventBus.$emit("showAlert", "未設定信箱無法寄送新密碼，請聯繫相關人員處理");
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        })
    },
  },
};
</script>
