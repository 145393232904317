/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
    queryPatch: [],
    getRedeemCodeRecordList: [],
    getItemCodeList: [],
    getDailySignInList: [],
}

// getters
const getters = {
}

// actions
const actions = {
    eventQueryAll({ commit }, data) {
        api.event.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setEventQueryAll', res.data.result);
            }
        })
    },
    getRedeemCodeRecordList({ commit }, data){
        api.event.getRedeemCodeRecordList(data).then(res => {
            if (res.data.status == "1") {
                commit('setEventredeemCodeRecordList', res.data.result);
            }
        })
    },
    getItemCodeList({ commit }, data){
        api.event.getItemCodeList(data).then(res => {
            if (res.data.status == "1") {
                commit('setItemCodeList', res.data.result);
            }
        })
    },
    getDailySignInList({ commit }, data){
        api.event.getDailySignInList(data).then(res => {
            if (res.data.status == "1") {
                commit('setDailySignInList', res.data.result);
            }
        })
    },
    eventExport({ commit }, data) {
        api.event.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                }
                let datas = res.data.result.datas;
                // let exportDatas = [];
                // for (var i = 0; i < datas.length; i++) {
                //     let usedTime = datas[i].usedTime;
                //     let createTime = datas[i].createTime;

                //     if (usedTime.substring(0, 4) == "0001") usedTime = "";
                //     else usedTime = appUtils.formatTime(usedTime, "YYYY/MM/DD HH:mm:ss");

                //     if (createTime == null || createTime.substring(0, 4) == "0001") createTime = "";
                //     else createTime = appUtils.formatTime(createTime, "YYYY/MM/DD HH:mm:ss");

                //     exportDatas.push({
                //         獎項: datas[i].awardsTitle,
                //         使用者: datas[i].uId,
                //         ID: datas[i].id,
                //         序號: datas[i].sn,
                //         對應序號: datas[i].vendorSN,
                //         有無被兌換過: datas[i].isUsed == '1' ? "已兌換" : datas[i].isUsed == '2' ? "待確認" : "未兌換",
                //         兌換時間: usedTime,
                //         建立日期: createTime,
                //     })
                // }


                appUtils.exportData(
                    datas,
                    appUtils.formatTime(new Date(), "YYYYMMDD") + "_活動歷程" + ".xlsx",
                    "活動歷程"
                );
            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
}

// mutations
const mutations = {
    setEventQueryAll(state, eventQueryAll) {
        state.queryAll = eventQueryAll;
    },
    setEventredeemCodeRecordList(state, getRedeemCodeRecordList) {
        state.getRedeemCodeRecordList = getRedeemCodeRecordList;
    },
    setItemCodeList(state, getItemCodeList) {
        state.getItemCodeList = getItemCodeList;
    },
    setDailySignInList(state, getDailySignInList) {
        state.getDailySignInList = getDailySignInList;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}