import api from "@/assets/js/app-api";
import utils from '@/assets/js/app-utils';

// initial state
// shape: [{ id, quantity }]
const state = {
    xinList: [],
    xinTotal: [],
    xinQuery: [],
    xinResponse: "",
}

// getters
const getters = {
}

// actions
const actions = {
    search({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.xinAccount.search(data).then(res => {
                if (res.data.status == "1") {
                    commit('setXinList', res.data.content.list);
                    commit('setXinTotal', res.data.content.total);
                } 
                resolve(res.data);
            })
        })
    },

    export({ commit }, data) {
        //data = utils.deepCloneObj(data);
        api.xinAccount.search(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.content.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    res.data.content.list.forEach(item => {
                        items.push({
                            '日期': item.date,
                            '遊戲館': item.game,
                            '流量': item.amount
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_流量查詢列表.xlsx`,
                        '流量查詢'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setXinList(state, xinList) {
        state.xinList = xinList;
    },
    setXinTotal(state, xinTotal) {
        state.xinTotal = xinTotal;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}