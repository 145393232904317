<template>
  <div class="wrapper" id="App">
    <router-view v-if="isRouterAlive" />
    <AlertBox ref="alertBox" />
    <AlertAsyncBox />
  </div>
</template>

<script>
/* eslint-disable */
import AlertBox from '@/components/AlertBox';
import AlertAsyncBox from '@/components/AlertAsyncBox';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {};
  },
  components: {
    AlertBox,
    AlertAsyncBox
  },
  mounted() {
  },
  computed: mapState({
    loginUser: (state) => state.loginUser,
    isRouterAlive: (state) => state.isRouterAlive,
  }),
  watch: {
  },
  methods: {
    reload() {
      // this.isRouterAlive = false;
      // this.$nextTick(function () {
      //   this.isRouterAlive = true;
      // });
    },
  },
};
</script>
