<template>
  <div id="CollectTypeManager">
    <section class="content-header">
     <h1>收藏管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">收藏管理</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary max850">
        <div class="box-body">

          <div class="top-box">
            <button @click="addType()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增
            </button>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-bordered classTypeTable" id="classTypes">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px;" width="10%">分類ID</th>
                  <th style="min-width: 150px;" width="50%">分類名稱</th>
                  <th style="min-width: 150px;" width="10%">公用/私人</th>
                  <th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用狀態</th>
                  <th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
                </tr>

                <tr>
                  <td>sport1</td>
                  <td>奧運好手</td>
                  <td>
                    <select disabled class="w100"> 
                      <option value="public">公開</option>
                      <option value="private">私人</option>
                    </select>
                  </td>
                  <td class="text-center" align="center" data-fun="啟用狀態">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                      />
                      <label></label>
                    </div>
                  </td>
                  <td class="text-center" data-fun="功能">
                    <button @click="change(item)" class="btn btn-primary" >修改</button>
                   
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                    >刪除</button>
                    <!-- <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                    >儲存</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                    >取消</button>-->
                  </td>
                </tr>

                <!--
                <tr :key="index" v-for="(item, index) in classTypes.datas">
                  <td>
                    <p class="m-0" v-show="item.editor==0">{{ item.classId }}</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.classId"
                      v-show="item.editor!=0"
                    />
                  </td>
                  <td>
                    <p class="m-0" v-show="item.editor==0">{{ item.className }}</p>
                    <div
                      v-for="(detail, index) in detailClassLang"
                      :key="index"
                      v-show="item.editor!=0"
                    >
                      <p>{{getLangName(detail.lang)}}：</p>
                      <input
                        class="form-control"
                        required="required"
                        type="text"
                        v-model="detail.className"
                      />
                    </div>
                  </td>
                  <td>
                    <select v-model="item.module" :disabled="item.editor==0">
                      <option
                        :value="item.id"
                        :key="index"
                        v-for="(item, index) in projectData.module"
                      >{{item.name}}</option>
                    </select>
                  </td>
                  
                  <td class="text-center" align="center" data-fun="啟用狀態">
                    <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :false-value="1"
                        :id="'check'+index"
                        :true-value="0"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.isStop"
                      />
                      <label :for="'check'+index"></label>
                    </div>
                  </td>

                  <td class="text-center" data-fun="功能">
                    <button @click="change(item)" class="btn btn-primary" v-show="item.editor==0">修改</button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-show="item.editor!=0"
                    >儲存</button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-show="item.editor==0"
                    >刪除</button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-show="item.editor!=0"
                    >取消</button>
                  </td>
                </tr>
                -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  delimiters: ["${", "}"],
  data() {
    return {
      moduleId: "",
      langTypes: {},
      detailClassLang: [],
      tempEdit: false,
    };
  },
  created() {
    //this.getModuleQueryAll();
  },
  mounted() {},
  computed: {
    // ...mapState({
    //   classTypes: state => state.classTypes.queryAll,
    //   response: state => state.classTypes.response,
    //   projectData: state => state.module.queryAll,
    //   detailQueryAll: state => state.classTypes.detailQueryAll
    // })
  },
  
}
</script>
