<template>
    <div id="ForumMuteSetting">
        <section class="content-header">
            <h1> <span > {{edit.專案}}</span>
                - 貼文設定/關鍵字列表</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/FBRobot/RobotList">FB機器人</router-link>
                </li>
                <li class="active">貼文設定/關鍵字列表</li>
            </ol>
        </section>

        <section class="content forum-mute">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <h4 class="pull-left" id="title">貼文設定</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>

                    <div class="form-horizontal" name="postSetForm" method="post" @submit.prevent="submit">

                        <div class="box-body" style="max-width: 80%">
                            <label class="sr-only">
                                編號: {{edit.代號}}
                            </label>

                            <!-- 選擇貼文 -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="target_post">
                                    選擇貼文：
                                </label>
                                <div class="col-sm-10">
                                    <select class="form-control" id="target_post" 
                                        v-model="edit.貼文ID"
                                        @change="postChosen()">
                                        <option disabled="disabled" value>請選擇要設定機器人的貼文</option>
                                        <option :key="index" :value="item.貼文ID" v-for="(item, index) in fbList">
                                            ({{ item.貼文ID }}) {{ item.貼文註解 }} -
                                            {{$utils.formatTime( edit.建立時間,"YYYY-MM-DD HH:mm:ss")}}
                                        </option>
                                    </select>

                                    <a v-if="edit.貼文ID" :href="edit.貼文連結" target="_blank" class="fbPostCard">
                                        <div class="text">
                                            <p class="intro" v-html="edit.內容"></p>
                                            <p class="date">
                                                {{$utils.formatTime( edit.建立時間,"YYYY-MM-DD HH:mm:ss")}}
                                            </p>
                                        </div>
                                        <div class="pic jqImgFill"><img :src="edit.圖片連結"></div>
                                    </a>
                                </div>
                            </div>

                            <!-- 貼文註解(預設貼文內容的前25個字) -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="postBriefly">貼文註解：
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="postBriefly" required="required" type="text"
                                        :maxlength="brieflyMax" v-model="edit.貼文註解"
                                        :placeholder="`請輸入貼文註解，上限${brieflyMax}字`" />
                                </div>
                            </div>

                            <!-- 貼文回覆啟用時間 -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="startTime">
                                    回覆啟用時間：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio-date">
                                        <div class="input-group date">
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div class="pull-right">
                                                <DatePicker :propValue.sync="啟用時間" />
                                            </div>
                                        </div>
                                        <div class="input-group time">
                                            <div class="input-group-addon">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                            <input class="form-control timepicker" type="text" id="startTimeOnly" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 貼文回覆結束時間 -->
                            <div class="form-group">
                                <label class="col-sm-2 control-label" for="endTime">
                                    回覆結束時間：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio-date">
                                        <div class="input-group date">
                                            <div class="input-group-addon">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                            <div class="pull-right">
                                                <DatePicker :propValue.sync="結束時間" />
                                            </div>
                                        </div>
                                        <div class="input-group time">
                                            <div class="input-group-addon">
                                                <i class="fa fa-clock-o"></i>
                                            </div>
                                            <input class="form-control timepicker" type="text" id="endTimeOnly" />
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>

                             <div class="form-group">
                                <label class="col-sm-2 control-label">
                                    <span class="text-red">*</span> 發佈：
                                </label>
                                <div class="col-sm-10">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="上架" name="radioMode"
                                            v-model="edit.狀態" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="下架" name="radioMode"
                                            v-model="edit.狀態" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-2 control-label"> <span class="sr-only">說明</span> </label>
                                <div class="col-sm-10">
                                    <div class="help-block">
                                        <span class="text-red mt-10">
                                            *"確認送出"必選擇貼文、填寫貼文註解、啟用時間、停用時間</span>
                                    </div>
                                    <div class="help-block" v-if="edit.代號 == 0">
                                        <span class="text-red mt-10">
                                            *要設定關鍵字請先"確認送出"，再新增關鍵字</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="box-footer">
                            <div class="btns">
                                <!-- <button @click="saveDraft()" v-if="edit.狀態 === 2 || edit.代號 == null "
                                    class="btn btn-warning btn-lg" style="margin-right: 10px" type="button">
                                    儲存草稿
                                </button> -->
                                <button @click="submit()" class="btn btn-primary btn-lg" type="button">
                                    確認送出
                                </button>
                            </div>
                        </div>
                    </div>

                    <hr class="divider mb-10" />
                    <div class="top-box with-border" v-if="isEdit">
                        <div class="w100 clearfix">
                            <h4 class="pull-left" id="title">關鍵字列表</h4>
                            <button @click="addKeyword()" class="btn btn-block btn-warning btn-add fr" type="button">
                                <i class="fa fa-plus"></i> 新增關鍵字
                            </button>
                        </div>
                    </div>

                    <div class="table-responsive mt-10 m-auto2 mb-30" v-if="isEdit">
                        <table class="table table-bordered min1000 AdListTable">
                            <tbody>
                                <tr class="bg-gray">
                                    <th class="text-center">編號</th>
                                    <th style="min-width: 100px">關鍵字</th>
                                    <th style="min-width: 80px">符合度</th>
                                    <th style="min-width: 200px;width:20%" colspan="2">回覆留言</th>
                                    <th style="min-width: 200px;width:20%" colspan="2">回覆私訊</th>
                                    <th style="min-width: 80px">留言回覆數</th>
                                    <th style="min-width: 80px">私訊回覆數</th>
                                    <th style="width: 80px">自動按讚</th>
                                    <th style="width: 80px">排序</th>
                                    <th style="min-width: 80px">功能</th>
                                </tr>

                                <tr v-if="keywordData.length === 0">
                                    <td class="text-center" colspan="9">查詢無任何資料</td>
                                </tr>

                                <tr :key="index" v-else v-for="(item, index) in keywordData">
                                    <td class="text-center">{{ index+1 }} <br>
                                        <span class="sr-only">代號:{{item.代號}}</span>
                                    </td>
                                    <td class="text-center">
                                        {{ item.關鍵字 | isNull }}
                                    </td>
                                    <td class="text-center">{{ getNeed(item.符合度) }}
                                    </td>
                                    <td v-html="$options.filters.isAnyContent(item.留言回覆訊息)"></td>
                                    <td class="text-center" style="width: 80px; min-width:80px;max-width:80;">
                                        <img :src="item.留言圖片? item.留言圖片:require('@/assets/no-image2.png')" />
                                    </td>
                                    <td v-html="$options.filters.isAnyContent(item.私訊回覆訊息)"></td>
                                    <td class="text-center" style="width: 100px; min-width:80px;max-width:80;">
                                        <img :src="item.私訊圖片? item.私訊圖片:require('@/assets/no-image2.png')" />
                                    </td>
                                    <td class="text-center">{{ item.留言數 }}</td>
                                    <td class="text-center">{{ item.私訊數 }}</td>
                                    <td class="text-center">{{ getAuto(item.自動按讚) }}</td>
                                    <td class="td-sort text-nowrap">
                                        <input type="number" v-model="item.排序" />
                                        <button
                                            @click="updateGameOd(item)"
                                            class="btn btn-warning btn-add btn-upDate"
                                            type="button"
                                        >更新</button>
                                    </td>
                                    <td class="text-center">
                                        <button @click="editorKeyword(item)" class="btn btn-primary">
                                            修改</button>
                                        <br>
                                        <button @click="copy(item)" class="btn btn-warning">
                                            複製</button>
                                        <br>
                                        <button @click="remove(item)" class="btn btn-danger">
                                            刪除</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    /* eslint-disable */
    import moment from "moment";
    import DatePicker from "@/components/DatePicker";
    import iCheckRedioButton from "@/components/iCheckRedioButton";
    import {
        mapState,
        mapActions
    } from "vuex";
    import utils from "@/assets/js/app-utils";

    export default {
        components: {
            DatePicker,
            iCheckRedioButton: iCheckRedioButton,
        },

        data() {
            return {
                total: 0,
                isEdit: false, //false: is creat state, not even a draft
                brieflyMax: 25,

                edit: {
                    代號: 0,
                    專案:"",
                    貼文ID: "", //post id
                    貼文註解: "",
                    狀態: 0, //0：下架 1：上架 2：草稿
                },
                oldPostID: "",

                啟用時間: new moment().format("YYYY-MM-DD 00:00:00"),
                結束時間: new moment().format("YYYY-MM-DD 23:59:59"),

                newFbPost: [], //讀取fbPost
                selectedFbPost: [], //被選擇過的fb貼文
                isChooseSelected: false, //是否選到被選擇過的

                searchData: {
                    專案:(this.$route.params.fanPage),
                },

                // keyword
                keywordData: {},
                copyKey: [],

            };
        },
        updated() {
            this.$nextTick(function () {
                $(".jqImgFill").imgLiquid();
            });
        },
        created() {
            this.GetFbPostData();
            //this.newFbPostSelect();
            // console.log(this.$route.params.searchData);
            this.searchData = this.$route.params.searchData;
            
            if (this.$route.params.id != null) {
                this.isEdit = true;
                this.GetQueryData();
            }

            
        },
        mounted() {
            $(".timepicker").timepicker({
                showInputs: false,
            });

            this.updateTime();
            // this.cutKeyItem();
        },

        computed: {
            ...mapState({
                detailQuery: (state) => state.fbRobot.postQuery,
                fbList: state => state.fbRobot.fbList,
            }),
        },

        watch: {
            detailQuery() {
                //post
                this.edit = utils.deepCloneObj(this.detailQuery.model);
                if (this.edit.貼文ID == null) this.edit.貼文ID = "";
                if (this.edit.貼文註解 == null) this.edit.貼文註解 = "";
                if (this.edit.建立時間 == null) 
                this.getPostDate();
                // console.log(this.edit);
                this.updateTime(); //日期
                this.oldPostID = this.edit.貼文ID;
                this.$nextTick(function () {
                    this.postChosen();
                });
               
                //keyword
                this.keywordData = utils.deepCloneObj(this.detailQuery.keyword.datas);
            },

            fbList() {
                //all fb post
                this.newFbPost = utils.deepCloneObj(this.fbList);

                //selected fb posts
                this.newFbPost.forEach((item, index) => {
                    if (item.狀態 == 1) {
                        this.selectedFbPost.push(item);
                    }
                });
            },
        },

        methods: {
            /* =============== psot ================ */
            //取得該資料
            GetQueryData() {
                this.$store.dispatch("fbRobot/query", {
                    專案: (this.$route.params.fanPage),
                    代號: (Number(this.$route.params.id)),
                });
            },

            //取得所有fb貼文
            GetFbPostData() {
                this.$store.dispatch("fbRobot/fbAll", {
                    專案: (this.$route.params.fanPage),
                    狀態: -1 //狀態：-1：列出所有。0：未使用。1：已使用
                });
            },

            updateTime() {
                this.啟用時間 = this.edit.啟用時間 || this.啟用時間;
                this.結束時間 = this.edit.結束時間 || this.結束時間;

                $("#startTimeOnly").val(this.global.getTime(this.啟用時間));
                $("#endTimeOnly").val(this.global.getTime(this.結束時間));
            },

            // newFbPostSelect() {
            //     this.fbList.forEach((item, index) => {
            //         this.edit.代號
            //     });
            // },

            //index
            postChosen() {
                this.newFbPost.forEach((item, index) => {
                    if (item.貼文ID == this.edit.貼文ID) {
                        this.edit.貼文ID = item.貼文ID;
                        this.edit.貼文連結 = item.貼文連結;
                        this.edit.內容 = item.內容;
                        this.edit.圖片連結 = item.圖片連結;
                        this.edit.貼文註解 = item.貼文註解;
                        this.isChooseSelected = false;
                        // console.log(this.edit);
                    }
                });

                // console.log(this.edit.貼文ID);
            },

            getPostDate() {
                this.newFbPost.forEach((item, index) => {
                    if (item.貼文ID == this.edit.貼文ID) {
                        this.edit.建立時間 = item.建立時間;
                    }
                });
            },

            editDateUpdate() {
                const startTimeOnly = $("#startTimeOnly").val();
                const endTimeOnly = $("#endTimeOnly").val();
                this.啟用時間 = new Date(
                    `${this.啟用時間.split(" ")[0]} ${startTimeOnly}`
                );
                this.結束時間 = new Date(
                    `${this.結束時間.split(" ")[0]} ${endTimeOnly}`
                );

                if (startTimeOnly === "" || !this.global.isValidDay(this.啟用時間)) {
                    this.$eventBus.$emit("showAlert", "請填寫貼文回覆開始時間！");
                    return;
                }

                if (
                    endTimeOnly === "" ||
                    !this.global.isValidDay(this.結束時間)
                ) {
                    this.$eventBus.$emit("showAlert", "請填寫貼文回覆結束時間！");
                    return;
                }

                if (!this.global.isValidFutureDay(this.結束時間)) {
                    this.$eventBus.$emit("showAlert", "貼文「回覆結束時間」請勿填寫過去時間！");
                    return;
                }

                if (!this.global.isValidDayRange(this.啟用時間, this.結束時間)) {
                    this.$eventBus.$emit("showAlert", "請填寫正確時間區間！");
                    return;
                }

                this.edit.啟用時間 = new Date(`${this.啟用時間} UTC`);
                this.edit.結束時間 = new Date(`${this.結束時間} UTC`);

            },

            commonSubmit() {
                // console.log('this.edit.貼文ID:' + this.edit.貼文ID);
                // console.log('this.oldPostID:' + this.oldPostID);

                this.selectedFbPost.forEach((item, index) => {
                    //新選的貼文不可以是選過的 且 排除自已本來的貼文
                    if (item.貼文ID == this.edit.貼文ID &&
                        item.貼文ID !== this.oldPostID) {
                        this.isChooseSelected = true;
                        //console.log('選擇的貼文已設定過');
                        return;
                    }
                });

                if (this.isChooseSelected == true) {
                    this.$eventBus.$emit("showAlert", "選擇的貼文已設定過，請選擇其它貼文");
                    return;
                }

                this.edit.專案 = this.$route.params.fanPage;
                this.edit.狀態 = (Number(this.edit.狀態));
                if (this.edit.代號 == 0) {
                    // console.log('is creat');
                    this.$store
                        .dispatch("fbRobot/create", this.edit)
                        .then((result) => {
                            this.backList();
                        });
                } else {
                    // console.log('is edit');
                    this.$store
                        .dispatch("fbRobot/edit", this.edit)
                        .then((result) => {
                            this.backList();
                        });

                }
            },

            submit() {
                if (!this.edit.貼文ID) {
                    this.$eventBus.$emit("showAlert", "請選擇貼文！");
                    return;
                }

                if (!this.edit.貼文註解) {
                    this.$eventBus.$emit("showAlert", "請填寫貼文註解！");
                    return;
                }

                this.edit.oldPostID = this.oldPostID;

                this.editDateUpdate(); //date

                //console.log(this.edit);

        
                this.commonSubmit();
            },

            saveDraft() {
                this.editDateUpdate();
                this.edit.狀態 = 2;
                // alert('creat saveDraft');
                console.log(this.edit);
                this.commonSubmit();
            },

            // TODO: 轉跳至列表頁
            backList() {
                this.searchData.專案 = this.edit.專案;
                // console.log(this.$route.params.searchData);

                this.$router.push({
                    name: "FBRobotList",
                    params: {
                        searchData: this.$route.params.searchData,
                        
                    },
                });
            },

            /* =============== keyword ================ */

            getAuto(item) {
                switch (item) {
                    case 1:
                        return '是'
                        break;
                    case 0:
                        return '否'
                        break;
                }
            },

            getNeed(item) {
                switch (item) {
                    case 1:
                        return '完全符合'
                        break;
                    case 0:
                        return '部分符合'
                        break;
                }
            },

            addKeyword() {
                this.$router.push({
                    name: "FBKeywords",
                    params: {
                        searchData: this.searchData,
                        fanPage: this.$route.params.fanPage,
                        id: this.$route.params.id,
                    },
                });
            },

            editorKeyword(item) {
                this.$router.push({
                    name: "FBKeywords",
                    params: {
                        searchData: this.searchData,
                        fanPage: this.$route.params.fanPage,
                        id: this.$route.params.id,
                        key_id: item.代號,
                    },
                });
            },

            copy(item) {
                // console.log(item);
                this.$eventBus.$emit('showConfirm', '確定要複製這個關鍵字嗎?', (isOK) => {
                    this.$store
                        .dispatch("fbRobot/createKeywords", item)
                        .then((result) => {
                            this.GetQueryData();
                        });
                });

            },

            remove(item) {
                this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
                    if (isOK) {
                        this.$store
                            .dispatch("fbRobot/delKeywords", {
                                "代號": item.代號
                            })
                            .then((result) => {
                                this.GetQueryData();
                            });
                    }
                });
            },

            updateGameOd(item) {
                let keyData = item;
                keyData.排序 = parseInt(item.排序);
                //console.log(keyData);

                // 檢查排序值並重排
                let res = this.$store.dispatch("fbRobot/updateKeywords", keyData);
                if (res.status != "1") {
                    this.$eventBus.$emit("showAlert", "更新失敗!");
                } else {
                    this.$eventBus.$emit("showAlert", "更新成功!");
                    this.GetQueryData();
                }
                
            },
        },

        

    };

</script>
