<template>
    <div id="ClassTypeManager">
        <section class="content-header">
            <h1>{{ $t('客服系統Server管理') }}</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li class="active">{{ $t('客服系統Server管理') }}</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box">
                        <button class="btn btn-primary" @click="reConnectGameServer()">{{ $t('重連遊戲Server') }}</button>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import api from "@/assets/js/app-api";
import store from '@/store.js';
export default {
    data() {
        return {

        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            $(this.$refs.loading).hide();
        },
        reConnectGameServer() {
            let ServiceNo = store.state.projectId;
            this.$eventBus.$emit("showAsyncConfirm", this.$t('重連遊戲Server確認訊息'), this.$t('重連遊戲Server'), async () => {
                $(this.$refs.loading).show();
                try {
                    (await api.CS.ChatServerReconnect({ ServiceNo })).data;
                    this.$eventBus.$emit("showAlert", this.$t('成功'));
                } catch (e) {
                    this.$eventBus.$emit("showAlert", e);
                }
                $(this.$refs.loading).hide();
            });
        },
    }
};
</script>
<style scoped></style>