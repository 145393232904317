<template>
    <div>
        <section class="content-header">
            <h1> 新增/修改 支付設定</h1>
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingList',
                        params: $route.params,
                    });">金流設定</a>
                </li>
                <li>
                    <a :href="'/'" @click.prevent="$router.push({
                        name: 'IPSSettingPayList',
                        params: $route.params,
                    });">支付設定</a>
                </li>
                <li class="active">新增/修改 支付設定</li>
            </ol>
        </section>
        <section class="content">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="top-box with-border">
                        <h4 class="pull-left" id="title">內容</h4>
                        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
                            <i class="fa fa-reply"></i> 返回列表
                        </button>
                    </div>
                    <div class="form-horizontal">
                        <div class="box-body">
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 支付名稱：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.支付名稱" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 支付方式代碼：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.支付方式代碼" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 地區：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.地區">
                                        <option v-for="item in 地區列表" :key="item.編號" :value="item.編號">
                                            {{ item.地區 }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 限制幣別：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.限制幣別">
                                        <option v-for="item in 地區列表" :key="item.編號" :value="item.編號">
                                            {{ item.幣別 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 金流類型：
                                </label>
                                <div class="col-sm-8">
                                    <select class="selPaymentType form-control" v-model="edit.金流類型">
                                        <option v-for="(item, id) of 金流類型設定Key" :key="item.編號" :value="item.編號">
                                            {{ item.類型 }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 是否支援支付品號：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="是否支援支付品號"
                                            v-model="edit.是否支援支付品號" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="是否支援支付品號"
                                            v-model="edit.是否支援支付品號" />
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red"></span> 支付品號：
                                </label>
                                <div class="col-sm-8">
                                    <input class="form-control" type="text" v-model.trim="edit.支付品號" />
                                </div>
                            </div>

                            <template v-if="限制開關()">
                                <div class="form-group">
                                    <!-- {{ edit.額外限制 }} -->
                                    <label class="col-sm-4 control-label">
                                        <span class="text-red">*</span> 額外限制：
                                    </label>
                                    <div class="col-sm-8">
                                        <LimitControler ref="comp" :額外限制List.sync="edit.額外限制"></LimitControler>
                                    </div>
                                </div>
                            </template>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 是否開立發票：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="是否開立發票" v-model="edit.是否開立發票" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="是否開立發票" v-model="edit.是否開立發票" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label">
                                    <span class="text-red">*</span> 停用/啟用：
                                </label>
                                <div class="col-sm-8">
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="0" message="否" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                    <div class="radio radio-inline">
                                        <iCheckRedioButton checkValue="1" message="是" name="radioMode" v-model="edit.啟用" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-footer">
                            <div class="btns">
                                <button class="btn btn-primary btn-lg" type="button" @click="Save">
                                    確認送出
                                </button>&emsp;
                                <button class="btn btn-default btn-lg" type="button" @click="backList()">
                                    取消返回
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay" ref="loading">
                    <i class="fa fa-refresh fa-spin"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>




import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import { mapState } from "vuex";
import { APILib, IsSelectedInOptions } from './IPSTool2'
import LimitControler from "./components/LimitControler2.vue";
export default {
    components: {
        iCheckRedioButton,
        LimitControler
    },
    data() {
        return {
            isEdit: false,
            server端的額外限制編號: "-",//複製一份用，避免前端修改資料時動到，最後送出時，比對用
            edit: {
                啟用: "1",
                支付名稱: "",
                支付品號: "",
                支付方式代碼: "",
                是否支援支付品號: "1",
                是否開立發票: "1",
                金流設定主檔編號: parseInt(this.$route.params.ipsId),
                金流類型: 1,
                地區: -1,
                限制幣別: -1,
                額外限制: [
                    // {
                    //     編號:1,
                    //     限制主項目: "金流",
                    //     限制主條件: 1,
                    //     判斷主條件: "",
                    //     限制主參數: "",
                    //     限制細項: "[{\"編號\": 1,\"描述\": \"每月上限\",\"參數\": \"5000\"},{\"編號\": 2,\"描述\": \"每周上限\",\"參數\": \"1000\"},{\"編號\": 3,\"描述\": \"每日上限\",\"參數\": \"300\"},{\"編號\": 4,\"描述\": \"單筆上限\",\"參數\": \"100\"},{\"編號\": 5,\"描述\": \"單筆下限\",\"參數\": \"50\"}]"
                    // }
                ]
            },
        };
    },
    computed: {
        ...mapState({
            地區列表: state => state.IPSSetting.基礎設定檔.地區列表,
            額外限制列表: state => state.IPSSetting.基礎設定檔.額外限制列表,
            金額限制項目: state => state.IPSSetting.基礎設定檔.金額限制項目,
        }),
        金流類型設定Key() {
            return this.$store.getters["IPSSetting/金流類型設定Key"];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.$store.dispatch("IPSSetting/基礎設定檔_查詢", {});
                let 編號 = parseInt(this.$route.params.id);
                this.isEdit = 編號 > 0 ? true : false;
                if (this.isEdit) {
                    let find = APILib.IPS(await api.IPSSetting.金流支付設定_查詢({ 編號 })).content[0];
                    // find.額外限制 = "26,27,28,";
                    this.server端的額外限制編號 = find.額外限制;
                    if (find) {
                        find.額外限制 = await this.$store.dispatch("IPSSetting/額外限制List_查詢2", { 額外限制: find.額外限制, 額外限制列表: this.額外限制列表 });
                        delete find.建立時間;
                        this.edit = { ...find };
                    } else {
                        throw "找不到資料";
                    }
                }

                $(this.$refs.loading).hide();
            } catch (e) {
                this.$eventBus.$emit("showAlert", e);
                this.backList();
            }
        },
        backList() {
            this.$router.push({
                name: "IPSSettingPayList",
                params: { ipsId: parseInt(this.$route.params.ipsId) },
            });
        },
        限制開關() {
            return this.金流類型設定Key[this.edit.金流類型] && this.金流類型設定Key[this.edit.金流類型].限額開關 == 1
        },
        async Save() {
            // let obj = JSON.parse(JSON.stringify(this.edit));
            // this.$refs.comp.Check(obj.額外限制);
            try {
                let obj = JSON.parse(JSON.stringify(this.edit));



                if (!obj.支付名稱) {
                    throw "支付名稱不可為空";
                }
                if (!obj.支付方式代碼) {
                    throw "支付方式代碼不可為空";
                }
                if (obj.地區 <= 0 || !IsSelectedInOptions(obj.地區, this.地區列表, "編號")) {
                    throw "請選擇地區";
                }
                if (obj.限制幣別 <= 0 || !IsSelectedInOptions(obj.限制幣別, this.地區列表, "編號")) {
                    throw "請選擇限制幣別";
                }
                if (obj.金流類型 <= 0 || !IsSelectedInOptions(obj.金流類型, this.金流類型設定Key, "編號")) {
                    throw "請選擇金流類型";
                }
                if (this.限制開關() && !this.$refs.comp.Check(obj.額外限制)) {
                    throw "請檢查額外限制";
                }

                $(this.$refs.loading).show();
                if (this.限制開關()) {
                    obj.額外限制 = await this.$refs.comp.Save(this.server端的額外限制編號, obj.額外限制);
                } else {
                    obj.額外限制 = this.server端的額外限制編號;
                }

                if (this.isEdit) {
                    APILib.IPS(await api.IPSSetting.金流支付設定_修改(obj));
                } else {
                    APILib.IPS(await api.IPSSetting.金流支付設定_新增(obj));
                }
                this.$eventBus.$emit("showAlert", "成功");//失敗會跳catch，所以這邊直接show 成功
                this.backList();
            } catch (e) {
                $(this.$refs.loading).hide();
                this.$eventBus.$emit("showAlert", e);
            }

        },
    },
};
</script>
