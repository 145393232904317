/* eslint-disable */
import api from '@/assets/js/app-api';
import utils from '@/assets/js/app-utils';
import define from '@/views/projects/FC/_Define';

const state = {
    queryAll: {},
}

// getters
const getters = {
}

// actions
const actions = {
    shareQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.mftdBefore.shareQueryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    queryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.mftdBefore.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    contactQueryAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.contactUs.queryAll(data).then(res => {
                if (res.data.status === '1') {
                    const result = res.data.result;
                    commit('setQueryAll', result);
                    resolve(result);
                }
            });
        });
    },
    contactQuery({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.contactUs.query(data).then(res => {
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    contactUpdate({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.contactUs.update(data).then(res => {
                this._vm.global.showResponse(this._vm, res, 'update');
                if (res.data.status === '1') {
                    resolve(res.data.result);
                }
            });
        });
    },
    contactExport({ commit }, data) {
        data = utils.deepCloneObj(data);
        data.pageNum = 0;
        data.pageSize = 0;
        api.contactUs.queryAll(data).then(res => {
            if (res.data.status === '1') {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit('showAlert', '查無資料');
                } else {
                    const items = [];
                    res.data.result.datas.forEach(item => {
                        items.push({
                            'ID': item.contactUsId,
                            '填單時間': item.createTime,
                            '角色名稱': item.name,
                            '玩家ID': item.companyName,
                            'Email': item.email,
                            '連絡電話': item.phoneNumber,
                            '主旨': item.subject,
                            '狀態': define.handleStatus[item.status],
                            '處理人員': item.updateUName,
                        });
                    });
                    utils.exportData(
                        items,
                        `${utils.formatTime(new Date(), 'YYYYMMDD')}_聯繫客服列表.xlsx`,
                        '聯繫客服'
                    );
                }
            } else {
                this._vm.$eventBus.$emit('showAlert', res.data.message);
            }
        })
    },
}

// mutations
const mutations = {
    setQueryAll(state, queryAll) {
        state.queryAll = queryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}