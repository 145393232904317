<!--

HOW TO USE THIS COMPONENT
to show items of the table, use the prop show-list.
the value of show-list is a space seperated list of the data-types you want to display.

show-list values are: "index category title image description url file date online"

img-help="Image upload specifications go here";
file-help="File upload guide goes here";

EXAMPLE
<this-component show-list="index title image url online" img-help="圖片尺寸為 1400 * 520px，大小請勿超過 2Mb"> TITLE GOES HERE </this-component>

-->

<template>
  <div class="c-UrlListTable">

    <h3 class="text-center" v-if="this.$slots.default"><slot></slot></h3>
    
    <div v-if="editMode">
      <div class="top-box with-border">
        <h4 class="pull-left">編輯內容</h4>
        <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
          <i class="fa fa-reply"></i> 返回列表
        </button>
      </div>
      <form class="form-horizontal" name="PartnerForm">
        <div class="box-body">

          <div class="form-group td-category" v-if="showCategory">
            <label class="col-sm-2 control-label" for="videoIntro">
              <span class="text-red">*</span> 類別：
            </label>
            <div class="col-sm-10">
              <input 
                class="form-control"
                id="ContactPageLink"
                required="required"
                type="text"
                v-model="edit.PartnerTitle"
                >
            </div>
          </div>

          <div class="form-group td-title" v-if="showTitle">
            <label class="col-sm-2 control-label">
              <span class="text-red">*</span> 標題：
            </label>
            <div class="col-sm-10">
              <input 
                class="form-control"
                id="ContactPageTitle"
                required="required"
                type="text"
                v-model="edit.detailTopTitle">
            </div>
          </div>

          <div class="form-group td-description" v-if="showDescription">
            <label class="col-sm-2 control-label" for="videoIntro">
              <span class="text-red">*</span> 敘述：
            </label>
            <div class="col-sm-10">
              <input 
                class="form-control"
                id="ContactPageLink"
                required="required"
                type="text"
                v-model="list[editItem].description">
            </div>
          </div>

          <div class="form-group td-url" v-if="showLink">
            <label class="col-sm-2 control-label" for="videoIntro">
              <span class="text-red">*</span> 連結：
            </label>
            <div class="col-sm-10">
              <input 
                class="form-control"
                id="ContactPageLink"
                required="required"
                type="text"
                v-model="list[editItem].url">
            </div>
          </div>

          <div class="form-group td-file" v-if="showFile">
            <label class="col-sm-2 control-label" for="File">
              <span class="text-red">*</span> 檔案：
            </label>
            <div class="col-sm-10">
              <div class="pic">
                <img draggable="false" :src="edit.mainImgFullFileName" />
              </div>
              <input id="File" type="file" @change="onFileChange($event)" />
              <div class="help-block">
                <span class="text-red">檔案可以為: pdf, docx, xlsx, pptx 等等</span>
              </div>
            </div>
          </div>

          <div class="form-group td-image" v-if="showImage">
            <label class="col-sm-2 control-label" for="Pic">
              <span class="text-red">*</span> 圖示：
            </label>
            <div class="col-sm-10">
              <div class="pic">
                <img draggable="false" :src="edit.mainImgFullFileName" />
              </div>
              <input accept="image/*" id="Pic" type="file" @change="onFileChange($event)" />
              <div class="help-block">
                <span class="text-red" v-text="imgHelp">請勿小於該尺寸。</span>
              </div>
            </div>
          </div>



          <div class="form-group td-online" v-if="showOnline">
            <label class="col-sm-2 control-label">
              <span class="text-red">*</span> 發佈：
            </label>
            <div class="col-sm-10">
              <div class="radio radio-inline">
                <iCheckRedioButton
                  checkValue="0"
                  message="上架"
                  name="radioMode"
                  v-model="edit.isStop"
                />
              </div>
              <div class="radio radio-inline">
                <iCheckRedioButton
                  checkValue="1"
                  message="下架"
                  name="radioMode"
                  v-model="edit.isStop"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer">
          <div class="btns">
            <button class="btn btn-primary btn-lg" type="button" @click="Save">確認送出</button>
          </div>
        </div>
      </form>
    </div>

    <div class="top-box" v-if="!editMode">
      <div class="input-group">
        <input
          class="form-control pull-right"
          name="table_search"
          placeholder="搜尋關鍵字"
          type="text"
        />
        <div class="input-group-btn">
          <button class="btn btn-default" type="button">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <div class="text">
        <p class="">
          資料總筆數：
          <span id="total">{{ list.length }}</span>
        </p>
      </div>
      <div class="flex-sepperator"></div>
      <button  class="btn btn-warning btn-add" type="button" @click="editorItem()">
        <i class="fa fa-plus"></i> 新增資料
      </button>
    </div>
    
    <div class="table-responsive mt-10">
      <table class="table table-hover table-bordered" v-bind:class="{'edit-mode': editMode}">
        <thead>
          <tr class="bg-gray text-nowrap">
            <th class="td-index" v-if="showIndex">編號</th>
            <th class="td-category" v-if="showCategory">類別</th>
            <th class="td-title" v-if="showTitle">標題</th>
            <th class="td-image" v-if="showImage">圖示</th>
            <th class="td-description" v-if="showDescription">敘述</th>
            <th class="td-url" v-if="showLink">連結</th>
            <th class="td-file" v-if="showFile">檔案</th>
            <th class="td-date" v-if="showDate">上傳日期</th>
            <th class="td-online" v-if="showOnline">狀態</th>
            <th class="td-order">排序</th>
            <th class="td-action">功能</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="item.id">
            <td class="td-index text-center" v-if="showIndex">
              {{ index + 1 }}
            </td>
            <td class="td-category" v-if="showCategory">
              {{ item.catagory }}
            </td>
            <td class="td-title" v-if="showTitle">
              {{ item.title }}
            </td>
            <td class="td-image" v-if="showImage">
              <img :src="item.image" :alt="item.title">
            </td>
            <td class="td-description" v-if="showDescription">
              <span class="ellipsis">
                {{ item.description }}
              </span>
            </td>
            <td class="td-url" v-if="showLink">
              <a :href="item.url">
                <span class="ellipsis">
                {{ item.url }}
                </span>
              </a>
            </td>
            <td class="td-file" v-if="showFile">
              <a :href="item.file">
                <span class="ellipsis">
                {{ item.file }}
                </span>
              </a>
            </td>
            <td class="td-date text-center text-nowrap" v-if="showDate">
              {{ formatDate(item.date) }}
            </td>
            <td class="td-online text-center text-nowrap" v-if="showOnline">
              <span v-if="item.online">上架</span>
              <span v-else>下架</span>
            </td>
            <td class="td-order text-center text-nowrap">
              <input class="" type="number" value="0" />
              <button class="btn btn-warning btn-add btn-upDate" type="button">更新</button>
            </td>
            <td class="td-action text-center">
              <button class="btn btn-primary" @click="editorItem(index)">修改</button>
              <button class="btn btn-danger">刪除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="list.length > 10" class="box-footer text-center no-border">
      <ul class="pagination pagination-sm no-margin">
        <li><a href="javascript:void(0)"><i class="fa fa-angle-double-left"></i></a></li>
        <li><a href="javascript:void(0)"><i class="fa fa-angle-left"></i></a></li>
        <li class="active"><a href="javascript:void(0)">1</a></li>
        <li><a href="javascript:void(0)">2</a></li>
        <li><a href="javascript:void(0)"><i class="fa fa-angle-right"></i></a></li>
        <li><a href="javascript:void(0)"><i class="fa fa-angle-double-right"></i></a></li>
      </ul>
    </div>

  </div>
</template>

<style lang="scss">
  .c-UrlListTable{
    .box-body{
      max-width: 840px;
    }
    .input-search{
      display: table;
      border-collapse: collapse;
      > *{
        display: table-cell;
      }
    }
    td {
      
      .ellipsis{
        display: -webkit-box;
        max-height: 80px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
      img {
        max-height: 80px;
        display: block;
        margin: 0 auto;
      }
    }
    table{
      transition: filter .5s, opacity .5s;
      &.edit-mode{
        transition: filter .5s, opacity .5s;
        opacity: .1;
        pointer-events: none;
        filter: blur(2px);
      }

      .td-category{
        min-width: 75px;
      }
      .td-title{
        min-width: 120px;
      }
      .td-image{
        min-width: 75px;
        max-width: 300px;
      }
      .td-description{
        min-width: 125px;
      }
      .td-url, .td-file{
        min-width: 125px;
      }
      .td-order{
        > * + *{
          margin-left: 4px;
        }
        input {
          border: 1px solid #ccc;
          width: 54px;
          padding: 0 0 0 6px;
          line-height: 32px;
          border-radius: 3px;
          display: inline-block;
          vertical-align: bottom;
        }
      }
      .td-action{
        text-align: center;
        white-space: nowrap;
        > * + *{
          margin-left: 4px;
        }
      }
    }
  }
  
</style>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, /*mapActions*/ } from "vuex";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
    showList: "",
    fileHelp: "",
    imgHelp: ""
  },
  data() {
    return {
      isEdit: false,
      editLang: "zh-tw",
      langTypes: {},
      detailSalePointLang: [],
      editMode: false,
      editItem: 0, //TEMP
      edit: {
        salePointId: utils.getTimeId(), //銷售據點Id
        projectId: this.$store.state.projectId, // 專案Id
        classTypeId: "", //分類Id
        salePointUrl: "", // 網址
        salePointType: 0, //銷售據點類型
        salePointDetailId: "", // 內容Id
        detailTopTitle: "", //標題
        isStop: 0, // 狀態
        sortId: 0, // 排序
        mainImgFullFileName: "" // 圖片
      },
      list:[ // TEMP
        {
          id: "sgahf3",
          index: 0,
          catagory: "與樂同行連結",
          title: "進德國小與樂同行音樂方案計畫",
          description: "阿蘭奶奶的願望：經基金會及評審委員審核獲選後，基金會同仁致電社工通知喜訊，並與社工再度討論關於奶奶的願望，為其家計收支、現實情況做最縝密的預算及最符合需求之評估，訂定每月補助金額，並經本會通過。",
          url: "https://www.google.com",
          image: "https://picsum.photos/id/2/280/104",

          date: "2020-06-10T13:08:00+08:00",
          online: true,
        },
        {
          id: "af3swg",
          index: 1,
          catagory: "與樂同行連結",
          title: "進德國小與樂同行音樂方案計畫",
          description: "testing testing 123123123",
          url: "https://stackoverflow.com/questions/41995229/webkit-line-clamp-does-not-work-on-chrome-unless-i-make-some-unrelated-css-chan",
          image: "https://picsum.photos/id/18/104/280",
          date: "123425/123/123//3",

          online: false,
        },
        {
          id: "654ewr",
          index: 2,
          catagory: "與樂同行連結",
          title: "進德國小與樂同行音樂方案計畫",
          description: "",
          url: "https://www.google.com",
          image: "https://picsum.photos/id/35/280/104",

          online: true,
        },
        {
          id: "60891r",
          index: 3,
          catagory: "與樂同行連結",
          title: "進德國小與樂同行音樂方案計畫",
          description: "Hello World",
          url: "https://www.google.com",
          image: "https://picsum.photos/id/35/280/104",

          online: true,
        }
      ]
    };
  },
  created() {
    this.GetProjectInfo();
  },
  mounted() {},
  computed: {
    ...mapState({
      salePointTypes: state => state.classTypes.queryAll,
      salePoint: state => state.salePoint.query,
      detailQueryAll: state => state.salePoint.detailQueryAll,
      projectData: state => state.module.queryAll,
      response: state => state.salePoint.response,
      fileUploadResponse: state => state.file.response
    }),

    showIndex: function(){return this.showList ? /index/i.test(this.showList) : true},
    showCategory: function(){return this.showList ? /category/i.test(this.showList) : true},
    showTitle: function(){return this.showList ? /title/i.test(this.showList) : true},
    showDescription: function(){return this.showList ? /description/i.test(this.showList) : true},
    showImage: function(){return this.showList ? /image|img/i.test(this.showList) : true},
    showLink: function(){return this.showList ? /url|link|href/i.test(this.showList) : true},
    showFile: function(){return this.showList ? /file/i.test(this.showList) : true},
    showDate: function(){return this.showList ? /date/i.test(this.showList) : true},
    showOnline: function(){return this.showList ? /state|status|online/i.test(this.showList) : true},
  },
  watch: {
    salePoint() {
      this.edit = this.salePoint;
      this.GetSalePointLangData();
    },
    projectData() {
      this.langTypes = this.projectData.lang;
      this.editLang = this.langTypes[0].id;
      this.edit.lang = this.langTypes[0].id;

      this.GetSalePointClassTypes();
      // this.imgList = [];
      if (this.$route.params.id > 0) {
        this.isEdit = true;
        this.GetSalePointData();
      } else {
        this.detailSalePointLang = [];
        for (var i = 0; i < this.langTypes.length; i++) {
          const detailId = this.edit.salePointId + i + 1;
          this.detailSalePointLang.push({
            salePointDetailId: detailId,
            lang: this.langTypes[i].id,
            title: this.edit.detailTopTitle
          });
        }
      }
    },
    detailQueryAll() {
      let that = this;
      this.detailSalePointLang = [];
      if (this.detailQueryAll.total > 0) {
        for (var i = 0; i < this.langTypes.length; i++) {
          let findLang = false;
          const Id = this.edit.salePointId + i + 1;
          this.detailQueryAll.datas.some(details => {
            if (that.langTypes[i].id == details.lang) {
              if (that.langTypes[i].id == this.editLang) {
                that.edit.salePointDetailId = details.salePointDetailId;
                that.edit.detailTopTitle = details.title;
              }
              that.detailSalePointLang.push({
                salePointDetailId: details.salePointDetailId,
                lang: details.lang,
                title: details.title
              });
              findLang = true;
              return true;
            }
          });

          if (!findLang) {
            that.detailSalePointLang.push({
              salePointDetailId: Id,
              lang: that.langTypes[i].id,
              title: ""
            });
          }

        }
        // this.GetImgList();
      }
    },
    response(value) {
      if (value.status == "1") {
        this.$eventBus.$emit("showAlert", "更新成功!!");

        this.$router.push({
          name: "SalePointList",
          params: { searchData: this.$route.params.searchData }
        });
      } else this.$eventBus.$emit("showAlert", "更新失敗!!");
    },
    fileUploadResponse(value) {
      if (value.status != "1") {
        this.$eventBus.$emit("showAlert", "圖片上傳失敗!!");
      } else {
        if (this.isEdit) {
          this.$store.dispatch("salePoint/salePointUpdate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        } else {
          this.$store.dispatch("salePoint/salePointCreate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        }
      }
    }
  },
  methods: {
    editorItem(index){
      this.editMode = true;
      
      if(index){
        this.editItem = index; //TEMP
      }
      
    },
    formatDate(isoDateString){
      if (!isNaN(Date.parse(isoDateString))){
        return new Date(isoDateString).toLocaleDateString("zh-tw",{year:"numeric",month:"2-digit",day:"2-digit"});
      }
      // if (isoDateString) return isoDateString;
      else return null;
    },

    //取得該銷售據點資料
    GetSalePointData() {
      this.$store.dispatch("salePoint/salePointQuery", {
        salePointId: this.$route.params.id
      });
    },

    //取得該銷售據點多語系資料
    GetSalePointLangData() {
      this.$store.dispatch("salePoint/salePointDetailQueryAll", {
        salePointId: this.$route.params.id
      });
    },

    //取得分類資料
    GetSalePointClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "salepoint",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    GetProjectInfo() {
      this.$store.dispatch("module/moduleQueryAll", {});
    },

    // GetImgList() {
    //   this.SetLangData(this.editLang);
    //   this.$store.dispatch("news/newsDetailImgList", {
    //     newsDetailId: this.edit.newsDetailId
    //   });
    // },

    //取得該語系資料
    GetDetailSalePointLang(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (lang == this.langTypes[i].id) {
          this.edit.salePointDetailId = this.detailSalePointLang[
            i
          ].salePointDetailId;
          this.edit.detailTopTitle = this.detailSalePointLang[i].title;
        }
      }
    },

    //更換語系
    ChangeLang(lang) {
      // this.removeFile();
      this.SetLangData(this.editLang);
      this.GetDetailSalePointLang(lang);
      this.editLang = lang;
      // this.GetImgList();
    },

    //儲存該語系資料
    SetLangData(lang) {
      for (var i = 0; i < this.langTypes.length; i++) {
        if (this.langTypes[i].id == lang) {
          this.detailSalePointLang[i].title = this.edit.detailTopTitle;
        }
      }
    },

    //*---- 返回
    backList() {
      this.editMode = false;
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //   this.uploadFile(files[0], this.module, this.propFileId);

      this.preview(files);
    },
    removeFile() {
      document.getElementById("Pic").value = "";
    },
    uploadFile(files, folderName, id, multi = false, override = false) {
      let data = new FormData();
      data.append("files", files);
      data.append("projectId", this.edit.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", "zh-tw");
      data.append("multi", multi);
      data.append("isOverride", override);

      this.$store.dispatch("file/fileUpload", data);
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          that.edit.mainImgFullFileName = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },

    //送出
    async Save() {
      this.edit.isStop = parseInt(this.edit.isStop);
      this.edit.salePointType = parseInt(this.edit.salePointType);
      this.SetLangData(this.editLang);

      if (this.edit.classTypeId == "") {
        this.$eventBus.$emit("showAlert", "請選擇分類");
        return;
      }

      let folderName = "salepoint";
      let override = false;

      if (this.edit.detailTopTitle == "") {
        this.$eventBus.$emit("showAlert", "通路名稱不能為空");
        return;
      }

      if (this.edit.salePointUrl == "") {
        this.$eventBus.$emit("showAlert", "網址不能為空");
        return;
      }

      let AdPic = document.getElementById("Pic");

      if (AdPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(
          AdPic.files[0],
          folderName,
          this.edit.salePointId,
          false,
          override
        );
      } else if (this.edit.mainImgFullFileName == "") {
        this.$eventBus.$emit("showAlert", "請上傳圖片!!");
        return;
      } else {
        if (this.isEdit) {
          this.$store.dispatch("salePoint/salePointUpdate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        } else {
          this.$store.dispatch("salePoint/salePointCreate", {
            master_model: this.edit,
            detail_model: this.detailSalePointLang
          });
        }
      }
    }
  }
};
</script>