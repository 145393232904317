<template>
	<div id="gameList" class="slot">
		<section class="content-header">
			<h1>App列表</h1>
			<ol class="breadcrumb">
				<li>
					<router-link to="/">Home</router-link>
				</li>
				<li>遊戲管理</li>
				<li class="active">App列表</li>
			</ol>
		</section>
		<section class="content">
			<div class="box box-primary">
				<div class="box-body">
					<div class="top-box">
						<button @click="add()" class="btn btn-block btn-warning btn-add" type="button">
							<i class="fa fa-plus"></i> 新增
						</button>
						<div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
								v-model="searchData.開發商編號"
								@change="resetDataList()"
              >
                <option :value="0">全部</option>
								<option
									v-for="(company, index) in companyList"
									:value="Number(company.編號)"
								>
									{{ company.開發商名稱 }}
								</option>
              </select>
            </div>
						<div class="input-group">
							<input
								class="form-control pull-right"
								name="table_search"
								placeholder="搜尋關鍵字"
								type="text"
								v-model.trim="searchData.遊戲名稱"
							/>
              <i class="fa fa-close" v-if="searchData.遊戲名稱" @click="searchData.遊戲名稱 = ''"></i>
							<div class="input-group-btn">
								<button v-on:click="resetDataList()" class="btn btn-default" type="button">
									<i class="fa fa-search"></i>
								</button>
							</div>
						</div>
						<div class="text">
							<p class="data-num">
								<span>資料總筆數：</span>
								<span id="total">{{ total }}</span>
							</p>
						</div>
					</div>
					<div class="table-responsive mt-10">
						<table class="table table-bordered classTypeTable" id="classTypes">
							<tbody>
								<tr class="bg-gray">
									<th class="text-nowrap text-center" style="min-width: 90px;">圖示</th>
									<th style="min-width: 150px;" width="50%">
										<span @click="changeSort()" style="cursor: pointer;">
											App名稱
											<i class="fa fa-sort"></i>
										</span>
									</th>
									<th style="min-width: 150px;" width="50%">開發商名稱</th>
									<th style="min-width: 97px;">遊戲</th>
									<th style="min-width: 150px;" width="50%">建立時間</th>
									<th style="min-width: 150px;" width="50%">更新時間</th>
									<th class="text-nowrap text-center" style="min-width: 100px; width: 10%;">啟用</th>
									<th class="text-nowrap text-center" style="min-width: 150px; width: 20%;">功能</th>
								</tr>
								<tr>
									<td colspan="8"><Empty v-if="total == 0" /></td>
								</tr>
								<tr :key="index" v-for="(item, index) in list">
									<td class="text-nowrap text-center">
										<div class="photo">
											<img :src="item.圖示" alt="">
										</div>
									</td>
									<td>{{ item.遊戲名稱 }}</td>
									<td>{{ item.開發商名稱 }}</td>
									<td class="text-nowrap text-center">{{ item.遊戲館數 }}</td>
									<td class="text-nowrap text-center">{{ item.建立時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
									<td class="text-nowrap text-center">{{ item.更新時間 | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
									<td class="text-center" align="center">
										<div class="ckbx-style-8 ckbx toggleInput">
											<input
												:false-value="0"
												:id="'check'+index"
												:true-value="1"
												@change="changeStatus(item)"
												name="ckbx-style-8"
												type="checkbox"
												v-model="item.是否啟用"
												disabled
											/>
											<label :for="'check'+index" @click="changeStatus(item)"></label>
										</div>
									</td>
									<td class="text-center">
										<button @click="edit(item)" class="btn btn-primary">編輯</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="box-footer text-center no-border" v-if="total != 0">
					<select
            class="form-control page-size mr-10"
            v-model.number="searchData.pageSize"
            @change="resetDataList()"
          >
            <option value="20">每頁筆數20</option>
            <option value="30">每頁筆數30</option>
            <option value="50">每頁筆數50</option>
          </select>
					<Pagination
						ref="pagination"
						:total="total"
						:pageLimitCount="searchData.pageSize"
						v-on:pageMethod="onPageChange"
						:type2="true"
						/>
				</div>
			</div>
			<!--新增-->
			<div class="modal fade" ref="create">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title text-center">{{ this.isEdit ? '編輯' : '新增' }}App</h4>
						</div>
						<div class="modal-body">
							<form class="form-horizontal" name="announcementForm">
								<div class="box-body">
									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 圖示：
										</label>
										<div class="col-sm-10">
											<input
												accept="image/*"
												id="Pic"
												type="file"
												@change="onFileChange($event)"
												multiple
												v-show="addData.圖示 == ''"
											/>
											<img
												v-show="addData.圖示 != ''"
												:src="addData.圖示"
												style="height: 60px; cursor: pointer;"
												@click="changePic()" 
											/>
											<span class="text-red">上限為 5 MB 的 PNG、JPG 檔，尺寸 120 x 120。</span>
										</div>
									</div>
									
									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 名稱：
										</label>
										<div class="col-sm-10">
											<div class="input-group-hasClose">
												<input
													class="form-control"
													id="inputTitle"
													required="required"
													type="text"
													placeholder="遊戲名稱"
													v-model.trim="addData.遊戲名稱"
												/>
												<i class="fa fa-close" v-if="addData.遊戲名稱 != ''" @click="addData.遊戲名稱 = ''"></i>
											</div>
										</div>
									</div>

									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											<span class="text-red">*</span> 開發商：
										</label>
										<div class="col-sm-10">
											<select class="form-control" v-model="addData.開發商編號">
												<option disabled="disabled" :value="0">請選擇開發商</option>
												<option
													v-for="(company, index) in companyList"
													:value="Number(company.編號)"
												>
													{{ company.開發商名稱 }}
												</option>
											</select>
										</div>
									</div>

									<div class="form-group">
										<label class="col-sm-2 control-label text-nowrap">
											產品總覽：
										</label>
										<div class="col-sm-10">
											<div class="input-group-hasClose">
												<input
													class="form-control mb-10"
													id="inputTitle"
													required="required"
													type="text"
													placeholder="Android網址"
													v-model.trim="addData.android網址"
												/>
												<i class="fa fa-close" v-if="addData.android網址 != ''" @click="addData.android網址 = ''"></i>
											</div>
											<div class="input-group-hasClose">
												<input
													class="form-control mb-10"
													id="inputTitle"
													required="required"
													type="text"
													placeholder="ioS網址"
													v-model.trim="addData.ioS網址"
												/>
												<i class="fa fa-close" v-if="addData.ioS網址 != ''" @click="addData.ioS網址 = ''"></i>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div class="modal-footer text-center">
							<button class="btn btn-default" data-dismiss="modal" type="button" @click="cancel()">取消</button>
							<button
								class="btn btn-primary"
								type="button"
								@click="save()"
								:disabled="
									addData.圖示 == '' ||
									addData.遊戲名稱 == '' ||
									addData.開發商編號 == 0
								"
							>
								儲存
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
  
<script>
  /* eslint-disable */
	import api from "@/assets/js/app-api";
	import Pagination from "@/components/Pagination";
	import Empty from "@/components/SlotEmpty";

  import utils from "@/assets/js/app-utils";
  import { mapState, mapActions } from "vuex";
  
  export default {
		components: {
			Pagination,
			Empty
		},
    delimiters: ["${", "}"],
    data() {
      return {
				id: utils.getTimeId(),
				isEdit: false,
				searchData: {
					開發商編號: 0,
					遊戲名稱: "",//關鍵字比對
					// 是否啟用: 1,
					pageNum: 1,
					pageSize: 20,
					排序條件: 1,//1:更新時間,2:建立時間,3.遊戲名稱
					排序順序: 1,//1:DESC,2:ASC
				},
				companyList: [],
				list: [],
				total: 0,
				addData: {
					開發商編號: 0,
					遊戲名稱: "",
					圖示: "",
					android網址: "",
					ioS網址: "",
					是否啟用: 1,
				},
				editData: {
					url: '',
					name: '',
					company: '',
				},
				testList: [
					{
						url: 'https://d1icd6shlvmxi6.cloudfront.net/gsc/9T1WIA/7b/23/94/7b23949df54e41df95ca82f09741fb09/images/e2_%E9%81%8A%E6%88%B2%E5%88%97%E8%A1%A8/p0.png?pageId=505ab22e-15e6-4415-9b31-bc9269a13358',
						name: 'Cash Frenzy',
						company: 'Triple Sevens: Casino Games',
						count: 1,
						time: '2022-10-25 10:54:00',
						isStop: 0,
					},
					{
						url: 'https://d1icd6shlvmxi6.cloudfront.net/gsc/9T1WIA/7b/23/94/7b23949df54e41df95ca82f09741fb09/images/e2_%E9%81%8A%E6%88%B2%E5%88%97%E8%A1%A8/p2.png?pageId=505ab22e-15e6-4415-9b31-bc9269a13358',
						name: 'Slotomania',
						company: 'SpinX Games Limited',
						count: 2,
						time: '2022-10-25 10:54:00',
						isStop: 1,
					}
				]
      };
    },
    created() {
			this.getCompanyList();
			this.getDataList();
    },
    mounted() {},
    computed: {
			...mapState({
      	imgUrlList: state => state.game.imgList
    	}),
		},
    watch: {
			imgUrlList() {
				console.log(this.imgUrlList[0]);
				this.addData.圖示 = this.imgUrlList[0].imgFullFileName;
				this.$forceUpdate();
			}
		},
    methods: {
			getCompanyList() {
				api.slotGame.company.queryAll({
					pageNum: 1,
					pageSize: 90,
					排序條件: 2,//1:建立時間,2.開發商名稱
					排序順序: 2,//1:DESC,2:ASC
				})
					.then((res) => {
						this.companyList = res.data.result.datas;
					})
			},
			getDataList() {
				api.slotGame.game.queryAll(this.searchData)
					.then((res) => {
						this.list = res.data.result.datas;
						this.total = res.data.result.total;
						this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
					})
			},
			resetDataList() {
				this.searchData.pageNum = 1;
				this.getDataList();
			},
			changeSort() {
				if(this.searchData.排序條件 != 3) {
					this.searchData.排序條件 = 3;
					this.searchData.排序順序 = 2;
				} else {
					this.searchData.排序順序 = this.searchData.排序順序 == 1 ? 2 : 1;
				}
				this.resetDataList();
			},
			save(){
				let type = this.isEdit ? 'update' : 'create'
				let message = this.isEdit ? '更新' : '新增'

				api.slotGame.game[type](this.addData)
					.then((res) => {
						if(res.data.status == '1') {
							this.$eventBus.$emit("showAlert", `${message}成功!`);
							$(this.$refs.create).modal("hide");
							this.cancel();
							this.getDataList();
						} else if(res.data.message) {
							this.$eventBus.$emit("showAlert", `${res.data.message}`);
						} else {
							this.$eventBus.$emit("showAlert", `${message}失敗!`);
						}
					})
			},
			changeStatus(item) {
				this.$eventBus.$emit("showConfirm", 
						`確認要${item.是否啟用 == 1 ? '停用' : '啟用'}開發商？
						將${item.是否啟用 == 1 ? '隱藏' : '顯示'}已建立的相關資料
						包含遊戲、遊戲館、集成分析。`,
				isOK => {
					if (isOK) {
						api.slotGame.game.state({
							編號: item.編號,
							是否啟用: item.是否啟用 == 1 ? 0 : 1
						})
							.then((res) => {
								if(res.data.status == '1') {
									this.$eventBus.$emit("showAlert", "更新成功!");
									this.getDataList();
								} else {
									this.$eventBus.$emit("showAlert", "更新失敗!");
								}
							})
					}
				});
			},
			add() {
				$(this.$refs.create).modal("show");
			},
			edit(item) {
				this.isEdit = true;
				this.addData.編號= item.編號;
				this.addData.開發商編號= item.開發商編號;
				this.addData.遊戲名稱= item.遊戲名稱;
				this.addData.圖示= item.圖示;
				this.addData.android網址= item.android網址;
				this.addData.ioS網址= item.ioS網址;
				this.addData.是否啟用= item.是否啟用;
				$(this.$refs.create).modal("show");
			},
			cancel() {
				document.getElementById('Pic').value = '';
				this.addData.編號;
				this.addData.開發商編號= 0;
				this.addData.遊戲名稱= "";
				this.addData.圖示= "";
				this.addData.android網址= "";
				this.addData.ioS網址= "";
				this.addData.是否啟用= 1;
				this.isEdit = false;
			},
			onFileChange(evt) {

				var files = evt.target.files;
				if (files.length > 5) {
					this.$eventBus.$emit(
						"showAlert",
						"您選擇的檔案數量超過5張上限，請重新選擇。"
					);
					return;
				}
				if (files.length > 0) {
					for (var i = 0; i < files.length; i++) {
						var isCheckType = true; //是否檢查圖片副檔名
						var fileSize = files[i].size;
						var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
						var fileName = files[i].name;

						if (isCheckType && !re.test(fileName)) {
							this.$eventBus.$emit(
								"showAlert",
								"圖片格式不符，請選擇 png 或 jpg"
							);
							return;
						}

						if (fileSize > 5000000) {
							this.$eventBus.$emit(
								"showAlert",
								"圖片超出 5MB，請重新選擇"
							);
							return;
						}
					}
				}

				this.uploadFile(files, 'slotGame', this.id);
			},
			uploadFile(files, folderName, id, multi = false) {
				let data = new FormData();

				for (var i = 0; i < files.length; i++) {
					data.append("files", files[i]);
				}

				data.append("projectId", this.$store.state.projectId);
				data.append("module", folderName);
				data.append("moduleId", id);
				data.append("lang", "zh-tw");
				data.append("multi", multi);

				this.$store.dispatch("file/fileUpload", data).then(res => {
					if (res.status != "0") this.GetImgList();
				});
			},
			GetImgList() {
				this.$store.dispatch("game/gameImgList", {
					contentId: this.id
				});
			},
			changePic() {
				$('#Pic').click();
			},
			async onPageChange(pageIdx, pageSize) {
				this.searchData.pageNum = pageIdx;
				this.searchData.pageSize = pageSize;
				this.getDataList();
			},
		}
  };
</script>
<style>
	.modal-content {
		border-radius: 10px;
	}
</style>