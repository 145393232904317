/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
const state = {
  queryAll: [],
  menu: {},
  countyQueryAll: {},
  response: "",
}

// getters
const getters = {
}

// actions
const actions = {
  queryAll({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.person.queryAll(data).then(res => {
        if (res.data.isSuccess) {
            const result = res.data.data;
            commit('setPersonQueryAll', result);
            resolve(result);
        }
      });
    });
  },
  add({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.person.add(data).then(res => {
        resolve(res);
      });
    });
  },
  export({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.person.export(data).then(res => {
        resolve(res);
      });
    });
  },
  setTickets({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.person.setTickets(data).then(res => {
        if (res.data.isSuccess) {
            const result = res;
            resolve(result);
        }
      });
    });
  },
  getTickets({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.person.getTickets(data).then(res => {
        if (res.data.isSuccess) {
            const result = res.data;
            resolve(result);
        }
      });
    });
  },
  menu({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.newtalk.election.menu(data).then(res => {
        if (res.data.isSuccess) {
            const result = res.data.data.總統大選.立委;
            commit('setMenuQuery', result);
            resolve(result);
        }
      });
    });
  },
}

// mutations
const mutations = {
  setPersonQueryAll(state, groupQueryAll) {
      state.queryAll = groupQueryAll;
  },
  setMenuQuery(state, menu) {
    state.menu = menu;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}