<template>
  <div id="QppAnnouncementList">
    <section class="content-header">
      <h1>公告列表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公告管理</li>
        <li class="active">公告列表</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增公告
            </button>
            <div class="select-group">
              <select
                @change="changeNewsType"
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="newsType"
              >
                <option value="官方">官方</option>
                <option value="個人">個人</option>
                <option value="回覆">回覆</option>
                <option value="留言">留言</option>
              </select>
            </div>
            <div class="select-group" v-if="newsType == '官方'">
              <select
                @change="clickSearch"
                class="form-control"
                required="required"
                v-model="searchData.categoryNo"
              >
                <option disabled value="0">請選擇語系</option>
                <option
                  :key="index"
                  :value="item.categoryNo"
                  v-for="(item, index) in categoryquery"
                >{{ item.language }}</option>
              </select>
            </div>
            <div class="select-group">
              <input
                class="form-control"
                name="table_search"
                placeholder="門號搜尋"
                type="text"
                v-model="searchData.targetMobile"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.articleTitle"
              />
              <div class="input-group-btn">
                <button class="btn btn-default" type="button" v-on:click="clickSearch()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ typeTotalCount }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered AdListTable"
              v-if="newsType == '官方' || newsType == '個人'"
            >
              <tbody>
                <tr class="bg-gray">
                  <th>ID</th>
                  <th v-if="newsType == '官方'">語系</th>
                  <th v-if="newsType == '官方'">置頂</th>
                  <th style="min-width: 150px;" v-if="newsType == '官方'">置頂排序</th>
                  <th>熱門</th>
                  <th style="min-width: 150px;">熱門排序</th>
                  <th style="min-width: 200px;">標題</th>
                  <th style="min-width: 150px;">發佈日期</th>
                  <th>發佈者</th>
                  <th style="min-width: 150px;">功能</th>
                </tr>
                <template v-if="news.totalCount > 0">
                  <tr :key="item.id" v-for="item in news.articleList">
                    <td class="text-nowrap">{{ item.articleId }}</td>
                    <td
                      class="text-nowrap"
                      v-if="newsType == '官方'"
                    >{{ getCategory(item.categoryNo) }}</td>
                    <td class="text-nowrap text-center" v-if="newsType == '官方'">
                      {{ item.officialHotTop > 0 ? "是" : "否" }}
                      <button
                        @click="deleteOfficialTop(item)"
                        class="btn btn-danger btn-add btn-upDate"
                        type="button"
                        v-if="item.officialHotTop > 0"
                      >取消</button>
                    </td>
                    <td class="text-nowrap" v-if="newsType == '官方'">
                      <div class="input-group">
                        <input
                          class="form-control"
                          max="5"
                          min="1"
                          type="number"
                          v-model="item.officialHotTop"
                        />
                        <button
                          @click="setOfficialTop(item)"
                          class="btn btn-warning btn-add btn-upDate"
                          type="button"
                        >更新</button>
                      </div>
                    </td>
                    <td class="text-nowrap text-center">
                      {{ item.hotTop > 0 ? "是" : "否" }}
                      <button
                        @click="deleteHopTop(item)"
                        class="btn btn-danger btn-add btn-upDate"
                        type="button"
                        v-if="item.hotTop > 0"
                      >取消</button>
                    </td>
                    <td class="text-nowrap">
                      <div class="input-group">
                        <input
                          class="form-control"
                          max="5"
                          min="1"
                          type="number"
                          v-model="item.hotTop"
                        />
                        <button
                          @click="setHotTop(item)"
                          class="btn btn-warning btn-add btn-upDate"
                          type="button"
                        >更新</button>
                      </div>
                    </td>
                    <td>{{ item.articleTitle }}</td>
                    <td>{{ FormatTime(item.publishTime) }}</td>
                    <td class="text-nowrap">{{ item.authorHide }}</td>
                    <td class="text-center">
                      <button v-if="newsType == '官方'" @click="editorItem(item)" class="btn btn-primary">詳細</button>
                      <button v-else @click="showContent(item.articleId)" class="btn btn-primary">詳細</button>
                      &nbsp;
                      <button
                        @click="deleteNews(item)"
                        class="btn btn-danger"
                      >刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="table table-hover table-bordered" v-if="newsType == '回覆'">
              <tbody>
                <tr class="bg-gray">
                  <th>ID</th>
                  <th style="min-width: 200px;">內容</th>
                  <th style="min-width: 150px;">發佈日期</th>
                  <th>發佈者</th>
                  <th style="min-width: 150px;">功能</th>
                </tr>
                <template v-if="comment.totalCount > 0">
                  <tr :key="item.commentId" v-for="item in comment.commentDetailList">
                    <td class="text-nowrap">{{ item.commentId }}</td>
                    <td>
                      <span class="text-red" v-if="item.commentContent == ''">＊此篇文章內容已刪除</span>
                      <span v-else>{{ onlyText(item.commentContent) }}</span>
                    </td>
                    <td>{{ FormatTime(item.commentTime) }}</td>
                    <td class="text-nowrap">{{ item.commentAuthor }}</td>
                    <td class="text-center">
                      <span v-if="item.commentContent != ''">
                        <button @click="showContent(item.commentId)" class="btn btn-primary">詳細</button>
                        &nbsp;
                        <button
                          @click="deleteComment(item)"
                          class="btn btn-danger"
                        >刪除</button>
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <table class="table table-hover table-bordered" v-if="newsType == '留言'">
              <tbody>
                <tr class="bg-gray">
                  <th>ID</th>
                  <th style="min-width: 200px;">內容</th>
                  <th style="min-width: 150px;">發佈日期</th>
                  <th>發佈者</th>
                  <th style="min-width: 150px;">功能</th>
                </tr>
                <template v-if="reply.totalCount > 0">
                  <tr :key="item.replyId" v-for="item in reply.replyDetailList">
                    <td class="text-nowrap">{{ item.replyId }}</td>
                    <td>
                      <span class="text-red" v-if="item.replyContent == ''">＊此篇文章內容已刪除</span>
                      <span v-else>{{ onlyText(item.replyContent) }}</span>
                    </td>
                    <td>{{ FormatTime(item.replyTime) }}</td>
                    <td class="text-nowrap">{{ item.author }}</td>
                    <td class="text-center">
                      <span v-if="item.replyContent != ''">
                        <button @click="showContent('R' + item.replyId)" class="btn btn-primary">詳細</button>
                        &nbsp;
                        <button
                          @click="deleteReply(item)"
                          class="btn btn-danger"
                        >刪除</button>
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="news.totalCount > 0 && (newsType == '官方' || newsType == '個人')"
        >
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="news.totalCount"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="comment.totalCount > 0 && newsType == '回覆'"
        >
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="comment.totalCount"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="reply.totalCount > 0 && newsType == '留言'"
        >
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="reply.totalCount"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <forumContent :propsCategoryNo="contentCategoryNo" :propsID="itemID" ref="forumContent" />
  </div>
</template>

<script>
/* eslint-disable */
//import CKEditor from "@/components/CKEditor";
import api from '@/assets/js/app-api';
import Pagination from '@/components/Pagination';
import utils from '@/assets/js/app-utils';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import forumContent from '@/views/projects/qpp/ForumContent';

export default {
  components: {
    Pagination,
    forumContent,
  },
  delimiters: ['${', '}'],
  name: 'app',
  props: {
    id: '',
  },
  data() {
    return {
      projectId: this.$store.state.projectId,
      newsType: '官方',
      typeTotalCount: 0,
      searchData: {
        categoryNo: 1,
        targetMobile: '',
        articleTitle: '',
        page: 1,
        pageSize: 20,
      },
      itemID: '',
      contentCategoryNo: '',
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
      if (this.$route.params.searchData.categoryNo == '0')
        this.newsType = '個人';
    }

    await this.getCategoryquery();
    this.switchTypeGetAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      categoryquery: (state) => state.qppArticle.categoryquery,
      news: (state) => state.qppArticle.queryList,
      newsResponse: (state) => state.qppArticle.response,
      reply: (state) => state.qppReport.reply, //留言
      comment: (state) => state.qppReport.comment, //回覆
      replyCommentRes: (state) => state.qppReport.response,
    }),
  },
  watch: {
    newsResponse(value) {
      if (value.status == '1') {
        this.$eventBus.$emit('showAlert', '更新成功!');
      } else {
        this.$eventBus.$emit('showAlert', '更新失敗!');
      }
      this.switchTypeGetAll();
    },
    replyCommentRes(value) {
      if (value == 'ok') {
        this.$eventBus.$emit('showAlert', '刪除成功!');
      } else {
        this.$eventBus.$emit('showAlert', '刪除失敗!');
      }
      this.switchTypeGetAll();
    },
    news() {
      this.typeTotalCount = this.news.totalCount;
      this.$refs.pagination.setPage(this.searchData.page, this.news.totalCount);
    },
    reply() {
      this.typeTotalCount = this.reply.totalCount;
      this.$refs.pagination.setPage(
        this.searchData.page,
        this.reply.totalCount
      );
    },
    comment() {
      this.typeTotalCount = this.comment.totalCount;
      this.$refs.pagination.setPage(
        this.searchData.page,
        this.comment.totalCount
      );
    },
  },
  methods: {
    //*================ 共用 ================*//
    switchTypeGetAll() {
      if (this.newsType == '回覆') {
        this.getCommentAll();
      } else if (this.newsType == '留言') {
        this.getReplyAll();
      } else {
        this.getNewsQueryAll();
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.switchTypeGetAll();
    },
    //搜尋
    clickSearch() {
      this.searchData.page = 1;
      this.switchTypeGetAll();
    },
    //切換類別
    changeNewsType() {
      this.searchData.page = 1;
      if (this.newsType == '官方') this.searchData.categoryNo = '1';
      this.switchTypeGetAll();
    },
    FormatTime(time) {
      if (time == undefined) return '';
      return new moment(time).format('YYYY/MM/DD HH:mm:ss');
    },
    onlyText(item) {
      item = item.replace(/(\n)/g, '');
      item = item.replace(/(\t)/g, '');
      item = item.replace(/(\r)/g, '');
      item = item.replace(/<\/?[^>]*>/g, '');
      item = item.replace(/\s*/g, '');

      if (item.length > 20) {
        return item.substring(0, 20) + '...';
      } else {
        return item;
      }
    },

    //*================ 回覆 ================*//
    getCommentAll() {
      this.searchData.authorUid = this.searchData.targetMobile;
      this.searchData.contentKey = this.searchData.articleTitle;
      this.$store.dispatch('qppReport/comment', this.searchData);
    },
    showContent(id) {
      if (id.indexOf('P') === 0) {
        this.contentCategoryNo = 0; //個人
      } else if (id.indexOf('C') === 0) {
        this.contentCategoryNo = 'C'; //回覆
      } else if (id.indexOf('R') === 0) {
        this.contentCategoryNo = 'R'; //留言
      } else {
        this.contentCategoryNo = 1; //官方
      }
      this.itemID = id;

      if (this.itemID === id) {
        this.$refs.forumContent.showForumContent();
      }
    },
    deleteComment(item) {
      let comment = {
        commentId: item.commentId,
      };
      this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('qppReport/deleteComment', comment);
        }
      });
    },

    //*================ 留言 ================*//
    getReplyAll() {
      this.searchData.authorUid = this.searchData.targetMobile;
      this.searchData.contentKey = this.searchData.articleTitle;
      this.$store.dispatch('qppReport/reply', this.searchData);
    },
    deleteReply(item) {
      let reply = {
        replyId: item.replyId,
      };
      this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('qppReport/deleteReply', reply);
        }
      });
    },

    //*================ 官方 & 個人 ================*//
    getNewsQueryAll() {
      //列表
      if (this.newsType == '個人') {
        this.searchData.categoryNo = '0';
      } else if (this.newsType == '官方' && this.searchData.categoryNo == '0') {
        this.$eventBus.$emit('showAlert', '請選擇語系');
        return;
      }
      this.$store.dispatch('qppArticle/QueryList', this.searchData);
    },
    deleteNews(news) {
      var query = {
        articleId: news.articleId,
        uid: this.newsType == '個人' ? '0' : '',
      };
      this.$eventBus.$emit('showConfirm', '確定要刪除嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('qppArticle/DeleteQuery', query);
        }
      });
    },
    //語系
    async getCategoryquery() {
      this.$store.dispatch('qppArticle/categoryquery', {});
    },
    getCategory(categoryNo) {
      if (categoryNo == '0') return '';
      var category = this.categoryquery.filter(
        (cat) => cat.categoryNo == categoryNo
      );
      return category[0].language;
    },
    //新增內容
    addNew() {
      this.$router.push({
        name: 'QppAnniuncementEdit',
        params: {
          searchData: this.searchData,
        },
      });
      return;
    },
    //修改內容
    async editorItem(item) {
      this.$router.push({
        name: 'QppAnniuncementEdit',
        params: {
          id: item.articleId,
          lang: item.categoryNo,
          searchData: this.searchData,
        },
      });
      return;
    },
    setHotTop(item) {
      if (item.hotTop == 0) {
        this.$eventBus.$emit('showAlert', '排序不可為0!');
        return;
      } else if (item.hotTop > 5) {
        this.$eventBus.$emit('showAlert', '排序不可超過5!');
        this.getNewsQueryAll();
        return;
      }
      //置頂
      var query = {
        targetId: item.articleId,
        topType: '3',
        topPosition: item.hotTop,
        author:
          this.newsType == '官方'
            ? this.getCategory(item.categoryNo)
            : item.authorMobilKey,
      };
      this.$eventBus.$emit(
        'showConfirm',
        '確定要更新排序嗎?(其他同數字熱門會被取代)',
        (isOK) => {
          if (isOK) {
            this.$store.dispatch('qppArticle/SetTop', query);
          }
        }
      );
    },
    setOfficialTop(item) {
      if (item.officialHotTop == 0) {
        this.$eventBus.$emit('showAlert', '排序不可為0!');
        return;
      } else if (item.officialHotTop > 5) {
        this.$eventBus.$emit('showAlert', '排序不可超過5!');
        this.getNewsQueryAll();
        return;
      }
      //置頂
      var query = {
        targetId: item.articleId,
        topType: '1',
        topPosition: item.officialHotTop,
        author: this.getCategory(item.categoryNo),
      };
      this.$store.dispatch('qppArticle/SetTop', query);
    },
    deleteHopTop(item) {
      var query = {
        targetId: item.articleId,
        topType: '3',
        author:
          this.newsType == '官方'
            ? this.getCategory(item.categoryNo)
            : item.authorMobilKey,
      };
      this.$eventBus.$emit('showConfirm', '確定要取消置頂嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('qppArticle/DeleteTop', query);
        }
      });
    },
    deleteOfficialTop(item) {
      var query = {
        targetId: item.articleId,
        topType: '1',
        author: this.getCategory(item.categoryNo),
      };
      this.$eventBus.$emit('showConfirm', '確定要取消置頂嗎?', (isOK) => {
        if (isOK) {
          this.$store.dispatch('qppArticle/DeleteTop', query);
        }
      });
    },
  },
};
</script>
