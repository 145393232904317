<template>
  <div id="gameInfo" class="slot max1040">
    <section class="content-header">
      <h1>{{ isEdit ? "編輯" : "新增" }}遊戲</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>遊戲管理</li>
        <li>遊戲列表</li>
        <li class="active">{{ isEdit ? "編輯" : "新增" }}遊戲</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">基本資料</h4>
          </div>

          <div class="form-horizontal" name="gameForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label text-nowrap">
                  <span class="text-red">*</span> 圖示：
                </label>
                <div class="col-sm-10">
                  <input
										accept="image/*"
										id="Pic"
										type="file"
										@change="onFileChange($event)"
                    v-show="addData.圖示 == ''"
                  />
                  <img
                    :src="addData.圖示" 
                    v-show="addData.圖示 != ''"
                    style="height: 120px; cursor: pointer;"
                    @click="changePic()"
                  />
									<p style="color: #b3b3b3;">上限為 5 MB 的 PNG、JPG 檔，尺寸 120 x 120。</p>
                </div>
              </div>

              <div class="form-group" style="width: 100%;">
                <label class="col-sm-2 control-label" for="inputTitle">
                  連結：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    placeholder="Youtube網址"
                    v-model.trim="addData.影片連結"
                  />
                </div>
              </div>
              <br />

              <div class="form-group">
                <label class="col-sm-4 control-label text-nowrap">
                  <span class="text-red">*</span> App：
                </label>
                <div class="col-sm-8">
                  <select
                    class="form-control"
                    v-model="addData.遊戲編號"
                  >
                    <option :value="0">請選擇遊戲</option>
                    <option
                      v-for="(game, index) in gameList"
                      :value="game.編號"
                    >
                      {{ game.遊戲名稱 }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-4 control-label" for="inputTitle">
                  <span class="text-red">*</span> 遊戲：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model.trim="addData.遊戲館名稱"
                  />
                </div>
              </div>

							<div class="form-group">
                <label class="col-sm-4 control-label" for="editor">
                  <span class="text-red">*</span> 標籤：
                </label>
                <div class="col-sm-8">
                  <multiselect
                    placeholder="搜尋標籤"
                    label="標籤名稱"
                    track-by="標籤名稱"
                    required
                    :options="tagList"
                    :hide-selected="true"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="addData.標籤集合"
                    max-elements="已達標籤上限"
                    :max="6"
                  >
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.標籤名稱 }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                  <p style="color: #b3b3b3;">最少選用 1 個標籤，最多 6 個。</p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-4 control-label" for="editor">
                  <span class="text-red">*</span> 玩法：
                </label>
                <div class="col-sm-8">
                  <multiselect
                    placeholder="搜尋玩法"
                    label="玩法名稱"
                    track-by="玩法名稱"
                    required
                    :options="playList"
                    :hide-selected="true"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    v-model="addData.玩法集合"
                    max-elements="已達玩法上限"
                  >
                    <span class="notice" slot="noOptions">列表為空</span>
                    <span class="notice" slot="noResult">查無結果</span>
                    <span class="notice maxElements" slot="maxElements">
                      已達標籤上限
                    </span>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.玩法名稱 }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                  <p style="color: #b3b3b3;">最少選用 1 個玩法。</p>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
								<button
									class="btn btn-primary pull-left"
                  @click="save()"
                  :disabled="
                    addData.圖示 == '' ||
                    addData.遊戲編號 == 0 ||
                    addData.遊戲館名稱 == '' ||
                    addData.玩法集合.length == 0 ||
                    addData.標籤集合.length == 0
                  "
								>
									儲存
								</button>
								<button
									class="btn btn-default pull-right"
                  @click="backList()"
								>
									返回
								</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    multiselect,
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      isEdit: false,
      gameHallId: Number(this.$route.params.id) || "",
      id: utils.getTimeId(),
      addData: {
        遊戲編號: 0,
        遊戲館名稱: "",
        圖示: "",
        影片連結: "",
        是否啟用: 1,
        標籤集合: [],
        玩法集合: [],
      },
      gameList: [],
      tagList: [],
      playList: [],
		};
  },
  created() {
    if(this.gameHallId != "") {
      this.isEdit = true;
      this.getData();
    }
    this.getGameList();
    this.getTagList();
    this.getPlayList();
  },
  mounted() {},
  computed: {
    ...mapState({
      imgUrlList: state => state.game.imgList
    }),
  },
  watch: {
    imgUrlList() {
      this.addData.圖示 = this.imgUrlList[0].imgFullFileName;
      this.$forceUpdate();
    }
  },
  methods: {
    getData() {
      api.slotGame.gameHall.query({ 編號: this.gameHallId })
      .then((res) => {
          this.addData = res.data.result
        })
    },
    getGameList() {
      api.slotGame.game.queryAll({
        開發商編號: 0,
        遊戲名稱: "",//關鍵字比對
        PageNum: 1,
        PageSize: 90,
        排序條件: 3,//1:更新時間,2:建立時間,3.遊戲名稱
        排序順序: 2,//1:DESC,2:ASC
      })
        .then((res) => {
          this.gameList = res.data.result.datas;
        })
    },
    getTagList() {
      api.slotGame.tag.queryAll({
        排序條件: 2,//1:建立時間,2.標籤名稱,3.被標記數
        排序順序: 2,//1:DESC,2:ASC
      })
        .then((res) => {
            this.tagList = res.data.result.datas;
          })
    },
    getPlayList() {
      api.slotGame.play.queryAll({
        排序條件: 2,//1:建立時間,2.玩法名稱
        排序順序: 2,//1:DESC,2:ASC
      })
        .then((res) => {
            this.playList = res.data.result.datas;
          })
    },
    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您選擇的檔案數量超過5張上限，請重新選擇。"
        );
        return;
      }
      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          var isCheckType = true; //是否檢查圖片副檔名
          var fileSize = files[i].size;
          var re = /\.(jpg|png|jpeg|JPG|PNG|JPEG)$/i; //允許的副檔名
          var fileName = files[i].name;

          if (isCheckType && !re.test(fileName)) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片格式不符，請選擇 png 或 jpg。"
            );
            return;
          }

          if (fileSize > 5000000) {
            this.$eventBus.$emit(
              "showAlert",
              "圖片超出 5MB，請重新選擇。"
            );
            return;
          }
        }
      }

      this.uploadFile(files, 'slotGame', this.id);
      },
      uploadFile(files, folderName, id, multi = false) {
      let data = new FormData();

      for (var i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      data.append("projectId", this.$store.state.projectId);
      data.append("module", folderName);
      data.append("moduleId", id);
      data.append("lang", "zh-tw");
      data.append("multi", multi);

      this.$store.dispatch("file/fileUpload", data).then(res => {
        if (res.status != "0") this.GetImgList();
      });
    },
    GetImgList() {
      this.$store.dispatch("game/gameImgList", {
        contentId: this.id
      });
    },
    changePic() {
      $('#Pic').click();
    },
    save() {
      console.log(this.addData);
      this.addData.標籤集合 = 
        this.addData.標籤集合.map(e => {
          return {編號: e.編號, 標籤名稱: e.標籤名稱}
        })

      this.addData.玩法集合 = 
        this.addData.玩法集合.map(e => {
          return {編號: e.編號, 玩法名稱: e.玩法名稱}
        })

      if(this.isEdit) {
        api.slotGame.gameHall.update(this.addData)
          .then((res) => {
              if(res.data.status == "1") {
                this.$eventBus.$emit("showAlert", "更新成功");
                this.backList()
              } else {
                this.$eventBus.$emit("showAlert", "更新失敗");
              }
            })
      } else {
        api.slotGame.gameHall.create(this.addData)
          .then((res) => {
              if(res.data.status == "1") {
                this.$eventBus.$emit("showAlert", "新增成功");
                this.backList()
              } else {
                this.$eventBus.$emit("showAlert", res.data.message || "新增失敗");
              }
            })
      }

    },
    nameWithLang ({ 標籤名稱 }) {
      console.log(標籤名稱);
      return `${標籤名稱}`
    },
    backList() {
			this.$router.push({
				name: 'slotGamehallList',
				params: {
					searchData: this.$route.params.backSearchData,
				},
			});
		}
  },
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>