import { Mul, 檢查輸入數值 } from "@/views/projects/ips/Arithmetic";
function 檢查上限輸入數值(val) {
    if (parseInt(val) === -1) {
        return true;
    } else {
        return 檢查輸入數值(val, 0, 4);
    }
}
function 檢查限額不可大於(限額物件1, 限額物件2, decimal = 4) {
    if (!限額物件1) {
        return true;
    }
    if (!限額物件2) {
        return true;
    }
    let val = 限額物件1.參數;
    let max = 限額物件2.參數;
    let pow = Math.pow(10, decimal);
    val = Mul(val, pow)//parseFloat(val);
    max = Mul(max, pow);

    let notlimit = -1 * pow;//-1為無限制

    if (max != notlimit) {
        if (val > max) {
            return false;
        }
    }

    if (val == notlimit && max != notlimit) {
        return false;
    }
    return true;
}
function ErrorList() {
    let error = [];
    this.add = function (log, canSend = false) {
        error.push({ log, canSend });
    };
    this.getLogs = function () {
        let logs = {
            warning: [],
            error: []
        };
        for (let i of error) {
            if (i.canSend) {
                logs.warning.push(i.log)
            } else {
                logs.error.push(i.log);
            }
        }
        return logs;
    };
    //取得是否能送資料，只要有一筆是false，就不能送出
    this.getCanSend = function () {
        for (let i of error) {
            if (i.canSend == false) {
                return false;
            }
        }
        return true;
    }
    this.test = {
        s1: null,
        s2: null,
        s3: null
    },
        this.addTest = function (額外限制List, 上層限制集合) {
            let self = 額外限制List.find((item) => { return item.限制主條件 == 1 });
            let up1 = 上層限制集合[0] && 上層限制集合[0].find((item) => { return item.限制主條件 == 1 });
            let up2 = 上層限制集合[1] && 上層限制集合[1].find((item) => { return item.限制主條件 == 1 });
            function 找參數(限制細項, 編號) {
                let obj = 限制細項.find((item) => {
                    return item.編號 == 編號;
                });
                if (obj && obj.是否已設) {
                    return obj.參數;
                } else {
                    return -1;
                }
            }
            function 組合(self) {
                if (self) {

                    return {
                        限制主項目: self.限制主項目,
                        每月上限: 找參數(self.限制細項, 1),
                        每周上限: 找參數(self.限制細項, 2),
                        每日上限: 找參數(self.限制細項, 3),
                        單筆上限: 找參數(self.限制細項, 4),
                        單筆下限: 找參數(self.限制細項, 5),
                    }
                } else {
                    return {
                        限制主項目: "無資料",
                        每月上限: - 1,
                        每周上限: - 1,
                        每日上限: - 1,
                        單筆上限: - 1,
                        單筆下限: - 1,
                    }
                }
            }
            let selfObj = 組合(self);
            let up1Obj = 組合(up1);
            let up2Obj = 組合(up2);
            this.test.s1 = selfObj;
            this.test.s2 = up1Obj;
            this.test.s3 = up2Obj;
        },
        this.getTest = function () {
            return this.test;
        }
}
// 核心物件，利用建構式置入策略 ========================================================================   
function 額外限制策略(strategy) {
    this.strategy = strategy;
}

// 核心方法-檢查    
額外限制策略.prototype.檢查 = function (errorList, 自己, 額外限制List, 上層限制集合) {
    return this.strategy.檢查(errorList, 自己, 額外限制List, 上層限制集合);
}

// 訂定策略    
// let 無策略 = {
//     檢查: function (自己, 額外限制List) {
//         return [];
//     }
// }
let 限制細項檢查策略 = {
    檢查: function (errorList, 限制項目, 額外限制List) {
        let 每月上限 = 限制項目.限制細項.find((item) => { return item.編號 == 1 && item.是否已設 });
        let 每周上限 = 限制項目.限制細項.find((item) => { return item.編號 == 2 && item.是否已設 });
        let 每日上限 = 限制項目.限制細項.find((item) => { return item.編號 == 3 && item.是否已設 });
        let 單筆上限 = 限制項目.限制細項.find((item) => { return item.編號 == 4 && item.是否已設 });
        let 單筆下限 = 限制項目.限制細項.find((item) => { return item.編號 == 5 && item.是否已設 });
        let num = 0;
        num = 每月上限 ? num + 1 : num;
        num = 每周上限 ? num + 1 : num;
        num = 每日上限 ? num + 1 : num;
        num = 單筆上限 ? num + 1 : num;
        num = 單筆下限 ? num + 1 : num;

        if (num == 0) {
            errorList.add(`[${限制項目.限制主項目}]請至少設定一筆限制`);
        }
        if (每月上限 && !檢查上限輸入數值(每月上限.參數)) {
            errorList.add(`[${限制項目.限制主項目}]每月上限數值輸入錯誤`);
            每月上限 = null;//ps.這裡故意改null，是為了避免後面會用錯誤的數值檢查上下限額
        }
        if (每周上限 && !檢查上限輸入數值(每周上限.參數)) {
            errorList.add(`[${限制項目.限制主項目}]每周上限數值輸入錯誤`);
            每周上限 = null;
        }
        if (每日上限 && !檢查上限輸入數值(每日上限.參數)) {
            errorList.add(`[${限制項目.限制主項目}]每日上限數值輸入錯誤`);
            每日上限 = null;
        }
        if (單筆上限 && !檢查上限輸入數值(單筆上限.參數)) {
            errorList.add(`[${限制項目.限制主項目}]單筆上限數值輸入錯誤`);
            單筆上限 = null;
        }
        if (單筆下限 && !檢查上限輸入數值(單筆下限.參數)) {
            errorList.add(`[${限制項目.限制主項目}]單筆下限數值輸入錯誤`);
            單筆下限 = null;
        }
        if (!檢查限額不可大於(每周上限, 每月上限)) {
            errorList.add(`[${限制項目.限制主項目}]每周上限超出每月上限`);
        }
        if (!檢查限額不可大於(每日上限, 每月上限)) {
            errorList.add(`[${限制項目.限制主項目}]每日上限超出每月上限`);
        }
        if (!檢查限額不可大於(每日上限, 每周上限)) {
            errorList.add(`[${限制項目.限制主項目}]每日上限超出每周上限`);
        }
        if (!檢查限額不可大於(單筆上限, 每月上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆上限超出每月上限`);
        }
        if (!檢查限額不可大於(單筆上限, 每周上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆上限超出每周上限`);
        }
        if (!檢查限額不可大於(單筆上限, 每日上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆上限超出每日上限`);
        }
        if (!檢查限額不可大於(單筆下限, 每月上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆下限超出每月上限`);
        }
        if (!檢查限額不可大於(單筆下限, 每周上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆下限超出每周上限`);
        }
        if (!檢查限額不可大於(單筆下限, 每日上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆下限超出每日上限`);
        }
        if (!檢查限額不可大於(單筆下限, 單筆上限)) {
            errorList.add(`[${限制項目.限制主項目}]單筆下限超出單筆上限`);
        }
    }
}
let 預設值檢查策略 = {
    檢查: function (errorList, 限制項目, 額外限制List) {
        //
    }
};
let 預設值上層限制檢查策略 = {
    檢查: function (errorList, 限制項目, 額外限制List, 上層限制集合) {
        let 每月上限 = 限制項目.限制細項.find((item) => { return item.編號 == 1 && item.是否已設 });
        let 每周上限 = 限制項目.限制細項.find((item) => { return item.編號 == 2 && item.是否已設 });
        let 每日上限 = 限制項目.限制細項.find((item) => { return item.編號 == 3 && item.是否已設 });
        let 單筆上限 = 限制項目.限制細項.find((item) => { return item.編號 == 4 && item.是否已設 });
        let 單筆下限 = 限制項目.限制細項.find((item) => { return item.編號 == 5 && item.是否已設 });

        for (let 上層限制列表 of 上層限制集合) {
            for (let 上層限制項目 of 上層限制列表) {
                if (上層限制項目.限制主條件 != 1) {
                    continue;
                }
                let 上層每月上限 = 上層限制項目.限制細項.find((item) => { return item.編號 == 1 && item.是否已設 });
                let 上層每周上限 = 上層限制項目.限制細項.find((item) => { return item.編號 == 2 && item.是否已設 });
                let 上層每日上限 = 上層限制項目.限制細項.find((item) => { return item.編號 == 3 && item.是否已設 });
                let 上層單筆上限 = 上層限制項目.限制細項.find((item) => { return item.編號 == 4 && item.是否已設 });
                let 上層單筆下限 = 上層限制項目.限制細項.find((item) => { return item.編號 == 5 && item.是否已設 });


                if (每月上限 && 上層每月上限) {
                    if (!檢查限額不可大於(每月上限, 上層每月上限)) {
                        errorList.add(`[${限制項目.限制主項目}]每月上限超出上層[${上層限制項目.限制主項目}]設定：${上層每月上限.參數}`, true);
                    }
                }
                if (每周上限 && 上層每周上限) {
                    if (!檢查限額不可大於(每周上限, 上層每周上限)) {
                        errorList.add(`[${限制項目.限制主項目}]每周上限超出上層[${上層限制項目.限制主項目}]設定：${上層每周上限.參數}`, true);
                    }
                }

                if (每日上限 && 上層每日上限) {
                    if (!檢查限額不可大於(每日上限, 上層每日上限)) {
                        errorList.add(`[${限制項目.限制主項目}]每日上限超出上層[${上層限制項目.限制主項目}]設定：${上層每日上限.參數}`, true);
                    }
                }

                if (單筆上限 && 上層單筆上限) {
                    if (!檢查限額不可大於(單筆上限, 上層單筆上限)) {
                        errorList.add(`[${限制項目.限制主項目}]單筆上限超出上層[${上層限制項目.限制主項目}]設定：${上層單筆上限.參數}`, true);
                    }
                }

                if (單筆下限 && 上層單筆下限) {
                    if (!檢查限額不可大於(單筆下限, 上層單筆下限)) {
                        errorList.add(`[${限制項目.限制主項目}]單筆下限超出上層[${上層限制項目.限制主項目}]設定：${上層單筆下限.參數}`, true);
                    }
                }
            }
        }
    }
};
let 區碼檢查策略 = {
    檢查: function (errorList, 限制項目, 額外限制List) {
        if (!限制項目.限制主參數) {
            errorList.add(`[${限制項目.限制主項目}]限制主參數不可為空值`);
        }
        // errorList.add(JSON.stringify(限制項目));
    }
};
let 一組預設值策略 = {
    檢查: function (errorList, 自己, 額外限制List) {
        let flag = false;
        for (let i of 額外限制List) {
            if (i.限制主條件 == 1) {
                if (flag) {
                    errorList.add("預設值只能有一組");
                    return;
                } else {
                    flag = true;
                }
            }
        }
    }
}
let 限制說明不可為空值策略 = {
    檢查: function (errorList, 自己, 額外限制List) {
        for (let 限制項目 of 額外限制List) {
            if (限制項目.限制主項目 == "") {
                errorList.add("限制說明不可為空值");
            }
        }
    }
}
let 限制主條件不可為空值策略 = {
    檢查: function (errorList, 自己, 額外限制List) {
        for (let 限制項目 of 額外限制List) {
            if (!(限制項目.限制主條件 == 1 || 限制項目.限制主條件 == 2)) {
                errorList.add(`[${限制項目.限制主項目}]限制主條件不可為空值`);
            }
        }
    }
}
export const Limit = {
    Check(額外限制List, 上層限制集合) {
        // console.error("A", JSON.stringify(額外限制List));
        // console.error("B", JSON.stringify(上層限制集合))



        let errorList = new ErrorList();

        errorList.addTest(額外限制List, 上層限制集合)
        new 額外限制策略(一組預設值策略).檢查(errorList, null, 額外限制List);
        new 額外限制策略(限制說明不可為空值策略).檢查(errorList, null, 額外限制List);
        new 額外限制策略(限制主條件不可為空值策略).檢查(errorList, null, 額外限制List);
        for (let 限制項目 of 額外限制List) {
            switch (限制項目.限制主條件) {
                case 1: {
                    new 額外限制策略(限制細項檢查策略).檢查(errorList, 限制項目, 額外限制List);
                    new 額外限制策略(預設值檢查策略).檢查(errorList, 限制項目, 額外限制List);
                    if (errorList.getCanSend()) {
                        //前面兩個過了，再檢查上層
                        new 額外限制策略(預設值上層限制檢查策略).檢查(errorList, 限制項目, 額外限制List, 上層限制集合);
                    }
                    break;
                }
                case 2: {
                    new 額外限制策略(限制細項檢查策略).檢查(errorList, 限制項目, 額外限制List);
                    new 額外限制策略(區碼檢查策略).檢查(errorList, 限制項目, 額外限制List);
                    break;
                }
            }
        }
        return errorList;
    }
}