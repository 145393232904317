<template>
  <div id="ElectionGroupEdit">
    <section class="content-header">
      <h1>政黨編輯</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>政黨列表</li>
        <li class="active">政黨編輯</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯內容</h4>
            <button @click="backList()" class="btn btn-block btn-default btn-cog" type="button">
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>

          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 黨名：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.partyName"
                  />
                </div>
              </div>

              <div class="form-group image-part">
                <label class="col-sm-2 control-label" for="white_background_forum">
                  <span class="text-red">*</span> 圖片：
                </label>
                <div class="col-sm-9">
                  <div class="newtalkLogo">
                    <!-- <div class="img-close fa fa-fw fa-close" @click="DeletePic(item)"></div> -->
                    <img draggable="false" :src="edit.imageUrl" />
                  </div>
                  <input
                    accept="image/*"
                    id="white_background_forum"
                    type="file"
                    @change="onFileChange($event)"
                  />
                  <!-- <template>
                    <div class="help-block">
                      <span class="text-red" id="note3"
                        >推薦遊戲圖片建議尺寸 395 * 55</span>
                    </div>
                  </template> -->
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 排序：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.order"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 發佈：
                </label>
                <div class="col-sm-10">
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="上架"
                      name="radioMode"
                      v-model.number="edit.status"
                    />
                  </div>
                  <div class="radio radio-inline">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="下架"
                      name="radioMode"
                      v-model.number="edit.status"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button class="btn btn-primary btn-lg" type="button" @click="save()">確認送出</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/components/DatePicker";
import CKEditor from "@/components/CKEditor";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";
import tinymce from "@/components/Tinymce";
import config from "@/assets/js/app-config";
import moment from "moment";
import multiselect from "vue-multiselect";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    DatePicker,
    tinymce,
    multiselect
  },
  delimiters: ["${", "}"],
  name: "app",
  data() {
    return {
      id: this.$route.params.id || "",
      isEdit: false,
      module: "political_party",
      folderName: "profile_pic",
      type: "profile_pic",
      imagePath: process.env.VUE_APP_NEWTALK_IMAGE_PATH,
      edit: {
        module_id: utils.getTimeId(), 
        partyName: "",
        imageUrl: "",
        order: "",
        status: 1, // 1:上架 0:下架
      },
      imgList: [],
    };
  },
  created() {
    if(this.id) {
      this.isEdit = true;
      this.getData();
    } 
  },
  mounted() {
  },
  computed: {
    ...mapState({
      query: state => state.electionGroup.query[0],
      imageList: (state) => state.newtalk.imageList,
    }),
  },
  watch: {
    query() {
      this.edit = this.query;
    },
    imageList() {
      this.edit.imageUrl = this.imagePath + this.imageList[0].folder + this.imageList[0].filename;
    }
  },
  methods: {
    getData() {
      let data = new FormData();
      data.append("id", this.id);

      this.$store.dispatch(`electionGroup/query`, data);
    },
    GetImageList() {
      const formData = new FormData();
      formData.append("module", this.module);
      formData.append("module_id", this.edit.module_id);
      this.$store.dispatch("newtalk/getImageList", formData);
    },

    onFileChange(evt) {
      var files = evt.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案格式不符，請重新選擇。"
          );
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      this.uploadFile(files[0]);
    },

    uploadFile(image) {
      let data = new FormData();
      data.append("module", this.module);
      data.append("module_id", this.edit.module_id);
      data.append("image", image);
      data.append("tag", this.type);
      data.append("upload_type", this.folderName);

      this.$store.dispatch("newtalk/imageUpload", data).then((res) => {
        if (res.status != "0") {
          this.GetImageList();
        }
      });
    },
    save() {
      if(this.edit.partyName == "") {
        this.$eventBus.$emit("showAlert", '請輸入黨名');
        return
      }

      if(this.edit.imageUrl == "") {
        this.$eventBus.$emit("showAlert", '請上傳圖片');
        return
      }

      if(this.edit.order == "") {
        this.$eventBus.$emit("showAlert", '請輸入排序');
        return
      }

      let data = new FormData();
      if(this.isEdit) data.append("id", this.id);
      data.append("partyName", this.edit.partyName);
      data.append("module_id", this.edit.module_id);
      data.append("imageUrl", this.edit.imageUrl);
      data.append("order", this.edit.order);
      data.append("status", this.edit.status);

      let type = this.isEdit ? 'update' : 'add';
      let massege = this.isEdit ? '更新' : '新增';

      this.$store.dispatch(`electionGroup/${type}`, data).then((res) => {
        if(!res.data.isSuccess) {
          this.$eventBus.$emit("showAlert", res.data.errorMessage || `${massege}失敗!`);
          return
        }
        this.$eventBus.$emit("showAlert", `${massege}成功!`);
        this.backList();
      });
    },
    //*---- 返回
    backList() {
      this.$router.push({
        name: "NewtalkElectionGroupList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer);
    next();
  },
};
</script>
<style>
.radio.radio-inline._time .radio-date {
  position: absolute;
  top: 0;
  left: 50px;
}
.radio.radio-inline._time .radio-date .form-control {
  width: 182px;
}
</style>