import { render, staticRenderFns } from "./articleEdit.vue?vue&type=template&id=26933704"
import script from "./articleEdit.vue?vue&type=script&lang=js"
export * from "./articleEdit.vue?vue&type=script&lang=js"
import style0 from "./articleEdit.vue?vue&type=style&index=0&id=26933704&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports