<template>
  <div id="TopupRecordList">
    <section class="content-header">
      <h1>儲值查詢</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">儲值查詢</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <div class="form-group">
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <div class="pull-right">
                  <DateRangePicker
                    :propEndDate.sync="searchData.local.endTime"
                    :propStartDate.sync="searchData.local.startTime"
                    :propTimePicker="true"
                  />
                </div>
              </div>
            </div>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="玩家UID"
                type="text"
                v-model.trim="searchData.userId"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="訂單編號"
                type="text"
                v-model.trim="searchData.orderId"
              />
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.productType"
                @change="resetDataList()"
              >
                <option value="">儲值項目</option>
                <option
                  v-for="(product, index) in products"
                  :key="index"
                  :value="product.productType"
                >
                  {{ product.productName }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                v-model="searchData.platform"
                @change="resetDataList()"
              >
                <option value="Android">Android</option>
                <option value="iOS">iOS</option>
              </select>
            </div>
            <div class="btns">
              <button
                @click="resetDataList()"
                class="btn btn-primary"
                type="button"
              >
                查詢
              </button>
              <button
                class="btn btn-default"
                type="button"
                @click="exportOrders"
              >
                匯出
              </button>
              <button
                @click="goEdit()"
                class="btn btn-warning hide"
                type="button"
              >
                <i class="fa fa-cog"></i> 儲值品項管理
              </button>
            </div>
            <div class="text">
              <p>總儲值(USD)：{{ totalAmount | currencyFormat }}</p>
              <p>總儲值(TWD)：{{ (totalAmount * 30) | currencyFormat }}</p>
              <p>總筆數：{{ total }}</p>
              <p>不重複人數：{{ distinctCount }}</p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered text-center min-1000">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px">交易時間</th>
                  <th class="text-nowrap" style="min-width: 100px">玩家UID</th>
                  <th style="min-width: 150px">訂單編號</th>
                  <th>儲值平台</th>
                  <th>儲值項目</th>
                  <th>儲值品項</th>
                  <th>儲值金額</th>
                </tr>
                <!-- 由上往下排序：新到舊 -->
                <tr v-if="loading">
                  <td colspan="7">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td colspan="7">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td>
                    {{ item.配點時間 | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap">{{ item.id }}</td>
                  <td class="text-nowrap">{{ item.交易ID }}</td>
                  <td>{{ searchData.platform }}</td>
                  <td class="text-nowrap">{{ item.產品類別 }}</td>
                  <td class="text-nowrap">{{ item.產品項目 }}</td>
                  <td>{{ item.點數 | currencyFormat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0, //總筆數
      searchData: {
        keyword: "",
        pageNum: 1,
        isDelete: 0,
        pageSize: 20, //每頁顯示筆數
        local: {
          startTime: new moment().format("YYYY-MM-DD 00:00:00"),
          endTime: new moment().format("YYYY-MM-DD 23:59:59"),
        },
        platform: "Android",
        productType: "",
        orderId: "",
        userId: "",
      },
      products: [],
      datas: [],
      totalAmount: 0,
      distinctCount: 0,
    };
  },
  async created() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getProductList();
  },
  methods: {
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    getProductList() {
      this.$store.dispatch("fcDeposit/productQueryAll").then((result) => {
        this.products = result || [];
        this.getDataList();
      });
    },
    getDataList() {
      this.searchData.startTime = new Date(
        `${this.searchData.local.startTime} UTC`
      );
      this.searchData.endTime = new Date(
        `${this.searchData.local.endTime} UTC`
      );
      this.$store
        .dispatch("fcDeposit/queryAll", this.searchData)
        .then((result) => {
          this.datas = result.datas || [];
          this.total = result.total;
          this.totalAmount = result.totalAmount;
          this.distinctCount = result.distinctCount;
          this.loading = false;
          this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
        });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    goEdit() {
      this.$router.push({
        name: "TopupRecordEdit",
        params: {
          searchData: this.searchData,
        },
      });
    },
    exportOrders() {
      this.$store.dispatch("fcDeposit/export", this.searchData);
    },
  },
};
</script>