import api from "@/assets/js/app-api";

// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    dataUrl: "",
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    fileUpload({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.upload(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    fileDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.delete(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    filesDelete({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.deleteFilesApply(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    setIsMain({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.setIsMain(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    fileUploadByOrder({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.fileUploadByOrder(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    updateFileInfoApply({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.updateFileInfoApply(data).then(res => {
                commit('setResponse', res.data);
                resolve(res.data);
            })
        })
    },
    readFile({ commit }, data) {
        api.file.readFile(data).then(res => {
            if (res.data.status == "1") {
                commit('setDataUrl', "data:image/jpeg;base64," + res.data.result.fileContents);
            }
        })
    },
    queryAll({ commit }, data) {
        api.file.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setQueryAll', res.data.result);
            }
        })
    },
    updateFile({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.updateFile(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setResponse', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    updateFiles({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.updateFiles(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setResponse', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
    queryApplyAll({ commit }, data) {
        return new Promise((resolve, reject) => {
            api.file.queryApplyAll(data).then(res => {
                if (res.data.status == "1") {
                    if(!data.skipCommit){
                        commit('setResponse', res.data.result);
                    }
                }
                resolve(res.data);
            })
        });
    },
}

// mutations
const mutations = {
    setDataUrl(state, dataUrl) {
        state.dataUrl = dataUrl;
    },
    setResponse(state, response) {
        state.response = response;
    },
    setQueryAll(state, fileQueryAll) {
        state.queryAll = fileQueryAll;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}