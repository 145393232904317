<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>活動管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>活動管理</li>
        <li class="active">藝術大賞</li>
      </ol>
    </section>

    <section class="content">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button @click="addNew()" class="btn btn-block btn-warning btn-add" type="button">
              <i class="fa fa-plus"></i> 新增內容
            </button>
            <div class="select-group">
              <label class="control-label" style="width: 70px;">狀態：</label>
              <select
                class="form-control"
                required="required"
                v-model="searchData.isStop"
                @change="clickSearch"
              >
                <option :value="-1">全部</option>
                <option :value="0">上架</option>
                <option :value="1">下架</option>
              </select>
            </div>
            <div class="select-group">
              <label class="control-label" style="width: 70px;">類別：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.classTypeId"
                @change="clickSearch"
              >
                <option value>全部</option>
                <option
                  :value="item.classTypeId"
                  v-for="(item, index) in classTypes.datas"
                  :key="index"
                >{{item.className}}</option>
              </select>
            </div>
            <div class="select-group">
              <label class="control-label" style="width: 70px;">排序：</label>
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isDescOrder"
                @change="clickSearch"
              >
              <option :value="-1">舊→新</option>
                <option :value="1">新→舊</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="作品名稱"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button v-on:click="clickSearch()" class="btn btn-default" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="searchShow = 1">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ listDatas.total }}</span>
              </p>
            </div>
          </div>

          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800 AdListTable">
              <tbody>
                <tr class="bg-gray text-nowrap">
                  <th>#</th>
                  <th style="min-width: 150px;">id</th>
                  <th style="min-width: 100px;">類別</th>
                  <th style="min-width: 150px;">作品名稱</th>
                  <th style="min-width: 100px;">作者名稱</th>
                  <th style="min-width: 100px; width: 10%">主圖</th>
                  <!-- <th style="min-width: 60px;">年度</th> -->
                  <th style="min-width: 150px;">排序</th>
                  <th style="min-width: 60px;">狀態</th>
                  <th style="min-width: 150px;">功能</th>
                </tr>
                <template v-if="listDatas.total > 0">
                  <tr :key="item.id" v-for="(item, dataIndex) in listDatas.datas">
                    <td align="center" v-if="searchData.isDescOrder == -1">{{ dataIndex + 1 }}</td>
                    <td align="center" v-else>{{ listDatas.total - dataIndex }}</td>
                    <td align="center">{{ item.contentId }}</td>
                    <td align="center">{{ item.classTypeName }}</td>
                    <td align="center">{{ item.detailTopTitle }}</td>
                    <td align="center">{{ item.detailTopInfo }}</td>
                    <td>
                      <div class="pic jqimgFill">
                        <!-- 430 * 330 -->
                        <img :src="item.mainImgFullFileName" draggable="false" />
                      </div>
                    </td>
                    <td class="td-sort text-nowrap">
                      <input type="number" v-model="item.sortId" min="1" />
                      <button
                        @click="updateGameOd(item)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                      >更新</button>
                    </td>
                    <td align="center">
                      <div class="ckbx-style-8 ckbx toggleInput">
                      <input
                        :false-value="1"
                        :id="'check'+dataIndex"
                        :true-value="0"
                        @change="updateGameStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.isStop"
                      />
                      <label :for="'check'+dataIndex"></label>
                    </div>
                    </td>
                    <td class="text-center text-nowrap">
                      <button @click="editorItem(item)" class="btn btn-primary">修改</button>&nbsp;
                      <button @click="deleteItem(item)" class="btn btn-danger">刪除</button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="7">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  data() {
    return {
      orderByList: [],
      total: 0, //總筆數
      editPage: "ArtCreationEditing",
      searchData: {
        year : 2022,
        module: "art_activities",
        classTypeId: "",
        Lang: "zh-tw",
        isStop: -1,
        isDelete: 0,
        orderByVoteCount: -1,
        keyword: "",
        isDescOrder: -1, // -1 (舊→新)，1 (新→舊)
      }
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    await this.GetClassTypes();
    this.QueryAll();
  },
  mounted() {},
  computed: {
    ...mapState({
      classTypes: state => state.classTypes.queryAll,
      listDatas: state => state.game.queryAll,
      response: state => state.game.response
    }),
  },
  watch: {
    classTypes() {},
    response() {
      if (this.response.status == "1")
        this.$eventBus.$emit("showAlert", "更新成功!");
      else this.$eventBus.$emit("showAlert", "更新失敗!");

      this.QueryAll();
    },
  },
  methods: {
    QueryAll() {
      //列表
      this.$store.dispatch("game/gameQueryAll", this.searchData);
    },

    async GetClassTypes() {
      //分類
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        projectId: this.searchData.projectId,
        module: this.searchData.module,
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0
      });
    },

    updateGameOd(item) {
      item.sortId = parseInt(item.sortId);
      item.isTurnPlay = parseInt(item.isTurnPlay);

      this.$store.dispatch("game/gameUpdateSort", item);
    },

    updateGameStatus(item) {
      let detailContent = [];
      detailContent.push({
        lang: item.detailTopLang,
        title: item.detailTopTitle,
        info: item.detailTopInfo,
      });
      this.$store.dispatch("game/gameUpdate", {
        master_model: item,
        detail_model: detailContent
      });
    },

    changeStopStatus(status) {
      return (status == 1) ? 0 : 1;
    },

    clickSearch() {
      this.QueryAll();
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: this.editPage,
        params: { searchData: this.searchData }
      });
      return;
    },

    //*---- 修改內容
    editorItem(item) {
      this.$router.push({
        name: this.editPage,
        params: { id: item.contentId, searchData: this.searchData }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          this.$store.dispatch("game/gameDelete", item);
        }
      });
    }
  }
};
</script>
<style scoped>
.select-group {
  width: 200px !important;
  display: flex !important;
  align-items: center !important;
}
</style>