<template>
  <div id="NewtalkAnnouncementList" class="normal">
    <section class="content-header">
      <h1>討論區公告管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">討論區公告管理</li>
      </ol>
    </section>
    <section class="content forum-announcement">
      <div class="box box-primary">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="updateArticle()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增公告
            </button>
            <div class="form-group" style="margin-right: 0px">
              <label class="control-label">開始日期：</label>
              <div class="input-group date">
                <div class="input-group-addon">
                  <i class="fa fa-calendar"></i>
                </div>
                <DateRangePicker
                  :propEndDate.sync="searchData.endTime"
                  :propStartDate.sync="searchData.startTime"
                />
              </div>
            </div>
            <div class="select-group">
              <select
                id="selectUnit"
                class="form-control"
                required="required"
                @change="resetDataList()"
                v-model.number="searchData.articleType"
              >
                <option value="1">話題</option>
                <option value="3">留言</option>
                <option value="4">回覆</option>
              </select>
            </div>
            <div class="select-group">
              <input
                class="form-control"
                type="text"
                placeholder="發佈者搜尋"
                v-model.trim="searchData.targetMobile"
              />
            </div>
            <div class="select-group" v-if="searchData.articleType !== 1">
              <input
                class="form-control"
                type="text"
                placeholder="暱稱搜尋"
                v-model.trim="searchData.nickName"
              />
            </div>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="搜尋關鍵字"
                v-model.trim="searchData.articleTitle"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span>資料總筆數：</span>
                <span id="total">{{ total }}</span>
              </p>
            </div>
            <div class="btns">
              <button
                @click="deleteAll()"
                class="btn btn-block btn-danger btn-cog mr-10"
                type="button"
              >
                <i class="fa fa-trash"></i> 批次刪除
              </button>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table
              class="table table-hover table-bordered AdListTable"
              v-if="searchData.articleType === 1"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 50px">ID</th>
                  <th style="min-width: 50px">置頂</th>
                  <th style="min-width: 150px">置頂排序</th>
                  <th style="min-width: 50px">類型</th>
                  <th style="min-width: 200px">標題</th>
                  <th style="min-width: 100px">發佈日期</th>
                  <th style="min-width: 100px">發佈者</th>
                  <th style="min-width: 50px">狀態</th>
                  <th style="min-width: 50px">喜歡</th>
                  <th style="min-width: 60px">不喜歡</th>
                  <th style="min-width: 50px">留言</th>
                  <th style="min-width: 150px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="13">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="13">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="!item.isDelete">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ link: item.isDelete === 0 }"
                    @click="linkItem(item)"
                  >
                    {{ item.articleId }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.officialHotTop > 0 ? "是" : "否" }}
                    <button
                      @click="deleteHopTop(item, 'officialHotTop', 1)"
                      class="btn btn-danger btn-add btn-upDate"
                      type="button"
                      v-if="item.officialHotTop > 0"
                    >
                      取消
                    </button>
                  </td>
                  <td class="text-nowrap">
                    <div class="input-group">
                      <input
                        class="form-control"
                        type="number"
                        min="1"
                        max="5"
                        v-model.number="item.local.officialHotTop"
                      />
                      <button
                        @click="setHotTop(item, 'officialHotTop', 1)"
                        class="btn btn-warning btn-add btn-upDate"
                        type="button"
                        :disabled="item.isDelete == 2"
                      >
                        更新
                      </button>
                    </div>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ newtalkTypes[item.newtalkType] || item.newtalkType }}
                  </td>
                  <td>
                    <span :class="{ 'text-red': item.isDelete }">
                      {{ item.articleTitle }}
                    </span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.publishTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.authorHide }}
                  </td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ 'text-red': item.isDelete }"
                  >
                    {{ define.deleteStatus[item.isDelete] }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.likecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.notlikecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    <span
                      class="link"
                      v-if="item.comment_count > 0"
                      @click="updateComment(item)"
                    >
                      {{ item.comment_count }}
                    </span>
                    <span v-else>
                      {{ item.comment_count }}
                    </span>
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary"
                      v-if="item.authorHide === official"
                      @click="updateArticle(item)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-primary"
                      v-else
                      @click="showContent(item, item.articleId)"
                    >
                      詳細
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-warning"
                      v-if="item.authorHide === official && item.isDelete === 2"
                      @click="previewItem(item)"
                    >
                      預覽
                    </button>
                    <button
                      class="btn btn-warning"
                      v-if="!item.isDelete"
                      @click="officialRply(item, 1)"
                    >
                      回覆
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-hover table-bordered"
              v-if="searchData.articleType === 3"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 100px">ID</th>
                  <th style="min-width: 60px">樓層</th>
                  <th style="min-width: 200px">內容</th>
                  <th style="min-width: 100px">發佈日期</th>
                  <th style="min-width: 100px">發佈者</th>
                  <th style="min-width: 100px">暱稱</th>
                  <th style="min-width: 60px">狀態</th>
                  <th style="min-width: 60px">喜歡</th>
                  <th style="min-width: 60px">不喜歡</th>
                  <th style="min-width: 60px">回覆</th>
                  <th style="min-width: 200px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="12">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="12">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="!item.isDelete">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-nowrap text-center">{{ item.commentId }}</td>
                  <td class="text-nowrap text-center">
                    {{ item.commentFloor || "-" }}
                  </td>
                  <td>
                    <span
                      :class="{ 'text-red': item.isDelete }"
                      v-html="showText(item, item.commentContent)"
                    ></span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.commentTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap">{{ item.commentAuthor }}</td>
                  <td class="text-nowrap">{{ item.nickName || "-" }}</td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ 'text-red': item.isDelete }"
                  >
                    {{ define.deleteStatus[item.isDelete] }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.likecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.notlikecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    <span
                      class="link"
                      v-if="item.reply_count > 0"
                      @click="updateReply(item)"
                    >
                      {{ item.reply_count }}
                    </span>
                    <span v-else>
                      {{ item.reply_count }}
                    </span>
                  </td>
                  <td class="text-center">
                    <button
                      @click="showContent(item, item.commentId)"
                      class="btn btn-primary"
                    >
                      詳細
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-warning"
                      v-if="!item.isDelete"
                      @click="officialRply(item, 2)"
                    >
                      回覆
                    </button>
                    <button
                      v-if="!item.isDelete"
                      @click="deleteItem(item)"
                      class="btn btn-danger ml-5"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-hover table-bordered"
              v-if="searchData.articleType === 4"
            >
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 50px">
                    <div class="checkbox-inline">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th style="min-width: 60px">ID</th>
                  <th style="min-width: 80px">樓層</th>
                  <th style="min-width: 200px">內容</th>
                  <th style="min-width: 150px">發佈日期</th>
                  <th style="min-width: 150px">發佈者</th>
                  <th style="min-width: 150px">暱稱</th>
                  <th style="min-width: 60px">狀態</th>
                  <th style="min-width: 60px">喜歡</th>
                  <th style="min-width: 60px">不喜歡</th>
                  <th style="min-width: 200px">功能</th>
                </tr>
                <tr v-if="loading">
                  <td class="text-center" colspan="11">查詢中</td>
                </tr>
                <tr v-else-if="datas.length === 0">
                  <td class="text-center" colspan="11">查詢無任何資料</td>
                </tr>
                <tr v-else v-for="(item, index) in datas" :key="index">
                  <td class="text-center">
                    <div class="checkbox-inline" v-if="!item.isDelete">
                      <label>
                        <iCheckCheckBox
                          :propValue="item"
                          :propChecked="item.checked"
                          v-on:update:checked="checkChange"
                        />
                      </label>
                    </div>
                  </td>
                  <td class="text-nowrap text-center">{{ item.replyId }}</td>
                  <td class="text-nowrap text-center">
                    {{ item.commentFloor }}-{{ item.ReplyFloor }}
                  </td>
                  <td>
                    <span
                      :class="{ 'text-red': item.isDelete }"
                      v-html="showText(item, item.replyContent)"
                    ></span>
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.replyTime | dateFormat("YYYY/MM/DD HH:mm:ss") }}
                  </td>
                  <td class="text-nowrap">{{ item.author }}</td>
                  <td class="text-nowrap">{{ item.nickName || "-" }}</td>
                  <td
                    class="text-nowrap text-center"
                    :class="{ 'text-red': item.isDelete }"
                  >
                    {{ define.deleteStatus[item.isDelete] }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.likecount }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.notlikecount }}
                  </td>
                  <td class="text-center">
                    <button
                      @click="showContent(item, item.replyId)"
                      class="btn btn-primary"
                    >
                      詳細
                    </button>
                    &nbsp;
                    <button
                      class="btn btn-warning"
                      v-if="!item.isDelete"
                      @click="officialRply(item, 3)"
                    >
                      回覆
                    </button>
                    <button
                      v-if="!item.isDelete"
                      @click="deleteItem(item)"
                      class="btn btn-danger ml-5"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border">
          <Pagination
            :pageLimitCount="searchData.pageSize"
            :total="total"
            ref="pagination"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <forumContent ref="forumContent" :getDataList="getDataList" :page="'announcement'" />
    <announcementAlert
      ref="announcementAlert"
      :actionType="actionType"
      :checkDatas="checkDatas"
      v-on:updateList="getDataList"
    />
    <announcementReplyAlert
      ref="announcementReplyAlert"
      :actionType="actionType"
      :checkDatas="checkDatas"
      v-on:updateList="getDataList"
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import Pagination from "@/components/Pagination";
import DateRangePicker from "@/components/DateRangePicker";
import iCheckCheckBox from "@/components/iCheckBox";
import forumContent from "@/views/projects/Newtalk/ForumContent";
import announcementAlert from "@/views/projects/Newtalk/AnnouncementAlert";
import announcementReplyAlert from "@/views/projects/Newtalk/AnnouncementReplyAlert";
import htmlToText from "html-to-formatted-text";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckCheckBox,
    forumContent,
    announcementAlert,
    announcementReplyAlert,
  },
  data() {
    return {
      loading: true,
      total: 0,
      official: "886905390185",
      searchData: {
        keyword: "",
        isDelete: -1,
        page: 1,
        pageSize: 20,
        categoryNo: 1, // 1: 討論區
        orderType: 2, // 0：時間順排，2：時間逆排
        articleType: 1,
        articleTitle: "",
        targetMobile: "",
        authorUid: "",
        contentKey: "",
        nickName: "",
        startTime: new moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: new moment().format("YYYY-MM-DD 23:59:59"),
      },
      newtalkTypes: {
        討論區: "話題",
        新聞留言: "評論",
        讀者投書: "投書",
        特別企劃: "特企",
      },
      datas: [],
      checkIds: [],
      checkDatas: [],
      actionType: "",
      isCheckAll: false,
    };
  },
  mounted() {
    this.searchData = this.$route.params.searchData || this.searchData;
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.clearCheckAll();
      this.searchData.authorUid = this.searchData.targetMobile;
      this.searchData.contentKey = this.searchData.articleTitle;
      this.actionType =
        this.searchData.articleType === 4
          ? "reply"
          : this.searchData.articleType === 3
          ? "comment"
          : "article";
      this.loading = true;
      this.$store
        .dispatch(`newtalk/${this.actionType}QueryAll`, this.searchData)
        .then((result) => {
          this.datas =
            result.articleList ||
            result.commentDetailList ||
            result.replyDetailList ||
            [];
          this.total = result.totalCount;
          this.loading = false;
          this.updateDataList();
          this.$refs.pagination.setPage(this.searchData.page, this.total);
        });
    },
    updateDataList() {
      this.datas.forEach((item) => {
        item.local = item.local || {};
        item.local.officialHotTop = item.officialHotTop;
        item.local.hotTop = item.hotTop;
      });
    },
    resetDataList() {
      this.searchData.page = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    linkItem(item) {
      this.$refs.forumContent.linkItem(item);
    },
    previewItem(item) {
      this.$refs.forumContent.previewItem(item);
    },
    clearCheckAll() {
      this.checkIds = [];
      this.checkDatas = [];
      this.checkAll({ checked: 0 });
      this.$forceUpdate();
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.datas.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    checkChange(data) {
      const id =
        data.value.replyId || data.value.commentId || data.value.articleId;
      if (data.checked && !this.checkIds.includes(id)) {
        this.checkIds.push(id);
        this.checkDatas.push(data.value);
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.checkDatas.splice(index, 1);
        }
      }
    },
    showContent(item, targetId) {
      item.articleType = this.searchData.articleType;
      this.$refs.forumContent.showForumContent(item, targetId);
    },
    officialRply(item, type) {
      this.$refs.announcementReplyAlert.showAlert(item, type);
    },
    showText(item, content) {
      if (!item.contentText) {
        if (item.newtalkType === "討論區" && !item.userType && !item.replyId) {
          item.contentText = this.ellipsis(htmlToText(content), 35);
        } else if (content.startsWith("<p>")) {
          item.contentText = this.ellipsis(htmlToText(content), 35);
        } else {
          item.contentText = this.ellipsis(content, 35);
        }
      }
      return item.contentText;
    },
    ellipsis(value, limit) {
      value = value || "";
      limit = limit || value.length;
      return value.length > limit ? `${value.substring(0, limit)}...` : value;
    },
    updateArticle(item) {
      this.$router.push({
        name: "NewtalkAnnouncementEdit",
        params: {
          id: item ? item.articleId : null,
          searchData: this.searchData,
        },
      });
    },
    updateComment(item) {
      this.$router.push({
        name: "NewtalkAnnouncementDetailList",
        params: {
          type: 3,
          id: item ? item.articleId : null,
          searchData: this.searchData,
        },
      });
    },
    updateReply(item) {
      this.$router.push({
        name: "NewtalkAnnouncementDetailList",
        params: {
          type: 4,
          id: item ? item.commentId : null,
          searchData: this.searchData,
        },
      });
    },
    deleteAll() {
      if (this.checkIds.length === 0) {
        this.$eventBus.$emit("showAlert", "您尚未選擇刪除對象！");
        return;
      }
      this.$refs.announcementAlert.showAlert();
    },
    deleteItem(item) {
      this.checkDatas = []
      this.$refs.announcementAlert.showAlert(item);
    },
    setHotTop(item, key, type) {
      if (item.local) {
        if (item.local[key] === 0) {
          this.$eventBus.$emit("showAlert", "排序不可為0!");
          return;
        } else if (item.local[key] > 5) {
          this.$eventBus.$emit("showAlert", "排序不可超過5!");
          return;
        }
        this.$store
          .dispatch("newtalk/topCreate", {
            topType: type,
            targetId: item.articleId,
            topPosition: item.local[key],
          })
          .then(() => {
            item[key] = item.local[key];
          });
      }
    },
    deleteHopTop(item, key, type) {
      if (item.local) {
        this.$store
          .dispatch("newtalk/topDelete", {
            topType: type,
            targetId: item.articleId,
          })
          .then(() => {
            item[key] = 0;
            item.local[key] = 0;
          });
      }
    },
  },
};
</script>
