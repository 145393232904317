<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>{{ channalKPIData.target_name }}的KPI</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>網紅列表</li>
        <li class="active">KPI</li>
      </ol>
    </section>

    <section class="content"  v-if="kolkpiData">
      <div class="box box-primary">
        <div class="box-body">
          <!-- ==== 目前數據 ==== -->
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">目前數據</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>

            <button
              @click="editorItem()"
              class="btn btn-block btn-warning btn-cog"
              type="button"
            >
              <i class="fa fa-pencil"></i> 編輯
            </button>

            <button
              @click="exportData()"
              class="btn btn-block btn-primary btn-cog"
              type="button">
              <i class="fa fa-upload"></i> 匯出
            </button>

            <button
              class="btn btn-block btn-google btn-cog"
              type="button"
              @click="crawlerKOLChannel()"
            >
              <i class="fa fa-bug"></i> 重抓
            </button>
          </div>

          <!-- 選擇平台 -->
          <div class="top-box mt-15">
            <div class="select-group">
              <select
                class="form-control"
                id="selectSocial"
                v-model="platform"
                @change="changePlatform()"
              >
                <option disabled>請選擇平台</option>
                <option
                  v-for="(item, key) in platformList"
                  :value="item"
                  :key="key"
                >
                  {{ item.className }}
                </option>
              </select>
            </div>

            <div class="select-group long">
              <select class="form-control" id="selectSocial" 
                v-model="channel">
                <option disabled>請選擇頻道</option>
                <option
                  :value="item"
                  :key="key"
                  v-for="(item, key) in channelList"
                >
                  {{ item.channel_name == '' ? '尚未設置頻道名' : item.channel_name }}
                </option>
              </select>
            </div>

            <a :href="channalKPIData.channel_url" 
              class="" target="_blank"
                ><i class="c-BigDataLinkIcon t-5 fa fa-link"></i
              ></a>
          </div>

          <div class="dataCardWrapper clearfix">
            <div class="dataCard">
              <h5 class="card-title">粉絲數</h5>
              <p class="card-text">
                {{ pareToFloor(channel.follow) | currencyFormat2}}
              </p>
            </div>

            <div class="dataCard" v-if="channalKPIData.platform != 'yt'">
              <h5 class="card-title">總貼文數</h5>
              <p class="card-text">
                {{ channel.total | currencyFormat }}
              </p>
            </div>

            <div class="dataCard" v-else>
              <h5 class="card-title">總影片數</h5>
              <p class="card-text">
                {{ channel.total | currencyFormat}}
              </p>
            </div>
          </div>

          <!-- ==== 數據分析 ==== -->
          <hr class="divider mb-10" />
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">數據分析</h4>
          </div>

          <!-- date search -->
          <div class="top-box mt-15">

            <!-- day -->
            <div class="btn-group w100-sm ml-10 ml-0-sm">
              <button
                type="button"
                class="btn btn-default w25-sm"
                v-for="(day, key) in dayAnalysis"
                :key="key"
                v-on:click="changeDays(day)"
                v-bind:class="{
                  active: dayAnalysisChoose == 'day' + day,
                }"
              >
                {{ day + "天" }}
              </button>
            </div>
          </div>

          <!-- data card -->
          <div class="dataCardWrapper clearfix">
            <label class="card-label">
              貼文數：{{ channalKPIData.interval_post_count }}</label
            >
            <div class="dataCard">
              <h5 class="card-title">平均留言數</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="總留言數/貼文數"
                >?</i
              >
              <p class="card-text">
                {{ channalKPIData.avg_comment.toFixed(2) | currencyFormat}}
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">平均按讚數</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="總按讚數/貼文數"
                >?</i
              >
              <p class="card-text">
                {{ channalKPIData.avg_like.toFixed(2) | currencyFormat }}
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">平均互動數</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="(按讚數+分享數+留言數)/貼文數"
                >?</i
              >
              <p class="card-text">
                {{ channalKPIData.avg_post_interactions.toFixed(2) | currencyFormat}}
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">互動率</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="貼文平均互動數 / 平台追蹤數 * 100%"
                >?</i
              >
              <p class="card-text">
                {{ parseFloatNoRounding(channalKPIData.avg_post_probability) }}%
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">影片平均觀看數</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="總觀看數/影片數"
                >?</i
              >
              <p class="card-text">
                {{ channalKPIData.avg_view_content.toFixed(2) | currencyFormat}}
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">影片平均觀看率</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="影片平均觀看數／平台追蹤數 * 100%"
                >?</i
              >
              <p class="card-text">
                {{
                  parseFloatNoRounding(
                    channalKPIData.avg_view_content / channalKPIData.max_follow
                  )
                }}%
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">影片平均互動數</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="指定時間內（按讚數＋留言數）／影片數"
                >?</i
              >
              <p class="card-text">
                {{ channalKPIData.avg_post_interactions.toFixed(2) | currencyFormat }}
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">影片平均互動率</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="平均互動數 / 平台追蹤數 * 100%"
                >?</i
              >
              <p class="card-text">
                {{ parseFloatNoRounding(channalKPIData.avg_post_probability) }}%
              </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">漲粉數</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="總平台追蹤數 - 指定時間平台追蹤數"
                >?</i
              >
              <p class="card-text">{{ channalKPIData.fans_up | currencyFormat}} </p>
            </div>

            <div class="dataCard">
              <h5 class="card-title">漲粉率</h5>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="(總平台追蹤數 - 指定時間前平台追蹤數) / 指定時間前平台追蹤數 * 100%"
                >?</i
              >
              <p class="card-text">
                {{ parseFloatNoRounding(channalKPIData.fans_up_probability) }}%
              </p>
            </div>
          </div>

          <div class="nav-tabs-custom boxShadow-none clearfix overflowX-auto">
            <ul class="nav nav-tabs nav-yellow min-600">
              <li
                v-for="(tab, key) in tabAnalysisName"
                :key="key"
                v-bind:class="{ active: tabAnalysis == 'tab' + key }"
                @click.prevent="SetGraphValue(key)"
              >
                <a :href="'#tab' + key"> {{ tab }}</a>
              </li>
            </ul>
            <div class="tab-content">
              <div
                v-for="(tab, key) in tabAnalysisName"
                :key="key"
                v-bind:class="{ active: tabAnalysis == 'tab' + key }"
                class="tab-pane pt-30"
                :id="'#tab' + key"
              >
                <highcharts :options="chartOptions"></highcharts>
              </div>
            </div>
          </div>

          <!-- ==== 歷史貼文 ==== -->
          <hr class="divider mb-10" />
          <div class="top-box mt-20">
            <!-- 搜尋 -->
            <div class="input-group atLeft">
              <input
                name="table_search"
                placeholder="搜尋貼文關鍵字"
                type="text"
                class="form-control pull-right"
                v-model="historySearchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click="searchHistoryKeyWord"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>

            <!-- 重設 -->
            <button
              @click="searchReset()"
              class="btn btn-default"
              type="button"
            >
              <i class="fa fa-refresh"></i> 重設
            </button>

            <!-- table/list -->
            <div class="btn-group fr">
              <button
                type="button"
                class="btn btn-default"
                v-on:click="tabLayout = 'table'"
                v-bind:class="{ active: tabLayout == 'table' }"
              >
                <i class="fa fa-list-ul"></i>
              </button>
              <button
                type="button"
                class="btn btn-default"
                v-on:click="tabLayout = 'list'"
                v-bind:class="{ active: tabLayout == 'list' }"
              >
                <i class="fa fa-th"></i>
              </button>
            </div>
          </div>

          <div class="nav-tabs-custom boxShadow-none clearfix mt-35">
            <ul class="nav nav-tabs nav-yellow">
              <li
                v-for="(tab, key) in tabPostName"
                :key="key"
                v-bind:class="{ active: tabPost == 'tab' + key }"
                @click.prevent="changeSortTab(key)"
              >
                <a :href="'#tab' + key"> {{ tab }}</a>
              </li>
            </ul>

            <div class="tab-content">
              <div
                v-for="(tab, key) in tabPostName"
                :key="key"
                v-bind:class="{ active: tabPost == 'tab' + key }"
                class="tab-pane overflowX-auto table-responsive"
                :id="'#tab' + key"
              >
                <div
                  class="css-table post-table"
                  v-bind:class="{ cssList: tabLayout == 'list' }"
                >
                  <div class="thead">
                    <div class="tr">
                      <div class="th" style="width: 50%">貼文</div>
                      <!-- <div class="th" style="width: 10%">互動率</div> -->
                      <div class="th" style="width: 10%">按讚數</div>
                      <div class="th" style="width: 10%">留言數</div>
                      <div class="th" style="width: 10%" 
                        v-if="platform.classId == 'yt'"
                        >觀看數</div>
                      <div class="th" style="width: 10%">爬蟲時間</div>
                    </div>
                  </div>
                  <div class="tbody">
                    <a
                      class="postCard tr"
                      v-for="(post, key) in historyList"
                      v-if="post.is_valid !== false"
                      :key="key"
                      href="javascript:;"
                      @click="goPostData(post)"
                    >
                      <div class="card-body td">
                        <div class="card-img jqImgFill">
                                
                          <img :src="post.post_display_url?post.post_display_url:require('@/assets/no-image.png')"/>

                          <i
                            class="typeIcon fa"
                            v-bind:class="
                              !isVideo(post) ? 'fa-sticky-note' : 'fa-play'
                            "
                          ></i>
                        </div>
                        <p class="card-text" v-html="post.description"></p>
                        <span class="card-date">{{
                          $utils.formatTime(
                            post.upload_date,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}</span>
                      </div>
                      <!-- <div class="td td-rate">
                        {{ post.avg_post_probability }}
                      </div> -->
                      <div class="td td-like">
                        <i class="icon fa fa-thumbs-up"></i>
                        {{ post.like_count | currencyFormat }}
                      </div>
                      <div class="td td-comment">
                        <i class="icon fa fa-comment"></i
                        >{{ post.comment_counts | currencyFormat }}
                      </div>
                      <div class="td td-browse"
                        v-if="platform.classId == 'yt'">
                        <i class="icon fa fa-eye"></i> 
                        {{ post.view_count | currencyFormat}}
                      </div>
                      <div class="td td-crawl time">
                        {{
                          $utils.formatTime(
                            post.last_update,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="box-footer text-center no-border mt-20">
                <Pagination
                  :pageLimitCount="historySearchData.page_size"
                  :total="historyTotal"
                  ref="pagination"
                  v-on:pageMethod="onPageChange"
                  v-show="historyTotal > 0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import appUtils from "@/assets/js/app-utils";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    DateRangePicker,
    Pagination,
  },
  data() {
    return {
      dayAnalysis: ["3", "7", "14", "30"],
      dayAnalysisChoose: "day7",

      tabAnalysis: "tab0",
      tabPostName: ["近期貼文", "熱門貼文"],
      tabPost: "tab0",
      tabLayout: "table",

      platform: {}, //請選擇平台
      platformList: [],
      channel: {},//請選擇頻道
      channelList: [],

      tabAnalysisName: [
        "粉絲數",
        "平均觀看數",
        "平均按讚數",
        "平均留言數",
        "互動率",
        "漲粉率",
      ],

      chartOptions: {
        chart: { type: "spline" },
        title: { text: "" },
        xAxis: {
          title: { text: "日期" },
          categories: [
            "2021/04/21",
            "2021/04/20",
            "2021/04/19",
            "2021/04/18",
            "2021/04/17",
            "2021/04/16",
            "2021/04/15",
          ],
        },
        yAxis: {
          title: { text: "人數" },
        },
        series: [
          {
            name: "人數",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
        tooltip: {},
      },

      channalKPIData: {
        platform: "",
        main_id: "",
        channel_id: "",
        max_follow: 0.0,
        min_follow: 0.0,
        max_like: 0.0,
        min_like: 0.0,
        avg_like: 0.0,
        sum_like: 0.0,
        max_comment: 0.0,
        avg_comment: 0.0,
        interval_post_count: 0.0,
        min_comment: 0.0,
        sum_comment: 0.0,
        avg_view_content: 0.0,
        sum_view_content: 0.0,
        avg_post_interactions: 0.0,
        avg_post_probability: 0.0,
        fans_up: 0.0,
        fans_up_probability: 0.0,
        max_follow_list: [0, 0, 0, 0, 0, 0, 0],
        min_follow_list: [0, 0, 0, 0, 0, 0, 0],
        max_like_list: [0, 0, 0, 0, 0, 0, 0],
        min_like_list: [0, 0, 0, 0, 0, 0, 0],
        avg_like_list: [0, 0, 0, 0, 0, 0, 0],
        sum_like_list: [0, 0, 0, 0, 0, 0, 0],
        max_comment_list: [0, 0, 0, 0, 0, 0, 0],
        avg_comment_list: [0, 0, 0, 0, 0, 0, 0],
        interval_post_count_list: [0, 0, 0, 0, 0, 0, 0],
        min_comment_list: [0, 0, 0, 0, 0, 0, 0],
        sum_comment_list: [0, 0, 0, 0, 0, 0, 0],
        avg_view_content_list: [0, 0, 0, 0, 0, 0, 0],
        sum_view_content_list: [0, 0, 0, 0, 0, 0, 0],
        avg_post_interactions_list: [0, 0, 0, 0, 0, 0, 0],
        avg_post_probability_list: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        fans_up_list: [0, 0, 0, 0, 0, 0, 0],
        fans_up_probability_list: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
        update_at_list: [
          "2021-08-24",
          "2021-08-25",
          "2021-08-26",
          "2021-08-27",
          "2021-08-28",
          "2021-08-29",
          "2021-08-30",
        ],
        analysis_days: 7,
        update_at: "2021-08-31T04:30:45.876756",
      },

      //之後要嘗試外面選擇什麼社群進來就一樣的社群
      // searchData: {
      //   platform: "",
      // },

      kolSearchData: {
        KolId: this.$route.params.id,
        channel_id: "",
        platform: "",
        days: 7,
      },

      historySearchData: {
        channel_id: "",
        keyword: "",
        platform: "",
        page_number: 1,
        page_size: 10,
        sort: { upload_date: "desc" },
      },
    };
  },
  created() {
    this.GetQueryData();
  },
  mounted() {
    $("[data-toggle='tooltip']").tooltip();
    window.scrollTo(0,0);
  },
  computed: {
    ...mapState({
      historyList: (state) => state.kolBigData.historyList,
      historyTotal: (state) => state.kolBigData.historyTotal,
      kolTypes: (state) => state.classTypes.queryAll,
      kolkpiData: (state) => state.kolBigData.kolkpiData,
      query: (state) => state.kolBigData.kolQuery,
    }),
  },
  watch: {
    query() {
      this.getClassTypes();
      // console.log(this.historyList[0])
    },
    kolTypes() {
      this.query.channel_data.forEach((item) => {
        let index = this.kolTypes.datas.findIndex(
          (type) => type.classId == item.platform
        );
        if (index != -1) {
          let platformListIndex = this.platformList.findIndex(
            (p) => p.classId == item.platform
          );
          if (platformListIndex == -1) {
            this.platformList.push(this.kolTypes.datas[index]);
            this.platformList[this.platformList.length - 1].channels = [];
            this.platformList[this.platformList.length - 1].channels.push(item);
          } else {
            this.platformList[platformListIndex].channels.push(item);
          }
        }
      });

      this.platform = this.platformList[0];
      this.channelList = this.platformList[0].channels;
      this.channel = this.platformList[0].channels[0];
      if (this.$route.params.datas != undefined) {
        this.platform = this.$route.params.datas.platform;
        this.channelList = this.$route.params.datas.channelList;
        this.channel = this.$route.params.datas.channel;
      }

      // this.GetHistoryListData();
      // this.GetKolKpiData();
    },
    kolkpiData() {
      this.channalKPIData = this.kolkpiData[0];
      this.SetGraphValue(0);
    },
    channel() {
      this.historySearchData.page_number = 1;
      this.GetHistoryListData();
      this.GetKolKpiData();
    },
  },
  methods: {
    // TODO: 取得網紅單筆資料
    GetQueryData() {
      this.$store.dispatch("kolBigData/query", {
        KolID: this.$route.params.id,
      });
    },

    // TODO: 平台
    getClassTypes() {
      this.$store.dispatch("classTypes/classTypeQueryAll", {
        module: "KOLPlatform",
        lang: "zh-tw",
        isDelete: 0,
        isStop: 0,
      });
    },

    // TODO: 取得網紅頻道KPI資料
    GetKolKpiData() {
      // this.kolSearchData.platform = this.platform;
      this.kolSearchData.channel_id = this.channel.channel_id;
      this.kolSearchData.platform = this.channel.platform;
      this.$store.dispatch("kolBigData/kolkpi", this.kolSearchData);
    },

    // TODO: 切換天數
    changeDays(day) {
      this.dayAnalysisChoose = "day" + day;
      this.kolSearchData.days = Number(day);
      this.GetKolKpiData();
    },

    // TODO: 切換歷史資料排序
    changeSortTab(key) {
      this.tabPost = "tab" + key;
      switch (key) {
        case 0:
          this.historySearchData.sort = { upload_date: "desc" };
          break;
        case 1:
          this.historySearchData.sort = { like_count: "desc" };
          break;
      }
      this.historySearchData.page_number = 1;
      this.GetHistoryListData();
    },

    // TODO: keyword查詢歷史資料
    searchHistoryKeyWord() {
      this.historySearchData.page_number = 1;
      this.GetHistoryListData();
    },

    // TODO: 歷史資料查詢重置
    searchReset() {
      this.historySearchData.keyword = "";
      this.historySearchData.page_number = 1;
      this.GetHistoryListData();
    },

    // TODO: 取的歷史資料
    GetHistoryListData() {
      this.historySearchData.channel_id = this.channel.channel_id;
      this.historySearchData.platform = this.channel.platform;
      this.$store.dispatch("kolBigData/history", this.historySearchData);
    },

    // TODO: 將頻道加入爬蟲排程
    crawlerKOLChannel() {
      this.$store
        .dispatch("kolBigData/crawlerKOLChannel", {
          params: { channel_id: this.channalKPIData.channel_id },
        })
        .then((res) => {
          if (res.status == "1") {
            this.$eventBus.$emit("showAlert", "已加入排程執行!!");
            return;
          } else {
            this.$eventBus.$emit("showAlert", "出現異常，請稍後再操作!!");
            return;
          }
        });
    },

    // TODO: 切換平台
    changePlatform() {
      this.channelList = this.platform.channels;
      this.channel = this.platform.channels[0];
    },

    // TODO: 切換數據資料
    SetGraphValue(index) {
      this.chartOptions.xAxis.categories = this.channalKPIData.update_at_list;

      this.tabAnalysis = "tab" + index;

      // this.chartOptions.yAxis.title.text = "人數";
      // this.chartOptions.series[0].name = "人數";

      switch (index) {
        case 0:
          this.chartOptions.series[0].data =
            this.channalKPIData.max_follow_list;
          break;
        case 1:
          this.chartOptions.series[0].data =
            this.channalKPIData.avg_view_content_list;
          break;
        case 2:
          this.chartOptions.series[0].data = this.channalKPIData.avg_like_list;
          break;
        case 3:
          this.chartOptions.series[0].data =
            this.channalKPIData.avg_comment_list;
          break;
        case 4:
          this.chartOptions.series[0].data =
            this.channalKPIData.avg_post_interactions_list;
          break;
        case 5:
          this.chartOptions.series[0].data =
            this.channalKPIData.fans_up_probability_list;
          break;
      }
    },

    // TODO: 匯出網紅頻道KPI
    exportData() {
      let typeName = "網紅KPI";
      let tempDatas = [];
      tempDatas.push({
        名稱: this.channalKPIData.target_name,
        平台: this.platform.className,
        頻道: this.channalKPIData.channel_name,
        粉絲數:
          this.channalKPIData.max_follow == 0
            ? this.channalKPIData.max_like
            : this.channalKPIData.max_follow,
        總貼文數: this.channalKPIData.interval_post_count,
        平均留言數: this.channalKPIData.avg_comment,
        平均按讚數: this.channalKPIData.avg_like,
        平均互動數: this.channalKPIData.avg_post_interactions,
        互動率:
          this.parseFloatNoRounding(this.channalKPIData.avg_post_probability) +
          "%",
        影片平均觀看數: this.channalKPIData.avg_view_content,
        影片平均觀看率:
          this.parseFloatNoRounding(
            this.channalKPIData.avg_view_content /
              this.channalKPIData.max_follow
          ) + "%",
        影片平均互動數: this.channalKPIData.avg_post_interactions,
        影片平均互動率:
          this.parseFloatNoRounding(this.channalKPIData.avg_post_probability) +
          "%",
        漲粉數: this.channalKPIData.fans_up,
        漲粉率:
          this.parseFloatNoRounding(this.channalKPIData.fans_up_probability) +
          "%",
        計算區間: this.GetTimeRange(this.channalKPIData.update_at_list),
      });

      appUtils.exportData(
        tempDatas,
        appUtils.formatTime(new Date(), "YYYYMMDD") + "_" + typeName + ".xlsx",
        typeName
      );
    },

    // TODO: format時間區間
    GetTimeRange(timeRange) {
      if (timeRange.length > 1) {
        return (
          appUtils.formatTime(timeRange[0]) +
          "~" +
          appUtils.formatTime(timeRange[timeRange.length - 1])
        );
      } else if (timeRange.length == 1) {
        return appUtils.formatTime(timeRange[timeRange.length - 1]);
      }

      return "";
    },

    // TODO: 切換分頁
    onPageChange(pageIdx, pageSize) {
      //pageIdx 頁碼   //pageSize 一頁幾筆
      this.historySearchData.page_number = pageIdx;
      this.historySearchData.page_size = pageSize;

      this.GetHistoryListData();
    },

    // TODO: 判斷是否為Video
    isVideo(item) {
      if (item.platform == "yt" || item.is_video) return true;
      return false;
    },

    // TODO: format百分比
    parseFloatNoRounding(num) {
      if (isNaN(parseInt(num))) return 0;
      return Math.floor(num * 100) / 100;
    },

    pareToFloor(num) {
      if (isNaN(parseInt(num))) return 0;
      return Math.floor(num);
    },

    // TODO: 轉跳至編輯頁
    editorItem() {
      this.$router.push({
        name: "SpiderBigDataEdit",
        params: {
          id: this.$route.params.id,
          searchData: this.$route.params.searchData, back: "SpiderBigDataKOL"
        },
      });
      return;
    },

    // TODO: 轉跳至列表頁
    backList() {
      this.$router.push({
        name: this.$route.params.back || "SpiderBigDataList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },

    // TODO: 轉跳至文章KPI頁
    goPostData(item) {
      this.$router.push({
        name: "SpiderBigDataPost",
        params: {
          searchData: this.$route.params.searchData,
          kolSearchData: this.kolSearchData,
          id: this.$route.params.id,
          post_id: item.post_id,
          datas: {
            platform: this.platform,
            platformList: this.platformList,
            channel: this.channel,
            channelList: this.channelList,
          },
        },
      });
    },

  },
};
</script>

