<template>
  <div id="Dashboard">
    <section class="content-header">
      <h1>會員資料</h1>
      <ol class="breadcrumb">
        <li><router-link to="/">Home</router-link></li>
        <li v-if="name" class="active"><router-link :to="{name:'H5MemberData'}">會員資料</router-link></li>
        <li v-else class="active">會員資料</li>
        <li v-if="name" class="active">{{ name }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-primary mb-10">
        <div class="box-body">
          <h2 class="text-big text-center mt-10">各版本在線人數</h2>
          <div class="stats mb-10">
            <div :style="'color:'+ chartData.colors[0]">
              <h3 class="text-bold text-big m-0">紅版(APK)</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
            <div :style="'color:'+ chartData.colors[1]">
              <h3 class="text-bold text-big m-0">藍版(Google)</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
            <div :style="'color:'+ chartData.colors[2]">
              <h3 class="text-bold text-big m-0">iOS版</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
            <div :style="'color:'+ chartData.colors[3]">
              <h3 class="text-bold text-big m-0">PC版</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-tabs-custom mb-10">
        <div class="box-body">
          <highcharts :options="chartData"></highcharts>
        </div>
      </div>
      <div class="nav-tabs-custom">
        <div class="box-body">
          <h2 class="text-big text-center mt-10">金幣總數</h2>
          <div class="stats mb-10 text-muted">
            <div style="min-width:16em;">
              <h3 class="text-bold text-big m-0">同時在線上的人</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
            <div style="min-width:16em;">
              <h3 class="text-bold text-big m-0">在線上的活躍玩家星幣總數</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
            <div style="min-width:16em;">
              <h3 class="text-bold text-big m-0">不在線上的活躍玩家星幣總數</h3>
              <p class="text-black text-huge text-center">123456</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  .stats{
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
    >div{
      width: 0%;
      min-width: 10em;
      flex: 1 1 auto;
      border: 1px solid currentColor;
      border-radius: .25em;
      padding: .5em 1em;
    }
    h3, p {
      margin: 0;
    }
    h3{
      font-size: 1em;
    }
  }
</style>

<script>
/* eslint-disable */
import moment from "moment";
import router from "../../../router";

export default {
  components: {
    router
  },
  props:{
    name: ""
  },
  data() {
    return {
      loading: false,
      searchData: {
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
        nickName: "",
        phoneNumber: "",
        暱稱: "",
      },
      result: {},
      chartData:{
        credits: false,
        chart: { type: "spline", spacing: [5,5,5,5], height:360 },
        title: { text: "無資料" },
        xAxis: {
          // title: { text: "日期" },
          categories: ["3/11","3/12","3/13","3/14","3/15","3/16","3/17","3/18","3/19","3/20","3/21"]
        },
        yAxis: {
          title: { text: null /*"事件數"*/, margin: 0 },
          // labels: {}
        },
        series: [
          { name:"APK", data: [1,2,3,4,5,6,2,3,4,5,6] },
          { name:"Google", data: [6,5,4,3,2,3,4,5,6,2,1] },
          { name:"iOS", data: [7,4,2,3,4,5,6,1,8,5,2] },
          { name:"PC", data: [9,6,3,8,5,2,3,4,5,6,2] },
          { name:"Web", data: [7,2,3,4,5,6,3,2,8,9,4] },
        ],
        colors: ["#f44", "#48f", "#4b4", "#fa0", "#666"],
        tooltip: {
        },
      },
    };
  },
  async created() {

  },
  watch: {

  },
  methods: {
    uiDate(date) {
      let d = new Date(date);
      if (d && date) return d.toLocaleString('zh-TW', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
      else return '';
    }
  },
};
</script>