<template>
  <label>
    <input class="flat" :name="name" type="radio" :value="checkValue" />
    {{message}}
  </label>
</template>

<script>
export default {
  props: ["checkValue", "message", "name", "value"],
  data() {
    return {
      isChecked: "checkValue"
    };
  },
  mounted() {
    var vm = this;

    vm.setStatus(vm.value);

    $(vm.$el).on("ifToggled", function(e) {
      vm.setStatus(vm.checkValue);
    });

    $('input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue"
    });
  },
  watch: {
    value(value) {
      this.setStatus(value);
    }
  },
  methods: {
    setStatus(isChecked) {
      this.isChecked = isChecked;
      if (this.checkValue == this.isChecked) {
        $(this.$el).iCheck("toggle");
        this.$emit("input", this.isChecked);
        // if (isChecked == 1) this.$emit("onClick");
      }
    }
  }
};
</script>
