<template>
  <div :class="['slotPlayContent', showContent ? 'is-active' : '']">
    <div class="mask"></div>
    <div class="close" @click="goBack()">
      <i class="fa fa-close"></i>
    </div>
    <div class="content">
      <div :class="['main', !showInfo ? 'is-active' : '']">
        <div class="top-box">
          <div class="input-group mr-10">
            <input
              class="form-control pull-right"
              name="table_search"
              placeholder="遊戲館名稱"
              type="text"
              v-model="searchData.玩法名稱"
            />
            <i class="fa fa-close" v-if="searchData.玩法名稱" @click="searchData.玩法名稱 = ''"></i>
            <div class="input-group-btn">
              <button class="btn btn-default" type="button" @click="resetData()">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="data-num mr-10">
            <span>資料總筆數：</span>
            <span id="total">{{ total }}</span>
          </div>
          <div class="dropdown" style="display: inline;">
            <button
              class="btn btn-sort dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="dropdown = dropdown == 'none' ? 'block' : 'none'"
              >
              <div class="icon-sort"></div>
              排序依據
            </button>
            <ul class="dropdown-menu" :style="{ 'display': `${dropdown}` }">
              <li>
                <a 
                  :class="['dropdown-item', searchData.排序順序 == 2 ? 'active' : '']"
                  href="#" 
                  @click="resetData(2)"
                >
                  A to Z
                </a>
              </li>
              <li>
                <a
                  :class="['dropdown-item', searchData.排序順序 == 1 ? 'active' : '']"
                  href="#" 
                  @click="resetData(1)"
                >
                Z to A
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Empty v-if="total == 0" :title="'找不到結果'" :desc="'請改用其他關鍵字或移除排序條件'" />
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in list"
            @click="getData(item.編號)"
          >
            <span>{{ item.玩法名稱 }}</span>
          </div>
        </div>
      </div>
      <div :class="['sub', showInfo ? 'is-active' : '']">
        <div class="top">
          <button class="btn" @click="showInfo = false">
            <i class="fa fa-chevron-left"></i>
          </button>
          {{ data.玩法名稱 }}
        </div>
        <div class="editor text-center">
          <div v-html="data.詳細說明"></div>
          <p>已經到底了</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import api from "../assets/js/app-api";
import { mapState } from "vuex";
import Empty from "@/components/SlotEmpty";

export default {
  components: {
    Empty
  },
  data() {
    return {
      showContent: false,
      showInfo: false,
      dropdown: 'none',
      searchData: {
        玩法名稱: "",//關鍵字
        PageNum: 1,
        PageSize: 999,
        排序條件: 2,//1:更新時間,2.遊戲館名稱
        排序順序: 2,//1:DESC,2:ASC
      }
    };
  },
  async created() {
    // if (this.$route.params.searchData != undefined) {
    //   this.searchData = this.$route.params.searchData;
    // }
    this.getDataList();
    // this.$store.dispatch("slot/gameQuery", { 編號: this.searchData.遊戲編號 })
  },
  computed: {
    ...mapState({
      list: state => state.slot.playQueryAll.datas,
      total: state => state.slot.playQueryAll.total,
      data: state => state.slot.playQuery,
    }),
  },
  mounted() {
    // console.log(this.list);
  },
  watch: {
    
  },
  methods: {
    getDataList() {
      this.$store.dispatch("slot/playQueryAll", this.searchData);
    },
    getData(id) {
      this.$store.dispatch("slot/playQuery", { 編號: id})
        .then((res) => {
          this.showInfo = true;
          this.showContent = true;
        })
    },
    resetData(order = 1) {
      this.searchData.排序順序 = order;
      this.dropdown = "none";
      this.getDataList();
    },
    goBack() {
      this.showInfo = false;
      this.showContent = false;
    }
  }
};
</script>