/* eslint-disable */
import api from "@/assets/js/app-api";
import appUtils from "@/assets/js/app-utils";
// initial state
// shape: [{ id, quantity }]
const state = {
    queryAll: [],
    query: [],
    response: "",
}

// getters
const getters = {
}

// actions
const actions = {
    redeemPrizeQueryAll({ commit }, data) {
        api.redeemPrize.queryAll(data).then(res => {
            if (res.data.status == "1") {
                commit('setRedeemPrizeQueryAll', res.data.result);
            }
        })
    },
    redeemPrizeQuery({ commit }, data) {
        api.redeemPrize.query(data).then(res => {
            if (res.data.status == "1") {
                commit('setRedeemPrizeQuery', res.data.result);
            }
        })
    },
    redeemPrizeCreate({ commit }, data) {
        api.redeemPrize.create(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    redeemPrizeUpdate({ commit }, data) {
        api.redeemPrize.update(data).then(res => {
            commit('setResponse', res.data);
        })
    },
    redeemPrizeExport({ commit }, data) {
        api.redeemPrize.queryAll(data).then(res => {
            if (res.data.status == "1") {
                if (res.data.result.total <= 0) {
                    this._vm.$eventBus.$emit("showAlert", "查無資料");
                } else {
                    let datas = res.data.result.datas;

                    appUtils.exportData(
                        datas,
                        appUtils.formatTime(new Date(), "YYYYMMDD") + "_獎項兌換列表" + ".xlsx",
                        "獎項兌換列表"
                    );
                }

            } else {
                //let errCode = res.data.stateCode;
                let errMsg = res.data.message;
                this._vm.$eventBus.$emit("showAlert", errMsg);
            }
        })
    },
}

// mutations
const mutations = {
    setRedeemPrizeQueryAll(state, redeemPrizeQueryAll) {
        state.queryAll = redeemPrizeQueryAll;
    },
    setRedeemPrizeQuery(state, redeemPrizeQuery) {
        state.query = redeemPrizeQuery;
    },
    setResponse(state, response) {
        state.response = response;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}